import React from "react";
import PropTypes from "prop-types";
import { useHistory, useRouteMatch } from "react-router-dom";
import FlexRow from "../../../core/FlexRow";
import FlexColumn from "../../../core/FlexColumn";
import UnitTypeIcon from "../../unit-list/UnitTypeIcon";
import ColumnSeparator from "../../../core/ColumnSeparator";
import Separator from "../../../core/Separator";
import UnitButton from "../../unit-list/UnitButton";
import { useEntity } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";

function ContentVideoRelatedUnitsListItem({ unitId }) {
  const match = useRouteMatch();
  const history = useHistory();

  const unit = useEntity(UNIT_SCHEMA, unitId);

  return (
    <FlexRow position="relative" padding="18px 0">
      <FlexRow flex={1} alignItems="center" paddingRight={10}>
        <UnitTypeIcon width={60} height={60} type={unit.type} isDisabled={false} />
        <ColumnSeparator size="xs" />
        <FlexColumn alignItems="flex-start">
          <FlexRow alignItems="center">
            <h1
              style={{
                margin: 0,
                fontSize: 14,
                lineHeight: "14px",
                fontWeight: "bold",
                color: "#424242",
              }}
            >
              {unit.name}
            </h1>
          </FlexRow>
          <Separator size="xs" />
          <UnitButton
            onClick={() => {
              history.replace(
                `${match.url.replace(/\/[0-9a-fA-F]{24}\/content-video-exercise/, "")}/${unit.id}/exercise`,
                {
                  contentVideoRecommendation: true,
                }
              );
            }}
            isDisabled={false}
            type="START"
          />
        </FlexColumn>
      </FlexRow>
    </FlexRow>
  );
}

ContentVideoRelatedUnitsListItem.propTypes = {
  unitId: PropTypes.string.isRequired,
};

export default ContentVideoRelatedUnitsListItem;
