import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import AccountForm from "./AccountForm";
import { UPDATE_PROFILE_FLOW } from "../../consts";

const AccountFormContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => (
      <Entity name="schoolClass">
        {({ getById: getSchoolClassById }) => (
          <Entity name="school">
            {({ getById: getSchoolById }) => {
              const profile = getProfileById(sessionStorage.getItem("id"));
              const schoolClass = getSchoolClassById(profile.schoolClass);
              const school = getSchoolById(schoolClass?.school);
              return (
                <Flow id={UPDATE_PROFILE_FLOW}>
                  {({ isPending, handleStart }) => (
                    <AccountForm
                      onSubmit={handleStart}
                      isSubmitting={isPending}
                      initialValues={profile}
                      requiredSchoolFields={school?.requiredStudentFields || []}
                    />
                  )}
                </Flow>
              );
            }}
          </Entity>
        )}
      </Entity>
    )}
  </Entity>
);

export default AccountFormContainer;
