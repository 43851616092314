import React from "react";
import PropTypes from "prop-types";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const ScoreItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          transform: {
            sm: "scale(0.75)",
            md: "scale(1)",
          }[props.size],
        }}
      >
        <img
          src={props.iconSrc}
          alt="score-icon"
          style={{
            marginTop: 3,
            marginRight: 15,
            width: "auto",
            height: 52,
          }}
        />
        <div
          style={{
            textAlign: "left",
          }}
        >
          <TranslatedText
            as="label"
            translateKey={props.title}
            style={{
              color: {
                primary: "rgb(66, 66, 66)",
                gray: "rgb(66, 66, 66, 0.5)",
              }[props.intent],
              fontSize: 16,
              lineHeight: "24px",
              marginBottom: 8,
            }}
          />
          <h5
            style={{
              color: {
                primary: primaryColor,
                gray: "rgb(66, 66, 66, 0.8)",
              }[props.intent],
              fontSize: 32,
              fontWeight: 700,
              lineHeight: "24px",
              margin: 0,
            }}
          >
            {props.value}
            {props.secondaryValue && (
              <h3
                style={{
                  color: {
                    primary: primaryColor,
                    gray: "rgb(66, 66, 66, 0.8)",
                  }[props.intent],
                  fontSize: 20,
                  fontWeight: 700,
                  margin: 0,
                  display: "inline",
                }}
              >
                /{props.secondaryValue}
              </h3>
            )}
          </h5>
        </div>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

ScoreItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  secondaryValue: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md"]),
  intent: PropTypes.oneOf(["primary", "gray"]),
};

ScoreItem.defaultProps = {
  secondaryValue: null,
  size: "md",
  intent: "primary",
};

export default ScoreItem;
