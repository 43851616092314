import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../../core/Async";
import CourseThemeProvider from "../../core/CourseThemeProvider";
import CertificationTestExecutionScene from "./CertificationTestExecutionScene";
import {
  START_CERTIFICATION_TEST_EXECUTION_FLOW,
  END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
} from "../../consts";

class CertificationTestExecutionSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={!this.props.hasStarted}
        id={START_CERTIFICATION_TEST_EXECUTION_FLOW}
        params={{
          certificationTest: this.props.match.params.idCertificationTest,
        }}
      >
        {({ isPending: isStarting }) => (
          <Flow autoStart={false} id={END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW}>
            {({ isPending: isEndingAbility }) =>
              !isStarting && (
                <Flow
                  autoStart={true}
                  id={START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW}
                  params={{
                    certificationTest: this.props.match.params.idCertificationTest,
                    ability: this.props.match.params.ability,
                  }}
                >
                  {({ isPending }) => (
                    <CourseThemeProvider>
                      <Async fetching={isPending || isEndingAbility}>
                        <CertificationTestExecutionScene />
                      </Async>
                    </CourseThemeProvider>
                  )}
                </Flow>
              )
            }
          </Flow>
        )}
      </Flow>
    );
  }
}

const mapStateToProps = (state) => ({
  hasStarted: !!state.certificationTestResult.startedAt,
});

export default connect(mapStateToProps)(withRouter(CertificationTestExecutionSceneContainer));
