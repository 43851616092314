import React, { useRef } from "react";
import throttle from "lodash/throttle";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { readLongText } from "student-front-commons/src/actions/itemExecution";

const TextExerciseRender = (props) => {
  const text = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const dispatch = useDispatch();

  const readLongTextRef = useRef(
    throttle(() => {
      dispatch(readLongText());
    }, 30000)
  );
  const handleMouseMove = () => readLongTextRef.current();

  return (
    <div
      style={{
        maxWidth: 690,
        margin: "0 auto",
      }}
    >
      <HighlightableItemBox flexDirection="column">
        <div onMouseMove={handleMouseMove}>
          {text
            .split("\n")
            .filter((paragraph) => paragraph.length)
            .map((paragraph) => (
              <div
                key={`text-${props.itemId}-${paragraph}`}
                style={{
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <QuestionLabel asParagraph fontSize={16} text={paragraph} lineHeight="24px" />
              </div>
            ))}
        </div>
      </HighlightableItemBox>
    </div>
  );
};

export default function TextExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <TextExerciseRender key={id} itemId={id} />);
}
