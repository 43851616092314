import React from "react";
import PropTypes from "prop-types";
import Form from "student-front-commons/src/components/Form";
import { isEmail, isRequired, isUpdated } from "student-front-commons/src/validators";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import browserHistory from "../../browserHistory";
import TextInput from "../../core/form/TextInput";
import Row from "../../core/Row";
import Column from "../../core/Column";
import MaterialTheme from "../../core/MaterialTheme";
import Button from "../../core/form/Button";
import Separator from "../../core/Separator";
import RadioInput from "../../core/form/RadioInput";
import ColumnSeparator from "../../core/ColumnSeparator";
import Tooltip from "../../core/Tooltip";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";
import get from "lodash/get";
import HtmlForm from "../../core/HtmlForm";
import FlexRow from "../../core/FlexRow";
import CircularLoading from "../../core/CircularLoading";
import ProfilePictureFormContainer from "../../core/ProfilePictureFormContainer";
import { UPDATE_PROFILE_FORM } from "../../consts";
import FlexColumn from "../../core/FlexColumn";
import PhoneInput from "../../core/form/PhoneInput";

const AccountForm = (props) => (
  <MaterialTheme>
    <Form
      id={UPDATE_PROFILE_FORM}
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      validations={{
        nickname: [isRequired],
        email: [isRequired, isEmail],
        rankingParticipant: [isRequired],
        password: [(value, formValues) => (!value || !value.length) && formValues.newPassword && "Required"],
        newPassword: [(value, formValues) => (!value || !value.length) && formValues.password && "Required"],
        ...props.requiredSchoolFields.reduce(
          (acc, field) => ({
            ...acc,
            [field]: [
              ...(!props.initialValues.profileCompleted ? [isUpdated(props.initialValues[field])] : [isRequired]),
              ...(field === "email" ? [isEmail] : []),
            ],
          }),
          {}
        ),
      }}
    >
      {({ handleSubmit, handleChange, isSubmitted, values, errors }) => (
        <HtmlForm onSubmit={handleSubmit}>
          <Row>
            <div
              style={{
                padding: 50,
                backgroundColor: "#fff",
                boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.15)",
                borderBottomLeftRadius: 3,
                borderBottomRightRadius: 3,
                position: "relative",
              }}
            >
              <FlexColumn position="absolute" right={50}>
                <ProfilePictureFormContainer showFeedback={true} />
              </FlexColumn>
              <TranslatedText
                as="h1"
                translateKey="profile.account.title"
                style={{
                  color: "#607d8b",
                  lineHeight: "24px",
                  fontWeight: "bold",
                  fontSize: 14,
                  margin: 0,
                  textTransform: "uppercase",
                }}
              />
              <Separator size="md" />
              <Separator size="sm" />
              <Row>
                <Column lgSize={8} mdSize={8} smSize={6} xsSize={6}>
                  <Tooltip text={props.getTranslation("profile.account.nicknameTooltip")}>
                    <TextInput
                      disabled={props.initialValues.demoStudent || props.isSubmitting}
                      onChange={(value) => handleChange("nickname", value)}
                      value={get(values, "nickname", "")}
                      error={isSubmitted ? get(errors, "nickname", null) : null}
                      label="Nickname"
                      maxLength={15}
                      fullWidth
                    />
                  </Tooltip>
                </Column>
              </Row>
              <Separator size="md" />
              <Separator size="xl" />
              <Separator size="md" />
              {get(props, "requiredSchoolFields", []).some((field) => field === "name") && (
                <>
                  <Row>
                    <Column lgSize={12} mdSize={12} smSize={12} xsSize={12}>
                      <TextInput
                        disabled={props.initialValues.demoStudent || props.isSubmitting}
                        onChange={(value) => handleChange("name", value)}
                        value={get(values, "name", "")}
                        error={isSubmitted ? get(errors, "name", null) : null}
                        label={props.getTranslation("profile.account.name")}
                        fullWidth
                      />
                    </Column>
                  </Row>
                  <Separator size="md" />
                </>
              )}
              <Row>
                <Column lgSize={7} mdSize={7} smSize={6} xsSize={6}>
                  <TextInput
                    disabled={props.initialValues.demoStudent || props.isSubmitting}
                    onChange={(value) => handleChange("email", value)}
                    value={get(values, "email", "")}
                    error={isSubmitted ? get(errors, "email", null) : null}
                    label={props.getTranslation("profile.account.email")}
                    fullWidth
                  />
                </Column>
                <Column lgSize={5} mdSize={5} smSize={4} xsSize={4}>
                  <PhoneInput
                    disabled={props.initialValues.demoStudent || props.isSubmitting}
                    onChange={(value) => handleChange("contactPhone", value)}
                    value={get(values, "contactPhone", "")}
                    error={isSubmitted ? get(errors, "contactPhone", null) : null}
                    label={props.getTranslation("profile.account.contactPhone")}
                    fullWidth
                  />
                </Column>
              </Row>
              <Separator size="md" />
              <Row>
                <Column lgSize={12} mdSize={12} smSize={12} xsSize={12}>
                  <WhitelabelContext.Consumer>
                    {({ title }) => (
                      <RadioInput
                        disabled={props.initialValues.demoStudent || props.isSubmitting}
                        onChange={(value) => handleChange("rankingParticipant", value)}
                        value={get(values, "rankingParticipant", "")}
                        name=""
                        options={[
                          {
                            value: "S",
                            label: props.getTranslation("profile.account.rankingParticipant", { name: title }),
                          },
                          {
                            value: "N",
                            label: (
                              <FlexColumn>
                                <TranslatedText
                                  as="span"
                                  translateKey="profile.account.notRankingParticipant"
                                  values={title}
                                />
                                <div
                                  style={{
                                    fontSize: "13px",
                                  }}
                                >
                                  <TranslatedText as="small" translateKey="profile.account.rankingDescription" />
                                </div>
                              </FlexColumn>
                            ),
                          },
                        ]}
                      />
                    )}
                  </WhitelabelContext.Consumer>
                </Column>
              </Row>
              <Separator size="lg" />
              <TranslatedText
                as="h1"
                translateKey="profile.account.passwordTitle"
                style={{
                  color: "#607d8b",
                  lineHeight: "24px",
                  fontWeight: "bold",
                  fontSize: 14,
                  margin: 0,
                  textTransform: "uppercase",
                }}
              />
              <TranslatedText
                as="small"
                translateKey="profile.account.passwordDescription"
                style={{
                  color: "rgba(117, 117, 117, 0.8)",
                }}
              />
              <Row>
                <Column lgSize={6} mdSize={6} smSize={6} xsSize={6}>
                  <TextInput
                    type="password"
                    disabled={props.initialValues.demoStudent || props.isSubmitting}
                    onChange={(value) => {
                      handleChange("password", value);
                      if (!value || !value.length) {
                        handleChange("newPassword", value);
                      }
                    }}
                    value={get(values, "password", "")}
                    error={isSubmitted ? get(errors, "password", null) : null}
                    label={props.getTranslation("profile.account.currentPassword")}
                    fullWidth
                  />
                </Column>
                <Column lgSize={6} mdSize={6} smSize={6} xsSize={6}>
                  <TextInput
                    type="password"
                    disabled={props.initialValues.demoStudent || props.isSubmitting || !get(values, "password", false)}
                    onChange={(value) => handleChange("newPassword", value)}
                    value={get(values, "newPassword", "")}
                    error={isSubmitted ? get(errors, "newPassword", null) : null}
                    label={props.getTranslation("profile.account.newPassword")}
                    fullWidth
                  />
                </Column>
              </Row>
              <Separator size="md" />
              <Separator size="md" />
              <Separator size="md" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {props.initialValues.profileCompleted && (
                  <Button
                    type="button"
                    disabled={props.isSubmitting}
                    onClick={() => browserHistory.push("/")}
                    color="default"
                  >
                    {props.getTranslation("profile.account.button.back")}
                  </Button>
                )}
                <ColumnSeparator />
                <FlexRow alingItems="center">
                  {props.isSubmitting && (
                    <React.Fragment>
                      <CircularLoading size="xs" rgb={{ r: 141, g: 141, b: 141 }} />
                      <Separator />
                    </React.Fragment>
                  )}
                  <Button type="submit" disabled={props.initialValues.demoStudent || props.isSubmitting}>
                    {props.getTranslation("profile.account.button.save")}
                  </Button>
                </FlexRow>
              </div>
            </div>
          </Row>
        </HtmlForm>
      )}
    </Form>
  </MaterialTheme>
);

AccountForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    profileCompleted: PropTypes.bool,
    demoStudent: PropTypes.bool,
  }).isRequired,
  requiredSchoolFields: PropTypes.arrayOf(PropTypes.string),
};

AccountForm.defaultProps = {
  requiredSchoolFields: [],
};

export default withTranslation(AccountForm);
