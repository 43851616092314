import React, { Component } from "react";
import FetchData from "student-front-commons/src/components/FetchData";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import { getCurrentScore } from "student-front-commons/src/services/studyQualityService";
import StudyQualityPanel from "./StudyQualityPanel";
import { SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW } from "../../consts";

class StudyQualityPanelContainer extends Component {
  render() {
    return (
      <Flow autoStart={false} id={SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW}>
        {({ handleStart }) => (
          <Entity name="profile">
            {({ getById: getProfileById }) => (
              <Entity name="schoolClass">
                {({ getById: getSchoolClassById }) => {
                  const profile = getProfileById(sessionStorage.getItem("id"));
                  const schoolClass = getSchoolClassById(profile.schoolClass);

                  return (
                    <FetchData
                      service={getCurrentScore}
                      params={{
                        id: sessionStorage.getItem("id"),
                      }}
                    >
                      {({ data, isFetching }) => (
                        <StudyQualityPanel
                          currentScore={data}
                          fetching={isFetching}
                          studentProfile={profile}
                          isInEvaluationPeriod={profile.isInEvaluationPeriod}
                          hasEvaluationTemplate={
                            profile.apiVersion === "V2"
                              ? !!profile.evaluationTemplate
                              : !!schoolClass.evaluationTemplate
                          }
                          onHelpClick={handleStart}
                        />
                      )}
                    </FetchData>
                  );
                }}
              </Entity>
            )}
          </Entity>
        )}
      </Flow>
    );
  }
}

export default StudyQualityPanelContainer;
