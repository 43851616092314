import React, { useCallback, useState } from "react";
import Styled from "styled-components";
import TranslatedText from "../../core/TranslatedText";
import ReviewImportanceModal from "./ReviewImportanceModal";

const LinkButton = Styled.button`
  font-size: 12px;
  font-weight: 600;
  outline: none;
  background-color: transparent;
  border: none;
  color: inherit;
  paddingLeft: 0;
  textAlign: left;              
  :hover {
    text-decoration: underline;
  }
`;

function ReviewImportanceLinkButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);
  return (
    <>
      <LinkButton
        onClick={handleToggleModal}
        type="button"
        style={{
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
          textAlign: "center",
          width: "100%",
          paddingLeft: 0,
        }}
      >
        <TranslatedText
          as="span"
          translateKey="unitResult.reviewStep.didWell.reviewImportance"
          style={{
            fontSize: "14px",
            color: "#753FE5",
            fontWeight: 600,
          }}
        />
      </LinkButton>
      {isModalOpen && <ReviewImportanceModal isOpen={isModalOpen} onConfirm={handleToggleModal} />}
    </>
  );
}

export default ReviewImportanceLinkButton;
