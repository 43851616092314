import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import UnitSectionProgress from "./UnitSectionProgress";

class UnitSectionProgressContainer extends Component {
  static propTypes = {
    group: PropTypes.string.isRequired,
    percentageToEnableNextUnitGroup: PropTypes.number.isRequired,
  };

  render() {
    const previousGroup = String.fromCharCode(this.props.group.charCodeAt(0) - 1);
    return (
      <Entity name="unit">
        {({ getAll }) => {
          const units = getAll().filter(
            (unit) =>
              unit.module === this.props.match.params.idModule && unit.group === previousGroup && !unit.isContentVideo
          );
          const points = units.reduce(
            (acc, unit) => {
              acc.totalPoints += unit.defaultPoints + unit.firstReviewPoints;
              if (unit.yourChallengeStatus === "COMPLETED") {
                acc.conqueredPoints += unit.defaultPoints;
              }
              if (unit.firstReviewStatus === "COMPLETED") {
                acc.conqueredPoints += unit.firstReviewPoints;
              }
              return acc;
            },
            { totalPoints: 0, conqueredPoints: 0 }
          );

          const nextGroupTarget = (points.totalPoints * this.props.percentageToEnableNextUnitGroup) / 100;
          const percentage = (points.conqueredPoints / nextGroupTarget) * 100;
          return <UnitSectionProgress percentage={percentage} />;
        }}
      </Entity>
    );
  }
}

const mapStateToProps = (state) => ({
  percentageToEnableNextUnitGroup: state.configurations.percentageToEnableNextUnitGroup,
});

export default withRouter(connect(mapStateToProps)(UnitSectionProgressContainer));
