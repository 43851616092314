import React from "react";
import PropTypes from "prop-types";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import FlexColumn from "../../core/FlexColumn";

UnitReward.propTypes = {
  points: PropTypes.number.isRequired,
  completed: PropTypes.bool,
};

function UnitReward({ points, completed }) {
  return (
    <FlexColumn minWidth={122} padding="0px 15px" alignItems="center">
      <TranslatedText
        as="label"
        translateKey={completed ? "unit.reward.conqueredTitle" : "unit.reward.title"}
        style={{
          textTransform: "uppercase",
          fontSize: 11,
          color: "#727272",
          margin: 0,
        }}
      />
      <Separator size="sm" />
      <TranslatedText
        as="label"
        translateKey="unit.points"
        values={{
          points,
        }}
        style={{
          color: "#343434",
          textTransform: "uppercase",
          fontSize: 12,
          fontWeight: "bold",
          lineHeight: "14px",
          margin: 0,
          display: "block",
          width: "50%",
          textAlign: "center",
        }}
      />
    </FlexColumn>
  );
}

export default UnitReward;
