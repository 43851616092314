import React from "react";
import PropTypes from "prop-types";

export default class FetchPageableData extends React.PureComponent {
  static propTypes = {
    service: PropTypes.func.isRequired,
    pageContentProperty: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired,
    params: PropTypes.shape({}),
  };

  static defaultProps = {
    params: {},
  };

  state = {
    isFetching: true,
    hasError: false,
    data: [],
  };

  fetchPage = async () => {
    try {
      const data = await this.props.service({
        ...this.props.params,
        page: this.props.page,
        size: this.props.size,
      });

      this.setState({
        data: [...this.state.data, ...data[this.props.pageContentProperty]],
        error: null,
        hasError: false,
        isFetching: false,
      });
    } catch (error) {
      this.setState({
        data: [],
        error: error.message,
        hasError: true,
        isFetching: false,
      });
    }
  };

  async componentDidMount() {
    await this.fetchPage();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      await this.fetchPage();
    }
  }

  render() {
    return this.props.children({
      data: this.state.data,
      error: this.state.error,
      isFetching: this.state.isFetching,
      hasError: this.state.hasError,
    });
  }
}
