import React from "react";
import PropTypes from "prop-types";
import ContentVideoRelatedUnitsListItem from "./ContentVideoRelatedUnitsListItem";
import FlexColumn from "../../../core/FlexColumn";

function ContentVideoRelatedUnitsList({ unitsIds }) {
  return (
    <div>
      {unitsIds.map((unitId, index) => (
        <FlexColumn key={unitId}>
          <ContentVideoRelatedUnitsListItem unitId={unitId} />
          {unitsIds.length - 1 !== index && (
            <div style={{ width: "100%", height: "2px", backgroundColor: "#D9D9D9" }} />
          )}
        </FlexColumn>
      ))}
    </div>
  );
}

ContentVideoRelatedUnitsList.propTypes = {
  unitsIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ContentVideoRelatedUnitsList;
