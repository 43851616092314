import { schema } from "normalizr";

import unitTypeSchema from "./unitTypeSchema";
import { UNIT_SCHEMA } from "./index";
import functionOfLanguageSchema from "./functionOfLanguageSchema";

const getUnitStatus = (unit) => {
  if (unit.disabled) {
    return "LOCKED";
  } else if (!unit.defaultFinishedAt) {
    return "YOUR_CHALLENGE";
  } else if (unit.firstReviewAvailableAt && !unit.firstReviewFinishedAt) {
    return "FIRST_REVIEW";
  } else if (unit.secondReviewAvailableAt && !unit.secondReviewFinishedAt) {
    return "SECOND_REVIEW";
  } else if (unit.simpleReviewCount < 3) {
    return "SIMPLE_REVIEW";
  }
  return null;
};

const getChallengeStatus = (availableAt, finishedAt) => {
  if (!availableAt) {
    return "BLOCKED";
  } else if (availableAt && !finishedAt) {
    return "AVAILABLE";
  } else if (finishedAt) {
    return "COMPLETED";
  }
  return null;
};

export default new schema.Entity(
  UNIT_SCHEMA,
  {
    type: unitTypeSchema,
    functionsOfLanguage: [functionOfLanguageSchema],
  },
  {
    processStrategy: (entity) => {
      return {
        ...entity,
        isContentVideo: entity.type.name.toLowerCase().replace(/ /g, "-") === "content-video",
        status: getUnitStatus(entity),
        yourChallengeStatus:
          !entity.disabled && !entity.availableAt
            ? "AVAILABLE"
            : getChallengeStatus(entity.availableAt, entity.defaultFinishedAt),
        firstReviewStatus: getChallengeStatus(entity.firstReviewAvailableAt, entity.firstReviewFinishedAt),
        secondReviewStatus: getChallengeStatus(entity.secondReviewAvailableAt, entity.secondReviewFinishedAt),
      };
    },
  }
);
