import { useParams } from "react-router-dom";
import FlexColumn from "../../core/FlexColumn";
import Separator from "../../core/Separator";
import ButtonV2 from "../../core/ButtonV2";
import TranslatedText from "../../core/TranslatedText";
import FlexRow from "../../core/FlexRow";
import { useService } from "student-front-commons/src/hooks";
import { getBonusTestRewards } from "student-front-commons/src/services/bonusTestService";
import { useState } from "react";
import Async from "../../core/Async";
import BonusTestStartModal from "./BonusTestStartModal";
import BonusTestGift from "../../images/bonus-test-gift.png";
import BonusTestRewardsItem from "./BonusTestRewardsItem";

export default function BonusTestRewards() {
  const params = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rewards, setRewards] = useState({});
  const [isFetchingRewards] = useService(getBonusTestRewards, {
    autoStart: true,
    params: {
      idCourse: params.idCourse,
    },
    onData: (data) => setRewards(data),
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Async fetching={isFetchingRewards}>
      <Separator size="lg" />
      <FlexColumn alignItems="center" position="relative">
        <img src={BonusTestGift} />
        <Separator size="md" />
        <TranslatedText
          as="span"
          translateKey="bonusTest.introduction.welcome"
          style={{ color: "#fff", fontSize: "28px", fontWeight: 700 }}
        />
        <TranslatedText
          as="p"
          translateKey="bonusTest.introduction.description"
          style={{
            color: "#fff",
            width: "400px",
            fontSize: "14px",
            lineHeight: "21px",
            textAlign: "center",
            marginTop: "4px",
          }}
        />
        <FlexRow
          width="528px"
          alignItems="center"
          justifyContent="space-between"
          marginTop="8px"
          marginBottom="52px"
          zIndex="9"
        >
          <BonusTestRewardsItem reward={rewards.bronze} />
          <BonusTestRewardsItem reward={rewards.silver} />
          <BonusTestRewardsItem reward={rewards.gold} />
        </FlexRow>
        <div style={{ zIndex: 2 }}>
          <ButtonV2 labelKey="bonusTest.start" onClick={toggleModal} intent="success" size="md" />
        </div>
      </FlexColumn>
      <div
        style={{
          backgroundColor: "#00000010",
          height: "400px",
          width: "100%",
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <BonusTestStartModal isOpen={isModalOpen} onClose={toggleModal} />
    </Async>
  );
}
