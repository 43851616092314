import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { getRankingByPeriodAndLevel } from "student-front-commons/src/services/rankingService";
import FetchData from "student-front-commons/src/components/FetchData";
import Async from "../../core/Async";
import ClosedRankingList from "./ClosedRankingList";

class ClosedRankingListContainer extends Component {
  static propTypes = {
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
    level: PropTypes.oneOf(["national", "regional"]).isRequired,
  };

  render() {
    return (
      <FetchData
        key={`${this.props.level}-${this.props.type}`}
        service={getRankingByPeriodAndLevel}
        params={{
          id: sessionStorage.getItem("id"),
          size: 11,
          level: this.props.level,
          from: this.props.from,
          to: this.props.to,
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching || !data} size="sm" color="dark">
            <ClosedRankingList students={data} />
          </Async>
        )}
      </FetchData>
    );
  }
}

export default withRouter(ClosedRankingListContainer);
