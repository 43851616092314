import React, { useCallback, useEffect, useRef, useState } from "react";
import MediaQuery from "react-responsive";
import { GREY_5 } from "../../consts/color";
import CircularButton from "../CircularButton";
import { useSelector } from "react-redux";
import { isInstructionHighlighted } from "student-front-commons/src/selectors/itemInstruction";
import { get } from "lodash";

export default function ControlBarLayoutItemResponsive(props) {
  const popoverRef = useRef();
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleOutsidePopoverClick, false);

    return () => {
      document.removeEventListener("click", handleOutsidePopoverClick, false);
    };
  }, []);

  const handleOutsidePopoverClick = (e) => {
    if (popoverRef.current && popoverRef.current.contains(e.target)) {
      return false;
    }

    if (showMore) {
      setShowMore(false);
    }
  };

  const toggleMore = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  const isInstructionEnabled = useSelector((state) =>
    props.types.some((t) => get(state.itemInstructions, "step", "") === t)
  );
  const shouldHighlight = useSelector(isInstructionHighlighted);

  return (
    <div
      style={{
        position: "relative",
        display: "inline-block",
      }}
      ref={popoverRef}
    >
      <MediaQuery minWidth={541}>{props.children}</MediaQuery>
      <MediaQuery maxWidth={540}>
        <div
          style={{
            position: "absolute",
            bottom: showMore ? 80 : -160,
            opacity: showMore ? 1 : 0,
            transition: "all .5s",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            display: "inline-block",
            textAlign: "center",
            position: "relative",
            pointerEvents: isInstructionEnabled && shouldHighlight ? "none" : "all",
          }}
          className={
            !showMore && isInstructionEnabled
              ? shouldHighlight
                ? "animate__animated animate__bounce animate__infinite"
                : "animate__animated animate__pulse animate__infinite"
              : null
          }
        >
          <MediaQuery maxWidth={540}>
            {(matches) => (
              <CircularButton
                icon="dots-horizontal"
                color={GREY_5}
                size={matches ? "md" : "lg"}
                onClick={toggleMore}
                disabled={props.isDisabled}
              />
            )}
          </MediaQuery>
        </div>
      </MediaQuery>
    </div>
  );
}
