import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import CircularLoading from "./CircularLoading";

const AsyncContent = Styled.div`
  opacity: ${(props) => (props.fetching ? 0 : 1)};
  transition: all .3s ease-in;
`;

const Async = (props) => (
  <div>
    {props.fetching && (
      <div
        style={{
          margin: props.margin || "60px 0",
        }}
      >
        <CircularLoading
          size={props.size}
          rgb={{
            r: props.color === "dark" ? 237 : 255,
            g: props.color === "dark" ? 237 : 255,
            b: props.color === "dark" ? 237 : 255,
          }}
        />
      </div>
    )}
    <AsyncContent fetching={props.fetching}>{props.fetching ? null : props.children}</AsyncContent>
  </div>
);

Async.propTypes = {
  fetching: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["sm", "md"]),
  color: PropTypes.oneOf(["default", "dark"]),
  margin: PropTypes.string,
};

Async.defaultProps = {
  size: "md",
  color: "default",
  margin: null,
};

export default Async;
