import React from "react";
import PropTypes from "prop-types";
import StatsList from "./StatsList";
import StatsHeader from "./StatsHeader";

const StudyQualityStatsScene = (props) => (
  <div
    style={{
      backgroundColor: "#fff",
      borderRadius: 10,
      overflow: "hidden",
      maxWidth: 730,
      width: "100%",
      margin: "0 auto",
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 8px 0px",
    }}
  >
    <StatsHeader score={props.studyQualityDetail.score} />
    <StatsList stats={props.studyQualityDetail.requirements} />
  </div>
);

StudyQualityStatsScene.propTypes = {
  studyQualityDetail: PropTypes.shape({}).isRequired,
};

export default StudyQualityStatsScene;
