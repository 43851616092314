import React from "react";
import TranslatedText from "../TranslatedText";
import FlexRow from "../FlexRow";
import FlexColumn from "../FlexColumn";
import PropTypes from "prop-types";

function ValidationWrapper(props) {
  return (
    <FlexColumn alignItems="center" jusitfyItems="center" marginTop="30px" gap="20px">
      <div style={{ background: "#607d8b", padding: "5px 20px", borderRadius: "30px" }}>
        <TranslatedText
          as="span"
          translateKey="items.correctAnswer"
          style={{
            fontSize: 16,
            color: "#ffffff",
          }}
        />
      </div>
      <FlexRow flexWrap="wrap" width="max-content" maxWidth={800} justifyContent="center">
        {props.children}
      </FlexRow>
    </FlexColumn>
  );
}

ValidationWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ValidationWrapper;
