import React from "react";
import PropTypes from "prop-types";
import RepeatButton from "./RepeatButton";
import { PLAY_ITEM_AUDIO_FLOW, PLAY_ITEM_VIDEO_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function RepeatButtonContainer() {
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const recordCount = useSelector(getCurrentItemExecutionProp("recordCount"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const [isPending, handleStart] = useFlow(
    ["VIDEO", "VIDEO_LONG"].some((t) => t === itemType) ? PLAY_ITEM_VIDEO_FLOW : PLAY_ITEM_AUDIO_FLOW
  );

  const isRepeatDisabled = () => {
    if (
      isExecutionValidated &&
      ["DICTATION", "MULTIPLE_CHOICE_TEXT", "SINGLE_CHOICE_IMAGE", "SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_AUDIO"].some(
        (type) => type === itemType
      )
    ) {
      return isPending;
    }
    if (
      [
        "PRESENTATION",
        "SPEECH_PRACTICE",
        "VIDEO_SHORT",
        "SINGLE_CHOICE_TEXT",
        "SINGLE_CHOICE_IMAGE",
        "SINGLE_CHOICE_AUDIO",
        "MULTIPLE_CHOICE_TEXT",
        "TRUE_FALSE",
        "VOCABULARY_ACADEMIC",
      ].some((type) => type === itemType)
    ) {
      return isDisabled || isRecording || isSubmittingRecord;
    }
    if (["PRESENTATION_SPEECHLESS", "VOCABULARY_ACADEMIC_SPEECHLESS"].some((type) => type === itemType)) {
      return isDisabled || repeatCount > 0;
    }
    if (itemType === "PRONUNCIATION") {
      return isDisabled || isSubmittingRecord || !recordCount;
    }
    if (["SPEECH_PRACTICE_SPEECHLESS", "DICTATION"].some((type) => type === itemType)) {
      return isDisabled;
    }
    if (["AUDIO_LONG", "VIDEO_LONG"].some((type) => type === itemType)) {
      return isDisabled || repeatCount > 0;
    }
    return true;
  };

  return (
    <RepeatButton
      onClick={handleStart}
      isDisabled={isPending || (currentInstructionStep && currentInstructionStep !== "repeat") || isRepeatDisabled()}
    />
  );
}

RepeatButtonContainer.propTypes = {
  isPlacementRepeat: PropTypes.bool,
};

RepeatButtonContainer.defaultProps = {
  isPlacementRepeat: false,
  type: "audio",
};

export default RepeatButtonContainer;
