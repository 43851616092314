import request from "../core/request";
import safeCall from "../core/safeCall";

/**
 * get all configurations and audios
 *
 * @throws {ApiError} throws an exception with the api error
 *
 */
export const getConfiguration = async () => {
  return await safeCall(
    async () => {
      return await request({
        url: "configuration",
        method: "get",
      });
    },
    "error_load_configuration",
    "Unexpected error to load configurations."
  );
};
