import React from "react";
import PropTypes from "prop-types";
import Paragraph from "../../../core/Paragraph";
import FlexColumn from "../../../core/FlexColumn";
import TranslatedText from "../../../core/TranslatedText";
import { useSelector } from "react-redux";
import { getRecommendedUnitIdsByGrammarsIdsAndModuleId } from "student-front-commons/src/selectors/entity";
import ContentVideoRelatedUnitsList from "./ContentVideoRelatedUnitsList";
import AttachmentFile from "./AttachmentFile";
import ScrolledContent from "../../../core/ScrolledContent";

function ContentVideoInformationSection({ unit, idModule }) {
  const relatedUnits = useSelector(getRecommendedUnitIdsByGrammarsIdsAndModuleId(unit.grammars, idModule));

  return (
    <ScrolledContent
      style={{ display: "flex", height: "calc(100vh - 140px)", width: 310, flexDirection: "column" }}
      vertical
      containerColor="transparent"
    >
      <FlexColumn
        boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.25)"
        minHeight="calc(100vh - 140px)"
        background="#fff"
        gap={26}
        padding="36px 17px"
      >
        <FlexColumn gap={8}>
          <span
            style={{
              color: "#2A2E34",
              fontSize: "16px",
              fontWeight: 700,
            }}
          >
            {unit.name}
          </span>
          {unit?.description && <Paragraph style={{ color: "#2A2E34" }}>{unit.description}</Paragraph>}
        </FlexColumn>
        <div style={{ backgroundColor: "#D9D9D9", height: "2px", width: "100%" }} />
        <FlexColumn gap={8}>
          <TranslatedText
            as="span"
            translateKey="contentVideo.execution.attachments"
            style={{
              color: "#2A2E34",
              fontSize: "16px",
              fontWeight: 700,
            }}
          />
          {!unit?.attachments?.length ? (
            <TranslatedText
              as="span"
              translateKey="contentVideo.execution.noAttachments"
              style={{
                color: "#2A2E34",
                fontSize: "12px",
              }}
            />
          ) : (
            unit.attachments?.map((att, index) => (
              <AttachmentFile src={att} position={index + 1} labelKey="contentVideo.execution.lesson" />
            ))
          )}
        </FlexColumn>
        <FlexColumn gap={8} overflow="hidden">
          <TranslatedText
            as="span"
            translateKey="contentVideo.execution.recommendedUnits"
            style={{
              color: "#2A2E34",
              fontSize: "16px",
              fontWeight: 700,
            }}
          />
          {!relatedUnits?.length ? (
            <TranslatedText
              as="span"
              translateKey="contentVideo.execution.noRecommendedUnits"
              style={{
                color: "#2A2E34",
                fontSize: "12px",
              }}
            />
          ) : (
            <ContentVideoRelatedUnitsList unitsIds={relatedUnits} />
          )}
        </FlexColumn>
      </FlexColumn>
    </ScrolledContent>
  );
}

ContentVideoInformationSection.propTypes = {
  unit: PropTypes.object.isRequired,
  idModule: PropTypes.string.isRequired,
};

export default ContentVideoInformationSection;
