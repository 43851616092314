import React from "react";
import BonusTestResultScene from "./BonusTestResultScene";
import Async from "../../core/Async";
import { END_BONUS_TEST_EXECUTION_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";

export default function BonusTestResultSceneContainer() {
  const [isFlowPending] = useFlow(END_BONUS_TEST_EXECUTION_FLOW, { autoStart: false });

  return (
    <Async fetching={isFlowPending || false}>
      <BonusTestResultScene />
    </Async>
  );
}
