import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestNextStep, requestConfigEnd } from "../../actionCreators/microphoneConfig";
import InitialStepOptions from "./InitialStepOptions";

const InitialStepOptionsContainer = (props) => (
  <InitialStepOptions onStart={props.handleStart} onDismiss={props.handleDismiss} />
);

InitialStepOptionsContainer.propTypes = {
  handleDismiss: PropTypes.func.isRequired,
  handleStart: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  handleDismiss: () => dispatch(requestConfigEnd()),
  handleStart: () => dispatch(requestNextStep("SPEECH_RECOGNITION")),
});

export default connect(null, mapDispatchToProps)(InitialStepOptionsContainer);
