import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getNotifications } from "student-front-commons/src/services/notificationService";
import { LOAD_NOTIFICATIONS_FLOW, SET_NOTIFICATIONS_READ_FLOW } from "../consts";
import { requestMergeNotifications } from "../actionCreators/notification";
import { logError } from "../util";
import browserHistory from "../browserHistory";
import moment from "moment";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import get from "lodash/get";
import { mergeEntities } from "student-front-commons/src/actions/entity";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_NOTIFICATIONS_FLOW), function* () {
    try {
      const flow = yield select(getFlow(LOAD_NOTIFICATIONS_FLOW));

      const pageableNotifications = yield call(getNotifications, {
        id: sessionStorage.getItem("id"),
        ...flow.params,
      });

      yield put(requestMergeNotifications(pageableNotifications));

      const closedRankingNotification = pageableNotifications.docs.find((n) => n.type === "CLOSED_RANKING" && !n.read);
      if (!!closedRankingNotification) {
        browserHistory.push(`/rankings/${moment(closedRankingNotification.createdAt).format("MMYYYY")}/closed`);
        yield put(startFlow(SET_NOTIFICATIONS_READ_FLOW));
      }
      const courseNotification = pageableNotifications.docs.find(
        (n) =>
          !n.read &&
          [
            "MODULE_GROUP_ENABLED",
            "UNIT_GROUP_ENABLED",
            "FIRST_REVIEW_ENABLED",
            "SECOND_REVIEW_ENABLED",
            "MASTERY_TEST_ENABLED",
            "MASTERY_TEST_RETRY",
          ].some((type) => type === n.type)
      );
      if (!!courseNotification) {
        const modules = yield call(getModulesByCourse, {
          id: sessionStorage.getItem("id"),
          course: get(courseNotification, "course.id", get(courseNotification, "module.course")),
        });
        yield put(mergeEntities(modules.entities));
      }
    } catch (error) {
      logError({ error, flow: LOAD_NOTIFICATIONS_FLOW });
    } finally {
      yield put(endFlow(LOAD_NOTIFICATIONS_FLOW));
    }
  });
}
