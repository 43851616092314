import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import * as Sentry from "@sentry/react";
import changeFormValueValidator from "student-front-commons/src/middlewares/changeFormValueValidator";
import submitFormValueValidator from "student-front-commons/src/middlewares/submitFormValueValidator";
import StudentFrontCommons from "student-front-commons/src";
import LogRocket from "logrocket";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import "bootstrap/dist/css/bootstrap.min.css";
import "@mdi/font/css/materialdesignicons.css";
import "animate.css/animate.css";
import "./index.css";
import "moment/min/locales";
import "numeral/locales";
import locales from "./locales";
import reducers from "./reducers";
import flows from "./flows";
import AppRoutes from "./AppRoutes";
import numeral from "numeral";

//set de default locale
numeral.locale("en-au");

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE,
    environment: process.env.REACT_APP_ENVIRONMENT,
    // integrations: [new Integrations.BrowserTracing()],
    // tracesSampleRate: process.env.REACT_APP_ENVIRONMENT === "production" ? 0.5 : 1.0,
    attachStacktrace: true,
    ignoreErrors: ["InvalidTokenError"],
    normalizeDepth: 10,
    beforeSend: (event, hint) => {
      const error = hint.originalException;
      const exceptionsToFilterOut = /invalid password|invalid or expired token/i;
      if (error && error.message && error.message.match(exceptionsToFilterOut)) {
        return null;
      }
      return event;
    },
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (hint && hint.xhr) {
        const logRocketUrl = /r\.logrocket\.io|google-analytics\.com/i;
        if (hint.xhr.responseURL.match(logRocketUrl)) {
          return null;
        }
      }
      return breadcrumb;
    },
  });
}

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    Sentry.withScope((scope) => {
      scope.setTag("location", "generic-saga-error-handler");
      scope.setExtra("error", JSON.stringify(error));
      Sentry.captureException(error);
    });
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: () => null,
  stateTransformer: (state) => {
    if (isEmpty(state.forms)) {
      return null;
    }
    return { forms: state.forms };
  },
});

const middleware = [changeFormValueValidator, submitFormValueValidator, sagaMiddleware];

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  middleware.push(LogRocket.reduxMiddleware());
}

if (process.env.REACT_APP_ENVIRONMENT === "development") {
  middleware.push(createLogger());
}

const store = createStore(reducers, compose(applyMiddleware(...middleware), sentryReduxEnhancer));

sagaMiddleware.run(flows);

locales.setLanguage(toLower(navigator.language || navigator.languages[0]).split("-")[0]);

render(
  <StudentFrontCommons
    assetsUrl={process.env.REACT_APP_FILES_URL}
    endpointUrl={process.env.REACT_APP_API_URL}
    speechRecognitionKey={process.env.REACT_APP_SPEECH_RECOGNITION_API_KEY}
    speechRecognitionEndpointUrl={process.env.REACT_APP_SPEECH_RECOGNITION_API_URL + "/speech-recognition"}
    setToken={(token) => sessionStorage.setItem("accessToken", token)}
    getToken={() => sessionStorage.getItem("accessToken")}
    onLogout={() => {
      sessionStorage.clear();
      if (window.location.pathname !== "/login") {
        window.location.reload();
      }
    }}
  >
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  </StudentFrontCommons>,
  document.getElementById("root")
);
