import { END, eventChannel } from "redux-saga";
import { call, put, race, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW, CLOSE_UNIT_EXECUTION_FLOW, DETECT_DEV_TOOLS_FLOW } from "../consts";
import { logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

function watchDevToolsEvent() {
  return eventChannel((emitter) => {
    window.addEventListener("devtools-open", () => {
      emitter({ isOpen: true });
      emitter(END);
    });
    return () => false;
  });
}

export default function* () {
  yield takeLatest(getFlowStart(DETECT_DEV_TOOLS_FLOW), function* () {
    yield race({
      cancelUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      cancelMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          if (process.env.REACT_APP_ENVIRONMENT === "development") {
            return;
          }

          const channel = yield call(watchDevToolsEvent);
          const { isOpen } = yield take(channel);
          if (isOpen) {
            yield put(
              showMessage({
                message: "error.error_inspect_html",
              })
            );
          }
        } catch (error) {
          logError({ error, flow: DETECT_DEV_TOOLS_FLOW });
        } finally {
          yield put(endFlow(DETECT_DEV_TOOLS_FLOW));
        }
      }),
    });
  });
}
