import React, { Component } from "react";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import UserAwayModal from "./UserAwayModal";
import { CLOSE_USER_AWAY_MODAL, SHOW_USER_AWAY_MODAL } from "../consts";

class UserAwayModalContainer extends Component {
  render() {
    return (
      <Flow autoStart={false} id={SHOW_USER_AWAY_MODAL}>
        {({ isPending, getParams }) => (
          <Flow autoStart={false} id={CLOSE_USER_AWAY_MODAL}>
            {({ handleStart: handleClose }) =>
              isPending && <UserAwayModal message={get(getParams(), "message", "")} onClose={handleClose} />
            }
          </Flow>
        )}
      </Flow>
    );
  }
}

export default UserAwayModalContainer;
