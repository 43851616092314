import React from "react";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import TranslatedText from "../TranslatedText";
import CircularButton from "../CircularButton";
import PageableScroll from "../PageableScroll";
import MessageChannelListContainer from "./MessageChannelListContainer";
import MessageChatHeaderContainer from "./MessageChatHeaderContainer";
import MessageChatListContainer from "./MessageChatListContainer";
import MessageChatReplyFormContainer from "./MessageChatReplyFormContainer";
import NewMessageFormContainer from "./NewMessageFormContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import NewMessageHeader from "./NewMessageHeader";

class MessageScene extends React.Component {
  state = { currentScene: "channels" };

  componentDidMount() {
    this.props.history.listen((location) => {
      this.setState({
        currentScene: get(location, "state.messagePopoverScene", "channels"),
      });
    });
  }

  render() {
    if (this.state.currentScene === "channels") {
      return (
        <WhitelabelContext.Consumer>
          {({ primaryColor }) => (
            <div>
              <div
                style={{
                  padding: "15px 30px",
                  backgroundColor: primaryColor,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TranslatedText
                  as="p"
                  translateKey="messages.title"
                  style={{
                    margin: 0,
                    fontSize: 24,
                    color: "#fff",
                  }}
                />
                <CircularButton
                  icon="plus"
                  color="rgba(255, 255, 255, 0.1)"
                  size="md"
                  onClick={(event) => {
                    event.preventDefault();
                    this.props.history.replace({
                      pathname: this.props.history.location.pathname,
                      state: {
                        messagePopoverScene: "new-message",
                      },
                    });
                  }}
                />
              </div>
              <PageableScroll pageHeight={270}>
                {({ page }) => <MessageChannelListContainer page={page} />}
              </PageableScroll>
            </div>
          )}
        </WhitelabelContext.Consumer>
      );
    }
    if (this.state.currentScene === "message-list") {
      return (
        <div>
          <MessageChatHeaderContainer />
          <MessageChatListContainer />
          <MessageChatReplyFormContainer />
        </div>
      );
    }
    if (this.state.currentScene === "new-message") {
      return (
        <React.Fragment>
          <NewMessageHeader />
          <NewMessageFormContainer />
        </React.Fragment>
      );
    }
    return null;
  }
}

export default withRouter(MessageScene);
