import React from "react";
import PropTypes from "prop-types";
import { WHITE } from "../../../consts/color";
import Separator from "../../../core/Separator";
import TranslatedText from "../../../core/TranslatedText";
import FlexColumn from "../../../core/FlexColumn";
import SuperBeginnerIcon from "../../../images/super-beginner-icon.svg";
import BeginnerIcon from "../../../images/beginner-icon.svg";
import FindLevelIcon from "../../../images/find-level-icon.svg";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Styled from "styled-components";
import CircularLoading from "../../../core/CircularLoading";

const Button = Styled.button`
  border: none;
  background: none;
  outline: none;
  padding: 0;
  margin: 0;
  transition: all ease-in-out 150ms;
  
  &:hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

const LevelSelectButton = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <Button onClick={props.onClick} disabled={props.isDisabled}>
        <FlexColumn
          backgroundColor={WHITE}
          padding="40px 20px"
          justifyContent="center"
          alignItems="center"
          borderRadius={3}
          maxWidth={250}
        >
          <img
            alt="level-select-icon"
            src={
              {
                none: SuperBeginnerIcon,
                beginner: BeginnerIcon,
                placement: FindLevelIcon,
              }[props.type]
            }
            style={{
              width: "auto",
              height: 51,
            }}
          />
          <Separator size="lg" />
          <TranslatedText
            as="h3"
            translateKey={`placementTest.levelSelect.${props.type}.title`}
            style={{
              color: primaryColor,
              fontSize: 18,
              fontWeight: "bold",
              margin: 0,
            }}
          />
          <Separator size="xs" />
          <TranslatedText
            as="p"
            translateKey={`placementTest.levelSelect.${props.type}.description`}
            style={{
              color: "#343434",
              fontSize: 14,
              margin: 0,
              textAlign: "center",
            }}
          />
          {props.isLoading && (
            <React.Fragment>
              <Separator size="sm" />
              <CircularLoading rgb={{ r: 230, g: 230, b: 230 }} />
            </React.Fragment>
          )}
        </FlexColumn>
      </Button>
    )}
  </WhitelabelContext.Consumer>
);

LevelSelectButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["none", "beginner", "placement"]).isRequired,
};

export default LevelSelectButton;
