import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import browserHistory from "../browserHistory";
import Icon from "./Icon";
import ColumnSeparator from "./ColumnSeparator";
import Button from "./Button";
import TranslatedText from "./TranslatedText";
import CertificationTestStatusFailedPointCountContainer from "./CertificationTestStatusFailedPointCountContainer";
import FlexRow from "./FlexRow";

const getStatus = (certificationTest, autoEnable) => {
  if (!certificationTest) {
    return null;
  } else if (certificationTest.failedAt && certificationTest.studentCheckedAt) {
    return "failed";
  } else if (certificationTest.reviewedAt && !certificationTest.studentCheckedAt) {
    return "reviewed";
  } else if (certificationTest.completedAt && !certificationTest.approvedAt && !certificationTest.failedAt) {
    return "awaiting_revision";
  } else if (
    certificationTest.startedAt &&
    !certificationTest.completedAt &&
    moment().diff(moment(certificationTest.startedAt), "hours", true) < 1.5
  ) {
    return "continue";
  } else if (certificationTest.enabledAt && !certificationTest.startedAt) {
    return "enabled";
  } else if (certificationTest.scheduledFor && !certificationTest.startedAt) {
    return autoEnable &&
      moment().format("DD-MM-YYYY") === moment(certificationTest.scheduledFor).utc().format("DD-MM-YYYY")
      ? "enabled"
      : "scheduled";
  } else if (certificationTest.createdAt && !certificationTest.startedAt) {
    return "created";
  }
  return null;
};

const CertificationTestStatusBar = (props) => {
  const status = getStatus(props.certificationTest, props.autoEnable);
  return (
    status && (
      <div
        style={{
          backgroundColor: {
            created: "#DBDBDB",
            scheduled: "rgba(255, 224, 130, 0.7)",
            enabled: "rgba(128, 203, 196, 0.7)",
            continue: "rgba(128, 203, 196, 0.7)",
            awaiting_revision: "#DBDBDB",
            reviewed: "rgba(255, 224, 130, 0.7)",
            failed: "#DBDBDB",
          }[status],
          borderRadius: 3,
          minHeight: 60,
          padding: "5px 15px",
          display: "flex",
          alignItems: "center",
          boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
          marginBottom: 20,
          width: "100%",
        }}
      >
        <Icon
          icon={
            {
              created: "alert-circle",
              scheduled: "calendar",
              enabled: "check",
              continue: "check",
              awaiting_revision: "eye",
              reviewed: "check-all",
              failed: "close",
            }[status]
          }
          size="md"
          color={
            {
              created: "#757575",
              scheduled: "#b85604",
              enabled: "#00695c",
              continue: "#00695c",
              awaiting_revision: "#757575",
              reviewed: "#b85604",
              failed: "#757575",
            }[status]
          }
        />
        <ColumnSeparator />
        <div
          style={{
            margin: 0,
            fontSize: 14,
            color: {
              created: "#757575",
              scheduled: "#b85604",
              enabled: "#00695c",
              continue: "#00695c",
              awaiting_revision: "#757575",
              reviewed: "#b85604",
              failed: "#757575",
            }[status],
          }}
        >
          {status === "created" && <TranslatedText translateKey="certificationTest.created" />}
          {status === "scheduled" && (
            <TranslatedText
              as="span"
              translateKey="certificationTest.scheduled"
              values={{
                scheduledTo: moment(props.certificationTest.scheduledFor).format("DD/MM/YYYY"),
              }}
            />
          )}
          {status === "enabled" && (
            <span>
              <TranslatedText as="span" translateKey="certificationTest.enabled" />
              <span
                style={{
                  display: "inline-block",
                  width: 40,
                }}
              />
              <Button
                onClick={() =>
                  browserHistory.push(
                    `/course/${props.certificationTest.course.id}/certification-test/${props.certificationTest.id}/abilities`
                  )
                }
                style={{
                  backgroundColor: "rgba(0, 150, 136, 0.8)",
                  boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
                  color: "#fff",
                  width: "auto",
                }}
              >
                <TranslatedText translateKey="certificationTest.button.start" />
              </Button>
            </span>
          )}
          {status === "continue" && (
            <span>
              <TranslatedText as="span" translateKey="certificationTest.continue" />
              <span
                style={{
                  display: "inline-block",
                  width: 40,
                }}
              />
              <Button
                onClick={() =>
                  browserHistory.push(
                    `/course/${props.certificationTest.course.id}/certification-test/${props.certificationTest.id}/abilities`
                  )
                }
                style={{
                  backgroundColor: "rgba(0, 150, 136, 0.8)",
                  boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
                  color: "#fff",
                  width: "auto",
                }}
              >
                <TranslatedText translateKey="certificationTest.button.continue" />
              </Button>
            </span>
          )}
          {status === "awaiting_revision" && <TranslatedText translateKey="certificationTest.awaitingRevision" />}
          {status === "reviewed" && (
            <span>
              <TranslatedText as="span" translateKey="certificationTest.reviewed" />
              <span
                style={{
                  display: "inline-block",
                  width: 40,
                }}
              />
              <Button
                onClick={() =>
                  browserHistory.push(
                    `/course/${props.certificationTest.course.id}/certification-test/${props.certificationTest.id}/result`
                  )
                }
                style={{
                  backgroundColor: "rgba(184, 86, 4, 0.8)",
                  boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
                  color: "#fff",
                  width: "auto",
                }}
              >
                <TranslatedText translateKey="certificationTest.button.reviewed" />
              </Button>
            </span>
          )}
          {status === "failed" && (
            <FlexRow alignItems="center">
              <TranslatedText
                as="span"
                translateKey="certificationTest.failed"
                values={{
                  unitsCounts: props.unitsToFinishAfterCertificationTestFailure,
                }}
              />
              <ColumnSeparator />
              <CertificationTestStatusFailedPointCountContainer
                course={props.certificationTest.course.id}
                unitsNeeded={props.unitsToFinishAfterCertificationTestFailure}
                failedAt={props.certificationTest.failedAt}
              />
            </FlexRow>
          )}
        </div>
      </div>
    )
  );
};

CertificationTestStatusBar.propTypes = {
  autoEnable: PropTypes.bool,
  certificationTest: PropTypes.shape({
    id: PropTypes.string,
    scheduledFor: PropTypes.string,
    failedAt: PropTypes.string,
  }),
  unitsToFinishAfterCertificationTestFailure: PropTypes.number,
};

CertificationTestStatusBar.defaultProps = {
  certificationTest: null,
  autoEnable: false,
  unitsToFinishAfterCertificationTestFailure: null,
};

export default CertificationTestStatusBar;
