import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue, initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { addSentryUserAction, linkAnswers, logError } from "../util";
import {
  GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  PLACEMENT_TEST_EXECUTION_FORM,
} from "../consts";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getExtraPlacementTestItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW), function* () {
    try {
      const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
      const placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));

      const extraItem = placementTestExecutionForm.values.extraPlacementTestItems.find((placementTestItem) => {
        return (
          placementTestItem.placementTestLevel.id === itemExecutionForm.values.associativeItem.placementTestLevel.id &&
          placementTestItem.item.grammar.id === itemExecutionForm.values.associativeItem.item.grammar.id
        );
      });

      if (!extraItem || !extraItem.item) {
        yield put(
          changeFormValue(
            PLACEMENT_TEST_EXECUTION_FORM,
            "currentIndex",
            placementTestExecutionForm.values.currentIndex + 1
          )
        );
        yield put(startFlow(GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
      } else {
        const linkedAnswers = linkAnswers(extraItem.item.answers);

        yield put(
          initForm(ITEM_EXECUTION_FORM, {
            associativeItem: {
              ...extraItem,
              item: {
                ...extraItem.item,
                order: extraItem.order,
                linkedAnswers: typesToOrderAnswers.find((type) => type === extraItem.item.type.key)
                  ? orderBy(linkedAnswers, "text", "asc")
                  : shuffle(linkedAnswers),
              },
            },
            startedAt: new Date(),
            isDisabled: true,
            answer: null,
            repeatCount: 0,
          })
        );

        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Placement Test Extra Item added`,
        });

        yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true, isPlacementRepeat: false }));
      }
    } catch (error) {
      logError({ error, flow: GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
    }
  });
}
