import React, { useState } from "react";
import PropTypes from "prop-types";
import { getCurrentPositions } from "student-front-commons/src/services/positionsService";
import StudentPosition from "./StudentPosition";
import withTranslation from "./withTranslation";
import { useService } from "student-front-commons/src/hooks";
import CircularLoading from "./CircularLoading";

function CurrentSchoolPositionContainer(props) {
  const [positions, setPositions] = useState({});
  const [isFetching] = useService(getCurrentPositions, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
    },
    onData: (data) => setPositions(data),
  });

  if (isFetching) {
    return <CircularLoading size="xs" />;
  }

  return <StudentPosition title={props.showTitle} color={props.color} position={positions.school} />;
}

CurrentSchoolPositionContainer.propTypes = {
  color: PropTypes.string,
  showTitle: PropTypes.bool,
};

export default withTranslation(CurrentSchoolPositionContainer);
