import React from "react";
import { useSelector } from "react-redux";
import TitleLabel from "../TitleLabel";
import Separator from "../Separator";
import ItemImage from "./ItemImage";
import HighlightableItemBox from "./HighlightableItemBox";
import ExerciseItemPanel from "./ExerciseItemPanel";
import SpeechRecognitionResultHistory from "../speech-recognition/SpeechRecognitionResultHistory";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";

function PresentationRender(props) {
  const title = useSelector(getItemExecutionPropById(props.itemId, "item.title"));

  return (
    <ExerciseItemPanel className="presentation-tour">
      {title && (
        <React.Fragment>
          <TitleLabel text={title} />
          <Separator />
        </React.Fragment>
      )}
      <HighlightableItemBox flexGrow={1}>
        <ItemImage
          itemId={props.itemId}
          style={{
            width: "100%",
          }}
        />
      </HighlightableItemBox>
      <Separator size="sm" />
      <SpeechRecognitionResultHistory />
    </ExerciseItemPanel>
  );
}

export default function PresentationExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <PresentationRender key={id} itemId={id} />);
}
