import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { saveUnitItemExecution } from "student-front-commons/src/services/unitExecutionService";
import { CLOSE_UNIT_EXECUTION_FLOW, END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW, END_UNIT_EXECUTION_FLOW } from "../consts";
import { logError } from "../util";
import { removeLastItemExecutionAttempt } from "student-front-commons/src/actions/itemExecution";
import { addExecutionAnswer } from "student-front-commons/src/actions/execution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { getEntityById } from "student-front-commons/src/selectors/entity";

export default function* () {
  yield takeLatest(getFlowStart(END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          let execution = yield select((state) => state.executions);
          const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
          const currentCourse = profile?.activeCourses?.find((c) => !c.distributor);

          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.reduce(function* (promise, id) {
            yield promise;

            const executionItem = yield select((state) => state.itemExecutions.byId[id]);

            const answer = yield call(saveUnitItemExecution, {
              module: execution.module,
              unit: execution.unit,
              unitExecution: execution.id,
              item: executionItem.item.id,
              correct: true,
              errorCount: executionItem.errorCount,
              repeatCount: executionItem.repeatCount,
              recordCount: executionItem.recordCount,
              listenCount: executionItem.listenCount,
              readCount: executionItem.readCount,
              translateCount: executionItem.translateCount,
              userAwayCount: executionItem.userAwayCount,
              timeReportingError: executionItem.timeReportingError,
              attempts: executionItem.attempts,
              audio: executionItem.recordFile,
            });

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(executionItem.item.id));

            yield put(addExecutionAnswer({ answer: answer.id, itemOrder: associativeItemOrder }));
          }, Promise.resolve());

          yield put(
            startFlow(END_UNIT_EXECUTION_FLOW, {
              module: execution.module,
              unit: execution.unit,
              execution: execution.id,
              course: currentCourse.id,
            })
          );
        } catch (error) {
          logError({ error, flow: END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW });

          yield put(
            showMessage({
              message: "error.error_save_answer",
            })
          );

          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.map(function* (id) {
            const executionItem = yield select((state) => state.itemExecutions.byId[id]);
            yield put(removeLastItemExecutionAttempt(executionItem.item.id));
          });
        } finally {
          yield put(endFlow(END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW));
        }
      }),
    });
  });
}
