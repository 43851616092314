import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Paragraph from "../../core/Paragraph";
import TranslatedText from "../../core/TranslatedText";
import AchievementList from "./AchievementList";
import AchievementListItem from "./AchievementListItem";
import numeral from "numeral";
import moment from "moment";
import withTranslation from "../../core/withTranslation";

class AchievementListContainer extends Component {
  static propTypes = {
    type: PropTypes.oneOf(["national", "regional", "school", "student"]).isRequired,
    achievements: PropTypes.arrayOf(
      PropTypes.shape({
        achievement: PropTypes.shape({
          icon: PropTypes.string,
          position: PropTypes.number,
          achievedAt: PropTypes.string,
          iconByPosition: PropTypes.arrayOf(
            PropTypes.shape({
              icon: PropTypes.string,
              position: PropTypes.number,
            })
          ),
        }),
      })
    ).isRequired,
  };

  getDescription = (studentAchievement) => {
    if (this.props.type === "student") {
      return `
      ${studentAchievement.achievement.description} <br/>
      ${moment(studentAchievement.achievedAt).format("DD/MM/YYYY")} <br/>
      ${
        !studentAchievement.points
          ? this.props.getTranslation("bonusTest.noPoints")
          : this.props.getTranslation("bonusTest.points", { points: studentAchievement.points })
      }
      `;
    }
    return `${numeral(studentAchievement.position).format("0o")} on ${moment(studentAchievement.achievedAt).format(
      "MMMM/YYYY"
    )}`;
  };

  render() {
    const achievements = this.props.achievements.filter(
      (studentAchievement) => get(studentAchievement, "achievement.level", "regional").toLowerCase() === this.props.type
    );
    return (
      <AchievementList title={this.props.type}>
        {!achievements.length && (
          <TranslatedText
            as={Paragraph}
            translateKey={
              {
                national: "profile.achievement.noNationalData",
                regional: "profile.achievement.noRegionalData",
                school: "profile.achievement.noSchoolData",
                student: "profile.achievement.noStudentData",
              }[this.props.type]
            }
          />
        )}
        {achievements.map((studentAchievement) => (
          <AchievementListItem
            key={`achievement-${studentAchievement.id}`}
            description={this.getDescription(studentAchievement)}
            iconSrc={
              studentAchievement.achievement.iconByPosition.find(
                (iconPosition) => iconPosition.position === studentAchievement.position
              ).icon
            }
          />
        ))}
      </AchievementList>
    );
  }
}

export default withTranslation(AchievementListContainer);
