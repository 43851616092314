import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Spring } from "react-spring";
import { sample, get } from "lodash";
import RecordIcon from "../../images/icon-record.svg";
import TargetIcon from "../../images/icon-target.svg";
import SingleStar from "../../images/unit-execution-single-start.png";
import NotificationAudioOne from "../../audios/unit-execution-notification-1.mp3";
import NotificationAudioTwo from "../../audios/unit-execution-notification-2.mp3";
import ConfettiAnimation from "../../lotties/unit-execution-confetti.json";
import translations from "../../locales";
import { WHITE } from "../../consts/color";

export default class UnitExecutionToastListItem extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onHide: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.timeout = setTimeout(() => this.props.onHide(this.props.value), 5000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const type = this.props.value.substring(this.props.value.indexOf("_") + 1);
    const count = this.props.value.substring(0, this.props.value.indexOf("_"));
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {(type === "SPEECH_RECOGNITION_SEQUENCE" || type === "CHECKPOINT_SEQUENCE") && (
          <React.Fragment>
            {(this.props.value.startsWith("3_") || this.props.value.startsWith("6_")) && (
              <audio src={NotificationAudioOne} autoPlay={true} />
            )}
            {(this.props.value.startsWith("9_") || this.props.value.startsWith("MAX")) && (
              <audio src={NotificationAudioTwo} autoPlay={true} />
            )}
            {this.props.value.startsWith("MAX") && (
              <div
                style={{
                  position: "absolute",
                  top: -50,
                }}
              >
                <Lottie
                  width={260}
                  options={{
                    animationData: ConfettiAnimation,
                    loop: false,
                    autoplay: true,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                />
              </div>
            )}
            {(this.props.value.startsWith("6_") ||
              this.props.value.startsWith("9_") ||
              this.props.value.startsWith("MAX")) && (
              <React.Fragment>
                <Spring
                  from={{
                    transform: "scale(0)",
                    opacity: 1,
                  }}
                  to={{
                    transform: "scale(1)",
                    opacity: 1,
                  }}
                  config={{ delay: 350, easing: "ease-in" }}
                >
                  {(styleProps) => (
                    <React.Fragment>
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 18,
                          height: "auto",
                          top: -25,
                          left: 15,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 6,
                          height: "auto",
                          top: -5,
                          left: 0,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 8,
                          height: "auto",
                          top: -35,
                          left: 35,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 16,
                          height: "auto",
                          top: -25,
                          left: 45,
                          ...styleProps,
                        }}
                      />
                    </React.Fragment>
                  )}
                </Spring>
                <Spring
                  from={{
                    transform: "scale(0) rotateY(180deg)",
                    opacity: 1,
                  }}
                  to={{
                    transform: "scale(1) rotateY(180deg)",
                    opacity: 1,
                  }}
                  config={{ delay: 350, easing: "ease-in" }}
                >
                  {(styleProps) => (
                    <React.Fragment>
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 18,
                          height: "auto",
                          top: -25,
                          right: 15,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 6,
                          height: "auto",
                          top: -5,
                          right: 0,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 8,
                          height: "auto",
                          top: -35,
                          right: 35,
                          ...styleProps,
                        }}
                      />
                      <img
                        alt="left-stars"
                        src={SingleStar}
                        style={{
                          position: "absolute",
                          width: 16,
                          height: "auto",
                          top: -25,
                          right: 45,
                          ...styleProps,
                        }}
                      />
                    </React.Fragment>
                  )}
                </Spring>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <div
          style={{
            backgroundColor: WHITE,
            width: 28,
            height: 28,
            borderRadius: 28,
            color: {
              SPEECH_RECOGNITION_SEQUENCE: "#fdbf00",
              CHECKPOINT_SEQUENCE: "#fdbf00",
            }[type],
            fontSize: 11,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: -22,
            marginLeft: 25,
            zIndex: 2,
          }}
        >
          {count === "MAX" ? "+9" : count}X
        </div>
        <img
          alt="icon"
          src={
            {
              SPEECH_RECOGNITION_SEQUENCE: RecordIcon,
              CHECKPOINT_SEQUENCE: TargetIcon,
            }[type]
          }
          style={{
            position: "absolute",
            width: "auto",
            height: 60,
            top: type === "SPEECH_RECOGNITION_SEQUENCE" ? -50 : -42,
          }}
        />
        <div
          style={{
            backgroundColor: {
              SPEECH_RECOGNITION_SEQUENCE: "#fdbf00",
              CHECKPOINT_SEQUENCE: "#fdbf00",
            }[type],
            minWidth: 260,
            width: "fit-content",
            borderRadius: 25,
            padding: "15px 30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: {
              SPEECH_RECOGNITION_SEQUENCE: "0 0 50px 0 rgba(253, 191, 0, 0.8)",
              CHECKPOINT_SEQUENCE: "0 0 50px 0 rgba(253, 191, 0, 0.8)",
            }[type],
            zIndex: 2,
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: 14,
              color: WHITE,
            }}
          >
            {sample(get(translations, `unitExecutionNotification.${this.props.value}`, []))}
          </span>
        </div>
      </div>
    );
  }
}
