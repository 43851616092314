import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../Async";
import NotificationList from "./NotificationList";
import { LOAD_NOTIFICATIONS_FLOW } from "../../consts";

class NotificationListContainer extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
  };

  render() {
    return (
      <Flow
        key={`notification-page-${this.props.page}`}
        autoStart={true}
        id={LOAD_NOTIFICATIONS_FLOW}
        params={{
          page: this.props.page,
          size: 15,
        }}
      >
        {({ isPending }) => (
          <Async fetching={isPending && this.props.page === 1} size="sm" color="dark" margin="10px 0">
            <NotificationList
              notifications={this.props.notifications.filter(
                (n) =>
                  n.type !== "CERTIFICATION_TEST_SCHEDULED" ||
                  (n.type === "CERTIFICATION_TEST_SCHEDULED" && !!n.certificationTest)
              )}
              configurations={this.props.configurations}
            />
          </Async>
        )}
      </Flow>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notification.data,
  configurations: state.configurations,
});

export default connect(mapStateToProps)(NotificationListContainer);
