import React from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import AnimatedRoute from "./core/AnimatedRoute";
import PracticeTestSceneContainer from "./screens/placement-test/practice/PracticeTestSceneContainer";
import PracticeTestScoreSceneContainer from "./screens/placement-test/practice-score/PracticeTestScoreSceneContainer";
import PlacementTestSceneContainer from "./screens/placement-test/placement/PlacementTestSceneContainer";
import PlacementTestScoreSceneContainer from "./screens/placement-test/placement-score/PlacementTestScoreSceneContainer";
import TestIntroductionSceneContainer from "./screens/placement-test/introduction/TestIntroductionSceneContainer";
import NotFoundScene from "./core/NotFoundScene";
import HeaderContainer from "./core/HeaderContainer";
import CourseThemeProvider from "./core/CourseThemeProvider";
import { LOAD_STUDENT_TASTING_FLOW } from "./consts";
import { useFlow } from "student-front-commons/src/hooks";
import queryString from "query-string";
import TastingUnitExecutionSceneContainer from "./screens/unit-execution/tasting/TastingUnitExecutionSceneContainer";
import TastingResultSceneContainer from "./screens/unit-result/tasting/TastingResultSceneContainer";
import MasteryTestTastingExecutionSceneContainer from "./screens/mastery-test-execution/tasting/MasteryTestTastingExecutionSceneContainer";
import Async from "./core/Async";
import CertificationTestTastingExecutionSceneContainer from "./screens/certification-test/tasting/CertificationTestTastingExecutionSceneContainer";
import PlacementTastingSceneContainer from "./screens/placement-test/tasting/PlacementTastingSceneContainer";
import TastingContactModalContainer from "./screens/unit-execution/tasting/TastingContactModalContainer";

export default function TastingRoutesContainer() {
  const location = useLocation();
  const match = useRouteMatch();
  const [isLoadingStudent] = useFlow(LOAD_STUDENT_TASTING_FLOW, {
    autoStart: true,
    params: queryString.parse(location.search),
  });

  return (
    <>
      <TastingContactModalContainer />
      <Async fetching={isLoadingStudent}>
        <CourseThemeProvider>
          <HeaderContainer />
          <Switch>
            <Route path={`${match.path}/course/:idCourse`}>
              {({ match }) => (
                <CourseThemeProvider>
                  <AnimatedRoute
                    exact
                    key="tasting-unit-execution"
                    component={TastingUnitExecutionSceneContainer}
                    path={`${match.path}/modules/:idModule/units/:idUnit/exercise`}
                  />
                  <AnimatedRoute
                    exact
                    key="unit-result"
                    path={`${match.path}/modules/:idModule/units/:idUnit/exercise/:idExecution/result`}
                    component={TastingResultSceneContainer}
                  />
                  <AnimatedRoute
                    exact
                    key="mastery-test"
                    component={MasteryTestTastingExecutionSceneContainer}
                    path={`${match.path}/modules/:idModule/mastery-test/:idMasteryTest/execution`}
                  />
                  <AnimatedRoute
                    exact
                    key="mastery-test-result"
                    path={`${match.path}/modules/:idModule/mastery-test/:idMasteryTest/execution/:idExecution/result`}
                    component="div"
                  />
                  <AnimatedRoute
                    exact
                    key="certification-test-execution"
                    path={`${match.path}/certification-test/:idCertificationTest/abilities/:ability/execution`}
                    component={CertificationTestTastingExecutionSceneContainer}
                  />
                  <AnimatedRoute
                    exact
                    key="certification-test-result"
                    path={`${match.path}/certification-test/:idCertificationTest/result`}
                    component="div"
                  />
                </CourseThemeProvider>
              )}
            </Route>
            <AnimatedRoute
              exact
              key="placement-tasting"
              path={`${match.path}/placement`}
              component={PlacementTastingSceneContainer}
            />
            <AnimatedRoute
              exact
              key="practicetestintroduction"
              path="/practice-test-introduction"
              component={TestIntroductionSceneContainer}
            />
            <AnimatedRoute
              exact
              key="practicetest"
              path="/practice-test"
              component={PracticeTestSceneContainer}
              prompt={(nextLocation) =>
                (nextLocation.pathname !== "/practice-test-score" &&
                  "You will lose your progress. Are you sure want to leave?") ||
                true
              }
            />
            <AnimatedRoute
              exact
              key="practicetestscore"
              path="/practice-test-score"
              component={PracticeTestScoreSceneContainer}
            />
            <AnimatedRoute exact key="placementtest" path="/placement-test" component={PlacementTestSceneContainer} />
            <AnimatedRoute
              exact
              key="placementtestscore"
              path="/placement-test-score"
              component={PlacementTestScoreSceneContainer}
            />
            <Route component={NotFoundScene} />
          </Switch>
        </CourseThemeProvider>
      </Async>
    </>
  );
}
