import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import values from "lodash/values";
import get from "lodash/get";
import keys from "lodash/keys";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import DragOptionList from "../DragOptionList";
import DropOption from "../DropOption";
import DragAnswerItem from "../DragAnswerItem";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { isNil } from "lodash";
import FlexRow from "../FlexRow";
import { FEEDBACK_CORRECT } from "../../consts/color";
import ValidationWrapper from "./ValidationWrapper";

const DragAndDropValidationRender = (props) => {
  return (
    <ValidationWrapper>
      {props.exerciseText.split(" ").map((slice, index) => {
        const foundAnswer = props.itemLinkedAnswers.find((answer) => answer.index === index);
        const noAnswer = !props.itemAnswers.find((answer) => answer.index === index);
        const answer = get(props.chunks, index.toString(), "");
        return (
          <FlexRow
            key={props.itemId}
            alignItems="center"
            margin={answer?.index !== foundAnswer?.index ? 3 : 0}
            marginLeft={index === 0 ? 0 : 3}
          >
            <QuestionLabel
              cursor="normal"
              fontWeight={answer?.index !== foundAnswer?.index ? 700 : 400}
              color={answer?.index !== foundAnswer?.index ? FEEDBACK_CORRECT : "#607d8b"}
              textDecoration={answer?.index !== foundAnswer?.index ? "underline" : "none"}
            >
              {noAnswer ? slice : foundAnswer?.text}
            </QuestionLabel>
          </FlexRow>
        );
      })}
    </ValidationWrapper>
  );
};

const DragAndDropExerciseRender = (props) => {
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemPostPhrase = useSelector(getItemExecutionPropById(props.itemId, "item.postPhrase"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const itemAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const itemType = useSelector(getItemExecutionPropById(props.itemId, "item.type.key"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const [isPending] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const dispatch = useDispatch();
  const [chunks, setChunks] = useState({});
  const [nextIndex, setNextIndex] = useState(null);

  const exerciseText = itemType === "VOCABULARY_ACADEMIC_SPEECHLESS" ? itemPostPhrase : itemText;

  useEffect(() => {
    const answerText = exerciseText
      .split(" ")
      .reduce((acc, slice, sliceIndex) => {
        const dropAnswer = itemLinkedAnswers.find((answer) => answer.index === sliceIndex);
        if (dropAnswer) {
          const chunk = get(chunks, sliceIndex.toString(), null);
          return acc
            .concat(
              get(chunk, "text", "-")
                .replace(/[!.?,]$/, "")
                .concat(/[!.?,]$/.test(dropAnswer.text) ? dropAnswer.text.substr(dropAnswer.text.length - 1) : "")
            )
            .concat(" ");
        }
        if (!itemAnswers.some((answer) => answer.index === sliceIndex)) {
          return acc.concat(slice).concat(" ");
        }
        return acc;
      }, "")
      .trim();

    dispatch(addItemExecutionAnswer(props.itemId, { answer: Object.keys(chunks).length ? answerText : null }));
    setNextAvailableIndex(chunks);

    if (
      props.isAutoConfirm &&
      itemLinkedAnswers.filter((answer) => answer.index !== undefined).length === Object.keys(chunks || {}).length
    ) {
      setTimeout(() => {
        props.onConfirm();
      }, 0);
    }
  }, [chunks]);

  useEffect(() => {
    setChunks({});
    setNextAvailableIndex();
  }, [props.itemId]);

  const handleAddChunk = useCallback(
    (answer, index) => {
      setChunks(addChunk(chunks, answer, index));
    },
    [chunks]
  );

  const handleUpdateChunk = useCallback(
    (answer, indexToPut, indexToRemove) => {
      let newChunks = removeChunk(chunks, indexToRemove);
      newChunks = addChunk(newChunks, answer, indexToPut);
      setChunks(newChunks);
    },
    [chunks]
  );

  const handleRemoveChunk = useCallback(
    (answer, index) => {
      setChunks(removeChunk(chunks, index));
    },
    [chunks]
  );

  const addChunk = (chunks, answer, index) => {
    return {
      ...chunks,
      [index.toString()]: answer,
    };
  };

  const removeChunk = (chunks, index) => {
    return {
      ...Object.keys(chunks)
        .filter((key) => key !== index.toString())
        .reduce((acc, key) => ({ ...acc, [key]: get(chunks, key, null) }), {}),
    };
  };

  const setNextAvailableIndex = useCallback(
    (chunks) => {
      const possibleIndexes = exerciseText
        .split(" ")
        .map((_, index) => !!itemLinkedAnswers.find((answer) => answer.index === index));
      let nextIndex = possibleIndexes.findIndex((possibleIndex) => possibleIndex === true);

      if (!isNil(chunks)) {
        nextIndex = null;
        if (possibleIndexes.filter((possibleIndex) => possibleIndex === true).length > keys(chunks).length) {
          nextIndex = possibleIndexes.findIndex(
            (possibleIndex, currentIndex) => possibleIndex === true && !(currentIndex.toString() in chunks)
          );
        }
      }

      setNextIndex(nextIndex);
    },
    [itemLinkedAnswers, exerciseText]
  );

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox
        className="selectTextExerciseUnscrambleDragAndDrop"
        isWrong={showCorrectOption}
        showFeedback={isExecutionValidated}
      >
        {exerciseText.split(" ").map((slice, index) => {
          const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
          if (foundAnswer) {
            return (
              <React.Fragment key={`drop-option-${slice}-${foundAnswer.id}`}>
                <DropOption disabled={isDisabled || isPending} index={index} item={props.itemId}>
                  {(showCorrectOption || !!chunks[index]) && (
                    <DragAnswerItem
                      key={`drag-answer-${slice}`}
                      answer={chunks[index] || false}
                      isWrong={
                        showCorrectOption
                          ? chunks[index]?.index !== foundAnswer?.index && chunks[index]?.text !== foundAnswer?.text
                          : false
                      }
                      index={index}
                      item={props.itemId}
                      disabled={isDisabled || isPending}
                      onDrop={handleUpdateChunk}
                      onRemove={handleRemoveChunk}
                    />
                  )}
                </DropOption>
                {/[!.?,]$/.test(foundAnswer.text) && (
                  <React.Fragment>
                    <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} /> &nbsp;
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }
          return (
            !itemAnswers.find((answer) => answer.index === index) && (
              <div
                key={`${props.itemId}-text-${index}`}
                style={{
                  display: "flex",
                }}
              >
                <QuestionLabel text={slice} />
                &nbsp;
              </div>
            )
          );
        })}
      </HighlightableItemBox>
      <Separator size="md" />
      {showCorrectOption ? (
        <DragAndDropValidationRender
          itemLinkedAnswers={itemLinkedAnswers}
          itemAnswers={itemAnswers}
          exerciseText={exerciseText}
          chunks={chunks}
        />
      ) : (
        <DragOptionList
          answers={itemLinkedAnswers.map((answer) => ({
            ...answer,
            selected: values(chunks).some((a) => a.id === answer.id),
          }))}
          item={props.itemId}
          isDisabled={isDisabled || isPending}
          onDrop={handleAddChunk}
          nextIndex={nextIndex}
        />
      )}
    </ExerciseItemPanel>
  );
};

DragAndDropExerciseItem.propTypes = {
  onConfirm: PropTypes.func,
  isAutoConfirm: PropTypes.bool,
};

DragAndDropExerciseItem.defaultProps = {
  onConfirm: null,
  isAutoConfirm: false,
};

export default function DragAndDropExerciseItem(props) {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <DragAndDropExerciseRender key={id} itemId={id} {...props} />);
}
