import styled from "styled-components";
import PropTypes from "prop-types";

const MasteryTestListItemStatusLabel = styled.span`
  color: ${(props) => props.color};
  font-weight: 700 !important;
  font-size: 12px !important;
`;

MasteryTestListItemStatusLabel.propTypes = {
  color: PropTypes.string.isRequired,
};

export default MasteryTestListItemStatusLabel;
