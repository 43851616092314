import React, { useCallback, useState } from "react";
import { without } from "lodash";
import QuestionLabel from "../QuestionLabel";
import PopoverButton from "../PopoverButton";
import TranslateButton from "./TranslateButton";
import MediaQuery from "react-responsive";
import CircularLoading from "../CircularLoading";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { translateItem } from "student-front-commons/src/actions/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";
import useTranslation from "student-front-commons/src/hooks/useTranslation";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { VALIDATION_STEP_ITEMS } from "student-front-commons/src/consts";

const typesToEnableAfterRepeat = ["SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_AUDIO", "DICTATION"];

function TranslateButtonContainer() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const itemId = useSelector(getCurrentItemExecutionProp("item.id"));
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const readCount = useSelector(getCurrentItemExecutionProp("readCount"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const [isTranslating, translation] = useTranslation(itemId, profile.locale);

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      dispatch(translateItem(itemId));
    }

    setIsOpen(!isOpen);
  }, [isOpen, itemId]);

  const isTranslateDisabled = () => {
    if (
      isExecutionValidated &&
      [...without(VALIDATION_STEP_ITEMS, "UNSCRAMBLE_TEXT", "GRAMMAR_CHECK"), "SPEECH_PRACTICE"].some(
        (type) => type === itemType
      )
    ) {
      return isRecording || isSubmittingRecord;
    }
    if (["PRESENTATION", "SPEECH_PRACTICE", "VIDEO_SHORT", "SINGLE_CHOICE_IMAGE"].some((type) => type === itemType)) {
      return isDisabled || isRecording || !readCount || isSubmittingRecord;
    }
    if (itemType === "VOCABULARY_ACADEMIC") {
      return isDisabled || isRecording || readCount < 1 || isSubmittingRecord;
    }
    if (typesToEnableAfterRepeat.some((type) => type === itemType)) {
      return isDisabled || !repeatCount;
    }
    if (itemType === "PRONUNCIATION") {
      return isDisabled || isRecording || !repeatCount;
    }
    return true;
  };

  return (
    <MediaQuery maxWidth={626}>
      {(matches) => (
        <PopoverButton
          isOpen={isOpen}
          onClose={handleToggle}
          position={matches ? "right-corner" : "bottom"}
          content={
            isTranslating ? (
              <CircularLoading />
            ) : (
              translation &&
              translation
                .split("\n")
                .filter((text) => text.length)
                .map((text) => (
                  <QuestionLabel
                    key={text}
                    fontSize={16}
                    lineHeight="24px"
                    textAlign="justify"
                    asParagraph={translation.split("\n").length > 1}
                  >
                    {text}
                  </QuestionLabel>
                ))
            )
          }
        >
          <TranslateButton
            isDisabled={(currentInstructionStep && currentInstructionStep !== "translate") || isTranslateDisabled()}
            onClick={handleToggle}
          />
        </PopoverButton>
      )}
    </MediaQuery>
  );
}

export default TranslateButtonContainer;
