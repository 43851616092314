import React from "react";
import FlexColumn from "./FlexColumn";
import Icon from "./Icon";

const Completed = () => (
  <FlexColumn
    width={24}
    height={24}
    borderRadius={24}
    boxSizing="border-box"
    backgroundColor="#e8f7f6"
    justifyContent="center"
    alignItems="center"
    borderWidth={1}
    borderStyle="solid"
    borderColor="#e8f7f6"
  >
    <Icon icon="check" size="xs" color="#0FB1A2" />
  </FlexColumn>
);

export default Completed;
