import orderBy from "lodash/orderBy";
import { all, call, put, select, spawn, takeLatest, take } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { startTastingExecution } from "student-front-commons/src/services/tastingService";
import { addImageDataToItems, addSoundToItems } from "../util";
import { GET_NEXT_UNIT_ITEM_EXECUTION_FLOW, START_TASTING_UNIT_EXECUTION_FLOW } from "../consts";
import browserHistory from "../browserHistory";
import { startExecution } from "student-front-commons/src/actions/execution";
import apiRequest from "student-front-commons/src/core/request";
import LogRocket from "logrocket";

export default function* () {
  yield takeLatest(getFlowStart(START_TASTING_UNIT_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_TASTING_UNIT_EXECUTION_FLOW));
    try {
      const result = yield call(startTastingExecution, {
        unit: flow.params.unit,
      });

      yield put(mergeEntities(result.unit.entities));
      const orderedItems = orderBy(result.items, ["order"], ["asc"]);

      yield all([...addImageDataToItems(orderedItems.slice(0, 3)), ...addSoundToItems(orderedItems.slice(0, 3))]);

      yield put(
        startExecution({
          ...result.unitExecution,
          items: result.items,
          module: flow.params.module,
          unit: flow.params.unit,
        })
      );

      yield spawn(function* () {
        yield all([...addImageDataToItems(orderedItems.slice(3)), ...addSoundToItems(orderedItems.slice(3))]);
      });

      // send progress as 0 for execution start
      yield spawn(function* () {
        yield call(apiRequest, {
          method: "put",
          url: `modules/tasting/units/${flow.params.unit}/update-lead`,
          data: {
            progress: 0,
          },
        });

        const sessionUrl = yield new Promise((resolve) => LogRocket.getSessionURL(resolve));
        yield call(apiRequest, {
          method: "put",
          url: `modules/tasting/units/${flow.params.unit}/update-lead`,
          data: {
            linkLogRocket: sessionUrl,
          },
        });
      });
    } catch (error) {
      yield call(browserHistory.replace, "/login");
    } finally {
      yield put(endFlow(START_TASTING_UNIT_EXECUTION_FLOW));

      yield put(startFlow("SHOW_START_TASTING_MODAL"));
      yield take(getFlowEnd("SHOW_START_TASTING_MODAL"));
      yield put(startFlow(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW));
    }
  });
}
