import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import ModuleScoreItem from "./ModuleScoreItem";
import CourseThemeContext from "../../core/CourseThemeContext";
import { GREEN_3 } from "../../consts/color";

const getMaxValue = (module) => {
  return ["readingPoints", "writingPoints", "speakingPoints", "listeningPoints"].reduce(
    (acc, path) => (module[path] > acc ? module[path] : acc),
    0
  );
};

const ModuleScoreSummary = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div className="stepModuleScoreSummary">
        <ul
          style={{
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <Separator size="xs" />
          <ModuleScoreItem
            maxValue={getMaxValue(props.module)}
            totalValue={props.module.listeningPoints}
            value={props.module.conqueredListeningPoints}
            color={props.module.conqueredListeningPoints >= props.module.listeningPoints ? GREEN_3 : primary}
            title="Listening"
            type="listening"
          />
          <Separator size="xs" />
          <ModuleScoreItem
            maxValue={getMaxValue(props.module)}
            totalValue={props.module.speakingPoints}
            value={props.module.conqueredSpeakingPoints}
            color={props.module.conqueredSpeakingPoints >= props.module.speakingPoints ? GREEN_3 : primary}
            title="Speaking"
            type="speaking"
          />
          <Separator size="xs" />
          <ModuleScoreItem
            maxValue={getMaxValue(props.module)}
            totalValue={props.module.readingPoints}
            value={props.module.conqueredReadingPoints}
            color={props.module.conqueredReadingPoints >= props.module.readingPoints ? GREEN_3 : primary}
            title="Reading"
            type="reading"
          />
          <Separator size="xs" />
          <ModuleScoreItem
            maxValue={getMaxValue(props.module)}
            totalValue={props.module.writingPoints}
            value={props.module.conqueredWritingPoints}
            color={props.module.conqueredWritingPoints >= props.module.writingPoints ? GREEN_3 : primary}
            title="Writing"
            type="writing"
          />
        </ul>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

ModuleScoreSummary.propTypes = {
  module: PropTypes.shape({
    readingPoints: PropTypes.number.isRequired,
    writingPoints: PropTypes.number.isRequired,
    speakingPoints: PropTypes.number.isRequired,
    listeningPoints: PropTypes.number.isRequired,
    conqueredWritingPoints: PropTypes.number.isRequired,
    conqueredReadingPoints: PropTypes.number.isRequired,
    conqueredSpeakingPoints: PropTypes.number.isRequired,
    conqueredListeningPoints: PropTypes.number.isRequired,
  }).isRequired,
};

export default ModuleScoreSummary;
