import React, { Component } from "react";
import PropTypes from "prop-types";
import QuestionLabel from "../QuestionLabel";
import Separator from "../Separator";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";

export default class SingleChoiceTextItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      text: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    playingId: PropTypes.string,
    shouldShowCorrectOption: PropTypes.bool,
    abilityType: PropTypes.oneOf(["BOTH", "READING"]),
    size: PropTypes.oneOf(["sm", "md"]).isRequired,
  };

  static defaultProps = {
    playingId: "",
    abilityType: "BOTH",
    shouldShowCorrectOption: false,
  };

  render() {
    return (
      <ExerciseItemPanel>
        {this.props.abilityType === "READING" && (
          <div
            style={{
              paddingLeft: 20,
            }}
          >
            <Separator size="md" />
            <QuestionLabel text={this.props.item.text} />
            <Separator size="md" />
          </div>
        )}
        {this.props.item.linkedAnswers.map((answer) => (
          <div key={`answer-${answer.id}`}>
            <div className="selectExerciseSingleChoiceText">
              <ExerciseButton
                disabled={this.props.isDisabled}
                highlight={this.props.playingId === answer.id || (this.props.shouldShowCorrectOption && answer.correct)}
                onClick={() => {
                  this.props.onChange("answer", answer);
                  this.props.onConfirm();
                }}
              >
                <QuestionLabel
                  text={answer.text}
                  textAlign="left"
                  fontSize={{ sm: "16px", md: "22px" }[this.props.size]}
                />
              </ExerciseButton>
            </div>
            <Separator size="xs" />
          </div>
        ))}
      </ExerciseItemPanel>
    );
  }
}
