import React from "react";
import uniqBy from "lodash/uniqBy";
import Entity from "student-front-commons/src/components/Entity";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPlacementTestLevels } from "student-front-commons/src/services/placementTestLevelService";
import Async from "../../core/Async";
import CourseList from "./CourseList";

const CourseListContainer = () => (
  <FetchData service={getPlacementTestLevels}>
    {({ isFetching, data }) => (
      <Entity name="course">
        {({ getAll: getAllCourses }) => (
          <Async fetching={isFetching} color="dark" size="sm">
            <CourseList
              courses={uniqBy(
                (data || [])
                  .filter((placementLevel) => placementLevel.level >= 1)
                  .filter((placementLevel) => placementLevel.course.name.indexOf("+") < 0)
                  .sort((first, second) => {
                    if (first.level < second.level) {
                      return -1;
                    }
                    if (first.level > second.level) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((placementLevel) => placementLevel.course),
                "name"
              )}
              currentCourse={getAllCourses().find((course) => !course.distributor)}
            />
          </Async>
        )}
      </Entity>
    )}
  </FetchData>
);

export default CourseListContainer;
