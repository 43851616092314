import React from "react";
import PropTypes from "prop-types";
import { css, keyframes } from "styled-components";
import FlexRow from "../FlexRow";
import MediaQuery from "react-responsive";

const borderHighlight = keyframes`
  0%{
    box-shadow: inset 0px 0px 8px 0px rgba(124, 181, 176, .8);
  }
  50%{
    box-shadow: inset 0px 0px 8px 2px rgba(124, 181, 176, 1);
  }
  100%{
    box-shadow: inset 0px 0px 8px 0px rgba(124, 181, 176, .8);
  }
`;

const animation = () => css`
  ${borderHighlight} 1.5s linear infinite;
`;

const HighlightableItemBox = ({ children, highlight, className, ...rest }) => (
  <MediaQuery maxWidth={368}>
    {(matches) => (
      <div
        className={className}
        style={{
          boxShadow: "0px 3px 3px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          padding: matches ? 5 : 20,
          borderRadius: 3,
          position: "relative",
          animation: highlight && `${animation}`,
        }}
      >
        <FlexRow alignItems="center" justifyContent="center" {...rest}>
          {children}
        </FlexRow>
      </div>
    )}
  </MediaQuery>
);

HighlightableItemBox.propTypes = {
  children: PropTypes.node.isRequired,
  highlight: PropTypes.bool,
};

HighlightableItemBox.defaultProps = {
  highlight: false,
};

export default HighlightableItemBox;
