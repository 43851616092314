import React from "react";
import moment from "moment/moment";
import { withRouter } from "react-router-dom";
import Column from "../../core/Column";
import Panel from "../../core/Panel";
import Row from "../../core/Row";
import Title from "../../core/Title";
import Separator from "../../core/Separator";
import CustomRankingListContainer from "./CustomRankingListContainer";
import TranslatedText from "../../core/TranslatedText";
import queryString from "query-string";

const YearRanking = (props) => (
  <Panel>
    <Row>
      <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
        <TranslatedText translateKey="regional" as={Title} />
        <Separator size="sm" />
        <CustomRankingListContainer
          key={`regional-${queryString.parse(props.location.search).year}`}
          level="regional"
          from={moment()
            .year(queryString.parse(props.location.search).year || moment().year())
            .startOf("year")
            .toDate()}
          to={moment()
            .year(queryString.parse(props.location.search).year || moment().year())
            .endOf("year")
            .toDate()}
        />
      </Column>
      <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
        <TranslatedText translateKey="national" as={Title} />
        <Separator size="sm" />
        <CustomRankingListContainer
          key={`national-${queryString.parse(props.location.search).year}`}
          level="national"
          from={moment()
            .year(queryString.parse(props.location.search).year || moment().year())
            .startOf("year")
            .toDate()}
          to={moment()
            .year(queryString.parse(props.location.search).year || moment().year())
            .endOf("year")
            .toDate()}
        />
      </Column>
    </Row>
  </Panel>
);

export default withRouter(YearRanking);
