import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { useEntity } from "student-front-commons/src/hooks";
import MaterialTheme from "../MaterialTheme";
import HtmlForm from "../HtmlForm";
import TextInput from "../form/TextInput";
import withTranslation from "../withTranslation";
import FlexRow from "../FlexRow";
import Separator from "../Separator";
import ButtonV2 from "../ButtonV2";
import TranslatedText from "../TranslatedText";
import CircularLoading from "../CircularLoading";
import IconButton from "../IconButton";
import Lottie from "react-lottie";
import MessageSentAnimation from "../../lotties/message-sent.json";

const Container = Styled.div`
  width: 400px;
  height: auto;
  max-height: 95vh;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 10%) 0px 11px 30px 0px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  position: fixed;
  padding: 15px;
  cursor: auto;
  bottom: 10px;
  right: ${(props) => (props.isFormOnRight ? "10px" : "calc(100% - 410px)")};
  z-index: ${Number.MAX_SAFE_INTEGER};
  transition: right 0.7s ease;
`;

const UserErrorReportForm = ({
  getValue,
  setValue,
  getError,
  submit,
  isSubmitted,
  onDiscard,
  isSubmitting,
  isLoading,
  isSuccessfulReported,
  getTranslation,
  apiError,
}) => {
  const [isEmailFound, setIsEmailFound] = useState(false);
  const [isFormOnRight, setIsFormOnRight] = useState(true);

  const profile = useEntity("profile", sessionStorage.getItem("id"));

  useEffect(() => {
    if (profile) {
      setValue("email", profile.email);
      setIsEmailFound(true);
    }
  }, [profile]);

  return (
    <Container isFormOnRight={isFormOnRight}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          borderBottom: "1px solid rgba(33, 43, 55, 0.1)",
        }}
      >
        <TranslatedText
          translateKey="userErrorReport.title"
          as="h1"
          style={{
            color: "#4e4e4e",
            margin: 0,
            fontSize: "16px",
            fontWeight: 600,
          }}
        />
        <IconButton
          icon={isFormOnRight ? "transfer-left" : "transfer-right"}
          color="#4e4e4e"
          size="sm"
          onClick={() => setIsFormOnRight(!isFormOnRight)}
        />
      </div>
      {isLoading && (
        <>
          <Separator />
          <CircularLoading size="sm" rgb={{ r: 220, g: 220, b: 220 }} />
          <Separator />
        </>
      )}
      {!isLoading && !isSuccessfulReported && (
        <MaterialTheme>
          <HtmlForm onSubmit={submit}>
            <Separator />
            <TextInput
              disabled={isSubmitting || isEmailFound}
              onChange={(value) => setValue("email", value)}
              value={getValue("email")}
              error={isSubmitted() && getError("email")}
              label={getTranslation("userErrorReport.labels.email")}
              placeholder={getTranslation("userErrorReport.placeholders.email")}
              fullWidth
              autoComplete={false}
            />
            <Separator />
            <TextInput
              disabled={isSubmitting}
              onChange={(value) => setValue("subject", value)}
              value={getValue("subject")}
              error={isSubmitted() && getError("subject")}
              label={getTranslation("userErrorReport.labels.subject")}
              placeholder={getTranslation("userErrorReport.placeholders.subject")}
              fullWidth
              autoComplete={false}
            />
            <Separator />
            <TextInput
              disabled={isSubmitting}
              onChange={(value) => setValue("content", value)}
              value={getValue("content")}
              error={isSubmitted() && getError("content")}
              label={getTranslation("userErrorReport.labels.content")}
              placeholder={getTranslation("userErrorReport.placeholders.content")}
              fullWidth
              autoComplete={false}
              multiLine
            />
            <Separator />
            {!!apiError && (
              <div
                style={{
                  padding: 10,
                  borderRadius: 6,
                  backgroundColor: "#f8d7da",
                  borderColor: "#f5c2c7",
                }}
              >
                <TranslatedText
                  as="p"
                  translateKey={
                    apiError === "student_not_found"
                      ? "userErrorReport.errorMessages.studentNotFound"
                      : "userErrorReport.errorMessages.apiError"
                  }
                  style={{
                    color: "#842029",
                    fontSize: 12,
                    margin: 0,
                    padding: 0,
                  }}
                />
              </div>
            )}
            <Separator />
            <FlexRow alignItems="center" justifyContent="space-evenly">
              <ButtonV2
                onClick={onDiscard}
                intent="secondary"
                labelKey="userErrorReport.buttonsText.discardChanges"
                isDisabled={isSubmitting}
              />
              <FlexRow alignItems="center">
                <ButtonV2
                  intent="wl-primary"
                  labelKey="userErrorReport.buttonsText.submit"
                  isDisabled={isSubmitting}
                  onClick={submit}
                />
                {isSubmitting && (
                  <>
                    <Separator size="xxs" />
                    <CircularLoading size="xxs" rgb={{ r: 220, g: 220, b: 220 }} />
                  </>
                )}
              </FlexRow>
            </FlexRow>
          </HtmlForm>
        </MaterialTheme>
      )}
      {!isLoading && isSuccessfulReported && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Lottie
            width="auto"
            height={200}
            options={{
              animationData: MessageSentAnimation,
              loop: false,
              autoplay: true,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
          />
        </div>
      )}
    </Container>
  );
};

UserErrorReportForm.propTypes = {
  getTranslation: PropTypes.func.isRequired,
};

export default withTranslation(UserErrorReportForm);
