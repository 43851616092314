import React from "react";
import Modal from "../../../core/Modal";
import TastingContactFormContainer from "./TastingContactFormContainer";
import FlexColumn from "../../../core/FlexColumn";
import QuestionLabel from "../../../core/QuestionLabel";
import TranslatedText from "../../../core/TranslatedText";
import Paragraph from "../../../core/Paragraph";
import Separator from "../../../core/Separator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../../consts/color";

const TastingContactModal = () => (
  <Modal
    style={{
      padding: 0,
      width: "90%",
    }}
  >
    <WhitelabelContext.Consumer>
      {({ primaryColor, title }) => (
        <FlexColumn
          backgroundColor={primaryColor}
          alignItems="center"
          boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
          padding="25px 5px"
        >
          <TranslatedText
            as={QuestionLabel}
            translateKey="tastingContact.title"
            fontSize={24}
            color={WHITE}
            values={{ title }}
          />
          <Separator size="xs" />
          <TranslatedText
            as={Paragraph}
            translateKey="tastingContact.description"
            style={{
              fontSize: 14,
              color: WHITE,
              opacity: 0.8,
            }}
          />
        </FlexColumn>
      )}
    </WhitelabelContext.Consumer>
    <div
      style={{
        padding: "6% 5%",
        overflowY: "scroll",
        maxHeight: window.screen.availHeight * 0.8,
        textAlign: "left",
      }}
    >
      <TastingContactFormContainer />
    </div>
  </Modal>
);

export default TastingContactModal;
