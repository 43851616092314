import React from "react";
import BonusTestContent from "./BonusTestContent";
import BonusTestItemExecutionFormContainer from "./BonusTestItemExecutionFormContainer";
import OldErrorModalContainer from "../../error/OldErrorModalContainer";
import CloseBonusTestExecutionContainer from "./CloseBonusTestExecutionContainer";
import BonusTestSubHeader from "./BonusTestSubHeader";
import Separator from "../../core/Separator";
import BonusTestExecutionProgressBar from "./BonusTestExecutionProgressBar";

function BonusTestScene() {
  return (
    <div>
      <CloseBonusTestExecutionContainer />
      <BonusTestSubHeader />
      <BonusTestContent>
        <BonusTestExecutionProgressBar />
        <Separator />
        <BonusTestItemExecutionFormContainer />
      </BonusTestContent>
      <OldErrorModalContainer />
    </div>
  );
}

export default BonusTestScene;
