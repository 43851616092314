import React from "react";
import { useParams } from "react-router-dom";
import UnitResultScene from "./UnitResultScene";
import { END_UNIT_EXECUTION_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import CircularLoading from "../../core/CircularLoading";

function UnitResultSceneContainer() {
  const params = useParams();
  const [isPending] = useFlow(END_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      course: params.idCourse,
      module: params.idModule,
      unit: params.idUnit,
      execution: params.idExecution,
    },
  });

  if (isPending) {
    return <CircularLoading size="md" />;
  }

  return <UnitResultScene />;
}

export default UnitResultSceneContainer;
