import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import Flow from "student-front-commons/src/components/Flow";
import CourseSummaryNextStep from "./CourseSummaryNextStep";
import { LOAD_STUDENT_COURSE_FLOW } from "../../consts";

const CourseSummaryNextStepContainer = () => (
  <Flow autoStart={false} id={LOAD_STUDENT_COURSE_FLOW}>
    {({ handleStart, isPending }) => (
      <Entity name="profile">
        {({ getById: getProfileById }) => {
          const profile = getProfileById(sessionStorage.getItem("id"));

          return (
            <CourseSummaryNextStep
              onNextCourse={handleStart}
              isLoading={isPending}
              course={profile.activeCourses.find((c) => !c.distributor)}
              certificationTest={profile?.certificationTest}
            />
          );
        }}
      </Entity>
    )}
  </Flow>
);

export default CourseSummaryNextStepContainer;
