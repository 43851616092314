import React, { useMemo, useState } from "react";
import FlexColumn from "../../core/FlexColumn";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import MasteryTestResultPerformanceItem from "./MasteryTestResultPerformanceItem";
import { useEntity, useService } from "student-front-commons/src/hooks";
import { getMasteryTestResultPerformance } from "student-front-commons/src/services/masteryTestExecutionService";
import { useParams } from "react-router-dom";
import Async from "../../core/Async";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

const MasteryTestResultPerformance = () => {
  const { idModule, idMasteryTest, idExecution } = useParams();
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const [masteryTestResultPerformance, setMasteryTestResultPerformance] = useState({
    grammarsGood: [],
    grammarsToImprove: [],
  });
  const [isFetching] = useService(getMasteryTestResultPerformance, {
    autoStart: true,
    onData: setMasteryTestResultPerformance,
    params: {
      module: idModule,
      masteryTest: idMasteryTest,
      masteryTestExecution: idExecution,
    },
  });

  const grammarsGood = useMemo(() => {
    return masteryTestResultPerformance.grammarsGood.map((grammar) => ({
      ...grammar,
      description:
        grammar.description.find(({ locale }) => locale === profile.locale)?.value ||
        grammar.description.find(({ locale }) => locale === "en").value,
    }));
  }, [profile.locale, masteryTestResultPerformance]);

  const grammarsToImprove = useMemo(() => {
    return masteryTestResultPerformance.grammarsToImprove.map((grammar) => ({
      ...grammar,
      description:
        grammar.description.find(({ locale }) => locale === profile.locale)?.value ||
        grammar.description.find(({ locale }) => locale === "en").value,
    }));
  }, [profile.locale, masteryTestResultPerformance]);

  return (
    <Async fetching={isFetching}>
      <FlexColumn
        backgroundColor="white"
        width="100%"
        height="100%"
        borderRadius="24px 0px 0px 24px"
        padding="50px 50px"
      >
        <TranslatedText
          as="span"
          translateKey="masteryTest.result.aboutYourPerformance"
          style={{ textTransform: "uppercase", fontSize: 14, fontWeight: 400 }}
        />
        <Separator size="lg" />
        {!!masteryTestResultPerformance.grammarsGood.length && (
          <>
            <TranslatedText
              as="span"
              translateKey="masteryTest.result.niceJob"
              style={{ fontSize: 18, fontWeight: 700 }}
            />
            <Separator size="xs" />
            {grammarsGood.map((grammar) => (
              <MasteryTestResultPerformanceItem grammar={grammar} variant="good" />
            ))}
            <Separator size="lg" />
          </>
        )}
        {!!masteryTestResultPerformance.grammarsToImprove.length && (
          <>
            <TranslatedText
              as="span"
              translateKey="masteryTest.result.whatToImprove"
              style={{ fontSize: 18, fontWeight: 700 }}
            />
            <Separator size="xs" />
            {grammarsToImprove.map((grammar) => (
              <MasteryTestResultPerformanceItem grammar={grammar} variant="bad" />
            ))}
          </>
        )}
      </FlexColumn>
    </Async>
  );
};

export default MasteryTestResultPerformance;
