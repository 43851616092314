import React from "react";
import PropTypes from "prop-types";
import CourseThemeContext from "../CourseThemeContext";
import Icon from "../Icon";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG, GREY_3 } from "../../consts/color";

class MultipleChoiceTextCheckbox extends React.Component {
  static propTypes = {
    status: PropTypes.string.isRequired,
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ secondary }) => {
          const borderColor = this.props.status
            ? {
                SELECTED: secondary,
                CORRECT: FEEDBACK_CORRECT,
                WRONG: FEEDBACK_WRONG,
              }[this.props.status]
            : GREY_3;

          const backgroundColor = this.props.status
            ? {
                SELECTED: secondary,
                CORRECT: FEEDBACK_CORRECT,
                WRONG: FEEDBACK_WRONG,
              }[this.props.status]
            : "transparent";

          const iconName = {
            CORRECT: "check",
            SELECTED: "check",
            WRONG: "close",
          }[this.props.status];

          return (
            <div
              style={{
                width: "32px",
                display: "flex",
                marginRight: "24px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "32px",
                  height: "32px",
                  border: `2px solid ${borderColor}`,
                  background: backgroundColor,
                  borderRadius: "50px",
                  transition: "all .25s ease-in-out",
                }}
              >
                {iconName && <Icon icon={iconName} color="#fff" />}
              </div>
            </div>
          );
        }}
      </CourseThemeContext.Consumer>
    );
  }
}

export default MultipleChoiceTextCheckbox;
