import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import UserAvatar from "../UserAvatar";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { BRONZE, GOLD, GREY_1, SILVER } from "../../consts/color";
import TranslatedText from "../TranslatedText";

const RankingListItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ lightColor }) => (
      <li
        style={{
          width: "100%",
          height: 40,
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          backgroundColor: props.highlight ? lightColor : GREY_1,
          padding: "15px 15px 15px 50px",
          position: "relative",
          boxShadow: props.highlight ? `0 0 10px 0 ${lightColor}` : "none",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: 40,
            height: "100%",
            borderRadius: 3,
            backgroundColor: props.highlightPosition
              ? {
                  1: GOLD,
                  2: SILVER,
                  3: BRONZE,
                }[props.position] || "#b3b3b3"
              : "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <label
            htmlFor="position"
            style={{
              margin: 0,
              fontSize: 12,
              color: props.position <= 3 ? "#fff" : "#455a64",
              fontWeight: props.position <= 3 || props.highlight ? "bold" : "400",
            }}
          >
            {!!props.position ? numeral(props.position).format("00") : "-"}
          </label>
        </div>
        <UserAvatar src={props.profilePicture} />
        <div
          style={{
            display: "flex",
            width: "100%",
            marginLeft: 10,
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <label
            htmlFor="nickname"
            style={{
              margin: 0,
              color: "#455a64",
              fontSize: 12,
              fontWeight: props.highlight ? "bold" : "400",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {props.nickname}
          </label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/*{props.highlight && (*/}
            {/*<CompareValues type="up" value={3} />*/}
            {/*)}*/}
            <TranslatedText
              as="label"
              translateKey="ranking.rankingPoints"
              style={{
                margin: 0,
                marginLeft: 10,
                color: "#455a64",
                fontSize: 12,
                fontWeight: props.highlight ? "bold" : "400",
              }}
              values={{
                points: numeral(props.points).format("0,0").replace(",", "."),
              }}
            />
          </div>
        </div>
      </li>
    )}
  </WhitelabelContext.Consumer>
);

RankingListItem.propTypes = {
  idStudent: PropTypes.string.isRequired,
  nickname: PropTypes.string.isRequired,
  profilePicture: PropTypes.string,
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  position: PropTypes.number,
  highlight: PropTypes.bool,
  highlightPosition: PropTypes.bool,
};

RankingListItem.defaultProps = {
  highlight: false,
  highlightPosition: false,
  points: null,
  position: null,
  profilePicture: null,
};

export default RankingListItem;
