import React from "react";
import PropTypes from "prop-types";
import Panel from "./Panel";
import Async from "./Async";
import Paragraph from "./Paragraph";

const AsyncPanel = (props) => (
  <Panel title={props.title} padding={props.padding} headerOptions={props.headerOptions}>
    <Async fetching={props.fetching} size="sm" color="dark">
      {props.hasData ? props.children : <Paragraph>{props.noDataText}</Paragraph>}
    </Async>
  </Panel>
);

AsyncPanel.propTypes = {
  children: PropTypes.node.isRequired,
  noDataText: PropTypes.string.isRequired,
  hasData: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  title: PropTypes.string,
  headerOptions: PropTypes.node,
  padding: PropTypes.oneOf(["xs", "sm", "md", "lg", "none"]),
};

AsyncPanel.defaultProps = {
  title: null,
  headerOptions: null,
  padding: "md",
};

export default AsyncPanel;
