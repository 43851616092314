export const MERGE_MASTERY_TEST_RESULT = "MERGE_MASTERY_TEST_RESULT";
export const CLEAN_MASTERY_TEST_RESULT = "CLEAN_MASTERY_TEST_RESULT";

export const MERGE_PLACEMENT_TEST_RESULT = "MERGE_PLACEMENT_TEST_RESULT";
export const CLEAN_PLACEMENT_TEST_RESULT = "CLEAN_PLACEMENT_TEST_RESULT";

export const MERGE_BONUS_TEST_RESULT = "MERGE_BONUS_TEST_RESULT";
export const CLEAN_BONUS_TEST_RESULT = "CLEAN_BONUS_TEST_RESULT";

export const MERGE_CERTIFICATION_TEST_RESULT = "MERGE_CERTIFICATION_TEST_RESULT";
export const CLEAN_CERTIFICATION_TEST_RESULT = "CLEAN_CERTIFICATION_TEST_RESULT";

export const MERGE_NOTIFICATIONS_PAGE = "MERGE_NOTIFICATIONS_PAGE";
export const ADD_NOTIFICATION_TO_QUEUE = "ADD_NOTIFICATION_TO_QUEUE";
export const REMOVE_NOTIFICATION_FROM_QUEUE = "REMOVE_NOTIFICATION_FROM_QUEUE";
export const SET_NOTIFICATIONS_READ = "SET_NOTIFICATIONS_READ";

export const REQUEST_MICROPHONE_CONFIG_END = "REQUEST_MICROPHONE_CONFIG_END";
export const REQUEST_MICROPHONE_CONFIG_NEXT_STEP = "REQUEST_MICROPHONE_CONFIG_NEXT_STEP";
export const REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE =
  "REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE";
export const REQUEST_MICROPHONE_CONFIG_START = "REQUEST_MICROPHONE_CONFIG_START";
export const MICROPHONE_CONFIG_INITIAL_STEP = "MICROPHONE_CONFIG_INITIAL_STEP";
export const MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP = "MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP";
export const MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM = "MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM";
export const MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT = "MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT";
export const MICROPHONE_CONFIG_TUTORIAL_STEP = "MICROPHONE_CONFIG_TUTORIAL_STEP";
export const REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM =
  "REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM";
