import React, { useContext, useEffect, useState } from "react";
import CircularButton from "../CircularButton";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import QuestionLabel from "../QuestionLabel";
import { sample, head } from "lodash";
import get from "lodash/get";
import translations from "../../locales";
import PopoverButton from "../PopoverButton";
import { WHITE } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";
import { useSelector } from "react-redux";
import { getExecutionHelpNotifications } from "student-front-commons/src/selectors/executionAchievement";

const sentryUserAction = { mainComponent: "ListenButton" };

export default function ListenButton(props) {
  const [message, setMessage] = useState();
  const whitelabelThemeContext = useContext(WhitelabelContext);
  const notifications = useSelector(getExecutionHelpNotifications);

  const listenNotification = head(
    notifications.filter((t) => ["NO_LISTEN_SEQUENCE", "LISTEN_USAGE_TIP"].some((x) => t === x))
  );

  useEffect(() => {
    if (listenNotification) {
      setMessage(sample(get(translations, `unitExecutionNotification.${listenNotification}`, [])));
    } else {
      setMessage(null);
    }
  }, [listenNotification]);

  return (
    <MediaQuery maxWidth={860}>
      {(popoverMatches) => (
        <PopoverButton
          isOpen={!!message}
          position="right-corner"
          backgroundColor={whitelabelThemeContext.primaryColor}
          maxWidth={window.screen.availWidth * (popoverMatches ? 0.25 : 0.4)}
          padding={7}
          content={
            <QuestionLabel color={WHITE} fontSize={12} cursor="auto">
              {message}
            </QuestionLabel>
          }
        >
          <MediaQuery maxWidth={540}>
            {(matches) => (
              <CircularButton
                color={whitelabelThemeContext.primaryColor}
                disabled={props.isDisabled}
                onClick={() => {
                  props.onClick();
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "CircularButton",
                    action: `Listen Unit Item`,
                  });
                }}
                icon="ear-hearing"
                size={matches ? "md" : "lg"}
              />
            )}
          </MediaQuery>
        </PopoverButton>
      )}
    </MediaQuery>
  );
}
