import React from "react";
import PropTypes from "prop-types";
import Paragraph from "../Paragraph";
import TranslatedText from "../TranslatedText";
import MessageChannelLoading from "./MessageChannelLoading";
import MessageChannelListItem from "./MessageChannelListItem";
import Icon from "../Icon";

const MessageChannelList = (props) => (
  <ul
    style={{
      listStyle: "none",
      padding: 0,
      margin: 0,
      backgroundColor: "#f1f1f1",
    }}
  >
    <MessageChannelLoading fetching={props.fetching} />
    {props.channels.map((channel) => (
      <MessageChannelListItem key={channel.id} channel={channel} />
    ))}
    {!props.fetching && !props.channels.length && (
      <div
        style={{
          padding: "20px 0px",
          textAlign: "center",
        }}
      >
        <Icon icon="message-processing-outline" size="xl" color="#90a4ae" />
        <TranslatedText as={Paragraph} translateKey="messages.noData" />
      </div>
    )}
  </ul>
);

MessageChannelList.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetching: PropTypes.bool.isRequired,
};

export default MessageChannelList;
