import React from "react";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";

function PronunciationRender(props) {
  const text = useSelector(getItemExecutionPropById(props.itemId, "item.text"));

  return (
    <div
      style={{
        maxWidth: 690,
        margin: "0 auto",
        height: "100%",
      }}
    >
      <HighlightableItemBox>
        <QuestionLabel text={text} />
      </HighlightableItemBox>
    </div>
  );
}

export default function PronunciationExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <PronunciationRender key={id} itemId={id} />);
}
