import { schema } from "normalizr";

import unitTypeSchema from "./unitTypeSchema";

export default new schema.Entity("unit", {
  type: unitTypeSchema,
  module: new schema.Entity("module", {
    course: new schema.Entity("course"),
  }),
});
