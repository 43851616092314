import React from "react";
import { useSelector } from "react-redux";
import ProgressBar from "../../core/ProgressBar";
import { getExecutionProgress } from "student-front-commons/src/selectors/execution";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";

export default function ExecutionProgressContainer() {
  const itemType = useSelector(getItemsExecutionsType);
  const percentage = useSelector(getExecutionProgress);

  if (itemType === "CONTENT_VIDEO") {
    return null;
  }

  return <ProgressBar height="4px" backgroundColor="#fff" maxWidth="100%" percentage={percentage * 100} />;
}
