import get from "lodash/get";
import { put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import browserHistory from "../browserHistory";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  DISMISS_SYSTEM_MESSAGE_FLOW,
  SYSTEM_MESSAGE_FLOW,
} from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW), function* () {
    try {
      const messageFlow = yield select(getFlow(SYSTEM_MESSAGE_FLOW));
      if (get(messageFlow, "params.message", "") === "error.error_inspect_html") {
        if (browserHistory.location.pathname.indexOf("unit") > 0) {
          yield put(startFlow(CLOSE_UNIT_EXECUTION_FLOW));
        }
        if (browserHistory.location.pathname.indexOf("mastery-test") > 0) {
          yield put(startFlow(CLOSE_MASTERY_TEST_EXECUTION_FLOW));
        }
        browserHistory.push({ pathname: "/", state: { systemNavigation: true } });
      }

      yield put(endFlow(SYSTEM_MESSAGE_FLOW));
    } catch (error) {
      logError({ error, flow: DISMISS_SYSTEM_MESSAGE_FLOW });
    } finally {
      yield put(endFlow(DISMISS_SYSTEM_MESSAGE_FLOW));
    }
  });
}
