import React, { useCallback } from "react";
import ErrorModal from "./ErrorModal";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "student-front-commons/src/selectors/systemMessage";
import { hideMessage } from "student-front-commons/src/actions/systemMessage";

const ErrorModalContainer = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(getErrorMessage);

  const handleClose = useCallback(() => {
    dispatch(hideMessage());
  }, [dispatch]);

  if (!errorMessage || !errorMessage.message) {
    return null;
  }

  return (
    <ErrorModal
      icon={errorMessage.icon}
      button={errorMessage.button}
      message={errorMessage.message}
      onClose={handleClose}
    />
  );
};

export default ErrorModalContainer;
