import {
  ADD_EXECUTION_ANSWER,
  END_EXECUTION,
  START_EXECUTION,
  CLEAN_EXECUTION,
  SET_NEXT_EXECUTION_ORDER,
  PLAY_EXECUTION_INSTRUCTION,
  INCREMENT_GAME_SCORE,
} from "../actions/execution";
import { orderBy, max, pick, get } from "lodash";

export default (state = {}, action) => {
  switch (action.type) {
    case START_EXECUTION:
      const associativeItems = orderBy(action.payload.execution.items, ["order"], ["asc"]);
      const answers = (action.payload.execution?.answers || []).map((answer) => ({
        ...answer,
        itemOrder: get(
          associativeItems.find((associativeItem) => associativeItem.item.id === answer.item),
          "order",
          undefined
        ),
      }));
      return {
        ...pick(action.payload.execution, ["id", "startedAt", "type", "module", "unit", "masteryTest"]),
        associativeItems,
        answers,
        initialInstructionsPlayed: [],
        currentOrder: max(answers.map((answer) => answer.itemOrder)) || 0,
        ...(action.payload.execution.isGame && {
          studentGameScore: (action.payload.execution?.answers || [])
            .reduce((acc, answer) => [...acc, ...answer.attempts], [])
            .filter((answer) => answer.correct).length,
          computerGameScore: (action.payload.execution?.answers || [])
            .reduce((acc, answer) => [...acc, ...answer.attempts], [])
            .filter((answer) => !answer.correct).length,
        }),
        isReview: action.payload.execution.isReview,
      };
    case ADD_EXECUTION_ANSWER:
      return {
        ...state,
        answers: [...state.answers, action.payload.answer],
      };
    case END_EXECUTION:
      return {
        ...state,
        result: action.payload.result,
        lastExecutionFinishedAt: new Date(),
      };
    case SET_NEXT_EXECUTION_ORDER:
      return {
        ...state,
        currentOrder: state.associativeItems.find((ai) => ai.order > state.currentOrder).order,
      };
    case PLAY_EXECUTION_INSTRUCTION:
      return {
        ...state,
        initialInstructionsPlayed: [...state.initialInstructionsPlayed, action.payload.itemType],
      };
    case INCREMENT_GAME_SCORE:
      return {
        ...state,
        [{ student: "studentGameScore", computer: "computerGameScore" }[action.payload.type]]:
          state[{ student: "studentGameScore", computer: "computerGameScore" }[action.payload.type]] + 1,
      };
    case CLEAN_EXECUTION:
      return {};
    default:
      return state;
  }
};
