export const MERGE_CONFIGURATION = "MERGE_CONFIGURATION";
export const MERGE_INSTRUCTION_AUDIOS = "MERGE_INSTRUCTION_AUDIOS";
export const MERGE_INSTRUCTION_VIDEOS = "MERGE_INSTRUCTION_VIDEOS";

/**
 * create an action to merge the configuration
 *
 * @param configuration
 * @returns {{type, payload: {configuration: *}}}
 */
export const requestMergeConfiguration = (data) => ({
  type: MERGE_CONFIGURATION,
  payload: {
    data,
  },
});

/**
 * create an action to merge the instruction audios
 *
 * @param configuration
 * @returns {{type, payload: {configuration: *}}}
 */
export const requestMergeInstructionAudios = (data) => ({
  type: MERGE_INSTRUCTION_AUDIOS,
  payload: {
    data,
  },
});

/**
 * create an action to merge the instruction videos
 *
 * @param configuration
 * @returns {{type, payload: {configuration: *}}}
 */
export const requestMergeInstructionVideos = (data) => ({
  type: MERGE_INSTRUCTION_VIDEOS,
  payload: {
    data,
  },
});
