import { normalize } from "normalizr";

import profileSchema from "../schemas/profileSchema";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * get the user profile on api
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const getProfile = async () => {
  return await safeCall(
    async () => {
      const profile = await request({
        url: "profile",
        method: "get",
      });

      return normalize(profile, profileSchema);
    },
    "error_get_profile",
    "Unexpected error to get the profile."
  );
};

/**
 * update the user profile on api and return the new info
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.role - the user role
 * @param {string} payload.name - the user name
 * @param {string} payload.nickname - the user nickname
 * @param {string} payload.email - the user email
 * @param {boolean} payload.rankingParticipant - user wants to participate ?
 * @param {string} payload.password - the current password
 * @param {string} payload.newPassword - the new password
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const updateProfile = async (payload) => {
  validate(
    {
      role: {
        presence: {
          allowEmpty: false,
        },
      },
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      email: {
        presence: {
          allowEmpty: false,
        },
        email: true,
      },
    },
    payload
  );

  if (payload.password || payload.newPassword) {
    validate(
      {
        password: {
          presence: {
            allowEmpty: false,
          },
        },
        newPassword: {
          presence: {
            allowEmpty: false,
          },
        },
      },
      payload
    );
  }

  if (payload.role === "STUDENT") {
    validate(
      {
        rankingParticipant: {
          presence: {
            allowEmpty: false,
          },
        },
      },
      payload
    );
  }

  return await safeCall(
    async () => {
      const profile = await request({
        url: "profile",
        method: "put",
        data: payload,
      });

      return normalize(profile, profileSchema);
    },
    "error_update_profile",
    "Unexpected error to updathe the profile."
  );
};

/**
 * update the user profile picture on api
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.image - the image buffer
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const updateProfilePicture = async (payload) => {
  validate(
    {
      image: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const formData = new FormData();
      formData.append("image", payload.image);

      await request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "profile-picture",
        method: "post",
        data: formData,
      });

      const profile = await request({
        url: "profile",
        method: "get",
      });
      return normalize(profile, profileSchema);
    },
    "error_update_profile_picture",
    "Unexpected error to update profile picture."
  );
};

/**
 * update the user push notification token
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.image - the image buffer
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const updatePushNotificationToken = async (payload) => {
  validate(
    {
      token: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `profile/${payload.token}/push-notification-token`,
        method: "patch",
      });
    },
    "error_save_push_notification_token",
    "Unexpected error to save the push notification token."
  );
};

/**
 * update the user online time
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.time - the online time
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const updateOnlineTime = async (payload) => {
  validate(
    {
      time: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: "profile/online-time",
        method: "patch",
        data: payload,
      });
    },
    "error_save_push_notification_token",
    "Unexpected error to save the push notification token."
  );
};
