import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Column from "../../core/Column";
import Panel from "../../core/Panel";
import Row from "../../core/Row";
import Title from "../../core/Title";
import Separator from "../../core/Separator";
import CustomRankingListContainer from "./CustomRankingListContainer";
import TranslatedText from "../../core/TranslatedText";
import queryString from "query-string";

class SemesterRanking extends React.Component {
  getSemesterStart = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.semester === "1") {
      return moment()
        .year(params.year || moment().year())
        .startOf("year")
        .toDate();
    }
    return moment()
      .year(params.year || moment().year())
      .month(6)
      .startOf("month")
      .toDate();
  };

  getSemesterEnd = () => {
    const params = queryString.parse(this.props.location.search);
    if (params.semester === "1") {
      return moment()
        .year(params.year || moment().year())
        .month(5)
        .endOf("month")
        .toDate();
    }
    return moment()
      .year(params.year || moment().year())
      .endOf("year")
      .toDate();
  };

  render() {
    return (
      <Panel>
        <Row>
          <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
            <TranslatedText translateKey="regional" as={Title} />
            <Separator size="sm" />
            <CustomRankingListContainer
              id="currentSemesterRegional"
              key={`regional-${this.props.location.search}`}
              level="regional"
              from={this.getSemesterStart()}
              to={this.getSemesterEnd()}
            />
          </Column>
          <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
            <TranslatedText translateKey="national" as={Title} />
            <Separator size="sm" />
            <CustomRankingListContainer
              id="currentSemesterNational"
              key={`national-${this.props.location.search}`}
              level="national"
              from={this.getSemesterStart()}
              to={this.getSemesterEnd()}
            />
          </Column>
        </Row>
      </Panel>
    );
  }
}

export default withRouter(SemesterRanking);
