import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Button from "../../../core/Button";
import Separator from "../../../core/Separator";
import TranslatedText from "../../../core/TranslatedText";
import TestHeader from "../TestHeader";
import TestPanel from "../TestPanel";
import TestPanelTitle from "../TestPanelTitle";
import PracticeTestPanelResultDescription from "./PracticeTestPanelResultDescription";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_5 } from "../../../consts/color";
import FlexColumn from "../../../core/FlexColumn";
import TestStepContainer from "../TestStepContainer";
import { addSentryUserAction } from "../../../util";

const sentryUserAction = { mainComponent: "PracticeTestScoreScene" };

const PracticeTestScoreScene = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <TestHeader color={primaryColor} />
        <TestStepContainer currentStep={3} />
        <TestPanel>
          <TranslatedText
            as={TestPanelTitle}
            translateKey={
              props.correctPercentage >= 60 ? "placementTest.warmingGoodScore" : "placementTest.warmingBadScore"
            }
            color={primaryColor}
          />
          <Separator />
          <TranslatedText
            as={PracticeTestPanelResultDescription}
            translateKey={
              props.correctPercentage >= 60
                ? "placementTest.warmingGoodScoreDescription"
                : "placementTest.warmingBadScoreDescription"
            }
            color={GREY_5}
            fontSize="16px"
          />
          <Separator size="xxl" />
          <Separator size="md" />
          <FlexColumn alignItems="center">
            <TranslatedText
              as={Button}
              translateKey="placementTest.button.startPlacement"
              width="300px"
              fontSize="14px"
              fontWeight="bold"
              fontColor="#fff"
              color={primaryColor}
              onClick={() => {
                props.history.push("/placement-test");
                addSentryUserAction({
                  ...sentryUserAction,
                  clickedComponent: "TranslatedText",
                  action: `Advance to Placement Test. Navigate to /placement-test`,
                });
              }}
              style={{
                textTransform: "uppercase",
              }}
            />
            <Separator size="sm" />
            <Separator size="sm" />
            <TranslatedText
              as={Link}
              translateKey="placementTest.button.repeatWarming"
              style={{
                fontSize: 14,
                color: GREY_5,
                textTransform: "uppercase",
              }}
              to="/practice-test"
              onClick={() => {
                addSentryUserAction({
                  ...sentryUserAction,
                  clickedComponent: "TranslatedText",
                  action: `Repeat Practice Test. Navigate to /practice-test`,
                });
              }}
            />
          </FlexColumn>
        </TestPanel>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

PracticeTestScoreScene.propTypes = {
  correctPercentage: PropTypes.number.isRequired,
};

export default withRouter(PracticeTestScoreScene);
