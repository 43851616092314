import React from "react";
import Styled, { keyframes } from "styled-components";
import Icon from "../../../core/Icon";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../../consts/color";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    transform: scale(1.5);
  }
`;

const BeaconPanel = Styled.span`
  display: inline-block;
  animation: ${pulse} 1.2s ease-in-out infinite;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  box-sizing: border-box;
  padding: 4px 7px;
`;

class PracticeTestTourIcon extends React.PureComponent {
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <BeaconPanel {...this.props} color={primaryColor}>
            <Icon color={WHITE} icon="help" size="xs" />
          </BeaconPanel>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default PracticeTestTourIcon;
