import React from "react";
import PropTypes from "prop-types";
import inRange from "lodash/inRange";
import ControlBarLayoutItem from "../../core/old-control-bar/ControlBarLayoutItem";
import ControlBarLayout from "../../core/old-control-bar/ControlBarLayout";
import withTranslation from "../../core/withTranslation";
import RecordButtonContainer from "../../core/old-control-bar/RecordButtonContainer";
import RepeatButtonContainer from "../../core/old-control-bar/RepeatButtonContainer";
import NextButton from "../../core/old-control-bar/NextButton";
import CertificationTestItemTimerContainer from "./CertificationTestItemTimerContainer";

class CertificationTestExecutionControlBar extends React.Component {
  static propTypes = {
    values: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  };

  isRecordDisabled = () => {
    if (["FREE_SPEAK", "FREE_SPEAK_IMAGE"].some((type) => type === this.props.values.associativeItem.item.type.key)) {
      return this.props.values.isDisabled || this.props.values.isSubmittingRecord;
    }
    return true;
  };

  isRepeatDisabled = () => {
    if (
      ["SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_IMAGE", "SINGLE_CHOICE_AUDIO", "TRUE_FALSE", "DICTATION"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return (
        this.props.values.isDisabled ||
        this.props.values.isRecording ||
        this.props.values.isSubmittingRecord ||
        this.props.values.repeatCount > 0
      );
    }
    return true;
  };

  isNextDisabled = () => {
    if (
      ["DICTATION", "GAP_FILL_MULTIPLE", "UNSCRAMBLE_DRAG_AND_DROP", "GAP_FILL_SELECT"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return (
        this.props.values.isDisabled ||
        this.props.values.isRecording ||
        this.props.values.isSubmittingRecord ||
        !this.props.values.answer
      );
    }
    if (["FREE_TEXT", "FREE_TEXT_IMAGE"].some((type) => type === this.props.values.associativeItem.item.type.key)) {
      return (
        this.props.values.isDisabled ||
        this.props.values.isRecording ||
        this.props.values.isSubmittingRecord ||
        !this.props.values.answer ||
        !inRange(
          this.props.values.answer.length,
          this.props.values.associativeItem.item.charactersMinLimit,
          this.props.values.associativeItem.item.charactersMaxLimit
        )
      );
    }
    return true;
  };

  render() {
    return (
      <ControlBarLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ControlBarLayoutItem label={this.props.getTranslation("record")}>
            <RecordButtonContainer isDisabled={this.isRecordDisabled()} isRecording={!!this.props.values.isRecording} />
          </ControlBarLayoutItem>
          {this.props.values.associativeItem.ability !== "SPEAKING" && (
            <ControlBarLayoutItem label={this.props.getTranslation("repeat")}>
              <RepeatButtonContainer isDisabled={this.isRepeatDisabled()} isPlacementRepeat={true} type="audio" />
            </ControlBarLayoutItem>
          )}
          <ControlBarLayoutItem label={this.props.getTranslation("timer")}>
            <CertificationTestItemTimerContainer
              isSubmitting={this.props.isSubmitting}
              isRecording={!!this.props.values.isRecording}
              key={this.props.values.associativeItem.item.id}
              time={this.props.values.associativeItem.item.type.defaultMasteryTestTime}
              itemType={this.props.values.associativeItem.item.type.key}
              onTimerEnd={
                ["FREE_SPEAK", "FREE_SPEAK_IMAGE"].some(
                  (type) => type === this.props.values.associativeItem.item.type.key
                )
                  ? () => false
                  : this.props.onSave
              }
            />
          </ControlBarLayoutItem>
          <ControlBarLayoutItem label={this.props.getTranslation("next")}>
            <NextButton
              onClick={this.props.onSave}
              isDisabled={this.isNextDisabled()}
              isSubmitting={this.props.isSubmitting}
            />
          </ControlBarLayoutItem>
        </div>
      </ControlBarLayout>
    );
  }
}

export default withTranslation(CertificationTestExecutionControlBar);
