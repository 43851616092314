import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import ClosedRankingTypeButton from "./ClosedRankingTypeButton";
import Separator from "../../core/Separator";
import { GREY_6, WHITE } from "../../consts/color";
import IconButton from "../../core/IconButton";

class ClosedRankingTypeFilter extends React.Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  state = {
    selectedType: "monthly",
    availableTypes: ["monthly"],
    from: moment(this.props.match.params.period, "MMYYYY").startOf("month"),
    to: moment(this.props.match.params.period, "MMYYYY").endOf("month"),
  };

  componentDidMount() {
    const availableTypes = ["monthly"];
    const month = moment(this.props.match.params.period, "MMYYYY").month();
    if (month === 5 || month === 11) {
      availableTypes.push("semiannual");
    }
    if (month === 11) {
      availableTypes.push("yearly");
    }
    this.setState({
      availableTypes,
    });
  }

  handleTypeChange = (type) => {
    let from = moment(this.props.match.params.period, "MMYYYY").startOf("month");
    if (type === "semiannual") {
      from = from.subtract(5, "months");
    }
    if (type === "yearly") {
      from = from.subtract(11, "months");
    }

    this.setState({
      selectedType: type,
      from,
    });
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            backgroundColor: "#fff",
            width: 40,
            height: 40,
            borderRadius: "100%",
            position: "absolute",
            top: 10,
            right: -50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton icon="close" color={GREY_6} onClick={() => this.props.history.replace("/")} />
        </div>
        <div
          style={{
            width: 400,
            height: 60,
            boxSizing: "border-box",
            boxShadow: "0px 5px 30px 0 rgba(0, 0, 0, 0.1);",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            backgroundColor: WHITE,
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
            zIndex: 1,
            position: "relative",
          }}
        >
          {this.state.availableTypes.map((type) => (
            <ClosedRankingTypeButton
              key={type}
              label={`closedRanking.${type}`}
              isActive={type === this.state.selectedType}
              onClick={() => this.handleTypeChange(type)}
            />
          ))}
        </div>
        <Separator size="md" />
        {this.props.children({ type: this.state.selectedType, from: this.state.from, to: this.state.to })}
      </div>
    );
  }
}

export default withRouter(ClosedRankingTypeFilter);
