import { isEmpty, min } from "lodash";
import { useContext } from "react";
import BreadcrumbContainer from "../../core/BreadcrumbContainer";
import CourseThemeContext from "../../core/CourseThemeContext";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import Panel from "../../core/Panel";
import Separator from "../../core/Separator";
import SubHeaderDetailsContainer from "../../core/SubHeaderDetailsContainer";
import BonusTestHistory from "../../core/BonusTestHistory";
import BonusTestRewards from "./BonusTestRewards";
import BonusTestRewardsBlocked from "./BonusTestRewardsBlocked";
import { useSelector } from "react-redux";
import { getCoursePercentageProgress } from "student-front-commons/src/selectors/entity";
import { useParams } from "react-router-dom";
import { useService } from "student-front-commons/src/hooks";
import { getCurrentBonusTest } from "student-front-commons/src/services/bonusTestService";
import browserHistory from "../../browserHistory";
import Async from "../../core/Async";

export default function BonusTestIntroductionScene() {
  const params = useParams();
  const courseThemeContext = useContext(CourseThemeContext);
  const coursePercentage = useSelector(getCoursePercentageProgress(params.idCourse));
  // Bonus Test is available when user reaches 60% progress in current course
  const percentage = min([(coursePercentage / 60) * 100, 100]);
  const canStartBonusTest = percentage >= 100;

  const [isFetching] = useService(getCurrentBonusTest, {
    autoStart: true,
    params: { idCourse: params.idCourse },
    onData: (data) => {
      if (!isEmpty(data)) {
        browserHistory.replace(`/course/${params.idCourse}/bonus-test/result`);
      }
    },
  });

  return (
    <Async fetching={isFetching}>
      <SubHeaderDetailsContainer />
      <BreadcrumbContainer />
      <Separator size="xs" />
      <div className="container">
        <Separator size="xs" />
        <Panel padding="none">
          <FlexRow justifyContent="space-between" height="800px">
            <FlexColumn
              width="60%"
              position="relative"
              alignItems="center"
              backgroundColor={courseThemeContext.primary}
            >
              {canStartBonusTest ? <BonusTestRewards /> : <BonusTestRewardsBlocked />}
            </FlexColumn>
            <FlexColumn width="40%">
              <BonusTestHistory />
            </FlexColumn>
          </FlexRow>
        </Panel>
        <Separator size="xl" />
      </div>
    </Async>
  );
}
