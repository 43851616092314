import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CourseThemeContext from "./CourseThemeContext";

const BreadcrumbTabItem = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          color: props.active ? primary : "#757575",
          height: 40,
          display: "flex",
          alignItems: "center",
          padding: "0px 8px",
          borderBottom: props.active ? `2px solid ${primary}` : "2px solid #757575",
        }}
      >
        {props.label && props.to && (
          <Link to={props.to}>
            <span
              style={{
                fontSize: 14,
                fontWeight: props.active ? 700 : "normal",
              }}
            >
              {props.label}
            </span>
          </Link>
        )}
        {props.label && !props.to && (
          <span
            style={{
              fontSize: 14,
              fontWeight: props.active ? 700 : "normal",
            }}
          >
            {props.label}
          </span>
        )}
      </div>
    )}
  </CourseThemeContext.Consumer>
);

BreadcrumbTabItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  active: PropTypes.bool,
};

BreadcrumbTabItem.defaultProps = {
  to: null,
  label: null,
  last: false,
};

export default BreadcrumbTabItem;
