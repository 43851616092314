import PropTypes from "prop-types";
import Modal from "../../core/Modal";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import ButtonV2 from "../../core/ButtonV2";

function MasteryTestListItemConfirmExecutionModal({ onCancel, isOpen, onConfirm }) {
  return (
    <Modal isOpen={isOpen}>
      <FlexColumn alignItems="center">
        <TranslatedText translateKey="masteryTestStartExecutionModal.title" />
        <Separator size="xl" />
        <FlexRow>
          <ButtonV2 onClick={onCancel} size="md" labelKey="masteryTestStartExecutionModal.exit" intent="light" />
          <Separator size="lg" />
          <ButtonV2
            onClick={onConfirm}
            size="md"
            labelKey="masteryTestStartExecutionModal.start"
            intent="course-primary"
          />
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
}

MasteryTestListItemConfirmExecutionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default MasteryTestListItemConfirmExecutionModal;
