import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import FlexRow from "../../core/FlexRow";
import InBetweenSpacing from "../../core/InBetweenSpacing";
import CourseIcon from "../../core/CourseIcon";

const StudentCourseList = (props) => (
  <FlexRow flexWrap="wrap" alignItems="center">
    <InBetweenSpacing size="sm">
      {orderBy(props.courses, "order", "asc").map((course) => (
        <CourseIcon key={`course-list-${course.name}`} value={course.name} iconUrl={course.iconUrl} size="xs" />
      ))}
    </InBetweenSpacing>
  </FlexRow>
);

StudentCourseList.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
};

export default StudentCourseList;
