export const START_ITEM_INSTRUCTION = "START_ITEM_INSTRUCTION";
export const TOGGLE_ITEM_STEP_INSTRUCTION = "TOGGLE_ITEM_STEP_INSTRUCTION";
export const FINISH_ITEM_INSTRUCTION = "FINISH_ITEM_INSTRUCTION";

/**
 * start an item instruction
 *
 * @param {string} step
 * @returns {{ type: string, payload: { step: string } }}
 */
export const startItemInstruction = (step) => ({
  type: START_ITEM_INSTRUCTION,
  payload: {
    step,
  },
});

/**
 * set state to highlight the instruction step
 *
 * @returns {{ type: string, payload: { step: string } }}
 */
export const highlightItemInstruction = () => ({
  type: TOGGLE_ITEM_STEP_INSTRUCTION,
  payload: {
    highlight: true,
  },
});

/**
 * set state to highlight the instruction step
 *
 * @returns {{ type: string, payload: { step: string } }}
 */
export const unhighlightItemInstruction = () => ({
  type: TOGGLE_ITEM_STEP_INSTRUCTION,
  payload: {
    highlight: false,
  },
});

/**
 * finished an item instruction
 *
 * @returns {{ type: string }}
 */
export const finishItemInstruction = () => ({
  type: FINISH_ITEM_INSTRUCTION,
});
