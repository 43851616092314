import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { min, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import ColumnSeparator from "./ColumnSeparator";
import FlexRow from "./FlexRow";
import FlexColumn from "./FlexColumn";
import { getCoursePercentageProgress } from "student-front-commons/src/selectors/entity";
import CourseThemeContext from "./CourseThemeContext";
import { GREY_2 } from "../consts/color";
import Icon from "./Icon";
import ButtonV2 from "./ButtonV2";
import { getCurrentBonusTest } from "student-front-commons/src/services/bonusTestService";
import { useService } from "student-front-commons/src/hooks";
import CircularLoading from "./CircularLoading";
import { useHistory } from "react-router-dom";

export default function BonusTestProgress(props) {
  const history = useHistory();
  const [currentBonusTest, setCurrentBonusTest] = useState({});
  const courseThemeContext = useContext(CourseThemeContext);
  const coursePercentage = useSelector(getCoursePercentageProgress(props.idCourse));
  // Bonus Test is available when user reaches 60% progress in current course
  const percentage = min([(coursePercentage / 60) * 100, 100]);
  const isCompleted = percentage >= 100;

  const [isFetching, loadCurrentBonusTest] = useService(getCurrentBonusTest, {
    autoStart: false,
    onData: (data) => setCurrentBonusTest(data),
  });

  useEffect(() => {
    if (isCompleted) {
      loadCurrentBonusTest({ idCourse: props.idCourse });
    }
  }, [isCompleted]);

  const handleClick = () => {
    if (!isEmpty(currentBonusTest)) {
      history.push(`/course/${props.idCourse}/bonus-test/result`);
    } else {
      history.push(`/course/${props.idCourse}/bonus-test/introduction`);
    }
  };

  return (
    <div>
      <FlexRow alignItems="center" marginTop={props.marginTop} position="relative">
        <FlexColumn
          position="relative"
          backgroundColor={GREY_2}
          borderRadius={10}
          height={9}
          width={320}
          overflow="hidden"
          marginRight={-3}
        >
          <FlexColumn
            width={`${percentage}%`}
            backgroundColor={courseThemeContext.secondary}
            height="100%"
            position="absolute"
            transition="width 1s ease-in-out"
            left={0}
            top={0}
          />
        </FlexColumn>
        <span
          style={{
            color: courseThemeContext.secondary,
            fontSize: "10px",
            fontWeight: 700,
            position: "absolute",
            left: 0,
            top: "-4px",
          }}
        >
          BONUS
        </span>
        <FlexColumn
          alignItems="center"
          justifyContent="center"
          backgroundColor={isCompleted ? courseThemeContext.secondary : GREY_2}
          width="28px"
          height="28px"
          borderRadius="25px"
          cursor={isCompleted ? "default" : "pointer"}
        >
          <Icon
            icon="wallet-giftcard"
            color={isCompleted ? "#fff" : courseThemeContext.secondary}
            size="xs"
            onClick={handleClick}
          />
        </FlexColumn>
        <ColumnSeparator size="xs" />
        {isCompleted && (
          <FlexColumn width="120px">
            {isFetching ? (
              <CircularLoading size="xxs" />
            ) : (
              <ButtonV2
                onClick={handleClick}
                labelKey={!isEmpty(currentBonusTest) ? "placementTest.score" : "bonusTest.rescue"}
                intent="course-primary"
                fullWidth={true}
                size="sm"
              />
            )}
          </FlexColumn>
        )}
      </FlexRow>
    </div>
  );
}

BonusTestProgress.propTypes = {
  idCourse: PropTypes.string.isRequired,
  marginTop: PropTypes.string,
};

BonusTestProgress.defaultProps = {
  marginTop: "6px",
};
