import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";

export default class TextInput extends React.PureComponent {
  static propTypes = {
    style: PropTypes.object,
    underlineFocusStyle: PropTypes.object,
    floatingLabelFocusStyle: PropTypes.object,
    floatingLabelStyle: PropTypes.object,
    inputStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    onChange: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    fullWidth: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOf(["text", "number", "password"]),
    step: PropTypes.number,
    multiLine: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    maxLength: PropTypes.number,
    hintText: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    style: {},
    underlineFocusStyle: {},
    floatingLabelFocusStyle: {},
    floatingLabelStyle: {},
    inputStyle: {},
    value: "",
    label: null,
    error: null,
    fullWidth: false,
    disabled: false,
    maxLength: null,
    onChange: null,
    type: "text",
    step: 0.01,
    multiLine: false,
    rows: 2,
    rowsMax: 4,
    hintText: "",
    placeholder: "",
  };

  render() {
    return (
      <TextField
        style={this.props.style}
        label={this.props.label}
        error={!!this.props.error}
        helperText={this.props.error}
        value={this.props.value}
        onChange={(e) => this.props.onChange && this.props.onChange(e.target.value)}
        fullWidth={this.props.fullWidth}
        disabled={this.props.disabled}
        type={this.props.type}
        step={this.props.step}
        multiline={this.props.multiLine}
        rows={this.props.multiLine ? this.props.rows : 1}
        rowsMax={this.props.multiLine ? this.props.rowsMax : 1}
        inputStyle={this.props.inputStyle}
        inputProps={{ maxLength: this.props.maxLength }}
        autoComplete="off"
        placeholder={this.props.placeholder}
      />
    );
  }
}
