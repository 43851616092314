import React from "react";
import { useParams } from "react-router-dom";
import { useFlow } from "student-front-commons/src/hooks";
import Async from "../../../core/Async";
import { LOAD_CONFIGURATION_FLOW, START_UNIT_EXECUTION_FLOW } from "../../../consts";
import ContentVideoUnitExecutionScene from "./ContentVideoUnitExecutionScene";

export default function ContentVideoUnitExecutionSceneContainer() {
  const { idModule, idUnit } = useParams();

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: idModule,
      unit: idUnit,
    },
  });

  return (
    <Async fetching={isLoadingConfig || isStartingExecution}>
      <ContentVideoUnitExecutionScene isPending={isLoadingConfig || isStartingExecution} />
    </Async>
  );
}
