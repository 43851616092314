import React from "react";
import PropTypes from "prop-types";
import { GREY_5, WHITE } from "../../consts/color";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../../core/TranslatedText";

const ClosedRankingLevelButton = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, lightColor }) => (
      <TranslatedText
        as="button"
        translateKey={props.label}
        onClick={props.onClick}
        style={{
          outline: "none",
          border: "none",
          backgroundColor: props.isActive ? lightColor : WHITE,
          color: props.isActive ? primaryColor : GREY_5,
          fontWeight: props.isActive ? "bold" : "normal",
          fontSize: 11,
          textTransform: "uppercase",
          width: 100,
          padding: "15px 0px",
          boxSizing: "border-box",
        }}
      />
    )}
  </WhitelabelContext.Consumer>
);

ClosedRankingLevelButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ClosedRankingLevelButton;
