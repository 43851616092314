import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Async from "../core/Async";

class AuthSceneContainer extends React.Component {
  componentDidMount() {
    const search = queryString.parse(this.props.location.search);

    sessionStorage.clear();
    sessionStorage.setItem("accessToken", search.token);
    if (!search.token) {
      this.props.history.replace("/login");
      return;
    }

    const decodedToken = jwtDecode(search.token);
    sessionStorage.setItem("id", decodedToken.sub);
    sessionStorage.setItem("onboardingUserDemo", decodedToken.openByPortal && decodedToken.isDemoAccess)
    if (decodedToken.contractCode) {
      sessionStorage.setItem("contractCode", decodedToken.contractCode);
    }
    this.props.history.replace("/");
  }

  render() {
    return (
      <Async fetching>
        <div />
      </Async>
    );
  }
}

export default withRouter(AuthSceneContainer);
