import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startFlow } from "../actions/flow";

export default function useFlow(flowId, { autoStart, params } = {}) {
  const autoStartRef = useRef(!!autoStart);

  const dispatch = useDispatch();
  const isPending = useSelector((state) => state.flows[flowId]?.isPending);

  const start = useCallback(
    (params) => {
      dispatch(startFlow(flowId, params));
    },
    [flowId]
  );

  useEffect(() => {
    if (autoStartRef.current && !isPending) {
      start(params);
      autoStartRef.current = false;
    }
  }, []);

  return [autoStartRef.current || isPending, start];
}
