import React from "react";
import PropTypes from "prop-types";
import Color from "color";
import DateLabel from "../../core/DateLabel";
import SprintListItem from "../../core/SprintListItem";
import ColumnSeparator from "../../core/ColumnSeparator";
import withTranslation from "../../core/withTranslation";

const SprintHistoryItem = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <DateLabel color="rgba(0, 0, 0, 0.5)" format="DD/MM" date={props.date} size="sm" />
    <ColumnSeparator size="xs" />
    <SprintListItem
      label={props.type === "record" ? "Your Record!" : null}
      suffix={props.getTranslation("days")}
      value={props.value}
      max={props.max}
      backgroundColor={
        {
          default: Color("#607c8a")
            .fade(1 - (props.value === props.max ? 0.8 : props.value / props.max))
            .string(),
          record: "rgba(0, 150, 136, .2)",
        }[props.type]
      }
      labelColor={
        {
          default: "#607c8a",
          record: "rgba(0, 150, 136, 1)",
        }[props.type]
      }
    />
  </div>
);

SprintHistoryItem.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
  type: PropTypes.oneOf(["default", "record"]),
};

SprintHistoryItem.defaultProps = {
  type: "default",
};

export default withTranslation(SprintHistoryItem);
