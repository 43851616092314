import axios from "axios";
import axiosRetry from "axios-retry";
import ApiError from "../exceptions/apiError";
import { getEndpointUrl, getToken, onLogout } from "../config";

axiosRetry(axios, {
  retries: 5,
  retryCondition: (error) => {
    if (!!error?.response?.config?.url?.includes("speech-recognition")) {
      return false;
    }
    return axiosRetry.isRetryableError(error);
  },
  retryDelay: (retryCount) => retryCount * 2500,
});

const request = async ({ url, isV2 = false, method = "GET", headers, params, data }) => {
  try {
    const response = await axios({
      headers: {
        Authorization: `Bearer ${await getToken()}`,
        ...headers,
      },
      url: `${getEndpointUrl()}/${isV2 ? "v2" : "v1"}/${url}`,
      method,
      params,
      data,
    });
    return response.data;
  } catch (error) {
    const token = await getToken();
    if (error.response && error.response.status === 401 && !!token) {
      onLogout();
    }

    throw new ApiError(error);
  }
};

export default request;
