import omit from "lodash/omit";

import { CHANGE_FORM_VALUE } from "../actions/form";

export default (store) => (next) => (action) => {
  if (action.type && action.type === CHANGE_FORM_VALUE && !!store.getState().forms[action.payload.formId]) {
    const formValues = store.getState().forms[action.payload.formId].values;
    const error =
      action.payload.validations &&
      action.payload.validations.find((validatorFn) => validatorFn(action.payload.value, formValues));

    return next({
      ...action,
      payload: {
        ...omit(action.payload, "validations"),
        error: error ? error(action.payload.value, formValues) : null,
      },
    });
  }
  return next(action);
};
