import { head, get, uniqBy } from "lodash";

export const getExecutionType = (state) => {
  return state.executions?.type;
};

export const getNextItemsToExecute = (state) => {
  return state.executions.associativeItems.filter(
    (ai) => ai.order === state.executions.associativeItems.find((ai) => ai.order > state.executions.currentOrder).order
  );
};

export const getExecutionProgress = (state) => {
  const uniqueItems = uniqBy(get(state.executions, "associativeItems", []), "order").length;
  const uniqueAnswers = uniqBy(get(state.executions, "answers", []), "itemOrder").length;
  return uniqueAnswers / uniqueItems;
};

export const getIsFirstItemTypeText = (state) =>
  get(head(state.executions?.associativeItems), "item.type.key", "") === "TEXT";

export const getFirstItemText = (state) => get(head(state.executions?.associativeItems), "item.text", "");

export const getDialogueHistory = (state) =>
  state.executions?.associativeItems.filter(
    (ai) =>
      ai.order <= state.executions?.currentOrder &&
      ["DIALOGUE", "DIALOGUE_OPTION", "DIALOGUE_OPTION_SPEECHLESS"].some((type) => type === ai.item.type.key)
  );

export const getAssociativeItemOrderByItemId = (itemId) => (state) => {
  const associativeItem = state.executions.associativeItems.find(
    (associativeItem) => associativeItem.item.id === itemId
  );
  return get(associativeItem, "order", undefined);
};

export const getGameScoreByType = (type) => (state) =>
  get(state.executions, { student: "studentGameScore", computer: "computerGameScore" }[type], 0);

export const isExecutionApproved = (state) => state.executions?.result?.score >= state.executions?.result.scoreToPass;
