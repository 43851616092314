import React from "react";
import PropTypes from "prop-types";

const AnsweringQuestionIcon = ({ color, style }) => (
  <svg width="119" height="138" viewBox="0 0 119 138" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <circle cx="59.5" cy="105.5" r="29.5" fill="white" />
    <path
      d="M53.16 52.9685C52.1541 52.9685 51.3864 53.806 51.3864 54.7308C51.3864 55.7385 52.2378 56.4931 53.16 56.4931H116.671C117.677 56.4931 118.445 55.6556 118.445 54.7308V2.26231C118.445 1.25462 117.593 0.5 116.671 0.5H2.27354C1.26773 0.5 0.5 1.33751 0.5 2.26231V54.7308C0.5 55.7385 1.35136 56.4931 2.27354 56.4931H42.7501C43.7559 56.4931 44.5237 55.6556 44.5237 54.7308C44.5237 53.7231 43.6723 52.9685 42.7501 52.9685H4.10246V4.07951H114.898V52.9685H53.16Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M47.9552 52.9684C46.9494 52.9684 46.1816 53.806 46.1816 54.7308C46.1816 55.7385 47.033 56.4931 47.9552 56.4931C48.9535 56.4931 49.7172 55.6681 49.7286 54.7516C49.798 53.7665 48.9142 52.9684 47.9552 52.9684Z"
      fill={color}
      stroke={color}
    />
    <path d="M13.2368 17.5748H105.707" stroke={color} strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M13.2368 28.5514H105.707" stroke={color} strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M13.2368 39.5281H66.9472" stroke={color} strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" />
    <path
      d="M58.875 123.125C57.5 123.125 56.375 122 56.375 120.625C56.375 119.25 57.5 118.125 58.875 118.125C60.25 118.125 61.375 119.25 61.375 120.625C61.375 122 60.25 123.125 58.875 123.125ZM61.375 111.875V108.513C66.2125 107.338 69.5625 102.962 69.3625 98C69.15 92.6 64.725 88.2125 59.275 88C56.3625 87.8875 53.6125 88.9375 51.5375 90.9375C49.5 92.9125 48.375 95.55 48.375 98.375C48.375 99.75 49.5 100.875 50.875 100.875C52.25 100.875 53.375 99.75 53.375 98.375C53.375 96.925 53.95 95.5625 55.0125 94.5375C56.1 93.4875 57.55 92.9375 59.1 93C61.95 93.1125 64.275 95.3875 64.375 98.1875C64.475 100.8 62.675 103.112 60.0875 103.675C57.9 104.15 56.3875 106.038 56.3875 108.263V111.875C56.3875 113.25 57.5125 114.375 58.8875 114.375C60.25 114.375 61.375 113.25 61.375 111.875ZM75.5625 132.388C76.7375 131.675 77.125 130.138 76.4 128.95C75.6875 127.763 74.15 127.388 72.9625 128.112C68.7625 130.65 63.9375 132 59 132C44.1125 132 32 119.888 32 105C32 90.1125 44.1125 78 59 78C73.8875 78 86 90.1125 86 105C86 110.325 84.4125 115.5 81.425 119.987C80.6625 121.138 80.9625 122.688 82.1125 123.45C83.2625 124.213 84.8125 123.913 85.575 122.763C89.125 117.463 91 111.312 91 105C91 96.45 87.675 88.4125 81.625 82.375C75.5875 76.325 67.55 73 59 73C50.45 73 42.4125 76.325 36.375 82.375C30.325 88.4125 27 96.45 27 105C27 113.55 30.325 121.588 36.375 127.625C42.425 133.675 50.45 137 59 137C64.85 137 70.575 135.4 75.5625 132.388Z"
      fill={color}
    />
  </svg>
);

AnsweringQuestionIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default AnsweringQuestionIcon;
