import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import CircularLoading from "../CircularLoading";
import DefaultImage from "../../images/no-image.jpg";
import { getImage } from "../../stores/image-store";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ItemImage = (props) => {
  const [loading, setLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(0);

  const handleLoad = useCallback((event) => {
    if (props.zoomable) {
      event.currentTarget.style.cursor = "zoom-in";
    }
    setLoading(false);
  }, []);

  const handleError = useCallback(() => {
    if (reloadCount === 3) {
      return;
    }

    setLoading(true);
    setReloadCount(reloadCount + 1);
  }, [reloadCount]);

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {loading && (
        <div
          style={{
            margin: "60px auto",
          }}
        >
          <CircularLoading
            size="sm"
            rgb={{
              r: 175,
              g: 175,
              b: 175,
            }}
          />
        </div>
      )}
      <MediaQuery maxDeviceWidth={425}>
        {(matches) =>
          props.zoomable ? (
            <Zoom>
              <img
                alt={`presentation-for-${props.itemId}`}
                src={
                  reloadCount >= 3
                    ? DefaultImage
                    : getImage(`items/${props.itemId}/images/lg.png`) ||
                      `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/${matches ? "xs" : "lg"}.png${
                        reloadCount ? `?${reloadCount}` : ""
                      }`
                }
                onLoad={handleLoad}
                onError={handleError}
                style={{
                  ...props.style,
                  transition: "opacity .5s",
                  opacity: loading ? 0 : 1,
                  position: loading ? "absolute" : "relative",
                }}
              />
            </Zoom>
          ) : (
            <img
              alt={`presentation-for-${props.itemId}`}
              src={
                reloadCount >= 3
                  ? DefaultImage
                  : getImage(`items/${props.itemId}/images/lg.png`) ||
                    `${process.env.REACT_APP_FILES_URL}/items/${props.itemId}/images/${matches ? "xs" : "lg"}.png${
                      reloadCount ? `?${reloadCount}` : ""
                    }`
              }
              onLoad={handleLoad}
              onError={handleError}
              style={{
                ...props.style,
                transition: "opacity .5s",
                opacity: loading ? 0 : 1,
                position: loading ? "absolute" : "relative",
              }}
            />
          )
        }
      </MediaQuery>
    </div>
  );
};

ItemImage.propTypes = {
  itemId: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
  zoomable: PropTypes.bool,
};

ItemImage.defaultProps = {
  style: {},
  zoomable: false,
};

export default ItemImage;
