import React from "react";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import SceneVideoIntroduction from "./SceneVideoIntroduction";
import { UPDATE_STUDENT_PAGE_ACCESSED_FLOW, SHOW_VIDEO_INSTRUCTION_FLOW } from "../consts";

const SceneVideoIntroductionMenuContainer = () => (
  <Flow autoStart={false} id={SHOW_VIDEO_INSTRUCTION_FLOW}>
    {({ isPending, getParams }) => (
      <Flow
        autoStart={false}
        id={UPDATE_STUDENT_PAGE_ACCESSED_FLOW}
        params={{
          screen: get(getParams(), "scene", null),
        }}
      >
        {({ handleStart }) =>
          isPending && <SceneVideoIntroduction scene={get(getParams(), "scene", null)} onEnd={handleStart} />
        }
      </Flow>
    )}
  </Flow>
);

export default SceneVideoIntroductionMenuContainer;
