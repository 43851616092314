import React, { Component } from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

export default class Tabs extends Component {
  static propTypes = {
    extra: PropTypes.node,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        component: PropTypes.node.isRequired,
      })
    ).isRequired,
    title: PropTypes.node,
    headerStyle: PropTypes.shape({}),
  };

  static defaultProps = {
    headerStyle: {},
    title: null,
  };

  state = { activeTab: "" };

  componentWillMount() {
    this.setState({
      activeTab: this.props.tabs[0].key || this.props.tabs[0].label,
    });
  }

  handleChangeTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  renderTabContent = () => {
    let content = null;
    if (this.props.tabs && this.props.tabs.find((tab) => (tab.key || tab.label) === this.state.activeTab)) {
      content = this.props.tabs.find((tab) => (tab.key || tab.label) === this.state.activeTab).component;
    }
    return content;
  };

  render() {
    return (
      <div style={{ width: "100%" }}>
        <WhitelabelContext.Consumer>
          {({ secondaryColor }) => (
            <div
              style={{
                width: "100%",
                height: 50,
                boxSizing: "border-box",
                padding: "10px 20px 10px 20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                ...this.props.headerStyle,
              }}
            >
              {this.props.title && this.props.title}
              <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
                {this.props.tabs &&
                  this.props.tabs.map((tab) => (
                    <button
                      key={`tab-button${tab.key || tab.label}`}
                      onClick={() => this.handleChangeTab(tab.key || tab.label)}
                      style={{
                        color: this.state.activeTab === (tab.key || tab.label) ? secondaryColor : "rgba(0, 0, 0, 0.5)",
                        fontSize: 13,
                        background: "none",
                        border: "none",
                        borderRadius: 0,
                        outline: "none",
                        borderBottom:
                          this.state.activeTab === (tab.key || tab.label) ? `2px solid ${secondaryColor}` : "none",
                      }}
                    >
                      {tab.label}
                    </button>
                  ))}
              </div>
              {this.props.extra}
            </div>
          )}
        </WhitelabelContext.Consumer>
        {this.renderTabContent()}
      </div>
    );
  }
}
