import React from "react";
import PropTypes from "prop-types";

const CertificationTestContent = (props) => (
  <div
    className="container"
    style={{
      paddingTop: 20,
      paddingBottom: 160,
    }}
  >
    {props.children}
  </div>
);

CertificationTestContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CertificationTestContent;
