import React from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import CourseSummaryHeader from "./CourseSummaryHeader";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import { GREY_5 } from "../../consts/color";

const CourseSummaryHeaderContainer = (props) => (
  <Entity name="course">
    {({ getById }) => {
      const course = getById(props.match.params.idCourse);
      return (
        <>
          <CourseSummaryHeader course={course} />
          <TranslatedText
            as="h1"
            translateKey="courseSummary.title"
            style={{
              color: GREY_5,
              fontSize: 42,
              fontWeight: "bold",
              margin: 0,
              textAlign: "center",
            }}
          />
          <Separator size="xs" />
          <TranslatedText
            as="p"
            translateKey="courseSummary.description"
            values={{
              course: course.name,
            }}
            style={{
              margin: 0,
              fontSize: 14,
              textTransform: "uppercase",
              textAlign: "center",
              color: GREY_5,
            }}
          />
        </>
      );
    }}
  </Entity>
);

export default withRouter(CourseSummaryHeaderContainer);
