import React from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const PracticeTestProgressBar = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth: 690,
          height: 2,
          borderRadius: 3,
          backgroundColor: "#cecece",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transition: "width 1s ease-in-out",
            backgroundColor: primaryColor,
            width: `${props.percentage > 100 ? 100 : props.percentage}%`,
            height: 2,
          }}
        />
      </div>
    )}
  </WhitelabelContext.Consumer>
);

export default PracticeTestProgressBar;
