import React from "react";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import { connect } from "react-redux";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPlacementTestLevels } from "student-front-commons/src/services/placementTestLevelService";
import Async from "../../core/Async";
import ScoreRuler from "./ScoreRuler";

const ScoreRulerContainer = (props) => (
  <FetchData service={getPlacementTestLevels}>
    {({ isFetching, data }) => (
      <Async fetching={isFetching}>
        <ScoreRuler
          levels={orderBy(data || [], ["level"], ["asc"]).filter((placementLevel) =>
            Number.isInteger(placementLevel.level)
          )}
          reachedLevel={get(props.reachedLevel, "level", 0)}
        />
      </Async>
    )}
  </FetchData>
);

const mapStateToProps = (state) => ({
  reachedLevel: state.placementTestResult.reachedLevel,
});

export default connect(mapStateToProps)(ScoreRulerContainer);
