import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import Styled from "styled-components";
import CompareValues from "./CompareValues";
import Tooltip from "./Tooltip";
import TranslatedText from "./TranslatedText";

const Ranking = Styled.div`
  display: inline-block;
  position: relative;
  float: right;
  text-align: center;
  z-index: 2;
  box-sizing: border-box;
  padding: 20px 0px;
  height: 80px;
`;

const Title = Styled.h1`
  margin: 0px;
  color: #8097A2;
  font-size: 12px;
`;

const Value = Styled.label`
  display: inline-block;
  color: #607D8B;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
`;

const SubHeaderRanking = (props) => (
  <Ranking>
    <div>
      <TranslatedText as={Title} translateKey={props.title} />
    </div>
    <div
      style={{
        textAlign: "left",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Value>
        {props.value > 0 ? (
          numeral(props.value).format("0o")
        ) : (
          <Tooltip text="Get your firsts points to discover your position">-</Tooltip>
        )}
      </Value>
      {!!props.value && !!props.compareValue && (
        <CompareValues
          type={props.value === props.compareValue ? "equal" : props.value < props.compareValue ? "up" : "down"}
          value={props.compareValue}
        />
      )}
    </div>
  </Ranking>
);

SubHeaderRanking.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  compareValue: PropTypes.number,
};

SubHeaderRanking.defaultProps = {
  title: "",
  value: null,
  compareValue: null,
};

export default SubHeaderRanking;
