import React from "react";
import ContentVideoList from "../../core/content-video-list/ContentVideoList";
import FlexColumn from "../../core/FlexColumn";
import EmptyState from "../../assets/icons/empty-state.png";
import TranslatedText from "../../core/TranslatedText";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getModuleIdsByCourseId, isAnyContentVideoUnitByCourseId } from "student-front-commons/src/selectors/entity";
import SubHeaderDetailsContainer from "../../core/SubHeaderDetailsContainer";
import BreadcrumbContainer from "../../core/BreadcrumbContainer";

function ModuleContentVideoListScene() {
  const { idCourse } = useParams();

  const moduleIds = useSelector(getModuleIdsByCourseId(idCourse));
  const hasCourseContentVideo = useSelector(isAnyContentVideoUnitByCourseId(idCourse));

  return (
    <div style={{ marginBottom: 20 }}>
      <SubHeaderDetailsContainer />
      <BreadcrumbContainer />
      <div className="container">
        {hasCourseContentVideo ? (
          moduleIds?.map((moduleId) => (
            <ContentVideoList allowBlockedUnit={true} moduleId={moduleId} key={moduleId} type="card" />
          ))
        ) : (
          <FlexColumn
            height={350}
            marginTop={26}
            borderRadius={4}
            width="100%"
            backgroundColor="#fff"
            boxShadow="0 0 4px 0 rgba(0, 0, 0, 0.25)"
            gap={26}
            justifyContent="center"
          >
            <FlexColumn width="100%" alignItems="center">
              <img src={EmptyState} alt="empty-state" />
            </FlexColumn>
            <FlexColumn alignItems="center" justifyContent="center" gap={5}>
              <TranslatedText
                translateKey="contentVideo.list.emptyState.title"
                style={{ fontWeight: 700, fontSize: 14 }}
                as="span"
              />
              <TranslatedText
                style={{ fontSize: 14 }}
                translateKey="contentVideo.list.emptyState.description"
                as="span"
              />
            </FlexColumn>
          </FlexColumn>
        )}
      </div>
    </div>
  );
}

export default ModuleContentVideoListScene;
