import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import GradeScene from "./GradeScene";
import { DETECT_DEV_TOOLS_FLOW } from "../../consts";
import ErrorModalContainer from "../../error/ErrorModalContainer";

const GradeSceneContainer = () => (
  <Flow autoStart={true} id={DETECT_DEV_TOOLS_FLOW}>
    {() => (
      <React.Fragment>
        <GradeScene />
        <ErrorModalContainer />
      </React.Fragment>
    )}
  </Flow>
);

export default GradeSceneContainer;
