import React from "react";
import HighlightableItemBox from "./HighlightableItemBox";
import TitleLabel from "../TitleLabel";
import Separator from "../Separator";
import ItemImage from "./ItemImage";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";

const ImageExerciseRender = (props) => {
  const title = useSelector(getItemExecutionPropById(props.itemId, "item.title"));

  return (
    <ExerciseItemPanel className="image-tour">
      {title && (
        <React.Fragment>
          <TitleLabel text={title} />
          <Separator />
        </React.Fragment>
      )}
      <HighlightableItemBox flexGrow={1}>
        <ItemImage
          itemId={props.itemId}
          style={{
            width: "100%",
          }}
          zoomable={true}
        />
      </HighlightableItemBox>
    </ExerciseItemPanel>
  );
};

export default function TextExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <ImageExerciseRender key={id} itemId={id} />);
}
