import React, { useContext } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import TranslatedText from "./TranslatedText";
import CourseThemeContext from "./CourseThemeContext";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const ButtonWithHover = Styled.button`
  position: relative;
  transition: all ease-in-out 150ms;

  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 4px 10px 0px rgba(0, 0, 0, 0.3)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

const ButtonV2 = (props) => {
  const courseThemeContext = useContext(CourseThemeContext);
  const whitelabelThemeContext = useContext(WhitelabelContext);

  return (
    <TranslatedText
      as={ButtonWithHover}
      translateKey={props.labelKey}
      disabled={props.isDisabled}
      onClick={props.onClick}
      type={props.buttonType}
      style={{
        backgroundColor: {
          primary: "#FF9F51",
          light: "#ECECEC",
          success: "#00786A",
          blank: "#fff",
          "course-primary": courseThemeContext?.primary,
          "wl-primary": whitelabelThemeContext?.primaryColor,
        }[props.intent],
        boxShadow: {
          md: "inset 0px -6px 0px rgba(0, 0, 0, 0.1)",
          sm: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
          xs: "inset 0px -2px 0px rgba(0, 0, 0, 0.1)",
        }[props.size],
        borderRadius: 50,
        width: props.fullWidth ? "100%" : "max-content",
        outline: "none",
        border: "none",
        padding: {
          md: props.fullWidth ? "12px 0" : "12px 50px",
          sm: props.fullWidth ? "7px 0" : "7px 40px",
          xs: props.fullWidth ? "5px 0" : "5px 25px",
        }[props.size],
        fontWeight: "bold",
        color: {
          light: "#424242",
          primary: "#fff",
          success: "#fff",
          blank: courseThemeContext?.primary,
          "course-primary": "#fff",
          "wl-primary": "#fff",
        }[props.intent],
        fontSize: {
          md: 14,
          sm: 12,
          xs: 10,
        }[props.size],
        lineHeight: {
          md: "16px",
          sm: "14px",
          xs: "12px",
        }[props.size],
        textTransform: "uppercase",
      }}
    />
  );
};

ButtonV2.propTypes = {
  onClick: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  intent: PropTypes.oneOf(["primary", "secondary", "light", "course-primary", "success", "blank", "wl-primary"]),
  size: PropTypes.oneOf(["sm", "xs", "md"]),
  buttonType: PropTypes.oneOf(["button", "submit"]),
  fullWidth: PropTypes.bool,
};

ButtonV2.defaultProps = {
  isDisabled: false,
  intent: "primary",
  size: "sm",
  fullWidth: false,
  buttonType: "button",
};

export default ButtonV2;
