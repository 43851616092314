import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import CircularButton from "../CircularButton";
import ColumnSeparator from "../ColumnSeparator";
import UserAvatar from "../UserAvatar";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const MessageChatHeader = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          backgroundColor: primaryColor,
          height: 70,
          display: "flex",
          alignItems: "center",
          padding: "0px 10px 0px 30px",
        }}
      >
        <CircularButton
          icon="arrow-left"
          color="rgba(255, 255, 255, 0.1)"
          size="md"
          onClick={(event) => {
            event.preventDefault();
            props.history.replace({
              pathname: props.history.location.pathname,
              state: {
                messagePopoverScene: "channels",
                messageChannel: null,
              },
            });
          }}
        />
        <ColumnSeparator size="md" />
        <UserAvatar src={props.chattingWith.profilePicture} size="sm" />
        <ColumnSeparator size="xs" />
        <div>
          <label
            htmlFor="sender"
            style={{
              fontSize: 14,
              color: "#fff",
            }}
          >
            {props.chattingWith.name || props.chattingWith.email}
          </label>
          <span
            style={{
              display: "block",
              fontSize: 12,
              color: "rgba(255, 255, 255, 0.8)",
            }}
          >
            {props.channel.subject}
          </span>
        </div>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

MessageChatHeader.propTypes = {
  channel: PropTypes.shape({
    subject: PropTypes.string,
  }).isRequired,
  chattingWith: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    profilePicture: PropTypes.string,
  }).isRequired,
};

export default withRouter(MessageChatHeader);
