import React from "react";
import PropTypes from "prop-types";
import { getColorOfCourse, WHITE } from "../consts/color";
import RippleEffect from "./RippleEffect";

const CourseButton = (props) => (
  <button
    onClick={props.onClick && props.onClick}
    disabled={props.disabled}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: {
        xs: 30,
        sm: 40,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      height: {
        xs: 30,
        sm: 40,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      minWidth: {
        xs: 30,
        sm: 40,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      backgroundColor: getColorOfCourse(props.isReview ? "review" : props.course).primary,
      border: `3px solid ${getColorOfCourse(props.isReview ? "review" : props.course).secondary}`,
      borderWidth: {
        xs: 2,
        sm: 2,
        md: 3,
        mdg: 3,
        lg: 3,
        xlg: 4,
      }[props.size],
      boxSizing: "border-box",
      outline: "none",
      borderRadius: "50%",
      cursor: props.onClick ? "pointer" : "inherited",
      color: WHITE,
      fontSize: {
        xs: 14.2,
        sm: 15.2,
        md: 28,
        mdg: 38,
        lg: 50,
        xlg: 60,
      }[props.size],
      fontWeight: 700,
      position: "relative",
      overflow: "hidden",
    }}
  >
    {props.course.toLowerCase().startsWith("pre") && (
      <span
        style={{
          backgroundColor: "#fff",
          color: getColorOfCourse(props.value).primary,
          borderRadius: 10,
          padding: "0px 5px",
          fontSize: 7,
          fontWeight: "bold",
          marginBottom: -5,
          height: 10,
        }}
      >
        PRE
      </span>
    )}
    {props.course.replace(/pre/i, "").replace("Bett", "A1")}
    {props.children}
  </button>
);

CourseButton.propTypes = {
  course: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isReview: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["xs", "sm", "md", "mdg", "lg", "xlg"]),
  children: PropTypes.node,
};

CourseButton.defaultProps = {
  onClick: null,
  isReview: false,
  disabled: false,
  size: "md",
  children: null,
};

export default RippleEffect(CourseButton);
