import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import Styled from "styled-components";
import CircularProgress from "./CircularProgress";
import ColumnSeparator from "./ColumnSeparator";
import TranslatedText from "./TranslatedText";
import CircularLoading from "./CircularLoading";

const Container = Styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: calc(-180px + 90px + 40px);
  height: 180px;
  right: 6%;
  z-index: 2;
  width: 20%;
  box-sizing: border-box;
  margin-left: -15px;
  background-color: #b5b5b5b5;
  border-right: 4px solid #fff;
  border-radius: 100%;
  padding-left: 30px;
`;

const Title = Styled.div`
  width: 100px;
  font-size: 11px;
  color: #FFF;
  display: inline-block;
`;

const Value = Styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #FFF;
  line-height: 42px;
`;

const WeekStatsPanel = (props) => (
  <Container>
    <TranslatedText as={Title} translateKey="subMenu.weekTime" />
    {props.isFetching ? (
      <CircularLoading size="xs" />
    ) : (
      <Value>
        {moment.duration(props.value || 0, "hours").format("hh:mm", { trim: false })}
        <ColumnSeparator size="xs" />
        <CircularProgress value={((props.value || 0) / props.requiredHours) * 100} />
      </Value>
    )}
  </Container>
);

WeekStatsPanel.propTypes = {
  requiredHours: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

WeekStatsPanel.defaultProps = {
  value: null,
};

export default WeekStatsPanel;
