import React, { useCallback, useContext, useRef } from "react";
import { last } from "lodash";
import { useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
  getItemExecutions,
  getSelectedId,
} from "student-front-commons/src/selectors/itemExecution";
import ItemImage from "./ItemImage";
import Column from "../Column";
import Row from "../Row";
import { useFlow } from "student-front-commons/src/hooks";
import { SELECT_ITEM_FLOW } from "../../consts";
import ExerciseButton from "./ExerciseButton";
import CourseThemeContext from "../CourseThemeContext";
import { SPEECH_GREEN, SPEECH_RED, WHITE } from "../../consts/color";
import CircularButton from "../CircularButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import {
  getInstructionStep,
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import SpeechRecognitionResultWordList from "../speech-recognition/SpeechRecognitionResultWordList";

const VocabularyExerciseRender = (props) => {
  const instructionStepsToDisableItem = ["initial", "select", "record", "translate"];
  const courseThemeContext = useContext(CourseThemeContext);
  const currentItemDivSpeechWordsRef = useRef();

  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);

  const selectedItemId = useSelector(getSelectedId);
  const itemExecutions = useSelector(getItemExecutions);
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const attempts = useSelector(getItemExecutionPropById(props.itemId, "attempts"));
  const qualityScore = useSelector(getItemExecutionPropById(props.itemId, "speechRecognitionResult.qualityScore"));
  const isSelectInstructionEnabled = useSelector(isInstructionEnableForStep("select"));
  const instructionStep = useSelector(getInstructionStep);
  const shouldHighlightInstruction = useSelector(isInstructionHighlighted);
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));

  const handleClick = useCallback(() => {
    if (selectedItemId !== props.itemId) {
      startSelectItemFlow({
        itemId: props.itemId,
      });
    }
  }, [selectedItemId, props.itemId]);

  const getSeparatorHeight = useCallback(() => {
    const currentItemIndex = itemExecutions.findIndex((itemExecution) => itemExecution.item.id === props.itemId);
    const indexesWithFeedback = itemExecutions
      .map((itemExecution, index) => ({ ...itemExecution, index }))
      .filter((itemExecution) => itemExecution.attempts.length > 0)
      .map((itemExecution) => itemExecution.index);

    const minHeight = 20;
    let separatorHeight = 0;

    if (indexesWithFeedback.indexOf(currentItemIndex + 2) >= 0) {
      separatorHeight = minHeight + 10;

      if (currentItemDivSpeechWordsRef.current) {
        separatorHeight += currentItemDivSpeechWordsRef.current.clientHeight / 2;
      } else if (selectedItemId === props.itemId) {
        separatorHeight += 10;
      }
    } else {
      if (currentItemDivSpeechWordsRef.current) {
        separatorHeight += currentItemDivSpeechWordsRef.current.clientHeight / 2 + 5;
      } else if (selectedItemId === props.itemId) {
        separatorHeight += 15;
      }
    }

    return separatorHeight || minHeight;
  }, [selectedItemId, itemExecutions, props.itemId]);

  return (
    <div
      className={
        isSelectInstructionEnabled && props.shouldPlayInstruction && !selectedItemId
          ? shouldHighlightInstruction
            ? "animate__animated animate__bounce animate__infinite"
            : "animate__animated animate__pulse animate__infinite"
          : null
      }
    >
      <ExerciseButton
        disabled={
          itemExecutions.some((ie) => !!ie.playingId && props.itemId !== ie.item.id) ||
          isSubmittingRecord ||
          isRecording ||
          isDisabled ||
          (((!props.shouldPlayInstruction && selectedItemId !== props.itemId) ||
            (props.shouldPlayInstruction && selectedItemId && selectedItemId !== props.itemId)) &&
            (instructionStepsToDisableItem.some((step) => step === instructionStep) || shouldHighlightInstruction))
        }
        overflow="visible"
        showBorder={selectedItemId === props.itemId || (last(attempts) && last(attempts).correct)}
        highlight={playingId && playingId.indexOf("post-phrase") < 0}
        highlightColor={last(attempts) && last(attempts).correct ? SPEECH_GREEN : null}
        onClick={handleClick}
      >
        {last(attempts) && !!qualityScore && (
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              zIndex: 2,
              left: 0,
              top: "-25px",
            }}
          >
            <CircularButton
              size="lg"
              color={last(attempts).correct ? SPEECH_GREEN : SPEECH_RED}
              style={{ position: "absolute" }}
            >
              <span style={{ color: WHITE, fontWeight: 400, fontSize: 20 }}>{qualityScore}</span>
            </CircularButton>
          </div>
        )}
        <ItemImage style={{ height: 175, width: "100%" }} itemId={props.itemId} />
        {selectedItemId === props.itemId && (
          <div
            style={{
              position: "absolute",
              bottom: currentItemDivSpeechWordsRef.current
                ? (currentItemDivSpeechWordsRef.current.clientHeight / 2) * -1
                : -16,
              left: 0,
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {!attempts.length && (
              <div
                style={{
                  backgroundColor: courseThemeContext.primary,
                  color: WHITE,
                  padding: "2px 10px",
                  borderRadius: 15,
                  fontSize: "19px",
                }}
              >
                {itemText}
              </div>
            )}
            {attempts.length > 0 && !!qualityScore && (
              <div ref={currentItemDivSpeechWordsRef} style={{ display: "flex", justifyContent: "center" }}>
                <SpeechRecognitionResultWordList />
              </div>
            )}
          </div>
        )}
      </ExerciseButton>
      <div
        style={{
          height: getSeparatorHeight(),
        }}
      />
    </div>
  );
};

VocabularyExerciseItem.propTypes = {};

export default function VocabularyExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return (
    <ExerciseItemPanel>
      <Row style={{ marginTop: 20 }}>
        {items.map((id, index) => (
          <Column lgSize={6}>
            <VocabularyExerciseRender key={id} itemId={id} shouldPlayInstruction={index === 0} />
          </Column>
        ))}
      </Row>
    </ExerciseItemPanel>
  );
}
