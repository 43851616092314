import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useService } from "student-front-commons/src/hooks";
import { getMasteryTestResultPerformance } from "student-front-commons/src/services/masteryTestExecutionService";
import { useParams, withRouter } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { addSentryUserAction } from "../../util";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import TranslatedText from "../../core/TranslatedText";
import { map, find } from "lodash";
import { getUnitsByGrammarsIdsAndModuleIdAndGroup } from "student-front-commons/src/selectors/entity";
import { useSelector } from "react-redux";
import MasteryTestListItemRecommendedUnitsListItem from "./MasteryTestListItemRecommendedUnitsListItem";
import locales from "../../locales";

const sentryUserAction = { mainComponent: "MasteryTestListItemRecommendedUnitsList" };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #de5a671a;
  overflow: hidden;
  position: relative;
  max-height: ${(props) => props.$height}px;
  transition: max-height 0.45s ease-in-out;
`;

const Bullet = styled.div`
  border-radius: 50%;
  background-color: #de5a67;
  font-size: 10px;
  width: 10px;
  height: 10px;
  text-align: center;
  color: white;
`;

const MasteryTestListItemRecommendedUnitsList = ({ masteryTest, expanded, history, match }) => {
  const { idModule } = useParams();
  const [masteryTestPerformance, setMasteryTestPerformance] = useState({
    grammarsGood: [],
    grammarsToImprove: [],
  });
  const [containerChildrenHeight, setContainerChildrenHeight] = useState(0);
  const currentLanguage = locales.getLanguage();

  useService(getMasteryTestResultPerformance, {
    autoStart: true,
    onData: setMasteryTestPerformance,
    params: {
      module: idModule,
      masteryTest: masteryTest.id,
      masteryTestExecution: masteryTest.executionResult,
    },
  });

  const unitsByGrammarId = useSelector(
    getUnitsByGrammarsIdsAndModuleIdAndGroup(
      map(masteryTestPerformance.grammarsToImprove, "id"),
      masteryTest.module,
      masteryTest.group
    )
  );

  const grammars = useMemo(() => {
    return unitsByGrammarId.map(({ grammarId, units }) => {
      const grammar = find(masteryTestPerformance.grammarsToImprove, ["id", grammarId]);
      return {
        grammar: {
          ...grammar,
          description:
            grammar.description.find(({ locale }) => locale === currentLanguage)?.value ||
            grammar.description.find(({ locale }) => locale === "en").value,
        },
        units,
      };
    });
  }, [unitsByGrammarId, masteryTestPerformance, currentLanguage]);

  const handleUnitClick = useCallback(
    (unitId) => {
      history.replace(`${match.url}/${unitId}/exercise`);
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "UnitButton",
        action: `Navigate to ${match.url}/${unitId}/exercise`,
      });
    },
    [history, match]
  );

  return (
    <Container
      $height={expanded ? containerChildrenHeight + 40 : 0}
      ref={(ref) => {
        if (ref) {
          const childrenHeight = Array.prototype.slice.call(ref.children).reduce((acc, el) => {
            acc += el.clientHeight;
            return acc;
          }, 0);
          setContainerChildrenHeight(childrenHeight);
        }
      }}
    >
      <FlexColumn padding="20px 20px 20px 10px">
        <FlexRow padding="5px 0px 5px 0px" borderBottom="1px solid #DE5A67" margin="0px 0px 10px 20px">
          <TranslatedText
            as="span"
            translateKey="masteryTest.recommendedUnits"
            style={{ fontSize: 15, fontWeight: 400, color: "#424242" }}
          />
        </FlexRow>
        <FlexRow>
          {grammars.map(({ grammar, units }) => (
            <FlexRow key={grammar.id} alignItems="baseline" flexBasis={grammar.length > 0 ? "50%" : "100%"}>
              <FlexColumn flexBasis="50px" alignItems="center">
                <Bullet />
              </FlexColumn>
              <FlexColumn flexBasis="100%" gap={10}>
                <span style={{ fontWeight: 700, fontSize: 16 }}>{grammar.description}</span>
                {units.map((unit) => (
                  <MasteryTestListItemRecommendedUnitsListItem
                    unit={unit}
                    completed={
                      !!unit.lastExecutionCompletedAt &&
                      moment(unit.lastExecutionCompletedAt).isAfter(masteryTest.failedAt)
                    }
                    onClick={handleUnitClick}
                  />
                ))}
              </FlexColumn>
            </FlexRow>
          ))}
        </FlexRow>
      </FlexColumn>
    </Container>
  );
};

MasteryTestListItemRecommendedUnitsList.propTypes = {
  masteryTest: PropTypes.shape({
    id: PropTypes.string,
    module: PropTypes.string,
  }).isRequired,
  expanded: PropTypes.bool,
};

MasteryTestListItemRecommendedUnitsList.defaultProps = {
  expanded: false,
};

export default withRouter(MasteryTestListItemRecommendedUnitsList);
