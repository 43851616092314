import React from "react";
import PropTypes from "prop-types";

export default class FetchData extends React.PureComponent {
  static propTypes = {
    service: PropTypes.func.isRequired,
    params: PropTypes.shape({}),
  };

  static defaultProps = {
    params: {},
  };

  state = {
    isFetching: true,
    hasError: false,
    data: null,
  };

  async componentDidMount() {
    try {
      const data = await this.props.service(this.props.params);

      this.setState({
        data: data,
        error: null,
        hasError: false,
        isFetching: false,
      });
    } catch (error) {
      this.setState({
        data: null,
        error: error.message,
        hasError: true,
        isFetching: false,
      });
    }
  }

  render() {
    return this.props.children({
      data: this.state.data,
      error: this.state.error,
      isFetching: this.state.isFetching,
      hasError: this.state.hasError,
    });
  }
}
