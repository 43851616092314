import axios from "axios";
import { getEndpointUrl } from "../config";

export const userErrorReportService = async (data) => {
  return axios({
    url: `${getEndpointUrl().replace("/v1", "")}/public/error-report`,
    method: "post",
    headers: {
      "Content-Type":" multipart/form-data",
    },
    data,
  });
};
