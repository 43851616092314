import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import get from "lodash/get";
import { connect } from "react-redux";
import ScoreLike from "../../images/score-like.png";
import ScoreTrophy from "../../images/score-trophy.png";
import ScoreTarget from "../../images/score-target.png";
import ScoreItem from "./ScoreItem";
import withTranslation from "../../core/withTranslation";

const ScoreItemContainer = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ScoreItem
        iconSrc={ScoreLike}
        title="placementTest.placementResultPoint"
        value={numeral(get(props.reachedLevel, "level", 0)).format("0.0").replace(",", ".")}
      />
      <ScoreItem
        iconSrc={ScoreTrophy}
        title="placementTest.placementResultEnglishLevel"
        value={get(props.reachedLevel, "course.name", props.getTranslation("placementTest.beginner"))}
      />

      <ScoreItem
        iconSrc={ScoreTarget}
        title="placementTest.placementResultCorrects"
        value={get(props, "answers", [])
          .filter((answer) => answer.correct)
          .length.toString()}
        secondaryValue={get(props, "answers", []).length.toString()}
      />
    </div>
  );
};

ScoreItemContainer.propTypes = {
  reachedLevel: PropTypes.shape({
    level: PropTypes.number.isRequired,
    course: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  reachedLevel: state.placementTestResult.reachedLevel,
  answers: state.placementTestResult.answeredItems,
});

export default connect(mapStateToProps, null)(withTranslation(ScoreItemContainer));
