import Styled from "styled-components";

const PracticeTestHeader = Styled.div`
width: 100%;
height: 350px;
background-color: ${(props) => props.color};
box-sizing: border-box;
position: absolute;
top: 50px;
box-shadow: 0px 1px 8px 0 rgba(0, 0, 0, 0.5);
z-index: -1;
`;

export default PracticeTestHeader;
