import moment from "moment";
import { useState } from "react";
import { useService } from "student-front-commons/src/hooks";
import { getBonusTestHistory } from "student-front-commons/src/services/bonusTestService";
import { GREY_2, GREY_4 } from "../consts/color";
import Async from "./Async";
import FlexRow from "./FlexRow";
import { isNil, orderBy } from "lodash";
import TranslatedText from "./TranslatedText";
import Tooltip from "./Tooltip";
import Icon from "./Icon";
import withTranslation from "./withTranslation";
import RankingParticipantContainer from "./RankingParticipantContainer";

function BonusTestHistory(props) {
  const [history, setHistory] = useState([]);
  const [isFetching] = useService(getBonusTestHistory, {
    autoStart: true,
    onData: (data) => setHistory(data),
  });

  const borderColorByPosition = { 3: "#2ba98c", 2: "#27defb", 1: "#da073a" };

  return (
    <div
      style={{
        width: "100%",
        height: "350px",
        borderRadius: "8px",
        padding: "20px",
      }}
    >
      <Async fetching={isFetching}>
        <TranslatedText
          as="span"
          translateKey="bonusTest.history"
          style={{
            fontSize: "16px",
            borderBottom: `1px solid ${GREY_4} `,
            paddingBottom: "10px",
            display: "block",
            fontWeight: 700,
          }}
        />
        <div style={{ marginTop: "20px", overflowY: "auto", height: "700px" }}>
          {orderBy(history, ["startedAt"], ["desc"]).map((item, index, array) => {
            const correctAnswers = item.answeredItems.filter((ai) => ai.correct).length;

            const previousItemCorrectAnswers = array
              .find((arrayItem, arrayItemIndex) => !isNil(arrayItem.completedAt) && arrayItemIndex > index)
              ?.answeredItems.filter((ai) => ai.correct).length;
            const diffToPrevious =
              previousItemCorrectAnswers !== undefined ? correctAnswers - previousItemCorrectAnswers : 0;
            const badgeColor = { 0: "#DB1B1B", 1: "#0FB1A2" }[Number(diffToPrevious > 0)];

            return (
              <div key={`history-${item.id}`}>
                <FlexRow
                  alignItems="center"
                  backgroundColor="#fff"
                  minHeight="48px"
                  maxHeight="80px"
                  marginBottom="8px"
                  padding="12px"
                  fontSize="14px"
                  borderRadius="4px"
                  borderTop={`1px solid ${GREY_2}`}
                  borderBottom={`1px solid ${GREY_2}`}
                  borderLeft={`1px solid ${GREY_2}`}
                  borderRight={
                    item.studentAchievement
                      ? `4px solid ${borderColorByPosition[item.studentAchievement.position]}`
                      : `4px solid ${GREY_2}`
                  }
                >
                  <FlexRow alignItems="center" marginRight="16px">
                    <span style={{ color: "#989898" }}>
                      {item.completedAt
                        ? moment(item.completedAt).format("DD/MM/YYYY")
                        : moment(item.startedAt).format("DD/MM/YYYY")}
                    </span>
                  </FlexRow>
                  {item.completedAt && (
                    <TranslatedText
                      as="span"
                      translateKey="bonusTest.correctAnswers"
                      values={{ correctAnswers }}
                      style={{
                        fontSize: "14px",
                        lineHeight: "14px",
                        marginRight: "16px",
                        width: "33%",
                      }}
                    />
                  )}
                  <FlexRow alignItems="center" width={!item.completedAt ? "100%" : "20%"}>
                    {item.type === "PLACEMENT" && (
                      <TranslatedText
                        as="span"
                        translateKey="placementTest.placement"
                        style={{
                          fontWeight: 700,
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#424242",
                          opacity: 0.5,
                        }}
                      />
                    )}
                    {item.type === "BONUS" ? (
                      item.completedAt ? (
                        item.studentAchievement ? (
                          <RankingParticipantContainer>
                            {item.studentAchievement.points > 0 ? (
                              <TranslatedText
                                translateKey="bonusTest.points"
                                values={{ points: item.studentAchievement.points }}
                                style={{ fontWeight: 700, fontSize: "14px", lineHeight: "14px" }}
                              />
                            ) : (
                              <TranslatedText
                                as="span"
                                translateKey="bonusTest.noPoints"
                                style={{
                                  fontWeight: 700,
                                  fontSize: "14px",
                                  lineHeight: "14px",
                                  color: "#424242",
                                  opacity: 0.5,
                                }}
                              />
                            )}
                          </RankingParticipantContainer>
                        ) : (
                          <RankingParticipantContainer>
                            <TranslatedText
                              as="span"
                              translateKey="bonusTest.noBonus"
                              style={{
                                fontWeight: 700,
                                fontSize: "14px",
                                lineHeight: "14px",
                                color: "#424242",
                                opacity: 0.5,
                              }}
                            />
                          </RankingParticipantContainer>
                        )
                      ) : (
                        <TranslatedText
                          as="span"
                          translateKey="bonusTest.history.notCompleted"
                          style={{ fontSize: "14px", lineHeight: "14px" }}
                        />
                      )
                    ) : null}
                  </FlexRow>
                  <FlexRow width="15%" marginLeft="16px" alignItems="center" justifyContent="center">
                    {diffToPrevious !== 0 && item.completedAt && array[index + 1]?.completedAt && (
                      <Tooltip text={props.getTranslation("bonusTest.historyDiff")}>
                        <FlexRow
                          alignItems="center"
                          justifyContent="space-between"
                          width="44px"
                          height="24px"
                          borderRadius="4px"
                          backgroundColor={`${badgeColor}10`}
                          padding="0 4px 0 6px"
                        >
                          <Icon icon={`arrow-${diffToPrevious > 0 ? "up" : "down"}`} color={badgeColor} size="xxs" />
                          <span
                            style={{
                              color: badgeColor,
                              fontSize: "12px",
                              fontWeight: 700,
                              width: "16px",
                              textAlign: "center",
                            }}
                          >
                            {Math.abs(diffToPrevious)}
                          </span>
                        </FlexRow>
                      </Tooltip>
                    )}
                  </FlexRow>
                  <FlexRow width="24px" alignItems="center" justifyContent="center" marginLeft="16px">
                    {item.studentAchievement ? (
                      <img
                        src={`${process.env.REACT_APP_FILES_URL}/${
                          item.studentAchievement.achievement.iconByPosition.find(
                            (icon) => icon.position === item.studentAchievement.position
                          ).icon
                        }
                          `}
                        style={{ width: "24px" }}
                      />
                    ) : (
                      <div />
                    )}
                  </FlexRow>
                </FlexRow>
              </div>
            );
          })}
        </div>
      </Async>
    </div>
  );
}

export default withTranslation(BonusTestHistory);
