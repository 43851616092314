import React from "react";
import BonusTestItemExecutionForm from "./BonusTestItemExecutionForm";
import { SAVE_BONUS_TEST_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";

export default function BonusTestItemExecutionFormContainer() {
  const [isPending, startFlow] = useFlow(SAVE_BONUS_TEST_ITEM_EXECUTION_ANSWER_FLOW);

  return <BonusTestItemExecutionForm onSubmit={startFlow} isSubmitting={!!isPending} />;
}
