/**
 * @class ValidationError
 */
export default class ValidationError extends Error {
  constructor(errors) {
    super(errors);
    this.errors = errors;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
