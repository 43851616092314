import * as Sentry from "@sentry/browser";
import jwtDecode from "jwt-decode";
import moment from "moment";
import numeral from "numeral";
import LogRocket from "logrocket";
import get from "lodash/get";
import head from "lodash/head";
import isFinite from "lodash/isFinite";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getFlowStart, getFlowEnd } from "student-front-commons/src/selectors/flow";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getCourses } from "student-front-commons/src/services/courseService";
import browserHistory from "../browserHistory";
import locales from "../locales";
import {
  LOAD_CONFIGURATION_FLOW,
  LOAD_LOCALIZED_LABELS_FLOW,
  LOAD_STUDENT_FLOW,
  MONITOR_NETWORK_CONNECTION_FLOW,
} from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_STUDENT_FLOW), function* () {
    try {
      if (!sessionStorage.getItem("accessToken")) {
        sessionStorage.clear();
        browserHistory.push("/login");
      }

      const id = sessionStorage.getItem("id");
      const decodedToken = jwtDecode(sessionStorage.getItem("accessToken"));
      sessionStorage.setItem("locale", decodedToken.locale);

      yield put(startFlow(MONITOR_NETWORK_CONNECTION_FLOW));
      yield put(startFlow(LOAD_LOCALIZED_LABELS_FLOW, { locale: head(decodedToken.locale.split("-")) }));
      yield take(getFlowEnd(LOAD_LOCALIZED_LABELS_FLOW));

      const profileResult = yield call(getProfile);
      yield put(mergeEntities(profileResult.entities));

      yield put(startFlow(LOAD_CONFIGURATION_FLOW));

      const courses = yield call(getCourses, {
        id,
      });
      yield put(mergeEntities(courses.entities));

      const profile = yield select(getEntityById("profile", id));
      const schoolClass = yield select(getEntityById("schoolClass", profile.schoolClass));
      const school = yield select(getEntityById("school", schoolClass?.school));
      const company = yield select(getEntityById("company", profile.company));

      if (profile.currentEnglishLevel >= 1.5) {
        locales.setLanguage("en");
        moment.locale("en");
        numeral.locale("en-au");
      } else {
        locales.setLanguage(head(decodedToken.locale.split("-")));
        if (moment.locales().some((locale) => locale === decodedToken.locale)) {
          moment.locale(decodedToken.locale);
        } else {
          moment.locale("en");
        }

        if (get(numeral.locales, decodedToken.locale, false)) {
          numeral.locale({ pt: "pt-br", es: "es", en: "en-au" }[decodedToken.locale] || "en-au");
        } else {
          numeral.locale("en-au");
        }
      }

      Sentry.setUser({
        email: profile.email,
        id: profile.id,
        company: company?.name,
        school: school?.name,
        classroom: schoolClass?.name,
      });

      if (process.env.REACT_APP_LOG_ROCKET_KEY) {
        LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);

        LogRocket.identify(sessionStorage.getItem("id"), {
          name: profile.name,
          email: profile.email,
          company: company?.name,
          school: school?.name,
          classroom: schoolClass?.name,
        });
      }

      if (!isFinite(profile.initialEnglishLevel)) {
        yield call(browserHistory.replace, "/placement");
      } else if (profile.isPlacementTestOnly && isFinite(profile.initialEnglishLevel)) {
        sessionStorage.clear();
        yield call(browserHistory.replace, "/login");
      } else if (!profile.profileCompleted) {
        yield call(browserHistory.replace, "/complete-profile");
      }

      window.dispatchEvent(new Event("watch-devtools"));
    } catch (error) {
      logError({ error, flow: LOAD_STUDENT_FLOW });
    } finally {
      yield put(endFlow(LOAD_STUDENT_FLOW));
    }
  });
}
