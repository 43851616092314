import React from "react";
import { connect } from "react-redux";
import CertificationTestFinishedPanel from "./CertificationTestFinishedPanel";

const CertificationTestFinishedPanelContainer = (props) => props.isFinished && <CertificationTestFinishedPanel />;

const mapStateToProps = (state) => ({
  isFinished: !!state.certificationTestResult.finishedAt,
});

export default connect(mapStateToProps)(CertificationTestFinishedPanelContainer);
