import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const ScoreRuler = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <div>
        <Separator size="lg" />
        <TranslatedText
          as="p"
          translateKey="placementTest.placementRulerTitle"
          style={{
            color: "rgb(66, 66, 66)",
            fontSize: 16,
            lineHeight: "24px",
            margin: 0,
          }}
        />
        <Separator size="sm" />
        <ul
          style={{
            position: "relative",
            listStyle: "none",
            padding: 0,
            margin: 0,
          }}
        >
          <div
            style={{
              width: "100%",
              height: 2,
              borderRadius: 1,
              backgroundColor: "rgba(95, 95, 95, .7)",
              position: "absolute",
              top: 29,
            }}
          />
          <div
            style={{
              width: `${props.reachedLevel / 0.06}%`,
              height: 4,
              borderRadius: 1,
              backgroundColor: primaryColor,
              position: "absolute",
              top: 28,
              transition: "all 1s",
            }}
          >
            <span
              style={{
                width: 18,
                height: 18,
                borderRadius: "100%",
                border: `4px solid ${primaryColor}`,
                backgroundColor: secondaryColor,
                position: "absolute",
                top: -7,
                right: -10,
                zIndex: 1,
              }}
            />
          </div>
          {props.levels.map((placementLevel, index) => (
            <li
              key={`level-${placementLevel.id}`}
              style={{
                display: "inline-block",
                position: "relative",
                width: index + 1 === props.levels.length ? 0 : 105,
                height: 80,
              }}
            >
              {(placementLevel.level === 0 || placementLevel.level === 4 || placementLevel.level === 6) && (
                <label
                  htmlFor="level"
                  style={{
                    fontSize: 14,
                    color: "rgba(95, 95, 95, .6)",
                    lineHeight: "24px",
                    position: "absolute",
                    top: -3,
                    left: -9,
                    margin: 0,
                  }}
                >
                  {placementLevel.level}.0
                </label>
              )}
              <span
                style={{
                  borderRadius: 1,
                  backgroundColor: props.reachedLevel >= placementLevel.level ? primaryColor : "rgba(95, 95, 95, .7)",
                  height: 20,
                  width: 2,
                  position: "absolute",
                  left: 0,
                  top: 20,
                }}
              />
              <label
                key={`step-course-${placementLevel.id}`}
                htmlFor="courseName"
                style={{
                  color: props.reachedLevel >= placementLevel.level ? primaryColor : "rgba(95, 95, 95, .6)",
                  fontWeight: props.reachedLevel >= placementLevel.level ? "bold" : "normal",
                  fontSize: 14,
                  lineHeight: "24px",
                  textTransform: "uppercase",
                  position: "absolute",
                  top: 40,
                  left: -7,
                  margin: 0,
                }}
              >
                {index > 0 ? placementLevel.course?.name : "PA1"}
              </label>
              {index > 0 && placementLevel.course?.name === "B2" && (
                <TranslatedText
                  as="label"
                  translateKey="placementTest.englishFluency"
                  style={{
                    color: props.reachedLevel >= placementLevel.level ? primaryColor : "rgba(95, 95, 95, .8)",
                    fontWeight: "bold",
                    fontSize: 11,
                    lineHeight: "11px",
                    position: "absolute",
                    width: 50,
                    top: 65,
                    left: -24,
                    margin: 0,
                  }}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

ScoreRuler.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      level: PropTypes.number,
      course: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  reachedLevel: PropTypes.number.isRequired,
};

export default ScoreRuler;
