import { put, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { clearAudios } from "../stores/audio-store";
import { clearImages } from "../stores/image-store";
import { CLOSE_BONUS_TEST_EXECUTION_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(CLOSE_BONUS_TEST_EXECUTION_FLOW), function* () {
    try {
      clearAudios();
      clearImages();
    } catch (error) {
      logError({ error, flow: CLOSE_BONUS_TEST_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(CLOSE_BONUS_TEST_EXECUTION_FLOW));
    }
  });
}
