import React from "react";
import get from "lodash/get";
import numeral from "numeral";
import ScoreItem from "../ScoreItem";
import ScoreLike from "../../../images/score-like.png";
import ScoreTrophy from "../../../images/score-trophy.png";
import ScoreTarget from "../../../images/score-target.png";
import withTranslation from "../../../core/withTranslation";

const OldScoreSection = (props) => (
  <div
    style={{
      borderRadius: 5,
      backgroundColor: "#4242421A",
      padding: "20px 55px",
      width: 740,
      margin: "0 auto",
    }}
  >
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <ScoreItem
        iconSrc={ScoreLike}
        title="placementTest.placementResultPoint"
        value={numeral(get(props.placementTest, "reachedLevel.level", 0)).format("0.0").replace(",", ".")}
        intent="gray"
        size="sm"
      />
      <ScoreItem
        iconSrc={ScoreTrophy}
        title="placementTest.placementResultEnglishLevel"
        value={get(props.placementTest, "reachedLevel.course.name", props.getTranslation("placementTest.beginner"))}
        intent="gray"
        size="sm"
      />
      <ScoreItem
        iconSrc={ScoreTarget}
        title="placementTest.placementResultCorrects"
        value={get(props.placementTest, "answeredItems", [])
          .filter((answer) => answer.correct)
          .length.toString()}
        secondaryValue={get(props.placementTest, "answeredItems", []).length.toString()}
        intent="gray"
        size="sm"
      />
    </div>
  </div>
);

export default withTranslation(OldScoreSection);
