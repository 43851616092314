import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import MediaQuery from "react-responsive";
import FlexRow from "./FlexRow";
import Icon from "./Icon";
import Separator from "./Separator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_5 } from "../consts/color";

export default class CountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      pause: true,
    };
  }

  static propTypes = {
    delay: PropTypes.number,
    isDisabled: PropTypes.bool,
    time: PropTypes.number.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
  };

  static defaultProps = {
    delay: 0,
    isDisabled: true,
    isMounted: false,
  };

  timerInterval = null;
  timerTimeout = null;

  handleClearTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.timerTimeout) {
      clearTimeout(this.timerTimeout);
    }
  };

  handleCreateTimer = () => {
    if (!this.state.isMounted) {
      return;
    }
    this.handleClearTimer();
    this.setState({
      time: this.props.time,
      pause: true,
    });

    this.timerTimeout = setTimeout(() => {
      this.setState(
        {
          pause: false,
        },
        () => {
          this.timerInterval = setInterval(this.handleInterval, 1000);
        }
      );
    }, this.props.delay);
  };

  handleInterval = () => {
    if (!this.state.isMounted) {
      return;
    }
    this.setState(
      {
        pause: this.props.isDisabled,
        time: this.state.time - (this.props.isDisabled ? 0 : 1),
      },
      () => {
        if (this.state.time <= 0) {
          this.handleCreateTimer();
          if (!this.state.pause) {
            this.props.onTimerEnd();
          }
        }
      }
    );
  };

  componentDidMount() {
    this.setState(
      {
        isMounted: true,
      },
      () => {
        this.handleCreateTimer();
      }
    );
  }

  componentWillUnmount() {
    this.handleClearTimer();
    this.setState({
      isMounted: false,
    });
  }

  render() {
    return (
      <MediaQuery maxWidth={540}>
        {(matches) => (
          <WhitelabelContext.Consumer>
            {({ primaryColor }) => (
              <FlexRow
                alignItems="center"
                justifyContent="center"
                padding={matches ? "0px 15px" : "0px 10px"}
                transition="all .3s"
                borderRadius={3}
                backgroundColor={this.state.time <= 5 && this.state.isMounted ? "#f54f4f" : "#eceff1"}
              >
                <Icon
                  className={
                    this.state.time <= 5 && this.state.isMounted
                      ? "animate__animated animate__shake animate__infinite"
                      : ""
                  }
                  icon={this.state.pause ? "pause" : "clock"}
                  color={this.state.time <= 5 && this.state.isMounted ? "#fff" : GREY_5}
                  size={matches ? "sm" : "md"}
                />
                <Separator size="xs" />
                <span
                  style={{
                    color: this.state.time <= 5 && this.state.isMounted ? "#fff" : primaryColor,
                    fontSize: matches ? 18 : 26,
                    fontWeight: 700,
                  }}
                >
                  {moment.duration(this.state.time, "seconds").format("mm:ss", { trim: false })}
                </span>
              </FlexRow>
            )}
          </WhitelabelContext.Consumer>
        )}
      </MediaQuery>
    );
  }
}
