import React, { useEffect, useRef } from "react";
import { Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import isFinite from "lodash/isFinite";
import Async from "./core/Async";
import AnimatedRoute from "./core/AnimatedRoute";
import PracticeTestSceneContainer from "./screens/placement-test/practice/PracticeTestSceneContainer";
import PracticeTestScoreSceneContainer from "./screens/placement-test/practice-score/PracticeTestScoreSceneContainer";
import PlacementTestSceneContainer from "./screens/placement-test/placement/PlacementTestSceneContainer";
import UnitResultSceneContainer from "./screens/unit-result/UnitResultSceneContainer";
import AccountScene from "./screens/account/AccountScene";
import PlacementTestScoreSceneContainer from "./screens/placement-test/placement-score/PlacementTestScoreSceneContainer";
import TestIntroductionSceneContainer from "./screens/placement-test/introduction/TestIntroductionSceneContainer";
import ProfileScene from "./screens/profile/ProfileScene";
import NotFoundScene from "./core/NotFoundScene";
import RankingScene from "./screens/ranking/RankingScene";
import CertificationTestAbilitiesSceneContainer from "./screens/certification-test/CertificationTestAbilitiesSceneContainer";
import CertificationTestExecutionSceneContainer from "./screens/certification-test/CertificationTestExecutionSceneContainer";
import MicrophoneConfigSceneContainer from "./screens/microphone-config/MicrophoneConfigSceneContainer";
import HeaderContainer from "./core/HeaderContainer";
import HomeScene from "./screens/home/HomeScene";
import CourseThemeProvider from "./core/CourseThemeProvider";
import HistoryScene from "./screens/history/HistoryScene";
import GradeSceneContainer from "./screens/grades/GradeSceneContainer";
import UnitExecutionSceneContainer from "./screens/unit-execution/UnitExecutionSceneContainer";
import MasteryTestExecutionSceneContainer from "./screens/mastery-test-execution/MasteryTestExecutionSceneContainer";
import MasteryTestResultScene from "./screens/mastery-test-result/MasteryTestResultScene";
import CourseSummaryScene from "./screens/course-summary/CourseSummaryScene";
import StudyQualityStatsModalContainer from "./core/study-quality-stats/StudyQualityStatsModalContainer";
import CertificationTestResultSceneContainer from "./screens/certification-test-result/CertificationTestResultSceneContainer";
import { LOAD_MODULES_FLOW, LOAD_STUDENT_FLOW } from "./consts";
import ClosedRankingScene from "./screens/closed-ranking/ClosedRankingScene";
import NotificationToastContainer from "./core/notification/NotificationToastContainer";
import HomeSceneB2C from "./screens/home/HomeScene.b2c";
import CheckDistributorType from "./core/CheckDistributorType";
import SceneVideoIntroductionMenuContainer from "./core/SceneVideoIntroductionMenuContainer";
import CompleteProfileScene from "./screens/complete-profile/CompleteProfileScene";
import TestCompleteProfileScene from "./screens/placement-test/complete-profile/TestCompleteProfileScene";
import LevelSelectSceneContainer from "./screens/placement-test/level-select/LevelSelectSceneContainer";
import PlacementTestScoreAnalysisSceneContainer from "./screens/placement-test/placement-test-score-analysis/PlacementTestScoreAnalysisSceneContainer";
import { useEntity, useFlow, useService } from "student-front-commons/src/hooks";
import UnitListScene from "./screens/unit-list/UnitListScene";
import BonusTestIntroductionScene from "./screens/bonus-test-introduction/BonusTestIntroductionScene";
import BonusTestSceneContainer from "./screens/bonus-test/BonusTestSceneContainer";
import BonusTestResultSceneContainer from "./screens/bonus-test-result/BonusTestResultSceneContainer";
import PlacementTestInviteModalContainer from "./core/PlacementTestInviteModalContainer";
import SurveyModalContainer from "./core/SurveyModalContainer";
import ContentVideoUnitExecutionSceneContainer from "./screens/unit-execution/content-video/ContentVideoUnitExecutionSceneContainer";
import ModuleContentVideoListScene from "./screens/module-list/ModuleContentVideoListScene";
import ModuleListScene from "./screens/module-list/ModuleListScene";
import { updateOnlineTime } from "student-front-commons/src/services/profileService";

function CourseRoutes({ isContentVideoTabAllowed }) {
  const { path } = useRouteMatch();
  const { idCourse } = useParams();

  const [isLoadingModules] = useFlow(LOAD_MODULES_FLOW, {
    autoStart: true,
    params: { course: idCourse },
  });

  if (isLoadingModules) {
    return (
      <Async fetching={true}>
        <div />
      </Async>
    );
  }

  return (
    <Switch>
      <AnimatedRoute exact key="modules" path={`${path}/modules`} component={ModuleListScene} />
      {isContentVideoTabAllowed && (
        <AnimatedRoute exact key="videos" path={`${path}/videos`} component={ModuleContentVideoListScene} />
      )}
      <AnimatedRoute exact key="units" path={`${path}/modules/:idModule/units`} component={UnitListScene} />
      <AnimatedRoute
        exact
        key="unit-execution"
        component={UnitExecutionSceneContainer}
        path={`${path}/modules/:idModule/units/:idUnit/exercise`}
      />
      <AnimatedRoute
        exact
        key="unit-result"
        path={`${path}/modules/:idModule/units/:idUnit/exercise/:idExecution/result`}
        component={UnitResultSceneContainer}
      />
      <AnimatedRoute
        exact
        key="content-video-unit-execution"
        component={ContentVideoUnitExecutionSceneContainer}
        path={`${path}/modules/:idModule/units/:idUnit/content-video-exercise`}
      />
      <AnimatedRoute
        exact
        key="mastery-test"
        component={MasteryTestExecutionSceneContainer}
        path={`${path}/modules/:idModule/mastery-test/:idMasteryTest/execution`}
      />
      <AnimatedRoute
        exact
        key="mastery-test-result"
        path={`${path}/modules/:idModule/mastery-test/:idMasteryTest/execution/:idExecution/result`}
        component={MasteryTestResultScene}
      />
      <AnimatedRoute
        exact
        key="certification-test-abilities"
        path={`${path}/certification-test/:idCertificationTest/abilities`}
        component={CertificationTestAbilitiesSceneContainer}
      />
      <AnimatedRoute
        exact
        key="certification-test-execution"
        path={`${path}/certification-test/:idCertificationTest/abilities/:ability/execution`}
        component={CertificationTestExecutionSceneContainer}
      />
      <AnimatedRoute
        exact
        key="certification-test-result"
        path={`${path}/certification-test/:idCertificationTest/result`}
        component={CertificationTestResultSceneContainer}
      />
      <AnimatedRoute
        exact
        key="bonus-test-introduction"
        path={`${path}/bonus-test/introduction`}
        component={BonusTestIntroductionScene}
      />
      <AnimatedRoute
        exact
        key="bonus-test-execution"
        path={`${path}/bonus-test/execution`}
        component={BonusTestSceneContainer}
      />
      <AnimatedRoute
        exact
        key="bonus-test-execution"
        path={`${path}/bonus-test/result`}
        component={BonusTestResultSceneContainer}
      />
      <AnimatedRoute exact key="course-summary" path={`${path}/summary`} component={CourseSummaryScene} />
      <Route component={NotFoundScene} />
    </Switch>
  );
}

const TWO_AND_A_HALF_MINUTES = 60 * 2500;

function PingOnline() {
  const intervalRef = useRef(null);

  const [, callPatchOnlineTime] = useService(updateOnlineTime, {
    autoStart: false,
  });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      callPatchOnlineTime({ time: TWO_AND_A_HALF_MINUTES / 1000 });
    }, TWO_AND_A_HALF_MINUTES);

    return () => {
      if (intervalRef?.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return null;
}

export default function UserRoutesContainer() {
  const [isLoadingStudent] = useFlow(LOAD_STUDENT_FLOW, { autoStart: true });

  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const schoolClass = useEntity("schoolClass", profile?.schoolClass);
  const school = useEntity("school", schoolClass?.school);
  const company = useEntity("company", profile?.company);

  return (
    <Async fetching={isLoadingStudent}>
      <CourseThemeProvider>
        <PingOnline />
        <HeaderContainer />
        {isFinite(profile?.initialEnglishLevel) && (
          <React.Fragment>
            <StudyQualityStatsModalContainer />
            <NotificationToastContainer />
            <SceneVideoIntroductionMenuContainer />
            <SurveyModalContainer />
          </React.Fragment>
        )}
        <PlacementTestInviteModalContainer />
        <CheckDistributorType>
          {({ isB2C }) => (
            <Switch>
              <AnimatedRoute exact path="/" component={isB2C ? HomeSceneB2C : HomeScene} />
              <Route path="/course/:idCourse">
                <CourseThemeProvider>
                  <CourseRoutes isContentVideoTabAllowed={company?.allowContentVideoTab} />
                </CourseThemeProvider>
              </Route>
              <AnimatedRoute exact key="profile" path="/profile" component={ProfileScene} />
              <AnimatedRoute exact key="account" path="/account" component={AccountScene} />
              <AnimatedRoute exact key="complete-profile" path="/complete-profile" component={CompleteProfileScene} />
              <AnimatedRoute
                exact
                key="placement-test-complete-profile"
                path="/placement-test-complete-profile"
                component={TestCompleteProfileScene}
              />
              <AnimatedRoute
                exact
                key="placementtestscore"
                path="/placement-test-score"
                component={PlacementTestScoreSceneContainer}
              />
              <AnimatedRoute exact key="grades" path="/grades" component={GradeSceneContainer} />
              <AnimatedRoute exact key="history" path="/history" component={HistoryScene} />
              <AnimatedRoute exact key="ranking" path="/rankings" component={RankingScene} />
              <AnimatedRoute
                exact
                key="closed-ranking"
                path="/rankings/:period/closed"
                component={ClosedRankingScene}
              />
              <AnimatedRoute
                exact
                key="microphone-test"
                path="/microphone-test"
                component={MicrophoneConfigSceneContainer}
              />
              <AnimatedRoute exact key="levelselect" path="/level-select" component={LevelSelectSceneContainer} />
              <AnimatedRoute
                exact
                key="practicetestintroduction"
                path="/practice-test-introduction"
                component={TestIntroductionSceneContainer}
              />
              <AnimatedRoute
                exact
                key="practicetest"
                path="/practice-test"
                component={PracticeTestSceneContainer}
                prompt={(nextLocation) =>
                  (nextLocation.pathname !== "/practice-test-score" &&
                    "You will lose your progress. Are you sure want to leave?") ||
                  true
                }
              />
              <AnimatedRoute
                exact
                key="practicetestscore"
                path="/practice-test-score"
                component={PracticeTestScoreSceneContainer}
              />
              <AnimatedRoute exact key="placementtest" path="/placement-test" component={PlacementTestSceneContainer} />
              <AnimatedRoute
                exact
                key="placementtestscore"
                path="/placement-test-score"
                component={PlacementTestScoreSceneContainer}
              />
              <AnimatedRoute
                exact
                key="placementtestscoreanalysis"
                path="/placement-test-score-analysis"
                component={PlacementTestScoreAnalysisSceneContainer}
              />
              {!isFinite(profile?.initialEnglishLevel) ? (
                <Redirect
                  to={
                    profile?.isPlacementTestOnly ||
                    (profile.apiVersion === "V2" ? !company?.allowLevelSelection : !school?.allowLevelSelection)
                      ? "/practice-test-introduction"
                      : "/level-select"
                  }
                />
              ) : (
                <Route component={NotFoundScene} />
              )}
            </Switch>
          )}
        </CheckDistributorType>
      </CourseThemeProvider>
    </Async>
  );
}
