import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Styled from "styled-components";
import color from "color";
import isFinite from "lodash/isFinite";
import toInteger from "lodash/toInteger";
import range from "lodash/range";
import Star from "../../images/star-sq.png";
import EmptyStar from "../../images/empty-star-sq.png";
import Check from "../../images/icon-sq.png";
import TranslatedText from "../TranslatedText";

const StatsDescriptionItem = Styled.div`
  padding: 10px 20px 10px 50px;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 450px;
  position: relative;
  font-size: 15px;
  color: #fff;
  height: 52px;
  
  &:after{
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    left: 100%;
    top: -2px;
    border: 28px solid transparent;
    border-left: 22px solid ${(props) => props.color};
    margin-left: -1px;
}
`;

const getScoreByType = (item) => {
  const getScore = (divisor) => {
    if (isFinite(item.secondScore) && isFinite(item.thirdScore)) {
      return toInteger(
        (item.firstScore * 2.5) / divisor +
          ((item.secondScore || 0) * 1.6) / divisor +
          ((item.thirdScore || 0) * 0.9) / divisor
      );
    }
    return toInteger((item.firstScore / divisor) * 5);
  };

  if (
    ["DAYS_STUDIED", "DAYS_STUDIED_AT_LEAST_30_MINUTES", "UNIT_AVERAGE_SCORE", "TIME_STUDIED"].find(
      (type) => type === item.requirement
    )
  ) {
    return getScore(3);
  } else if (
    ["SINGLE_CHOICE_AVERAGE_SCORE", "SPEECH_RECOGNITION_AVERAGE_SCORE", "LISTEN_USAGE"].find(
      (type) => type === item.requirement
    )
  ) {
    return getScore(2);
  } else if (item.requirement === "REPEAT_USAGE") {
    return getScore(4);
  }
  return null;
};

const StatsListItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <li
        style={{
          display: "flex",
          overflow: "hidden",
          flexWrap: "wrap",
        }}
      >
        <StatsDescriptionItem color={props.odd ? secondaryColor : primaryColor}>
          {getScoreByType(props.statsItem) === 5 && (
            <img
              alt="icon-check"
              src={Check}
              style={{
                position: "absolute",
                left: 15,
              }}
            />
          )}
          <TranslatedText translateKey={`studyQuality.requirements.${props.statsItem.requirement}`} />
        </StatsDescriptionItem>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            backgroundColor: color(props.odd ? secondaryColor : primaryColor).fade(0.75),
            padding: "10px 20px 10px 30px",
          }}
        >
          {range(5).map((index) => {
            const score = getScoreByType(props.statsItem);
            return (
              <img
                key={`start-${index}`}
                alt="sq-icon"
                src={score <= index ? EmptyStar : Star}
                style={{
                  marginLeft: 10,
                  opacity: score <= index ? 0.3 : 1,
                }}
              />
            );
          })}
        </div>
      </li>
    )}
  </WhitelabelContext.Consumer>
);

StatsListItem.propTypes = {
  odd: PropTypes.bool.isRequired,
  statsItem: PropTypes.shape({
    requirement: PropTypes.string,
    firstScore: PropTypes.number,
    secondScore: PropTypes.number,
    thirdScore: PropTypes.number,
  }).isRequired,
};

export default StatsListItem;
