import React, { Component } from "react";
import get from "lodash/get";
import queryString from "query-string";
import Flow from "student-front-commons/src/components/Flow";
import DefaultThemeProvider from "../../../core/DefaultThemeProvider";
import Async from "../../../core/Async";
import PracticeTestScene from "./PracticeTestScene";
import { START_PRACTICE_TEST_EXECUTION_FLOW } from "../../../consts";

class PracticeTestSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={true}
        id={START_PRACTICE_TEST_EXECUTION_FLOW}
        params={{
          isRetry: !!get(queryString.parse(this.props.location.search), "retry", false),
        }}
      >
        {({ isPending }) => (
          <DefaultThemeProvider>
            <Async fetching={isPending}>
              <PracticeTestScene />
            </Async>
          </DefaultThemeProvider>
        )}
      </Flow>
    );
  }
}

export default PracticeTestSceneContainer;
