import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import Button from "../../core/Button";
import withTranslation from "../../core/withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const SpeechRecognitionTestResult = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          borderRadius: 5,
          backgroundColor: props.status === "NEED_CONFIG" ? "#f9e9e9" : "#f2f2f2",
          padding: "30px 30px 30px 35px",
          boxSizing: "border-box",
        }}
      >
        <div style={{ width: 365 }}>
          <h1
            style={{
              fontWeight: "bold",
              fontSize: 16,
              color: "#607d8b",
              margin: 0,
            }}
          >
            {props.status === "NEED_CONFIG" && props.getTranslation("microphoneTest.badScoreTitle")}
            {props.status === "SOUNDS_GOOD" && props.getTranslation("microphoneTest.goodScoreTitle")}
          </h1>
          <Separator size="xs" />
          <p
            style={{
              margin: 0,
              fontSize: 14,
              color: "#607d8b",
            }}
          >
            {props.status === "NEED_CONFIG" && (
              <span
                dangerouslySetInnerHTML={{
                  __html: props.getTranslation("microphoneTest.badScoreDescription"),
                }}
              />
            )}
            {props.status === "SOUNDS_GOOD" && (
              <span>{props.getTranslation("microphoneTest.goodScoreDescription")}</span>
            )}
          </p>
        </div>
        {props.status === "NEED_CONFIG" && (
          <div>
            <Button
              style={{
                height: 60,
                width: 260,
                backgroundColor: primaryColor,
                fontSize: 16,
                fontWeight: "bold",
                color: "#fff",
                borderRadius: 5,
                textTransform: "uppercase",
              }}
              onClick={props.onGoToTutorial}
            >
              {props.getTranslation("microphoneTest.button.checkTutorial")}
            </Button>
            <Separator size="md" />
            <Button
              style={{
                width: 260,
                backgroundColor: "transparent",
                fontSize: 16,
                color: "rgba(96, 125, 139, 0.5)",
                border: "none",
                outline: "none",
                boxShadow: "none",
                textTransform: "uppercase",
              }}
              onClick={props.onReset}
            >
              {props.getTranslation("microphoneTest.button.tryAgain")}
            </Button>
          </div>
        )}
        {props.status === "SOUNDS_GOOD" && (
          <Button
            style={{
              height: 60,
              width: 260,
              backgroundColor: primaryColor,
              fontSize: 16,
              fontWeight: "bold",
              color: "#fff",
              borderRadius: 5,
              textTransform: "uppercase",
            }}
            onClick={props.onEnd}
          >
            {props.getTranslation("microphoneTest.button.close")}
          </Button>
        )}
      </div>
    )}
  </WhitelabelContext.Consumer>
);

SpeechRecognitionTestResult.propTypes = {
  status: PropTypes.string.isRequired,
  onGoToTutorial: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
};

export default withTranslation(SpeechRecognitionTestResult);
