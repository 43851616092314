import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import MasteryTestItemExecutionForm from "./MasteryTestItemExecutionForm";
import { SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";

const MasteryTestItemExecutionFormContainer = () => (
  <Flow id={SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW}>
    {({ isPending, handleStart }) => {
      return <MasteryTestItemExecutionForm onSubmit={handleStart} isSubmitting={isPending} />;
    }}
  </Flow>
);

export default MasteryTestItemExecutionFormContainer;
