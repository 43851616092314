import React from "react";
import PropTypes from "prop-types";
import Title from "./Title";
import Separator from "./Separator";
import PositionIcon from "./PositionIcon";
import TranslatedText from "./TranslatedText";

const StudentPosition = (props) => (
  <div>
    {props.showTitle && (
      <>
        <TranslatedText translateKey="ranking.currentSchoolPosition" as={Title} />
        <Separator size="xs" />
      </>
    )}
    {!!props.position && <PositionIcon color={props.color} position={props.position} />}
    {!props.position && "-"}
  </div>
);

StudentPosition.propTypes = {
  position: PropTypes.number,
  showTitle: PropTypes.bool,
  color: PropTypes.string,
};

StudentPosition.defaultProps = {
  position: null,
  showTitle: true,
  color: "#607d8b",
};

export default StudentPosition;
