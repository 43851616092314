import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * delete a scheduled unit review
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.id - the module id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const deleteUnitReview = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(async () => {
    return await request({
      url: `/schedule-unit-reviews/${payload.id}`,
      method: "delete",
      isV2: true,
    });
  });
};
