import FlexRow from "./FlexRow";
import Icon from "./Icon";
import ColumnSeparator from "./ColumnSeparator";
import FlexColumn from "./FlexColumn";
import TranslatedText from "./TranslatedText";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

const AnnouncementsListItem = ({ translateKey }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = useCallback(() => {
    localStorage.setItem(translateKey, "hide");
    setVisible(false);
  }, [translateKey]);

  useEffect(() => {
    if (localStorage.getItem(translateKey) !== "hide") {
      setVisible(true);
    }
  }, [translateKey]);

  if (!visible) {
    return null;
  }
  return (
    <FlexRow
      backgroundColor="rgba(255, 224, 130, 0.7)"
      borderRadius={3}
      minHeight={60}
      padding="5px 15px"
      alignItems="center"
      boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
      marginBottom={20}
      width="100%"
      transform="transition"
    >
      <Icon icon="alert-circle" size="md" color="#b85604" />
      <ColumnSeparator />
      <FlexColumn margin={0} fontSize={14} color="#b85604">
        <TranslatedText as="span" translateKey={translateKey} />
      </FlexColumn>
      <FlexColumn alignItems="center">
        <Icon icon="close" size="xs" onClick={handleClick} />
      </FlexColumn>
    </FlexRow>
  );
};

const AnnouncementsList = () => {
  const announcements = useMemo(() => {
    return [
      {
        translateKey: "announcement.newMasteryTestListLayout",
        until: moment("2022-10-31T23:59:59").toDate(),
      },
    ];
  }, []);

  return announcements
    .filter((announcement) => moment().isBefore(moment(announcement.until)))
    .map((announcement) => <AnnouncementsListItem translateKey={announcement.translateKey} />);
};

export default AnnouncementsList;
