import React from "react";
import PropTypes from "prop-types";

const HtmlForm = ({ onSubmit, children }) => (
  <form
    onSubmit={(event) => {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }}
    style={{
      width: "100%",
      position: "relative",
    }}
  >
    {children}
  </form>
);

HtmlForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default HtmlForm;
