import React, { Component } from "react";
import PropTypes from "prop-types";
import round from "lodash/round";
import TranslatedText from "./TranslatedText";
import FlexRow from "./FlexRow";

export default class SprintListItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    suffix: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    labelColor: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
  };

  static defaultProps = {
    label: null,
    suffix: null,
  };

  state = { width: 0 };

  componentDidMount() {
    this.progressTimeout = setTimeout(() => {
      this.setState({
        width: (this.props.value * 100) / this.props.max,
      });
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
  }

  render() {
    return (
      <div
        style={{
          height: 30,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          width: "100%",
        }}
      >
        <FlexRow alignItems="center" columnGap={3}>
          {!!this.props.value && (
            <label
              htmlFor="value"
              style={{
                fontWeight: 400,
                fontSize: 13,
                color: this.props.labelColor,
                margin: 0,
              }}
            >
              {`${round(this.props.value, 0)} ${this.props.suffix || ""}`} -
            </label>
          )}
          <TranslatedText
            translateKey={this.props.value ? `sprint.${this.props.label}` : `sprint.no.${this.props.label}`}
            style={{
              fontWeight: 400,
              fontSize: 11,
              width: "max-content",
              color: "#a0b1b9",
              margin: 0,
            }}
          />
        </FlexRow>
        <div
          style={{
            width: `${this.state.width}%`,
            maxWidth: "100%",
            height: 4,
            backgroundColor: this.props.backgroundColor,
            transition: "width 1s ease-in-out",
          }}
        />
      </div>
    );
  }
}
