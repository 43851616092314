import React from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import MessageSentAnimation from "../../lotties/message-sent";
import { GREY_6 } from "../../consts/color";

const NewMessageAlert = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor: "#fff",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
    }}
  >
    <Lottie
      width="auto"
      height={200}
      options={{
        animationData: MessageSentAnimation,
        loop: false,
        autoplay: true,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
    />
    <span
      style={{
        display: "block",
        fontSize: 16,
        fontWeight: "bold",
        color: GREY_6,
      }}
    >
      {props.message}
    </span>
  </div>
);

NewMessageAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default NewMessageAlert;
