import React from "react";
import PropTypes from "prop-types";

const StudentSummary = (props) => (
  <div
    style={{
      backgroundColor: props.backgroundColor,
      width: "100%",
      height: "100%",
      textAlign: "center",
      boxSizing: "border-box",
      padding: "20px 0px",
    }}
  >
    <img
      alt="icon"
      src={props.iconSrc}
      style={{
        marginBottom: 5,
      }}
    />
    <h1
      style={{
        margin: 0,
        color: props.labelColor,
        fontSize: 22,
        fontWeight: 700,
      }}
    >
      {props.value || "-"}
    </h1>
    <label
      htmlFor="value"
      style={{
        margin: 0,
        color: props.labelColor,
        fontSize: 13,
        opacity: 0.5,
      }}
    >
      {props.label}
    </label>
  </div>
);

StudentSummary.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  labelColor: PropTypes.string.isRequired,
};

StudentSummary.defaultProps = {
  value: null,
};

export default StudentSummary;
