import React from "react";
import Separator from "../../core/Separator";
import ScoreRulerContainer from "./ScoreRulerContainer";
import ScoreItemContainer from "./ScoreItemContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const ScoreBox = () => {
  return (
    <WhitelabelContext.Consumer>
      {({ lightColor }) => (
        <div
          style={{
            borderRadius: 5,
            backgroundColor: lightColor,
            padding: "40px 55px 13px 55px",
            width: 740,
            margin: "0 auto",
          }}
        >
          <ScoreItemContainer />
          <Separator size="md" />
          <ScoreRulerContainer />
        </div>
      )}
    </WhitelabelContext.Consumer>
  )
};

export default ScoreBox;
