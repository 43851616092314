import React from "react";
import PropTypes from "prop-types";
import browserHistory from "../../../browserHistory";
import CourseButton from "../../../core/CourseButton";
import Button from "../../../core/Button";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import FlexColumn from "../../../core/FlexColumn";
import Separator from "../../../core/Separator";
import { addSentryUserAction } from "../../../util";

const sentryUserAction = { mainComponent: "CourseSection" };

const CourseSection = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 60px",
        }}
      >
        <FlexColumn alignItems="center">
          <TranslatedText
            as="h3"
            translateKey="placementTest.nextStep"
            style={{
              color: primaryColor,
              fontSize: 26,
              lineHeight: "24px",
              margin: 0,
            }}
          />
          <Separator size="sm" />
          <CourseButton size="mdg" course={props.course.name === "Pre A1" ? "PA1" : props.course.name} />
        </FlexColumn>
        {props.isPlacementTestOnly ? (
          <TranslatedText
            as={Button}
            translateKey="placementTest.button.exit"
            color={primaryColor}
            onClick={() => {
              sessionStorage.clear();
              browserHistory.push("/login");
              addSentryUserAction({
                ...sentryUserAction,
                clickedComponent: "TranslatedText",
                action: `Placement Test finished. Navigate to /login`,
              });
            }}
            style={{
              width: 260,
              fontSize: 16,
              fontWeight: 700,
              borderRadius: 5,
              height: 50,
              textTransform: "uppercase",
            }}
          />
        ) : (
          <TranslatedText
            as={Button}
            translateKey="placementTest.button.continueToPlatform"
            color={primaryColor}
            onClick={() => {
              browserHistory.push("/placement-test-complete-profile");
              addSentryUserAction({
                ...sentryUserAction,
                clickedComponent: "TranslatedText",
                action: `Placement Test finished. Navigate to /placement-test-complete-profile`,
              });
            }}
            style={{
              width: 240,
              fontSize: 16,
              fontWeight: 700,
              borderRadius: 5,
              height: 50,
              textTransform: "uppercase",
            }}
          />
        )}
      </div>
    )}
  </WhitelabelContext.Consumer>
);

CourseSection.propTypes = {
  course: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
  isPlacementTestOnly: PropTypes.bool.isRequired,
};

export default CourseSection;
