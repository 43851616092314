import { useContext } from "react";
import PropTypes from "prop-types";
import WhiteLabelContext from "student-front-commons/src/components/WhitelabelContext";
import TestHeader from "../TestHeader";
import TestPanel from "../TestPanel";
import HeaderContainer from "../../../core/HeaderContainer";
import FlexColumn from "../../../core/FlexColumn";
import Separator from "../../../core/Separator";
import TranslatedText from "../../../core/TranslatedText";
import PlacementTestRegisterForm from "./PlacementTestRegisterForm";

export default function PlacementTestRegisterScene(props) {
  const whitelabelContext = useContext(WhiteLabelContext);

  return (
    <div>
      <HeaderContainer />
      <TestHeader color={whitelabelContext.primaryColor} />
      <Separator size="lg" />
      <TranslatedText
        as="span"
        translateKey="placementTest.register.title"
        style={{
          color: "#fff",
          fontSize: "20px",
          fontWeight: "bold",
          textAlign: "center",
          display: "block",
        }}
      />
      <TestPanel width="640px" marginTop="40px" style={{ position: "relative" }}>
        <FlexColumn alignItems="center">
          <img
            src={
              props.data.logoUrl
                ? `${process.env.REACT_APP_FILES_URL}/${props.data.logoUrl}`
                : whitelabelContext.logoUrl
            }
            alt="School logo"
            width="300px"
          />
          <Separator size="lg" />
          <PlacementTestRegisterForm slug={props.slug} data={props.data} version={props.version} />
        </FlexColumn>
      </TestPanel>
    </div>
  );
}

PlacementTestRegisterScene.propTypes = {
  data: PropTypes.shape({
    logoUrl: PropTypes.string,
  }).isRequired,
  slug: PropTypes.string,
  version: PropTypes.string.isRequired,
};
