import React, { useContext } from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import bgLogin from "../../images/bg-login.png";
import bgLoginFlexge from "../../images/bg-login-flexge.png";
import Separator from "../../core/Separator";
import { WHITE } from "../../consts/color";
import LoginForm from "./LoginForm";

function LoginScene() {
  const whitelabelContext = useContext(WhitelabelContext);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
        backgroundColor: whitelabelContext.primaryColor,
        overflow: "hidden",
      }}
    >
      <img
        src={whitelabelContext.domain === "flexge.com" ? bgLoginFlexge : bgLogin}
        alt="bg"
        style={{
          position: "fixed",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: 520,
          padding: 40,
          position: "relative",
          backgroundColor: WHITE,
          borderRadius: 3,
          boxSizing: "border-box",
          boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
          transition: "all .3s",
        }}
      >
        <img
          src={whitelabelContext.logoUrl}
          alt="logo"
          style={{
            objectFit: "scale-down",
            height: "auto",
            width: 300,
          }}
        />
        <Separator size="md" />
        <Separator size="lg" />
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginScene;
