import React from "react";
import PropTypes from "prop-types";
import CircularButton from "../CircularButton";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "SlowRepeatButton" };

const SlowRepeatButton = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <CircularButton
        color={secondaryColor}
        size="sm"
        onClick={() => {
          props.onClick();
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "CircularButton",
            action: `Slow Repeat Unit Item`,
          });
        }}
        disabled={props.isDisabled}
        style={{
          color: "#fff",
        }}
      >
        S
      </CircularButton>
    )}
  </WhitelabelContext.Consumer>
);

SlowRepeatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default SlowRepeatButton;
