import React from "react";
import PropTypes from "prop-types";
import Styled, { keyframes } from "styled-components";

const loadAnimation = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const AsyncBox = Styled.div`
  border-radius: 50%;
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border: ${(props) => `${props.size * 10}em solid rgba(${props.rgb.r}, ${props.rgb.g}, ${props.rgb.b}, 0.2)`};
  border-left: ${(props) => `${props.size * 10}em solid rgba(${props.rgb.r}, ${props.rgb.g}, ${props.rgb.b}, 1)`};
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${loadAnimation} 1.1s infinite linear;
  animation: ${loadAnimation} 1.1s infinite linear;
  width: ${(props) => `${props.size * 100}em`};
  height: ${(props) => `${props.size * 100}em`};
  opacity: 1;
  transition: all 1s;
`;

const CircularLoading = (props) => (
  <AsyncBox
    rgb={props.rgb}
    size={
      {
        xxs: 0.02,
        xs: 0.03,
        sm: 0.05,
        md: 0.11,
      }[props.size]
    }
  />
);

CircularLoading.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md"]),
  rgb: PropTypes.shape({
    r: PropTypes.number.isRequired,
    g: PropTypes.number.isRequired,
    b: PropTypes.number.isRequired,
  }),
};

CircularLoading.defaultProps = {
  size: "xs",
  rgb: {
    r: 255,
    g: 255,
    b: 255,
  },
};

export default CircularLoading;
