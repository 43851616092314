import React, { Component } from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import QuestionLabel from "../QuestionLabel";
import Separator from "../Separator";
import ExerciseItemPanel from "./ExerciseItemPanel";
import HighlightableItemBox from "./HighlightableItemBox";
import CourseThemeContext from "../CourseThemeContext";
import FlexRow from "../FlexRow";
import FlexColumn from "../FlexColumn";
import InBetweenSpacing from "../InBetweenSpacing";
import Icon from "../Icon";
import { GREY_5 } from "../../consts/color";
import ColumnSeparator from "../ColumnSeparator";

export default class GapFillRadio extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }).isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    userAnswer: PropTypes.object,
  };

  static defaultProps = {
    userAnswer: {},
  };

  handleClick = (answer) => {
    this.props.onChange(
      "answer",
      this.props.item.linkedAnswers.find((x) => x.id === answer.id)
    );
    setTimeout(() => this.props.onConfirm(), 0);
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <ExerciseItemPanel>
            <HighlightableItemBox flexDirection="column">
              <FlexRow
                margin={10}
                flexWrap="wrap"
                justifyContent="center"
                alignItems="baseline"
                className="selectTextExerciseGapFill"
              >
                {this.props.item.text.split(" ").map((slice, index) => {
                  const foundAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === index);
                  if (foundAnswer) {
                    return (
                      <div
                        key={`answer_id_${foundAnswer.id}`}
                        style={{
                          display: "flex",
                        }}
                      >
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            color: "rgb(96, 125, 139)",
                            display: "inline",
                            textDecoration: "underline",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                        {/[!.?,]$/.test(foundAnswer.text) && (
                          <React.Fragment>
                            <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} /> &nbsp;
                          </React.Fragment>
                        )}
                      </div>
                    );
                  }
                  return (
                    !this.props.item.answers.find((answer) => answer.index === index) && (
                      <div
                        key={`${this.props.item.id}-text-${index}`}
                        style={{
                          display: "flex",
                        }}
                      >
                        <QuestionLabel text={slice} />
                        &nbsp;
                      </div>
                    )
                  );
                })}
              </FlexRow>
              <Separator size="md" />
              <FlexColumn className="selectOptionExerciseGapFill" alignItems="baseline">
                <InBetweenSpacing size="xs">
                  {orderBy(this.props.item.linkedAnswers, "text", "asc").map((answer) => (
                    <button
                      key={`answer-${answer.id}`}
                      style={{
                        fontWeight: 400,
                        color: "rgb(96, 125, 139)",
                        textIndent: 0,
                        cursor: this.props.isDisabled ? "not-allowed" : "pointer",
                        outline: "none",
                        border: "none",
                        backgroundColor: "transparent",
                        display: "flex",
                        alignItems: "center",
                      }}
                      disabled={this.props.isDisabled}
                      onClick={() => this.handleClick(answer)}
                    >
                      <Icon
                        icon={answer.id !== get(this.props.userAnswer, "id", "") ? "radiobox-blank" : "radiobox-marked"}
                        color={answer.id !== get(this.props.userAnswer, "id", "") ? GREY_5 : primary}
                      />
                      <ColumnSeparator size="xs" />
                      <QuestionLabel fontSize={18}>
                        {/[!.?,]$/.test(answer.text) ? answer.text.replace(/[!.?,]$/, "") : answer.text}
                      </QuestionLabel>
                    </button>
                  ))}
                </InBetweenSpacing>
              </FlexColumn>
            </HighlightableItemBox>
          </ExerciseItemPanel>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}
