import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Separator from "../Separator";
import CircularButton from "../CircularButton";
import Icon from "../Icon";
import TranslatedText from "../TranslatedText";
import { useFlow } from "student-front-commons/src/hooks";
import { PLAY_ITEM_AUDIO_STRIPE_FLOW } from "../../consts";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";

function SpeechRecognitionResultWordButton(props) {
  const [isPlaying, play] = useFlow(PLAY_ITEM_AUDIO_STRIPE_FLOW);

  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const nativeRecognitionResult = useSelector(getCurrentItemExecutionProp("nativeRecognitionResult.qualityScore"));

  const handlePlay = useCallback(() => {
    play({
      type: props.type,
      spriteIndex: props.index,
    });
  }, [props.index, props.type, play]);

  return (
    <div
      style={{
        padding: "0 10px",
        textAlign: "center",
      }}
    >
      <TranslatedText
        as="span"
        translateKey={
          {
            user: "you",
            native: "native",
          }[props.type]
        }
        style={{
          color: props.color,
          fontSize: 12,
        }}
      />
      <Separator size="xs" />
      <CircularButton
        size="sm"
        color={props.color}
        onClick={handlePlay}
        disabled={isDisabled || isPlaying || !nativeRecognitionResult}
      >
        <Icon size="xs" icon="play" color="#fff" />
      </CircularButton>
    </div>
  );
}

SpeechRecognitionResultWordButton.propTypes = {
  type: PropTypes.oneOf(["user", "native"]).isRequired,
  color: PropTypes.string.isRequired,
};

export default SpeechRecognitionResultWordButton;
