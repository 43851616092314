import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import MessageChatListItem from "./MessageChatListItem";
import Separator from "../Separator";

export default class MessageChatList extends Component {
  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  render() {
    return (
      <div>
        {this.props.messages.length &&
          this.props.messages.map((message, index) => (
            <div key={message.id}>
              <MessageChatListItem
                message={message}
                shouldGroup={message.sender.id === get(this.props.messages[index + 1], "sender.id", "")}
              />
              {message.sender.id !== get(this.props.messages[index + 1], "sender.id", "") && <Separator size="xs" />}
            </div>
          ))}
      </div>
    );
  }
}
