import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow, getFlowEnd } from "student-front-commons/src/selectors/flow";
import { CLOSE_UNIT_EXECUTION_FLOW, PLAY_ITEM_VIDEO_FLOW } from "../consts/index";
import { logError } from "../util";
import {
  disableItemExecution,
  enableItemExecution,
  startPlayItem,
} from "student-front-commons/src/actions/itemExecution";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { get } from "lodash";

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_VIDEO_FLOW), function* () {
    yield race({
      closeUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      takeForcedStop: take(getFlowEnd(PLAY_ITEM_VIDEO_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(PLAY_ITEM_VIDEO_FLOW));
        const initialPlay = get(flow.params, "initialPlay", false);
        try {
          yield put(disableItemExecution());
          const item = yield select(getCurrentItemExecutionProp("item"));
          yield put(startPlayItem(item.id, { isInitialPlay: initialPlay }));
          yield take((action) => action.type === "FINISH_PLAY_ITEM");
        } catch (error) {
          logError({ error, flow: PLAY_ITEM_VIDEO_FLOW });
        } finally {
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_ITEM_VIDEO_FLOW));
        }
      }),
    });
  });
}
