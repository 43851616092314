import React from "react";
import PropTypes from "prop-types";
import RankingListItem from "./RankingListItem";
import Separator from "../Separator";
import { GREY_3 } from "../../consts/color";

const RankingList = (props) => (
  <ul
    style={{
      listStyle: "none",
      padding: 0,
      margin: 0,
    }}
  >
    {props.rankings.map((rankingItem, index) => (
      <div key={`${rankingItem.id}-`}>
        {index > 0 && index !== props.separatorPosition && <Separator size="xs" />}
        <RankingListItem
          idStudent={rankingItem.id}
          nickname={rankingItem.nickname || rankingItem.name}
          profilePicture={rankingItem.profilePicture}
          position={rankingItem.position}
          points={rankingItem.points}
          highlightPosition={rankingItem.position <= props.separatorPosition}
          highlight={sessionStorage.getItem("id") === rankingItem.id}
        />
        {props.rankings.length > props.separatorPosition && index === props.separatorPosition - 1 && (
          <div
            style={{
              width: 25,
              borderTop: `5px dotted ${GREY_3}`,
              height: 10,
              marginTop: 10,
              marginLeft: "calc(50% - 12px)",
              display: "inline-block",
            }}
          />
        )}
      </div>
    ))}
  </ul>
);

RankingList.propTypes = {
  rankings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      profilePicture: PropTypes.string,
      nickname: PropTypes.string,
      points: PropTypes.number,
      position: PropTypes.number,
    })
  ).isRequired,
  separatorPosition: PropTypes.number,
};

RankingList.defaultProps = {
  separatorPosition: 3,
};

export default RankingList;
