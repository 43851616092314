import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * find the current student sprint stats
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getCurrentSprint = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/sprint`,
        method: "get",
      });
    },
    "error_get_student_current_sprint_stats",
    "Unexpected error to get current sprint stats."
  );
};

/**
 * find the student sprints history based on period
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getSprintHistoryByPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/sprint-history`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_student_sprints_history",
    "Unexpected error to load sprint history."
  );
};
