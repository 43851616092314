import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list the study quality score history based on period and group
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.group - the score group condition (day, week, month)
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getScoreHistoryByPeriodAndGroup = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      group: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["month", "week", "day"],
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/study-quality-history`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
          group: payload.group,
        },
      });
    },
    "error_get_study_quality_score_history",
    "Unexpected error to Study Quality score history."
  );
};

/**
 * find the current study quality score
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getCurrentScore = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/study-quality`,
        method: "get",
      });
    },
    "error_get_study_quality_current_score",
    "Unexpected error to get current Study Quality score."
  );
};

/**
 * find the current study quality score with requirements details
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getDetailedCurrentScore = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/study-quality-details`,
        method: "get",
      });
    },
    "error_get_detailed_study_quality_current_score",
    "Unexpected error to get Study Quality score details."
  );
};
