import React from "react";
import numeral from "numeral";
import Lottie from "react-lottie";
import { withRouter } from "react-router-dom";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPositionsByPeriod } from "student-front-commons/src/services/positionsService";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Async from "../../core/Async";
import Separator from "../../core/Separator";
import ClosedRankingTypeFilter from "./ClosedRankingTypeFilter";
import ClosedRankingLevelFilter from "./ClosedRankingLevelFilter";
import ClosedRankingListContainer from "./ClosedRankingListContainer";
import ClosedRankingCongratulations from "./ClosedRankingCongratulations";
import withTranslation from "../../core/withTranslation";
import Bg from "../../images/closed-ranking-bg.png";
import LightsBg from "../../images/closed-ranking-lights.png";
import { WHITE } from "../../consts/color";
import ClosedRankingAudio from "../../audios/closed-ranking.mp3";
import ClosedRankingAnimation from "../../lotties/closed-ranking";

numeral.locale(sessionStorage.getItem("locale"));

const ClosedRankingScene = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          width: "100%",
          minHeight: "100vh",
          backgroundColor: primaryColor,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
        }}
      >
        <img
          alt="bg"
          src={Bg}
          style={{
            position: "fixed",
            top: 0,
            width: "90%",
            height: "auto",
          }}
        />
        <img
          alt="lights"
          src={LightsBg}
          style={{
            position: "fixed",
            top: 0,
            width: 1050,
            height: "auto",
            animation: "fade",
          }}
        />
        <ClosedRankingTypeFilter>
          {({ type, from, to }) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <Separator size="md" />
              <h3
                dangerouslySetInnerHTML={{
                  __html: props.getTranslation("closedRanking.champions", { month: to.format("MMMM/YYYY") }),
                }}
                style={{
                  color: WHITE,
                  fontWeight: 700,
                  fontSize: 24,
                  margin: 0,
                  textTransform: "uppercase",
                }}
              />
              <Separator size="md" />
              <Separator size="xs" />
              <FetchData
                key={type}
                service={getPositionsByPeriod}
                params={{
                  id: sessionStorage.getItem("id"),
                  from: from.toDate(),
                  to: to.toDate(),
                }}
              >
                {({ data, isFetching }) => {
                  if (isFetching) {
                    return <Async fetching={true} />;
                  }
                  if (!data && !isFetching) {
                    return null;
                  }
                  return (
                    <Async fetching={isFetching}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <audio src={ClosedRankingAudio} autoPlay={true} />
                        <div
                          style={{
                            position: "absolute",
                            width: "150%",
                          }}
                        >
                          <Lottie
                            options={{
                              animationData: ClosedRankingAnimation,
                              loop: false,
                              autoplay: true,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                          />
                        </div>
                        <ClosedRankingLevelFilter>
                          {({ level }) => (
                            <React.Fragment>
                              {!!data[level] && data[level] > 0 && data[level] <= 10 && (
                                <ClosedRankingCongratulations />
                              )}
                              {!!data[level] && (
                                <label
                                  dangerouslySetInnerHTML={{
                                    __html: props.getTranslation(`closedRanking.congratulationsMessage.${level}`, {
                                      position: numeral(data[level]).format("0o"),
                                    }),
                                  }}
                                  style={{
                                    margin: 0,
                                    color: WHITE,
                                    fontSize: 22,
                                  }}
                                />
                              )}
                              <Separator size="md" />
                              <Separator size="xs" />
                              {level !== "school" && (
                                <ClosedRankingListContainer level={level} from={from.toDate()} to={to.toDate()} />
                              )}
                            </React.Fragment>
                          )}
                        </ClosedRankingLevelFilter>
                      </div>
                    </Async>
                  );
                }}
              </FetchData>
            </div>
          )}
        </ClosedRankingTypeFilter>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

export default withTranslation(withRouter(ClosedRankingScene));
