import React from "react";
import Separator from "../../../core/Separator";
import UnitResultHeader from "../UnitResultHeader";
import Button from "../../../core/Button";
import CourseThemeContext from "../../../core/CourseThemeContext";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import HubspotForm from "../../../core/HubspotForm";

const TastingResultScene = () => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div className="container">
        <Separator size="lg" />
        <div
          style={{
            maxWidth: 920,
            backgroundColor: "#fff",
            margin: "0 auto",
            borderRadius: 3,
          }}
        >
          <UnitResultHeader />
          <Separator size="md" />
          <Separator size="md" />
          <WhitelabelContext.Consumer>
            {({ domain }) => (
              <React.Fragment>
                {domain === "flexge.com" ? (
                  <div
                    style={{
                      padding: "20px 40px",
                    }}
                  >
                    <HubspotForm
                      onSubmit={() => false}
                      formId={
                        {
                          en: "8195eb21-13be-42a6-bbd9-5ea2b6e28548",
                          es: "e54f15b5-f069-4d04-98f5-e516b40bfc58",
                          pt: "96a874c3-544f-4873-aa97-d6b2beca3823",
                          "pt-br": "96a874c3-544f-4873-aa97-d6b2beca3823",
                        }[sessionStorage.getItem("locale")] || "8195eb21-13be-42a6-bbd9-5ea2b6e28548"
                      }
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      minHeight: 60,
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  >
                    <Button
                      onClick={() => {
                        sessionStorage.clear();
                        window.location.href = `https://${domain}`;
                      }}
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        width: "auto",
                        height: 40,
                        color: "#fff",
                        backgroundColor: primary,
                        margin: "10px 20px",
                        textTransform: "uppercase",
                      }}
                    >
                      CLOSE
                    </Button>
                  </div>
                )}
              </React.Fragment>
            )}
          </WhitelabelContext.Consumer>
          <Separator size="md" />
        </div>
        <Separator size="lg" />
      </div>
    )}
  </CourseThemeContext.Consumer>
);

export default TastingResultScene;
