import React from "react";
import PropTypes from "prop-types";
import DragOptionItem from "./DragOptionItem";

const DragOptionList = (props) => (
  <div
    style={{
      display: "flex",
      flexWrap: "wrap",
      maxWidth: 660,
      margin: "0 auto",
      justifyContent: "center",
      border: props.isOver ? "2px dashed #cecece" : "none",
      borderRadius: 3,
      padding: props.isOver ? "10px 0px" : "0px",
      zIndex: 11,
    }}
    className="selectOptionExerciseUnscrambleDragAndDrop"
  >
    {props.answers.map((answer) => (
      <div
        key={`option-item-${answer.id}`}
        style={{
          margin: 10,
        }}
      >
        <DragOptionItem
          answer={answer}
          item={props.item}
          disabled={props.isDisabled}
          onDrop={props.onDrop}
          nextIndex={props.nextIndex}
        />
      </div>
    ))}
  </div>
);

DragOptionList.propTypes = {
  onDrop: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  answers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  nextIndex: PropTypes.number.isRequired,
};

export default DragOptionList;
