import React, { useMemo } from "react";
import FlexRow from "../FlexRow";
import Icon from "../Icon";
import FlexColumn from "../FlexColumn";
import Paragraph from "../Paragraph";
import TranslatedText from "../TranslatedText";
import UnitButton from "../../screens/unit-list/UnitButton";
import PropTypes from "prop-types";
import Separator from "../Separator";
import UnitListItemColumnSeparator from "../../screens/unit-list/UnitListItemColumnSeparator";
import UnitReward from "../../screens/unit-list/UnitReward";

function ContentVideoListItem(props) {
  const status = useMemo(() => {
    if (props.unit.disabled && !props.allowBlockedUnit) {
      return "BLOCKED";
    }
    if (props.allowBlockedUnit && props.unit.yourChallengeStatus === "BLOCKED") {
      return "AVAILABLE";
    }
    return props.unit.yourChallengeStatus;
  }, [props.unit.yourChallengeStatus, props.allowBlockedUnit, props.isDisabled]);

  return (
    <FlexRow
      position="relative"
      justifyContent="flex-start"
      borderRadius={5}
      backgroundColor="#fff"
      boxShadow={{ ghost: "none", bordered: "rgba(0, 0, 0, 0.1) 0px 2px 10px" }[props.type]}
    >
      {status === "BLOCKED" && (
        <FlexRow
          top={0}
          left={0}
          width="100%"
          height="100%"
          position="absolute"
          backgroundColor="#ffffffcc"
          zIndex={5}
          justifyContent="center"
          alignItems="center"
        >
          <FlexColumn
            width={64}
            height={64}
            borderRadius={64}
            backgroundColor="#ECECEC"
            justifyContent="center"
            alignItems="center"
          >
            <Icon color="#42424280" icon="lock-outline" size="sm" />
          </FlexColumn>
        </FlexRow>
      )}
      <FlexColumn position="relative" justifyContent="center" alignItems="center">
        <img
          alt="video-thumbnail"
          style={{
            borderRadius: "4px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            width: "260px",
            height: "148px",
            objectFit: "cover",
          }}
          src={
            props.unit.thumbnail.indexOf("https") < 0
              ? `${process.env.REACT_APP_FILES_URL}/${props.unit.thumbnail}`
              : props.unit.thumbnail
          }
        />
        <FlexColumn position="absolute">
          <Icon icon="play-circle-outline" size="lg" color="#fff" opacity={0.8} />
        </FlexColumn>
      </FlexColumn>
      <Separator size="xs" />
      <FlexRow
        alignItems="center"
        justifyContent="space-between"
        gap={props.isOptionalReviewEnabled ? 0 : 25}
        padding="20px 15px"
        width="100%"
      >
        <FlexColumn flex={1}>
          <span
            style={{
              color: "#424242",
              fontSize: 16,
              fontWeight: "bold",
              lineHeight: "18px",
            }}
          >
            {props.unit.name}
          </span>
          <Separator size="xxs" />
          {props.unit?.description && <Paragraph style={{ color: "#2A2E34" }}>{props.unit.description}</Paragraph>}
          <Separator size="sm" />
          <UnitButton
            onClick={props.onClick}
            isDisabled={status === "BLOCKED"}
            type={
              status === "BLOCKED" ? "LOCKED" : props.unit.yourChallengeStatus === "COMPLETED" ? "WATCH_AGAIN" : "WATCH"
            }
          />
        </FlexColumn>
        <UnitListItemColumnSeparator />
        {!props.isOptionalReviewEnabled && (
          <FlexColumn width={200} alignItems="center">
            <TranslatedText
              as="label"
              translateKey="unit.contentVideoStep.title"
              style={{
                textTransform: "uppercase",
                fontSize: 11,
                lineHeight: "11px",
                color: "#727272",
                margin: 0,
              }}
            />
            <Separator size="md" />
            <FlexColumn
              width={32}
              height={32}
              borderRadius={32}
              backgroundColor={
                {
                  COMPLETED: "#00786A",
                  AVAILABLE: "#FF9F51",
                  BLOCKED: "#fff",
                }[status]
              }
              border="2px solid #fff"
              borderColor={
                {
                  COMPLETED: "#00786A",
                  AVAILABLE: "#FF9F51",
                  BLOCKED: "#00000033",
                }[status]
              }
              boxSizing="border-box"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                color={
                  {
                    COMPLETED: "#fff",
                    AVAILABLE: "#fff",
                    BLOCKED: "#00000033",
                  }[status]
                }
                icon={
                  {
                    COMPLETED: "check",
                    AVAILABLE: "lock-open-variant-outline",
                    BLOCKED: "lock-outline",
                  }[status]
                }
                size="xs"
              />
            </FlexColumn>
            <Separator size="xxs" />
            <TranslatedText
              as="label"
              translateKey={`unit.contentVideoStep.${status.toLowerCase()}`}
              style={{
                color: "#424242",
                opacity: props.unit.yourChallengeStatus === "BLOCKED" ? 0.5 : 1,
                fontSize: 13,
                lineHeight: "15px",
                margin: 0,
              }}
            />
            {status !== "BLOCKED" && (
              <TranslatedText
                as="label"
                translateKey="unit.points"
                values={{
                  points: props.unit.defaultPoints,
                }}
                style={{
                  color: status === "COMPLETED" ? "#00786A" : "#343434",
                  fontSize: 12,
                  fontWeight: "bold",
                  lineHeight: "14px",
                  margin: 0,
                }}
              />
            )}
          </FlexColumn>
        )}
        {!!props.isOptionalReviewEnabled && (
          <UnitReward points={props.unit.defaultPoints} completed={props.unit.yourChallengeStatus === "COMPLETED"} />
        )}
      </FlexRow>
    </FlexRow>
  );
}

ContentVideoListItem.propTypes = {
  unit: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  allowBlockedUnit: PropTypes.bool,
  type: PropTypes.oneOf(["ghost", "bordered"]),
  isOptionalReviewEnabled: PropTypes.bool,
};

ContentVideoListItem.defaultProps = {
  type: "bordered",
  allowBlockedUnit: false,
};

export default ContentVideoListItem;
