import React from "react";
import PropTypes from "prop-types";
import SprintListItem from "../../core/SprintListItem";
import Separator from "../../core/Separator";
import withTranslation from "../../core/withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const SprintList = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor, lightColor }) => (
      <div>
        <SprintListItem
          label="currentSprint"
          value={props.currentSprint}
          max={props.maxSprint}
          backgroundColor={lightColor}
          labelColor={secondaryColor}
          suffix={props.getTranslation("days").toLowerCase()}
        />
        <Separator size="xs" />
        <SprintListItem
          label="maximumSprint"
          value={props.maxSprint}
          max={props.maxSprint}
          backgroundColor={lightColor}
          labelColor={primaryColor}
          suffix={props.getTranslation("days").toLowerCase()}
        />
        <Separator size="xs" />
        <SprintListItem
          label="schoolAverage"
          value={Number(props.companyAverage.toFixed(1))}
          max={props.maxSprint}
          backgroundColor="rgba(96, 125, 139, .4)"
          labelColor="#607d8b"
          suffix={props.getTranslation("days").toLowerCase()}
        />
      </div>
    )}
  </WhitelabelContext.Consumer>
);

SprintList.propTypes = {
  currentSprint: PropTypes.number.isRequired,
  maxSprint: PropTypes.number.isRequired,
  companyAverage: PropTypes.number.isRequired,
};

export default withTranslation(SprintList);
