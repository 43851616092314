import { useContext } from "react";
import { useParams } from "react-router-dom";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import Icon from "../../core/Icon";
import browserHistory from "../../browserHistory";
import ButtonV2 from "../../core/ButtonV2";
import TranslatedText from "../../core/TranslatedText";
import { useSelector } from "react-redux";
import { getCoursePercentageProgress } from "student-front-commons/src/selectors/entity";
import { min, round } from "lodash";
import { GREY_2 } from "../../consts/color";
import CourseThemeContext from "../../core/CourseThemeContext";

export default function BonusTestRewardsBlocked() {
  const courseThemeContext = useContext(CourseThemeContext);
  const params = useParams();
  const coursePercentage = useSelector(getCoursePercentageProgress(params.idCourse));
  // Bonus Test is available when user reaches 60% progress in current course
  const percentage = min([(coursePercentage / 60) * 100, 100]);

  return (
    <FlexColumn alignItems="center" marginTop="35px" width="100%" padding="0 20px">
      <Icon icon="wallet-giftcard" color="#fff" size="lg" opacity="0.5" />
      <TranslatedText
        as="span"
        translateKey="bonusTest.introduction.blocked.title"
        style={{
          color: "#fff",
          fontSize: "28px",
          fontWeight: 700,
          width: "400px",
          textAlign: "center",
          lineHeight: "32px",
        }}
      />
      <TranslatedText
        as="p"
        translateKey="bonusTest.introduction.blocked.description"
        style={{
          color: "#fff",
          fontSize: "14px",
          lineHeight: "21px",
          textAlign: "center",
          display: "block",
          marginBottom: "-10px",
          marginTop: "60px",
        }}
      />
      <FlexRow alignItems="center" justifyContent="center" position="relative" width="100%">
        <FlexColumn
          position="relative"
          backgroundColor={GREY_2}
          borderRadius={10}
          height={20}
          maxWidth="520px"
          width="100%"
          overflow="hidden"
        >
          <FlexColumn
            width={`${percentage}%`}
            backgroundColor={courseThemeContext.secondary}
            height="100%"
            position="absolute"
            transition="width 1s ease-in-out"
            borderTopLeftRadius={10}
            borderBottomLeftRadius={10}
            left={0}
            top={0}
            overflow="hidden"
          />
        </FlexColumn>
        <FlexColumn
          alignItems="center"
          justifyContent="center"
          backgroundColor={GREY_2}
          width="64px"
          height="64px"
          borderRadius="60px"
          marginLeft="-8px"
        >
          <Icon icon="wallet-giftcard" color={courseThemeContext.secondary} size="md" />
        </FlexColumn>
      </FlexRow>
      <FlexRow width="100%" maxWidth="520px" position="relative">
        <FlexColumn position="absolute" bottom="-20px" left={`calc(${percentage}% - 48px)`}>
          <div style={{ backgroundColor: "#fff", padding: "2px 5px", borderRadius: "4px", position: "relative" }}>
            <div
              style={{
                width: 0,
                height: 0,
                borderLeft: "5px solid transparent",
                borderRight: "5px solid transparent",
                borderBottom: "8px solid #fff",
                position: "absolute",
                top: "-6px",
                left: 0,
                right: 0,
                margin: "0 auto",
              }}
            />
            <span style={{ fontSize: "16px", fontWeight: 700, color: courseThemeContext.primary }}>
              {round(percentage)}%
            </span>
          </div>
        </FlexColumn>
      </FlexRow>
      <FlexRow alignItems="center" justifyContent="center" marginTop="110px">
        <ButtonV2
          labelKey="masteryTest.button.back"
          onClick={() => browserHistory.push(`/course/${params.idCourse}/modules`)}
          intent="blank"
          size="md"
        />
      </FlexRow>
    </FlexColumn>
  );
}
