import React from "react";
import PropTypes from "prop-types";

const Row = (props) => (
  <div className="row" style={props.style}>
    {props.children}
  </div>
);

Row.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

Row.defaultProps = {
  style: null,
};

export default Row;
