import React from "react";
import { connect } from "react-redux";
import { Trail, animated } from "react-spring";
import head from "lodash/head";
import NotificationToast from "./NotificationToast";
import { requestRemoveNotification } from "../../actionCreators/notification";

class NotificationToastContainer extends React.PureComponent {
  render() {
    return (
      <div
        style={{
          position: "fixed",
          top: 45,
          zIndex: 15,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "0px 20px",
        }}
      >
        <Trail
          items={this.props.notification}
          unique
          keys={(item) => item && item.id}
          from={{ transform: "scale(0)" }}
          to={{ transform: "scale(1)" }}
          delay={500}
        >
          {(item) =>
            item
              ? (props) => (
                  <animated.div style={props}>
                    <NotificationToast
                      notification={item}
                      configurations={this.props.configurations}
                      onHide={this.props.handleHide}
                    />
                  </animated.div>
                )
              : null
          }
        </Trail>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  notification: head(state.notification.queue),
  configurations: state.configurations,
});

const mapDispatchToProps = (dispatch) => ({
  handleHide: (id) => dispatch(requestRemoveNotification(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationToastContainer);
