import React from "react";
import { useSelector } from "react-redux";
import ControlBarLayoutItem from "../../core/control-bar/ControlBarLayoutItem";
import ColumnSeparator from "../../core/ColumnSeparator";
import ControlBarLayoutItemResponsive from "../../core/control-bar/ControlBarLayoutItemResponsive";
import ControlBarLayout from "../../core/control-bar/ControlBarLayout";
import RecordButtonContainer from "../../core/control-bar/RecordButtonContainer";
import RepeatButtonContainer from "../../core/control-bar/RepeatButtonContainer";
import SlowRepeatButtonContainer from "../../core/control-bar/SlowRepeatButtonContainer";
import ListenButtonContainer from "../../core/control-bar/ListenButtonContainer";
import NextButton from "../../core/control-bar/NextButton";
import ReadButtonContainer from "../../core/control-bar/ReadButtonContainer";
import TranslateButtonContainer from "../../core/control-bar/TranslateButtonContainer";
import MediaQuery from "react-responsive";
import { getItemsExecutionsType, isAnyItemExecutionValidated } from "student-front-commons/src/selectors/itemExecution";
import { VALIDATION_STEP_ITEMS } from "student-front-commons/src/consts";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function UnitExecutionControlBar() {
  const itemType = useSelector(getItemsExecutionsType);
  const isExecutionValidated = useSelector(isAnyItemExecutionValidated);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <ControlBarLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MediaQuery maxWidth={368}>
          {(matches) => (
            <div
              style={{
                display: "inline-block",
              }}
            >
              {!profile.isSpeechRecognitionDisabled && (
                <>
                  <ControlBarLayoutItem type="record">
                    <RecordButtonContainer />
                  </ControlBarLayoutItem>
                  <ColumnSeparator size={matches ? "xs" : "md"} />
                  <ControlBarLayoutItem type="listen">
                    <ListenButtonContainer />
                  </ControlBarLayoutItem>
                </>
              )}
              <ColumnSeparator size={matches ? "xs" : "md"} />
              <ControlBarLayoutItem type="repeat">
                <RepeatButtonContainer />
                <MediaQuery minWidth={368}>
                  <div
                    style={{
                      position: "absolute",
                      right: -23,
                      bottom: 18,
                    }}
                  >
                    <SlowRepeatButtonContainer />
                  </div>
                </MediaQuery>
              </ControlBarLayoutItem>
            </div>
          )}
        </MediaQuery>
        <ControlBarLayoutItemResponsive types={["reading", "translate"]}>
          <ControlBarLayoutItem type="reading">
            <ReadButtonContainer />
          </ControlBarLayoutItem>
          <ColumnSeparator size="md" />
          <ControlBarLayoutItem type="translate">
            <TranslateButtonContainer />
          </ControlBarLayoutItem>
        </ControlBarLayoutItemResponsive>
        <div
          style={{
            display: "inline-block",
          }}
        >
          <ControlBarLayoutItem
            type="next"
            label={!isExecutionValidated && VALIDATION_STEP_ITEMS.includes(itemType) ? "validate" : "next"}
          >
            <NextButton />
          </ControlBarLayoutItem>
        </div>
      </div>
    </ControlBarLayout>
  );
}

export default UnitExecutionControlBar;
