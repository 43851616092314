import React from "react";
import MediaQuery from "react-responsive";
import Row from "../../core/Row";
import Column from "../../core/Column";
import Separator from "../../core/Separator";
import StudentDetails from "./StudentDetails";
import SprintHistoryPanel from "./SprintHistoryPanel";
import HistoryPanelContainer from "./HistoryPanelContainer";
import AchievementPanel from "./AchievementPanel";
import CertificationTestStatusBarContainer from "../../core/CertificationTestStatusBarContainer";
import PerformancePanel from "./PerformancePanel";

const ProfileScene = () => (
  <div className="container">
    <Separator size="md" />
    <CertificationTestStatusBarContainer margin="md" />
    <MediaQuery minWidth={992}>
      {(matches) => (
        <Row>
          <Column lgSize={matches ? 5 : 12} mdSize={matches ? 5 : 12} smSize={12} xsSize={12}>
            <StudentDetails />
          </Column>
          {matches && (
            <Column lgSize={7} mdSize={7}>
              <AchievementPanel />
              <Separator size="md" />
              <PerformancePanel />
              <Separator size="md" />
              <SprintHistoryPanel />
              <Separator size="md" />
              <HistoryPanelContainer />
            </Column>
          )}
        </Row>
      )}
    </MediaQuery>
    <Separator size="md" />
  </div>
);

export default ProfileScene;
