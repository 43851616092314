import React from "react";
import PropTypes from "prop-types";
import withTranslation from "../../core/withTranslation";
import AsyncPanel from "../../core/AsyncPanel";
import TranslatedText from "../../core/TranslatedText";
import GradeListEvaluationColumn from "./GradeListEvaluationColumn";
import GradeListStudyQualityColumn from "./GradeListStudyQualityColumn";
import GradeListNoContentColumn from "./GradeListNoContentColumn";
import GradeListTimeColumn from "./GradeListTimeColumn";
import GradeList from "./GradeList";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const GradePanel = (props) => (
  <WhitelabelContext.Consumer>
    {({ lightColor }) => (
      <AsyncPanel
        padding="md"
        hasData={!props.fetching && !!props.data.length}
        fetching={props.fetching}
        noDataText={props.getTranslation("grades.noData")}
      >
        <GradeList
          columns={[
            {
              header: "",
              render: (row) => (
                <GradeListEvaluationColumn
                  start={row.start}
                  end={row.end}
                  name={row.name}
                  bonusWeeks={row.bonusWeeks}
                />
              ),
            },
            {
              header: `Study Quality (${props.school.percentStudyQualityRelevanceInGrade}%)`,
              headerStyle: {
                width: 300,
              },
              render: (row) =>
                row.grade ? (
                  <GradeListStudyQualityColumn
                    grade={row.grade.studyQualityGrade}
                    average={row.grade.averageStudyQuality}
                    preview={!row.grade.id}
                    start={row.start}
                    bonusWeeks={row.bonusWeeks}
                  />
                ) : (
                  <GradeListNoContentColumn />
                ),
            },
            {
              header: (
                <TranslatedText
                  as="b"
                  translateKey="grades.time"
                  values={{
                    percentageRelevance: props.school.percentHoursRelevanceInGrade,
                  }}
                  style={{
                    fontFamily: "inherit",
                  }}
                />
              ),
              headerStyle: {
                width: 300,
              },
              render: (row) =>
                row.grade ? (
                  <GradeListTimeColumn
                    grade={row.grade.hoursGrade}
                    requiredStudyTime={row.grade.hoursRequired}
                    totalStudyTime={row.grade.hoursStudied}
                    preview={!row.grade.id}
                  />
                ) : (
                  <GradeListNoContentColumn />
                ),
            },
            {
              header: props.getTranslation("grades.finalGrade"),
              headerStyle: {
                backgroundColor: lightColor,
                width: 250,
              },
              columnStyle: {
                backgroundColor: lightColor,
              },
              render: (row) =>
                row.grade ? (
                  <div
                    style={{
                      textAlign: "center",
                      color: row.grade.id ? "rgb(55, 71, 79)" : "rgb(145, 153, 156)",
                      fontSize: 42,
                    }}
                  >
                    {row.grade.formattedFinalGrade || "N/A"}
                    <TranslatedText
                      as="small"
                      translateKey={
                        row.grade.id
                          ? "grades.closed"
                          : row.grade.finalGrade >= 0
                          ? "grades.preview"
                          : "grades.awaitingSq"
                      }
                      style={{
                        display: "block",
                        fontSize: 16,
                        lineHeight: "10px",
                        marginBottom: 10,
                      }}
                    />
                    {row.grade.finalGrade >= 0 && (
                      <TranslatedText
                        as="small"
                        translateKey="grades.yourSchoolGrade"
                        values={{
                          grade: row.grade.finalGrade,
                        }}
                        style={{
                          display: "block",
                          fontSize: 13,
                        }}
                      />
                    )}
                    {row.grade.finalGrade >= 0 && (
                      <TranslatedText
                        as="small"
                        translateKey="grades.maximumSchoolGrade"
                        values={{
                          grade: row.grade.maximumGrade || props.school.maximumGrade,
                        }}
                        style={{
                          display: "block",
                          fontSize: 13,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  <GradeListNoContentColumn />
                ),
            },
          ]}
          rows={props.data}
        />
      </AsyncPanel>
    )}
  </WhitelabelContext.Consumer>
);

GradePanel.propTypes = {
  fetching: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  school: PropTypes.shape({
    gradeFormat: PropTypes.string,
    maximumGrade: PropTypes.number,
    percentHoursRelevanceInGrade: PropTypes.number,
    percentStudyQualityRelevanceInGrade: PropTypes.number,
  }).isRequired,
  weeklyHoursRequired: PropTypes.number.isRequired,
};

export default withTranslation(GradePanel);
