import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { call, put, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import {
  END_PRACTICE_TEST_ITEM_TOUR,
  GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  PRACTICE_TEST_EXECUTION_FORM,
  START_PRACTICE_TEST_ITEM_TOUR,
} from "../consts";
import { addSentryUserAction, linkAnswers, logError } from "../util";
import { playAudio } from "../stores/audio-store";
import { getInstructionAudio } from "student-front-commons/src/selectors/configuration";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { awaitHideAction } from "student-front-commons/src/selectors/systemMessage";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getNextPracticeTestItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW), function* () {
    try {
      const practiceTestExecutionForm = yield select(getForm(PRACTICE_TEST_EXECUTION_FORM));
      const nextOrder = practiceTestExecutionForm.values.currentOrder + 1;
      const nextPracticeTestItem = practiceTestExecutionForm.values.practiceTestItems.find(
        (practiceItem) => practiceItem.order === nextOrder
      );

      const linkedAnswers = linkAnswers(nextPracticeTestItem.item.answers);

      yield put(
        initForm(ITEM_EXECUTION_FORM, {
          associativeItem: {
            ...nextPracticeTestItem,
            item: {
              ...nextPracticeTestItem.item,
              linkedAnswers: typesToOrderAnswers.find((type) => type === nextPracticeTestItem.item.type.key)
                ? orderBy(linkedAnswers, "text", "asc")
                : shuffle(linkedAnswers),
            },
          },
          startedAt: new Date(),
          isDisabled: true,
          answer: null,
          repeatCount: 0,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      if (practiceTestExecutionForm.values.currentOrder === 0) {
        const practiceTestStartAudio = yield select(getInstructionAudio("placementTest.practiceTest.start"));
        try {
          yield call(playAudio, {
            url: practiceTestStartAudio,
            isCompleteUrl: false,
            rate: 1,
          });
        } catch (playError) {
          if (
            typeof playError === "string" &&
            (playError === "required-user-click" || playError.indexOf("Playback was unable to start") > -1)
          ) {
            yield spawn(function* () {
              yield put(
                showMessage({
                  icon: "play-circle-outline",
                  message: "error.error_browser_block_autoplay",
                  button: "continue",
                })
              );
              yield take(awaitHideAction);
              yield call(playAudio, {
                url: practiceTestStartAudio,
                isCompleteUrl: false,
                rate: 1,
              });
            });
          } else {
            throw playError;
          }
        }
      }

      if (
        !practiceTestExecutionForm.values.typesShowedInIntro.some((type) => type === nextPracticeTestItem.item.type.key)
      ) {
        yield put(startFlow(START_PRACTICE_TEST_ITEM_TOUR, { itemType: nextPracticeTestItem.item.type.key }));
        yield take(getFlowStart(END_PRACTICE_TEST_ITEM_TOUR));
        yield put(endFlow(START_PRACTICE_TEST_ITEM_TOUR));
        yield put(endFlow(END_PRACTICE_TEST_ITEM_TOUR));
      }

      yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true }));
    } catch (error) {
      logError({ error, flow: GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW));
    }
  });
}
