import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getMessageChannels } from "student-front-commons/src/services/messageService";
import { LOAD_MESSAGE_CHANNELS_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_MESSAGE_CHANNELS_FLOW), function* () {
    try {
      const flow = yield select(getFlow(LOAD_MESSAGE_CHANNELS_FLOW));

      const pageableMessageChannel = yield call(getMessageChannels, {
        ...flow.params,
      });

      yield put(mergeEntities(pageableMessageChannel.docs.entities));
    } catch (error) {
      logError({ error, flow: LOAD_MESSAGE_CHANNELS_FLOW });
    } finally {
      yield put(endFlow(LOAD_MESSAGE_CHANNELS_FLOW));
    }
  });
}
