import React from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import Icon from '../Icon';
import QuestionLabel from '../QuestionLabel';
import ExerciseButton from './ExerciseButton';
import ExerciseItemPanel from './ExerciseItemPanel';

const TrueFalseExerciseItem = props => (
  <ExerciseItemPanel>
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 50,
      }}
    >
      {(props.abilityType === 'READING' || props.abilityType === 'BOTH') && (
        <QuestionLabel
          text={props.item.text}
        />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: 50,
        }}
      >
        {orderBy(props.item.answers, 'text', 'desc').map(answer => (
          <div
            key={`answer-${answer.id}`}
            style={{
              margin: '0px 7px',
            }}
          >
            <ExerciseButton
              disabled={props.isDisabled}
              onClick={() => {
                props.onChange('answer', answer);
                props.onConfirm();
              }}
            >
              <Icon
                size="lg"
                icon={answer.text === 'True' ? 'thumb-up' : 'thumb-down'}
                color={answer.text === 'True' ? 'rgb(13, 150, 137)' : 'rgb(232, 66, 66)'}
              />
            </ExerciseButton>
          </div>
        ))}
      </div>
    </div>
  </ExerciseItemPanel>
);

TrueFalseExerciseItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  abilityType: PropTypes.oneOf(['READING', 'LISTENING', 'BOTH']),
};

TrueFalseExerciseItem.defaultProps = {
  abilityType: 'BOTH',
};

export default TrueFalseExerciseItem;
