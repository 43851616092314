import React, { useCallback, useEffect, useRef, useState } from "react";
import ExerciseItemPanel from "./ExerciseItemPanel";
import HighlightableItemBox from "./HighlightableItemBox";
import CircularLoading from "../CircularLoading";
import FlexColumn from "../FlexColumn";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import VideoPlayer, { Modes } from "../VideoPlayer";
import { finishPlayItem, updateItemMediaProgress } from "student-front-commons/src/actions/itemExecution";
import { addSentryUserAction } from "../../util";
import { useFlow } from "student-front-commons/src/hooks";
import { PLAY_ITEM_VIDEO_FLOW } from "../../consts";

function VideoExerciseRender(props) {
  const videoPlayerRef = useRef(null);
  const [duration, setDuration] = useState(0);
  const dispatch = useDispatch();

  const [isPending] = useFlow(PLAY_ITEM_VIDEO_FLOW, { autoStart: false });

  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const videoLink = useSelector(getItemExecutionPropById(props.itemId, "item.videoLink"));

  const handleVideoEnd = useCallback(() => {
    dispatch(finishPlayItem(props.itemId, { isInitialPlay: true }));
  }, [props.itemId]);

  const handleChange = useCallback((change) => {
    setDuration(change.duration);
    dispatch(updateItemMediaProgress(props.itemId, { duration: change.duration, currentTime: change.seconds }));
  }, []);

  const handleError = useCallback((data) => {
    addSentryUserAction({
      mainComponent: "VideoExerciseItem",
      clickedComponent: "None",
      action: `Video error ${data.name} on method ${data.method} with message: ${data.message}`,
    });
    dispatch(finishPlayItem(props.itemId, { isInitialPlay: true }));
  }, []);

  useEffect(() => {
    if (isPending) {
      videoPlayerRef.current?.play();
    } else {
      videoPlayerRef.current?.pause();
    }
  }, [isPending])

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox flexDirection="column">
        {!duration && (
          <FlexColumn position="absolute" width="100%" height="100%" justifyContent="center" alignItems="center">
            <CircularLoading size="sm" rgb={{ r: 237, g: 237, b: 237 }} />
          </FlexColumn>
        )}
        <div key={`play-${repeatCount}`}>
          <VideoPlayer
            src={videoLink}
            ref={videoPlayerRef}
            autoStart={false}
            onVideoEnd={handleVideoEnd}
            onChange={handleChange}
            onError={handleError}
            mode={Modes.WITH_PROGRESS_BAR}
          />
        </div>
      </HighlightableItemBox>
    </ExerciseItemPanel>
  );
}

export default function VideoExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <VideoExerciseRender key={id} itemId={id} />);
}
