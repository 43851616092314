export const CERTIFICATION_TEST_SCHEMA = "certificationTest";
export const COURSE_SCHEMA = "course";
export const MASTERY_TEST_SCHEMA = "masteryTest";
export const MESSAGE_CHANNEL_SCHEMA = "messageChannel";
export const MESSAGE_SCHEMA = "message";
export const MODULE_SCHEMA = "module";
export const PROFILE_SCHEMA = "profile";
export const SCHOOL_CLASS_SCHEMA = "schoolClass";
export const SCHOOL_SCHEMA = "school";
export const COMPANY_SCHEMA = "company";
export const UNIT_SCHEMA = "unit";
export const SCHEDULED_UNIT_REVIEW_SCHEMA = "scheduledUnitReview";
export const UNIT_TYPE_SCHEMA = "unitType";
export const FUNCTION_OF_LANGUAGE_SCHEMA = "functionOfLanguage";
