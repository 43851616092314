import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getModuleStatsByPeriod } from "student-front-commons/src/services/studentStatsService";
import CircularLoading from "../CircularLoading";
import { useService } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getUnitsToFinishAfterTestFailure } from "student-front-commons/src/selectors/configuration";

function MasteryTestListItemPoints(props) {
  const unitsToFinishAfterTestFailure = useSelector(getUnitsToFinishAfterTestFailure);

  const [stats, setStats] = useState({});
  const [isFetching] = useService(getModuleStatsByPeriod, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      module: props.module,
      from: moment(props.failedAt).toDate(),
      to: moment().endOf("day").toDate(),
    },
    onData: (data) => setStats(data),
  });

  if (isFetching) {
    return <CircularLoading size="xxs" />;
  }
  if (stats.approvedUnits >= unitsToFinishAfterTestFailure) {
    return (
      <label
        className={props.className}
        htmlFor="check"
        style={{
          color: "#FFF",
          fontSize: 11,
          margin: 0,
        }}
      >
        Checking...
      </label>
    );
  }
  return (
    <label
      className={props.className}
      htmlFor="points"
      style={{
        color: "#FFF",
        fontSize: 13,
        margin: 0,
      }}
    >
      {stats.approvedUnits || 0}/{unitsToFinishAfterTestFailure}
    </label>
  );
}
MasteryTestListItemPoints.propTypes = {
  module: PropTypes.string.isRequired,
  failedAt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default MasteryTestListItemPoints;
