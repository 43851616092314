import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "../../core/UserAvatar";
import { BRONZE, GOLD, SILVER, WHITE } from "../../consts/color";
import Separator from "../../core/Separator";
import numeral from "numeral";
import TranslatedText from "../../core/TranslatedText";

const ClosedRankingListLeaderItem = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: 210,
      boxSizing: "border-box",
      paddingTop: {
        1: 0,
        2: 120,
        3: 170,
      }[props.student.position],
      paddingLeft: {
        1: 0,
        2: 65,
        3: 0,
      }[props.student.position],
      paddingRight: {
        1: 0,
        2: 0,
        3: 65,
      }[props.student.position],
      zIndex: 2,
    }}
  >
    <div
      style={{
        width: {
          1: 102,
          2: 72,
          3: 72,
        }[props.student.position],
        height: {
          1: 102,
          2: 72,
          3: 72,
        }[props.student.position],
        borderRadius: "100%",
        backgroundColor: "transparent",
        border: "3px solid #ffa000",
        borderColor: {
          1: GOLD,
          2: SILVER,
          3: BRONZE,
        }[props.student.position],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <UserAvatar
        src={props.student.profilePicture}
        size={
          {
            1: "mdl",
            2: "md",
            3: "md",
          }[props.student.position]
        }
      />
    </div>
    <Separator size="xs" />
    <span
      style={{
        color: WHITE,
        fontSize: 14,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: 130,
      }}
    >
      {props.student.nickname || props.student.name}
    </span>
    <Separator size="xs" />
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.1)",
        borderRadius: 10,
        padding: "0px 20px",
      }}
    >
      <TranslatedText
        translateKey="closedRanking.points"
        values={{
          points: numeral(props.student.points || 0)
            .format("0,0")
            .replace(",", "."),
        }}
        as="span"
        style={{
          color: WHITE,
          fontSize: 14,
          fontWeight: "bold",
        }}
      />
    </div>
    <span
      style={{
        margin: {
          1: "70px 0px 0px 0px",
          2: "70px 45px 0px 0px",
          3: "50px 00px 0px 60px",
        }[props.student.position],
        color: WHITE,
        fontSize: 48,
        fontWeight: 700,
        display: "flex",
        alignItems: "baseline",
        lineHeight: "48px",
        textTransform: "uppercase",
      }}
    >
      {props.student.position}
      <small style={{ fontSize: 22 }}>
        {numeral(props.student.position).format("0o").substring(props.student.position.toString().length)}
      </small>
    </span>
  </div>
);

ClosedRankingListLeaderItem.propTypes = {
  student: PropTypes.shape({}).isRequired,
};

export default ClosedRankingListLeaderItem;
