import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import { useEntity } from "student-front-commons/src/hooks";
import { getRecommendedContentVideoByGrammarsIdsAndModuleIdAndCourseId } from "student-front-commons/src/selectors/entity";
import ContentVideoListItem from "../../core/content-video-list/ContentVideoListItem";
import { addSentryUserAction } from "../../util";
import { COMPANY_SCHEMA, PROFILE_SCHEMA, UNIT_SCHEMA } from "student-front-commons/src/schemas";

const sentryUserAction = { mainComponent: "RecommendedUnitSection" };

function RecommendedUnitSection() {
  const history = useHistory();
  const { idUnit, idCourse, idModule } = useParams();

  const courseThemeContext = useContext(CourseThemeContext);

  const unit = useEntity(UNIT_SCHEMA, idUnit);
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile?.company);

  const recommendedContentVideo = useSelector(
    getRecommendedContentVideoByGrammarsIdsAndModuleIdAndCourseId(
      unit.grammars,
      idModule,
      idCourse,
      company?.allowContentVideoTab
    )
  );

  if (!recommendedContentVideo) {
    return <div style={{ height: "46px", width: "100%" }} />;
  }

  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "0px 30px 20px 30px",
        marginBottom: 24,
      }}
    >
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#e9e7e7",
          marginBottom: "20px",
        }}
      />
      <TranslatedText
        as="h1"
        translateKey="unitResult.recommendedUnits"
        style={{
          fontWeight: 700,
          fontSize: 12,
          lineHeight: "12px",
          margin: 0,
          color: courseThemeContext.primary,
          textTransform: "uppercase",
          marginBottom: 8,
        }}
      />
      <ContentVideoListItem
        type="ghost"
        allowBlockedUnit={company?.allowContentVideoTab}
        onClick={() => {
          history.replace(
            `/course/${idCourse}/modules/${recommendedContentVideo.module}/units/${recommendedContentVideo.id}/content-video-exercise`
          );
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "UnitButton",
            action: `Navigate to /modules/${module.id}/units/${recommendedContentVideo.id}/content-video-exercise`,
          });
        }}
        unit={recommendedContentVideo}
      />
      <div
        style={{
          height: 1,
          width: "100%",
          backgroundColor: "#e9e7e7",
          marginTop: "20px",
        }}
      />
    </div>
  );
}

export default RecommendedUnitSection;
