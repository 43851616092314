import React from "react";
import { connect } from "react-redux";
import Entity from "student-front-commons/src/components/Entity";
import CertificationTestResultDetail from "./CertificationTestResultDetail";

const CertificationTestResultDetailContainer = (props) => (
  <Entity name="profile">
    {({ getById }) => {
      const profile = getById(sessionStorage.getItem("id"));
      if (!profile?.id) {
        return null;
      }
      return (
        <CertificationTestResultDetail
          certificationTest={props.certificationTest}
          unitsToFinishAfterCertificationTestFailure={props.unitsToFinishAfterCertificationTestFailure}
          nextCourse={profile.activeCourses.find((course) => !course.distributor)}
        />
      );
    }}
  </Entity>
);

const mapStateToProps = (state) => ({
  certificationTest: state.certificationTestResult,
  unitsToFinishAfterCertificationTestFailure: state.configurations.unitsToFinishAfterCertificationTestFailure,
});

export default connect(mapStateToProps)(CertificationTestResultDetailContainer);
