import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Async from "../../core/Async";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import CourseThemeProvider from "../../core/CourseThemeProvider";
import MasteryTestExecutionScene from "./MasteryTestExecutionScene";
import { LOAD_CONFIGURATION_FLOW, LOAD_MODULES_FLOW, START_MASTERY_TEST_EXECUTION_FLOW } from "../../consts";

class MasteryTestExecutionSceneContainer extends Component {
  render() {
    return (
      <Entity name="module">
        {({ getAll: getAllModules }) => (
          <Flow
            autoStart={!getAllModules().length}
            id={LOAD_MODULES_FLOW}
            params={{
              course: this.props.match.params.idCourse,
            }}
          >
            {({ isPending: isModulesPending }) => (
              <Flow
                autoStart={false}
                id={LOAD_CONFIGURATION_FLOW}
                params={{
                  course: this.props.match.params.idCourse,
                }}
              >
                {({ isPending: isConfigPending }) => (
                  <Flow
                    autoStart
                    id={START_MASTERY_TEST_EXECUTION_FLOW}
                    params={{
                      course: this.props.match.params.idCourse,
                      module: this.props.match.params.idModule,
                      masteryTest: this.props.match.params.idMasteryTest,
                    }}
                  >
                    {({ isPending }) => (
                      <Async fetching={isModulesPending || isConfigPending || isPending}>
                        <CourseThemeProvider>
                          <MasteryTestExecutionScene />
                        </CourseThemeProvider>
                      </Async>
                    )}
                  </Flow>
                )}
              </Flow>
            )}
          </Flow>
        )}
      </Entity>
    );
  }
}

export default withRouter(MasteryTestExecutionSceneContainer);
