import { get, last, round } from "lodash";
import { call, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import apiRequest from "student-front-commons/src/core/request";
import {
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  START_NEXT_UNIT_ITEM_EXECUTION,
} from "../consts";
import { logError } from "../util";
import { disableItemExecution, enableItemExecutionValidation } from "student-front-commons/src/actions/itemExecution";
import { addExecutionAnswer } from "student-front-commons/src/actions/execution";
import LogRocket from "logrocket";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import { VALIDATION_STEP_ITEMS } from "student-front-commons/src/consts";

export default function* () {
  yield takeLatest(getFlowStart(SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          let execution = yield select((state) => state.executions);

          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.reduce(function* (promise, id) {
            yield promise;

            const executionItem = yield select((state) => state.itemExecutions.byId[id]);

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(executionItem.item.id));

            yield put(
              addExecutionAnswer({
                id: executionItem.item.id,
                correct: get(last(executionItem.attempts), "correct", false),
                itemOrder: associativeItemOrder,
              })
            );
          }, Promise.resolve());

          const checkAnswerFlow = yield select(getFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          if (checkAnswerFlow.isPending) {
            yield take(getFlowEnd(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          }

          const itemExecutionsType = yield select(getItemsExecutionsType);
          if (VALIDATION_STEP_ITEMS.includes(itemExecutionsType)) {
            yield put(enableItemExecutionValidation());
          } else {
            yield put(startFlow(START_NEXT_UNIT_ITEM_EXECUTION));
          }

          // send progress for every answer
          yield spawn(function* () {
            yield call(apiRequest, {
              method: "put",
              url: `modules/tasting/units/${execution.unit}/update-lead`,
              data: {
                progress: round((execution.answers.length / execution.associativeItems.length) * 100, 0),
                linkLogRocket: LogRocket.sessionURL,
              },
            });
          });
        } catch (error) {
          logError({ error, flow: SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW });
        } finally {
          yield put(endFlow(SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
