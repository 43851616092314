import { call, put, select, takeLatest } from "redux-saga/effects";
import moment from "moment";
import { endFlow } from "student-front-commons/src/actions/flow";
import { addEntity, mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getMessages, markAsReadMessage } from "student-front-commons/src/services/messageService";
import { LOAD_MESSAGES_FLOW } from "../consts";
import { logError } from "../util";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_MESSAGES_FLOW), function* () {
    try {
      const id = sessionStorage.getItem("id");
      const flow = yield select(getFlow(LOAD_MESSAGES_FLOW));

      const messages = yield call(getMessages, {
        idMessageChannel: flow.params.messageChannel,
      });

      const profile = yield select(getEntityById(PROFILE_SCHEMA, sessionStorage.getItem("id")));
      delete messages.entities.profile[profile.id];

      yield put(mergeEntities(messages.entities));

      yield* messages.result
        .map((id) => messages.entities["message"][id])
        .filter((message) => !message.readAt && message.sender !== id)
        .map(function* (message) {
          yield call(markAsReadMessage, {
            idMessageChannel: flow.params.messageChannel,
            idMessage: message.id,
          });
          const messageChannel = yield select(getEntityById("messageChannel", flow.params.messageChannel));
          yield put(addEntity("messageChannel", { ...messageChannel, readAt: moment().format(), isRead: true }));
        });
    } catch (error) {
      logError({ error, flow: LOAD_MESSAGES_FLOW });
    } finally {
      yield put(endFlow(LOAD_MESSAGES_FLOW));
    }
  });
}
