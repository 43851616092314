import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Separator from "../../core/Separator";
import CertificationTestContent from "./CertificationTestContent";
import CertificationTestItemsProgressContainer from "./CertificationTestItemsProgressContainer";
import CertificationTestItemExecutionFormContainer from "./CertificationTestItemExecutionFormContainer";
import CertificationTestSubHeaderContainer from "./CertificationTestSubHeaderContainer";
import OldErrorModalContainer from "../../error/OldErrorModalContainer";

class CertificationTestExecutionScene extends Component {
  render() {
    return (
      <div>
        <CertificationTestSubHeaderContainer />
        <CertificationTestContent>
          <CertificationTestItemsProgressContainer />
          <Separator />
          <CertificationTestItemExecutionFormContainer />
        </CertificationTestContent>
        <OldErrorModalContainer />
      </div>
    );
  }
}

export default withRouter(CertificationTestExecutionScene);
