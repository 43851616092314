import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import AsyncPanel from "../../core/AsyncPanel";
import Paragraph from "../../core/Paragraph";
import Separator from "../../core/Separator";
import withTranslation from "../../core/withTranslation";
import SprintList from "./SprintList";

const SprintPanel = (props) => (
  <AsyncPanel
    title="Sprint"
    padding="lg"
    fetching={props.fetching}
    hasData={!props.fetching && !!get(props.sprintStats, "maxSprint", 0)}
    noDataText={props.getTranslation("sprint.noData")}
  >
    <Paragraph>{props.getTranslation("sprint.description")}</Paragraph>
    <Separator size="md" />
    <SprintList
      currentSprint={get(props.sprintStats, "currentSprint", 0)}
      maxSprint={get(props.sprintStats, "maxSprint", 0)}
      companyAverage={get(props.sprintStats, "companyAverage", 0)}
    />
  </AsyncPanel>
);

SprintPanel.propTypes = {
  sprintStats: PropTypes.shape({}),
  fetching: PropTypes.bool.isRequired,
};

SprintPanel.defaultProps = {
  sprintStats: null,
};

export default withTranslation(SprintPanel);
