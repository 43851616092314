import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Icon from "./Icon";
import { GREY_2, GREY_3, WHITE } from "../consts/color";
import RippleEffect from "./RippleEffect";
import CircularLoading from "./CircularLoading";

const Button = Styled.button`
  transition: all ease-in-out 150ms;
  animation: ${(props) => props.animation}
  &:hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
  }
`;

const CircularButton = (props) => (
  <Button
    onClick={props.onClick}
    onDoubleClick={(event) => event.preventDefault()}
    disabled={props.disabled || props.loading}
    animation={props.animation}
    style={{
      ...props.style,
      width: {
        xs: 25,
        sm: 30,
        md: 40,
        lg: 50,
      }[props.size],
      height: {
        xs: 25,
        sm: 30,
        md: 40,
        lg: 50,
      }[props.size],
      // eslint-disable-next-line no-undef
      backgroundColor: props.disabled || props.loading ? GREY_2 : props.color || GREY_3,
      boxSizing: "border-box",
      border: props.border,
      outline: "none",
      borderRadius: "50%",
      cursor: props.disabled ? "not-allowed" : props.loading ? "wait" : "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      overflow: "hidden",
      transition: "all .5s",
    }}
  >
    {props.loading && <CircularLoading />}
    {!props.loading && props.icon && (
      <Icon
        size={props.size === "lg" ? "sm" : "xs"}
        icon={props.icon}
        color={props.color && props.color !== "#fff" ? WHITE : null}
        cursor={props.disabled ? "not-allowed" : props.loading ? "wait" : "pointer"}
      />
    )}
    {props.children}
  </Button>
);

CircularButton.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg", "xs"]),
  children: PropTypes.node,
  style: PropTypes.shape({}),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

CircularButton.defaultProps = {
  icon: null,
  children: null,
  onClick: null,
  color: null,
  size: "md",
  border: "none",
  style: null,
  disabled: false,
  loading: false,
};

export default RippleEffect(CircularButton);
