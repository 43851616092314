import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import CourseThemeContext from "../CourseThemeContext";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG, GREY_4 } from "../../consts/color";
import { last } from "lodash";
import { useFlow } from "student-front-commons/src/hooks";
import { SELECT_ITEM_FLOW } from "../../consts";

export default function MeaningsAssociatingVocabularyAnswer({ itemId, answerId }) {
  const [status, setStatus] = useState("DEFAULT");

  const dispatch = useDispatch();
  const courseThemeContext = useContext(CourseThemeContext);
  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);

  const text = useSelector(getItemExecutionPropById(answerId, "item.text"));
  const isDisabled = useSelector(getItemExecutionPropById(answerId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(answerId, "isFinished"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(answerId, "showCorrectOption"));
  const isExecutionValidated = useSelector(getItemExecutionPropById(answerId, "isExecutionValidated"));

  const correctText = useSelector(getItemExecutionPropById(itemId, "item.text"));
  const attempts = useSelector(getItemExecutionPropById(itemId, "attempts"));

  const selectedId = useSelector((state) => state.itemExecutions?.selectedId);

  const feedbackColor = { CORRECT: FEEDBACK_CORRECT, WRONG: FEEDBACK_WRONG }[
    last(attempts)?.correct ? "CORRECT" : "WRONG"
  ];

  const rightColumnBackgroundColor = {
    DEFAULT: courseThemeContext.primary,
    IS_SELECTED: courseThemeContext.primary,
    CAN_REPLACE: "#ffffff",
    FEEDBACK: isExecutionValidated && !last(attempts)?.correct ? GREY_4 : feedbackColor,
  }[status];

  const rightColumnBorderStyle = {
    DEFAULT: "solid",
    IS_SELECTED: "solid",
    CAN_REPLACE: "dashed",
    FEEDBACK: "solid",
  }[status];

  const rightColumnBorderColor = {
    DEFAULT: courseThemeContext.primary,
    IS_SELECTED: courseThemeContext.primary,
    CAN_REPLACE: courseThemeContext.primary,
    FEEDBACK: isExecutionValidated && !last(attempts)?.correct ? FEEDBACK_WRONG : feedbackColor,
  }[status];

  const rightColumnTextColor = {
    DEFAULT: "#ffffff",
    IS_SELECTED: "#ffffff",
    CAN_REPLACE: courseThemeContext.primary,
    FEEDBACK: isExecutionValidated && !last(attempts)?.correct ? FEEDBACK_WRONG : "#ffffff",
  }[status];

  useEffect(() => {
    setStatus("DEFAULT");
  }, []);

  useEffect(() => {
    if (isFinished && !last(attempts)?.correct) {
      setStatus("DEFAULT");
    }
  }, [isFinished]);

  useEffect(() => {
    if (shouldShowCorrectOption || isExecutionValidated) {
      setStatus("FEEDBACK");
    }
  }, [shouldShowCorrectOption, isExecutionValidated]);

  useEffect(() => {
    if (!isFinished && !shouldShowCorrectOption) {
      if (selectedId === answerId) {
        setStatus("IS_SELECTED");
      } else if (selectedId !== answerId && selectedId !== null) {
        setStatus("CAN_REPLACE");
      } else {
        setStatus("DEFAULT");
      }
    }
  }, [selectedId, answerId]);

  const handleClick = () => {
    if (["IS_SELECTED", "IS_ASSOCIATED"].some((s) => s === status)) {
      startSelectItemFlow({ itemId: null });
      return;
    }

    if (status === "CAN_REPLACE") {
      dispatch(addItemExecutionAnswer(itemId, { answer: selectedId }));
    } else {
      startSelectItemFlow({ itemId: answerId });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100px",
        marginBottom: 0,
        pointerEvents: isDisabled ? "none" : "all",
      }}
    >
      <button
        onClick={handleClick}
        disabled={isDisabled}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: rightColumnBackgroundColor,
          padding: "10px",
          borderRadius: "5px",
          width: "220px",
          minHeight: "60px",
          cursor: "pointer",
          transition: "all .25s",
          transform: status === "IS_SELECTED" ? "scale(1.1)" : "none",
          borderStyle: rightColumnBorderStyle,
          borderColor: rightColumnBorderColor,
          borderWidth: "2px",
          position: "relative",
          outline: "none",
        }}
      >
        <span
          style={{
            fontSize: "22px",
            lineHeight: "26px",
            color: rightColumnTextColor,
            textAlign: "center",
          }}
        >
          {shouldShowCorrectOption && !isExecutionValidated ? correctText : text}
        </span>
      </button>
      {isExecutionValidated && !last(attempts)?.correct && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: GREY_4,
            padding: "10px",
            borderRadius: "5px",
            width: "220px",
            minHeight: "60px",
            marginTop: "5px",
            border: `2px solid ${FEEDBACK_CORRECT}`,
          }}
        >
          <span
            style={{
              fontSize: "22px",
              lineHeight: "26px",
              color: FEEDBACK_CORRECT,
              textAlign: "center",
            }}
          >
            {correctText}
          </span>
        </div>
      )}
    </div>
  );
}

MeaningsAssociatingVocabularyAnswer.propTypes = {
  itemId: PropTypes.string.isRequired,
  answerId: PropTypes.string.isRequired,
};
