import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { all, call, delay, put, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  addImageDataToItems,
  addSentryUserAction,
  addSoundToItems,
  logError,
  reduceImageDataToItems,
  reduceSoundToItems,
} from "../util";
import { clearAudios } from "../stores/audio-store";
import {
  DISMISS_SYSTEM_MESSAGE_FLOW,
  GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW,
  BONUS_TEST_EXECUTION_FORM,
  START_BONUS_TEST_EXECUTION_FLOW,
} from "../consts";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { requestCleanBonusTestResult } from "../actionCreators/bonusTestResult";
import { startBonusTest } from "student-front-commons/src/services/bonusTestService";

const sentryUserAction = { mainComponent: "startBonusTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(START_BONUS_TEST_EXECUTION_FLOW), function* () {
    try {
      yield call(clearAudios);
      yield put(requestCleanBonusTestResult());

      const bonusTestExecution = yield call(startBonusTest);

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Started execution of Bonus Test`,
      });

      const { items, extraItems } = orderBy(bonusTestExecution.items, ["placementTestLevel.level", "order"]).reduce(
        (acc, grammarLevelItems) => {
          const items = shuffle(grammarLevelItems.items).map((item) => ({
            item: {
              ...item,
            },
            placementTestLevel: grammarLevelItems.placementTestLevel,
            order: grammarLevelItems.order,
          }));
          return {
            ...acc,
            items: [...acc.items, ...items.slice(0, grammarLevelItems.itemsToShow)],
            extraItems: [...acc.extraItems, ...items.slice(grammarLevelItems.itemsToShow)],
          };
        },
        { items: [], extraItems: [] }
      );

      yield all(
        addImageDataToItems(
          yield all(addSoundToItems(items.filter((associativeItem) => associativeItem.placementTestLevel.level <= 0.5)))
        )
      );
      const itemsToNotLoadAssets = items.filter((associativeItem) => associativeItem.placementTestLevel.level > 0.5);

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Added sounds and images to Items`,
      });

      yield all(
        addImageDataToItems(
          yield all(
            addSoundToItems(extraItems.filter((associativeItem) => associativeItem.placementTestLevel.level <= 0.5))
          )
        )
      );
      const extraItemsToNotLoadAssets = extraItems.filter(
        (associativeItem) => associativeItem.placementTestLevel.level > 0.5
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Added sounds and images to Extra Items`,
      });

      yield put(
        initForm(BONUS_TEST_EXECUTION_FORM, {
          bonusTestItems: items,
          extraBonusTestItems: extraItems,
          execution: bonusTestExecution.bonusTest.id,
          answers: [],
          currentIndex: -1,
          extraItemsAddedOnLevel: 0,
          extraItemsRemainingOnLevel: 0,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Bonus Test Execution Form`,
      });

      yield put(startFlow(GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW));

      yield spawn(function* () {
        yield delay(30000);
        yield call(reduceImageDataToItems, itemsToNotLoadAssets);
        yield call(reduceSoundToItems, itemsToNotLoadAssets);
        yield call(reduceImageDataToItems, extraItemsToNotLoadAssets);
        yield call(addSoundToItems, extraItemsToNotLoadAssets);
      });
    } catch (error) {
      logError({ error, flow: START_BONUS_TEST_EXECUTION_FLOW });
      yield put(
        showMessage({
          message: "placementTest.startError",
          button: "placementTest.button.tryAgain",
        })
      );

      yield put(endFlow(START_BONUS_TEST_EXECUTION_FLOW));
      yield take(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW));
      yield put(startFlow(START_BONUS_TEST_EXECUTION_FLOW));
    } finally {
      yield put(endFlow(START_BONUS_TEST_EXECUTION_FLOW));
    }
  });
}
