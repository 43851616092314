import React, { useCallback, useEffect, useState } from "react";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW, SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function SingleChoiceAudioRender(props) {
  const dispatch = useDispatch();
  const [answerAudioPlayed, setAnswerAudioPlayed] = useState({ 0: false, 1: false, 2: false, 3: false });
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [isCheckingAnswer, startCheckAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const linkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const selectedAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const handleSubmit = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(props.itemId, { answer }));
      startCheckAnswerFlow();
    },
    [dispatch, startCheckAnswerFlow, props.itemId]
  );

  useEffect(() => {
    const answerIndex = linkedAnswers.findIndex((answer) => answer.id === playingId);
    if (answerAudioPlayed[answerIndex] === false) {
      setAnswerAudioPlayed({
        ...answerAudioPlayed,
        [answerIndex]: true,
      });
    }
  }, [playingId]);

  useEffect(() => {
    if (selectedAnswer) {
      setAnswerAudioPlayed({ 0: false, 1: false, 2: false, 3: false });
    }
  }, [selectedAnswer]);

  return (
    <ExerciseItemPanel>
      <div
        style={{
          display: "grid",
          gridGap: "5%",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          marginBottom: 100,
        }}
      >
        {linkedAnswers.map((answer, index) => (
          <ExerciseButton
            key={answer.id}
            disabled={
              isExecutionValidated ||
              !answerAudioPlayed[index] ||
              isCheckingAnswer ||
              (isDisabled && shouldShowCorrectOption) ||
              !!currentInstructionStep
            }
            highlight={playingId === answer.id || ((isExecutionValidated || isSaving) && answer.correct)}
            isWrong={(isExecutionValidated || isSaving) && !answer.correct && selectedAnswer === answer.text}
            onClick={() => handleSubmit(answer)}
          >
            <h1
              style={{
                width: "100%",
                textAlign: "center",
                fontSize: 72,
              }}
            >
              {["A", "B", "C", "D"][index]}
            </h1>
          </ExerciseButton>
        ))}
      </div>
    </ExerciseItemPanel>
  );
}

export default function SingleChoiceAudioItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SingleChoiceAudioRender key={id} itemId={id} />);
}
