import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import round from "lodash/round";
import Panel from "../../core/Panel";
import { WHITE } from "../../consts/color";
import GradeHeaderLabelItem from "./GradeHeaderLabelItem";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";

const GradeHeader = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor, title }) => (
      <Panel padding="none">
        <div
          style={{
            height: 40,
            backgroundColor: secondaryColor,
            boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
            display: "flex",
            alignItems: "center",
            padding: "0px 15px",
            position: "relative",
            zIndex: 2,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
          }}
        >
          <TranslatedText
            as="label"
            translateKey="grades.title"
            style={{
              textTransform: "uppercase",
              color: WHITE,
              fontSize: 14,
              fontWeight: 700,
              margin: 0,
            }}
            values={{
              whitelabel: title,
              year: moment().format("YYYY"),
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px 20px",
          }}
        >
          <div>
            <GradeHeaderLabelItem label="student" value={props.student} width={85} />
            {props.classRoom && <GradeHeaderLabelItem label="classRoom" value={props.classRoom} width={85} />}
            {props.teachers && (
              <GradeHeaderLabelItem label="teachers" value={props.teachers} width={85} />
            )}
          </div>
          <div>
            <GradeHeaderLabelItem label="school" value={props.school} width={200} />
            <GradeHeaderLabelItem
              label="requiredStudyTime"
              value={
                props.weeklyHoursRequired ? `${props.weeklyHoursRequired} hrs / ${props.getTranslation("week")}` : "N/A"
              }
              width={200}
            />
            {props.gradeFormat && (
              <GradeHeaderLabelItem
                label="maximumGrade"
                value={props.gradeFormat ? round(props.maximumGrade, props.gradeFormat.indexOf(".") ? 1 : 0) : "N/A"}
                width={200}
              />
            )}
          </div>
        </div>
      </Panel>
    )}
  </WhitelabelContext.Consumer>
);

GradeHeader.propTypes = {
  student: PropTypes.string,
  classRoom: PropTypes.string,
  weeklyHoursRequired: PropTypes.number,
  teacher: PropTypes.string,
  school: PropTypes.string,
  gradeFormat: PropTypes.string,
  maximumGrade: PropTypes.number,
};

export default withTranslation(GradeHeader);
