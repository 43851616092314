import React from "react";
import PropTypes from "prop-types";
import FlexColumn from "../../core/FlexColumn";
import TranslatedText from "../../core/TranslatedText";
import Icon from "../../core/Icon";
import Separator from "../../core/Separator";
import PopoverButton from "../../core/PopoverButton";
import Paragraph from "../../core/Paragraph";

export default class UnitStepItem extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string.isRequired,
    points: PropTypes.number.isRequired,
    tooltip: PropTypes.string.isRequired,
    status: PropTypes.oneOf(["COMPLETED", "AVAILABLE", "BLOCKED", "COUNTDOWN"]).isRequired,
  };

  state = {
    showPopover: false,
  };

  togglePopover = () => {
    this.setState({
      showPopover: !this.state.showPopover,
    });
  };

  render() {
    return (
      <PopoverButton
        position="bottom"
        content={
          <TranslatedText
            as={Paragraph}
            translateKey={this.props.tooltip}
            style={{
              fontSize: 13,
              maxWidth: 220,
            }}
          />
        }
        isOpen={this.state.showPopover}
        onClose={this.togglePopover}
      >
        <FlexColumn position="relative" zIndex={2} alignItems="center" width={63}>
          <button
            onClick={this.togglePopover}
            disabled={this.props.status !== "AVAILABLE"}
            style={{
              border: "none",
              background: "none",
              outline: "none",
            }}
          >
            <FlexColumn
              width={32}
              height={32}
              borderRadius={32}
              backgroundColor={
                {
                  COMPLETED: "#00786A",
                  AVAILABLE: "#FF9F51",
                  BLOCKED: "#fff",
                  COUNTDOWN: "#fff",
                }[this.props.status]
              }
              border="2px solid #fff"
              borderColor={
                {
                  COMPLETED: "#00786A",
                  AVAILABLE: "#FF9F51",
                  BLOCKED: "#00000033",
                  COUNTDOWN: "#00000033",
                }[this.props.status]
              }
              boxSizing="border-box"
              justifyContent="center"
              alignItems="center"
            >
              <Icon
                color={
                  {
                    COMPLETED: "#fff",
                    AVAILABLE: "#fff",
                    BLOCKED: "#00000033",
                    COUNTDOWN: "#00000033",
                  }[this.props.status]
                }
                icon={
                  {
                    COMPLETED: "check",
                    AVAILABLE: "lock-open-variant-outline",
                    BLOCKED: "lock-outline",
                    COUNTDOWN: "timer-sand",
                  }[this.props.status]
                }
                size="xs"
              />
            </FlexColumn>
          </button>
          <Separator size="xxs" />
          <TranslatedText
            as="label"
            translateKey={`unit.steps.${this.props.type.toLowerCase()}`}
            style={{
              color: "#424242",
              opacity: this.props.status === "BLOCKED" ? 0.5 : 1,
              fontSize: 13,
              lineHeight: "15px",
              margin: 0,
            }}
          />
          {(this.props.status === "COMPLETED" || this.props.status === "AVAILABLE") && (
            <TranslatedText
              as="label"
              translateKey="unit.points"
              values={{
                points: this.props.points,
              }}
              style={{
                color: this.props.status === "COMPLETED" ? "#00786A" : "#343434",
                fontSize: 12,
                fontWeight: "bold",
                lineHeight: "14px",
                margin: 0,
              }}
            />
          )}
        </FlexColumn>
      </PopoverButton>
    );
  }
}
