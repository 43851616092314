import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import Styled, { keyframes } from "styled-components";

const rippleAnimation = keyframes`
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

const Effect = Styled.div`
  position: absolute;
  opacity: 1;
  border-radius: 50%;
  background: rgba(255, 255, 255, .4);
  transform: scale(0);
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  animation: ${rippleAnimation} .4s linear;
`;

const RippleEffect = (WrappedComponent) =>
  class extends Component {
    static propTypes = {
      onClick: PropTypes.func,
      children: PropTypes.node,
    };

    static defaultProps = {
      onClick: null,
      children: null,
    };

    state = { ripples: [] };

    handleClick = (event) => {
      let buttonDom = $(event.target);
      while (!buttonDom.is("button")) {
        buttonDom = buttonDom.parent();
      }

      const posX = buttonDom.offset().left;
      const posY = buttonDom.offset().top;
      let buttonWidth = buttonDom.width();
      let buttonHeight = buttonDom.height();

      if (buttonWidth >= buttonHeight) {
        buttonHeight = buttonWidth;
      } else {
        buttonWidth = buttonHeight;
      }

      // Get the center of the element
      const x = event.pageX - posX - buttonWidth / 2;
      const y = event.pageY - posY - buttonHeight / 2;

      const id = Math.random().toString(36).slice(-8);
      this.setState({
        ripples: [
          ...this.state.ripples,
          {
            id,
            top: y,
            left: x,
            width: buttonWidth,
            height: buttonHeight,
          },
        ],
      });

      this.effectTimeout = setTimeout(() => {
        this.setState({
          ripples: this.state.ripples.filter((ripple) => ripple.id !== id),
        });
      }, 590);

      if (this.props.onClick) {
        this.props.onClick(event);
      }
    };

    componentWillUnmount() {
      clearTimeout(this.effectTimeout);
    }

    render() {
      return (
        <WrappedComponent {...this.props} onClick={this.handleClick}>
          {this.props.children && this.props.children}
          {this.state.ripples.map((ripple) => (
            <Effect key={ripple.id} top={ripple.top} left={ripple.left} width={ripple.width} height={ripple.height} />
          ))}
        </WrappedComponent>
      );
    }
  };

export default RippleEffect;
