import React from "react";
import PropTypes from "prop-types";
import { Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from "@material-ui/core";

const RadioInput = (props) => (
  <FormControl component="fieldset" error={props.error}>
    <FormLabel component="legend">{props.name}</FormLabel>
    <RadioGroup row={props.row} value={props.value} onChange={(event, value) => props.onChange(value)}>
      {props.showOptionsBorders ? (
        props.options.map((option, index) => (
          <>
            <div style={{
              ...(props.optionsStyle ? {
                ...props.optionsStyle
              } : {
                height: "30px",
                padding: "20px 28px",
                borderRadius: "4px",
                border: "1px solid #e4e4e4",
                display: "flex",
                alignItems: "center"
              })
            }}>
              <FormControlLabel
                key={`radio-${option.value}`}
                value={option.value}
                label={option.label}
                disabled={props.disabled}
                control={<Radio />}
              />
            </div>
            {index + 1 < props.options.length && (<div style={{ height: "8px" }} />)}
          </>
        ))
      ) : (
        props.options.map((option) => (
          <FormControlLabel
            key={`radio-${option.value}`}
            value={option.value}
            label={option.label}
            disabled={props.disabled}
            control={<Radio />}
          />
        ))
      )}
    </RadioGroup>
  </FormControl>
);

RadioInput.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.any,
  row: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    })
  ).isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  showOptionsBorders: PropTypes.bool,
  optionsStyle: PropTypes.object,
};

RadioInput.defaultProps = {
  error: null,
  value: null,
  disabled: false,
  onChange: null,
  row: false,
  style: null,
  showOptionsBorders: false,
  optionsStyle: null,
};

export default RadioInput;
