export const ADD_ENTITY = "ADD_ENTITY";
export const MERGE_ENTITIES = "MERGE_ENTITIES";
export const CLEAN_ENTITIES = "CLEAN_ENTITIES";
export const REPLACE_ENTITIES = "REPLACE_ENTITIES";
export const REMOVE_ENTITY = "REMOVE_ENTITY";

/**
 * create an action to add a specific entity
 *
 * @param entityName
 * @param entity
 * @returns {{type, payload: {entityName: *, entity: *}}}
 */
export const addEntity = (entityName, entity) => ({
  type: ADD_ENTITY,
  payload: {
    entityName,
    entity,
  },
});

/**
 * create an action to remove a specific entity
 *
 * @param entityName
 * @param entityId
 * @returns {{type, payload: {entityName: *, entity: *}}}
 */
export const removeEntity = (entityName, entityId) => ({
  type: REMOVE_ENTITY,
  payload: {
    entityName,
    entityId,
  },
});

/**
 * create an action to merge the entities
 *
 * @param entities
 * @returns {{type, payload: {entities: *}}}
 */
export const mergeEntities = (entities) => ({
  type: MERGE_ENTITIES,
  payload: {
    entities,
  },
});

/**
 * create an action to replace the entities
 *
 * @param entities
 * @returns {{type, payload: {entities: *}}}
 */
export const replaceEntities = (entities) => ({
  type: REPLACE_ENTITIES,
  payload: {
    entities,
  },
});

/**
 * create an action to reset the entity state tree
 *
 * @param entities
 * @returns {{type, payload: {entities: *}}}
 */
export const cleanEntities = () => ({
  type: CLEAN_ENTITIES,
});
