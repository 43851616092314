import { actionChannel, call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { buffers } from "redux-saga";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  CLOSE_USER_AWAY_MODAL,
  END_TASTING_UNIT_EXECUTION_FLOW,
  END_UNIT_EXECUTION_FLOW,
  USER_LAST_ACTION_FLOW,
} from "../consts";
import { logError } from "../util";
import {
  ADD_ITEM_EXECUTION_ANSWER,
  LISTEN_ITEM,
  READ_ITEM,
  SAVE_RECORD_ITEM_RESULT,
  START_RECORD_ITEM,
  SUBMIT_RECORD_ITEM,
  TRANSLATE_ITEM,
  START_PLAY_ITEM,
  REPEAT_ITEM,
} from "student-front-commons/src/actions/itemExecution";
import { updateLastStudentAction } from "student-front-commons/src/services/unitExecutionService";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { addEntity } from "student-front-commons/src/actions/entity";

export default function* () {
  yield takeLatest(getFlowStart(USER_LAST_ACTION_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      endUnitExecution: take(getFlowStart(END_UNIT_EXECUTION_FLOW)),
      endTastingUnitExecution: take(getFlowStart(END_TASTING_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const execution = yield select((state) => state.executions);
          const unit = yield select(getEntityById("unit", execution.unit));
          const scheduledUnitReview = yield select(getEntityById("scheduledUnitReview", execution.unit));

          while (true) {
            const userUnitItemActionChannel = yield actionChannel(
              (action) =>
                action.type === READ_ITEM ||
                action.type === TRANSLATE_ITEM ||
                action.type === LISTEN_ITEM ||
                action.type === REPEAT_ITEM ||
                action.type === ADD_ITEM_EXECUTION_ANSWER ||
                action.type === START_RECORD_ITEM ||
                action.type === START_PLAY_ITEM ||
                action.type === SUBMIT_RECORD_ITEM ||
                action.type === SAVE_RECORD_ITEM_RESULT ||
                getFlowEnd(CLOSE_USER_AWAY_MODAL)(action),
              buffers.sliding(2)
            );

            yield take(userUnitItemActionChannel);

            const result = yield call(updateLastStudentAction, {
              module: execution.module,
              unit: execution.unit,
              unitExecution: execution.id,
            });

            if (execution.isReview) {
              yield put(
                addEntity("scheduledUnitReview", {
                  ...scheduledUnitReview,
                  lastExecutionLastActionAt: result.lastActionAt,
                })
              );
            } else {
              yield put(
                addEntity("unit", {
                  ...unit,
                  lastExecutionLastActionAt: result.lastActionAt,
                })
              );
            }
          }
        } catch (error) {
          logError({ error, flow: USER_LAST_ACTION_FLOW });
        } finally {
          yield put(endFlow(USER_LAST_ACTION_FLOW));
        }
      }),
    });
  });
}
