import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import CircularAbilityScore from "../../core/CircularAbilityScore";
import { GREEN_3 } from "../../consts/color";
import TranslatedText from "../../core/TranslatedText";
import FlexRow from "../../core/FlexRow";
import round from "lodash/round";
import min from "lodash/min";

const ModuleDetailsScore = (props) => (
  <div>
    <FlexRow alignItems="center" justifyContent="space-between">
      <TranslatedText
        as="h1"
        translateKey="module.moduleDetailDescription"
        style={{
          margin: 0,
          color: "#607d8b",
          textTransform: "uppercase",
          fontSize: 13,
        }}
      />
      <TranslatedText
        as="span"
        translateKey="module.percentageComplete"
        values={{
          percentage: round(min([props.module.percentageComplete, 100])),
        }}
        style={{
          position: "relative",
          color: "rgba(96, 125, 139, 0.8)",
          fontSize: "11px",
          marginLeft: 10,
        }}
      />
    </FlexRow>
    <Separator size={!!props.isSpeechRecognitionDisabled ? "xs" : "md"} />
    {!!props.isSpeechRecognitionDisabled ? (
      <FlexRow width="100%" height={5} bottom={0} left={0} overflow="hidden" backgroundColor="#e0e0e0">
        <FlexRow width={`${props.module.percentageComplete}%`} height="100%" backgroundColor={props.color} />
      </FlexRow>
    ) : (
      <FlexRow justifyContent="center">
        <CircularAbilityScore
          totalValue={props.module.listeningPoints}
          value={props.module.conqueredListeningPoints}
          title="Listening"
          type="listening"
          color={props.module.conqueredListeningPoints >= props.module.listeningPoints ? GREEN_3 : props.color}
        />
        <Separator size="xs" />
        <CircularAbilityScore
          totalValue={props.module.speakingPoints}
          value={props.module.conqueredSpeakingPoints}
          title="Speaking"
          type="speaking"
          color={props.module.conqueredSpeakingPoints >= props.module.speakingPoints ? GREEN_3 : props.color}
        />
        <Separator size="xs" />
        <CircularAbilityScore
          totalValue={props.module.readingPoints}
          value={props.module.conqueredReadingPoints}
          title="Reading"
          type="reading"
          color={props.module.conqueredReadingPoints >= props.module.readingPoints ? GREEN_3 : props.color}
        />
        <Separator size="xs" />
        <CircularAbilityScore
          totalValue={props.module.writingPoints}
          value={props.module.conqueredWritingPoints}
          title="Writing"
          type="writing"
          color={props.module.conqueredWritingPoints >= props.module.writingPoints ? GREEN_3 : props.color}
        />
      </FlexRow>
    )}
  </div>
);

ModuleDetailsScore.propTypes = {
  color: PropTypes.string.isRequired,
  isSpeechRecognitionDisabled: PropTypes.bool.isRequired,
  module: PropTypes.shape({
    readingPoints: PropTypes.number.isRequired,
    writingPoints: PropTypes.number.isRequired,
    speakingPoints: PropTypes.number.isRequired,
    listeningPoints: PropTypes.number.isRequired,
    conqueredWritingPoints: PropTypes.number.isRequired,
    conqueredReadingPoints: PropTypes.number.isRequired,
    conqueredSpeakingPoints: PropTypes.number.isRequired,
    conqueredListeningPoints: PropTypes.number.isRequired,
  }).isRequired,
};

export default ModuleDetailsScore;
