export const COUNTRIES = [
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/af.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/af.png",
    name: "Afghanistan",
    dialCode: "+93",
    code: "AF",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/al.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/al.png",
    name: "Albania",
    dialCode: "+355",
    code: "AL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/dz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/dz.png",
    name: "Algeria",
    dialCode: "+213",
    code: "DZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ad.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ad.png",
    name: "Andorra",
    dialCode: "+376",
    code: "AD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ao.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ao.png",
    name: "Angola",
    dialCode: "+244",
    code: "AO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ag.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ag.png",
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    code: "AG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ar.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ar.png",
    name: "Argentina",
    dialCode: "+54",
    code: "AR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/am.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/am.png",
    name: "Armenia",
    dialCode: "+374",
    code: "AM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/au.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/au.png",
    name: "Australia",
    dialCode: "+61",
    code: "AU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/at.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/at.png",
    name: "Austria",
    dialCode: "+43",
    code: "AT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/az.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/az.png",
    name: "Azerbaijan",
    dialCode: "+994",
    code: "AZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bs.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bs.png",
    name: "Bahamas",
    dialCode: "+1242",
    code: "BS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bh.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bh.png",
    name: "Bahrain",
    dialCode: "+973",
    code: "BH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bd.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bd.png",
    name: "Bangladesh",
    dialCode: "+880",
    code: "BD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bb.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bb.png",
    name: "Barbados",
    dialCode: "+1246",
    code: "BB",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/by.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/by.png",
    name: "Belarus",
    dialCode: "+375",
    code: "BY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/be.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/be.png",
    name: "Belgium",
    dialCode: "+32",
    code: "BE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bz.png",
    name: "Belize",
    dialCode: "+501",
    code: "BZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bj.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bj.png",
    name: "Benin",
    dialCode: "+229",
    code: "BJ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bt.png",
    name: "Bhutan",
    dialCode: "+975",
    code: "BT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bo.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bo.png",
    name: "Bolivia",
    dialCode: "+591",
    code: "BOL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ba.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ba.png",
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    code: "BA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bw.png",
    name: "Botswana",
    dialCode: "+267",
    code: "BW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/br.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/br.png",
    name: "Brazil",
    dialCode: "+55",
    code: "BR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bg.png",
    name: "Bulgaria",
    dialCode: "+359",
    code: "BG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bf.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bf.png",
    name: "Burkina Faso",
    dialCode: "+226",
    code: "BF",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/bi.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/bi.png",
    name: "Burundi",
    dialCode: "+257",
    code: "BI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kh.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kh.png",
    name: "Cambodia",
    dialCode: "+855",
    code: "KH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cm.png",
    name: "Cameroon",
    dialCode: "+237",
    code: "CM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ca.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ca.png",
    name: "Canada",
    dialCode: "+1",
    code: "CA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cv.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cv.png",
    name: "Cape Verde",
    dialCode: "+238",
    code: "CV",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cf.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cf.png",
    name: "Central African Republic",
    dialCode: "+236",
    code: "CF",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/td.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/td.png",
    name: "Chad",
    dialCode: "+235",
    code: "TD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cl.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cl.png",
    name: "Chile",
    dialCode: "+56",
    code: "CL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cn.png",
    name: "China",
    dialCode: "+86",
    code: "CN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/co.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/co.png",
    name: "Colombia",
    dialCode: "+57",
    code: "CO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/km.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/km.png",
    name: "Comoros",
    dialCode: "+269",
    code: "KM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cd.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cd.png",
    name: "Congo, The Democratic Republic of the Congo",
    dialCode: "+243",
    code: "CD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ck.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ck.png",
    name: "Cook Islands",
    dialCode: "+682",
    code: "CK",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cr.png",
    name: "Costa Rica",
    dialCode: "+506",
    code: "CR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ci.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ci.png",
    name: "Cote d'Ivoire",
    dialCode: "+225",
    code: "CI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/hr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/hr.png",
    name: "Croatia",
    dialCode: "+385",
    code: "HR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cu.png",
    name: "Cuba",
    dialCode: "+53",
    code: "CU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cy.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cy.png",
    name: "Cyprus",
    dialCode: "+357",
    code: "CY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/cz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/cz.png",
    name: "Czech Republic",
    dialCode: "+420",
    code: "CZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/dk.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/dk.png",
    name: "Denmark",
    dialCode: "+45",
    code: "DK",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/dj.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/dj.png",
    name: "Djibouti",
    dialCode: "+253",
    code: "DJ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/dm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/dm.png",
    name: "Dominica",
    dialCode: "+1767",
    code: "DM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/do.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/do.png",
    name: "Dominican Republic",
    dialCode: "+1849",
    code: "DO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ec.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ec.png",
    name: "Ecuador",
    dialCode: "+593",
    code: "EC",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/eg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/eg.png",
    name: "Egypt",
    dialCode: "+20",
    code: "EG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sv.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sv.png",
    name: "El Salvador",
    dialCode: "+503",
    code: "SV",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gq.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gq.png",
    name: "Equatorial Guinea",
    dialCode: "+240",
    code: "GQ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/er.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/er.png",
    name: "Eritrea",
    dialCode: "+291",
    code: "ER",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ee.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ee.png",
    name: "Estonia",
    dialCode: "+372",
    code: "EE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/et.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/et.png",
    name: "Ethiopia",
    dialCode: "+251",
    code: "ET",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/fj.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/fj.png",
    name: "Fiji",
    dialCode: "+679",
    code: "FJ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/fi.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/fi.png",
    name: "Finland",
    dialCode: "+358",
    code: "FI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/fr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/fr.png",
    name: "France",
    dialCode: "+33",
    code: "FR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/fr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/fr.png",
    name: "French Guiana",
    dialCode: "+594",
    code: "GF",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ga.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ga.png",
    name: "Gabon",
    dialCode: "+241",
    code: "GA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gm.png",
    name: "Gambia",
    dialCode: "+220",
    code: "GM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ge.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ge.png",
    name: "Georgia",
    dialCode: "+995",
    code: "GE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/de.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/de.png",
    name: "Germany",
    dialCode: "+49",
    code: "DE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gh.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gh.png",
    name: "Ghana",
    dialCode: "+233",
    code: "GH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gr.png",
    name: "Greece",
    dialCode: "+30",
    code: "GR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gd.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gd.png",
    name: "Grenada",
    dialCode: "+1473",
    code: "GD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gt.png",
    name: "Guatemala",
    dialCode: "+502",
    code: "GT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gn.png",
    name: "Guinea",
    dialCode: "+224",
    code: "GN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gy.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gy.png",
    name: "Guyana",
    dialCode: "+595",
    code: "GY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ht.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ht.png",
    name: "Haiti",
    dialCode: "+509",
    code: "HT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/hn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/hn.png",
    name: "Honduras",
    dialCode: "+504",
    code: "HN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/hu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/hu.png",
    name: "Hungary",
    dialCode: "+36",
    code: "HU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/is.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/is.png",
    name: "Iceland",
    dialCode: "+354",
    code: "IS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/in.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/in.png",
    name: "India",
    dialCode: "+91",
    code: "IN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/id.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/id.png",
    name: "Indonesia",
    dialCode: "+62",
    code: "ID",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/iq.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/iq.png",
    name: "Iraq",
    dialCode: "+964",
    code: "IQ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ie.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ie.png",
    name: "Ireland",
    dialCode: "+353",
    code: "IE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/il.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/il.png",
    name: "Israel",
    dialCode: "+972",
    code: "IL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/it.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/it.png",
    name: "Italy",
    dialCode: "+39",
    code: "IT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/jm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/jm.png",
    name: "Jamaica",
    dialCode: "+1876",
    code: "JM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/jp.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/jp.png",
    name: "Japan",
    dialCode: "+81",
    code: "JP",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/jo.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/jo.png",
    name: "Jordan",
    dialCode: "+962",
    code: "JO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kz.png",
    name: "Kazakhstan",
    dialCode: "+77",
    code: "KZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ke.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ke.png",
    name: "Kenya",
    dialCode: "+254",
    code: "KE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ki.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ki.png",
    name: "Kiribati",
    dialCode: "+686",
    code: "KI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kp.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kp.png",
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    code: "KP",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kr.png",
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    code: "KR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kw.png",
    name: "Kuwait",
    dialCode: "+965",
    code: "KW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kg.png",
    name: "Kyrgyzstan",
    dialCode: "+996",
    code: "KG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/la.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/la.png",
    name: "Laos",
    dialCode: "+856",
    code: "LA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lv.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lv.png",
    name: "Latvia",
    dialCode: "+371",
    code: "LV",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lb.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lb.png",
    name: "Lebanon",
    dialCode: "+961",
    code: "LB",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ls.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ls.png",
    name: "Lesotho",
    dialCode: "+266",
    code: "LS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lr.png",
    name: "Liberia",
    dialCode: "+231",
    code: "LR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/li.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/li.png",
    name: "Liechtenstein",
    dialCode: "+423",
    code: "LI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lt.png",
    name: "Lithuania",
    dialCode: "+370",
    code: "LT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lu.png",
    name: "Luxembourg",
    dialCode: "+352",
    code: "LU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mk.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mk.png",
    name: "Macedonia",
    dialCode: "+389",
    code: "MK",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mg.png",
    name: "Madagascar",
    dialCode: "+261",
    code: "MG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mw.png",
    name: "Malawi",
    dialCode: "+265",
    code: "MW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/my.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/my.png",
    name: "Malaysia",
    dialCode: "+60",
    code: "MY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mv.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mv.png",
    name: "Maldives",
    dialCode: "+960",
    code: "MV",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ml.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ml.png",
    name: "Mali",
    dialCode: "+223",
    code: "ML",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mt.png",
    name: "Malta",
    dialCode: "+356",
    code: "MT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mh.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mh.png",
    name: "Marshall Islands",
    dialCode: "+692",
    code: "MH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mr.png",
    name: "Mauritania",
    dialCode: "+222",
    code: "MR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mu.png",
    name: "Mauritius",
    dialCode: "+230",
    code: "MU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mx.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mx.png",
    name: "Mexico",
    dialCode: "+52",
    code: "MX",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/md.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/md.png",
    name: "Moldova",
    dialCode: "+373",
    code: "MD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mc.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mc.png",
    name: "Monaco",
    dialCode: "+377",
    code: "MC",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mn.png",
    name: "Mongolia",
    dialCode: "+976",
    code: "MN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/me.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/me.png",
    name: "Montenegro",
    dialCode: "+382",
    code: "ME",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ma.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ma.png",
    name: "Morocco",
    dialCode: "+212",
    code: "MA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mz.png",
    name: "Mozambique",
    dialCode: "+258",
    code: "MZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/mm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/mm.png",
    name: "Myanmar",
    dialCode: "+95",
    code: "MM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/na.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/na.png",
    name: "Namibia",
    dialCode: "+264",
    code: "NA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/nr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/nr.png",
    name: "Nauru",
    dialCode: "+674",
    code: "NR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/np.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/np.png",
    name: "Nepal",
    dialCode: "+977",
    code: "NP",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/nl.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/nl.png",
    name: "Netherlands",
    dialCode: "+31",
    code: "NL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/nz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/nz.png",
    name: "New Zealand",
    dialCode: "+64",
    code: "NZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ni.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ni.png",
    name: "Nicaragua",
    dialCode: "+505",
    code: "NI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ne.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ne.png",
    name: "Niger",
    dialCode: "+227",
    code: "NE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ng.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ng.png",
    name: "Nigeria",
    dialCode: "+234",
    code: "NG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/nu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/nu.png",
    name: "Niue",
    dialCode: "+683",
    code: "NU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/no.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/no.png",
    name: "Norway",
    dialCode: "+47",
    code: "NO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/om.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/om.png",
    name: "Oman",
    dialCode: "+968",
    code: "OM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pk.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pk.png",
    name: "Pakistan",
    dialCode: "+92",
    code: "PK",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pw.png",
    name: "Palau",
    dialCode: "+680",
    code: "PW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pa.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pa.png",
    name: "Panama",
    dialCode: "+507",
    code: "PA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/py.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/py.png",
    name: "Paraguay",
    dialCode: "+595",
    code: "PY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pe.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pe.png",
    name: "Peru",
    dialCode: "+51",
    code: "PE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ph.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ph.png",
    name: "Philippines",
    dialCode: "+63",
    code: "PH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pl.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pl.png",
    name: "Poland",
    dialCode: "+48",
    code: "PL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/pt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/pt.png",
    name: "Portugal",
    dialCode: "+351",
    code: "PT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/qa.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/qa.png",
    name: "Qatar",
    dialCode: "+974",
    code: "QA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ro.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ro.png",
    name: "Romania",
    dialCode: "+40",
    code: "RO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ru.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ru.png",
    name: "Russia",
    dialCode: "+7",
    code: "RU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/rw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/rw.png",
    name: "Rwanda",
    dialCode: "+250",
    code: "RW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/kn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/kn.png",
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    code: "KN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/lc.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/lc.png",
    name: "Saint Lucia",
    dialCode: "+1758",
    code: "LC",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/vc.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/vc.png",
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    code: "VC",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ws.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ws.png",
    name: "Samoa",
    dialCode: "+685",
    code: "WS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sm.png",
    name: "San Marino",
    dialCode: "+378",
    code: "SM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/st.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/st.png",
    name: "Sao Tome and Principe",
    dialCode: "+239",
    code: "ST",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sa.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sa.png",
    name: "Saudi Arabia",
    dialCode: "+966",
    code: "SA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sn.png",
    name: "Senegal",
    dialCode: "+221",
    code: "SN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/rs.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/rs.png",
    name: "Serbia",
    dialCode: "+381",
    code: "RS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sc.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sc.png",
    name: "Seychelles",
    dialCode: "+248",
    code: "SC",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sl.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sl.png",
    name: "Sierra Leone",
    dialCode: "+232",
    code: "SL",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sg.png",
    name: "Singapore",
    dialCode: "+65",
    code: "SG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sk.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sk.png",
    name: "Slovakia",
    dialCode: "+421",
    code: "SK",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/si.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/si.png",
    name: "Slovenia",
    dialCode: "+386",
    code: "SI",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sb.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sb.png",
    name: "Solomon Islands",
    dialCode: "+677",
    code: "SB",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/so.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/so.png",
    name: "Somalia",
    dialCode: "+252",
    code: "SO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ss.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ss.png",
    name: "South Sudan",
    dialCode: "+211",
    code: "SS",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/es.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/es.png",
    name: "Spain",
    dialCode: "+34",
    code: "ES",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sd.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sd.png",
    name: "Sudan",
    dialCode: "+249",
    code: "SD",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sr.png",
    name: "Suriname",
    dialCode: "+597",
    code: "SR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/sz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/sz.png",
    name: "Swaziland",
    dialCode: "+268",
    code: "SZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/se.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/se.png",
    name: "Sweden",
    dialCode: "+46",
    code: "SE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ch.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ch.png",
    name: "Switzerland",
    dialCode: "+41",
    code: "CH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tw.png",
    name: "Taiwan",
    dialCode: "+886",
    code: "TW",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tj.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tj.png",
    name: "Tajikistan",
    dialCode: "+992",
    code: "TJ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/th.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/th.png",
    name: "Thailand",
    dialCode: "+66",
    code: "TH",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tg.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tg.png",
    name: "Togo",
    dialCode: "+228",
    code: "TG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/to.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/to.png",
    name: "Tonga",
    dialCode: "+676",
    code: "TO",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tt.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tt.png",
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    code: "TT",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tn.png",
    name: "Tunisia",
    dialCode: "+216",
    code: "TN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tr.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tr.png",
    name: "Turkey",
    dialCode: "+90",
    code: "TR",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tm.png",
    name: "Turkmenistan",
    dialCode: "+993",
    code: "TM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/tv.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/tv.png",
    name: "Tuvalu",
    dialCode: "+688",
    code: "TV",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ug.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ug.png",
    name: "Uganda",
    dialCode: "+256",
    code: "UG",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ua.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ua.png",
    name: "Ukraine",
    dialCode: "+380",
    code: "UA",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/gb.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/gb.png",
    name: "United Kingdom",
    dialCode: "+44",
    code: "GB",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/us.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/us.png",
    name: "United States",
    dialCode: "+1",
    code: "US",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/uy.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/uy.png",
    name: "Uruguay",
    dialCode: "+598",
    code: "UY",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/uz.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/uz.png",
    name: "Uzbekistan",
    dialCode: "+998",
    code: "UZ",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/vu.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/vu.png",
    name: "Vanuatu",
    dialCode: "+678",
    code: "VU",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/vn.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/vn.png",
    name: "Vietnam",
    dialCode: "+84",
    code: "VN",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/ye.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/ye.png",
    name: "Yemen",
    dialCode: "+967",
    code: "YE",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/zm.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/zm.png",
    name: "Zambia",
    dialCode: "+260",
    code: "ZM",
  },
  {
    normal: "http://flags.fmcdn.net/data/flags/normal/zw.png",
    mini: "http://flags.fmcdn.net/data/flags/mini/zw.png",
    name: "Zimbabwe",
    dialCode: "+263",
    code: "ZW",
  },
];

export const VALIDATION_STEP_ITEMS = [
  "DICTATION",
  "GAP_FILL",
  "GAP_FILL_MULTIPLE",
  "UNSCRAMBLE_DRAG_AND_DROP",
  "UNSCRAMBLE_SPEECH_RECOGNITION",
  "GAP_FILL_SELECT",
  "SINGLE_CHOICE_TEXT",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_AUDIO",
  "MULTIPLE_CHOICE_TEXT",
  "GRAMMAR_CHECK",
  "MEANINGS_ASSOCIATING",
  "UNSCRAMBLE_TEXT",
  "PRESENTATION_SPEECHLESS",
  "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
  "GAP_FILL_SPEECHLESS",
  "PRONUNCIATION_SPEECHLESS",
  "SPEECH_PRACTICE_SPEECHLESS",
  "VOCABULARY_ACADEMIC_SPEECHLESS",
];
