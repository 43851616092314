import React from "react";
import TranslatedText from "../TranslatedText";
import { GREY_6, WHITE } from "../../consts/color";

const NotificationTitle = () => (
  <div
    style={{
      padding: "15px 20px",
      backgroundColor: WHITE,
      display: "flex",
      alignItems: "center",
    }}
  >
    <TranslatedText
      translateKey="notifications.title"
      as="p"
      style={{
        margin: 0,
        fontSize: 18,
        color: GREY_6,
        fontWeight: "bold",
      }}
    />
  </div>
);

export default NotificationTitle;
