import React, { Component } from "react";
import PropTypes from "prop-types";
import ExerciseButton from "./ExerciseButton";
import AnswerImage from "./AnswerImage";
import ExerciseItemPanel from "./ExerciseItemPanel";

export default class SingleChoiceImageItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      text: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    playingId: PropTypes.string,
    shouldShowCorrectOption: PropTypes.bool,
  };

  static defaultProps = {
    playingId: "",
    shouldShowCorrectOption: false,
  };

  render() {
    return (
      <ExerciseItemPanel>
        <div
          style={{
            display: "grid",
            gridGap: "5%",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            marginBottom: 180,
          }}
        >
          {this.props.item.linkedAnswers.map((answer, index) => (
            <div key={answer.id} className={!index ? "selectExerciseSingleChoiceImage" : undefined}>
              <ExerciseButton
                disabled={this.props.isDisabled}
                highlight={this.props.playingId === answer.id || (this.props.shouldShowCorrectOption && answer.correct)}
                onClick={() => {
                  this.props.onChange("answer", answer);
                  this.props.onConfirm();
                }}
              >
                <AnswerImage
                  item={this.props.item}
                  answer={answer}
                  style={{
                    height: 175,
                  }}
                />
              </ExerciseButton>
            </div>
          ))}
        </div>
      </ExerciseItemPanel>
    );
  }
}
