import Styled from "styled-components";

const PracticeTestPanelResultDescription = Styled.div`
width: 100%;
color: ${(props) => props.color};
font-size: ${(props) => props.fontSize};
font-weight: ${(props) => props.fontWeight};
padding-left: 40px;
padding-right: 40px;
text-align: center;
position: relative;
`;

export default PracticeTestPanelResultDescription;
