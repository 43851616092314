import React from "react";
import PropTypes from "prop-types";

const PronunciationIcon = ({ color, style }) => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect x="55" y="93" width="10" height="16" fill="white" />
    <path d="M46 115C46 112.239 48.2386 110 51 110H71C73.7614 110 76 112.239 76 115V117H46V115Z" fill="white" />
    <rect x="40" y="2" width="39" height="80" rx="19.5" fill="white" />
    <path
      d="M60 84.4922C72.1582 84.4922 82.0312 74.6191 82.0312 62.4609V22.0312C82.0312 9.87305 72.1582 0 60 0C47.8418 0 37.9688 9.87305 37.9688 22.0312V62.4609C37.9687 74.6191 47.8418 84.4922 60 84.4922ZM60 4.6875C69.5508 4.6875 77.3438 12.4805 77.3438 22.0312V41.3965H42.6562V22.0312C42.6562 12.4805 50.4492 4.6875 60 4.6875ZM42.6562 46.084H77.3438V62.4609C77.3438 72.0117 69.5508 79.8047 60 79.8047C50.4492 79.8047 42.6562 72.0117 42.6562 62.4609V46.084Z"
      fill={color}
    />
    <path
      d="M54.2871 30.791H65.6836C66.9726 30.791 68.0273 29.7363 68.0273 28.4473C68.0273 27.1582 66.9726 26.1035 65.6836 26.1035H54.2871C52.998 26.1035 51.9434 27.1582 51.9434 28.4473C51.9434 29.7363 52.998 30.791 54.2871 30.791Z"
      fill={color}
    />
    <path
      d="M54.2871 22.0312H65.6836C66.9726 22.0312 68.0273 20.9766 68.0273 19.6875C68.0273 18.3984 66.9726 17.3438 65.6836 17.3438H54.2871C52.998 17.3438 51.9434 18.3984 51.9434 19.6875C51.9434 20.9766 52.998 22.0312 54.2871 22.0312Z"
      fill={color}
    />
    <path
      d="M90 60.1172C88.7109 60.1172 87.6563 61.1719 87.6563 62.4609C87.6563 77.7246 75.2344 90.1172 60 90.1172C44.7363 90.1172 32.3438 77.6953 32.3438 62.4609C32.3438 61.1719 31.2891 60.1172 30 60.1172C28.7109 60.1172 27.6562 61.1719 27.6562 62.4609C27.6562 77.9297 38.5547 90.8789 53.0859 94.0723V105.908H52.9102C46.4355 105.908 41.1621 111.182 41.1621 117.656C41.1621 118.945 42.2168 120 43.5059 120H76.4648C77.7539 120 78.8086 118.945 78.8086 117.656C78.8086 111.182 73.5352 105.908 67.0898 105.908H66.9141V94.0723C81.4453 90.9082 92.3438 77.9297 92.3438 62.4609C92.3438 61.1719 91.2891 60.1172 90 60.1172ZM73.7402 115.313H46.2598C47.2266 112.588 49.834 110.596 52.9102 110.596H67.0898C70.166 110.625 72.7734 112.588 73.7402 115.313ZM62.2266 105.938H57.7734V94.7461C58.5059 94.8047 59.2383 94.834 60 94.834C60.7617 94.834 61.4941 94.8047 62.2266 94.7461V105.938Z"
      fill={color}
    />
    <path
      d="M11.7188 21.8848C10.4297 21.8848 9.375 22.9395 9.375 24.2285V47.1094C9.375 48.3984 10.4297 49.4531 11.7188 49.4531C13.0078 49.4531 14.0625 48.3984 14.0625 47.1094V24.2285C14.0625 22.9395 13.0078 21.8848 11.7188 21.8848Z"
      fill={color}
    />
    <path
      d="M30.4688 43.5938C31.7578 43.5938 32.8125 42.5391 32.8125 41.25V30.0879C32.8125 28.7988 31.7578 27.7441 30.4688 27.7441C29.1797 27.7441 28.125 28.7988 28.125 30.0879V41.25C28.125 42.5391 29.1797 43.5938 30.4688 43.5938Z"
      fill={color}
    />
    <path
      d="M2.34375 12.6562C1.05469 12.6562 0 13.7109 0 15V53.9941C0 55.2832 1.05469 56.3379 2.34375 56.3379C3.63281 56.3379 4.6875 55.2832 4.6875 53.9941V15C4.6875 13.7109 3.63281 12.6562 2.34375 12.6562Z"
      fill={color}
    />
    <path
      d="M21.0938 19.541C19.8047 19.541 18.75 20.5957 18.75 21.8848V53.5547C18.75 54.8438 19.8047 55.8984 21.0938 55.8984C22.3828 55.8984 23.4375 54.8438 23.4375 53.5547V21.8848C23.4375 20.5664 22.3828 19.541 21.0938 19.541Z"
      fill={color}
    />
    <path
      d="M108.281 21.8848C106.992 21.8848 105.938 22.9395 105.938 24.2285V47.1094C105.938 48.3984 106.992 49.4531 108.281 49.4531C109.57 49.4531 110.625 48.3984 110.625 47.1094V24.2285C110.625 22.9395 109.57 21.8848 108.281 21.8848Z"
      fill={color}
    />
    <path
      d="M89.5312 43.5938C90.8203 43.5938 91.875 42.5391 91.875 41.25V30.0879C91.875 28.7988 90.8203 27.7441 89.5312 27.7441C88.2422 27.7441 87.1875 28.7988 87.1875 30.0879V41.25C87.1875 42.5391 88.2422 43.5938 89.5312 43.5938Z"
      fill={color}
    />
    <path
      d="M117.656 12.6562C116.367 12.6562 115.312 13.7109 115.312 15V53.9941C115.312 55.2832 116.367 56.3379 117.656 56.3379C118.945 56.3379 120 55.2832 120 53.9941V15C120 13.7109 118.945 12.6562 117.656 12.6562Z"
      fill={color}
    />
    <path
      d="M98.9062 51.2109C98.291 51.2109 97.6758 51.4746 97.2363 51.8848C96.7969 52.3242 96.5625 52.9395 96.5625 53.5547C96.5625 54.1699 96.8262 54.7852 97.2363 55.2246C97.6758 55.6641 98.291 55.8984 98.9062 55.8984C99.5215 55.8984 100.137 55.6348 100.576 55.2246C101.016 54.7852 101.25 54.1992 101.25 53.5547C101.25 52.9395 100.986 52.3242 100.576 51.8848C100.137 51.4453 99.5215 51.2109 98.9062 51.2109Z"
      fill={color}
    />
    <path
      d="M98.9062 19.541C97.6172 19.541 96.5625 20.5957 96.5625 21.8848V43.623C96.5625 44.9121 97.6172 45.9668 98.9062 45.9668C100.195 45.9668 101.25 44.9121 101.25 43.623V21.8848C101.25 20.5664 100.195 19.541 98.9062 19.541Z"
      fill={color}
    />
    <path
      d="M60 74.6484C66.709 74.6484 72.1875 69.1699 72.1875 62.4609C72.1875 61.1719 71.1328 60.1172 69.8438 60.1172C68.5547 60.1172 67.5 61.1719 67.5 62.4609C67.5 66.5918 64.1309 69.9609 60 69.9609C58.7109 69.9609 57.6562 71.0156 57.6562 72.3047C57.6562 73.5937 58.7109 74.6484 60 74.6484Z"
      fill={color}
    />
    <path
      d="M69.8438 56.7188C70.459 56.7188 71.0742 56.4551 71.5137 56.0449C71.9531 55.6055 72.1875 54.9902 72.1875 54.375C72.1875 53.7598 71.9238 53.1445 71.5137 52.7051C71.0742 52.2656 70.459 52.0312 69.8438 52.0312C69.2285 52.0312 68.6133 52.2949 68.1738 52.7051C67.7344 53.1445 67.5 53.7598 67.5 54.375C67.5 54.9902 67.7637 55.6055 68.1738 56.0449C68.6133 56.4551 69.2285 56.7188 69.8438 56.7188Z"
      fill={color}
    />
  </svg>
);

PronunciationIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default PronunciationIcon;
