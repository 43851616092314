import React from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import isFinite from "lodash/isFinite";
import Header from "./Header";

const HeaderContainer = ({ location }) => {
  if (
    /\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise/.test(location.pathname) ||
    /^\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/mastery-test\/[0-9a-fA-F]{24}\/execution$/.test(
      location.pathname
    ) ||
    /\/course\/[0-9a-fA-F]{24}\/certification-test\/[0-9a-fA-F]{24}\/abilities/.test(location.pathname) ||
    /\/course\/[0-9a-fA-F]{24}\/certification-test\/[0-9a-fA-F]{24}\/abilities\/[A-Z]\/execution/.test(
      location.pathname
    ) ||
    /\/course\/[0-9a-fA-F]{24}\/bonus-test\/execution/.test(location.pathname) ||
    /\/rankings\/[0-9]{6}\/closed/.test(location.pathname)
  ) {
    return null;
  }

  return (
    <Entity name="profile">
      {({ getById: getProfileById }) => (
        <Entity name="company">
          {({ getById: getCompanyById }) => (
            <Entity name="schoolClass">
              {({ getById: getSchoolClassById }) => (
                <Entity name="school">
                  {({ getById: getSchoolById }) => {
                    const profile = getProfileById(sessionStorage.getItem("id"));
                    const schoolClass = getSchoolClassById(profile.schoolClass);
                    const school = getSchoolById(schoolClass?.school);
                    const company = getCompanyById(profile.company);

                    return (
                      <Header
                        profilePicture={profile.profilePicture}
                        locale={profile.locale}
                        rankingParticipant={profile.rankingParticipant === "S"}
                        logoSrc={profile.apiVersion === "V2" ? company.logoUrl : school.logoUrl}
                        disabled={
                          !!(
                            !isFinite(profile.initialEnglishLevel) ||
                            !profile.profileCompleted ||
                            (profile.apiVersion === "V2"
                              ? profile.isPlacementTestOnly
                              : schoolClass.isPlacementTestClass)
                          )
                        }
                      />
                    );
                  }}
                </Entity>
              )}
            </Entity>
          )}
        </Entity>
      )}
    </Entity>
  );
};

export default withRouter(HeaderContainer);
