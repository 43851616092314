import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Separator from "../../core/Separator";
import HorizontalDivider from "../../core/HorizontalDivider";
import ModuleDetailsScore from "./ModuleDetailsScore";
import CourseThemeContext from "../../core/CourseThemeContext";

const ModuleListDetailPanel = Styled.div`
  position: fixed;
  width: 380px;
  z-index: 2;
  top: 0;
  background: #FFF;
  padding: 20px;
  height: 100%;
  transition: all .3s ease-in;
`;

export default class ModuleDetails extends Component {
  static propTypes = {
    module: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      backgroundUrl: PropTypes.string,
    }).isRequired,
    isSpeechRecognitionDisabled: PropTypes.bool,
  };

  state = {
    topPosition: 200,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const topPosition = 200 - window.scrollY;
    this.setState({
      topPosition: topPosition > 0 ? topPosition : 20,
    });
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <ModuleListDetailPanel
            style={{
              paddingTop: this.state.topPosition,
            }}
          >
            <div
              style={{
                position: "relative",
                color: primary,
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              {this.props.module.name}
            </div>
            <Separator size="md" />
            <div
              style={{
                position: "relative",
                fontSize: 13,
                lineHeight: 1.85,
                color: "#607d8b",
              }}
            >
              {this.props.module.description}
            </div>
            <Separator size="xs" />
            <HorizontalDivider />
            <Separator size="md" />
            <Separator size="sm" />
            <ModuleDetailsScore
              module={this.props.module}
              color={primary}
              isSpeechRecognitionDisabled={!!this.props.isSpeechRecognitionDisabled}
            />
          </ModuleListDetailPanel>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}
