import React, { Component } from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import HighlightableItemBox from "../../core/item-type/HighlightableItemBox";
import QuestionLabel from "../../core/QuestionLabel";
import withTranslation from "../../core/withTranslation";

class TextReader extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  state = { showHeight: false, textHeight: 0 };

  componentDidMount() {
    this.setState({
      textHeight: $("#text-container").height(),
    });
  }

  handleToggleText = () => {
    const showHeight = $("#text-container").height();
    this.setState({
      showHeight: this.state.showHeight ? 0 : showHeight - 10,
    });
  };

  render() {
    return (
      <div
        style={{
          maxWidth: 690,
          margin: "0 auto",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 230,
            height: 48,
            position: "fixed",
            bottom: 125 + this.state.showHeight,
            boxSizing: "border-box",
            overflow: "hidden",
            transition: "all .5s",
            zIndex: 3,
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px -2px 10px 0px",
              width: "100%",
              height: 165,
              borderRadius: "100%",
              position: "absolute",
              top: 5,
            }}
          />
          <button
            htmlFor="label"
            style={{
              margin: 0,
              position: "relative",
              fontSize: 12,
              color: "rgb(96, 125, 139)",
              background: "none",
              outline: "none",
              border: "none",
              padding: 0,
              height: "100%",
              width: "100%",
            }}
            onClick={this.handleToggleText}
          >
            {this.state.showHeight ? this.props.getTranslation("dismiss") : this.props.getTranslation("checkText")}
          </button>
        </div>
        <div
          id="text-container"
          style={{
            position: "fixed",
            opacity: this.state.showHeight ? 1 : 0,
            zIndex: this.state.showHeight ? 4 : -1,
            bottom: this.state.showHeight ? 125 : 130 - this.state.textHeight,
            maxHeight: 300,
            maxWidth: 590,
            overflowY: "auto",
            transition: "all .5s",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px -2px 10px 0px",
          }}
        >
          <HighlightableItemBox flexDirection="column">
            {this.props.text
              .split("\n")
              .filter((text) => text.length)
              .map((text) => (
                <div
                  key={`text-${text}`}
                  style={{
                    width: "100%",
                    marginBottom: 10,
                  }}
                >
                  <QuestionLabel asParagraph fontSize={16} text={text} />
                </div>
              ))}
          </HighlightableItemBox>
        </div>
      </div>
    );
  }
}

export default withTranslation(TextReader);
