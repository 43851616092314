import React from "react";
import PropTypes from "prop-types";
import FlexColumn from "./FlexColumn";
import { WHITE } from "../consts/color";
import MediaQuery from "react-responsive";

export default class PopoverButton extends React.Component {
  static propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    position: PropTypes.oneOf(["top", "bottom", "right", "left", "left-corner", "right-corner"]).isRequired,
    animated: PropTypes.bool,
    positionOffset: PropTypes.number,
    arrowOffset: PropTypes.number,
  };

  static defaultProps = {
    onClose: null,
    animated: false,
    positionOffset: 80,
    arrowOffset: 15,
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsidePopoverClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsidePopoverClick, false);
  }

  handleOutsidePopoverClick = (e) => {
    if (this.popoverNode && this.popoverNode.contains(e.target)) {
      return false;
    }

    if (this.props.isOpen && this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const { isOpen, position, content, onClose, children, ...rest } = this.props;
    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: {
            top: "center",
            bottom: "center",
            right: "center",
            left: "center",
            "left-corner": "flex-start",
            "right-corner": "flex-end",
          }[position],
          justifyContent: "center",
        }}
        ref={(node) => {
          this.popoverNode = node;
        }}
      >
        <MediaQuery maxWidth={626}>
          {(matches) => (
            <FlexColumn
              className={this.props.animated ? "animate__animated animate__pulse animate__infinite" : ""}
              alignItems="center"
              position="absolute"
              maxWidth={matches ? window.screen.availWidth * 0.7 : 500}
              width="max-content"
              opacity={isOpen ? 1 : 0}
              zIndex={isOpen ? 3 : -1}
              userSelect="none"
              transition="all .5s"
              backgroundColor={WHITE}
              borderRadius={5}
              padding="20px 0px"
              boxShadow="0px 10px 20px 0 rgba(0, 0, 0, 0.2)"
              {...{
                ...((position === "bottom" || position === "left-corner" || position === "right-corner") && {
                  bottom: this.props.isOpen ? this.props.positionOffset : 0,
                }),
                ...(position === "top" && {
                  top: this.props.isOpen ? this.props.positionOffset : 0,
                }),
                ...(position === "right" && {
                  right: this.props.isOpen ? this.props.positionOffset : 0,
                }),
                ...(position === "left" && {
                  left: this.props.isOpen ? this.props.positionOffset : 0,
                }),
              }}
              {...rest}
            >
              <FlexColumn
                position="absolute"
                width={0}
                height={0}
                {...{
                  ...(position === "bottom" && {
                    bottom: (rest.padding || 10) * -1,
                    borderLeft: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderRight: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderTop: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                  ...(position === "top" && {
                    bottom: (rest.padding || 10) * -1,
                    borderLeft: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderRight: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderBottom: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                  ...(position === "right" && {
                    right: (rest.padding || 10) * -1,
                    borderTop: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderBottom: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderLeft: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                  ...(position === "left" && {
                    left: (rest.padding || 10) * -1,
                    borderTop: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderBottom: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderRight: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                  ...(position === "left-corner" && {
                    bottom: (rest.padding || 10) * -1,
                    left: this.props.arrowOffset,
                    borderLeft: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderRight: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderTop: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                  ...(position === "right-corner" && {
                    bottom: (rest.padding || 10) * -1,
                    right: this.props.arrowOffset,
                    borderLeft: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderRight: `${(rest.padding || 15) * 0.7}px solid transparent`,
                    borderTop: `${rest.padding || 15}px solid ${rest.backgroundColor || WHITE}`,
                  }),
                }}
              />
              <FlexColumn display={isOpen ? "flex" : "none"} maxHeight={300} overflowY="auto" padding="0px 20px">
                {content}
              </FlexColumn>
            </FlexColumn>
          )}
        </MediaQuery>
        {children}
      </div>
    );
  }
}
