import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconButton from "./IconButton";
import HeaderMenuItem from "./HeaderMenuItem";
import UserAvatar from "./UserAvatar";
import PopoverMenu from "./PopoverMenu";
import FlexRow from "./FlexRow";
import InBetweenSpacing from "./InBetweenSpacing";
import MenuItem from "./MenuItem";
import MenuButtonItem from "./MenuButtonItem";
import RankingParticipantContainer from "./RankingParticipantContainer";
import NotificationPopoverContainer from "./notification/NotificationPopoverContainer";
import MessagePopoverContainer from "./message/MessagePopoverContainer";
import CheckDistributorType from "./CheckDistributorType";
import { SHOW_VIDEO_INSTRUCTION_FLOW } from "../consts";
import Flow from "student-front-commons/src/components/Flow";
import { useEntity } from "student-front-commons/src/hooks";

function HeaderMenu(props) {
  const profile = useEntity("profile", sessionStorage.getItem("id"));

  return (
    <FlexRow alignItems="center" justifyContent="space-between">
      <InBetweenSpacing size="sm">
        <HeaderMenuItem>
          <div className="stepMenuNotifications">
            <NotificationPopoverContainer />
          </div>
        </HeaderMenuItem>
        <HeaderMenuItem>
          <MessagePopoverContainer />
        </HeaderMenuItem>
        <RankingParticipantContainer>
          <HeaderMenuItem>
            <div className="stepMenuHistory">
              <Link to="/rankings">
                <IconButton size="sm" icon="trophy-variant" />
              </Link>
            </div>
          </HeaderMenuItem>
        </RankingParticipantContainer>
        {!profile.isSpeechRecognitionDisabled && (
          <HeaderMenuItem>
            <PopoverMenu
              contentStyle={{
                padding: "10px 15px",
                width: 220,
              }}
              rootStyle={{
                left: "calc(50% - 110px)",
              }}
              renderButton={<IconButton size="sm" icon="help" />}
            >
              <MenuItem icon="microphone-settings" link="/microphone-test" label="menu.microphoneTest" />
              <React.Fragment>
                <Flow
                  autoStart={false}
                  id={SHOW_VIDEO_INSTRUCTION_FLOW}
                  params={{
                    scene: "MODULE_LIST",
                  }}
                >
                  {({ handleStart }) => (
                    <MenuButtonItem onClick={handleStart} icon="television-play" label="menu.modulesHelp" />
                  )}
                </Flow>
                <Flow
                  autoStart={false}
                  id={SHOW_VIDEO_INSTRUCTION_FLOW}
                  params={{
                    scene: "UNIT_LIST",
                  }}
                >
                  {({ handleStart }) => (
                    <MenuButtonItem onClick={handleStart} icon="television-play" label="menu.exercisesHelp" />
                  )}
                </Flow>
              </React.Fragment>
            </PopoverMenu>
          </HeaderMenuItem>
        )}
        <HeaderMenuItem>
          <div className="stepMenuProfile">
            <PopoverMenu
              contentStyle={{
                padding: "10px 15px",
              }}
              rootStyle={{
                left: "calc(50% - 50px)",
              }}
              renderButton={<UserAvatar size="sm" src={props.profilePicture} />}
            >
              <CheckDistributorType>
                {({ isB2C }) => !isB2C && <MenuItem icon="book-open" link="/grades" label="menu.grades" />}
              </CheckDistributorType>
              <MenuItem icon="view-list" link="/history" label="menu.history" />
              <MenuItem icon="account" link="/profile" label="menu.profile" />
              <MenuItem
                icon="logout"
                link="/"
                label="menu.logout"
                onClick={() => {
                  sessionStorage.clear();
                  window.location.reload();
                }}
              />
            </PopoverMenu>
          </div>
        </HeaderMenuItem>
      </InBetweenSpacing>
    </FlexRow>
  );
}

HeaderMenu.propTypes = {
  profilePicture: PropTypes.string,
  locale: PropTypes.string.isRequired,
};

HeaderMenu.defaultProps = {
  profilePicture: null,
};

export default HeaderMenu;
