import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * get the student total of hours and points
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the id to load
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const getAllTimeStats = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/stats`,
        method: "get",
      });
    },
    "error_get_all_time_stats",
    "Unexpected error to get stats of all time."
  );
};

/**
 * get the student total hours/points of a module based on the period.
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.group - the group option month or week
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const getStatsByPeriodAndGroup = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      group: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["week", "month"],
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/stats-history`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
          group: payload.group,
        },
      });
    },
    "error_get_period_stats",
    "Unexpect error to get stats by period"
  );
};

/**
 * get the student total hours/points of a module based on the period.
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.module - the module id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{entities: any; result: any}>}
 */
export const getModuleStatsByPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/modules/${payload.module}/stats`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_module_stats",
    "Unexpected error to get stats by module and period."
  );
};
