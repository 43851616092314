import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const TextLink = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <button
        style={{
          background: "none",
          border: "none",
          outline: "none",
          fontSize: 12,
          color: secondaryColor,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={props.onClick}
      >
        {props.label}
      </button>
    )}
  </WhitelabelContext.Consumer>
);

TextLink.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TextLink;
