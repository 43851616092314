import React, { Component } from "react";
import PropTypes from "prop-types";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";

export default class SingleChoiceAudioItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      text: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    playingId: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    shouldShowCorrectOption: PropTypes.bool,
  };

  static defaultProps = {
    shouldShowCorrectOption: false,
  };

  state = {
    answerAudioPlayed: {
      0: false,
      1: false,
      2: false,
      3: false,
    },
  };

  componentDidUpdate(prevProps) {
    if (prevProps.playingId !== this.props.playingId) {
      const answerIndex = this.props.item.linkedAnswers.findIndex((answer) => answer.id === this.props.playingId);
      if (this.state.answerAudioPlayed[answerIndex] === false) {
        this.setState({
          answerAudioPlayed: {
            ...this.state.answerAudioPlayed,
            [answerIndex]: true,
          },
        });
      }
    }
    if (prevProps.item.id !== this.props.item.id) {
      this.setState({ answerAudioPlayed: { 0: false, 1: false, 2: false, 3: false } });
    }
  }

  render() {
    return (
      <ExerciseItemPanel>
        <div
          style={{
            display: "grid",
            gridGap: "5%",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            marginBottom: 100,
          }}
        >
          {this.props.item.linkedAnswers.map((answer, index) => (
            <div key={answer.id} className={!index ? "selectExerciseSingleChoiceAudio" : undefined}>
              <ExerciseButton
                disabled={this.state.answerAudioPlayed[index] === false}
                highlight={this.props.playingId === answer.id || (this.props.shouldShowCorrectOption && answer.correct)}
                onClick={() => {
                  this.props.onChange("answer", answer);
                  this.props.onConfirm();
                }}
              >
                <h1
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: 72,
                  }}
                >
                  {["A", "B", "C", "D"][index]}
                </h1>
              </ExerciseButton>
            </div>
          ))}
        </div>
      </ExerciseItemPanel>
    );
  }
}
