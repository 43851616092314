import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestConfigStart } from "../../actionCreators/microphoneConfig";
import MicrophoneConfigScene from "./MicrophoneConfigScene";

class MicrophoneConfigSceneContainer extends Component {
  static propTypes = {
    currentStep: PropTypes.string.isRequired,
    handleStart: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.handleStart();
  }

  render() {
    return <MicrophoneConfigScene currentStep={this.props.currentStep} />;
  }
}

const mapStateToProps = (state) => ({
  currentStep: state.microphoneConfig.currentStep,
});

const mapDispatchToProps = (dispatch) => ({
  handleStart: () => dispatch(requestConfigStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MicrophoneConfigSceneContainer);
