import React from "react";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPlacementTestLevels } from "student-front-commons/src/services/placementTestLevelService";
import Async from "../../../core/Async";
import LevelSelectScene from "./LevelSelectScene";

const LevelSelectSceneContainer = () => (
  <FetchData service={getPlacementTestLevels}>
    {({ isFetching, data }) => (
      <Async fetching={isFetching}>
        <LevelSelectScene placementTestLevels={data} />
      </Async>
    )}
  </FetchData>
);

export default LevelSelectSceneContainer;
