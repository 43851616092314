import React from "react";
import PropTypes from "prop-types";

const QuestionLabel = (props) => (
  <label
    htmlFor="text"
    style={{
      fontSize: props.fontSize,
      fontWeight: props.fontWeight,
      cursor: props.cursor,
      color: props.color,
      textIndent: props.asParagraph ? 30 : 0,
      userSelect: "none",
      lineHeight: props.lineHeight,
      textDecoration: props.textDecoration,
      margin: 0,
      textAlign: props.textAlign,
      whiteSpace: props.whiteSpace,
    }}
  >
    {props.text || props.children}
  </label>
);

QuestionLabel.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  color: PropTypes.string,
  cursor: PropTypes.string,
  textDecoration: PropTypes.string,
  asParagraph: PropTypes.bool,
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  lineHeight: PropTypes.string,
  textAlign: PropTypes.oneOf(["center", "left", "right"]),
  whiteSpace: PropTypes.oneOf(["normal", "nowrap", "pre-wrap", "pre-line", "inherit"]),
};

QuestionLabel.defaultProps = {
  asParagraph: false,
  color: "#607d8b",
  cursor: "pointer",
  textDecoration: "none",
  fontSize: 22,
  fontWeight: 400,
  lineHeight: null,
  textAlign: null,
  whiteSpace: "pre-wrap",
};

export default QuestionLabel;
