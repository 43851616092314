import React from "react";
import PropTypes from "prop-types";
import $ from "jquery";
import Icon from "./Icon";
import FlexRow from "./FlexRow";
import TranslatedText from "./TranslatedText";
import Separator from "./Separator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

class SystemMessage extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#system-message").offset().top,
        },
        1000
      );
    }, 0);
  }

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <FlexRow
            id="system-message"
            backgroundColor={
              {
                error: "#ef5350",
                success: "#287F4C",
                primary: primaryColor,
              }[this.props.type]
            }
            transition="opacity .3s"
            flex={1}
            padding="5px 20px"
            boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
            borderRadius={3}
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TranslatedText
              as="p"
              translateKey={this.props.message}
              style={{
                margin: 0,
                color: "#fff",
                fontSize: 13,
              }}
            />
            <Separator size="lg" />
            <Icon icon="close-circle" color="#fff" cursor="pointer" onClick={this.props.onClose} />
          </FlexRow>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

SystemMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["error", "success", "primary"]).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SystemMessage;
