import React from "react";
import { useSelector } from "react-redux";
import PresentationExerciseItem from "../../core/item-type/PresentationExerciseItem";
import VideoExerciseItem from "../../core/item-type/VideoExerciseItem";
import SingleChoiceAudioItem from "../../core/item-type/SingleChoiceAudioItem";
import TextExerciseItem from "../../core/item-type/TextExerciseItem";
import SingleChoiceTextItem from "../../core/item-type/SingleChoiceTextItem";
import MultipleChoiceTextItem from "../../core/item-type/MultipleChoiceTextItem";
import SingleChoiceImageItem from "../../core/item-type/SingleChoiceImageItem";
import SpeechRecognitionExerciseItem from "../../core/item-type/SpeechRecognitionExerciseItem";
import DragAndDropExerciseItem from "../../core/item-type/DragAndDropExerciseItem";
import {
  getHasLastItemTypeImageOnUnit,
  getItemsExecutionsType,
} from "student-front-commons/src/selectors/itemExecution";
import ImageExerciseItem from "../../core/item-type/ImageExerciseItem";
import ImageThumbnail from "../../core/item-type/ImageThumbnail";
import FlexColumn from "../../core/FlexColumn";
import TrueFalseExerciseItem from "../../core/item-type/TrueFalseExerciseItem";
import SpeechPracticeExerciseItem from "../../core/item-type/SpeechPracticeExerciseItem";
import DictationExerciseItem from "../../core/item-type/DictationExerciseItem";
import SelectExerciseItem from "../../core/item-type/SelectExerciseItem";
import PronunciationExerciseItem from "../../core/item-type/PronunciationExerciseItem";
import AudioLongExerciseItem from "../../core/item-type/AudioLongExerciseItem";
import UnscrambleTextExerciseItem from "../../core/item-type/UnscrambleTextExerciseItem";
import DialogueExerciseItem from "../../core/item-type/DialogueExerciseItem";
import GrammarCheckExerciseItem from "../../core/item-type/GrammarCheckExerciseItem";
import MeaningsAssociatingExerciseItem from "../../core/item-type/MeaningsAssociatingExerciseItem";
import VocabularyExerciseItem from "../../core/item-type/VocabularyExerciseItem";
import GapFillRadioExerciseItem from "../../core/item-type/GapFillRadioExerciseItem";
import VocabularySpeechlessExerciseItem from "../../core/item-type/VocabularySpeechlessExerciseItem";

export default function UnitItemExecution() {
  const hasLastItemTypeImageOnUnit = useSelector(getHasLastItemTypeImageOnUnit);
  const itemType = useSelector(getItemsExecutionsType);

  return (
    <FlexColumn justifyContent="space-between" flexDirection={hasLastItemTypeImageOnUnit ? "row" : "column"}>
      {hasLastItemTypeImageOnUnit && <ImageThumbnail />}
      {["PRESENTATION", "PRESENTATION_SPEECHLESS"].some((type) => type === itemType) && <PresentationExerciseItem />}
      {["VIDEO", "VIDEO_LONG"].some((type) => type === itemType) && <VideoExerciseItem />}
      {itemType === "VOCABULARY_ACADEMIC" && <VocabularyExerciseItem />}
      {itemType === "VOCABULARY_ACADEMIC_SPEECHLESS" && <VocabularySpeechlessExerciseItem />}
      {itemType === "SINGLE_CHOICE_AUDIO" && <SingleChoiceAudioItem />}
      {itemType === "SINGLE_CHOICE_IMAGE" && <SingleChoiceImageItem />}
      {itemType === "TEXT" && <TextExerciseItem />}
      {itemType === "SINGLE_CHOICE_TEXT" && <SingleChoiceTextItem size={hasLastItemTypeImageOnUnit ? "sm" : "md"} />}
      {["GAP_FILL", "UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === itemType) && (
        <SpeechRecognitionExerciseItem />
      )}
      {itemType === "MULTIPLE_CHOICE_TEXT" && (
        <MultipleChoiceTextItem size={hasLastItemTypeImageOnUnit ? "sm" : "md"} />
      )}
      {itemType === "IMAGE" && <ImageExerciseItem />}
      {[
        "UNSCRAMBLE_DRAG_AND_DROP",
        "GAP_FILL_MULTIPLE",
        "PRESENTATION_SPEECHLESS",
        "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
        "VOCABULARY_ACADEMIC_SPEECHLESS",
      ].some((type) => type === itemType) && <DragAndDropExerciseItem />}
      {itemType === "GAP_FILL_SPEECHLESS" && <GapFillRadioExerciseItem />}
      {itemType === "TRUE_FALSE" && <TrueFalseExerciseItem />}
      {["SPEECH_PRACTICE", "SPEECH_PRACTICE_SPEECHLESS"].some((type) => type === itemType) && (
        <SpeechPracticeExerciseItem />
      )}
      {["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].some((type) => type === itemType) && <DictationExerciseItem />}
      {["GAP_FILL_SELECT", "PRONUNCIATION_SPEECHLESS"].some((type) => type === itemType) && <SelectExerciseItem />}
      {itemType === "PRONUNCIATION" && <PronunciationExerciseItem />}
      {itemType === "AUDIO_LONG" && <AudioLongExerciseItem />}
      {itemType === "UNSCRAMBLE_TEXT" && <UnscrambleTextExerciseItem />}
      {["DIALOGUE", "DIALOGUE_OPTION", "DIALOGUE_OPTION_SPEECHLESS"].some((type) => type === itemType) && (
        <DialogueExerciseItem />
      )}
      {itemType === "GRAMMAR_CHECK" && <GrammarCheckExerciseItem />}
      {itemType === "MEANINGS_ASSOCIATING" && <MeaningsAssociatingExerciseItem />}
    </FlexColumn>
  );
}
