import React from "react";
import orderBy from "lodash/orderBy";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPlacementTestLevels } from "student-front-commons/src/services/placementTestLevelService";
import Async from "../../core/Async";
import CoursesRuler from "./CoursesRuler";
import Entity from "student-front-commons/src/components/Entity";

const CoursesRulerContainer = () => (
  <FetchData service={getPlacementTestLevels}>
    {({ isFetching, data }) => (
      <Entity name="profile">
        {({ getById: getProfileById }) => {
          const profile = getProfileById(sessionStorage.getItem("id"));
          return (
            <Async fetching={isFetching} size="sm">
              <CoursesRuler
                placementCourses={orderBy(data || [], "level", "desc")}
                initialEnglishLevel={profile.initialEnglishLevel}
                currentEnglishLevel={profile.currentEnglishLevel}
              />
            </Async>
          );
        }}
      </Entity>
    )}
  </FetchData>
);

export default CoursesRulerContainer;
