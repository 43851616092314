import { useContext } from "react";
import FlexColumn from "../../core/FlexColumn";
import BreadcrumbContainer from "../../core/BreadcrumbContainer";
import CourseThemeContext from "../../core/CourseThemeContext";
import FlexRow from "../../core/FlexRow";
import Panel from "../../core/Panel";
import Separator from "../../core/Separator";
import BonusTestHistory from "../../core/BonusTestHistory";
import SubHeaderDetailsContainer from "../../core/SubHeaderDetailsContainer";
import BonusTestRewards from "./BonusTestRewards";

export default function BonusTestResultScene() {
  const courseThemeContext = useContext(CourseThemeContext);

  return (
    <>
      <SubHeaderDetailsContainer />
      <BreadcrumbContainer />
      <Separator size="xs" />
      <div className="container">
        <Separator size="xs" />
        <Panel padding="none">
          <FlexRow justifyContent="space-between" height="800px">
            <FlexColumn width="60%" alignItems="center" backgroundColor={courseThemeContext.primary}>
              <BonusTestRewards />
            </FlexColumn>
            <FlexColumn width="40%">
              <BonusTestHistory />
            </FlexColumn>
          </FlexRow>
        </Panel>
        <Separator size="xl" />
      </div>
    </>
  );
}
