import React, { useCallback } from "react";
import CircularButton from "../CircularButton";
import { BLUE_1 } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";
import { useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutions,
  getItemsExecutionsType,
  isAllItemExecutionsAnswered,
  isAnyItemExecutionValidated,
} from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import {
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  PLAY_RECORD_AUDIO_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  START_NEXT_UNIT_ITEM_EXECUTION,
} from "../../consts";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";
import { last } from "lodash";
import { getExecutionProgress } from "student-front-commons/src/selectors/execution";
import { VALIDATION_STEP_ITEMS } from "student-front-commons/src/consts";

const sentryUserAction = { mainComponent: "NextButton" };

function NextButton() {
  const [isSubmitting, validate] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [, next] = useFlow(START_NEXT_UNIT_ITEM_EXECUTION);
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [isListenPlaying] = useFlow(PLAY_RECORD_AUDIO_FLOW);

  const itemType = useSelector(getItemsExecutionsType);
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isExecutionValidated = useSelector(isAnyItemExecutionValidated);
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isFinished = useSelector(getCurrentItemExecutionProp("isFinished"));
  const recordCount = useSelector(getCurrentItemExecutionProp("recordCount"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const answer = useSelector(getCurrentItemExecutionProp("answer"));
  const attempts = useSelector(getCurrentItemExecutionProp("attempts"));
  const currentInstructionStep = useSelector(getInstructionStep);
  const unitExecutionProgress = useSelector(getExecutionProgress);
  const isAllItemsAnswered = useSelector(isAllItemExecutionsAnswered);
  const itemExecutions = useSelector(getItemExecutions);

  const isNextDisabled = () => {
    if (isExecutionValidated) {
      return isListenPlaying;
    }
    if (["PRESENTATION", "PRONUNCIATION", "SPEECH_PRACTICE", "VIDEO_SHORT"].some((type) => type === itemType)) {
      return (
        isDisabled ||
        isRecording ||
        !recordCount ||
        isSubmittingRecord ||
        (!last(attempts)?.correct && attempts.length < 3)
      );
    }
    if (["GAP_FILL", "UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === itemType)) {
      return isDisabled || isRecording || isSubmittingRecord || !answer;
    }
    if (
      [
        "GAP_FILL_MULTIPLE",
        "GAP_FILL_SELECT",
        "UNSCRAMBLE_DRAG_AND_DROP",
        "DICTATION",
        "PRESENTATION_SPEECHLESS",
        "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
        "PRONUNCIATION_SPEECHLESS",
        "SPEECH_PRACTICE_SPEECHLESS",
        "VOCABULARY_ACADEMIC_SPEECHLESS",
      ].some((type) => type === itemType)
    ) {
      return isDisabled || !answer;
    }
    if (["VIDEO", "CONTENT_VIDEO"].some((type) => type === itemType)) {
      return isDisabled;
    }
    if (itemType === "UNSCRAMBLE_TEXT") {
      return isDisabled && !isFinished;
    }
    if (["AUDIO_LONG", "VIDEO_LONG"].some((type) => type === itemType)) {
      return isDisabled && repeatCount === 0;
    }
    if (["TEXT", "IMAGE"].some((type) => type === itemType)) {
      return false;
    }
    if (itemType === "MULTIPLE_CHOICE_TEXT") {
      return isDisabled || answer?.length === 0;
    }
    if (["DIALOGUE", "DIALOGUE_OPTION"].some((type) => type === itemType)) {
      return unitExecutionProgress < 1;
    }
    if (itemType === "GRAMMAR_CHECK") {
      return (isDisabled || !answer) && !isFinished;
    }
    if (itemType === "MEANINGS_ASSOCIATING") {
      return isDisabled || !isAllItemsAnswered;
    }
    if (itemType === "VOCABULARY_ACADEMIC") {
      return (
        isDisabled ||
        isRecording ||
        !recordCount ||
        isSubmittingRecord ||
        itemExecutions.some(
          (itemExecution) => !last(itemExecution.attempts)?.correct && itemExecution.attempts.length < 3
        )
      );
    }
    return true;
  };

  const handleClick = useCallback(() => {
    if (isExecutionValidated) {
      next();
    } else {
      validate();
    }
    addSentryUserAction({
      ...sentryUserAction,
      clickedComponent: "CircularButton",
      action: `Advance to next Unit Item`,
    });
  }, [isExecutionValidated, next, validate]);

  return (
    <MediaQuery maxWidth={540}>
      {(matches) => (
        <CircularButton
          icon={!isExecutionValidated && VALIDATION_STEP_ITEMS.includes(itemType) ? "check" : "skip-next"}
          color={BLUE_1}
          size={matches ? "md" : "lg"}
          onClick={handleClick}
          disabled={(currentInstructionStep && currentInstructionStep !== "next") || isNextDisabled()}
          loading={isSubmitting || isSaving}
        />
      )}
    </MediaQuery>
  );
}

export default NextButton;
