import FlexRow from "./FlexRow";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Separator from "./Separator";
import Modal from "./Modal";
import FlexColumn from "./FlexColumn";
import PlacementRedoIcon from "../images/placement-redo-icon.svg";
import TranslatedText from "./TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import ButtonV2 from "./ButtonV2";

class PlacementTestInviteModal extends React.PureComponent {
  static propTypes = {
    allowDismiss: PropTypes.bool.isRequired,
  };

  state = {
    show: true,
  };

  handleDismiss = () => {
    this.setState({
      show: false,
    });
  };

  handlePlacement = () => {
    this.handleDismiss();
    this.props.history.replace("/practice-test-introduction");
  };

  render() {
    return (
      this.state.show && (
        <WhitelabelContext.Consumer>
          {({ primaryColor }) => (
            <Modal>
              <img
                alt="placement-redo-icon"
                src={PlacementRedoIcon}
                style={{
                  width: "auto",
                  height: 142,
                }}
              />
              <Separator size="xs" />
              <TranslatedText
                as="h1"
                translateKey="placementTestInvite.title"
                style={{
                  color: primaryColor,
                  fontSize: 28,
                  fontWeight: 400,
                  lineHeight: "32px",
                  margin: 0,
                }}
              />
              <Separator size="sm" />
              <Separator size="md" />
              <TranslatedText
                as="h2"
                translateKey="placementTestInvite.subTitle"
                style={{
                  color: "#424242",
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: "20px",
                  margin: 0,
                }}
              />
              <Separator size="xs" />
              <TranslatedText
                as="p"
                translateKey="placementTestInvite.description"
                style={{
                  opacity: 0.8,
                  color: "#424242",
                  margin: 0,
                }}
              />
              <Separator size="xxl" />
              <FlexColumn justifyContent="center" alignItems="center">
                <FlexRow>
                  <ButtonV2 onClick={this.handlePlacement} labelKey="placementTestInvite.button.startPlacement" />
                  <Separator size="sm" />
                  {this.props.allowDismiss && (
                    <ButtonV2
                      onClick={this.handleDismiss}
                      labelKey="placementTestInvite.button.doLater"
                      intent="secondary"
                    />
                  )}
                </FlexRow>
              </FlexColumn>
            </Modal>
          )}
        </WhitelabelContext.Consumer>
      )
    );
  }
}

export default withRouter(PlacementTestInviteModal);
