import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Icon from "../core/Icon";

const Value = Styled.label`
  display: inline-block;
  font-size: 12px;
  color: #758C98;
  margin: 0;
  line-height: 30px;
  vertical-align: bottom;
`;

const CompareValues = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      height: 30,
    }}
  >
    <Icon
      icon={
        {
          up: "menu-up",
          down: "menu-down",
          equal: "minus",
        }[props.type]
      }
      color={
        {
          up: "#A5D7A7",
          down: "#EF8C3B",
          equal: "#CCC",
        }[props.type]
      }
      size={
        {
          up: "sm",
          down: "sm",
          equal: "xs",
        }[props.type]
      }
    />
    <Value>{props.value}</Value>
  </div>
);

CompareValues.propTypes = {
  value: PropTypes.number,
  type: PropTypes.oneOf(["up", "down", "equal"]).isRequired,
};

CompareValues.defaultProps = {
  value: 0,
};

export default CompareValues;
