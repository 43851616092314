import React from "react";
import PropTypes from "prop-types";
import TranslatedText from "../../core/TranslatedText";

const GradeHeaderLabelItem = (props) => (
  <div style={{ marginBottom: 5 }}>
    <TranslatedText
      as="label"
      translateKey={`grades.${props.label}`}
      style={{
        display: "inline-block",
        textAlign: "right",
        fontSize: 16,
        marginRight: 15,
        width: props.width,
        color: "rgb(96, 125, 139)",
      }}
    />
    <span
      style={{
        textAlign: "right",
        fontSize: 16,
        fontWeight: "bold",
        color: "rgb(55, 71, 79)",
      }}
    >
      {props.value}
    </span>
  </div>
);

GradeHeaderLabelItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.number.isRequired,
};

export default GradeHeaderLabelItem;
