import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestItemScore } from "../../actionCreators/microphoneConfig";
import SpeechRecognitionTestList from "./SpeechRecognitionTestList";

const SpeechRecognitionTestListContainer = (props) => (
  <SpeechRecognitionTestList items={props.items} onRecordEnd={props.handleRequestItemScore} />
);

SpeechRecognitionTestListContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleRequestItemScore: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  items: state.microphoneConfig.items,
});

const mapDispatchToProps = (dispatch) => ({
  handleRequestItemScore: (id, blob) => dispatch(requestItemScore(id, blob)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeechRecognitionTestListContainer);
