import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import moment from "moment";
import get from "lodash/get";
import MonthPicker from "./MonthPicker";

class MonthPickerContainer extends React.Component {
  state = {
    selectedMonth: moment().format("MM"),
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.history.replace(
        `${this.props.match.url}?${queryString.stringify({
          ...queryString.parse(this.props.location.search),
          month: moment().format("MM"),
        })}`
      );
    }, 0);
  }

  handleChangeMonth = (month) => {
    this.setState({
      selectedMonth: month,
    });
    this.props.history.replace(
      `${this.props.match.url}?${queryString.stringify({ ...queryString.parse(this.props.location.search), month })}`
    );
  };

  render() {
    return (
      <MonthPicker
        value={this.state.selectedMonth}
        onChange={this.handleChangeMonth}
        selectedYear={get(queryString.parse(this.props.location.search), "year", moment().year())}
      />
    );
  }
}

export default withRouter(MonthPickerContainer);
