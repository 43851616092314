import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { head } from "lodash";
import Form from "student-front-commons/src/components/Form";
import get from "lodash/get";
import ColumnSeparator from "../ColumnSeparator";
import Separator from "../Separator";
import withTranslation from "../withTranslation";
import Button from "../Button";
import Icon from "../Icon";
import HtmlForm from "../HtmlForm";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import MaterialTheme from "../MaterialTheme";
import TextInput from "../form/TextInput";
import { isRequired } from "student-front-commons/src/validators";
import TranslatedText from "../TranslatedText";
import FlexColumn from "../FlexColumn";
import { NEW_MESSAGE_CHANNEL_FORM } from "../../consts";
import NewMessageAlertContainer from "./NewMessageAlertContainer";
import Select from "../form/Select";
import { useEntity } from "student-front-commons/src/hooks";

const NewMessageForm = (props) => {
  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const isOnlyOneTeacher = useMemo(() => {
    if (!profile.teachers.length) {
      return false;
    }
    return profile.teachers.length === 1;
  }, [profile]);

  return (
    <MaterialTheme>
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <Form
            id={NEW_MESSAGE_CHANNEL_FORM}
            onSubmit={props.onSubmit}
            validations={{
              type: [isRequired],
              subject: [isRequired],
              text: [isRequired],
            }}
            initialValues={{ teacher: isOnlyOneTeacher ? head(profile.teachers).id : "" }}
          >
            {({ handleSubmit, handleChange, isSubmitted, values, errors }) => {
              return (
                <HtmlForm onSubmit={handleSubmit}>
                  <div
                    style={{
                      width: "100%",
                      padding: "25px 20px",
                      backgroundColor: "#fff",
                      position: "relative",
                    }}
                  >
                    <NewMessageAlertContainer />
                    <FlexColumn display={values.type ? "none" : "flex"} alignItems="center">
                      <TranslatedText
                        as="label"
                        translateKey="messages.messageTypeSelection"
                        style={{
                          margin: 0,
                          fontSize: 13,
                        }}
                      />
                      <Separator size="md" />
                      <Button
                        type="button"
                        color={primaryColor}
                        onClick={() => handleChange("type", "TO_TEACHER")}
                        style={{
                          textTransform: "uppercase",
                          fontSize: 16,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 235,
                        }}
                      >
                        <TranslatedText translateKey="messages.messageToTeacher" />
                      </Button>
                      <Separator />
                      <Button
                        type="button"
                        color={primaryColor}
                        onClick={() => handleChange("type", "TO_SUPPORT")}
                        style={{
                          textTransform: "uppercase",
                          fontSize: 16,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 235,
                        }}
                      >
                        <TranslatedText translateKey="messages.messageToSupport" />
                      </Button>
                    </FlexColumn>
                    <FlexColumn display={values.type ? "flex" : "none"} alignItems="flex-start">
                      {values.type === "TO_SUPPORT" ? (
                        <Select
                          disabled={props.isSubmitting}
                          onChange={(value) => handleChange("subject", value)}
                          value={get(values, "subject", "")}
                          error={isSubmitted ? get(errors, "subject", null) : null}
                          label={props.getTranslation("messages.subjectPlaceholder")}
                          fullWidth
                          options={[
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.exercise"),
                              value: "Dúvida sobre o Exercício",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.ranking"),
                              value: "Dúvida sobre o ranking",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.studyQuality"),
                              value: "Dúvida sobre a Qualidade de Estudo",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.learningTest"),
                              value: "Dúvida sobre o Teste de Aprendizagem",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.certificationTest"),
                              value: "Dúvida sobre o Teste de Certificação",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.answerExercise"),
                              value: "Como responder o exercício",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.exerciseBlockedFunctionality"),
                              value: "Função bloqueada no exercício",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.speechRecognition"),
                              value: "Reconhecimento de fala",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.exerciseImage"),
                              value: "Imagem do exercício",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.exerciseAudio"),
                              value: "Áudio do exercício",
                            },
                            {
                              label: props.getTranslation("messages.supportSubjectLabelOptions.exerciseTranslation"),
                              value: "Tradução do exercício",
                            },
                          ]}
                        />
                      ) : (
                        <>
                          <Select
                            disabled={props.isSubmitting || isOnlyOneTeacher}
                            onChange={(value) => handleChange("teacher", value)}
                            value={get(values, "teacher", "")}
                            error={isSubmitted ? get(errors, "teacher", null) : null}
                            label={props.getTranslation("messages.selectTeacher")}
                            fullWidth
                            options={profile.teachers.map((teacher) => ({
                              label: teacher.name,
                              value: teacher.id,
                            }))}
                          />
                          <Separator size="sm" />
                          <TextInput
                            disabled={props.isSubmitting}
                            onChange={(value) => handleChange("subject", value)}
                            value={get(values, "subject", "")}
                            error={isSubmitted ? get(errors, "subject", null) : null}
                            label={props.getTranslation("messages.subjectPlaceholder")}
                            fullWidth
                          />
                        </>
                      )}
                      <Separator size="sm" />
                      <TextInput
                        disabled={props.isSubmitting}
                        onChange={(value) => handleChange("text", value)}
                        value={get(values, "text", "")}
                        error={isSubmitted ? get(errors, "text", null) : null}
                        label={props.getTranslation("messages.placeholder")}
                        fullWidth
                        multiLine={true}
                      />
                      <Separator size="sm" />
                      <Button
                        disabled={
                          props.isSubmitting
                          || !get(values, "text", false)
                          || !get(values, "subject", false)
                          || (get(values, "type", "") === "TO_TEACHER" && !get(values, "teacher", false))
                        }
                        loading={props.isSubmitting}
                        color={primaryColor}
                        width="auto"
                        style={{
                          textTransform: "uppercase",
                          fontSize: 16,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {props.getTranslation("messages.send")}
                        <ColumnSeparator size="sm" />
                        <Icon icon="send" color="#fff" size="xs" />
                      </Button>
                    </FlexColumn>
                  </div>
                </HtmlForm>
              )
            }}
          </Form>
        )}
      </WhitelabelContext.Consumer>
    </MaterialTheme>
  )
};

NewMessageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation(NewMessageForm);
