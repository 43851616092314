import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import color from "color";
import { withRouter } from "react-router-dom";
import camelCase from "lodash/camelCase";
import TranslatedText from "../TranslatedText";
import ColumnSeparator from "../ColumnSeparator";
import NotificationIcon from "./NotificationIcon";
import NotificationPropsHoc from "./NotificationPropsHoc";
import { GREY_6, WHITE } from "../../consts/color";

class NotificationListItem extends React.PureComponent {
  static propTypes = {
    configurations: PropTypes.shape({
      unitsToFinishAfterTestFailure: PropTypes.number,
    }).isRequired,
    notification: PropTypes.shape({
      type: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      read: PropTypes.bool.isRequired,
    }).isRequired,
  };

  handleNotificationClick = () => {
    if (
      this.props.notification.type === "FIRST_REVIEW_ENABLED" ||
      this.props.notification.type === "SECOND_REVIEW_ENABLED"
    ) {
      if (this.props.currentCourse !== this.props.notification.module.course) {
        return null;
      }

      this.props.history.push(
        `/course/${this.props.notification.module.course}/modules/${this.props.notification.module.id}/units`,
        {
          targetUnit: this.props.notification.unit.id,
        }
      );
    }
    if (
      this.props.notification.type === "MASTERY_TEST_ENABLED" ||
      this.props.notification.type === "MASTERY_TEST_RETRY"
    ) {
      if (this.props.currentCourse !== this.props.notification.module.course) {
        return null;
      }
      this.props.history.push(`/course/${this.props.notification.module.course}/modules`, {
        targetMasteryTest: this.props.notification.masteryTest.id,
      });
    }
    if (this.props.notification.type === "UNIT_GROUP_ENABLED") {
      if (this.props.currentCourse !== this.props.notification.module.course) {
        return null;
      }
      this.props.history.push(
        `/course/${this.props.notification.module.course}/modules/${this.props.notification.module.id}/units`
      );
    }
    if (this.props.notification.type === "MODULE_GROUP_ENABLED") {
      if (this.props.currentCourse !== this.props.notification.course.id) {
        return null;
      }
      this.props.history.push(`/course/${this.props.notification.course.id}/modules`);
    }
    if (this.props.notification.type === "ACHIEVEMENT") {
      this.props.history.push("/profile");
    }
    return null;
  };

  hasClick = () =>
    [
      "FIRST_REVIEW_ENABLED",
      "SECOND_REVIEW_ENABLED",
      "MASTERY_TEST_ENABLED",
      "MASTERY_TEST_RETRY",
      "UNIT_GROUP_ENABLED",
      "MODULE_GROUP_ENABLED",
      "ACHIEVEMENT",
    ].some((t) => t === this.props.notification.type);

  render() {
    return (
      <button
        onClick={this.handleNotificationClick}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          position: "relative",
          backgroundColor: this.props.notification.read ? "#fff" : color(this.props.color).fade(0.95),
          padding: "5px 30px 5px 15px",
          cursor: this.hasClick() ? "pointer" : "auto",
          outline: "none",
          border: "none",
          textAlign: "left",
          width: "100%",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 70,
            height: 70,
            borderRadius: 70,
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: color(this.props.color).fade(0.8),
            backgroundColor: WHITE,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 60,
              height: 60,
              borderRadius: 60,
              borderWidth: 1,
              backgroundColor: color(this.props.color).fade(0.8),
            }}
          >
            <NotificationIcon type={this.props.notification.type} />
          </div>
        </div>
        <ColumnSeparator size="sm" />
        <div
          style={{
            display: "flex",
            flex: 1,
            color: GREY_6,
            opacity: 0.9,
          }}
        >
          <TranslatedText
            as="span"
            translateKey={`notifications.type.${camelCase(this.props.notification.type)}`}
            values={this.props.messageProps}
          />
        </div>
        <ColumnSeparator size="xs" />
        <div
          style={{
            textAlign: "right",
            color: GREY_6,
            opacity: 0.5,
            fontSize: 12,
            alignSelf: "flex-start",
            paddingTop: 10,
          }}
        >
          {moment(this.props.notification.createdAt).locale(sessionStorage.getItem("locale")).fromNow()}
        </div>
      </button>
    );
  }
}

export default withRouter(NotificationPropsHoc(NotificationListItem));
