import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ColumnSeparator from "./ColumnSeparator";
import Separator from "./Separator";
import Icon from "./Icon";

const getErrorCode = (path) => {
  if (["/mastery-test-approved", "/mastery-test-failed", "/mastery-test-not-found"].some((p) => p === path)) {
    return 400;
  }
  return 404;
};

const getErrorCodeDescription = (path) => {
  if (path === "/exercise-block-due-time") {
    return "Page Blocked";
  }
  return "Page Not Found";
};

const getError = (path) => {
  if (path === "/exercise-block-due-time") {
    return "Sorry, this exercise is Locked.";
  }
  if (path === "/mastery-test-approved") {
    return "You already finished the mastery test.";
  }
  if (path === "/mastery-test-failed") {
    return "The mastery test is failed. Keep doing the units to enable it again";
  }
  return "Sorry, the page not found on server.";
};

const getErrorDescription = (path) => {
  if (path === "/exercise-block-due-time") {
    return "You will need to wait 3 minutes until doing doing this exercise again.";
  }
  if (path === "/mastery-test-approved") {
    return "You already did the mastery test. The mastery tests available have the orange color.";
  }
  if (path === "/mastery-test-failed") {
    return "You already did the mastery test and have failed. To enable it again you need to study more units.";
  }
  return "The link you followed is either outdated, inaccurate or the server has been instructed not let you have it.";
};

const NotFoundScene = (props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div
      style={{
        background: "#fff",
        borderRadius: 3,
        padding: "80px 60px",
        maxWidth: 750,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
      }}
    >
      <div
        style={{
          backgroundColor: "#eee",
          borderRadius: "100%",
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <label
          htmlFor="error"
          style={{
            fontSize: 68,
            fontWeight: "bold",
            color: "#8e8e8e",
            margin: 0,
            lineHeight: "68px",
          }}
        >
          {getErrorCode(props.location.pathname)}
        </label>
        <small
          style={{
            color: "#8e8e8e",
          }}
        >
          {getErrorCodeDescription(props.location.pathname)}
        </small>
      </div>
      <div
        style={{
          textAlign: "center",
          maxWidth: 375,
        }}
      >
        <h2
          style={{
            margin: 0,
            fontSize: 52,
            fontWeight: "bold",
            lineHeight: "52px",
            color: "#1188ff",
          }}
        >
          Oops!
        </h2>
        <Separator size="lg" />
        <p
          style={{
            textTransform: "uppercase",
            margin: 0,
            color: "#444",
            fontWeight: "bold",
          }}
        >
          {getError(props.location.pathname)}
        </p>
        <Separator size="md" />
        <Separator size="xs" />
        <label
          htmlFor="message"
          style={{
            margin: 0,
            color: "#8e8e8e",
            fontSize: 12,
          }}
        >
          {getErrorDescription(props.location.pathname)}
        </label>
        <Separator size="lg" />
        <a href="/">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              textDecoration: "underline",
              justifyContent: "center",
            }}
          >
            <Icon icon="arrow-left-bold" size="sm" />
            <ColumnSeparator size="xs" />
            Back to home
          </div>
        </a>
      </div>
    </div>
  </div>
);

NotFoundScene.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default withRouter(NotFoundScene);
