import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import FlexRow from "../FlexRow";
import InBetweenSpacing from "../InBetweenSpacing";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../CourseThemeContext";

export default class SpeechRecognitionExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }).isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    extraData: PropTypes.shape({}),
    shouldShowCorrectOption: PropTypes.bool,
  };

  static defaultProps = {
    extraData: null,
    shouldShowCorrectOption: false,
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <ExerciseItemPanel>
            <HighlightableItemBox flexWrap="wrap">
              {this.props.item.text.split(" ").map((slice, index) => {
                const foundAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === index);
                if (foundAnswer) {
                  return (
                    <div key={`answer_id_${foundAnswer.id}`}>
                      {get(this.props.extraData, index.toString(), false) ? (
                        <QuestionLabel
                          color={primary}
                          fontWeight={700}
                          text={
                            this.props.shouldShowCorrectOption
                              ? foundAnswer.text.replace(/[!.?,]$/, "")
                              : get(this.props.extraData, index.toString(), "").replace(/[!.?,]$/, "")
                          }
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: 18,
                            fontWeight: 400,
                            color: "rgb(96, 125, 139)",
                            display: "inline",
                            textDecoration: "underline",
                            margin: "0 5px",
                          }}
                        >
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div>
                      )}
                      {/[!.?,]$/.test(foundAnswer.text) && (
                        <QuestionLabel text={foundAnswer.text.substring(foundAnswer.text.length - 1)} />
                      )}
                      &nbsp;
                    </div>
                  );
                }
                return (
                  !this.props.item.answers.find((answer) => answer.index === index) && (
                    <div
                      key={`${this.props.item.id}-text-${index}`}
                      style={{
                        display: "flex",
                      }}
                    >
                      <QuestionLabel text={slice} />
                      &nbsp;
                    </div>
                  )
                );
              })}
            </HighlightableItemBox>
            <Separator size="lg" />
            <FlexRow justifyContent="center" flexWrap="wrap">
              <InBetweenSpacing size="sm">
                {this.props.item.linkedAnswers.map((answer) => (
                  <div
                    key={`answer-${answer.id}`}
                    style={{
                      width: "auto",
                      height: "auto",
                      borderRadius: 3,
                      boxSizing: "border-box",
                      padding: "10px 15px",
                      textAlign: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      borderBottom: "1px solid #dedede",
                      marginBottom: 10,
                    }}
                  >
                    {/[!.?,]$/.test(answer.text) ? (
                      <QuestionLabel text={answer.text.substring(0, answer.text.length - 1)} cursor="normal" />
                    ) : (
                      <QuestionLabel text={answer.text} cursor="normal" />
                    )}
                  </div>
                ))}
              </InBetweenSpacing>
            </FlexRow>
          </ExerciseItemPanel>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}
