import Styled from "styled-components";

const CourseDetailPanel = Styled.div`
  display: flex;
  align-items: center;
  z-index: 4;
  position: absolute;
  width: ${(props) => props.width};
  top: calc(-250px + 125px + 40px);
  height: 250px;
  vertical-align: top;
  background-color: ${(props) => props.color};
  border-right: 4px solid #fff;
  border-radius: 100%;
  box-sizing: border-box;
  padding: 0px 10px;
  margin-left: -9px;
`;

export default CourseDetailPanel;
