import React, { useContext } from "react";
import { GREY_1 } from "../consts/color";
import { min } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import CourseThemeContext from "./CourseThemeContext";

const MediaProgressBar = ({ currentTime, duration, loadedPercent }) => {
  const courseThemeContext = useContext(CourseThemeContext);
  const percentProgress = !currentTime || !duration ? 0 : (currentTime / duration) * 100;
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "4px",
          backgroundColor: GREY_1,
          display: "block",
          marginTop: "20px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            transition: "width 1s ease-in-out",
            backgroundColor: courseThemeContext.secondary,
            opacity: 0.5,
            width: `${loadedPercent}%`,
            height: 3,
          }}
        />
        <div
          style={{
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            width: `${percentProgress}%`,
            backgroundColor: courseThemeContext.primary,
            transition: "all .25s linear",
          }}
        />
        <div
          style={{
            width: "14px",
            height: "14px",
            borderRadius: "25px",
            backgroundColor: courseThemeContext.primary,
            position: "absolute",
            // we use 98 as maximum value to prevent the bullet to overflow the progress bar width
            left: `${min([98, percentProgress])}%`,
            top: "-5px",
            transition: "all .25s linear",
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "15px",
        }}
      >
        {!!duration && !!currentTime && (
          <>
            <span style={{ color: courseThemeContext.primary }}>
              {moment.duration(currentTime, "seconds").format("mm:ss", { trim: false })}
            </span>
            <span style={{ color: courseThemeContext.primary }}>
              -{moment.duration(duration - currentTime, "seconds").format("mm:ss", { trim: false })}
            </span>
          </>
        )}
      </div>
    </>
  );
};

MediaProgressBar.propTypes = {
  currentTime: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  loadedPercent: PropTypes.number,
};

MediaProgressBar.defaultProps = {
  loadedPercent: 0,
};

export default MediaProgressBar;
