import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../TranslatedText";

const StudyQualityStatsHeader = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          padding: "15px 40px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <TranslatedText
          as="label"
          translateKey="studyQuality.modalTitle"
          style={{
            fontSize: 18,
            lineHeight: "20px",
            width: 60,
            margin: 0,
            textAlign: "center",
            color: primaryColor,
          }}
        />
        <label
          htmlFor="score"
          style={{
            fontSize: 80,
            lineHeight: "80px",
            width: 80,
            margin: 0,
            color: primaryColor,
          }}
        >
          {props.score}
        </label>
        <TranslatedText
          as="h1"
          translateKey="studyQuality.howToImprove"
          style={{
            margin: 0,
            fontSize: 36,
            width: 330,
            color: primaryColor,
          }}
        />
      </div>
    )}
  </WhitelabelContext.Consumer>
);

StudyQualityStatsHeader.propTypes = {
  score: PropTypes.number.isRequired,
};

export default StudyQualityStatsHeader;
