import React from "react";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import Entity from "student-front-commons/src/components/Entity";
import MessageChatHeader from "./MessageChatHeader";

const MessageChatHeaderContainer = (props) => (
  <Entity name="profile">
    {({ getById: getProfileById }) => (
      <Entity name="messageChannel">
        {({ getById }) => {
          const channel = getById(get(props.history.location, "state.messageChannel", null));
          if (channel) {
            return <MessageChatHeader channel={channel} chattingWith={getProfileById(channel.chattingWith)} />;
          }
          return null;
        }}
      </Entity>
    )}
  </Entity>
);

export default withRouter(MessageChatHeaderContainer);
