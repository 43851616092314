import React, { Component } from "react";
import moment from "moment";
import FetchData from "student-front-commons/src/components/FetchData";
import { getStatsByPeriodAndGroup } from "student-front-commons/src/services/studentStatsService";
import MonthPerformanceChart from "./MonthPerformanceChart";

class MonthPerformanceChartContainer extends Component {
  render() {
    const from = moment().subtract(6, "month").startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("month").format("YYYY-MM-DD");
    return (
      <FetchData
        service={getStatsByPeriodAndGroup}
        params={{
          id: sessionStorage.getItem("id"),
          from,
          to,
          group: "month",
        }}
      >
        {({ data, isFetching }) => <MonthPerformanceChart data={data || []} isFetching={isFetching} />}
      </FetchData>
    );
  }
}

export default MonthPerformanceChartContainer;
