import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ScrolledContent from "../core/ScrolledContent";

const PageableScroll = (props) => {
  const observedDiv = useRef(null);

  const [page, setPage] = useState(1);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    if (observedDiv.current) {
      const contentHeight = observedDiv.current?.querySelector(".ScrollbarsCustom-Content")?.clientHeight;
      if (contentHeight) {
        setHeight(contentHeight);
      }
    }
  }, [props.children, props.pageHeight]);

  const handleScroll = ({ scrollTop }) => {
    if (scrollTop >= page * props.pageHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const { children, pageHeight, ...rest } = props;
  return (
    <div ref={observedDiv} style={{ height: "100%", width: "100%" }}>
      <ScrolledContent
        style={{
          height,
          maxHeight: 450,
          ...rest,
        }}
        vertical
        onScroll={handleScroll}
      >
        {children({
          page,
        })}
      </ScrolledContent>
    </div>
  );
};

ScrolledContent.propTypes = {
  children: PropTypes.node.isRequired,
  pageHeight: PropTypes.number.isRequired,
};

export default PageableScroll;
