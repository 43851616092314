import trim from "lodash/trim";
import shuffle from "lodash/shuffle";
import orderBy from "lodash/orderBy";
import last from "lodash/last";
import compact from "lodash/compact";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * start the execution of an unit
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.module - the module id
 * @param {string} payload.unit - the unit id
 * @param {boolean} payload.optionalReview - send it to indicate that the unit it's an optional review
 * @param {boolean} payload.contentVideoRecommendation - send it to indicate that the unit was accessed from a content video recommendation
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const startUnitExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const result = await request({
        url: `modules/${payload.module}/units/${payload.unit}/executions`,
        method: "post",
        data: {
          optionalReview: !!payload.optionalReview,
          contentVideoRecommendation: !!payload.contentVideoRecommendation,
        },
      });

      return {
        unitExecution: result.unitExecution,
        items: orderBy(
          result.items.map((unitItem) => ({
            ...unitItem,
            item: {
              ...unitItem.item,
              text: ["TEXT", "UNSCRAMBLE_TEXT"].some((type) => type === unitItem.item.type.key)
                ? trim(unitItem.item.text)
                : trim(unitItem.item.text).replace(/\s+/g, " "),
              answers: shuffle(unitItem.item.answers),
            },
          })),
          "order",
          "asc"
        ),
      };
    },
    "error_start_unit_execution",
    "Unexpected error to start unit execution."
  );
};

/**
 * save the unit item execution
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the unit id
 * @param {!string} payload.unitExecution - the unitResult id
 * @param {!string} payload.item - the unitItem id
 * @param {!boolean} payload.correct - if answer is correct or not
 * @param {!number} payload.errorCount - the count of errors
 * @param {!number} payload.repeatCount - the count of repeat usage
 * @param {!number} payload.recordCount - the count of record usage
 * @param {!number} payload.listenCount - the count of listen usage
 * @param {!number} payload.readCount - the count of read usage
 * @param {!number} payload.readCount - the count of read usage
 * @param {!number} payload.translateCount - the count of translate usage
 * @param {number} payload.userAwayCount - the count of user away notifications
 * @param {number} payload.timeReportingError - the total time using the report error function
 * @param {!Object[]} payload.attempts - the count of translate usage
 * @param {!(string|number|boolean)} payload.attempts[].answer - the user answer, could be the SR score, text, or true/false
 * @param {!boolean} payload.attempts[].correct - if the attempt is correct or not
 * @param {blob} payload.audio - the last SR blob
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const saveUnitItemExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      unitExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      item: {
        presence: {
          allowEmpty: false,
        },
      },
      correct: {
        presence: {
          allowEmpty: false,
        },
      },
      errorCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      repeatCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      recordCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      listenCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      readCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      translateCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      userAwayCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      timeReportingError: {
        presence: {
          allowEmpty: false,
        },
        numericality: true,
      },
      attempts: {
        presence: {
          allowEmpty: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const formData = new FormData();

      formData.append("item", payload.item);
      formData.append("correct", payload.correct);
      formData.append("errorCount", payload.errorCount);
      formData.append("repeatCount", payload.repeatCount);
      formData.append("recordCount", payload.recordCount);
      formData.append("listenCount", payload.listenCount);
      formData.append("readCount", payload.readCount);
      formData.append("translateCount", payload.translateCount);
      formData.append("userAwayCount", payload.userAwayCount);
      formData.append("timeReportingError", payload.timeReportingError);

      payload.attempts.forEach((attempt, index) => {
        formData.append(`attempts[${index}][answer]`, attempt.answer);
        formData.append(`attempts[${index}][correct]`, attempt.correct);
      });

      const lastAttempt = last(payload.attempts);
      if (lastAttempt?.wordScoreList) {
        compact(lastAttempt.wordScoreList).forEach(({ word, qualityScore, audioStartTime, audioEndTime }, index) => {
          formData.append(`lastRecordWordScoreList[${index}][word]`, word);
          formData.append(`lastRecordWordScoreList[${index}][qualityScore]`, qualityScore);
          formData.append(`lastRecordWordScoreList[${index}][audioStartTime]`, audioStartTime);
          formData.append(`lastRecordWordScoreList[${index}][audioEndTime]`, audioEndTime);
        });
      }

      if (payload.audio) {
        formData.append("audio", payload.audio);
      }

      return await request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.unitExecution}/answers`,
        method: "post",
        data: formData,
      });
    },
    "error_save_unit_execution_answer",
    "Unexpected error to save answer."
  );
};

/**
 * end the execution of an unit and get the score
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the unit id
 * @param {!string} payload.unitExecution - the unitResult id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const endUnitExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      unitExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.unitExecution}`,
        method: "put",
      });
    },
    "error_end_unit_execution",
    "Unexpected error to end unit execution."
  );
};

/**
 * end the execution of an unit and get the score
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the unit id
 * @param {!string} payload.unitExecution - the unitResult id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const reportUnitExecutionError = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      unitExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.unitExecution}/report-error`,
        method: "patch",
      });
    },
    "error_report_unit_execution_error",
    "Unexpected error to save report error count."
  );
};

/**
 * update the last action of a student inside an item
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the unit id
 * @param {!string} payload.unitExecution - the unitResult id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const updateLastStudentAction = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      unitExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.unitExecution}/last-action`,
        method: "patch",
      });
    },
    "error_update_last_action",
    "Unexpected error to update student last action."
  );
};

/**
 * save the rating of unit execution
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the unit id
 * @param {!string} payload.unitExecution - the unitResult id
 * @param {!string} payload.question - the rating question
 * @param {!string} payload.score - the rating score
 * @param {!string} payload.comment - the rating comment
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const saveUnitExecutionRating = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      unitExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      question: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return request({
        url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.unitExecution}/rating`,
        method: "patch",
        data: {
          question: payload.question,
          comment: payload.comment,
          ...(payload.score && { score: payload.score }),
        },
      });
    },
    "error_save_unit_execution_rating",
    "Unexpected error to save unit execution rating."
  );
};
