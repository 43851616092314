import React, { Component } from "react";
import PropTypes from "prop-types";
import HighlightableItemBox from "./HighlightableItemBox";
import QuestionLabel from "../QuestionLabel";
import Select from "../Select";
import withTranslation from "../withTranslation";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../../core/CourseThemeContext";
import get from "lodash/get";

class SelectExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    onConfirm: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    playingId: PropTypes.string,
    extraData: PropTypes.string,
    shouldShowCorrectOption: PropTypes.bool,
  };

  static defaultProps = {
    extraData: "",
    playingId: null,
    shouldShowCorrectOption: false,
  };

  updateUserAnswer = (extraData) => {
    const answerText = this.props.item.text
      .split(" ")
      .reduce((acc, slice, sliceIndex) => {
        const dropAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === sliceIndex);
        if (dropAnswer) {
          const chunk = get(extraData.chunks, dropAnswer.id.toString(), "-");
          return acc
            .concat(chunk)
            .concat(/[!.?,]$/.test(dropAnswer.text) ? dropAnswer.text.substr(dropAnswer.text.length - 1) : "")
            .concat(" ");
        }
        if (!this.props.item.answers.some((answer) => answer.index === sliceIndex)) {
          return acc.concat(slice).concat(" ");
        }
        return acc;
      }, "")
      .trim();

    this.props.onChange("answer", answerText);
  };

  handleChange = (id, value) => {
    const extraData = {
      ...this.props.extraData,
      chunks: {
        ...get(this.props.extraData, "chunks", {}),
        [id.toString()]: value,
      },
    };
    this.props.onChange("extraData", extraData);
    this.updateUserAnswer(extraData);
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <ExerciseItemPanel>
            <HighlightableItemBox flexWrap="wrap">
              {this.props.item.text.split(" ").map((slice, index) => {
                const foundAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === index);
                if (foundAnswer) {
                  return (
                    <div
                      key={`select-answer-${foundAnswer.id}`}
                      className="selectExerciseDropDown"
                      style={{
                        minWidth: 100,
                        height: 40,
                        margin: "7px 13px 13px 5px",
                        display: "flex",
                        alignItems: "baseline",
                      }}
                    >
                      <Select
                        placeholder={this.props.getTranslation("items.placeholder.gapFillSelect")}
                        value={
                          this.props.shouldShowCorrectOption
                            ? foundAnswer.text
                            : get(this.props.extraData.chunks, foundAnswer.id)
                        }
                        onChange={(value) => this.handleChange(foundAnswer.id, value)}
                        options={this.props.item.linkedAnswers.map((answer) => ({
                          value: answer.text.replace(/[!.?,]$/, ""),
                          label: answer.text.replace(/[!.?,]$/, ""),
                        }))}
                        disabled={this.props.isDisabled}
                        style={{
                          minWidth: 175,
                          ...(this.props.shouldShowCorrectOption && {
                            color: primary,
                          }),
                        }}
                      />
                      {/[!.?,]$/.test(foundAnswer.text) && (
                        <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} />
                      )}
                      &nbsp;
                    </div>
                  );
                }
                return (
                  !this.props.item.answers.find((answer) => answer.index === index) && (
                    <div key={`${this.props.item.id}-text-${slice}-index-${index}`}>
                      <QuestionLabel text={slice} /> &nbsp;
                    </div>
                  )
                );
              })}
            </HighlightableItemBox>
          </ExerciseItemPanel>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}

export default withTranslation(SelectExerciseItem);
