import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import ColumnSeparator from "../../core/ColumnSeparator";

const CourseSummaryStatsItem = (props) => (
  <div>
    <img
      alt="icon"
      src={props.icon}
      style={{
        height: 100,
      }}
    />
    <ColumnSeparator size="xs" />
    <ColumnSeparator size="sm" />
    <div
      style={{
        display: "inline-block",
        verticalAlign: "top",
      }}
    >
      <Separator />
      <p
        style={{
          fontSize: 14,
          opacity: 0.5,
          color: props.color,
          margin: 0,
        }}
      >
        {props.title}
      </p>
      <h1
        style={{
          margin: 0,
          fontSize: 42,
          color: props.color,
          fontWeight: "bold",
        }}
      >
        {props.value}
      </h1>
    </div>
  </div>
);

CourseSummaryStatsItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default CourseSummaryStatsItem;
