import React, { useContext } from "react";
import PropTypes from "prop-types";
import { min } from "lodash";
import CourseThemeContext from "./CourseThemeContext";

function ProgressBar(props) {
  const courseThemeContext = useContext(CourseThemeContext);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: props.maxWidth || 690,
        height: props.height || 2,
        borderRadius: 3,
        backgroundColor: props.backgroundColor || "#cecece",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          transition: "width 1s ease-in-out",
          backgroundColor: courseThemeContext.secondary,
          width: `${min([props.percentage, 100])}%`,
          height: props.height || 2,
        }}
      />
    </div>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default ProgressBar;
