import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import TranslatedText from "../../core/TranslatedText";
import Icon from "../../core/Icon";

const ButtonWithHover = Styled.button`
  position: relative;
  transition: all ease-in-out 150ms;

  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 4px 10px 0px rgba(0, 0, 0, 0.3)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
  
  i {
    line-height: 18px;
  }
`;

const UnitButton = (props) => (
  <ButtonWithHover
    disabled={props.isDisabled}
    onClick={props.onClick}
    style={{
      backgroundColor: {
        RECAP: "#EF8C3B",
        WATCH_AGAIN: "#ECECEC",
        START: props.isReview ? "#AF88FF" : "#EF8C3B",
        CONTINUE: props.isReview ? "#AF88FF" : "#EF8C3B",
        WATCH: "#EF8C3B",
        LOCKED: "#ECECEC",
      }[props.type],
      boxShadow: {
        RECAP: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
        WATCH_AGAIN: "inset 0px -2px 0px rgba(0, 0, 0, 0.1)",
        START: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
        CONTINUE: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
        WATCH: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
        LOCKED: "none",
      }[props.type],
      borderRadius: 50,
      width: "max-content",
      outline: "none",
      border: "none",
      padding: {
        RECAP: "7px 40px",
        WATCH_AGAIN: "5px 25px",
        START: "7px 40px",
        CONTINUE: "7px 40px",
        WATCH: "7px 40px",
        LOCKED: "7px 40px",
      }[props.type],
      display: "flex",
      gap: 4,
      alignItems: "center",
    }}
  >
    {["WATCH", "WATCH_AGAIN"].includes(props.type) && (
      <Icon
        icon="play-circle-outline"
        color={
          {
            WATCH_AGAIN: "#424242",
            WATCH: "#fff",
          }[props.type]
        }
        size={
          {
            WATCH_AGAIN: "xxs",
            WATCH: "xs",
          }[props.type]
        }
      />
    )}
    <TranslatedText
      translateKey={`unit.button.${props.type.toLowerCase()}`}
      style={{
        fontWeight: "bold",
        color: {
          RECAP: "#fff",
          WATCH_AGAIN: "#424242",
          START: "#fff",
          CONTINUE: "#fff",
          WATCH: "#fff",
          LOCKED: "#42424280",
        }[props.type],
        fontSize: {
          RECAP: 12,
          WATCH_AGAIN: 10,
          START: 12,
          CONTINUE: 12,
          WATCH: 12,
          LOCKED: 12,
        }[props.type],
        lineHeight: {
          RECAP: "14px",
          WATCH_AGAIN: "12px",
          START: "14px",
          CONTINUE: "14px",
          WATCH: "14px",
          LOCKED: "14px",
        }[props.type],
        textTransform: "uppercase",
      }}
    />
  </ButtonWithHover>
);

UnitButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isReview: PropTypes.bool,
  type: PropTypes.oneOf(["START", "CONTINUE", "LOCKED", "RECAP", "WATCH", "WATCH_AGAIN"]).isRequired,
};

export default UnitButton;
