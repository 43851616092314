import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import HeaderMenu from "./HeaderMenu";
import FlexRow from "./FlexRow";
import FlexColumn from "./FlexColumn";
import { WHITE } from "../consts/color";

const Header = (props) => (
  <div
    style={{
      backgroundColor: WHITE,
      height: 60,
      boxSizing: "border-box",
      padding: "8px 0px",
      boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
      zIndex: 10,
      position: "relative",
    }}
  >
    <div className="container">
      <FlexRow alignItems="center" justifyContent="space-between">
        <FlexColumn>
          {!props.disabled ? (
            <Link to="/">
              <Logo src={props.logoSrc} />
            </Link>
          ) : (
            <Logo src={props.logoSrc} />
          )}
        </FlexColumn>
        {!props.disabled && (
          <FlexColumn>
            <HeaderMenu
              locale={props.locale}
              profilePicture={props.profilePicture}
              disabled={props.disabled}
              rankingParticipant={props.rankingParticipant}
            />
          </FlexColumn>
        )}
      </FlexRow>
    </div>
  </div>
);

Header.propTypes = {
  disabled: PropTypes.bool.isRequired,
  rankingParticipant: PropTypes.bool.isRequired,
  locale: PropTypes.string,
  visible: PropTypes.bool,
  logoSrc: PropTypes.string,
  profilePicture: PropTypes.string,
};

Header.defaultProps = {
  logoSrc: null,
  locale: null,
  profilePicture: null,
};

export default Header;
