import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getCourses, setManualCourse } from "student-front-commons/src/services/courseService";
import { logError } from "../util";
import { SET_MANUAL_COURSE } from "../consts";
import { getProfile } from "student-front-commons/src/services/profileService";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import browserHistory from "../browserHistory";

export default function* () {
  yield takeLatest(getFlowStart(SET_MANUAL_COURSE), function* () {
    try {
      const id = sessionStorage.getItem("id");
      const flow = yield select(getFlow(SET_MANUAL_COURSE));

      yield call(setManualCourse, {
        id,
        course: flow.params.course,
      });

      const profileResult = yield call(getProfile, {
        id,
        role: "STUDENT",
      });
      yield put(mergeEntities(profileResult.entities));

      const courses = yield call(getCourses, {
        id,
      });
      yield put(mergeEntities(courses.entities));

      yield call(browserHistory.replace, "/placement-test-complete-profile");
    } catch (error) {
      logError({ error, flow: SET_MANUAL_COURSE });
    } finally {
      yield put(endFlow(SET_MANUAL_COURSE));
    }
  });
}
