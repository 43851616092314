import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import Form from "student-front-commons/src/components/Form";
import HtmlForm from "../HtmlForm";
import MaterialTheme from "../MaterialTheme";
import FlexColumn from "../FlexColumn";
import FlexRow from "../FlexRow";
import Button from "../form/Button";
import Separator from "../Separator";
import Toggle from "../form/Toggle";
import Paragraph from "../Paragraph";
import TextInput from "../form/TextInput";
import TranslatedText from "../TranslatedText";
import withTranslation from "../withTranslation";
import ProfilePictureFormContainer from "../ProfilePictureFormContainer";
import { COMPLETE_PROFILE_FORM } from "../../consts";

const CompleteProfileForm = (props) => (
  <MaterialTheme>
    <Form
      id={COMPLETE_PROFILE_FORM}
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      validations={{
        nickname: [isRequired],
        email: [isRequired, isEmail],
        password: [(value, formValues) => (!value || !value.length) && formValues.newPassword && "Required"],
        newPassword: [(value, formValues) => (!value || !value.length) && formValues.password && "Required"],
      }}
    >
      {({ handleSubmit, handleChange, isSubmitted, values, errors }) => (
        <HtmlForm onSubmit={handleSubmit}>
          <FlexColumn>
            <FlexRow>
              <ProfilePictureFormContainer showFeedback={false} />
            </FlexRow>
            <Separator size="md" />
            <FlexRow justifyContent="space-between">
              <FlexColumn flex={1}>
                <TextInput
                  disabled={props.isSubmitting}
                  onChange={(value) => handleChange("nickname", value)}
                  value={get(values, "nickname", "")}
                  error={isSubmitted ? get(errors, "nickname", null) : null}
                  label="Nickname"
                  maxLength={15}
                  fullWidth
                />
              </FlexColumn>
              <Separator size="lg" />
              <FlexColumn flex={1}>
                <TextInput
                  disabled={props.isSubmitting}
                  onChange={(value) => handleChange("email", value)}
                  value={get(values, "email", "")}
                  error={isSubmitted ? get(errors, "email", null) : null}
                  label={props.getTranslation("profile.account.email")}
                  fullWidth
                />
              </FlexColumn>
            </FlexRow>
            <Separator size="lg" />
            <FlexRow>
              <Toggle
                disabled={props.isSubmitting}
                onChange={(value) => handleChange("changePassword", value)}
                value={get(values, "changePassword", "")}
                label={props.getTranslation("profile.account.passwordQuestion")}
              />
            </FlexRow>
            <Separator size="sm" />
            {get(values, "changePassword", false) && (
              <FlexColumn>
                <Separator size="xs" />
                <TranslatedText
                  as={Paragraph}
                  translateKey="profile.account.passwordDescription"
                  style={{
                    color: "rgba(117, 117, 117, 0.8)",
                  }}
                />
                <FlexRow justifyContent="space-between">
                  <FlexColumn flex={1}>
                    <TextInput
                      type="password"
                      disabled={props.isSubmitting}
                      onChange={(value) => {
                        handleChange("password", value);
                        if (!value || !value.length) {
                          handleChange("newPassword", value);
                        }
                      }}
                      value={get(values, "password", "")}
                      error={isSubmitted ? get(errors, "password", null) : null}
                      label={props.getTranslation("profile.account.currentPassword")}
                      fullWidth
                    />
                  </FlexColumn>
                  <Separator size="lg" />
                  <FlexColumn flex={1}>
                    <TextInput
                      type="password"
                      disabled={props.isSubmitting || !get(values, "password", false)}
                      onChange={(value) => handleChange("newPassword", value)}
                      value={get(values, "newPassword", "")}
                      error={isSubmitted ? get(errors, "newPassword", null) : null}
                      label={props.getTranslation("profile.account.newPassword")}
                      fullWidth
                    />
                  </FlexColumn>
                </FlexRow>
              </FlexColumn>
            )}
            <Separator size="lg" />
            <Button
              type="submit"
              disabled={props.isSubmitting}
              labelStyle={{
                fontWeight: "bold",
                fontSize: 16,
                lineHeight: "20px",
              }}
              colored
            >
              {props.getTranslation("profile.account.button.saveContinue")}
            </Button>
          </FlexColumn>
        </HtmlForm>
      )}
    </Form>
  </MaterialTheme>
);

CompleteProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
};

export default withTranslation(CompleteProfileForm);
