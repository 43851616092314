import React from "react";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import TextReader from "./TextReader";
import { getFirstItemText, getIsFirstItemTypeText } from "student-front-commons/src/selectors/execution";

export default function TextReaderContainer() {
  const isFirstItemTypeText = useSelector(getIsFirstItemTypeText);
  const firstItemText = useSelector(getFirstItemText);
  const currentItemType = useSelector(getCurrentItemExecutionProp("item.type.key"));

  if (isFirstItemTypeText && currentItemType !== "TEXT") {
    return <TextReader text={firstItemText} />;
  }
  return null;
}
