import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  SELECT_ITEM_FLOW,
  START_ITEM_INTRODUCTION_FLOW,
} from "../consts";
import { logError } from "../util";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { get, head } from "lodash";
import { selectItem } from "student-front-commons/src/actions/itemExecution";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getItemExecutions, getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";

const typesToNotAutoSelectId = ["VOCABULARY_ACADEMIC", "MEANINGS_ASSOCIATING"];

export default function* () {
  yield takeLatest(getFlowStart(SELECT_ITEM_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const flow = yield select(getFlow(SELECT_ITEM_FLOW));
          const itemId = get(flow.params, "itemId", null);
          const isInitialPlay = get(flow.params, "initialPlay", false);

          const itemExecutionsType = yield select(getItemsExecutionsType);

          const itemExecutions = yield select(getItemExecutions);

          if (typesToNotAutoSelectId.some((type) => type === itemExecutionsType)) {
            yield put(selectItem(itemId));
          } else {
            const itemIdToAutoSelect = head(itemExecutions.map((itemExecution) => itemExecution.item.id));
            yield put(selectItem(itemIdToAutoSelect));
          }

          const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
          let mediaFlow = PLAY_ITEM_AUDIO_FLOW;
          if (["VIDEO", "VIDEO_LONG", "CONTENT_VIDEO"].some((type) => type === itemExecutionsType)) {
            mediaFlow = PLAY_ITEM_VIDEO_FLOW;
          }

          if (!(profile.pagesAccessed || []).includes(itemExecutionsType) && !profile.skipInstructions) {
            if (isInitialPlay) {
              yield put(startFlow(START_ITEM_INTRODUCTION_FLOW));
            } else {
              yield put(startFlow(mediaFlow, { initialPlay: true }));
            }
          } else {
            yield put(startFlow(mediaFlow, { initialPlay: true }));
          }
        } catch (error) {
          logError({ error, flow: SELECT_ITEM_FLOW });
        } finally {
          yield put(endFlow(SELECT_ITEM_FLOW));
        }
      }),
    });
  });
}
