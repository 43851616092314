import React from "react";
import PropTypes from "prop-types";

const AuthMessage = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: 300,
      maxWidth: 480,
      padding: "10px 30px",
      borderRadius: 4,
      boxSizing: "border-box",
      fontWeight: "bold",
      fontSize: 14,
      color: "#fff",
      boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
      position: "absolute",
      bottom: props.isOpen ? -20 : -40,
      backgroundColor: {
        error: "#ef5350",
        success: "#287F4C",
      }[props.type],
      transition: "opacity .3s, bottom .3s",
      opacity: props.isOpen ? 1 : 0,
      textAlign: "center",
    }}
  >
    {props.message}
  </div>
);

AuthMessage.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["error", "success"]).isRequired,
};

export default AuthMessage;
