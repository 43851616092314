import React from "react";
import Tabs from "../../core/Tabs";
import Title from "../../core/Title";
import withTranslation from "../../core/withTranslation";
import Panel from "../../core/Panel";
import WeekPerformanceChartContainer from "./WeekPerformanceChartContainer";
import MonthPerformanceChartContainer from "./MonthPerformanceChartContainer";

const PerformancePanel = (props) => (
  <div className="stepPerfomance">
    <Panel
      padding="lg"
      // noDataText={props.getTranslation('profile.performance.noData')}
    >
      <Tabs
        title={<Title>{props.getTranslation("profile.performance.title")}</Title>}
        tabs={[
          {
            label: props.getTranslation("week"),
            component: <WeekPerformanceChartContainer />,
          },
          {
            label: props.getTranslation("month"),
            component: <MonthPerformanceChartContainer />,
          },
        ]}
      />
    </Panel>
  </div>
);

export default withTranslation(PerformancePanel);
