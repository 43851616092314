import styled, { keyframes } from "styled-components";
import { isEmpty, isNil, min } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useService } from "student-front-commons/src/hooks";
import { getBonusTestRewards } from "student-front-commons/src/services/bonusTestService";
import { BONUS_TEST_EXECUTION_FORM } from "../../consts";
import { GREY_1 } from "../../consts/color";
import CourseThemeContext from "../../core/CourseThemeContext";
import AudioExecutionReward from "../../audios/bonus-test-execution-reward.mp3";

const JumpingGemAnimation = keyframes`
  0% {
    transform: translateY(0) scale(1.15, 0.85);
  }
  50% {
    transform: translateY(-60%) scale(1, 1);
  }
  55% {
    transform: translateY(-60%) rotate(15deg);
  }
  60% {
    transform: translateY(-60%) rotate(-15deg);
  }
  65% {
    transform: translateY(-60%) rotate(15deg);
  }
  70% {
    transform: translateY(-60%) rotate(-15deg);
  }
  100% {
    transform: translateY(0) scale(1.15, 0.85);
  }
`;

const JumpingGem = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  left: -16px;
  top: -50px;
  animation-name: ${JumpingGemAnimation};
  animation-duration: 1s;
  animation-iteration-count: 3;
`;

function ProgressCheckpoint(props) {
  const checkpointLabel = props.reward?.label.toLowerCase();
  const courseThemeContext = useContext(CourseThemeContext);
  const left = { bronze: 60, silver: 80, gold: 100 }[checkpointLabel];
  const imageBackgroundColor = { bronze: "#F1FFE8", silver: "#DAFAFF", gold: "#FFD3D2" }[checkpointLabel];

  return (
    <>
      {props.correctAnswers >= props.reward?.correctAnswers && <audio src={AudioExecutionReward} autoPlay={true} />}
      <div
        style={{
          position: "absolute",
          left: `calc(${left}% - 12px)`,
          backgroundColor:
            props.correctAnswers >= props.reward?.correctAnswers ? courseThemeContext.secondary : "#cecece",
          border: `4px solid ${GREY_1}`,
          width: "16px",
          height: "16px",
          borderRadius: "16px",
          top: "-6px",
          transition: "all 1.5s ease",
        }}
      >
        {props.correctAnswers >= props.reward?.correctAnswers ? (
          <JumpingGem bgColor={imageBackgroundColor} animate={props.correctAnswers >= props.reward?.correctAnswers}>
            <img src={`${process.env.REACT_APP_FILES_URL}/${props.reward?.icon}`} width="22px" />
          </JumpingGem>
        ) : (
          <div
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "40px",
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: imageBackgroundColor,
              left: "-16px",
              top: "-50px",
            }}
          >
            <img src={`${process.env.REACT_APP_FILES_URL}/${props.reward?.icon}`} width="22px" />
          </div>
        )}
      </div>
    </>
  );
}

export default function BonusTestExecutionProgressBar() {
  const courseThemeContext = useContext(CourseThemeContext);
  const [rewards, setRewards] = useState({});
  const params = useParams();
  const correctAnswers = useSelector(
    (state) => state.forms[BONUS_TEST_EXECUTION_FORM].values.answers.filter((answer) => answer.correct).length
  );

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isEmpty(rewards) && !isNil(correctAnswers)) {
      const noBonusMultiplier =
        correctAnswers >= rewards.bronze.correctAnswers ? rewards.bronze.correctAnswers : correctAnswers;

      let silverBonusDivider = 1;
      let silverBonusMultiplier = 0;
      if (correctAnswers > rewards.bronze.correctAnswers) {
        silverBonusDivider = rewards.silver.correctAnswers - rewards.bronze.correctAnswers;
        silverBonusMultiplier =
          correctAnswers > rewards.silver.correctAnswers
            ? rewards.silver.correctAnswers - rewards.bronze.correctAnswers
            : correctAnswers - rewards.bronze.correctAnswers;
      }

      let goldBonusDivider = 1;
      let goldBonusMultiplier = 0;
      if (correctAnswers > rewards.silver.correctAnswers) {
        goldBonusDivider = rewards.gold.correctAnswers - rewards.silver.correctAnswers;
        goldBonusMultiplier = correctAnswers - rewards.silver.correctAnswers;
      }

      setProgress(
        (60 / rewards.bronze.correctAnswers) * noBonusMultiplier +
          (correctAnswers <= rewards.bronze.correctAnswers ? 0 : (20 / silverBonusDivider) * silverBonusMultiplier) +
          (20 / goldBonusDivider) * goldBonusMultiplier
      );
    }
  }, [rewards, correctAnswers]);

  useService(getBonusTestRewards, {
    autoStart: true,
    params: { idCourse: params.idCourse },
    onData: (data) => setRewards(data),
  });

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        maxWidth: "690px",
        height: "4px",
        borderRadius: "4px",
        backgroundColor: "#cecece",
        margin: "45px auto 10px auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          transition: "width 1s ease",
          backgroundColor: courseThemeContext.secondary,
          width: `${min([progress, 100])}%`,
          borderRadius: "4px",
          height: "4px",
        }}
      />
      <div
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "24px",
          backgroundColor: courseThemeContext.secondary,
          border: `4px solid ${GREY_1}`,
          position: "absolute",
          left: `calc(${min([progress, 100])}% - 16px)`,
          top: "-10px",
          transition: "left 1s ease",
          zIndex: 2,
        }}
      />
      <ProgressCheckpoint reward={rewards.bronze} correctAnswers={correctAnswers} />
      <ProgressCheckpoint reward={rewards.silver} correctAnswers={correctAnswers} />
      <ProgressCheckpoint reward={rewards.gold} correctAnswers={correctAnswers} />
    </div>
  );
}
