import React from "react";
import PropTypes from "prop-types";

const GrammarIcon = ({ color, style }) => (
  <svg width="170" height="89" viewBox="0 0 170 89" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M163 0H30.5C29.2 0 28.2 1.1 28.2 2.3C28.2 3.5 29.3 4.6 30.5 4.6H163C164.3 4.6 165.3 5.7 165.3 6.9V81.9C165.3 83.2 164.2 84.2 163 84.2H7C5.7 84.2 4.7 83.1 4.7 81.9V7C4.7 5.7 5.8 4.7 7 4.7H11.7C13 4.7 14 3.6 14 2.4C14 1.2 13 0 11.7 0H7C3.2 0 0 3.2 0 7V82C0 85.9 3.2 89 7 89H163C166.9 89 170 85.8 170 82V7C170 3.2 166.8 0 163 0Z"
      fill={color}
    />
    <rect x="12" y="12" width="146" height="65" fill="white" />
    <path
      d="M103.6 75C102.3 75 101.3 76.1 101.3 77.3C101.3 78.6 102.4 79.6 103.6 79.6H158.3C159.6 79.6 160.6 78.5 160.6 77.3V11.7C160.6 10.4 159.5 9.4 158.3 9.4H11.6999C10.3999 9.4 9.3999 10.5 9.3999 11.7V77.3C9.3999 78.6 10.4999 79.6 11.6999 79.6H84.7999C86.0999 79.6 87.0999 78.5 87.0999 77.3C87.0999 76 85.9999 75 84.7999 75H14.0999V14.1H156V75H103.6Z"
      fill={color}
    />
    <path
      d="M21.0998 4.7C22.3998 4.7 23.3998 3.6 23.3998 2.4C23.3998 1.2 22.3998 0 21.0998 0C19.7998 0 18.7998 1.1 18.7998 2.3C18.7998 3.5 19.7998 4.7 21.0998 4.7Z"
      fill={color}
    />
    <path
      d="M94.1999 75C92.8999 75 91.8999 76.1 91.8999 77.3C91.8999 78.6 92.9999 79.6 94.1999 79.6C95.4999 79.6 96.4999 78.5 96.4999 77.3C96.5999 76.1 95.4999 75 94.1999 75Z"
      fill={color}
    />
    <path
      d="M29.7791 35.0896C32.8477 35.0896 35.2344 36.3398 36.8256 38.7265L33.8706 41.2268C32.8477 39.7494 31.4839 38.9538 29.6655 38.9538C26.9378 38.9538 25.1194 40.7722 25.1194 43.4999C25.1194 46.3412 26.9378 48.2732 29.8928 48.2732C31.4839 48.2732 32.7341 47.705 33.5297 46.7958V46.0002H29.6655V42.2497H37.8484V47.4777C37.0529 48.8415 35.9163 49.978 34.5525 50.7736C33.075 51.5691 31.4839 52.0237 29.7791 52.0237C24.7785 52.1374 20.687 48.2732 20.8007 43.4999C20.687 38.8401 24.7785 34.976 29.7791 35.0896Z"
      fill={color}
    />
    <path
      d="M50.919 51.7964L47.1684 46.6821H45.35V51.7964H41.0312V35.3169H47.5094C51.1463 35.3169 53.8739 37.59 53.8739 40.9995C53.8739 43.0453 52.851 44.75 51.2599 45.6593L55.5787 51.7964H50.919ZM45.4637 42.9316H47.6231C48.8732 42.9316 49.5551 42.136 49.5551 40.9995C49.5551 39.863 48.7596 39.0674 47.6231 39.0674H45.4637V42.9316Z"
      fill={color}
    />
    <path
      d="M67.7385 50.4326H61.8286L61.2603 51.7964H56.6006L64.1016 34.976H65.4654L72.9664 51.7964H68.3067L67.7385 50.4326ZM64.7835 43.1589L63.4197 47.0231H66.261L64.7835 43.1589Z"
      fill={color}
    />
    <path
      d="M92.2878 35.0896V51.9101H87.969V44.9773L84.5594 48.9551H83.1956L79.7861 44.9773V51.9101H75.4673V35.0896H76.8311L83.8775 43.6135L90.9239 35.0896H92.2878Z"
      fill={color}
    />
    <path
      d="M113.2 35.0896V51.9101H108.881V44.9773L105.472 48.9551H104.108L100.698 44.9773V51.9101H96.3794V35.0896H97.7432L104.79 43.6135L111.836 35.0896H113.2Z"
      fill={color}
    />
    <path
      d="M126.838 50.4326H120.928L120.359 51.7964H115.7L123.201 34.976H124.565L132.066 51.7964H127.406L126.838 50.4326ZM123.883 43.1589L122.405 47.0231H125.246L123.883 43.1589Z"
      fill={color}
    />
    <path
      d="M144.34 51.7964L140.589 46.6821H138.771V51.7964H134.452V35.3169H140.93C144.567 35.3169 147.295 37.59 147.295 40.9995C147.295 43.0453 146.272 44.75 144.681 45.6593L149 51.7964H144.34ZM138.885 42.9316H141.044C142.294 42.9316 142.976 42.136 142.976 40.9995C142.976 39.863 142.18 39.0674 141.044 39.0674H138.885V42.9316Z"
      fill={color}
    />
  </svg>
);

GrammarIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default GrammarIcon;
