import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
import Player from "@vimeo/player";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Slider as MaterialSlider } from "@material-ui/core";
import CourseThemeContext from "./CourseThemeContext";
import FlexRow from "./FlexRow";
import FlexColumn from "./FlexColumn";
import IconButton from "./IconButton";
import ErrorModal from "../error/ErrorModal";
import CircularLoading from "./CircularLoading";
import { getSubtitles } from "student-front-commons/src/services/subtitlesService";
import { useEntity, useService } from "student-front-commons/src/hooks";

const Modes = {
  DEFAULT: "default",
  WITH_PROGRESS_BAR: "progress_bar",
  WITH_CONTROL_BAR: "control_bar",
  WITH_RETURN_10_SECONDS: "return_10_seconds",
};

const VideoPlayer = forwardRef((props, ref) => {
  const courseThemeContext = useContext(CourseThemeContext);
  const profile = useEntity("profile", sessionStorage.getItem("id"));

  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [requestUserInteraction, setRequestUserInteraction] = useState(false);
  const [loadVideoAttempts, setLoadVideoAttempts] = useState(0);
  const [subtitles, setSubtitles] = useState([]);
  const [currentSubtitle, setCurrentSubtitle] = useState("");
  const [selectedLocale, setSelectedLocale] = useState("");

  const playerRef = useRef();
  const slidingRef = useRef(false);

  const [, fetchSubtitles] = useService(getSubtitles, {
    autoStart: false,
    onData: (data) => {
      setSubtitles((prevSubtitle) => [...(prevSubtitle || []), data]);
    },
  });

  useEffect(() => {
    if (props?.textTracks.length) {
      props.textTracks.forEach((track) => fetchSubtitles({ uri: track.uri, language: track.language }));
    }
  }, []);

  useEffect(() => {
    if (props?.textTracks.length) {
      setSelectedLocale(
        props.textTracks.find((track) => track.language === profile?.locale)?.language ||
          props.textTracks.find((track) => track.language === "en")?.language ||
          props.textTracks[0].language
      );
    }
  }, [profile]);

  const handlePlay = useCallback(() => {
    playerRef.current.play();
  }, []);

  const handlePause = useCallback(() => {
    playerRef.current.pause();
  }, []);

  const handleChange = useCallback(
    (event, value) => {
      if (props.mode === Modes.WITH_CONTROL_BAR) {
        slidingRef.current = true;
        setProgress(value);
        setCurrentTime((value * duration) / 100);
      }
    },
    [props.mode, duration]
  );

  const handleChangeCommited = useCallback(
    (event, value) => {
      if (props.mode === Modes.WITH_CONTROL_BAR) {
        slidingRef.current = false;
        playerRef.current.setCurrentTime((value * duration) / 100);
      }
    },
    [props.mode, duration]
  );

  const handleReturn = useCallback(() => {
    if (currentTime >= 10) {
      setCurrentTime((prevTime) => {
        playerRef.current?.setCurrentTime(prevTime - 10);
        return prevTime - 10;
      });
    } else {
      setCurrentTime(0);
      playerRef.current?.setCurrentTime(0);
    }
  }, [currentTime]);

  const handleClose = useCallback(() => {
    setRequestUserInteraction(false);
    playerRef.current.play();
  }, []);

  const Slider = withStyles({
    root: {
      color: courseThemeContext.primary,
    },
    rail: {
      color: courseThemeContext.secondary,
    },
  })(MaterialSlider);

  const handleLoadVideoError = useCallback(() => {
    ["play", "pause", "ended", "timeupdate", "error"].forEach((event) => playerRef.current.off(event));
    playerRef.current.destroy();

    setTimeout(() => {
      setLoadVideoAttempts(loadVideoAttempts + 1);
    }, 500);
  }, [loadVideoAttempts]);

  useEffect(() => {
    if (loadVideoAttempts === 3) {
      props.onFailed();
    }
    if (loadVideoAttempts < 3) {
      const player = new Player("video-container", {
        url: props.src,
        width: props.width,
        responsive: props.responsive,
        keyboard: false,
        height: null,
        loop: false,
        controls: props.mode === Modes.DEFAULT,
      });

      player
        .ready()
        .then(() => {
          player.setVolume(1);
          player.on("play", () => {
            setPlaying(true);
          });
          player.on("pause", () => {
            setPlaying(false);
          });
          player.on("ended", () => {
            props.onVideoEnd();
            setProgress(0);
            setCurrentTime(0);
            setPlaying(false);
          });
          player.on("timeupdate", (data) => {
            setDuration(data.duration);
            if (!slidingRef.current) {
              setProgress(data.percent * 100);
              setCurrentTime(data.seconds);
            }
            props.onChange({ progress: data.percent * 100, duration: data.duration, seconds: data.seconds });
          });
          player.on("error", (error) => {
            if (error.name !== "NotAllowedError") {
              props.onError(error);
            }
          });

          if (props.autoStart) {
            player.play().catch(() => {
              setRequestUserInteraction(true);
            });
          }
        })
        .catch(handleLoadVideoError);
      playerRef.current = player;
      return () => {
        player.destroy();
      };
    }
  }, [props.src, loadVideoAttempts]);

  const handleLocaleChange = useCallback((locale) => {
    setSelectedLocale(locale);
  }, []);

  useEffect(() => {
    if (selectedLocale !== "off") {
      const findIntersection = subtitles
        .find((subtitle) => subtitle.language === selectedLocale)
        ?.data?.find((subtitle) => currentTime * 1000 >= subtitle.startTime && currentTime * 1000 <= subtitle.endTime);

      if (!findIntersection) {
        setCurrentSubtitle("");
      }

      if (findIntersection && findIntersection.text !== currentSubtitle) {
        setCurrentSubtitle(findIntersection.text);
      }
    } else {
      setCurrentSubtitle("");
    }
  }, [currentTime, subtitles, selectedLocale, currentSubtitle]);

  const publicRef = {
    play: () => {
      playerRef.current.play().catch(() => {
        setRequestUserInteraction(true);
      });
    },
    pause: () => {
      playerRef.current.pause();
    },
  };

  useImperativeHandle(ref, () => publicRef);

  return (
    <>
      <FlexColumn width="100%" position="relative">
        {!duration && (
          <FlexColumn justifyContent="center" alignItems="center" width="100%" height="100%" position="absolute">
            <CircularLoading
              size="md"
              rgb={{
                r: 237,
                g: 237,
                b: 237,
              }}
            />
          </FlexColumn>
        )}
        {currentSubtitle && (
          <FlexColumn
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            margin="auto"
            padding="2px 8px"
            bottom={90}
            backgroundColor="#000000"
            maxWidth="80%"
            position="absolute"
          >
            <span style={{ color: "#fff", textAlign: "center" }}>{currentSubtitle}</span>
          </FlexColumn>
        )}
        <div id="video-container" style={{ minHeight: 200 }} />
        <FlexRow alignItems="center">
          {[Modes.WITH_CONTROL_BAR, Modes.WITH_RETURN_10_SECONDS].includes(props.mode) && (
            <FlexColumn marginLeft={-15}>
              {!playing ? (
                <IconButton icon="play" color={courseThemeContext.primary} onClick={handlePlay} />
              ) : (
                <IconButton icon="pause" color={courseThemeContext.primary} onClick={handlePause} />
              )}
            </FlexColumn>
          )}
          {props.mode === Modes.WITH_RETURN_10_SECONDS && (
            <FlexColumn marginLeft={-15}>
              <IconButton icon="rotate-left" color={courseThemeContext.primary} onClick={handleReturn} />
            </FlexColumn>
          )}
          {[Modes.WITH_PROGRESS_BAR, Modes.WITH_CONTROL_BAR, Modes.WITH_RETURN_10_SECONDS].includes(props.mode) && (
            <Slider
              value={progress}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommited}
              color={courseThemeContext.primary}
            />
          )}
          {props.textTracks.length ? (
            <FlexRow marginLeft={15}>
              <select
                style={{
                  background: "none",
                  border: "none",
                  outline: "none",
                  color: "inherit",
                  fontWeight: "bold",
                }}
                value={selectedLocale}
                onChange={(event) => handleLocaleChange(event.target.value)}
              >
                <option value="off">Off</option>
                {props.textTracks.map((track) => (
                  <option value={track.language}>{track.title}</option>
                ))}
              </select>
            </FlexRow>
          ) : null}
        </FlexRow>
        {[Modes.WITH_PROGRESS_BAR, Modes.WITH_CONTROL_BAR, Modes.WITH_RETURN_10_SECONDS].includes(props.mode) && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: courseThemeContext.primary }}>
              {moment.duration(currentTime, "seconds").format("mm:ss", { trim: false })}
            </span>
            <span style={{ color: courseThemeContext.primary }}>
              -{moment.duration(duration - currentTime, "seconds").format("mm:ss", { trim: false })}
            </span>
          </div>
        )}
      </FlexColumn>
      {requestUserInteraction && (
        <ErrorModal
          onClose={handleClose}
          icon="play-circle-outline"
          message="error.error_browser_block_autoplay"
          button="continue"
        />
      )}
    </>
  );
});

VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  onVideoEnd: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  onFailed: PropTypes.func,
  autoStart: PropTypes.bool,
  responsive: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  textTracks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      language: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ),
  mode: PropTypes.oneOf([Modes.DEFAULT, Modes.WITH_PROGRESS_BAR, Modes.WITH_CONTROL_BAR]),
};

VideoPlayer.defaultProps = {
  autoStart: false,
  responsive: false,
  width: 640,
  height: null,
  onVideoEnd: () => {},
  onChange: () => {},
  onError: () => {},
  textTracks: [],
  onFailed: () => {},
  mode: Modes.DEFAULT,
};

export default VideoPlayer;

export { Modes };
