import React from "react";
import PropTypes from "prop-types";

const VocabularyIcon = ({ color, style }) => (
  <svg width="166" height="120" viewBox="0 0 166 120" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path d="M2 26C2 22.134 5.13401 19 9 19H141C144.866 19 148 22.134 148 26V78H2V26Z" fill="white" />
    <path d="M10 16C10 12.134 13.134 9 17 9H149C152.866 9 156 12.134 156 16V68H10V16Z" fill="white" />
    <path d="M19 8C19 4.13401 22.134 1 26 1H158C161.866 1 165 4.13401 165 8V60H19V8Z" fill="white" />
    <path
      d="M158.1 0H23.9C19.6 0 16 3.5 16 7.9V8H15.9C11.6 8 8 11.6 8 15.9V16H7.9C3.5 16 0 19.6 0 23.9V112.1C0 116.4 3.5 120 7.9 120H142C146.4 120 149.9 116.5 149.9 112.1V112H150C154.4 112 157.9 108.5 157.9 104.1V104H158C162.4 104 165.9 100.5 165.9 96.1V7.9C166 3.5 162.4 0 158.1 0ZM19.8 7.9C19.8 5.6 21.7 3.8 23.9 3.8H158C160.3 3.8 162.1 5.7 162.1 7.9V60.1H157.8V15.9C157.8 11.5 154.3 8 149.9 8H19.8V7.9ZM11.8 15.9C11.8 13.6 13.7 11.8 15.9 11.8H150C152.3 11.8 154.1 13.7 154.1 15.9V68.1H149.9V23.9C149.9 19.5 146.4 16 142 16H11.8V15.9ZM7.9 19.8H142C144.3 19.8 146.1 21.7 146.1 23.9V76.1H3.8V23.9C3.8 21.7 5.6 19.8 7.9 19.8ZM146.1 112.1C146.1 114.4 144.2 116.2 142 116.2H7.9C5.6 116.2 3.8 114.3 3.8 112.1V79.9H146.2L146.1 112.1ZM154.2 104.1C154.2 106.4 152.3 108.2 150.1 108.2H150V71.9H154.3L154.2 104.1ZM158.1 100.2H158V63.9H162.3V96.1C162.2 98.3 160.3 100.2 158.1 100.2Z"
      fill={color}
    />
    <path
      d="M66 88.2H13.9C12.9 88.2 12 89 12 90.1C12 91.1 12.8 92 13.9 92H66C67 92 67.9 91.2 67.9 90.1C67.9 89 67.1 88.2 66 88.2Z"
      fill={color}
    />
    <path
      d="M46 104.2H13.9C12.9 104.2 12 105 12 106.1C12 107.1 12.8 108 13.9 108H46C47 108 47.9 107.2 47.9 106.1C47.9 105 47 104.2 46 104.2Z"
      fill={color}
    />
    <path
      d="M13.9 100H25.9C26.9 100 27.8 99.2 27.8 98.1C27.8 97.1 27 96.2 25.9 96.2H13.9C12.9 96.2 12 97 12 98.1C12 99.1 12.9 100 13.9 100Z"
      fill={color}
    />
    <path
      d="M66 96.2H33.9C32.9 96.2 32 97 32 98.1C32 99.1 32.8 100 33.9 100H66C67 100 67.9 99.2 67.9 98.1C67.9 97 67.1 96.2 66 96.2Z"
      fill={color}
    />
    <path d="M25.0002 41.3L19.6002 53.4H18.6002L13.2002 41.3H16.7002L19.1002 47.1L21.5002 41.3H25.0002Z" fill={color} />
    <path
      d="M32.3004 41C35.8004 40.9 38.8004 43.8 38.7004 47.2C38.8004 50.6 35.7004 53.4 32.3004 53.4C28.8004 53.5 25.8004 50.7 25.9004 47.2C25.8004 43.8 28.8004 41 32.3004 41ZM32.3004 43.9C30.4004 43.9 29.1004 45.3 29.1004 47.2C29.1004 48.2 29.4004 49 30.0004 49.6C30.6004 50.2 31.4004 50.5 32.3004 50.5C34.1004 50.5 35.5004 49.2 35.5004 47.2C35.5004 45.2 34.1004 43.9 32.3004 43.9Z"
      fill={color}
    />
    <path
      d="M46.8003 41C49.2003 41 51.2003 42.2 52.2003 44.2L49.8003 45.8C49.0003 44.5 48.0003 43.9 46.7003 43.9C44.8003 43.9 43.5003 45.2 43.5003 47.2C43.5003 48.2 43.8003 49 44.4003 49.6C45.0003 50.2 45.8003 50.5 46.7003 50.5C48.0003 50.5 49.0003 49.9 49.8003 48.6L52.2003 50.2C51.1003 52.2 49.1003 53.4 46.7003 53.4C43.2003 53.5 40.2003 50.7 40.3003 47.2C40.4003 43.8 43.3003 41 46.8003 41Z"
      fill={color}
    />
    <path
      d="M61.3003 52.2H57.0003L56.6003 53.2H53.3003L58.7003 41H59.7003L65.1003 53.1H61.8003L61.3003 52.2ZM59.2003 46.9L58.2003 49.7H60.3003L59.2003 46.9Z"
      fill={color}
    />
    <path
      d="M72.4004 53.1H66.9004V41.3H71.2004C73.5004 41.3 75.2004 42.7 75.2004 44.8C75.2004 45.6 75.0004 46.3 74.5004 46.8C75.7004 47.3 76.5004 48.3 76.5004 49.6C76.4004 51.7 74.7004 53.1 72.4004 53.1ZM69.8004 43.9V46H71.0004C71.7004 46 72.2004 45.6 72.2004 45C72.2004 44.4 71.7004 44 71.0004 44H69.8004V43.9ZM72.2004 48.4H69.8004V50.5H72.2004C72.9004 50.5 73.4004 50.2 73.4004 49.5C73.4004 48.8 73.0004 48.4 72.2004 48.4Z"
      fill={color}
    />
    <path
      d="M78.6001 41.3H81.7001V48.4C81.7001 49.8 82.6001 50.6 83.9001 50.6C85.2001 50.6 86.1001 49.8 86.1001 48.4V41.3H89.2001V48.1C89.2001 51.2 86.9001 53.4 83.9001 53.4C80.9001 53.4 78.6001 51.3 78.6001 48.1V41.3Z"
      fill={color}
    />
    <path d="M92 41.3H95.1V50.4H100V53.2H92V41.3Z" fill={color} />
    <path
      d="M109.1 52.2H104.8L104.4 53.2H101.1L106.5 41.1H107.5L112.9 53.2H109.6L109.1 52.2ZM107 46.9L106 49.7H108.1L107 46.9Z"
      fill={color}
    />
    <path
      d="M121.7 53.1L119 49.4H117.7V53.1H114.6V41.3H119.3C121.9 41.3 123.9 42.9 123.9 45.4C123.9 46.9 123.2 48.1 122 48.8L125.1 53.2H121.7V53.1ZM117.7 46.8H119.3C120.2 46.8 120.7 46.2 120.7 45.4C120.7 44.6 120.1 44 119.3 44H117.7V46.8Z"
      fill={color}
    />
    <path d="M132.4 48.6V53.2H129.3V48.7L125.2 41.3H128.6L130.8 45.4L133 41.3H136.4L132.4 48.6Z" fill={color} />
  </svg>
);

VocabularyIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default VocabularyIcon;
