import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

const Modal = ({ children, style }) => {
  const modalRoot = document.getElementById("root");
  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        top: 0,
        left: 0,
        zIndex: 15,
      }}
    >
      <div
        style={{
          maxWidth: 720,
          boxShadow: "0 1 8 rgba(0, 0, 0, 0.2)",
          borderRadius: 5,
          backgroundColor: "#ffffff",
          padding: "40px 70px",
          textAlign: "center",
          overflow: "hidden",
          boxSizing: "border-box",
          ...style,
        }}
      >
        {children}
      </div>
    </div>,
    modalRoot
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Modal;
