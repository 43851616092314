import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import AbilityList from "./AbilityList";

const AbilityListContainer = (props) => <AbilityList abilities={props.abilities} />;

AbilityListContainer.propTypes = {
  abilities: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(["AVAILABLE", "BLOCKED", "FINISHED"]),
      type: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  abilities: get(state.certificationTestResult, "abilityStatus", []),
});

export default connect(mapStateToProps)(AbilityListContainer);
