import Icon from "../../../core/Icon";
import FlexRow from "../../../core/FlexRow";
import TranslatedText from "../../../core/TranslatedText";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function AttachmentFile({ labelKey, src, position }) {
  return (
    <Link to={{ pathname: `${process.env.REACT_APP_FILES_URL}/${src}` }} target="_blank">
      <FlexRow
        cursor="pointer"
        width="100%"
        padding={8}
        border="1px solid #D9D9D9"
        borderRadius={4}
        alignItems="center"
        gap={4}
      >
        <Icon icon="paperclip" />
        <TranslatedText translateKey={labelKey} values={{ value: position }} color="#2A2E34" />
      </FlexRow>
    </Link>
  );
}

AttachmentFile.propTypes = {
  labelKey: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  src: PropTypes.string.isRequired,
};

export default AttachmentFile;
