import { schema } from "normalizr";
import moment from "moment";

import unitTypeSchema from "./unitTypeSchema";
import { SCHEDULED_UNIT_REVIEW_SCHEMA } from "./index";
import functionOfLanguageSchema from "./functionOfLanguageSchema";

const getUnitStatus = (unit) => {
  if (moment(unit.unitReview.availableAt).isAfter(moment())) {
    return "LOCKED";
  }
  if (unit.unitReview.isOptional) {
    return "OPTIONAL_REVIEW";
  }
  return "FIRST_REVIEW";
};

export default new schema.Entity(
  SCHEDULED_UNIT_REVIEW_SCHEMA,
  {
    type: unitTypeSchema,
    functionsOfLanguage: [functionOfLanguageSchema],
  },
  {
    processStrategy: (entity) => {
      return {
        ...entity,
        status: getUnitStatus(entity),
      };
    },
  }
);
