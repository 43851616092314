import React, { Component } from "react";
import Flow from "student-front-commons/src/components/Flow";
import { DISMISS_SYSTEM_MESSAGE_FLOW, SYSTEM_MESSAGE_FLOW } from "../consts";
import OldErrorModal from "./OldErrorModal";

class OldErrorModalContainer extends Component {
  render() {
    return (
      <Flow id={SYSTEM_MESSAGE_FLOW}>
        {({ isPending, getParams }) =>
          isPending && (
            <Flow id={DISMISS_SYSTEM_MESSAGE_FLOW}>
              {({ handleStart }) => (
                <OldErrorModal
                  message={getParams().message}
                  buttonLabel={getParams().buttonLabel || "close"}
                  icon={getParams().icon}
                  onClose={handleStart}
                />
              )}
            </Flow>
          )
        }
      </Flow>
    );
  }
}

export default OldErrorModalContainer;
