import { get, head, values } from "lodash";

export const getSelectedId = (state) => state.itemExecutions?.selectedId;

export const getItemExecutions = (state) =>
  get(state.itemExecutions, "allIds", []).map((id) => state.itemExecutions.byId[id]);

export const getHasLastItemTypeImageOnUnit = (state) => !!state.itemExecutions?.lastItemTypeImage;

export const getCurrentItemExecutionProp = (path) => (state) =>
  state.itemExecutions?.selectedId ? get(state.itemExecutions.byId[state.itemExecutions.selectedId], path) : undefined;

export const getItemsExecutionsType = (state) => {
  const itemId = head(state.itemExecutions?.allIds);
  if (!itemId) {
    return undefined;
  }
  return state.itemExecutions?.byId[itemId]?.item?.type?.key;
};

export const getLastItemTypeImageOnUnitProp = (path) => (state) => get(state.itemExecutions.lastItemTypeImage, path);

export const getItemExecutionPropById = (id, path) => (state) => get(state.itemExecutions.byId[id], path);

export const isAllItemExecutionsAnswered = (state) =>
  values(state.itemExecutions.byId).every((item) => item.answer !== "" && item.answer?.length > 0);

export const getItemExecutionByAnswerId = (id) => (state) =>
  values(state.itemExecutions.byId).find((item) => item.answer === id);

export const isAnyItemExecutionValidated = (state) =>
  values(state.itemExecutions.byId).some((item) => item.isExecutionValidated);
