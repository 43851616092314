const labels = { 
'account.avatarTakePhoto': 'Take a photo', 
'account.birthDate': 'Birth Date', 
'account.button.save': 'Save', 
'account.button.saveContinue': 'Save and Continue', 
'account.cancelAvatar': 'Cancel', 
'account.changeAvatar': 'Change avatar', 
'account.chooseAvatarOption': 'Choose an option', 
'account.currentPassword': 'Current Password', 
'account.email': 'Email', 
'account.female': 'Female', 
'account.firstAccessDescription': 'As this is the first time you have accessed the platform, we need some information to continue.', 
'account.gender': 'Gender', 
'account.male': 'Male', 
'account.newPassword': 'New Password', 
'account.notRankingParticipant': 'I do not want to be in the ranking', 
'account.passwordDescription': 'To change your password, you must enter your current password and then the new one.', 
'account.passwordQuestion': 'Would you like to change your password?', 
'account.passwordTitle': 'Password', 
'account.rankingParticipant': 'I want to be in the ranking', 
'account.selectFromGallery': 'Select an image from the gallery', 
'account.title': 'Account info', 
'achievement.noData': 'You don\'t have any achievements yet - keep studying to earn your first medal!', 
'achievement.noNationalData': 'No national achievements', 
'achievement.noRegionalData': 'No regional achievements', 
'achievement.noSchoolData': 'No institutional achievements', 
'achievement.noStudentData': 'No personal achievements', 
'achievement.title': 'Achievements', 
'alert.closeContentVideoExecution': 'Are you sure that you want to leave?', 
'alert.closeExecution': 'If you leave now, your progress will be lost after {timeToAllowContinueUnit}. Are you sure want to leave?', 
'alert.closeExecutionNoProgressLoss': 'Are you sure want to leave?', 
'alert.closeMastery': 'If you exit now, your Mastery Test will be failed and you will have to pass new units to enable a new attempt. Are you sure you want to exit?', 
'alert.closeTasting': 'Do you really want to end your demo?', 
'alert.logout': 'You are exiting the app, are you sure you want to exit?', 
'alert.no': 'No', 
'alert.oldAppVersionButton': 'Update now', 
'alert.oldAppVersionDescription': 'You have pending updates. Please update the app to continue.', 
'alert.oldAppVersionTitle': 'Update available', 
'alert.title': 'Attention', 
'alert.yes': 'Yes', 
'announcement.newMasteryTestListLayout': 'Hi, student! There have been some changes made to the Mastery Tests. They will now be available at the end of each phase and will be unlocked once you finish all the exercises of that phase.  If you have any questions regarding this change, our support team will be happy to help you.', 
'bonusTest.closeExecution': 'If you quit now, you will lose the opportunity to continue advancing in the test to achieve maximum progress according to your level, as well as the score bonus. Are you sure you want to quit?', 
'bonusTest.correctAnswers': '{correctAnswers} correct answers', 
'bonusTest.executionTitle': 'Bonus Test', 
'bonusTest.history': 'Bonus Test History', 
'bonusTest.history.notCompleted	': 'Test not completed', 
'bonusTest.historyDiff': 'Compared to the previous test.', 
'bonusTest.hits': '{hits} hits', 
'bonusTest.introduction.blocked.description': 'You have to reach 60% of the course to receive the bonus.', 
'bonusTest.introduction.blocked.title': 'Keep progressing through the course to earn your Bonus.', 
'bonusTest.introduction.confirmation': 'You only have one chance to take the exam and your progress cannot be saved. Are you sure you want to start?', 
'bonusTest.introduction.description': 'Answer as many questions correctly as possible to analyze your progress and continue earning points for the ranking.', 
'bonusTest.introduction.welcome': 'Welcome to the Bonus Test!', 
'bonusTest.noBonus': 'No Bonus', 
'bonusTest.noPoints': 'No points', 
'bonusTest.points': '{points} points', 
'bonusTest.rescue': 'Get Bonus', 
'bonusTest.result.bonusPoints': 'You earned {points} bonus points', 
'bonusTest.result.correctAnswers': 'You got <b>{correctAnswers} answers</b>!', 
'bonusTest.result.lastResults': 'Latest Results', 
'bonusTest.result.noBonus.description': 'Don\'t worry about it, you can take a new test in your next course.', 
'bonusTest.result.noBonus.title': 'I\'m sorry. Better luck next time.', 
'bonusTest.result.noLastResults': 'You do not have any previous completed tests yet to compare with your current result.', 
'bonusTest.result.notCompleted.description1': 'If you don\'t complete the test, you will miss your chance to earn points and analyze your progress.', 
'bonusTest.result.notCompleted.description2': 'You will get another chance on your next course.', 
'bonusTest.result.notCompleted.title': 'Hey! Your test has not been completed.', 
'bonusTest.result.title': 'Your result', 
'bonusTest.start': 'Start Bonus Test', 
'bugReporter.button.cancel': 'Cancel', 
'bugReporter.button.send': 'Send', 
'bugReporter.inputPlaceholder': 'Describe the problem for us', 
'bugReporter.successMessage': 'Your report was sent. Thank you for helping us be a better platform.', 
'bugReporter.title': 'Report Error', 
'cancelMasteryTest': 'Finishing the Mastery Test', 
'certificationTest.awaitingRevision': 'Soon, your certification test will be reviewed. In a few days, you will receive the result.', 
'certificationTest.button.access': 'Access', 
'certificationTest.button.accessNewCourse': 'Access the new course', 
'certificationTest.button.back': 'Back to home', 
'certificationTest.button.continue': 'CONTINUE CERTIFICATION TEST', 
'certificationTest.button.reviewed': 'Click here to check your result', 
'certificationTest.button.start': 'START CERTIFICATION TEST', 
'certificationTest.continue': 'Continue your Certification Test. Use a computer to continue.', 
'certificationTest.created': 'You can take the certification test. Talk to your teacher to schedule it, in the meantime keep studying to be prepared for the exam.', 
'certificationTest.enabled': 'Your Certification Test is available. Use a computer to take it.', 
'certificationTest.execution.ability.listening': 'Listening test', 
'certificationTest.execution.ability.reading': 'Reading test', 
'certificationTest.execution.ability.speaking': 'Speaking test', 
'certificationTest.execution.ability.writing': 'Writing test', 
'certificationTest.execution.completedDescription': 'Once the evaluator reviews your test, you\'ll receive a notification with your grade! Good luck!', 
'certificationTest.execution.completedTitle': 'Certification test completed and submitted for review', 
'certificationTest.execution.status.available': 'Start', 
'certificationTest.execution.status.blocked': 'Locked', 
'certificationTest.execution.status.finished': 'Done', 
'certificationTest.execution.title': 'Obtain a minimum score of 70 to enable the next step that is locked.', 
'certificationTest.failed': 'You didn\'t achieve the minimum score in your last Certification Test. Study {units} different units to enable it again.', 
'certificationTest.help.free_speak': 'In this exercise, unlike units, you should not repeat the question on the screen, but rather answer it. You will have up to 1 minute to think about your answer before the recording starts automatically. If you wish, you can also start earlier.', 
'certificationTest.help.free_speak_image': 'In this exercise, you should describe the image on the screen. You will have up to 1 minute to think about your answer before the recording starts automatically. If you wish, you can also start earlier.', 
'certificationTest.help.gotIt': 'Okay, got it', 
'certificationTest.loading': 'Calculating your score', 
'certificationTest.result.approvedDescription': 'You have achieved the necessary score to pass the Certification Test.', 
'certificationTest.result.approvedDescriptionTwo': 'You have shown us that you have reached a new level of English on the scale of the Common European Framework of Reference for Languages.', 
'certificationTest.result.approvedTitle': 'Great!', 
'certificationTest.result.failedDescription': 'You did not achieve the required score to pass the Certification Test.', 
'certificationTest.result.failedDescriptionTwo': 'Study {units} more units to retake the Certification Test.', 
'certificationTest.result.failedTitle': 'Oh no...', 
'certificationTest.result.finalScore': 'Final score', 
'certificationTest.result.scoreBySkill': 'Score by skill', 
'certificationTest.result.unlockedCourse': 'Unlocked course', 
'certificationTest.reviewed': 'Your Certification Test has been reviewed.', 
'certificationTest.scheduled': 'Your Certification Test is scheduled for <b>{scheduledTo}</b>', 
'certificationTest.title': 'Certification Test', 
'checkText': 'Check the text again', 
'checkTextAgain': 'Click here to check the text again!', 
'close': 'Close', 
'closeExecution.defaultMessage': 'If you leave now, your progress will be lost after {timeToAllowContinueUnit}. Are you sure want to leave?', 
'closeExecution.masteryMessage': 'If you exit now, your Mastery Test will be failed and you will have to pass new units to enable a new attempt. Are you sure you want to exit?', 
'closeExecution.no': 'No', 
'closeExecution.noProgressLossMessage': 'Are you sure want to leave?', 
'closeExecution.title': 'Attention', 
'closeExecution.yes': 'Yes', 
'closedRanking.champions': '<b style="color: #fffc00">{month}</b> Ranking', 
'closedRanking.congratulations': 'Congratulations!', 
'closedRanking.congratulationsMessage.national': 'Your national position was <b style="color: #fffc00">{position}</b>.', 
'closedRanking.congratulationsMessage.regional': 'Your regional position was <b style="color: #fffc00">{position}</b>.', 
'closedRanking.congratulationsMessage.school': 'Your institutional position was <b style="color: #fffc00">{position}</b>.', 
'closedRanking.monthly': 'Monthly', 
'closedRanking.national': 'National', 
'closedRanking.points': '{points} Points', 
'closedRanking.regional': 'Regional', 
'closedRanking.school': 'School', 
'closedRanking.semiannual': 'Biannual', 
'closedRanking.yearly': 'Annual', 
'congratulations': 'Congratulations', 
'contentVideo.execution.attachments': 'Attached documents', 
'contentVideo.execution.incompleteLesson': 'Incomplete Lesson', 
'contentVideo.execution.lesson': 'Lesson {value}', 
'contentVideo.execution.lessonCompleted': 'Lesson completed', 
'contentVideo.execution.localesSelect': 'Select a subtitle language', 
'contentVideo.execution.noAttachments': 'Não há documentos anexos nesta atividade', 
'contentVideo.execution.noRecommendedUnits': 'No recommended exercises', 
'contentVideo.execution.openAttachmentError': 'It was not possible to open the file', 
'contentVideo.execution.openRecommendedUnitModal.confirm': 'Yes', 
'contentVideo.execution.openRecommendedUnitModal.title': 'Você tem certeza que deseja iniciar este exercício?', 
'contentVideo.execution.recommendedUnits': 'Recommended exercises', 
'contentVideo.list.emptyState.description': 'Seu (sua) Teacher não cadastrou vídeos.', 
'contentVideo.list.emptyState.title': 'Oops... No videos here yet', 
'contentVideo.list.module': 'Module {value}', 
'contentVideo.list.title': 'Recorded Classes', 
'continue': 'Continue', 
'courseSummary.button.access': 'Access the new course', 
'courseSummary.button.back': 'Back to home', 
'courseSummary.certificationTestPart1': 'The next step is the <b>Certification Test</b>, ask your teacher to schedule it.', 
'courseSummary.certificationTestPart2': 'Use the recaps to review the content before your certification test. Good luck!', 
'courseSummary.description': 'You have completed the course', 
'courseSummary.hoursInCourse': 'Total Hours in course', 
'courseSummary.pointsInCourse': 'Total Points in course', 
'courseSummary.title': 'Congratulations!', 
'courseSummary.unlockedCourse': 'Unlocked course', 
'courseSummary.yourProgress': 'Your Progress', 
'createAccount.button.saveAndContinue': 'Save and continue', 
'createAccount.button.validateCpf': 'Next', 
'createAccount.button.validateRa': 'Next', 
'createAccount.completeMessage': 'Complete your register', 
'createAccount.error.activeAccount': 'It looks like you have already registered. Go to the login page and use your email and password to access the platform.', 
'createAccount.error.email': 'The provided email has already been used. Please, use another email.', 
'createAccount.error.general': 'We were unable to update your data. Please check the fields and try again.', 
'createAccount.error.invalidCpf': 'We could not find a user with your CPF. Please check your CPF or contact your institution.', 
'createAccount.error.invalidRa': 'We could not find a user with your RA. Please check your RA or contact your institution.', 
'createAccount.error.limit_reached': 'All accounts have been taken.', 
'createAccount.field.confirmPassword': 'Confirm your password', 
'createAccount.field.cpf': 'Inform your CPF', 
'createAccount.field.email': 'Your email', 
'createAccount.field.name': 'Your name', 
'createAccount.field.password': 'Create a password', 
'createAccount.field.ra': 'Inform your AR', 
'createAccount.infoMessage': 'Enter the data below', 
'createAccount.infoStep.cancel': 'Cancel', 
'createAccount.infoStep.confirmPassword': 'Confirm Password', 
'createAccount.infoStep.description': 'Fill in your details to create your account', 
'createAccount.infoStep.email': 'Email', 
'createAccount.infoStep.name': 'Name', 
'createAccount.infoStep.password': 'Password', 
'createAccount.infoStep.saveAndContinue': 'Save and Continue', 
'createAccount.infoStep.title': 'Welcome to {name}', 
'createAccount.planStep.confirmAndPay': 'Confirm and Pay', 
'createAccount.planStep.description': 'Select the plan and confirm', 
'createAccount.planStep.monthly.byPeriod': '/ month', 
'createAccount.planStep.privacyPolicy': 'Privacy Policy', 
'createAccount.planStep.quarterly.byPeriod': '/ quarter', 
'createAccount.planStep.quarterly.monthCompare': '3 months at $ {price}/month.', 
'createAccount.planStep.quarterly.saveDescription': 'Save {discount}%', 
'createAccount.planStep.semiannually.byPeriod': '/ semester', 
'createAccount.planStep.semiannually.monthCompare': '6 months at $ {price}/month.', 
'createAccount.planStep.semiannually.saveDescription': 'Save {discount}%', 
'createAccount.planStep.title': 'What plan do you want?', 
'createAccount.planStep.useTerms': 'Terms of use', 
'createAccount.success.general': 'Account successfully created. Now you will be redirected to your course.', 
'createAccount.success.payment': 'Account successfully created. Now you will be redirected to your payment.', 
'createAccount.successStep.accessPlacement': 'Continue', 
'createAccount.successStep.placementDescription': 'The next step is to find out your English level. Use the button below to access our Placement Test', 
'createAccount.successStep.title': 'You are ready to start your course', 
'createAccount.welcomeMessage': 'Welcome to your English course.', 
'day': 'Day', 
'days': 'Days', 
'dismiss': 'Dismiss', 
'error.account_duplicated_email': 'The email has already been taken. Choose another email.', 
'error.account_general': 'We were unable to update your data. Please check the fields and try again.', 
'error.account_invalid_password': 'Check your password. To update your password you must type you current password and the new password.', 
'error.account_picture_general': 'We were unable to update your photo. Please try again.', 
'error.androidVersion': 'This device has an old android version. The minimum required version: 5.0 Lollipop', 
'error.audioDownload': 'There was a problem to play the audio. Try again using the Repeat button.', 
'error.checkAudioPermission.chrome.button': 'Allow and continue', 
'error.checkAudioPermission.chrome.description': 'Press the button below and continue.', 
'error.checkAudioPermission.description': 'Sorry, we detected you are using the <b style="textTransform: capitalize">{browserName}</b> web browser and our site does not have the permissions to auto play the audios.', 
'error.checkAudioPermission.safari.description': 'You can switch between different Autoplay configurations  from <b>Safari settings</b> (Preferences > Websites > Auto-Play) or follow the <b>steps below</b> to enable the auto play for audios:', 
'error.checkAudioPermission.safari.step1': 'Right click on address bar and select the option: <b>Settings for this Website</b>', 
'error.checkAudioPermission.safari.step2': 'On Auto-Play select the option: <b>Allow All Auto-Play</b>', 
'error.checkAudioPermission.safari.step3': 'Refresh your page and you will be ready to start', 
'error.checkAudioPermission.title': 'Almost there', 
'error.checkBrowserType.description1': 'You can have a better experience on our platform by using the latest version of Google Chrome web browser.', 
'error.checkBrowserType.description2': 'You can also use Mozila Firefox or Safari.', 
'error.checkBrowserType.title': 'Sorry, your current web browser is not supported by us.', 
'error.checkBrowserVersion.description': 'You can have a better experience by using the latest version of your web browser. You can download it at this {link}.', 
'error.checkBrowserVersion.title': 'Your web browser is out of date.', 
'error.close': 'Close', 
'error.create_account_duplicated_email': 'The reported email is already in use. Please contact our support for more information.', 
'error.create_account_general': 'We were unable to create your account. Check the information and try again', 
'error.differentPasswords': 'The passwords do not match', 
'error.duplicatedEmail': 'The provided email already exists in our database.', 
'error.emailNotFound': 'We could not find a user with that email.', 
'error.emailPasswordInvalid': 'Check your email and/or password.', 
'error.error_browser_block_autoplay': 'Click the button below to continue the exercise.', 
'error.error_browser_block_intro_autoplay': 'Let\'s start! Click the button below and follow the instructions.', 
'error.error_find_speech_recognition_text': 'There was a problem to find your answer. Try again using the Record button', 
'error.error_get_speech_recognition_score': 'There was a problem calculating your speech score. Try again using the Record button', 
'error.error_inspect_html': 'It is not allowed to inspect the site. The progress will be lost.', 
'error.error_no_speech': 'We could not detect your speech. Try again using the Record button', 
'error.error_play_audio': 'There was a problem playing the audio. Try using the Repeat button again.', 
'error.error_play_record': 'There was a problem playing your recording. Try again using the Listen button', 
'error.error_record_missing_mic': 'It was not possible to access your microphone. Check your browser settings.', 
'error.error_record_not_allowed': 'The platform was not allowed to access your microphone. Check your browser\'s permissions.', 
'error.error_reply_ct_speak': 'It looks like you repeated the questions instead of answering them. Record your answer again.', 
'error.error_save_answer': 'There was a problem saving your answer. Try to confirm your answer again', 
'error.error_start_record': 'There was a problem accessing your microphone. Try again using the record button', 
'error.error_stop_record': 'There was a problem stopping your recording. Try using the record button again', 
'error.error_word_alignment': 'Your sentence is incomplete. Try record again.', 
'error.generalConnectionError': 'A connection instability has been detected. Please do this exercise again.', 
'error.generateUnitScore': 'There was a problem generating your score.', 
'error.google_access_denied': 'You must enable the oAuth permissions to authenticate with Google.', 
'error.google_email_not_found': 'Email not found.', 
'error.google_invalid_user_role': 'Your account not is a Student\'s account.', 
'error.invalidEmail': 'Invalid email', 
'error.kidsUser': 'This section is not compatible with your course. Access our web platform to continue.', 
'error.noSpeechDetected': 'It was not possible to detect your speech. Check your microphone, it may be muted or set to a very low sensitivity. Try listening to your recording to verify the audio.', 
'error.payment_canceled': 'Canceled purchase', 
'error.payment_deferred': 'Purchase blocked because missing permissions', 
'error.payment_error': 'Purchase not completed. Check your info and try again', 
'error.placement_end': 'We were unable to generate you score. Try again.', 
'error.placement_save_answer': 'We were unable to save you answer. Try again.', 
'error.placement_start': 'We were unable to start your placement test. Try again.', 
'error.practice_save_answer': 'We were unable to save you answer. Try again.', 
'error.practice_start': 'We were unable to start the practice test.', 
'error.refresh': 'Refresh', 
'error.required': 'Required', 
'error.three_minute_block': 'You just finished the exercise. Now you need to wait 3 minutes to do it again, but you can do any other exercise in the meantime.', 
'error.tryAgain': 'Try Again', 
'error.updateAvatarError': 'There was a problem updating your avatar.', 
'error.updateProfileError': 'There was a problem updating your profile.', 
'grades.averageSq': 'Average SQ: <b>{grade}!</b>', 
'grades.awaitingSq': 'Awaiting SQ Score', 
'grades.classRoom': 'Classroom', 
'grades.closed': 'Closed', 
'grades.excellent': 'Excellent', 
'grades.finalGrade': 'Final Grade', 
'grades.good': 'Good', 
'grades.maximumGrade': 'Maximum grade:', 
'grades.maximumSchoolGrade': 'Maximum school grade: {grade}', 
'grades.moderate': 'Moderate', 
'grades.noData': 'Your grades are not available yet.', 
'grades.preview': 'Preview', 
'grades.requiredStudyTime': 'Required study time: <b>{time}</b>', 
'grades.school': 'School:', 
'grades.student': 'Student:', 
'grades.teacher': 'Teacher:', 
'grades.teachers': 'Teachers:', 
'grades.time': 'Time  ({percentageRelevance}%)', 
'grades.title': 'Grades - {year}', 
'grades.titleHeader': 'Your Grades', 
'grades.totalStudyTime': 'Total study time: <b>{time}</b>', 
'grades.veryGood': 'Very Good', 
'grades.veryWeak': 'Very Weak', 
'grades.weak': 'Weak', 
'grades.weekToSqScore': '{count} weeks to SQ Score', 
'grades.yourSchoolGrade': 'Your school grade: {grade}', 
'greeting.f': 'Hi {name}, Welcome back! :)', 
'greeting.m': 'Hi {name}, Welcome back! :)', 
'help.AUDIO_LONG': 'Listen carefully to the audio to answer the following exercises.', 
'help.DIALOGUE': 'Read the messages and select the best option to continue the dialogue.', 
'help.DIALOGUE.typing': 'Typing...', 
'help.DIALOGUE_OPTION': 'Read the messages and select the best option to continue the dialogue.', 
'help.DIALOGUE_OPTION.question': 'Which option is the correct one to continue the dialogue? Record your answer.', 
'help.DIALOGUE_OPTION_SPEECHLESS.question': 'Which option is the correct one to continue the dialogue?', 
'help.DICTATION': 'Listen carefully to the audio and write the sentence in the field below.', 
'help.GAP_FILL': 'Record the <b>entire</b> phrase with the correct option.', 
'help.GAP_FILL_MULTIPLE': 'Complete the phrase <b>by clicking</b> the correct words.', 
'help.GAP_FILL_MULTIPLE_DRAG_AND_DROP': 'Complete the phrase <b>by dragging</b> the correct words into the available spaces.', 
'help.GAP_FILL_SELECT': 'Complete the phrase <b>by selecting</b> one of the available options.', 
'help.GAP_FILL_SPEECHLESS': 'Complete the phrase <b>by selecting</b> one of the available options.', 
'help.GRAMMAR_CHECK': 'Read the following sentence carefully to identify the error.', 
'help.GRAMMAR_CHECK.correction_phrase': 'Correct the sentence by changing the incorrect words or expressions.', 
'help.IMAGE': 'Analyze the image carefully in order to answer the following questions.', 
'help.MEANINGS_ASSOCIATING': 'Match each term by clicking on the word and the corresponding field of its definition.', 
'help.MULTIPLE_CHOICE_TEXT': 'Listen to the question and check the correct options.', 
'help.PRESENTATION': 'Use the Repeat, Record, and Listen buttons to record your voice repeating the audio you heard.', 
'help.PRESENTATION_SPEECHLESS': 'Unscramble the phrase <b>by dragging</b> the correct words into the available spaces.', 
'help.PRONUNCIATION': 'Use the Record button to <b>say the text</b> below.', 
'help.PRONUNCIATION_SPEECHLESS': 'Complete the phrase <b>by selecting</b> one of the available options.', 
'help.SINGLE_CHOICE_AUDIO': 'Listen to the question and <b>click</b> on the correct option.', 
'help.SINGLE_CHOICE_IMAGE': 'Listen to the question and <b>click</b> on the correct option.', 
'help.SINGLE_CHOICE_TEXT': 'Listen to the question and <b>click</b> on the correct option.', 
'help.SPEECH_PRACTICE': '<b>Listen</b> carefully and use the Record button to <b>repeat</b> the audio.', 
'help.SPEECH_PRACTICE_SPEECHLESS': 'Listen carefully to the audio and write the sentence in the field below.', 
'help.TEXT': '<b>Read the text carefully </b> and then click Next to continue.', 
'help.TRUE_FALSE': 'Listen to the statement and <b>click </b> on the true or false button.', 
'help.UNSCRAMBLE_DRAG_AND_DROP': 'Unscramble the phrase <b>by dragging</b> the correct words into the available spaces.', 
'help.UNSCRAMBLE_DRAG_AND_DROP_CLICK': 'Unscramble the phrase <b>by clicking</b> in the correct words to fill the gaps.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION': 'Unscramble the phrase using the Record button to <b>record the phrase</b> in the correct sequence.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS': 'Unscramble the phrase <b>by dragging</b> the correct words into the available spaces.', 
'help.UNSCRAMBLE_TEXT': 'Order the paragraphs to organize the text in the correct sequence.', 
'help.VIDEO_LONG': 'Watch the video to answer the following exercises. If necessary, you can repeat the video once.', 
'help.VOCABULARY_ACADEMIC': '<b>Click</b> on the image and then record the phrase you hear.', 
'help.VOCABULARY_ACADEMIC_SPEECHLESS': 'Unscramble the phrase <b>by dragging</b> the correct words into the available spaces.', 
'history.averageSR': 'Average SR', 
'history.byContent': 'Your history by content', 
'history.byDate': 'Your history by date', 
'history.corrects': 'Correct Answers', 
'history.course': 'Course', 
'history.date': 'Date', 
'history.module': 'Module', 
'history.noContentData': 'You have no history for content.', 
'history.noData': 'You don\'t have a history yet.', 
'history.noDateData': 'You have no history by date.', 
'history.notFinished': 'Not finished', 
'history.points': 'Points', 
'history.rankingPosition': 'Ranking Positions', 
'history.score': 'Score', 
'history.studiedTime': 'Studied Time', 
'history.title': 'History', 
'history.type': 'Type', 
'history.unit': 'Unit', 
'home.accessCourse': 'Access your course', 
'home.currentRulerLevel': 'You are here, great!', 
'home.firstCourse': 'You just started, keep studying to unlock the next course.', 
'home.initialRulerLevel': 'You started here!', 
'home.reviewCourses': 'Check your previous courses', 
'home.welcome.F': 'Welcome back, {name} :)', 
'home.welcome.M': 'Welcome back, {name} :)', 
'hours': 'Hours', 
'imageCrop.cancel': 'Cancel', 
'imageCrop.changeLabel': 'Change avatar', 
'imageCrop.confirm': 'Confirm', 
'instructions': 'Instructions', 
'intro.done': 'End', 
'intro.gotIt': 'Got it', 
'intro.next': 'Next', 
'intro.previous': 'Previous', 
'intro.skip': 'Skip', 
'intro.steps.controlBar1': 'Button to repeat the audio. Remember, you can only use it once!', 
'intro.steps.controlBar2': 'Maximum time for each question. Remember that the time starts counting a few seconds after loading the exercise.', 
'intro.steps.controlBar3': 'When completing the sentence, use the Next button to proceed to the next exercise. Don\'t forget that the exercise time will continue to be counted as long as you don\'t click the Next button.', 
'intro.steps.controlBarRepeat': 'Button to repeat the audio. Remember, you can only use it once!', 
'intro.steps.controlBarTimer': 'Maximum time for each question. Counting starts after loading the exercise.', 
'intro.steps.gapFill1': 'The text is incomplete. You need to complete it by selecting one of the options.', 
'intro.steps.gapFill2': 'Click to select one of the options.', 
'intro.steps.gapFillMultiple1': 'The text is incomplete. You need to complete it with the correct options.', 
'intro.steps.gapFillMultiple2': 'These are the options. Click on them to create the correct phrase!', 
'intro.steps.gapFillMultiple2_drag': 'These are the options. Drag them to create the correct phrase!', 
'intro.steps.gapFillSelect': 'Click to check the available options and then select one to complete the phrase.', 
'intro.steps.singleChoiceAudio': 'Listen to the audio and select the correct option.', 
'intro.steps.singleChoiceImage': 'Listen to the audio and select the correct image.', 
'intro.steps.singleChoiceText': 'Listen to the audio and select the correct option.', 
'intro.steps.unscrambleDragDrop1': 'Here you need to unscramble the phrase using the available options.', 
'intro.steps.unscrambleDragDrop2': 'These are the available options. Click on them to complete the phrase!', 
'intro.steps.unscrambleDragDrop2_drag': 'These are the options. Drag them to create the correct phrase!', 
'introductionButton': 'Check instructions', 
'items.correctAnswer': 'Correct answer:', 
'items.dictation.errorTitle': 'Check the list below to understand your errors', 
'items.dictation.missingWordsError': 'Your phrase has {count} missing words', 
'items.dictation.moreWordsError': 'Your phrase has {count} more words than needed', 
'items.dictation.wordsWithTyposError': 'Words with typos: {words}.', 
'items.dictation.wrongWordsError': 'Wrong Word(s): {words}', 
'items.placeholder.dictation': 'Type here...', 
'items.placeholder.freeSpeak': 'In {time} seconds the recording will start automatically. If you want, you can start earlier.', 
'items.placeholder.gapFillSelect': 'Select an option', 
'items.speechRecognition.errorLowScore': 'We could not generate a score for your speech, please try again.', 
'items.speechRecognition.errorNoSpeech': 'We could not detect any speech, please try again.', 
'items.speechRecognition.historyTitle': 'Your score history', 
'items.speechRecognition.tryAgain': 'Try Again!', 
'listen': 'Listen', 
'listening': 'Listening', 
'loadingExercises': 'Loading exercises', 
'loadingMasteryTest': 'Loading the Mastery Test', 
'locked': 'Locked', 
'login.backToLogin': 'Back to login', 
'login.classroom_not_found': 'Your account does not have a classroom.', 
'login.createAccount': 'Create account', 
'login.email': 'Email', 
'login.email_not_found': 'Email not found.', 
'login.enter': 'Enter', 
'login.forgotPassword': 'Forgot my password', 
'login.forgotPasswordDescription': 'Enter your email address in the field below to receive a new password by email', 
'login.forgotPasswordPostDescription': 'You can change the new password on your edit profile page.', 
'login.goToLogin': 'Access login page', 
'login.googleSignIn': 'Sign in with Google', 
'login.inactive_classroom': 'Inactive classroom.', 
'login.invalid_password': 'Invalid password.', 
'login.limit_reached': 'All available accounts have already been used.', 
'login.out_of_access_period': 'Invalid access period', 
'login.out_of_demo_period': 'The trial period is over, please contact your system administrator.', 
'login.password': 'Password', 
'login.payment_required': 'You have a pending payment. Check your email or contact our support.', 
'login.resetPassword': 'Reset Password', 
'login.resetPasswordSuccess': 'Your new password was sent by email.', 
'login.student_contract_blocked': 'Oops, it appears that your access has been blocked. Contact your teacher for more information.', 
'login.unexpected_error': 'It was not possible to login. Please try again later.', 
'login.user_disabled': 'Account disabled. Check with your institution.', 
'login.user_not_found_in_company': 'Email not found.', 
'login.user_not_found_in_distributor': 'Email not found.', 
'login.user_with_placement_in_classroom': 'You already finished your Placement Test.', 
'masteryTest.approved': 'Approved', 
'masteryTest.approvedMessageDescription': 'You\'ve passed your Mastery Test', 
'masteryTest.approvedMessageTitle': 'Congratulations!', 
'masteryTest.button.back': 'Back to module', 
'masteryTest.button.courseSummary': 'Check course summary', 
'masteryTest.description': 'The mastery test is designed to assess the knowledge acquired during your studies and has activities similar to the exercises you have already completed. In case you fail, you only have to complete 5 units and you will then be able to take the test again.', 
'masteryTest.exercisesToUnlock.moreThenOneExercise': '{total} exercises left to unlock the test', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndOneRevision': '{total} exercises and 1 review required left to unlock the test', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndRevision': '{totalExercises} exercises and {totalRevisions} reviews required left to unlock the test', 
'masteryTest.exercisesToUnlock.moreThenOneRevision': '{total} revisions required left to unlock the test', 
'masteryTest.exercisesToUnlock.moreThenOneRevisionAndOneExercise': '1 exercise and {total} reviews required left to unlock the test', 
'masteryTest.exercisesToUnlock.oneExercise': '1 exercise left to unlock the test', 
'masteryTest.exercisesToUnlock.oneExerciseAndMoreThenOneRevision': '1 exercise and {total} revisions required left to unlock the test', 
'masteryTest.exercisesToUnlock.oneExerciseAndRevision': '1 exercise and 1 review required left to unlock the test', 
'masteryTest.exercisesToUnlock.oneRevision': '1 review left to unlock the test', 
'masteryTest.failed': 'Failed', 
'masteryTest.failedMessageDescription': 'It was not this time. <b>Try again!</b>', 
'masteryTest.failedMessageTitle': 'Well...', 
'masteryTest.loading': 'Calculating your score', 
'masteryTest.locked': 'Locked', 
'masteryTest.name': 'Mastery Test', 
'masteryTest.recommendedUnits': 'Do at least 5 exercises again. Here are some suggestions for what we need to improve:', 
'masteryTest.result.aboutYourPerformance': 'About your performance', 
'masteryTest.result.congratulationsOnYourEfforts': 'Congratulations for your hard work.', 
'masteryTest.result.dontBeDiscouraged': 'But don\'t be discouraged.', 
'masteryTest.result.niceJob': 'Nice job', 
'masteryTest.result.whatToImprove': 'What to improve', 
'masteryTest.result.yorResultOnMasteryTest': 'Your Mastery Test Result', 
'masteryTest.result.youDidntPass': 'You didn\'t pass!', 
'masteryTest.result.youPass': 'You passed!', 
'masteryTest.resultTitle': 'Your result', 
'masteryTest.score': 'Score: {score}', 
'masteryTest.scoreToPass': 'Score to pass: {score}', 
'masteryTest.start': 'Start', 
'masteryTest.timer.runningOut': 'Time is running out...', 
'masteryTest.timer.title': 'Time to answer', 
'masteryTest.tooltip.approved': 'You have completed the test.', 
'masteryTest.tooltip.failed': 'You need to finish <b>{unitsNeeded} different exercises</b> to be able to do it again.', 
'masteryTest.tooltip.locked': 'It will be unlocked when you complete {modulePercentageToActive}% of this module. You need more <b>{requiredPoints} points</b>.', 
'masteryTest.tooltip.unlocked': 'Mastery Test available. Click here to start', 
'masteryTest.unlocked': 'Unlocked', 
'masteryTest.whatToImprove': 'What to improve?', 
'masteryTestStartExecutionModal.exit': 'Exit', 
'masteryTestStartExecutionModal.start': 'Start', 
'masteryTestStartExecutionModal.title': 'The test consists of about 20 questions, and after starting the test, it will not be possible to pause. If you give up during the execution, the test will be considered unsuccessful, and you will need to study 5 units to unlock a new attempt. Are you sure you want to start the Mastery Test?', 
'meeting.button.join': 'Join the meeting', 
'meeting.title': 'Your next class', 
'menu.contentVideo': 'Lessons', 
'menu.course': 'Course', 
'menu.errorReport': 'Report Error', 
'menu.exercises': 'Exercises', 
'menu.exercisesHelp': 'Understanding the exercises', 
'menu.grades': 'Grades', 
'menu.history': 'History', 
'menu.logout': 'Logout', 
'menu.messages': 'Messages', 
'menu.microphoneTest': 'Microphone Test', 
'menu.modules': 'Modules', 
'menu.modulesHelp': 'Understanding the modules', 
'menu.notifications': 'Notifications', 
'menu.privacyPolicy': 'Privacy Policy', 
'menu.profile': 'Profile', 
'menu.ranking': 'Ranking', 
'menu.units': 'Exercises', 
'messages.messageToSupport': 'To support', 
'messages.messageToTeacher': 'To my teacher', 
'messages.messageTypeSelection': 'Who do you want to send the message to?', 
'messages.newMessage': 'New message', 
'messages.newMessageTo': 'New message to', 
'messages.noData': 'You don\'t have messages', 
'messages.notRead': 'Not read', 
'messages.placeholder': 'Message', 
'messages.readAt': 'Read at {readAt}', 
'messages.selectTeacher': 'Select a teacher to receive the message', 
'messages.send': 'Send', 
'messages.subjectPlaceholder': 'Subject', 
'messages.successSendNewMessage': 'Message successfully sent!', 
'messages.successSent': 'Message successfully sent!', 
'messages.supportSubjectLabelOptions.answerExercise': 'How to answer the exercise', 
'messages.supportSubjectLabelOptions.certificationTest': 'Doubts about the Certification Test', 
'messages.supportSubjectLabelOptions.exercise': 'Doubts about the Exercise', 
'messages.supportSubjectLabelOptions.exerciseAudio': 'Exercise audio', 
'messages.supportSubjectLabelOptions.exerciseBlockedFunctionality': 'Functionality blocked on exercise', 
'messages.supportSubjectLabelOptions.exerciseImage': 'Exercise image', 
'messages.supportSubjectLabelOptions.exerciseTranslation': 'Exercise translation', 
'messages.supportSubjectLabelOptions.learningTest': 'Doubts about the Learning Test', 
'messages.supportSubjectLabelOptions.ranking': 'Doubts about the ranking', 
'messages.supportSubjectLabelOptions.speechRecognition': 'Speech recognition', 
'messages.supportSubjectLabelOptions.studyQuality': 'Doubts about Study Quality', 
'messages.title': 'Messages', 
'messages.titleNewMessage': 'Send a new message', 
'microphoneTest.badScoreDescription': 'Check the tutorial in the next screen and <b>config your microphone</b>.', 
'microphoneTest.badScoreTitle': 'Oh no! Your scores are not good.', 
'microphoneTest.button.checkTutorial': 'Check tutorial', 
'microphoneTest.button.close': 'Close', 
'microphoneTest.button.skipTest': 'Configure later', 
'microphoneTest.button.start': 'Start test', 
'microphoneTest.button.tryAgain': 'Try Again', 
'microphoneTest.description': 'On the next screen, try to say each phrase and set up your microphone to get the minimum score of 80.', 
'microphoneTest.goodScoreDescription': 'You can come back here anytime you want using the help menu.', 
'microphoneTest.goodScoreTitle': 'Very good! Your scores are great.', 
'microphoneTest.phrasesTitle': 'Record the following sentences:', 
'microphoneTest.title': 'Let\'s set up your microphone!', 
'microphoneTest.tutorialDescriptionOne': 'The sensitivity of your microphone may be too high or too low, in either case it can affect your score', 
'microphoneTest.tutorialDescriptionTwo.part1': 'How to fix it?', 
'microphoneTest.tutorialDescriptionTwo.part2': 'You can check the', 
'microphoneTest.tutorialDescriptionTwo.part3': 'step by step in this', 
'microphoneTest.tutorialDescriptionTwo.part4': ', or <b>watch the above video</b> to learn how to improve your microphone sensitivity.', 
'microphoneTest.tutorialTitle': 'Configuring microphone sensitivity', 
'missingPermission.button.openSettings': 'Check settings', 
'missingPermission.camera.description': 'Check the settings and allow our access to the camera.', 
'missingPermission.media_library.description': 'Check the settings and allow our access to the photo library.', 
'missingPermission.microphone.description': 'Check the settings and allow us to access the microphone.', 
'module.access': 'Access', 
'module.availablePoints': 'of {points}', 
'module.button.enter': 'Enter', 
'module.button.locked': 'Locked', 
'module.button.review': 'Review', 
'module.conqueredPercentage': '{percentage}%', 
'module.conqueredPoints': '{points} Points', 
'module.firstAccessTooltip': 'Click here', 
'module.lastStudied': 'Last Studied', 
'module.loading': 'Loading modules', 
'module.moduleDetailDescription': 'Your Module Score', 
'module.nationalRanking': 'National Ranking', 
'module.noStudy': 'Not yet recorded.', 
'module.percentageComplete': '({percentage}% completed)', 
'module.score': 'Score', 
'module.tabTitle': 'Exercises', 
'module.title': 'Modules', 
'module.totalPoints': 'of {points}', 
'module.weekTime': 'Week Time', 
'month': 'Month', 
'national': 'National', 
'native': 'Native', 
'needHelp': 'Do you need help?', 
'next': 'Next', 
'noInternet.awaitingMessage': 'Waiting for connection...', 
'noInternet.message': 'You are offline. Check your connection.', 
'noInternet.title': 'Connect to the Internet', 
'notifications.intent.congratulations': 'Congratulations!', 
'notifications.intent.informative': 'Info!', 
'notifications.intent.motivational': 'Keep going!', 
'notifications.noData': 'You don\'t have notifications.', 
'notifications.title': 'Notifications', 
'notifications.type.1HourToCompleteEvaluationPeriodTime': 'You need 1 hour to completed the required time of your evaluation period.', 
'notifications.type.25PercentOfCourseCompleted': '25% of the {courseName} course was completed.', 
'notifications.type.2HourToCompleteEvaluationPeriodTime': 'You need 2 hours to complete the required time of your evaluation period.', 
'notifications.type.30MinutesInTheDay': 'You have completed 30 minutes of study. Keep going!', 
'notifications.type.50PercentOfCourseCompleted': '50% of the {courseName} course was completed.', 
'notifications.type.50PercentOfModuleCompleted': '50% of the module  "{moduleName}" ({courseName}) has been completed', 
'notifications.type.80PercentOfCourseCompleted': '80% of the {courseName} course was completed', 
'notifications.type.80PercentOfModuleCompleted': '80% of the module {moduleName} has been completed.', 
'notifications.type.90PercentOfCourseCompleted': '90% of the {courseName} course was completed.', 
'notifications.type.90PercentOfModuleCompleted': '90% of the module {moduleName} has been completed.', 
'notifications.type.achievement': 'Congratulations! you were the  {position} of {month} in your {location}', 
'notifications.type.certificationTestCreated': 'Certification Test created.', 
'notifications.type.certificationTestEnabled': 'Your Certification Test is available.', 
'notifications.type.certificationTestReviewed': 'Certification Test reviewed.', 
'notifications.type.certificationTestScheduled': 'Certification Test scheduled for {scheduledTo}.', 
'notifications.type.closedRanking': 'The ranking of {month} ended.', 
'notifications.type.firstReviewEnabled': 'Review available for the unit {unitName} of the module {moduleName}.', 
'notifications.type.masteryTestEnabled': 'You have unlocked a Mastery Test in the module {moduleName}.', 
'notifications.type.masteryTestFailed': 'Study {units} more units in the module  {moduleName} to unlock the Mastery Test again.', 
'notifications.type.masteryTestRetry': 'You have reached the needed points to retake the mastery test of the module {moduleName}.', 
'notifications.type.moduleGroupEnabled': 'You have unlocked a new set of modules for the course {courseName}.', 
'notifications.type.moduleListeningCompleted': 'You completed the Listening points of the {moduleName} module.', 
'notifications.type.moduleReadingCompleted': 'You completed the Reading points of the {moduleName} module.', 
'notifications.type.moduleSpeakingCompleted': 'You completed the Speaking points of the {moduleName} module.', 
'notifications.type.moduleWritingCompleted': 'You completed the Writing points of the {moduleName} module.', 
'notifications.type.more50PointsToUnlockUnitGroup': 'You need only more 50 points to unlock new units in the {moduleName} module.', 
'notifications.type.secondReviewEnabled': 'Second review available for unit {unitName} of {moduleName}.', 
'notifications.type.studyQualityScore10': 'Congratulations! You have a great Study Quality score.', 
'notifications.type.studyQualityScore15': 'Congratulations! You have reached the maximum Study Quality score.', 
'notifications.type.top10National': 'Congratulations, you are in the Top 10 of your country.', 
'notifications.type.top10Regional': 'Congratulations, you are in the Top 10 of your region.', 
'notifications.type.top10School': 'Congratulations, you are in the Top 10 of your school.', 
'notifications.type.top30School': 'Congratulations, you are in the Top 30 of your school.', 
'notifications.type.top50National': 'Congratulations, you are in the Top 50 of your country.', 
'notifications.type.top50Regional': 'Congratulations, you are in the Top 50 of your country.', 
'notifications.type.unitGroupEnabled': 'You have unlocked a new set of units in the module {moduleName}.', 
'notifications.type.weekTimeCloseToFinish': 'You need a few more minutes to complete you week time. Keep going!', 
'notifications.type.weekTimeCompleted': 'Congratulations! You have completed you required week time.', 
'otherOptions': 'Other Options', 
'payment.pendingPayment': 'You have a open payment due on {date}. <a href="{link}" target="_blank" style="color: blue; text-decoration: underline">Click here</a> to pay now.', 
'placementTest.advanced': 'Advanced', 
'placementTest.beginner': 'Beginner', 
'placementTest.button.continueToPlatform': 'Continue', 
'placementTest.button.exit': 'Exit', 
'placementTest.button.repeatWarming': 'Repeat Warm-up', 
'placementTest.button.startPlacement': 'Start Placement Test', 
'placementTest.button.startWarm': 'Start Warm-up', 
'placementTest.button.tryAgain': 'Try Again', 
'placementTest.button.watchVideo': 'Watch the above video', 
'placementTest.completeProfile.subTitle': 'Complete your register', 
'placementTest.completeProfile.title': 'Profile', 
'placementTest.completeProfileTitle': 'Before we start, let\'s complete your profile', 
'placementTest.description': 'Watch the video below, it will give you important tips on how to take the placement test.', 
'placementTest.endError': 'There was a problem completing your placement test. Click the button below to try again.', 
'placementTest.englishFluency': 'English Fluency', 
'placementTest.finished.description': 'Thank you for taking the placement test. Please speak with your teacher in regards to your results.', 
'placementTest.finished.title': 'Test finished', 
'placementTest.greeting': 'Welcome {name}!', 
'placementTest.intermediate': 'Intermediate', 
'placementTest.introduction': 'Introduction', 
'placementTest.introduction.button.start': 'Start warm-up', 
'placementTest.introduction.button.watchVideo': 'Watch the video above', 
'placementTest.introduction.description': 'Watch the video below, it will give you important tips on how to take the placement test.', 
'placementTest.introduction.subTitle': 'Take your Placement Test', 
'placementTest.introduction.title': 'Introduction', 
'placementTest.levelSelect.beginner.description': 'Suitable for those who have a base of English vocabulary.', 
'placementTest.levelSelect.beginner.title': 'Beginner', 
'placementTest.levelSelect.none.description': 'You will start from the beginning, from the first words.', 
'placementTest.levelSelect.none.title': 'Super Beginner', 
'placementTest.levelSelect.placement.description': 'Identify your level with our placement test.', 
'placementTest.levelSelect.placement.title': 'Find Your Level', 
'placementTest.levelSelect.subTitle': 'Or take our placement test', 
'placementTest.levelSelect.title': 'Choose your English level', 
'placementTest.nextStep': 'Your next step:', 
'placementTest.pauseDescription1': 'You already reached the level {level} ({course}).', 
'placementTest.pauseDescription2': 'Use this break to relax and take a deep breath. The test will continue in 30 seconds.', 
'placementTest.pauseTitle': 'You are doing great!', 
'placementTest.placement': 'Placement Test', 
'placementTest.placementResult.beginnerEnglishLevel': 'Beginner', 
'placementTest.placementResult.button.continue': 'Continue', 
'placementTest.placementResult.button.exit': 'Exit', 
'placementTest.placementResult.correctCount': 'Hits', 
'placementTest.placementResult.englishLevel': 'English Level', 
'placementTest.placementResult.englishLevelRuler': 'Your English proficiency level according to the international standard:', 
'placementTest.placementResult.loading': 'Calculating your result...', 
'placementTest.placementResult.startingCourse': 'You will start in the course', 
'placementTest.placementResult.subTitle': 'Check your score', 
'placementTest.placementResult.title': 'Placement Test result', 
'placementTest.placementResultCorrects': 'Hits', 
'placementTest.placementResultEnglishLevel': 'English Level', 
'placementTest.placementResultPoint': 'Score', 
'placementTest.placementRulerTitle': 'Your English proficiency level according to the international standard', 
'placementTest.placementScore': 'Your score', 
'placementTest.placementScoreAnalysisFirst': 'Your first score', 
'placementTest.placementScoreAnalysisNew': 'Your new score', 
'placementTest.placementTest.loading': 'Loading test...', 
'placementTest.placementTest.title': 'Placement Test', 
'placementTest.practiceResult.badScoreDescription': 'You can start the Placement Test. If you want, you can also repeat the warm-up.', 
'placementTest.practiceResult.badScoreTitle': 'Okay.', 
'placementTest.practiceResult.button.retry': 'Repeat warm-up', 
'placementTest.practiceResult.button.start': 'Start Placement Test', 
'placementTest.practiceResult.goodScoreDescription': 'You seem to be ready to start the placement test. Good luck!', 
'placementTest.practiceResult.goodScoreTitle': 'Very good!', 
'placementTest.practiceResult.subTitle': 'Now, les\'s find out your level', 
'placementTest.practiceResult.title': 'Warm-up Completed', 
'placementTest.practiceTest.loading': 'Loading the warm-up...', 
'placementTest.practiceTest.title': 'Warm-up', 
'placementTest.profile': 'Profile', 
'placementTest.register.duplicatedEmail': 'Email already registered in the system. Check with your teacher how to access the platform to take the placement test.', 
'placementTest.register.title': 'Welcome to your placement test.', 
'placementTest.score': 'Score', 
'placementTest.startError': 'There was a problem starting your placement test. Click the button below to try again.', 
'placementTest.steps': '{from} of {to}', 
'placementTest.testPause.advancedLevel': '(advanced)', 
'placementTest.testPause.greeting': 'Very good!', 
'placementTest.testPause.greetingDescriptionPart1': 'You have reached', 
'placementTest.testPause.greetingDescriptionPart2': 'level {course}', 
'placementTest.testPause.intermediateLevel': '(intermediate)', 
'placementTest.testPause.pauseDescription': 'The test will continue in 30 seconds, once the timer stops.', 
'placementTest.testPause.pauseTitle': 'Let\'s take a break.', 
'placementTest.testPause.title': 'Placement Test', 
'placementTest.userDemoOnboarding.button': 'Ok', 
'placementTest.userDemoOnboarding.message': '<p>This test is just a demonstration of what your students will be doing, so don\'t worry about getting things wrong!</p><p>After finishing, just refresh your profile page to access any course through the "Open Demo" button.</p>', 
'placementTest.warming': 'Warm-up', 
'placementTest.warmingBadScore': 'Okay, you have completed the warm-up', 
'placementTest.warmingBadScoreDescription': 'You can start the Placement Test. If you want, you can also repeat the warm-up.', 
'placementTest.warmingGoodScore': 'Well done, you\'ve finished the warm-up!', 
'placementTest.warmingGoodScoreDescription': 'You seem to be ready to start the placement test. Good luck!', 
'placementTestInvite.button.doLater': 'Not right now', 
'placementTestInvite.button.startPlacement': 'Start Placement Test', 
'placementTestInvite.description': 'Click on the button below and see how much you have improved.', 
'placementTestInvite.subTitle': 'Congratulations for your time studying English!', 
'placementTestInvite.title': 'Let\'s check your progress until now', 
'points': 'Points', 
'profile.account.birthDate': 'Birth Date', 
'profile.account.button.back': 'Back', 
'profile.account.button.save': 'Save', 
'profile.account.button.saveContinue': 'Save and Continue', 
'profile.account.changeAvatar': 'Change Avatar', 
'profile.account.city': 'City', 
'profile.account.completeProfileTitle': 'Before we start, let\'s complete your profile', 
'profile.account.confirmPassword': 'Confirm password', 
'profile.account.contactPhone': 'Contact Phone', 
'profile.account.currentPassword': 'Current Password', 
'profile.account.email': 'Email', 
'profile.account.error.email': 'This email is already in use. Please use another email.', 
'profile.account.error.general': 'We were unable to update your info. Please check the fields and try again.', 
'profile.account.error.password': 'Check you current password. To change your password you must inform your current password and the new password.', 
'profile.account.error.picture': 'We were unable to update your avatar. Try again later.', 
'profile.account.female': 'Female', 
'profile.account.gender': 'Gender', 
'profile.account.institution': 'Institution', 
'profile.account.locale.english': 'English', 
'profile.account.locale.label': 'Locale', 
'profile.account.locale.portuguese': 'Portuguese', 
'profile.account.locale.spanish': 'Spanish', 
'profile.account.male': 'Male', 
'profile.account.name': 'Name', 
'profile.account.newPassword': 'New Password', 
'profile.account.nicknameTooltip': 'Name that will be shown on the platform', 
'profile.account.notRankingParticipant': 'I don\'t want to participate in the {name} ranking', 
'profile.account.passwordDescription': 'To change your password, enter your current password and the desired new password.', 
'profile.account.passwordQuestion': 'Would you like to change your password?', 
'profile.account.passwordTitle': 'Password', 
'profile.account.phone': 'Phone', 
'profile.account.rankingDescription': 'Points received while not participating in the ranking won\'t be recorded.', 
'profile.account.rankingParticipant': 'I want to participate in the {name} ranking', 
'profile.account.school': 'School', 
'profile.account.success.picture': 'Your avatar has been successfully updated.', 
'profile.account.success.profile': 'Successfully updated your info.', 
'profile.account.title': 'Student info', 
'profile.achievement.noData': 'You don\'t have any achievements yet. Keep studying and try to be the best to get your first medal!', 
'profile.achievement.noNationalData': 'No national achievements', 
'profile.achievement.noRegionalData': 'No regional achievements', 
'profile.achievement.noSchoolData': 'No institutional achievements', 
'profile.achievement.noStudentData': 'No personal achievements', 
'profile.achievement.title': 'Achievements', 
'profile.history.noData': 'You don\'t have a history yet.', 
'profile.history.tableTitle': 'Ranking Positions', 
'profile.history.title': 'History', 
'profile.performance.noData': 'Keep studying and we will show you your performance!', 
'profile.performance.noHoursMonthChartData': 'No studied hours in month {month}', 
'profile.performance.noHoursWeekChartData': 'No studied hours in week {week}', 
'profile.performance.noPointsMonthChartData': 'No earned points in month {month}', 
'profile.performance.noPointsWeekChartData': 'No earned points in week {week}', 
'profile.performance.title': 'Performance', 
'profile.progressTimeline': 'Progress Timeline', 
'profile.sprint.noData': 'Keep take your course and we will track your sprints!', 
'profile.sprint.title': 'Sprint History', 
'profile.timelineApprovedMasteryTest': 'Completed a Mastery Test', 
'profile.timelineBonusTestCompleted': 'Bonus Test completed', 
'profile.timelineCertificationTestCompleted': 'Completed the Certification Test', 
'profile.timelineCourseStarted': 'Course started', 
'profile.timelineDailyRecord': 'Earned <b>{points}</b> points', 
'profile.timelineFailedMasteryTest': 'Failed a Mastery Test', 
'profile.timelineModuleCompleted': 'Completed the module <b>{moduleName}</b>', 
'profile.timelineNoData': 'Start your course and we will create your timeline.', 
'profile.timelinePlacementTestLevel': 'Reached level <b>{level}</b> in the Placement Test', 
'profile.title': 'Profile', 
'profile.updateProfileLink': 'Update Profile', 
'profile.yourWeekTime': 'Your Week Time', 
'ranking.cardCheckFullData': 'See the full ranking', 
'ranking.cardNoData': 'No Ranking available', 
'ranking.currentSchoolPosition': 'Your Institutional Position', 
'ranking.firstSemester': 'First semester', 
'ranking.monthSelectLabel': 'Select the month', 
'ranking.nationalPosition': 'National position', 
'ranking.noData': 'No ranking available yet, be the first to unlock the ranking.', 
'ranking.noPositionHistory': 'No position history', 
'ranking.positionHistory': 'Position History', 
'ranking.rankingPoints': '{points} points', 
'ranking.regionalPosition': 'Regional position', 
'ranking.rewards.ranking': 'Ranking points', 
'ranking.rewards.time': 'Weekly time', 
'ranking.rewards.title': 'Your rewards', 
'ranking.schoolPosition': 'Institutional position', 
'ranking.secondSemester': 'Second semester', 
'ranking.title': '{month} Ranking', 
'ranking.topOfAllTime': 'Top of all times', 
'ranking.topOfMonth': 'Top of', 
'ranking.topOfSemester': 'Top of Semester', 
'ranking.topOfYear': 'Top of the Year', 
'ranking.yearSelect': 'Year', 
'reading': 'Reading', 
'record': 'Record', 
'regional': 'Regional', 
'repeat': 'Repeat', 
'requestPermission.button.allow': 'Allow', 
'requestPermission.camera.description': 'We need your confirmation to access, process, collect and store your profile photo. Your profile photo is public and will be visible in the rankings.', 
'requestPermission.camera.title': 'Camera access', 
'requestPermission.media_library.description': 'We need your confirmation to access, process, collect and store the profile photo chosen by you. Your profile photo is public and will be visible in the rankings.', 
'requestPermission.media_library.title': 'Library access', 
'requestPermission.microphone.description': 'We need you consent to access, process, colect and store the audios recorded as answers during the exercises.', 
'requestPermission.microphone.title': 'Microphone access', 
'requireDesktop.hasAppCard.description': 'Press the button to start the app.', 
'requireDesktop.hasAppCard.title': 'Do you already have the app?', 
'requireDesktop.installAppCard.step1': 'Install the app on your smartphone.', 
'requireDesktop.installAppCard.step2': 'Press the button to start the app.', 
'requireDesktop.installAppCard.title': 'Haven\'t installed it yet?', 
'requireDesktop.open': 'Open app', 
'requireDesktop.placementDemo': 'Open placement', 
'requireDesktop.title': 'Oops! You need to download our app', 
'requireDesktop.unitDemo': 'Open demonstration', 
'school': 'Institutional', 
'scoreToPass': 'Score to pass', 
'slowRepeat': 'Slow Repeat', 
'speaking': 'Speaking', 
'sprint.classAverage': 'Classroom average', 
'sprint.currentSprint': 'Current sprint', 
'sprint.description': 'Days of continuous study', 
'sprint.maximumSprint': 'Maximum sprint', 
'sprint.no.classAverage': 'No classroom average', 
'sprint.no.currentSprint': 'No current sprint', 
'sprint.no.maximumSprint': 'No caximum sprint', 
'sprint.no.schoolAverage': 'No Institutional average', 
'sprint.noData': 'Your sprint record will be generated after 2 days of study', 
'sprint.schoolAverage': 'Institutional average', 
'sprint.title': 'Sprint', 
'startIntroductionButton': 'Start Introduction', 
'startTastingModal.button': 'Start', 
'startTastingModal.firstDescription': 'Before starting check if your headset or speaker is working, as the instructions will be provided via audio. Follow them for a full experience.', 
'startTastingModal.secondDescription': 'Let\'s go!', 
'startTastingModal.title': 'Welcome!', 
'student': 'Personal', 
'studyQuality.classAverage': 'Class average', 
'studyQuality.classroom': 'Classroom', 
'studyQuality.howToImprove': 'How to improve it?', 
'studyQuality.howToImproveNoData': 'We currently do not have details of your study. Keep studying and we will generate the information to help you.', 
'studyQuality.modalTitle': 'Your Study Quality', 
'studyQuality.name': 'Study Quality', 
'studyQuality.noData': 'Your Study Quality will be generated after 1 hour of study', 
'studyQuality.noEvaluationPeriod': 'You are not in an evaluation period.', 
'studyQuality.requirements.DAYS_STUDIED': 'Study at least 7 days every 15 days', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'When you study, do it for at least 30 minutes.', 
'studyQuality.requirements.LISTEN_USAGE': 'Use of the Listen button', 
'studyQuality.requirements.READING_USAGE': 'Do not overuse the Read button', 
'studyQuality.requirements.REPEAT_RECORD_LISTEN_RELATION': 'Use 2x more the Repeat button than the Listen and Speak buttons', 
'studyQuality.requirements.REPEAT_USAGE': 'Use of the Repeat button', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Correct Answers', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Average Speech Recognition score', 
'studyQuality.requirements.TIME_STUDIED': 'Study the required week time', 
'studyQuality.requirements.TRANSLATE_USAGE': 'Do not overuse the Translate button', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Average score in units', 
'studyQuality.studentScore': 'Your score', 
'studyQuality.title': 'Study Quality History', 
'studyQuality.yours': 'Yours', 
'subMenu.courseProgress': 'Your progress', 
'subMenu.masteryTestDescription': 'Mastery Test for the Module {moduleName}', 
'subMenu.weekTime': 'Week Time', 
'survey.button.doLater': 'Later', 
'survey.button.start': 'Answer now', 
'survey.description': 'We want to know what you think. You can respond in less than 1 minute. Click the button below to get started, we appreciate your participation!', 
'survey.title': 'What do you think about studying English with our app?', 
'tastingContact.company': 'Company*', 
'tastingContact.country': 'Country*', 
'tastingContact.description': 'Please, fill out the form below to continue.', 
'tastingContact.email': 'Email*', 
'tastingContact.fullName': 'Full name*', 
'tastingContact.message': 'Message', 
'tastingContact.name': 'Name*', 
'tastingContact.phone': 'Phone/Skype', 
'tastingContact.saveAndContinue': 'Try now', 
'tastingContact.title': 'Try {title}', 
'tastingContact.whatsApp': 'WhatsApp*', 
'timer': 'Timer', 
'translate': 'Translate', 
'unit.abilities': 'Skills', 
'unit.blockedDueTime': 'Locked for 3 minutes', 
'unit.button.continue': 'Continue', 
'unit.button.locked': 'Locked', 
'unit.button.play': 'Play', 
'unit.button.recap': 'Start', 
'unit.button.replay': 'Replay', 
'unit.button.start': 'Start', 
'unit.button.watch': 'Watch', 
'unit.button.watch_again': 'Watch again', 
'unit.challengeTooltip': 'Click on start to take on the Challenge and earn those points.', 
'unit.contentVideoStep.available': 'Available', 
'unit.contentVideoStep.blocked': 'Blocked', 
'unit.contentVideoStep.completed': 'Watched', 
'unit.contentVideoStep.title': 'Lesson progress', 
'unit.enunciationsModal.actions.back': 'Back', 
'unit.enunciationsModal.actions.start': 'Start', 
'unit.enunciationsModal.content': 'Content', 
'unit.enunciationsModal.grammars': 'Grammars', 
'unit.enunciationsModal.skills': 'Skills', 
'unit.firstAccessTooltip': 'Click here', 
'unit.group.A': 'Phase 1', 
'unit.group.B': 'Phase 2', 
'unit.group.C': 'Phase 3', 
'unit.group.D': 'Phase 4', 
'unit.group.E': 'Phase 5', 
'unit.optionalReview': 'Optional', 
'unit.points': '{points} Points', 
'unit.requiredReview': 'Required', 
'unit.reviewTooltip': 'You must do the Review to complete the steps of this exercise. Click start to finish it.', 
'unit.reward.conqueredTitle': 'Conquered', 
'unit.reward.title': 'Earn up to', 
'unit.simpleReviewTooltip': 'You can review it 3 times, and get {amountOfPoints} points each time.', 
'unit.status.continue': 'Continue', 
'unit.status.first_review': 'Review', 
'unit.status.simple_review': 'Recap', 
'unit.status.your_challenge': 'Challenge', 
'unit.steps.description': 'Each exercise consists of two stages: Challenge and Review. When the Challenge stage is completed, the Review is available the next day.', 
'unit.steps.exercise': 'Exercise', 
'unit.steps.first_review': 'Review', 
'unit.steps.title': 'Exercise Steps', 
'unit.steps.your_challenge': 'Challenge', 
'unit.title': 'Exercises', 
'unit.unblockReviewIn': 'Review will be available tomorrow', 
'unitExecutionNotification.3_CHECKPOINT_SEQUENCE': [ 
'Your efforts are paying off. Way to go!', 
'Impressive, you know so much!', 
'Oh my, you’re such a fast learner! Well done!', 
'Amazing streak of right answers! Congrats!', 
'Great, keep going!', 
], 
'unitExecutionNotification.3_SPEECH_RECOGNITION_SEQUENCE': [ 
'Great! You really know your English!', 
'Triple dose of amazing pronunciation! Congratulations!', 
'You never get tired of being amazing!', 
'Nailed it on that pronunciation!', 
'Your potential for English is wonderful!', 
'I could listen to your English for hours! Fascinating!', 
], 
'unitExecutionNotification.6_CHECKPOINT_SEQUENCE': [ 
'Wow, you’re so focused!', 
'Awesome, you’re too good. This isn’t even fair!', 
'Wow! You’re crushing it!', 
'You’re just trying to deceive me saying you don’t know English!', 
'No way, please stand up for the applause people!', 
], 
'unitExecutionNotification.6_SPEECH_RECOGNITION_SEQUENCE': [ 
'You’re doing great on pronunciation! Amazing!', 
'You are going places with that pronunciation!', 
'Wow! Another excellent pronunciation!', 
'Did great on that pronunciation! Way to go!', 
'Tell the truth, you already knew how to speak English, huh?', 
'OMG, your pronunciation is wonderful!', 
'The English language and you were made for each other!', 
'Match made in heaven! You and English are already in a serious relationship!', 
], 
'unitExecutionNotification.9_CHECKPOINT_SEQUENCE': [ 
'You’re a genius!', 
'What an incredible demonstration of knowledge!', 
'You just don’t make mistakes. Stunning!', 
'You can already call yourself an English guru!', 
'Your level of knowledge is spectacular!', 
'Your performance is out of this world! Wonderful', 
], 
'unitExecutionNotification.9_SPEECH_RECOGNITION_SEQUENCE': [ 
'You’re speaking like an expert!', 
'You speak amazingly well!', 
'Well, well, well... looks like we have a little genius here!', 
'Wow! Were you born speaking English?', 
'When will this wonderful streak end? I’m amazed!', 
'What a great streak! You’re wonderful!', 
'I’m sure you could easily talk with an American, no problem!', 
'You’re basically a master of English.', 
'OMG! From now on we will speak only in English please!', 
'So exciting! My little Padawan has become almost an English Jedi!', 
'Hey, how much are you charging to teach English?', 
], 
'unitExecutionNotification.LISTEN_USAGE_TIP': [ 
'Increase your Study Quality by using the Listen button more times.', 
'Hey, don’t forget about using the Listen button to listen to your pronunciation.', 
'Listening to your own pronunciation improves your learning.', 
'The Listen button helps you understand your pronunciation. Don’t forget to use it!', 
'Listening to yourself helps you learn better. Use it more often!', 
], 
'unitExecutionNotification.MAX_CHECKPOINT_SEQUENCE': [ 
'Are you from the US by any chance?', 
'Don’t even deny it, you’re from the US!', 
'OMG, with your English level you should already be able to become an American citizen!', 
'Are you, perhaps, a language Einstein?', 
'You know so much, not even a native English speaker would believe your nationality!', 
], 
'unitExecutionNotification.MAX_SPEECH_RECOGNITION_SEQUENCE': [ 
'Wow, you’re basically American.', 
'Confess already! English is your native language, isn’t it?', 
'I bet you said hello instead of crying when you were born!', 
'You have reached Jedi level, may the pronunciation be with you!', 
'M-A-R-V-E-L-O-U-S! You’re completely dominating English!', 
'Nobody told me I was talking to an American citizen!', 
'Okay, no need to show us your passport, we know you’re from the US!', 
'Wow, how long have you been living in the US?', 
'If you tell me you\'re not fluent in English, I\'ll just have to believe you!', 
'Limits mean nothing to you, huh? If you keep this up, we will have to hire you to work with us!', 
'You\'re making me blush! I wasn\'t ready to speak with someone fluent in English.', 
'I checked your ID and it doesn’t say you were born in the US. Is that a mistake?', 
], 
'unitExecutionNotification.NO_LISTEN_SEQUENCE': [ 
'Don’t get discouraged, listen to your pronunciation and it will be easier to get it right!', 
'Support buttons make life easier and help you learn. Feel free to use them!', 
'Listen to your pronunciation and compare it to the fluent speaker\'s pronunciation.', 
'If you’re having trouble, don’t forget to listen to yourself.', 
'Listening to yourself will improve your English pronunciation.', 
'Don’t forget to listen to yourself. That will make learning easier.', 
'Before trying again, listen to yourself to compare your pronunciation with the audio.', 
], 
'unitExecutionNotification.NO_REPEAT_SEQUENCE': [ 
'Listen to the audio multiple times before trying again.', 
'Use the Repeat button to listen carefully to the pronunciation.', 
'Listen closely to the sentence again to help you get it right!', 
'If you’re having trouble, just use the Support buttons. Take advantage of them!', 
'You can repeat the audio slower if you’re having problems.', 
'Support buttons are there to help you. Don’t be afraid to use them!', 
'Listening to the pronunciation carefully will help you! Use the Repeat button more!', 
'How about using the support buttons for additional help?', 
], 
'unitExecutionNotification.REPEAT_USAGE_TIP': [ 
'By using the Repeat button, your Study Quality will improve.', 
'Don\'t forget to listen to the pronunciation in the audio several times.', 
'Listening to the audio multiple times will help improve your pronunciation.', 
'Use the Repeat button more often. This accelerates your learning.', 
'Use the Repeat button to listen to the pronunciation in the audio multiple times.', 
], 
'unitRating.comment': 'Leave a comment...', 
'unitRating.question': 'Your rating is very important to us.', 
'unitRating.title': 'Leave a feedback', 
'unitResult.closeTasting': 'Close', 
'unitResult.conclude': 'Complete', 
'unitResult.congrats': 'Congratulations!', 
'unitResult.continueToUnitList': 'Continue to the exercise list', 
'unitResult.currentRanking': 'Ranking', 
'unitResult.keepPracticing': 'Keep practicing', 
'unitResult.loading': 'Calculating your score', 
'unitResult.newPoints': 'Your new stats', 
'unitResult.newRanking': 'New Ranking', 
'unitResult.next': 'Next', 
'unitResult.recommendedUnits': 'Recommended unit', 
'unitResult.reviewStep.didWell.button.no': 'No, I don\'t want to review', 
'unitResult.reviewStep.didWell.button.yes': 'Yes, I want to review', 
'unitResult.reviewStep.didWell.reviewImportance': 'Why is it important to review?', 
'unitResult.reviewStep.didWell.subtitle': 'Would you like to review this unit?', 
'unitResult.reviewStep.didWell.title1': 'You did very well!', 
'unitResult.reviewStep.didWell.title2': 'The review will be optional.', 
'unitResult.reviewStep.firstReview.subtitle': 'Please try again before advancing through the course.', 
'unitResult.reviewStep.firstReview.title': 'It looks like you are having difficulty with this exercise.', 
'unitResult.reviewStep.modal.button': 'Got it', 
'unitResult.reviewStep.modal.paragraph1': 'Reviewing is very important to consolidate the knowledge you have already acquired and fill in any gaps that remain.', 
'unitResult.reviewStep.modal.paragraph2': 'Studying again on different days helps you record it in your long-term memory!', 
'unitResult.reviewStep.modal.paragraph3': 'Your review will be available tomorrow.', 
'unitResult.reviewStep.modal.title': 'Why is it important to review?', 
'unitResult.reviewStep.notWell.description': 'A review has been added to your list and will be available tomorrow.', 
'unitResult.reviewStep.notWell.title': 'Let\'s try again', 
'unitResult.reviewStep.optionalReview.button.no': 'No', 
'unitResult.reviewStep.optionalReview.button.yes': 'Yes', 
'unitResult.reviewStep.optionalReview.subtitle': 'Would you like to try again?', 
'unitResult.reviewStep.optionalReview.title': 'This review was optional.', 
'unitResult.reviewStep.title': 'Review', 
'unitResult.scoreToPass': 'Score to pass: {score}', 
'unitResult.yourScore': 'Your Score', 
'unitResult.yourTotalPoints': 'Your total points', 
'updates.button.updateNow': 'Update now', 
'updates.description': 'We have an update available for you!', 
'userAway.button': 'Yes, I am here!', 
'userAway.description': 'You\'ve been away for a while. If you are there just click the button below.', 
'userAway.penalityLabel': 'Due to inactivity, you will lose 30 seconds of study time on this exercise.', 
'userErrorReport.buttonsText.discardChanges': 'Discard', 
'userErrorReport.buttonsText.submit': 'Submit', 
'userErrorReport.errorMessages.apiError': 'We were unable to submit your report. Please wait a moment and try again', 
'userErrorReport.errorMessages.studentNotFound': 'The email address is not registered in the platform', 
'userErrorReport.labels.content': 'Tell us what happened', 
'userErrorReport.labels.email': 'Email', 
'userErrorReport.labels.subject': 'Subject', 
'userErrorReport.placeholders.content': 'Feel free to send us any suggestions and feedback', 
'userErrorReport.placeholders.email': 'Enter your email address registered on the platform', 
'userErrorReport.placeholders.subject': 'Type here the reason for your report', 
'userErrorReport.title': 'Report an error', 
'userSnapButton': 'Report Error', 
'validate': 'Validate', 
'validations.Invalid email': 'Invalid email', 
'validations.Must Match Password': 'Must match the password', 
'validations.Required': 'Required', 
'videoIntroduction.button': 'Continue', 
'videoIntroduction.home.title': 'Understand your course', 
'videoIntroduction.module_list.title': 'Understand your course', 
'videoIntroduction.unit_list.title': 'Know your exercises', 
'videoIntroduction.welcome.title': 'Understand your course', 
'videoPlayer.localesSelect': 'Select a subtitle language', 
'videoPlayer.noSubtitles': 'Off', 
'week': 'Week', 
'welcomeModal.description': 'You will need a <b>speaker</b> and a <b>microphone</b> to do the exercises.', 
'welcomeModal.startButton': 'Let\'s start', 
'welcomeModal.title': 'Welcome to the Flexge', 
'writing': 'Writing', 
'you': 'You', 
}; 
export default labels;