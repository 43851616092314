import React from "react";
import PropTypes from "prop-types";
import Flow from "student-front-commons/src/components/Flow";
import RecordButton from "./RecordButton";
import { OLD_END_RECORD_FLOW, OLD_START_RECORD_FLOW } from "../../consts";

const RecordButtonContainer = (props) => (
  <Flow autoStart={false} id={OLD_END_RECORD_FLOW}>
    {({ isPending, handleStart: handleEndRecord }) => (
      <Flow autoStart={false} id={OLD_START_RECORD_FLOW}>
        {({ isPending: isStartingRecording, handleStart: handleStartRecord }) => (
          <RecordButton
            onClick={props.isRecording ? handleEndRecord : handleStartRecord}
            isLoading={isPending || isStartingRecording}
            isRecording={props.isRecording}
            isDisabled={isPending || isStartingRecording || props.isDisabled}
          />
        )}
      </Flow>
    )}
  </Flow>
);

RecordButtonContainer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isRecording: PropTypes.bool.isRequired,
};

export default RecordButtonContainer;
