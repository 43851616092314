import React, { useCallback } from "react";
import { Transition } from "react-spring";
import UnitExecutionToastListItem from "./UnitExecutionToastListItem";
import { useDispatch, useSelector } from "react-redux";
import { removeNotification } from "student-front-commons/src/actions/executionAchievement";
import { getExecutionAchievementNotifications } from "student-front-commons/src/selectors/executionAchievement";

function UnitExecutionToastList() {
  const dispatch = useDispatch();
  const notifications = useSelector(getExecutionAchievementNotifications);

  const handleHide = useCallback((value) => {
    dispatch(removeNotification(value));
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        bottom: 160,
        width: "100%",
        left: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex: 11,
      }}
    >
      <Transition
        items={notifications}
        from={{
          transform: "scale(0)",
          opacity: 0,
        }}
        enter={{
          transform: "scale(1)",
          opacity: 1,
        }}
        leave={{
          transform: "scale(0)",
          opacity: 0,
        }}
      >
        {(item) => (styleProps) =>
          (
            <div style={styleProps}>
              <UnitExecutionToastListItem value={item} onHide={handleHide} />
            </div>
          )}
      </Transition>
    </div>
  );
}

export default UnitExecutionToastList;
