import React from "react";
import PropTypes from "prop-types";
import withTranslation from "../../core/withTranslation";
import TranslatedText from "../../core/TranslatedText";

const GradeListStudyQualityColumn = (props) => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    <h1
      style={{
        color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
        margin: 0,
        marginBottom: 5,
        fontSize: 22,
        fontWeight: "bold",
      }}
    >
      {props.grade !== null ? props.grade : "N/A"}
    </h1>
    {props.grade !== null && (
      <div>
        <TranslatedText
          as="span"
          translateKey="grades.averageSq"
          values={{
            grade: props.average !== null ? props.average.toFixed(1) : "N/A",
          }}
          style={{
            color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
            fontSize: 12,
            marginBottom: 3,
          }}
        />
        <br />
        {props.average !== null && (
          <span
            style={{
              color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
              fontSize: 12,
              marginBottom: 5,
            }}
          >
            {props.average >= 10 && props.getTranslation("grades.excellent")}
            {props.average < 10 && props.average >= 7 && props.getTranslation("grades.veryGood")}
            {props.average < 7 && props.average >= 4 && props.getTranslation("grades.good")}
            {props.average < 4 && props.average >= 1 && props.getTranslation("grades.moderate")}
            {props.average < 1 && props.average >= -2 && props.getTranslation("grades.weak")}
            {props.average < -2 && props.getTranslation("grades.veryWeak")}!
          </span>
        )}
      </div>
    )}
  </div>
);

GradeListStudyQualityColumn.propTypes = {
  grade: PropTypes.number,
  average: PropTypes.number,
  start: PropTypes.string.isRequired,
  preview: PropTypes.bool.isRequired,
};

GradeListStudyQualityColumn.defaultProps = {
  grade: null,
  average: null,
};

export default withTranslation(GradeListStudyQualityColumn);
