import { put, takeLatest, select, call } from "redux-saga/effects";
import { getLocalization } from "student-front-commons/src/services/localizationService";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import localizedLabels from "../locales";
import { LOAD_LOCALIZED_LABELS_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_LOCALIZED_LABELS_FLOW), function* () {
    try {
      const flow = yield select(getFlow(LOAD_LOCALIZED_LABELS_FLOW));

      const englishLabels = yield call(getLocalization, {
        type: "STUDENT",
        locale: "en",
      });
      const userLabels = yield call(getLocalization, {
        type: "STUDENT",
        locale: flow.params.locale,
      });

      localizedLabels.setContent({
        en: englishLabels,
        [flow.params.locale]: userLabels,
      });
    } catch (error) {
      logError({ error, flow: LOAD_LOCALIZED_LABELS_FLOW });
    } finally {
      yield put(endFlow(LOAD_LOCALIZED_LABELS_FLOW));
    }
  });
}
