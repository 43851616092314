import React from "react";
import PropTypes from "prop-types";
import Modal from "../core/Modal";
import Separator from "../core/Separator";
import withTranslation from "../core/withTranslation";
import TranslatedText from "../core/TranslatedText";
import CourseThemeContext from "../core/CourseThemeContext";
import Button from "../core/Button";
import { WHITE } from "../consts/color";
import QuestionLabel from "../core/QuestionLabel";
import FlexRow from "../core/FlexRow";
import Icon from "../core/Icon";
import ColumnSeparator from "../core/ColumnSeparator";

const OldErrorModal = (props) => (
  <Modal>
    <FlexRow alignItems="center" justifyContent="center">
      {props.icon && (
        <React.Fragment>
          <Icon icon={props.icon} color="#607d8b" size="xl" />
          <ColumnSeparator size="md" />
        </React.Fragment>
      )}
      <TranslatedText translateKey={props.message} as={QuestionLabel} fontSize={16} fontWeight={900} cursor="default" />
    </FlexRow>
    <Separator size="lg" />
    <CourseThemeContext.Consumer>
      {({ primary }) => (
        <Button color={primary} fontColor={WHITE} width="auto" fontWeight={900} onClick={props.onClose}>
          {props.getTranslation(props.buttonLabel)}
        </Button>
      )}
    </CourseThemeContext.Consumer>
  </Modal>
);

OldErrorModal.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

OldErrorModal.defaultProps = {
  icon: null,
};

export default withTranslation(OldErrorModal);
