import parsePhoneNumber from "libphonenumber-js";
const isPhoneNumber = (value) => {
  if (value) {
    try {
      const parsedNumber = parsePhoneNumber(value);
      if (parsedNumber) {
        return !parsedNumber.isValid() && "Invalid phone number";
      }
    } catch (error) {
      return "Invalid phone number";
    }
  }
};

export default isPhoneNumber;
