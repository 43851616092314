export const INIT_FORM = "INIT_FORM";
export const CHANGE_FORM_VALUE = "CHANGE_FORM_VALUE";
export const SUBMIT_FORM = "SUBMIT_FORM";
export const RESET_FORM = "RESET_FORM";
export const DELETE_FORM = "DELETE_FORM";

/**
 * action to init a form in state
 *
 * @param formId
 * @param initialValues
 * @returns {{type, payload: {formId: *, initialValues: *|{}}}}
 */
export const initForm = (formId, initialValues) => ({
  type: INIT_FORM,
  payload: {
    formId,
    initialValues,
  },
});

/**
 * action to update the value (path) in form
 *
 * @param formId
 * @param path
 * @param value
 * @param validations
 * @returns {{type, payload: {formId: *, path: *, value: *}}}
 */
export const changeFormValue = (formId, path, value, validations = null) => ({
  type: CHANGE_FORM_VALUE,
  payload: {
    formId,
    path,
    value,
    validations,
  },
});

/**
 * action to mark a form as submitted
 *
 * @param formId
 * @returns {{type, payload: {formId: *}}}
 */
export const submitForm = (formId, validations = null) => ({
  type: SUBMIT_FORM,
  payload: {
    formId,
    validations,
  },
});

/**
 * action to mark a form as submitted
 *
 * @param formId
 * @returns {{type, payload: {formId: *}}}
 */
export const deleteForm = (formId) => ({
  type: DELETE_FORM,
  payload: {
    formId,
  },
});
