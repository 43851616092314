import React from "react";
import PropTypes from "prop-types";
import RankingIcon from "../../images/icon-ranking.svg";
import PointsIcon from "../../images/icon-points.svg";
import ProgressIcon from "../../images/icon-progress.svg";
import StudyQualityIcon from "../../images/icon-study-quality.svg";
import TimeIcon from "../../images/icon-time.svg";
import UnlockIcon from "../../images/icon-unlock.svg";
import InfoIcon from "../../images/icon-info.svg";

const NotificationIcon = (props) => (
  <img
    alt="notification-icon"
    src={
      {
        FIRST_REVIEW_ENABLED: UnlockIcon,
        SECOND_REVIEW_ENABLED: UnlockIcon,
        MASTERY_TEST_ENABLED: UnlockIcon,
        MASTERY_TEST_FAILED: InfoIcon,
        MASTERY_TEST_RETRY: UnlockIcon,
        MODULE_GROUP_ENABLED: ProgressIcon,
        UNIT_GROUP_ENABLED: ProgressIcon,
        CERTIFICATION_TEST_CREATED: InfoIcon,
        CERTIFICATION_TEST_SCHEDULED: InfoIcon,
        CERTIFICATION_TEST_ENABLED: InfoIcon,
        CERTIFICATION_TEST_REVIEWED: InfoIcon,
        ACHIEVEMENT: RankingIcon,
        CLOSED_RANKING: RankingIcon,
        "90_PERCENT_OF_MODULE_COMPLETED": ProgressIcon,
        "80_PERCENT_OF_MODULE_COMPLETED": ProgressIcon,
        "50_PERCENT_OF_MODULE_COMPLETED": ProgressIcon,
        "90_PERCENT_OF_COURSE_COMPLETED": ProgressIcon,
        "80_PERCENT_OF_COURSE_COMPLETED": ProgressIcon,
        "50_PERCENT_OF_COURSE_COMPLETED": ProgressIcon,
        "25_PERCENT_OF_COURSE_COMPLETED": ProgressIcon,
        "30_MINUTES_IN_THE_DAY": TimeIcon,
        WEEK_TIME_COMPLETED: TimeIcon,
        WEEK_TIME_CLOSE_TO_FINISH: TimeIcon,
        "1_HOUR_TO_COMPLETE_EVALUATION_PERIOD_TIME": TimeIcon,
        "2_HOUR_TO_COMPLETE_EVALUATION_PERIOD_TIME": TimeIcon,
        STUDY_QUALITY_SCORE_15: StudyQualityIcon,
        STUDY_QUALITY_SCORE_10: StudyQualityIcon,
        MODULE_READING_COMPLETED: PointsIcon,
        MODULE_LISTENING_COMPLETED: PointsIcon,
        MODULE_SPEAKING_COMPLETED: PointsIcon,
        MODULE_WRITING_COMPLETED: PointsIcon,
        MORE_50_POINTS_TO_UNLOCK_UNIT_GROUP: UnlockIcon,
        TOP_10_NATIONAL: RankingIcon,
        TOP_50_NATIONAL: RankingIcon,
        TOP_10_REGIONAL: RankingIcon,
        TOP_50_REGIONAL: RankingIcon,
        TOP_10_SCHOOL: RankingIcon,
        TOP_30_SCHOOL: RankingIcon,
      }[props.type]
    }
    style={{
      width: "auto",
      height: 32,
    }}
  />
);

NotificationIcon.propTypes = {
  type: PropTypes.string.isRequired,
};

export default NotificationIcon;
