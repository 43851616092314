import React, { Component } from "react";
import PropTypes from "prop-types";
import ExerciseItemPanel from "./ExerciseItemPanel";
import SpeechPracticeAnimation from "../../lotties/speech-practice-animation.json";
import Lottie from "react-lottie";

export default class SpeechPracticeExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      audio: PropTypes.string.isRequired,
      generatedAudio: PropTypes.string.isRequired,
    }).isRequired,
    playingId: PropTypes.string.isRequired,
    currentResult: PropTypes.shape({
      audio: PropTypes.string,
      qualityScore: PropTypes.number,
      wordScoreList: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  };

  static defaultProps = {
    currentResult: null,
  };

  render() {
    return (
      <ExerciseItemPanel alignItems="center">
        <Lottie
          width={260}
          isPaused={!this.props.playingId || this.props.playingId !== this.props.item.id}
          options={{
            animationData: SpeechPracticeAnimation,
            loop: false,
            autoplay: true,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
        />
      </ExerciseItemPanel>
    );
  }
}
