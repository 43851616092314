export const DETECT_DEV_TOOLS_FLOW = "DETECT_DEV_TOOLS_FLOW";

export const SYSTEM_MESSAGE_FLOW = "SYSTEM_MESSAGE_FLOW";
export const DISMISS_SYSTEM_MESSAGE_FLOW = "DISMISS_SYSTEM_MESSAGE_FLOW";

export const UPDATE_PROFILE_FLOW = "UPDATE_PROFILE_FLOW";
export const UPDATE_PROFILE_FORM = "UPDATE_PROFILE_FORM";
export const UPDATE_PROFILE_PICTURE_FLOW = "UPDATE_PROFILE_PICTURE_FLOW";
export const UPDATE_PROFILE_PICTURE_FORM = "UPDATE_PROFILE_PICTURE_FORM";
export const COMPLETE_PROFILE_FORM = "COMPLETE_PROFILE_FORM";
export const COMPLETE_PROFILE_FLOW = "COMPLETE_PROFILE_FLOW";
export const CREATE_ACCOUNT_FORM = "CREATE_ACCOUNT_FORM";
export const CREATE_ACCOUNT_FLOW = "CREATE_ACCOUNT_FLOW";

export const LOAD_STUDENT_FLOW = "LOAD_STUDENT_FLOW";
export const LOAD_STUDENT_TASTING_FLOW = "LOAD_STUDENT_TASTING_FLOW";
export const LOAD_STUDENT_COURSE_FLOW = "LOAD_STUDENT_COURSE_FLOW";
export const LOAD_MODULES_FLOW = "LOAD_MODULES_FLOW";
export const UPDATE_STUDENT_PAGE_ACCESSED_FLOW = "UPDATE_STUDENT_PAGE_ACCESSED_FLOW";

export const LOAD_CONFIGURATION_FLOW = "LOAD_CONFIGURATION_FLOW";
export const LOAD_LOCALIZED_LABELS_FLOW = "LOAD_LOCALIZED_LABELS_FLOW";

export const OLD_START_RECORD_FLOW = "OLD_START_RECORD_FLOW";
export const OLD_END_RECORD_FLOW = "OLD_END_RECORD_FLOW";
export const OLD_END_RECORD_BY_TIMEOUT_FLOW = "OLD_END_RECORD_BY_TIMEOUT_FLOW";

export const START_RECORD_FLOW = "START_RECORD_FLOW";
export const END_RECORD_FLOW = "END_RECORD_FLOW";
export const END_RECORD_BY_TIMEOUT_FLOW = "END_RECORD_BY_TIMEOUT_FLOW";
export const PLAY_RECORD_AUDIO_FLOW = "PLAY_RECORD_AUDIO_FLOW";
export const PLAY_HISTORY_RECORD_AUDIO_FLOW = "PLAY_HISTORY_RECORD_AUDIO_FLOW";

export const ACHIEVEMENT_EXECUTION_FORM = "ACHIEVEMENT_EXECUTION_FORM";
export const CHECK_ACHIEVEMENT_EXECUTION_FLOW = "CHECK_ACHIEVEMENT_EXECUTION_FLOW";

export const OLD_PLAY_ITEM_AUDIO_FLOW = "OLD_PLAY_ITEM_AUDIO_FLOW";

export const PLAY_ITEM_AUDIO_FLOW = "PLAY_ITEM_AUDIO_FLOW";
export const PLAY_ITEM_VIDEO_FLOW = "PLAY_ITEM_VIDEO_FLOW";
export const PLAY_ITEM_AUDIO_STRIPE_FLOW = "PLAY_ITEM_AUDIO_STRIPE_FLOW";
export const ITEM_EXECUTION_FORM = "ITEM_EXECUTION_FORM";

export const ITEM_INTRODUCTION_FORM = "ITEM_INTRODUCTION_FORM";
export const START_ITEM_INTRODUCTION_FLOW = "START_ITEM_INTRODUCTION_FLOW";

export const UNIT_EXECUTION_FORM = "UNIT_EXECUTION_FORM";
export const START_UNIT_EXECUTION_FLOW = "START_UNIT_EXECUTION_FLOW";
export const CLOSE_UNIT_EXECUTION_FLOW = "CLOSE_UNIT_EXECUTION_FLOW";
export const END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW = "END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW";
export const GET_NEXT_UNIT_ITEM_EXECUTION_FLOW = "GET_NEXT_UNIT_ITEM_EXECUTION_FLOW";
export const CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW = "CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW";
export const SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW";
export const START_NEXT_UNIT_ITEM_EXECUTION = "START_NEXT_UNIT_ITEM_EXECUTION";
export const END_UNIT_EXECUTION_FLOW = "END_UNIT_EXECUTION_FLOW";

export const MASTERY_TEST_EXECUTION_FORM = "MASTERY_TEST_EXECUTION_FORM";
export const START_MASTERY_TEST_EXECUTION_FLOW = "START_MASTERY_TEST_EXECUTION_FLOW";
export const CLOSE_MASTERY_TEST_EXECUTION_FLOW = "CLOSE_MASTERY_TEST_EXECUTION_FLOW";
export const GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW = "GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW";
export const SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW";
export const END_MASTERY_TEST_EXECUTION_FLOW = "END_MASTERY_TEST_EXECUTION_FLOW";
export const START_MASTERY_TEST_TASTING_EXECUTION_FLOW = "START_MASTERY_TEST_TASTING_EXECUTION_FLOW";

export const PRACTICE_TEST_EXECUTION_FORM = "PRACTICE_TEST_EXECUTION_FORM";
export const START_PRACTICE_TEST_EXECUTION_FLOW = "START_PRACTICE_TEST_EXECUTION_FLOW";
export const CLOSE_PRACTICE_TEST_EXECUTION_FLOW = "CLOSE_PRACTICE_TEST_EXECUTION_FLOW";
export const GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW = "GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW";
export const SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW";
export const START_PRACTICE_TEST_ITEM_TOUR = "START_PRACTICE_TEST_ITEM_TOUR";
export const END_PRACTICE_TEST_ITEM_TOUR = "END_PRACTICE_TEST_ITEM_TOUR";

export const PLACEMENT_TEST_EXECUTION_FORM = "PLACEMENT_TEST_EXECUTION_FORM";
export const START_PLACEMENT_TEST_EXECUTION_FLOW = "START_PLACEMENT_TEST_EXECUTION_FLOW";
export const CLOSE_PLACEMENT_TEST_EXECUTION_FLOW = "CLOSE_PLACEMENT_TEST_EXECUTION_FLOW";
export const GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW = "GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW";
export const GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW = "GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW";
export const PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW = "PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW";
export const SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW";
export const END_PLACEMENT_TEST_EXECUTION_FLOW = "END_PLACEMENT_TEST_EXECUTION_FLOW";
export const START_PLACEMENT_TEST_TASTING_FLOW = "START_PLACEMENT_TEST_TASTING_FLOW";

export const BONUS_TEST_EXECUTION_FORM = "BONUS_TEST_EXECUTION_FORM";
export const START_BONUS_TEST_EXECUTION_FLOW = "START_BONUS_TEST_EXECUTION_FLOW";
export const GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW = "GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW";
export const GET_EXTRA_BONUS_TEST_ITEM_EXECUTION_FLOW = "GET_EXTRA_BONUS_TEST_ITEM_EXECUTION_FLOW";
export const SAVE_BONUS_TEST_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_BONUS_TEST_ITEM_EXECUTION_ANSWER_FLOW";
export const CLOSE_BONUS_TEST_EXECUTION_FLOW = "CLOSE_BONUS_TEST_EXECUTION_FLOW";
export const END_BONUS_TEST_EXECUTION_FLOW = "END_BONUS_TEST_EXECUTION_FLOW";

export const SET_MANUAL_COURSE = "SET_MANUAL_COURSE";

export const START_CERTIFICATION_TEST_EXECUTION_FLOW = "START_CERTIFICATION_TEST_EXECUTION_FLOW";
export const CERTIFICATION_TEST_ABILITY_EXECUTION_FORM = "CERTIFICATION_TEST_ABILITY_EXECUTION_FORM";
export const START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW = "START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW";
export const CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW = "CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW";
export const GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW =
  "GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW";
export const SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW =
  "SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW";
export const END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW = "END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW";
export const CHECK_CERTIFICATION_TEST_RESULT_FLOW = "CHECK_CERTIFICATION_TEST_RESULT_FLOW";
export const START_CERTIFICATION_TEST_TASTING_ABILITY_EXECUTION_FLOW =
  "START_CERTIFICATION_TEST_TASTING_ABILITY_EXECUTION_FLOW";

export const USER_AWAY_FLOW = "USER_AWAY_FLOW";
export const USER_AWAY_TIMEOUT_FLOW = "USER_AWAY_TIMEOUT_FLOW";
export const SHOW_USER_AWAY_MODAL = "SHOW_USER_AWAY_MODAL";
export const CLOSE_USER_AWAY_MODAL = "CLOSE_USER_AWAY_MODAL";
export const READING_TEXT_FLOW = "READING_TEXT_FLOW";

export const USER_LAST_ACTION_FLOW = "USER_LAST_ACTION_FLOW";

export const NEW_MESSAGE_CHANNEL_FORM = "NEW_MESSAGE_CHANNEL_FORM";
export const CREATE_NEW_MESSAGE_CHANNEL_FLOW = "CREATE_NEW_MESSAGE_CHANNEL_FLOW";
export const LOAD_MESSAGE_CHANNELS_FLOW = "LOAD_MESSAGE_CHANNELS_FLOW";
export const LOAD_MESSAGES_FLOW = "LOAD_MESSAGES_FLOW";
export const MESSAGE_FORM = "MESSAGE_FORM";
export const SEND_MESSAGE_FLOW = "SEND_MESSAGE_FLOW";

export const LOAD_NOTIFICATIONS_FLOW = "LOAD_NOTIFICATIONS_FLOW";
export const SET_NOTIFICATIONS_READ_FLOW = "SET_NOTIFICATIONS_READ_FLOW";

export const SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW = "SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW";
export const CLOSE_STUDY_QUALITY_DETAILS_MODAL_FLOW = "CLOSE_STUDY_QUALITY_DETAILS_MODAL_FLOW";

export const START_TASTING_UNIT_EXECUTION_FLOW = "START_TASTING_UNIT_EXECUTION_FLOW";
export const SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW = "SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW";
export const END_TASTING_UNIT_EXECUTION_FLOW = "END_TASTING_UNIT_EXECUTION_FLOW";

export const TASTING_CONTACT_FORM = "TASTING_CONTACT_FORM";
export const SAVE_TASTING_CONTACT_FLOW = "SAVE_TASTING_CONTACT_FLOW";
export const SHOW_TASTING_CONTACT_MODAL = "SHOW_TASTING_CONTACT_MODAL";
export const START_TASTING_TOUR_FLOW = "START_TASTING_TOUR_FLOW";
export const END_TASTING_TOUR_FLOW = "END_TASTING_TOUR_FLOW";

export const SHOW_VIDEO_INSTRUCTION_FLOW = "SHOW_VIDEO_INSTRUCTION_FLOW";

export const ENGLISH4ALL_DISTRIBUTOR_ID = "5e4d6ddab557a00015465782";

export const MONITOR_NETWORK_CONNECTION_FLOW = "MONITOR_NETWORK_CONNECTION_FLOW";

export const SELECT_ITEM_FLOW = "SELECT_ITEM_FLOW";

export const COMPANY_ALLOWED_TO_NEW_PLACEMENT = "62ea9c19ebd465edd813581a";
