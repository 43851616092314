import React, { Component } from "react";
import moment from "moment";
import "moment-duration-format";
import numeral from "numeral";
import get from "lodash/get";
import FetchData from "student-front-commons/src/components/FetchData";
import { getAllTimeStats } from "student-front-commons/src/services/studentStatsService";
import Async from "../../core/Async";
import StudentSummary from "./StudentSummary";
import HoursIcon from "../../images/student-details-hours-icon.png";
import PointsIcon from "../../images/student-details-points-icon.png";
import withTranslation from "../../core/withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_3, GREY_5, WHITE } from "../../consts/color";

class StudentSummaryContainer extends Component {
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ secondaryColor }) => (
          <FetchData
            service={getAllTimeStats}
            params={{
              id: sessionStorage.getItem("id"),
            }}
          >
            {({ isFetching, data }) => (
              <Async fetching={isFetching} size="sm" color="dark">
                <div
                  style={{
                    height: " 50%",
                  }}
                >
                  <StudentSummary
                    backgroundColor={secondaryColor}
                    labelColor={WHITE}
                    label={this.props.getTranslation("points")}
                    iconSrc={PointsIcon}
                    value={numeral(get(data, "points", 0)).format("0,0").replace(",", ".")}
                  />
                </div>
                <div
                  style={{
                    height: " 50%",
                  }}
                >
                  <StudentSummary
                    backgroundColor={GREY_3}
                    labelColor={GREY_5}
                    label={this.props.getTranslation("hours")}
                    value={moment.duration(get(data, "hours", 0), "hours").format("hh:mm", { trim: false })}
                    iconSrc={HoursIcon}
                  />
                </div>
              </Async>
            )}
          </FetchData>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

// studentStats: !pageAccessed ? state.fakeData.student.stats : state.student.stats || {},
export default withTranslation(StudentSummaryContainer);
