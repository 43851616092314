import React from "react";
import PropTypes from "prop-types";
import Entity from "student-front-commons/src/components/Entity";
import TestStepItem from "./TestStepItem";
import TestStep from "./TestStep";

const TestStepContainer = (props) => (
  <Entity name="profile">
    {({ getById }) => (
      <Entity name="company">
        {({ getById: getCompanyById }) => (
          <Entity name="schoolClass">
            {({ getById: getSchoolClassById }) => (
              <Entity name="school">
                {({ getById: getSchoolById }) => {
                  const profile = getById(sessionStorage.getItem("id"));
                  const schoolClass = getSchoolClassById(profile.schoolClass);
                  const school = getSchoolById(schoolClass?.school);
                  const company = getCompanyById(profile.company);

                  const allowLevelSelection =
                    profile.apiVersion === "V2"
                      ? !profile.isPlacementTestOnly && company.allowLevelSelection
                      : !schoolClass.isPlacementTestClass && school.allowLevelSelection;
                  const totalSteps = 4 - (allowLevelSelection ? 0 : 1) - (schoolClass.isPlacementTestClass ? 1 : 0);

                  return (
                    <TestStep
                      completedWidth={((props.currentStep - (allowLevelSelection ? 0 : 1) - 1) / totalSteps) * 100}
                    >
                      {allowLevelSelection && (
                        <TestStepItem
                          label="placementTest.levelSelect"
                          current={props.currentStep === 1}
                          completed={props.currentStep > 1}
                        />
                      )}
                      <TestStepItem
                        label="placementTest.introduction"
                        current={props.currentStep === 2}
                        completed={props.currentStep > 2}
                      />
                      <TestStepItem
                        label="placementTest.warming"
                        current={props.currentStep === 3}
                        completed={props.currentStep >= 3}
                      />
                      <TestStepItem
                        label="placementTest.placement"
                        current={props.currentStep === 4}
                        completed={props.currentStep >= 4}
                      />
                      {!schoolClass.isPlacementTestClass && (
                        <TestStepItem
                          label="placementTest.profile"
                          current={props.currentStep === 5}
                          completed={props.currentStep > 5}
                        />
                      )}
                    </TestStep>
                  );
                }}
              </Entity>
            )}
          </Entity>
        )}
      </Entity>
    )}
  </Entity>
);

TestStepContainer.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default TestStepContainer;
