import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { updateProfilePicture } from "student-front-commons/src/services/profileService";
import { UPDATE_PROFILE_PICTURE_FLOW, UPDATE_PROFILE_PICTURE_FORM } from "../consts";
import { logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

export default function* () {
  yield takeLatest(getFlowStart(UPDATE_PROFILE_PICTURE_FLOW), function* () {
    const flow = yield select(getFlow(UPDATE_PROFILE_PICTURE_FLOW));
    try {
      const profileForm = yield select(getForm(UPDATE_PROFILE_PICTURE_FORM));

      const data = {
        image: profileForm.values.image,
      };

      const result = yield call(updateProfilePicture, data);
      yield put(mergeEntities(result.entities));

      yield put(initForm(UPDATE_PROFILE_PICTURE_FORM, result.entities.profile[profileForm.values.id]));

      if (flow.params.showFeedback) {
        yield put(
          showMessage({
            message: "profile.account.success.picture",
            type: "success",
          })
        );
      }
    } catch (error) {
      logError({ error, flow: UPDATE_PROFILE_PICTURE_FLOW });
      if (flow.params.showFeedback) {
        yield put(
          showMessage({
            message: "profile.account.error.picture",
            type: "error",
          })
        );
      }
    } finally {
      yield put(endFlow(UPDATE_PROFILE_PICTURE_FLOW));
    }
  });
}
