import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import Separator from "../../../core/Separator";
import CountDownTimer from "../../../core/CountDownTimer";
import Modal from "../../../core/Modal";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const PlacementTestPauseModal = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <Modal>
        <TranslatedText
          as="h1"
          translateKey="placementTest.pauseTitle"
          style={{
            color: primaryColor,
            fontSize: 30,
            fontWeight: 400,
            lineHeight: "24px",
            margin: 0,
          }}
        />
        <Separator size="sm" />
        <Separator size="md" />
        <p
          style={{
            opacity: 0.8,
            color: "#424242",
            lineHeight: "30px",
            margin: 0,
          }}
        >
          <TranslatedText
            as="b"
            translateKey="placementTest.pauseDescription1"
            values={{
              level: numeral(props.placementLevel.level).format("0.0"),
              course: props.placementCourses.find((pc) => pc.level === props.placementLevel.level).course.name,
            }}
            style={{ fontSize: 20 }}
          />
          <br />
          <TranslatedText as="span" translateKey="placementTest.pauseDescription2" />
        </p>
        <Separator size="lg" />
        <div
          style={{
            width: 260,
            margin: "0 auto",
          }}
        >
          <CountDownTimer time={props.pauseTime} isDisabled={false} onTimerEnd={() => false} />
        </div>
      </Modal>
    )}
  </WhitelabelContext.Consumer>
);

PlacementTestPauseModal.propTypes = {
  pauseTime: PropTypes.number.isRequired,
  placementCourses: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.number,
    })
  ).isRequired,
  placementLevel: PropTypes.shape({
    level: PropTypes.number,
    course: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default PlacementTestPauseModal;
