import moment from "moment";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { addEntity } from "student-front-commons/src/actions/entity";
import { endFlow } from "student-front-commons/src/actions/flow";
import { deleteForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { setMasteryTestExecutionAsFailed } from "student-front-commons/src/services/masteryTestExecutionService";
import { clearAudios } from "../stores/audio-store";
import { clearImages } from "../stores/image-store";
import { logError } from "../util";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW, ITEM_EXECUTION_FORM, MASTERY_TEST_EXECUTION_FORM } from "../consts";

export default function* () {
  yield takeLatest(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW), function* () {
    try {
      const masteryTestExecutionForm = yield select(getForm(MASTERY_TEST_EXECUTION_FORM));
      if (masteryTestExecutionForm && masteryTestExecutionForm.values) {
        yield call(setMasteryTestExecutionAsFailed, {
          module: masteryTestExecutionForm.values.module,
          masteryTest: masteryTestExecutionForm.values.masteryTest,
          masteryTestExecution: masteryTestExecutionForm.values.execution,
        });

        const masteryTest = yield select(getEntityById("masteryTest", masteryTestExecutionForm.values.masteryTest));
        masteryTest.failedAt = moment().format();
        yield put(addEntity("masteryTest", masteryTest));
      }

      clearAudios();
      clearImages();

      yield put(deleteForm(MASTERY_TEST_EXECUTION_FORM));
      yield put(deleteForm(ITEM_EXECUTION_FORM));
    } catch (error) {
      logError({ error, flow: CLOSE_MASTERY_TEST_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(CLOSE_MASTERY_TEST_EXECUTION_FLOW));
    }
  });
}
