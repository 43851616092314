import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { updateProfile } from "student-front-commons/src/services/profileService";
import { UPDATE_PROFILE_FLOW, UPDATE_PROFILE_FORM } from "../consts";
import { logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

export default function* () {
  yield takeLatest(getFlowStart(UPDATE_PROFILE_FLOW), function* () {
    try {
      const profileForm = yield select(getForm(UPDATE_PROFILE_FORM));

      const data = {
        role: "STUDENT",
        name: profileForm.values.name,
        nickname: profileForm.values.nickname,
        email: profileForm.values.email,
        rankingParticipant: profileForm.values.rankingParticipant === "S",
        contactPhone: profileForm.values.contactPhone || null,
        observation: profileForm.values.observation || null,
        ...(!!profileForm.values.password && {
          password: profileForm.values.password,
        }),
        ...(!!profileForm.values.newPassword && {
          newPassword: profileForm.values.newPassword,
        }),
      };

      const result = yield call(updateProfile, data);
      yield put(mergeEntities(result.entities));

      yield put(
        showMessage({
          message: "profile.account.success.profile",
          type: "success",
        })
      );
    } catch (error) {
      let message = "profile.account.error.general";
      if (error.code === "invalid_password") {
        message = "profile.account.error.password";
      } else if (error.message.indexOf("E11000") > -1) {
        message = "profile.account.error.email";
      } else {
        logError({ error, flow: UPDATE_PROFILE_FLOW });
      }
      yield put(
        showMessage({
          message: message,
          type: "error",
        })
      );
    } finally {
      yield put(endFlow(UPDATE_PROFILE_FLOW));
    }
  });
}
