import axios from "axios";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { CREATE_ACCOUNT_FLOW, CREATE_ACCOUNT_FORM } from "../consts";
import { logError } from "../util";
import { detect } from "detect-browser";
import systemMessage from "student-front-commons/src/reducers/systemMessage";
import { hideMessage } from "student-front-commons/src/actions/systemMessage";

export default function* () {
  yield takeLatest(getFlowStart(CREATE_ACCOUNT_FLOW), function* () {
    try {
      yield put(hideMessage());

      const createAccountFlow = yield select(getFlow(CREATE_ACCOUNT_FLOW));
      const createAccountForm = yield select(getForm(CREATE_ACCOUNT_FORM));

      const deviceInfo = detect();

      const response = yield call(axios, {
        method: "put",
        url: process.env.REACT_APP_API_URL.replace("/v1", "/public")
          .concat("/schools/")
          .concat(createAccountFlow.params.schoolId)
          .concat("/students/")
          .concat(createAccountForm.values.id)
          .concat("/enable"),
        data: {
          name: createAccountForm.values.name,
          email: createAccountForm.values.email,
          password: createAccountForm.values.password,
          browser: `${deviceInfo.name}_${deviceInfo.version}`,
          os: deviceInfo.os,
        },
      });

      if (response.data.type === "require-payment") {
        yield put(
          systemMessage({
            message: "createAccount.success.payment",
            type: "success",
          })
        );
        yield delay(5000);
        window.location.href = response.data.link;
      } else {
        yield put(
          systemMessage({
            message: "createAccount.success.general",
            type: "success",
          })
        );

        const result = response.data;
        sessionStorage.setItem("id", result.id);
        sessionStorage.setItem("accessToken", result.accessToken);
        if (result.contractCode) {
          sessionStorage.setItem("contractCode", result.contractCode);
        }

        yield delay(5000);

        window.location.href = "/";
      }
    } catch (error) {
      let message = "createAccount.error.general";
      if (error.response.data.message.indexOf("E11000") > -1) {
        message = "createAccount.error.email";
      } else if (error.response.data.error === "limit_reached") {
        message = "createAccount.error.limit_reached";
      } else {
        logError({ error, flow: CREATE_ACCOUNT_FLOW });
      }

      yield put(
        systemMessage({
          message: message,
          type: "error",
        })
      );
    } finally {
      yield put(endFlow(CREATE_ACCOUNT_FLOW));
    }
  });
}
