import React from "react";
import PropTypes from "prop-types";

const PresentationIcon = ({ color, style }) => (
  <svg width="148" height="145" viewBox="0 0 148 145" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M89.0216 53.6848C95.6765 53.6848 101.09 48.2718 101.09 41.6169C101.09 34.9619 95.6765 29.5255 89.0216 29.5255C82.3666 29.5255 76.9536 34.9385 76.9536 41.5934C76.9536 48.2484 82.3666 53.6848 89.0216 53.6848ZM89.0216 34.2121C93.0989 34.2121 96.4029 37.5161 96.4029 41.5934C96.4029 45.6708 93.0989 48.9748 89.0216 48.9748C84.9442 48.9748 81.6402 45.6708 81.6402 41.5934C81.6402 37.5161 84.9442 34.2121 89.0216 34.2121Z"
      fill={color}
    />
    <path
      d="M89.0919 104.534C89.0216 104.394 88.9513 104.253 88.881 104.136C88.7873 104.019 88.6935 103.878 88.5998 103.784C88.5061 103.691 88.3655 103.574 88.2483 103.503C88.1311 103.41 87.9905 103.339 87.85 103.292C87.7094 103.222 87.5688 103.175 87.4047 103.152C87.1001 103.081 86.7955 103.081 86.4908 103.152C86.3502 103.175 86.1862 103.222 86.0456 103.292C85.905 103.363 85.7644 103.433 85.6473 103.503C85.5301 103.597 85.3895 103.691 85.2958 103.784C85.202 103.878 85.0849 104.019 85.0146 104.136C84.9208 104.253 84.8505 104.394 84.8037 104.534C84.7334 104.675 84.6865 104.815 84.6631 104.98C84.6396 105.12 84.6162 105.284 84.6162 105.448C84.6162 105.589 84.6396 105.753 84.6631 105.917C84.6865 106.057 84.7334 106.221 84.8037 106.362C84.874 106.503 84.9443 106.643 85.0146 106.76C85.1083 106.878 85.202 107.018 85.2958 107.112C85.3426 107.159 85.4129 107.206 85.4598 107.276C85.5301 107.323 85.577 107.37 85.6473 107.417C85.7644 107.51 85.905 107.581 86.0456 107.627C86.1862 107.698 86.3268 107.745 86.4908 107.768C86.6314 107.791 86.7955 107.815 86.9595 107.815C87.1001 107.815 87.2641 107.791 87.4282 107.768C87.5688 107.745 87.7328 107.698 87.8734 107.627C88.014 107.557 88.1546 107.487 88.2717 107.417C88.342 107.37 88.3889 107.323 88.4592 107.276C88.5295 107.229 88.5764 107.182 88.6232 107.112C88.7404 106.995 88.8341 106.878 88.9044 106.76C88.9982 106.643 89.0685 106.503 89.1153 106.362C89.1856 106.221 89.2325 106.081 89.2559 105.917C89.2794 105.776 89.3028 105.612 89.3028 105.448C89.3028 105.308 89.2794 105.144 89.2559 104.98C89.2091 104.839 89.1622 104.675 89.0919 104.534Z"
      fill={color}
    />
    <path
      d="M47.8969 13.6145C47.9672 13.7551 48.0375 13.8957 48.1078 14.0129C48.2016 14.13 48.2953 14.2706 48.4125 14.3644C48.5296 14.4581 48.6468 14.5753 48.764 14.6456C48.8811 14.7393 49.0217 14.8096 49.1623 14.8565C49.3029 14.9268 49.4435 14.9502 49.6075 14.9971C49.7481 15.0205 49.9122 15.0439 50.0528 15.0439C50.1934 15.0439 50.3574 15.0205 50.498 14.9971C50.6386 14.9736 50.8026 14.9268 50.9432 14.8565C51.0838 14.7862 51.2244 14.7159 51.3416 14.6456C51.4822 14.5518 51.5993 14.4581 51.6931 14.3644C51.7868 14.2706 51.904 14.13 51.9743 14.0129C52.068 13.8723 52.1383 13.7551 52.1852 13.6145C52.232 13.4739 52.2789 13.3333 52.3258 13.1693C52.3492 13.0053 52.3726 12.8647 52.3726 12.7006C52.3726 12.5366 52.3492 12.396 52.3258 12.232C52.3023 12.0914 52.2555 11.9273 52.1852 11.7868C52.1149 11.6462 52.0446 11.5056 51.9743 11.3884C51.8805 11.2712 51.7868 11.1306 51.6931 11.0369C51.5993 10.9432 51.4587 10.826 51.3416 10.7557C51.2244 10.662 51.0838 10.5917 50.9432 10.5448C50.8026 10.4745 50.662 10.4511 50.498 10.4042C50.1934 10.3339 49.8887 10.3339 49.5841 10.4042C49.4435 10.4276 49.2795 10.4745 49.1389 10.5448C48.9983 10.6151 48.8577 10.6854 48.7405 10.7557C48.5999 10.8494 48.4828 10.9432 48.389 11.0369C48.2953 11.1306 48.1781 11.2712 48.0844 11.3884C47.9907 11.5056 47.9204 11.6462 47.8735 11.7868C47.8266 11.9273 47.7798 12.0679 47.7329 12.232C47.7095 12.396 47.686 12.5366 47.686 12.7006C47.686 12.8647 47.7095 13.0053 47.7329 13.1693C47.7798 13.3099 47.8266 13.4739 47.8969 13.6145Z"
      fill={color}
    />
    <path
      d="M106.878 10.3808C105.472 4.45226 100.129 0 93.7551 0H20.246C9.09198 0 0 9.09198 0 20.246V93.7786C0 101.09 5.8348 107.042 13.099 107.252C14.505 113.181 19.8477 117.633 26.2214 117.633H106.526C113.954 117.633 120 111.588 120 104.159V86.4675V23.8313C119.977 16.5436 114.142 10.5682 106.878 10.3808ZM115.29 104.136C115.29 108.987 111.353 112.923 106.503 112.923H26.198C22.4487 112.923 19.2384 110.557 17.9731 107.252C17.5981 106.292 17.4107 105.237 17.4107 104.136V102.566V91.7165L44.2882 46.116C45.1084 44.7335 46.5612 43.9133 48.1781 43.9367C49.795 43.9602 51.2009 44.8272 51.9742 46.2332L63.5501 67.4868L80.3749 98.3948C80.7967 99.1681 81.5934 99.6134 82.437 99.6134C82.812 99.6134 83.2103 99.5196 83.5618 99.3322C84.71 98.7229 85.1084 97.2935 84.4991 96.1453L79.0392 86.116C81.4294 86.2332 83.7961 85.4599 85.6708 83.8899L96.2156 75.1494C97.9028 73.7434 100.41 73.8137 102.027 75.29L102.566 75.8055L107.252 80.0937L115.29 87.4751V104.136ZM115.29 81.1248L107.252 73.7434L102.566 70.1816C99.5196 68.8928 95.8875 69.338 93.2162 71.5641L82.6714 80.3046C81.5231 81.2654 80 81.6637 78.5237 81.3591C77.0475 81.0779 75.7821 80.164 75.0556 78.8284L67.6509 65.2607L63.5267 57.6684L56.075 44.007C54.4815 41.1013 51.5524 39.3204 48.225 39.2501C44.8975 39.2033 41.9215 40.867 40.2343 43.7258L17.4107 82.4839V23.8313C17.4107 18.9807 21.3474 15.0439 26.198 15.0439H40.6796C41.9684 15.0439 43.0229 13.9895 43.0229 12.7006C43.0229 11.4118 41.9684 10.3574 40.6796 10.3574H26.198C18.7698 10.3574 12.7241 16.403 12.7241 23.8313V91.0603V102.519C8.22496 102.144 4.68658 98.3714 4.68658 93.7786V20.246C4.68658 11.6696 11.6696 4.68658 20.246 4.68658H93.7786C97.5278 4.68658 100.738 7.05331 102.004 10.3574H59.4259C58.1371 10.3574 57.0826 11.4118 57.0826 12.7006C57.0826 13.9895 58.1371 15.0439 59.4259 15.0439H102.566L107.252 15.0674C111.752 15.4657 115.29 19.2384 115.29 23.8313V81.1248Z"
      fill={color}
    />
    <circle cx="105" cy="102" r="40.5" fill="white" stroke={color} strokeWidth="5" />
    <path
      d="M105 113.185C110.639 113.185 115.217 108.606 115.217 102.967V84.2174C115.217 78.5788 110.639 74 105 74C99.3615 74 94.7827 78.5788 94.7827 84.2174V102.967C94.7827 108.606 99.3615 113.185 105 113.185ZM105 76.1739C109.429 76.1739 113.044 79.788 113.044 84.2174V93.1984H96.9566V84.2174C96.9566 79.788 100.571 76.1739 105 76.1739ZM96.9566 95.3723H113.044V102.967C113.044 107.397 109.429 111.011 105 111.011C100.571 111.011 96.9566 107.397 96.9566 102.967V95.3723Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M102.351 88.2799H107.636C108.234 88.2799 108.723 87.7908 108.723 87.1929C108.723 86.5951 108.234 86.106 107.636 86.106H102.351C101.753 86.106 101.264 86.5951 101.264 87.1929C101.264 87.7908 101.753 88.2799 102.351 88.2799Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M102.351 84.2174H107.636C108.234 84.2174 108.723 83.7283 108.723 83.1304C108.723 82.5326 108.234 82.0435 107.636 82.0435H102.351C101.753 82.0435 101.264 82.5326 101.264 83.1304C101.264 83.7283 101.753 84.2174 102.351 84.2174Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M118.913 101.88C118.315 101.88 117.826 102.37 117.826 102.967C117.826 110.046 112.065 115.793 105 115.793C97.9212 115.793 92.1739 110.033 92.1739 102.967C92.1739 102.37 91.6848 101.88 91.087 101.88C90.4891 101.88 90 102.37 90 102.967C90 110.141 95.0543 116.147 101.793 117.628V123.117H101.712C98.7092 123.117 96.2636 125.562 96.2636 128.565C96.2636 129.163 96.7527 129.652 97.3505 129.652H112.636C113.234 129.652 113.723 129.163 113.723 128.565C113.723 125.562 111.277 123.117 108.288 123.117H108.207V117.628C114.946 116.16 120 110.141 120 102.967C120 102.37 119.511 101.88 118.913 101.88ZM111.372 127.478H98.6277C99.0761 126.215 100.285 125.291 101.712 125.291H108.288C109.715 125.304 110.924 126.215 111.372 127.478ZM106.033 123.13H103.967V117.94C104.307 117.967 104.647 117.981 105 117.981C105.353 117.981 105.693 117.967 106.033 117.94V123.13Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M105 108.62C108.111 108.62 110.652 106.079 110.652 102.967C110.652 102.37 110.163 101.88 109.565 101.88C108.967 101.88 108.478 102.37 108.478 102.967C108.478 104.883 106.916 106.446 105 106.446C104.402 106.446 103.913 106.935 103.913 107.533C103.913 108.13 104.402 108.62 105 108.62Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M109.565 100.304C109.85 100.304 110.136 100.182 110.339 99.9918C110.543 99.788 110.652 99.5027 110.652 99.2174C110.652 98.9321 110.53 98.6467 110.339 98.4429C110.136 98.2391 109.85 98.1304 109.565 98.1304C109.28 98.1304 108.994 98.2527 108.791 98.4429C108.587 98.6467 108.478 98.9321 108.478 99.2174C108.478 99.5027 108.6 99.788 108.791 99.9918C108.994 100.182 109.28 100.304 109.565 100.304Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

PresentationIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default PresentationIcon;
