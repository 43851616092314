import React, { useCallback, useEffect, useState } from "react";
import Modal from "../../../core/Modal";
import ButtonV2 from "../../../core/ButtonV2";
import TranslatedText from "../../../core/TranslatedText";

const UserDemoOnboardingModal = () => {
  const [visible, setVisible] = useState(true);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    setVisible(!!sessionStorage.getItem("onboardingUserDemo"));
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <Modal>
      <TranslatedText translateKey="placementTest.userDemoOnboarding.message" />
      <ButtonV2
        onClick={handleClose}
        labelKey="placementTest.userDemoOnboarding.button"
        intent="course-primary"
        size="md"
      />
    </Modal>
  );
};

export default UserDemoOnboardingModal;
