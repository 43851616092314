import React from "react";
import PropTypes from "prop-types";

const VideoComprehensionIcon = ({ color, style }) => (
  <svg width="147" height="90" viewBox="0 0 147 90" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <rect x="4" y="3" width="141" height="84" fill="white" />
    <path
      d="M69.5 73.5C63.9621 73.5 58.5486 71.8285 53.944 68.6969C49.3395 65.5653 45.7506 61.1142 43.6314 55.9065C41.5121 50.6988 40.9576 44.9684 42.038 39.4399C43.1184 33.9115 45.7852 28.8333 49.701 24.8475C53.6169 20.8617 58.606 18.1473 64.0375 17.0476C69.4689 15.948 75.0988 16.5123 80.2151 18.6694C85.3315 20.8265 89.7045 24.4795 92.7812 29.1663C95.8578 33.8531 97.5 39.3632 97.5 45C97.5 52.5587 94.55 59.8078 89.299 65.1526C84.048 70.4973 76.9261 73.5 69.5 73.5Z"
      fill={`${color}1A`}
      stroke={color}
      strokeWidth="3"
      strokeMiterlimit="10"
    />
    <path
      d="M141.9 0.887634L23.1755 1.76404C22.2284 1.76404 21.4998 2.56741 21.4998 3.44382C21.4998 4.39326 22.3012 5.12359 23.1755 5.12359L141.9 4.24718C142.847 4.24718 143.576 5.05056 143.576 5.92696L143.576 83.927C143.576 84.8764 142.774 85.6068 141.9 85.6068L6.0541 86.4832C5.10696 86.4832 4.37839 85.6798 4.37839 84.8034V6.80337C4.37839 5.85393 5.17982 5.12359 6.0541 5.12359H9.47839C10.4255 5.12359 11.1541 4.32022 11.1541 3.44382C11.1541 2.49438 10.3527 1.76404 9.47839 1.76404H6.0541C3.21267 1.76404 0.954102 4.10112 0.954102 6.8764V84.8764C0.954102 87.7247 3.28553 89.9888 6.0541 89.9888L141.9 89.1124C144.741 89.1124 147 86.7753 147 84L147 5.99999C147 3.22471 144.669 0.887634 141.9 0.887634Z"
      fill={color}
    />
    <path
      d="M17 1C15.9231 1 15 1.92308 15 3C15 4.07692 15.9231 5 17 5C18.0769 5 19 4.07692 19 3C19 1.84615 18.1538 1 17 1Z"
      fill={color}
    />
    <path
      d="M65 37.9395V52.1387C65.0012 52.4848 65.1034 52.8238 65.2953 53.1178C65.4871 53.4118 65.761 53.6493 66.0864 53.8038C66.4117 53.9582 66.7758 54.0235 67.1379 53.9925C67.5001 53.9614 67.846 53.8351 68.1371 53.6277L79.1212 46.5364C79.3921 46.3595 79.6137 46.1226 79.7669 45.8459C79.92 45.5693 80 45.2613 80 44.9486C80 44.6359 79.92 44.3279 79.7669 44.0513C79.6137 43.7747 79.3921 43.5377 79.1212 43.3609L68.1371 36.2613C67.8223 36.0909 67.4665 36.0009 67.1044 36C66.7422 35.9991 66.386 36.0875 66.0703 36.2563C65.7545 36.4252 65.4901 36.669 65.3025 36.9639C65.115 37.2588 65.0108 37.5949 65 37.9395Z"
      fill="white"
    />
    <path
      d="M63 52.1939V37.8061C62.9977 37.1247 63.1775 36.4553 63.5206 35.8682C63.8637 35.281 64.3574 34.7979 64.9498 34.4695C65.5422 34.1411 66.2115 33.9796 66.8873 34.0021C67.563 34.0245 68.2203 34.2299 68.7901 34.5968L79.3114 41.7824C79.8317 42.1394 80.2576 42.6192 80.5519 43.18C80.8462 43.7408 81 44.3656 81 45C81 45.6344 80.8462 46.2592 80.5519 46.82C80.2576 47.3808 79.8317 47.8606 79.3114 48.2177L68.7901 55.4032C68.2203 55.7701 67.563 55.9755 66.8873 55.9979C66.2115 56.0204 65.5422 55.8589 64.9498 55.5305C64.3574 55.2021 63.8637 54.719 63.5206 54.1318C63.1775 53.5447 62.9977 52.8753 63 52.1939ZM66.7553 37.8061V45V52.1939L77.2684 45L66.7553 37.8061Z"
      fill={color}
    />
  </svg>
);

VideoComprehensionIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default VideoComprehensionIcon;
