import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * find the current student position in each level based on period
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getCurrentPositions = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/current-positions`,
        method: "get",
      });
    },
    "error_get_student_current_positions",
    "Unexpected error to get current positions."
  );
};

/**
 * find the current student position in each level based on period
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getPositionsByPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/positions`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_student_positions",
    "Unexpected error to get positions."
  );
};

/**
 * find the student position history in each level based on period
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getPositionsHistoryByPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/positions-history`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_student_positions_history",
    "Unexpected error to load the position history."
  );
};
