import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import UserAvatar from "../../core/UserAvatar";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const StudentInformation = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
      >
        <TranslatedText
          as={Link}
          translateKey="profile.updateProfileLink"
          to="/account"
          style={{
            position: "absolute",
            right: -20,
            top: -15,
            borderBottom: `1px solid ${secondaryColor}`,
            color: secondaryColor,
            fontSize: 12,
            textDecoration: "none",
          }}
        />
        <MediaQuery minWidth={1280}>
          {(matches) => <UserAvatar size={matches ? "lg" : "md"} src={props.profilePicture} />}
        </MediaQuery>
        <div
          style={{
            marginLeft: 20,
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: 22,
              color: "#455a64",
              maxWidth: 150,
              overflow: "hidden",
              textTransform: "capitalize",
            }}
          >
            {props.name}
          </h1>
          <Separator size="sm" />
          <label
            htmlFor="school"
            style={{
              margin: 0,
              fontSize: 13,
              color: "rgba(69, 90, 100, 0.8)",
              textTransform: "capitalize",
            }}
          >
            {props.school}
          </label>
        </div>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

StudentInformation.propTypes = {
  name: PropTypes.string.isRequired,
  school: PropTypes.string.isRequired,
  profilePicture: PropTypes.string,
};

StudentInformation.defaultProps = {
  profilePicture: null,
};

export default StudentInformation;
