import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import ItemImage from "./ItemImage";
import CourseThemeContext from "../CourseThemeContext";
import { WHITE } from "../../consts/color";
import ExerciseItemPanel from "./ExerciseItemPanel";
import HighlightableItemBox from "./HighlightableItemBox";
import FlexColumn from "../FlexColumn";
import Separator from "../Separator";

const VocabularySpeechlessExerciseRender = (props) => {
  const courseThemeContext = useContext(CourseThemeContext);

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox flexGrow={1}>
        <ItemImage
          itemId={props.itemId}
          style={{
            width: "100%",
          }}
        />
      </HighlightableItemBox>
      <FlexColumn
        width="100%"
        height={7}
        alignItems="center"
        justifyContent="center"
        position="relative"
        backgroundColor={courseThemeContext.primary}
      >
        <div
          style={{
            backgroundColor: courseThemeContext.primary,
            color: WHITE,
            padding: "0px 15px",
            borderRadius: 15,
            fontSize: "19px",
            position: "absolute",
          }}
        >
          {itemText}
        </div>
      </FlexColumn>
      <Separator size="md" />
    </ExerciseItemPanel>
  );
};

VocabularySpeechlessExerciseItem.propTypes = {};

export default function VocabularySpeechlessExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <VocabularySpeechlessExerciseRender key={id} itemId={id} />);
}
