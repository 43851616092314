import React from "react";
import PropTypes from "prop-types";
import PositionLabel from "./PositionLabel";
import ColumnSeparator from "./ColumnSeparator";
import GoldPin from "../images/school-first-position.png";
import SilverPin from "../images/school-second-position.png";
import BronzePin from "../images/school-thirdy-position.png";

const PositionIcon = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    {props.position <= 3 && (
      <img
        alt="icon"
        src={
          {
            1: GoldPin,
            2: SilverPin,
            3: BronzePin,
          }[props.position]
        }
        style={{
          width: "auto",
          height: 40,
          objectFit: "scale-down",
        }}
      />
    )}
    {props.position <= 10 && <ColumnSeparator />}
    <PositionLabel position={props.position} size="lg" color={props.color} bold />
  </div>
);

PositionIcon.propTypes = {
  position: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default PositionIcon;
