import React, { useState } from "react";
import PropTypes from "prop-types";
import CircularLoading from "../CircularLoading";
import DefaultImage from "../../images/no-image-single-choice.jpg";
import { getImage } from "../../stores/image-store";

export default function AnswerImage({ itemId, answerId, style }) {
  const [loading, setLoading] = useState(true);
  const [reloadCount, setReloadCount] = useState(0);

  const handleLoad = () => setLoading(false);

  const handleError = () => {
    if (reloadCount === 3) {
      return;
    }
    setReloadCount(reloadCount + 1);
  };

  return (
    <div
      style={{
        width: "100%",
        height: 175,
      }}
    >
      {loading && (
        <div
          style={{
            margin: "60px auto",
          }}
        >
          <CircularLoading
            size="sm"
            rgb={{
              r: 175,
              g: 175,
              b: 175,
            }}
          />
        </div>
      )}
      <img
        alt={`for-${answerId}`}
        src={
          reloadCount >= 3
            ? DefaultImage
            : getImage(`items/${itemId}/images/${answerId}.png`) ||
              `${process.env.REACT_APP_FILES_URL}/items/${itemId}/images/${answerId}.png?${reloadCount}`
        }
        onLoad={handleLoad}
        onError={handleError}
        style={{
          ...style,
          transition: "opacity .5s",
          opacity: loading ? 0 : 1,
          position: loading ? "absolute" : "relative",
        }}
      />
    </div>
  );
}

AnswerImage.propTypes = {
  itemId: PropTypes.string.isRequired,
  answerId: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

AnswerImage.defaultProps = {
  style: {},
};
