import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Tag } from "antd";
import moment from "moment";
import Styled from "styled-components";
import UserAvatar from "../UserAvatar";
import ColumnSeparator from "../ColumnSeparator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const ListItem = Styled.li`
  padding: 15px 30px;
  background-color: ${(props) => (!!props.readtAt ? "#fff" : props.unReadColor)};
  display: flex;
  align-items: center;
  cursor: pointer;
  
  &:hover {
    background-color: #E7ECEE;
  }
`;

const MessageChannelListItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, lightColor }) => (
      <ListItem
        unReadColor={lightColor}
        readtAt={props.channel.isRead}
        onClick={(event) => {
          event.preventDefault();
          props.history.replace({
            pathname: props.history.location.pathname,
            state: {
              messagePopoverScene: "message-list",
              messageChannel: props.channel.id,
            },
          });
        }}
      >
        <UserAvatar size="md" src={props.channel.chattingWith.profilePicture} />
        <ColumnSeparator size="xs" />
        <div
          style={{
            display: "inline-block",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <span
                style={{
                  fontSize: 14,
                  color: "rgba(84, 110, 122, 0.6)",
                  marginRight: 10,
                }}
              >
                {props.channel.chattingWith.name || props.channel.chattingWith.email}
              </span>
              {props.channel.chattingWith.role === "ADMIN" && <Tag color={primaryColor}>Admin</Tag>}
            </div>
            <span
              style={{
                fontSize: 12,
                color: "rgba(84, 110, 122, 0.5)",
              }}
            >
              {moment(props.channel.lastMessageSentAt).fromNow()}
            </span>
          </div>
          <h4
            style={{
              margin: 0,
              fontSize: 14,
              fontWeight: "bold",
              color: "#546e7a",
              marginTop: 5,
            }}
          >
            {props.channel.subject}
          </h4>
        </div>
      </ListItem>
    )}
  </WhitelabelContext.Consumer>
);

MessageChannelListItem.propTypes = {
  channel: PropTypes.shape({
    id: PropTypes.string,
    subject: PropTypes.string,
    chattingWith: PropTypes.shape({
      id: PropTypes.string,
      profilePicture: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(MessageChannelListItem);
