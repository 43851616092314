import React from "react";
import { useSelector } from "react-redux";
import Async from "../../../core/Async";
import TestIntroductionScene from "./TestIntroductionScene";
import { LOAD_CONFIGURATION_FLOW } from "../../../consts";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { getInstructionVideo } from "student-front-commons/src/selectors/configuration";

const TestIntroductionSceneContainer = () => {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const instructionVideo = useSelector(getInstructionVideo("PLACEMENT"));

  const [isConfigPending] = useFlow(LOAD_CONFIGURATION_FLOW);

  return (
    <Async fetching={isConfigPending}>
      <TestIntroductionScene profile={profile} videoUrl={instructionVideo?.video} />
    </Async>
  );
};

export default TestIntroductionSceneContainer;
