import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../../../core/Async";
import CertificationTestExecutionScene from "../CertificationTestExecutionScene";
import { START_CERTIFICATION_TEST_TASTING_ABILITY_EXECUTION_FLOW } from "../../../consts";
import queryString from "query-string";

class CertificationTestTastingExecutionSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={true}
        id={START_CERTIFICATION_TEST_TASTING_ABILITY_EXECUTION_FLOW}
        params={{
          ability: this.props.match.params.ability,
          certificationTest: this.props.match.params.idCertificationTest,
          ...queryString.parse(this.props.location.search),
        }}
      >
        {({ isPending }) => (
          <Async fetching={isPending}>
            <CertificationTestExecutionScene />
          </Async>
        )}
      </Flow>
    );
  }
}

export default withRouter(CertificationTestTastingExecutionSceneContainer);
