import head from "lodash/head";
import orderBy from "lodash/orderBy";
import { all, call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { startExecution } from "student-front-commons/src/actions/execution";
import { addEntity } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { startUnitExecution } from "student-front-commons/src/services/unitExecutionService";
import { addImageDataToItems, addSentryUserAction, addSoundToItems, logError } from "../util";
import browserHistory from "../browserHistory";
import {
  DETECT_DEV_TOOLS_FLOW,
  GET_NEXT_UNIT_ITEM_EXECUTION_FLOW,
  LOAD_CONFIGURATION_FLOW,
  START_UNIT_EXECUTION_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
  USER_LAST_ACTION_FLOW,
} from "../consts";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const sentryUserAction = { mainComponent: "startUnitExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(START_UNIT_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_UNIT_EXECUTION_FLOW));
    try {
      const configurations = yield select((state) => state.configurations.id);
      if (!configurations) {
        yield put(startFlow(LOAD_CONFIGURATION_FLOW));
      }

      // const unitType = yield select(getEntityById('unitType', unit.type));
      // const hasConfig = localStorage.getItem(`${sessionStorage.getItem('id')}_microphone_config`);
      // if (unitType.abilities.some(ability => ability === 'SPEAKING') && !hasConfig) {
      //   yield call(browserHistory.replace, {
      //     pathname: '/microphone-test',
      //     state: { redirectUrl: window.location.pathname },
      //   });
      //   return;
      // }

      const result = yield call(startUnitExecution, {
        module: flow.params.module,
        unit: flow.params.unit,
        optionalReview: !!flow.params.optionalReview,
        contentVideoRecommendation: !!flow.params.contentVideoRecommendation,
      });

      if ((result.unitExecution.answers || []).length >= result.items.length) {
        yield put(
          startExecution({
            ...result.unitExecution,
            items: result.items,
            module: flow.params.module,
            unit: flow.params.unit,
            course: flow.params.course,
            isReview: !!flow.params.isReview,
          })
        );

        browserHistory.replace(`${browserHistory.location.pathname}/${result.unitExecution.id}/result`);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Unit finished. Navigate to: ${browserHistory.location.pathname}/${result.unitExecution.id}/result`,
        });
        return;
      }

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Started execution of unit ${flow.params.unit} from module ${flow.params.module}`,
      });

      const orderedItems = orderBy(result.items, ["order"], ["asc"]);

      yield all([
        ...addImageDataToItems(
          orderedItems.slice(
            (result.unitExecution.answers || []).length,
            (result.unitExecution.answers || []).length + 3
          )
        ),
        ...addSoundToItems(
          orderedItems.slice(
            (result.unitExecution.answers || []).length,
            (result.unitExecution.answers || []).length + 3
          )
        ),
      ]);

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Added sounds and images to Items`,
      });

      yield put(
        startExecution({
          ...result.unitExecution,
          items: result.items,
          module: flow.params.module,
          unit: flow.params.unit,
          isReview: !!flow.params.isReview,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Unit Execution Form`,
      });

      yield spawn(function* () {
        yield all([
          ...addImageDataToItems(orderedItems.slice((result.unitExecution.answers || []).length + 3)),
          ...addSoundToItems(orderedItems.slice((result.unitExecution.answers || []).length + 3)),
        ]);
      });

      const unit = yield select(getEntityById("unit", flow.params.unit));
      yield put(
        addEntity("unit", {
          ...unit,
          lastExecutionStartedAt: result.unitExecution.startedAt,
          lastExecutionStudiedTime: 0,
        })
      );

      yield put(startFlow(DETECT_DEV_TOOLS_FLOW));
      yield put(startFlow(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW));
      yield put(startFlow(USER_LAST_ACTION_FLOW));
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Advance to next Unit Item`,
      });
    } catch (error) {
      yield put(endFlow(USER_AWAY_TIMEOUT_FLOW));
      if (error.code === "three_minute_block") {
        yield put(
          showMessage({
            message: "error.three_minute_block",
            icon: "lock-outline",
          })
        );
        yield call(browserHistory.replace, `${head(window.location.pathname.split("/units"))}/units`);
      } else {
        logError({ error, location: "start-unit-execution" });
        yield call(browserHistory.replace, "/exercise-not-found");
      }
    } finally {
      yield put(endFlow(START_UNIT_EXECUTION_FLOW));
    }
  });
}
