import get from "lodash/get";
import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { call, delay, put, select, takeLatest, spawn } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue, initForm } from "student-front-commons/src/actions/form";
import { getInstructionAudio } from "student-front-commons/src/selectors/configuration";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { addSentryUserAction, linkAnswers, logError } from "../util";
import {
  END_PLACEMENT_TEST_EXECUTION_FLOW,
  GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  PLACEMENT_TEST_EXECUTION_FORM,
} from "../consts";
import browserHistory from "../browserHistory";
import { playAudio } from "../stores/audio-store";
import { uniqBy } from "lodash";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getNextPlacementTestItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW), function* () {
    try {
      const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
      const placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));

      const nextIndex = placementTestExecutionForm.values.currentIndex + 1;
      let nextPlacementTestItem = placementTestExecutionForm.values.placementTestItems[nextIndex];

      if (!nextPlacementTestItem) {
        let reachedLevel = itemExecutionForm.values.associativeItem.placementTestLevel;
        const totalErrors = placementTestExecutionForm.values.answers.filter(
          (answer) => answer.status === "WRONG"
        ).length;
        if (reachedLevel.level === 6 && totalErrors > 5) {
          reachedLevel = placementTestExecutionForm.values.answers.find(
            (answer) => answer.placementTestLevel.level === 5.2
          ).placementTestLevel;
        }

        yield put(
          startFlow(END_PLACEMENT_TEST_EXECUTION_FLOW, {
            stopReason: "FINALIZOU",
            reachedLevel: get(reachedLevel, "id", null),
            placementTestExecution: placementTestExecutionForm.values.execution,
          })
        );

        browserHistory.push("/placement-test-score");
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Placement Test finished. Navigate to: /placement-test-score`,
        });
        return;
      }

      const advanceToNextGrammarOnLevel =
        nextPlacementTestItem.placementTestLevel.id ===
          get(itemExecutionForm, "values.associativeItem.placementTestLevel.id", 0) &&
        nextPlacementTestItem.order > get(itemExecutionForm, "values.associativeItem.item.order");

      if (
        advanceToNextGrammarOnLevel &&
        placementTestExecutionForm.values.answers.filter(
          (answer) => answer.placementTestLevel.id === nextPlacementTestItem.placementTestLevel.id
        ).length > 0
      ) {
        yield put(
          changeFormValue(
            PLACEMENT_TEST_EXECUTION_FORM,
            "extraItemsRemainingOnLevel",
            placementTestExecutionForm.values.extraItemsRemainingOnLevel - 1
          )
        );
      }

      const totalPreviousCorrectAnswersOfCurrentLevel = placementTestExecutionForm.values.answers.filter(
        (answer) => answer.placementTestLevel.id === nextPlacementTestItem.placementTestLevel.id && answer.correct
      ).length;

      const totalPlacementItemsOfCurrentLevel = placementTestExecutionForm.values.placementTestItems.filter(
        (placementTestItem) => placementTestItem.placementTestLevel.id === nextPlacementTestItem.placementTestLevel.id
      ).length;

      const totalRemainingQuestionsOfCurrentLevel =
        totalPlacementItemsOfCurrentLevel +
        placementTestExecutionForm.values.extraItemsRemainingOnLevel +
        placementTestExecutionForm.values.extraItemsAddedOnLevel;

      if (
        nextPlacementTestItem.placementTestLevel.level <= 3.7 &&
        (totalPreviousCorrectAnswersOfCurrentLevel / totalRemainingQuestionsOfCurrentLevel) * 100 >=
          100 - nextPlacementTestItem.placementTestLevel.placementPercentageError
      ) {
        nextPlacementTestItem = placementTestExecutionForm.values.placementTestItems.find(
          (placementTestItem) =>
            placementTestItem.placementTestLevel.level > nextPlacementTestItem.placementTestLevel.level
        );
        yield put(
          changeFormValue(
            PLACEMENT_TEST_EXECUTION_FORM,
            "currentIndex",
            placementTestExecutionForm.values.placementTestItems.indexOf(nextPlacementTestItem) - 1
          )
        );
      }

      if (
        (get(itemExecutionForm, "values.associativeItem.placementTestLevel.level", 0) === 2 &&
          nextPlacementTestItem.placementTestLevel.level === 2.2) ||
        (get(itemExecutionForm, "values.associativeItem.placementTestLevel.level", 0) === 4 &&
          nextPlacementTestItem.placementTestLevel.level === 4.2)
      ) {
        yield put(
          startFlow(PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW, {
            placementTestLevel: itemExecutionForm.values.associativeItem.placementTestLevel,
          })
        );

        if (itemExecutionForm.values.associativeItem.placementTestLevel.level >= 4) {
          const placementTestPause2Audio = yield select(getInstructionAudio("placementTest.pause2"));
          yield spawn(playAudio, {
            url: placementTestPause2Audio,
            isCompleteUrl: false,
            rate: 1,
          });
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Fluency level achieved`,
          });
        } else {
          const placementTestPause1Audio = yield select(getInstructionAudio("placementTest.pause1"));
          yield spawn(playAudio, {
            url: placementTestPause1Audio,
            isCompleteUrl: false,
            rate: 1,
          });
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Intermediate level achieved`,
          });
        }

        yield delay(30000);
        yield put(
          changeFormValue(
            PLACEMENT_TEST_EXECUTION_FORM,
            "level",
            itemExecutionForm.values.associativeItem.placementTestLevel.level >= 4 ? "advanced" : "intermediate"
          )
        );
        yield put(endFlow(PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
      }

      if (
        nextPlacementTestItem.placementTestLevel.level >
          get(itemExecutionForm, "values.associativeItem.placementTestLevel.level", 0) ||
        placementTestExecutionForm.values.answers.filter(
          (answer) => answer.placementTestLevel.id === nextPlacementTestItem.placementTestLevel.id
        ).length === 0
      ) {
        yield put(changeFormValue(PLACEMENT_TEST_EXECUTION_FORM, "extraItemsAddedOnLevel", 0));
        yield put(
          changeFormValue(
            PLACEMENT_TEST_EXECUTION_FORM,
            "extraItemsRemainingOnLevel",
            uniqBy(
              placementTestExecutionForm.values.placementTestItems.filter(
                (item) => item.placementTestLevel.id === nextPlacementTestItem.placementTestLevel.id
              ),
              (placementTestItem) => placementTestItem.order
            ).length
          )
        );
      }

      const linkedAnswers = linkAnswers(nextPlacementTestItem.item.answers);
      yield put(
        initForm(ITEM_EXECUTION_FORM, {
          associativeItem: {
            ...nextPlacementTestItem,
            item: {
              ...nextPlacementTestItem.item,
              order: nextPlacementTestItem.order,
              linkedAnswers: typesToOrderAnswers.find((type) => type === nextPlacementTestItem.item.type.key)
                ? orderBy(linkedAnswers, "text", "asc")
                : shuffle(linkedAnswers),
            },
          },
          startedAt: new Date(),
          isDisabled: true,
          answer: null,
          repeatCount: 0,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      if (placementTestExecutionForm.values.currentIndex === -1) {
        yield delay(1000);
        try {
          const placementTestStartAudio = yield select(getInstructionAudio("placementTest.start"));
          yield call(playAudio, {
            url: placementTestStartAudio,
            isCompleteUrl: false,
            rate: 1,
          });
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Placement Test started`,
          });
        } catch (introError) {
          logError({ introError, flow: GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW });
        }
      }

      yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true, isPlacementRepeat: false }));
    } catch (error) {
      logError({ error, flow: GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
    }
  });
}
