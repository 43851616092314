import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import withTranslation from "../../core/withTranslation";
import TranslatedText from "../../core/TranslatedText";

const GradeListTimeColumn = (props) => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    <h1
      style={{
        color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
        margin: 0,
        marginBottom: 5,
        fontSize: 22,
        fontWeight: "bold",
      }}
    >
      {props.grade}
    </h1>
    <TranslatedText
      as="span"
      translateKey="grades.totalStudyTime"
      values={{
        time: moment.duration(props.totalStudyTime, "hours").format("hh:mm", { trim: false }),
      }}
      style={{
        color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
        fontSize: 12,
        marginBottom: 3,
      }}
    />
    <br />
    <TranslatedText
      as="span"
      translateKey="grades.requiredStudyTime"
      values={{
        time: moment.duration(props.requiredStudyTime, "hours").format("hh:mm", { trim: false }),
      }}
      style={{
        color: props.preview ? "rgb(145, 153, 156)" : "rgb(55, 71, 79",
        fontSize: 12,
        marginBottom: 5,
      }}
    />
  </div>
);

GradeListTimeColumn.propTypes = {
  grade: PropTypes.number.isRequired,
  requiredStudyTime: PropTypes.number.isRequired,
  totalStudyTime: PropTypes.number.isRequired,
  preview: PropTypes.bool.isRequired,
};

export default withTranslation(GradeListTimeColumn);
