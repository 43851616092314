import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import Cropper from "react-cropper";
import "../../../node_modules/cropperjs/dist/cropper.css";
import CircularLoading from "../CircularLoading";
import UserAvatar from "../UserAvatar";
import Button from "./Button";
import Separator from "../Separator";
import withTranslation from "../withTranslation";

function ImagePicker(props) {
  const cropperRef = useRef();
  const fileInputRef = useRef();
  const [imageToCrop, setImageToCrop] = useState();
  const [croppedImage, setCroppedImage] = useState();
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    if (event.target && event.target.files.length > 0) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = (loadEvent) => {
        setImageToCrop(loadEvent.target.result);
        setCroppedImage(null);
        setLoading(false);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCancelChanges = useCallback(() => {
    setImageToCrop(null);
    setCroppedImage(null);
    setLoading(false);
  }, []);

  const handleConfirmChanges = () => {
    cropperRef.current?.cropper
      .getCroppedCanvas({
        width: 120,
        height: 120,
        imageSmoothingQuality: "medium",
      })
      .toBlob((blob) => {
        props.onConfirmChanges(blob);
        setImageToCrop(null);
        setCroppedImage(URL.createObjectURL(blob));
        setLoading(false);
      });
  };

  const handleOpenFileDialog = useCallback(() => {
    if (!props.disabled) {
      fileInputRef.current?.click();
    }
  }, [props.disabled, fileInputRef]);

  return (
    <div
      style={{
        flexDirection: "inherit",
      }}
    >
      <Dialog open={!!imageToCrop}>
        <DialogContent>
          <Cropper
            src={imageToCrop}
            style={{
              maxHeight: 300,
            }}
            guides={false}
            dragMode="move"
            ref={cropperRef}
            initialAspectRatio={1}
            background={false}
            viewMode={2}
            minCropBoxWidth={40}
            minCropBoxHeight={40}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmChanges}>{props.getTranslation("imageCrop.confirm")}</Button>
          <Button onClick={handleCancelChanges} color="default">
            {props.getTranslation("imageCrop.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && <CircularLoading size="md" />}
      {!loading && (
        <div
          style={{
            display: "flex",
            flexDirection: "inherit",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {props.isSubmitting && (
            <div
              style={{
                position: "absolute",
                marginTop: -20,
              }}
            >
              <CircularLoading size="xs" />
            </div>
          )}
          <div>
            {croppedImage ? (
              <img
                alt="profile"
                src={croppedImage}
                style={{
                  height: 120,
                  borderRadius: "100%",
                }}
              />
            ) : (
              <UserAvatar size="lg" src={props.src} onClick={handleOpenFileDialog} />
            )}
          </div>
          <Separator size="md" />
          <Button onClick={handleOpenFileDialog} disabled={props.disabled} backgroundColor="#ECECEC">
            {props.getTranslation("imageCrop.changeLabel")}
            <input
              accept=".png, .jpeg, .jpg"
              onChange={handleChange}
              ref={fileInputRef}
              disabled={props.disabled}
              type="file"
              style={{
                visibility: "hidden",
                position: "absolute",
                top: 0,
                left: 0,
                width: 0,
                height: 0,
              }}
            />
          </Button>
        </div>
      )}
    </div>
  );
}

ImagePicker.propTypes = {
  src: PropTypes.string,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
  onConfirmChanges: PropTypes.func.isRequired,
};

ImagePicker.defaultProps = {
  src: null,
  isSubmitting: false,
  disabled: false,
};

export default withTranslation(ImagePicker);
