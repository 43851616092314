import React from "react";
import PropTypes from "prop-types";
import FlexRow from "../../core/FlexRow";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import UnitListContainer from "./UnitListContainer";
import FlexColumn from "../../core/FlexColumn";
import UnitSectionProgressContainer from "./UnitSectionProgressContainer";
import Completed from "../../core/Completed";

const UnitSectionListItem = (props) => (
  <FlexColumn>
    <FlexRow flex={1} justifyContent="space-between" alignItems="center">
      <FlexRow alignItems="center">
        {props.isCompleted && (
          <React.Fragment>
            <Completed />
            <Separator size="xxs" />
          </React.Fragment>
        )}
        <TranslatedText
          as="h1"
          translateKey={`unit.group.${props.group.value}`}
          style={{
            margin: 0,
            color: "#727272",
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: "16px",
          }}
        />
      </FlexRow>
      {props.group.isDisabled && <UnitSectionProgressContainer group={props.group.value} />}
    </FlexRow>
    <Separator size="sm" />
    <UnitListContainer group={props.group.value} />
  </FlexColumn>
);

UnitSectionListItem.propTypes = {
  group: PropTypes.shape({
    value: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isCompleted: PropTypes.bool.isRequired,
  }).isRequired,
};

export default UnitSectionListItem;
