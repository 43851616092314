import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { GREY_3 } from "../consts/color";
import Icon from "./Icon";

const DragBox = Styled.div`
  background-color: ${(props) => (props.isWrong ? "rgba(255, 0, 0, 0.3)" : GREY_3)};
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};

  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 3px 3px 0 rgba(0, 0, 0, 0.1)")};
  }
`;

const DragLabel = Styled.label`
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  color: #607d8b;
  margin: -5px;
  min-width: fit-content;
  min-width: -moz-max-content;
`;

const DragBoxContainer = Styled.div`
  cursor: pointer;
  position: relative;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};

  &:hover {
    .close-container {
      opacity: ${(props) => (props.disabled ? 0 : 1)};
    }
  }
`;

const CloseContainer = Styled.div`
  background-color: #00000060;
  position: absolute;
  width: 100%;
  height: 82%;
  border-radius: 3px;
  right: 1px;
  top: 0;
  transition: all .25s ease;
  opacity: 0;
  display: ${(props) => (props.shouldHide ? "none" : "flex")};
  align-items: center;
  justify-content: center;
`;

const DragAnswerItem = (props) => {
  return (
    <div>
      <DragBoxContainer onClick={() => props.onRemove(props.answer, props.index)} disabled={props.disabled}>
        <CloseContainer className="close-container">
          <Icon icon="close" color="#fff" />
        </CloseContainer>
        <DragBox isWrong={props.isWrong} disabled={props.disabled}>
          <DragLabel>{props.answer && props.answer.text ? props.answer.text.replace(/[!.?,]$/, "") : ""}</DragLabel>
        </DragBox>
      </DragBoxContainer>
    </div>
  );
};

DragAnswerItem.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  onDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isWrong: PropTypes.bool,
};

DragAnswerItem.defaultProps = {
  disabled: false,
};

export default DragAnswerItem;
