import React from "react";
import PropTypes from "prop-types";
import round from "lodash/round";
import min from "lodash/min";
import ModuleScoreSummary from "./ModuleScoreSummary";
import TranslatedText from "../../core/TranslatedText";

const ModuleListItemScore = (props) => (
  <React.Fragment>
    <div
      style={{
        position: "relative",
      }}
    >
      <TranslatedText
        as="span"
        translateKey="module.score"
        style={{
          position: "relative",
          color: "#607D8B",
          fontSize: "13px",
          textTransform: "uppercase",
        }}
      />
      <TranslatedText
        as="span"
        translateKey="module.percentageComplete"
        values={{
          percentage: round(min([props.module.percentageComplete, 100])),
        }}
        style={{
          position: "relative",
          color: "rgba(96, 125, 139, 0.8)",
          fontSize: "12px",
          marginLeft: 10,
        }}
      />
    </div>
    <ModuleScoreSummary module={props.module} />
  </React.Fragment>
);

ModuleListItemScore.propTypes = {
  module: PropTypes.shape({
    percentageComplete: PropTypes.number,
  }).isRequired,
};

export default ModuleListItemScore;
