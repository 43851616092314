import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Separator from "../../../core/Separator";
import UserAwayModalContainer from "../../../error/UserAwayModalContainer";
import ErrorModalContainer from "../../../error/ErrorModalContainer";
import StartTastingModal from "../StartTastingModal";
import UnitSubHeader from "../UnitSubHeader";
import CloseUnitExecutionContainer from "../CloseUnitExecutionContainer";
import ContentVideoItem from "../../../core/item-type/ContentVideoItem";
import ContentVideoInformationSection from "./ContentVideoInformationSection";
import { useEntity } from "student-front-commons/src/hooks";
import FlexRow from "../../../core/FlexRow";
import FlexColumn from "../../../core/FlexColumn";

function ContentVideoUnitExecutionScene(props) {
  const params = useParams();

  const unit = useEntity("unit", params.idUnit);
  return (
    <div>
      <StartTastingModal />
      <UnitSubHeader />
      {!props.isPending && (
        <FlexRow className="container" justifyContent="space-between">
          <UserAwayModalContainer />
          <CloseUnitExecutionContainer />
          <FlexColumn flex={1} justifyContent="center" height="100%">
            <Separator size="xs" />
            <ContentVideoItem />
          </FlexColumn>
          <ContentVideoInformationSection idModule={params.idModule} unit={unit} />
        </FlexRow>
      )}
      <ErrorModalContainer />
    </div>
  );
}

ContentVideoUnitExecutionScene.propTypes = {
  isPending: PropTypes.bool,
};

ContentVideoUnitExecutionScene.defaultProps = {
  isPending: false,
};

export default ContentVideoUnitExecutionScene;
