import React from "react";
import PropTypes from "prop-types";
import { GREY_5 } from "../../consts/color";

const ControlBarLayoutItem = (props) => (
  <div
    style={{
      display: "inline-block",
      textAlign: "center",
      position: "relative",
      ...props.style,
    }}
    className={props.className}
  >
    {props.children}
    <label
      htmlFor="button"
      style={{
        fontSize: 12,
        color: props.textColor || GREY_5,
        margin: 0,
        pointerEvents: "none",
        userSelect: "none",
      }}
    >
      {props.label}
    </label>
  </div>
);

ControlBarLayoutItem.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};

export default ControlBarLayoutItem;
