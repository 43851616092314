import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import moment from "moment";
import MediaQuery from "react-responsive";
import Styled from "styled-components";
import CourseThemeContext from "../../core/CourseThemeContext";
import Separator from "../../core/Separator";
import Row from "../../core/Row";
import Column from "../../core/Column";
import Icon from "../../core/Icon";
import Button from "../../core/Button";
import ModuleListItemScore from "./ModuleListItemScore";
import withTranslation from "../../core/withTranslation";
import TranslatedText from "../../core/TranslatedText";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import Completed from "../../core/Completed";
import ColumnSeparator from "../../core/ColumnSeparator";
import PopoverButton from "../../core/PopoverButton";
import Paragraph from "../../core/Paragraph";
import { addSentryUserAction } from "../../util";
import { useEntity } from "student-front-commons/src/hooks";
import MasteryTestList from "../../core/mastery-test/MasteryTestList";
import Tooltip from "../../core/Tooltip";
import { MODULE_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import round from "lodash/round";
import min from "lodash/min";

const sentryUserAction = { mainComponent: "ModuleListItemPanel" };

const ModuleListItemPanel = Styled.div`
  position: relative;
  height: 100%;
  border-radius: 3px;
  background: #FFF;
  background-image: url(${(props) => props.srcImage});
  box-shadow: 0 1px 3px 0 rgba(85, 85, 85, 0.38);
  background-size: cover;
  padding: 30px;
  filter: ${(props) => (props.disabled ? "grayscale(100%)" : "grayscale(0%)")};
`;

function ModuleListItem(props) {
  const courseThemeContext = useContext(CourseThemeContext);

  const module = useEntity(MODULE_SCHEMA, props.moduleId);
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <ModuleListItemPanel
      className="stepModuleList"
      srcImage={`${process.env.REACT_APP_FILES_URL}/${module.backgroundUrl}`}
      disabled={module.disabled}
    >
      {module.disabled && <DisabledLayer />}
      <Row>
        <MediaQuery minWidth={528} maxWidth={991}>
          {(matches) => (
            <Column
              lgSize={!!profile.isSpeechRecognitionDisabled ? 9 : 5}
              mdSize={!!profile.isSpeechRecognitionDisabled ? 9 : 5}
              smSize={5}
              xsSize={matches ? 5 : 12}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: 178,
                }}
              >
                <div>
                  <FlexRow alignItems="center">
                    {module.moduleCompleted && (
                      <React.Fragment>
                        <Completed />
                        <ColumnSeparator size="xs" />
                      </React.Fragment>
                    )}
                    <div
                      style={{
                        position: "relative",
                        color: "#607D8B",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {module.name}
                    </div>
                    <span style={{ color: "rgba(96, 125, 139,1)", fontSize: "11px", marginLeft: 5 }}>
                      ({round(min([module.percentageComplete, 100]))}%)
                    </span>
                  </FlexRow>
                  <Separator size="sm" />
                  {!module.disabled && (
                    <div
                      style={{
                        position: "relative",
                        color: "rgba(96, 125, 139, 0.8)",
                        fontSize: "12px",
                      }}
                    >
                      {props.getTranslation("module.lastStudied")}:{" "}
                      {module.lastStudied
                        ? moment(module.lastStudied).fromNow()
                        : props.getTranslation("module.noStudy")}
                    </div>
                  )}
                </div>
                <div>
                  <div
                    style={{
                      width: 120,
                    }}
                    className="stepModuleEnter"
                  >
                    {module.moduleCompleted ? (
                      <TranslatedText
                        as={Button}
                        fontSize="10px"
                        translateKey="module.button.enter"
                        width="75px"
                        color="#fff"
                        fontColor={courseThemeContext.primary}
                        shrink
                        textTransform="uppercase"
                        onClick={() => {
                          props.history.push(`${props.match.url}/${module.id}/units`);
                          addSentryUserAction({
                            ...sentryUserAction,
                            clickedComponent: "Button",
                            action: `Navigate to ${props.match.url}/${module.id}/units`,
                          });
                        }}
                      />
                    ) : (
                      <PopoverButton
                        isOpen={props.showPopoverHint}
                        animated={true}
                        position="bottom"
                        positionOffset={55}
                        content={
                          <TranslatedText
                            as={Paragraph}
                            translateKey="module.firstAccessTooltip"
                            style={{
                              fontWeight: "bold",
                            }}
                          />
                        }
                      >
                        <TranslatedText
                          as={Button}
                          translateKey={module.disabled ? "module.button.locked" : "module.button.enter"}
                          disabled={module.disabled}
                          color={courseThemeContext.primary}
                          textTransform="uppercase"
                          onClick={() => {
                            props.history.push(`${props.match.url}/${module.id}/units`);
                            addSentryUserAction({
                              ...sentryUserAction,
                              clickedComponent: "Button",
                              action: `Navigate to ${props.match.url}/${module.id}/units`,
                            });
                          }}
                        />
                      </PopoverButton>
                    )}
                  </div>
                </div>
              </div>
            </Column>
          )}
        </MediaQuery>
        {!module.disabled && (
          <Column
            lgSize={!!profile.isSpeechRecognitionDisabled ? 3 : 7}
            mdSize={!!profile.isSpeechRecognitionDisabled ? 3 : 7}
            smSize={!!profile.isSpeechRecognitionDisabled ? 3 : 7}
            xsSize={7}
          >
            <Row>
              <Column
                lgSize={!!profile.isSpeechRecognitionDisabled ? 12 : 4}
                mdSize={!!profile.isSpeechRecognitionDisabled ? 12 : 4}
              >
                <FlexRow alignItems="center" gap={7}>
                  <TranslatedText
                    translateKey="masteryTest.name"
                    className="stepModuleMasteryTests"
                    style={{
                      position: "relative",
                      color: "#607D8B",
                      lineHeight: "12px",
                      fontSize: "13px",
                      textTransform: "uppercase",
                    }}
                  />
                  <Tooltip text={props.getTranslation("masteryTest.description")}>
                    <FlexRow
                      alignItems="center"
                      justifyContent="center"
                      borderRadius={16}
                      width={16}
                      color="grey"
                      fontSize={10}
                      lineHeight="15px"
                      fontWeight={700}
                      height={16}
                      backgroundColor="transparent"
                      border="1px solid grey"
                    >
                      ?
                    </FlexRow>
                  </Tooltip>
                </FlexRow>
                <MasteryTestList moduleId={module.id} />
              </Column>
              {!profile.isSpeechRecognitionDisabled && (
                <Column lgSize={8} mdSize={8}>
                  <ModuleListItemScore module={module} />
                </Column>
              )}
            </Row>
          </Column>
        )}
      </Row>
      {!!profile.isSpeechRecognitionDisabled && (
        <FlexRow
          position="absolute"
          width="100%"
          height={5}
          bottom={0}
          left={0}
          overflow="hidden"
          backgroundColor="#ffffffb3"
        >
          <FlexRow width={`${module.percentageComplete}%`} height="100%" backgroundColor={courseThemeContext.primary} />
        </FlexRow>
      )}
    </ModuleListItemPanel>
  );
}

function DisabledLayer() {
  return (
    <FlexRow
      top={0}
      left={0}
      width="100%"
      height="100%"
      position="absolute"
      backgroundColor="#ffffffcc"
      zIndex={5}
      justifyContent="center"
      alignItems="center"
    >
      <FlexColumn
        width={104}
        height={104}
        borderRadius={104}
        backgroundColor="#ECECEC"
        justifyContent="center"
        alignItems="center"
      >
        <Icon color="#42424280" icon="lock-outline" size="md" />
      </FlexColumn>
    </FlexRow>
  );
}

ModuleListItem.propTypes = {
  showPopoverHint: PropTypes.bool.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    backgroundUrl: PropTypes.string,
    disabled: PropTypes.bool,
    moduleCompleted: PropTypes.bool,
    lastStudied: PropTypes.string,
    percentageComplete: PropTypes.number,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(withTranslation(ModuleListItem));
