import React, { Component } from "react";
import CourseThemeContext from "./CourseThemeContext.js";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

class DefaultThemeProvider extends Component {
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, secondaryColor }) => (
          <CourseThemeContext.Provider
            value={{
              primary: primaryColor,
              secondary: secondaryColor,
            }}
          >
            {this.props.children}
          </CourseThemeContext.Provider>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default DefaultThemeProvider;
