import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import browserHistory from "../browserHistory";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  GET_NEXT_UNIT_ITEM_EXECUTION_FLOW,
  START_NEXT_UNIT_ITEM_EXECUTION,
} from "../consts";
import { logError } from "../util";
import { finishItemExecution } from "student-front-commons/src/actions/itemExecution";
import { setNextExecutionOrder } from "student-front-commons/src/actions/execution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";

const typesToNotAdvanceOnLastItem = ["DIALOGUE", "DIALOGUE_OPTION"];

export default function* () {
  yield takeLatest(getFlowStart(START_NEXT_UNIT_ITEM_EXECUTION), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const execution = yield select((state) => state.executions);

          if (execution.answers.length >= execution.associativeItems.length) {
            const itemType = yield select(getCurrentItemExecutionProp("item.type.key"));
            const isFinished = yield select(getCurrentItemExecutionProp("isFinished"));

            if (typesToNotAdvanceOnLastItem.find((type) => type === itemType) && !isFinished) {
              const itemId = yield select(getCurrentItemExecutionProp("item.id"));
              yield put(finishItemExecution(itemId));
              yield put(setNextExecutionOrder());
            } else {
              browserHistory.replace(
                `${browserHistory.location.pathname.replace("content-video-exercise", "exercise")}/${
                  execution.id
                }/result`
              );
            }
          } else {
            yield put(setNextExecutionOrder());
            yield put(startFlow(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({ error, flow: START_NEXT_UNIT_ITEM_EXECUTION });

          yield put(
            showMessage({
              message: "error.error_next_unit_execution",
            })
          );
          yield put(setNextExecutionOrder());
        } finally {
          yield put(endFlow(START_NEXT_UNIT_ITEM_EXECUTION));
        }
      }),
    });
  });
}
