import { eventChannel } from "redux-saga";
import { takeLatest, put, call, take } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { MONITOR_NETWORK_CONNECTION_FLOW } from "../consts";
import { addSentryUserAction, logError } from "../util";

function networkEvents() {
  return eventChannel((emitter) => {
    const listenerStatus = (status) => emitter({ status });

    window.addEventListener("online", () => listenerStatus("online"));
    window.addEventListener("offline", () => listenerStatus("offline"));

    return () => {
      window.removeEventListener("online", () => listenerStatus("online"));
      window.removeEventListener("offline", () => listenerStatus("offline"));
    };
  });
}

const sentryUserAction = { mainComponent: "monitorNetworkConnectionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(MONITOR_NETWORK_CONNECTION_FLOW), function* () {
    try {
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `User network ${navigator.onLine ? "connected" : "disconnected"}`,
      });

      const channel = yield call(networkEvents);
      while (true) {
        const { status } = yield take(channel);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `User network status: ${status}`,
        });
      }
    } catch (error) {
      logError({ flow: MONITOR_NETWORK_CONNECTION_FLOW, error });
    } finally {
      yield put(endFlow(MONITOR_NETWORK_CONNECTION_FLOW));
    }
  });
}
