import React, { useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useEntity } from "student-front-commons/src/hooks";
import Separator from "../../core/Separator";
import UnitResultHeader from "./UnitResultHeader";
import UnitResultScoreStep from "./UnitResultScoreStep";
import UnitResultRatingStep from "./UnitResultRatingStep";
import UnitResultReviewStep from "./UnitResultReviewStep";
import { getExecutionType, isExecutionApproved } from "student-front-commons/src/selectors/execution";
import { MODULE_SCHEMA } from "student-front-commons/src/schemas";
import { useHistory, useParams } from "react-router-dom";
import UnitResultButton from "./UnitResultButton";

const UnitResultScene = () => {
  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const company = useEntity("company", profile?.company);
  const history = useHistory();
  const { idCourse, idModule } = useParams();
  const module = useEntity(MODULE_SCHEMA, idModule);

  const isApproved = useSelector(isExecutionApproved);
  const executionType = useSelector(getExecutionType);

  const [step, setStep] = useState("SCORE");

  const unitResultButtonLabelKey = useMemo(
    () =>
      company?.isOptionalReviewEnabled &&
      (executionType === "DEFAULT" || (["FIRST_REVIEW", "OPTIONAL_REVIEW"].includes(executionType) && !isApproved))
        ? "unitResult.next"
        : profile?.isSpeechRecognitionDisabled
        ? "unitResult.continueToUnitList"
        : "unitResult.next",
    [company?.isOptionalReviewEnabled, executionType, isApproved, profile?.isSpeechRecognitionDisabled]
  );

  const handleNextStep = useCallback(() => {
    if (step === "SCORE") {
      if (
        company?.isOptionalReviewEnabled &&
        (executionType === "DEFAULT" || (["FIRST_REVIEW", "OPTIONAL_REVIEW"].includes(executionType) && !isApproved))
      ) {
        setStep("REVIEW");
      } else if (profile.isSpeechRecognitionDisabled) {
        history.replace(
          module.disabled ? `/course/${idCourse}/videos` : `/course/${idCourse}/modules/${idModule}/units`
        );
      } else {
        setStep("RATING");
      }
    }
    if (step === "REVIEW") {
      if (profile.isSpeechRecognitionDisabled) {
        history.replace(
          module.disabled ? `/course/${idCourse}/videos` : `/course/${idCourse}/modules/${idModule}/units`
        );
      } else {
        setStep("RATING");
      }
    }
  }, [company?.isOptionalReviewEnabled, step]);

  return (
    <div className="container">
      <Separator size="lg" />
      <div
        className="scroll-hidden"
        style={{
          maxWidth: 800,
          backgroundColor: "#fff",
          margin: "0 auto",
          overflow: "hidden",
          overflowY: "auto",
          borderRadius: 16,
          boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)",
          ...(step === "REVIEW" && {
            height: "676px",
          }),
          ...(step === "RATING" && {
            height: "664px",
          }),
          ...(step === "SCORE" && {
            maxHeight: "calc(100vh - 50px)",
            margin: "-16px auto",
          }),
        }}
      >
        <UnitResultHeader step={step} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px 24px 0",
            position: "relative",
            zIndex: 1,
          }}
        >
          {
            {
              SCORE: <UnitResultScoreStep onClick={handleNextStep} />,
              REVIEW: <UnitResultReviewStep onClick={handleNextStep} />,
              RATING: <UnitResultRatingStep />,
            }[step]
          }
        </div>
        {step === "SCORE" && (
          <UnitResultButton
            backgroundColor={unitResultButtonLabelKey !== "unitResult.continueToUnitList" ? "#285D8D" : undefined}
            labelKey={unitResultButtonLabelKey}
            onClick={handleNextStep}
          />
        )}
      </div>
    </div>
  );
};

export default UnitResultScene;
