import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { all, call, delay, put, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { startPlacementTest } from "student-front-commons/src/services/placementTestService";
import {
  addImageDataToItems,
  addSentryUserAction,
  addSoundToItems,
  logError,
  reduceImageDataToItems,
  reduceSoundToItems,
} from "../util";
import { requestCleanPlacementTestResult } from "../actionCreators/placementTestResult";
import { clearAudios } from "../stores/audio-store";
import {
  DISMISS_SYSTEM_MESSAGE_FLOW,
  GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  PLACEMENT_TEST_EXECUTION_FORM,
  START_PLACEMENT_TEST_EXECUTION_FLOW,
} from "../consts";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const sentryUserAction = { mainComponent: "startPlacementTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(START_PLACEMENT_TEST_EXECUTION_FLOW), function* () {
    try {
      yield call(clearAudios);
      yield put(requestCleanPlacementTestResult());

      const placementTestExecution = yield call(startPlacementTest, {
        byLink: sessionStorage.getItem("registeredByLink") || false,
      });

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Started execution of Placement Test`,
      });

      const { items, extraItems } = orderBy(placementTestExecution.items, ["placementTestLevel.level", "order"]).reduce(
        (acc, grammarLevelItems) => {
          const items = shuffle(grammarLevelItems.items).map((item) => ({
            item: {
              ...item,
            },
            placementTestLevel: grammarLevelItems.placementTestLevel,
            order: grammarLevelItems.order,
          }));
          return {
            ...acc,
            items: [...acc.items, ...items.slice(0, grammarLevelItems.itemsToShow)],
            extraItems: [...acc.extraItems, ...items.slice(grammarLevelItems.itemsToShow)],
          };
        },
        { items: [], extraItems: [] }
      );

      yield all(
        addImageDataToItems(
          yield all(
            addSoundToItems(
              items.filter(
                (associativeItem) => associativeItem.placementTestLevel.level <= 0.7 && !!associativeItem.item.audio
              )
            )
          )
        )
      );
      const itemsToNotLoadAssets = items.filter(
        (associativeItem) => associativeItem.placementTestLevel.level > 0.7 && !!associativeItem.item.audio
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Added sounds and images to Items`,
      });

      yield all(
        addImageDataToItems(
          yield all(
            addSoundToItems(
              extraItems.filter(
                (associativeItem) => associativeItem.placementTestLevel.level <= 0.7 && !!associativeItem.item.audio
              )
            )
          )
        )
      );
      const extraItemsToNotLoadAssets = extraItems.filter(
        (associativeItem) => associativeItem.placementTestLevel.level > 0.7 && !!associativeItem.item.audio
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Added sounds and images to Extra Items`,
      });

      yield put(
        initForm(PLACEMENT_TEST_EXECUTION_FORM, {
          placementTestItems: items,
          extraPlacementTestItems: extraItems,
          execution: placementTestExecution.placementTest.id,
          isPlacementRedo: placementTestExecution.isPlacementRedo,
          level: "beginner",
          answers: [],
          currentIndex: -1,
          extraItemsAddedOnLevel: 0,
          extraItemsRemainingOnLevel: 0,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Placement Test Execution Form`,
      });

      yield put(startFlow(GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));

      yield spawn(function* () {
        yield delay(30000);
        yield call(reduceImageDataToItems, itemsToNotLoadAssets);
        yield call(reduceSoundToItems, itemsToNotLoadAssets);
        yield call(reduceImageDataToItems, extraItemsToNotLoadAssets);
        yield call(addSoundToItems, extraItemsToNotLoadAssets);
      });
    } catch (error) {
      logError({ error, flow: START_PLACEMENT_TEST_EXECUTION_FLOW });
      yield put(
        showMessage({
          message: "placementTest.startError",
          button: "placementTest.button.tryAgain",
        })
      );

      yield put(endFlow(START_PLACEMENT_TEST_EXECUTION_FLOW));
      yield take(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW));
      yield put(startFlow(START_PLACEMENT_TEST_EXECUTION_FLOW));
    } finally {
      yield put(endFlow(START_PLACEMENT_TEST_EXECUTION_FLOW));
    }
  });
}
