import React from "react";
import ItemInstruction from "../../core/item-type/ItemInstruction";
import withTranslation from "../../core/withTranslation";
import { useSelector } from "react-redux";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";

function ExerciseHelpText(props) {
  let itemType = useSelector(getItemsExecutionsType);

  if (itemType === "UNSCRAMBLE_DRAG_AND_DROP") {
    itemType = "UNSCRAMBLE_DRAG_AND_DROP_CLICK";
  }

  if (itemType === "CONTENT_VIDEO") {
    return null;
  }

  return (
    <ItemInstruction>
      <span
        dangerouslySetInnerHTML={{
          __html: props.getTranslation(`help.${itemType}`),
        }}
      />
    </ItemInstruction>
  );
}

export default withTranslation(ExerciseHelpText);
