import React from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import ModuleDetails from "./ModuleDetails";

const ModuleDetailsContainer = (props) => (
  <Entity name="profile">
    {({ getById: getProfileById }) => (
      <Entity name="module">
        {({ getById }) => (
          <ModuleDetails
            module={getById(props.match.params.idModule)}
            isSpeechRecognitionDisabled={getProfileById(sessionStorage.getItem("id")).isSpeechRecognitionDisabled}
          />
        )}
      </Entity>
    )}
  </Entity>
);

export default withRouter(ModuleDetailsContainer);
