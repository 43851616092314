import React, { Component } from "react";
import MediaQuery from "react-responsive";
import get from "lodash/get";
import { getCurrentPositions } from "student-front-commons/src/services/positionsService";
import FetchData from "student-front-commons/src/components/FetchData";
import Async from "../core/Async";
import SubHeaderRanking from "./SubHeaderRanking";
import Carousel from "./Carousel";
import Icon from "./Icon";
import CheckDistributorType from "./CheckDistributorType";

class SubHeaderRankingsContainer extends Component {
  renderRankings = (positions) => [
    <CheckDistributorType key="ranking-school">
      {({ isB2C }) =>
        !isB2C && (
          <SubHeaderRanking
            title="ranking.schoolPosition"
            value={get(positions, "school", null)}
            // compareValue={props.previousPositions.school}
          />
        )
      }
    </CheckDistributorType>,
    <SubHeaderRanking
      key="ranking-regional"
      title="ranking.regionalPosition"
      value={get(positions, "regional", null)}
      // compareValue={props.previousPositions.regional}
    />,
    <SubHeaderRanking
      key="ranking-national"
      title="ranking.nationalPosition"
      value={get(positions, "national", null)}
      // compareValue={props.previousPositions.national}
    />,
  ];

  render() {
    return (
      <FetchData
        service={getCurrentPositions}
        params={{
          id: sessionStorage.getItem("id"),
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching} size="sm" color="dark" margin="10px 0">
            <MediaQuery minWidth={992}>
              <div
                className="stepModuleRanking"
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: 320,
                  float: "right",
                }}
              >
                {this.renderRankings(data)}
              </div>
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={991}>
              <div className="stepModuleRanking">
                <Carousel
                  iconType={<Icon icon="replaced" />}
                  style={{
                    padding: "0 30px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {this.renderRankings(data)}
                </Carousel>
              </div>
            </MediaQuery>
          </Async>
        )}
      </FetchData>
    );
  }
}

export default SubHeaderRankingsContainer;
