import { useSelector } from "react-redux";
import { getTranslation } from "../services/translationService";
import { useCallback, useEffect, useState } from "react";
import { get, head } from "lodash";

const getItemPropByItemId = (itemId, path) => (state) => {
  let associativeItems = [];
  if (state.executions.associativeItems) {
    associativeItems = [...state.executions.associativeItems];
  } else if (state.forms["ITEM_EXECUTION_FORM"]) {
    associativeItems = [...state.forms["ITEM_EXECUTION_FORM"].values.associativeItems];
  }
  const associativeItem = associativeItems.find((associativeItem) => associativeItem.item.id === itemId);
  return get(associativeItem, path, undefined);
};

export default function useTranslation(itemId, locale) {
  const [isTranslating, setIsTranslating] = useState(false);
  const [translation, setTranslation] = useState(null);

  //Not using getItemExecutionPropById because in student-web in the Dialogue exercise the items that has translate button
  //is not present in itemExecutions state and in kids-web there is no itemExecutions fulfilled in the state
  const type = useSelector(getItemPropByItemId(itemId, "item.type.key"));
  const text = useSelector(getItemPropByItemId(itemId, "item.text"));
  const postPhrase = useSelector(getItemPropByItemId(itemId, "item.postPhrase"));
  const portugueseTranslation = useSelector(getItemPropByItemId(itemId, "item.translation"));
  const spanishTranslation = useSelector(getItemPropByItemId(itemId, "item.spanishTranslation"));

  const translate = useCallback(async () => {
    setTranslation(null);
    const treatedLocale = head(locale.split("-"));
    if (["en", "es", "pt"].some((l) => l === treatedLocale)) {
      const localeToText = {
        en: "VOCABULARY_ACADEMIC" === type ? postPhrase : text,
        es: spanishTranslation,
        pt: portugueseTranslation,
      };
      const translation = localeToText[treatedLocale] || localeToText["en"];
      setTranslation(translation);
    } else {
      let payload = text;
      if ("VOCABULARY_ACADEMIC" === type) {
        payload = postPhrase;
      }
      if (payload) {
        setIsTranslating(true);
        const result = await getTranslation({ text: payload });
        setTranslation(result.translation);
        setIsTranslating(false);
      }
    }
  }, [text, type, locale, postPhrase, portugueseTranslation, spanishTranslation]);

  useEffect(() => {
    if (locale) {
      translate();
    }
  }, [itemId, locale]);

  return [isTranslating, translation];
}
