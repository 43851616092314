import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useEntity } from "student-front-commons/src/hooks";
import { MODULE_SCHEMA } from "student-front-commons/src/schemas";
import UnitRatingSection from "./UnitRatingSection";
import UnitResultButton from "./UnitResultButton";

const UnitResultRatingStep = () => {
  const history = useHistory();
  const { idCourse, idModule } = useParams();

  const module = useEntity(MODULE_SCHEMA, idModule);

  return (
    <>
      <UnitRatingSection />
      <div style={{ width: "100%", height: "64px" }} />
      <UnitResultButton
        onClick={() =>
          history.replace(
            module.disabled ? `/course/${idCourse}/videos` : `/course/${idCourse}/modules/${idModule}/units`
          )
        }
        labelKey="unitResult.continueToUnitList"
      />
    </>
  );
};

export default UnitResultRatingStep;
