import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import MessageChannelCounter from "./MessageChannelCounter";

const MessageChannelCounterContainer = () => (
  <Entity name="messageChannel">
    {({ getAll }) => {
      const unreadCount = getAll().filter((messageChannel) => !messageChannel.isRead).length;
      if (unreadCount) {
        return <MessageChannelCounter value={unreadCount} />;
      }
      return null;
    }}
  </Entity>
);

export default MessageChannelCounterContainer;
