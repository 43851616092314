import {
  START_ITEM_EXECUTION,
  FINISH_ITEM_EXECUTION,
  START_PLAY_ITEM,
  FINISH_PLAY_ITEM,
  READ_ITEM,
  TRANSLATE_ITEM,
  START_RECORD_ITEM,
  SUBMIT_RECORD_ITEM,
  FINISH_RECORD_ITEM,
  SAVE_RECORD_ITEM_RESULT,
  ADD_ITEM_EXECUTION_ANSWER,
  DISABLE_ITEM_EXECUTION,
  ENABLE_ITEM_EXECUTION,
  INCREMENT_ITEM_EXECUTION_ERROR,
  SHOW_ITEM_CORRECT_OPTION,
  HIDE_ITEM_CORRECT_OPTION,
  START_PLAY_ANSWER,
  FINISH_PLAY_ANSWER,
  REMOVE_LAST_ITEM_EXECUTION_ATTEMPT,
  LISTEN_ITEM,
  CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION,
  REPEAT_ITEM,
  ADD_REPORT_ERROR_TIME,
  INCREMENT_ITEM_EXECUTION_USER_AWAY,
  UPDATE_ITEM_MEDIA_PROGRESS,
  UPDATE_ITEM_MEDIA_LOAD,
  ADD_ITEM_EXECUTION_ATTEMPT,
  SELECT_ITEM,
  CLEAN_ITEM_EXECUTION_AUDIO,
  START_PLAY_ACCESSORY,
  FINISH_PLAY_ACCESSORY,
  UNSELECT_ITEM,
  ENABLE_ITEM_EXECUTION_VALIDATION,
} from "../actions/itemExecution";
import { CLEAN_EXECUTION } from "../actions/execution";
import { orderBy, shuffle, values } from "lodash";

const linkAnswers = (answers, shouldOrder) => {
  const linkedAnswers = orderBy(answers, "index").reduce((result, answer) => {
    if (result.find((resultAnswer) => answer.index && resultAnswer.linkTo === answer.index)) {
      return [
        ...result.map((resultAnswer) => {
          if (resultAnswer.linkTo === answer.index) {
            return {
              ...resultAnswer,
              linkTo: answer.linkTo,
              text: resultAnswer.text.concat(" ").concat(answer.text),
            };
          }
          return resultAnswer;
        }),
      ];
    }
    return [...result, answer];
  }, []);

  if (shouldOrder) {
    return orderBy(linkedAnswers, "text", "asc");
  }
  //TODO validate to always return a shuffle
  return shuffle(linkedAnswers);
};

const typesToOrderAnswers = ["GAP_FILL", "GAP_FILL_IMAGE", "TRUE_FALSE_KIDS"];

const typesWithArrayAnswer = ["MULTIPLE_CHOICE_TEXT"];

export default (state = {}, action) => {
  switch (action.type) {
    case START_ITEM_EXECUTION:
      return {
        allIds: action.payload.associativeItems.map((ai) => ai.item.id),
        lastItemTypeImage: action.payload.imageItem,
        byId: action.payload.associativeItems.reduce(
          (acc, ai) => ({
            ...acc,
            [ai.item.id]: {
              item: {
                ...ai.item,
                linkedAnswers: linkAnswers(
                  ai.item.answers,
                  typesToOrderAnswers.some((type) => type === ai.item.type.key)
                ),
              },
              answer: typesWithArrayAnswer.some((type) => type === ai.item.type.key) ? [] : "",
              attempts: [],
              isDisabled: true,
              isSaving: false,
              isRecording: false,
              isSubmittingRecord: false,
              isExecutionValidated: false,
              isFinished: false,
              showCorrectOption: false,
              errorCount: 0,
              repeatCount: 0,
              recordCount: 0,
              listenCount: 0,
              readCount: 0,
              translateCount: 0,
              userAwayCount: 0,
              timeReportingError: 0,
              playingId: null,
              playingStats: null,
              loadingStats: null,
              recordUrl: null,
              recordFile: null,
              speechRecognitionFailCount: 0,
              speechRecognitionResult: null,
              speechRecognitionResultHistory: [],
              nativeRecognitionResult: ai.item.nativeSpeechRecognition,
            },
          }),
          {}
        ),
      };
    case FINISH_ITEM_EXECUTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isFinished: true,
            isExecutionValidated: false,
          },
        },
      };
    case START_PLAY_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isDisabled: true,
            playingStats: null,
            loadingStats: null,
            playingId: action.payload.options.audioType
              ? `${action.payload.options.audioType}-${action.payload.itemId}`
              : action.payload.itemId,
            repeatCount:
              !action.payload.options.isInitialPlay &&
              ["AUDIO_LONG", "VIDEO", "VIDEO_LONG", "CONTENT_VIDEO"].some(
                (type) => type === state.byId[action.payload.itemId].item.type.key
              )
                ? state.byId[action.payload.itemId].repeatCount + 1
                : state.byId[action.payload.itemId].repeatCount,
          },
        },
      };
    case UPDATE_ITEM_MEDIA_LOAD:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            loadingStats: {
              percent: action.payload.options.percent,
            },
          },
        },
      };
    case UPDATE_ITEM_MEDIA_PROGRESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingStats: {
              duration: action.payload.options.duration,
              currentTime: action.payload.options.currentTime,
            },
          },
        },
      };
    case FINISH_PLAY_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isDisabled: false,
            playingId: null,
            isFinished:
              state.byId[action.payload.itemId].item.type.key === "CONTENT_VIDEO" ||
              (state.byId[action.payload.itemId].item.type.key === "GAP_FILL_IMAGE" &&
                state.byId[action.payload.itemId].attempts.some((attempt) => attempt.correct)),
            repeatCount:
              !action.payload.options.isInitialPlay &&
              !["AUDIO_LONG", "VIDEO", "VIDEO_LONG", "CONTENT_VIDEO"].some(
                (type) => type === state.byId[action.payload.itemId].item.type.key
              )
                ? state.byId[action.payload.itemId].repeatCount + 1
                : state.byId[action.payload.itemId].repeatCount,
          },
        },
      };
    case START_PLAY_ANSWER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingId: action.payload.answerId,
          },
        },
      };
    case FINISH_PLAY_ANSWER:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingId: null,
          },
        },
      };
    case START_PLAY_ACCESSORY:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingId: `${action.payload.accessory}-${action.payload.itemId}`,
          },
        },
      };
    case FINISH_PLAY_ACCESSORY:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingId: null,
          },
        },
      };
    case REPEAT_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            repeatCount: state.byId[action.payload.itemId].repeatCount + 1,
          },
        },
      };
    case LISTEN_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            listenCount: state.byId[action.payload.itemId].listenCount + 1,
          },
        },
      };
    case READ_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            readCount: state.byId[action.payload.itemId].readCount + 1,
          },
        },
      };
    case TRANSLATE_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            translateCount: state.byId[action.payload.itemId].translateCount + 1,
          },
        },
      };
    case START_RECORD_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isRecording: true,
          },
        },
      };
    case SUBMIT_RECORD_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isRecording: false,
            isSubmittingRecord: true,
            recordFile: action.payload.recordFile,
            recordUrl: action.payload.recordUrl,
          },
        },
      };
    case SAVE_RECORD_ITEM_RESULT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            recordCount: state.byId[action.payload.itemId].recordCount + 1,
            ...(action.payload.speechRecognitionResult && {
              speechRecognitionResult: action.payload.speechRecognitionResult,
              speechRecognitionResultHistory: [
                ...state.byId[action.payload.itemId].speechRecognitionResultHistory,
                {
                  id: new Date().toISOString(),
                  recordUrl: state.byId[action.payload.itemId].recordUrl,
                  score: action.payload.speechRecognitionResult.qualityScore,
                },
              ],
            }),
          },
        },
      };
    case FINISH_RECORD_ITEM:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            isRecording: false,
            isSubmittingRecord: false,
          },
        },
      };
    case INCREMENT_ITEM_EXECUTION_ERROR:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            errorCount: state.byId[action.payload.itemId].errorCount + 1,
          },
        },
      };
    case INCREMENT_ITEM_EXECUTION_USER_AWAY:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            userAwayCount: state.byId[action.payload.itemId].userAwayCount + 1,
          },
        },
      };
    case ENABLE_ITEM_EXECUTION_VALIDATION:
      return {
        ...state,
        byId: Object.keys(state.byId).reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isExecutionValidated: true,
            },
          }),
          {}
        ),
      };
    case ADD_ITEM_EXECUTION_ANSWER:
      const selectedIdAssociatedDefinition =
        state.byId[action.payload.itemId]?.item?.type?.key === "MEANINGS_ASSOCIATING"
          ? values(state.byId)
              .filter((stateItem) => stateItem.item.id !== action.payload.itemId)
              .find((stateItem) => stateItem.answer === action.payload.answer)
          : null;

      return {
        ...state,
        ...(state.byId[action.payload.itemId]?.item.type.key === "MEANINGS_ASSOCIATING" && {
          selectedId: null,
        }),
        byId: {
          ...state.byId,
          ...(selectedIdAssociatedDefinition && {
            [selectedIdAssociatedDefinition.item.id]: {
              ...state.byId[selectedIdAssociatedDefinition.item.id],
              answer: null,
            },
          }),
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            answer: action.payload.answer,
            ...(action.payload.extraData && { extraData: action.payload.extraData }),
          },
        },
      };
    case ADD_ITEM_EXECUTION_ATTEMPT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            answer: action.payload.answer.answer,
            attempts: [...state.byId[action.payload.itemId].attempts, action.payload.answer],
            ...(["PRESENTATION", "SPEECH_PRACTICE", "PRONUNCIATION", "VOCABULARY_ACADEMIC"].some(
              (type) => type === state.byId[action.payload.itemId].item.type.key
            ) && {
              speechRecognitionFailCount: action.payload.answer.correct
                ? 0
                : state.byId[action.payload.itemId].speechRecognitionFailCount + 1,
            }),
          },
        },
      };
    case REMOVE_LAST_ITEM_EXECUTION_ATTEMPT:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            attempts: state.byId[action.payload.itemId].attempts.slice(
              state.byId[action.payload.itemId].attempts.length - 1
            ),
          },
        },
      };
    case DISABLE_ITEM_EXECUTION:
      return {
        ...state,
        byId: Object.keys(state.byId).reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isDisabled: true,
            },
          }),
          {}
        ),
      };
    case ENABLE_ITEM_EXECUTION:
      return {
        ...state,
        byId: Object.keys(state.byId).reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isDisabled: false,
            },
          }),
          {}
        ),
      };
    case SHOW_ITEM_CORRECT_OPTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            showCorrectOption: true,
          },
        },
      };
    case HIDE_ITEM_CORRECT_OPTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            showCorrectOption: false,
          },
        },
      };
    case CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            answer: "",
            extraData: null,
            speechRecognitionResult: null,
          },
        },
      };
    case ADD_REPORT_ERROR_TIME:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            timeReportingError: state.byId[action.payload.itemId].timeReportingError + action.payload.time,
          },
        },
      };
    case SELECT_ITEM:
      return {
        ...state,
        selectedId: action.payload.itemId,
      };
    case UNSELECT_ITEM:
      return {
        ...state,
        selectedId: null,
      };
    case CLEAN_ITEM_EXECUTION_AUDIO:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.itemId]: {
            ...state.byId[action.payload.itemId],
            playingId: null,
          },
        },
      };
    case CLEAN_EXECUTION:
      return {};
    default:
      return state;
  }
};
