import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { updateProfile } from "student-front-commons/src/services/profileService";
import browserHistory from "../browserHistory";
import { COMPLETE_PROFILE_FLOW, COMPLETE_PROFILE_FORM } from "../consts";
import { addSentryUserAction, logError } from "../util";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const sentryUserAction = { mainComponent: "completeProfileFlow" };

export default function* () {
  yield takeLatest(getFlowStart(COMPLETE_PROFILE_FLOW), function* () {
    try {
      const profileForm = yield select(getForm(COMPLETE_PROFILE_FORM));

      const data = {
        role: "STUDENT",
        name: profileForm.values.name,
        nickname: profileForm.values.nickname,
        email: profileForm.values.email,
        rankingParticipant: true,
        ...(!!profileForm.values.password && {
          password: profileForm.values.password,
        }),
        ...(!!profileForm.values.newPassword && {
          newPassword: profileForm.values.newPassword,
        }),
      };

      const result = yield call(updateProfile, data);
      yield put(mergeEntities(result.entities));

      yield call(browserHistory.replace, "/");
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Profile completed. Navigate to: /`,
      });
    } catch (error) {
      let message = "profile.account.error.general";
      if (error.code === "invalid_password") {
        message = "profile.account.error.password";
      } else if (error.message.indexOf("E11000") > -1) {
        message = "profile.account.error.email";
      } else {
        logError({ error, flow: COMPLETE_PROFILE_FLOW });
      }
      yield put(
        showMessage({
          message: message,
          type: "error",
        })
      );
    } finally {
      yield put(endFlow(COMPLETE_PROFILE_FLOW));
    }
  });
}
