import trim from "lodash/trim";
import shuffle from "lodash/shuffle";
import orderBy from "lodash/orderBy";
import { normalize } from "normalizr";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

import tastingSchema from "../schemas/tastingSchema";

/**
 * start the execution of an unit as a tasting user
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.unit - the unit id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const startTastingExecution = async (payload) => {
  validate(
    {
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const result = await request({
        url: `modules/tasting/units/${payload.unit}/executions`,
        method: "post",
      });

      return {
        unitExecution: result.unitExecution,
        unit: normalize(result.unit, tastingSchema),
        items: orderBy(
          result.items.map((unitItem) => ({
            ...unitItem,
            item: {
              ...unitItem.item,
              text:
                !['TEXT', 'UNSCRAMBLE_TEXT'].some(type => type === unitItem.item.type.key) ? trim(unitItem.item.text).replace(/\s+/g, " ")
                  : trim(unitItem.item.text),
              answers: shuffle(unitItem.item.answers),
            },
          })),
          "order",
          "asc"
        ),
      };
    },
    "error_start_unit_tasting_execution",
    "Unexpected error to start unit tasting execution."
  );
};
