import React from "react";
import PropTypes from "prop-types";
import FlexRow from "../FlexRow";
import MediaQuery from "react-responsive";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG } from "../../consts/color";
import Icon from "../Icon";

const HighlightableItemBox = ({ children, highlight, className, isWrong, showFeedback, feedbackContent, ...rest }) => (
  <MediaQuery maxWidth={368}>
    {(matches) => (
      <div
        className={className}
        style={{
          boxShadow: "0px 3px 3px 0 rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
          padding: matches ? 5 : 20,
          border: "2px solid #fff",
          transition: "border-color 1.5s",
          borderColor: isWrong ? FEEDBACK_WRONG : showFeedback ? FEEDBACK_CORRECT : "#fff",
          borderRadius: 3,
          position: "relative",
        }}
      >
        <FlexRow alignItems="center" justifyContent="center" {...rest}>
          <FlexRow alignItems="center" justifyContent="center" margin="auto" flexWrap="wrap" width="100%">
            {children}
          </FlexRow>
          {isWrong && (
            <FlexRow
              fontSize={13}
              fontWeight={700}
              height={30}
              width={30}
              minWidth={30}
              justifyContent="center"
              color="#ffffff"
              alignItems="center"
              background={FEEDBACK_WRONG}
              borderRadius={20}
            >
              {feedbackContent || "!"}
            </FlexRow>
          )}
          {!isWrong && showFeedback && (
            <FlexRow
              fontWeight={700}
              height={30}
              width={30}
              justifyContent="center"
              color="#ffffff"
              background={FEEDBACK_CORRECT}
              alignItems="center"
              borderRadius={30}
            >
              {feedbackContent || <Icon color="#ffffff" icon="check" size="xxs" />}
            </FlexRow>
          )}
        </FlexRow>
      </div>
    )}
  </MediaQuery>
);

HighlightableItemBox.propTypes = {
  children: PropTypes.node.isRequired,
  feedbackContent: PropTypes.string,
};

export default HighlightableItemBox;
