import React from 'react';
import PropTypes from 'prop-types';
import QuestionLabel from '../QuestionLabel';
import HighlightableItemBox from './HighlightableItemBox';

const PronunciationExerciseItem = props => (
  <div
    style={{
      maxWidth: 690,
      margin: '0 auto',
      height: '100%',
    }}
  >
    <HighlightableItemBox>
      <QuestionLabel
        text={props.item.text}
      />
    </HighlightableItemBox>
  </div>
);

PronunciationExerciseItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default PronunciationExerciseItem;
