import React from "react";
import PropTypes from "prop-types";
import jwtDecode from "jwt-decode";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { ENGLISH4ALL_DISTRIBUTOR_ID } from "../consts";

const CheckDistributorType = (props) => {
  const b2cCompanies = [
    // Flexge b2c company
    "5eb736c122875700138aae32",
  ];
  const b2cDistributors = [ENGLISH4ALL_DISTRIBUTOR_ID];

  if (!sessionStorage.getItem("accessToken")) {
    return null;
  }

  const decodedToken = jwtDecode(sessionStorage.getItem("accessToken"));

  return (
    <WhitelabelContext.Consumer>
      {({ distributor }) =>
        props.children({
          isB2C: b2cDistributors.some((d) => d === distributor) || b2cCompanies.some((c) => c === decodedToken.company),
        })
      }
    </WhitelabelContext.Consumer>
  );
};

CheckDistributorType.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CheckDistributorType;
