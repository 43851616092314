import { useContext } from "react";
import CourseThemeContext from "../../core/CourseThemeContext";
import FlexColumn from "../../core/FlexColumn";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";

export default function BonusTestRewardsItem(props) {
  const courseThemeContext = useContext(CourseThemeContext);
  const bgColor = { bronze: "#f1ffe8", silver: "#dafaff", gold: "#ffd3d2" }[props.reward.label.toLowerCase()];

  return (
    <FlexColumn
      width="160px"
      height="264px"
      background={`linear-gradient(0deg, ${courseThemeContext.secondary} 0%, ${courseThemeContext.primary} 85%)`}
      borderRadius="4px"
      overflow="hidden"
    >
      <FlexColumn height="216px" alignItems="center" justifyContent="flex-end" marginBottom="18px">
        <FlexColumn
          alignItems="center"
          justifyContent="center"
          border="1px solid #ffffff10"
          width="112px"
          height="112px"
          borderRadius="80px"
        >
          <FlexColumn
            alignItems="center"
            justifyContent="center"
            border="2px solid #ffffff10"
            width="96px"
            height="96px"
            borderRadius="80px"
          >
            <FlexColumn
              alignItems="center"
              justifyContent="center"
              backgroundColor={bgColor}
              width="80px"
              height="80px"
              borderRadius="80px"
            >
              <img src={`${process.env.REACT_APP_FILES_URL}/${props.reward?.icon}`} />
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
        <Separator size="xs" />
        <RankingParticipantContainer>
          <TranslatedText
            as="span"
            translateKey="bonusTest.points"
            values={{ points: props.reward?.points }}
            style={{ fontWeight: 700, fontSize: "14px", color: "#fff" }}
          />
        </RankingParticipantContainer>
      </FlexColumn>
      <FlexColumn height="48px" backgroundColor="#fff" alignItems="center" justifyContent="center">
        <TranslatedText
          as="span"
          translateKey="bonusTest.hits"
          values={{ hits: props.reward?.correctAnswers }}
          style={{ fontWeight: 700, fontSize: "14px", color: courseThemeContext.primary, textTransform: "uppercase" }}
        />
      </FlexColumn>
    </FlexColumn>
  );
}
