import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import FlexRow from "../../core/FlexRow";
import { WHITE } from "../../consts/color";
import UnitTypeIcon from "./UnitTypeIcon";
import ColumnSeparator from "../../core/ColumnSeparator";
import FlexColumn from "../../core/FlexColumn";
import UnitTypeAbilities from "./UnitTypeAbilities";
import UnitSteps from "./UnitSteps";
import UnitListItemColumnSeparator from "./UnitListItemColumnSeparator";
import Separator from "../../core/Separator";
import UnitButton from "./UnitButton";
import Completed from "../../core/Completed";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import Paragraph from "../../core/Paragraph";
import PopoverButton from "../../core/PopoverButton";
import $ from "jquery";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "UnitListItem" };

class UnitListItem extends React.PureComponent {
  static propTypes = {
    unit: PropTypes.shape({
      name: PropTypes.string,
      defaultFinishedAt: PropTypes.string,
      lastExecutionStartedAt: PropTypes.string,
      lastExecutionCompletedAt: PropTypes.string,
      lastExecutionLastActionAt: PropTypes.string,
    }).isRequired,
    numberOfDayBeforeFirstReview: PropTypes.number.isRequired,
    isSimpleReviewAllowed: PropTypes.bool.isRequired,
    showFirstAccessTooltip: PropTypes.bool.isRequired,
    timeToAllowContinueUnit: PropTypes.number.isRequired,
  };

  state = {
    isContinueAllowed: false,
    isBlockedForThreeMinutes: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.unit.lastExecutionLastActionAt && !props.unit.lastExecutionCompletedAt) {
      if (
        !props.timeToAllowContinueUnit ||
        moment().diff(moment(props.unit.lastExecutionLastActionAt), "minutes", true) <= props.timeToAllowContinueUnit
      ) {
        return {
          ...state,
          isContinueAllowed: true,
        };
      }
    }
    if (props.unit.lastExecutionCompletedAt) {
      const isBlocked = moment().diff(moment(props.unit.lastExecutionCompletedAt), "minutes", true) <= 3.1;
      if (isBlocked) {
        return {
          ...state,
          isBlockedForThreeMinutes: true,
        };
      }
    }
    return state;
  }

  addContinueTimeout = () => {
    if (this.state.isContinueAllowed) {
      const diff = moment().diff(
        moment(this.props.unit.lastExecutionStartedAt).add(this.props.unit.lastExecutionStudiedTime, "seconds"),
        "minutes",
        true
      );
      if (this.isContinueAllowedTimeout) {
        clearTimeout(this.isContinueAllowedTimeout);
      }
      this.isContinueAllowedTimeout = setTimeout(() => {
        this.setState({
          isContinueAllowed: false,
        });
      }, 14.5 * 60 * 1000 - diff * 60 * 1000);
    }
  };

  addBlockTimeout = () => {
    if (this.state.isBlockedForThreeMinutes) {
      const diff = moment().diff(moment(this.props.unit.lastExecutionCompletedAt), "minutes", true);
      if (this.isBlockTimeout) {
        clearTimeout(this.isBlockTimeout);
      }
      this.isBlockTimeout = setTimeout(() => {
        this.setState({
          isBlockedForThreeMinutes: false,
        });
      }, 3.1 * 60 * 1000 - diff * 60 * 1000);
    }
  };

  componentDidUpdate() {
    this.addContinueTimeout();
    this.addBlockTimeout();
    if (
      this.props.location.state &&
      this.props.location.state.targetUnit &&
      this.props.unit.id === this.props.location.state.targetUnit
    ) {
      $(`#unit-${this.props.location.state.targetUnit}`).css("transition", "transform .3s");
      $("html, body").animate(
        {
          scrollTop: $(`#unit-${this.props.location.state.targetUnit}`).offset().top - 100,
        },
        1000,
        () => {
          $(`#unit-${this.props.location.state.targetUnit}`).css("transform", "scale(1.07)");
          setTimeout(() => {
            $(`#unit-${this.props.location.state.targetUnit}`).css("transform", "scale(1)");
            this.props.history.replace(this.props.location.pathname, {});
          }, 1000);
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.isContinueAllowedTimeout) {
      clearTimeout(this.isContinueAllowedTimeout);
    }
    if (this.isBlockTimeout) {
      clearTimeout(this.isBlockTimeout);
    }
  }

  getUnitButtonStatus = () => {
    if (this.state.isBlockedForThreeMinutes) {
      return null;
    }
    if (this.state.isContinueAllowed) {
      return "CONTINUE";
    }
    if (this.props.unit.status) {
      if (this.props.unit.status === "SECOND_REVIEW") {
        return null;
      }
      if (this.props.unit.status === "SIMPLE_REVIEW") {
        return this.props.isSimpleReviewAllowed ? "SIMPLE_REVIEW" : null;
      }
      return this.props.unit.status;
    }
    if (this.props.isSimpleReviewAllowed) {
      return "SIMPLE_REVIEW";
    }
    return null;
  };

  render() {
    const completed =
      this.props.unit.yourChallengeStatus === "COMPLETED" && this.props.unit.firstReviewStatus === "COMPLETED";
    return (
      <FlexRow
        id={`unit-${this.props.unit}`}
        position="relative"
        backgroundColor={WHITE}
        padding="20px 15px"
        borderRadius={5}
        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
        border={completed ? "1px solid #0FB1A2" : "none"}
      >
        {this.props.unit.disabled && (
          <FlexRow
            top={0}
            left={0}
            width="100%"
            height="100%"
            position="absolute"
            backgroundColor="#ffffffcc"
            zIndex={5}
            justifyContent="center"
            alignItems="center"
          >
            <FlexColumn
              width={64}
              height={64}
              borderRadius={64}
              backgroundColor="#ECECEC"
              justifyContent="center"
              alignItems="center"
            >
              <Icon color="#42424280" icon="lock-outline" size="sm" />
            </FlexColumn>
          </FlexRow>
        )}
        <FlexRow flex={1} alignItems="center" paddingRight={10}>
          <UnitTypeIcon type={this.props.unit.type} isDisabled={this.props.unit.disabled} />
          <ColumnSeparator size="xs" />
          <ColumnSeparator size="sm" />
          <FlexColumn alignItems="flex-start">
            <FlexRow alignItems="center">
              {completed && (
                <React.Fragment>
                  <Completed />
                  <ColumnSeparator size="xs" />
                </React.Fragment>
              )}
              <h1
                style={{
                  margin: 0,
                  fontSize: 16,
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#424242",
                }}
              >
                {this.props.unit.name}
              </h1>
            </FlexRow>
            <Separator size="xs" />
            {this.getUnitButtonStatus() !== null && (
              <PopoverButton
                isOpen={this.props.showFirstAccessTooltip}
                animated={true}
                position="left"
                positionOffset={135}
                content={
                  <TranslatedText
                    as={Paragraph}
                    translateKey="unit.firstAccessTooltip"
                    style={{
                      fontWeight: "bold",
                    }}
                  />
                }
              >
                <UnitButton
                  onClick={() => {
                    this.props.history.replace(`${this.props.match.url}/${this.props.unit.id}/exercise`);
                    addSentryUserAction({
                      ...sentryUserAction,
                      clickedComponent: "UnitButton",
                      action: `Navigate to ${this.props.match.url}/${this.props.unit.id}/exercise`,
                    });
                  }}
                  isDisabled={this.props.unit.disabled}
                  type={
                    {
                      LOCKED: "LOCKED",
                      YOUR_CHALLENGE: "START",
                      FIRST_REVIEW: "START",
                      CONTINUE: "CONTINUE",
                      SIMPLE_REVIEW: "RECAP",
                    }[this.getUnitButtonStatus()]
                  }
                />
              </PopoverButton>
            )}
            {this.state.isBlockedForThreeMinutes && (
              <TranslatedText
                translateKey="unit.blockedDueTime"
                style={{
                  margin: 0,
                  fontSize: 10,
                  lineHeight: "12px",
                  color: "#424242",
                  opacity: 0.5,
                }}
              />
            )}
            {!this.state.isBlockedForThreeMinutes &&
              this.props.unit.yourChallengeStatus === "COMPLETED" &&
              this.props.unit.firstReviewStatus === "BLOCKED" && (
                <TranslatedText
                  translateKey="unit.unblockReviewIn"
                  values={{
                    diff: moment
                      .duration(
                        moment(
                          moment(this.props.unit.defaultFinishedAt)
                            .add(this.props.numberOfDayBeforeFirstReview, "days")
                            .startOf("day")
                            .format("YYYY-MM-DD HH:mm:ss"),
                          "YYYY-MM-DD HH:mm:ss"
                        ).diff(moment(), "days", true),
                        "days"
                      )
                      .humanize(true),
                  }}
                  style={{
                    margin: "5px 0px 0px 0px",
                    fontSize: 10,
                    lineHeight: "12px",
                    color: "#424242",
                    opacity: 0.5,
                  }}
                />
              )}
          </FlexColumn>
        </FlexRow>
        <FlexRow>
          {!this.props.isSpeechRecognitionDisabled && (
            <>
              <UnitListItemColumnSeparator />
              <UnitTypeAbilities
                unit={this.props.unit.id}
                type={this.props.unit.type}
                isDisabled={this.props.unit.disabled}
              />
            </>
          )}
          <UnitListItemColumnSeparator />
          <UnitSteps unit={this.props.unit} />
        </FlexRow>
      </FlexRow>
    );
  }
}

export default withRouter(UnitListItem);
