import React from "react";
import PropTypes from "prop-types";

const FlexColumn = ({ children, className, ...rest }) => (
  <div
    className={className}
    style={{
      display: "flex",
      flexDirection: "column",
      ...rest,
    }}
  >
    {children}
  </div>
);

FlexColumn.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FlexColumn;
