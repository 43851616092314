import React from "react";
import Lottie from "react-lottie";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import SpeechPracticeAnimation from "../../lotties/speech-practice-animation.json";

function SpeechPracticeRender(props) {
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));

  return (
    <ExerciseItemPanel alignItems="center">
      <Lottie
        width={260}
        isPaused={!playingId || props.itemId !== playingId}
        options={{
          animationData: SpeechPracticeAnimation,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
      />
    </ExerciseItemPanel>
  );
}

export default function SpeechPracticeExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SpeechPracticeRender key={id} itemId={id} />);
}
