import trim from "lodash/trim";
import shuffle from "lodash/shuffle";
import orderBy from "lodash/orderBy";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * start the execution of a mastery test
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.module - the student id
 * @param {string} payload.masteryTest - the mastery test id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const startMasteryTestExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const result = await request({
        url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions`,
        method: "post",
      });

      return {
        masteryTestExecution: result.masteryTestExecution,
        items: orderBy(
          result.items.map((masteryTestItem) => ({
            ...masteryTestItem,
            item: {
              ...masteryTestItem.item,
              text: trim(masteryTestItem.item.text),
              answers: shuffle(masteryTestItem.item.answers),
            },
          })),
          "order",
          "asc"
        ),
      };
    },
    "error_start_mastery_test_execution",
    "Unexpected error to start Mastery Test execution."
  );
};

/**
 * save the mastery test item execution
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the student id
 * @param {!string} payload.masteryTest - the masteryTest id
 * @param {!string} payload.masteryTestExecution - the mastery test execution id
 * @param {!string} payload.item - the unitItem id
 * @param {!boolean} payload.correct - if answer is correct or not
 * @param {!number} payload.errorCount - the count of errors
 * @param {!number} payload.repeatCount - the count of repeat usage
 * @param {!number} payload.recordCount - the count of record usage
 * @param {!number} payload.listenCount - the count of listen usage
 * @param {number} payload.userAwayCount - the count of user away notifications
 * @param {!Object[]} payload.attempts - the count of translate usage
 * @param {!(string|number|boolean)} payload.attempts[].answer - the user answer, could be the SR score, text, or true/false
 * @param {!boolean} payload.attempts[].correct - if the attempt is correct or not
 * @param {blob} payload.audio - the last SR blob
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const saveMasteryTestItemExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      item: {
        presence: {
          allowEmpty: false,
        },
      },
      correct: {
        presence: {
          allowEmpty: false,
        },
      },
      errorCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      repeatCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      recordCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      listenCount: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      attempts: {
        presence: {
          allowEmpty: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const formData = new FormData();

      formData.append("item", payload.item);
      formData.append("correct", payload.correct);
      formData.append("errorCount", payload.errorCount);
      formData.append("repeatCount", payload.repeatCount);
      formData.append("recordCount", payload.recordCount);
      formData.append("listenCount", payload.listenCount);

      payload.attempts.forEach((attempt, index) => {
        formData.append(`attempts[${index}][answer]`, attempt.answer);
        formData.append(`attempts[${index}][correct]`, attempt.correct);
      });

      if (payload.audio) {
        formData.append("audio", payload.audio);
      }

      return await request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions/${payload.masteryTestExecution}/answers`,
        method: "post",
        data: formData,
      });
    },
    "error_save_mastery_test_execution_answer",
    "Unexpected error to save the answer."
  );
};

/**
 * end the execution of a mastery test and get the score
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.masteryTest - the unit id
 * @param {!string} payload.masteryTestExecution - the unitResult id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const endMasteryTestExecution = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions/${payload.masteryTestExecution}`,
        method: "put",
      });
    },
    "error_end_mastery_test_execution",
    "Unexpected error to end the Mastery Test execution."
  );
};

export const reportMasteryTestExecutionError = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions/${payload.masteryTestExecution}/report-error`,
        method: "patch",
      });
    },
    "error_report_mastery_test_execution_error",
    "Unexpected error to report the Mastery Test execution error."
  );
};

/**
 * end the execution and set the mastery test as failed
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.masteryTest - the unit id
 * @param {!string} payload.masteryTestExecution - the unitResult id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setMasteryTestExecutionAsFailed = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions/${payload.masteryTestExecution}/failed`,
        method: "put",
      });
    },
    "error_fail_mastery_test_execution",
    "Unexpected error to fail the Mastery Test execution."
  );
};

/**
 * get mastery test execution performance
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.masteryTest - the mastery test id
 * @param {!string} payload.masteryTestExecution - the mastery test execution id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getMasteryTestResultPerformance = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTest: {
        presence: {
          allowEmpty: false,
        },
      },
      masteryTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(async () => {
    return await request({
      url: `modules/${payload.module}/mastery-tests/${payload.masteryTest}/executions/${payload.masteryTestExecution}/performance`,
      method: "get",
      isV2: true,
    });
  });
};
