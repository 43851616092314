import React, { Component } from "react";
import PropTypes from "prop-types";
import { detect } from "detect-browser";
import Separator from "./Separator";
import AudioFile from "../audios/mute-sound.mp3";
import SafariConfig from "../images/safari-audio-config.gif";
import TranslatedText from "./TranslatedText";
import withTranslation from "./withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import bgLogin from "../images/bg-login.png";

class CheckAudioPermissionContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { audioAllowed: true };

  componentDidMount() {
    if (detect().name === "safari") {
      this.audio = new Audio(AudioFile);
      const playPromise = this.audio.play();
      if (playPromise) {
        playPromise
          .then(() => this.audio.pause())
          .catch((error) => {
            console.log(error);
            this.setState({
              audioAllowed: false,
            });
          });
      }
    }
  }

  isAllowedRoute = () => (this.props.allowedRoutes || []).some((r) => r === window.location.pathname);

  render() {
    if (this.isAllowedRoute() || this.state.audioAllowed) {
      return this.props.children;
    }
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, logoUrl, domain }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
              backgroundColor: primaryColor,
              overflow: "hidden",
            }}
          >
            <img
              src={bgLogin}
              alt="bg"
              style={{
                position: "fixed",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                background: "#fff",
                borderRadius: 3,
                padding: "80px 60px",
                maxWidth: "55%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
                zIndex: 1,
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{
                  objectFit: "scale-down",
                  height: "auto",
                  width: 300,
                }}
              />
              <Separator size="md" />
              <Separator size="lg" />
              <p
                style={{
                  margin: 0,
                  color: "#444",
                  textAlign: "center",
                }}
              >
                <TranslatedText
                  as="span"
                  translateKey="error.checkAudioPermission.description"
                  values={{
                    browserName: detect().name,
                  }}
                />
                {detect().name === "safari" && (
                  <TranslatedText as="span" translateKey="error.checkAudioPermission.safari.description" />
                )}
                {detect().name === "chrome" && (
                  <TranslatedText as="span" translateKey="error.checkAudioPermission.chrome.description" />
                )}
              </p>
              <Separator size="lg" />
              {detect().name === "safari" && (
                <div>
                  <ul
                    style={{
                      padding: 0,
                      listStyle: "decimal",
                    }}
                  >
                    <li
                      dangerouslySetInnerHTML={{
                        __html: this.props.getTranslation("error.checkAudioPermission.safari.step1"),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: this.props.getTranslation("error.checkAudioPermission.safari.step2"),
                      }}
                    />
                    <li
                      dangerouslySetInnerHTML={{
                        __html: this.props.getTranslation("error.checkAudioPermission.safari.step3"),
                      }}
                    />
                  </ul>
                  {domain === "flexge.com" && (
                    <>
                      <Separator size="lg" />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <img
                          alt="config-safari"
                          src={SafariConfig}
                          style={{
                            width: "100%",
                            maxWidth: 500,
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withTranslation(CheckAudioPermissionContainer);
