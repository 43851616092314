import { round, head } from "lodash";
import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getProfile } from "student-front-commons/src/services/profileService";
import {
  setCertificationTestAsFailed,
  setCertificationTestAsFinished,
} from "student-front-commons/src/services/certificationTestExecutionService";
import { requestMergeCertificationTestResult } from "../actionCreators/certificationTestResult";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import browserHistory from "../browserHistory";
import {
  CERTIFICATION_TEST_ABILITY_EXECUTION_FORM,
  CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import { addSentryUserAction, logError } from "../util";

const sentryUserAction = { mainComponent: "endCertificationTestAbilityExecutionAnswerFlow" };

export default function* () {
  yield takeLatest(getFlowStart(END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          if (sessionStorage.getItem("id") === "tasting_user") {
            browserHistory.push(`${head(browserHistory.location.pathname.split("/abilities"))}/abilities`);
            return;
          }

          const certificationTestExecutionForm = yield select(getForm(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM));

          if (["READING", "LISTENING"].some((ability) => ability === certificationTestExecutionForm.values.ability)) {
            const answers = certificationTestExecutionForm.values.answers;
            const score = round((answers.filter((answer) => answer.correct).length / answers.length) * 100);

            if (score < 70) {
              const saveAnswerFlow = yield select(getFlow(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW));
              if (saveAnswerFlow.isPending) {
                yield take(getFlowEnd(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW));
              }

              yield call(setCertificationTestAsFailed, {
                certificationTest: certificationTestExecutionForm.values.certificationTest,
              });

              const profileResult = yield call(getProfile);
              yield put(mergeEntities(profileResult.entities));

              browserHistory.push(`${head(browserHistory.location.pathname.split("/abilities"))}/result`);
              addSentryUserAction({
                ...sentryUserAction,
                clickedComponent: "None",
                action: `Certification Test failed. Navigate to: ${head(
                  browserHistory.location.pathname.split("/abilities")
                )}/result`,
              });
              return;
            }
          }

          const certificationTestResult = yield select((state) => state.certificationTestResult);
          const abilityIndex = certificationTestResult.abilityStatus.findIndex(
            (abilityStatus) => abilityStatus.type === certificationTestExecutionForm.values.ability
          );

          certificationTestResult.abilityStatus[abilityIndex].status = "FINISHED";
          if (abilityIndex < certificationTestResult.abilityStatus.length - 1) {
            certificationTestResult.abilityStatus[abilityIndex + 1].status = "AVAILABLE";
          }
          yield put(requestMergeCertificationTestResult(certificationTestResult));

          if (abilityIndex === certificationTestResult.abilityStatus.length - 1) {
            certificationTestResult.finishedAt = new Date();
            yield put(requestMergeCertificationTestResult(certificationTestResult));

            yield call(setCertificationTestAsFinished, {
              certificationTest: certificationTestExecutionForm.values.certificationTest,
            });

            const profileResult = yield call(getProfile);
            yield put(mergeEntities(profileResult.entities));
          }

          browserHistory.push(`${head(browserHistory.location.pathname.split("/abilities"))}/abilities`);
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Certification Test finished. Navigate to: ${head(
              browserHistory.location.pathname.split("/abilities")
            )}/abilities`,
          });
        } catch (error) {
          logError({ error, flow: END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW });
          // yield put(startFlow(HANDLE_ERROR_FLOW, {
          //   error: 'error_save_answer',
          //   retryFlow: {
          //     id: SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          //   }
          // }));
        } finally {
          yield put(endFlow(END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW));
        }
      }),
    });
  });
}
