import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import CountDownTimer from "../../core/CountDownTimer";
import {
  OLD_PLAY_ITEM_AUDIO_FLOW,
  PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  START_PRACTICE_TEST_ITEM_TOUR,
} from "../../consts";

export default class TestItemTimerContainer extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    time: PropTypes.number.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    itemType: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    isDisabled: false,
  };

  getTimerDelay = () => {
    return ["GAP_FILL", "GAP_FILL_MULTIPLE", "GAP_FILL_SELECT", "UNSCRAMBLE_DRAG_AND_DROP"].find(
      (type) => type === this.props.itemType
    )
      ? 3000
      : 0;
  };

  render() {
    return (
      <Flow autoStart={false} id={START_PRACTICE_TEST_ITEM_TOUR}>
        {({ isPending: isInTour }) => (
          <Flow autoStart={false} id={PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW}>
            {({ isPending: isPaused }) => (
              <Flow autoStart={false} id={OLD_PLAY_ITEM_AUDIO_FLOW}>
                {({ isPending, getParams }) => (
                  <CountDownTimer
                    key={this.props.id}
                    delay={this.getTimerDelay()}
                    isDisabled={
                      this.props.isSubmitting ||
                      this.props.isDisabled ||
                      isPaused ||
                      isInTour ||
                      (isPending && !!get(getParams(), "initialPlay", false))
                    }
                    time={this.props.time}
                    onTimerEnd={this.props.onTimerEnd}
                  />
                )}
              </Flow>
            )}
          </Flow>
        )}
      </Flow>
    );
  }
}
