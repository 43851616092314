import { CLEAN_PLACEMENT_TEST_RESULT, MERGE_PLACEMENT_TEST_RESULT } from "../consts/actions";

export const requestMergePlacementTestResult = (placementTestResult) => ({
  type: MERGE_PLACEMENT_TEST_RESULT,
  payload: {
    placementTestResult,
  },
});

export const requestCleanPlacementTestResult = () => ({
  type: CLEAN_PLACEMENT_TEST_RESULT,
});
