import React from "react";
import { withRouter } from "react-router-dom";
import { getCourseStats } from "student-front-commons/src/services/courseService";
import FetchData from "student-front-commons/src/components/FetchData";
import CourseSummaryStats from "./CourseSummaryStats";
import Async from "../../core/Async";

const CourseSummaryStatsContainer = (props) => (
  <FetchData
    service={getCourseStats}
    params={{
      id: sessionStorage.getItem("id"),
      course: props.match.params.idCourse,
    }}
  >
    {({ isFetching, data }) => (
      <Async fetching={isFetching}>
        <CourseSummaryStats stats={data || {}} />
      </Async>
    )}
  </FetchData>
);

export default withRouter(CourseSummaryStatsContainer);
