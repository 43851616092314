import { get } from "lodash";
import queryString from "query-string";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useService } from "student-front-commons/src/hooks";
import Async from "../../../core/Async";
import PlacementTestRegisterScene from "./PlacementTestRegisterScene";

export default function PlacementTestRegisterSceneContainer(props) {
  const { slug } = useParams();
  const urlQuery = queryString.parse(props.location.search);
  const [data, setData] = useState({});
  const history = useHistory();

  const service = () =>
    axios({
      url: `${process.env.REACT_APP_API_URL}/v2/auth/placement/${slug}/validate`,
      method: "post",
    });

  // deprecated
  const tokenService = ({ token, version }) =>
    axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${process.env.REACT_APP_API_URL}/${version}/auth/validate-placement-token`,
      method: "post",
    });

  const [isFetchingData, loadData] = useService(slug ? service : tokenService, {
    onData: ({ data }) => {
      setData(data);
    },
    onError: () => history.push("/login"),
  });

  useEffect(() => {
    sessionStorage.clear();

    const token = get(urlQuery, "token");
    sessionStorage.setItem("placementRegisterToken", token);
    loadData({ token, version: props.location.pathname === "/v2/placement-register" ? "v2" : "v1" });
  }, []);

  return (
    <Async fetching={isFetchingData}>
      <PlacementTestRegisterScene
        data={data}
        version={props.location.pathname === "/v2/placement-register" ? "v2" : "v1"}
        slug={slug}
      />
    </Async>
  );
}
