import { CLEAN_BONUS_TEST_RESULT, MERGE_BONUS_TEST_RESULT } from "../consts/actions";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGE_BONUS_TEST_RESULT: {
      return {
        ...state,
        ...action.payload.bonusTestResult,
      };
    }
    case CLEAN_BONUS_TEST_RESULT: {
      return {};
    }
    default:
      return state;
  }
};
