import React from "react";
import PropTypes from "prop-types";
import Color from "color";

const TimeLineListItem = (props) => (
  <li
    style={{
      display: "flex",
      alignItems: "center",
      padding: "10px 20px 10px 0",
    }}
  >
    <span
      style={{
        borderRadius: "100%",
        position: "relative",
        backgroundColor: props.color,
        width: {
          sm: 14,
          md: 21,
          lg: 28,
        }[props.size],
        height: {
          sm: 13,
          md: 19,
          lg: 25,
        }[props.size],
        marginRight: {
          sm: 15,
          md: 8,
          lg: 0,
        }[props.size],
        left: {
          sm: "-6px",
          md: "-8px",
          lg: "-12px",
        }[props.size],
      }}
    />
    <div
      style={{
        width: "100%",
        height: 40,
        backgroundColor: props.highlight ? Color(props.color).fade(0.7) : props.color,
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 20px 0px 10px",
      }}
    >
      {props.children}
    </div>
  </li>
);

TimeLineListItem.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  highlight: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

TimeLineListItem.defaultProps = {
  size: "sm",
  color: "#f2f2f2",
  highlight: false,
};

export default TimeLineListItem;
