import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import { WHITE } from "../../consts/color";
import UserAvatar from "../../core/UserAvatar";
import ColumnSeparator from "../../core/ColumnSeparator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../../core/TranslatedText";

const ClosedRankingListItem = (props) => (
  <div
    style={{
      backgroundColor: WHITE,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "10px 40px 10px 20px",
      position: "relative",
    }}
  >
    {props.student.id === sessionStorage.getItem("id") && (
      <WhitelabelContext.Consumer>
        {({ lightColor }) => (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: lightColor,
            }}
          />
        )}
      </WhitelabelContext.Consumer>
    )}
    <div
      style={{
        display: "flex",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <span
        style={{
          fontSize: 16,
          fontWeight: "bold",
          color: "#1f1f1f",
          width: 40,
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        {props.student.position ? props.student.position : "-"}
        {!!props.student.position && (
          <small>
            {numeral(props.student.position).format("0o").substring(props.student.position.toString().length)}
          </small>
        )}
      </span>
      <ColumnSeparator size="sm" />
      <UserAvatar src={props.student.profilePicture} size="sm" />
      <ColumnSeparator size="sm" />
      <span
        style={{
          fontSize: 16,
          color: "#1f1f1f",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxWidth: 330,
        }}
      >
        {props.student.nickname || props.student.name}
      </span>
    </div>
    <div
      style={{
        backgroundColor: "#ddd",
        borderRadius: 10,
        padding: "0px 20px",
        zIndex: 1,
      }}
    >
      <TranslatedText
        translateKey="closedRanking.points"
        values={{
          points: numeral(props.student.points || 0)
            .format("0,0")
            .replace(",", "."),
        }}
        as="span"
        style={{
          color: "#303030",
          fontSize: 14,
          fontWeight: "bold",
        }}
      />
    </div>
  </div>
);

ClosedRankingListItem.propTypes = {
  student: PropTypes.shape({}).isRequired,
};

export default ClosedRankingListItem;
