import React from "react";
import PropTypes from "prop-types";
import UnitSectionListItem from "./UnitSectionListItem";
import Separator from "../../core/Separator";
import MasteryTestListItem from "./MasteryTestListItem";

const UnitSectionList = (props) =>
  props.groups.map((group) => (
    <React.Fragment key={`group-${group.value}`}>
      <UnitSectionListItem group={group} />
      <MasteryTestListItem group={group.value} />
      <Separator size="xs" />
      <Separator size="sm" />
    </React.Fragment>
  ));

UnitSectionList.propTypes = {
  groups: PropTypes.array.isRequired,
};

export default UnitSectionList;
