import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { startCertificationTestExecution } from "student-front-commons/src/services/certificationTestExecutionService";
import {
  requestCleanCertificationTestResult,
  requestMergeCertificationTestResult,
} from "../actionCreators/certificationTestResult";
import { START_CERTIFICATION_TEST_EXECUTION_FLOW } from "../consts";
import { logError, addSentryUserAction } from "../util";

const sentryUserAction = { mainComponent: "startCertificationTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(START_CERTIFICATION_TEST_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_CERTIFICATION_TEST_EXECUTION_FLOW));
    try {
      yield put(requestCleanCertificationTestResult());

      const result = yield call(startCertificationTestExecution, {
        certificationTest: flow.params.certificationTest,
      });

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Certification Test Started`,
      });

      yield put(
        requestMergeCertificationTestResult({
          certificationTest: result.id,
          abilityStatus: Object.keys(result.status).reduce(
            (acc, key) => [...acc, { type: key.toUpperCase(), status: result.status[key] }],
            []
          ),
          startedAt: result.startedAt,
          finishedAt: null,
        })
      );
    } catch (error) {
      logError({ error, flow: START_CERTIFICATION_TEST_EXECUTION_FLOW });
      // yield put(startFlow(HANDLE_ERROR_FLOW, {
      //   error: error.message,
      //   retryFlow: {
      //     id: START_MASTERY_TEST_EXECUTION_FLOW,
      //     params: flow.params,
      //   }
      // }));
    } finally {
      yield put(endFlow(START_CERTIFICATION_TEST_EXECUTION_FLOW));
    }
  });
}
