import React from "react";
import CongratulationsBg from "../../images/closed-ranking-congratulations-bg.png";
import ColumnSeparator from "../../core/ColumnSeparator";
import TranslatedText from "../../core/TranslatedText";
import { WHITE } from "../../consts/color";
import Separator from "../../core/Separator";

const ClosedRankingCongratulations = () => (
  <React.Fragment>
    <div
      style={{
        display: "flex",
        alignItems: "baseline",
      }}
    >
      <img
        alt="effect"
        src={CongratulationsBg}
        style={{
          width: 61,
          height: "auto",
        }}
      />
      <ColumnSeparator size="xs" />
      <TranslatedText
        translateKey="closedRanking.congratulations"
        style={{
          fontWeight: 900,
          fontSize: 56,
          margin: 0,
          color: WHITE,
        }}
        as="h1"
      />
      <ColumnSeparator size="xs" />
      <img
        alt="effect"
        src={CongratulationsBg}
        style={{
          width: 61,
          height: "auto",
          transform: "rotateY(180deg)",
        }}
      />
    </div>
    <Separator size="sm" />
  </React.Fragment>
);

export default ClosedRankingCongratulations;
