import round from "lodash/round";
import { put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { END_TASTING_UNIT_EXECUTION_FLOW } from "../consts";
import { logError } from "../util";
import { endExecution } from "student-front-commons/src/actions/execution";

export default function* () {
  yield takeLatest(getFlowStart(END_TASTING_UNIT_EXECUTION_FLOW), function* () {
    try {
      const unitExecution = yield select((state) => state.executions);
      const unit = yield select(getEntityById("unit", unitExecution.unit));

      const total = unitExecution.answers.length;
      const correct = unitExecution.answers.filter((answer) => answer.correct).length;

      const result = {
        score: round((correct / total) * 100),
        points: unit.defaultPoints,
      };

      yield put(endExecution(result));
      sessionStorage.clear();
    } catch (error) {
      logError({ error, flow: END_TASTING_UNIT_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(END_TASTING_UNIT_EXECUTION_FLOW));
    }
  });
}
