import React from "react";
import PropTypes from "prop-types";
import NotificationListItem from "./NotificationListItem";
import TranslatedText from "../TranslatedText";
import { GREY_5 } from "../../consts/color";

const NotificationList = (props) => (
  <div
    style={{
      height: "100%",
    }}
  >
    {props.notifications.length ? (
      props.notifications.map((notification, index) => (
        <NotificationListItem
          key={`notification-${notification.id}`}
          notification={notification}
          configurations={props.configurations}
        />
      ))
    ) : (
      <TranslatedText
        translateKey="notifications.noData"
        style={{
          textAlign: "center",
          color: GREY_5,
          padding: "10px 0px",
        }}
      />
    )}
  </div>
);

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  configurations: PropTypes.shape({}).isRequired,
};

export default NotificationList;
