import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * get bonus test rewards by course
 *
 * @param {object} payload - The user current course id
 * @param {string} payload.idCourse - The user current course id
 *
 * @returns {Promise<*>}
 */
export const getBonusTestRewards = async (payload) => {
  validate(
    {
      idCourse: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(async () => {
    return await request(
      {
        url: `courses/${payload.idCourse}/bonus-tests/rewards`,
        method: "get",
      },
      "error_get_bonus_test_rewards",
      "Unexpected error getting bonus test rewards."
    );
  });
};

/**
 * get bonus test history
 *
 * @returns {Promise<*>}
 */
export const getBonusTestHistory = async () =>
  await safeCall(async () => {
    return await request(
      {
        url: `/bonus-tests/history`,
        method: "get",
      },
      "error_get_bonus_test_history",
      "Unexpected error getting bonus test history."
    );
  });

/**
 * start a Bonus test for the student
 *
 * @param {Object} payload - The object with all the params
 *
 * @returns {Promise<*>}
 */
export const startBonusTest = async () => {
  return await safeCall(
    async () => {
      return await request({
        url: "bonus-tests/start",
        method: "post",
      });
    },
    "error_start_bonus_test",
    "Unexpected error to start Bonus Test execution."
  );
};

/**
 * save a Bonus test item execution for the student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.bonusTestExecution - the bonus test execution id
 * @param {!string} payload.item - the unitItem id
 * @param {!boolean} payload.correct - if answer is correct or not
 * @param {!boolean} payload.answer - the student answer
 *
 * @returns {Promise<*>}
 */
export const saveBonusTestItemExecution = async (payload) => {
  validate(
    {
      bonusTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      item: {
        presence: {
          allowEmpty: false,
        },
      },
      correct: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `bonus-tests/${payload.bonusTestExecution}/answers`,
        method: "patch",
        data: {
          item: payload.item,
          answer: payload.answer,
          correct: payload.correct,
        },
      });
    },
    "error_save_bonus_test_item_answer",
    "Unexpected error to save the answer."
  );
};

/**
 * end a Bonus test execution for the student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.bonusTestExecution - the bonus test execution id
 * @param {!string} payload.reachedLevel - the bonus test level id
 * @param {!string} payload.stopReason - Why the test stoped
 *
 * @returns {Promise<*>}
 */
export const endBonusTestExecution = async (payload) => {
  validate(
    {
      bonusTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      stopReason: {
        presence: {
          allowEmpty: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `bonus-tests/${payload.bonusTestExecution}/end`,
        method: "put",
        data: {
          reachedLevel: payload.reachedLevel,
          stopReason: payload.stopReason,
        },
      });
    },
    "error_end_bonus_test_execution",
    "Unexpected error to end the Bonus test execution."
  );
};

/**
 * get bonus test result
 *
 * @returns {Promise<*>}
 */
export const getCurrentBonusTest = async (payload) => {
  validate(
    {
      idCourse: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(async () => {
    return await request(
      {
        url: `courses/${payload.idCourse}/bonus-tests/current`,
        method: "get",
      },
      "error_get_bonus_test_history",
      "Unexpected error getting bonus test history."
    );
  });
};
