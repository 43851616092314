import React, { useCallback } from "react";
import ExerciseButton from "./ExerciseButton";
import AnswerImage from "./AnswerImage";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW, SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function SingleChoiceImageRender(props) {
  const dispatch = useDispatch();
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [, startCheckAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const linkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const selectedAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const handleSubmit = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(props.itemId, { answer }));
      startCheckAnswerFlow();
    },
    [dispatch, startCheckAnswerFlow, props.itemId]
  );

  return (
    <ExerciseItemPanel>
      <div
        style={{
          display: "grid",
          gridGap: "5%",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          marginBottom: 180,
        }}
      >
        {linkedAnswers.map((answer) => (
          <ExerciseButton
            key={answer.id}
            disabled={isDisabled || !!currentInstructionStep || isExecutionValidated}
            highlight={playingId === answer.id || ((isExecutionValidated || isSaving) && answer.correct)}
            isWrong={(isExecutionValidated || isSaving) && !answer.correct && selectedAnswer === answer.text}
            onClick={() => handleSubmit(answer)}
          >
            <AnswerImage
              itemId={props.itemId}
              answerId={answer.id}
              style={{
                height: 175,
              }}
            />
          </ExerciseButton>
        ))}
      </div>
    </ExerciseItemPanel>
  );
}

export default function SingleChoiceImageItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SingleChoiceImageRender key={id} itemId={id} />);
}
