import React from "react";
import PropTypes from "prop-types";
import { WHITE } from "../consts/color";
import Separator from "./Separator";

const Panel = (props) => (
  <div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {props.title && (
        <h1
          style={{
            color: "#37474f",
            fontSize: 13,
            textTransform: "uppercase",
            lineHeight: "24px",
            fontWeight: "bold",
            margin: 0,
            marginRight: 15,
          }}
        >
          {props.title}
        </h1>
      )}
      {props.headerOptions && props.headerOptions}
    </div>
    {(props.title || props.headerOptions) && <Separator size="xs" />}
    <div
      style={{
        position: "relative",
        backgroundColor: WHITE,
        borderRadius: 3,
        boxSizing: "border-box",
        padding: {
          xs: "5px",
          sm: "10px",
          md: "15px",
          lg: "20px",
          xl: "40px",
          none: "0px",
        }[props.padding],
        boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
        transition: "all .3s",
      }}
    >
      {props.children}
    </div>
  </div>
);

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  headerOptions: PropTypes.node,
  padding: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "none"]),
};

Panel.defaultProps = {
  title: null,
  headerOptions: null,
  padding: "md",
};

export default Panel;
