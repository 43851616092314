import { put, select, takeLatest } from "redux-saga/effects";
import { get, head } from "lodash";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getNextItemsToExecute } from "student-front-commons/src/selectors/execution";
import { startItemExecution } from "student-front-commons/src/actions/itemExecution";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { GET_NEXT_UNIT_ITEM_EXECUTION_FLOW, SELECT_ITEM_FLOW, USER_AWAY_TIMEOUT_FLOW } from "../consts";
import { addSentryUserAction, logError } from "../util";

const sentryUserAction = { mainComponent: "getNextUnitItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW), function* () {
    try {
      const currentOrder = yield select((state) => state.executions.currentOrder);
      const allUnitItems = yield select((state) => state.executions.associativeItems);

      const nextUnitItems = yield select(getNextItemsToExecute);
      const nextItemType = get(head(nextUnitItems), "item.type.key", "");

      // here we get the last item type IMAGE to show the thumbnail
      const lastUnitItemTypeImage = allUnitItems
        .filter((unitItem) => unitItem.order - 1 < currentOrder)
        .reverse()
        .find((unitItem) => nextItemType !== "IMAGE" && unitItem.item.type.key === "IMAGE");

      yield put(startItemExecution(nextUnitItems, { imageItem: lastUnitItemTypeImage }));

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      yield put(startFlow(SELECT_ITEM_FLOW, { initialPlay: true }));
      yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));
    } catch (error) {
      logError({ error, flow: GET_NEXT_UNIT_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW));
    }
  });
}
