import React from "react";
import PropTypes from "prop-types";
import Form from "student-front-commons/src/components/Form";
import { isRequired } from "student-front-commons/src/validators";
import ImagePicker from "./form/ImagePicker";
import get from "lodash/get";
import { UPDATE_PROFILE_PICTURE_FORM } from "../consts";

const ProfilePictureForm = (props) => (
  <Form
    id={UPDATE_PROFILE_PICTURE_FORM}
    onSubmit={props.onSubmit}
    initialValues={props.initialValues}
    validations={{
      image: [isRequired],
    }}
  >
    {({ handleSubmit, handleChange, values }) => (
      <ImagePicker
        disabled={props.initialValues.demoStudent}
        isSubmitting={props.isSubmitting}
        src={get(values, "profilePicture", null)}
        onConfirmChanges={(blob) => {
          handleChange("image", blob);
          handleSubmit();
        }}
      />
    )}
  </Form>
);

ProfilePictureForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({ demoStudent: PropTypes.bool }).isRequired,
};

export default ProfilePictureForm;
