import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";

const AchievementList = (props) => (
  <div
    style={{
      display: "inline-block",
      padding: "20px 0px",
      boxSizing: "border-box",
      verticalAlign: "top",
      width: "100%",
    }}
  >
    <TranslatedText
      as="h1"
      translateKey={props.title}
      style={{
        fontSize: 12,
        color: "rgba(69, 90, 100, 0.5)",
        margin: 0,
        textTransform: "uppercase",
      }}
    />
    <Separator size="xs" />
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 10,
      }}
    >
      {props.children}
    </div>
  </div>
);

AchievementList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AchievementList;
