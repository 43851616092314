import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import MessageChatList from "./MessageChatList";
import Async from "../Async";
import MessageChatListScroll from "./MessageChatListScroll";
import { LOAD_MESSAGES_FLOW } from "../../consts";

class MessageChatListContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={true}
        id={LOAD_MESSAGES_FLOW}
        params={{
          messageChannel: get(this.props.history, "location.state.messageChannel", ""),
        }}
      >
        {({ isPending }) => (
          <MessageChatListScroll scrollBottom={!isPending}>
            <Entity name="profile">
              {({ getById: getProfileById }) => (
                <Entity name="message">
                  {({ getAll }) => (
                    <Async fetching={isPending}>
                      <MessageChatList
                        messages={orderBy(
                          getAll().filter(
                            (message) =>
                              message.messageChannel === get(this.props.history, "location.state.messageChannel", "")
                          ),
                          ["sentAt"],
                          ["asc"]
                        ).map((message) => ({
                          ...message,
                          sender: getProfileById(message.sender),
                        }))}
                      />
                    </Async>
                  )}
                </Entity>
              )}
            </Entity>
          </MessageChatListScroll>
        )}
      </Flow>
    );
  }
}

export default withRouter(MessageChatListContainer);
