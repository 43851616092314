import React from "react";
import PropTypes from "prop-types";
import Separator from "../Separator";
import ScrolledContent from "../ScrolledContent";

const MessageChatListScroll = (props) => (
  <div
    style={{
      margin: 0,
      backgroundColor: "#f1f1f1",
      height: 395,
      boxShadow: "rgba(0, 0, 0, 0.1) 0px -16px 20px -10px inset",
    }}
  >
    <ScrolledContent
      vertical
      scrollBottom={props.scrollBottom}
      style={{
        height: "100%",
        maxHeight: 395,
        padding: "0px 30px 0px 10px",
      }}
    >
      <Separator />
      {props.children}
      <Separator />
    </ScrolledContent>
  </div>
);

MessageChatListScroll.propTypes = {
  scrollBottom: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default MessageChatListScroll;
