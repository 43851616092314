import React, { Component } from "react";
import PageableScroll from "../PageableScroll";
import NotificationTitle from "./NotificationTitle";
import NotificationListContainer from "./NotificationListContainer";

class NotificationScene extends Component {
  render() {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <NotificationTitle />
        <PageableScroll pageHeight={470}>{({ page }) => <NotificationListContainer page={page} />}</PageableScroll>
      </div>
    );
  }
}

export default NotificationScene;
