import React from "react";
import PropTypes from "prop-types";

export default function BonusTestContent(props) {
  return (
    <div
      className="container"
      style={{
        paddingTop: 20,
        paddingBottom: 160,
      }}
    >
      {props.children}
    </div>
  );
}

BonusTestContent.propTypes = {
  children: PropTypes.node.isRequired,
};
