import { call, put, select, takeLatest } from "redux-saga/effects";
import { initForm } from "student-front-commons/src/actions/form";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { sendMessage } from "student-front-commons/src/services/messageService";
import { MESSAGE_FORM, SEND_MESSAGE_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(SEND_MESSAGE_FLOW), function* () {
    try {
      const flow = yield select(getFlow(SEND_MESSAGE_FLOW));
      const form = yield select(getForm(MESSAGE_FORM));

      const message = yield call(sendMessage, {
        idMessageChannel: flow.params.messageChannel,
        text: form.values.text,
      });

      yield put(mergeEntities(message.entities));
      yield put(initForm(MESSAGE_FORM));
    } catch (error) {
      logError({ error, flow: SEND_MESSAGE_FLOW });
    } finally {
      yield put(endFlow(SEND_MESSAGE_FLOW));
    }
  });
}
