import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CourseThemeContext from "./CourseThemeContext";
import Icon from "./Icon";

const BreadcrumbItem = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          color: primary,
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        {props.icon && (
          <Link to={props.to}>
            <Icon icon={props.icon} cursor="pointer" />
          </Link>
        )}
        {props.label && props.to && (
          <Link to={props.to}>
            <span
              style={{
                fontSize: 14,
                fontWeight: 700,
              }}
            >
              {props.label}
            </span>
          </Link>
        )}
        {props.label && !props.to && (
          <span
            style={{
              fontSize: 14,
              fontWeight: 700,
            }}
          >
            {props.label}
          </span>
        )}
        {!props.last && <Icon icon="chevron-right" size="xs" />}
      </div>
    )}
  </CourseThemeContext.Consumer>
);

BreadcrumbItem.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  last: PropTypes.bool,
};

BreadcrumbItem.defaultProps = {
  to: null,
  label: null,
  icon: null,
  last: false,
};

export default BreadcrumbItem;
