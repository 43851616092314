import Styled from "styled-components";

const PracticeTestPanelTitle = Styled.div`
width: 100%;
color: ${(props) => props.color};
padding: 0px;
font-size: 28px;
position: relative;
`;

export default PracticeTestPanelTitle;
