import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { startFlow, endFlow } from "student-front-commons/src/actions/flow";
import { getInstructionAudios } from "student-front-commons/src/services/instructionAudiosService";
import { getInstructionVideos } from "student-front-commons/src/services/instructionVideoService";
import { getConfiguration } from "student-front-commons/src/services/configurationService";
import { insertAudio } from "../stores/audio-store";
import {
  requestMergeConfiguration,
  requestMergeInstructionAudios,
  requestMergeInstructionVideos,
} from "student-front-commons/src/actions/configuration";
import { COMPANY_ALLOWED_TO_NEW_PLACEMENT, LOAD_CONFIGURATION_FLOW } from "../consts";
import { isFinite } from "lodash";
import { logError } from "../util";

let errorCount = 0;

export default function* () {
  yield takeLatest(getFlowStart(LOAD_CONFIGURATION_FLOW), function* () {
    try {
      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));

      let instructionVideoKeys = ["WELCOME", "MODULE_LIST", "UNIT_LIST", "OPTIONAL_REVIEW"];

      if (!isFinite(profile?.initialEnglishLevel) || profile?.company === COMPANY_ALLOWED_TO_NEW_PLACEMENT) {
        const instructionAudios = yield call(getInstructionAudios, {
          type: "STUDENT",
          keys: [
            "placementTest.practiceTest.start",
            "placementTest.start",
            "placementTest.result",
            "placementTest.pause1",
            "placementTest.pause2",
          ],
          locale: profile.locale || "en",
        });

        const instructionAudiosInserted = yield all(
          instructionAudios.map(async (item) => {
            try {
              await insertAudio({
                isCompleteUrl: false,
                isDeletable: false,
                url: item.audio || item.generatedAudio,
              });
              return item;
            } catch (error) {
              console.log(error);
            }
          })
        );
        yield put(requestMergeInstructionAudios(instructionAudiosInserted));

        instructionVideoKeys.push("PLACEMENT");
      }

      const instructionVideos = yield call(getInstructionVideos, {
        type: "STUDENT",
        keys: instructionVideoKeys,
        locale: profile.locale || "en",
      });

      yield put(requestMergeInstructionVideos(instructionVideos));

      const configuration = yield call(getConfiguration);
      yield put(requestMergeConfiguration(configuration));
      yield all(
        [
          "correctAudios",
          "errorAudios",
          "firstTryAudios",
          "secondTryAudios",
          "speechRecognitionErrorAudios",
          "userAwayAudios",
          "optionAudios",
        ].reduce(async (acc, path) => {
          await acc;

          await Promise.all(
            configuration[path].map(async (item) => {
              await insertAudio({
                isCompleteUrl: false,
                isDeletable: false,
                url: item.path || item.generatedAudio,
              });
              return item;
            })
          );
        }, Promise.resolve())
      );

      yield put(requestMergeConfiguration(configuration));
      errorCount = 0;
    } catch (error) {
      logError({ error, flow: LOAD_CONFIGURATION_FLOW });
      errorCount += 1;
    } finally {
      yield put(endFlow(LOAD_CONFIGURATION_FLOW));
      if (errorCount > 0 && errorCount < 4) {
        yield put(startFlow(LOAD_CONFIGURATION_FLOW));
      }
    }
  });
}
