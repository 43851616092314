import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import uniq from "lodash/uniq";
import Entity from "student-front-commons/src/components/Entity";
import UnitSectionList from "./UnitSectionList";
import browserHistory from "../../browserHistory";

class UnitSectionListContainer extends Component {
  render() {
    return (
      <Entity name="module">
        {({ getById: getModuleById }) => (
          <Entity name="unit">
            {({ getAll }) => {
              const studentModule = getModuleById(this.props.match.params.idModule);
              if (studentModule.disabled) {
                browserHistory.replace(`${browserHistory.location.pathname.replace(/\/[0-9a-fA-F]{24}\/units/, "")}`);
                return;
              }
              const groups = uniq(
                getAll()
                  .filter((unit) => unit.module === this.props.match.params.idModule)
                  .map((u) => u.group)
              ).map((group) => ({
                value: group,
                isDisabled: !(studentModule.unitGroupEnabled?.length ? studentModule.unitGroupEnabled : ["A"]).some(
                  (g) => g === group
                ),
                isCompleted: !getAll()
                  .filter((u) => u.module === this.props.match.params.idModule && u.group === group)
                  .some((u) => !u.defaultFinishedAt || !u.firstReviewFinishedAt),
              }));
              return <UnitSectionList groups={groups} />;
            }}
          </Entity>
        )}
      </Entity>
    );
  }
}

export default withRouter(UnitSectionListContainer);
