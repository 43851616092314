import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import $ from "jquery";
import Cleave from "cleave.js";
import "cleave.js/dist/addons/cleave-phone.i18n";
import TextInput from "./TextInput";
import { COUNTRIES } from "student-front-commons/src/consts";

export default class MaskInput extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    phoneRegionCode: PropTypes.string.isRequired,
    error: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    fullWidth: PropTypes.bool,
    maskType: PropTypes.oneOf(["phone", "numeral", "custom"]),
    delimiters: PropTypes.arrayOf(PropTypes.string),
    blocks: PropTypes.arrayOf(PropTypes.number),
    numericOnly: PropTypes.bool,
    numeralPositiveOnly: PropTypes.bool,
    required: PropTypes.bool,
  };

  static defaultProps = {
    value: "",
    disabled: false,
    error: null,
    delimiters: [],
    numericOnly: false,
    numeralPositiveOnly: false,
    required: false,
    maskType: "custom",
    phoneRegionCode: "BR",
    blocks: [],
  };

  state = { maskedValue: "", ddi: "+55" };

  mountMaskOptions = () => {
    const maskOptions = {
      delimiters: this.props.delimiters,
      numericOnly: this.props.numericOnly,
      blocks: this.props.blocks,
      numeralPositiveOnly: this.props.numeralPositiveOnly,
      phoneRegionCode: this.props.phoneRegionCode,
      onValueChanged: this.handleChange,
    };
    maskOptions[this.props.maskType] = true;

    this.maskedField = new Cleave($(ReactDOM.findDOMNode(this.textInput)).find("input"), maskOptions);
    this.maskedField.setRawValue(this.props.value);

    this.setState({
      maskedValue: this.maskedField.getFormattedValue(),
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.phoneRegionCode !== this.props.phoneRegionCode) {
      this.maskedField.destroy();
      this.setState({
        ddi: COUNTRIES.find((country) => country.code === this.props.phoneRegionCode)?.dialCode || "+55",
      });
      this.mountMaskOptions();
    }
  }

  componentDidMount() {
    this.mountMaskOptions();
  }

  componentWillUnmount() {
    this.maskedField.destroy();
  }

  handleChange = (event) => {
    if (event.target.value === this.state.ddi.slice(0, -1) || event.target.value === this.state.ddi) {
      this.setState({
        maskedValue: "",
      });
      this.props.onChange("", "");
    } else {
      this.setState({
        maskedValue: event.target.value.startsWith(this.state.ddi)
          ? event.target.value
          : `${this.state.ddi} ${event.target.value}`,
      });
      this.props.onChange(event.target.rawValue, event.target.value);
    }
  };

  render() {
    return (
      <TextInput
        label={this.props.label}
        value={this.state.maskedValue}
        disabled={this.props.disabled}
        error={this.props.error}
        fullWidth={this.props.fullWidth}
        type="text"
        ref={(input) => {
          this.textInput = input;
        }}
      />
    );
  }
}
