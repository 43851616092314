import { schema } from "normalizr";
import { COURSE_SCHEMA } from "./index";

export default new schema.Entity(
  COURSE_SCHEMA,
  {},
  {
    idAttribute: (value) => value.id,
  }
);
