import { call, put, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { markAsRead } from "student-front-commons/src/services/notificationService";
import { SET_NOTIFICATIONS_READ_FLOW } from "../consts";
import { requestSetNotificationsAsRead } from "../actionCreators/notification";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(SET_NOTIFICATIONS_READ_FLOW), function* () {
    try {
      yield call(markAsRead);

      yield put(requestSetNotificationsAsRead());
    } catch (error) {
      logError({ error, flow: SET_NOTIFICATIONS_READ_FLOW });
    } finally {
      yield put(endFlow(SET_NOTIFICATIONS_READ_FLOW));
    }
  });
}
