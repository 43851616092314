import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "../IconButton";
import BodyPortal from "../BodyPortal";
import MessageChannelCounterContainer from "./MessageChannelCounterContainer";
import MessageScene from "./MessageScene";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_2 } from "../../consts/color";

class MessagePopoverContainer extends Component {
  state = {
    isOpen: false,
    leftPosition: 0,
  };

  componentDidMount() {
    const boundaries = this.parentNode.getBoundingClientRect();
    this.setState({
      leftPosition: boundaries.x - 380,
    });
  }

  handlePopoverToggle = () => {
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () => {
        if (this.state.isOpen) {
          document.addEventListener("click", this.handleOutsidePopoverClick, true);
        } else {
          document.removeEventListener("click", this.handleOutsidePopoverClick, true);
        }
      }
    );
  };

  handleOutsidePopoverClick = (event) => {
    if (event.defaultPrevented || (this.popoverNode && this.popoverNode.contains(event.target))) {
      return;
    }

    this.setState({
      isOpen: false,
    });
    this.props.history.replace({
      pathname: this.props.history.location.pathname,
      state: {
        messagePopoverScene: "channels",
        messageChannel: null,
      },
    });
    document.removeEventListener("click", this.handleOutsidePopoverClick, true);
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div
            ref={(node) => {
              this.parentNode = node;
            }}
            style={{
              position: "relative",
            }}
          >
            <IconButton
              icon="message"
              color={this.state.isOpen ? primaryColor : GREY_2}
              onClick={this.handlePopoverToggle}
            />
            <div
              style={{
                position: "absolute",
                zIndex: 11,
                top: 5,
                right: 5,
              }}
            >
              <MessageChannelCounterContainer />
            </div>
            <BodyPortal>
              <div
                ref={(node) => {
                  this.popoverNode = node;
                }}
                style={{
                  display: this.state.isOpen ? "block" : "none",
                  visibility: this.state.isOpen ? "visible" : "hidden",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: 50,
                    left: this.state.leftPosition + 395,
                    width: 0,
                    height: 0,
                    borderLeft: "10px solid transparent",
                    borderRight: "10px solid transparent",
                    borderBottom: `15px solid ${primaryColor}`,
                    zIndex: 11,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 58,
                    left: this.state.leftPosition,
                    width: 460,
                    minHeight: 200,
                    backgroundColor: "#f1f1f1",
                    boxShadow: "0px 3px 20px 0 rgba(0, 0, 0, 0.3)",
                    borderRadius: 3,
                    zIndex: 9,
                    overflow: "hidden",
                  }}
                >
                  <MessageScene />
                </div>
              </div>
            </BodyPortal>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withRouter(MessagePopoverContainer);
