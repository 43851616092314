import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import CourseSection from "./CourseSection";

const CourseSectionContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));

      if (!profile?.id) {
        return null;
      }
      return (
        <CourseSection
          course={profile.activeCourses.find((course) => !course.distributor)}
          isPlacementTestOnly={getProfileById(sessionStorage.getItem("id")).isPlacementTestOnly}
        />
      );
    }}
  </Entity>
);

export default CourseSectionContainer;
