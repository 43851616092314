import React, { useMemo } from "react";
import FlexColumn from "../../core/FlexColumn";
import { GREY_4 } from "../../consts/color";
import PropTypes from "prop-types";

const ConfettiIcon = (props) => {
  return (
    <FlexColumn
      width={64}
      height={64}
      borderRadius="50%"
      backgroundColor="#0FB1A2"
      alignItems="center"
      justifyContent="center"
      {...props}
    >
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.55658 9.18589C9.0758 8.70504 8.25554 8.88084 8.01599 9.51955L0.0614922 30.7315C-0.220302 31.4829 0.515588 32.2208 1.26842 31.9384C2.33379 31.5389 21.6254 24.3045 22.4803 23.984C23.116 23.7456 23.2973 22.9266 22.814 22.4434L9.55658 9.18589ZM9.26022 11.5411L13.1125 15.3934C13.3791 18.597 14.5659 21.6767 16.5011 24.2237L12.4909 25.7275C9.49133 22.6074 7.96056 18.245 8.36828 13.9196L9.26022 11.5411ZM6.73276 27.8868C5.808 26.8365 5.03887 25.666 4.43947 24.3964L6.70332 18.3594C7.2656 21.4472 8.65245 24.2095 10.5976 26.4374L6.73276 27.8868ZM3.56527 26.7277C3.9575 27.3759 4.39309 27.9959 4.86981 28.5855L2.54115 29.4587L3.56527 26.7277ZM18.3437 23.5327C16.9378 21.8222 15.9287 19.8102 15.3872 17.668L20.4588 22.7396L18.3437 23.5327Z"
          fill="white"
        />
        <path
          d="M21.7822 17.5092C22.1483 17.8753 22.7418 17.8753 23.1079 17.5092C26.6347 13.9825 30.0688 15.0728 30.1031 15.0842C30.5942 15.2479 31.1251 14.9825 31.2889 14.4913C31.4527 14.0003 31.1871 13.4692 30.696 13.3055C30.5111 13.2439 26.1193 11.8464 21.7822 16.1835C21.4161 16.5495 21.416 17.1432 21.7822 17.5092Z"
          fill="white"
        />
        <path
          d="M14.4906 10.2176C14.8567 10.5837 15.4503 10.5838 15.8164 10.2176C20.1534 5.8806 18.7559 1.48875 18.6944 1.30389C18.5306 0.812731 17.9997 0.547185 17.5086 0.710987C17.0174 0.874727 16.752 1.40563 16.9157 1.89679C16.9271 1.9311 18.0174 5.36519 14.4906 8.89197C14.1245 9.25801 14.1245 9.8516 14.4906 10.2176Z"
          fill="white"
        />
        <path
          d="M17.805 12.2063C17.4389 12.5724 17.4389 13.1659 17.805 13.5321C18.1677 13.8948 18.7571 13.8996 19.1251 13.5376C19.6094 13.2963 21.1126 14.2014 21.948 13.3663C22.7743 12.5399 21.8963 11.0673 22.1156 10.5512C22.6324 10.3313 24.1044 11.2098 24.9308 10.3834C25.7574 9.55699 24.8793 8.0844 25.0986 7.56824C25.6162 7.34832 27.0874 8.22689 27.9138 7.4005C28.7403 6.57405 27.8623 5.10146 28.0816 4.5853C28.596 4.36651 30.0709 5.24307 30.8968 4.4175C31.7318 3.58237 30.8285 2.07652 31.068 1.59474C31.4286 1.22827 31.4267 0.638866 31.0624 0.274576C30.6964 -0.0915255 30.1028 -0.0915255 29.7367 0.274576C28.9978 1.01347 29.1424 2.05659 29.2816 2.80235C28.5358 2.66318 27.4927 2.51862 26.7538 3.25745C26.015 3.99634 26.1595 5.03946 26.2987 5.78523C25.5529 5.64611 24.5097 5.50143 23.7709 6.24039C23.032 6.97928 23.1766 8.0224 23.3158 8.76816C22.5699 8.62899 21.5269 8.48437 20.788 9.22326C20.0491 9.96215 20.1937 11.0053 20.3329 11.751C19.587 11.612 18.5437 11.4674 17.805 12.2063Z"
          fill="white"
        />
        <path
          d="M23.1079 4.25182C23.474 3.88572 23.474 3.2922 23.1079 2.9261C22.7418 2.55999 22.1482 2.55999 21.7821 2.9261C21.416 3.2922 21.416 3.88572 21.7821 4.25182C22.1482 4.61793 22.7418 4.61793 23.1079 4.25182Z"
          fill="white"
        />
        <path
          d="M29.0737 11.1551C29.5914 11.1551 30.0111 10.7354 30.0111 10.2177C30.0111 9.69996 29.5914 9.28026 29.0737 9.28026C28.5559 9.28026 28.1362 9.69996 28.1362 10.2177C28.1362 10.7354 28.5559 11.1551 29.0737 11.1551Z"
          fill="white"
        />
        <path
          d="M27.0851 18.835C26.719 19.2011 26.719 19.7947 27.0851 20.1608C27.4512 20.5269 28.0447 20.5269 28.4109 20.1608C28.777 19.7947 28.777 19.2011 28.4109 18.835C28.0448 18.4689 27.4512 18.4689 27.0851 18.835Z"
          fill="white"
        />
        <path
          d="M13.8277 5.57755C14.1938 5.21145 14.1938 4.61793 13.8277 4.25183C13.4616 3.88572 12.8681 3.88572 12.502 4.25183C12.1359 4.61786 12.1359 5.21145 12.502 5.57755C12.8681 5.94366 13.4617 5.94366 13.8277 5.57755Z"
          fill="white"
        />
      </svg>
    </FlexColumn>
  );
};

const MasteryTestResultScore = ({ score, scoreToPass }) => {
  const approved = useMemo(() => {
    return score >= scoreToPass;
  }, [score, scoreToPass]);
  return (
    <FlexColumn
      background="white"
      borderRadius="50%"
      width={246}
      height={246}
      justifyContent="center"
      color="#536E7B"
      margin="auto"
      position="relative"
    >
      {approved && <ConfettiIcon position="absolute" top={-32} left={123} marginLeft={-32} />}
      <span style={{ fontSize: 80, fontWeight: 700, color: approved ? "#0FB1A2" : "#536E7B", lineHeight: "90px" }}>
        {score}
      </span>
      <span style={{ color: GREY_4, fontSize: 14, fontWeight: 700 }}>Score to pass: {scoreToPass}</span>
    </FlexColumn>
  );
};

MasteryTestResultScore.propTypes = {
  score: PropTypes.number.isRequired,
  scoreToPass: PropTypes.number.isRequired,
};

export default MasteryTestResultScore;
