import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import Form from "student-front-commons/src/components/Form";
import PracticeTestTour from "./PracticeTestTour";
import {
  END_PRACTICE_TEST_ITEM_TOUR,
  PRACTICE_TEST_EXECUTION_FORM,
  START_PRACTICE_TEST_ITEM_TOUR,
} from "../../../consts";

class PracticeTestTourContainer extends React.PureComponent {
  render() {
    return (
      <Flow autoStart={false} id={START_PRACTICE_TEST_ITEM_TOUR}>
        {({ isPending, getParams }) =>
          isPending && (
            <Flow autoStart={false} id={END_PRACTICE_TEST_ITEM_TOUR}>
              {({ handleStart }) => (
                <Form autoInit={false} id={PRACTICE_TEST_EXECUTION_FORM} onSubmit={() => false}>
                  {({ values, handleChange }) => {
                    return (
                      <PracticeTestTour
                        screen={getParams().itemType}
                        onFinish={() => {
                          handleChange("typesShowedInIntro", [...values.typesShowedInIntro, getParams().itemType]);
                          handleStart();
                        }}
                      />
                    );
                  }}
                </Form>
              )}
            </Flow>
          )
        }
      </Flow>
    );
  }
}

export default PracticeTestTourContainer;
