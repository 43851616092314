import React from "react";
import ItemImage from "./ItemImage";
import HighlightableItemBox from "./HighlightableItemBox";
import { useSelector } from "react-redux";
import { getLastItemTypeImageOnUnitProp } from "student-front-commons/src/selectors/itemExecution";

const ImageThumbnail = () => {
  const lastItemTypeImageOnUnitId = useSelector(getLastItemTypeImageOnUnitProp("item.id"));
  return (
    <div
      style={{
        marginRight: "30px",
        width: "100%",
        height: "100%",
        maxWidth: "555px",
        position: "sticky",
        top: "15px",
      }}
    >
      <HighlightableItemBox flexGrow={1}>
        <ItemImage
          itemId={lastItemTypeImageOnUnitId}
          style={{
            width: "100%",
          }}
          zoomable={true}
        />
      </HighlightableItemBox>
    </div>
  );
};

export default ImageThumbnail;
