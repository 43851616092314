import request from "../core/request";
import safeCall from "../core/safeCall";

/**
 * list all placement test levels
 *
 * @returns {Promise<*>}
 */
export const getPlacementTestLevels = async () => {
  return safeCall(
    async () => {
      return await request({
        url: "placement-test-levels",
        method: "get",
      });
    },
    "error_get_placement_test_levels",
    "Unexpected error to load Placement test levels."
  );
};
