import { schema } from "normalizr";
import { MASTERY_TEST_SCHEMA } from "./index";

const getStatus = (masteryTest) => {
  if (masteryTest.approvedAt) {
    return "APPROVED";
  } else if (masteryTest.failedAt) {
    return "FAILED";
  } else if (masteryTest.availableAt) {
    return "UNLOCKED";
  }
  return "LOCKED";
};

export default new schema.Entity(
  MASTERY_TEST_SCHEMA,
  {},
  {
    processStrategy: (entity) => ({
      ...entity,
      status: getStatus(entity),
    }),
  }
);
