import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import numeral from "numeral";
import Table from "../../core/Table";
import DateLabel from "../../core/DateLabel";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import withTranslation from "../../core/withTranslation";

const HistoryPerformanceList = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <Table
        headerColor={secondaryColor}
        rowKey="date"
        columns={[
          {
            name: " ",
            path: "date",
            render: (row) => <DateLabel date={moment(row.date).toDate()} format="MMM/YYYY" size="sm" color="#607d8b" />,
          },
          {
            name: props.getTranslation("points"),
            path: "points",
            render: (row) => numeral(row.points).format("0,0").replace(",", "."),
          },
          {
            name: props.getTranslation("hours"),
            path: "hours",
            render: (row) => (row.hours ? moment.duration(row.hours, "hours").format("hh:mm", { trim: false }) : "-"),
          },
        ]}
        data={props.data}
      />
    )}
  </WhitelabelContext.Consumer>
);

HistoryPerformanceList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      points: PropTypes.number,
      hours: PropTypes.number,
    })
  ).isRequired,
};

export default withTranslation(HistoryPerformanceList);
