import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import CertificationTestSubHeader from "./CertificationTestSubHeader";
import { CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW } from "../../consts";

const CertificationTestSubHeaderContainer = () => (
  <Flow id={CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW}>
    {({ handleStart }) => <CertificationTestSubHeader onBack={handleStart} />}
  </Flow>
);

export default CertificationTestSubHeaderContainer;
