import React from "react";
import queryString from "query-string";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../../../core/Async";
import { START_PLACEMENT_TEST_TASTING_FLOW } from "../../../consts";

const PlacementTastingSceneContainer = (props) => (
  <Flow autoStart={true} id={START_PLACEMENT_TEST_TASTING_FLOW} params={queryString.parse(props.location.search)}>
    {({ isPending }) => (
      <Async fetching={isPending}>
        <div />
      </Async>
    )}
  </Flow>
);

export default PlacementTastingSceneContainer;
