import React from "react";
import PropTypes from "prop-types";
import Button from "../../core/Button";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const InitialStepOptions = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div>
        <TranslatedText
          as={Button}
          translateKey="microphoneTest.button.start"
          onClick={props.onStart}
          style={{
            height: 60,
            width: 260,
            backgroundColor: primaryColor,
            fontSize: 16,
            fontWeight: "bold",
            color: "#fff",
            borderRadius: 5,
            textTransform: "uppercase",
          }}
        />
        <Separator size="md" />
        <TranslatedText
          as={Button}
          translateKey="microphoneTest.button.skipTest"
          onClick={props.onDismiss}
          style={{
            width: 260,
            backgroundColor: "transparent",
            fontSize: 16,
            color: "rgba(96, 125, 139, 0.5)",
            border: "none",
            outline: "none",
            boxShadow: "none",
            textTransform: "uppercase",
          }}
        />
      </div>
    )}
  </WhitelabelContext.Consumer>
);

InitialStepOptions.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onStart: PropTypes.func.isRequired,
};

export default InitialStepOptions;
