import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * schedule a unit review
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.module - the module id
 * @param {!string} payload.unit - the current unit id
 * @param {!string} payload.execution - the current unit execution id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const scheduleUnitReview = async (payload) => {
  validate(
    {
      module: {
        presence: {
          allowEmpty: false,
        },
      },
      unit: {
        presence: {
          allowEmpty: false,
        },
      },
      execution: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(async () => {
    return await request({
      url: `modules/${payload.module}/units/${payload.unit}/executions/${payload.execution}/schedule-unit-reviews`,
      method: "post",
      isV2: true,
    });
  });
};
