import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list the current month ranking
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.level - one of national or regional
 * @param {string} payload.size - the size of return list
 *
 * @returns {Promise<*>}
 */
export const getCurrentRankingByLevel = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      level: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["national", "regional", "school"],
      },
      size: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/current-ranking`,
        method: "get",
        params: {
          level: payload.level,
          size: payload.size,
        },
      });
    },
    "error_list_current_ranking",
    "Unexpected error to list current ranking."
  );
};

/**
 * list the ranking based on period with the studetn position
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.level - one of national or regional
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 * @param {string} payload.size - the size of return list
 *
 * @returns {Promise<*>}
 */
export const getRankingByPeriodAndLevel = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      level: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["national", "regional", "school"],
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      size: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `reports/students/${payload.id}/ranking`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
          level: payload.level,
          size: payload.size,
        },
      });
    },
    "error_list_ranking",
    "Unexpected error to list ranking."
  );
};
