import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "../../core/Button";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import FlexRow from "../../core/FlexRow";

function UnitResultButton({ onClick, htmlType, isDisabled, backgroundColor, labelKey }) {
  const courseThemeContext = useContext(CourseThemeContext);

  return (
    <FlexRow
      position="sticky"
      zIndex={2}
      bottom={0}
      width="100%"
      height={120}
      background="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.827519) 28.12%, #FFFFFF 58.5%)"
      justifyContent="center"
      alignItems="center"
    >
      <TranslatedText
        as={Button}
        translateKey={labelKey}
        style={{
          fontSize: 14,
          fontWeight: 700,
          height: 40,
          color: "#fff",
          padding: "10px 48px",
          width: "auto",
          backgroundColor: backgroundColor || courseThemeContext.primary,
          margin: "10px 20px",
          textTransform: "uppercase",
        }}
        type={htmlType}
        onClick={onClick}
        disabled={isDisabled}
      />
    </FlexRow>
  );
}

UnitResultButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  backgroundColor: PropTypes.string,
  labelKey: PropTypes.string,
  htmlType: PropTypes.oneOf(["button", "submit"]),
};

UnitResultButton.defaultProps = {
  htmlType: "button",
  isDisabled: false,
  backgroundColor: null,
};

export default UnitResultButton;
