import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../../core/Async";
import CertificationTestResultScene from "./CertificationTestResultScene";
import { CHECK_CERTIFICATION_TEST_RESULT_FLOW } from "../../consts";

class CertificationTestResultSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={true}
        id={CHECK_CERTIFICATION_TEST_RESULT_FLOW}
        params={{
          certificationTest: this.props.match.params.idCertificationTest,
        }}
      >
        {({ isPending }) => (
          <Async fetching={isPending}>
            <CertificationTestResultScene />
          </Async>
        )}
      </Flow>
    );
  }
}

export default withRouter(CertificationTestResultSceneContainer);
