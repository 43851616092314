import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { LOAD_CONFIGURATION_FLOW, START_PLACEMENT_TEST_TASTING_FLOW } from "../consts";
import browserHistory from "../browserHistory";

export default function* () {
  yield takeLatest(getFlowStart(START_PLACEMENT_TEST_TASTING_FLOW), function* () {
    const flow = yield select(getFlow(START_PLACEMENT_TEST_TASTING_FLOW));
    try {
      const profileResult = {
        result: "tasting_user",
        entities: {
          profile: {
            tasting_user: {
              id: "tasting_user",
              name: "Tasting",
              email: "tasting@flexge.com",
              initialEnglishLevel: undefined,
              schoolClass: "tasting-class-room",
              locale: flow.params.locale,
              isPlacementTestOnly: true,
            },
          },
          schoolClass: {
            "tasting-class-room": {
              id: "tasting-class-room",
              isPlacementTestClass: true,
              school: "tasting-school",
            },
          },
          school: {
            "tasting-school": {
              id: "tasting-school",
              allowLevelSelection: false,
            },
          },
        },
      };
      yield put(mergeEntities(profileResult.entities));

      yield put(startFlow(LOAD_CONFIGURATION_FLOW));
      yield take(getFlowEnd(LOAD_CONFIGURATION_FLOW));

      browserHistory.replace("/practice-test-introduction");
    } catch (error) {
      yield call(browserHistory.replace, "/login");
    } finally {
      yield put(endFlow(START_PLACEMENT_TEST_TASTING_FLOW));
    }
  });
}
