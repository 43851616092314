import React from "react";
import { useParams } from "react-router-dom";
import TastingResultScene from "./TastingResultScene";
import { useFlow } from "student-front-commons/src/hooks";
import CircularLoading from "../../../core/CircularLoading";
import { END_TASTING_UNIT_EXECUTION_FLOW } from "../../../consts";

function TastingResultSceneContainer() {
  const params = useParams();
  const [isPending] = useFlow(END_TASTING_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      course: params.idCourse,
      module: params.idModule,
      unit: params.idUnit,
      execution: params.idExecution,
    },
  });

  if (isPending) {
    return <CircularLoading size="md" />;
  }

  return <TastingResultScene />;
}

export default TastingResultSceneContainer;
