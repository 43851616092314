import get from "lodash/get";
import { normalize } from "normalizr";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

import courseSchema from "../schemas/courseSchema";

/**
 * list the student courses
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getCourses = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const courses = await request({
        url: `students/${payload.id}/courses`,
        method: "get",
      });

      return normalize(courses, [courseSchema]);
    },
    "error_load_courses",
    "Unexpected error to load the courses."
  );
};

/**
 * set the course for student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setManualCourse = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `students/${payload.id}/courses/${payload.course}`,
        method: "put",
      });
    },
    "error_setting_course",
    "Unexpected error to set the course."
  );
};

/**
 * get the student course stats (hours and points)
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getCourseStats = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `students/${payload.id}/courses/${payload.course}/stats`,
        method: "get",
      });
    },
    "error_get_course_stats",
    "Unexpected error to get course stats."
  );
};

/**
 * get the student course stats (hours and points) ina period
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getCourseStatsByPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `students/${payload.id}/courses/${payload.course}/stats`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_course_stats_by_period",
    "Unexpected error to get course stats of period."
  );
};
