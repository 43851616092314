import React, { Component } from "react";
import PlacementTestContent from "../TestContent";
import Column from "../../../core/Column";
import Row from "../../../core/Row";
import TranslatedText from "../../../core/TranslatedText";
import PlacementTestItemExecutionFormContainer from "./PlacementTestItemExecutionFormContainer";
import PlacementTestLevelTitleContainer from "./PlacementTestLevelTitleContainer";
import PlacementTestPauseModalContainer from "./PlacementTestPauseModalContainer";
import ClosePlacementTestExecutionContainer from "./ClosePlacementTestExecutionContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../../consts/color";
import OldErrorModalContainer from "../../../error/OldErrorModalContainer";

class PlacementTestScene extends Component {
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div>
            <ClosePlacementTestExecutionContainer />
            <div
              style={{
                height: 80,
                boxShadow: "0 1px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: primaryColor,
                position: "relative",
              }}
            >
              <div className="container">
                <Row>
                  <Column lgSize={12} mdSize={12}>
                    <TranslatedText
                      as="h1"
                      translateKey="placementTest.placement"
                      style={{
                        color: WHITE,
                        fontSize: 22,
                        fontWeight: 700,
                        lineHeight: "24px",
                        margin: "20px 0 5px 0px",
                      }}
                    />
                    <PlacementTestLevelTitleContainer />
                  </Column>
                </Row>
              </div>
            </div>
            <PlacementTestContent>
              <PlacementTestItemExecutionFormContainer />
              <PlacementTestPauseModalContainer />
            </PlacementTestContent>
            <OldErrorModalContainer />
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default PlacementTestScene;
