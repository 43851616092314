import React from "react";
import ProgressBar from "../../core/ProgressBar";
import { MASTERY_TEST_EXECUTION_FORM } from "../../consts/index";
import Form from "student-front-commons/src/components/Form";

const MasteryTestItemsProgressContainer = () => (
  <Form id={MASTERY_TEST_EXECUTION_FORM} autoInit={false} onSubmit={() => false}>
    {({ values }) => <ProgressBar percentage={(values.answers.length / values.masteryTestItems.length) * 100} />}
  </Form>
);

export default MasteryTestItemsProgressContainer;
