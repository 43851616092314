import styled from "styled-components";
import Icon from "../../core/Icon";
import PropTypes from "prop-types";

const MasteryTestListItemBackground = styled(Icon)`
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  &:before {
    font-size: 175px !important;
  }
`;

MasteryTestListItemBackground.propTypes = {
  color: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default MasteryTestListItemBackground;
