import React from "react";
import PropTypes from "prop-types";
import StatsListItem from "./StatsListItem";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function StatsList(props) {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  return (
    <ul
      style={{
        margin: 0,
        padding: 0,
        listStyle: "none",
      }}
    >
      {props.stats
        .filter((statsItem) =>
          !!profile.isSpeechRecognitionDisabled
            ? !["SPEECH_RECOGNITION_AVERAGE_SCORE", "LISTEN_USAGE"].includes(statsItem.requirement)
            : true
        )
        .map((statsItem, index) => (
          <StatsListItem key={`stats-item-${statsItem.requirement}`} odd={index % 2 !== 0} statsItem={statsItem} />
        ))}
    </ul>
  );
}

StatsList.propTypes = {
  stats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default StatsList;
