import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Form from "student-front-commons/src/components/Form";
import CircularButton from "../CircularButton";
import ColumnSeparator from "../ColumnSeparator";
import HtmlForm from "../HtmlForm";
import withTranslation from "../withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { MESSAGE_FORM } from "../../consts";
import MaterialTheme from "../MaterialTheme";
import TextInput from "../form/TextInput";

const MessageChatReplyForm = (props) => (
  <MaterialTheme>
    <WhitelabelContext.Consumer>
      {({ primaryColor }) => (
        <Form id={MESSAGE_FORM} onSubmit={props.onSubmit}>
          {({ values, handleSubmit, handleChange }) => (
            <HtmlForm onSubmit={handleSubmit}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "0px 10px 10px 10px",
                  backgroundColor: "#fff",
                  boxShadow: "0px -3px 10px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <TextInput
                  disabled={props.isSubmitting}
                  onChange={(value) => handleChange("text", value)}
                  value={get(values, "text", "")}
                  label={props.getTranslation("messages.placeholder")}
                  fullWidth
                  rowsMax={3}
                  rows={1}
                  multiLine={true}
                />
                <ColumnSeparator size="xs" />
                <div
                  style={{
                    minWidth: 30,
                    marginTop: 12,
                  }}
                >
                  <CircularButton
                    disabled={props.isSubmitting || !get(values, "text", false)}
                    loading={props.isSubmitting}
                    icon="send"
                    color={primaryColor}
                    size="sm"
                  />
                </div>
              </div>
            </HtmlForm>
          )}
        </Form>
      )}
    </WhitelabelContext.Consumer>
  </MaterialTheme>
);

MessageChatReplyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation(MessageChatReplyForm);
