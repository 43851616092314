import React, { Component } from "react";
import PropTypes from "prop-types";
import TestHeader from "../TestHeader";
import TestPanel from "../TestPanel";
import TestPanelTitle from "../TestPanelTitle";
import TestPanelDescription from "./TestPanelDescription";
import Button from "../../../core/Button";
import browserHistory from "../../../browserHistory";
import VideoPlayer from "../../../core/VideoPlayer";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_5, GREY_6 } from "../../../consts/color";
import Separator from "../../../core/Separator";
import TestStepContainer from "../TestStepContainer";
import { addSentryUserAction } from "../../../util";
import UserDemoOnboardingModal from "./UserDemoOnboardingModal";
import PlacementTestIntroductionPortugueseGif from "../../../gifs/placement-test/pt/placement-introduction.gif";
import PlacementTestIntroductionEnglishGif from "../../../gifs/placement-test/en/placement-introduction.gif";
import PlacementTestIntroductionSpanishGif from "../../../gifs/placement-test/es/placement-introduction.gif";

const sentryUserAction = { mainComponent: "TestIntroductionScene" };

const localeImageMap = {
  pt: PlacementTestIntroductionPortugueseGif,
  en: PlacementTestIntroductionEnglishGif,
  es: PlacementTestIntroductionSpanishGif,
};

class TestIntroductionScene extends Component {
  static propTypes = {
    profile: PropTypes.shape({
      name: PropTypes.string,
      locale: PropTypes.string,
    }).isRequired,
    videoUrl: PropTypes.string.isRequired,
  };

  state = { endVideo: false, videoRendered: true };

  onVideoEnd = () => {
    this.setState({ endVideo: true });
  };

  handleVideoFailed = () => {
    this.setState({ videoRendered: false });
  };

  render() {
    const { profile, videoUrl } = this.props;
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <TestHeader color={primaryColor} />
            <TestStepContainer currentStep={2} />
            <TestPanel width="760px">
              <TranslatedText
                as={TestPanelTitle}
                translateKey="placementTest.greeting"
                values={{
                  name: this.props.profile.name,
                }}
                color={primaryColor}
              />
              <Separator />
              <TranslatedText as={TestPanelDescription} translateKey="placementTest.description" color={GREY_6} />
              <div
                style={{
                  width: "100%",
                  padding: 40,
                }}
              >
                {!!videoUrl && this.state.videoRendered ? (
                  <VideoPlayer
                    width={680}
                    responsive={true}
                    src={this.props.videoUrl}
                    onVideoEnd={this.onVideoEnd}
                    onFailed={this.handleVideoFailed}
                  />
                ) : (
                  <img
                    src={localeImageMap[profile.locale]}
                    alt={`Placement Introduction ${profile.locale}`}
                    width="100%"
                  />
                )}
              </div>
              <TranslatedText
                as={Button}
                translateKey={
                  this.state.endVideo || !this.state.videoRendered
                    ? "placementTest.button.startWarm"
                    : "placementTest.button.watchVideo"
                }
                width="300px"
                height="50px"
                fontSize="16px"
                fontWeight="bold"
                disabled={this.state.videoRendered && !this.state.endVideo}
                color={this.state.endVideo || !this.state.videoRendered ? primaryColor : GREY_5}
                style={{
                  textTransform: "uppercase",
                }}
                onClick={() => {
                  browserHistory.push("/practice-test");
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "TranslatedText",
                    action: `Navigate to /practice-test`,
                  });
                }}
              />
              <UserDemoOnboardingModal />
            </TestPanel>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default TestIntroductionScene;
