import { useParams } from "react-router-dom";
import Separator from "../../core/Separator";
import browserHistory from "../../browserHistory";
import Async from "../../core/Async";
import { useState } from "react";
import { useService } from "student-front-commons/src/hooks";
import { getCurrentBonusTest } from "student-front-commons/src/services/bonusTestService";
import { getBonusTestRewards } from "student-front-commons/src/services/bonusTestService";
import { isEmpty } from "lodash";
import BonusTestRewardsCurrentResult from "./BonusTestRewardsCurrentResult";
import BonusTestRewardsLastResults from "./BonusTestRewardsLastResults";

export default function BonusTestRewards() {
  const params = useParams();
  const [bonusTestResult, setBonusTestResult] = useState({});
  const [rewards, setRewards] = useState({});

  const [isFetchingBonusTest] = useService(getCurrentBonusTest, {
    autoStart: true,
    params: { idCourse: params.idCourse },
    onData: (data) => {
      if (isEmpty(data)) {
        browserHistory.replace(`/course/${params.idCourse}/bonus-test/introduction`);
      } else {
        setBonusTestResult(data);
      }
    },
  });

  const [isFetchingRewards] = useService(getBonusTestRewards, {
    autoStart: true,
    params: { idCourse: params.idCourse },
    onData: (data) => setRewards(data),
  });

  return (
    <div style={{ width: "100%" }}>
      <Async fetching={isFetchingBonusTest || isFetchingRewards}>
        <BonusTestRewardsCurrentResult bonusTestResult={bonusTestResult} rewards={rewards} />
        <Separator size="xxxl" />
        <Separator size="md" />
        <BonusTestRewardsLastResults rewards={rewards} />
      </Async>
    </div>
  );
}
