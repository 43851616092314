import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import Flow from "student-front-commons/src/components/Flow";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import Icon from "../Icon";
import Paragraph from "../Paragraph";
import TranslatedText from "../TranslatedText";
import ExerciseItemPanel from "./ExerciseItemPanel";
import ItemImage from "./ItemImage";
import FlexRow from "../FlexRow";
import { OLD_START_RECORD_FLOW } from "../../consts";

class FreeSpeakTimer extends PureComponent {
  static propTypes = {
    onEnd: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
  };

  state = {
    seconds: 60,
  };

  componentDidUpdate(prevProps) {
    if (this.props.isDisabled) {
      if (this.timeoutInterval) {
        clearInterval(this.timeoutInterval);
      }
    }
    if (prevProps.isDisabled && !this.props.isDisabled) {
      this.timeoutInterval = setInterval(() => {
        this.setState(
          {
            seconds: this.state.seconds - 1,
          },
          () => {
            if (this.state.seconds <= 0) {
              clearInterval(this.timeoutInterval);
              this.props.onEnd();
            }
          }
        );
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.timeoutInterval) {
      clearInterval(this.timeoutInterval);
    }
  }

  render() {
    return this.props.children({
      seconds: moment.duration(this.state.seconds, "seconds").format("mm:ss", { trim: false }),
    });
  }
}

export default class FreeSpeakExerciseItem extends PureComponent {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    inputType: PropTypes.oneOf(["TEXT", "IMAGE"]).isRequired,
    shouldShowStartTimer: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    shouldShowStartTimer: false,
    isDisabled: true,
  };

  render() {
    return (
      <ExerciseItemPanel>
        <Flow autoStart={false} id={OLD_START_RECORD_FLOW}>
          {({ handleStart: handleStartRecord }) =>
            this.props.shouldShowStartTimer && (
              <FreeSpeakTimer onEnd={handleStartRecord} isDisabled={this.props.isDisabled}>
                {({ seconds }) => (
                  <FlexRow
                    alignItems="center"
                    justifyContent="center"
                    // backgroundColor={WHITE}
                    // padding={5}
                    // borderRadius={3}
                    // boxShadow="0px 3px 3px 0 rgba(0, 0, 0, 0.1)"
                  >
                    <Icon icon="information" />
                    <TranslatedText
                      as={Paragraph}
                      translateKey="items.placeholder.freeSpeak"
                      values={{
                        time: seconds,
                      }}
                      style={{
                        fontSize: 15,
                        marginLeft: 5,
                      }}
                    />
                  </FlexRow>
                )}
              </FreeSpeakTimer>
            )
          }
        </Flow>
        <Separator size="lg" />
        <HighlightableItemBox>
          <QuestionLabel text={this.props.item.text} />
        </HighlightableItemBox>
        {this.props.inputType === "IMAGE" && (
          <HighlightableItemBox>
            <ItemImage
              item={this.props.item}
              style={{
                width: "100%",
              }}
            />
          </HighlightableItemBox>
        )}
      </ExerciseItemPanel>
    );
  }
}
