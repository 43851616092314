import React from "react";
import Separator from "../../core/Separator";
import Tabs from "../../core/Tabs";
import SemesterRanking from "./SemesterRanking";
import YearRanking from "./YearRanking";
import AllTimeRanking from "./AllTimeRanking";
import MonthPickerContainer from "./MonthPickerContainer";
import withTranslation from "../../core/withTranslation";
import MonthRanking from "./MonthRanking";
import SemesterPickerContainer from "./SemesterPickerContainer";

const RankingScene = (props) => (
  <div className="container">
    <Separator size="md" />
    <Tabs
      tabs={[
        {
          key: "month",
          label: (
            <div>
              <span>{props.getTranslation("ranking.topOfMonth")}</span>
              <MonthPickerContainer />
            </div>
          ),
          component: <MonthRanking />,
        },
        {
          key: "semester",
          label: (
            <div>
              <span>{props.getTranslation("ranking.topOfSemester")}</span>
              <SemesterPickerContainer />
            </div>
          ),
          component: <SemesterRanking />,
        },
        {
          key: "year",
          label: props.getTranslation("ranking.topOfYear"),
          component: <YearRanking />,
        },
        {
          key: "allTime",
          label: props.getTranslation("ranking.topOfAllTime"),
          component: <AllTimeRanking />,
        },
      ]}
    />
  </div>
);

export default withTranslation(RankingScene);
