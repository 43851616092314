import React from "react";
import PropTypes from "prop-types";
import PositionLabel from "../../core/PositionLabel";
import Separator from "../../core/Separator";
import withTranslation from "../../core/withTranslation";

const HistoryPositionBox = (props) => (
  <div
    style={{
      width: "100%",
      backgroundColor: "rgba(96, 125, 139, 0.2)",
      borderRadius: 3,
      boxSizing: "border-box",
      padding: 10,
    }}
  >
    <h1
      style={{
        textTransform: "uppercase",
        color: "rgba(96, 125, 139, 0.8)",
        fontWeight: 400,
        fontSize: 10,
        margin: 0,
      }}
    >
      {props.title}
    </h1>
    <Separator size="xs" />
    {!!props.position && <PositionLabel position={props.position} color="#455a64" />}
    {!props.position && (
      <label
        htmlFor="noData"
        style={{
          color: "#455a64",
          fontSize: 12,
        }}
      >
        {props.getTranslation("ranking.noPositionHistory")}
      </label>
    )}
  </div>
);

HistoryPositionBox.propTypes = {
  position: PropTypes.number,
  title: PropTypes.string.isRequired,
};

HistoryPositionBox.defaultProps = {
  position: null,
};

export default withTranslation(HistoryPositionBox);
