import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { setConfig } from "./config";

export default function Config(props) {
  useEffect(() => {
    setConfig({
      endpointUrl: props.endpointUrl,
      speechRecognitionEndpointUrl: props.speechRecognitionEndpointUrl,
      speechRecognitionKey: props.speechRecognitionKey,
      assetsUrl: props.assetsUrl,
      setToken: props.setToken,
      getToken: props.getToken,
      onLogout: props.onLogout,
    });
  }, []);

  return props.children;
}

Config.propTypes = {
  endpointUrl: PropTypes.string.isRequired,
  speechRecognitionEndpointUrl: PropTypes.string.isRequired,
  speechRecognitionKey: PropTypes.string.isRequired,
  assetsUrl: PropTypes.string.isRequired,
  setToken: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
