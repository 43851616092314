import React, { Component } from "react";
import PropTypes from "prop-types";
import { detect } from "detect-browser";
import Separator from "./Separator";
import TranslatedText from "./TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import bgLogin from "../images/bg-login.png";

class CheckBrowserContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { allowedBrowser: true };

  componentDidMount() {
    const browser = detect();
    this.setState({
      allowedBrowser: browser && (browser.name === "chrome" || browser.name === "firefox" || browser.name === "safari"),
    });
  }

  isAllowedRoute = () =>
    this.props.allowedRoutes &&
    this.props.allowedRoutes.some((r) =>
      r instanceof RegExp ? r.test(window.location.pathname) : r === window.location.pathname
    );

  render() {
    if (this.state.allowedBrowser || this.isAllowedRoute()) {
      return this.props.children;
    }
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, logoUrl }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
              backgroundColor: primaryColor,
              overflow: "hidden",
            }}
          >
            <img
              src={bgLogin}
              alt="bg"
              style={{
                position: "fixed",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                background: "#fff",
                borderRadius: 3,
                padding: "80px 60px",
                maxWidth: "55%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
                zIndex: 1,
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{
                  objectFit: "scale-down",
                  height: "auto",
                  width: 300,
                }}
              />
              <Separator size="md" />
              <Separator size="lg" />
              <TranslatedText
                as="p"
                translateKey="error.checkBrowserType.title"
                style={{
                  textTransform: "uppercase",
                  margin: 0,
                  color: "#444",
                  fontWeight: "bold",
                }}
              />
              <Separator size="md" />
              <Separator size="xs" />
              <TranslatedText
                as="label"
                translateKey="error.checkBrowserType.description1"
                style={{
                  margin: 0,
                  color: "#8e8e8e",
                  fontSize: 14,
                }}
              />
              <Separator size="xs" />
              <TranslatedText
                as="label"
                translateKey="error.checkBrowserType.description2"
                style={{
                  margin: 0,
                  color: "#8e8e8e",
                  fontSize: 14,
                }}
              />
            </div>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default CheckBrowserContainer;
