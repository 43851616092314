import React from "react";
import PropTypes from "prop-types";
import browserHistory from "../../browserHistory";
import AbilityListItem from "./AbilityListItem";

const AbilityList = (props) => (
  <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    }}
  >
    {props.abilities.map((ability) => (
      <AbilityListItem
        key={`ability-${ability.type}`}
        type={ability.type}
        status={ability.status}
        onClick={() => {
          browserHistory.push(`${browserHistory.location.pathname}/${ability.type}/execution`);
        }}
      />
    ))}
  </div>
);

AbilityList.propTypes = {
  abilities: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(["AVAILABLE", "BLOCKED", "FINISHED"]),
      type: PropTypes.string,
    })
  ).isRequired,
};

export default AbilityList;
