import React from "react";
import PropTypes from "prop-types";
import CircularLoading from "./CircularLoading";
import LogRocket from "logrocket";

class HubspotForm extends React.Component {
  static propTypes = {
    formId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
  };

  static defaultProps = {
    initialValues: null,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    let script = document.createElement(`script`);
    script.id = "jquery-to-delete";
    script.integrity = `sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=`;
    script.crossOrigin = "anonymous";
    script.onload = () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "9429913",
        formId: this.props.formId,
        target: `#hubspot-form-${this.props.formId}`,
        onFormSubmitted: () => {
          LogRocket.log("Form submitted");
          this.props.afterSubmit();
        },
        onFormSubmit: ($form) => {
          LogRocket.log("Form Ready to submitted");
          this.props.onSubmit($form.serializeArray().reduce((acc, item) => ({ ...acc, [item.name]: item.value }), {}));
        },
        onFormReady: ($form) => {
          LogRocket.log("Form ready");
          if (this.props.initialValues) {
            Object.keys(this.props.initialValues).forEach((key) =>
              $form.find(`[name=${key}]`).val(this.props.initialValues[key])
            );
          }

          this.setState({ lading: false });
        },
      });
    };
    document.head.appendChild(script);

    script.src = `https://code.jquery.com/jquery-3.6.0.min.js`;
  }

  componentWillUnmount() {
    document.getElementById("jquery-to-delete").remove();
  }

  render() {
    return (
      <div id={`hubspot-form-${this.props.formId}`}>
        {this.state.lading && <CircularLoading size="sm" rgb={{ r: 0, g: 0, b: 0 }} />}
      </div>
    );
  }
}

export default HubspotForm;
