import DefaultMasteryTestListItemPoints from "../../core/mastery-test/MasteryTestListItemPoints";
import styled from "styled-components";
import PropTypes from "prop-types";

const MasteryTestListItemFailedStats = styled(DefaultMasteryTestListItemPoints)`
  font-size: 20px !important;
  font-weight: 700 !important;
`;

MasteryTestListItemFailedStats.propTypes = {
  module: PropTypes.string.isRequired,
  failedAt: PropTypes.string.isRequired,
};

export default MasteryTestListItemFailedStats;
