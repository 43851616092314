import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import CoursesRulerListItem from "./CoursesRulerListItem";
import TranslatedText from "../../core/TranslatedText";
import { GREY_2 } from "../../consts/color";

const StudentLine = Styled.div`
  width: 2px;
  height: ${(props) => props.end}px;
  background-color: ${(props) => props.color};
  position: absolute;
  bottom: ${(props) => props.start}px;
  z-index: 2;
  transition: all 2s ease;

  &:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    display: block;
    position: absolute;
    left: -4px;
    top: -5px;
    z-index: 2;
  }

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
    display: block;
    position: absolute;
    left: -4px;
    bottom: -5px;
    z-index: 2;
  }
`;

export default class CoursesRulerList extends Component {
  static propTypes = {
    end: PropTypes.number,
    start: PropTypes.number.isRequired,
    placementCourses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  };

  static defaultProps = {
    end: null,
  };

  render() {
    return (
      <ul
        style={{
          backgroundColor: "#fff",
          listStyle: "none",
          margin: 0,
          position: "relative",
        }}
      >
        <div
          style={{
            width: 2,
            height: "100%",
            backgroundColor: GREY_2,
            position: "absolute",
          }}
        />
        <WhitelabelContext.Consumer>
          {({ primaryColor }) => (
            <StudentLine color={primaryColor} start={this.props.start} end={this.props.end}>
              {this.props.end > 0 && (
                <TranslatedText
                  as="label"
                  translateKey="home.currentRulerLevel"
                  style={{
                    position: "absolute",
                    top: "-10px",
                    width: 100,
                    transform: "rotate(270deg)",
                    color: primaryColor,
                    left: "-70px",
                    fontSize: 11,
                    maxWidth: "none",
                    display: "block",
                    margin: 0,
                    fontWeight: "normal",
                  }}
                />
              )}
              <TranslatedText
                as="label"
                translateKey="home.initialRulerLevel"
                style={{
                  position: "absolute",
                  bottom: "-5px",
                  width: 102,
                  transform: "rotate(270deg)",
                  left: "-68px",
                  fontSize: 11,
                  maxWidth: "none",
                  display: "block",
                  margin: 0,
                  fontWeight: "normal",
                  color: "#cacaca",
                }}
              />
            </StudentLine>
          )}
        </WhitelabelContext.Consumer>
        <li style={{ height: 60 }} />
        {this.props.placementCourses
          .filter((placementCourse) => placementCourse.level % 0.5 === 0)
          .map((placementCourse) => (
            <CoursesRulerListItem
              key={`level-item-${placementCourse.id}`}
              point={placementCourse.level}
              course={
                placementCourse.level > 0 && Number.isInteger(placementCourse.level)
                  ? placementCourse.course?.name
                  : null
              }
            />
          ))}
      </ul>
    );
  }
}
