import React from "react";
import PropTypes from "prop-types";
import { Progress } from "antd";
import CourseThemeContext from "./CourseThemeContext";
import "antd/lib/progress/style/index.css";

const CircularProgress = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <Progress
        type="circle"
        percent={props.value}
        width={35}
        showInfo={false}
        strokeWidth={15}
        strokeColor={primary}
      />
    )}
  </CourseThemeContext.Consumer>
);

CircularProgress.propTypes = {
  value: PropTypes.number.isRequired,
};

export default CircularProgress;
