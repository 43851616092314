import React, { useContext, useEffect, useState } from "react";
import { Buffer } from "buffer";
import PropTypes from "prop-types";
import { userErrorReportService } from "../services/userErrorReportService";
import { useForm, useService } from "../hooks";
import { isEmail, isRequired } from "../validators";
import WhitelabelContext from "./WhitelabelContext";

export class UserReportEvents {
  static OPEN = "user-report-open";
  static CLOSE = "user-report-close";

  static _events = { [UserReportEvents.OPEN]: [], [UserReportEvents.CLOSE]: [] };

  static subscribe = (event, listener) => {
    this._events[event].push(listener);
  };

  static unsubscribe = (event, listener) => {
    this._events[event] = this._events[event].filter((fn) => fn !== listener);
  };

  static dispatch = (event) => {
    this._events[event].forEach((fn) => fn());
  };
}

const UserReportContext = React.createContext(null);

const UserReportContextProvider = ({ children, platform, onTakeScreenshot, reportFormType, imageEditorType }) => {
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [screenShot, setScreenShot] = useState("");
  const [apiError, setApiError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccessfulReported, setIsSuccessfulReported] = useState(false);
  const [isDeactivate, setIsDeactivate] = useState(false);

  const whitelabelContext = useContext(WhitelabelContext);

  const [isFetching, sendReport] = useService(userErrorReportService, {
    autoStart: false,
    onData: () => {
      setIsSuccessfulReported(true);
      setTimeout(() => {
        UserReportEvents.dispatch(UserReportEvents.CLOSE);
        setIsReportOpen(false);
        reset();
      }, 2000);
    },
    onError: (err) => {
      if (err && err.response?.data?.error === "student_not_found") {
        setApiError(err.response?.data?.error);
      } else {
        setApiError("generic_error");
      }
    },
  });

  const { getValue, setValue, submit, isSubmitted, reset, getError } = useForm({
    initialValues: {
      email: "",
      subject: "",
      content: "",
      platform,
    },
    validations: {
      email: [isRequired, isEmail],
      subject: [isRequired],
      content: [isRequired],
    },
    onSubmit: async (values) => {
      setApiError(null);
      const formData = new FormData();
      if (platform === "student_mobile") {
        formData.append("screenshot", {
          uri: values.screenshot,
          type: `image/${values.screenshot.split("/").pop().split(".").pop()}`,
          name: values.screenshot.split("/").pop(),
        });
      } else {
        formData.append(
          "screenshot",
          new Blob([Buffer.from(values.screenshot.replace("data:image/png;base64,", ""), "base64")], {
            type: "image/png",
          })
        );
      }
      formData.append("email", values.email);
      formData.append("subject", values.subject);
      formData.append("content", values.content);
      formData.append("platform", values.platform);
      if (values.device) {
        formData.append("device", values.device);
      }
      if (values.deviceSystem) {
        formData.append("deviceSystem", values.deviceSystem);
      }
      if (values.appVersion) {
        formData.append("appVersion", values.appVersion);
      }
      if (values.sessionUrl) {
        formData.append("sessionUrl", values.sessionUrl);
      }
      if (values.executionId) {
        formData.append("executionId", values.executionId);
      }

      sendReport(formData);
    },
  });

  const handleStartReport = () => {
    if (isDeactivate) {
      return;
    }
    setLoading(true);
    setIsSuccessfulReported(false);
    setIsReportOpen(true);

    UserReportEvents.dispatch(UserReportEvents.OPEN);

    onTakeScreenshot((imageBase64, { currentSessionURL, device, deviceSystem, appVersion, executionId }) => {
      setValue("sessionUrl", currentSessionURL);
      setValue("device", device);
      setValue("deviceSystem", deviceSystem);
      setValue("appVersion", appVersion);
      setValue("screenshot", imageBase64);
      setValue("executionId", executionId);

      setScreenShot(imageBase64);
      setLoading(false);
    });
  };

  const handleImageEditorChange = (blob) => {
    setValue("screenshot", blob);
  };

  const handleDiscard = () => {
    reset();

    setScreenShot(null);
    setIsReportOpen(false);

    UserReportEvents.dispatch(UserReportEvents.CLOSE);
  };

  useEffect(() => {
    if (["learnlaughspeak.com"].includes(whitelabelContext?.domain)) {
      setIsDeactivate(true);
    }
  }, [whitelabelContext?.domain]);

  return (
    <UserReportContext.Provider value={{ handleStartReport, isReportOpen, isDeactivate }}>
      {children}
      {isReportOpen &&
        !isLoading &&
        React.createElement(imageEditorType, {
          image: screenShot,
          onChange: handleImageEditorChange,
          isSuccessfulReported,
        })}
      {isReportOpen &&
        React.createElement(reportFormType, {
          getValue,
          setValue,
          getError,
          submit,
          isSubmitted,
          isSubmitting: isFetching,
          onDiscard: handleDiscard,
          apiError,
          isLoading,
          isSuccessfulReported,
        })}
    </UserReportContext.Provider>
  );
};

UserReportContextProvider.propTypes = {
  platform: PropTypes.oneOf(["student_web", "student_mobile", "kids_web"]).isRequired,
  reportFormType: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
};

export default UserReportContextProvider;

export function useUserReport() {
  return useContext(UserReportContext);
}
