import React, { Component } from "react";
import moment from "moment";
import FetchData from "student-front-commons/src/components/FetchData";
import { getStatsByPeriodAndGroup } from "student-front-commons/src/services/studentStatsService";
import WeekPerformanceChart from "./WeekPerformanceChart";

class WeekPerformanceChartContainer extends Component {
  render() {
    const from = moment().subtract(6, "weeks").startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("weeks").format("YYYY-MM-DD");
    return (
      <FetchData
        service={getStatsByPeriodAndGroup}
        params={{
          id: sessionStorage.getItem("id"),
          from,
          to,
          group: "week",
        }}
      >
        {({ data, isFetching }) => <WeekPerformanceChart data={data || []} isFetching={isFetching} />}
      </FetchData>
    );
  }
}

export default WeekPerformanceChartContainer;
