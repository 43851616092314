import React, { useCallback, useState } from "react";
import QuestionLabel from "../QuestionLabel";
import PopoverButton from "../PopoverButton";
import ReadButton from "./ReadButton";
import MediaQuery from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { readItem } from "student-front-commons/src/actions/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function ReadButtonContainer() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const itemId = useSelector(getCurrentItemExecutionProp("item.id"));
  const itemText = useSelector(getCurrentItemExecutionProp("item.text"));
  const itemPostPhrase = useSelector(getCurrentItemExecutionProp("item.postPhrase"));
  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const repeatCount = useSelector(getCurrentItemExecutionProp("repeatCount"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const isReadDisabled = () => {
    if (isExecutionValidated && "SINGLE_CHOICE_IMAGE" === itemType) {
      return false;
    }
    if (
      ["PRESENTATION", "VIDEO_SHORT", "SINGLE_CHOICE_IMAGE", "VOCABULARY_ACADEMIC"].some((type) => type === itemType)
    ) {
      return isDisabled || isRecording || !repeatCount || isSubmittingRecord;
    }
    return true;
  };

  const handleToggle = useCallback(() => {
    if (!isOpen) {
      dispatch(readItem(itemId));
    }

    setIsOpen(!isOpen);
  }, [isOpen, itemId]);

  const getPopoverContent = useCallback(() => {
    if (itemType === "VOCABULARY_ACADEMIC") {
      return itemPostPhrase;
    }
    return itemText;
  }, [itemType, itemText, itemPostPhrase]);

  return (
    <MediaQuery maxWidth={626}>
      {(matches) => (
        <PopoverButton
          isOpen={isOpen}
          onClose={handleToggle}
          position={matches ? "right-corner" : "bottom"}
          content={<QuestionLabel fontSize={16}>{getPopoverContent()}</QuestionLabel>}
        >
          <ReadButton
            isDisabled={(currentInstructionStep && currentInstructionStep !== "reading") || isReadDisabled()}
            onClick={handleToggle}
          />
        </PopoverButton>
      )}
    </MediaQuery>
  );
}

export default ReadButtonContainer;
