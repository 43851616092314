import React from "react";
import PropTypes from "prop-types";
import FlexColumn from "../../core/FlexColumn";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import FlexRow from "../../core/FlexRow";
import UnitStepItem from "./UnitStepItem";
import ColumnSeparator from "../../core/ColumnSeparator";
import Tooltip from "../../core/Tooltip";
import withTranslation from "../../core/withTranslation";

const UnitSteps = (props) => (
  <FlexColumn padding="0px 5px 0px 20px" alignItems="center">
    <FlexRow alignItems="center" gap={7}>
      <TranslatedText
        as="label"
        translateKey="unit.steps.title"
        style={{
          textTransform: "uppercase",
          fontSize: 11,
          lineHeight: "11px",
          color: "#727272",
          margin: 0,
        }}
      />
      <Tooltip text={props.getTranslation("unit.steps.description")}>
        <FlexRow
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
          width={16}
          color="grey"
          fontSize={10}
          lineHeight="15px"
          fontWeight={700}
          height={16}
          backgroundColor="#fff"
          border="1px solid grey"
        >
          ?
        </FlexRow>
      </Tooltip>
    </FlexRow>
    <Separator size="sm" />
    <FlexRow position="relative" alignItems="flex-start" justifyContent="center">
      <UnitStepItem
        type="YOUR_CHALLENGE"
        points={props.unit.defaultPoints}
        status={props.unit.disabled ? "BLOCKED" : props.unit.yourChallengeStatus}
        tooltip="unit.challengeTooltip"
      />
      <ColumnSeparator size="xl" />
      <div
        style={{
          position: "absolute",
          border: "1px solid #CCCCCC",
          width: 70,
          display: "flex",
          margin: "15px 0px",
        }}
      />
      <UnitStepItem
        type="FIRST_REVIEW"
        points={props.unit.firstReviewPoints}
        status={
          props.unit.yourChallengeStatus === "COMPLETED" && props.unit.firstReviewStatus === "BLOCKED"
            ? "COUNTDOWN"
            : props.unit.disabled
            ? "BLOCKED"
            : props.unit.firstReviewStatus
        }
        tooltip="unit.reviewTooltip"
      />
    </FlexRow>
  </FlexColumn>
);

UnitSteps.propTypes = {
  unit: PropTypes.shape({
    defaultPoints: PropTypes.number,
    firstReviewPoints: PropTypes.number,
    yourChallengeStatus: PropTypes.string,
    firstReviewStatus: PropTypes.string,
  }).isRequired,
};

export default withTranslation(UnitSteps);
