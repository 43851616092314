import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Flow from "student-front-commons/src/components/Flow";
import Async from "../../core/Async";
import CourseThemeProvider from "../../core/CourseThemeProvider";
import CertificationTestAbilitiesScene from "./CertificationTestAbilitiesScene";
import { START_CERTIFICATION_TEST_EXECUTION_FLOW } from "../../consts";

class CertificationTestAbilitiesSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart={!this.props.hasStarted}
        id={START_CERTIFICATION_TEST_EXECUTION_FLOW}
        params={{
          certificationTest: this.props.match.params.idCertificationTest,
        }}
      >
        {({ isPending }) => (
          <CourseThemeProvider>
            <Async fetching={isPending}>
              <CertificationTestAbilitiesScene />
            </Async>
          </CourseThemeProvider>
        )}
      </Flow>
    );
  }
}

const mapStateToProps = (state) => ({
  hasStarted: !!state.certificationTestResult.startedAt,
});

export default connect(mapStateToProps)(withRouter(CertificationTestAbilitiesSceneContainer));
