import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import ClosedRankingLevelButton from "./ClosedRankingLevelButton";
import { WHITE } from "../../consts/color";
import CheckDistributorType from "../../core/CheckDistributorType";

class ClosedRankingLevelFilter extends React.Component {
  static propLevels = {
    children: PropTypes.func.isRequired,
  };

  state = {
    selectedLevel: "regional",
  };

  handleLevelChange = (level) => {
    this.setState({
      selectedLevel: level,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            borderRadius: 30,
            overflow: "hidden",
            backgroundColor: WHITE,
            boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.3)",
            zIndex: 1,
          }}
        >
          <CheckDistributorType>
            {({ isB2C }) =>
              !isB2C && (
                <ClosedRankingLevelButton
                  label="closedRanking.school"
                  isActive={"school" === this.state.selectedLevel}
                  onClick={() => this.handleLevelChange("school")}
                />
              )
            }
          </CheckDistributorType>
          <ClosedRankingLevelButton
            label="closedRanking.regional"
            isActive={"regional" === this.state.selectedLevel}
            onClick={() => this.handleLevelChange("regional")}
          />
          <ClosedRankingLevelButton
            label="closedRanking.national"
            isActive={"national" === this.state.selectedLevel}
            onClick={() => this.handleLevelChange("national")}
          />
        </div>
        <Separator size="md" />
        {this.props.children({ level: this.state.selectedLevel })}
      </React.Fragment>
    );
  }
}

export default ClosedRankingLevelFilter;
