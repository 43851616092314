import React, { useContext, useState } from "react";
import { detect } from "detect-browser";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import MaterialTheme from "../../core/MaterialTheme";
import HtmlForm from "../../core/HtmlForm";
import TextInput from "../../core/form/TextInput";
import Separator from "../../core/Separator";
import FlexRow from "../../core/FlexRow";
import CircularLoading from "../../core/CircularLoading";
import withTranslation from "../../core/withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { useForm, useService } from "student-front-commons/src/hooks";
import { login } from "student-front-commons/src/services/authenticationService";
import { cleanEntities } from "student-front-commons/src/actions/entity";
import AuthMessage from "../../core/AuthMessage";
import ButtonV2 from "../../core/ButtonV2";

function LoginForm(props) {
  const history = useHistory();
  const [error, setError] = useState();
  const whitelabelContext = useContext(WhitelabelContext);
  const deviceInfo = detect();
  const dispatch = useDispatch();

  const [isSubmitting, start] = useService(login, {
    onData: (data) => {
      if (data.academicPlan === "KIDS") {
        sessionStorage.clear();
        setError(`Please, go to the Kids app (https://${window.location.host.replace("student", "kids")})`);
        return;
      }

      sessionStorage.setItem("id", data.id);
      if (data.contractCode) {
        sessionStorage.setItem("contractCode", data.contractCode);
      }
      history.replace("/");
    },
    onError: (error) => {
      setError(error.code ? props.getTranslation(`login.${error.code}`) : error.message);
    },
  });

  const { getValue, setValue, getError, submit, isSubmitted } = useForm({
    initialValues: {
      role: "STUDENT",
      distributor: whitelabelContext.distributor,
      company: whitelabelContext.company,
      deviceInfo: {
        browser: `${deviceInfo.name}_${deviceInfo.version}`,
        os: deviceInfo.os,
        screenSize: `${window.screen.width}_x_${window.screen.height}`,
        internetSpeed: "N/A",
      },
    },
    validations: {
      email: [isRequired, isEmail],
      password: [isRequired],
    },
    onSubmit: (values) => {
      dispatch(cleanEntities());
      setError(null);
      start(values);
    },
  });

  return (
    <>
      <MaterialTheme>
        <HtmlForm onSubmit={submit}>
          <TextInput
            disabled={isSubmitting}
            onChange={(value) => setValue("email", value.replace(/\s+/g, ""))}
            value={getValue("email")}
            error={isSubmitted() && getError("email")}
            label={props.getTranslation("login.email")}
            fullWidth
            autoComplete={false}
          />
          <Separator />
          <TextInput
            disabled={isSubmitting}
            onChange={(value) => setValue("password", value)}
            value={getValue("password")}
            error={isSubmitted() && getError("password")}
            label={props.getTranslation("login.password")}
            fullWidth
            type="password"
            autoComplete={false}
          />
          <Separator size="lg" />
          <Separator size="lg" />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p
              style={{
                textTransform: "uppercase",
                color: "#4a4a4a",
                margin: 0,
              }}
            >
              <Link to="/forgot-password">{props.getTranslation("login.forgotPassword")}</Link>
            </p>
            <FlexRow alignItems="center">
              {isSubmitting && <CircularLoading size="xs" rgb={{ r: 141, g: 141, b: 141 }} />}
              <Separator />
              <ButtonV2
                size="md"
                intent="wl-primary"
                labelKey="login.enter"
                isDisabled={isSubmitting}
                buttonType="submit"
              />
            </FlexRow>
          </div>
        </HtmlForm>
      </MaterialTheme>
      <Separator size="md" />
      <AuthMessage isOpen={!!error} message={error} type="error" />
    </>
  );
}

export default withTranslation(LoginForm);
