import React from "react";
import PropTypes from "prop-types";

const Table = (props) => (
  <div
    style={{
      display: "inline-block",
      width: "100%",
      borderTop: "1px solid #dedede",
    }}
  >
    <div
      style={{
        boxSizing: "border-box",
        height: 30,
        padding: "0px 30px",
      }}
    >
      <label
        htmlFor="title"
        style={{
          fontSize: 13,
          margin: 0,
          color: "rgba(96, 125, 139, 0.8)",
          verticalAlign: "sub",
        }}
      >
        {props.title}
      </label>
    </div>
    <table
      style={{
        width: "100%",
        borderTop: "1px solid #dedede",
      }}
    >
      <thead
        style={{
          color: props.headerColor,
          fontSize: 13,
          margin: 0,
        }}
      >
        <tr>
          {props.columns.map((column) => (
            <th
              key={`header-${column.name}`}
              style={{
                fontSize: 13,
                fontWeight: 700,
                padding: "15px 30px",
                textTransform: "capitalize",
                borderBottom: "1px solid #dedede",
                color: props.headerColor,
                textAlign: "center",
              }}
            >
              {column.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {props.data.map((row) => (
          <tr key={`row-${row[props.rowKey]}`}>
            {props.columns.map((column) => (
              <td
                key={`row-${row.id}-column-${column.path}`}
                style={{
                  color: "#607d8b",
                  fontSize: 13,
                  padding: "15px 30px",
                  textAlign: "center",
                }}
              >
                {column.render ? column.render(row) : row[column.path] || "-"}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

Table.propTypes = {
  title: PropTypes.string,
  rowKey: PropTypes.string,
  headerColor: PropTypes.string,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      render: PropTypes.func,
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
};

Table.defaultProps = {
  data: [],
  title: "",
  rowKey: "id",
  headerColor: "#455a64",
};

export default Table;
