import React from "react";
import PropTypes from "prop-types";
import SpeechRecognitionTestListItem from "./SpeechRecognitionTestListItem";
import Separator from "../../core/Separator";

const SpeechRecognitionTestList = (props) => (
  <div>
    {props.items.map((item, index) => [
      ...(index ? [<Separator size="xs" key={`separator-${item.id}`} />] : []),
      <SpeechRecognitionTestListItem
        key={item.id}
        text={item.text}
        status={item.status}
        score={item.score}
        submitting={item.submitting}
        onRecordEnd={(blob) => props.onRecordEnd(item.id, blob)}
      />,
    ])}
  </div>
);

SpeechRecognitionTestList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRecordEnd: PropTypes.func.isRequired,
};

export default SpeechRecognitionTestList;
