import {
  REQUEST_MICROPHONE_CONFIG_END,
  REQUEST_MICROPHONE_CONFIG_NEXT_STEP,
  REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE,
  REQUEST_MICROPHONE_CONFIG_START,
} from "../consts/actions";

export const requestNextStep = (step) => ({
  type: REQUEST_MICROPHONE_CONFIG_NEXT_STEP,
  payload: {
    step,
  },
});

export const requestItemScore = (id, blob) => ({
  type: REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE,
  payload: {
    id,
    blob,
  },
});

export const requestConfigStart = () => ({
  type: REQUEST_MICROPHONE_CONFIG_START,
});

export const requestConfigEnd = () => ({
  type: REQUEST_MICROPHONE_CONFIG_END,
});
