import React from "react";
import PropTypes from "prop-types";
import Flow from "student-front-commons/src/components/Flow";
import RepeatButton from "./RepeatButton";
import { OLD_PLAY_ITEM_AUDIO_FLOW, PLAY_ITEM_VIDEO_FLOW } from "../../consts";

const RepeatButtonContainer = (props) => (
  <Flow
    autoStart={false}
    id={
      {
        video: PLAY_ITEM_VIDEO_FLOW,
        audio: OLD_PLAY_ITEM_AUDIO_FLOW,
      }[props.type]
    }
    params={{
      isSlowRepeat: false,
      isPlacementRepeat: props.isPlacementRepeat,
    }}
  >
    {({ isPending, handleStart }) => (
      <RepeatButton {...props} onClick={handleStart} isDisabled={isPending || props.isDisabled} />
    )}
  </Flow>
);

RepeatButtonContainer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["video", "audio"]).isRequired,
  isPlacementRepeat: PropTypes.bool,
};

RepeatButtonContainer.defaultProps = {
  isPlacementRepeat: false,
};

export default RepeatButtonContainer;
