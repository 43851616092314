import React from "react";
import PropTypes from "prop-types";
import CourseIcon from "../../core/CourseIcon";
import HeaderBg from "../../images/certification-test-result-header-bg.png";
import ColumnSeparator from "../../core/ColumnSeparator";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import { GREY_5 } from "../../consts/color";

const CertificationTestResultHeader = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          backgroundColor: props.certificationTest.approvedAt ? primary : GREY_5,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          padding: "40px 70px 30px 70px",
          height: 190,
          display: "flex",
          alignItems: "center",
          ...(props.certificationTest.approvedAt && {
            backgroundImage: `url(${HeaderBg})`,
          }),
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <CourseIcon value={props.certificationTest.course.name} size="xl" />
          {props.certificationTest.approvedAt && (
            <div
              style={{
                width: 32,
                height: 32,
                backgroundColor: "#00786A",
                borderRadius: "100%",
                position: "absolute",
                top: 2,
                right: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon icon="check" color="#ffF" />
            </div>
          )}
        </div>
        <ColumnSeparator size="md" />
        <div>
          <TranslatedText
            as="h1"
            translateKey={
              props.certificationTest.approvedAt
                ? "certificationTest.result.approvedTitle"
                : "certificationTest.result.failedTitle"
            }
            style={{
              color: "#fff",
              margin: 0,
              fontSize: 56,
              fontWeight: "bold",
              lineHeight: "40px",
              marginBottom: 15,
            }}
          />
          <TranslatedText
            as="p"
            translateKey={
              props.certificationTest.approvedAt
                ? "certificationTest.result.approvedDescription"
                : "certificationTest.result.failedDescription"
            }
            style={{
              color: "#fff",
              margin: 0,
              fontSize: 18,
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CertificationTestResultHeader.propTypes = {
  certificationTest: PropTypes.shape({
    approvedAt: PropTypes.string,
    course: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default CertificationTestResultHeader;
