import { call, put, race, select, takeLatest } from "redux-saga/effects";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getCourses } from "student-front-commons/src/services/courseService";
import { setCertificationTestAsViewed } from "student-front-commons/src/services/certificationTestExecutionService";
import { requestMergeCertificationTestResult } from "../actionCreators/certificationTestResult";
import { mergeEntities, replaceEntities } from "student-front-commons/src/actions/entity";
import { CHECK_CERTIFICATION_TEST_RESULT_FLOW } from "../consts";
import { logError } from "../util";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { getEntityById } from "student-front-commons/src/selectors/entity";

export default function* () {
  yield takeLatest(getFlowStart(CHECK_CERTIFICATION_TEST_RESULT_FLOW), function* () {
    yield race({
      call: call(function* () {
        try {
          const flow = yield select(getFlow(CHECK_CERTIFICATION_TEST_RESULT_FLOW));

          const certificationTestResult = yield call(setCertificationTestAsViewed, {
            certificationTest: flow.params.certificationTest,
          });
          yield put(requestMergeCertificationTestResult(certificationTestResult));

          const profileResult = yield call(getProfile, {
            id: sessionStorage.getItem("id"),
            role: "STUDENT",
          });
          yield put(mergeEntities(profileResult.entities));

          if (certificationTestResult.approvedAt) {
            const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
            const currentCourse = profile?.activeCourses?.find((c) => !c.distributor);

            const courses = yield call(getCourses, {
              id: profile.id,
            });
            yield put(mergeEntities(courses.entities));

            const modules = yield call(getModulesByCourse, {
              id: profile.id,
              course: currentCourse.id,
            });
            yield put(replaceEntities(modules.entities));
          }
        } catch (error) {
          logError({ error, flow: CHECK_CERTIFICATION_TEST_RESULT_FLOW });
          // yield put(startFlow(HANDLE_ERROR_FLOW, {
          //   error: 'error_save_answer',
          //   retryFlow: {
          //     id: SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          //   }
          // }));
        } finally {
          yield put(endFlow(CHECK_CERTIFICATION_TEST_RESULT_FLOW));
        }
      }),
    });
  });
}
