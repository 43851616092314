import React from "react";
import PropTypes from "prop-types";
import CourseIcon from "../../core/CourseIcon";
import ColumnSeparator from "../../core/ColumnSeparator";
import { getColorOfCourse } from "../../consts/color";

const CourseList = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    {props.courses.map((course, index) => [
      index ? <ColumnSeparator size="md" key={`separator-${course.id}`} /> : null,
      <div
        key={`course-${course.id}`}
        style={{
          opacity: course.id === props.currentCourse.id ? 1 : 0.2,
          ...(course.id === props.currentCourse.id && {
            boxShadow: `0px 0px 25px 0px ${getColorOfCourse(course.name).primary}`,
            borderRadius: "100%",
          }),
        }}
      >
        <CourseIcon value={course.name} size="sm" />
      </div>,
    ])}
  </div>
);

CourseList.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  currentCourse: PropTypes.shape({}).isRequired,
};

export default CourseList;
