import React from "react";
import TitleLabel from "../TitleLabel";
import Separator from "../Separator";
import ExerciseItemPanel from "./ExerciseItemPanel";
import HighlightableItemBox from "./HighlightableItemBox";
import ItemImage from "./ItemImage";
import { useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import MediaProgressBar from "../MediaProgressBar";

function AudioLongRender(props) {
  const title = useSelector(getItemExecutionPropById(props.itemId, "item.title"));
  const currentTime = useSelector(getItemExecutionPropById(props.itemId, "playingStats.currentTime")) || 0;
  const duration = useSelector(getItemExecutionPropById(props.itemId, "playingStats.duration")) || 0;

  return (
    <ExerciseItemPanel className="audio-long">
      {title && (
        <React.Fragment>
          <TitleLabel text={title} />
          <Separator />
        </React.Fragment>
      )}
      <HighlightableItemBox flexGrow={1} flexDirection="column">
        <ItemImage
          itemId={props.itemId}
          style={{
            width: "100%",
          }}
        />
        <MediaProgressBar duration={duration} currentTime={currentTime} />
      </HighlightableItemBox>
    </ExerciseItemPanel>
  );
}

export default function AudioLongExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <AudioLongRender key={id} itemId={id} />);
}
