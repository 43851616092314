import React from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import bgLogin from "../../images/bg-login.png";
import bgLoginFlexge from "../../images/bg-login-flexge.png";
import { WHITE } from "../../consts/color";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPasswordScene = () => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, logoUrl, domain }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
          backgroundColor: primaryColor,
          overflow: "hidden",
        }}
      >
        <img
          src={domain === "flexge.com" ? bgLoginFlexge : bgLogin}
          alt="bg"
          style={{
            position: "fixed",
            objectFit: "cover",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: 520,
            padding: 40,
            position: "relative",
            backgroundColor: WHITE,
            borderRadius: 3,
            boxSizing: "border-box",
            boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
            transition: "all .3s",
          }}
        >
          <img
            src={logoUrl}
            alt="logo"
            style={{
              objectFit: "scale-down",
              height: "auto",
              width: 300,
            }}
          />
          <Separator size="lg" />
          <Separator size="lg" />
          <TranslatedText
            as="p"
            translateKey="login.forgotPasswordDescription"
            style={{ color: "rgb(117, 117, 117)" }}
          />
          <TranslatedText
            as="p"
            translateKey="login.forgotPasswordPostDescription"
            style={{ color: "rgb(117, 117, 117)" }}
          />
          <Separator size="sm" />
          <ForgotPasswordForm />
        </div>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

export default ForgotPasswordScene;
