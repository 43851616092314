import get from "lodash/get";
import { detect } from "detect-browser";
import { MediaStreamRecorder, RecordRTCPromisesHandler, StereoAudioRecorder } from "recordrtc";
import { call, cancelled, delay, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import sample from "lodash/sample";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { getFlowStart, getFlow, getFlowEnd } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getAnswer, getOption, getScore } from "student-front-commons/src/services/speechRecognitionService";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import {
  CHECK_ACHIEVEMENT_EXECUTION_FLOW,
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  END_RECORD_BY_TIMEOUT_FLOW,
  END_RECORD_FLOW,
  ITEM_EXECUTION_FORM,
  PLAY_ITEM_AUDIO_FLOW,
  SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW,
  START_RECORD_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "../consts";
import { insertSpeechRecognitionAudio, playAudio, stopAudio } from "../stores/audio-store";
import LogRocket from "logrocket";
import { logError } from "../util";
import {
  addItemExecutionAnswer,
  addItemExecutionAttempt,
  finishRecordItem,
  saveRecordItemResult,
  startRecordItem,
  submitRecordItem,
} from "student-front-commons/src/actions/itemExecution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { awaitHideAction } from "student-front-commons/src/selectors/systemMessage";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import apiRequest from "student-front-commons/src/core/request";

const info = detect();
let recordStream = null;
let recorderInstance = null;

export function* startRecordFlow() {
  yield takeLatest(getFlowStart(START_RECORD_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        const itemId = yield select((state) => state.itemExecutions.selectedId);

        try {
          recordStream = yield new Promise((resolve, reject) => {
            if (navigator.mediaDevices) {
              navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then((stream) => resolve(stream))
                .catch((error) => reject(error));
            } else {
              (
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia
              )(
                { audio: true, video: false },
                (stream) => resolve(stream),
                (error) => reject(error)
              );
            }
          });

          recorderInstance = new RecordRTCPromisesHandler(recordStream, {
            type: "audio",
            numberOfAudioChannels: 1,
            recorderType: info.name === "safari" ? StereoAudioRecorder : MediaStreamRecorder,
            disableLogs: true,
          });
          yield recorderInstance.startRecording();

          yield put(startRecordItem(itemId));

          const timeToAutoEndRecord = yield select(getCurrentItemExecutionProp("timeToAutoEndRecord"));
          yield put(startFlow(END_RECORD_BY_TIMEOUT_FLOW, { time: timeToAutoEndRecord }));
        } catch (error) {
          if (error.name === "PermissionDeniedError" || error.name === "NotAllowedError") {
            yield put(
              showMessage({
                message: "error.error_record_not_allowed",
              })
            );
          } else if (error.message === "Requested device not found") {
            yield put(
              showMessage({
                message: "error.error_record_missing_mic",
              })
            );
          } else {
            logError({ error, flow: START_RECORD_FLOW });
            yield put(
              showMessage({
                message: "error.error_start_record",
              })
            );
          }
          if (recorderInstance) {
            yield recorderInstance.stopRecording();
            yield recorderInstance.destroy();

            recordStream.getAudioTracks().forEach((track) => track.stop());
          }
          yield put(finishRecordItem(itemId));
        } finally {
          if (yield cancelled()) {
            if (recorderInstance) {
              yield recorderInstance.stopRecording();
              recorderInstance.clearRecordedData();
            }
          }
          yield put(endFlow(START_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endRecordFlow() {
  yield takeLatest(getFlowStart(END_RECORD_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        const itemId = yield select((state) => state.itemExecutions.selectedId);

        try {
          yield recorderInstance.stopRecording();
          recordStream.getAudioTracks().forEach((track) => track.stop());

          const recordBlob = yield recorderInstance.getBlob();
          const recordUrl = yield recorderInstance.getDataURL();

          if (recordBlob.size === 0) {
            yield put(
              showMessage({
                message: "error.noSpeechDetected",
              })
            );
            return;
          }

          yield put(submitRecordItem(itemId, { recordFile: recordBlob, recordUrl }));
          yield call(insertSpeechRecognitionAudio, {
            data: recordUrl,
            isDeletable: true,
          });

          const currentExecution = yield select((state) => state.itemExecutions.byId[itemId]);

          const id = sessionStorage.getItem("id");
          const contractCode = sessionStorage.getItem("contractCode");
          let profile = {
            isDemoStudent: false,
          };
          let schoolClass = {};
          let school = { allowSpeechRecognitionBonus: true };
          let company = { allowSpeechRecognitionBonus: true };
          if (id !== "tasting_user") {
            profile = yield select(getEntityById("profile", id));
            company = yield select(getEntityById("company", profile.company));
            if (!profile.apiVersion) {
              schoolClass = yield select(getEntityById("schoolClass", profile.schoolClass));
              school = yield select(getEntityById("school", schoolClass.school));
            }
          }

          if (
            ["VIDEO_SHORT", "PRESENTATION", "SPEECH_PRACTICE", "PRONUNCIATION", "VOCABULARY_ACADEMIC"].some(
              (type) => type === currentExecution.item.type.key
            )
          ) {
            try {
              const speechRecognitionResult = yield call(getScore, {
                id: contractCode ? `${contractCode}-${id}` : id,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                text:
                  { VOCABULARY_ACADEMIC: currentExecution.item.postPhrase }[currentExecution.item.type.key] ||
                  currentExecution.item.text,
                wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
                allowSpeechRecognitionBonus:
                  currentExecution.recordCount >= 3 &&
                  (profile.apiVersion === "V2"
                    ? company.allowSpeechRecognitionBonus
                    : school.allowSpeechRecognitionBonus),
              });
              yield put(saveRecordItemResult(itemId, { speechRecognitionResult }));
              yield call(insertSpeechRecognitionAudio, {
                data: recordUrl,
                isDeletable: true,
                spriteWords: speechRecognitionResult.wordScoreList,
              });

              const minimumSpeechRecognitionScore = yield select(
                (state) => state.configurations.scoreToPassOfSpeechRecognition
              );
              const answerResult = yield call(checkAnswer, {
                item: currentExecution.item,
                answer: speechRecognitionResult.qualityScore,
                minimumSpeechRecognitionScore,
              });

              // we need to store the attempts here, because every SR should be stored as an attempt
              const currentAnswer = {
                answer: answerResult.answer,
                wordScoreList: speechRecognitionResult.wordScoreList,
                correct: answerResult.status === "CORRECT",
              };
              yield put(addItemExecutionAttempt(itemId, { answer: currentAnswer }));

              if (window.location.pathname.indexOf("/units") > 0) {
                if (currentExecution.item.type.key === "PRONUNCIATION" && speechRecognitionResult.qualityScore > 50) {
                  yield put(startFlow(PLAY_ITEM_AUDIO_FLOW));
                  yield take(getFlowEnd(PLAY_ITEM_AUDIO_FLOW));
                }

                yield put(
                  startFlow(CHECK_ACHIEVEMENT_EXECUTION_FLOW, {
                    type: "record-check",
                  })
                );
              }
            } catch (error) {
              LogRocket.log(error);
              if (error.code === "error_no_speech") {
                yield put(
                  showMessage({
                    message: `error.${error.code}`,
                  })
                );
              } else {
                yield put(
                  showMessage({
                    message: "error.error_get_speech_recognition_score",
                  })
                );
              }
            }
          }

          if (["GAP_FILL", "UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === currentExecution.item.type.key)) {
            try {
              const { answer, selectedAnswers } = yield call(getAnswer, {
                id: contractCode ? `${contractCode}-${id}` : id,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                text: currentExecution.item.text,
                answers: currentExecution.item.answers,
                wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
              });

              yield put(saveRecordItemResult(itemId, { speechRecognitionResult: null }));
              yield put(addItemExecutionAnswer(itemId, { answer, extraData: selectedAnswers }));
            } catch (error) {
              LogRocket.log(error);
              if (
                error.code === "error_no_speech" ||
                error.code === "error_word_alignment" ||
                error.code === "error_find_speech_recognition_text"
              ) {
                yield put(
                  showMessage({
                    message: `error.${error.code}`,
                  })
                );
              } else {
                const speechErrorAudio = yield select((state) => state.configurations.speechRecognitionErrorAudios);
                const audio = sample(speechErrorAudio);
                yield call(playAudio, {
                  url: audio.path || audio.generatedAudio,
                });
              }
            }
          }

          if (["FREE_SPEAK", "FREE_SPEAK_IMAGE"].some((type) => type === currentExecution.item.type.key)) {
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));
            if (currentExecution.item.type.key === "FREE_SPEAK") {
              try {
                const speechRecognitionResult = yield call(getScore, {
                  id: contractCode ? `${contractCode}-${id}` : id,
                  isDemoStudent: !!profile.isDemoStudent,
                  isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                  record: recordBlob,
                  text: currentExecution.item.text,
                  wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
                  allowSpeechRecognitionBonus: false,
                  passUnknownWordsError: true,
                });

                // check if student recorded the item text instead of reply
                if (speechRecognitionResult.qualityScore >= 75) {
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
                  yield put(
                    showMessage({
                      message: "error.error_reply_ct_speak",
                      button: "certificationTest.help.gotIt",
                    })
                  );
                  yield take(awaitHideAction);
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
                  return;
                }
              } catch (error) {
                LogRocket.log(error);
                if (error.code === "error_no_speech") {
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
                  yield put(
                    showMessage({
                      message: "error.noSpeechDetected",
                    })
                  );
                  yield take(awaitHideAction);
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
                  return;
                }
              }
            }

            yield put(changeFormValue(ITEM_EXECUTION_FORM, "answer", recordBlob));
            yield put(addItemExecutionAnswer(itemId, { answer: recordBlob }));
            //TODO
            yield put(startFlow(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW));
            // yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
            return;
          }

          if (["DIALOGUE_OPTION"].some((type) => type === currentExecution.item.type.key)) {
            try {
              const answer = yield call(getOption, {
                id: contractCode ? `${contractCode}-${id}` : id,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                answers: [
                  ...currentExecution.item.answers,
                  { text: currentExecution.item.text, correct: true }, // check if needed when new item type is added
                ],
                wordsDictionary: currentExecution.item.speechRecognitionDictionary || {},
              });

              yield put(saveRecordItemResult(itemId, { speechRecognitionResult: null }));
              yield put(addItemExecutionAnswer(itemId, { answer: answer.text }));
              yield put(startFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
            } catch (error) {
              LogRocket.log(error);
              if (
                error.code === "error_no_speech" ||
                error.code === "error_word_alignment" ||
                error.code === "error_find_speech_recognition_text"
              ) {
                yield put(
                  showMessage({
                    message: `error.${error.code}`,
                  })
                );
              } else {
                const speechErrorAudio = yield select((state) => state.configurations.speechRecognitionErrorAudios);
                const audio = sample(speechErrorAudio);
                yield call(playAudio, {
                  url: audio.path || audio.generatedAudio,
                });
              }
            }
          }

          yield put(finishRecordItem(itemId));

          //if it is a unit execution, start use away timeout flow because it was stopped on record start
          if (window.location.pathname.indexOf("/units") > 0) {
            yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));
          }

          if (id === "tasting_user") {
            yield spawn(function* () {
              const execution = yield select((state) => state.executions);
              const currentExecution = yield select((state) => state.itemExecutions.byId[itemId]);

              yield call(apiRequest, {
                method: "put",
                url: `modules/tasting/units/${execution.unit}/update-lead`,
                data: {
                  action:
                    (currentExecution?.speechRecognitionResult?.qualityScore || 0) >= 80
                      ? "SUCCESSFUL_RECORD"
                      : "RECORD",
                },
              });
            });
          }
        } catch (error) {
          if (typeof error === "string" && error.indexOf("Empty blob") > -1) {
            yield put(
              showMessage({
                message: "error.noSpeechDetected",
              })
            );
          } else {
            logError({ error, flow: END_RECORD_FLOW });
            yield put(
              showMessage({
                message: "error.error_stop_record",
              })
            );
          }
          yield put(finishRecordItem(itemId));
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }

          yield recorderInstance.destroy();
          yield put(endFlow(END_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endRecordByTimeoutFlow() {
  yield takeLatest(getFlowStart(END_RECORD_BY_TIMEOUT_FLOW), function* () {
    const flow = yield select(getFlow(END_RECORD_BY_TIMEOUT_FLOW));
    const raceWinner = yield race({
      timeout: delay(get(flow.params, "time", 60000) || 60000),
      exitUnit: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      userStop: take(getFlowStart(END_RECORD_FLOW)),
    });
    if (raceWinner.timeout) {
      yield put(startFlow(END_RECORD_FLOW));
    }
  });
}
