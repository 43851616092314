import React, { Component } from "react";
import PropTypes from "prop-types";
import values from "lodash/values";
import get from "lodash/get";
import keys from "lodash/keys";
import isNil from "lodash/isNil";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import DragOptionList from "../DragOptionList";
import DropOption from "../DropOption";
import DragAnswerItem from "../DragAnswerItem";
import ExerciseItemPanel from "./ExerciseItemPanel";

export default class DragAndDropExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type: PropTypes.shape({
        key: PropTypes.string.isRequired,
      }).isRequired,
      answers: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
    onConfirm: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isAutoConfirm: PropTypes.bool,
    shouldShowCorrectOption: PropTypes.bool,
    playingId: PropTypes.string,
    extraData: PropTypes.shape({}),
  };

  static defaultProps = {
    onConfirm: null,
    extraData: null,
    playingId: null,
    isAutoConfirm: false,
    shouldShowCorrectOption: false,
  };

  state = {
    nextIndex: null,
  };

  componentDidMount() {
    this.setNextIndex();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.item.text !== this.props.item.text) {
      this.setNextIndex();
    }
  }

  updateUserAnswer = (extraData) => {
    const answerText = this.props.item.text
      .split(" ")
      .reduce((acc, slice, sliceIndex) => {
        const dropAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === sliceIndex);
        if (dropAnswer) {
          const chunk = get(extraData.chunks, sliceIndex.toString(), null);
          return acc
            .concat(
              get(chunk, "text", "-")
                .replace(/[!.?,]$/, "")
                .concat(/[!.?,]$/.test(dropAnswer.text) ? dropAnswer.text.substr(dropAnswer.text.length - 1) : "")
            )
            .concat(" ");
        }
        if (!this.props.item.answers.some((answer) => answer.index === sliceIndex)) {
          return acc.concat(slice).concat(" ");
        }
        return acc;
      }, "")
      .trim();

    this.props.onChange("answer", answerText);
  };

  handleAddChunk = (answer, index) => {
    const extraData = {
      ...this.props.extraData,
      chunks: {
        ...get(this.props.extraData, "chunks", {}),
        [index.toString()]: answer,
      },
    };
    this.props.onChange("extraData", extraData);
    this.updateUserAnswer(extraData);
    this.setNextIndex(extraData);

    if (
      this.props.isAutoConfirm &&
      this.props.item.linkedAnswers.filter((answer) => answer.index !== undefined).length ===
        Object.keys(get(extraData, "chunks", {})).length
    ) {
      setTimeout(() => {
        this.props.onConfirm();
      }, 0);
    }
  };

  handleUpdateChunk = (answer, indexToPut, indexToRemove) => {
    this.handleRemoveChunk(answer, indexToRemove);
    this.handleAddChunk(answer, indexToPut);
  };

  handleRemoveChunk = (answer, index) => {
    const extraData = {
      ...this.props.extraData,
      chunks: Object.keys(get(this.props.extraData, "chunks", {}))
        .filter((key) => key !== index.toString())
        .reduce((acc, key) => ({ ...acc, [key]: get(this.props.extraData.chunks, key, null) }), {}),
    };
    this.props.onChange("extraData", extraData);
    this.updateUserAnswer(extraData);
    this.setNextIndex(extraData);
  };

  setNextIndex = (extraData) => {
    const possibleIndexes = this.props.item.text
      .split(" ")
      .map((_, index) => !!this.props.item.linkedAnswers.find((answer) => answer.index === index));
    let nextIndex = possibleIndexes.findIndex((possibleIndex) => possibleIndex === true);

    if (!isNil(extraData)) {
      nextIndex = null;
      if (
        possibleIndexes.filter((possibleIndex) => possibleIndex === true).length >
        keys(get(extraData, "chunks", {})).length
      ) {
        nextIndex = possibleIndexes.findIndex(
          (possibleIndex, currentIndex) =>
            possibleIndex === true && !(currentIndex.toString() in get(extraData, "chunks", {}))
        );
      }
    }

    this.setState({ nextIndex });
  };

  render() {
    return (
      <ExerciseItemPanel>
        <HighlightableItemBox className="selectTextExerciseUnscrambleDragAndDrop" flexWrap="wrap">
          {this.props.item.text.split(" ").map((slice, index) => {
            const foundAnswer = this.props.item.linkedAnswers.find((answer) => answer.index === index);
            if (foundAnswer) {
              return (
                <React.Fragment key={`drop-option-${slice}-${foundAnswer.id}`}>
                  <DropOption disabled={this.props.isDisabled} index={index} item={this.props.item.id}>
                    {(this.props.shouldShowCorrectOption ||
                      get(this.props.extraData, `chunks[${index.toString()}]`, false)) && (
                      <DragAnswerItem
                        key={`drag-answer-${slice}`}
                        answer={
                          this.props.shouldShowCorrectOption
                            ? foundAnswer
                            : get(this.props.extraData.chunks, index.toString(), false)
                        }
                        index={index}
                        item={this.props.item.id}
                        disabled={this.props.isDisabled}
                        onDrop={this.handleUpdateChunk}
                        onRemove={this.handleRemoveChunk}
                      />
                    )}
                  </DropOption>
                  {/[!.?,]$/.test(foundAnswer.text) && (
                    <React.Fragment>
                      <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} /> &nbsp;
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            }
            return (
              !this.props.item.answers.find((answer) => answer.index === index) && (
                <div
                  key={`${this.props.item.id}-text-${index}`}
                  style={{
                    display: "flex",
                  }}
                >
                  <QuestionLabel text={slice} />
                  &nbsp;
                </div>
              )
            );
          })}
        </HighlightableItemBox>
        <Separator size="md" />
        <DragOptionList
          answers={this.props.item.linkedAnswers.map((answer) => ({
            ...answer,
            selected: values(get(this.props.extraData, "chunks", {})).some((a) => a.id === answer.id),
          }))}
          item={this.props.item.id}
          isDisabled={this.props.isDisabled}
          onDrop={this.handleAddChunk}
          nextIndex={this.state.nextIndex}
        />
      </ExerciseItemPanel>
    );
  }
}
