import React from "react";
import PropTypes from "prop-types";
import withTranslation from "../../core/withTranslation";

const SemesterPicker = (props) => (
  <select
    style={{
      background: "none",
      border: "none",
      outline: "none",
      color: "inherit",
    }}
    value={props.value}
    onChange={(event) => props.onChange(event.target.value)}
  >
    <option value="1">{props.getTranslation("ranking.firstSemester")}</option>
    <option value="2">{props.getTranslation("ranking.secondSemester")}</option>
  </select>
);

SemesterPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTranslation(SemesterPicker);
