import React from "react";
import Separator from "../../core/Separator";
import GradeHeaderContainer from "./GradeHeaderContainer";
import GradePanelContainer from "./GradePanelContainer";

const GradeScene = () => (
  <div className="container">
    <Separator size="lg" />
    <GradeHeaderContainer />
    <Separator size="lg" />
    <GradePanelContainer />
    <Separator size="md" />
  </div>
);

export default GradeScene;
