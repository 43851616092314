import { put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import {
  GET_NEXT_UNIT_ITEM_EXECUTION_FLOW,
  SAVE_TASTING_CONTACT_FLOW,
  SHOW_TASTING_CONTACT_MODAL,
  TASTING_CONTACT_FORM,
} from "../consts";
import { logError } from "../util";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getEntityById } from "student-front-commons/src/selectors/entity";

export default function* () {
  yield takeLatest(getFlowStart(SAVE_TASTING_CONTACT_FLOW), function* () {
    try {
      const contactForm = yield select(getForm(TASTING_CONTACT_FORM));

      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));

      if (profile.id === "tasting_user") {
        yield put(
          mergeEntities({
            profile: {
              [profile.id]: {
                ...profile,
                email: contactForm.values.email,
                name: `${contactForm.values.firstname} ${contactForm.values.lastname}`,
              },
            },
          })
        );
      }
      yield put(startFlow(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW));
    } catch (error) {
      logError({ error, flow: SAVE_TASTING_CONTACT_FLOW });
    } finally {
      yield put(endFlow(SHOW_TASTING_CONTACT_MODAL));
      yield put(endFlow(SAVE_TASTING_CONTACT_FLOW));
    }
  });
}
