import React, { useCallback, useEffect, useRef, useState } from "react";
import Separator from "../Separator";
import QuestionLabel from "../QuestionLabel";
import CircularButton from "../CircularButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { Transition, animated } from "react-spring";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG, GREEN_3, GREY_3, GREY_5 } from "../../consts/color";
import Icon from "../Icon";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW, SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { addItemExecutionAnswer, translateItem } from "student-front-commons/src/actions/itemExecution";
import { get, orderBy, shuffle } from "lodash";
import useTranslation from "student-front-commons/src/hooks/useTranslation";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import FlexColumn from "../FlexColumn";

const UnscrambleTextExerciseRender = (props) => {
  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const extraData = useSelector(getItemExecutionPropById(props.itemId, "extraData"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(props.itemId, "isFinished"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const isExecutionValidated = useSelector(getItemExecutionPropById(props.itemId, "isExecutionValidated"));
  const attempts = useSelector(getItemExecutionPropById(props.itemId, "attempts"));
  const dispatch = useDispatch();
  const [isPending] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [paragraphs, setParagraphs] = useState([]);
  const paragraphsContainer = useRef();

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const [, translation] = useTranslation(props.itemId, profile.locale);

  useEffect(() => {
    setParagraphs([]);
    if (translation) {
      const shuffledParagraphs = shuffleParagraphs(itemText);
      mountAndCalculateParagraphs(shuffledParagraphs);
    }
  }, [props.itemId, translation]);

  useEffect(() => {
    if (paragraphs.length && showCorrectOption) {
      setParagraphs(
        orderBy(paragraphs, ["position", "asc"]).map((paragraph, index) => ({
          ...paragraph,
          index,
        }))
      );
    }
  }, [showCorrectOption]);

  useEffect(() => {
    if (!isFinished && !isExecutionValidated && !isSaving && paragraphs.length) {
      dispatch(
        addItemExecutionAnswer(props.itemId, {
          answer: paragraphs,
          extraData: {
            paragraphsFeedback: paragraphs.map((paragraph) => ({
              position: paragraph.position,
              status: "DEFAULT",
            })),
          },
        })
      );
    }
  }, [paragraphs, isExecutionValidated, isSaving]);

  const shuffleParagraphs = useCallback(
    (paragraphs) => {
      let shuffledParagraphsArray = [];
      let isShuffled = false;
      while (!isShuffled) {
        shuffledParagraphsArray = shuffle(
          paragraphs.split("\n").map((paragraph, index) => ({
            text: paragraph,
            translation: translation.split("\n")[index],
            isShowingTranslation: false,
            position: index,
          }))
        );
        const shuffledParagraphsText = shuffledParagraphsArray.map((paragraph) => paragraph.text).join("\n");
        isShuffled = shuffledParagraphsText !== itemText;
      }
      return shuffledParagraphsArray;
    },
    [itemText, translation]
  );

  const mountAndCalculateParagraphs = useCallback(
    (paragraphsParam) => {
      setParagraphs(
        paragraphsParam.map((paragraph, index) => ({
          ...paragraph,
          index,
        }))
      );

      setTimeout(() => {
        const heightInterval = setInterval(() => {
          setParagraphs((prevParagraphs) => {
            if (prevParagraphs.length && paragraphsContainer.current) {
              if (!prevParagraphs.some((p) => !p.elementHeight)) {
                clearInterval(heightInterval);
              }
              if (prevParagraphs.some((p) => !p.elementHeight)) {
                return Array.from(paragraphsContainer.current.children).map((element, index) => {
                  return {
                    ...prevParagraphs[index],
                    elementHeight: element.offsetHeight,
                    translationHeight: element.querySelector(".translation").clientHeight,
                  };
                });
              }
            }
            return prevParagraphs;
          });
        }, 0);
      }, 500);
    },
    [paragraphs]
  );

  const handleReorder = useCallback(
    (moveFrom, moveTo) => {
      let reorderedParagraphs = [...paragraphs];
      const paragraphToMove = reorderedParagraphs.splice(moveFrom, 1)[0];
      reorderedParagraphs.splice(moveTo, 0, paragraphToMove);

      reorderedParagraphs = reorderedParagraphs.map((paragraph, index) => ({
        ...paragraph,
        index,
      }));

      setParagraphs(reorderedParagraphs);
    },
    [paragraphs]
  );

  const handleToggleTranslation = useCallback(
    (paragraphIndex) => {
      setParagraphs(
        paragraphs.map((paragraph) => ({
          ...paragraph,
          isShowingTranslation: paragraph.index === paragraphIndex && paragraph.isShowingTranslation === false,
        }))
      );
      if (!isDisabled && !isPending && !isFinished) {
        dispatch(translateItem(props.itemId));
      }
    },
    [props.itemId, paragraphs]
  );

  return (
    <ExerciseItemPanel>
      <div
        key={props.itemId}
        ref={paragraphsContainer}
        style={{
          marginTop: "10px",
          height: paragraphs.reduce(
            (acc, i) => acc + i.elementHeight + (i.isShowingTranslation ? 0 : -i.translationHeight),
            60
          ),
        }}
      >
        <Transition
          items={paragraphs}
          keys={(item) => item.text}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          leave={{ opacity: 0 }}
          update={(item) => {
            if (!paragraphs.some((p) => !p.elementHeight)) {
              const height =
                paragraphs
                  .filter((i) => i.index < item.index)
                  .reduce((acc, i) => acc + i.elementHeight + (i.isShowingTranslation ? 0 : -i.translationHeight), 0) ||
                0;
              return {
                position: "absolute",
                transform: `translate3d(0, ${height}px, 0)`,
                zIndex: paragraphs.length - item.index,
              };
            }
            return { position: "relative" };
          }}
          unique
        >
          {(paragraph) => (styles) => {
            const currentParagraph = get(extraData, "paragraphsFeedback", []).find(
              (p) => p.position === paragraph.position
            );
            const showFeedback =
              isPending || ((isFinished || isExecutionValidated || isSaving) && currentParagraph.status === "CORRECT");
            const feedbackColor =
              showFeedback &&
              {
                CORRECT: FEEDBACK_CORRECT,
                WRONG: FEEDBACK_WRONG,
              }[currentParagraph.status];
            const feedbackIcon =
              showFeedback &&
              {
                CORRECT: "check",
                WRONG: "close",
              }[currentParagraph.status];

            return (
              <div id={`paragraph-${paragraph.index}`} style={{ opacity: paragraph.elementHeight ? 1 : 0 }}>
                <animated.div
                  style={{
                    width: "690px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    ...styles,
                  }}
                >
                  <FlexColumn
                    transtion="all .5s"
                    alignItems="center"
                    minHeight={68}
                    position="relative"
                    width={645}
                    borderWidth={showFeedback ? 2 : 0}
                    borderRadius={showFeedback ? 3 : 0}
                    borderStyle="solid"
                    borderColor={showFeedback ? feedbackColor : "#fff"}
                  >
                    <FlexColumn position="absolute" left={-14} top={-14}>
                      <CircularButton
                        size="sm"
                        icon={paragraph.isShowingTranslation ? "close" : "crop"}
                        disabled={
                          isExecutionValidated
                            ? false
                            : ((isDisabled || isPending || isFinished) && !showCorrectOption) || // with this rule, users can see the translation after the item has finished
                              attempts.length === 0
                        }
                        color={paragraph.isShowingTranslation ? GREEN_3 : GREY_5}
                        onClick={() => handleToggleTranslation(paragraph.index)}
                      />
                    </FlexColumn>
                    <FlexColumn
                      className="translation"
                      height="auto"
                      width="100%"
                      backgroundColor={GREY_3}
                      maxHeight={
                        paragraph.isShowingTranslation || !paragraph.elementHeight ? paragraph.elementHeight : 0
                      }
                      overflow="hidden"
                      transition="all .25s"
                      borderTopLeftRadius={3}
                      borderTopRightRadius={3}
                      padding={`${paragraph.isShowingTranslation || !paragraph.elementHeight ? "15px" : "0"} 20px`}
                    >
                      <QuestionLabel fontSize={16} text={paragraph.translation} lineHeight="30px" textAlign="left" />
                    </FlexColumn>
                    <FlexColumn
                      width="100%"
                      padding={20}
                      backgroundColor="#fff"
                      border="1px solid #fff"
                      borderRadius={3}
                    >
                      <QuestionLabel fontSize={18} text={paragraph.text} textAlign="left" lineHeight="30px" />
                    </FlexColumn>
                  </FlexColumn>
                  {showFeedback ? (
                    <FlexColumn
                      width={30}
                      height={30}
                      backgroundColor={feedbackColor}
                      borderRadius="50%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon icon={feedbackIcon} size="xs" color="#ffffff" />
                    </FlexColumn>
                  ) : (
                    <div>
                      {paragraph.index > 0 && (
                        <CircularButton
                          icon="arrow-up"
                          size="sm"
                          color={GREEN_3}
                          disabled={isDisabled || isPending || isFinished}
                          style={{ marginBottom: "8px" }}
                          onClick={() => handleReorder(paragraph.index, paragraph.index - 1)}
                        />
                      )}
                      {paragraph.index < paragraphs.length - 1 && (
                        <CircularButton
                          icon="arrow-down"
                          size="sm"
                          color={GREEN_3}
                          disabled={isDisabled || isPending || isFinished}
                          onClick={() => handleReorder(paragraph.index, paragraph.index + 1)}
                        />
                      )}
                    </div>
                  )}
                </animated.div>
                <Separator size="xs" />
              </div>
            );
          }}
        </Transition>
      </div>
    </ExerciseItemPanel>
  );
};

UnscrambleTextExerciseItem.propTypes = {};

export default function UnscrambleTextExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <UnscrambleTextExerciseRender key={id} itemId={id} />);
}
