import { SET_NEXT_EXECUTION_ORDER, START_EXECUTION } from "../actions/execution";
import {
  CHECK_SPEECH_RECOGNITION_TIPS,
  INCREMENT_CHECKPOINT_SEQUENCE,
  INCREMENT_SPEECH_RECOGNITION_SEQUENCE,
  REMOVE_NOTIFICATION,
  RESET_CHECKPOINT_SEQUENCE,
  RESET_SPEECH_RECOGNITION_SEQUENCE,
} from "../actions/executionAchievement";
import {
  CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION,
  LISTEN_ITEM,
  SELECT_ITEM,
  START_ITEM_EXECUTION,
  START_PLAY_ITEM,
} from "../actions/itemExecution";

export default (state = {}, action) => {
  switch (action.type) {
    case START_EXECUTION:
      return {
        speechRecognitionSequence: 0,
        checkPointSequence: 0,
        noRepeatSequence: 0,
        noListenSequence: 0,
        executionProgressChecked: false,
        notificationQueue: [],
        totalRepeatCount: 0,
        totalListenCount: 0,
      };
    case SELECT_ITEM:
    case SET_NEXT_EXECUTION_ORDER:
    case CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION:
      return {
        ...state,
        noRepeatSequence: 0,
        noListenSequence: 0,
        notificationQueue: [],
      };
    case START_PLAY_ITEM: {
      return {
        ...state,
        noRepeatSequence: 0,
        totalRepeatCount: state.totalRepeatCount + 1,
        notificationQueue: state.notificationQueue.filter(
          (n) => n !== "NO_REPEAT_SEQUENCE" && n !== "REPEAT_USAGE_TIP"
        ),
      };
    }
    case LISTEN_ITEM: {
      return {
        ...state,
        noListenSequence: 0,
        totalListenCount: state.totalListenCount + 1,
        notificationQueue: state.notificationQueue.filter(
          (n) => n !== "NO_LISTEN_SEQUENCE" && n !== "REPEAT_LISTEN_TIP"
        ),
      };
    }
    case START_ITEM_EXECUTION: {
      return {
        ...state,
        noRepeatSequence: 0,
        noListenSequence: 0,
        notificationQueue: [],
      };
    }
    case INCREMENT_SPEECH_RECOGNITION_SEQUENCE:
      return {
        ...state,
        speechRecognitionSequence: state.speechRecognitionSequence + 1,
        checkPointSequence: 0,
        noRepeatSequence: 0,
        noListenSequence: 0,
        notificationQueue:
          (state.speechRecognitionSequence + 1) % 3 === 0
            ? [
                ...state.notificationQueue,
                `${
                  state.speechRecognitionSequence + 1 > 9 ? "MAX" : state.speechRecognitionSequence + 1
                }_SPEECH_RECOGNITION_SEQUENCE`,
              ]
            : state.notificationQueue,
      };
    case RESET_SPEECH_RECOGNITION_SEQUENCE:
      return {
        ...state,
        speechRecognitionSequence: 0,
        noRepeatSequence: action.payload.wasRepeatUsed ? 0 : state.noRepeatSequence + 1,
        noListenSequence: action.payload.wasListenUsed ? 0 : state.noListenSequence + 1,
        notificationQueue: [
          ...((action.payload.wasRepeatUsed ? 0 : state.noRepeatSequence + 1) && (state.noRepeatSequence + 1) % 2 === 0
            ? ["NO_REPEAT_SEQUENCE"]
            : []),
          ...((action.payload.wasListenUsed ? 0 : state.noListenSequence + 1) && (state.noListenSequence + 1) % 2 === 0
            ? ["NO_LISTEN_SEQUENCE"]
            : []),
        ],
      };
    case INCREMENT_CHECKPOINT_SEQUENCE:
      return {
        ...state,
        speechRecognitionSequence: 0,
        checkPointSequence: state.checkPointSequence + 1,
        notificationQueue:
          (state.checkPointSequence + 1) % 3 === 0
            ? [
                ...state.notificationQueue,
                `${state.checkPointSequence + 1 > 9 ? "MAX" : state.checkPointSequence + 1}_CHECKPOINT_SEQUENCE`,
              ]
            : state.notificationQueue,
      };
    case RESET_CHECKPOINT_SEQUENCE:
      return {
        ...state,
        checkPointSequence: 0,
        notificationQueue: [],
      };
    case CHECK_SPEECH_RECOGNITION_TIPS:
      return action.payload.progress < 0.7 || state.executionProgressChecked
        ? state
        : {
            ...state,
            executionProgressChecked: true,
            notificationQueue: [
              ...state.notificationQueue,
              ...(!state.totalRepeatCount ? ["REPEAT_USAGE_TIP"] : []),
              ...(!state.totalListenCount ? ["LISTEN_USAGE_TIP"] : []),
            ],
          };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notificationQueue: state.notificationQueue.filter((n) => n !== action.payload.notification),
      };
    default:
      return state;
  }
};
