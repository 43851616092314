import React from "react";
import PropTypes from "prop-types";

const DialogueIcon = ({ color, style }) => (
  <svg width="139" height="138" viewBox="0 0 139 138" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M85.1498 94.3199C85.1604 87.8481 83.6667 81.4623 80.7865 75.6667C77.9063 69.8711 73.7184 64.8242 68.5535 60.9244C63.3886 57.0246 57.3879 54.3787 51.0251 53.1954C44.6624 52.0121 38.1117 52.3239 31.89 54.1061C25.6684 55.8883 19.9462 59.0922 15.175 63.4649C10.4037 67.8375 6.71411 73.2593 4.39733 79.3023C2.08054 85.3452 1.2 91.844 1.8252 98.2856C2.4504 104.727 4.56422 110.935 7.99983 116.42C8.29981 116.898 8.49113 117.436 8.56025 117.995C8.62937 118.555 8.57461 119.124 8.39983 119.66L3.75983 134L18.0598 129.4C18.5952 129.229 19.1611 129.177 19.7187 129.246C20.2763 129.315 20.8123 129.504 21.2898 129.8C27.6118 133.743 34.8736 135.923 42.322 136.113C49.7704 136.304 57.1341 134.498 63.6494 130.883C70.1647 127.269 75.5942 121.977 79.3748 115.556C83.1554 109.136 85.1493 101.821 85.1498 94.3699V94.3199Z"
      fill="white"
    />
    <path
      d="M3.99992 137.73L21.8499 132L22.1499 132.18C31.2148 137.389 41.8632 139.128 52.1143 137.074C62.3655 135.019 71.5213 129.311 77.8788 121.011C84.2362 112.711 87.3624 102.384 86.6758 91.9519C85.9891 81.5195 81.5364 71.6916 74.146 64.2965C66.7555 56.9014 56.9305 52.4426 46.4985 51.7494C36.0665 51.0562 25.7379 54.1759 17.434 60.5281C9.13001 66.8803 3.41611 76.0326 1.35517 86.2825C-0.705773 96.5323 1.02656 107.182 6.22992 116.25L6.39993 116.56L0.639923 134.46C0.495852 134.923 0.482861 135.418 0.602383 135.888C0.721905 136.359 0.969279 136.787 1.31712 137.125C1.66496 137.464 2.0997 137.7 2.5732 137.806C3.0467 137.913 3.5405 137.887 3.99992 137.73ZM7.25992 131.16L11.7699 117C11.8884 116.636 11.9257 116.25 11.879 115.87C11.8324 115.49 11.703 115.125 11.4999 114.8C6.58445 106.918 4.74054 97.5038 6.31954 88.3502C7.89854 79.1966 12.7901 70.9439 20.0621 65.1647C27.334 59.3854 36.4781 56.4836 45.7518 57.0122C55.0255 57.5408 63.7806 61.4629 70.3488 68.0311C76.917 74.5992 80.839 83.3543 81.3676 92.628C81.8963 101.902 78.9945 111.046 73.2152 118.318C67.436 125.59 59.1833 130.481 50.0297 132.06C40.8761 133.639 31.4615 131.795 23.5799 126.88C23.255 126.678 22.8902 126.548 22.5105 126.5C22.1308 126.451 21.7452 126.486 21.3799 126.6L7.25992 131.16Z"
      fill={color}
      fillOpacity={0.5}
    />
    <path
      d="M36.54 54.18C36.5369 46.528 38.3109 38.9799 41.7225 32.1306C45.134 25.2812 50.0897 19.3178 56.199 14.7104C62.3084 10.1029 69.4043 6.97741 76.9276 5.58014C84.4509 4.18287 92.1959 4.55204 99.5522 6.65855C106.908 8.76506 113.675 12.5513 119.318 17.719C124.962 22.8866 129.328 29.2943 132.072 36.4371C134.817 43.5799 135.865 51.2626 135.134 58.8795C134.403 66.4965 131.913 73.8395 127.86 80.33C127.507 80.8955 127.282 81.5313 127.201 82.1929C127.12 82.8546 127.184 83.5259 127.39 84.16L132.83 101.07L115.92 95.62C115.285 95.415 114.614 95.3518 113.952 95.4347C113.29 95.5177 112.654 95.7448 112.09 96.1C104.61 100.763 96.019 103.341 87.2074 103.565C78.3959 103.789 69.6847 101.652 61.9772 97.3757C54.2698 93.0991 47.8467 86.8385 43.3739 79.2432C38.9012 71.6479 36.5417 62.9944 36.54 54.18Z"
      fill="white"
    />
    <path
      d="M85.4497 57.89C87.7749 57.89 89.6598 56.0051 89.6598 53.68C89.6598 51.3549 87.7749 49.47 85.4497 49.47C83.1246 49.47 81.2397 51.3549 81.2397 53.68C81.2397 56.0051 83.1246 57.89 85.4497 57.89Z"
      fill={color}
    />
    <path
      d="M68.6001 57.89C70.9253 57.89 72.8101 56.0051 72.8101 53.68C72.8101 51.3549 70.9253 49.47 68.6001 49.47C66.275 49.47 64.3901 51.3549 64.3901 53.68C64.3901 56.0051 66.275 57.89 68.6001 57.89Z"
      fill={color}
    />
    <path
      d="M102.29 57.89C104.615 57.89 106.5 56.0051 106.5 53.68C106.5 51.3549 104.615 49.47 102.29 49.47C99.965 49.47 98.0801 51.3549 98.0801 53.68C98.0801 56.0051 99.965 57.89 102.29 57.89Z"
      fill={color}
    />
    <path
      d="M122.362 17.3937C122.824 16.1042 122.153 14.6845 120.863 14.2226C119.574 13.7606 118.154 14.4314 117.692 15.7208C117.23 17.0103 117.901 18.43 119.191 18.8919C120.48 19.3539 121.9 18.6831 122.362 17.3937Z"
      fill={color}
    />
    <path
      d="M135.09 106.82C135.486 106.949 135.906 106.986 136.318 106.929C136.73 106.872 137.124 106.722 137.47 106.49C137.87 106.21 138.199 105.841 138.433 105.412C138.667 104.984 138.8 104.508 138.82 104.02L131.32 80.77L131.65 80.2C136.281 72.1285 138.718 62.9855 138.72 53.68C138.755 43.1343 135.619 32.8216 129.72 24.08C129.491 23.7428 129.19 23.4604 128.839 23.253C128.488 23.0456 128.095 22.9182 127.689 22.88C127.283 22.8418 126.874 22.8937 126.49 23.032C126.107 23.1702 125.758 23.3915 125.47 23.68C125.014 24.1314 124.729 24.7271 124.663 25.3655C124.597 26.0038 124.755 26.6452 125.11 27.18C130.345 34.9886 133.152 44.1714 133.179 53.5723C133.206 62.9732 130.45 72.1718 125.26 80.01L131.65 99.87L113.05 93.87C112.664 93.7474 112.256 93.7099 111.853 93.76C111.451 93.81 111.065 93.9465 110.72 94.16C100.284 100.67 87.736 102.897 75.6967 100.376C63.6574 97.8552 53.0574 90.7807 46.1101 80.63C37.0001 67.39 35.2401 50.79 41.2701 35.09C43.4941 29.3299 46.8892 24.094 51.2406 19.7133C55.592 15.3327 60.8051 11.9026 66.5501 9.63998C80.9001 4.00998 96.1501 4.93998 108.85 12.12C109.431 12.4482 110.111 12.5542 110.764 12.418C111.417 12.2819 111.999 11.913 112.4 11.38C112.641 11.0658 112.812 10.7037 112.901 10.3181C112.991 9.93256 112.997 9.5323 112.919 9.14415C112.842 8.75599 112.682 8.38889 112.451 8.0674C112.22 7.74592 111.923 7.47745 111.58 7.27998C97.5001 -0.720018 80.5801 -1.72002 64.5801 4.39998C58.1013 6.90265 52.2183 10.7341 47.3098 15.6478C42.4012 20.5615 38.5759 26.4486 36.0801 32.93C29.3501 50.48 31.3201 69.01 41.4801 83.79C49.0496 94.8579 60.5152 102.663 73.5877 105.648C86.6601 108.632 100.377 106.576 112 99.89L112.58 99.56L135.09 106.82Z"
      fill={color}
    />
  </svg>
);

DialogueIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default DialogueIcon;
