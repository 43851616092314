import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import B2BTastingContactForm from "./TastingContactForm";
import { SAVE_TASTING_CONTACT_FLOW } from "../../../consts";

const TastingContactFormContainer = () => (
  <Flow id={SAVE_TASTING_CONTACT_FLOW}>
    {({ isPending, handleStart }) => <B2BTastingContactForm onSubmit={handleStart} isSubmitting={isPending} />}
  </Flow>
);

export default TastingContactFormContainer;
