import get from "lodash/get";
import { detect } from "detect-browser";
import { RecordRTCPromisesHandler, StereoAudioRecorder, MediaStreamRecorder } from "recordrtc";
import { call, cancelled, delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import sample from "lodash/sample";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart, getFlow, getFlowEnd } from "student-front-commons/src/selectors/flow";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getAnswer, getScore } from "student-front-commons/src/services/speechRecognitionService";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import {
  CHECK_ACHIEVEMENT_EXECUTION_FLOW,
  OLD_END_RECORD_BY_TIMEOUT_FLOW,
  OLD_END_RECORD_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW,
  OLD_START_RECORD_FLOW,
  SYSTEM_MESSAGE_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
} from "../../consts";
import { insertSpeechRecognitionAudio, playAudio, stopAudio } from "../../stores/audio-store";
import LogRocket from "logrocket";
import { logError } from "../../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const info = detect();
let recordStream = null;
let recorderInstance = null;

export function* startOldRecordFlow() {
  yield takeLatest(getFlowStart(OLD_START_RECORD_FLOW), function* () {
    yield race({
      cancelMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      cancelCertification: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          recordStream = yield new Promise((resolve, reject) => {
            if (navigator.mediaDevices) {
              navigator.mediaDevices
                .getUserMedia({ audio: true, video: false })
                .then((stream) => resolve(stream))
                .catch((error) => reject(error));
            } else {
              (
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia ||
                navigator.msGetUserMedia
              )(
                { audio: true, video: false },
                (stream) => resolve(stream),
                (error) => reject(error)
              );
            }
          });

          const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));

          recorderInstance = new RecordRTCPromisesHandler(recordStream, {
            type: "audio",
            numberOfAudioChannels: 1,
            recorderType: info.name === "safari" ? StereoAudioRecorder : MediaStreamRecorder,
            disableLogs: true,
          });
          yield recorderInstance.startRecording();

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isRecording", true));
          yield put(
            startFlow(OLD_END_RECORD_BY_TIMEOUT_FLOW, {
              time: itemExecutionForm.values.associativeItem.timeToAutoEndRecord,
            })
          );
        } catch (error) {
          if (error.name === "PermissionDeniedError" || error.name === "NotAllowedError") {
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.error_record_not_allowed",
              })
            );
          } else if (error.message === "Requested device not found") {
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.error_record_missing_mic",
              })
            );
          } else {
            logError({ error, flow: OLD_START_RECORD_FLOW });
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.error_start_record",
              })
            );
          }
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isRecording", false));
          if (recorderInstance) {
            recorderInstance.stopRecording();
            yield recorderInstance.destroy();

            recordStream.getAudioTracks().forEach((track) => track.stop());
          }
        } finally {
          if (yield cancelled()) {
            if (recorderInstance) {
              recorderInstance.stopRecording();
              recorderInstance.clearRecordedData();
            }
          }
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
          yield put(endFlow(OLD_START_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endOldRecordFlow() {
  yield takeLatest(getFlowStart(OLD_END_RECORD_FLOW), function* () {
    yield race({
      cancelMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      cancelCertification: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield recorderInstance.stopRecording();
          recordStream.getAudioTracks().forEach((track) => track.stop());

          const recordBlob = yield recorderInstance.getBlob();
          const recordUrl = yield recorderInstance.getDataURL();

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", true));
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isRecording", false));

          const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          const currentItem = itemExecutionForm.values.associativeItem.item;

          if (recordBlob.size === 0) {
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.noSpeechDetected",
              })
            );
            return;
          }

          yield call(insertSpeechRecognitionAudio, {
            data: recordUrl,
            isDeletable: true,
          });
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "recordFile", recordBlob));
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "recordSound", recordUrl));

          const id = sessionStorage.getItem("id");
          const contractCode = sessionStorage.getItem("contractCode");
          let profile = {
            isDemoStudent: false,
          };
          let schoolClass = {};
          let school = { allowSpeechRecognitionBonus: true };
          let company = { allowSpeechRecognitionBonus: true };
          if (id !== "tasting_user") {
            profile = yield select(getEntityById("profile", id));
            company = yield select(getEntityById("company", profile.company));
            if (!profile.apiVersion) {
              schoolClass = yield select(getEntityById("schoolClass", profile.schoolClass));
              school = yield select(getEntityById("school", schoolClass.school));
            }
          }

          if (
            ["VIDEO_SHORT", "PRESENTATION", "SPEECH_PRACTICE", "PRONUNCIATION"].some(
              (type) => type === currentItem.type.key
            )
          ) {
            try {
              const speechRecognitionResult = yield call(getScore, {
                id: contractCode ? `${contractCode}-${id}` : id,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                text: currentItem.text,
                wordsDictionary: currentItem.speechRecognitionDictionary || {},
                allowSpeechRecognitionBonus:
                  itemExecutionForm.values.recordCount >= 3 &&
                  (profile.apiVersion === "V2"
                    ? company.allowSpeechRecognitionBonus
                    : school.allowSpeechRecognitionBonus),
              });
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "speechRecognitionResult", speechRecognitionResult));
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "recordCount", itemExecutionForm.values.recordCount + 1));
              yield call(insertSpeechRecognitionAudio, {
                data: recordUrl,
                isDeletable: true,
                spriteWords: speechRecognitionResult.wordScoreList,
              });

              const minimumSpeechRecognitionScore = yield select(
                (state) => state.configurations.scoreToPassOfSpeechRecognition
              );

              const answerResult = yield call(checkAnswer, {
                item: currentItem,
                answer: speechRecognitionResult.qualityScore,
                minimumSpeechRecognitionScore,
              });

              // we need to store the attempts here, because every SR should be stored as an attempt
              const currentAnswer = { answer: answerResult.answer, correct: answerResult.status === "CORRECT" };
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "answer", currentAnswer));
              yield put(
                changeFormValue(ITEM_EXECUTION_FORM, "attempts", [...itemExecutionForm.values.attempts, currentAnswer])
              );

              if (currentItem.scope === "UNIT") {
                const historyItem = {
                  id: new Date().toISOString(),
                  sound: recordUrl,
                  score: speechRecognitionResult.qualityScore,
                };
                yield put(
                  changeFormValue(ITEM_EXECUTION_FORM, "speechRecognitionResultHistory", [
                    ...itemExecutionForm.values.speechRecognitionResultHistory,
                    historyItem,
                  ])
                );

                if (currentItem.type.key === "PRONUNCIATION" && speechRecognitionResult.qualityScore > 50) {
                  yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW));
                  yield take(getFlowEnd(OLD_PLAY_ITEM_AUDIO_FLOW));
                }

                yield put(startFlow(CHECK_ACHIEVEMENT_EXECUTION_FLOW, { type: "record-check" }));
              }
            } catch (error) {
              LogRocket.log(error);
              if (error.code === "error_no_speech") {
                yield put(
                  showMessage({
                    message: `error.${error.code}`,
                  })
                );
              }
            } finally {
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
            }
          }

          if (["GAP_FILL", "UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === currentItem.type.key)) {
            try {
              const { answer, selectedAnswers } = yield call(getAnswer, {
                id: contractCode ? `${contractCode}-${id}` : id,
                isDemoStudent: !!profile.isDemoStudent,
                isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                record: recordBlob,
                text: currentItem.text,
                answers: currentItem.answers,
                wordsDictionary: currentItem.speechRecognitionDictionary || {},
              });

              yield put(changeFormValue(ITEM_EXECUTION_FORM, "recordCount", itemExecutionForm.values.recordCount + 1));
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "answer", answer));
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "extraData", selectedAnswers));
            } catch (error) {
              if (
                error.code === "error_no_speech" ||
                error.code === "error_word_alignment" ||
                error.code === "error_find_speech_recognition_text"
              ) {
                yield put(
                  showMessage({
                    message: `error.${error.code}`,
                  })
                );
              } else {
                const speechErrorAudio = yield select((state) => state.configurations.speechRecognitionErrorAudios);
                const audio = sample(speechErrorAudio);
                yield call(playAudio, {
                  url: audio.path || audio.generatedAudio,
                });
              }
            } finally {
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
            }
          }

          if (["FREE_SPEAK", "FREE_SPEAK_IMAGE"].some((type) => type === currentItem.type.key)) {
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));
            if (currentItem.type.key === "FREE_SPEAK") {
              try {
                const speechRecognitionResult = yield call(getScore, {
                  id: contractCode ? `${contractCode}-${id}` : id,
                  isDemoStudent: !!profile.isDemoStudent,
                  isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
                  record: recordBlob,
                  text: currentItem.text,
                  wordsDictionary: currentItem.speechRecognitionDictionary || {},
                  allowSpeechRecognitionBonus: false,
                  passUnknownWordsError: true,
                });

                // check if student recorded the item text instead of reply
                if (speechRecognitionResult.qualityScore >= 75) {
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
                  yield put(
                    startFlow(SYSTEM_MESSAGE_FLOW, {
                      message: "error.error_reply_ct_speak",
                      button: "certificationTest.help.gotIt",
                    })
                  );
                  yield take(getFlowEnd(SYSTEM_MESSAGE_FLOW));
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
                  return;
                }
              } catch (error) {
                if (error.code === "error_no_speech") {
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
                  yield put(
                    startFlow(SYSTEM_MESSAGE_FLOW, {
                      message: "error.noSpeechDetected",
                    })
                  );
                  yield take(getFlowEnd(SYSTEM_MESSAGE_FLOW));
                  yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
                  return;
                }
              }
            }

            yield put(changeFormValue(ITEM_EXECUTION_FORM, "answer", recordBlob));
            yield put(startFlow(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW));
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
            return;
          }

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));

          //if it is a unit execution, start use away timeout flow because it was stopped on record start
          if (window.location.pathname.indexOf("/units") > 0) {
            yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));
          }
        } catch (error) {
          if (typeof error === "string" && error.indexOf("Empty blob") > -1) {
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.noSpeechDetected",
              })
            );
          } else {
            logError({ error, flow: OLD_END_RECORD_FLOW });
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.error_stop_record",
              })
            );
          }
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isRecording", false));
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSubmittingRecord", false));
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }

          yield recorderInstance.destroy();
          yield put(endFlow(OLD_END_RECORD_FLOW));
        }
      }),
    });
  });
}

export function* endOldRecordByTimeoutFlow() {
  yield takeLatest(getFlowStart(OLD_END_RECORD_BY_TIMEOUT_FLOW), function* () {
    const flow = yield select(getFlow(OLD_END_RECORD_BY_TIMEOUT_FLOW));
    const raceWinner = yield race({
      timeout: delay(get(flow.params, "time", 60000) || 60000),
      exitMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      exitCertification: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      userStop: take(getFlowStart(OLD_END_RECORD_FLOW)),
    });
    if (raceWinner.timeout) {
      yield put(startFlow(OLD_END_RECORD_FLOW));
    }
  });
}
