import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import StudentInformation from "./StudentInformation";

const StudentInformationContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));

      if (profile.apiVersion === "V2") {
        return (
          <Entity name="company">
            {({ getById: getCompanyById }) => {
              const company = getCompanyById(profile.company);

              return (
                <StudentInformation
                  school={company.name}
                  name={profile.nickname || profile.name.split(" ")[0]}
                  profilePicture={profile.profilePicture}
                />
              );
            }}
          </Entity>
        );
      }

      return (
        <Entity name="schoolClass">
          {({ getById: getSchoolClassById }) => (
            <Entity name="school">
              {({ getById: getSchoolById }) => {
                const profile = getProfileById(sessionStorage.getItem("id"));
                const schoolClass = getSchoolClassById(profile.schoolClass);
                const school = getSchoolById(schoolClass?.school);

                return (
                  <StudentInformation
                    school={school.name}
                    name={profile.nickname || profile.name.split(" ")[0]}
                    profilePicture={profile.profilePicture}
                  />
                );
              }}
            </Entity>
          )}
        </Entity>
      );
    }}
  </Entity>
);

export default StudentInformationContainer;
