import React, { Component } from "react";
import PropTypes from "prop-types";
import StudyQualityStatsSceneContainer from "./StudyQualityStatsSceneContainer";
import Icon from "../Icon";
import { GREY_3, WHITE } from "../../consts/color";

class StudyQualityStatsModal extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsidePopoverClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsidePopoverClick, true);
  }

  handleOutsidePopoverClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    document.removeEventListener("click", this.handleOutsidePopoverClick, true);
    this.props.onClose();
  };

  render() {
    return (
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          top: 0,
          left: 0,
          zIndex: 15,
        }}
      >
        <div
          ref={(node) => {
            this.node = node;
          }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            backgroundColor: WHITE,
            minWidth: 360,
            borderRadius: 10,
            transition: "all .3s",
          }}
        >
          <div
            style={{
              position: "absolute",
              right: 0,
              top: -5,
            }}
          >
            <Icon icon="close-circle" size="md" cursor="pointer" color={GREY_3} onClick={this.props.onClose} />
          </div>
          <StudyQualityStatsSceneContainer />
        </div>
      </div>
    );
  }
}

export default StudyQualityStatsModal;
