export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";

/**
 * show a system message
 *
 * @param {object} options
 * @param {string} options.message
 * @param {string} [options.type] error, success, info
 * @param {string} [options.icon]
 * @param {string} [options.button]
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const showMessage = ({ message, icon, button, type }) => ({
  type: SHOW_MESSAGE,
  payload: {
    message,
    icon,
    button,
    type,
  },
});

/**
 * hide the message
 *
 * @returns {{ type: string }}
 */
export const hideMessage = () => ({
  type: HIDE_MESSAGE,
});
