import PropTypes from "prop-types";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import BonusTestRewardsProgressBar from "./BonusTestRewardsProgressBar";
import RewardBg from "../../images/bonus-test-result-reward-bg.png";
import NotCompletedImg from "../../images/bonus-test-result-not-completed.png";
import { get } from "lodash";
import styled, { keyframes } from "styled-components";
import { useHistory } from "react-router-dom";
import AudioResultReward from "../../audios/bonus-test-result-reward.mp3";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.15);
  }
`;

const BgRewardAnimated = styled.img`
  animation: ${rotateAnimation} 30s linear infinite;
  position: absolute;
  top: 9px;
`;

const GemRewardAnimated = styled.img`
  animation: ${pulseAnimation} 1s linear infinite;
`;

export default function BonusTestRewardsCurrentResult(props) {
  const correctAnswers = get(props.bonusTestResult, "answeredItems", []).filter((item) => item.correct).length;
  const bgColor = { 3: "#f1ffe8", 2: "#dafaff", 1: "#ffd3d2" }[props.bonusTestResult?.studentAchievement?.position];
  const history = useHistory();

  return (
    <FlexColumn alignItems="center" width="100%" height="365px">
      {props.bonusTestResult.studentAchievement && (
        <>
          {history.location.state?.fromTestExecution && <audio src={AudioResultReward} autoPlay={true} />}
          <Separator size="xl" />
          <TranslatedText
            as="span"
            translateKey="bonusTest.result.title"
            style={{ color: "#fff", fontSize: "28px", fontWeight: 700 }}
          />
          <Separator size="md" />
          {history.location.state?.fromTestExecution ? (
            <BgRewardAnimated src={RewardBg} />
          ) : (
            <img src={RewardBg} style={{ position: "absolute", top: "9px" }} />
          )}
          <div className={history.location.state?.fromTestExecution ? "animate__animated animate__zoomIn" : ""}>
            <FlexColumn
              alignItems="center"
              justifyContent="center"
              backgroundColor={bgColor}
              width="80px"
              height="80px"
              borderRadius="80px"
              position="relative"
            >
              {history.location.state?.fromTestExecution ? (
                <GemRewardAnimated
                  src={`${process.env.REACT_APP_FILES_URL}/${
                    props.bonusTestResult.studentAchievement.achievement.iconByPosition.find(
                      (icon) => icon.position === props.bonusTestResult.studentAchievement.position
                    ).icon
                  }`}
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_FILES_URL}/${
                    props.bonusTestResult.studentAchievement.achievement.iconByPosition.find(
                      (icon) => icon.position === props.bonusTestResult.studentAchievement.position
                    ).icon
                  }`}
                />
              )}
            </FlexColumn>
          </div>
          <Separator size="md" />
          <TranslatedText
            translateKey="bonusTest.result.correctAnswers"
            as="span"
            values={{
              correctAnswers: correctAnswers,
            }}
            style={{
              color: "#fff",
              width: "400px",
              fontSize: "16px",
              textAlign: "center",
            }}
          />
          <Separator size="sm" />
          <RankingParticipantContainer>
            <FlexRow
              alignItems="center"
              justifyContent="center"
              height="40px"
              backgroundColor="#ffffff10"
              padding="0 10px"
              borderRadius="4px"
            >
              {props.bonusTestResult.studentAchievement?.points > 0 ? (
                <>
                  <span style={{ marginRight: "3px", fontSize: "14px", fontWeight: 700, color: "#fff" }}>+</span>
                  <TranslatedText
                    translateKey="bonusTest.points"
                    values={{
                      points: props.bonusTestResult.studentAchievement?.points,
                    }}
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                  />
                </>
              ) : (
                <TranslatedText
                  translateKey="bonusTest.noPoints"
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                />
              )}
            </FlexRow>
          </RankingParticipantContainer>
        </>
      )}
      {!props.bonusTestResult.studentAchievement && props.bonusTestResult.completedAt && (
        <>
          <Separator size="xl" />
          <TranslatedText
            as="span"
            translateKey="bonusTest.result.title"
            style={{ color: "#fff", fontSize: "28px", fontWeight: 700 }}
          />
          <Separator size="md" />
          <TranslatedText
            translateKey="bonusTest.result.correctAnswers"
            as="span"
            values={{
              correctAnswers: correctAnswers,
            }}
            style={{
              color: "#fff",
              width: "400px",
              fontSize: "20px",
              textAlign: "center",
            }}
          />
          <Separator size="md" />
          <TranslatedText
            translateKey="bonusTest.result.noBonus.title"
            as="span"
            style={{
              color: "#fff",
              width: "400px",
              fontSize: "16px",
              textAlign: "center",
              lineHeight: "24px",
            }}
          />
          <TranslatedText
            translateKey="bonusTest.result.noBonus.description"
            as="span"
            style={{
              color: "#fff",
              width: "400px",
              fontSize: "16px",
              textAlign: "center",
              lineHeight: "24px",
              fontWeight: 700,
            }}
          />
        </>
      )}
      {!props.bonusTestResult.completedAt && (
        <>
          <Separator size="xxl" />
          <img src={NotCompletedImg} />
          <Separator size="lg" />
          <TranslatedText
            as="span"
            translateKey="bonusTest.result.notCompleted.title"
            style={{ color: "#fff", fontSize: "24px", fontWeight: 700 }}
          />
          <Separator size="xs" />
          <TranslatedText
            as="span"
            translateKey="bonusTest.result.notCompleted.description1"
            style={{ color: "#fff", fontSize: "16px", lineHeight: "24px", width: "400px", textAlign: "center" }}
          />
          <TranslatedText
            as="span"
            translateKey="bonusTest.result.notCompleted.description2"
            style={{
              color: "#fff",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 700,
              width: "400px",
              textAlign: "center",
            }}
          />
        </>
      )}
      {props.bonusTestResult.completedAt && (
        <>
          <Separator size="xxxl" />
          <BonusTestRewardsProgressBar correctAnswers={correctAnswers} rewards={props.rewards} />
        </>
      )}
    </FlexColumn>
  );
}

BonusTestRewardsCurrentResult.propTypes = {
  rewards: PropTypes.object.isRequired,
  bonusTestResult: PropTypes.object.isRequired,
};
