import React from "react";
import Form from "student-front-commons/src/components/Form";
import PracticeTestProgressBar from "./PracticeTestProgressBar";
import { PRACTICE_TEST_EXECUTION_FORM } from "../../../consts";

const PracticeTestProgressBarContainer = () => (
  <Form id={PRACTICE_TEST_EXECUTION_FORM} onSubmit={() => false} autoInit={false}>
    {({ values }) => (
      <PracticeTestProgressBar percentage={(values.answers.length / values.practiceTestItems.length) * 100} />
    )}
  </Form>
);

export default PracticeTestProgressBarContainer;
