import React, { useEffect, useState, useRef } from "react";
import omit from "lodash/omit";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import Icon from "../Icon";
import TranslatedText from "../TranslatedText";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../../core/CourseThemeContext";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG } from "../../consts/color";
import ValidationWrapper from "./ValidationWrapper";

function DictationRender(props) {
  const [rows, setRows] = useState(1);
  const dispatch = useDispatch();
  const dictationInput = useRef();
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const extraData = useSelector(getItemExecutionPropById(props.itemId, "extraData"));
  const answer = useSelector(getItemExecutionPropById(props.itemId, "answer"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));

  useEffect(() => {
    if (dictationInput.current) {
      dictationInput.current.focus();
    }
  });

  const handleKeyDown = (event) => {
    const key = event.keyCode || event.wich;
    if (key === 13) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const input = dictationInput?.current;
    setRows(Math.trunc(input?.scrollHeight / 38));
  }, [answer, dictationInput]);

  const blockFeature = (event) => event.preventDefault();

  return (
    <CourseThemeContext.Consumer>
      {({ primary }) => (
        <ExerciseItemPanel>
          <HighlightableItemBox
            feedbackContent={extraData?.rating}
            isWrong={shouldShowCorrectOption}
            showFeedback={isExecutionValidated}
          >
            <textarea
              spellCheck={false}
              autoCorrect="off"
              autoComplete="off"
              autoCapitalize="off"
              ref={(input) => {
                dictationInput.current = input;
              }}
              value={answer}
              onKeyDown={handleKeyDown}
              onCopy={blockFeature}
              onPaste={blockFeature}
              onCut={blockFeature}
              rows={rows}
              disabled={isDisabled || isExecutionValidated}
              onChange={(event) => {
                const value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                dispatch(addItemExecutionAnswer(props.itemId, { answer: value }));
              }}
              style={{
                border: "none",
                borderBottom: "1px solid #cecece",
                padding: "0px 5px",
                fontSize: 30,
                width: "100%",
                lineHeight: "38px",
                outline: "none",
                fontWeight: 400,
                color: "#607d8b",
                ...(!shouldShowCorrectOption &&
                  isExecutionValidated && {
                    color: primary,
                  }),
                opacity: isDisabled ? 0.7 : 1,
                backgroundColor: "#fff",
                resize: "none",
                overflow: "hidden",
              }}
            />
          </HighlightableItemBox>
          <Separator />
          {!isExecutionValidated &&
            extraData &&
            !!Object.keys(omit(extraData, ["bestMatch", "diffs", "originalText", "rating"])).length && (
              <div
                style={{
                  paddingLeft: 5,
                }}
              >
                <TranslatedText
                  as="p"
                  translateKey="items.dictation.errorTitle"
                  style={{
                    color: "#90a4ae",
                    fontSize: 16,
                    margin: 0,
                  }}
                />
                <ul
                  style={{
                    listStyle: "none",
                    paddingLeft: 5,
                  }}
                >
                  {extraData.moreWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.moreWordsError"
                        values={{
                          count: extraData.moreWords,
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {extraData.missingWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.missingWordsError"
                        values={{
                          count: extraData.missingWords,
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {extraData.typos && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.wordsWithTyposError"
                        values={{
                          words: extraData.typos.reduce(
                            (acc, word, index) => acc.concat(index ? ", " : "").concat(word),
                            ""
                          ),
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {extraData.wrongWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.wrongWordsError"
                        values={{
                          words: extraData.wrongWords.reduce(
                            (acc, word, index) => acc.concat(index ? ", " : "").concat(word),
                            ""
                          ),
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                </ul>
              </div>
            )}
          {isExecutionValidated && extraData?.rating < 100 && (
            <ValidationWrapper>
              <span
                style={{
                  fontSize: 30,
                  color: primary,
                }}
              >
                {(extraData?.diffs || []).map((chunk, index) => (
                  <>
                    <span
                      style={{
                        color: chunk.removed ? FEEDBACK_WRONG : chunk.added ? FEEDBACK_CORRECT : "#607d8b",
                        fontWeight: chunk.added ? "bold" : "inherit",
                        textDecoration: chunk.removed ? "line-through" : chunk.added ? "underline" : "none",
                        fontFamily: "inherit",
                      }}
                    >
                      {chunk.text.trim()}
                    </span>
                    {extraData.diffs[index + 1] && extraData.diffs[index + 1].text.charAt(0).match(/[a-zA-Z0-9\s]/)
                      ? " "
                      : ""}
                  </>
                ))}
              </span>
            </ValidationWrapper>
          )}
        </ExerciseItemPanel>
      )}
    </CourseThemeContext.Consumer>
  );
}

export default function DictationExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <DictationRender key={id} itemId={id} />);
}
