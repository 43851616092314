import React from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import FlexColumn from "../../core/FlexColumn";
import CompleteProfileCard from "../../core/complete-profile/CompleteProfileCard";

const CompleteProfileScene = () => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <FlexColumn alignItems="center">
        <div
          style={{
            width: "100%",
            height: 350,
            backgroundColor: primaryColor,
            boxSizing: "border-box",
            position: "absolute",
            top: 50,
            boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.5)",
            zIndex: -1,
          }}
        />
        <Separator size="sm" />
        <Separator size="sm" />
        <TranslatedText
          as="h1"
          translateKey="profile.account.completeProfileTitle"
          style={{
            color: "#fff",
            fontSize: 20,
            fontWeight: "bold",
            margin: 0,
            lineHeight: "26px",
          }}
        />
        <Separator size="md" />
        <CompleteProfileCard />
      </FlexColumn>
    )}
  </WhitelabelContext.Consumer>
);

export default CompleteProfileScene;
