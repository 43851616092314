import { Howl } from "howler";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getScore } from "student-front-commons/src/services/speechRecognitionService";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import browserHistory from "../browserHistory";
import {
  MICROPHONE_CONFIG_INITIAL_STEP,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
  MICROPHONE_CONFIG_TUTORIAL_STEP,
  REQUEST_MICROPHONE_CONFIG_END,
  REQUEST_MICROPHONE_CONFIG_NEXT_STEP,
  REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE,
  REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM,
} from "../consts/actions";

function* requestNextStep({ payload }) {
  try {
    if (payload.step === "INITIAL") {
      yield put({
        type: MICROPHONE_CONFIG_INITIAL_STEP,
      });
    } else if (payload.step === "SPEECH_RECOGNITION") {
      yield put({
        type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP,
      });
      yield put({
        type: REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM,
        payload: {},
      });
    } else if (payload.step === "TUTORIAL") {
      yield put({
        type: MICROPHONE_CONFIG_TUTORIAL_STEP,
      });
    }
  } catch (error) {
    yield put({
      type: MICROPHONE_CONFIG_INITIAL_STEP,
    });
  }
}

function* requestNextItem({ payload }) {
  const nextId = (payload.id || 0) + 1;
  const item = yield select((state) => state.microphoneConfig.items.find((x) => x.id === nextId));

  yield put({
    type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
    payload: {
      id: item.id,
      body: {
        ...item,
        status: "active",
      },
    },
  });

  const sound = new Howl({
    src: [item.audio],
    autoplay: false,
    loop: false,
    volume: 1,
  });
  yield new Promise((resolve, reject) => {
    sound.once("end", () => resolve());
    sound.once("playerror", (id, error) => reject(error));
    sound.play();
  });
}

function* requestItemScore({ payload }) {
  try {
    const totalItems = yield select((state) => state.microphoneConfig.items.length);
    const item = yield select((state) => state.microphoneConfig.items.find((x) => x.id === payload.id));

    yield put({
      type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
      payload: {
        id: item.id,
        body: {
          ...item,
          submitting: true,
        },
      },
    });

    const id = sessionStorage.getItem("id");
    const profile = yield select(getEntityById("profile", id));
    const speechRecognitionResult = yield call(getScore, {
      id,
      isDemoStudent: !!profile.isDemoStudent,
      isStagingEnvironment: process.env.REACT_APP_ENVIRONMENT !== "production",
      record: payload.blob,
      text: item.text,
      wordsDictionary: {},
      allowSpeechRecognitionBonus: false,
    });

    yield put({
      type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
      payload: {
        id: item.id,
        body: {
          ...item,
          submitting: false,
          score: speechRecognitionResult.qualityScore,
          status: speechRecognitionResult.qualityScore >= 80 ? "success" : "error",
        },
      },
    });

    if (item.id < totalItems) {
      yield put({
        type: REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM,
        payload: {
          id: item.id,
        },
      });
    } else {
      const items = yield select((state) => state.microphoneConfig.items);

      yield put({
        type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT,
        payload: {
          status: items.find((x) => x.status === "error") ? "NEED_CONFIG" : "SOUNDS_GOOD",
        },
      });
    }
  } catch (error) {
    const item = yield select((state) => state.microphoneConfig.items.find((x) => x.id === payload.id));
    yield put({
      type: MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
      payload: {
        id: item.id,
        body: {
          ...item,
          submitting: false,
          score: undefined,
          status: "active",
        },
      },
    });
  }
}

function* requestEnd() {
  localStorage.setItem(`${sessionStorage.getItem("id")}_microphone_config`, true);
  if (browserHistory.location.state && browserHistory.location.state.redirectUrl) {
    yield call(browserHistory.replace, browserHistory.location.state.redirectUrl);
  } else {
    yield call(browserHistory.replace, "/");
  }
  yield put({
    type: MICROPHONE_CONFIG_INITIAL_STEP,
  });
}

const watchRequestNextStep = function* () {
  yield takeEvery(REQUEST_MICROPHONE_CONFIG_NEXT_STEP, requestNextStep);
};

const watchRequestItemScore = function* () {
  yield takeEvery(REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_ITEM_SCORE, requestItemScore);
};

const watchRequestNextItem = function* () {
  yield takeEvery(REQUEST_MICROPHONE_CONFIG_SPEECH_RECOGNITION_NEXT_ITEM, requestNextItem);
};

const watchRequestEnd = function* () {
  yield takeEvery(REQUEST_MICROPHONE_CONFIG_END, requestEnd);
};

export default [watchRequestNextStep, watchRequestItemScore, watchRequestNextItem, watchRequestEnd];
