import React from "react";

const DropOption = (props) => (
  <span
    style={{
      display: "inline-block",
      backgroundColor: props.isOver && !props.disabled ? "rgba(207, 216, 220, .1)" : "rgba(207, 216, 220, .5)",
      minWidth: 100,
      width: "auto",
      height: 40,
      boxShadow: props.isOver && !props.disabled ? "0px 3px 3px 0 rgba(0, 0, 0, 0.1)" : "none",
      border: "1.5px dashed rgb(96, 125, 138)",
      textAlign: "center",
      margin: "10px 10px",
      borderRadius: 3,
      overflow: "hidden",
    }}
  >
    {props.children}
  </span>
);

export default DropOption;
