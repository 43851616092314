import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrolledContent from "../../core/ScrolledContent";
import IconButton from "../../core/IconButton";
import CoursesRulerList from "./CoursesRulerList";
import BodyPortal from "../../core/BodyPortal";
import WindowResizeListener from "../../core/WindowResizeListener";

export default class CoursesRuler extends Component {
  static propTypes = {
    placementCourses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    initialEnglishLevel: PropTypes.number,
    currentEnglishLevel: PropTypes.number,
  };

  static defaultProps = {
    initialEnglishLevel: null,
    currentEnglishLevel: null,
  };

  state = {
    position: -200,
    expandable: false,
    levelEnd: 0,
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
    clearTimeout(this.toggleTimeout);
  }

  getRulerStartPosition = () => {
    let start = 0;

    if (this.props.initialEnglishLevel > 0) {
      start +=
        this.props.placementCourses.filter((placementCourse) => placementCourse.level <= this.props.initialEnglishLevel)
          .length * 55;
      start += 55;
    }
    if (this.props.initialEnglishLevel > 0 && this.props.initialEnglishLevel < 1) {
      start += 110 * this.props.initialEnglishLevel;
      start -= 55;
    }
    if (this.props.initialEnglishLevel === 0) {
      start = 55;
    }

    return start;
  };

  handleToggleBar = () => {
    this.toggleTimeout = setTimeout(() => {
      this.setState({
        position: this.state.position === 0 ? -200 : 0,
      });
    }, 0);
  };

  handleResize = ({ windowWidth }) => {
    let position = 0;
    let expandable = false;
    if (windowWidth < 1280) {
      position = -200;
      expandable = true;
    }
    this.setState({
      position,
      expandable,
    });

    this.timeout = setTimeout(() => {
      this.setState({
        levelEnd: (this.props.currentEnglishLevel - this.props.initialEnglishLevel) * 220,
      });
    }, 100);
  };

  render() {
    return (
      <div
        className="stepRuler"
        style={{
          position: "fixed",
          overflow: "hidden",
          right: 0,
          top: 0,
          width: 200,
          height: "100vh",
          zIndex: 5,
        }}
      >
        <WindowResizeListener onResize={this.handleResize} />
        {this.state.expandable && (
          <BodyPortal>
            <div
              style={{
                backgroundColor: "#fff",
                width: 80,
                height: 100,
                right: this.state.position === 0 ? "155px" : "-48px",
                top: "50vh",
                borderRadius: "100%",
                position: "fixed",
                transition: "all .3s ease-in",
                animation: "fade",
                padding: "28px 0px",
                zIndex: this.state.position === 0 ? 4 : 6,
              }}
            >
              <IconButton
                icon={this.state.position === 0 ? "arrow-right" : "arrow-left"}
                onClick={this.handleToggleBar}
              />
            </div>
          </BodyPortal>
        )}
        {this.state.expandable && this.state.position === 0 && (
          <BodyPortal>
            <div
              style={{
                backgroundColor: "rgba(0, 0, 0, .7)",
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 2,
                animation: "fade",
              }}
            />
          </BodyPortal>
        )}
        <div
          style={{
            position: "relative",
            right: `${this.state.position}px`,
            transition: "all .3s ease-in",
          }}
        >
          <ScrolledContent
            vertical
            scrollTo={
              900 -
              this.props.placementCourses.filter(
                (placementCourse) => placementCourse.level <= this.props.currentEnglishLevel
              ).length *
                55
            }
            style={{
              height: "100vh",
              animation: "fade",
            }}
          >
            <CoursesRulerList
              placementCourses={this.props.placementCourses}
              start={this.getRulerStartPosition()}
              end={this.state.levelEnd}
            />
          </ScrolledContent>
        </div>
      </div>
    );
  }
}
