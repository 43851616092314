import jwtDecode from "jwt-decode";
import { call, put, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities, replaceEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getCourses } from "student-front-commons/src/services/courseService";
import browserHistory from "../browserHistory";
import { LOAD_STUDENT_COURSE_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_STUDENT_COURSE_FLOW), function* () {
    try {
      if (!sessionStorage.getItem("accessToken")) {
        sessionStorage.clear();
        browserHistory.push("/login");
      }

      const id = sessionStorage.getItem("id");
      const decodedToken = jwtDecode(sessionStorage.getItem("accessToken"));
      sessionStorage.setItem("locale", decodedToken.locale);

      const profileResult = yield call(getProfile);
      yield put(mergeEntities(profileResult.entities));

      const courses = yield call(getCourses, {
        id,
      });
      yield put(replaceEntities(courses.entities));
    } catch (error) {
      logError({ error, flow: LOAD_STUDENT_COURSE_FLOW });
    } finally {
      yield put(endFlow(LOAD_STUDENT_COURSE_FLOW));
    }
  });
}
