import { useEntity, useEntityList } from "student-front-commons/src/hooks";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import TranslatedText from "../../core/TranslatedText";
import FlexColumn from "../../core/FlexColumn";
import Separator from "../../core/Separator";
import round from "lodash/round";
import { COMPANY_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";

const MasteryTestListItemLockedStats = ({ module, group }) => {
  const units = useEntityList("unit");

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile.company);

  const lockedStats = useMemo(() => {
    const moduleUnitsGroup = units.filter((u) => u.module === module && u.group === group && !u.isContentVideo);
    return {
      defaultUnfinished: moduleUnitsGroup.filter((u) => !u.defaultFinishedAt).length,
      reviewUnfinished: moduleUnitsGroup.filter((u) =>
        company.isOptionalReviewEnabled ? u.defaultFinishedAt && !u.firstReviewFinishedAt : !u.firstReviewFinishedAt
      ).length,
      total: company.isOptionalReviewEnabled ? moduleUnitsGroup.length : moduleUnitsGroup.length * 2,
    };
  }, [units, module, group]);

  const { translateKey, values } = useMemo(() => {
    let translateKey;
    let values;
    if (lockedStats.defaultUnfinished === 1 && !lockedStats.reviewUnfinished) {
      translateKey = "masteryTest.exercisesToUnlock.oneExercise";
    }
    if (lockedStats.defaultUnfinished > 1 && !lockedStats.reviewUnfinished) {
      translateKey = "masteryTest.exercisesToUnlock.moreThenOneExercise";
      values = { total: lockedStats.defaultUnfinished };
    }
    if (lockedStats.reviewUnfinished === 1 && !lockedStats.defaultUnfinished) {
      translateKey = "masteryTest.exercisesToUnlock.oneRevision";
    }
    if (lockedStats.reviewUnfinished > 1 && !lockedStats.defaultUnfinished) {
      translateKey = "masteryTest.exercisesToUnlock.moreThenOneRevision";
      values = { total: lockedStats.reviewUnfinished };
    }
    if (lockedStats.defaultUnfinished === 1 && lockedStats.reviewUnfinished === 1) {
      translateKey = "masteryTest.exercisesToUnlock.oneExerciseAndRevision";
    }
    if (lockedStats.defaultUnfinished === 1 && lockedStats.reviewUnfinished > 1) {
      translateKey = "masteryTest.exercisesToUnlock.oneExerciseAndMoreThenOneRevision";
      values = {
        total: lockedStats.reviewUnfinished,
      };
    }
    if (lockedStats.defaultUnfinished > 1 && lockedStats.reviewUnfinished === 1) {
      translateKey = "masteryTest.exercisesToUnlock.moreThenOneExerciseAndOneRevision";
      values = {
        total: lockedStats.defaultUnfinished,
      };
    }
    if (lockedStats.reviewUnfinished > 1 && lockedStats.defaultUnfinished === 1) {
      translateKey = "masteryTest.exercisesToUnlock.moreThenOneRevisionAndOneExercise";
      values = {
        total: lockedStats.reviewUnfinished,
      };
    }
    if (lockedStats.defaultUnfinished > 1 && lockedStats.reviewUnfinished > 1) {
      translateKey = "masteryTest.exercisesToUnlock.moreThenOneExerciseAndRevision";
      values = {
        totalExercises: lockedStats.defaultUnfinished,
        totalRevisions: lockedStats.reviewUnfinished,
      };
    }
    return { translateKey, values };
  }, [lockedStats.defaultUnfinished, lockedStats.total, lockedStats.reviewUnfinished]);

  return (
    <FlexColumn>
      <Separator />
      <div
        style={{
          position: "relative",
          height: 5,
          width: 175,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "#CCC",
          }}
        />
        <div
          style={{
            width: `${round(
              ((lockedStats.total - (lockedStats.defaultUnfinished + lockedStats.reviewUnfinished)) /
                lockedStats.total) *
                100,
              2
            )}%`,
            height: "100%",
            background: "#FF9F51",
            position: "absolute",
            top: 0,
            transition: "width 1s ease-in-out",
          }}
        />
      </div>
      <Separator size="xxs" />
      <span style={{ fontSize: 12, color: "#424242" }}>
        <TranslatedText as="label" translateKey={translateKey} values={values} />
      </span>
    </FlexColumn>
  );
};

MasteryTestListItemLockedStats.propTypes = {
  module: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
};

export default MasteryTestListItemLockedStats;
