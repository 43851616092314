import Styled from "styled-components";

const PracticeTestPanelDescription = Styled.div`
width: 100%;
color: ${(props) => props.color};
font-size: 16px;
text-align: center;
padding-left: 40px;
padding-right: 40px;
position: relative;
`;

export default PracticeTestPanelDescription;
