import ApiError from "../exceptions/apiError";
import GeneralError from "../exceptions/generalError";

const safeCall = (fn, errorCode, errorMessage) => {
  try {
    return fn();
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new GeneralError(errorCode, errorMessage, error);
  }
};

export default safeCall;
