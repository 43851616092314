import React from "react";
import PropTypes from "prop-types";

const MessageChannelCounter = (props) => (
  <div
    style={{
      backgroundColor: "#F44336",
      width: 16,
      height: 16,
      borderRadius: "100%",
      border: "1px solid",
      borderColor: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    className={props.value > 0 ? "animate__animated animate__heartBeat" : ""}
  >
    <span
      style={{
        color: "#fff",
        fontSize: 9,
        fontWeight: 700,
      }}
    >
      {props.value}
    </span>
  </div>
);

MessageChannelCounter.propTypes = {
  value: PropTypes.number.isRequired,
};

export default MessageChannelCounter;
