import trim from "lodash/trim";
import floor from "lodash/floor";
import isString from "lodash/isString";
import stringSimilarity from "string-similarity";
import { diffWords, diffArrays } from "diff/lib/index";

import validate from "../core/validate";

/**
 * Return the chunks of different text between the correct and the given
 *
 * @param {Object} payload - The object with all the params
 * @param {String} payload.answer - the student answer
 * @param {String} payload.originalText - the original text
 *
 * @returns [Object]
 */
const handleAnswerDiff = ({ answer, originalText }) => {
  const diffs = diffArrays(
    answer.split(/([\s.,!?;])/g).filter((p) => p.trim()),
    originalText.split(/([\s.,!?;])/g).filter((p) => p.trim())
  );

  return diffs.reduce((array, diff) => {
    return [
      ...array,
      ...diff.value.map((text) => ({
        text,
        removed: !!diff.removed,
        added: !!diff.added,
      })),
    ];
  }, []);
};

/**
 * check the answer based on item type
 *
 * @param {!Object} payload - The object with all the params
 * @param {!Object} payload.item - the item to check
 * @param {!string} payload.item.type.key - the item with the item type key
 * @param {!(string|number|boolean)} payload.answer - the user answer
 * @param {!number} payload.minimumSpeechRecognitionScore - the minimum score to consider correct
 *
 * @returns {{status: string, extraData: null}}
 */
export const checkAnswer = (payload) => {
  validate(
    {
      item: {
        presence: {
          allowEmpty: false,
        },
      },
      "item.type.key": {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  const result = {
    status: "WRONG",
    extraData: null,
    answer: null,
  };

  if (!payload.answer) {
    return result;
  }

  if (["DICTATION", "GRAMMAR_CHECK", "SPEECH_PRACTICE_SPEECHLESS"].some((type) => type === payload.item.type.key)) {
    const dictationResult = validateBySimilarity(trim(payload.answer), payload.item);
    result.extraData = {
      bestMatch: dictationResult.extraData.bestMatch,
      rating: floor((dictationResult.extraData.rating || 0) * 100, 0),
      originalText: dictationResult.extraData.originalText,
    };
    result.status = dictationResult.status;
    result.answer = payload.answer;

    if (["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].includes(payload.item.type.key)) {
      result.extraData.diffs = handleAnswerDiff({
        originalText: result.extraData.originalText,
        answer: result.answer,
      });
    }

    const answerWordCount = dictationResult.normalizedAnswer.split(" ").length;
    if (
      dictationResult.extraData.bestMatch &&
      answerWordCount > dictationResult.extraData.bestMatch.split(" ").length
    ) {
      result.extraData = {
        ...(result.extraData || {}),
        moreWords: answerWordCount - dictationResult.extraData.bestMatch.split(" ").length,
      };
    }
    if (
      dictationResult.extraData.bestMatch &&
      answerWordCount < dictationResult.extraData.bestMatch.split(" ").length
    ) {
      result.extraData = {
        ...(result.extraData || {}),
        missingWords: dictationResult.extraData.bestMatch.split(" ").length - answerWordCount,
      };
    }
    if (dictationResult.extraData.badWrittenWords && dictationResult.extraData.badWrittenWords.length > 0) {
      result.extraData = {
        ...(result.extraData || {}),
        typos: dictationResult.extraData.badWrittenWords,
      };
    }
    if (dictationResult.extraData.wrongWords && dictationResult.extraData.wrongWords.length > 0) {
      result.extraData = {
        ...(result.extraData || {}),
        wrongWords: dictationResult.extraData.wrongWords,
      };
    }
  } else if (
    [
      "SPEECH_PRACTICE",
      "PRESENTATION",
      "PRONUNCIATION",
      "VIDEO_SHORT",
      "VOCABULARY",
      "PHONEME",
      "VOCABULARY_ACADEMIC",
    ].find((type) => payload.item.type.key === type)
  ) {
    result.status = validateBySpeechRecognition(payload.answer, payload.minimumSpeechRecognitionScore)
      ? "CORRECT"
      : "WRONG";
    result.answer = payload.answer;
  } else if (payload.item.type.key === "MULTIPLE_CHOICE_TEXT") {
    const totalUserCorrectAnswers = payload.answer.filter((answer) => answer.correct).length;
    const totalItemCorrectAnswers = payload.item.answers.filter((answer) => answer.correct).length;

    if (totalUserCorrectAnswers !== totalItemCorrectAnswers) {
      result.status = "WRONG";
    } else {
      const answersResult = payload.answer.map((answer) => validateByBoolean(answer));
      result.status = answersResult.some((resultStatus) => resultStatus === false) ? "WRONG" : "CORRECT";
    }

    result.extraData = {
      selectedAnswers: payload.answer.map((answer) => ({
        id: answer.id,
        status: answer.correct ? "CORRECT" : "WRONG",
      })),
    };
    result.answer = payload.answer.reduce((acc, answer) => `${acc}${answer.text}\n`, "");
  } else if (payload.item.type.key === "UNSCRAMBLE_TEXT") {
    result.answer = payload.answer.map((paragraph) => paragraph.text).join("\n");
    result.status = validateByText(result.answer, payload.item.text) ? "CORRECT" : "WRONG";
    const splittedText = payload.item.text.split("\n");
    result.extraData = {
      paragraphsFeedback: splittedText.map((paragraph, index) => ({
        position: index,
        status: payload.answer[index].text === paragraph ? "CORRECT" : "WRONG",
      })),
    };
  } else if (payload.item.type.key === "MEANINGS_ASSOCIATING") {
    result.status = validateByText(payload.answer.text, payload.item.text) ? "CORRECT" : "WRONG";
    result.answer = payload.answer.postPhrase;
  } else if (isString(payload.answer)) {
    const lastWord = payload.item.text.split(" ")[payload.item.text.split(" ").length - 1];
    result.status = validateByText(
      payload.answer.concat(
        payload.item.type.key === "PRESENTATION_SPEECHLESS" && lastWord[lastWord.length - 1].includes(".") ? "." : ""
      ),
      payload.item.type.key === "VOCABULARY_ACADEMIC_SPEECHLESS" ? payload.item.postPhrase : payload.item.text
    )
      ? "CORRECT"
      : "WRONG";
    result.answer = payload.answer;
  } else {
    result.status = validateByBoolean(payload.answer) ? "CORRECT" : "WRONG";
    result.answer = payload.answer.text;
  }

  return result;
};

/**
 * normalize the given text to remove accents and punctuations
 *
 * @param {String} text - The text to be normalized
 *
 * @returns {String}
 */
const normalizeAnswer = (text) => {
  const normalizedText = text
    .replace(new RegExp(String.fromCharCode(8217), "g"), String.fromCharCode(39))
    .replace(/['!.?,]/g, "")
    .replace(/\s+/g, " ")
    .trim();
  return normalizedText;
};

/**
 * calculate the rating between the correct and student answer.
 *
 * @param {String} correctAnswer - The text to be compared
 * @param {String} studentAnswer - The student answer to compare
 * @returns {Number} - number between 0 and 1
 */
const calculateRating = (correctAnswer, studentAnswer) => {
  const correctWords = correctAnswer.split(" ");
  const answerDiff = diffWords(correctAnswer, studentAnswer);

  const answerDiffWords = answerDiff.reduce((acc, diff) => {
    return [
      ...acc,
      ...diff.value
        .trim()
        .split(" ")
        .map((word) => ({ word, added: diff.added, removed: diff.removed })),
    ];
  }, []);

  const validatedCorrectPositionedWords = answerDiffWords.reduce((acc, wordDiff, runningIndex) => {
    if (wordDiff.added || wordDiff.removed) {
      return acc;
    }
    if (
      wordDiff.word ===
      correctWords.at(acc.length + answerDiffWords.filter((wd, index) => index < runningIndex && wd.removed).length)
    ) {
      return [...acc, wordDiff.word];
    }

    return acc;
  }, []);

  return (
    validatedCorrectPositionedWords.length /
    (correctWords.length + answerDiffWords.filter((wordDiff) => wordDiff.added).length)
  );
};

const validateBySimilarity = (userAnswer, item) => {
  let status = "WRONG";
  const extraData = {};

  const normalizedAnswer = normalizeAnswer(userAnswer);

  const normalizedAndOriginalAnswers = item.answers.reduce(
    (acc, answer) => [
      ...acc,
      {
        original: answer.text,
        normalized: answer.text.replace(/['!.?,]/g, "").trim(),
      },
    ],
    item.type.key !== "GRAMMAR_CHECK"
      ? [
          {
            original: item.text,
            normalized: item.type.key !== "GRAMMAR_CHECK" ? item.text.replace(/['!.?,]/g, "").trim() : "",
          },
        ]
      : []
  );

  const comparingText = stringSimilarity.findBestMatch(
    normalizedAnswer,
    normalizedAndOriginalAnswers.map((answer) => answer.normalized)
  );

  comparingText.bestMatch =
    comparingText.ratings.find((item) => item.rating >= 1 && item.target.trim() === normalizedAnswer) ||
    comparingText.bestMatch;
  extraData.bestMatch = comparingText.bestMatch.target.trim();
  extraData.rating = calculateRating(extraData.bestMatch, normalizedAnswer);
  extraData.originalText = normalizedAndOriginalAnswers.find(
    (answer) => answer.normalized === extraData.bestMatch
  ).original;

  const correctDefinition =
    item.type.key !== "GRAMMAR_CHECK"
      ? extraData.rating >= 0.8
      : comparingText.bestMatch.rating >= 1 && comparingText.bestMatch.target.trim() === normalizedAnswer;

  if (correctDefinition) {
    status = "CORRECT";
  } else {
    const diffs = diffWords(comparingText.bestMatch.target, normalizedAnswer);
    extraData.badWrittenWords = diffs
      .filter((diffPart) => diffPart.added)
      .reduce(
        (acc, diffPart) => [
          ...acc,
          ...diffPart.value
            .trim()
            .split(" ")
            .filter(
              (text) => !!item.text.trim().match(new RegExp(`(${text.replace(/[|\\{}()[\]^$+*?.-]/g, "\\$&")})`, "i"))
            )
            .map((text) => text),
        ],
        []
      );
    extraData.wrongWords = diffs
      .filter((diffPart) => diffPart.added)
      .reduce(
        (acc, diffPart) => [
          ...acc,
          ...diffPart.value
            .trim()
            .split(" ")
            .filter(
              (text) => !item.text.trim().match(new RegExp(`(${text.replace(/[|\\{}()[\]^$+*?.-]/g, "\\$&")})`, "i"))
            )
            .map((text) => text),
        ],
        []
      );
  }

  return {
    normalizedAnswer,
    status,
    extraData,
  };
};

const validateByText = (userAnswer, text) => {
  const normalizedAnswer = userAnswer
    .trim()
    .replace(new RegExp(String.fromCharCode(8217), "g"), String.fromCharCode(39));
  const normalizedItemText = text.trim().replace(new RegExp(String.fromCharCode(8217), "g"), String.fromCharCode(39));
  return normalizedAnswer === normalizedItemText;
};

const validateBySpeechRecognition = (userAnswer, minimumScore) => userAnswer >= minimumScore;

const validateByBoolean = (userAnswer) => userAnswer && userAnswer.correct;
