import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Row from "../../core/Row";
import Column from "../../core/Column";
import CircularButton from "../../core/CircularButton";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import { WHITE } from "../../consts/color";

const CertificationTestSubHeader = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary, secondary }) => (
      <div
        style={{
          height: 80,
          overflow: "hidden",
          position: "relative",
          boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
          backgroundImage: `linear-gradient(to right, ${primary} 50%, #FFFFFF 51%)`,
        }}
      >
        <div className="container">
          <Row>
            <Column lgSize={12} style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: 80,
                  backgroundColor: primary,
                }}
              >
                <CircularButton
                  onClick={props.onBack}
                  color={primary}
                  icon="arrow-left"
                  border={`2px solid ${secondary}`}
                />
                <h1
                  style={{
                    margin: 0,
                    marginLeft: 30,
                    color: WHITE,
                    fontSize: 32,
                    fontWeight: 700,
                  }}
                >
                  Certification Test
                </h1>
              </div>
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    backgroundColor: secondary,
                    height: 147,
                    width: 147,
                    border: "solid 5px #ffffff",
                    boxShadow: "-4px 3px 6px -2px rgba(33, 46, 130, 0.2)",
                    position: "absolute",
                    top: "-35px",
                    right: 0,
                    borderRadius: "100%",
                    textAlign: "center",
                  }}
                >
                  <TranslatedText
                    as="label"
                    translateKey="scoreToPass"
                    style={{
                      fontSize: 13,
                      color: "rgba(255, 255, 255, .8)",
                    }}
                  />
                  <h1
                    style={{
                      color: WHITE,
                      fontSize: 28,
                      fontWeight: 700,
                      margin: 0,
                    }}
                  >
                    70
                  </h1>
                </div>
              </div>
            </Column>
          </Row>
        </div>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CertificationTestSubHeader.propTypes = {
  onBack: PropTypes.func.isRequired,
};

export default withRouter(CertificationTestSubHeader);
