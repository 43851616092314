import { Component } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";

export default class WindowResizeListener extends Component {
  static propTypes = {
    onResize: PropTypes.func.isRequired,
  };

  componentDidMount() {
    if (!this.listeners.length) {
      this.debouncedResize = debounce(this.onResize, this.DEBOUNCE_TIME);
      window.addEventListener("resize", this.debouncedResize, false);
    }
    this.listeners.push(this.props.onResize);
    this.debouncedResize();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.onResize !== this.props.onResize) {
      const idx = this.listeners.indexOf(this.props.onResize);
      this.listeners.splice(idx, 1, nextProps.onResize);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.onResize !== this.props.onResize;
  }

  componentWillUnmount() {
    const idx = this.listeners.indexOf(this.props.onResize);
    this.listeners.splice(idx, 1);
    if (!this.listeners.length) {
      window.removeEventListener("resize", this.debouncedResize, false);
    }
  }

  listeners = [];

  DEBOUNCE_TIME = 100;

  onResize = () => {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    this.listeners.forEach((listener) => {
      listener({
        windowWidth,
        windowHeight,
      });
    });
  };

  render() {
    return null;
  }
}
