import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularLoading from '../CircularLoading';
import DefaultImage from '../../images/no-image-single-choice.jpg';
import { getImage } from '../../stores/image-store';

export default class AnswerImage extends Component {

  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    answer: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    style: {},
  };

  state = { loading: true, reloadCount: 0 };

  handleLoad = () => {
    this.setState({
      loading: false,
    });
  };

  handleError = () => {
    if (this.state.reloadCount === 3) {
      // this.props.onError();
      return;
    }

    this.setState({
      reloadCount: this.state.reloadCount + 1,
    });
  };

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: 175,
        }}
      >
        {this.state.loading && (
          <div
            style={{
              margin: '60px auto',
            }}
          >
            <CircularLoading
              size="sm"
              rgb={{
                r: 175,
                g: 175,
                b: 175,
              }}
            />
          </div>
        )}
        <img
          alt={`for-${this.props.answer.id}`}
          src={this.state.reloadCount >= 3 ?
            DefaultImage :
            (
              getImage(`items/${this.props.item.id}/images/${this.props.answer.id}.png`) ||
              `${process.env.REACT_APP_FILES_URL}/items/${this.props.item.id}/images/${this.props.answer.id}.png?${this.state.reloadCount}`
            )
          }
          onLoad={this.handleLoad}
          onError={this.handleError}
          style={{
            ...this.props.style,
            transition: 'opacity .5s',
            opacity: this.state.loading ? 0 : 1,
            position: this.state.loading ? 'absolute' : 'relative',
          }}
        />
      </div>
    );
  }
}
