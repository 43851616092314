import React, { useMemo } from "react";
import FlexRow from "../FlexRow";
import Icon from "../Icon";
import FlexColumn from "../FlexColumn";
import Paragraph from "../Paragraph";
import TranslatedText from "../TranslatedText";
import PropTypes from "prop-types";
import Separator from "../Separator";
import UnitButton from "../../screens/unit-list/UnitButton";

function ContentVideoCard(props) {
  const status = useMemo(() => {
    if (props.allowBlockedUnit && props.unit.yourChallengeStatus === "BLOCKED") {
      return "AVAILABLE";
    }
    return props.unit.yourChallengeStatus;
  }, [props.unit.yourChallengeStatus, props.allowBlockedUnit]);

  return (
    <FlexColumn
      borderRadius={3}
      backgroundColor="#fff"
      boxShadow="0 1px 3px 0 rgba(85, 85, 85, 0.38)"
      position="relative"
      height="100%"
      justifyContent="space-between"
    >
      {status === "BLOCKED" && (
        <FlexRow
          top={0}
          left={0}
          width="100%"
          height="100%"
          position="absolute"
          backgroundColor="#ffffffcc"
          zIndex={5}
          justifyContent="center"
          alignItems="center"
          paddingBottom={130}
        >
          <FlexColumn
            width={64}
            height={64}
            borderRadius={64}
            backgroundColor="#ECECEC"
            justifyContent="center"
            alignItems="center"
          >
            <Icon color="#42424280" icon="lock-outline" size="sm" />
          </FlexColumn>
        </FlexRow>
      )}
      <div>
        <FlexRow position="relative">
          <FlexColumn position="relative" justifyContent="center" alignItems="center" width="100%">
            <img
              alt="video-thubmnail"
              style={{
                borderRadius: "4px",
                width: "100%",
                height: 240,
                objectFit: "cover",
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }}
              src={
                props.unit.thumbnail.indexOf("https") < 0
                  ? `${process.env.REACT_APP_FILES_URL}/${props.unit.thumbnail}`
                  : props.unit.thumbnail
              }
            />
            <FlexColumn position="absolute">
              <Icon icon="play-circle-outline" size="lg" color="#fff" opacity={0.8} />
            </FlexColumn>
          </FlexColumn>
          <FlexRow
            padding="0px 15px"
            borderRadius={55}
            alignItems="center"
            justifyContent="center"
            backgroundColor={
              {
                COMPLETED: "#00786A",
                AVAILABLE: "#FF9F51",
                BLOCKED: "#fff",
              }[status]
            }
            border="2px solid #fff"
            borderColor={
              {
                COMPLETED: "#00786A",
                AVAILABLE: "#FF9F51",
                BLOCKED: "#00000033",
              }[status]
            }
            position="absolute"
            textAlign="center"
            top={10}
            right={10}
          >
            <Icon
              color={
                {
                  COMPLETED: "#fff",
                  AVAILABLE: "#fff",
                  BLOCKED: "#00000033",
                }[status]
              }
              icon={
                {
                  COMPLETED: "check",
                  AVAILABLE: "lock-open-variant-outline",
                  BLOCKED: "lock-outline",
                }[status]
              }
              size="xs"
            />
            <Separator size="sm" />
            <label style={{ fontSize: 14, fontWeight: "bold", margin: 0, lineHeight: "14px", color: "#fff" }}>
              {props.unit.defaultPoints}
            </label>
            <TranslatedText
              as="label"
              translateKey="points"
              style={{
                color: "#fff",
                fontSize: 11,
                lineHeight: "11px",
                margin: 0,
                marginLeft: 3,
                textTransform: "lowercase",
              }}
            />
          </FlexRow>
        </FlexRow>
        <FlexColumn padding={16}>
          <span
            style={{
              color: "#2A2E34",
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {props.unit.name}
          </span>
          <Separator size="xxs" />
          {props.unit?.description && <Paragraph style={{ color: "#2A2E34" }}>{props.unit.description}</Paragraph>}
        </FlexColumn>
      </div>
      <FlexColumn padding="0 16px 16px 16px">
        <UnitButton
          onClick={props.onClick}
          isDisabled={status === "BLOCKED"}
          type={status === "COMPLETED" ? "WATCH_AGAIN" : "WATCH"}
        />
      </FlexColumn>
    </FlexColumn>
  );
}

ContentVideoCard.propTypes = {
  allowBlockedUnit: PropTypes.bool,
  unit: PropTypes.object.isRequired,
};

ContentVideoCard.defaultProps = {
  allowBlockedUnit: false,
};

export default ContentVideoCard;
