import React, { Component } from "react";
import Form from "student-front-commons/src/components/Form";
import PracticeTestScoreScene from "./PracticeTestScoreScene";
import { PRACTICE_TEST_EXECUTION_FORM } from "../../../consts";

class PracticeTestScoreSceneContainer extends Component {
  render() {
    return (
      <Form id={PRACTICE_TEST_EXECUTION_FORM} onSubmit={() => false} autoInit={false}>
        {({ values }) => (
          <PracticeTestScoreScene
            correctPercentage={
              (values.answers.filter((answer) => answer.correct).length / values.practiceTestItems.length) * 100
            }
          />
        )}
      </Form>
    );
  }
}

export default PracticeTestScoreSceneContainer;
