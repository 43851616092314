import React, { Component } from "react";
import TestContent from "../TestContent";
import Column from "../../../core/Column";
import Row from "../../../core/Row";
import TranslatedText from "../../../core/TranslatedText";
import PracticeTestItemExecutionFormContainer from "./PracticeTestItemExecutionFormContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../../consts/color";
import Separator from "../../../core/Separator";
import PracticeTestProgressBarContainer from "./PracticeTestProgressBarContainer";
import OldErrorModalContainer from "../../../error/OldErrorModalContainer";
import ErrorModalContainer from "../../../error/ErrorModalContainer";

class PracticeTestScene extends Component {
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div>
            <div
              style={{
                height: 80,
                boxShadow: "0 1px 8px rgba(0, 0, 0, 0.2)",
                backgroundColor: primaryColor,
                position: "relative",
              }}
            >
              <div className="container">
                <Row>
                  <Column lgSize={12} mdSize={12}>
                    <TranslatedText
                      as="h1"
                      translateKey="placementTest.warming"
                      style={{
                        color: WHITE,
                        fontSize: 22,
                        fontWeight: 700,
                        lineHeight: "24px",
                        margin: "28px 0 5px 0px",
                      }}
                    />
                  </Column>
                </Row>
              </div>
            </div>
            <TestContent>
              <PracticeTestProgressBarContainer />
              <Separator size="md" />
              <PracticeTestItemExecutionFormContainer />
            </TestContent>
            <OldErrorModalContainer />
            <ErrorModalContainer />
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default PracticeTestScene;
