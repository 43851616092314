import get from "lodash/get";
import { normalize } from "normalizr";
import orderBy from "lodash/orderBy";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

import moduleSchema from "../schemas/moduleSchema";

/**
 * list the student courses modules, units and mastery test
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getModulesByCourse = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      const modules = await request({
        url: `students/${payload.id}/courses/${payload.course}/details`,
        method: "get",
      });
      if (!modules) {
        return normalize([], [moduleSchema]);
      }

      const orderedModules = orderBy(
        modules.map((module) => ({
          ...module,
          units: orderBy(module.units, ["group", "order"]),
          masteryTests: orderBy(module.masteryTests, "group").map((mt) => {
            const units = module.units.filter((u) => u.group === mt.group);
            const defaultFinished = units.filter((u) => u.defaultFinishedAt).length;
            const firstReviewFinished = units.filter((u) => u.firstReviewFinishedAt).length;
            return {
              ...mt,
              percentageToUnlock: ((defaultFinished + firstReviewFinished) / (units.length * 2)) * 100,
            };
          }),
        })),
        ["group", "order"]
      );

      return normalize(orderedModules, [moduleSchema]);
    },
    "error_load_module",
    "Unexpected error to load modules."
  );
};

/**
 * list the student courses modules, units and mastery test
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 * @param {string} payload.module - the module id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setModuleAccess = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
      module: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      await request({
        url: `students/${payload.id}/courses/${payload.course}/modules/${payload.module}`,
        method: "patch",
      });
    },
    "error_set_module_access",
    "Unexpected error to set module access."
  );
};
