import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import FlexColumn from "../../core/FlexColumn";

const StepItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <FlexColumn alignItems="center" justifyContent="center" position="relative">
        <FlexColumn
          width={40}
          height={40}
          borderRadius="100%"
          alignItems="center"
          justifyContent="center"
          position="relative"
          backgroundColor={props.current && !props.completed ? secondaryColor : primaryColor}
        >
          <FlexColumn
            alignItems="center"
            justifyContent="center"
            width={30}
            height={30}
            borderRadius="100%"
            backgroundColor={props.completed ? "#fff" : primaryColor}
            border="2px solid #fff"
            borderColor={props.completed || props.current ? "#fff" : "#ffffff33"}
          >
            {props.completed && <Icon size="xs" icon="check" color={primaryColor} />}
          </FlexColumn>
        </FlexColumn>
        <MediaQuery maxWidth={540}>
          {(matches) => (
            <TranslatedText
              as="span"
              translateKey={props.label}
              style={{
                fontSize: 14,
                color: "#fff",
                bottom: "-100%",
                textAlign: "center",
                position: "absolute",
                width: matches ? undefined : "max-content",
                opacity: props.current ? 1 : 0.5,
              }}
            />
          )}
        </MediaQuery>
      </FlexColumn>
    )}
  </WhitelabelContext.Consumer>
);

StepItem.propTypes = {
  label: PropTypes.string,
  current: PropTypes.bool,
  completed: PropTypes.bool,
};

StepItem.defaultProps = {
  label: null,
  current: null,
  completed: null,
};

export default StepItem;
