import React from "react";
import PropTypes from "prop-types";

const GapFillIcon = ({ color, style }) => (
  <svg width="182" height="100" viewBox="0 0 182 100" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M42.8887 53.4209H16.3928C15.7367 53.4209 15.2321 53.9761 15.2321 54.5817C15.2321 55.1873 15.7872 55.7424 16.3928 55.7424H42.8887C43.5447 55.7424 44.0494 56.2976 44.0494 56.9032V94.7544C44.0494 95.4105 43.4943 95.9152 42.8887 95.9152H4.53278C3.87669 95.9152 3.37201 95.36 3.37201 94.7544V56.9032C3.37201 56.2471 3.92716 55.7424 4.53278 55.7424H6.90479C7.56087 55.7424 8.06556 55.1873 8.06556 54.5817C8.06556 53.9761 7.51041 53.4209 6.90479 53.4209H4.53278C2.56452 53.4209 1 55.0359 1 56.9537V94.8049C1 96.7731 2.61498 98.3377 4.53278 98.3377H42.8887C44.8569 98.3377 46.4214 96.7227 46.4214 94.8049V56.9537C46.4214 54.9854 44.8065 53.4209 42.8887 53.4209Z"
      fill={color}
      stroke={color}
    />
    <path d="M20.8491 65.7656H26.0272V84.7517H20.8491V65.7656Z" fill={color} />
    <path
      d="M177.467 53.6835C175.499 53.6835 125.081 53.6835 125.081 53.6835C124.425 53.6835 123.92 54.2386 123.92 54.8442C123.92 55.4499 124.475 56.005 125.081 56.005C125.081 56.005 176.811 56.005 177.467 56.005C178.123 56.005 178.628 56.5602 178.628 57.1658V95.017C178.628 95.6731 178.073 96.1778 177.467 96.1778H113.272C112.615 96.1778 112.111 95.6226 112.111 95.017V57.2163C112.111 56.5602 112.666 56.0555 113.272 56.0555H115.644C116.3 56.0555 116.804 55.5003 116.804 54.8947C116.804 54.2891 116.249 53.7339 115.644 53.7339H113.272C111.303 53.7339 109.739 55.3489 109.739 57.2667V95.1179C109.739 97.0862 111.354 98.6507 113.272 98.6507H177.467C179.435 98.6507 181 97.0357 181 95.1179V57.2163C181 55.2985 179.435 53.6835 177.467 53.6835Z"
      fill={color}
      stroke={color}
    />
    <path
      d="M129.289 78.0086V84.4088H124.946V78.1229L119.232 67.8369H124.032L127.118 73.6656L130.204 67.8369H135.004L129.289 78.0086Z"
      fill={color}
    />
    <path
      d="M144.834 67.494C149.634 67.3797 153.863 71.3799 153.863 76.0657C153.977 80.8659 149.748 84.7517 144.834 84.6374C140.034 84.7517 135.805 80.8659 135.919 76.0657C135.805 71.2656 139.919 67.3797 144.834 67.494ZM144.834 71.3799C142.205 71.3799 140.262 73.3228 140.262 75.9514C140.262 77.3229 140.719 78.4658 141.519 79.2658C142.32 80.0659 143.462 80.523 144.834 80.523C147.348 80.523 149.405 78.6944 149.405 75.9514C149.405 73.3228 147.348 71.3799 144.834 71.3799Z"
      fill={color}
    />
    <path
      d="M156.947 67.7226H161.29V77.6658C161.29 79.6087 162.547 80.7516 164.262 80.7516C165.976 80.7516 167.233 79.6087 167.233 77.6658V67.8369H171.576V77.4372C171.576 81.7802 168.376 84.7517 164.262 84.7517C160.033 84.7517 156.947 81.7802 156.947 77.4372V67.7226Z"
      fill={color}
    />
    <path
      opacity="0.5"
      d="M101.836 53.3705H67.3658C66.7097 53.3705 66.205 53.9256 66.205 54.5313C66.205 55.1369 66.7602 55.692 67.3658 55.692H101.836C101.836 55.692 102.996 56.2472 102.996 56.8528V94.704C102.996 95.3601 102.441 95.8648 101.836 95.8648H55.5562C54.9001 95.8648 54.3954 95.3096 54.3954 94.704V56.9033C54.3954 56.2472 54.9506 55.7425 55.5562 55.7425H57.9282C58.5843 55.7425 59.089 55.1873 59.089 54.5817C59.089 53.9761 58.5338 53.421 57.9282 53.421H55.5562C53.588 53.421 52.0234 55.0359 52.0234 56.9537V94.8049C52.0234 96.7732 53.6384 98.3377 55.5562 98.3377H101.836C103.804 98.3377 105.368 96.7227 105.368 94.8049V56.9033C105.368 54.9855 103.804 53.3705 101.836 53.3705Z"
      fill={color}
      stroke={color}
    />
    <path d="M76.5 42.5V34.5H88.5L76.5 42.5Z" fill="white" />
    <rect x="67" y="1" width="75" height="34" rx="5" fill="white" />
    <path
      d="M76.9114 44C76.6986 44 76.4764 43.9535 76.2821 43.8512C75.7916 43.6093 75.4677 43.107 75.4677 42.5581L75.4121 36.5581H72.756C69.0263 36.5581 66 33.507 66 29.7674V6.7907C66 3.05116 69.0356 0 72.756 0H136.244C139.974 0 143 3.05116 143 6.7907V29.7674C143 33.5163 139.964 36.5581 136.244 36.5581H86.9992L77.7999 43.693C77.5407 43.8977 77.2261 44 76.9114 44ZM72.756 2.91163C70.6274 2.91163 68.8968 4.65116 68.8968 6.7907V29.7674C68.8968 31.907 70.6274 33.6465 72.756 33.6465H76.8466C77.6425 33.6465 78.2904 34.2884 78.2996 35.0884L78.3367 39.6L85.6202 33.9535C85.8701 33.7581 86.1847 33.6465 86.5087 33.6465H136.244C138.373 33.6465 140.103 31.907 140.103 29.7674V6.7907C140.103 4.65116 138.373 2.91163 136.244 2.91163H72.756Z"
      fill={color}
    />
    <circle cx="90" cy="18" r="4" fill={color} />
    <circle cx="105" cy="18" r="4" fill={color} />
    <circle cx="120" cy="18" r="4" fill={color} />
  </svg>
);

GapFillIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default GapFillIcon;
