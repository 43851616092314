import { useRef, useState, useCallback, useEffect } from "react";
import { get, set } from "lodash";

function useForm({ initialValues, validations, onSubmit, onReset }) {
  const submittedRef = useRef(false);
  const validationsRef = useRef({});
  const initialValuesRef = useRef({});

  const [values, setValues] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (validations) {
      validationsRef.current = validations;
    }
    if (initialValues) {
      initialValuesRef.current = initialValues;
      //
      // setValues((currentValues: any) => {
      //   const newValues = {
      //     ...currentValues,
      //     ...Object.keys(initialValues).reduce(
      //       (result, key) => ({
      //         ...result,
      //         [key]: initialValues[key],
      //       }),
      //       {},
      //     ),
      //   };
      //   return newValues;
      // });
    }
  }, []);

  const getInitialValues = () => initialValuesRef.current;

  const isSubmitted = () => submitted;

  const getValues = () => values;

  const getValue = (path, defaultValue = "") => {
    return get(values, path, defaultValue);
  };

  const getErrors = (paths) => {
    if (!validationsRef.current) {
      return null;
    }
    const errors = Object.keys(validationsRef.current)
      .filter((filterPath) => !paths || paths.some((p) => p === filterPath))
      .reduce((result, path) => {
        const error = getError(path);
        return error ? { ...result, [path]: error } : result;
      }, {});
    return Object.keys(errors).length > 0 ? errors : null;
  };

  const getError = (path) => {
    if (!validationsRef.current) {
      return null;
    }
    if (!validationsRef.current[path]) {
      return null;
    }
    if (!submittedRef.current) {
      return null;
    }
    return (
      validationsRef.current[path].map((validation) => validation(getValue(path), values)).find((error) => error) ||
      null
    );
  };

  const setValue = (path, value) => {
    setValues({ ...set(values, path, value) });
    setIsDirty(true);
  };

  const isValid = (path) => {
    return !!getValue(path) && !getError(path);
  };

  const submit = () => {
    submittedRef.current = true;
    setSubmitted(true);
    if (getErrors()) {
      return;
    }
    onSubmit({ ...values });
  };

  const reset = () => {
    submittedRef.current = false;

    setValues(initialValuesRef.current);
    setSubmitted(false);
    setIsDirty(false);

    if (onReset) {
      onReset();
    }
  };

  // const setValidation = (propName, validations: any[]) => {
  //   if (!validationsRef.current) {
  //     return;
  //   }
  //   validationsRef.current[propName] = validations;
  // }

  return {
    isSubmitted,
    getInitialValues,
    getValues,
    getValue,
    getErrors,
    getError,
    setValue,
    setValues,
    submit,
    reset,
    isValid,
    isDirty,
  };
}
export default useForm;
