import React from "react";
import { withRouter } from "react-router-dom";
import Separator from "../../core/Separator";
import CourseThemeContext from "../../core/CourseThemeContext";
import TranslatedText from "../../core/TranslatedText";
import AbilityListContainer from "./AbilityListContainer";
import CourseListContainer from "./CourseListContainer";
import CertificationTestSubHeader from "./CertificationTestSubHeader";
import CertificationTestFinishedPanelContainer from "./CertificationTestFinishedPanelContainer";

const CertificationTestAbilitiesScene = (props) => (
  <div>
    <CertificationTestSubHeader onBack={() => props.history.push("/")} />
    <div className="container">
      <Separator size="lg" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <CourseThemeContext.Consumer>
          {({ primary }) => (
            <div>
              <h1
                style={{
                  margin: 0,
                  fontSize: 32,
                  fontWeight: "bold",
                  color: primary,
                }}
              >
                Certification Test
              </h1>
              <Separator size="md" />
              <TranslatedText
                as="p"
                translateKey="certificationTest.execution.title"
                style={{
                  margin: 0,
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 0.5)",
                }}
              />
            </div>
          )}
        </CourseThemeContext.Consumer>
        <CourseListContainer />
      </div>
      <Separator size="xl" />
      <AbilityListContainer />
      <Separator size="xl" />
      <CertificationTestFinishedPanelContainer />
    </div>
  </div>
);

export default withRouter(CertificationTestAbilitiesScene);
