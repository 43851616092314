export const INCREMENT_SPEECH_RECOGNITION_SEQUENCE = "INCREMENT_SPEECH_RECOGNITION_SEQUENCE";
export const RESET_SPEECH_RECOGNITION_SEQUENCE = "RESET_SPEECH_RECOGNITION_SEQUENCE";
export const CHECK_SPEECH_RECOGNITION_TIPS = "CHECK_SPEECH_RECOGNITION_TIPS";
export const INCREMENT_CHECKPOINT_SEQUENCE = "INCREMENT_CHECKPOINT_SEQUENCE";
export const RESET_CHECKPOINT_SEQUENCE = "RESET_CHECKPOINT_SEQUENCE";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

/**
 * increment the SR sequence count, because a good score
 *
 * @returns {{ type: string }}
 */
export const incrementSpeechRecognitionSequence = () => ({
  type: INCREMENT_SPEECH_RECOGNITION_SEQUENCE,
});

/**
 * reset the SR sequence count, because a bad score
 *
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const resetSpeechRecognitionSequence = ({ wasRepeatUsed, wasListenUsed }) => ({
  type: RESET_SPEECH_RECOGNITION_SEQUENCE,
  payload: {
    wasRepeatUsed,
    wasListenUsed,
  },
});

/**
 * reset the SR sequence count, because a bad score
 *
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const checkSpeechRecognitionTips = ({ progress }) => ({
  type: CHECK_SPEECH_RECOGNITION_TIPS,
  payload: {
    progress,
  },
});

/**
 * increment the checkpoint sequence count, because a correct answer
 *
 * @returns {{ type: string }}
 */
export const incrementCheckpointSequence = () => ({
  type: INCREMENT_CHECKPOINT_SEQUENCE,
});

/**
 * reset the checkpoint sequence count, because a  wrong answer
 *
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const resetCheckpointSequence = () => ({
  type: RESET_CHECKPOINT_SEQUENCE,
});

/**
 * remove the notification, because it is already showed
 *
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const removeNotification = (notification) => ({
  type: REMOVE_NOTIFICATION,
  payload: {
    notification,
  },
});
