import React from "react";
import PropTypes from "prop-types";

const HeaderMenuItem = (props) => (
  <div
    style={{
      width: 50,
      maxHeight: 50,
      display: "inline-block",
    }}
  >
    {props.children}
  </div>
);

HeaderMenuItem.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HeaderMenuItem;
