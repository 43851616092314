import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Table, Tag } from "antd";
import "antd/lib/table/style/index.css";
import "antd/lib/tag/style/index.css";
import LinearProgress from "../../core/LinearProgress";
import withTranslation from "../../core/withTranslation";
import round from "lodash/round";
import Select from "../../core/form/Select";

const ContentHistoryList = (props) => (
  <Table
    indentSize={10}
    columns={[
      {
        title:
          props.courses.length > 1 ? (
            <Select
              label="Course"
              value={props.currentCourse}
              options={props.courses.map((course) => ({ value: course.id, label: course.name }))}
              onChange={props.onCourseChange}
              style={{ width: 100 }}
            />
          ) : (
            ""
          ),
        dataIndex: "id",
        width: 350,
        render: (value, row) => {
          if (row.docType === "MODULE" || row.docType === "UNIT") {
            return row.name;
          } else if (row.docType === "MASTERY") {
            return `Mastery Test for ${row.modulePercentageToActive}%`;
          }
          return moment(row.startedAt).format("DD/MM/YYYY HH:mm");
        },
      },
      {
        title: props.getTranslation("history.studiedTime"),
        dataIndex: "studiedTime",
        className: "text-center",
        width: 100,
        render: (value, row) =>
          row.docType === "MODULE"
            ? {
                children: moment
                  .duration(
                    row.children.reduce((acc, children) => acc + (children.studiedTime || 0), 0),
                    "seconds"
                  )
                  .format("hh:mm:ss", { trim: false }),
              }
            : {
                children: moment.duration(value || 0, "seconds").format("hh:mm:ss", { trim: false }),
              },
      },
      {
        title: props.getTranslation("history.score"),
        dataIndex: "score",
        className: "text-center",
        render: (value, row) => {
          let children = "";
          switch (row.docType) {
            case "MODULE":
              children = props.isSpeechRecognitionDisabled ? (
                <LinearProgress value={round(row.percentageComplete) || 0} />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    textAlign: "left",
                  }}
                >
                  <div style={{ flexBasis: 200 }}>
                    <span>Reading</span>
                    <LinearProgress value={row.readingProgress || 0} />
                  </div>
                  <div style={{ flexBasis: 200 }}>
                    <span>Speaking</span>
                    <LinearProgress value={row.speakingProgress || 0} />
                  </div>
                  <div style={{ flexBasis: 200 }}>
                    <span>Listening</span>
                    <LinearProgress value={row.listeningProgress || 0} />
                  </div>
                  <div style={{ flexBasis: 200 }}>
                    <span>Writing</span>
                    <LinearProgress value={row.writingProgress || 0} />
                  </div>
                </div>
              );
              break;
            case "UNIT":
              children = (
                <div>
                  {props.getTranslation("scoreToPass")}: {row.scoreToPass}
                </div>
              );
              break;
            case "MASTERY":
              children = (
                <div>
                  {props.getTranslation("scoreToPass")}: {row.scoreToPass}
                </div>
              );
              break;
            default:
              children = ["DEFAULT", "FIRST_REVIEW", "SECOND_REVIEW", "SIMPLE_REVIEW"].find(
                (type) => type === row.type
              ) ? (
                <Tag color={row.completedAt ? (row.points ? "green" : "red") : null}>
                  {value || props.getTranslation("history.notFinished")}
                </Tag>
              ) : (
                <Tag color={row.score ? (row.score >= row.scoreToPass ? "green" : "red") : null}>
                  {value ? `${value}/${row.scoreToPass}` : props.getTranslation("history.notFinished")}
                </Tag>
              );
              break;
          }
          return {
            children,
            props: {
              colSpan: row.children && 4,
            },
          };
        },
      },
      {
        title: props.getTranslation("history.type"),
        dataIndex: "type",
        className: "text-center",
        render: (value, row) => {
          let translatedValue = "";
          switch (value) {
            case "DEFAULT":
              translatedValue = "Your Challenge";
              break;
            case "FIRST_REVIEW":
              translatedValue = moment(row.startedAt).year() <= 2018 ? "First Review" : "Review";
              break;
            case "SECOND_REVIEW":
              translatedValue = moment(row.startedAt).year() <= 2018 ? "Second Review" : "-";
              break;
            case "SIMPLE_REVIEW":
              translatedValue = moment(row.startedAt).year() <= 2018 ? "Simple Review" : "Recap";
              break;
            case "OPTIONAL_REVIEW":
              translatedValue = "Optional Review";
              break;
            default:
              break;
          }
          return {
            children: translatedValue,
            props: {
              colSpan: row.children && 0,
            },
          };
        },
      },
      {
        title: props.getTranslation("history.points"),
        dataIndex: "rankingPoints",
        className: "text-center",
        render: (value, row) => ({
          children: value,
          props: {
            colSpan: row.children && 0,
          },
        }),
      },
      {
        title: props.getTranslation("history.averageSR"),
        dataIndex: "averageSpeechRecognitionScore",
        className: "text-center",
        render: (value, row) => ({
          children: value ? value.toFixed(0) : "",
          props: {
            colSpan: row.children && 0,
          },
        }),
      },
    ].filter(
      (column) =>
        !props.isSpeechRecognitionDisabled ||
        (props.isSpeechRecognitionDisabled && column.dataIndex !== "averageSpeechRecognitionScore")
    )}
    dataSource={props.data}
    pagination={false}
    rowKey="id"
    locale={{
      emptyText: props.getTranslation("history.noContentData"),
    }}
  />
);

ContentHistoryList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSpeechRecognitionDisabled: PropTypes.bool,
  courses: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  onCourseChange: PropTypes.func.isRequired,
  currentCourse: PropTypes.string.isRequired,
};

export default withTranslation(ContentHistoryList);
