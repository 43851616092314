import React from "react";
import Separator from "../../core/Separator";
import SubHeaderDetailsContainer from "../../core/SubHeaderDetailsContainer";
import BreadcrumbContainer from "../../core/BreadcrumbContainer";
import ModuleDetailsContainer from "./ModuleDetailsContainer";
import CertificationTestStatusBarContainer from "../../core/CertificationTestStatusBarContainer";
import UnitSectionListContainer from "./UnitSectionListContainer";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import ColumnSeparator from "../../core/ColumnSeparator";
import ErrorModalContainer from "../../error/ErrorModalContainer";
import AnnouncementsList from "../../core/AnnoucementsList";
import { useService } from "student-front-commons/src/hooks";
import { setModuleAccess } from "student-front-commons/src/services/moduleService";
import { withRouter } from "react-router-dom";

function UnitListScene(props) {
  useService(setModuleAccess, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      course: props.match.params.idCourse,
      module: props.match.params.idModule,
    },
  });

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <SubHeaderDetailsContainer />
      <BreadcrumbContainer />
      <div className="container">
        <FlexRow>
          <FlexColumn flex={1}>
            <Separator size="sm" />
            <AnnouncementsList />
            <CertificationTestStatusBarContainer />
            <UnitSectionListContainer />
          </FlexColumn>
          <ColumnSeparator size="lg" />
          <FlexColumn width={380}>
            <ModuleDetailsContainer />
          </FlexColumn>
        </FlexRow>
      </div>
      <ErrorModalContainer />
    </div>
  );
}

export default withRouter(UnitListScene);
