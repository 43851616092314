import React from "react";
import moment from "moment";
import Row from "../../core/Row";
import Panel from "../../core/Panel";
import Column from "../../core/Column";
import Title from "../../core/Title";
import HorizontalDivider from "../../core/HorizontalDivider";
import Separator from "../../core/Separator";
import NationalRankingListContainer from "../../core/ranking/NationalRankingListContainer";
import RegionalRankingListContainer from "../../core/ranking/RegionalRankingListContainer";
import PositionHistoryContainer from "./PositionHistoryContainer";
import CurrentSchoolPositionContainer from "../../core/CurrentSchoolPositionContainer";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";
import CheckDistributorType from "../../core/CheckDistributorType";

const RankingPanel = (props) => (
  <div className="stepRanking">
    <Panel title={props.getTranslation("ranking.title", { month: moment().format("MMMM") })}>
      <Row>
        <Column lgSize={2} mdSize={12} smSize={12} xsSize={12}>
          <Row>
            <CheckDistributorType>
              {({ isB2C }) =>
                !isB2C && (
                  <React.Fragment>
                    <Column lgSize={12} mdSize={3} smSize={12} xsSize={12}>
                      <CurrentSchoolPositionContainer color="#607d8b" />
                      <div className="d-sm-none d-md-none d-lg-inline">
                        <Separator size="sm" />
                        <HorizontalDivider />
                        <Separator size="xs" />
                        <Separator size="sm" />
                      </div>
                    </Column>
                  </React.Fragment>
                )
              }
            </CheckDistributorType>
            <Column lgSize={12} mdSize={9} smSize={12} xsSize={12}>
              <PositionHistoryContainer />
            </Column>
          </Row>
        </Column>
        <div className="d-md-flex d-lg-none">
          <Column>
            <Separator size="sm" />
            <HorizontalDivider />
            <Separator size="sm" />
          </Column>
        </div>
        <Column lgSize={5} mdSize={6} smSize={12}>
          <TranslatedText translateKey="ranking.regionalPosition" as={Title} />
          <Separator size="sm" />
          <RegionalRankingListContainer />
        </Column>
        <Column lgSize={5} mdSize={6} smSize={12}>
          <TranslatedText translateKey="ranking.nationalPosition" as={Title} />
          <Separator size="sm" />
          <NationalRankingListContainer />
        </Column>
      </Row>
    </Panel>
  </div>
);

export default withTranslation(RankingPanel);
