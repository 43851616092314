import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import withTranslation from "../../core/withTranslation";
import AsyncPanel from "../../core/AsyncPanel";
import StudyQualityChartTab from "./StudyQualityChartTab";
import TextLink from "../../core/TextLink";
import CurrentStudyQuality from "./CurrentStudyQuality";

const StudyQualityPanel = (props) => (
  <AsyncPanel
    fetching={props.fetching}
    hasData={!props.fetching && !!props.currentScore && props.currentScore.student !== null}
    noDataText={
      props.hasEvaluationTemplate
        ? props.isInEvaluationPeriod
          ? props.getTranslation("studyQuality.noData")
          : props.getTranslation("studyQuality.noEvaluationPeriod")
        : props.getTranslation("studyQuality.noData")
    }
    title={props.getTranslation("studyQuality.title")}
    padding={!props.fetching && !!props.currentScore && !!props.currentScore.student ? "none" : "lg"}
    headerOptions={<TextLink onClick={props.onHelpClick} label={props.getTranslation("studyQuality.howToImprove")} />}
  >
    <MediaQuery minWidth={992}>
      {(matches) => (
        <div className="stepStudyQuality">
          {matches && <CurrentStudyQuality current={props.currentScore} profile={props.studentProfile} />}
          <div
            style={{
              display: "inline-block",
              width: matches ? "75%" : "100%",
              height: "100%",
              verticalAlign: "top",
            }}
          >
            <StudyQualityChartTab title="your history" />
          </div>
        </div>
      )}
    </MediaQuery>
  </AsyncPanel>
);

StudyQualityPanel.propTypes = {
  fetching: PropTypes.bool.isRequired,
  currentScore: PropTypes.shape({
    student: PropTypes.number,
    group: PropTypes.number,
  }),
  studentProfile: PropTypes.shape({}).isRequired,
  isInEvaluationPeriod: PropTypes.bool.isRequired,
  hasEvaluationTemplate: PropTypes.bool.isRequired,
  onHelpClick: PropTypes.func.isRequired,
};

StudyQualityPanel.defaultProps = {
  currentScore: {},
};

export default withTranslation(StudyQualityPanel);
