import React from "react";
import { connect } from "react-redux";
import CertificationTestResultHeader from "./CertificationTestResultHeader";

const CertificationTestResultHeaderContainer = (props) => (
  <CertificationTestResultHeader certificationTest={props.certificationTest} />
);

const mapStateToProps = (state) => ({
  certificationTest: state.certificationTestResult,
});

export default connect(mapStateToProps)(CertificationTestResultHeaderContainer);
