import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import React from "react";
import PropTypes from "prop-types";

const StarIcon = () => {
  return (
    <FlexColumn
      width={32}
      height={32}
      borderRadius="50%"
      backgroundColor="#0FB1A21A"
      alignItems="center"
      justifyContent="center"
    >
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.07928 16C3.84606 15.999 3.61583 15.9475 3.40445 15.849C3.19307 15.7505 3.00558 15.6073 2.85489 15.4293C2.70421 15.2513 2.59392 15.0427 2.53163 14.818C2.46934 14.5932 2.45653 14.3576 2.49409 14.1275L3.11331 10.3121L0.455843 7.56788C0.249771 7.35573 0.106016 7.09099 0.0403039 6.80262C-0.0254079 6.51425 -0.0105451 6.21337 0.0832665 5.93288C0.177078 5.65239 0.346225 5.40311 0.572202 5.21229C0.798179 5.02148 1.07228 4.8965 1.36452 4.85101L4.9637 4.2986L6.54965 0.910539C6.68251 0.637532 6.88946 0.407387 7.14688 0.246382C7.4043 0.0853767 7.7018 0 8.00542 0C8.30904 0 8.60654 0.0853767 8.86396 0.246382C9.12137 0.407387 9.32832 0.637532 9.46119 0.910539L11.0419 4.29375L14.6462 4.85101C14.9385 4.8965 15.2126 5.02148 15.4386 5.21229C15.6645 5.40311 15.8337 5.65239 15.9275 5.93288C16.0213 6.21337 16.0362 6.51425 15.9705 6.80262C15.9047 7.09099 15.761 7.35573 15.5549 7.56788L12.9002 10.3012L13.5171 14.128C13.5661 14.4262 13.5302 14.7321 13.4134 15.0108C13.2967 15.2895 13.1037 15.5296 12.8568 15.7037C12.6098 15.8778 12.3188 15.9788 12.0171 15.9952C11.7153 16.0115 11.4151 15.9425 11.1508 15.7961L8.00746 14.0508L4.86018 15.7961C4.6215 15.9295 4.3527 15.9997 4.07928 16V16ZM8.00526 12.4479C8.27787 12.4481 8.5459 12.5179 8.78396 12.6508L11.9277 14.3966L11.3163 10.5687C11.2767 10.3203 11.2954 10.0661 11.3708 9.82619C11.4462 9.58623 11.5764 9.36709 11.751 9.18606L14.4061 6.45221L10.8038 5.8811C10.5422 5.841 10.2946 5.73691 10.083 5.57807C9.87144 5.41922 9.70236 5.21056 9.59085 4.97062L8.01074 1.58841H8.01034C8.00937 1.58558 8.00792 1.58294 8.00606 1.5806L6.41891 4.97142C6.30752 5.21115 6.13861 5.41964 5.92721 5.57834C5.7158 5.73705 5.46846 5.84105 5.20716 5.8811L1.60759 6.43352L4.25917 9.18558C4.43388 9.36657 4.56413 9.58569 4.63964 9.82565C4.71515 10.0656 4.73385 10.3198 4.69426 10.5683L4.07467 14.3844L7.22623 12.6511C7.46435 12.5181 7.73251 12.4481 8.00526 12.4479V12.4479Z"
          fill="#0FB1A2"
        />
      </svg>
    </FlexColumn>
  );
};

const WarningIcon = () => {
  return (
    <FlexColumn
      width={32}
      height={32}
      borderRadius="50%"
      backgroundColor="#DE5A671A"
      alignItems="center"
      justifyContent="center"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.0034663 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9975 5.87904 15.1538 3.84566 13.6541 2.34591C12.1543 0.84616 10.121 0.00250216 8 0ZM8 14.5455C6.70543 14.5455 5.43994 14.1616 4.36354 13.4423C3.28715 12.7231 2.4482 11.7009 1.95279 10.5048C1.45738 9.30881 1.32776 7.99274 1.58032 6.72304C1.83288 5.45335 2.45627 4.28706 3.37167 3.37166C4.28707 2.45627 5.45335 1.83287 6.72305 1.58031C7.99274 1.32776 9.30881 1.45738 10.5048 1.95279C11.7009 2.4482 12.7231 3.28715 13.4423 4.36354C14.1616 5.43993 14.5455 6.70543 14.5455 8C14.5435 9.73537 13.8533 11.3991 12.6262 12.6262C11.3991 13.8533 9.73537 14.5435 8 14.5455Z"
          fill="#DE5A67"
        />
        <path
          d="M8.00001 2.90909C7.80712 2.90909 7.62214 2.98571 7.48575 3.1221C7.34936 3.25849 7.27274 3.44348 7.27274 3.63636V9.45454C7.27274 9.64743 7.34936 9.83241 7.48575 9.9688C7.62214 10.1052 7.80712 10.1818 8.00001 10.1818C8.19289 10.1818 8.37788 10.1052 8.51427 9.9688C8.65066 9.83241 8.72728 9.64743 8.72728 9.45454V3.63636C8.72728 3.44348 8.65066 3.25849 8.51427 3.1221C8.37788 2.98571 8.19289 2.90909 8.00001 2.90909Z"
          fill="#DE5A67"
        />
        <path
          d="M8.00001 12.3636C8.40167 12.3636 8.72728 12.038 8.72728 11.6364C8.72728 11.2347 8.40167 10.9091 8.00001 10.9091C7.59835 10.9091 7.27274 11.2347 7.27274 11.6364C7.27274 12.038 7.59835 12.3636 8.00001 12.3636Z"
          fill="#DE5A67"
        />
      </svg>
    </FlexColumn>
  );
};

const MasteryTestResultPerformanceItem = ({ grammar, variant }) => {
  return (
    <FlexRow
      padding="15px 10px"
      borderLeft="3px solid"
      borderColor={variant === "good" ? "#0FB1A2" : "#DE5A67"}
      marginBottom={15}
      alignItems="center"
      gap={10}
    >
      <FlexColumn>
        {variant === "good" && <StarIcon />}
        {variant === "bad" && <WarningIcon />}
      </FlexColumn>
      <FlexColumn>{grammar.description}</FlexColumn>
    </FlexRow>
  );
};

MasteryTestResultPerformanceItem.propTypes = {
  functionOfLanguage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
  variant: PropTypes.oneOf(["good", "bad"]),
};

export default MasteryTestResultPerformanceItem;
