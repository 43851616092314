import React from "react";
import PropTypes from "prop-types";
import { pick } from "lodash";
import { parse as parseDomain } from "tldts";
import WhitelabelContext from "./WhitelabelContext";

class WhitelabelAppProvider extends React.Component {
  static propTypes = {
    whitelabelConfigs: PropTypes.object,
    host: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    defaultLogo: PropTypes.string.isRequired,
  };

  static defaultProps = {
    whitelabelConfigs: undefined,
  };

  state = {
    isLoading: true,
    context: {
      favIconUrl: null,
      primaryColor: "#455a64",
      secondaryColor: "#607d8b",
      lightColor: "#cfd8dc",
      title: "",
      domain: "",
      appDistributions: [],
    },
  };

  handleWhitelabelChanges = () => {
    window.document.title = this.state.context.title || "";

    if (this.state.context.favIconUrl) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href =
        this.state.context.favIconUrl === "favicon.ico"
          ? this.state.context.favIconUrl
          : `${process.env.REACT_APP_FILES_URL}/${this.state.context.favIconUrl}`;
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    setTimeout(() => {
      this.setState({
        isLoading: false,
      });
    }, 500);
  };

  handleSuccess = (whitelabelConfig) => {
    this.setState(
      {
        context: {
          logoUrl:
            whitelabelConfig.domain === "flexge.com"
              ? this.props.defaultLogo
              : `${process.env.REACT_APP_FILES_URL}/${whitelabelConfig.logoUrl}`,
          kidsLogoUrl:
            whitelabelConfig.domain === "flexge.com"
              ? this.props.defaultLogo
              : `${process.env.REACT_APP_FILES_URL}/${whitelabelConfig.kidsLogoUrl || whitelabelConfig.logoUrl}`,
          favIconUrl: whitelabelConfig.favIconUrl,
          primaryColor: whitelabelConfig.primaryColor,
          secondaryColor: whitelabelConfig.secondaryColor,
          lightColor: whitelabelConfig.lightColor,
          title: whitelabelConfig.title,
          domain: whitelabelConfig.domain,
          distributor: whitelabelConfig.distributor,
          company: whitelabelConfig.company,
          appDistributions: whitelabelConfig.appDistributions,
          timeToAllowContinueUnit: whitelabelConfig.configuration
            ? whitelabelConfig.configuration.timeToAllowContinueUnit
            : 15,
        },
      },
      () => this.handleWhitelabelChanges()
    );
  };

  componentDidMount() {
    const { domain } = parseDomain(window.location.href, { validHosts: ["localhost"] });
    if (!domain) {
      this.setState({
        isLoading: false,
      });
      return;
    }

    if (this.props.whitelabelConfigs && this.props.whitelabelConfigs[domain]) {
      this.handleSuccess(this.props.whitelabelConfigs[domain]);
    }
    if (domain !== "flexge.com") {
      fetch(`${this.props.host}/public/whitelabel-config`)
        .then((response) => {
          if (response.ok) {
            response.json().then((apiWhitelabelConfig) => {
              const propsToCompare = [
                "title",
                "primaryColor",
                "secondaryColor",
                "lightColor",
                "logoUrl",
                "kidsLogoUrl",
                "favIconUrl",
                "configuration",
              ];
              const oldConfig = JSON.stringify(pick(this.props.whitelabelConfigs[domain], propsToCompare));
              const newConfig = JSON.stringify(pick(apiWhitelabelConfig, propsToCompare));
              if (oldConfig !== newConfig) {
                this.handleSuccess(apiWhitelabelConfig);
              }
            });
          }
        })
        .catch((error) => console.error(error))
        .finally(() => {
          this.setState({
            isLoading: false,
          });
        });
    }
  }

  render() {
    return (
      <WhitelabelContext.Provider value={this.state.context}>
        {this.props.children({ isLoading: this.state.isLoading })}
      </WhitelabelContext.Provider>
    );
  }
}

export default WhitelabelAppProvider;
