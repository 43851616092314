import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import CourseBanner from "./CourseBanner";

const CourseBannerContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));
      return <CourseBanner student={profile} />;
    }}
  </Entity>
);

export default CourseBannerContainer;
