import React from "react";
import Form from "student-front-commons/src/components/Form";
import PlacementTestLevelTitle from "./PlacementTestLevelTitle";
import { PLACEMENT_TEST_EXECUTION_FORM } from "../../../consts";

const PlacementTestLevelTitleContainer = () => (
  <Form id={PLACEMENT_TEST_EXECUTION_FORM} onSubmit={() => false} autoInit={false}>
    {({ values }) => <PlacementTestLevelTitle level={values.level} />}
  </Form>
);

export default PlacementTestLevelTitleContainer;
