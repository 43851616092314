import React, { useCallback, useState } from "react";
import ExerciseItemPanel from "./ExerciseItemPanel";
import HighlightableItemBox from "./HighlightableItemBox";
import VideoPlayer, { Modes } from "../VideoPlayer";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { useDispatch, useSelector } from "react-redux";
import { finishPlayItem, updateItemMediaProgress } from "student-front-commons/src/actions/itemExecution";
import FlexColumn from "../FlexColumn";
import { addSentryUserAction } from "../../util";
import ContentVideoFinishTag from "./ContentVideoFinishTag";

const ContentVideoItemRender = (props) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const dispatch = useDispatch();
  const videoLink = useSelector(getItemExecutionPropById(props.itemId, "item.videoLink"));
  const subtitles = useSelector(getItemExecutionPropById(props.itemId, "item.videoSubtitles"));

  const handleVideoEnd = useCallback(() => {
    dispatch(finishPlayItem(props.itemId, { isInitialPlay: true }));
  }, [props.itemId, dispatch]);

  const handleChange = useCallback(
    (change) => {
      dispatch(updateItemMediaProgress(props.itemId, { duration: change.duration, currentTime: change.seconds }));

      if ((change.seconds * 100) / change.duration >= 90) {
        setIsCompleted(true);
      }
    },
    [dispatch]
  );

  const handleError = useCallback((data) => {
    addSentryUserAction({
      mainComponent: "ContentVideoItem",
      clickedComponent: "None",
      action: `Video error ${data.name} on method ${data.method} with message: ${data.message}`,
    });
    dispatch(finishPlayItem(props.itemId, { isInitialPlay: true }));
  }, []);

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox>
        <FlexColumn width="100%" gap={20}>
          <VideoPlayer
            onVideoEnd={handleVideoEnd}
            onError={handleError}
            onChange={handleChange}
            src={videoLink}
            textTracks={subtitles?.map((subtitle) => ({
              language: subtitle.locale,
              title: subtitle.locale.toUpperCase(),
              uri: subtitle.subtitleUrl,
            }))}
            autoStart
            mode={Modes.WITH_RETURN_10_SECONDS}
          />
          <FlexColumn width="100%" justifyContent="flex-end" alignItems="end">
            <ContentVideoFinishTag isCompleted={isCompleted} itemId={props.itemId} />
          </FlexColumn>
        </FlexColumn>
      </HighlightableItemBox>
    </ExerciseItemPanel>
  );
};

export default function ContentVideoItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <ContentVideoItemRender key={id} itemId={id} />);
}
