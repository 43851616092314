import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import orderBy from "lodash/orderBy";
import { Link, withRouter } from "react-router-dom";
import CourseButton from "../../core/CourseButton";
import Separator from "../../core/Separator";
import VerticalDivider from "../../core/VerticalDivider";
import HorizontalDivider from "../../core/HorizontalDivider";
import TranslatedText from "../../core/TranslatedText";
import InBetweenSpacing from "../../core/InBetweenSpacing";
import CourseThemeContext from "../../core/CourseThemeContext";
import { GREY_5, WHITE } from "../../consts/color";
import ExtraCourseButton from "../../core/ExtraCourseButton";
import FlexColumn from "../../core/FlexColumn";

const CoursePanel = (props) => (
  <div
    className="stepAccessYourCourse"
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: WHITE,
      borderRadius: 3,
      boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
      position: "relative",
      zIndex: 2,
      width: "max-content",
      height: 140,
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 50px 20px 35px",
      }}
    >
      <CourseButton
        course={props.currentCourse.name || ""}
        onClick={() => {
          props.history.push(`course/${props.currentCourse.id}/modules`);
        }}
      />
      <Separator size="xs" />
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <Link
            to={`course/${props.currentCourse.id}/modules`}
            style={{
              margin: 0,
              fontSize: 13,
              color: primary,
            }}
          >
            <TranslatedText translateKey="home.accessCourse" />
          </Link>
        )}
      </CourseThemeContext.Consumer>
    </div>
    <MediaQuery minWidth={768}>{(matches) => (matches ? <VerticalDivider /> : <HorizontalDivider />)}</MediaQuery>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 20,
      }}
    >
      {props.extraCourses.length ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <InBetweenSpacing size="sm">
            {orderBy(props.extraCourses, "order", "asc").map((course) => (
              <FlexColumn alignItems="center">
                <ExtraCourseButton
                  key={`extra-course-${course.id}`}
                  iconUrl={course.iconUrl}
                  onClick={() => {
                    props.history.push(`course/${course.id}/modules`);
                  }}
                  size="sm"
                />
                <Separator size="xs" />
                <Link
                  to={`course/${course.id}/modules`}
                  style={{
                    margin: 0,
                    fontSize: 13,
                    color: GREY_5,
                  }}
                >
                  <label>{course.name}</label>
                </Link>
              </FlexColumn>
            ))}
          </InBetweenSpacing>
        </div>
      ) : (
        <label
          htmlFor="label"
          style={{
            color: "rgba(0, 0, 0, 0.5)",
            fontSize: 13,
            margin: 0,
            textAlign: "center",
            maxWidth: 160,
          }}
        >
          <TranslatedText translateKey="home.firstCourse" />
        </label>
      )}
    </div>
  </div>
);

CoursePanel.propTypes = {
  currentCourse: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  extraCourses: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, iconUrl: PropTypes.string })
  ).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default withRouter(CoursePanel);
