import React, { Component } from "react";
import PropTypes from "prop-types";
import Color from "color";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import numeral from "numeral";

export default class ModuleScoreItem extends Component {
  static propTypes = {
    maxValue: PropTypes.number.isRequired,
    totalValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    type: PropTypes.oneOf(["listening", "speaking", "reading", "writing"]).isRequired,
  };

  state = { width: 0 };

  componentDidMount() {
    this.progressTimeout = setTimeout(() => {
      this.setState({
        width: Math.round((this.props.value * 100) / this.props.totalValue),
      });
    }, 250);
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          minWidth: 170,
          width: `${(this.props.totalValue / this.props.maxValue) * 100}%`,
          height: 30,
          background: "rgba(117, 117, 117, .4)",
          borderRadius: 30,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: 0,
            width: `${this.state.width}%`,
            height: "100%",
            borderRadius: this.props.value === this.props.totalValue && 30,
            transition: "width 1s ease-in-out",
            borderTopLeftRadius: 30,
            borderBottomLeftRadius: 30,
            background: `repeating-linear-gradient(-32deg, ${this.props.color}, ${this.props.color} 3px, ${Color(
              this.props.color
            ).fade(0.2)} 0px, ${Color(this.props.color).fade(0.2)} 5px)`,
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            marginLeft: "10px",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Icon
            color="white"
            size="xs"
            icon={
              {
                listening: "ear-hearing",
                speaking: "forum",
                reading: "book-open-variant",
                writing: "lead-pencil",
              }[this.props.type]
            }
          />
          <TranslatedText
            as="div"
            translateKey={this.props.type}
            style={{
              width: 50,
              color: "#FFF",
              marginLeft: "10px",
              marginRight: "15px",
              fontSize: "13px",
            }}
          />
          <div
            style={{
              color: "#FFF",
              fontSize: "13px",
            }}
          >
            {numeral(this.props.value).format("0,0").replace(",", ".")} de{" "}
            {numeral(this.props.totalValue).format("0,0").replace(",", ".")}
          </div>
        </div>
      </div>
    );
  }
}
