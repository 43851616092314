import kebabCase from "lodash/kebabCase";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import browserHistory from "../browserHistory";
import { DISMISS_SYSTEM_MESSAGE_FLOW, END_BONUS_TEST_EXECUTION_FLOW } from "../consts";
import { addSentryUserAction, logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { requestMergeBonusTestResult } from "../actionCreators/bonusTestResult";
import { endBonusTestExecution } from "student-front-commons/src/services/bonusTestService";

const sentryUserAction = { mainComponent: "endBonusTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(END_BONUS_TEST_EXECUTION_FLOW), function* () {
    try {
      const flow = yield select(getFlow(END_BONUS_TEST_EXECUTION_FLOW));

      const result = yield call(endBonusTestExecution, {
        stopReason: flow.params.stopReason,
        reachedLevel: flow.params.reachedLevel,
        bonusTestExecution: flow.params.bonusTestExecution,
      });

      yield put(requestMergeBonusTestResult(result));
    } catch (error) {
      if (error && error.data && error.data.message) {
        yield call(browserHistory.push, `/${kebabCase(error.data.message.toLowerCase())}`);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Bonus Test error. Navigate to: /${kebabCase(error.data.message.toLowerCase())}`,
        });
      } else {
        logError({ error, flow: END_BONUS_TEST_EXECUTION_FLOW });

        yield put(
          showMessage({
            message: "placementTest.endError",
            button: "placementTest.button.tryAgain",
          })
        );

        yield put(endFlow(END_BONUS_TEST_EXECUTION_FLOW));
        yield take(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW));
        yield put(startFlow(END_BONUS_TEST_EXECUTION_FLOW));
      }
    } finally {
      yield put(endFlow(END_BONUS_TEST_EXECUTION_FLOW));
    }
  });
}
