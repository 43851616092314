import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { initForm, changeFormValue, submitForm } from "../actions/form";

class Form extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    form: PropTypes.shape({
      values: PropTypes.shape({}),
      errors: PropTypes.shape({}),
      isDirty: PropTypes.bool,
      isSubmitted: PropTypes.bool,
    }),
    initialValues: PropTypes.shape({}),
    validations: PropTypes.shape({}),
    autoInit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    handleInit: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {
    validations: {},
    initialValues: null,
    autoInit: true,
  };

  componentDidMount() {
    if (this.props.autoInit) {
      this.props.handleInit(this.props.initialValues);
    }
  }

  handleChange = (path, value) => this.props.handleChange(path, value, get(this.props.validations, path));

  handleSubmit = () => {
    this.props.handleSubmit(this.props.validations);

    const error =
      this.props.validations &&
      Object.keys(this.props.validations).find((path) => {
        return get(this.props.validations, path, []).find((func) =>
          func(this.props.form.values[path], this.props.form.values)
        );
      });
    if (!error) {
      this.props.onSubmit();
    }
  };

  render() {
    if (this.props.form) {
      return this.props.children({
        handleChange: this.handleChange,
        handleSubmit: this.handleSubmit,
        isDirty: this.props.form.isDirty,
        isSubmitted: this.props.form.isSubmitted,
        errors: this.props.form.errors,
        values: this.props.form.values,
      });
    }
    return null;
  }
}

const mapStateToProps = (state, ownProps) => ({
  form: state.forms[ownProps.id],
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleInit: (initialValues) => dispatch(initForm(ownProps.id, initialValues)),
  handleChange: (path, value, validations) => dispatch(changeFormValue(ownProps.id, path, value, validations)),
  handleSubmit: (validations) => dispatch(submitForm(ownProps.id, validations)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
