import React, { Component } from "react";
import FetchData from "student-front-commons/src/components/FetchData";
import { getDetailedCurrentScore } from "student-front-commons/src/services/studyQualityService";
import Async from "../Async";
import Paragraph from "../Paragraph";
import StudyQualityStatsScene from "./StudyQualityStatsScene";
import TranslatedText from "../TranslatedText";

class StudyQualityStatsSceneContainer extends Component {
  render() {
    return (
      <FetchData
        service={getDetailedCurrentScore}
        params={{
          id: sessionStorage.getItem("id"),
        }}
      >
        {({ isFetching, data }) => (
          <Async fetching={isFetching} color="dark">
            {data && data.date ? (
              <StudyQualityStatsScene studyQualityDetail={data} />
            ) : (
              <div
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  padding: "15px 45px 15px 25px",
                  boxShadow: "0 1 8 rgba(0, 0, 0, 0.2)",
                }}
              >
                <TranslatedText as={Paragraph} translateKey="studyQuality.howToImproveNoData" />
              </div>
            )}
          </Async>
        )}
      </FetchData>
    );
  }
}

export default StudyQualityStatsSceneContainer;
