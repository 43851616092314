import React, { useState } from "react";
import { useService } from "student-front-commons/src/hooks";
import { getNextMeeting } from "student-front-commons/src/services/meetingService";
import NextMeetingPanel from "./NextMeetingPanel";

const NextMeetingPanelContainer = () => {
  const [nextEvent, setNextEvent] = useState(undefined);

  const [isFetching] = useService(getNextMeeting, {
    autoStart: true,
    onData: setNextEvent,
  });

  if (!nextEvent) {
    return null;
  }
  return <NextMeetingPanel nextEvent={nextEvent} fetching={isFetching} />;
};

export default NextMeetingPanelContainer;
