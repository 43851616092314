import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import CertificationTestItemExecutionForm from "./CertificationTestItemExecutionForm";
import { SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";

const CertificationTestItemExecutionFormContainer = () => (
  <Flow id={SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW}>
    {({ isPending, handleStart }) => {
      return <CertificationTestItemExecutionForm onSubmit={handleStart} isSubmitting={isPending} />;
    }}
  </Flow>
);

export default CertificationTestItemExecutionFormContainer;
