export const START_FLOW = "START_FLOW";
export const END_FLOW = "END_FLOW";

/**
 * start a flow
 * @param {string} flowId
 * @param {object} [params]
 * @returns {{ type: string, payload: {flowId: string, params: *}}}
 */
export const startFlow = (flowId, params) => ({
  type: START_FLOW,
  payload: {
    flowId,
    params,
  },
});

/**
 * end a flow
 * @param flowId
 * @returns {{type, payload: {flowId: *}}}
 */
export const endFlow = (flowId) => ({
  type: END_FLOW,
  payload: {
    flowId,
  },
});
