import head from "lodash/head";
import toLower from "lodash/toLower";
import { call, put, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { LOAD_CONFIGURATION_FLOW, LOAD_LOCALIZED_LABELS_FLOW, LOAD_STUDENT_TASTING_FLOW } from "../consts";
import locales from "../locales";
import browserHistory from "../browserHistory";
import jwtDecode from "jwt-decode";
import LogRocket from "logrocket";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_STUDENT_TASTING_FLOW), function* () {
    const flow = yield select(getFlow(LOAD_STUDENT_TASTING_FLOW));
    try {
      sessionStorage.setItem("id", "tasting_user");
      sessionStorage.setItem("accessToken", flow.params.token);
      const locale = toLower(flow.params.locale || navigator.language || head(navigator.languages));
      sessionStorage.setItem("locale", head(locale.split("-")));
      locales.setLanguage(sessionStorage.getItem("locale"));

      const tokenData = jwtDecode(flow.params.token);
      sessionStorage.setItem("tasting_email", tokenData.email);

      const profileResult = {
        result: "tasting_user",
        entities: {
          profile: {
            tasting_user: {
              id: "tasting_user",
              email: tokenData.email,
              initialEnglishLevel: 0,
              schoolClass: "tasting-class-room",
              company: "tasting-company",
              locale,
              skipInstructions: flow.params.disableInstructions === "true",
              pagesAccessed: [],
            },
          },
          schoolClass: {
            "tasting-class-room": {
              id: "tasting-class-room",
              // isPlacementTestClass: true,
              school: "tasting-school",
            },
          },
          school: {
            "tasting-school": {
              id: "tasting-school",
              allowLevelSelection: false,
            },
          },
          company: {
            "tasting-company": {
              id: "tasting-company",
              allowedStudentInactiveTime: 1,
              allowLevelSelection: false,
            },
          },
        },
      };
      yield put(mergeEntities(profileResult.entities));

      yield put(startFlow(LOAD_LOCALIZED_LABELS_FLOW, { locale: sessionStorage.getItem("locale") }));
      yield put(startFlow(LOAD_CONFIGURATION_FLOW));
      yield take(getFlowEnd(LOAD_CONFIGURATION_FLOW));

      if (process.env.REACT_APP_LOG_ROCKET_KEY) {
        LogRocket.identify(sessionStorage.getItem("id"), {
          email: tokenData.email,
          segment: tokenData.segment,
        });
      }
    } catch (error) {
      yield call(browserHistory.replace, "/login");
    } finally {
      yield put(endFlow(LOAD_STUDENT_TASTING_FLOW));
    }
  });
}
