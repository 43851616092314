import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import CertificationTestStatusBar from "./CertificationTestStatusBar";
import { LOAD_CONFIGURATION_FLOW } from "../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { COMPANY_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";

function CertificationTestStatusBarContainer() {
  const [isPending] = useFlow(LOAD_CONFIGURATION_FLOW);

  const profile = useSelector(getEntityById(PROFILE_SCHEMA, sessionStorage.getItem("id")));
  const company = useSelector(getEntityById(COMPANY_SCHEMA, profile.company));

  const unitsToFinishAfterCertificationTestFailure = useSelector(
    (state) => state.configurations.unitsToFinishAfterCertificationTestFailure || 0
  );

  if (isPending || !profile.certificationTest) {
    return null;
  }

  return (
    <CertificationTestStatusBar
      autoEnable={company.apiVersion === "V2"}
      certificationTest={profile.certificationTest}
      unitsToFinishAfterCertificationTestFailure={unitsToFinishAfterCertificationTestFailure}
    />
  );
}

CertificationTestStatusBarContainer.propTypes = {
  unitsToFinishAfterCertificationTestFailure: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  unitsToFinishAfterCertificationTestFailure: state.configurations.unitsToFinishAfterCertificationTestFailure || 0,
});

export default connect(mapStateToProps)(CertificationTestStatusBarContainer);
