import round from "lodash/round";
import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list a overview of each course available for student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getCoursesOverview = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `records/students/${payload.id}/courses-overview`,
        method: "get",
      });
    },
    "error_get_student_courses_overview",
    "Unexpected error to get the courses overview."
  );
};

/**
 * list a overview of each course available for student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getGrammarAudiosByCourseAndPeriod = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
      sort: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["latest", "oldest"],
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `records/students/${payload.id}/courses/${payload.course}/grammar-audios`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
          sort: payload.sort,
        },
      });
    },
    "error_get_studied_grammars_audios",
    "Unexpected error to load studied grammar and audios."
  );
};

/**
 * list all content course and progress
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.course - the course id
 *
 * @returns {Promise<*>}
 */
export const getCourseContentHistory = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      course: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const history = await request({
        url: `records/students/${payload.id}/courses/${payload.course}/content-details`,
        method: "get",
      });

      return history.map((module) => ({
        ...module,
        readingProgress: round(
          (module.children.reduce((acc, unit) => acc + (unit.conqueredReadingPoints || 0), 0) / module.readingPoints) *
            100
        ),
        listeningProgress: round(
          (module.children.reduce((acc, unit) => acc + (unit.conqueredListeningPoints || 0), 0) /
            module.listeningPoints) *
            100
        ),
        speakingProgress: round(
          (module.children.reduce((acc, unit) => acc + (unit.conqueredSpeakingPoints || 0), 0) /
            module.speakingPoints) *
            100
        ),
        writingProgress: round(
          (module.children.reduce((acc, unit) => acc + (unit.conqueredWritingPoints || 0), 0) / module.writingPoints) *
            100
        ),
      }));
    },
    "error_get_student_courses_content_history",
    "Unexpected error to load course history by content."
  );
};

/**
 * list all content course and progress
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.from - the start period date
 * @param {string} payload.to - the end period date
 *
 * @returns {Promise<*>}
 */
export const getCourseDateHistory = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      from: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
      to: {
        presence: {
          allowEmpty: false,
        },
        datetime: true,
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `records/students/${payload.id}/date-details`,
        method: "get",
        params: {
          from: payload.from,
          to: payload.to,
        },
      });
    },
    "error_get_student_courses_content_history",
    "Unexpected error to load course history by date."
  );
};
