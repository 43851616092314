import React from "react";
import PropTypes from "prop-types";
import { Button as MaterialButton } from "@material-ui/core";

const Button = (props) => {
  return (
    <MaterialButton
      variant={props.variant}
      disabled={props.disabled}
      type={props.type}
      fullWidth={props.fullWidth}
      onClick={props.onClick}
      color={props.color}
    >
      {props.children}
    </MaterialButton>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  raised: PropTypes.bool,
  accent: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["primary", "default"]),
  variant: PropTypes.oneOf(["contained", "outlined"]),
  type: PropTypes.oneOf(["button", "submit"]),
};

Button.defaultProps = {
  raised: true,
  accent: false,
  disabled: false,
  fullWidth: false,
  style: null,
  onClick: null,
  color: "primary",
  variant: "contained",
  type: "button",
};

export default Button;
