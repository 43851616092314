import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import NewMessageAlert from "./NewMessageAlert";
import { SYSTEM_MESSAGE_FLOW } from "../../consts";

const NewMessageAlertContainer = () => (
  <Flow autoStart={false} id={SYSTEM_MESSAGE_FLOW}>
    {({ isPending, getParams }) => isPending && <NewMessageAlert message={getParams().message} />}
  </Flow>
);

export default NewMessageAlertContainer;
