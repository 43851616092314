import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import TastingContactModal from "./TastingContactModal";
import { SHOW_TASTING_CONTACT_MODAL } from "../../../consts";

const TastingContactModalContainer = () => (
  <Flow id={SHOW_TASTING_CONTACT_MODAL}>{({ isPending }) => isPending && <TastingContactModal />}</Flow>
);

export default TastingContactModalContainer;
