import React from "react";
import PropTypes from "prop-types";
import LeaderBoardBg from "../../images/ranking-leaderboard.png";
import ClosedRankingListItem from "./ClosedRankingListItem";
import Separator from "../../core/Separator";
import ClosedRankingListLeaderItem from "./ClosedRankingListLeaderItem";

const ClosedRankingList = (props) =>
  !!props.students.length && (
    <div>
      <div
        style={{
          display: "flex",
          position: "relative",
          minWidth: 630,
          height: 445,
        }}
      >
        <img
          alt="leader-board"
          src={LeaderBoardBg}
          style={{
            maxWidth: 630,
            height: "auto",
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        />
        {props.students.some((s) => s.position === 2) && (
          <ClosedRankingListLeaderItem student={props.students.find((s) => s.position === 2)} />
        )}
        {props.students.some((s) => s.position === 1) && (
          <ClosedRankingListLeaderItem student={props.students.find((s) => s.position === 1)} />
        )}
        {props.students.some((s) => s.position === 3) && (
          <ClosedRankingListLeaderItem student={props.students.find((s) => s.position === 3)} />
        )}
      </div>
      <Separator />
      {props.students.slice(3).map((student) => (
        <React.Fragment key={student.id}>
          <ClosedRankingListItem student={student} />
          <Separator size="xs" />
        </React.Fragment>
      ))}
    </div>
  );

ClosedRankingList.propTypes = {
  students: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default ClosedRankingList;
