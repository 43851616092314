import React from "react";
import PropTypes from "prop-types";
import Table from "../../core/Table";
import withTranslation from "../../core/withTranslation";
import CheckDistributorType from "../../core/CheckDistributorType";

const HistoryPositionList = (props) => (
  <CheckDistributorType>
    {({ isB2C }) => (
      <Table
        title={props.getTranslation("profile.history.tableTitle")}
        headerColor="#455a64"
        rowKey="date"
        columns={[
          ...(!isB2C
            ? [
                {
                  name: props.getTranslation("school"),
                  path: "school",
                },
              ]
            : []),
          ...[
            {
              name: props.getTranslation("regional"),
              path: "regional",
            },
            {
              name: props.getTranslation("national"),
              path: "national",
            },
          ],
        ]}
        data={props.data}
      />
    )}
  </CheckDistributorType>
);

HistoryPositionList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      national: PropTypes.number,
      regional: PropTypes.number,
      school: PropTypes.number,
    })
  ).isRequired,
};

export default withTranslation(HistoryPositionList);
