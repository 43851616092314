import React from "react";
import PropTypes from "prop-types";
import CircularLoading from "../CircularLoading";

const MessageChannelLoading = (props) => (
  <div
    style={{
      position: "absolute",
      transition: "opacity .3s",
      bottom: 20,
      left: "calc(50% - 25px)",
      visibility: props.fetching ? "visible" : "hidden",
      opacity: props.fetching ? 1 : 0,
      backgroundColor: "#fff",
      borderRadius: "100%",
      boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
      width: 50,
      height: 50,
      display: "flex",
      alignItems: "center",
    }}
  >
    <CircularLoading
      rgb={{
        r: 237,
        g: 237,
        b: 237,
      }}
    />
  </div>
);

MessageChannelLoading.propTypes = {
  fetching: PropTypes.bool.isRequired,
};

export default MessageChannelLoading;
