import React from "react";
import PropTypes from "prop-types";
import { GREY_5 } from "../../consts/color";
import TranslatedText from "../TranslatedText";
import { useSelector } from "react-redux";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";

function ControlBarLayoutItem(props) {
  const isInstructionEnabled = useSelector(isInstructionEnableForStep(props.type));
  const shouldHighlight = useSelector(isInstructionHighlighted);

  return (
    <div
      style={{
        display: "inline-block",
        textAlign: "center",
        position: "relative",
        pointerEvents: isInstructionEnabled && shouldHighlight ? "none" : "all",
      }}
      className={
        isInstructionEnabled
          ? shouldHighlight
            ? "animate__animated animate__bounce animate__infinite"
            : "animate__animated animate__pulse animate__infinite"
          : null
      }
    >
      {props.children}
      <TranslatedText
        as="label"
        translateKey={props.label || props.type}
        htmlFor="button"
        style={{
          fontSize: 12,
          color: GREY_5,
          margin: 0,
          pointerEvents: "none",
          userSelect: "none",
        }}
      />
    </div>
  );
}

ControlBarLayoutItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default ControlBarLayoutItem;
