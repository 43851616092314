import { MERGE_CONFIGURATION, MERGE_INSTRUCTION_AUDIOS, MERGE_INSTRUCTION_VIDEOS } from "../actions/configuration";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGE_CONFIGURATION: {
      return {
        ...state,
        ...action.payload.data,
      };
    }
    case MERGE_INSTRUCTION_AUDIOS: {
      return {
        ...state,
        instructionAudios: [...action.payload.data],
      };
    }
    case MERGE_INSTRUCTION_VIDEOS: {
      return {
        ...state,
        instructionVideos: [...action.payload.data],
      };
    }
    default:
      return state;
  }
};
