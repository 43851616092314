import { get, last, orderBy } from "lodash";
import { call, cancelled, delay, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getCurrentItemExecutionProp, getItemExecutions } from "student-front-commons/src/selectors/itemExecution";
import {
  enableItemExecution,
  finishPlayItem,
  finishPlayItemAnswer,
  startPlayItem,
  startPlayItemAnswer,
  updateItemMediaProgress,
} from "student-front-commons/src/actions/itemExecution";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import { END, eventChannel } from "redux-saga";
import {
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_PLACEMENT_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  END_PLACEMENT_TEST_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
} from "../consts";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { awaitHideAction } from "student-front-commons/src/selectors/systemMessage";
import apiRequest from "student-front-commons/src/core/request";

function handleSoundEventChannel(sound) {
  return eventChannel((emitter) => {
    sound.on("play", () => {
      emitter({ event: "play", data: { duration: sound.duration() } });
    });
    sound.on("end", () => {
      emitter({ event: "end" });
    });
    sound.on("playerror", (soundId, error) => {
      emitter(new Error(error));
      emitter(END);
    });
    sound.on("seek", () => {
      emitter({ event: "seek", data: { currentTime: sound.seek() } });
    });
    const progressInterval = setInterval(() => {
      emitter({ event: "progress", data: { currentTime: sound.seek(), duration: sound.duration() } });
    }, 250);
    return () => {
      clearInterval(progressInterval);
      sound.off();
    };
  });
}

const typesToShowProgress = ["AUDIO_LONG"];

const typesToPlayPostPhrase = ["VOCABULARY_ACADEMIC", "VOCABULARY_ACADEMIC_SPEECHLESS"];

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_AUDIO_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closeMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      closePlacement: take(getFlowStart(CLOSE_PLACEMENT_TEST_EXECUTION_FLOW)),
      closeCertification: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      endPlacement: take(getFlowStart(END_PLACEMENT_TEST_EXECUTION_FLOW)),
      takeForcedStop: take(getFlowEnd(PLAY_ITEM_AUDIO_FLOW)),
      startCheckAnswer: take(getFlowStart(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(PLAY_ITEM_AUDIO_FLOW));
        try {
          const rate = get(flow.params, "isSlowRepeat", false) ? 0.7 : 1;
          const isInitialPlay = get(flow.params, "initialPlay", false);
          const itemExecutions = yield select(getItemExecutions);
          const currentSelectedItemId = yield select(getCurrentItemExecutionProp("item.id"));

          yield itemExecutions.reduce(function* (promise, execution) {
            yield promise;

            if (
              [
                "SINGLE_CHOICE_TEXT",
                "SINGLE_CHOICE_AUDIO",
                "SINGLE_CHOICE_IMAGE",
                "MULTIPLE_CHOICE_TEXT",
                "DICTATION",
                "PRESENTATION",
                "PRESENTATION_SPEECHLESS",
                "TRUE_FALSE",
                "SPEECH_PRACTICE",
                "AUDIO_LONG",
                "SPEECH_PRACTICE_SPEECHLESS",
                execution.recordCount > 0 && "PRONUNCIATION",
                currentSelectedItemId === execution.item.id && "VOCABULARY_ACADEMIC",
                "VOCABULARY_ACADEMIC_SPEECHLESS",
              ].some((typeKey) => typeKey === execution.item.type.key)
            ) {
              //if item type is VOCABULARY ACADEMIC and is repeat context do not play item audio
              if (execution.item.type.key !== "VOCABULARY_ACADEMIC" || isInitialPlay) {
                yield put(startPlayItem(execution.item.id, { isInitialPlay }));

                // enable edit while repeating on DICTATION
                if (!isInitialPlay && ["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].includes(execution.item.type.key)) {
                  yield put(enableItemExecution());
                }

                const sound = yield call(playAudio, {
                  url: execution.item.audio || execution.item.generatedAudio,
                  rate,
                  resolveOnPlay: typesToShowProgress.some((type) => type === execution.item.type.key),
                });

                if (typesToShowProgress.some((type) => type === execution.item.type.key)) {
                  const soundEventChannel = yield call(handleSoundEventChannel, sound);
                  while (true) {
                    const { event, data } = yield take(soundEventChannel);

                    if (event === "play" || event === "progress") {
                      yield put(updateItemMediaProgress(execution.item.id, data));
                    }
                    if (event === "end") {
                      soundEventChannel.close();
                      break;
                    }
                  }
                }
              }

              if (["SINGLE_CHOICE_AUDIO", "MULTIPLE_CHOICE_TEXT"].includes(execution.item.type.key)) {
                const optionAudios = orderBy(yield select((state) => state.configurations.optionAudios), "text", "asc");

                if (last(itemExecutions).isExecutionValidated) {
                  yield execution.item.linkedAnswers.reduce(function* (acc, answer, index) {
                    yield acc;
                    yield delay(500);

                    if (answer.correct) {
                      yield put(startPlayItemAnswer(execution.item.id, answer.id));

                      if (execution.item.type.key !== "MULTIPLE_CHOICE_TEXT") {
                        yield call(playAudio, {
                          url: optionAudios[index].path || optionAudios[index].generatedAudio,
                          rate,
                        });
                      }
                      yield call(playAudio, {
                        url: answer.audio || answer.generatedAudio,
                        rate,
                      });
                      yield put(finishPlayItemAnswer(execution.item.id, answer.id));
                    }
                  }, Promise.resolve(0));
                } else if (execution.item.type.key === "SINGLE_CHOICE_AUDIO") {
                  yield execution.item.linkedAnswers.reduce(function* (acc, answer, index) {
                    yield acc;
                    yield delay(500);
                    yield put(startPlayItemAnswer(execution.item.id, answer.id));
                    yield call(playAudio, {
                      url: optionAudios[index].path || optionAudios[index].generatedAudio,
                      rate,
                    });
                    yield call(playAudio, {
                      url: answer.audio || answer.generatedAudio,
                      rate,
                    });
                    yield put(finishPlayItemAnswer(execution.item.id, answer.id));
                  }, Promise.resolve(0));
                }
              }

              if (typesToPlayPostPhrase.some((type) => type === execution.item.type.key)) {
                yield put(startPlayItem(execution.item.id, { isInitialPlay, audioType: "post-phrase" }));
                yield delay(500);
                yield call(playAudio, {
                  rate,
                  isCompleteUrl: false,
                  url: execution.item.postPhraseAudio || execution.item.generatedPostPhraseAudio,
                });
              }

              yield put(finishPlayItem(execution.item.id, { isInitialPlay }));

              return Promise.resolve();
            }
          }, Promise.resolve());

          const id = sessionStorage.getItem("id");
          if (id === "tasting_user" && !isInitialPlay) {
            yield spawn(function* () {
              const execution = yield select((state) => state.executions);

              yield call(apiRequest, {
                method: "put",
                url: `modules/tasting/units/${execution.unit}/update-lead`,
                data: {
                  action: "REPEAT",
                },
              });
            });
          }
        } catch (error) {
          if (
            typeof error === "string" &&
            (error === "required-user-click" || error.indexOf("Playback was unable to start") > -1)
          ) {
            yield spawn(function* () {
              yield put(
                showMessage({
                  icon: "play-circle-outline",
                  message: "error.error_browser_block_autoplay",
                  button: "continue",
                })
              );
              yield take(awaitHideAction);
              yield put(startFlow(PLAY_ITEM_AUDIO_FLOW, flow.params));
            });
          } else {
            logError({ error, flow: PLAY_ITEM_AUDIO_FLOW });
            yield put(
              showMessage({
                message: "error.error_play_audio",
              })
            );
          }
          stopAudio();
        } finally {
          if (yield cancelled()) {
            stopAudio();
          } else {
            yield put(enableItemExecution());
            yield put(endFlow(PLAY_ITEM_AUDIO_FLOW));
          }
        }
      }),
    });
  });
}
