import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { CLOSE_UNIT_EXECUTION_FLOW, PLAY_ITEM_AUDIO_STRIPE_FLOW } from "../consts";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import {
  disableItemExecution,
  enableItemExecution,
  listenItem,
  repeatItem,
} from "student-front-commons/src/actions/itemExecution";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const typesToPlayPostPhraseAudioStripe = ["VOCABULARY_ACADEMIC"];

export default function* () {
  yield takeLatest(getFlowStart(PLAY_ITEM_AUDIO_STRIPE_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(PLAY_ITEM_AUDIO_STRIPE_FLOW));
        try {
          yield put(disableItemExecution());

          const item = yield select(getCurrentItemExecutionProp("item"));
          const recordUrl = yield select(getCurrentItemExecutionProp("recordUrl"));

          let audio = item.audio || item.generatedAudio;

          if (typesToPlayPostPhraseAudioStripe.some((type) => type === item.type.key)) {
            audio = item.postPhraseAudio;
          }

          yield call(playAudio, {
            rate: 1,
            url: flow.params.type === "user" ? recordUrl : audio,
            isCompleteUrl: flow.params.type === "user",
            sprite: `sprite_${flow.params.spriteIndex}`,
          });

          if (flow.params.type === "user") {
            yield put(listenItem(item.id));
          }
          if (flow.params.type === "native") {
            yield put(repeatItem(item.id));
          }
        } catch (error) {
          logError({ error, flow: PLAY_ITEM_AUDIO_STRIPE_FLOW });
          yield put(
            showMessage({
              message: "error.error_play_audio",
            })
          );
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_ITEM_AUDIO_STRIPE_FLOW));
        }
      }),
    });
  });
}
