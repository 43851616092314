import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import { GREY_5 } from "../consts/color";
import { Link } from "react-router-dom";
import TranslatedText from "./TranslatedText";
import FlexRow from "./FlexRow";

const MenuItem = ({ icon, label, link, ...rest }) => (
  <FlexRow alignItems="center" cursor="pointer" height={35}>
    <Icon icon={icon} size="xs" />
    <span
      style={{
        color: GREY_5,
        marginLeft: 5,
      }}
    >
      <Link to={link} {...rest}>
        <TranslatedText as="span" translateKey={label} />
      </Link>
    </span>
  </FlexRow>
);

MenuItem.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default MenuItem;
