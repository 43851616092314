import get from "lodash/get";
import { call, put, race, select, spawn, take, takeLeading } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import { savePlacementTestItemExecution } from "student-front-commons/src/services/placementTestService";
import browserHistory from "../browserHistory";
import {
  CLOSE_PLACEMENT_TEST_EXECUTION_FLOW,
  END_PLACEMENT_TEST_EXECUTION_FLOW,
  GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  PLACEMENT_TEST_EXECUTION_FORM,
  SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import { addSentryUserAction, logError } from "../util";

const sentryUserAction = { mainComponent: "savePlacementTestItemExecutionAnswerFlow" };

export default function* () {
  yield takeLeading(getFlowStart(SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_PLACEMENT_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          let itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          let placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));

          const answerResult = yield call(checkAnswer, {
            item: itemExecutionForm.values.associativeItem.item,
            answer: itemExecutionForm.values.answer,
          });
          const isCorrectAnswer = answerResult.status === "CORRECT";
          const currentAnswer = {
            answer: answerResult.answer,
            correct: isCorrectAnswer,
            placementTestLevel: itemExecutionForm.values.associativeItem.placementTestLevel,
            grammar: itemExecutionForm.values.associativeItem.item.grammar,
          };
          yield put(
            changeFormValue(PLACEMENT_TEST_EXECUTION_FORM, "answers", [
              ...placementTestExecutionForm.values.answers,
              currentAnswer,
            ])
          );

          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `${isCorrectAnswer ? "Correct" : "Incorrect"} Placement Test answer`,
          });

          yield spawn(function* () {
            try {
              yield call(savePlacementTestItemExecution, {
                ...currentAnswer,
                placementTestExecution: placementTestExecutionForm.values.execution,
                item: itemExecutionForm.values.associativeItem.item.id,
              });
            } catch (error) {
              logError({ error, flow: "SAVE_PLACEMENT_API_CALL" });
            }
          });

          placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));

          // if the answer is wrong validate the stop rules
          if (!currentAnswer.correct) {
            const shouldAddExtraItem =
              placementTestExecutionForm.values.answers
                .filter((answer) => !answer.correct)
                .filter(
                  (answer) =>
                    answer.placementTestLevel.id === itemExecutionForm.values.associativeItem.placementTestLevel.id
                )
                .filter((answer) => answer.grammar.id === itemExecutionForm.values.associativeItem.item.grammar.id)
                .length === 1;

            if (shouldAddExtraItem) {
              yield put(
                changeFormValue(
                  PLACEMENT_TEST_EXECUTION_FORM,
                  "extraItemsAddedOnLevel",
                  placementTestExecutionForm.values.extraItemsAddedOnLevel + 1
                )
              );
              placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));
            }

            const items = placementTestExecutionForm.values.placementTestItems;
            const answers = placementTestExecutionForm.values.answers;
            const currentItemLevel = itemExecutionForm.values.associativeItem;
            const extraItemsAddedOnLevel = placementTestExecutionForm.values.extraItemsAddedOnLevel;

            let stopReason = "";
            let endPlacementTest = false;

            // se for errada verificar % de erro permitida de acordo com config
            const availableQuantity =
              items.filter((item) => item.placementTestLevel.id === currentItemLevel.placementTestLevel.id).length +
              extraItemsAddedOnLevel;
            const wrongAnswersQuantity = answers
              .filter((answer) => !answer.correct)
              .filter((answer) => answer.placementTestLevel.id === currentItemLevel.placementTestLevel.id).length;

            if (
              wrongAnswersQuantity >
              availableQuantity * (currentItemLevel.placementTestLevel.placementPercentageError / 100)
            ) {
              endPlacementTest = true;
              stopReason = `${currentItemLevel.placementTestLevel.placementPercentageError}_PORCENTAGEM`;
            }

            // se for errada verifica 5 de 6
            if (answers.length >= 6) {
              const wrongQuantityOnLastFive = answers
                .slice(answers.length - 6)
                .filter((answer) => !answer.correct).length;
              if (wrongQuantityOnLastFive >= 5) {
                endPlacementTest = true;
                stopReason = "5_DE_6";
              }
            }

            // se for errada verifica 5 de 10
            if (answers.length > 10) {
              const wrongQuantityOnLastTen = answers
                .slice(answers.length - 10)
                .filter((answer) => !answer.correct).length;
              if (wrongQuantityOnLastTen >= 6) {
                endPlacementTest = true;
                stopReason = "6_DE_10";
              }
            }

            if (endPlacementTest) {
              let reachedLevel = answers
                .filter((answer) => answer.placementTestLevel.level < currentItemLevel.placementTestLevel.level)
                .reduce(
                  (returnLevel, answer) => {
                    if (answer.placementTestLevel.level > returnLevel.level) {
                      return {
                        ...answer.placementTestLevel,
                      };
                    }
                    return returnLevel;
                  },
                  { level: 0 }
                );

              const totalErrors = answers.filter((answer) => answer.status === "WRONG").length;
              if (reachedLevel.level === 6 && totalErrors > 5) {
                reachedLevel = answers.find((answer) => answer.placementTestLevel.level === 5.2).placementTestLevel;
              }

              yield put(
                startFlow(END_PLACEMENT_TEST_EXECUTION_FLOW, {
                  stopReason,
                  reachedLevel: get(reachedLevel, "id", null),
                  placementTestExecution: placementTestExecutionForm.values.execution,
                })
              );

              if (placementTestExecutionForm.values.isPlacementRedo) {
                browserHistory.push("/placement-test-score-analysis");
                addSentryUserAction({
                  ...sentryUserAction,
                  clickedComponent: "None",
                  action: `Placement Test ended. Navigate to: /placement-test-score-analysis`,
                });
              } else {
                browserHistory.push("/placement-test-score");
                addSentryUserAction({
                  ...sentryUserAction,
                  clickedComponent: "None",
                  action: `Placement Test ended. Navigate to: /placement-test-score`,
                });
              }
              return;
            }

            //check if need to add an extra item
            if (shouldAddExtraItem) {
              yield put(startFlow(GET_EXTRA_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
              return;
            }
          }

          yield put(
            changeFormValue(
              PLACEMENT_TEST_EXECUTION_FORM,
              "currentIndex",
              placementTestExecutionForm.values.currentIndex + 1
            )
          );
          yield put(startFlow(GET_NEXT_PLACEMENT_TEST_ITEM_EXECUTION_FLOW));
        } catch (error) {
          logError({ error, flow: SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW });
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
        } finally {
          yield put(endFlow(SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
