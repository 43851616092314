import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import round from "lodash/round";
import withTranslation from "./withTranslation";
import TranslatedText from "./TranslatedText";
import Tooltip from "./Tooltip";
import CourseThemeContext from "./CourseThemeContext";
import CircularLoading from "./CircularLoading";
import Separator from "./Separator";

const StudyQuality = Styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: calc(-180px + 90px + 40px);
  height: 180px;
  right: 23%;
  z-index: 3;
  width: 30%;
  box-sizing: border-box;
  margin-left: -15px;
  background-color: ${(props) => props.color};
  border-right: 4px solid #fff;
  border-radius: 100%;
  padding-left: 25px;
`;

const Title = Styled.div`
  width: 115px;
  font-size: 11px;
  color: #FFF;
  display: inline-block;
  margin-top: 3px;
`;

const Value = Styled.div`
  display: inline-block;
  font-size: 46px;
  color: #FFF;
  font-weight: 700;
  line-height: 42px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  transition: all .3s;
  
  &:hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
  }
`;

const Description = Styled.div`
  display: inline-block;
  width: 50px;
  margin-left: 8px;
  font-size: 10px;
  color: rgba(255, 255, 255, .7);
  cursor: pointer;
  transition: all .3s;
  
  &:hover {
    transform: scale(1.1);
    color: #fff;
    text-decoration: underline;
  }
`;

const StudyQualityPanel = (props) => (
  <CourseThemeContext.Consumer>
    {({ secondary }) => (
      <StudyQuality color={secondary}>
        <TranslatedText translateKey="studyQuality.name" as={Title} />
        <div className="stepModuleStudyQuality">
          {props.isFetching && (
            <Value disabled={true}>
              <Separator size="xs" />
              <CircularLoading size="xs" />
            </Value>
          )}
          {!props.isFetching && props.value !== null && (
            <React.Fragment>
              <Value disabled={!props.value} onClick={props.value && props.onClick}>
                {props.value !== null ? round(props.value, 0) : "-"}
              </Value>
              <TranslatedText as={Description} translateKey="studyQuality.howToImprove" onClick={props.onClick} />
            </React.Fragment>
          )}
          {!props.isFetching && props.value === null && (
            <Tooltip
              text={
                props.hasEvaluationTemplate
                  ? props.isInEvaluationPeriod
                    ? props.getTranslation("studyQuality.noData")
                    : props.getTranslation("studyQuality.noEvaluationPeriod")
                  : props.getTranslation("studyQuality.noData")
              }
            >
              <Value
                disabled
                style={{
                  fontSize: 28,
                }}
              >
                N/A
              </Value>
            </Tooltip>
          )}
        </div>
      </StudyQuality>
    )}
  </CourseThemeContext.Consumer>
);

StudyQualityPanel.propTypes = {
  isInEvaluationPeriod: PropTypes.bool.isRequired,
  hasEvaluationTemplate: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.number,
};

StudyQualityPanel.defaultProps = {
  value: null,
};

export default withTranslation(StudyQualityPanel);
