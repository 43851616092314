import React, { useContext, useMemo } from "react";
import CourseThemeContext from "../../core/CourseThemeContext";
import withTranslation from "../../core/withTranslation";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { END_MASTERY_TEST_EXECUTION_FLOW } from "../../consts";
import Async from "../../core/Async";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import { GREY_5 } from "../../consts/color";
import ImgRewardAnimated from "../../core/ImgRewardAnimated";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import MasteryTestResultScore from "./MasteryTestResultScore";
import MasteryTestResultPerformance from "./MasteryTestResultPerformance";
import browserHistory from "../../browserHistory";
import ButtonV2 from "../../core/ButtonV2";

const MasteryTestResultScene = () => {
  const { idCourse, idModule, idMasteryTest, idExecution } = useParams();
  const result = useSelector((state) => state.masteryTestResult);
  const [isPending] = useFlow(END_MASTERY_TEST_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      course: idCourse,
      module: idModule,
      masteryTest: idMasteryTest,
      masteryTestExecution: idExecution,
    },
  });
  const module = useEntity("module", idModule);
  const courseThemeContext = useContext(CourseThemeContext);

  const approved = useMemo(() => {
    return result.score >= result.scoreToPass;
  }, [result]);

  return (
    <>
      <Async fetching={isPending}>
        <FlexColumn
          backgroundColor={approved ? courseThemeContext.primary : GREY_5}
          height="calc(100vh - 60px)"
          justifyContent="center"
          alignItems="center"
        >
          <FlexRow width="100%">
            <FlexColumn flexBasis="50%" position="relative" textAlign="center" justifyContent="center">
              <FlexColumn zIndex={2} padding={70}>
                <span style={{ fontSize: 24, fontWeight: 700, color: "white" }}>{module.name}</span>
                <Separator size="md" />
                <TranslatedText
                  as="span"
                  translateKey="masteryTest.result.yorResultOnMasteryTest"
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "white",
                  }}
                />
                <Separator size="xl" />
                <MasteryTestResultScore score={result.score} scoreToPass={result.scoreToPass} />
                <Separator />
                <TranslatedText
                  as="span"
                  translateKey={approved ? "masteryTest.result.youPass" : "masteryTest.result.youDidntPass"}
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    color: "white",
                  }}
                />
                <Separator size="xs" />
                <TranslatedText
                  as="span"
                  translateKey={
                    approved
                      ? "masteryTest.result.congratulationsOnYourEfforts"
                      : "masteryTest.result.dontBeDiscouraged"
                  }
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    color: "white",
                  }}
                />
              </FlexColumn>
              {approved && <ImgRewardAnimated top="50%" left="50%" width={600} marginLeft={-300} marginTop={-300} />}
              <FlexRow justifyContent="center">
                {result.courseStatus === "CONTINUE_COURSE" && (
                  <ButtonV2
                    onClick={() => browserHistory.push(`/course/${idCourse}/modules`)}
                    size="md"
                    labelKey="masteryTest.button.back"
                    intent="light"
                  />
                )}
                {result.courseStatus === "COURSE_ENDED" && (
                  <ButtonV2
                    onClick={() => browserHistory.replace(`/course/${idCourse}/summary`)}
                    size="md"
                    labelKey="masteryTest.button.courseSummary"
                    intent="light"
                  />
                )}
              </FlexRow>
            </FlexColumn>
            <FlexColumn flexBasis="50%" justifyContent="center">
              <MasteryTestResultPerformance />
            </FlexColumn>
          </FlexRow>
        </FlexColumn>
      </Async>
    </>
  );
};

export default withTranslation(MasteryTestResultScene);
