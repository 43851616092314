import React, { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

export default class Carousel extends Component {
  static propTypes = {
    iconType: PropTypes.node.isRequired,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
  };

  static defaultProps = {
    style: {},
  };

  state = { active: 0, effect: "" };

  handleNext = () => {
    let active = this.state.active + 1;
    if (active >= this.props.children.length) {
      active = 0;
    }
    this.setState({
      active,
      effect: "left",
    });
  };

  handlePrevious = () => {
    let active = this.state.active - 1;
    if (active < 0) {
      active = this.props.children.length - 1;
    }
    this.setState({
      active,
      effect: "right",
    });
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          boxSizing: "border-box",
          padding: "0px 50px",
          overflow: "hidden",
          ...this.props.style,
        }}
      >
        <button
          onClick={this.handleNext}
          style={{
            position: "absolute",
            left: "-5px",
            top: "calc(50% - 19px)",
            cursor: "pointer",
            background: "none",
            border: "none",
            outline: "none",
          }}
        >
          <this.props.iconType.type {...this.props.iconType.props} icon="chevron-left" />
        </button>
        <button
          onClick={this.handlePrevious}
          style={{
            position: "absolute",
            right: "-5px",
            top: "calc(50% - 19px)",
            cursor: "pointer",
            background: "none",
            border: "none",
            outline: "none",
          }}
        >
          <this.props.iconType.type {...this.props.iconType.props} icon="chevron-right" />
        </button>
        <TransitionGroup>
          {this.props.children.map((child, index) => {
            return (
              this.state.active === index && (
                <CSSTransition
                  key={index}
                  classNames={`slide-${this.state.effect}`}
                  timeout={{ enter: 300, exit: 250 }}
                >
                  <>{React.cloneElement(child, { key: index })}</>
                </CSSTransition>
              )
            );
          })}
        </TransitionGroup>
      </div>
    );
  }
}
