import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { replaceEntities } from "student-front-commons/src/actions/entity";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { LOAD_MODULES_FLOW } from "../consts";
import { logError } from "../util";
import LogRocket from "logrocket";

export default function* () {
  yield takeLatest(getFlowStart(LOAD_MODULES_FLOW), function* () {
    try {
      const id = sessionStorage.getItem("id");
      const flow = yield select(getFlow(LOAD_MODULES_FLOW));

      const modules = yield call(getModulesByCourse, {
        id,
        course: flow.params.course,
      });

      if (!modules.result.some((moduleId) => modules.entities.module[moduleId].percentageComplete > 0)) {
        LogRocket.track("USER_WITH_NO_POINTS");
      }

      yield put(replaceEntities(modules.entities));
    } catch (error) {
      logError({ error, flow: LOAD_MODULES_FLOW });
    } finally {
      yield put(endFlow(LOAD_MODULES_FLOW));
    }
  });
}
