import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { CLOSE_UNIT_EXECUTION_FLOW, ITEM_EXECUTION_FORM, PLAY_HISTORY_RECORD_AUDIO_FLOW } from "../consts";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

export default function* () {
  yield takeLatest(getFlowStart(PLAY_HISTORY_RECORD_AUDIO_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const flow = yield select(getFlow(PLAY_HISTORY_RECORD_AUDIO_FLOW));
          const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          const historyItem = itemExecutionForm.values.speechRecognitionResultHistory.find(
            (item) => item.id === flow.params.id
          );
          yield call(playAudio, {
            url: historyItem.sound,
            rate: 1,
            isCompleteUrl: true,
          });

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "listenCount", itemExecutionForm.values.listenCount + 1));
        } catch (error) {
          logError({ error, flow: PLAY_HISTORY_RECORD_AUDIO_FLOW });
          yield put(
            showMessage({
              message: "error.error_play_record",
            })
          );
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
          yield put(endFlow(PLAY_HISTORY_RECORD_AUDIO_FLOW));
        }
      }),
    });
  });
}
