import React, { Component } from "react";
import PropTypes from "prop-types";
import Color from "color";
import Icon from "../../core/Icon";
import ColumnSeparator from "../../core/ColumnSeparator";
import TranslatedText from "../../core/TranslatedText";

export default class CertificationTestResultDetailItem extends Component {
  static propTypes = {
    value: PropTypes.number,
    type: PropTypes.oneOf(["listening", "speaking", "reading", "writing"]).isRequired,
  };

  static defaultProps = {
    value: undefined,
  };

  state = { width: 0, show: false };

  componentDidMount() {
    this.progressTimeout = setTimeout(() => {
      this.setState({
        width: this.props.value,
        show: true,
      });
    }, 250);
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
  }

  render() {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            minWidth: 150,
            width: 410,
            height: 30,
            background: "rgba(117, 117, 117, 0.4)",
            borderRadius: 30,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              left: 0,
              width: `${this.state.width}%`,
              height: "100%",
              transition: "width 1s ease-in-out",
              borderTopLeftRadius: 30,
              borderBottomLeftRadius: 30,
              background: `repeating-linear-gradient(-32deg, ${this.props.value >= 70 ? "#00786A" : "#546e7a"}, ${
                this.props.value >= 70 ? "#00786A" : "#546e7a"
              } 3px, ${Color(this.props.value >= 70 ? "#00786A" : "#546e7a").fade(0.2)} 0px, ${Color(
                this.props.value >= 70 ? "#00786A" : "#546e7a"
              ).fade(0.2)} 5px)`,
            }}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 40px 0px 20px",
              height: "100%",
              zIndex: 1,
              transition: "opacity 1s ease-in-out",
              opacity: this.state.show ? 1 : 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon
                color="white"
                size="xs"
                icon={
                  {
                    listening: "ear-hearing",
                    speaking: "forum",
                    reading: "book-open-variant",
                    writing: "lead-pencil",
                  }[this.props.type]
                }
              />
              <ColumnSeparator />
              <TranslatedText
                as="label"
                translateKey={this.props.type}
                style={{
                  color: "#FFF",
                  margin: 0,
                  fontSize: "13px",
                }}
              />
            </div>
            <div
              style={{
                color: "#FFF",
                fontSize: "13px",
              }}
            >
              {this.props.value || 0} / 100
            </div>
          </div>
        </div>
        {this.props.value >= 70 && <Icon icon="check" color="#00786A" />}
        {this.props.value < 70 && <Icon icon="close" color="#546e7a" />}
        {this.props.value === undefined && <Icon icon="lock-outline" color="rgba(117, 117, 117, 0.4)" />}
      </div>
    );
  }
}
