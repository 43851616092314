import uniqBy from "lodash/uniqBy";
import orderBy from "lodash/orderBy";
import {
  MERGE_NOTIFICATIONS_PAGE,
  SET_NOTIFICATIONS_READ,
  ADD_NOTIFICATION_TO_QUEUE,
  REMOVE_NOTIFICATION_FROM_QUEUE,
} from "../consts/actions";

const initialState = {
  data: [],
  queue: [],
  totalUnread: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGE_NOTIFICATIONS_PAGE:
      return {
        ...state,
        data: orderBy(
          uniqBy([...state.data, ...action.payload.pageable.docs], (x) => x.id),
          ["createdAt"],
          ["desc"]
        ),
        totalUnread: action.payload.pageable.totalUnread,
      };
    case SET_NOTIFICATIONS_READ:
      return {
        ...state,
        data: state.data.map((notification) => Object.assign(notification, { read: true })),
        totalUnread: 0,
      };
    case ADD_NOTIFICATION_TO_QUEUE:
      return {
        ...state,
        queue: [...state.queue, action.payload.notification],
        data: orderBy(state.data.concat([action.payload.notification]), ["createdAt"], ["desc"]),
        totalUnread: state.totalUnread + 1,
      };
    case REMOVE_NOTIFICATION_FROM_QUEUE:
      return {
        ...state,
        queue: state.queue.filter((notification) => notification.id !== action.payload.id),
      };
    default:
      return state;
  }
};
