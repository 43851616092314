import React, { Component } from "react";
import Flow from "student-front-commons/src/components/Flow";
import DefaultThemeProvider from "../../../core/DefaultThemeProvider";
import Async from "../../../core/Async";
import PlacementTestScene from "./PlacementTestScene";
import { START_PLACEMENT_TEST_EXECUTION_FLOW } from "../../../consts";

class PlacementTestSceneContainer extends Component {
  render() {
    return (
      <Flow autoStart={true} id={START_PLACEMENT_TEST_EXECUTION_FLOW}>
        {({ isPending }) => (
          <DefaultThemeProvider>
            <Async fetching={isPending}>
              <PlacementTestScene />
            </Async>
          </DefaultThemeProvider>
        )}
      </Flow>
    );
  }
}

export default PlacementTestSceneContainer;
