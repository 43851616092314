import React from "react";
import "moment-duration-format";

const GradeListNoContentColumn = () => (
  <div
    style={{
      textAlign: "center",
    }}
  >
    <h1
      style={{
        color: "rgb(55, 71, 79",
        margin: 0,
        marginBottom: 5,
        fontSize: 22,
        fontWeight: "bold",
      }}
    >
      -
    </h1>
  </div>
);

export default GradeListNoContentColumn;
