import React from "react";
import PropTypes from "prop-types";

const GradeListHeader = (props) => (
  <tr>
    {props.columns.map((column) => (
      <td
        key={`header-${column.title}`}
        style={{
          textAlign: "center",
          color: "rgb(55, 71, 79",
          fontWeight: "bold",
          fontSize: 14,
          padding: "10px 0px",
          ...column.style,
        }}
      >
        {column.title}
      </td>
    ))}
  </tr>
);

GradeListHeader.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      style: PropTypes.shape({}),
    })
  ).isRequired,
};

export default GradeListHeader;
