import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";

const PositionLabel = (props) => (
  <div>
    <label
      htmlFor="position"
      style={{
        margin: 0,
        fontWeight: props.bold ? "bold" : 400,
        fontSize: {
          xs: "10px",
          sm: "18px",
          md: "32px",
          lg: "42px",
        }[props.size],
        color: props.color,
        display: "inline-block",
      }}
    >
      {props.position}
    </label>
    <label
      htmlFor="suffix"
      style={{
        margin: 0,
        fontWeight: props.bold ? "bold" : 400,
        fontSize: 13,
        color: props.color,
        display: "inline-block",
      }}
    >
      {numeral(props.position).format("0o").substring(props.position.toString().length)}
    </label>
  </div>
);

PositionLabel.propTypes = {
  position: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  bold: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

PositionLabel.defaultProps = {
  size: "sm",
  bold: false,
};

export default PositionLabel;
