import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list all student achievements
 *
 * @param {Object} payload - The object with all the params
 * @param {number} payload.page - the page to request
 * @param {number} payload.size - the page size
 *
 * @returns {Promise<*>}
 */
export const getActivities = async (payload) => {
  validate(
    {
      page: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      size: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: "activities",
        method: "get",
        params: {
          page: payload.page,
          size: payload.size,
        },
      });
    },
    "error_get_activities_list",
    "Unexpected error to list Activities."
  );
};
