import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ContentVideoListItem from "./ContentVideoListItem";
import { useEntity } from "student-front-commons/src/hooks";
import FlexColumn from "../FlexColumn";
import TranslatedText from "../TranslatedText";
import { addSentryUserAction } from "../../util";
import { useSelector } from "react-redux";
import { getContentVideoUnitsByModuleId } from "student-front-commons/src/selectors/entity";
import ContentVideoCard from "./ContentVideoCard";

const sentryUserAction = { mainComponent: "ContentVideoList" };

function ContentVideoList(props) {
  const module = useEntity("module", props.moduleId);
  const contentVideoUnits = useSelector(getContentVideoUnitsByModuleId(props.moduleId));

  if (!contentVideoUnits.length) {
    return null;
  }

  return (
    <FlexColumn>
      <TranslatedText
        as="h1"
        translateKey="contentVideo.list.module"
        values={{ value: module.order }}
        style={{
          marginTop: "26px",
          marginBottom: "4px",
          color: "#727272",
          fontSize: 14,
          fontWeight: "bold",
          lineHeight: "16px",
        }}
      />
      <div
        style={{ ...(props.type === "card" && { display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gridGap: 12 }) }}
      >
        {contentVideoUnits.map((unit) => (
          <div key={unit.id}>
            {props.type === "card" ? (
              <ContentVideoCard
                unit={unit}
                allowBlockedUnit={props.allowBlockedUnit}
                onClick={() => {
                  props.history.replace({
                    pathname: `modules/${module.id}/units/${unit.id}/content-video-exercise`,
                    state: { fromContentVideo: props.allowBlockedUnit },
                  });
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "UnitButton",
                    action: `Navigate to ${props.match.url}/modules/${module.id}/units/${unit.id}/content-video-exercise`,
                  });
                }}
              />
            ) : (
              <ContentVideoListItem
                unit={unit}
                allowBlockedUnit={props.allowBlockedUnit}
                onClick={() => {
                  props.history.replace({
                    pathname: `modules/${module.id}/units/${unit.id}/content-video-exercise`,
                    state: { fromContentVideo: props.allowBlockedUnit },
                  });
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "UnitButton",
                    action: `Navigate to ${props.match.url}/modules/${module.id}/units/${unit.id}/content-video-exercise`,
                  });
                }}
              />
            )}
          </div>
        ))}
      </div>
    </FlexColumn>
  );
}

ContentVideoList.propTypes = {
  moduleId: PropTypes.string.isRequired,
  allowBlockedUnit: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(["list", "card"]),
};

ContentVideoList.defaultProps = {
  type: "list",
  allowBlockedUnit: false,
};

export default withRouter(ContentVideoList);
