import { combineReducers } from "redux";

import flexgeReducers from "student-front-commons/src/reducers";
import masteryTestResultReducer from "./masteryTestResult";
import placementTestResultReducer from "./placementTestResult";
import bonusTestResultReducer from "./bonusTestResult";
import certificationTestResultReducer from "./certificationTestResult";
import microphoneConfigReducer from "./microphoneConfig";
import notificationReducer from "./notification";

const reducers = combineReducers({
  ...flexgeReducers,
  masteryTestResult: masteryTestResultReducer,
  placementTestResult: placementTestResultReducer,
  bonusTestResult: bonusTestResultReducer,
  certificationTestResult: certificationTestResultReducer,
  microphoneConfig: microphoneConfigReducer,
  notification: notificationReducer,
});

export default reducers;
