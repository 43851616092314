import { set, get, omit } from "lodash";
import { INIT_FORM, CHANGE_FORM_VALUE, RESET_FORM, SUBMIT_FORM, DELETE_FORM } from "../actions/form";

export default (state = {}, action) => {
  switch (action.type) {
    case INIT_FORM:
      return {
        ...state,
        [action.payload.formId]: {
          values: {
            ...get(action.payload, "initialValues", {}),
          },
          errors: null,
          isDirty: false,
          isSubmitted: false,
          ...(action.payload.initialValues && {
            initialValues: {
              ...action.payload.initialValues,
            },
          }),
        },
      };
    case CHANGE_FORM_VALUE:
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          values: {
            ...set(state[action.payload.formId].values, action.payload.path, action.payload.value),
          },
          ...(action.payload.error
            ? {
                errors: {
                  ...get(state, `${action.payload.formId}.errors`, {}),
                  ...set({}, action.payload.path, action.payload.error),
                },
              }
            : {
                errors: {
                  ...omit(get(state, `${action.payload.formId}.errors`, {}), action.payload.path),
                },
              }),
          isDirty: true,
        },
      };
    case RESET_FORM:
      return {
        ...state,
        [action.payload.formId]: {
          values: state[action.payload.formId].initialValues,
          errors: null,
          isDirty: false,
          isSubmitted: false,
          initialValues: action.payload.initialValues,
        },
      };
    case SUBMIT_FORM:
      return {
        ...state,
        [action.payload.formId]: {
          ...state[action.payload.formId],
          isSubmitted: true,
          errors: action.payload.errors,
        },
      };
    case DELETE_FORM:
      return {
        ...omit(state, action.payload.formId),
      };
    default:
      return state;
  }
};
