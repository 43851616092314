import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { GREY_5 } from "../../consts/color";
import DialogueMessage from "./DialogueMessage";
import Icon from "../Icon";

export default function DialogueAnswer({ position, itemId, text, isCorrect, onSelect }) {
  const currentItemId = useSelector(getCurrentItemExecutionProp("item.id"));
  const currentItemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const showCorrectOption = useSelector(getCurrentItemExecutionProp("showCorrectOption"));
  const selectedAnswer = useSelector(getCurrentItemExecutionProp("answer"));
  const shouldHighlight = itemId === currentItemId && showCorrectOption && isCorrect;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        transition: "all .5s",
        transform: shouldHighlight ? "scale(1.1) translateX(-36px)" : "none",
        marginBottom: "10px",
        opacity: showCorrectOption ? (isCorrect ? 1 : 0.6) : 1,
      }}
      role={currentItemType === "DIALOGUE_OPTION_SPEECHLESS" ? "radio" : "none"}
      onClick={() => (currentItemType === "DIALOGUE_OPTION_SPEECHLESS" ? onSelect(text) : undefined)}
    >
      {currentItemType === "DIALOGUE_OPTION_SPEECHLESS" ? (
        <Icon icon={text !== selectedAnswer ? "radiobox-blank" : "radiobox-marked"} color="#607d8b" />
      ) : (
        <div
          style={{
            backgroundColor: GREY_5,
            width: "31px",
            height: "31px",
            borderRadius: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "-10px",
          }}
        >
          <span style={{ fontSize: "16px", color: "#ffffff" }}>{position}</span>
        </div>
      )}
      <DialogueMessage text={text} dialogueCharacter="ANSWER" shouldHighlight={shouldHighlight} />
    </div>
  );
}

DialogueAnswer.propTypes = {
  position: PropTypes.number.isRequired,
  itemId: PropTypes.string,
  text: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
};

DialogueAnswer.defaultProps = {
  itemId: null,
};
