import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LineChart from "./LineChart";
import Async from "./Async";
import withTranslation from "./withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

class StudyQualityChart extends Component {
  static propTypes = {
    fetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        student: PropTypes.number,
        schoolClass: PropTypes.number,
      })
    ).isRequired,
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <Async fetching={this.props.fetching} size="sm" color="dark">
            <LineChart
              height={175}
              data={this.props.data}
              labels={this.props.data.map((item) => moment(item.date).format("DD-MM"))}
              xAxes={[
                {
                  display: true,
                  gridLines: {
                    lineWidth: 1,
                    display: true,
                    drawOnChartArea: true,
                  },
                },
              ]}
              yAxes={[
                {
                  display: true,
                  gridLines: {
                    drawOnChartArea: false,
                  },
                  ticks: {
                    beginAtZero: true,
                    stepSize: 5,
                    min: -5,
                    max: 15,
                  },
                },
              ]}
              dataFormat={[
                {
                  color: primaryColor,
                  label: this.props.getTranslation("studyQuality.yours"),
                  valueRender: (item) => item.student || 0,
                  tooltipRender: (args) => `${args.yLabel}`,
                },
                {
                  color: "#9e9e9e",
                  label: this.props.getTranslation("studyQuality.classroom"),
                  valueRender: (item) => item.group || 0,
                  tooltipRender: (args) => `${args.yLabel}`,
                },
              ]}
            />
          </Async>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withTranslation(StudyQualityChart);
