import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import moment from "moment";
import { Stage, Layer, Arrow, Line, Rect, Image } from "react-konva";
import Icon from "../Icon";

const EditorButton = Styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: ${(props) => (props.selected ? "1px solid #E8E9EB" : "none")};
  background-color: ${(props) => (props.selected ? "#ffffff" : "#013666")};
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
  
  &:hover {
    transform: ${(props) => (props.disabled ? "none" : "scale(1.1)")};
  }
`;

const UserErrorReportPrintEditor = ({ image, onChange }) => {
  const canvasRef = useRef(null);
  const imageRef = useRef(new window.Image());
  const [drawables, setDrawables] = useState([]);
  const [newDrawable, setNewDrawable] = useState(null);
  const [drawableType, setDrawableType] = useState("Rectangle");
  const [isImageSet, setIsImageSet] = useState(null);

  useEffect(() => {
    if (imageRef.current) {
      imageRef.current.src = image;
      setIsImageSet(true);
    }
  }, [image]);

  const handleMouseDown = (e) => {
    const { x, y } = e.target.getStage().getPointerPosition();
    setNewDrawable({
      id: moment().toISOString(),
      startX: x,
      startY: y,
      drawableType,
      ...(drawableType !== "Rectangle" && {
        points: [x, y],
      }),
      ...(drawableType === "Rectangle" && {
        width: 0,
        height: 0,
      }),
    });
  };

  const handleMouseUp = (e) => {
    if (newDrawable) {
      const { x, y } = e.target.getStage().getPointerPosition();
      if (drawableType === "Rectangle") {
        setNewDrawable({ ...newDrawable, width: x - newDrawable.startX, height: y - newDrawable.startY });
      }
      if (drawableType === "ArrowDrawable") {
        setNewDrawable({ ...newDrawable, points: [newDrawable.startX, newDrawable.startY, x, y] });
      }
      if (drawableType === "FreePathDrawable") {
        setNewDrawable({ ...newDrawable, points: [...newDrawable.points, x, y] });
      }

      setNewDrawable(null);
      setDrawables([...drawables, newDrawable]);
      onChange(canvasRef.current.toDataURL());
    }
  };

  const handleMouseMove = (e) => {
    if (newDrawable) {
      const { x, y } = e.target.getStage().getPointerPosition();
      if (drawableType === "Rectangle") {
        setNewDrawable({ ...newDrawable, width: x - newDrawable.startX, height: y - newDrawable.startY });
      }
      if (drawableType === "ArrowDrawable") {
        setNewDrawable({ ...newDrawable, points: [newDrawable.startX, newDrawable.startY, x, y] });
      }
      if (drawableType === "FreePathDrawable") {
        setNewDrawable({ ...newDrawable, points: [...newDrawable.points, x, y] });
      }
    }
  };

  const { innerWidth: width, innerHeight: height } = window;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: "100vh",
        margin: 0,
        padding: 0,
        zIndex: Number.MAX_SAFE_INTEGER,
        cursor: drawableType === "FreePathDrawable" ? "cell" : "crosshair",
        border: "5px solid #013666",
        boxShadow: "inset 0 0 10px #f8a100",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          rowGap: 15,
          position: "absolute",
          top: 10,
          left: 10,
          zIndex: Number.MAX_SAFE_INTEGER,
        }}
      >
        <EditorButton onClick={() => setDrawableType("Rectangle")} selected={drawableType === "Rectangle"}>
          <Icon icon="rectangle-outline" size="md" color={drawableType === "Rectangle" ? "#000000" : "#FFFFFF"} />
        </EditorButton>
        <EditorButton onClick={() => setDrawableType("ArrowDrawable")} selected={drawableType === "ArrowDrawable"}>
          <Icon icon="arrow-top-left" size="sm" color={drawableType === "ArrowDrawable" ? "#000000" : "#FFFFFF"} />
        </EditorButton>
        <EditorButton
          onClick={() => setDrawableType("FreePathDrawable")}
          selected={drawableType === "FreePathDrawable"}
        >
          <Icon icon="pencil" size="sm" color={drawableType === "FreePathDrawable" ? "#000000" : "#FFFFFF"} />
        </EditorButton>
      </div>
      <Stage
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        width={width - 10}
        height={height - 10}
        ref={canvasRef}
      >
        {isImageSet && (
          <Layer>
            <Image image={imageRef.current} x={0} y={0} />
          </Layer>
        )}
        <Layer>
          {[...drawables, newDrawable]
            .filter((drawable) => !!drawable)
            .map((drawable) => {
              if (drawable.drawableType === "ArrowDrawable") {
                return (
                  <Arrow key={drawable.id} points={drawable.points} fill="black" stroke="#013666" strokeWidth={10} />
                );
              }
              if (drawable.drawableType === "FreePathDrawable") {
                return (
                  <Line key={drawable.id} points={drawable.points} fill="black" stroke="#013666" strokeWidth={5} />
                );
              }
              if (drawable.drawableType === "Rectangle") {
                return (
                  <Rect
                    key={drawable.id}
                    x={drawable.startX}
                    y={drawable.startY}
                    width={drawable.width}
                    height={drawable.height}
                    fill="transparent"
                    stroke="#013666"
                    strokeWidth={3}
                  />
                );
              }
              return null;
            })}
        </Layer>
      </Stage>
    </div>
  );
};

UserErrorReportPrintEditor.propTypes = {
  image: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UserErrorReportPrintEditor;
