import React from "react";
import PropTypes from "prop-types";
import GradeListHeader from "./GradeListHeader";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const GradeList = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <table
        style={{
          width: "100%",
        }}
      >
        <thead
          style={{
            borderBottom: `2px solid ${primaryColor}`,
          }}
        >
          <GradeListHeader
            columns={props.columns.map((column) => ({
              title: column.header,
              style: column.headerStyle,
            }))}
          />
        </thead>
        <tbody>
          {props.rows.map((row) => (
            <tr
              key={`row-${row.id}`}
              style={{
                borderTop: "1px solid #eeeeee",
              }}
            >
              {props.columns.map((column) => (
                <td
                  key={`column-${row.id}-${column.header}`}
                  style={{
                    padding: "20px 0",
                    ...column.columnStyle,
                  }}
                >
                  {column.render(row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </WhitelabelContext.Consumer>
);

GradeList.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.node,
      headerStyle: PropTypes.shape({}),
      render: PropTypes.func.isRequired,
      columnStyle: PropTypes.shape({}),
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default GradeList;
