import React from "react";
import FetchData from "student-front-commons/src/components/FetchData";
import { getCurrentSprint } from "student-front-commons/src/services/sprintService";
import SprintPanel from "./SprintPanel";

const SprintPanelContainer = () => (
  <FetchData
    service={getCurrentSprint}
    params={{
      id: sessionStorage.getItem("id"),
    }}
  >
    {({ isFetching, data }) => (
      <div className="stepSprint" style={{ width: "100%" }}>
        <SprintPanel sprintStats={data} fetching={isFetching} />
      </div>
    )}
  </FetchData>
);

// const pageAccessed = !!state.student.profile.pagesAccessed.find(page => page === 'HOME');
//   hasData: !pageAccessed ? true : !!Object.keys(state.sprint.stats).length,
export default SprintPanelContainer;
