import React from "react";
import PropTypes from "prop-types";
import SpeechRecordButton from "./SpeechRecordButton";
import Icon from "../../core/Icon";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const SpeechRecognitionTestListItem = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, lightColor }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          width: "100%",
          height: 100,
          borderRadius: 5,
          boxSizing: "border-box",
          padding: "0px 30px 0px 50px",
          backgroundColor: {
            disabled: "#fcfcfc",
            active: lightColor,
            success: lightColor,
            error: "#f9e9e9",
          }[props.status],
          transition: "all .5s",
        }}
      >
        {props.status === "active" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              left: -20,
              width: 40,
              height: 40,
              minWidth: 40,
              minHeight: 40,
              backgroundColor: primaryColor,
              borderRadius: "100%",
            }}
          >
            <Icon icon="arrow-right" color="#fff" />
          </div>
        )}
        <label
          htmlFor="text"
          style={{
            fontSize: 18,
            color: props.status === "disabled" ? "#c6c6c6" : "#607d8b",
          }}
        >
          {props.text}
        </label>
        {(props.status === "active" || props.status === "disabled") && (
          <SpeechRecordButton
            disabled={props.status === "disabled"}
            loading={props.submitting}
            onRecordEnd={props.onRecordEnd}
          />
        )}
        {(props.status === "success" || props.status === "error") && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 60,
              height: 60,
              minWidth: 60,
              minHeight: 60,
              backgroundColor: props.status === "error" ? "#c62828" : primaryColor,
              borderRadius: "100%",
              fontWeight: "bold",
              fontSize: 18,
              color: "#fff",
            }}
          >
            {props.score}
          </div>
        )}
      </div>
    )}
  </WhitelabelContext.Consumer>
);

SpeechRecognitionTestListItem.propTypes = {
  text: PropTypes.string.isRequired,
  onRecordEnd: PropTypes.func.isRequired,
  status: PropTypes.oneOf(["disabled", "active", "success", "error"]).isRequired,
  score: PropTypes.number,
  submitting: PropTypes.bool,
};

SpeechRecognitionTestListItem.defaultProps = {
  score: null,
  submitting: false,
};

export default SpeechRecognitionTestListItem;
