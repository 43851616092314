import PropTypes from "prop-types";
import { detect } from "detect-browser";
import Button from "../../../core/Button";
import MaterialTheme from "../../../core/MaterialTheme";
import FlexColumn from "../../../core/FlexColumn";
import HtmlForm from "../../../core/HtmlForm";
import { useForm, useService } from "student-front-commons/src/hooks";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import TextInput from "../../../core/form/TextInput";
import withTranslation from "../../../core/withTranslation";
import Separator from "../../../core/Separator";
import React, { useState } from "react";
import { useHistory } from "react-router";
import TranslatedText from "../../../core/TranslatedText";
import CircularLoading from "../../../core/CircularLoading";
import AuthMessage from "../../../core/AuthMessage";
import { getProfile } from "student-front-commons/src/services/profileService";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { useDispatch } from "react-redux";
import axios from "axios";
import PhoneInput from "../../../core/form/PhoneInput";
import isPhoneNumber from "student-front-commons/src/validators/isPhoneNumber";

function PlacementTestRegisterForm(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const history = useHistory();
  const deviceInfo = detect();

  // deprecated
  const tokenService = ({ version, data }) =>
    axios({
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("placementRegisterToken")}`,
      },
      url: `${process.env.REACT_APP_API_URL}/${version}/auth/placement-register`,
      method: "post",
      data,
    });

  const service = ({ data }) =>
    axios({
      url: `${process.env.REACT_APP_API_URL}/v2/auth/placement/${props.slug}/register`,
      method: "post",
      data,
    });

  const [, fetchProfile] = useService(getProfile, {
    onData: (data) => {
      dispatch(mergeEntities(data.entities));
    },
  });

  const [isSubmitting, register] = useService(props.slug ? service : tokenService, {
    onData: ({ data }) => {
      sessionStorage.setItem("id", data.id);
      sessionStorage.setItem("accessToken", data.accessToken);
      sessionStorage.setItem("registeredByLink", true);

      fetchProfile();

      history.replace("/practice-test-introduction");
    },
    onError: (error) => {
      const errorCode = error.response.data.error;
      const errorMessage = error.response.data.message;
      if (errorCode === "duplicated_data") {
        setError(props.getTranslation("placementTest.register.duplicatedEmail"));
      } else {
        setError(errorMessage);
      }
    },
  });

  const { getValue, setValue, getError, submit, isSubmitted } = useForm({
    initialValues: {
      browser: `${deviceInfo.name}_${deviceInfo.version}`,
      os: deviceInfo.os,
      screenSize: `${window.screen.width}_x_${window.screen.height}`,
      internetSpeed: "N/A",
    },
    validations: {
      name: [isRequired],
      email: [isRequired, isEmail],
      contactPhone: [isPhoneNumber],
    },
    onSubmit: (values) => {
      setError(null);
      register({ data: values, version: props.version });
    },
  });

  return (
    <>
      <FlexColumn width="100%" maxWidth={450} padding="0 15px" alignItems="center">
        <TranslatedText
          as="span"
          translateKey="createAccount.infoMessage"
          style={{ fontWeight: "bold", fontSize: "16px", color: "#646464" }}
        />
        <Separator />
        <MaterialTheme>
          <HtmlForm onSubmit={submit}>
            <TextInput
              disabled={isSubmitting}
              onChange={(value) => setValue("name", value)}
              value={getValue("name")}
              error={isSubmitted() && getError("name")}
              label={props.getTranslation("createAccount.field.name")}
              fullWidth
              autoComplete={false}
            />
            <Separator />
            <TextInput
              disabled={isSubmitting}
              onChange={(value) => setValue("email", value.replace(/\s+/g, ""))}
              value={getValue("email")}
              error={isSubmitted() && getError("email")}
              label={props.getTranslation("createAccount.field.email")}
              fullWidth
              autoComplete={false}
            />
            <Separator />
            <PhoneInput
              disabled={isSubmitting}
              onChange={(value) => setValue("contactPhone", value)}
              value={getValue("contactPhone")}
              error={isSubmitted() && getError("contactPhone")}
              label={props.getTranslation("profile.account.contactPhone")}
              fullWidth
            />
            <Separator size="lg" />
            <Button
              type="submit"
              disabled={isSubmitting}
              width="100%"
              textTransform="uppercase"
              fontColor="#fff"
              color="#0FB1A2"
              fontWeight="bold"
              fontSize="16px"
            >
              {isSubmitting ? (
                <CircularLoading size="xs" />
              ) : (
                props.getTranslation("placementTestInvite.button.startPlacement")
              )}
            </Button>
            <Separator />
          </HtmlForm>
        </MaterialTheme>
      </FlexColumn>
      <Separator />
      <AuthMessage isOpen={!!error} message={error} type="error" />
    </>
  );
}

PlacementTestRegisterForm.propTypes = {
  data: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  slug: PropTypes.string,
};

export default withTranslation(PlacementTestRegisterForm);
