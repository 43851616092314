import React, { useCallback, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import FlexRow from "../FlexRow";
import { orderBy } from "lodash";
import Icon from "../Icon";
import ExerciseButton from "./ExerciseButton";
import CourseThemeContext from "../CourseThemeContext";
import { FEEDBACK_CORRECT } from "../../consts/color";

const GapFillRadioExerciseRender = (props) => {
  const dispatch = useDispatch();

  const [isPending, startCheckUnitItemExecutionAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);

  const [chunk, setChunk] = useState(null);

  const courseThemeContext = useContext(CourseThemeContext);

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const itemAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));

  const handleChange = useCallback(
    (value) => {
      setChunk(value);
      const answerText = itemText
        .split(" ")
        .reduce((acc, slice, index) => {
          const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
          if (foundAnswer) {
            return acc
              .concat(value.replace(/[!.?,]$/, ""))
              .concat(/[!.?,]$/.test(foundAnswer.text) ? foundAnswer.text.substr(foundAnswer.text.length - 1) : "")
              .concat(" ");
          }
          if (!itemAnswers.find((answer) => answer.index === index)) {
            return acc.concat(slice).concat(" ");
          }
          return acc.concat("");
        }, "")
        .trim();

      dispatch(addItemExecutionAnswer(props.itemId, { answer: answerText }));
      startCheckUnitItemExecutionAnswerFlow();
    },
    [props.itemId, itemText, itemLinkedAnswers, itemAnswers]
  );

  useEffect(() => {
    setChunk(null);
  }, [props.itemId]);

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox isWrong={showCorrectOption} showFeedback={isExecutionValidated}>
        {itemText.split(" ").map((slice, index) => {
          const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
          if (foundAnswer) {
            return (
              <FlexRow key={`answer_id_${foundAnswer.id}`}>
                {chunk ? (
                  <QuestionLabel textDecoration="underline" color={courseThemeContext.primary} fontWeight="bold">
                    {chunk.replace(/[!.?,]$/, "")}
                  </QuestionLabel>
                ) : (
                  <QuestionLabel textDecoration="underline">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</QuestionLabel>
                )}
                <Separator size="xs" />
                {/[!.?,]$/.test(foundAnswer.text) && (
                  <React.Fragment>
                    <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} /> &nbsp;
                  </React.Fragment>
                )}
              </FlexRow>
            );
          }
          return (
            !itemAnswers.find((answer) => answer.index === index) && (
              <div
                key={`${props.itemId}-text-${index}`}
                style={{
                  display: "flex",
                }}
              >
                <QuestionLabel text={slice} />
                &nbsp;
              </div>
            )
          );
        })}
      </HighlightableItemBox>
      <Separator size="md" />
      <FlexRow gap={8}>
        {orderBy(itemLinkedAnswers, "text", "asc").map((answer) => (
          <ExerciseButton
            key={answer.id}
            showBorder={isExecutionValidated && showCorrectOption && answer.correct}
            highlightColor={FEEDBACK_CORRECT}
            disabled={isDisabled || isPending}
            onClick={() => handleChange(answer.text)}
          >
            <Icon icon={answer.text !== chunk ? "radiobox-blank" : "radiobox-marked"} color="#607d8b" />
            <Separator size="xs" />
            <QuestionLabel cursor="normal" color="#607d8b">
              {answer.text.replace(/[!.?,]$/, "")}
            </QuestionLabel>
          </ExerciseButton>
        ))}
      </FlexRow>
    </ExerciseItemPanel>
  );
};

GapFillRadioExerciseRender.propTypes = {
  itemId: PropTypes.string,
};

GapFillRadioExerciseRender.defaultProps = {
  itemId: null,
};

export default function GapFillRadioExerciseItem(props) {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <GapFillRadioExerciseRender key={id} itemId={id} {...props} />);
}
