import React from "react";
import { COMPANY_ALLOWED_TO_NEW_PLACEMENT, LOAD_STUDENT_FLOW } from "../consts";
import moment from "moment";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { useLocation } from "react-router-dom";
import PlacementTestInviteModal from "./PlacementTestInviteModal";

const PlacementTestInviteModalContainer = () => {
  const [isPending] = useFlow(LOAD_STUDENT_FLOW, { autoStart: false });

  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const currentRoute = useLocation();

  if (!isPending) {
    return (
      <>
        {!["tasting", "practice", "placement", "test"].some((string) => currentRoute.pathname.includes(string)) &&
          !profile?.pagesAccessed.some((key) => key === "PLACEMENT_REDO") &&
          profile?.company === COMPANY_ALLOWED_TO_NEW_PLACEMENT &&
          moment().isAfter(moment("2022-11-07")) && <PlacementTestInviteModal allowDismiss={false} />}
      </>
    );
  }

  return null;
};

export default PlacementTestInviteModalContainer;
