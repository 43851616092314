import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "student-front-commons/src/components/Form";
import FlexColumn from "../../core/FlexColumn";
import DragAndDropExerciseItem from "../../core/old-item-type/DragAndDropExerciseItem";
import GapFillRadio from "../../core/old-item-type/GapFillRadio";
import SelectExerciseItem from "../../core/old-item-type/SelectExerciseItem";
import DictationExerciseItem from "../../core/old-item-type/DictationExerciseItem";
import TrueFalseExerciseItem from "../../core/old-item-type/TrueFalseExerciseItem";
import SingleChoiceTextItem from "../../core/old-item-type/SingleChoiceTextItem";
import SingleChoiceAudioItem from "../../core/old-item-type/SingleChoiceAudioItem";
import SingleChoiceImageItem from "../../core/old-item-type/SingleChoiceImageItem";
import CertificationTestExecutionControlBar from "./CertificationTestExecutionControlBar";
import FreeTextExerciseItem from "../../core/old-item-type/FreeTextExerciseItem";
import FreeSpeakExerciseItem from "../../core/old-item-type/FreeSpeakExerciseItem";
import { ITEM_EXECUTION_FORM } from "../../consts";

export default class CertificationTestItemExecutionForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Form id={ITEM_EXECUTION_FORM} onSubmit={this.props.onSubmit} autoInit={false}>
        {({ values, handleSubmit, handleChange }) => {
          const itemType = values.associativeItem.item.type.key;
          return (
            <FlexColumn justifyContent="space-between">
              {["SINGLE_CHOICE_TEXT"].some((type) => type === itemType) && (
                <SingleChoiceTextItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                  abilityType={values.associativeItem.ability}
                />
              )}
              {["SINGLE_CHOICE_AUDIO"].some((type) => type === itemType) && (
                <SingleChoiceAudioItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["SINGLE_CHOICE_IMAGE"].some((type) => type === itemType) && (
                <SingleChoiceImageItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playing={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["GAP_FILL"].some((type) => type === itemType) && (
                <GapFillRadio
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playing={values.playingId}
                  userAnswer={values.answer}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["UNSCRAMBLE_DRAG_AND_DROP", "GAP_FILL_MULTIPLE"].some((type) => type === itemType) && (
                <DragAndDropExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  extraData={values.extraData}
                  onChange={handleChange}
                  onConfirm={handleSubmit}
                  isAutoConfirm={false}
                />
              )}
              {["GAP_FILL_SELECT"].some((type) => type === itemType) && (
                <SelectExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                  extraData={values.extraData}
                />
              )}
              {["DICTATION"].some((type) => type === itemType) && (
                <DictationExerciseItem
                  item={values.associativeItem.item}
                  answer={values.answer}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  onChange={handleChange}
                  extraData={values.extraData}
                />
              )}
              {["TRUE_FALSE"].some((type) => type === itemType) && (
                <TrueFalseExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                  abilityType={values.associativeItem.ability}
                />
              )}
              {["FREE_TEXT"].some((type) => type === itemType) && (
                <FreeTextExerciseItem
                  item={values.associativeItem.item}
                  answer={values.answer}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  onChange={handleChange}
                  inputType="TEXT"
                />
              )}
              {["FREE_TEXT_IMAGE"].some((type) => type === itemType) && (
                <FreeTextExerciseItem
                  item={values.associativeItem.item}
                  answer={values.answer}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  onChange={handleChange}
                  inputType="IMAGE"
                />
              )}
              {["FREE_SPEAK"].some((type) => type === itemType) && (
                <FreeSpeakExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled}
                  shouldShowStartTimer={!values.isRecording && !values.isSubmittingRecord && !values.answer}
                  inputType="TEXT"
                />
              )}
              {["FREE_SPEAK_IMAGE"].some((type) => type === itemType) && (
                <FreeSpeakExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  shouldShowStartTimer={!values.isRecording && !values.isSubmittingRecord && !values.answer}
                  inputType="IMAGE"
                />
              )}
              <CertificationTestExecutionControlBar
                values={values}
                isSubmitting={this.props.isSubmitting}
                onSave={handleSubmit}
                onChange={handleChange}
              />
            </FlexColumn>
          );
        }}
      </Form>
    );
  }
}
