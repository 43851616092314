import React, { Component } from "react";
import PropTypes from "prop-types";
import { keyframes, css } from "styled-components";
import CircularButton from "../CircularButton";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "RecordButton" };

const animationFrames = keyframes`
  0%{
    box-shadow: 0px 0px 5px 0px rgba(173,0,0,.3);
  }
  65%{
    box-shadow: 0px 0px 5px 13px rgba(173,0,0,.3);
  }
  90%{
    box-shadow: 0px 0px 5px 13px rgba(173,0,0,0);
  }
`;

const animation = () => css`
  ${animationFrames} 1.25s linear infinite;
`;

export default class RecordButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isRecording: PropTypes.bool.isRequired,
  };
  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <MediaQuery maxWidth={540}>
            {(matches) => (
              <CircularButton
                icon={this.props.isRecording ? "stop" : "microphone"}
                size={matches ? "md" : "lg"}
                loading={this.props.isLoading}
                disabled={this.props.isDisabled}
                onClick={() => {
                  this.props.onClick();
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "CircularButton",
                    action: `${this.props.isRecording ? "Stop" : "Start"} Recording Unit Item`,
                  });
                }}
                color={this.props.isRecording ? "#C80404" : primaryColor}
                animation={this.props.isRecording ? animation : null}
              />
            )}
          </MediaQuery>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}
