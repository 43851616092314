import PropTypes from "prop-types";
import { isEmpty, isNil, min } from "lodash";
import { useEffect, useRef, useState } from "react";
import FlexRow from "../../core/FlexRow";
import { useContext } from "react";
import CourseThemeContext from "../../core/CourseThemeContext";

function ProgressCheckpoint(props) {
  const courseThemeContext = useContext(CourseThemeContext);
  const left = props.reward ? { bronze: 60, silver: 80, gold: 100 }[props.reward?.label.toLowerCase()] : 0;

  return (
    <FlexRow alignItems="center" justifyContent="center" position="absolute" left={`${left}%`} top="-7px">
      <div
        style={{
          height: "16px",
          width: "16px",
          backgroundColor: props.progress >= left ? "#00786A" : "rgba(200, 200, 200, 0.85)",
          borderRadius: "20px",
          border: `4px solid ${courseThemeContext.primary}`,
          marginLeft: "-12px",
          filter: props.parentDarkerBg ? "brightness(0.93)" : "none",
          marginTop: props.showFullCheckpoints ? 0 : "1px",
        }}
      />
      {props.showFullCheckpoints && (
        <span
          style={{
            color: "#fff",
            fontSize: "12px",
            top: "20px",
            fontWeight: 700,
            position: "relative",
            width: "30px",
            marginLeft: "-22px",
            textAlign: "center",
            display: "block",
          }}
        >
          {props.reward?.correctAnswers ? props.reward?.correctAnswers : 0}
        </span>
      )}
    </FlexRow>
  );
}

export default function BonusTestRewardsProgressBar(props) {
  const [progress, setProgress] = useState(0);
  const courseThemeContext = useContext(CourseThemeContext);
  const timeoutRef = useRef();

  useEffect(() => {
    if (!isEmpty(props.rewards) && !isNil(props.correctAnswers)) {
      const noBonusMultiplier =
        props.correctAnswers >= props.rewards.bronze.correctAnswers
          ? props.rewards.bronze.correctAnswers
          : props.correctAnswers;

      let silverBonusDivider = 1;
      let silverBonusMultiplier = 0;
      if (props.correctAnswers > props.rewards.bronze.correctAnswers) {
        silverBonusDivider = props.rewards.silver.correctAnswers - props.rewards.bronze.correctAnswers;
        silverBonusMultiplier =
          props.correctAnswers > props.rewards.silver.correctAnswers
            ? props.rewards.silver.correctAnswers - props.rewards.bronze.correctAnswers
            : props.correctAnswers - props.rewards.bronze.correctAnswers;
      }

      let goldBonusDivider = 1;
      let goldBonusMultiplier = 0;
      if (props.correctAnswers > props.rewards.silver.correctAnswers) {
        goldBonusDivider = props.rewards.gold.correctAnswers - props.rewards.silver.correctAnswers;
        goldBonusMultiplier = props.correctAnswers - props.rewards.silver.correctAnswers;
      }

      timeoutRef.current = setTimeout(() => {
        setProgress(
          (60 / props.rewards.bronze.correctAnswers) * noBonusMultiplier +
            (props.correctAnswers <= props.rewards.bronze.correctAnswers
              ? 0
              : (20 / silverBonusDivider) * silverBonusMultiplier) +
            (20 / goldBonusDivider) * goldBonusMultiplier
        );
      }, 500);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [props.rewards, props.correctAnswers]);

  return (
    <FlexRow height="4px" width="90%" maxWidth="580px" backgroundColor="#ffffff30" position="relative">
      <div
        style={{
          backgroundColor: "#00786A",
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: `${min([progress, 100])}%`,
          transition: "all 1.5s ease",
        }}
      />
      {!!props.correctAnswers && (
        <div
          style={{
            position: "absolute",
            left: `calc(${min([progress, 100])}% - 15px)`,
            top: "-10px",
            transition: "all 1.5s ease",
            backgroundColor: "#00786A",
            borderRadius: "32px",
            height: "24px",
            width: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `4px solid ${courseThemeContext.primary}`,
            filter: props.parentDarkerBg ? "brightness(0.93)" : "none",
            zIndex: 2,
          }}
        />
      )}

      {props.showRewardsCheckpoints ? (
        <>
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            parentDarkerBg={false}
            showFullCheckpoints={true}
          />
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            reward={props.rewards.bronze}
            parentDarkerBg={false}
            showFullCheckpoints={true}
          />
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            reward={props.rewards.silver}
            parentDarkerBg={false}
            showFullCheckpoints={true}
          />
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            reward={props.rewards.gold}
            parentDarkerBg={false}
            showFullCheckpoints={true}
          />
        </>
      ) : (
        <>
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            parentDarkerBg={true}
            showFullCheckpoints={false}
          />
          <ProgressCheckpoint
            progress={progress}
            correctAnswers={props.correctAnswers}
            reward={props.rewards.gold}
            parentDarkerBg={true}
            showFullCheckpoints={false}
          />
        </>
      )}
    </FlexRow>
  );
}

BonusTestRewardsProgressBar.propTypes = {
  rewards: PropTypes.object.isRequired,
  correctAnswers: PropTypes.number,
  showRewardsCheckpoints: PropTypes.bool,
  parentDarkerBg: PropTypes.bool,
};

BonusTestRewardsProgressBar.defaultProps = {
  correctAnswers: 0,
  showRewardsCheckpoints: true,
  parentDarkerBg: false,
};
