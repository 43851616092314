import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

const ControlBarLayout = (props) => (
  <MediaQuery maxWidth={368}>
    {(matches) => (
      <div
        style={{
          position: "fixed",
          height: 140,
          width: "100%",
          bottom: 0,
          left: 0,
          zIndex: 12,
          ...props.containerStyle,
        }}
      >
        <div
          style={{
            position: "relative",
            zIndex: 1,
            maxWidth: 490,
            margin: "0 auto",
            top: matches ? 70 : 55,
            padding: "0 10px",
            ...props.actionsContainerStyle,
          }}
        >
          {props.children}
        </div>
        <div
          style={{
            backgroundColor: "rgba(255, 255, 255, .8)",
            boxShadow: "0px -5px 20px 0 rgba(0, 0, 0, 0.1)",
            position: "absolute",
            top: matches ? 40 : 10,
            left: "calc(-550px + 50%)",
            width: 1100,
            height: 720,
            borderRadius: "100%",
          }}
        />
      </div>
    )}
  </MediaQuery>
);

ControlBarLayout.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyle: PropTypes.shape({}),
  actionsContainerStyle: PropTypes.shape({}),
};

ControlBarLayout.defaultProps = {
  containerStyle: {},
  actionsContainerStyle: {},
};

export default ControlBarLayout;
