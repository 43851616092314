import get from "lodash/get";
import { eventChannel } from "redux-saga";
import { call, put, take, takeLatest, delay } from "redux-saga/effects";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { getProfile } from "student-front-commons/src/services/profileService";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { getMessageChannels } from "student-front-commons/src/services/messageService";
import { requestAddNotification } from "../actionCreators/notification";
import browserHistory from "../browserHistory";
import { LOAD_MESSAGES_FLOW, LOAD_STUDENT_FLOW } from "../consts";
import { logError } from "../util";
import LogRocket from "logrocket";

function handleNotifications(connection) {
  return eventChannel((emitter) => {
    connection.onmessage = (event) => {
      emitter({ data: JSON.parse(event.data) });
    };
    connection.onclose = (event) => {
      emitter({ error: { type: "closed", event } });
    };
    connection.onerror = (event) => {
      emitter({ error: { type: "error", event } });
    };
    return () => (connection.onmessage = null);
  });
}

export default function* () {
  yield takeLatest(getFlowStart(LOAD_STUDENT_FLOW), function* () {
    if (!sessionStorage.getItem("id")) {
      return;
    }

    LogRocket.log("Run webscoket connection");

    let errorCount = 0;
    const startWebSocketConnection = function* () {
      try {
        const socketConnection = new WebSocket(`${process.env.REACT_APP_WS_URL}?user=${sessionStorage.getItem("id")}`);

        const notificationChannel = yield call(handleNotifications, socketConnection);
        while (true) {
          const { data, error } = yield take(notificationChannel);

          if (error) {
            LogRocket.log("Connection error");
            LogRocket.log(error);
            if (error.type === "error") {
              errorCount++;
              yield delay(3000 * errorCount);
            }
            if (errorCount <= 10) {
              LogRocket.log("Try reconnect");
              yield startWebSocketConnection();
            }
            return;
          }

          //Handle course changes
          if (
            [
              "CERTIFICATION_TEST_CREATED",
              "CERTIFICATION_TEST_SCHEDULED",
              "CERTIFICATION_TEST_ENABLED",
              "CERTIFICATION_TEST_REVIEWED",
            ].some((type) => type === data.type)
          ) {
            const profileResult = yield call(getProfile);
            yield put(mergeEntities(profileResult.entities));
          }
          if (
            [
              "MODULE_GROUP_ENABLED",
              "UNIT_GROUP_ENABLED",
              "FIRST_REVIEW_ENABLED",
              "SECOND_REVIEW_ENABLED",
              "MASTERY_TEST_ENABLED",
              "MASTERY_TEST_RETRY",
            ].some((type) => type === data.type)
          ) {
            const modules = yield call(getModulesByCourse, {
              id: sessionStorage.getItem("id"),
              course: get(data, "course.id", get(data, "module.course")),
            });
            yield put(mergeEntities(modules.entities));
          }

          // Handle when should show notification popover
          if (data.type === "NEW_CHANNEL") {
            const pageableMessageChannel = yield call(getMessageChannels, {
              page: 1,
              size: 10,
            });

            yield put(mergeEntities(pageableMessageChannel.docs.entities));
          } else if (data.type === "MESSAGE_REPLY") {
            if (
              get(browserHistory.location, "state.messagePopoverScene", "") === "message-list" &&
              get(browserHistory.location, "state.messageChannel", "") === data.content.messageChannel
            ) {
              yield put(startFlow(LOAD_MESSAGES_FLOW, { messageChannel: data.content.messageChannel }));
            } else {
              const pageableMessageChannel = yield call(getMessageChannels, {
                page: 1,
                size: 10,
              });

              yield put(mergeEntities(pageableMessageChannel.docs.entities));
            }
          } else {
            yield put(requestAddNotification(data));
          }
        }
      } catch (error) {
        logError({ error, flow: "WEB_SOCKET_CONNECTION" });
      }
    };

    yield startWebSocketConnection();
  });
}
