import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "moment-duration-format";
import { Table, Tag } from "antd";
import "antd/lib/table/style/index.css";
import "antd/lib/tag/style/index.css";
import Icon from "../../core/Icon";
import ColumnSeparator from "../../core/ColumnSeparator";
import withTranslation from "../../core/withTranslation";

const DateHistoryList = (props) => (
  <Table
    indentSize={5}
    columns={[
      {
        title: props.getTranslation("history.date"),
        dataIndex: "startedAt",
        width: 180,
        render: (value, row) =>
          (row.children && row.children.length && moment(value).format("DD/MM/YYYY")) || (
            <span>
              {row.studentAccess && (row.studentAccess.os === "ios" || row.studentAccess.os === "android") ? (
                <Icon icon="cellphone" size="xs" />
              ) : (
                <Icon icon="desktop-mac" size="xs" />
              )}
              <ColumnSeparator size="xs" />
              {moment(value).format("DD/MM/YYYY HH:mm")}
            </span>
          ),
      },
      {
        title: props.getTranslation("history.course"),
        dataIndex: "unit.module.course.name",
        width: "60px",
        render: (value, row) => {
          if (row.unit) {
            return row.unit.module.course.name;
          }
          if (row.masteryTest) {
            return row.masteryTest.module.course.name;
          }
          return "";
        },
      },
      {
        title: props.getTranslation("history.module"),
        dataIndex: "unit.module.name",
        width: "20%",
        render: (value, row) => {
          if (row.unit) {
            return row.unit.module.name;
          }
          if (row.masteryTest) {
            return row.masteryTest.module.name;
          }
          return "";
        },
      },
      {
        title: props.getTranslation("history.unit"),
        dataIndex: "unit.name",
        width: "15%",
        render: (value, row) => {
          if (row.unit) {
            return row.unit.name;
          }
          if (row.masteryTest) {
            return `${row.masteryTest.modulePercentageToActive}% Module`;
          }
          return "";
        },
      },
      {
        title: props.getTranslation("history.type"),
        dataIndex: "type",
        render: (value, row) => {
          switch (value) {
            case "DEFAULT":
              return "You Challenge";
            case "FIRST_REVIEW":
              return moment(row.startedAt).year() <= 2018 ? "First Review" : "Review";
            case "SECOND_REVIEW":
              return moment(row.startedAt).year() <= 2018 ? "Second Review" : "-";
            case "SIMPLE_REVIEW":
              return moment(row.startedAt).year() <= 2018 ? "Simple Review" : "Recap";
            case "MASTERY_TEST":
              return "Mastery Test";
            case "OPTIONAL_REVIEW":
              return "Optional Review";
            default:
              return "";
          }
        },
      },
      {
        title: props.getTranslation("history.studiedTime"),
        dataIndex: "studiedTime",
        className: "text-center",
        render: (value) => moment.duration(value, "seconds").format("hh:mm:ss", { trim: false }),
      },
      {
        title: props.getTranslation("history.corrects"),
        dataIndex: "items",
        className: "text-center",
        render: (value) => value && `${value.filter((item) => item.correct).length}/${value.length}`,
      },
      {
        title: props.getTranslation("history.score"),
        dataIndex: "score",
        className: "text-center",
        render: (value, row) => {
          if (!row.children) {
            if (row.type === "MASTERY_TEST") {
              return (
                <Tag color={row.completedAt && row.score >= row.scoreToPass ? "green" : "red"}>
                  {value || props.getTranslation("history.notFinished")}
                </Tag>
              );
            }
            return (
              <Tag color={row.completedAt ? (row.points ? "green" : "red") : null}>
                {value || props.getTranslation("history.notFinished")}
              </Tag>
            );
          }
          return "";
        },
      },
      {
        title: props.getTranslation("history.points"),
        dataIndex: "points",
        className: "text-center",
      },
      {
        title: props.getTranslation("history.averageSR"),
        dataIndex: "averageSpeechRecognitionScore",
        className: "text-center",
        render: (value) => (value ? value.toFixed(0) : ""),
      },
    ].filter(
      (column) =>
        !props.isSpeechRecognitionDisabled ||
        (props.isSpeechRecognitionDisabled && column.dataIndex !== "averageSpeechRecognitionScore")
    )}
    dataSource={props.data}
    pagination={false}
    rowKey="id"
    locale={{
      emptyText: props.getTranslation("history.noDateData"),
    }}
  />
);

DateHistoryList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSpeechRecognitionDisabled: PropTypes.bool,
};

export default withTranslation(DateHistoryList);
