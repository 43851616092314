import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import FlexRow from "../../core/FlexRow";
import { WHITE } from "../../consts/color";
import UnitTypeIcon from "./UnitTypeIcon";
import ColumnSeparator from "../../core/ColumnSeparator";
import FlexColumn from "../../core/FlexColumn";
import UnitTypeAbilities from "./UnitTypeAbilities";
import UnitListItemColumnSeparator from "./UnitListItemColumnSeparator";
import Separator from "../../core/Separator";
import UnitButton from "./UnitButton";
import UnitReward from "./UnitReward";
import Completed from "../../core/Completed";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import Paragraph from "../../core/Paragraph";
import PopoverButton from "../../core/PopoverButton";
import { addSentryUserAction } from "../../util";
import $ from "jquery";

const sentryUserAction = { mainComponent: "UnitListItemV2" };

function UnitListItemV2({
  unit,
  timeToAllowContinueUnit,
  isSimpleReviewAllowed,
  showFirstAccessTooltip,
  numberOfDayBeforeFirstReview,
  isSpeechRecognitionDisabled,
  history,
  match,
  location,
}) {
  const [isContinueAllowed, setIsContinueAllowed] = useState(false);
  const continueAllowedTimeoutRef = useRef();

  const isCompleted = useMemo(() => {
    if (unit.unitReview) {
      return !!unit.unitReview.finishedAt;
    }
    return unit.yourChallengeStatus === "COMPLETED";
  }, [unit]);

  useEffect(() => {
    if (
      unit.lastExecutionLastActionAt &&
      !unit.lastExecutionCompletedAt &&
      (!!unit.unitReview || unit.status !== "FIRST_REVIEW")
    ) {
      if (
        !timeToAllowContinueUnit ||
        moment().diff(moment(unit.lastExecutionLastActionAt), "minutes", true) <= timeToAllowContinueUnit
      ) {
        setIsContinueAllowed(true);
      }
    }
  }, [unit?.lastExecutionLastActionAt, unit?.lastExecutionCompletedAt]);

  useEffect(() => {
    if (isContinueAllowed) {
      const diff = moment().diff(
        moment(unit.lastExecutionStartedAt).add(unit.lastExecutionStudiedTime, "seconds"),
        "minutes",
        true
      );
      if (continueAllowedTimeoutRef.current) {
        clearTimeout(continueAllowedTimeoutRef.current);
      }
      continueAllowedTimeoutRef.current = setTimeout(() => {
        setIsContinueAllowed(false);
      }, 14.5 * 60 * 1000 - diff * 60 * 1000);
    }
  }, [isContinueAllowed]);

  useEffect(() => {
    if (location.state && location.state.targetUnit && unit.id === location.state.targetUnit) {
      $(`#unit-${location.state.targetUnit}`).css("transition", "transform .3s");
      $("html, body").animate(
        {
          scrollTop: $(`#unit-${location.state.targetUnit}`).offset().top - 100,
        },
        1000,
        () => {
          $(`#unit-${location.state.targetUnit}`).css("transform", "scale(1.07)");
          setTimeout(() => {
            $(`#unit-${location.state.targetUnit}`).css("transform", "scale(1)");
            history.replace(location.pathname, {});
          }, 1000);
        }
      );
    }
    return () => {
      if (continueAllowedTimeoutRef.current) {
        clearTimeout(continueAllowedTimeoutRef.current);
      }
    };
  }, []);

  const unitPoints = useMemo(() => {
    if (isCompleted) {
      return unit.conqueredRankingPoints;
    }
    if (isSimpleReviewAllowed && unit.status === "SIMPLE_REVIEW") {
      return 10;
    }
    if (unit.unitReview) {
      return unit.unitReview.isOptional ? 10 : unit.firstReviewPoints;
    }
    return unit.defaultPoints;
  }, [unit, isCompleted, isSimpleReviewAllowed]);

  const hideButton = useMemo(() => {
    return (
      unit.status === "SECOND_REVIEW" ||
      (!unit.unitReview && unit.status === "FIRST_REVIEW" && isCompleted) ||
      (unit.status === "SIMPLE_REVIEW" && !isSimpleReviewAllowed) ||
      (unit.unitReview && (unit.status === "LOCKED" || isCompleted))
    );
  }, [unit, isSimpleReviewAllowed, isCompleted, isContinueAllowed]);

  return (
    <FlexRow
      id={`unit-${unit.id}`}
      position="relative"
      backgroundColor={WHITE}
      padding="20px 15px"
      borderRadius={5}
      boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
      border={isCompleted ? "1px solid #0FB1A2" : "none"}
    >
      {unit.disabled && (
        <FlexRow
          top={0}
          left={0}
          width="100%"
          height="100%"
          position="absolute"
          backgroundColor="#ffffffcc"
          zIndex={5}
          justifyContent="center"
          alignItems="center"
        >
          <FlexColumn
            width={64}
            height={64}
            borderRadius={64}
            backgroundColor="#ECECEC"
            justifyContent="center"
            alignItems="center"
          >
            <Icon color="#42424280" icon="lock-outline" size="sm" />
          </FlexColumn>
        </FlexRow>
      )}
      <FlexRow flex={1} alignItems="center" paddingRight={10}>
        <UnitTypeIcon
          type={unit.type}
          isDisabled={unit.disabled || (!!unit.unitReview && moment().isBefore(moment(unit.unitReview.availableAt)))}
          isReview={!!unit.unitReview}
        />
        <ColumnSeparator size="xs" />
        <ColumnSeparator size="sm" />
        <FlexColumn alignItems="flex-start">
          <FlexRow alignItems="center">
            {isCompleted && (
              <React.Fragment>
                <Completed />
                <ColumnSeparator size="xs" />
              </React.Fragment>
            )}
            <FlexColumn>
              {!!unit.unitReview ? (
                <>
                  <FlexRow alignItems="center">
                    <TranslatedText
                      translateKey="unit.steps.first_review"
                      style={{ fontWeight: "bold", fontSize: 12, color: "#424242" }}
                    />
                    {!!unit.unitReview.isOptional ? (
                      <>
                        <Separator size="xxs" />
                        <FlexColumn
                          height={15}
                          padding="0 8px"
                          borderRadius={27.6}
                          backgroundColor={
                            moment().isBefore(moment(unit.unitReview.availableAt)) ? "#ECECEC" : "#5923C91A"
                          }
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TranslatedText
                            translateKey="unit.optionalReview"
                            style={{ fontSize: 12, lineHeight: 12, color: "#424242" }}
                          />
                        </FlexColumn>
                      </>
                    ) : (
                      <>
                        <Separator size="xxs" />
                        <FlexColumn
                          height={15}
                          padding="0 8px"
                          borderRadius={27.6}
                          backgroundColor={
                            moment().isBefore(moment(unit.unitReview.availableAt)) ? "#ECECEC" : "#5900ffcc"
                          }
                          justifyContent="center"
                          alignItems="center"
                        >
                          <TranslatedText
                            translateKey="unit.requiredReview"
                            style={{
                              fontSize: 12,
                              lineHeight: 12,
                              color: moment().isBefore(moment(unit.unitReview.availableAt)) ? "#424242" : "#fff",
                            }}
                          />
                        </FlexColumn>
                      </>
                    )}
                  </FlexRow>
                  <Separator size="xxs" />
                </>
              ) : (
                <TranslatedText
                  translateKey="unit.steps.exercise"
                  style={{ fontWeight: "bold", fontSize: 12, color: "#424242" }}
                />
              )}
              <h1
                style={{
                  margin: 0,
                  fontSize: 16,
                  lineHeight: "18px",
                  fontWeight: "bold",
                  color: "#424242",
                }}
              >
                {unit.name}
              </h1>
            </FlexColumn>
          </FlexRow>
          <Separator size="xs" />
          {!hideButton && (
            <PopoverButton
              isOpen={showFirstAccessTooltip}
              animated={true}
              position="left"
              positionOffset={135}
              content={
                <TranslatedText
                  as={Paragraph}
                  translateKey="unit.firstAccessTooltip"
                  style={{
                    fontWeight: "bold",
                  }}
                />
              }
            >
              {!!unit.status && (
                <UnitButton
                  onClick={() => {
                    history.replace(`${match.url}/${unit.id}/exercise`, {
                      optionalReview: !!unit.unitReview?.isOptional,
                      isReview: !!unit.unitReview,
                    });
                    addSentryUserAction({
                      ...sentryUserAction,
                      clickedComponent: "UnitButton",
                      action: `Navigate to ${match.url}/${unit.id}/exercise`,
                    });
                  }}
                  isDisabled={unit.disabled}
                  isReview={!!unit.unitReview}
                  type={
                    isContinueAllowed
                      ? "CONTINUE"
                      : {
                          LOCKED: "LOCKED",
                          YOUR_CHALLENGE: "START",
                          FIRST_REVIEW: "START",
                          SIMPLE_REVIEW: "RECAP",
                          OPTIONAL_REVIEW: "START",
                        }[unit.status]
                  }
                />
              )}
            </PopoverButton>
          )}
          {!!unit.unitReview && unit.status === "LOCKED" && (
            <FlexRow columnGap={4} alignItems="center">
              <Icon color="#42424280" icon="lock-outline" size="xs" />
              <TranslatedText
                translateKey="unit.unblockReviewIn"
                values={{
                  diff: moment
                    .duration(
                      moment(
                        moment(unit.defaultFinishedAt)
                          .add(numberOfDayBeforeFirstReview, "days")
                          .startOf("day")
                          .format("YYYY-MM-DD HH:mm:ss"),
                        "YYYY-MM-DD HH:mm:ss"
                      ).diff(moment(), "days", true),
                      "days"
                    )
                    .humanize(true),
                }}
                style={{
                  fontSize: 12,
                  lineHeight: "13px",
                  color: "#424242",
                  opacity: 0.5,
                }}
              />
            </FlexRow>
          )}
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        {!isSpeechRecognitionDisabled && (
          <>
            <UnitListItemColumnSeparator />
            <UnitTypeAbilities
              unit={unit.id}
              type={unit.type}
              isDisabled={
                unit.disabled || (!!unit.unitReview && moment().isBefore(moment(unit.unitReview.availableAt)))
              }
              isReview={!!unit.unitReview}
            />
          </>
        )}
        <UnitListItemColumnSeparator />
        <UnitReward points={unitPoints} completed={isCompleted} />
      </FlexRow>
    </FlexRow>
  );
}

UnitListItemV2.propTypes = {
  unit: PropTypes.shape({
    name: PropTypes.string,
    defaultFinishedAt: PropTypes.string,
    lastExecutionStartedAt: PropTypes.string,
    lastExecutionCompletedAt: PropTypes.string,
    lastExecutionLastActionAt: PropTypes.string,
    unitReview: PropTypes.shape({
      availableAt: PropTypes.string,
    }),
  }).isRequired,
  numberOfDayBeforeFirstReview: PropTypes.number.isRequired,
  isSimpleReviewAllowed: PropTypes.bool.isRequired,
  showFirstAccessTooltip: PropTypes.bool.isRequired,
  timeToAllowContinueUnit: PropTypes.number.isRequired,
  isOptionalReviewEnabled: PropTypes.bool,
};

export default withRouter(UnitListItemV2);
