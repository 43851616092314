import React from "react";
import PropTypes from "prop-types";
import AsyncPanel from "../../core/AsyncPanel";
import VerticalDivider from "../../core/VerticalDivider";
import Title from "../../core/Title";
import withTranslation from "../../core/withTranslation";
import TranslatedText from "../../core/TranslatedText";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import HistoryPerformanceList from "./HistoryPerformanceList";
import HistoryPositionList from "./HistoryPositionList";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";

const HistoryPanel = (props) => (
  <div className="stepHistory">
    <AsyncPanel
      padding={!props.fetching && props.hasData ? "none" : "lg"}
      fetching={props.fetching}
      hasData={props.hasData}
      noDataText={props.getTranslation("profile.history.noData")}
    >
      <div
        style={{
          padding: 20,
        }}
      >
        <TranslatedText as={Title} translateKey="profile.history.title" />
      </div>
      <FlexRow height={320}>
        <FlexColumn flexGrow={1} flexBasis="49%">
          <HistoryPerformanceList data={props.stats} />
        </FlexColumn>
        <RankingParticipantContainer>
          <React.Fragment>
            <VerticalDivider />
            <FlexColumn flexBasis="49%">
              <HistoryPositionList data={props.positions} />
            </FlexColumn>
          </React.Fragment>
        </RankingParticipantContainer>
      </FlexRow>
    </AsyncPanel>
  </div>
);

HistoryPanel.propTypes = {
  fetching: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
  stats: PropTypes.arrayOf(PropTypes.shape({})),
  positions: PropTypes.arrayOf(PropTypes.shape({})),
};

HistoryPanel.defaultProps = {
  stats: [],
  positions: [],
};

export default withTranslation(HistoryPanel);
