import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import {
  OLD_END_RECORD_FLOW,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
} from "../../consts";
import MasteryTestCountDownTimer from "../../core/MasteryTestCountDownTimer";

export default class MasteryTestItemTimerContainer extends Component {
  static propTypes = {
    time: PropTypes.number.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    onTimerEnding: PropTypes.func.isRequired,
    itemType: PropTypes.string.isRequired,
  };

  getTimerDelay = () => {
    return [
      "GAP_FILL",
      "GAP_FILL_MULTIPLE",
      "GAP_FILL_SELECT",
      "UNSCRAMBLE_DRAG_AND_DROP",
      "UNSCRAMBLE_SPEECH_RECOGNITION",
    ].find((type) => type === this.props.itemType)
      ? 3000
      : 0;
  };

  render() {
    return (
      <Flow autoStart={false} id={SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW}>
        {({ isPending: isSaving }) => (
          <Flow autoStart={false} id={OLD_END_RECORD_FLOW}>
            {({ isPending: isEndingRecord }) => (
              <Flow autoStart={false} id={OLD_PLAY_ITEM_AUDIO_FLOW}>
                {({ isPending: isAudioPending, getParams }) => (
                  <MasteryTestCountDownTimer
                    delay={this.getTimerDelay()}
                    isDisabled={
                      isSaving || isEndingRecord || (isAudioPending && get(getParams(), "initialPlay", false))
                    }
                    time={this.props.time}
                    onTimerEnd={this.props.onTimerEnd}
                    onTimerEnding={this.props.onTimerEnding}
                  />
                )}
              </Flow>
            )}
          </Flow>
        )}
      </Flow>
    );
  }
}
