import React from "react";
import CourseSummaryStatsContainer from "./CourseSummaryStatsContainer";
import Separator from "../../core/Separator";
import CourseSummaryNextStepContainer from "./CourseSummaryNextStepContainer";
import CourseThemeProvider from "../../core/CourseThemeProvider";
import CourseSummaryHeaderContainer from "./CourseSummaryHeaderContainer";

const CourseSummaryScene = () => (
  <CourseThemeProvider>
    <div
      style={{
        maxWidth: 860,
        borderRadius: 3,
        boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: "#fff",
        margin: "0 auto",
        marginTop: 45,
      }}
    >
      <CourseSummaryHeaderContainer />
      <Separator size="xs" />
      <div
        style={{
          padding: "0 110px",
          boxSizing: "border-box",
        }}
      >
        <Separator size="lg" />
        <Separator size="lg" />
        <CourseSummaryStatsContainer />
        <Separator size="xl" />
        <Separator size="sm" />
        <CourseSummaryNextStepContainer />
      </div>
      <Separator size="lg" />
    </div>
  </CourseThemeProvider>
);

export default CourseSummaryScene;
