import React, { useCallback, useMemo, useState } from "react";
import { shuffle } from "lodash";
import { useSelector } from "react-redux";
import MeaningsAssociatingDefinition from "./MeaningsAssociatingDefinition";
import MeaningsAssociatingVocabularyOption from "./MeaningsAssociatingVocabularyOption";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { useEntity } from "student-front-commons/src/hooks";
import useTranslation from "student-front-commons/src/hooks/useTranslation";

export default function MeaningsAssociatingExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  const [selectedItemTranslation, setSelectedItemTranslation] = useState("");

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const [, translation] = useTranslation(selectedItemTranslation, profile.locale);

  const shuffledItems = useMemo(() => {
    return shuffle(items);
  }, [items]);

  const handleTranslationOpen = useCallback(
    (itemId) => {
      if (itemId === selectedItemTranslation) {
        setSelectedItemTranslation("");
      } else {
        setSelectedItemTranslation(itemId);
      }
    },
    [selectedItemTranslation]
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        margin: "20px auto 0 auto",
      }}
    >
      <div>
        {items.map((itemId, index) => (
          <div style={{ display: "flex", alignItems: "center", gap: "36px" }}>
            <MeaningsAssociatingVocabularyOption key={`meanings-vocabulary-${itemId}`} itemId={shuffledItems[index]} />
            <MeaningsAssociatingDefinition
              onTranslationOpen={() => handleTranslationOpen(itemId)}
              translation={translation}
              isTranslationOpen={itemId === selectedItemTranslation}
              key={`meanings-definition-${itemId}`}
              itemId={itemId}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
