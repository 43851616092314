export const WHITE = "#fff";

export const GREY_1 = "#ededed";
export const GREY_2 = "#e0e0e0";
export const GREY_3 = "#cfd8dc";
export const GREY_4 = "#cecece";
export const GREY_5 = "#607d8b";
export const GREY_6 = "#546e7a";
export const GREY_7 = "#555555";

export const GREEN_3 = "#00786A";

export const BLUE_1 = "#1188ff";

export const GOLD = "#f4bc13";
export const SILVER = "#9d999a";
export const BRONZE = "#d68c3c";

export const SPEECH_GREEN = "#00786A";
export const SPEECH_RED = "#B92A49";
export const SPEECH_ORANGE = "#FF9F51";

export const FEEDBACK_CORRECT = "#00786A";
export const FEEDBACK_WRONG = "#B92A49";

// This must be set by a rest call
const courseColors = {
  A1: {
    primary: "#00A098",
    secondary: "#00CEBA",
  },
  "A1+": {
    primary: "#00A098",
    secondary: "#00CEBA",
  },
  A2: {
    primary: "#008B94",
    secondary: "#00C4D0",
  },
  "A2+": {
    primary: "#008B94",
    secondary: "#00C4D0",
  },
  B1: {
    primary: "#115BC9",
    secondary: "#459AFF",
  },
  "B1+": {
    primary: "#115BC9",
    secondary: "#459AFF",
  },
  B2: {
    primary: "#263AA4",
    secondary: "#4471FF",
  },
  "B2+": {
    primary: "#263AA4",
    secondary: "#4471FF",
  },
  C1: {
    primary: "#3B187F",
    secondary: "#7758DB",
  },
  C2: {
    primary: "#2B0D66",
    secondary: "#5732CF",
  },
  "Pre A1": {
    primary: "#11AC71",
    secondary: "#00EA90",
  },
  PA1: {
    primary: "#11AC71",
    secondary: "#00EA90",
  },
  default: {
    primary: "#013666",
    secondary: "#00ADA4",
  },
};

export const getColorOfCourse = (course) => {
  return courseColors[course] || courseColors.default;
};
