import { get, isEmpty, isNil, orderBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useService } from "student-front-commons/src/hooks";
import { getBonusTestHistory } from "student-front-commons/src/services/bonusTestService";
import browserHistory from "../../browserHistory";
import Async from "../../core/Async";
import ButtonV2 from "../../core/ButtonV2";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import BonusTestRewardsProgressBar from "./BonusTestRewardsProgressBar";

function LastRewardResult(props) {
  return (
    <FlexColumn maxWidth="640px" width="100%" alignItems="center">
      <FlexRow width="100%" padding="0 23px" justifyContent="space-between" alignItems="center">
        <FlexRow>
          <TranslatedText
            translateKey="bonusTest.hits"
            values={{ hits: props.correctAnswers }}
            style={{
              fontWeight: "bold",
              textTransform: "uppercase",
              fontSize: "12px",
              color: "#fff",
            }}
          />
          <RankingParticipantContainer>
            <span style={{ fontWeight: 700, color: "#fff", margin: "0 5px", fontSize: "12px" }}>|</span>
            <TranslatedText
              translateKey="bonusTest.points"
              values={{ points: props.points }}
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "12px",
                color: "#fff",
              }}
            />
          </RankingParticipantContainer>
        </FlexRow>
        <span style={{ color: "#ffffff50", fontSize: "12px", padding: "0 5px" }}>
          {moment(props.completedAt).format("DD/MM/YYYY")}
        </span>
      </FlexRow>
      <Separator size="sm" />
      <BonusTestRewardsProgressBar
        rewards={props.rewards}
        correctAnswers={props.correctAnswers}
        showRewardsCheckpoints={false}
        parentDarkerBg={true}
      />
    </FlexColumn>
  );
}
export default function BonusTestRewardsLastResults(props) {
  const params = useParams();
  const [lastResults, setLastResults] = useState([]);
  const [isFetching] = useService(getBonusTestHistory, {
    autoStart: true,
    onData: (data) => {
      const completedTests = data.filter((test) => !isNil(test.completedAt) || !isEmpty(test.completedAt));
      setLastResults(orderBy(completedTests, ["startedAt"], ["desc"]).slice(1, 3));
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Async fetching={isFetching}>
        <FlexColumn alignItems="center" height="345px" backgroundColor="#00000010" paddingTop="30px">
          <FlexRow width="100%" maxWidth="630px" padding="0 15px">
            <TranslatedText
              translateKey="bonusTest.result.lastResults"
              as="span"
              style={{
                color: "#fff",
                fontSize: "18px",
                fontWeight: 700,
              }}
            />
          </FlexRow>
          <Separator size="md" />
          {lastResults.length > 0 ? (
            lastResults.map((result) => (
              <FlexColumn key={`last-result-${result.id}`} width="100%" alignItems="center">
                <LastRewardResult
                  rewards={props.rewards}
                  correctAnswers={result.answeredItems.filter((item) => item.correct).length}
                  points={get(result.studentAchievement, "points", 0)}
                  completedAt={result.completedAt}
                />
                <Separator size="xl" />
              </FlexColumn>
            ))
          ) : (
            <FlexRow alignItems="center" maxWidth="630px" width="100%" padding="0 15px">
              <TranslatedText
                translateKey="bonusTest.result.noLastResults"
                as="span"
                style={{
                  color: "#fff",
                  fontSize: "12px",
                }}
              />
            </FlexRow>
          )}
          {lastResults.length === 1 && <Separator size="lg" />}
          {lastResults.length === 0 && <Separator size="xxl" />}
          <FlexRow alignItems="center" justifyContent="center">
            <ButtonV2
              labelKey="masteryTest.button.back"
              onClick={() => browserHistory.push(`/course/${params.idCourse}/modules`)}
              intent="blank"
              size="md"
            />
          </FlexRow>
        </FlexColumn>
      </Async>
    </div>
  );
}

BonusTestRewardsLastResults.propTypes = {
  rewards: PropTypes.object.isRequired,
};
