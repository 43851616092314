import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import SemesterPicker from "./SemesterPicker";

class SemesterPickerContainer extends React.Component {
  state = {
    selectedSemester: "1",
  };

  componentDidMount() {
    setTimeout(() => {
      this.props.history.replace(
        `${this.props.match.url}?${queryString.stringify({
          ...queryString.parse(this.props.location.search),
          semester: "1",
        })}`
      );
    }, 0);
  }

  handleChangeMonth = (semester) => {
    this.setState({
      selectedSemester: semester,
    });
    this.props.history.replace(
      `${this.props.match.url}?${queryString.stringify({ ...queryString.parse(this.props.location.search), semester })}`
    );
  };

  render() {
    return <SemesterPicker value={this.state.selectedMonth} onChange={this.handleChangeMonth} />;
  }
}

export default withRouter(SemesterPickerContainer);
