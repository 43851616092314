import React from "react";
import PropTypes from "prop-types";

const UnitContent = (props) => (
  <div
    className="container"
    style={{
      display: "flex",
      flexDirection: "column",
      paddingTop: 20,
      paddingBottom: 160,
    }}
  >
    {props.children}
  </div>
);

UnitContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnitContent;
