import React from "react";
import Form from "student-front-commons/src/components/Form";
import ProgressBar from "../../core/ProgressBar";
import { CERTIFICATION_TEST_ABILITY_EXECUTION_FORM } from "../../consts";

const CertificationTestItemsProgressContainer = () => (
  <Form id={CERTIFICATION_TEST_ABILITY_EXECUTION_FORM} autoInit={false} onSubmit={() => false}>
    {({ values }) => <ProgressBar percentage={(values.answers.length / values.certificationTestItems.length) * 100} />}
  </Form>
);

export default CertificationTestItemsProgressContainer;
