import React, { Component } from "react";
import moment from "moment";
import numeral from "numeral";
import Entity from "student-front-commons/src/components/Entity";
import { COURSE_SCHEMA } from "student-front-commons/src/schemas";

const NotificationPropsHoc = (WrappedComponent) =>
  class extends Component {
    getNotificationIntent = () => {
      if (
        [
          "30_MINUTES_IN_THE_DAY",
          "WEEK_TIME_COMPLETED",
          "STUDY_QUALITY_SCORE_15",
          "STUDY_QUALITY_SCORE_10",
          "MODULE_READING_COMPLETED",
          "MODULE_LISTENING_COMPLETED",
          "MODULE_SPEAKING_COMPLETED",
          "MODULE_WRITING_COMPLETED",
          "ACHIEVEMENT",
          "TOP_10_NATIONAL",
          "TOP_50_NATIONAL",
          "TOP_10_REGIONAL",
          "TOP_50_REGIONAL",
          "TOP_10_SCHOOL",
          "TOP_30_SCHOOL",
        ].some((t) => t === this.props.notification.type)
      ) {
        return "congratulations";
      }
      if (
        [
          "WEEK_TIME_CLOSE_TO_FINISH",
          "1_HOUR_TO_COMPLETE_EVALUATION_PERIOD_TIME",
          "2_HOUR_TO_COMPLETE_EVALUATION_PERIOD_TIME",
          "90_PERCENT_OF_MODULE_COMPLETED",
          "80_PERCENT_OF_MODULE_COMPLETED",
          "50_PERCENT_OF_MODULE_COMPLETED",
          "MORE_50_POINTS_TO_UNLOCK_UNIT_GROUP",
          "90_PERCENT_OF_COURSE_COMPLETED",
          "80_PERCENT_OF_COURSE_COMPLETED",
          "50_PERCENT_OF_COURSE_COMPLETED",
          "25_PERCENT_OF_COURSE_COMPLETED",
        ].some((t) => t === this.props.notification.type)
      ) {
        return "motivational";
      }
      if (
        [
          "FIRST_REVIEW_ENABLED",
          "SECOND_REVIEW_ENABLED",
          "MASTERY_TEST_ENABLED",
          "MASTERY_TEST_FAILED",
          "MASTERY_TEST_RETRY",
          "MODULE_GROUP_ENABLED",
          "UNIT_GROUP_ENABLED",
          "CERTIFICATION_TEST_CREATED",
          "CERTIFICATION_TEST_SCHEDULED",
          "CERTIFICATION_TEST_ENABLED",
          "CERTIFICATION_TEST_REVIEWED",
          "CLOSED_RANKING",
        ].some((t) => t === this.props.notification.type)
      ) {
        return "informative";
      }
    };

    getNotificationMessageProps = () => {
      if (
        this.props.notification.type === "FIRST_REVIEW_ENABLED" ||
        this.props.notification.type === "SECOND_REVIEW_ENABLED"
      ) {
        return {
          unitName: this.props.notification.unit.name,
          moduleName: this.props.notification.module.name,
        };
      } else if (
        [
          "MASTERY_TEST_ENABLED",
          "MASTERY_TEST_RETRY",
          "UNIT_GROUP_ENABLED",
          "90_PERCENT_OF_MODULE_COMPLETED",
          "80_PERCENT_OF_MODULE_COMPLETED",
          "50_PERCENT_OF_MODULE_COMPLETED",
          "MODULE_READING_COMPLETED",
          "MODULE_LISTENING_COMPLETED",
          "MODULE_SPEAKING_COMPLETED",
          "MODULE_WRITING_COMPLETED",
          "MORE_50_POINTS_TO_UNLOCK_UNIT_GROUP",
        ].some((type) => type === this.props.notification.type)
      ) {
        return {
          moduleName: this.props.notification.module.name,
        };
      } else if (this.props.notification.type === "MASTERY_TEST_FAILED") {
        return {
          units: this.props.configurations.unitsToFinishAfterTestFailure,
          moduleName: this.props.notification.module.name,
        };
      } else if (
        [
          "MODULE_GROUP_ENABLED",
          "90_PERCENT_OF_COURSE_COMPLETED",
          "80_PERCENT_OF_COURSE_COMPLETED",
          "50_PERCENT_OF_COURSE_COMPLETED",
          "25_PERCENT_OF_COURSE_COMPLETED",
        ].some((type) => type === this.props.notification.type)
      ) {
        return {
          courseName: this.props.notification.course.name,
        };
      } else if (this.props.notification.type === "CERTIFICATION_TEST_SCHEDULED") {
        return {
          scheduledTo: moment(this.props.notification.certificationTest.scheduledFor)
            .locale(sessionStorage.getItem("locale"))
            .format("DD/MM/YYYY HH:mm"),
        };
      } else if (this.props.notification.type === "ACHIEVEMENT") {
        return {
          position: numeral(this.props.notification.studentAchievement.position).format("0o"),
          month: moment(this.props.notification.studentAchievement.achievedAt)
            .locale(sessionStorage.getItem("locale"))
            .format("MMMM/YYYY"),
          location: this.props.notification.studentAchievement.achievement.level.toLowerCase(),
        };
      } else if (this.props.notification.type === "CLOSED_RANKING") {
        return {
          month: moment(this.props.notification.createdAt).locale(sessionStorage.getItem("locale")).format("MMMM/YYYY"),
        };
      }
      return null;
    };

    render() {
      return (
        <Entity name={COURSE_SCHEMA}>
          {({ getAll }) => (
            <WrappedComponent
              {...this.props}
              intent={this.getNotificationIntent()}
              color={
                {
                  congratulations: "#009688",
                  motivational: "#f35d90",
                  informative: "#1188ff",
                }[this.getNotificationIntent()]
              }
              currentCourse={getAll().find((c) => !c.completedAt)?.id}
              messageProps={this.getNotificationMessageProps()}
            />
          )}
        </Entity>
      );
    }
  };

export default NotificationPropsHoc;
