import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Async from "../../../core/Async";
import { LOAD_CONFIGURATION_FLOW, START_TASTING_UNIT_EXECUTION_FLOW } from "../../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import UnitExecutionScene from "../UnitExecutionScene";
import { useSelector } from "react-redux";
import { getExecutionProgress } from "student-front-commons/src/selectors/execution";
import MediaQuery from "react-responsive";

const HubspotCallToAction = () => {
  const percentage = useSelector(getExecutionProgress);

  useEffect(() => {
    if (percentage > 0) {
      window.hbspt.cta.load(9429913, "97670de9-7e1f-4f36-9c07-e2afb390a28e", { useNewLoader: "true", region: "na1" });
    }
  }, [percentage]);

  return (
    <MediaQuery minWidth={982}>
      {(matches) => (
        <div
          style={{
            position: "absolute",
            zIndex: 100,
            ...(matches
              ? {
                  bottom: 10,
                  right: 10,
                }
              : {
                  top: 5,
                  right: 5,
                }),
          }}
        >
          <span className="hs-cta-wrapper" id="hs-cta-wrapper-97670de9-7e1f-4f36-9c07-e2afb390a28e">
            <span
              className="hs-cta-node hs-cta-97670de9-7e1f-4f36-9c07-e2afb390a28e"
              id="hs-cta-97670de9-7e1f-4f36-9c07-e2afb390a28e"
            ></span>
          </span>
        </div>
      )}
    </MediaQuery>
  );
};

function TastingUnitExecutionSceneContainer() {
  const { idModule, idUnit } = useParams();

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_TASTING_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      module: idModule,
      unit: idUnit,
    },
  });

  return (
    <Async fetching={isStartingExecution || isLoadingConfig}>
      <UnitExecutionScene isPending={isStartingExecution || isLoadingConfig} />
      <HubspotCallToAction />
    </Async>
  );
}

export default TastingUnitExecutionSceneContainer;
