import React, { useCallback } from "react";
import Modal from "../../core/Modal";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import Button from "../../core/Button";
import QuestionLabel from "../../core/QuestionLabel";
import { useDispatch } from "react-redux";
import Paragraph from "../../core/Paragraph";
import { WHITE } from "../../consts/color";
import { useFlow } from "student-front-commons/src/hooks";
import { endFlow } from "student-front-commons/src/actions/flow";
import LigueSom from "../../images/ligueosom.png";

function StartTastingModal() {
  const dispatch = useDispatch();
  const locale = sessionStorage.getItem("locale");
  const [isPending] = useFlow("SHOW_START_TASTING_MODAL");

  const handleClose = useCallback(() => {
    dispatch(endFlow("SHOW_START_TASTING_MODAL"));
  }, []);

  if (!isPending) {
    return null;
  }

  return (
    <Modal style={{ position: "relative", overflow: "visible" }}>
      {locale === "pt" && (
        <img
          src={LigueSom}
          align="ligue-som"
          style={{ position: "absolute", width: 120, objectFit: "contain", top: -50, right: -50 }}
        />
      )}
      <TranslatedText translateKey="startTastingModal.title" fontSize={22} fontWeight={900} as={QuestionLabel} />
      <Separator size="md" />
      <TranslatedText translateKey="startTastingModal.firstDescription" as={Paragraph} style={{ fontSize: 14 }} />
      <Separator size="xs" />
      <TranslatedText translateKey="startTastingModal.secondDescription" as={Paragraph} style={{ fontSize: 14 }} />
      <Separator size="lg" />
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <TranslatedText
            as={Button}
            translateKey="startTastingModal.button"
            color={primary}
            fontColor={WHITE}
            width="auto"
            fontWeight={900}
            onClick={handleClose}
          />
        )}
      </CourseThemeContext.Consumer>
    </Modal>
  );
}

export default StartTastingModal;
