import React from "react";
import Separator from "../../core/Separator";
import CertificationTestResultHeaderContainer from "./CertificationTestResultHeaderContainer";
import CertificationTestResultDetailContainer from "./CertificationTestResultDetailContainer";
import CertificationTestResultIconContainer from "./CertificationTestResultIconContainer";

const CertificationTestResultScene = () => (
  <div>
    <Separator size="lg" />
    <div
      style={{
        maxWidth: 920,
        margin: "0 auto",
        borderRadius: 3,
        boxShadow: "0 0 40px 0 rgba(0, 0, 0, 0.15)",
        backgroundColor: "#fff",
      }}
    >
      <CertificationTestResultHeaderContainer />
      <div
        style={{
          position: "relative",
          padding: "28px 405px 40px 75px",
          overflow: "hidden",
        }}
      >
        <CertificationTestResultDetailContainer />
        <CertificationTestResultIconContainer />
      </div>
    </div>
  </div>
);

export default CertificationTestResultScene;
