import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import SceneVideoIntroduction from "./SceneVideoIntroduction";
import { UPDATE_STUDENT_PAGE_ACCESSED_FLOW } from "../consts";

const SceneVideoIntroductionAutoContainer = (props) => (
  <Flow
    autoStart={false}
    id={UPDATE_STUDENT_PAGE_ACCESSED_FLOW}
    params={{
      screen: props.scene,
    }}
  >
    {({ handleStart }) => (
      <Entity name="profile">
        {({ getById: getProfileById }) => {
          const profile = getProfileById(sessionStorage.getItem("id"));
          return (
            !profile.pagesAccessed.some((key) => key === props.scene) && (
              <SceneVideoIntroduction scene={props.scene} onEnd={handleStart} />
            )
          );
        }}
      </Entity>
    )}
  </Flow>
);

SceneVideoIntroductionAutoContainer.propTypes = {
  scene: SceneVideoIntroduction.propTypes.scene,
};

export default SceneVideoIntroductionAutoContainer;
