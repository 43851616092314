import Styled from "styled-components";
import RippleEffect from "./RippleEffect";

const Button = Styled.button`
  color: ${(props) => (props.fontColor ? props.fontColor : "#eceff1")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  text-transform: ${(props) => props.textTransform || "normal"};
  padding: 0.25em 1em;
  width: ${(props) => (props.width ? props.width : "120px")};
  height: ${(props) => (props.shrink ? "30px" : "40px")};
  border-radius: 3px;
  border: none;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  background-color: ${(props) => props.color || "#00489f"};
  position: relative;
  overflow: hidden;
  outline: none;
  box-shadow: ${(props) => (props.disabled ? "none" : "0px 2px 8px 0px rgba(0, 0, 0, 0.4)")};
  transition: all ease-in-out 150ms;
  
  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 4px 10px 0px rgba(0, 0, 0, 0.3)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

export default RippleEffect(Button);
