import React from "react";
import Entity from "student-front-commons/src/components/Entity";

const RankingParticipantContainer = (props) => (
  <Entity name="profile">
    {({ getById }) => {
      if (getById(sessionStorage.getItem("id")).rankingParticipant === "S") {
        return props.children;
      }
      return null;
    }}
  </Entity>
);

export default RankingParticipantContainer;
