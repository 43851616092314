import {
  MERGE_NOTIFICATIONS_PAGE,
  SET_NOTIFICATIONS_READ,
  ADD_NOTIFICATION_TO_QUEUE,
  REMOVE_NOTIFICATION_FROM_QUEUE,
} from "../consts/actions";

export const requestMergeNotifications = (pageable) => ({
  type: MERGE_NOTIFICATIONS_PAGE,
  payload: {
    pageable,
  },
});

export const requestSetNotificationsAsRead = () => ({
  type: SET_NOTIFICATIONS_READ,
});

export const requestAddNotification = (notification) => ({
  type: ADD_NOTIFICATION_TO_QUEUE,
  payload: {
    notification,
  },
});

export const requestRemoveNotification = (id) => ({
  type: REMOVE_NOTIFICATION_FROM_QUEUE,
  payload: {
    id,
  },
});
