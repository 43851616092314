import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const GradeListEvaluationColumn = (props) => (
  <div>
    <h1
      style={{
        color: "rgb(55, 71, 79)",
        margin: 0,
        marginBottom: 5,
        fontSize: 22,
        fontWeight: "bold",
      }}
    >
      {props.name}
    </h1>
    <div
      style={{
        color: "rgb(96, 125, 139)",
        fontSize: 12,
        marginBottom: 3,
      }}
    >
      {moment(new Date(props.start)).format("MMM DD, YYYY")} - {moment(new Date(props.end)).format("MMM DD, YYYY")}
    </div>
    {props.bonusWeeks > 0 && (
      <div
        style={{
          color: "rgb(96, 125, 139)",
          fontSize: 12,
          marginBottom: 5,
        }}
      >
        Bonus: {props.bonusWeeks} weeks
      </div>
    )}
  </div>
);

GradeListEvaluationColumn.propTypes = {
  name: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  bonusWeeks: PropTypes.number,
};

GradeListEvaluationColumn.defaultProps = {
  bonusWeeks: null,
};

export default GradeListEvaluationColumn;
