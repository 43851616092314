import React, { useContext } from "react";
import Separator from "../../core/Separator";
import Column from "../../core/Column";
import Row from "../../core/Row";
import NationalRankingListContainer from "../../core/ranking/NationalRankingListContainer";
import RegionalRankingListContainer from "../../core/ranking/RegionalRankingListContainer";
import TranslatedText from "../../core/TranslatedText";
import CurrentSchoolPositionContainer from "../../core/CurrentSchoolPositionContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import CheckDistributorType from "../../core/CheckDistributorType";

function UnitResultRankingSection() {
  const whitelabelThemeContext = useContext(WhitelabelContext);

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          width: "100%",
          height: 1,
          position: "relative",
          marginTop: "-15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            fontWeight: 700,
            fontSize: 24,
            margin: 0,
            color: "#475569",
            textTransform: "capitalize",
            position: "absolute",
            backgroundColor: "#fff",
            padding: "0px 24px",
          }}
        >
          Ranking
        </p>
      </div>
      <Separator size="md" />
      <CheckDistributorType>
        {({ isB2C }) => (
          <Row>
            {!isB2C && (
              <Column lgSize={2} mdSize={2} smSize={12} xsSize={12}>
                <TranslatedText
                  as="h5"
                  translateKey="school"
                  style={{
                    color: whitelabelThemeContext.secondaryColor,
                    fontSize: 14,
                    margin: 0,
                    textTransform: "uppercase",
                  }}
                />
                <Separator size="xs" />
                <div
                  style={{
                    backgroundColor: whitelabelThemeContext.lightColor,
                    boxShadow: "rgba(15, 177, 162, 0.5) 0px 0px 10px 0px",
                    width: "100%",
                    borderRadius: 3,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 10px 0px 10px",
                  }}
                >
                  <CurrentSchoolPositionContainer color="#455a64" showTitle={false} />
                </div>
                <Separator size="sm" />
              </Column>
            )}
            <Column lgSize={isB2C ? 6 : 5} mdSize={isB2C ? 6 : 5} smSize={12} xsSize={12}>
              <TranslatedText
                as="h5"
                translateKey="regional"
                style={{
                  color: whitelabelThemeContext.secondaryColor,
                  fontSize: 14,
                  margin: 0,
                  textTransform: "uppercase",
                }}
              />
              <Separator size="xs" />
              <RegionalRankingListContainer />
              <Separator size="sm" />
            </Column>
            <Column lgSize={isB2C ? 6 : 5} mdSize={isB2C ? 6 : 5} smSize={12} xsSize={12}>
              <TranslatedText
                as="h5"
                translateKey="national"
                style={{
                  color: whitelabelThemeContext.secondaryColor,
                  fontSize: 14,
                  margin: 0,
                  textTransform: "uppercase",
                }}
              />
              <Separator size="xs" />
              <NationalRankingListContainer />
            </Column>
          </Row>
        )}
      </CheckDistributorType>
    </div>
  );
}

export default UnitResultRankingSection;
