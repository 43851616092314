import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemExecutionByAnswerId,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import CourseThemeContext from "../CourseThemeContext";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { GREY_4 } from "../../consts/color";
import { useFlow } from "student-front-commons/src/hooks";
import { SELECT_ITEM_FLOW } from "../../consts";

export default function MeaningsAssociatingVocabularyOption({ itemId }) {
  const [status, setStatus] = useState("DEFAULT");

  const dispatch = useDispatch();
  const courseThemeContext = useContext(CourseThemeContext);
  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);

  const text = useSelector(getItemExecutionPropById(itemId, "item.text"));
  const selectedId = useSelector((state) => state.itemExecutions?.selectedId);
  const vocabularyAnswer = useSelector(getItemExecutionByAnswerId(itemId));
  const selectedVocabularyAsAnswerInOtherItem = useSelector(getItemExecutionByAnswerId(selectedId));
  const isDisabled = useSelector(getItemExecutionPropById(itemId, "isDisabled"));
  const isFinished = useSelector(getItemExecutionPropById(itemId, "isFinished"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(itemId, "showCorrectOption"));

  const leftColumnBackgroundColor = {
    DEFAULT: courseThemeContext.primary,
    IS_SELECTED: courseThemeContext.primary,
    IS_ASSOCIATED: GREY_4,
    CAN_DISASSOCIATE: "#ffffff",
  }[status];

  const leftColumnBorderColor = {
    DEFAULT: courseThemeContext.primary,
    IS_SELECTED: courseThemeContext.primary,
    IS_ASSOCIATED: GREY_4,
    CAN_DISASSOCIATE: GREY_4,
  }[status];

  const leftColumnTextColor = {
    DEFAULT: "#ffffff",
    IS_SELECTED: "#ffffff",
    IS_ASSOCIATED: "#ffffff",
    CAN_DISASSOCIATE: GREY_4,
  }[status];

  const leftColumnCursor = {
    DEFAULT: "pointer",
    IS_SELECTED: "pointer",
    IS_ASSOCIATED: "not-allowed",
    CAN_DISASSOCIATE: "pointer",
  }[status];

  useEffect(() => {
    setStatus("DEFAULT");
  }, []);

  useEffect(() => {
    if (!isFinished && !shouldShowCorrectOption) {
      if (selectedId === itemId && !!selectedVocabularyAsAnswerInOtherItem) {
        setStatus("CAN_DISASSOCIATE");
      } else if (selectedId === itemId) {
        setStatus("IS_SELECTED");
      } else if (!!vocabularyAnswer) {
        setStatus("IS_ASSOCIATED");
      } else {
        setStatus("DEFAULT");
      }
    }
  }, [selectedId, itemId, vocabularyAnswer?.item?.id, selectedVocabularyAsAnswerInOtherItem?.item?.id]);

  const handleClick = () => {
    if (["IS_SELECTED", "IS_ASSOCIATED"].some((s) => s === status)) {
      startSelectItemFlow({ itemId: null });
      return;
    }

    if (status === "CAN_DISASSOCIATE") {
      dispatch(addItemExecutionAnswer(vocabularyAnswer.item.id, { answer: "" }));
    } else {
      startSelectItemFlow({ itemId });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100px",
        marginBottom: "18px",
        pointerEvents: isDisabled ? "none" : "all",
      }}
    >
      <div
        onClick={handleClick}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: leftColumnBackgroundColor,
          padding: "10px",
          borderRadius: "5px",
          width: "220px",
          minHeight: "60px",
          cursor: leftColumnCursor,
          transition: "all .25s",
          transform: status === "IS_SELECTED" ? "scale(1.1)" : "none",
          borderStyle: "dashed",
          borderColor: leftColumnBorderColor,
          borderWidth: "2px",
          position: "relative",
        }}
      >
        <span
          style={{
            fontSize: "22px",
            lineHeight: "26px",
            color: leftColumnTextColor,
            textAlign: "center",
          }}
        >
          {text}
        </span>
      </div>
    </div>
  );
}

MeaningsAssociatingVocabularyOption.propTypes = {
  itemId: PropTypes.string.isRequired,
};
