import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import StudyQualityStatsModal from "./StudyQualityStatsModal";
import { CLOSE_STUDY_QUALITY_DETAILS_MODAL_FLOW, SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW } from "../../consts";

const StudyQualityStatsModalContainer = () => (
  <Flow autoStart={false} id={SHOW_STUDY_QUALITY_DETAILS_MODAL_FLOW}>
    {({ isPending }) =>
      isPending && (
        <Flow autoStart={false} id={CLOSE_STUDY_QUALITY_DETAILS_MODAL_FLOW}>
          {({ handleStart }) => <StudyQualityStatsModal onClose={handleStart} />}
        </Flow>
      )
    }
  </Flow>
);

export default StudyQualityStatsModalContainer;
