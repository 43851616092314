import { ADD_ENTITY, MERGE_ENTITIES, CLEAN_ENTITIES, REPLACE_ENTITIES, REMOVE_ENTITY } from "../actions/entity";
import { get, uniq } from "lodash";

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_ENTITY:
      return {
        ...state,
        [action.payload.entityName]: {
          byId: {
            ...get(state, `${action.payload.entityName}.byId`, {}),
            [action.payload.entity.id]: action.payload.entity,
          },
          allIds: uniq([...get(state, `${action.payload.entityName}.allIds`, []), action.payload.entity.id]),
        },
      };
    case REMOVE_ENTITY:
      const byId = get(state, `${action.payload.entityName}.byId`, {});
      delete byId[action.payload.entityId];
      return {
        ...state,
        [action.payload.entityName]: {
          byId: {
            ...byId,
          },
          allIds: uniq([
            ...get(state, `${action.payload.entityName}.allIds`, []).filter((id) => action.payload.entityId !== id),
          ]),
        },
      };
    case REPLACE_ENTITIES:
      return {
        ...state,
        ...Object.keys(action.payload.entities)
          .map((entityName) => ({
            [entityName]: {
              byId: {
                ...action.payload.entities[entityName],
              },
              allIds: uniq([...Object.keys(action.payload.entities[entityName])]),
            },
          }))
          .reduce(
            (acc, entityState) => ({
              ...acc,
              ...entityState,
            }),
            {}
          ),
      };
    case MERGE_ENTITIES:
      return {
        ...state,
        ...Object.keys(action.payload.entities)
          .map((entityName) => ({
            [entityName]: {
              byId: {
                ...get(state, `${entityName}.byId`, {}),
                ...action.payload.entities[entityName],
              },
              allIds: uniq([
                ...get(state, `${entityName}.allIds`, []),
                ...Object.keys(action.payload.entities[entityName]),
              ]),
            },
          }))
          .reduce(
            (acc, entityState) => ({
              ...acc,
              ...entityState,
            }),
            {}
          ),
      };
    case CLEAN_ENTITIES:
      return {};
    default:
      return state;
  }
};
