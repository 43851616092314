import { CLEAN_MASTERY_TEST_RESULT, MERGE_MASTERY_TEST_RESULT } from "../consts/actions";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGE_MASTERY_TEST_RESULT: {
      return {
        ...state,
        ...action.payload.masteryTestResult,
      };
    }
    case CLEAN_MASTERY_TEST_RESULT: {
      return {};
    }
    default:
      return state;
  }
};
