import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

class Entity extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    entity: PropTypes.shape({
      byId: PropTypes.object,
      allIds: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    entity: {},
  };

  render() {
    const { byId, allIds } = this.props.entity;
    return this.props.children({
      getIds: () => allIds || [],
      getById: (id) => get(byId, id, {}),
      getAll: () => (allIds || []).map((id) => byId[id]),
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  entity: state.entities[ownProps.name],
});

export default connect(mapStateToProps)(Entity);
