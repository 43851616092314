import React, { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { animated } from "react-spring";
import Lottie from "react-lottie";
import MediaQuery from "react-responsive";
import { isNil } from "lodash";
import { useEntity } from "student-front-commons/src/hooks";
import Sr95Animation from "../../lotties/sr-95-animation";
import Sr100Animation from "../../lotties/sr-100-animation";
import Sr80Audio from "../../audios/sr-80.mp3";
import Sr90Audio from "../../audios/sr-90.mp3";
import Sr95Audio from "../../audios/sr-95.mp3";
import Sr100Audio from "../../audios/sr-100.mp3";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import SpeechRecognitionScore from "./SpeechRecognitionScore";
import {
  isInstructionEnableForStep,
  isInstructionHighlighted,
} from "student-front-commons/src/selectors/itemInstruction";
import SpeechRecognitionResultWordList from "./SpeechRecognitionResultWordList";

export default function SpeechRecognitionResult() {
  const { idModule } = useParams();
  const itemType = useSelector(getItemsExecutionsType);
  const sr95AnimationRef = useRef();
  const sr100AnimationRef = useRef();

  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const speechRecognitionFailCount = useSelector(getCurrentItemExecutionProp("speechRecognitionFailCount"));
  const qualityScore = useSelector(getCurrentItemExecutionProp("speechRecognitionResult.qualityScore"));

  const isInstructionEnabled = useSelector(isInstructionEnableForStep("speech-recognition-result"));
  const shouldHighlight = useSelector(isInstructionHighlighted);

  const module = useEntity("module", idModule);
  const course = useEntity("course", module?.course);

  const isKeepResultOnScreen = useMemo(() => {
    return !!course && !!qualityScore && ["C1", "C2"].includes(course.name);
  }, [course, qualityScore]);

  useEffect(() => {
    if (!isRecording && !isSubmittingRecord) {
      setTimeout(() => {
        if (qualityScore >= 95 && qualityScore < 100) {
          sr95AnimationRef.current?.play();
        }
        if (qualityScore >= 100) {
          sr100AnimationRef.current?.play();
        }
      }, 500);
    }
  }, [qualityScore, isRecording, isSubmittingRecord]);

  return ["PRESENTATION", "PRONUNCIATION", "SPEECH_PRACTICE"].some((type) => type === itemType) ? (
    <React.Fragment>
      <Lottie
        ref={sr95AnimationRef}
        isClickToPauseDisabled={true}
        isStopped={true}
        options={{
          loop: true,
          autoplay: false,
          animationData: Sr95Animation,
        }}
        style={{
          position: "absolute",
          bottom: 225,
          width: 190,
          height: 190,
          alignSelf: "center",
        }}
      />
      <Lottie
        ref={sr100AnimationRef}
        isClickToPauseDisabled={true}
        isStopped={true}
        ariaRole="span"
        options={{
          animationData: Sr100Animation,
          loop: true,
          autoplay: false,
        }}
        style={{
          position: "absolute",
          bottom: 220,
          width: 200,
          height: "auto",
          alignSelf: "center",
        }}
      />
      <MediaQuery maxWidth={523}>
        {(matches) => (
          <animated.div
            style={{
              left: 0,
              width: "100%",
              display: "flex",
              position: "fixed",
              justifyContent: "center",
              alignItems: "center",
              userSelect: "none",
              pointerEvents: isInstructionEnabled && shouldHighlight ? "none" : "all",
              zIndex: matches ? 8 : 10,
              transition: "opacity .3s ease, bottom .3s ease",
              opacity:
                isKeepResultOnScreen ||
                (!isRecording && !isSubmittingRecord && !speechRecognitionFailCount && !isNil(qualityScore)) ||
                !!speechRecognitionFailCount
                  ? 1
                  : 0,
              bottom:
                isKeepResultOnScreen ||
                (!isRecording && !isSubmittingRecord && !speechRecognitionFailCount && !isNil(qualityScore)) ||
                !!speechRecognitionFailCount
                  ? 220
                  : -100,
            }}
            className={
              isInstructionEnabled
                ? shouldHighlight
                  ? "animate__animated animate__bounce animate__infinite"
                  : "animate__animated animate__pulse animate__infinite"
                : null
            }
          >
            {qualityScore >= 80 && qualityScore < 90 && <audio src={Sr80Audio} autoPlay={true} />}
            {qualityScore >= 90 && qualityScore < 95 && <audio src={Sr90Audio} autoPlay={true} />}
            {qualityScore >= 95 && qualityScore < 100 && <audio src={Sr95Audio} autoPlay={true} />}
            {qualityScore >= 100 && <audio src={Sr100Audio} autoPlay={true} />}
            {(isKeepResultOnScreen || qualityScore > 30) && <SpeechRecognitionScore score={qualityScore} />}
            {(isKeepResultOnScreen || qualityScore > 30) && <SpeechRecognitionResultWordList />}
          </animated.div>
        )}
      </MediaQuery>
    </React.Fragment>
  ) : null;
}
