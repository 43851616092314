import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import Flow from "student-front-commons/src/components/Flow";
import Row from "../../core/Row";
import Column from "../../core/Column";
import BackgroundedIcon from "../../core/BackgroundedIcon";
import CircularButton from "../../core/CircularButton";
import Tooltip from "../../core/Tooltip";
import IconButton from "../../core/IconButton";
import CourseThemeContext from "../../core/CourseThemeContext";
import { GREY_3, WHITE } from "../../consts/color";
import {
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  START_ITEM_INTRODUCTION_FLOW,
} from "../../consts";
import withTranslation from "../../core/withTranslation";
import TranslatedText from "../../core/TranslatedText";
import MediaQuery from "react-responsive";
import { useEntity, useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { isAnyItemExecutionValidated } from "student-front-commons/src/selectors/itemExecution";

function UnitSubHeader(props) {
  const params = useParams();
  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();
  const courseThemeContext = useContext(CourseThemeContext);

  const [isChecking] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);

  const unit = useEntity("unit", params.idUnit);
  const unitType = useEntity("unitType", unit?.type);
  const profile = useEntity("profile", sessionStorage.getItem("id"));

  const isExecutionValidated = useSelector(isAnyItemExecutionValidated);

  const isTasting = match.path.indexOf("tasting") >= 0;

  return (
    <MediaQuery maxWidth={480}>
      {(matches) => (
        <div
          style={{
            height: matches ? 60 : 80,
            overflow: "hidden",
            position: "relative",
            boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
            ...(!!profile.isSpeechRecognitionDisabled
              ? {
                  backgroundColor: courseThemeContext.primary,
                }
              : {
                  backgroundImage: `linear-gradient(to right, ${courseThemeContext.primary} 50%, ${
                    matches ? courseThemeContext.primary : "#FFFFFF"
                  } 51%)`,
                }),
            zIndex: 2,
          }}
        >
          <div className="container">
            <Row>
              <Column lgSize={12} style={{ position: "relative" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: matches ? "center" : "flex-start",
                    height: matches ? 60 : 80,
                    backgroundColor: courseThemeContext.primary,
                  }}
                >
                  {!isTasting && (
                    <CircularButton
                      onClick={() => {
                        history.replace(
                          location.state?.fromContentVideo
                            ? `/course/${params.idCourse}/videos`
                            : `/course/${params.idCourse}/modules/${params.idModule}/units`
                        );
                      }}
                      color={courseThemeContext.primary}
                      icon="arrow-left"
                      border={`2px solid ${courseThemeContext.secondary}`}
                    />
                  )}
                  <h1
                    style={{
                      margin: 0,
                      marginLeft: isTasting ? 0 : 30,
                      color: WHITE,
                      fontSize: matches ? 22 : 32,
                      fontWeight: 700,
                    }}
                  >
                    {unit?.name}
                  </h1>
                </div>
                {!profile.isSpeechRecognitionDisabled && (
                  <MediaQuery minWidth={481}>
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    >
                      <div
                        className="hidden-xs"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: GREY_3,
                          height: 190,
                          width: 210,
                          border: "solid 5px #ffffff",
                          boxShadow: "0px 3px 5px 0 rgba(33, 46, 130, 0.2)",
                          position: "absolute",
                          top: "-60px",
                          right: 105,
                          borderRadius: "100%",
                          textAlign: "center",
                          boxSizing: "border-box",
                          padding: "45px 40px 40px 5px",
                        }}
                      >
                        {unitType?.abilities?.map((ability) => (
                          <div
                            key={`ability-icon-${ability}`}
                            style={{
                              marginRight: 5,
                            }}
                          >
                            <Tooltip text={props.getTranslation(ability.toLowerCase())} position="bottom">
                              <BackgroundedIcon
                                backgroundColor={courseThemeContext.primary}
                                icon={
                                  {
                                    READING: "book-open-variant",
                                    SPEAKING: "forum",
                                    LISTENING: "ear-hearing",
                                    WRITING: "lead-pencil",
                                  }[ability]
                                }
                              />
                            </Tooltip>
                          </div>
                        ))}
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: courseThemeContext.secondary,
                          height: 147,
                          width: 147,
                          border: "solid 5px #ffffff",
                          boxShadow: "-4px 3px 6px -2px rgba(33, 46, 130, 0.2)",
                          position: "absolute",
                          top: "-35px",
                          right: 0,
                          borderRadius: "100%",
                          textAlign: "center",
                        }}
                      >
                        <Flow id={START_ITEM_INTRODUCTION_FLOW}>
                          {({ isPending, handleStart }) => (
                            <React.Fragment>
                              <TranslatedText
                                as="label"
                                translateKey="needHelp"
                                style={{
                                  color: WHITE,
                                  marginBottom: -8,
                                  fontSize: 12,
                                }}
                              />
                              <IconButton
                                color="white"
                                icon="help"
                                disabled={isPending || isChecking || isSaving || isExecutionValidated}
                                onClick={handleStart}
                              />
                            </React.Fragment>
                          )}
                        </Flow>
                      </div>
                    </div>
                  </MediaQuery>
                )}
              </Column>
            </Row>
          </div>
        </div>
      )}
    </MediaQuery>
  );
}

UnitSubHeader.propTypes = {
  isTasting: PropTypes.bool,
};

UnitSubHeader.defaultProps = {
  isTasting: false,
};

export default withTranslation(UnitSubHeader);
