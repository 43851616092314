import React from "react";
import Panel from "../../core/Panel";
import TranslatedText from "../../core/TranslatedText";
import AchievementListPanelContainer from "./AchievementListPanelContainer";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../consts/color";

const AchievementPanel = () => (
  <div className="stepAchievements">
    <WhitelabelContext.Consumer>
      {({ secondaryColor }) => (
        <Panel padding="none">
          <div
            style={{
              height: 40,
              backgroundColor: secondaryColor,
              boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              padding: "0px 15px",
              position: "relative",
              zIndex: 2,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            }}
          >
            <TranslatedText
              as="label"
              translateKey="profile.achievement.title"
              style={{
                textTransform: "uppercase",
                color: WHITE,
                fontSize: 14,
                fontWeight: 700,
                margin: 0,
              }}
            />
          </div>
          <AchievementListPanelContainer />
        </Panel>
      )}
    </WhitelabelContext.Consumer>
  </div>
);

export default AchievementPanel;
