import React, { useState, useEffect } from "react";
import AsyncPanel from "../../core/AsyncPanel";
import Separator from "../../core/Separator";
import withTranslation from "../../core/withTranslation";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import zoomMeetingCameraIcon from "../../images/zoom-meeting-camera.svg";
import googleMeetingCameraIcon from "../../images/google-meeting-camera.svg";
import nextEventCardTopBackground from "../../images/next-event-card-top-background.svg";
import Icon from "../../core/Icon";
import moment from "moment";
import styled from "styled-components";
import Button from "../../core/Button";

const JoinMeetingButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 40px;
  width: 100%;
  font-size: 13px;
  font-weight: bold;
  margin-top: 15px;
`;

const NextEventCardTopBackgroundImage = styled.img`
  position: absolute;
  top: -70px;
  left: 0;
  z-index: 0;
  width: 100%;
`;

const MeetingCameraImage = styled.img`
  width: 20px;
  height: 20px;
`;

const CalendarIcon = styled(Icon)`
  display: flex;
  justify-content: center;
`;

const NextMeetingPanel = (props) => {
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (moment(props.nextEvent?.occurrences?.from).subtract(3, "minutes").isBefore(moment())) {
      setIsDisable(false);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (moment(props.nextEvent?.occurrences?.from).subtract(3, "minutes").isBefore(moment())) {
        setIsDisable(false);
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <AsyncPanel
      title={props.getTranslation("meeting.title")}
      padding="none"
      fetching={props.fetching}
      hasData={!props.data}
      noDataText={props.getTranslation("sprint.noData")}
    >
      <NextEventCardTopBackgroundImage src={nextEventCardTopBackground} />
      <FlexColumn padding="15px 20px" width={200}>
        <FlexRow
          backgroundColor="#00ADA4"
          width="40px"
          height="40px"
          borderRadius="40px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin="0"
          zIndex={1}
        >
          <CalendarIcon icon="calendar-check-outline" color="#fff" display="flex" size="sm" />
        </FlexRow>
        <Separator size="sm" />
        <FlexColumn borderLeft="2px solid #00ADA4" justifyContent="center" padding="5px 0px 5px 10px">
          <FlexRow alignItems="center" fontWeight="bold" textTransform="uppercase" fontSize="18px">
            {moment(props.nextEvent?.occurrences?.from).format("DD/MM")}
            <small>&nbsp; &#x2022; &nbsp;</small>
            {moment(props.nextEvent?.occurrences?.from).format("HH:mm")}
          </FlexRow>
          <FlexRow alignItems="center" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" fontSize={12}>
            Teacher: {props.nextEvent?.teacher?.name}
          </FlexRow>
          {props.nextEvent.meetingJoinUrl && (
            <JoinMeetingButton
              onClick={() => window.open(props.nextEvent.meetingJoinUrl, "_blank")}
              disabled={isDisable}
            >
              <MeetingCameraImage
                src={{ google: googleMeetingCameraIcon, zoom: zoomMeetingCameraIcon }[props.nextEvent.provider]}
                alt="Meeting camera icon"
              />
              {props.getTranslation("meeting.button.join")}
            </JoinMeetingButton>
          )}
        </FlexColumn>
      </FlexColumn>
    </AsyncPanel>
  );
};

export default withTranslation(NextMeetingPanel);
