import React, { useCallback } from "react";
import FlexRow from "../../core/FlexRow";
import Icon from "../../core/Icon";
import PropTypes from "prop-types";
import Styled from "styled-components";

const LinkButton = Styled.button`
  font-size: 12px;
  font-weight: 600;
  outline: none;
  background-color: transparent;
  border: none;
  color: inherit;
  paddingLeft: 0;
  textAlign: left;              
  :hover {
    text-decoration: underline;
  }
`;

const MasteryTestListItemRecommendedUnitsListItem = ({ unit, completed, onClick }) => {
  const handleUnitClick = useCallback(() => onClick(unit.id), [unit, onClick]);
  return (
    <FlexRow
      key={unit.id}
      borderRadius={8}
      backgroundColor="white"
      padding="10px 20px"
      border={completed ? "2px solid #00ADA4" : undefined}
    >
      <FlexRow fontSize={14} flexBasis="90%" alignItems="center">
        {unit.name}
      </FlexRow>
      <FlexRow flexBasis="20%" justifyContent="center">
        {completed && (
          <FlexRow height={16} alignItems="center">
            <Icon color="#00ADA4" icon="check" size="sm" />
          </FlexRow>
        )}
        {!completed && <LinkButton onClick={handleUnitClick}>START</LinkButton>}
      </FlexRow>
    </FlexRow>
  );
};

MasteryTestListItemRecommendedUnitsListItem.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  completed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MasteryTestListItemRecommendedUnitsListItem;
