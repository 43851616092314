import { get, head } from "lodash";
import { call, cancelled, delay, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart, getFlowEnd, getFlow } from "student-front-commons/src/selectors/flow";
import { logError, getItemTypeInstructionAudio } from "../util";
import { insertAudio, playAudio, stopAudio } from "../stores/audio-store";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  END_RECORD_FLOW,
  GET_NEXT_UNIT_ITEM_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  PLAY_ITEM_AUDIO_STRIPE_FLOW,
  PLAY_ITEM_VIDEO_FLOW,
  PLAY_RECORD_AUDIO_FLOW,
  SELECT_ITEM_FLOW,
  START_ITEM_INTRODUCTION_FLOW,
  START_RECORD_FLOW,
  UPDATE_STUDENT_PAGE_ACCESSED_FLOW,
} from "../consts";
import {
  cleanItemExecutionForInstruction,
  disableItemExecution,
  enableItemExecution,
  READ_ITEM,
  TRANSLATE_ITEM,
} from "student-front-commons/src/actions/itemExecution";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import {
  finishItemInstruction,
  highlightItemInstruction,
  startItemInstruction,
  unhighlightItemInstruction,
} from "student-front-commons/src/actions/itemInstruction";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { awaitHideAction } from "student-front-commons/src/selectors/systemMessage";
import LogRocket from "logrocket";

const typesToDontCleanExecution = [
  "MEANINGS_ASSOCIATING",
  "GRAMMAR_CHECK",
  "VOCABULARY_ACADEMIC",
  "UNSCRAMBLE_TEXT",
  "MULTIPLE_CHOICE_TEXT",
  "DICTATION",
];
const typesToIgnoreUserAction = ["AUDIO_LONG", "VIDEO_LONG"];
const typesToSelectItem = ["VOCABULARY_ACADEMIC"];

export default function* () {
  yield takeLatest(getFlowStart(START_ITEM_INTRODUCTION_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      cancelByNext: take(getFlowStart(GET_NEXT_UNIT_ITEM_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          let itemId = yield select(getCurrentItemExecutionProp("item.id"));
          const itemIds = yield select((state) => state.itemExecutions.allIds);
          const firstItemId = itemId ? itemId : head(itemIds);
          const itemType = yield select(getItemExecutionPropById(firstItemId, "item.type"));

          if (!itemType) {
            return;
          }

          const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));

          const introUrl = getItemTypeInstructionAudio(itemType, "INITIAL", profile.locale);
          const repeatUrl = getItemTypeInstructionAudio(itemType, "REPEAT", profile.locale);
          const recordUrl = getItemTypeInstructionAudio(itemType, "RECORD", profile.locale);
          const srResultUrl = getItemTypeInstructionAudio(itemType, "SPEECH_RECOGNITION_RESULT", profile.locale);
          const listenUrl = getItemTypeInstructionAudio(itemType, "LISTEN", profile.locale);
          const readUrl = getItemTypeInstructionAudio(itemType, "READ", profile.locale);
          const translateUrl = getItemTypeInstructionAudio(itemType, "TRANSLATE", profile.locale);
          const nextUrl = getItemTypeInstructionAudio(itemType, "NEXT", profile.locale);

          if (introUrl) {
            yield call(insertAudio, { url: introUrl });
          }
          if (recordUrl) {
            yield call(insertAudio, { url: recordUrl });
          }
          yield spawn(function* () {
            if (repeatUrl) {
              yield call(insertAudio, { url: repeatUrl });
            }
            if (srResultUrl) {
              yield call(insertAudio, { url: srResultUrl });
            }
            if (listenUrl) {
              yield call(insertAudio, { url: listenUrl });
            }
            if (readUrl) {
              yield call(insertAudio, { url: readUrl });
            }
            if (translateUrl) {
              yield call(insertAudio, { url: translateUrl });
            }
            if (nextUrl) {
              yield call(insertAudio, { url: nextUrl });
            }
          });

          //clean the form to avoid logic problems
          if (!typesToDontCleanExecution.some((type) => type === itemType.key)) {
            yield put(cleanItemExecutionForInstruction(itemId));
          }

          let mediaFlow = PLAY_ITEM_AUDIO_FLOW;
          if (["VIDEO", "VIDEO_LONG"].some((type) => type === itemType.key)) {
            mediaFlow = PLAY_ITEM_VIDEO_FLOW;
          }
          const flow = yield select(getFlow(mediaFlow));
          if (flow && flow.isPending) {
            yield put(endFlow(mediaFlow));
          }

          if (introUrl) {
            yield put(startItemInstruction("initial"));
            yield call(playAudio, {
              url: introUrl,
            });
            yield delay(500);
          }

          if (!itemId && typesToSelectItem.some((type) => type === itemType.key)) {
            yield put(startItemInstruction("select"));
            yield put(highlightItemInstruction());
            yield take(getFlowEnd(SELECT_ITEM_FLOW));
            yield put(unhighlightItemInstruction());
          } else {
            yield put(startFlow(mediaFlow, { initialPlay: true }));
          }
          yield take(getFlowEnd(mediaFlow));

          itemId = yield select(getCurrentItemExecutionProp("item.id"));
          if (itemId) {
            if (repeatUrl) {
              yield put(startItemInstruction("repeat"));
              yield put(highlightItemInstruction());
              yield call(playAudio, {
                url: repeatUrl,
              });
              yield put(unhighlightItemInstruction());

              if (typesToIgnoreUserAction.some((type) => type === itemType.key)) {
                yield put(finishItemInstruction());
              } else {
                yield take(getFlowStart(mediaFlow));
                yield take(getFlowEnd(mediaFlow));
              }

              yield delay(500);
            }

            if (recordUrl) {
              yield put(startItemInstruction("record"));
              yield put(highlightItemInstruction());
              yield call(playAudio, {
                url: recordUrl,
              });
              yield put(unhighlightItemInstruction());
              while (true) {
                yield take(getFlowStart(START_RECORD_FLOW));
                yield take(getFlowEnd(END_RECORD_FLOW));

                const answer = yield select(getItemExecutionPropById(itemId, "answer"));
                if (
                  ["VIDEO_SHORT", "PRESENTATION", "SPEECH_PRACTICE", "PRONUNCIATION", "VOCABULARY_ACADEMIC"].some(
                    (type) => type === itemType.key
                  ) &&
                  answer > 30
                ) {
                  break;
                }
                if (
                  ["GAP_FILL", "UNSCRAMBLE_SPEECH_RECOGNITION", "DIALOGUE_OPTION"].some(
                    (type) => type === itemType.key
                  ) &&
                  get(answer, "length", 0)
                ) {
                  break;
                }
              }
              yield delay(500);
            }

            if (profile.id !== "tasting_user") {
              if (srResultUrl) {
                const oldRepeatCount = yield select(getItemExecutionPropById(itemId, "repeatCount"));
                const oldListenCount = yield select(getItemExecutionPropById(itemId, "listenCount"));
                yield put(startItemInstruction("speech-recognition-result"));
                yield put(highlightItemInstruction());
                yield call(playAudio, {
                  url: srResultUrl,
                });
                yield put(unhighlightItemInstruction());
                while (true) {
                  yield take(getFlowStart(PLAY_ITEM_AUDIO_STRIPE_FLOW));
                  yield take(getFlowEnd(PLAY_ITEM_AUDIO_STRIPE_FLOW));

                  const newRepeatCount = yield select(getItemExecutionPropById(itemId, "repeatCount"));
                  const newListenCount = yield select(getItemExecutionPropById(itemId, "listenCount"));
                  if (newRepeatCount > oldRepeatCount && newListenCount > oldListenCount) {
                    break;
                  }
                }
                yield delay(500);
              }
            }

            if (listenUrl) {
              yield put(startItemInstruction("listen"));
              yield put(highlightItemInstruction());
              yield call(playAudio, {
                url: listenUrl,
              });
              yield put(unhighlightItemInstruction());
              yield take(getFlowStart(PLAY_RECORD_AUDIO_FLOW));
              yield take(getFlowEnd(PLAY_RECORD_AUDIO_FLOW));
              yield delay(500);
            }

            if (profile.id !== "tasting_user") {
              if (readUrl) {
                yield put(startItemInstruction("reading"));
                yield put(highlightItemInstruction());
                yield call(playAudio, {
                  url: readUrl,
                });
                yield put(unhighlightItemInstruction());
                yield take((action) => action.type === READ_ITEM);
              }

              if (translateUrl) {
                yield put(startItemInstruction("translate"));
                yield put(highlightItemInstruction());
                yield call(playAudio, {
                  url: translateUrl,
                });
                yield put(unhighlightItemInstruction());
                yield take((action) => action.type === TRANSLATE_ITEM);
              }
            }
          }

          if (nextUrl) {
            yield put(startItemInstruction("next"));
            yield put(highlightItemInstruction());
            yield call(playAudio, {
              url: nextUrl,
            });
            yield put(unhighlightItemInstruction());
          }

          yield put(
            startFlow(UPDATE_STUDENT_PAGE_ACCESSED_FLOW, {
              screen: itemType.key,
            })
          );
          yield take(getFlowEnd(UPDATE_STUDENT_PAGE_ACCESSED_FLOW));
        } catch (error) {
          LogRocket.log({ flow: START_ITEM_INTRODUCTION_FLOW, error });
          if (
            error === "required-user-click" ||
            (error.indexOf && error.indexOf("Playback was unable to start") > -1)
          ) {
            stopAudio();
            yield spawn(function* () {
              yield put(
                showMessage({
                  icon: "play-circle-outline",
                  message: "error.error_browser_block_intro_autoplay",
                  button: "startIntroductionButton",
                })
              );
              yield take(awaitHideAction);
              yield put(startFlow(START_ITEM_INTRODUCTION_FLOW));
            });
          } else {
            logError({ error, flow: START_ITEM_INTRODUCTION_FLOW });
          }
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(finishItemInstruction());
          yield put(enableItemExecution());
          yield put(endFlow(START_ITEM_INTRODUCTION_FLOW));
        }
      }),
    });
  });
}
