import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { css, keyframes } from "styled-components";
import Icon from "../Icon";
import { GREY_3, GREY_5 } from "../../consts/color";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getAudio } from "../../stores/audio-store";
import { Howl } from "howler";

const animationFrames = keyframes`
0%{
  box-shadow: 0px 0px 5px 0px rgba(96, 125, 139, 0.3);
}
65%{
  box-shadow: 0px 0px 5px 13px rgba(96, 125, 139, 0.3);
}
90%{
  box-shadow: 0px 0px 5px 13px rgba(96, 125, 139, 0.2);
}
`;

const animation = () => css`
  ${animationFrames} 1.25s linear infinite;
`;

function SpeechRecognitionResultHistoryItem(props) {
  const [isHover, setIsHover] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));

  const handleMouseEnter = useCallback(() => {
    if (isDisabled) {
      return;
    }
    setIsHover(true);
  }, [isDisabled]);

  const handleMouseLeave = useCallback(() => {
    setIsHover(false);
  }, []);

  const handlePlay = useCallback(() => {
    const audioData = getAudio({ url: props.recordUrl });

    const sound = new Howl({
      src: [audioData],
      autoplay: true,
      loop: false,
      html5: true,
      onend: () => {
        setIsPlaying(false);
        sound.unload();
      },
      onplay: () => setIsPlaying(true),
      onloaderror: (id, error) => {
        sound.unload();
      },
    });
  }, [props.recordUrl]);

  return (
    <button
      onClick={handlePlay}
      disabled={isDisabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        ...(isPlaying && {
          animation: animation,
        }),
        backgroundColor: isDisabled ? GREY_3 : GREY_5,
        boxSizing: "border-box",
        border: `3px solid ${GREY_3}`,
        borderRadius: "50%",
        display: "flex",
        cursor: "pointer",
        justifyContent: "center",
        padding: "5px 0px",
        overflow: "hidden",
        transition: "all .5s",
        fontSize: 22,
        fontWeight: 400,
        width: 50,
        height: 50,
        marginRight: 10,
        marginBottom: 10,
        color: "rgba(255, 255, 255, 0.8)",
        outline: "none",
      }}
    >
      {!isDisabled && isHover ? <Icon icon="play" cursor="pointer" color="rgba(255, 255, 255, 0.8)" /> : props.score}
    </button>
  );
}

SpeechRecognitionResultHistoryItem.propTypes = {
  score: PropTypes.number.isRequired,
  recordUrl: PropTypes.string.isRequired,
};

export default SpeechRecognitionResultHistoryItem;
