import React from "react";
import PropTypes from "prop-types";
import CircularButton from "../CircularButton";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { sample, get } from "lodash";
import translations from "../../locales";
import QuestionLabel from "../QuestionLabel";
import PopoverButton from "../PopoverButton";
import { WHITE } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "RepeatButton" };

class RepeatButton extends React.PureComponent {
  state = {
    message: null,
  };

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    showNoUsageMessage: PropTypes.bool.isRequired,
    showTipMessage: PropTypes.bool.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showNoUsageMessage && !prevProps.showNoUsageMessage) {
      this.setState({
        message: sample(get(translations, "unitExecutionNotification.NO_REPEAT_SEQUENCE", [])),
      });
    } else if (this.props.showTipMessage && !prevProps.showTipMessage) {
      this.setState({
        message: sample(get(translations, "unitExecutionNotification.REPEAT_USAGE_TIP", [])),
      });
    } else if (!this.props.showTipMessage && !this.props.showNoUsageMessage && !!prevState.message) {
      this.setState({
        message: null,
      });
    }
  }

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <MediaQuery maxWidth={612}>
            {(popoverMatches) => (
              <PopoverButton
                isOpen={!!this.state.message}
                position="left-corner"
                backgroundColor={primaryColor}
                maxWidth={popoverMatches ? window.screen.availWidth * 0.6 : 500}
                padding={7}
                content={
                  <QuestionLabel fontSize={12} color={WHITE} cursor="auto">
                    {this.state.message}
                  </QuestionLabel>
                }
              >
                <MediaQuery maxWidth={540}>
                  {(matches) => (
                    <CircularButton
                      color={primaryColor}
                      icon="repeat"
                      size={matches ? "md" : "lg"}
                      onClick={() => {
                        this.props.onClick();
                        addSentryUserAction({
                          ...sentryUserAction,
                          clickedComponent: "CircularButton",
                          action: `Repeat Unit Item`,
                        });
                      }}
                      disabled={this.props.isDisabled}
                    />
                  )}
                </MediaQuery>
              </PopoverButton>
            )}
          </MediaQuery>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default RepeatButton;
