import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { animated, Transition } from "react-spring";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import CourseThemeContext from "../CourseThemeContext";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG, GREY_3, GREY_4, GREY_5 } from "../../consts/color";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { last } from "lodash";
import { useFlow } from "student-front-commons/src/hooks";
import { SELECT_ITEM_FLOW } from "../../consts";
import CircularButton from "../CircularButton";
import FlexColumn from "../FlexColumn";
import MeaningsAssociatingVocabularyAnswer from "./MeaningsAssociatingVocabularyAnswer";

export default function MeaningsAssociatingDefinition({ itemId, onTranslationOpen, isTranslationOpen, translation }) {
  const dispatch = useDispatch();

  const [status, setStatus] = useState("DEFAULT");

  const courseThemeContext = useContext(CourseThemeContext);
  const [, startSelectItemFlow] = useFlow(SELECT_ITEM_FLOW);
  const postPhrase = useSelector(getItemExecutionPropById(itemId, "item.postPhrase"));
  const answer = useSelector(getItemExecutionPropById(itemId, "answer"));
  const selectedId = useSelector((state) => state.itemExecutions?.selectedId);
  const playingId = useSelector(getItemExecutionPropById(itemId, "playingId"));
  const isFinished = useSelector(getItemExecutionPropById(itemId, "isFinished"));
  const attempts = useSelector(getItemExecutionPropById(itemId, "attempts"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(itemId, "showCorrectOption"));
  const isExecutionValidated = useSelector(getItemExecutionPropById(itemId, "isExecutionValidated"));

  const feedbackColor = { CORRECT: FEEDBACK_CORRECT, WRONG: FEEDBACK_WRONG }[
    last(attempts)?.correct ? "CORRECT" : "WRONG"
  ];

  const borderColor = {
    DEFAULT: "#ffffff",
    FEEDBACK: feedbackColor,
  }[status];

  useEffect(() => {
    setStatus("DEFAULT");
  }, []);

  useEffect(() => {
    if (isFinished && !last(attempts)?.correct) {
      setStatus("DEFAULT");
    }
  }, [isFinished]);

  useEffect(() => {
    if (shouldShowCorrectOption) {
      setStatus("FEEDBACK");
    }
  }, [shouldShowCorrectOption]);

  const handleClick = () => {
    dispatch(addItemExecutionAnswer(itemId, { answer: selectedId }));
    startSelectItemFlow({ itemId: null });
  };

  return (
    <div>
      <Transition
        items={isTranslationOpen}
        from={{ height: 0, opacity: 0 }}
        enter={{ height: "auto", opacity: 1 }}
        leave={{ height: 0, opacity: 0 }}
      >
        {(show) =>
          show &&
          ((styles) => (
            <animated.div
              className="translation"
              style={{
                ...styles,
                overflow: "hidden",
                transition: "height 0.25s ease-out, opacity 0.25s ease-out",
              }}
            >
              <FlexColumn
                height="auto"
                width="100%"
                backgroundColor={GREY_3}
                overflow="hidden"
                transition="all .25s"
                borderTopLeftRadius={5}
                borderTopRightRadius={5}
                padding="15px 20px"
              >
                {translation}
              </FlexColumn>
            </animated.div>
          ))
        }
      </Transition>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "5px",
          borderTopLeftRadius: isTranslationOpen ? 0 : "5px",
          padding: "20px",
          height: isExecutionValidated && !last(attempts)?.correct ? "150px" : "100px",
          width: "815px",
          marginBottom: "18px",
          position: "relative",
          border: `1px solid ${borderColor}`,
          transition: "all .5s",
          transform: playingId === itemId ? "scale(1.1)" : "none",
        }}
      >
        {answer ? (
          <MeaningsAssociatingVocabularyAnswer answerId={answer} itemId={itemId} />
        ) : (
          <div
            onClick={handleClick}
            style={{
              borderRadius: "7px",
              borderStyle: "dashed",
              borderColor: "#bbbbbb",
              borderWidth: "2px",
              transform: "none",
              cursor: "pointer",
              transition: "all .25s",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: selectedId && !answer ? "#ffffff" : GREY_4,
                padding: "10px",
                borderRadius: "3px",
                width: "216px",
                minHeight: "56px",
                cursor: selectedId && !answer ? "pointer" : "not-allowed",
                transition: "all .25s",
              }}
            >
              <span
                style={{
                  fontSize: "22px",
                  lineHeight: "26px",
                  color: courseThemeContext.primary,
                  textAlign: "center",
                }}
              >
                {""}
              </span>
            </div>
          </div>
        )}
        <p
          style={{
            width: "510px",
            color: courseThemeContext.primary,
            fontSize: "18px",
            lineHeight: "23px",
            margin: 0,
          }}
        >
          {postPhrase}
        </p>
        <div
          style={{
            position: "absolute",
            right: "-12px",
            left: "auto",
            top: "-8px",
          }}
        >
          <CircularButton
            disabled={!isExecutionValidated}
            size="xs"
            icon="crop"
            color={GREY_5}
            onClick={onTranslationOpen}
            style={{ zIndex: 1 }}
          />
        </div>
      </div>
    </div>
  );
}

MeaningsAssociatingDefinition.propTypes = {
  itemId: PropTypes.string.isRequired,
  translation: PropTypes.string.isRequired,
  onTranslationOpen: PropTypes.func.isRequired,
  isTranslationOpen: PropTypes.bool.isRequired,
};
