import React from "react";
import PropTypes from "prop-types";
import FlexColumn from "../FlexColumn";

const ExerciseItemPanel = ({ children, ...rest }) => (
  <FlexColumn maxWidth={690} margin="0 auto" width="100%" {...rest}>
    {children}
  </FlexColumn>
);

ExerciseItemPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExerciseItemPanel;
