import React from "react";
import PropTypes from "prop-types";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import { UPDATE_PROFILE_PICTURE_FLOW } from "../consts";
import ProfilePictureForm from "./ProfilePictureForm";

const ProfilePictureFormContainer = (props) => (
  <Entity name="profile">
    {({ getById: getProfileById }) => (
      <Flow
        id={UPDATE_PROFILE_PICTURE_FLOW}
        params={{
          showFeedback: props.showFeedback,
        }}
      >
        {({ isPending, handleStart }) => (
          <ProfilePictureForm
            onSubmit={handleStart}
            isSubmitting={isPending}
            initialValues={getProfileById(sessionStorage.getItem("id"))}
          />
        )}
      </Flow>
    )}
  </Entity>
);

ProfilePictureFormContainer.propTypes = {
  showFeedback: PropTypes.bool,
};

ProfilePictureFormContainer.defaultProps = {
  showFeedback: true,
};

export default ProfilePictureFormContainer;
