import React from "react";
import PropTypes from "prop-types";

const TitleLabel = (props) => (
  <label
    htmlFor="text"
    style={{
      fontSize: 26,
      fontWeight: 400,
      color: "#607d8b",
    }}
  >
    {props.text}
  </label>
);

TitleLabel.propTypes = {
  text: PropTypes.string.isRequired,
};

export default TitleLabel;
