import { all, spawn, call } from "redux-saga/effects";

import loadConfigurationFlow from "./loadConfigurationFlow";
import loadStudentFlow from "./loadStudentFlow";
import loadStudentCourseFlow from "./loadStudentCourseFlow";
import loadModulesFlow from "./loadModulesFlow";
import { endRecordByTimeoutFlow, endRecordFlow, startRecordFlow } from "./recordFlow";
import playItemAudioFlow from "./playItemAudioFlow";
import playItemAudioStripeFlow from "./playItemAudioStripeFlow";
import playRecordAudioFlow from "./playRecordAudioFlow";
import playHistoryRecordAudioFlow from "./playHistoryRecordAudioFlow";
import startUnitExecutionFlow from "./startUnitExecutionFlow";
import getNextUnitItemFlow from "./getNextUnitItemFlow";
import checkUnitItemExecutionAnswerFlow from "./checkUnitItemExecutionAnswerFlow";
import saveUnitItemExecutionAnswerFlow from "./saveUnitItemExecutionAnswerFlow";
import endUnitExecutionFlow from "./endUnitExecutionFlow";
import closeUnitExecutionFlow from "./closeUnitExecutionFlow";
import updateProfileFlow from "./updateProfileFlow";
import updateProfilePictureFlow from "./updateProfilePictureFlow";
import updateStudentPageAccessedFlow from "./updateStudentPageAccessedFlow";
import userAwayTimeoutFlow from "./userAwayTimeoutFlow";
import userAwayFlow from "./userAwayFlow";
import startMasteryTestExecutionFlow from "./startMasteryTestExecutionFlow";
import getNextMasteryTestItemFlow from "./getNextMasteryTestItemFlow";
import saveMasteryTestItemExecutionAnswerFlow from "./saveMasteryTestItemExecutionAnswerFlow";
import endMasteryTestExecutionFlow from "./endMasteryTestExecutionFlow";
import closeMasteryTestExecutionFlow from "./closeMasteryTestExecutionFlow";
import dismissSystemMessageFlow from "./dismissSystemMessageFlow";
import startPracticeTestExecutionFlow from "./startPracticeTestExecutionFlow";
import getNextPracticeTestItemFlow from "./getNextPracticeTestItemFlow";
import savePracticeTestItemExecutionAnswerFlow from "./savePracticeTestItemExecutionAnswerFlow";
import startPlacementTestExecutionFlow from "./startPlacementTestExecutionFlow";
import getNextPlacementTestItemFlow from "./getNextPlacementTestItemFlow";
import getExtraPlacementTestItemFlow from "./getExtraPlacementTestItemFlow";
import savePlacementTestItemExecutionAnswerFlow from "./savePlacementTestItemExecutionAnswerFlow";
import endPlacementTestExecutionFlow from "./endPlacementTestExecutionFlow";
import closePlacementTestExecutionFlow from "./closePlacementTestExecutionFlow";
import startCertificationTestExecutionFlow from "./startCertificationTestExecutionFlow";
import startCertificationTestAbilityExecutionFlow from "./startCertificationTestAbilityExecutionFlow";
import getNextCertificationTestAbilityItemFlow from "./getNextCertificationTestAbilityItemFlow";
import saveCertificationTestAbilityItemExecutionAnswerFlow from "./saveCertificationTestAbilityItemExecutionAnswerFlow";
import endCertificationTestAbilityExecutionAnswerFlow from "./endCertificationTestAbilityExecutionAnswerFlow";
import closeCertificationTestAbilityExecutionFlow from "./closeCertificationTestAbilityExecutionFlow";
import checkCertificationTestResultFlow from "./checkCertificationTestResultFlow";
import loadMessageChannelsFlow from "./loadMessageChannelsFlow";
import loadMessagesFlow from "./loadMessagesFlow";
import sendMessageFlow from "./sendMessageFlow";
import createMessageChannelFlow from "./createMessageChannelFlow";
import loadNotificationsFlow from "./loadNotificationsFlow";
import setNotificationsReadFlow from "./setNotificationsReadFlow";
import studyQualityDetailModalFlow from "./studyQualityDetailModalFlow";
import detectDevToolsFlow from "./detectDevToolsFlow";
import loadStudentTastingFlow from "./loadStudentTastingFlow";
import startTastingUnitExecutionFlow from "./startTastingUnitExecutionFlow";
import saveTastingUnitItemExecutionAnswerFlow from "./saveTastingUnitItemExecutionAnswerFlow";
import endTastingUnitExecutionFlow from "./endTastingUnitExecutionFlow";
import saveTastingContactFlow from "./saveTastingContactFlow";
import checkAchievementExecutionFlow from "./checkAchievementExecutionFlow";
import startItemIntroductionFlow from "./startItemIntroductionFlow";
import webSocketNotificationFlow from "./webSocketNotificationFlow";
import completeProfileFlow from "./completeProfileFlow";
import createAccountFlow from "./createAccountFlow";
import loadLocalizedLabelsFlow from "./loadLocalizedLabelsFlow";
import setManualCourseFlow from "./setManualCourseFlow";
import startPlacementTestTastingFlow from "./startPlacementTestTastingFlow";
import startCertificationTestTastingAbilityExecutionFlow from "./startCertificationTestTastingAbilityExecutionFlow";
import startMasteryTestTastingExecutionFlow from "./startMasteryTestTastingExecutionFlow";
import playItemVideoFlow from "./playItemVideoFlow";
import monitorNetworkConnectionFlow from "./monitorNetworkConnectionFlow";
import startNextUnitItemFlow from "./startNextUnitItemFlow";

import oldPlayItemAudioFlow from "./old-flows/playItemAudioFlow";
import { endOldRecordByTimeoutFlow, endOldRecordFlow, startOldRecordFlow } from "./old-flows/recordFlow";

import microphoneConfigSaga from "../sagas/microphoneConfigSaga";
import { logError } from "../util";
import selectItemFlow from "./selectItemFlow";
import startBonusTestExecutionFlow from "./startBonusTestExecutionFlow";
import getNextBonusTestItemFlow from "./getNextBonusTestItemFlow";
import getExtraBonusTestItemFlow from "./getExtraBonusTestItemFlow";
import saveBonusTestItemExecutionAnswerFlow from "./saveBonusTestItemExecutionAnswerFlow";
import closeBonusTestExecutionFlow from "./closeBonusTestExecutionFlow";
import endBonusTestExecutionFlow from "./endBonusTestExecutionFlow";

import userLastActionFlow from "./userLastActionFlow";
import endContentVideoUnitExecution from "./endContentVideoUnitExecutionFlow";

export default function* () {
  const sagas = [
    userLastActionFlow,
    loadConfigurationFlow,
    loadStudentFlow,
    loadStudentCourseFlow,
    loadModulesFlow,
    startRecordFlow,
    endRecordFlow,
    endRecordByTimeoutFlow,
    playItemAudioFlow,
    playItemAudioStripeFlow,
    playRecordAudioFlow,
    playHistoryRecordAudioFlow,
    startUnitExecutionFlow,
    getNextUnitItemFlow,
    checkUnitItemExecutionAnswerFlow,
    saveUnitItemExecutionAnswerFlow,
    endUnitExecutionFlow,
    closeUnitExecutionFlow,
    updateProfileFlow,
    updateProfilePictureFlow,
    updateStudentPageAccessedFlow,
    userAwayFlow,
    userAwayTimeoutFlow,
    startMasteryTestExecutionFlow,
    getNextMasteryTestItemFlow,
    saveMasteryTestItemExecutionAnswerFlow,
    endMasteryTestExecutionFlow,
    closeMasteryTestExecutionFlow,
    startCertificationTestExecutionFlow,
    startCertificationTestAbilityExecutionFlow,
    getNextCertificationTestAbilityItemFlow,
    saveCertificationTestAbilityItemExecutionAnswerFlow,
    endCertificationTestAbilityExecutionAnswerFlow,
    closeCertificationTestAbilityExecutionFlow,
    checkCertificationTestResultFlow,
    dismissSystemMessageFlow,
    startPracticeTestExecutionFlow,
    getNextPracticeTestItemFlow,
    savePracticeTestItemExecutionAnswerFlow,
    startPlacementTestExecutionFlow,
    getNextPlacementTestItemFlow,
    getExtraPlacementTestItemFlow,
    savePlacementTestItemExecutionAnswerFlow,
    endPlacementTestExecutionFlow,
    closePlacementTestExecutionFlow,
    loadMessageChannelsFlow,
    loadMessagesFlow,
    sendMessageFlow,
    createMessageChannelFlow,
    loadNotificationsFlow,
    setNotificationsReadFlow,
    studyQualityDetailModalFlow,
    detectDevToolsFlow,
    loadStudentTastingFlow,
    startTastingUnitExecutionFlow,
    saveTastingUnitItemExecutionAnswerFlow,
    endTastingUnitExecutionFlow,
    saveTastingContactFlow,
    webSocketNotificationFlow,
    checkAchievementExecutionFlow,
    startItemIntroductionFlow,
    completeProfileFlow,
    createAccountFlow,
    loadLocalizedLabelsFlow,
    setManualCourseFlow,
    startPlacementTestTastingFlow,
    startCertificationTestTastingAbilityExecutionFlow,
    startMasteryTestTastingExecutionFlow,
    playItemVideoFlow,
    monitorNetworkConnectionFlow,
    oldPlayItemAudioFlow,
    startOldRecordFlow,
    endOldRecordFlow,
    endOldRecordByTimeoutFlow,
    startBonusTestExecutionFlow,
    getNextBonusTestItemFlow,
    getExtraBonusTestItemFlow,
    saveBonusTestItemExecutionAnswerFlow,
    closeBonusTestExecutionFlow,
    endBonusTestExecutionFlow,
    startNextUnitItemFlow,
    endContentVideoUnitExecution,
    ...microphoneConfigSaga.map((fn) => fn),
    selectItemFlow,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (error) {
            logError({ flow: "ROOT_SAGA", error });
          }
        }
      })
    )
  );
}
