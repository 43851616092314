import React, { Component } from "react";
import PropTypes from "prop-types";
import Form from "student-front-commons/src/components/Form";
import FlexColumn from "../../core/FlexColumn";
import DragAndDropExerciseItem from "../../core/old-item-type/DragAndDropExerciseItem";
import GapFillRadio from "../../core/old-item-type/GapFillRadio";
import SelectExerciseItem from "../../core/old-item-type/SelectExerciseItem";
import SingleChoiceTextItem from "../../core/old-item-type/SingleChoiceTextItem";
import SingleChoiceAudioItem from "../../core/old-item-type/SingleChoiceAudioItem";
import SingleChoiceImageItem from "../../core/old-item-type/SingleChoiceImageItem";
import { ITEM_EXECUTION_FORM } from "../../consts";
import TestExecutionControlBar from "./TestExecutionControlBar";

export default class TestItemExecutionForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <Form id={ITEM_EXECUTION_FORM} onSubmit={this.props.onSubmit} autoInit={false}>
        {({ values, handleSubmit, handleChange }) => {
          const itemType = values.associativeItem.item.type.key;
          return (
            <FlexColumn justifyContent="space-between">
              {["SINGLE_CHOICE_TEXT"].some((type) => type === itemType) && (
                <SingleChoiceTextItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["SINGLE_CHOICE_AUDIO"].some((type) => type === itemType) && (
                <SingleChoiceAudioItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["SINGLE_CHOICE_IMAGE"].some((type) => type === itemType) && (
                <SingleChoiceImageItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playing={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["GAP_FILL"].some((type) => type === itemType) && (
                <GapFillRadio
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playing={values.playingId}
                  userAnswer={values.answer}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                />
              )}
              {["UNSCRAMBLE_DRAG_AND_DROP", "GAP_FILL_MULTIPLE"].some((type) => type === itemType) && (
                <DragAndDropExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  extraData={values.extraData}
                  onChange={handleChange}
                  onConfirm={handleSubmit}
                />
              )}
              {["GAP_FILL_SELECT"].some((type) => type === itemType) && (
                <SelectExerciseItem
                  item={values.associativeItem.item}
                  isDisabled={values.isDisabled || this.props.isSubmitting}
                  playingId={values.playingId}
                  onConfirm={handleSubmit}
                  onChange={handleChange}
                  extraData={values.extraData}
                />
              )}
              <TestExecutionControlBar
                values={values}
                isSubmitting={this.props.isSubmitting}
                onSave={handleSubmit}
                onChange={handleChange}
              />
            </FlexColumn>
          );
        }}
      </Form>
    );
  }
}
