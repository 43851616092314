import React, { useEffect } from "react";
import { getCourseContentHistory } from "student-front-commons/src/services/recordsService";
import Async from "../../core/Async";
import ContentHistoryList from "./ContentHistoryList";
import { useEntity, useService } from "student-front-commons/src/hooks";

function ContentHistoryListContainer() {
  const [data, setData] = React.useState(null);
  const [currentCourse, setCurrentCourse] = React.useState(null);

  const profile = useEntity("profile", sessionStorage.getItem("id"));

  const [isFetching, fetch] = useService(getCourseContentHistory, { autoStart: false, onData: setData });

  useEffect(() => {
    setCurrentCourse(profile.activeCourses.find((c) => !c.distributor)?.id);
  }, []);

  useEffect(() => {
    fetch({
      id: sessionStorage.getItem("id"),
      course: currentCourse,
    });
  }, [currentCourse]);

  if (!profile || !profile.activeCourses.length) {
    return null;
  }

  return (
    <Async fetching={isFetching} color="dark" size="sm">
      <ContentHistoryList
        data={data || []}
        courses={profile.activeCourses}
        currentCourse={currentCourse}
        onCourseChange={setCurrentCourse}
        isSpeechRecognitionDisabled={profile.isSpeechRecognitionDisabled}
      />
    </Async>
  );
}

export default ContentHistoryListContainer;
