import React from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import moment from "moment";
import CourseThemeContext from "../../core/CourseThemeContext";
import CourseSummaryStatsItem from "./CourseSummaryStatsItem";
import HoursIcon from "../../images/course-summary-time-icon.png";
import PointsIcon from "../../images/course-summary-points-icon.png";
import withTranslation from "../../core/withTranslation";

const CourseSummaryStats = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <CourseSummaryStatsItem
          icon={PointsIcon}
          title={props.getTranslation("courseSummary.hoursInCourse")}
          value={`${moment
            .duration(props.stats.hours, "hours")
            .format("hh:mm", { trim: false })} ${props.getTranslation("hours")}`}
          color={primary}
        />
        <CourseSummaryStatsItem
          icon={HoursIcon}
          title={props.getTranslation("courseSummary.pointsInCourse")}
          value={`${numeral(props.stats.points).format("0,0").replace(",", ".")}`}
          color={primary}
        />
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CourseSummaryStats.propTypes = {
  stats: PropTypes.shape({
    hours: PropTypes.number,
    points: PropTypes.number,
  }).isRequired,
};

export default withTranslation(CourseSummaryStats);
