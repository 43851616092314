import React from "react";
import { useSelector } from "react-redux";
import TranslatedText from "../../core/TranslatedText";
import HeaderBackground from "../../images/unit-result-header.svg";
import HeaderFailBackground from "../../images/unit-result-fail-header.svg";
import StarryHeaderBackground from "../../images/rating-card-header.svg";
import Icon from "../../core/Icon";
import { isExecutionApproved } from "student-front-commons/src/selectors/execution";
import PropTypes from "prop-types";

function UnitResultHeader({ step }) {
  const executionScore = useSelector((state) => state.executions?.result?.score);
  const isApproved = useSelector(isExecutionApproved);

  return (
    <div
      style={{
        backgroundColor: { SCORE: isApproved ? "#0D9488" : "#285d8d", REVIEW: "#753FE5", RATING: "#285D8D" }[step],
        position: "relative",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundImage: {
          SCORE: isApproved ? `url("${HeaderBackground}")` : `url("${HeaderFailBackground}")`,
          REVIEW: `url("${StarryHeaderBackground}")`,
          RATING: `url("${StarryHeaderBackground}")`,
        }[step],
        backgroundPosition: "center",
        ...((step === "RATING" || step === "REVIEW") && {
          height: "184px",
          padding: "40px 16px 50px 16px",
        }),
        ...(step === "SCORE" && {
          height: "380px",
          padding: "40px 16px",
          justifyContent: "initial",
        })
      }}
    >
      {step === "SCORE" && (
        <>
          <TranslatedText
            as="span"
            translateKey={isApproved ? "unitResult.congrats" : "unitResult.keepPracticing"}
            style={{
              color: "#FEFEFE",
              fontSize: "36px",
              fontWeight: 600,
              marginBottom: 8,
              marginTop: -3,
            }}
          />
          <TranslatedText
            as="span"
            translateKey="unitResult.yourScore"
            style={{
              color: "#FEFEFE",
              fontSize: "16px",
              fontWeight: 500
            }}
          />
          <h2
            style={{
              margin: "5px 0px 18px",
              color: isApproved ? "#FCD34D" : "#FEFEFE",
              fontSize: "96px",
              fontWeight: 700,
              lineHeight: "86px",
            }}
          >
            {executionScore}
          </h2>
        </>
      )}
      {step === "REVIEW" && (
        <>
          <Icon icon="book-check-outline" color="#fff" size="lg" />
          <TranslatedText
            as="span"
            translateKey={isApproved ? "unitResult.reviewStep.title" : "unitResult.reviewStep.notWell.title"}
            style={{
              color: "#fff",
              fontSize: 36,
              fontWeight: 700,
              marginBottom: 12,
            }}
          />
        </>
      )}
      {step === "RATING" && (
        <>
          <Icon icon="heart-outline" color="#FEFEFE" size="mdl" />
          <TranslatedText
            as="span"
            translateKey="unitRating.title"
            style={{
              color: "#FEFEFE",
              fontSize: 36,
              fontWeight: 700,
              marginBottom: 12,
            }}
          />
        </>
      )}
    </div>
  );
}

UnitResultHeader.propTypes = {
  step: PropTypes.string.isRequired,
};

export default UnitResultHeader;
