import React, { Component } from "react";
import PropTypes from "prop-types";
import TimeLineListItem from "./TimeLineListItem";
import { getColorOfCourse } from "../../consts/color";
import DateLabel from "../../core/DateLabel";
import CourseButton from "../../core/CourseButton";
import TranslatedText from "../../core/TranslatedText";
import FlexRow from "../../core/FlexRow";
import Separator from "../../core/Separator";

export default class TimeLineList extends Component {
  static propTypes = {
    activities: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        createdAt: PropTypes.string,
      })
    ).isRequired,
  };

  getDescriptionByType = (activity) => {
    if (activity.type === "PLACEMENT_TEST_COMPLETED") {
      return (
        <TranslatedText
          translateKey="profile.timelinePlacementTestLevel"
          as="span"
          values={{
            level: activity.placementTest.reachedLevel.level,
          }}
        />
      );
    }
    if (activity.type === "BONUS_TEST_COMPLETED") {
      return <TranslatedText translateKey="profile.timelineBonusTestCompleted" as="span" />;
    }
    if (activity.type === "COURSE_STARTED") {
      return <TranslatedText as="span" translateKey="profile.timelineCourseStarted" />;
    }
    if (activity.type === "DAILY_RECORD") {
      return (
        <TranslatedText
          as="span"
          translateKey="profile.timelineDailyRecord"
          values={{
            points: activity.points,
          }}
        />
      );
    }
    if (activity.type === "MODULE_COMPLETED") {
      return (
        <TranslatedText
          as="span"
          translateKey="profile.timelineModuleCompleted"
          values={{
            moduleName: activity.module.module.name,
          }}
        />
      );
    }
    if (activity.type === "CERTIFICATION_TEST_COMPLETED") {
      return <TranslatedText as="span" translateKey="profile.timelineCertificationTestCompleted" />;
    }
    if (activity.type === "MASTERY_TEST_COMPLETED") {
      if (activity.masteryTest.failedAt) {
        return <TranslatedText as="span" translateKey="profile.timelineFailedMasteryTest" />;
      }
      if (activity.masteryTest.approvedAt) {
        return <TranslatedText as="span" translateKey="profile.timelineApprovedMasteryTest" />;
      }
    }
    return "";
  };

  getDateByType = (activity) => {
    if (activity.type === "PLACEMENT_TEST_COMPLETED") {
      return new Date(activity.placementTest.completedAt);
    }
    if (
      [
        "COURSE_STARTED",
        "DAILY_RECORD",
        "MODULE_COMPLETED",
        "CERTIFICATION_TEST_COMPLETED",
        "BONUS_TEST_COMPLETED",
      ].find((type) => activity.type === type)
    ) {
      return new Date(activity.createdAt);
    }
    if (activity.type === "MASTERY_TEST_COMPLETED") {
      if (activity.masteryTest.failedAt) {
        return new Date(activity.masteryTest.failedAt);
      } else if (activity.masteryTest.approvedAt) {
        return new Date(activity.masteryTest.approvedAt);
      } else {
        return new Date(activity.createdAt);
      }
    }
    return null;
  };

  getColorByType = (activity) => {
    if (activity.type === "COURSE_STARTED" && activity.course) {
      return getColorOfCourse(activity.course.course.name).secondary;
    }
    if (activity.type === "MODULE_COMPLETED") {
      return getColorOfCourse(activity.module.module.course.name).secondary;
    }
    return undefined;
  };

  render() {
    return (
      <ul
        style={{
          listStyle: "none",
          margin: 0,
          padding: "0px 20px 0px 40px",
        }}
      >
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: 3,
            height: "100%",
            backgroundColor: "rgba(155, 155, 155, .2)",
          }}
        />
        {this.props.activities
          .filter(
            (activity) => activity.type !== "COURSE_STARTED" || (activity.type === "COURSE_STARTED" && activity.course)
          )
          .map((activity) => (
            <TimeLineListItem
              key={`activity-item-${activity.id}`}
              size={
                {
                  CERTIFICATION_TEST_COMPLETED: "lg",
                  BONUS_TEST_COMPLETED: "md",
                  PLACEMENT_TEST_COMPLETED: "md",
                  COURSE_STARTED: "md",
                  MODULE_COMPLETED: "md",
                  MASTERY_TEST_COMPLETED: "md",
                  DAILY_RECORD: "sm",
                }[activity.type]
              }
              color={this.getColorByType(activity)}
              highlight={!!this.getColorByType(activity)}
            >
              <FlexRow alignItems="center">
                {activity.type === "COURSE_STARTED" && <CourseButton course={activity.course.course.name} size="xs" />}
                {activity.type === "COURSE_STARTED" && <Separator size="xs" />}
                <label
                  htmlFor="description"
                  style={{
                    color: "rgba(88, 88, 88, 0.8)",
                    margin: 0,
                    fontSize: 13,
                  }}
                >
                  {this.getDescriptionByType(activity)}
                </label>
              </FlexRow>
              <DateLabel color="rgba(88, 88, 88, 0.5)" format="DD/MM" date={this.getDateByType(activity)} size="xs" />
            </TimeLineListItem>
          ))}
      </ul>
    );
  }
}
