import React from "react";
import ListenButton from "./ListenButton";
import { PLAY_RECORD_AUDIO_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function ListenButtonContainer() {
  const [isPending, handleStart] = useFlow(PLAY_RECORD_AUDIO_FLOW);

  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const recordCount = useSelector(getCurrentItemExecutionProp("recordCount"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const isListenDisabled = () => {
    if (
      isExecutionValidated &&
      ["GAP_FILL", "DIALOGUE_OPTION", "UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === itemType)
    ) {
      return isPending;
    }
    return (
      isPending ||
      isDisabled ||
      isRecording ||
      isSubmittingRecord ||
      !recordCount ||
      ![
        "PRESENTATION",
        "PRONUNCIATION",
        "SPEECH_PRACTICE",
        "VIDEO_SHORT",
        "GAP_FILL",
        "UNSCRAMBLE_SPEECH_RECOGNITION",
        "DIALOGUE_OPTION",
        "VOCABULARY_ACADEMIC",
      ].some((type) => type === itemType)
    );
  };

  return (
    <ListenButton
      onClick={handleStart}
      isDisabled={(currentInstructionStep && currentInstructionStep !== "listen") || isListenDisabled()}
    />
  );
}

export default ListenButtonContainer;
