import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getUnitsToFinishAfterTestFailure } from "student-front-commons/src/selectors/configuration";
import PropTypes from "prop-types";
import { addSentryUserAction } from "../../util";
import Icon from "../Icon";
import ColumnSeparator from "../ColumnSeparator";
import TranslatedText from "../TranslatedText";
import MasteryTestListItemPoints from "./MasteryTestListItemPoints";
import Tooltip from "../Tooltip";
import round from "lodash/round";
import withTranslation from "../withTranslation";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import FlexColumn from "../FlexColumn";
import FlexRow from "../FlexRow";
import MasteryTestListItemConfirmExecutionModal from "../../screens/unit-list/MasteryTestListItemConfirmExecutionModal";
import { useEntity, useEntityList } from "student-front-commons/src/hooks";
import { COMPANY_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";

const sentryUserAction = { mainComponent: "MasteryTestListItem" };

const MasteryTestListItemProgress = ({ percentageToUnlock }) => {
  return (
    <div
      style={{
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        bottom: 0,
        left: 0,
        width: "100%",
        height: 3,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: `${percentageToUnlock}%`,
          backgroundColor: "#FF9F51",
          height: 3,
          transition: "width 1s 1s",
        }}
      />
    </div>
  );
};

MasteryTestListItemProgress.propTypes = {
  percentageToUnlock: PropTypes.number.isRequired,
};

const MasteryTestListItem = ({
  masteryTest,
  availablePoints,
  conqueredPoints,
  history,
  location,
  getTranslation,
  onExpand,
  expanded,
}) => {
  const units = useEntityList("unit");
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile?.company);

  const unitsToFinishAfterTestFailure = useSelector(getUnitsToFinishAfterTestFailure);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const lockedStats = useMemo(() => {
    const moduleUnitsGroup = units.filter(
      (u) => u.module === masteryTest.module && u.group === masteryTest.group && !u.isContentVideo
    );
    return {
      defaultUnfinished: moduleUnitsGroup.filter((u) => !u.defaultFinishedAt).length,
      reviewUnfinished: moduleUnitsGroup.filter((u) =>
        company.isOptionalReviewEnabled ? u.defaultFinishedAt && !u.firstReviewFinishedAt : !u.firstReviewFinishedAt
      ).length,
      total: company.isOptionalReviewEnabled ? moduleUnitsGroup.length : moduleUnitsGroup.length * 2,
    };
  }, [units, masteryTest.module, masteryTest.group]);

  const status = useMemo(() => {
    if (masteryTest?.approvedAt) {
      return "approved";
    } else if (masteryTest?.failedAt) {
      return "failed";
    } else if (masteryTest?.availableAt) {
      return "unlocked";
    }
    return "locked";
  }, [masteryTest]);

  useEffect(() => {
    if (location.state && location.state.targetMasteryTest && masteryTest.id === location.state.targetMasteryTest) {
      $("html, body").animate(
        {
          scrollTop: $(`#mastery-test-${location.state.targetMasteryTest}`).offset().top - 10,
        },
        1000
      );
      $(`#mastery-test-${location.state.targetMasteryTest}`).css("border", "2px solid #fff");
      history.replace(location.pathname, {});
    }
  }, [masteryTest, location, history]);

  const handleMasteryTestClick = useCallback(() => {
    if (status === "unlocked") {
      setIsModalOpen(true);
    }
  }, [status]);

  const handleModalClick = useCallback(() => {
    if (!masteryTest?.id) {
      return;
    }

    if (status === "unlocked") {
      history.push(
        `${history.location.pathname.replace(/\/[0-9a-fA-F]{24}\/units/, "")}/${masteryTest.module}/mastery-test/${
          masteryTest.id
        }/execution`
      );
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "li",
        action: `Navigate to ${history.location.pathname.replace(/\/[0-9a-fA-F]{24}\/units/, "")}/${
          masteryTest.module
        }/mastery-test/${masteryTest.id}/execution`,
      });
    }
  }, [masteryTest?.id, masteryTest?.module, history, status, onExpand]);

  return (
    <FlexColumn>
      <FlexRow
        id={`mastery-test-${masteryTest.id}`}
        width="100%"
        borderRadius={3}
        borderBottomLeftRadius={expanded ? 0 : 3}
        borderBottomRightRadius={expanded ? 0 : 3}
        justifyContent="space-between"
        backgroundColor={
          {
            unlocked: "#FF9F51",
            locked: "#757575",
            approved: "#00786A",
            failed: "#de5a67",
          }[status]
        }
        boxSizing="border-box"
        padding="7px 10px 8px"
        position="relative"
        cursor={status === "unlocked" ? "pointer" : ""}
        onClick={handleMasteryTestClick}
      >
        {status === "locked" && (
          <MasteryTestListItemProgress
            percentageToUnlock={round(
              ((lockedStats.total - (lockedStats.defaultUnfinished + lockedStats.reviewUnfinished)) /
                lockedStats.total) *
                100,
              2
            )}
          />
        )}
        <FlexRow alignItems="center" width="100%" paddingRight={10} boxSizing="border-box">
          <Icon color="white" icon="file-document" size="xs" cursor={status === "unlocked" ? "pointer" : ""} />
          <ColumnSeparator size="xs" />
          <TranslatedText
            as="label"
            translateKey={`masteryTest.${status}`}
            style={{
              color: "#FFF",
              fontSize: 13,
              margin: 0,
              cursor: "inherit",
              width: "100%",
            }}
          />
          {status === "failed" && <ColumnSeparator size="xs" />}
          {status === "failed" && (
            <MasteryTestListItemPoints failedAt={masteryTest.failedAt} module={masteryTest.module} />
          )}
        </FlexRow>
        <FlexRow alignItems="center">
          <FlexRow
            borderRadius="50%"
            backgroundColor="rgba(255,255,255,0.3)"
            width={28}
            height={28}
            textAlign="center"
            overflow="hidden"
            position="relative"
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip
              text={getTranslation(`masteryTest.tooltip.${status}`, {
                modulePercentageToActive: masteryTest.modulePercentageToActive,
                requiredPoints: round(
                  availablePoints * (masteryTest.modulePercentageToActive / 100) - conqueredPoints,
                  0
                ),
                unitsNeeded: unitsToFinishAfterTestFailure,
              })}
            >
              <Icon
                color="white"
                size="xs"
                icon={
                  {
                    unlocked: "lock-open-variant-outline",
                    locked: "lock-outline",
                    failed: "lock-outline",
                    approved: "check",
                  }[status]
                }
              />
            </Tooltip>
          </FlexRow>
        </FlexRow>
      </FlexRow>
      {isModalOpen && (
        <MasteryTestListItemConfirmExecutionModal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={handleModalClick}
        />
      )}
    </FlexColumn>
  );
};

MasteryTestListItem.propTypes = {
  masteryTest: PropTypes.shape({
    id: PropTypes.string,
    failedAt: PropTypes.string,
    approvedAt: PropTypes.string,
    availableAt: PropTypes.string,
    group: PropTypes.number,
    module: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      targetMasteryTest: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(withTranslation(MasteryTestListItem));
