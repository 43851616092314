import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * translate a text to user locale
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.text - the page to request
 *
 * @returns {Promise<*>}
 */
export const getTranslation = async (payload) => {
  validate(
    {
      text: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: "translate",
        method: "post",
        data: payload,
      });
    },
    "error_get_translation",
    "Unexpected error to translation."
  );
};
