import React from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const theme = (primaryColor, secondaryColor) => {
  return createTheme({
    typography: {
      fontFamily: "DaxlinePro",
    },
    palette: {
      common: {
        black: "#424242",
      },
      primary: {
        main: secondaryColor,
        contrastText: "#fff",
      },
      secondary: {
        main: secondaryColor,
      },
    },
    // datePicker: {
    //   selectColor: primaryColor,
    //   headerColor: primaryColor,
    // },
    // radioButton: {
    //   borderColor: "#42424280",
    // },
  });
};

const MaterialTheme = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor, secondaryColor }) => (
      <ThemeProvider theme={theme(primaryColor, secondaryColor)}>{props.children}</ThemeProvider>
    )}
  </WhitelabelContext.Consumer>
);

MaterialTheme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MaterialTheme;
