import React from "react";
import Separator from "../../core/Separator";
import Tabs from "../../core/Tabs";
import Panel from "../../core/Panel";
import ContentHistoryListContainer from "./ContentHistoryListContainer";
import DateHistoryListContainer from "./DateHistoryListContainer";
import withTranslation from "../../core/withTranslation";

const HistoryScene = (props) => (
  <div className="container">
    <Separator size="md" />
    <Tabs
      tabs={[
        {
          label: props.getTranslation("history.byContent"),
          component: (
            <Panel padding="none">
              <ContentHistoryListContainer />
            </Panel>
          ),
        },
        {
          label: props.getTranslation("history.byDate"),
          component: (
            <Panel padding="none">
              <DateHistoryListContainer />
            </Panel>
          ),
        },
      ]}
    />
  </div>
);

export default withTranslation(HistoryScene);
