import React from "react";
import Panel from "../../core/Panel";
import Title from "../../core/Title";
import Separator from "../../core/Separator";
import SprintHistoryListContainer from "./SprintHistoryListContainer";
import TranslatedText from "../../core/TranslatedText";

const SprintHistoryPanel = () => (
  <div className="stepSprintHistory">
    <Panel padding="lg">
      <TranslatedText as={Title} translateKey="profile.sprint.title" />
      <Separator size="md" />
      <SprintHistoryListContainer />
    </Panel>
  </div>
);

export default SprintHistoryPanel;
