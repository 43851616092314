import { last, sample, every, head, maxBy } from "lodash";
import { call, cancelled, delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import {
  CHECK_ACHIEVEMENT_EXECUTION_FLOW,
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import {
  addItemExecutionAnswer,
  addItemExecutionAttempt,
  cleanItemExecutionAudio,
  disableItemExecution,
  enableItemExecution,
  finishItemExecution,
  finishPlayItemAnswer,
  incrementItemExecutionError,
  showItemCorrectOption,
  startPlayItem,
  startPlayItemAnswer,
} from "student-front-commons/src/actions/itemExecution";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getItemExecutions, getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";

const typesToNoAudioFeedback = [
  "PRESENTATION",
  "PRONUNCIATION",
  "SPEECH_PRACTICE",
  "VIDEO_SHORT",
  "TEXT",
  "VIDEO",
  "CONTENT_VIDEO",
  "AUDIO_LONG",
  "VIDEO_LONG",
  "IMAGE",
  "DIALOGUE",
  "VOCABULARY_ACADEMIC",
];

// array defining the types that should post play the item audio
const typesToPostPlayItemAudio = [
  "GAP_FILL",
  "GAP_FILL_MULTIPLE",
  "GAP_FILL_SELECT",
  "DICTATION",
  "UNSCRAMBLE_DRAG_AND_DROP",
  "UNSCRAMBLE_SPEECH_RECOGNITION",
  "SPEECH_PRACTICE",
  "MEANINGS_ASSOCIATING",
  "PRESENTATION_SPEECHLESS",
  "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
  "GAP_FILL_SPEECHLESS",
  "PRONUNCIATION_SPEECHLESS",
  "SPEECH_PRACTICE_SPEECHLESS",
  "VOCABULARY_ACADEMIC_SPEECHLESS",
];

const typesToPostPlayItemPostPhrase = ["MEANINGS_ASSOCIATING", "VOCABULARY_ACADEMIC_SPEECHLESS"];

// array defining the types that should post play the answer audio
const typesToPostPlayAnswerAudio = [
  "SINGLE_CHOICE_TEXT",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_AUDIO",
  "MULTIPLE_CHOICE_TEXT",
  "GRAMMAR_CHECK",
];

const typesToPlayRetryAudio = [
  "SINGLE_CHOICE_TEXT",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_AUDIO",
  "DICTATION",
  "MULTIPLE_CHOICE_TEXT",
  "UNSCRAMBLE_TEXT",
  "DIALOGUE_OPTION",
  "GRAMMAR_CHECK",
  "PRESENTATION_SPEECHLESS",
  "SPEECH_PRACTICE_SPEECHLESS",
  "DIALOGUE_OPTION_SPEECHLESS",
  "VOCABULARY_ACADEMIC_SPEECHLESS",
];

const typesToShowCorrectAnswer = [
  "DICTATION",
  "GAP_FILL",
  "GAP_FILL_MULTIPLE",
  "UNSCRAMBLE_DRAG_AND_DROP",
  "UNSCRAMBLE_SPEECH_RECOGNITION",
  "GAP_FILL_SELECT",
  "SINGLE_CHOICE_TEXT",
  "SINGLE_CHOICE_IMAGE",
  "SINGLE_CHOICE_AUDIO",
  "MULTIPLE_CHOICE_TEXT",
  "UNSCRAMBLE_TEXT",
  "DIALOGUE_OPTION",
  "GRAMMAR_CHECK",
  "MEANINGS_ASSOCIATING",
  "PRESENTATION_SPEECHLESS",
  "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
  "GAP_FILL_SPEECHLESS",
  "PRONUNCIATION_SPEECHLESS",
  "SPEECH_PRACTICE_SPEECHLESS",
  "DIALOGUE_OPTION_SPEECHLESS",
  "VOCABULARY_ACADEMIC_SPEECHLESS",
];

const typesToNotCheckAnswer = [
  "PRESENTATION",
  "SPEECH_PRACTICE",
  "PRONUNCIATION",
  "TEXT",
  "VIDEO",
  "CONTENT_VIDEO",
  "AUDIO_LONG",
  "VIDEO_LONG",
  "IMAGE",
  "DIALOGUE",
  "VOCABULARY_ACADEMIC",
];

const typesToFinishAllItemExecutions = ["MEANINGS_ASSOCIATING", "GRAMMAR_CHECK"];

const typesToSaveAfterWrongFeedback = ["TRUE_FALSE"];

const isAllItemExecutionsFinished = (itemExecutions) =>
  every(itemExecutions, (itemExecution) => itemExecution.isFinished);

const getIsItemExecutionAnswerCorrect = (itemExecution) =>
  last(itemExecution.attempts) && last(itemExecution.attempts).correct;

const isAllItemExecutionsAnswersCorrect = (itemExecutions) =>
  every(itemExecutions, (itemExecution) => getIsItemExecutionAnswerCorrect(itemExecution));

const getMaxAttempts = (itemExecutions) =>
  maxBy(itemExecutions, (itemExecution) => itemExecution.attempts.length)?.attempts.length;

export default function* () {
  yield takeLatest(getFlowStart(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          let saveFlowId = SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW;
          const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
          if (profile.id === "tasting_user") {
            saveFlowId = SAVE_TASTING_UNIT_ITEM_EXECUTION_ANSWER_FLOW;
          }

          yield put(disableItemExecution());

          const itemExecutionsType = yield select(getItemsExecutionsType);

          let itemExecutions = yield select(getItemExecutions);

          //1. IF ALL ITEM EXECUTIONS IS FINISHED, START SAVE FLOW AND INTERRUPT THIS FLOW
          if (isAllItemExecutionsFinished(itemExecutions)) {
            yield put(startFlow(saveFlowId));
            return;
          }

          //2. CHECK ANSWER OF ALL ITEM EXECUTIONS
          if (!typesToNotCheckAnswer.some((type) => type === itemExecutionsType)) {
            yield itemExecutions.reduce(function* (promise, itemExecution) {
              yield promise;

              let answer = itemExecution.answer;
              if (itemExecutionsType === "MEANINGS_ASSOCIATING") {
                const answerItem = itemExecutions.find((execution) => execution.item.id === itemExecution.answer);
                answer = { postPhrase: answerItem.item.postPhrase, text: answerItem.item.text };
              }

              const answerResult = yield call(checkAnswer, {
                item: itemExecution.item,
                answer,
              });

              const currentAnswer = { answer: answerResult.answer, correct: answerResult.status === "CORRECT" };

              if (
                !last(itemExecution.attempts) ||
                (last(itemExecution.attempts)?.correct === false && currentAnswer.correct === true) ||
                currentAnswer.correct === false
              ) {
                yield put(addItemExecutionAttempt(itemExecution.item.id, { answer: currentAnswer }));
              }

              if (!currentAnswer.correct) {
                yield put(incrementItemExecutionError(itemExecution.item.id));
              }

              if (
                [
                  "DICTATION",
                  "MULTIPLE_CHOICE_TEXT",
                  "UNSCRAMBLE_TEXT",
                  "GRAMMAR_CHECK",
                  "MEANINGS_ASSOCIATING",
                  "SPEECH_PRACTICE_SPEECHLESS",
                ].some((type) => type === itemExecutionsType)
              ) {
                yield put(
                  addItemExecutionAnswer(itemExecution.item.id, {
                    answer: itemExecution.answer,
                    extraData: answerResult.extraData,
                  })
                );
              }

              yield put(startFlow(CHECK_ACHIEVEMENT_EXECUTION_FLOW, { type: "next-check" }));
              yield take(getFlowEnd(CHECK_ACHIEVEMENT_EXECUTION_FLOW));
            }, Promise.resolve());
          }

          itemExecutions = yield select(getItemExecutions);

          //3. IF ALL ITEM EXECUTIONS TYPE IS A TYPE TO NOT PLAY AUDIO FEEDBACK, START SAVE FLOW AND INTERRUPT THIS FLOW
          if (typesToNoAudioFeedback.some((type) => type === itemExecutionsType)) {
            yield put(startFlow(saveFlowId));
            return;
          }

          //4. IF ANSWER OF ALL ITEM EXECUTIONS IS CORRECT, START SAVE FLOW
          if (isAllItemExecutionsAnswersCorrect(itemExecutions)) {
            yield put(startFlow(saveFlowId));
          }

          //5. PLAY CORRECT/WRONG AUDIO FEEDBACK
          let randomAudio = null;
          if (isAllItemExecutionsAnswersCorrect(itemExecutions)) {
            const correctAudios = yield select((state) => state.configurations.correctAudios);
            randomAudio = sample(correctAudios);

            if (["DIALOGUE_OPTION", "DIALOGUE_OPTION_SPEECHLESS"].includes(itemExecutionsType)) {
              yield put(showItemCorrectOption(head(itemExecutions).item.id));
            } else if (itemExecutionsType === "MEANINGS_ASSOCIATING") {
              yield itemExecutions.reduce(function* (promise, itemExecution) {
                yield promise;
                yield put(showItemCorrectOption(itemExecution.item.id));
              }, Promise.resolve());
            }
          } else {
            const errorAudios = yield select((state) => state.configurations.errorAudios);
            randomAudio = sample(errorAudios);

            const maxAttemptsCount = getMaxAttempts(itemExecutions);
            if (itemExecutionsType === "MEANINGS_ASSOCIATING" && maxAttemptsCount === 3) {
              yield itemExecutions.reduce(function* (promise, itemExecution) {
                yield promise;
                yield put(showItemCorrectOption(itemExecution.item.id));
              }, Promise.resolve());
            }
          }

          if (
            ["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].includes(itemExecutionsType) &&
            isAllItemExecutionsAnswersCorrect(itemExecutions)
          ) {
            if (last(itemExecutions).extraData.rating === 100) {
              yield call(playAudio, {
                url: randomAudio.path || randomAudio.generatedAudio,
              });
            }
          } else {
            yield call(playAudio, {
              url: randomAudio.path || randomAudio.generatedAudio,
            });
          }

          //6. IF SOME ITEM EXECUTION ANSWER IS WRONG, TO THIS TYPES START SAVE FLOW AND INTERRUPT THIS FLOW
          if (
            !isAllItemExecutionsAnswersCorrect(itemExecutions) &&
            typesToSaveAfterWrongFeedback.some((type) => type === itemExecutionsType)
          ) {
            yield put(startFlow(saveFlowId));
            return;
          }

          /**
           * 7. IF ALL ITEM EXECUTIONS ANSWER IS CORRECT:
           *    7.1 POST PLAY ITEM AUDIO
           *    7.2 POST PLAY ITEM POSTPHRASE AUDIO
           *    7.3 POST PLAY ITEM ANSWERS AUDIO
           */
          if (isAllItemExecutionsAnswersCorrect(itemExecutions)) {
            if (typesToFinishAllItemExecutions.some((type) => type === itemExecutionsType)) {
              yield itemExecutions.reduce(function* (promise, itemExecution) {
                yield promise;
                yield put(finishItemExecution(itemExecution.item.id));
              }, Promise.resolve());
              yield delay(1000);
            }

            yield itemExecutions.reduce(function* (promise, itemExecution) {
              yield promise;

              //7.1 POST PLAY ITEM AUDIO
              if (typesToPostPlayItemAudio.some((type) => type === itemExecutionsType)) {
                yield put(startPlayItem(itemExecution.item.id, { isInitialPlay: false }));
                yield call(playAudio, {
                  url: itemExecution.item.audio || itemExecution.item.generatedAudio,
                });
              }
              //7.2 POST PLAY ITEM POSTPHRASE AUDIO
              if (typesToPostPlayItemPostPhrase.some((type) => type === itemExecutionsType)) {
                yield put(startPlayItem(itemExecution.item.id, { isInitialPlay: false }));
                yield delay(500);
                yield call(playAudio, {
                  url: itemExecution.item.postPhraseAudio || itemExecution.item.generatedPostPhraseAudio,
                });
              }
              yield put(cleanItemExecutionAudio(itemExecution.item.id));

              //7.3 POST PLAY ITEM ANSWERS AUDIO
              if (typesToPostPlayAnswerAudio.some((type) => type === itemExecutionsType)) {
                const correctAnswers =
                  "GRAMMAR_CHECK" === itemExecutionsType
                    ? itemExecution.item.linkedAnswers.filter(
                        (answer) => answer.text.replace(/['!.?,]/g, "").trim() === itemExecution.extraData.bestMatch
                      )
                    : itemExecution.item.linkedAnswers.filter((answer) => answer.correct);

                yield correctAnswers.reduce(function* (acc, correctAnswer) {
                  yield acc;
                  yield delay(500);
                  yield put(startPlayItemAnswer(itemExecution.item.id, correctAnswer.id));
                  yield call(playAudio, {
                    url: correctAnswer.audio || correctAnswer.generatedAudio,
                  });
                  yield put(finishPlayItemAnswer(itemExecution.item.id, correctAnswer.id));
                }, Promise.resolve(0));
              }
            }, Promise.resolve());
          }

          /**
           * 7. IF SOME ITEM EXECUTION ANSWER IS WRONG:
           *      IF IS FIRST ATTEMPTS:
           *        7.1 PLAY FIRST TRY AUDIOS
           *        7.2 RETRY ITEM AUDIO
           *      IF LAST ATTEMPT:
           *        7.1 PLAY SECOND TRY AUDIOS
           *        7.2 SHOW CORRECT ANSWER
           *        7.3 POST PLAY ITEM AUDIO
           *        7.4 POST PLAY ITEM POSTPHRASE AUDIO
           *        7.5 POST PLAY ITEM ANSWERS AUDIO
           */
          if (!isAllItemExecutionsAnswersCorrect(itemExecutions)) {
            //when some item execution is wrong, attempts quantity correspond to error count
            const maxAttemptsCount = getMaxAttempts(itemExecutions);

            //IF IS FIRST ATTEMPTS
            if (
              (["DICTATION", "GRAMMAR_CHECK", "MEANINGS_ASSOCIATING", "SPEECH_PRACTICE_SPEECHLESS"].some(
                (type) => type === itemExecutionsType
              ) &&
                maxAttemptsCount <= 2) ||
              (!["DICTATION", "GRAMMAR_CHECK", "MEANINGS_ASSOCIATING", "SPEECH_PRACTICE_SPEECHLESS"].some(
                (type) => type === itemExecutionsType
              ) &&
                maxAttemptsCount === 1)
            ) {
              //7.1 PLAY FIRST TRY AUDIOS
              const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
              const randomAudio = sample(firstTryAudios);
              yield call(playAudio, {
                url: randomAudio.path || randomAudio.generatedAudio,
              });
              //7.2 RETRY ITEM AUDIO
              if (typesToPlayRetryAudio.some((type) => type === itemExecutionsType)) {
                yield put(startFlow(PLAY_ITEM_AUDIO_FLOW));
                if (["MULTIPLE_CHOICE_TEXT"].some((type) => type === itemExecutionsType)) {
                  yield take(getFlowEnd(PLAY_ITEM_AUDIO_FLOW));
                  yield itemExecutions.reduce(function* (promise, itemExecution) {
                    yield promise;
                    const selectedAnswers = itemExecution.extraData.selectedAnswers.filter(
                      (selectedAnswer) => selectedAnswer.status === "CORRECT"
                    );
                    yield put(
                      addItemExecutionAnswer(itemExecution.item.id, {
                        answer: itemExecution.answer.filter((a) => selectedAnswers.some((sa) => sa.id === a.id)),
                        extraData: {
                          selectedAnswers: selectedAnswers,
                        },
                      })
                    );
                  }, Promise.resolve());
                }
              } else {
                yield put(enableItemExecution());
              }
            } else {
              //IF LAST ATTEMPT:
              //7.1 PLAY SECOND TRY AUDIOS
              const secondTryAudios = yield select((state) => state.configurations.secondTryAudios);
              const randomAudio = sample(secondTryAudios);
              yield call(playAudio, {
                url: randomAudio.path || randomAudio.generatedAudio,
              });

              yield put(startFlow(saveFlowId));

              if (typesToFinishAllItemExecutions.some((type) => type === itemExecutionsType)) {
                yield itemExecutions.reduce(function* (promise, itemExecution) {
                  yield promise;
                  yield put(finishItemExecution(itemExecution.item.id));
                }, Promise.resolve());
                yield delay(2000);
              }

              yield itemExecutions.reduce(function* (promise, itemExecution) {
                yield promise;

                //7.2 SHOW CORRECT ANSWER
                if (typesToShowCorrectAnswer.find((type) => type === itemExecutionsType)) {
                  yield put(showItemCorrectOption(itemExecution.item.id));
                  if (["DIALOGUE_OPTION", "DIALOGUE_OPTION_SPEECHLESS"].includes(itemExecutionsType)) {
                    yield delay(3000);
                  }
                }

                //7.3 POST PLAY ITEM AUDIO
                if (typesToPostPlayItemAudio.find((type) => type === itemExecutionsType)) {
                  yield put(startPlayItem(itemExecution.item.id, { isInitialPlay: false }));
                  yield call(playAudio, {
                    url: itemExecution.item.audio || itemExecution.item.generatedAudio,
                  });
                }

                //7.4 POST PLAY ITEM POSTPHRASE AUDIO
                if (typesToPostPlayItemPostPhrase.some((type) => type === itemExecutionsType)) {
                  yield put(startPlayItem(itemExecution.item.id, { isInitialPlay: false }));
                  yield delay(500);
                  yield call(playAudio, {
                    url: itemExecution.item.postPhraseAudio || itemExecution.item.generatedPostPhraseAudio,
                  });
                }
                yield put(cleanItemExecutionAudio(itemExecution.item.id));

                //7.5 POST PLAY ITEM ANSWERS AUDIO
                if (typesToPostPlayAnswerAudio.find((type) => type === itemExecutionsType)) {
                  const correctAnswers =
                    "GRAMMAR_CHECK" === itemExecutionsType
                      ? itemExecution.item.linkedAnswers.filter(
                          (answer) => answer.text.replace(/['!.?,]/g, "").trim() === itemExecution.extraData.bestMatch
                        )
                      : itemExecution.item.linkedAnswers.filter((answer) => answer.correct);

                  yield correctAnswers.reduce(function* (acc, correctAnswer) {
                    yield acc;
                    yield delay(500);
                    yield put(startPlayItemAnswer(itemExecution.item.id, correctAnswer.id));
                    yield call(playAudio, {
                      url: correctAnswer.audio || correctAnswer.generatedAudio,
                    });
                    yield put(finishPlayItemAnswer(itemExecution.item.id, correctAnswer.id));
                  }, Promise.resolve(0));
                }
              }, Promise.resolve());
            }
          }
        } catch (error) {
          yield put(enableItemExecution());
          logError({ error, flow: CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
