export const START_EXECUTION = "START_EXECUTION";
export const ADD_EXECUTION_ANSWER = "ADD_EXECUTION_ANSWER";
export const END_EXECUTION = "END_EXECUTION";
export const CLEAN_EXECUTION = "CLEAN_EXECUTION";
export const SET_NEXT_EXECUTION_ORDER = "SET_NEXT_EXECUTION_ORDER";
export const PLAY_EXECUTION_INSTRUCTION = "PLAY_EXECUTION_INSTRUCTION";
export const INCREMENT_GAME_SCORE = "INCREMENT_GAME_SCORE";

/**
 * start an execution
 *
 * @param {object} execution
 * @returns {{ type: string, payload: { execution: object } }}
 */
export const startExecution = (execution) => ({
  type: START_EXECUTION,
  payload: {
    execution,
  },
});

/**
 * save the answers in the execution
 *
 * @param {object} answer the answer
 * @returns {{ type: string, payload: { answers: [] } }}
 */
export const addExecutionAnswer = (answer) => ({
  type: ADD_EXECUTION_ANSWER,
  payload: {
    answer,
  },
});

/**
 * end the execution and store the result
 *
 * @param {object} result
 * @returns {{ type: string, payload: { result: object } }}
 */
export const endExecution = (result) => ({
  type: END_EXECUTION,
  payload: {
    result,
  },
});

/**
 * clean the execution state
 *
 * @returns {{ type: string }}
 */
export const cleanExecution = () => ({
  type: CLEAN_EXECUTION,
});

/**
 * clean the execution state
 *
 * @returns {{ type: string }}
 */
export const setNextExecutionOrder = () => ({
  type: SET_NEXT_EXECUTION_ORDER,
});

/**
 * Add item type to played instructions
 *
 * @returns {{ type: string }}
 */
export const playExecutionInstruction = (itemType) => ({
  type: PLAY_EXECUTION_INSTRUCTION,
  payload: {
    itemType,
  },
});

/**
 * increment the score by type
 *
 * @param {string} type - student or computer
 * @returns {{ type: string }}
 */
export const incrementGameScoreType = (type) => ({
  type: INCREMENT_GAME_SCORE,
  payload: {
    type,
  },
});
