import moment from "moment";
import validator from "validate.js";
import ValidationError from "../exceptions/validationError";

validator.validators.equalityIf = (value, options, key, object) => {
  if (value) {
    if (!object[options.ifProperty]) {
      return `The ${options.ifProperty} can\'t be blank`;
    }
    if (value !== object[options.ifProperty]) {
      return `The ${options.ifProperty} can\'t be different from ${key}`;
    }
  }
  return null;
};

validator.extend(validator.validators.datetime, {
  parse: function (value, options) {
    return moment(value).isValid() ? moment(value) : NaN;
  },
  format: function (value, options) {
    return moment(value).format(options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss");
  },
});

export default (schema, args) => {
  const errors = validator(args, schema);
  if (errors) {
    throw new ValidationError(errors);
  }
};
