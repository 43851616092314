import React from "react";
import PropTypes from "prop-types";
import LogRocket from "logrocket";
import Form from "student-front-commons/src/components/Form";
import MaterialTheme from "../../../core/MaterialTheme";
import withTranslation from "../../../core/withTranslation";
import HubspotForm from "../../../core/HubspotForm";
import { TASTING_CONTACT_FORM } from "../../../consts";

const TastingContactForm = (props) => (
  <MaterialTheme>
    <Form id={TASTING_CONTACT_FORM} onSubmit={props.onSubmit}>
      {({ handleSubmit, handleChange }) => (
        <HubspotForm
          formId={
            {
              en: "fd4acdf3-d1e9-4136-bbaf-3f4c0d8b26d0",
              es: "d0b78de2-8516-4531-82d4-48575b634e9d",
              pt: "aa6edb7f-924b-421e-a2ba-cced5ebd65b3",
              "pt-br": "aa6edb7f-924b-421e-a2ba-cced5ebd65b3",
            }[sessionStorage.getItem("locale")] || "fd4acdf3-d1e9-4136-bbaf-3f4c0d8b26d0"
          }
          initialValues={{
            link_logrocket_demo: LogRocket.sessionURL,
            hs_language: sessionStorage.getItem("locale"),
          }}
          onSubmit={(values) => {
            Object.keys(values).forEach((valueKey) => {
              handleChange(valueKey, values[valueKey]);
            });

            if (process.env.REACT_APP_LOG_ROCKET_KEY) {
              LogRocket.identify(sessionStorage.getItem("id"), {
                name: `${values.firstname} ${values.lastname}`,
                email: values.email,
                country: values.country,
              });
            }
            sessionStorage.setItem("tasting_email", values.email);
          }}
          afterSubmit={handleSubmit}
        />
      )}
    </Form>
  </MaterialTheme>
);

TastingContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation(TastingContactForm);
