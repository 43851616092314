import Styled from "styled-components";

const PracticeTestPanel = Styled.div`
  max-width: ${(props) => props.width || "720px"};
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
  box-shadow: 0px 1px 10px 0 rgba(0, 0, 0, 0.3);
  margin-top: ${(props) => props.marginTop || "80px"} ;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  background-color: #fff;
  box-sizing: border-box;
`;

export default PracticeTestPanel;
