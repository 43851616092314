import React from "react";
import PropTypes from "prop-types";
import Icon from "../../core/Icon";
import Separator from "../../core/Separator";
import Button from "../../core/Button";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import { GREY_5 } from "../../consts/color";

const AbilityListItem = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          width: 270,
          height: 200,
          boxSizing: "border-box",
          padding: 30,
          textAlign: "center",
          boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
          borderRadius: 3,
          backgroundColor: props.status === "FINISHED" ? primary : "#fff",
        }}
      >
        <div
          style={{
            marginTop: -10,
            marginBottom: -15,
            position: "relative",
          }}
        >
          <Icon
            size="lg"
            icon={
              {
                LISTENING: "ear-hearing",
                SPEAKING: "forum",
                READING: "book-open-variant",
                WRITING: "lead-pencil",
              }[props.type]
            }
            color={
              {
                FINISHED: "#fff",
                AVAILABLE: primary,
                BLOCKED: "rgba(96, 125, 139, 0.5)",
              }[props.status]
            }
          />
          {props.status === "FINISHED" && (
            <div
              style={{
                width: 32,
                height: 32,
                backgroundColor: "#00786A",
                borderRadius: "100%",
                position: "absolute",
                top: 0,
                right: 53,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Icon icon="check" color="#ffF" />
            </div>
          )}
        </div>
        <Separator size="md" />
        <TranslatedText
          as="h3"
          translateKey={`certificationTest.execution.ability.${props.type.toLowerCase()}`}
          style={{
            margin: 0,
            fontSize: 18,
            fontWeight: "bold",
            color: {
              FINISHED: "#fff",
              AVAILABLE: GREY_5,
              BLOCKED: "rgba(96, 125, 139, 0.5)",
            }[props.status],
          }}
        />
        <Separator size="xs" />
        <Separator size="md" />
        <TranslatedText
          as={Button}
          translateKey={`certificationTest.execution.status.${props.status.toLowerCase()}`}
          disabled={props.status !== "AVAILABLE"}
          onClick={props.onClick}
          shrink
          fontWeight="bold"
          fontSize={
            {
              FINISHED: "16px",
              AVAILABLE: "13px",
              BLOCKED: "13px",
            }[props.status]
          }
          fontColor={
            {
              FINISHED: "rgba(255, 255, 255, 0.5)",
              AVAILABLE: "#fff",
              BLOCKED: "#fff",
            }[props.status]
          }
          color={
            {
              FINISHED: "transparent",
              AVAILABLE: primary,
              BLOCKED: "rgba(96, 125, 139, 0.5)",
            }[props.status]
          }
          style={{
            textTransform: "uppercase",
          }}
        />
      </div>
    )}
  </CourseThemeContext.Consumer>
);

AbilityListItem.propTypes = {
  type: PropTypes.oneOf(["LISTENING", "SPEAKING", "READING", "WRITING"]).isRequired,
  status: PropTypes.oneOf(["FINISHED", "AVAILABLE", "BLOCKED"]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AbilityListItem;
