import React from "react";
import PropTypes from "prop-types";
import AsyncPanel from "../../core/AsyncPanel";
import Row from "../../core/Row";
import Column from "../../core/Column";
import AchievementListContainer from "./AchievementListContainer";
import VerticalDivider from "../../core/VerticalDivider";
import withTranslation from "../../core/withTranslation";
import CheckDistributorType from "../../core/CheckDistributorType";

const AchievementListPanel = (props) => (
  <AsyncPanel
    padding={props.achievements.length ? "none" : "lg"}
    noDataText={props.getTranslation("profile.achievement.noData")}
    hasData={!!props.achievements.length}
    fetching={props.fetching}
  >
    <CheckDistributorType>
      {({ isB2C }) => (
        <div className="container">
          <Row>
            {!isB2C && (
              <Column lgSize={4} mdSize={4} smSize={4} xsSize={12}>
                <AchievementListContainer type="national" achievements={props.achievements} />
                <div className="d-xs-none d-sm-flex">
                  <VerticalDivider />
                </div>
              </Column>
            )}
            <Column lgSize={isB2C ? 6 : 4} mdSize={isB2C ? 6 : 4} smSize={isB2C ? 6 : 4} xsSize={12}>
              <AchievementListContainer type="regional" achievements={props.achievements} />
              <div className="d-xs-none d-sm-flex">
                <VerticalDivider />
              </div>
            </Column>
            <Column lgSize={isB2C ? 6 : 4} mdSize={isB2C ? 6 : 4} smSize={isB2C ? 6 : 4} xsSize={12}>
              <AchievementListContainer type="school" achievements={props.achievements} />
            </Column>
          </Row>
          <Row>
            <Column lgSize={12} mdSize={12} smSize={12} xsSize={12}>
              <AchievementListContainer type="student" achievements={props.achievements} />
            </Column>
          </Row>
        </div>
      )}
    </CheckDistributorType>
  </AsyncPanel>
);

AchievementListPanel.propTypes = {
  fetching: PropTypes.bool.isRequired,
  achievements: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default withTranslation(AchievementListPanel);
