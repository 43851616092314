import React, { useContext, useMemo } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Flow from "student-front-commons/src/components/Flow";
import get from "lodash/get";
import CloseExecutionModal from "../../core/CloseExecutionModal";
import { CLOSE_UNIT_EXECUTION_FLOW, USER_AWAY_TIMEOUT_FLOW } from "../../consts";
import { useParams } from "react-router-dom";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { useEntity } from "student-front-commons/src/hooks";
import { UNIT_SCHEMA } from "student-front-commons/src/schemas";
import { useSelector } from "react-redux";
import { getCompanyTimeToAllowContinueUnit } from "student-front-commons/src/selectors/entity";

function CloseUnitExecutionContainer() {
  const whiteLabelContext = useContext(WhitelabelContext);
  const companyTimeToAllowContinueUnit = useSelector(getCompanyTimeToAllowContinueUnit);
  const params = useParams();
  const unit = useEntity(UNIT_SCHEMA, params.idUnit);
  const unitType = useEntity("unitType", unit.type);

  const timeToAllowContinueUnit = useMemo(() => {
    return companyTimeToAllowContinueUnit ?? whiteLabelContext.timeToAllowContinueUnit;
  }, [companyTimeToAllowContinueUnit, whiteLabelContext?.timeToAllowContinueUnit]);

  return (
    <NavigationPrompt
      disableNative={true}
      renderIfNotActive={true}
      when={(from, to) => {
        if (get(to, "state.systemNavigation", false)) {
          return false;
        }

        if (
          unitType.name.toLowerCase() === "content video" &&
          unit?.defaultFinishedAt &&
          (/\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units/.test(to.pathname) ||
            /\/course\/[0-9a-fA-F]{24}\/videos/.test(to.pathname))
        ) {
          return false;
        }

        return (
          !to ||
          (!/\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise\/[0-9a-fA-F]{24}\/result/.test(
            to.pathname
          ) &&
            !["/exercise-not-found", "/microphone-test", "/exercise-block-due-time"].some(
              (path) => to.pathname === path
            ))
        );
      }}
    >
      {({ isActive, onCancel, onConfirm }) =>
        isActive && (
          <Flow
            autoStart={false}
            id={CLOSE_UNIT_EXECUTION_FLOW}
            params={{
              course: params.idCourse,
            }}
          >
            {({ handleStart: handleClose }) => (
              <Flow autoStart={false} id={USER_AWAY_TIMEOUT_FLOW}>
                {({ handleStart: handleStartUserAway }) => (
                  <CloseExecutionModal
                    message={
                      !timeToAllowContinueUnit || unitType.name.toLowerCase() === "content video"
                        ? "closeExecution.noProgressLossMessage"
                        : "closeExecution.defaultMessage"
                    }
                    timeToAllowContinueUnit={timeToAllowContinueUnit}
                    onConfirm={() => {
                      onConfirm();
                      handleClose();
                    }}
                    onCancel={() => {
                      handleStartUserAway();
                      onCancel();
                    }}
                  />
                )}
              </Flow>
            )}
          </Flow>
        )
      }
    </NavigationPrompt>
  );
}

export default CloseUnitExecutionContainer;
