import React from "react";
import PropTypes from "prop-types";
import { WHITE } from "../consts/color";
import RippleEffect from "./RippleEffect";

const ExtraCourseButton = (props) => (
  <button
    onClick={props.onClick && props.onClick}
    disabled={props.disabled}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: {
        xs: 30,
        sm: 55,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      height: {
        xs: 30,
        sm: 55,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      minWidth: {
        xs: 30,
        sm: 55,
        md: 70,
        mdg: 85,
        lg: 100,
        xlg: 120,
      }[props.size],
      backgroundColor: "#fff",
      border: `3px solid #013666`,
      borderWidth: {
        xs: 2,
        sm: 2.5,
        md: 3,
        mdg: 3,
        lg: 3,
        xlg: 4,
      }[props.size],
      boxSizing: "border-box",
      outline: "none",
      borderRadius: "50%",
      cursor: props.onClick ? "pointer" : "inherited",
      color: WHITE,
      fontSize: {
        xs: 14.2,
        sm: 15.2,
        md: 28,
        mdg: 38,
        lg: 50,
        xlg: 60,
      }[props.size],
      fontWeight: 700,
      position: "relative",
      overflow: "hidden",
    }}
  >
    <img
      src={`${process.env.REACT_APP_FILES_URL}/${props.iconUrl}`}
      style={{ width: "auto", height: "100%", objectFit: "cover" }}
      alt="extra-course-icon"
    />
  </button>
);

ExtraCourseButton.propTypes = {
  disabled: PropTypes.bool,
  iconUrl: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["xs", "sm", "md", "mdg", "lg", "xlg"]),
};

ExtraCourseButton.defaultProps = {
  onClick: null,
  disabled: false,
  size: "md",
};

export default RippleEffect(ExtraCourseButton);
