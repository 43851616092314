import React from "react";
import PropTypes from "prop-types";
import { WHITE } from "../consts/color";
import Icon from "./Icon";

const BackgroundedIcon = (props) => (
  <span
    style={{
      width: props.size,
      height: props.size,
      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: props.backgroundColor,
      border: props.border ? `2px solid ${props.border}` : "none",
    }}
  >
    <Icon icon={props.icon} color={props.iconColor} size={props.iconSize} />
  </span>
);

BackgroundedIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  size: PropTypes.number,
  iconColor: PropTypes.string,
  iconSize: Icon.propTypes.size,
  border: PropTypes.string,
};

BackgroundedIcon.defaultProps = {
  border: null,
  iconColor: WHITE,
  iconSize: "xs",
  size: 30,
};

export default BackgroundedIcon;
