import React from "react";
import moment from "moment";
import get from "lodash/get";
import { getStatsByPeriodAndGroup } from "student-front-commons/src/services/studentStatsService";
import FetchData from "student-front-commons/src/components/FetchData";
import Entity from "student-front-commons/src/components/Entity";
import WeekStatsPanel from "./WeekStatsPanel";

const WeekStatsPanelContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => (
      <Entity name="schoolClass">
        {({ getById: getSchoolClassById }) => {
          const profile = getProfileById(sessionStorage.getItem("id"));
          const schoolClass = getSchoolClassById(profile.schoolClass);

          if (!profile.weeklyHoursRequired && !schoolClass.weeklyHoursRequired) {
            return null;
          }
          return (
            <FetchData
              service={getStatsByPeriodAndGroup}
              params={{
                id: sessionStorage.getItem("id"),
                from: moment().startOf("isoWeek").format("YYYY-MM-DD"),
                to: moment().endOf("isoWeek").format("YYYY-MM-DD"),
                group: "week",
              }}
            >
              {({ data, isFetching }) => (
                <WeekStatsPanel
                  isFetching={isFetching}
                  requiredHours={
                    profile.apiVersion === "V2" ? profile.weeklyHoursRequired : schoolClass.weeklyHoursRequired
                  }
                  value={get(data, "[0].hours", 0)}
                />
              )}
            </FetchData>
          );
        }}
      </Entity>
    )}
  </Entity>
);

export default WeekStatsPanelContainer;
