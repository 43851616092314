import { CLEAN_MASTERY_TEST_RESULT, MERGE_MASTERY_TEST_RESULT } from "../consts/actions";

export const requestMergeMasteryTestResult = (masteryTestResult) => ({
  type: MERGE_MASTERY_TEST_RESULT,
  payload: {
    masteryTestResult,
  },
});

export const requestCleanMasteryTestResult = () => ({
  type: CLEAN_MASTERY_TEST_RESULT,
});
