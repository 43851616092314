import React, { useContext } from "react";
import PropTypes from "prop-types";
import min from "lodash/min";
import Modal from "./Modal";
import VideoPlayer from "./VideoPlayer";
import Button from "./Button";
import CourseThemeContext from "./CourseThemeContext";
import TranslatedText from "./TranslatedText";
import FlexColumn from "./FlexColumn";
import { WHITE } from "../consts/color";
import CircularLoading from "./CircularLoading";
import { useSelector } from "react-redux";
import { getInstructionVideo } from "student-front-commons/src/selectors/configuration";

const SceneVideoIntroduction = ({ scene, onEnd }) => {
  const courseThemeContext = useContext(CourseThemeContext);

  const instructionVideo = useSelector(getInstructionVideo(scene));

  return (
    <Modal
      style={{
        maxWidth: 800,
        maxHeight: "95%",
        padding: 0,
      }}
    >
      <React.Fragment>
        <FlexColumn
          backgroundColor={courseThemeContext.primary}
          height={90}
          justifyContent="center"
          alignItems="center"
          boxShadow="0px 1px 3px 0 rgba(0, 0, 0, 0.2)"
        >
          <span
            style={{
              fontSize: 26,
              fontWeight: "bold",
              color: WHITE,
              margin: 0,
            }}
          >
            {instructionVideo?.title}
          </span>
        </FlexColumn>
        <div
          style={{
            padding: 20,
            maxWidth: 800,
            maxHeight: 460,
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          {!instructionVideo && (
            <FlexColumn
              position="absolute"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              zIndex={0}
              top={0}
              left={0}
            >
              <CircularLoading size="md" rgb={{ r: 237, g: 237, b: 237 }} />
            </FlexColumn>
          )}
          {!!instructionVideo && (
            <FlexColumn zIndex={1} position="relative">
              <VideoPlayer
                width={min([760, (window.innerHeight * 0.9 - 180) * 1.7])}
                height={window.innerHeight * 0.9 - 180}
                autoStart
                src={instructionVideo?.video}
                onVideoEnd={() => false}
              />
            </FlexColumn>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: 80,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <TranslatedText
            translateKey="videoIntroduction.button"
            as={Button}
            onClick={onEnd}
            style={{
              fontSize: 14,
              color: WHITE,
              backgroundColor: courseThemeContext.primary,
              width: "auto",
              textTransform: "uppercase",
              borderRadius: 20,
              padding: "10px 35px",
            }}
          />
        </div>
      </React.Fragment>
    </Modal>
  );
};

SceneVideoIntroduction.propTypes = {
  onEnd: PropTypes.func.isRequired,
  scene: PropTypes.oneOf(["WELCOME", "MODULE_LIST", "UNIT_LIST"]).isRequired,
};

export default SceneVideoIntroduction;
