import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { startFlow } from "../actions/flow";

class Flow extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired,
    handleStart: PropTypes.func.isRequired,
    isPending: PropTypes.bool.isRequired,
    autoStart: PropTypes.bool,
    params: PropTypes.shape({}),
  };

  static defaultProps = {
    params: null,
    isPending: false,
    autoStart: false,
  };

  componentDidMount() {
    if (this.props.autoStart) {
      this.props.handleStart();
    }
  }

  render() {
    return this.props.children({
      isPending: this.props.isPending,
      handleStart: this.props.handleStart,
      getParams: () => this.props.flowParams,
    });
  }
}

const mapStateToProps = (state, ownProps) => ({
  isPending: get(state.flows[ownProps.id], "isPending", ownProps.autoStart),
  flowParams: get(state.flows[ownProps.id], "params", null),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleStart: () => dispatch(startFlow(ownProps.id, ownProps.params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Flow);
