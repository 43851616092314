import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * get all items to practice before placement test
 *
 * @returns {Promise<*>}
 */
export const getPracticeTest = async () => {
  return await safeCall(
    async () => {
      return await request({
        url: "practice-test-items",
        method: "get",
      });
    },
    "error_get_practice_test",
    "Unexpected error to load Practice test items."
  );
};

/**
 * List all student placement tests
 *
 * @returns {Promise<*>}
 */
export const getPlacementTests = async () => {
  return await safeCall(
    async () => {
      return await request({
        url: "placement-tests",
        method: "get",
      });
    },
    "error_get_placement_tests",
    "Unexpected error to load Placement tests."
  );
};

/**
 * start a Placement test for the student
 *
 * @param {Object} payload - The object with all the params
 *
 * @returns {Promise<*>}
 */
export const startPlacementTest = async (payload) => {
  validate(
    {
      byLink: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: "placement-tests/start",
        method: "post",
        data: {
          byLink: payload.byLink,
        },
      });
    },
    "error_start_placement_test",
    "Unexpected error to start Placement Test execution."
  );
};

/**
 * save a Placement test item execution for the student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.placementTestExecution - the placement test execution id
 * @param {!string} payload.item - the unitItem id
 * @param {!boolean} payload.correct - if answer is correct or not
 * @param {!boolean} payload.answer - the student answer
 *
 * @returns {Promise<*>}
 */
export const savePlacementTestItemExecution = async (payload) => {
  validate(
    {
      placementTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      item: {
        presence: {
          allowEmpty: false,
        },
      },
      correct: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `placement-tests/${payload.placementTestExecution}/answers`,
        method: "patch",
        data: {
          item: payload.item,
          answer: payload.answer,
          correct: payload.correct,
        },
      });
    },
    "error_save_placement_test_item_answer",
    "Unexpected error to save the answer."
  );
};

/**
 * end a Placement test execution for the student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 * @param {string} payload.placementTestExecution - the placement test execution id
 * @param {!string} payload.reachedLevel - the placement test level id
 * @param {!string} payload.stopReason - Why the test stoped
 *
 * @returns {Promise<*>}
 */
export const endPlacementTestExecution = async (payload) => {
  validate(
    {
      placementTestExecution: {
        presence: {
          allowEmpty: false,
        },
      },
      stopReason: {
        presence: {
          allowEmpty: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `placement-tests/${payload.placementTestExecution}/end`,
        method: "put",
        data: {
          reachedLevel: payload.reachedLevel,
          stopReason: payload.stopReason,
        },
      });
    },
    "error_end_placement_test_execution",
    "Unexpected error to end the Placement test execution."
  );
};
