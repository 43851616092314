import { Howl } from "howler";
import sample from "lodash/sample";
import kebabCase from "lodash/kebabCase";
import { call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endMasteryTestExecution } from "student-front-commons/src/services/masteryTestExecutionService";
import { requestMergeMasteryTestResult } from "../actionCreators/masteryTestResult";
import { getProfile } from "student-front-commons/src/services/profileService";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import Sound1 from "../audios/end-unit-1.mp3";
import Sound2 from "../audios/end-unit-2.mp3";
import Sound3 from "../audios/end-unit-3.mp3";
import browserHistory from "../browserHistory";
import { END_MASTERY_TEST_EXECUTION_FLOW } from "../consts";
import { addSentryUserAction, logError } from "../util";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { getCourses } from "student-front-commons/src/services/courseService";

const sentryUserAction = { mainComponent: "endMasteryTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(END_MASTERY_TEST_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(END_MASTERY_TEST_EXECUTION_FLOW));
    try {
      const result = yield call(endMasteryTestExecution, {
        module: flow.params.masteryTest,
        masteryTest: flow.params.masteryTest,
        masteryTestExecution: flow.params.masteryTestExecution,
      });
      yield put(requestMergeMasteryTestResult(result));

      if (result.score >= result.scoreToPass) {
        const audio = new Howl({
          src: [sample([Sound1, Sound2, Sound3])],
          autoplay: false,
          loop: false,
          volume: 1,
        });

        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Mastery Test Passed.`,
        });

        audio.once("end", () => {
          audio.unload();
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Mastery Test audios cleared`,
          });
        });
        audio.play();
      }

      if (result.courseStatus === "COURSE_ENDED") {
        const profileResult = yield call(getProfile, {
          id: sessionStorage.getItem("id"),
          role: "STUDENT",
        });
        yield put(mergeEntities(profileResult.entities));

        const courses = yield call(getCourses, {
          id: sessionStorage.getItem("id"),
        });
        yield put(mergeEntities(courses.entities));
      }

      yield spawn(function* () {
        const modules = yield call(getModulesByCourse, {
          id: sessionStorage.getItem("id"),
          course: flow.params.course,
        });
        yield put(mergeEntities(modules.entities));
      });
    } catch (error) {
      if (error && error.data && error.data.message) {
        yield call(browserHistory.push, `/${kebabCase(error.data.message.toLowerCase())}`);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Mastery Test error. Navigate to: /${kebabCase(error.data.message.toLowerCase())}`,
        });
      } else {
        logError({ error, flow: END_MASTERY_TEST_EXECUTION_FLOW });
        yield call(browserHistory.push, "/error-end-mastery");
      }
    } finally {
      yield put(endFlow(END_MASTERY_TEST_EXECUTION_FLOW));
    }
  });
}
