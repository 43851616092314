import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import NewMessageForm from "./NewMessageForm";
import { CREATE_NEW_MESSAGE_CHANNEL_FLOW } from "../../consts";

const NewMessageFormContainer = () => (
  <Flow autoStart={false} id={CREATE_NEW_MESSAGE_CHANNEL_FLOW}>
    {({ isPending, handleStart }) => <NewMessageForm onSubmit={handleStart} isSubmitting={isPending} />}
  </Flow>
);

export default NewMessageFormContainer;
