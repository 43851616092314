import get from "lodash/get";
import orderBy from "lodash/orderBy";
import { call, cancelled, delay, put, race, select, take, takeLatest, spawn } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { getFlowStart, getFlowEnd, getFlow } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { playAudio, stopAudio } from "../../stores/audio-store";
import {
  ACHIEVEMENT_EXECUTION_FORM,
  CLOSE_BONUS_TEST_EXECUTION_FLOW,
  CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  CLOSE_PLACEMENT_TEST_EXECUTION_FLOW,
  CLOSE_PRACTICE_TEST_EXECUTION_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  END_BONUS_TEST_EXECUTION_FLOW,
  END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  END_MASTERY_TEST_EXECUTION_FLOW,
  END_PLACEMENT_TEST_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  SYSTEM_MESSAGE_FLOW,
} from "../../consts";
import { logError } from "../../util";

export default function* () {
  yield takeLatest(getFlowStart(OLD_PLAY_ITEM_AUDIO_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      closePractice: take(getFlowStart(CLOSE_PRACTICE_TEST_EXECUTION_FLOW)),
      closePlacement: take(getFlowStart(CLOSE_PLACEMENT_TEST_EXECUTION_FLOW)),
      closeBonus: take(getFlowStart(CLOSE_BONUS_TEST_EXECUTION_FLOW)),
      closeMastery: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      closeCertification: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      endPlacement: take(getFlowStart(END_PLACEMENT_TEST_EXECUTION_FLOW)),
      endBonus: take(getFlowStart(END_BONUS_TEST_EXECUTION_FLOW)),
      endMastery: take(getFlowStart(END_MASTERY_TEST_EXECUTION_FLOW)),
      endCertification: take(getFlowStart(END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      call: call(function* () {
        const flow = yield select(getFlow(OLD_PLAY_ITEM_AUDIO_FLOW));
        try {
          const achievementExecutionForm = yield select(getForm(ACHIEVEMENT_EXECUTION_FORM));
          if (achievementExecutionForm && achievementExecutionForm.values) {
            yield put(changeFormValue(ACHIEVEMENT_EXECUTION_FORM, "noRepeatSequence", 0));
            yield put(changeFormValue(ACHIEVEMENT_EXECUTION_FORM, "showRepeatUsageTip", false));
          }

          const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          const itemTypeKey = itemExecutionForm.values.associativeItem.item.type.key;
          const isInitialPlay = get(flow.params, "initialPlay", false);

          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          if (!isInitialPlay && (!!get(flow.params, "isPlacementRepeat", false) || itemTypeKey === "DICTATION")) {
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
          }

          const rate = get(flow.params, "isSlowRepeat", false) ? 0.7 : 1;

          if (
            [
              "SINGLE_CHOICE_TEXT",
              "SINGLE_CHOICE_AUDIO",
              "SINGLE_CHOICE_IMAGE",
              "MULTIPLE_CHOICE_TEXT",
              "DICTATION",
              "PRESENTATION",
              "TRUE_FALSE",
              "SPEECH_PRACTICE",
              "AUDIO_LONG",
              "SPEECH_PRACTICE_SPEECHLESS",
              itemExecutionForm.values.recordCount > 0 && "PRONUNCIATION",
            ].find((typeKey) => typeKey === itemTypeKey)
          ) {
            if (!isInitialPlay && ["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].includes(itemTypeKey)) {
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
            }

            yield put(
              changeFormValue(ITEM_EXECUTION_FORM, "playingId", itemExecutionForm.values.associativeItem.item.id)
            );

            if (itemTypeKey === "AUDIO_LONG") {
              if (!flow.params || !flow.params.initialPlay) {
                yield put(
                  changeFormValue(ITEM_EXECUTION_FORM, "repeatCount", itemExecutionForm.values.repeatCount + 1)
                );
              }
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSlowRepeat", get(flow.params, "isSlowRepeat", false)));
            }

            yield call(playAudio, {
              url:
                itemExecutionForm.values.associativeItem.item.audio ||
                itemExecutionForm.values.associativeItem.item.generatedAudio,
              rate,
            });

            if (itemTypeKey === "SINGLE_CHOICE_AUDIO") {
              const optionAudios = orderBy(yield select((state) => state.configurations.optionAudios), "text", "asc");

              yield itemExecutionForm.values.associativeItem.item.linkedAnswers.reduce(function* (acc, answer, index) {
                yield acc;
                yield delay(500);
                yield put(changeFormValue(ITEM_EXECUTION_FORM, "playingId", answer.id));
                yield call(playAudio, {
                  url: optionAudios[index].path || optionAudios[index].generatedAudio,
                  rate,
                });
                yield call(playAudio, {
                  url: answer.audio || answer.generatedAudio,
                  rate,
                });
              }, Promise.resolve(0));
            }

            if (itemTypeKey !== "AUDIO_LONG" && (!flow.params || !flow.params.initialPlay)) {
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "repeatCount", itemExecutionForm.values.repeatCount + 1));
            }
          }
        } catch (error) {
          if (
            typeof error === "string" &&
            (error === "required-user-click" || error.indexOf("Playback was unable to start") > -1)
          ) {
            yield spawn(function* () {
              yield put(
                startFlow(SYSTEM_MESSAGE_FLOW, {
                  icon: "play-circle-outline",
                  message: "error.error_browser_block_autoplay",
                  button: "continue",
                })
              );
              yield take(getFlowEnd(SYSTEM_MESSAGE_FLOW));
              yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, flow.params));
            });
          } else {
            logError({ error, flow: OLD_PLAY_ITEM_AUDIO_FLOW });
            yield put(
              startFlow(SYSTEM_MESSAGE_FLOW, {
                message: "error.error_play_audio",
              })
            );
          }
          stopAudio();
        } finally {
          if (yield cancelled()) {
            stopAudio();
          } else {
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "playingId", ""));
            yield put(changeFormValue(ITEM_EXECUTION_FORM, "isSlowRepeat", false));
            yield put(endFlow(OLD_PLAY_ITEM_AUDIO_FLOW));
          }
        }
      }),
    });
  });
}
