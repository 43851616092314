import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Flow from "student-front-commons/src/components/Flow";
import get from "lodash/get";
import CloseExecutionModal from "../../../core/CloseExecutionModal";
import { CLOSE_PLACEMENT_TEST_EXECUTION_FLOW } from "../../../consts";

const ClosePlacementTestExecutionContainer = () => (
  <NavigationPrompt
    disableNative={false}
    renderIfNotActive={true}
    when={(from, to) => {
      if (get(to, "state.systemNavigation", false)) {
        return false;
      }

      return !to || !["/placement-test-score", "/placement-test-score-analysis"].some((path) => to.pathname === path);
    }}
  >
    {({ isActive, onCancel, onConfirm }) =>
      isActive && (
        <Flow autoStart={false} id={CLOSE_PLACEMENT_TEST_EXECUTION_FLOW}>
          {({ handleStart: handleClose }) => (
            <CloseExecutionModal
              onConfirm={() => {
                handleClose();
                onConfirm();
              }}
              onCancel={onCancel}
            />
          )}
        </Flow>
      )
    }
  </NavigationPrompt>
);

export default ClosePlacementTestExecutionContainer;
