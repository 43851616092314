import React from "react";
import PropTypes from "prop-types";
import QuestionLabel from "../QuestionLabel";
import Separator from "../Separator";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW, SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

const SingleChoiceTextRender = (props) => {
  const [isSaving] = useFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW);

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const linkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const selectedAnswer = useSelector(getItemExecutionPropById(props.itemId, "answer"));
  const currentInstructionStep = useSelector(getInstructionStep);
  const [, startCheckUnitItemExecutionAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const dispatch = useDispatch();

  return (
    <ExerciseItemPanel>
      {props.abilityType === "READING" && (
        <div
          style={{
            paddingLeft: 20,
          }}
        >
          <Separator size="md" />
          <QuestionLabel text={itemText} />
          <Separator size="md" />
        </div>
      )}
      {linkedAnswers.map((answer) => (
        <div key={`answer-${answer.id}`}>
          <div className="selectExerciseSingleChoiceText">
            <ExerciseButton
              disabled={isDisabled || !!currentInstructionStep || isExecutionValidated}
              highlight={playingId === answer.id || ((isSaving || isExecutionValidated) && answer.correct)}
              isWrong={(isSaving || isExecutionValidated) && !answer.correct && selectedAnswer === answer.text}
              onClick={() => {
                dispatch(addItemExecutionAnswer(props.itemId, { answer }));
                startCheckUnitItemExecutionAnswerFlow();
              }}
            >
              <QuestionLabel
                text={answer.text}
                cursor="inherit"
                textAlign="left"
                fontSize={{ sm: 16, md: 22 }[props.size]}
              />
            </ExerciseButton>
          </div>
          <Separator size="xs" />
        </div>
      ))}
    </ExerciseItemPanel>
  );
};

export default function SingleChoiceTextItem(props) {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SingleChoiceTextRender key={id} itemId={id} {...props} />);
}

SingleChoiceTextItem.propTypes = {
  abilityType: PropTypes.oneOf(["BOTH", "READING"]),
  size: PropTypes.oneOf(["sm", "md"]).isRequired,
};

SingleChoiceTextItem.defaultProps = {
  abilityType: "BOTH",
  size: "md",
};
