import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import TranslatedText from "../../core/TranslatedText";

const ButtonWithHover = Styled.button`
  position: relative;
  transition: all ease-in-out 150ms;

  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 4px 10px 0px rgba(0, 0, 0, 0.3)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

const MasteryTestButton = (props) => (
  <TranslatedText
    as={ButtonWithHover}
    translateKey="masteryTest.start"
    disabled={props.isDisabled}
    onClick={props.onClick}
    style={{
      backgroundColor: "white",
      boxShadow: "inset 0px -4px 0px rgba(0, 0, 0, 0.1)",
      borderRadius: 50,
      width: "max-content",
      outline: "none",
      border: "none",
      padding: "7px 40px",
      fontWeight: "bold",
      color: "#EF8C3B",
      fontSize: 12,
      lineHeight: "14px",
      textTransform: "uppercase",
    }}
  />
);

MasteryTestButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default MasteryTestButton;
