import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LineChart from "../../core/LineChart";
import withTranslation from "../../core/withTranslation";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Async from "../../core/Async";

const WeekPerformanceChart = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <Async fetching={props.isFetching} color="dark" size="sm">
        <LineChart
          data={props.data}
          labels={props.data.map((item) => item.date)}
          dataFormat={[
            {
              color: primaryColor,
              label: props.getTranslation("points"),
              yAxisID: "points",
              valueRender: (item) => item.points,
              tooltipRender: (args) => {
                if (args.yLabel) {
                  return ` ${moment(args.xLabel, "YYYY-WW").startOf("isoWeek").format("YYYY/MM/DD")} - ${moment(
                    args.xLabel,
                    "YYYY-WW"
                  )
                    .endOf("isoWeek")
                    .format("YYYY/MM/DD")}: ${args.yLabel} ${props.getTranslation("points")}`;
                }
                return props.getTranslation("profile.performance.noPointsWeekChartData", { week: args.xLabel });
              },
            },
            {
              color: "#9e9e9e",
              label: props.getTranslation("hours"),
              yAxisID: "hours",
              valueRender: (item) => item.hours,
              tooltipRender: (args) => {
                if (args.yLabel) {
                  return ` ${moment(args.xLabel, "YYYY-WW").startOf("isoWeek").format("YYYY/MM/DD")} - ${moment(
                    args.xLabel,
                    "YYYY-WW"
                  )
                    .endOf("isoWeek")
                    .format("YYYY/MM/DD")}: ${moment
                    .duration(args.yLabel, "hours")
                    .format("hh:mm", { trim: false })} ${props.getTranslation("hours")}`;
                }
                return props.getTranslation("profile.performance.noHoursWeekChartData", { week: args.xLabel });
              },
            },
          ]}
          yAxes={[
            {
              type: "linear",
              display: "true",
              position: "left",
              id: "points",
            },
            {
              type: "linear",
              display: "true",
              position: "right",
              id: "hours",
              ticks: {
                callback: (label) => {
                  return moment.duration(label, "hours").format("hh:mm", { trim: false });
                },
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ]}
        />
      </Async>
    )}
  </WhitelabelContext.Consumer>
);

WeekPerformanceChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      points: PropTypes.number,
      hours: PropTypes.number,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default withTranslation(WeekPerformanceChart);
