import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import CourseThemeContext from "../../core/CourseThemeContext";
import { useEntity } from "student-front-commons/src/hooks";
import { UNIT_TYPE_SCHEMA } from "student-front-commons/src/schemas";
import AudioComprehensionIcon from "../../core/unit-type-icons/AudioComprehensionIcon";
import VideoComprehensionIcon from "../../core/unit-type-icons/VideoComprehensionIcon";
import AnsweringQuestionImageIcon from "../../core/unit-type-icons/AnsweringQuestionImageIcon";
import AnsweringQuestionIcon from "../../core/unit-type-icons/AnsweringQuestionIcon";
import DictationIcon from "../../core/unit-type-icons/DictationIcon";
import GapFillIcon from "../../core/unit-type-icons/GapFillIcon";
import GrammarIcon from "../../core/unit-type-icons/GrammarIcon";
import GrammarCheckIcon from "../../core/unit-type-icons/GrammarCheckIcon";
import MultipleCompletePhraseIcon from "../../core/unit-type-icons/MultipleCompletePhraseIcon";
import PresentationIcon from "../../core/unit-type-icons/PresentationIcon";
import PronunciationIcon from "../../core/unit-type-icons/PronunciationIcon";
import SpeechPracticeIcon from "../../core/unit-type-icons/SpeechPracticeIcon";
import TrueFalseIcon from "../../core/unit-type-icons/TrueFalseIcon";
import UnscramblePhraseDragandDropIcon from "../../core/unit-type-icons/UnscramblePhraseDragandDropIcon";
import UnscramblePhraseSpeechRecognitionIcon from "../../core/unit-type-icons/UnscramblePhraseSpeechRecognitionIcon";
import UnscrambleTextIcon from "../../core/unit-type-icons/UnscrambleTextIcon";
import VocabularyIcon from "../../core/unit-type-icons/VocabularyIcon";
import DialogueIcon from "../../core/unit-type-icons/DialogueIcon";
import { snakeCase } from "lodash";

function UnitTypeIcon({ isDisabled, isReview, type, height, width }) {
  const { primary } = useContext(CourseThemeContext);
  const unitType = useEntity(UNIT_TYPE_SCHEMA, type);

  const color = useMemo(() => {
    if (isDisabled) {
      return "#979797";
    }
    if (isReview) {
      return "#5923C9";
    }
    return primary;
  }, [primary, isDisabled, isReview]);

  const backgroundColor = useMemo(() => {
    if (isDisabled) {
      return "#ECECEC";
    }
    return `${color}1A`;
  }, [isDisabled, color]);

  const Icon = useMemo(() => {
    return React.createElement(
      {
        audio_comprehension: AudioComprehensionIcon,
        video_comprehension: VideoComprehensionIcon,
        answering_question_image: AnsweringQuestionImageIcon,
        answering_question_text: AnsweringQuestionIcon,
        dictation: DictationIcon,
        gap_fill: GapFillIcon,
        grammar: GrammarIcon,
        grammar_check: GrammarCheckIcon,
        multiple_complete_phrase: MultipleCompletePhraseIcon,
        presentation: PresentationIcon,
        presentation_vocabulary: PresentationIcon,
        pronunciation: PronunciationIcon,
        speech_practice: SpeechPracticeIcon,
        true_or_false: TrueFalseIcon,
        unscramble_phrase_drag_and_drop: UnscramblePhraseDragandDropIcon,
        unscramble_phrase_speech_recognition: UnscramblePhraseSpeechRecognitionIcon,
        unscramble_text: UnscrambleTextIcon,
        vocabulary: VocabularyIcon,
        vocabulary_meanings: VocabularyIcon,
        dialogue: DialogueIcon,
        demo: PresentationIcon,
      }[snakeCase(unitType.name)] || "div",
      {
        color,
        style: {
          width: `calc(${width}px - 40%)`,
        },
      }
    );
  }, [unitType, width, isDisabled, color]);

  return (
    <div
      style={{
        outline: "none",
        border: "none",
        width: width,
        height: height,
        borderRadius: width,
        boxSizing: "border-box",
        display: "flex",
        flexShrink: 0,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor,
      }}
    >
      {Icon}
    </div>
  );
}

UnitTypeIcon.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isReview: PropTypes.bool,
  type: PropTypes.string.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
};

UnitTypeIcon.defaultProps = {
  height: 80,
  width: 80,
};

export default UnitTypeIcon;
