import orderBy from "lodash/orderBy";
import sampleSize from "lodash/sampleSize";
import shuffle from "lodash/shuffle";
import { all, call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { getCertificationTestItemsByAbility } from "student-front-commons/src/services/certificationTestExecutionService";
import {
  addImageDataToItems,
  addSentryUserAction,
  addSoundToItems,
  getItemTypeInstructionAudio,
  logError,
} from "../util";
import {
  CERTIFICATION_TEST_ABILITY_EXECUTION_FORM,
  GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW,
  START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
} from "../consts";
import { getEntityById } from "student-front-commons/src/selectors/entity";

const sentryUserAction = { mainComponent: "startCertificationTestAbilityExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW), function* () {
    try {
      const flow = yield select(getFlow(START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW));

      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));

      const abilityResult = yield call(getCertificationTestItemsByAbility, {
        id: sessionStorage.getItem("id"),
        certificationTest: flow.params.certificationTest,
        ability: flow.params.ability,
      });

      let itemsToExecute = orderBy(abilityResult.items, ["order"], ["asc"]).reduce(
        (acc, courseAbility) => [
          ...acc,
          ...sampleSize(
            courseAbility.items.map((item) => ({
              ability: flow.params.ability,
              item: {
                ...item,
                answers: shuffle(item.answers),
                type: {
                  ...item.type,
                  initialInstructionSound: getItemTypeInstructionAudio(item.type, "INITIAL", profile.locale),
                },
              },
            })),
            courseAbility.itemsToShow
          ),
        ],
        []
      );

      yield all([
        ...addImageDataToItems(
          itemsToExecute.slice(
            abilityResult.answers.length ? abilityResult.answers.length - 1 : 0,
            abilityResult.answers.length + 5
          )
        ),
        ...(flow.params.ability !== "READING"
          ? addSoundToItems(
              itemsToExecute.slice(
                abilityResult.answers.length ? abilityResult.answers.length - 1 : 0,
                abilityResult.answers.length + 5
              )
            )
          : []),
      ]);

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "TranslatedText",
        action: `Added sounds and images to Items`,
      });

      yield put(
        initForm(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM, {
          certificationTestItems: itemsToExecute,
          answers: abilityResult.answers,
          certificationTest: flow.params.certificationTest,
          ability: flow.params.ability,
          currentIndex: abilityResult.answers.length - 1,
          playedInstructions: [],
        })
      );

      yield spawn(function* () {
        yield all([
          ...addImageDataToItems(itemsToExecute.slice(abilityResult.answers.length + 5)),
          ...(flow.params.ability !== "READING"
            ? addSoundToItems(itemsToExecute.slice(abilityResult.answers.length + 5))
            : []),
        ]);
      });

      yield put(startFlow(GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW));
    } catch (error) {
      logError({ error, flow: START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW });
      // yield put(startFlow(HANDLE_ERROR_FLOW, {
      //   error: error.message,
      //   retryFlow: {
      //     id: START_MASTERY_TEST_EXECUTION_FLOW,
      //     params: flow.params,
      //   }
      // }));
    } finally {
      yield put(endFlow(START_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW));
    }
  });
}
