import trim from "lodash/trim";

const isUpdated = (initialValue) => (value) => {
  if (!value || !String(value).trim() || trim(value) === trim(initialValue)) {
    return "Please update with a valid information";
  }
  return undefined;
};

export default isUpdated;
