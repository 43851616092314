import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useEntityList } from "student-front-commons/src/hooks";
import orderBy from "lodash/orderBy";
import Separator from "../Separator";
import MasteryTestListItem from "./MasteryTestListItem";
import FlexColumn from "../FlexColumn";

const MasteryTestList = ({ moduleId }) => {
  const masteryTests = useEntityList("masteryTest");

  const moduleMasteryTests = useMemo(() => {
    return orderBy(
      masteryTests.filter((masteryTest) => masteryTest.module === moduleId),
      ["group"],
      ["asc"]
    );
  }, [moduleId, masteryTests.map((m) => m.id).join("")]);

  return (
    <FlexColumn>
      {moduleMasteryTests.map((masteryTest) => (
        <div key={`mastery-test-item${masteryTest.id}`}>
          <Separator size="xs" />
          <MasteryTestListItem masteryTest={masteryTest} />
        </div>
      ))}
    </FlexColumn>
  );
};

MasteryTestList.propTypes = {
  moduleId: PropTypes.string.isRequired,
  expandable: PropTypes.bool,
};

MasteryTestList.defaultProps = {
  expandable: false,
};

export default MasteryTestList;
