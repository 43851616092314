import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import CompleteProfileForm from "./CompleteProfileForm";
import { COMPLETE_PROFILE_FLOW } from "../../consts";

const CompleteProfileFormContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));
      return (
        <Flow id={COMPLETE_PROFILE_FLOW}>
          {({ isPending, handleStart }) => (
            <CompleteProfileForm onSubmit={handleStart} isSubmitting={isPending} initialValues={profile} />
          )}
        </Flow>
      );
    }}
  </Entity>
);

export default CompleteProfileFormContainer;
