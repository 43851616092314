import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestConfigEnd, requestNextStep } from "../../actionCreators/microphoneConfig";
import ConfigStepOptions from "./ConfigStepOptions";

const ConfigStepOptionsContainer = (props) => (
  <ConfigStepOptions onTryAgain={props.handleTryAgain} onEnd={props.handleEnd} />
);

ConfigStepOptionsContainer.propTypes = {
  handleTryAgain: PropTypes.func.isRequired,
  handleEnd: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  handleEnd: () => dispatch(requestConfigEnd()),
  handleTryAgain: () => dispatch(requestNextStep("SPEECH_RECOGNITION")),
});

export default connect(null, mapDispatchToProps)(ConfigStepOptionsContainer);
