import React from "react";
import PropTypes from "prop-types";

const Breadcrumb = (props) => (
  <div
    style={{
      height: "100%",
      display: "flex",
      alignItems: "center",
    }}
  >
    {props.children}
  </div>
);

Breadcrumb.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Breadcrumb;
