import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import CountDownTimer from "../../core/CountDownTimer";
import { OLD_PLAY_ITEM_AUDIO_FLOW } from "../../consts";

export default class CertificationTestItemTimerContainer extends Component {
  static propTypes = {
    time: PropTypes.number.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    itemType: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isRecording: PropTypes.bool.isRequired,
  };

  getTimerDelay = () => {
    if (
      ["GAP_FILL", "GAP_FILL_MULTIPLE", "GAP_FILL_SELECT", "UNSCRAMBLE_DRAG_AND_DROP"].some(
        (type) => type === this.props.itemType
      )
    ) {
      return 3000;
    }
    return 0;
  };

  render() {
    return (
      <Flow autoStart={false} id={OLD_PLAY_ITEM_AUDIO_FLOW}>
        {({ isPending, getParams }) => (
          <CountDownTimer
            delay={this.getTimerDelay()}
            isDisabled={
              this.props.isSubmitting ||
              (["FREE_SPEAK", "FREE_SPEAK_IMAGE"].some((type) => type === this.props.itemType) &&
                !this.props.isRecording) ||
              (isPending && get(getParams(), "initialPlay", false))
            }
            time={this.props.time}
            onTimerEnd={this.props.onTimerEnd}
          />
        )}
      </Flow>
    );
  }
}
