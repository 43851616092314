import React from "react";
import get from "lodash/get";
import QuestionLabel from "../QuestionLabel";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import FlexRow from "../FlexRow";
import InBetweenSpacing from "../InBetweenSpacing";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../CourseThemeContext";
import { useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG } from "../../consts/color";
import ValidationWrapper from "./ValidationWrapper";

function SpeechRecognitionValidationRender(props) {
  return (
    <ValidationWrapper>
      {props.text.split(" ").map((slice, index) => {
        const foundAnswer = props.linkedAnswers.find((answer) => answer.index === index);
        const noAnswer = !props.answers.find((answer) => answer.index === index);
        const answer = get(props.extraData, index.toString(), "");

        return (
          <FlexRow
            key={props.itemId}
            alignItems="center"
            margin={answer?.index !== foundAnswer?.index ? 3 : 0}
            marginLeft={index === 0 && answer?.index !== foundAnswer?.index ? 0 : 3}
          >
            <QuestionLabel
              cursor="normal"
              fontWeight={foundAnswer && foundAnswer.text !== answer ? 700 : 400}
              color={foundAnswer && foundAnswer.text !== answer ? FEEDBACK_CORRECT : "#607d8b"}
              textDecoration={foundAnswer && foundAnswer.text !== answer ? "underline" : "none"}
            >
              {noAnswer ? slice : foundAnswer?.text}
            </QuestionLabel>
          </FlexRow>
        );
      })}
    </ValidationWrapper>
  );
}

function SpeechRecognitionRender(props) {
  const text = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemType = useSelector(getItemExecutionPropById(props.itemId, "item.type.key"));
  const answers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const linkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const extraData = useSelector(getItemExecutionPropById(props.itemId, "extraData"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const shouldShowCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const correctAnswer = answers.find((answer) => answer.correct);

  return (
    <CourseThemeContext.Consumer>
      {({ primary }) => (
        <ExerciseItemPanel>
          <HighlightableItemBox isWrong={shouldShowCorrectOption} showFeedback={isExecutionValidated}>
            {text.split(" ").map((slice, index) => {
              const foundAnswer = linkedAnswers.find((answer) => answer.index === index);
              if (foundAnswer) {
                return (
                  <div key={`answer_id_${foundAnswer.id}`}>
                    {get(extraData, index.toString(), false) ? (
                      <QuestionLabel
                        color={
                          itemType !== "GAP_FILL" &&
                          shouldShowCorrectOption &&
                          foundAnswer?.text !== get(extraData, index.toString(), "")
                            ? FEEDBACK_WRONG
                            : primary
                        }
                        fontWeight={700}
                        text={get(extraData, index.toString(), "").replace(/[!.?,]$/, "")}
                      />
                    ) : (
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: 400,
                          color: "rgb(96, 125, 139)",
                          display: "inline",
                          textDecoration: "underline",
                          margin: "0 5px",
                        }}
                      >
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                    )}
                    {/[!.?,]$/.test(foundAnswer.text) && (
                      <QuestionLabel text={foundAnswer.text.substring(foundAnswer.text.length - 1)} />
                    )}
                    &nbsp;
                  </div>
                );
              }
              return (
                !answers.find((answer) => answer.index === index) && (
                  <div
                    key={`${props.itemId}-text-${index}`}
                    style={{
                      display: "flex",
                    }}
                  >
                    <QuestionLabel text={slice} />
                    &nbsp;
                  </div>
                )
              );
            })}
          </HighlightableItemBox>
          <Separator size="lg" />
          {shouldShowCorrectOption && itemType !== "GAP_FILL" ? (
            <SpeechRecognitionValidationRender
              text={text}
              linkedAnswers={linkedAnswers}
              itemType={itemType}
              extraData={extraData}
              answers={answers}
            />
          ) : (
            <FlexRow justifyContent="center" flexWrap="wrap" width="150%" marginLeft="-25%">
              <InBetweenSpacing size="sm">
                {linkedAnswers.map((answer) => {
                  return (
                    <div
                      key={`answer-${answer.id}`}
                      style={{
                        width: "auto",
                        height: "auto",
                        borderRadius: 3,
                        border:
                          shouldShowCorrectOption && correctAnswer?.index === answer.index
                            ? `2px solid ${FEEDBACK_CORRECT}`
                            : "unset",
                        boxSizing: "border-box",
                        padding: "10px 15px",
                        textAlign: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.8)",
                        borderBottom: "1px solid #dedede",
                        marginBottom: 10,
                      }}
                    >
                      {itemType !== "GAP_FILL" ? (
                        /[!.?,]$/.test(answer.text) ? (
                          <QuestionLabel text={answer.text.substring(0, answer.text.length - 1)} cursor="normal" />
                        ) : (
                          <QuestionLabel text={answer.text} cursor="normal" />
                        )
                      ) : (
                        <FlexRow flexWrap="wrap" justifyContent="center">
                          {text.split(" ").map((slice, index) => (
                            <FlexRow
                              key={`${props.itemId}-text-${index}`}
                              alignItems="center"
                              margin={3}
                              marginLeft={index === 0 ? 0 : 3}
                              marginRight={index === text.split(" ").length - 1 ? 0 : 3}
                            >
                              <QuestionLabel
                                cursor="normal"
                                fontSize={20}
                                fontWeight={index === correctAnswer.index ? 700 : 400}
                                color="#607d8b"
                              >
                                {correctAnswer.index === index ? answer.text : slice}
                              </QuestionLabel>
                            </FlexRow>
                          ))}
                        </FlexRow>
                      )}
                    </div>
                  );
                })}
              </InBetweenSpacing>
            </FlexRow>
          )}
        </ExerciseItemPanel>
      )}
    </CourseThemeContext.Consumer>
  );
}

export default function SpeechRecognitionExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SpeechRecognitionRender key={id} itemId={id} />);
}
