import React from "react";
import TranslatedText from "../../core/TranslatedText";
import PageableScroll from "../../core/PageableScroll";
import TimeLineListContainer from "./TimeLineListContainer";
import { WHITE } from "../../consts/color";
import Panel from "../../core/Panel";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const TimeLinePanel = () => (
  <div className="stepProgressTimeline">
    <Panel padding="none">
      <WhitelabelContext.Consumer>
        {({ secondaryColor }) => (
          <div
            style={{
              height: 40,
              backgroundColor: secondaryColor,
              boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              padding: "0px 15px",
              position: "relative",
              zIndex: 2,
              borderTopLeftRadius: 3,
              borderTopRightRadius: 3,
            }}
          >
            <TranslatedText
              as="label"
              translateKey="profile.progressTimeline"
              style={{
                textTransform: "uppercase",
                color: WHITE,
                fontSize: 14,
                fontWeight: 700,
                margin: 0,
              }}
            />
          </div>
        )}
      </WhitelabelContext.Consumer>
      <PageableScroll pageHeight={360} maxHeight={880}>
        {({ page }) => <TimeLineListContainer page={page} />}
      </PageableScroll>
    </Panel>
  </div>
);

export default TimeLinePanel;
