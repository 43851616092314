import React from "react";
import PropTypes from "prop-types";
import round from "lodash/round";
import StudyQualitySummary from "./StudyQualitySummary";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import withTranslation from "../../core/withTranslation";

const CurrentStudyQuality = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          display: "inline-block",
          width: "25%",
          height: "100%",
          verticalAlign: "top",
        }}
      >
        <StudyQualitySummary
          value={round(props.current.student, 0)}
          label={props.getTranslation("studyQuality.yours")}
          background={primaryColor}
          icon='account'
          iconStyle={{
            right: 0,
            bottom: 30,
            fontSize: 120,
          }}
        />
        <StudyQualitySummary
          value={round(props.current.group, 1)}
          label={props.getTranslation("studyQuality.classAverage")}
          background="#607d8b"
          icon='account-group'
          iconStyle={{
            right: 20,
            bottom: 35,
            fontSize: 125,
          }}
        />
      </div>
    )}
  </WhitelabelContext.Consumer>
);

CurrentStudyQuality.propTypes = {
  current: PropTypes.shape({
    student: PropTypes.number,
    group: PropTypes.number,
  }).isRequired,
};

export default withTranslation(CurrentStudyQuality);
