import React from "react";
import PropTypes from "prop-types";
import Form from "student-front-commons/src/components/Form";
import FlexColumn from "../../core/FlexColumn";
import DragAndDropExerciseItem from "../../core/old-item-type/DragAndDropExerciseItem";
import GapFillRadio from "../../core/old-item-type/GapFillRadio";
import SelectExerciseItem from "../../core/old-item-type/SelectExerciseItem";
import DictationExerciseItem from "../../core/old-item-type/DictationExerciseItem";
import SpeechPracticeExerciseItem from "../../core/old-item-type/SpeechPracticeExerciseItem";
import PronunciationExerciseItem from "../../core/old-item-type/PronunciationExerciseItem";
import SpeechRecognitionExerciseItem from "../../core/old-item-type/SpeechRecognitionExerciseItem";
import TrueFalseExerciseItem from "../../core/old-item-type/TrueFalseExerciseItem";
import SingleChoiceTextItem from "../../core/old-item-type/SingleChoiceTextItem";
import SingleChoiceAudioItem from "../../core/old-item-type/SingleChoiceAudioItem";
import SingleChoiceImageItem from "../../core/old-item-type/SingleChoiceImageItem";
import MasteryTestExecutionControlBar from "./MasteryTestExecutionControlBar";
import { ITEM_EXECUTION_FORM } from "../../consts";
import SpeechRecognitionScore from "../../core/speech-recognition/SpeechRecognitionScore";
import { COMPANY_SCHEMA, PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import { useEntity } from "student-front-commons/src/hooks";

export default function MasteryItem(props) {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile?.company);

  return (
    <Form id={ITEM_EXECUTION_FORM} onSubmit={props.onSubmit} autoInit={false}>
      {({ values, handleSubmit, handleChange }) => {
        const itemType = values.associativeItem.item.type.key;
        return (
          <FlexColumn justifyContent="space-between">
            {["SINGLE_CHOICE_TEXT"].some((type) => type === itemType) && (
              <SingleChoiceTextItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                playingId={values.playingId}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["SINGLE_CHOICE_AUDIO"].some((type) => type === itemType) && (
              <SingleChoiceAudioItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                playingId={values.playingId}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["SINGLE_CHOICE_IMAGE"].some((type) => type === itemType) && (
              <SingleChoiceImageItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                playing={values.playingId}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["GAP_FILL", "GAP_FILL_SPEECHLESS"].some((type) => type === itemType) && (
              <GapFillRadio
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                playing={values.playingId}
                userAnswer={values.answer}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["UNSCRAMBLE_SPEECH_RECOGNITION"].some((type) => type === itemType) && (
              <SpeechRecognitionExerciseItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                extraData={values.extraData}
              />
            )}
            {["UNSCRAMBLE_DRAG_AND_DROP", "GAP_FILL_MULTIPLE", "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS"].some(
              (type) => type === itemType
            ) && (
              <DragAndDropExerciseItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                extraData={values.extraData}
                onChange={handleChange}
              />
            )}
            {["GAP_FILL_SELECT", "PRONUNCIATION_SPEECHLESS"].some((type) => type === itemType) && (
              <SelectExerciseItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                playingId={values.playingId}
                extraData={values.extraData}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].some((type) => type === itemType) && (
              <DictationExerciseItem
                item={values.associativeItem.item}
                answer={values.answer}
                isDisabled={values.isDisabled || props.isSubmitting}
                onChange={handleChange}
                extraData={values.extraData}
              />
            )}
            {["TRUE_FALSE"].some((type) => type === itemType) && (
              <TrueFalseExerciseItem
                item={values.associativeItem.item}
                isDisabled={values.isDisabled || props.isSubmitting}
                onConfirm={handleSubmit}
                onChange={handleChange}
              />
            )}
            {["SPEECH_PRACTICE"].some((type) => type === itemType) && (
              <SpeechPracticeExerciseItem item={values.associativeItem.item} playingId={values.playingId} />
            )}
            {["PRONUNCIATION"].some((type) => type === itemType) && (
              <PronunciationExerciseItem item={values.associativeItem.item} />
            )}
            {!values.isRecording && !values.isSubmittingRecord && values.speechRecognitionResult && (
              <div
                style={{
                  position: "fixed",
                  bottom: 160,
                  width: "100%",
                  maxWidth: 1140,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <SpeechRecognitionScore score={values.speechRecognitionResult.qualityScore} />
              </div>
            )}
            <MasteryTestExecutionControlBar
              values={values}
              isSpeechRecognitionDisabled={profile?.isSpeechRecognitionDisabled}
              isMasteryTestTimerDisabled={company?.disableMasteryTestTimer}
              isSubmitting={props.isSubmitting}
              onSave={handleSubmit}
              onChange={handleChange}
            />
          </FlexColumn>
        );
      }}
    </Form>
  );
}

MasteryItem.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
