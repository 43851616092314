import React from "react";
import PropTypes from "prop-types";
import FlexRow from "../FlexRow";
import TranslatedText from "../TranslatedText";
import QuestionLabel from "../QuestionLabel";
import FlexColumn from "../FlexColumn";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import SpeechRecognitionResultHistoryItem from "./SpeechRecognitionResultHistoryItem";

function SpeechRecognitionResultHistory() {
  const history = useSelector(getCurrentItemExecutionProp("speechRecognitionResultHistory"));

  if (!history || !history.length) {
    return null;
  }

  return (
    <FlexColumn>
      <TranslatedText as={QuestionLabel} fontSize={16} translateKey="items.speechRecognition.historyTitle" />
      <FlexRow flexWrap="wrap">
        {history.map((result) => (
          <SpeechRecognitionResultHistoryItem
            key={`history-score-${result.id}`}
            id={result.id}
            score={result.score}
            recordUrl={result.recordUrl}
          />
        ))}
      </FlexRow>
    </FlexColumn>
  );
}

SpeechRecognitionResultHistory.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  history: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SpeechRecognitionResultHistory;
