import { call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { updateProfile } from "student-front-commons/src/services/profileService";
import { UPDATE_STUDENT_PAGE_ACCESSED_FLOW, SHOW_VIDEO_INSTRUCTION_FLOW } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(UPDATE_STUDENT_PAGE_ACCESSED_FLOW), function* () {
    try {
      const flow = yield select(getFlow(UPDATE_STUDENT_PAGE_ACCESSED_FLOW));
      const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));

      if (profile.id === "tasting_user") {
        yield put(
          mergeEntities({
            profile: {
              [profile.id]: {
                ...profile,
                pagesAccessed: [...(profile.pagesAccessed || []), flow.params.screen],
              },
            },
          })
        );
        return;
      }

      const data = {
        role: "STUDENT",
        name: profile.name,
        email: profile.email,
        rankingParticipant: profile.rankingParticipant === "S",
        pagesAccessed: [...(profile.pagesAccessed || []), flow.params.screen],
      };

      const result = yield call(updateProfile, data);
      yield put(mergeEntities(result.entities));
    } catch (error) {
      logError({ error, flow: UPDATE_STUDENT_PAGE_ACCESSED_FLOW });
    } finally {
      yield put(endFlow(SHOW_VIDEO_INSTRUCTION_FLOW));
      yield put(endFlow(UPDATE_STUDENT_PAGE_ACCESSED_FLOW));
    }
  });
}
