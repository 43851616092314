import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import get from "lodash/get";
import Separator from "../../core/Separator";
import CourseThemeContext from "../../core/CourseThemeContext";
import TranslatedText from "../../core/TranslatedText";
import BannerIcon from "../../images/bg-home.png";
import CoursePanelContainer from "./CoursePanelContainer";
import { WHITE } from "../../consts/color";
import FlexRow from "../../core/FlexRow";

const CourseBanner = ({ student }) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          width: "100%",
          height: 230,
          backgroundColor: primary,
          boxSizing: "border-box",
          padding: "0px 15px",
          position: "relative",
          boxShadow: "0px 1px 3px 0 rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
        }}
      >
        <div className="container">
          <Separator size="sm" />
          <TranslatedText
            as="h1"
            translateKey={`home.welcome.${student.gender || "M"}`}
            values={{
              name: get(student, "nickname", get(student, "name", "")),
            }}
            style={{
              color: WHITE,
              fontSize: 26,
              margin: 0,
              fontWeight: 300,
              display: "inline-block",
            }}
          />
          <Separator />
          <FlexRow columnGap={16}>
            <CoursePanelContainer />
          </FlexRow>
        </div>
        <MediaQuery minWidth={1280}>
          {(matches) => (
            <img
              alt="icon"
              src={BannerIcon}
              style={{
                position: "absolute",
                right: matches ? "20%" : "5%",
                bottom: 0,
              }}
            />
          )}
        </MediaQuery>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CourseBanner.propTypes = {
  student: PropTypes.shape({
    gender: PropTypes.string,
    name: PropTypes.string,
    nickname: PropTypes.string,
  }).isRequired,
};

export default CourseBanner;
