import get from "lodash/get";
import { normalize } from "normalizr";

import messageChannelSchema from "../schemas/messageChannelSchema";
import messageSchema from "../schemas/messageSchema";

import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list the message channels available for student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.size - the size of returned list
 * @param {string} payload.page - the list page
 *
 * @returns {Promise<*>}
 */
export const getMessageChannels = async (payload) => {
  validate(
    {
      size: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      page: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const pageable = await request({
        url: "message-channels",
        method: "get",
        params: {
          size: payload.size,
          page: payload.page,
        },
      });

      return {
        page: payload.page,
        size: payload.size,
        total: pageable.total,
        docs: normalize(pageable.docs, [messageChannelSchema]),
      };
    },
    "error_load_message_channels",
    "Unexpected error to load message channels."
  );
};

/**
 * create a new message channel with the teacher
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.type - the message type
 * @param {string} payload.text - the message text
 * @param {string} payload.subject - the channel subject
 * @param {string} [payload.teacher] - the student teacher
 *
 * @returns {Promise<*>}
 */
export const createMessageChannel = async (payload) => {
  validate(
    {
      type: {
        presence: {
          allowEmpty: false,
        },
      },
      text: {
        presence: {
          allowEmpty: false,
        },
      },
      subject: {
        presence: {
          allowEmpty: false,
        },
      },
      ...(payload.type && payload.type === "TO_TEACHER" && {
        teacher: {
          presence: {
            allowEmpty: false,
          },
        },
      })
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: "message-channels",
        method: "post",
        data: {
          type: payload.type,
          text: payload.text,
          subject: payload.subject,
          ...(payload.type === "TO_TEACHER" && { teacher: payload.teacher }),
        },
      });
    },
    "error_create_message_channel",
    "Unexpected error to create message channel."
  );
};

/**
 * list the message for a channel
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.idMessageChannel - the message channel id
 *
 * @returns {Promise<*>}
 */
export const getMessages = async (payload) => {
  validate(
    {
      idMessageChannel: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const messages = await request({
        url: `message-channels/${payload.idMessageChannel}/messages`,
        method: "get",
      });

      return normalize(messages, [messageSchema]);
    },
    "error_load_messages",
    "Unexpected error to load messages."
  );
};

/**
 * send a message for a channel
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.idMessageChannel - the message channel id
 * @param {string} payload.text - the message
 *
 * @returns {Promise<*>}
 */
export const sendMessage = async (payload) => {
  validate(
    {
      idMessageChannel: {
        presence: {
          allowEmpty: false,
        },
      },
      text: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const message = await request({
        url: `message-channels/${payload.idMessageChannel}/messages`,
        method: "post",
        data: {
          text: payload.text,
        },
      });

      return normalize(message, messageSchema);
    },
    "error_reply_message",
    "Unexpected error to reply the message."
  );
};

/**
 * mark a message as read
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.idMessageChannel - the message channel id
 * @param {string} payload.idMessage - the message  id
 *
 * @returns {Promise<*>}
 */
export const markAsReadMessage = async (payload) => {
  validate(
    {
      idMessageChannel: {
        presence: {
          allowEmpty: false,
        },
      },
      idMessage: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const message = await request({
        url: `message-channels/${payload.idMessageChannel}/messages/${payload.idMessage}/read`,
        method: "patch",
      });

      return normalize(message, messageSchema);
    },
    "error_set_message_as_read",
    "Unexpected error to set message as read."
  );
};
