import React from "react";
import PropTypes from "prop-types";
import color from "color";
import camelCase from "lodash/camelCase";
import ColumnSeparator from "../ColumnSeparator";
import TranslatedText from "../TranslatedText";
import NotificationIcon from "./NotificationIcon";
import NotificationPropsHoc from "./NotificationPropsHoc";
import { GREY_6, WHITE } from "../../consts/color";
import NotificationAudio from "../../audios/notification-toast.mp3";

class NotificationToast extends React.PureComponent {
  static propTypes = {
    onHide: PropTypes.func.isRequired,
    notification: PropTypes.shape({
      type: PropTypes.string,
    }).isRequired,
    configurations: PropTypes.shape({
      unitsToFinishAfterTestFailure: PropTypes.number,
    }).isRequired,
  };

  componentDidMount() {
    this.timeout = setTimeout(() => this.props.onHide(this.props.notification.id), 5000);

    this.audio = new Audio(NotificationAudio);
    this.audio.load();
    setTimeout(async () => {
      await this.audio.play();
    }, 500);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <div
        style={{
          position: "relative",
          minWidth: 250,
          height: 50,
          borderRadius: 25,
          boxSizing: "border-box",
          backgroundColor: WHITE,
          boxShadow: `0px 10px 50px 0 ${color(this.props.color).fade(0.2)}`,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: this.props.color,
            padding: "0px 25px",
            borderRadius: 25,
            position: "absolute",
            top: -15,
            height: 25,
            display: "flex",
            alignItems: "center",
          }}
        >
          <TranslatedText
            translateKey={`notifications.intent.${this.props.intent}`}
            as="span"
            style={{
              color: WHITE,
              fontSize: 13,
              fontWeight: "bold",
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: color(this.props.color).fade(0.9),
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px 55px",
            borderRadius: 25,
          }}
        >
          <NotificationIcon type={this.props.notification.type} />
          <ColumnSeparator size="md" />
          <TranslatedText
            translateKey={`notifications.type.${camelCase(this.props.notification.type)}`}
            values={this.props.messageProps}
            as="span"
            style={{
              color: GREY_6,
              fontSize: 14,
              fontWeight: "bold",
            }}
          />
        </div>
      </div>
    );
  }
}

export default NotificationPropsHoc(NotificationToast);
