export const getInstructionAudio = (key) => (state) => {
  const instructionAudio = state.configurations.instructionAudios?.find((item) => item.key === key);
  return instructionAudio.audio || instructionAudio.generatedAudio;
};

export const getInstructionVideo = (key) => (state) => {
  return state.configurations.instructionVideos?.find((item) => item.key === key);
};

export const getUnitsToFinishAfterTestFailure = (state) => {
  return state.configurations.unitsToFinishAfterTestFailure;
};
