import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from "lodash/get";
import { getCourseStatsByPeriod } from "student-front-commons/src/services/courseService";
import FetchData from "student-front-commons/src/components/FetchData";
import CircularLoading from "./CircularLoading";

export default class CertificationTestStatusFailedPointCountContainer extends Component {
  static propTypes = {
    course: PropTypes.string.isRequired,
    unitsNeeded: PropTypes.number.isRequired,
    failedAt: PropTypes.string.isRequired,
  };

  render() {
    return (
      <FetchData
        service={getCourseStatsByPeriod}
        params={{
          id: sessionStorage.getItem("id"),
          course: this.props.course,
          from: moment(this.props.failedAt).toDate(),
          to: moment().endOf("day").toDate(),
        }}
      >
        {({ isFetching, data }) =>
          isFetching ? (
            <CircularLoading size="xs" />
          ) : (
            <label
              htmlFor="points"
              style={{
                color: "#757575",
                fontSize: 14,
                margin: 0,
              }}
            >
              {get(data, "approvedUnits", 0)}/{this.props.unitsNeeded}
            </label>
          )
        }
      </FetchData>
    );
  }
}
