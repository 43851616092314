import React from "react";
import { connect } from "react-redux";
import NotificationCounter from "./NotificationCounter";

const NotificationCounterContainer = (props) =>
  !!props.unreadCount && <NotificationCounter value={props.unreadCount} />;

const mapStateToProps = (state) => ({
  unreadCount: state.notification.totalUnread,
});

export default connect(mapStateToProps)(NotificationCounterContainer);
