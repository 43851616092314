import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlowStart, getFlow } from "student-front-commons/src/selectors/flow";
import { getPracticeTest } from "student-front-commons/src/services/placementTestService";
import { addImageDataToItems, addSoundToItems, logError } from "../util";
import {
  GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW,
  PRACTICE_TEST_EXECUTION_FORM,
  START_PRACTICE_TEST_EXECUTION_FLOW,
} from "../consts";

export default function* () {
  yield takeLatest(getFlowStart(START_PRACTICE_TEST_EXECUTION_FLOW), function* () {
    try {
      const flow = yield select(getFlow(START_PRACTICE_TEST_EXECUTION_FLOW));
      const practiceItems = yield call(getPracticeTest);

      yield put(
        initForm(PRACTICE_TEST_EXECUTION_FORM, {
          practiceTestItems: yield all(addImageDataToItems(yield all(addSoundToItems(practiceItems)))),
          answers: [],
          typesShowedInIntro: [],
          currentOrder: 0,
          repeatCount: 0,
          isRetry: flow.params.isRetry,
        })
      );

      yield put(startFlow(GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW));
    } catch (error) {
      logError({ error, flow: START_PRACTICE_TEST_EXECUTION_FLOW });
      // yield put(startFlow(HANDLE_ERROR_FLOW, {
      //   error: error.message,
      //   retryFlow: {
      //     id: START_MASTERY_TEST_EXECUTION_FLOW,
      //     params: flow.params,
      //   }
      // }));
    } finally {
      yield put(endFlow(START_PRACTICE_TEST_EXECUTION_FLOW));
    }
  });
}
