import React, { Component } from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import CircularLoading from "./CircularLoading";

export default class UserAvatar extends Component {
  static propTypes = {
    size: PropTypes.oneOf(["xs", "sm", "md", "mdl", "lg"]),
    onClick: PropTypes.func,
    src: PropTypes.string,
  };

  static defaultProps = {
    size: "xs",
    onClick: null,
    src: null,
  };

  state = { src: null, loading: false };

  componentDidMount() {
    this.setState({
      src: this.props.src,
      loading: !!this.props.src,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.setState({
        src: nextProps.src,
        loading: !!nextProps.src,
      });
    }
  }

  handleLoad = () => {
    this.setState({
      loading: false,
    });
  };

  handleError = () => {
    this.setState({
      loading: false,
      src: null,
    });
  };

  render() {
    return (
      <div
        style={{
          minWidth: {
            xs: 30,
            sm: 40,
            md: 60,
            mdl: 90,
            lg: 120,
          }[this.props.size],
          minHeight: {
            xs: 30,
            sm: 40,
            md: 60,
            mdl: 90,
            lg: 120,
          }[this.props.size],
          width: {
            xs: 30,
            sm: 40,
            md: 60,
            mdl: 90,
            lg: 120,
          }[this.props.size],
          height: {
            xs: 30,
            sm: 40,
            md: 60,
            mdl: 90,
            lg: 120,
          }[this.props.size],
          boxSizing: "border-box",
          borderRadius: "50%",
          backgroundColor: "#ECECEC",
          border: "2px solid rgba(0, 0, 0, 0.2)",
          borderWidth: {
            xs: 1,
            sm: 1,
            md: 1.5,
            mdl: 2,
            lg: 2,
          }[this.props.size],
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          cursor: this.props.onClick ? "pointer" : "normal",
        }}
        onClick={this.props.onClick}
      >
        {this.state.loading && (
          <CircularLoading
            size={
              {
                xs: "xxs",
                sm: "xxs",
                md: "sm",
                mdl: "sm",
                lg: "sm",
              }[this.props.size]
            }
          />
        )}
        {this.state.src ? (
          <img
            alt="profile"
            src={`${process.env.REACT_APP_FILES_URL}/${this.state.src}`}
            onLoad={this.handleLoad}
            onError={this.handleError}
            style={{
              width: "100%",
              opacity: this.state.loading ? 0 : 1,
              display: this.state.loading ? "none" : "block",
              transition: "all 0.5s ease 1s",
            }}
          />
        ) : (
          <Icon
            icon="account-outline"
            color="rgba(0, 0, 0, 0.2)"
            size={
              {
                xs: "xs",
                sm: "sm",
                md: "lg",
                mdl: "xl",
                lg: "xl",
              }[this.props.size]
            }
          />
        )}
      </div>
    );
  }
}
