import React, { useCallback, useEffect, useMemo, useRef } from "react";
import Modal from "./Modal";
import FlexColumn from "./FlexColumn";
import TranslatedText from "./TranslatedText";
import QuestionLabel from "./QuestionLabel";
import Separator from "./Separator";
import FlexRow from "./FlexRow";
import ButtonV2 from "./ButtonV2";
import { addSentryUserAction } from "../util";
import { useEntity } from "student-front-commons/src/hooks";
import moment from "moment";
import PropTypes from "prop-types";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

const sentryUserAction = { mainComponent: "CloseExecutionModal" };

function CloseExecutionModal({ message, timeToAllowContinueUnit, onCancel, onConfirm, autoClose }) {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const timeout = useRef();

  useEffect(() => {
    if (autoClose) {
      timeout.current = setTimeout(() => onConfirm(), 10000);
    }
    return () => {
      if (autoClose) {
        clearTimeout(timeout.current);
      }
    };
  }, [autoClose, onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel();
    addSentryUserAction({
      ...sentryUserAction,
      clickedComponent: "Button",
      action: `Cancel Unit Execution back navigation`,
    });
  }, [onCancel]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    addSentryUserAction({
      ...sentryUserAction,
      clickedComponent: "Button",
      action: `Confirm Unit Execution back navigation`,
    });
  }, [onConfirm]);

  const timeToAllowContinueUnitText = useMemo(() => {
    return moment
      .duration(timeToAllowContinueUnit, "minutes")
      .locale(profile?.locale || "en")
      .humanize();
  }, [timeToAllowContinueUnit, profile?.locale]);

  return (
    <Modal isOpen={true}>
      <FlexColumn alignItems="center">
        <TranslatedText as={QuestionLabel} translateKey="closeExecution.title" fontSize={24} fontWeight={900} />
        <Separator size="md" />
        <TranslatedText
          translateKey={message}
          values={{
            timeToAllowContinueUnit: timeToAllowContinueUnitText,
          }}
        />
        <Separator size="xl" />
        <FlexRow>
          <ButtonV2 onClick={handleCancel} size="md" labelKey="closeExecution.no" intent="course-primary" />
          <Separator size="lg" />
          <ButtonV2 onClick={handleConfirm} size="md" labelKey="closeExecution.yes" intent="light" />
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
}

CloseExecutionModal.propTypes = {
  message: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  timeToAllowContinueUnit: PropTypes.number.isRequired,
  autoClose: PropTypes.bool,
};

CloseExecutionModal.defaultProps = {
  autoClose: true,
  message: "closeExecution.defaultMessage",
};

export default CloseExecutionModal;
