import React from "react";
import PropTypes from "prop-types";
import { FormControlLabel, Switch } from "@material-ui/core";

const Toggle = (props) => (
  <FormControlLabel
    label={props.label}
    control={
      <Switch
        labelPosition="right"
        value={props.value}
        disabled={props.disabled}
        onChange={(event) => props.onChange(event.target.checked)}
      />
    }
  />
);

Toggle.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Toggle.defaultProps = {
  value: null,
  disabled: false,
  onChange: null,
};

export default Toggle;
