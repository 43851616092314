import React from "react";
import moment from "moment";
import { getCourseDateHistory } from "student-front-commons/src/services/recordsService";
import FetchData from "student-front-commons/src/components/FetchData";
import Entity from "student-front-commons/src/components/Entity";
import Async from "../../core/Async";
import DateHistoryList from "./DateHistoryList";

const DateHistoryListContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));

      return (
        <FetchData
          service={getCourseDateHistory}
          params={{
            id: sessionStorage.getItem("id"),
            course: profile.activeCourses.find((course) => !course.distributor).id,
            from: moment().subtract(2, "months").startOf("month").toDate(),
            to: moment().endOf("month").toDate(),
          }}
        >
          {({ isFetching, data }) => (
            <Async fetching={isFetching} color="dark" size="sm">
              <DateHistoryList data={data || []} isSpeechRecognitionDisabled={profile.isSpeechRecognitionDisabled} />
            </Async>
          )}
        </FetchData>
      );
    }}
  </Entity>
);

export default DateHistoryListContainer;
