import { call, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import { saveCertificationTestItemExecution } from "student-front-commons/src/services/certificationTestExecutionService";
import {
  CERTIFICATION_TEST_ABILITY_EXECUTION_FORM,
  CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW,
  GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import { logError, addSentryUserAction } from "../util";

const sentryUserAction = { mainComponent: "saveCertificationTestAbilityItemExecutionAnswerFlow" };

export default function* () {
  yield takeLatest(getFlowStart(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          let itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          let certificationTestExecutionForm = yield select(getForm(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM));

          const currentAnswer = {};
          if (
            ["FREE_TEXT", "FREE_TEXT_IMAGE", "FREE_SPEAK", "FREE_SPEAK_IMAGE"].some(
              (type) => type === itemExecutionForm.values.associativeItem.item.type.key
            )
          ) {
            currentAnswer.answer = itemExecutionForm.values.answer;
            currentAnswer.ability = certificationTestExecutionForm.values.ability;
            yield put(
              changeFormValue(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM, "answers", [
                ...certificationTestExecutionForm.values.answers,
                currentAnswer,
              ])
            );
          } else {
            const answerResult = yield call(checkAnswer, {
              item: itemExecutionForm.values.associativeItem.item,
              answer: itemExecutionForm.values.answer,
            });

            currentAnswer.answer = answerResult.answer;
            currentAnswer.correct = answerResult.status === "CORRECT";
            currentAnswer.ability = certificationTestExecutionForm.values.ability;
            yield put(
              changeFormValue(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM, "answers", [
                ...certificationTestExecutionForm.values.answers,
                currentAnswer,
              ])
            );

            addSentryUserAction({
              ...sentryUserAction,
              clickedComponent: "None",
              action: `${currentAnswer.correct ? "Correct" : "Incorrect"} answer on Item ${
                itemExecutionForm.values.associativeItem.item
              }`,
            });
          }

          yield spawn(saveCertificationTestItemExecution, {
            certificationTest: certificationTestExecutionForm.values.certificationTest,
            ability: certificationTestExecutionForm.values.ability,
            item: itemExecutionForm.values.associativeItem.item.id,
            answer: currentAnswer.answer,
            correct: currentAnswer.correct,
          });

          yield put(
            changeFormValue(
              CERTIFICATION_TEST_ABILITY_EXECUTION_FORM,
              "currentIndex",
              certificationTestExecutionForm.values.currentIndex + 1
            )
          );

          certificationTestExecutionForm = yield select(getForm(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM));
          if (
            certificationTestExecutionForm.values.answers.length >=
            certificationTestExecutionForm.values.certificationTestItems.length
          ) {
            yield put(startFlow(END_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW));
          } else {
            yield put(startFlow(GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({ error, flow: SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW });
          // yield put(startFlow(HANDLE_ERROR_FLOW, {
          //   error: 'error_save_answer',
          //   retryFlow: {
          //     id: SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          //   }
          // }));
        } finally {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
          yield put(endFlow(SAVE_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
