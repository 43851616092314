import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useFlow } from "student-front-commons/src/hooks";
import Async from "../../core/Async";
import UnitExecutionScene from "./UnitExecutionScene";
import { LOAD_CONFIGURATION_FLOW, START_UNIT_EXECUTION_FLOW } from "../../consts";

export default function UnitExecutionSceneContainer() {
  const { idModule, idUnit, idCourse } = useParams();
  const location = useLocation();

  const [isLoadingConfig] = useFlow(LOAD_CONFIGURATION_FLOW);
  const [isStartingExecution] = useFlow(START_UNIT_EXECUTION_FLOW, {
    autoStart: true,
    params: {
      course: idCourse,
      module: idModule,
      unit: idUnit,
      isReview: !!location.state?.isReview,
      optionalReview: !!location.state?.optionalReview,
      contentVideoRecommendation: !!location.state?.contentVideoRecommendation,
    },
  });

  return (
    <Async fetching={isLoadingConfig || isStartingExecution}>
      <UnitExecutionScene isPending={isLoadingConfig || isStartingExecution} />
    </Async>
  );
}
