import React from "react";
import Modal from "../../core/Modal";
import FlexColumn from "../../core/FlexColumn";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import Button from "../../core/Button";
import { WHITE } from "../../consts/color";
import reviewImportanceAvatar from "../../assets/icons/review-importance-avatar.png";

function ReviewImportanceModal({ isOpen, onConfirm }) {
  return (
    <Modal style={{ padding: "0 0 40px 0", width: 560 }} isOpen={isOpen}>
      <FlexColumn height={147} position="relative" background="#DAD7FE" width="100%">
        <img
          style={{ width: 204, height: 129, position: "absolute", bottom: 0, left: 190 }}
          src={reviewImportanceAvatar}
          alt="Review Importance"
        />
      </FlexColumn>
      <FlexColumn gap={10} alignItems="center" padding="20px 70px 0 70px">
        <TranslatedText
          as="span"
          style={{ fontWeight: 600, fontSize: "18px" }}
          translateKey="unitResult.reviewStep.modal.title"
        />
        <Separator size="sm" />
        <TranslatedText as="span" style={{ fontSize: "14px" }} translateKey="unitResult.reviewStep.modal.paragraph1" />
        <TranslatedText as="span" style={{ fontSize: "14px" }} translateKey="unitResult.reviewStep.modal.paragraph2" />
        <TranslatedText as="span" style={{ fontSize: "14px" }} translateKey="unitResult.reviewStep.modal.paragraph3" />
        <Separator size="md" />
        <Button backgroundColor="#285D8D" contentColor={WHITE} onClick={onConfirm}>
          <TranslatedText translateKey="unitResult.reviewStep.modal.button" />
        </Button>
      </FlexColumn>
    </Modal>
  );
}

export default ReviewImportanceModal;
