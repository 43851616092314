export const START_ITEM_EXECUTION = "START_ITEM_EXECUTION";
export const FINISH_ITEM_EXECUTION = "FINISH_ITEM_EXECUTION";
export const START_PLAY_ITEM = "START_PLAY_ITEM";
export const FINISH_PLAY_ITEM = "FINISH_PLAY_ITEM";
export const START_PLAY_ANSWER = "START_PLAY_ANSWER";
export const ENABLE_ITEM_EXECUTION_VALIDATION = "ENABLE_ITEM_EXECUTION_VALIDATION";
export const FINISH_PLAY_ANSWER = "FINISH_PLAY_ANSWER";
export const START_PLAY_ACCESSORY = "START_PLAY_ACCESSORY";
export const FINISH_PLAY_ACCESSORY = "FINISH_PLAY_ACCESSORY";
export const UPDATE_ITEM_MEDIA_PROGRESS = "UPDATE_ITEM_MEDIA_PROGRESS";
export const UPDATE_ITEM_MEDIA_LOAD = "UPDATE_ITEM_MEDIA_LOAD";
export const REPEAT_ITEM = "REPEAT_ITEM";
export const LISTEN_ITEM = "LISTEN_ITEM";
export const READ_ITEM = "READ_ITEM";
export const TRANSLATE_ITEM = "TRANSLATE_ITEM";
export const START_RECORD_ITEM = "START_RECORD_ITEM";
export const SUBMIT_RECORD_ITEM = "SUBMIT_RECORD_ITEM";
export const SAVE_RECORD_ITEM_RESULT = "SAVE_RECORD_ITEM_RESULT";
export const FINISH_RECORD_ITEM = "FINISH_RECORD_ITEM";
export const ADD_ITEM_EXECUTION_ANSWER = "ADD_ITEM_EXECUTION_ANSWER";
export const ADD_ITEM_EXECUTION_ATTEMPT = "ADD_ITEM_EXECUTION_ATTEMPT";
export const REMOVE_LAST_ITEM_EXECUTION_ATTEMPT = "REMOVE_LAST_ITEM_EXECUTION_ATTEMPT";
export const DISABLE_ITEM_EXECUTION = "DISABLE_ITEM_EXECUTION";
export const ENABLE_ITEM_EXECUTION = "ENABLE_ITEM_EXECUTION";
export const INCREMENT_ITEM_EXECUTION_ERROR = "INCREMENT_ITEM_EXECUTION_ERROR";
export const INCREMENT_ITEM_EXECUTION_USER_AWAY = "INCREMENT_ITEM_EXECUTION_USER_AWAY";
export const SHOW_ITEM_CORRECT_OPTION = "SHOW_ITEM_CORRECT_OPTION";
export const HIDE_ITEM_CORRECT_OPTION = "HIDE_ITEM_CORRECT_OPTION";
export const CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION = "CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION";
export const ADD_REPORT_ERROR_TIME = "ADD_REPORT_ERROR_TIME";
export const READ_LONG_TEXT = "READ_LONG_TEXT";
export const SELECT_ITEM = "SELECT_ITEM";
export const UNSELECT_ITEM = "UNSELECT_ITEM";
export const CLEAN_ITEM_EXECUTION_AUDIO = "CLEAN_ITEM_EXECUTION_AUDIO";

/**
 * start an item execution
 *
 * @param {object[]} associativeItems - the array of associative items to execute
 * @param {object} options
 * @param {object} [options.imageItem] - the item of type image to display together
 * @returns {{ type: string, payload: { associativeItems: object[] } }}
 */
export const startItemExecution = (associativeItems, { imageItem } = {}) => ({
  type: START_ITEM_EXECUTION,
  payload: {
    associativeItems,
    imageItem,
  },
});

/**
 * update the state to show that item audio is playing
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const finishItemExecution = (itemId) => ({
  type: FINISH_ITEM_EXECUTION,
  payload: {
    itemId,
  },
});

/**
 * update the state to show that item audio is playing
 *
 * @param {string} itemId
 * @param {object} [options]
 * @param {boolean} [options.isInitialPlay]
 * @param {string} [options.audioType]
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const startPlayItem = (itemId, { isInitialPlay, audioType }) => ({
  type: START_PLAY_ITEM,
  payload: {
    itemId,
    options: { isInitialPlay, audioType },
  },
});

/**
 * update the state to store the audio/video progress
 *
 * @param {string} itemId
 * @param {object} [options]
 * @param {number} [options.duration]
 * @param {number} [options.currentTime]
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const updateItemMediaProgress = (itemId, { duration, currentTime }) => ({
  type: UPDATE_ITEM_MEDIA_PROGRESS,
  payload: {
    itemId,
    options: { duration, currentTime },
  },
});

/**
 * update the state to store the audio/video load progress
 *
 * @param {string} itemId
 * @param {object} [options]
 * @param {number} [options.percent]
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const updateItemMediaLoad = (itemId, { percent }) => ({
  type: UPDATE_ITEM_MEDIA_LOAD,
  payload: {
    itemId,
    options: { percent },
  },
});

/**
 * update the state to show that item audio has finished
 *
 * @param {string} itemId
 * @param {object} [options]
 * @param {boolean} [options.isInitialPlay]
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const finishPlayItem = (itemId, { isInitialPlay }) => ({
  type: FINISH_PLAY_ITEM,
  payload: {
    itemId,
    options: { isInitialPlay },
  },
});

/**
 * update the state to show that item answer audio is playing
 *
 * @param {string} itemId
 * @param {string} answerId
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const startPlayItemAnswer = (itemId, answerId) => ({
  type: START_PLAY_ANSWER,
  payload: {
    itemId,
    answerId,
  },
});

/**
 * update the state to show that item answer audio has finished
 *
 * @param {string} itemId
 * @param {string} answerId
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const finishPlayItemAnswer = (itemId, answerId) => ({
  type: FINISH_PLAY_ANSWER,
  payload: {
    itemId,
    answerId,
  },
});

/**
 * update the state to show that item title/post phrase audio is playing
 *
 * @param {string} itemId
 * @param {string} accessory - the type of audio (title or post-phrase)
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const startPlayItemAccessory = (itemId, accessory) => ({
  type: START_PLAY_ACCESSORY,
  payload: {
    itemId,
    accessory,
  },
});

/**
 * update the state to show that item title/post phrase audio has finished
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const finishPlayItemAccessory = (itemId) => ({
  type: FINISH_PLAY_ACCESSORY,
  payload: {
    itemId,
  },
});

/**
 * update the state to increment repeat count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const repeatItem = (itemId) => ({
  type: REPEAT_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update the state to increment listen count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const listenItem = (itemId) => ({
  type: LISTEN_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update the state to increment read count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const readItem = (itemId) => ({
  type: READ_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update the state to increment the translate count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const translateItem = (itemId) => ({
  type: TRANSLATE_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update to the recording state
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const startRecordItem = (itemId) => ({
  type: START_RECORD_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update to the submitting record state
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const submitRecordItem = (itemId, { recordFile, recordUrl }) => ({
  type: SUBMIT_RECORD_ITEM,
  payload: {
    itemId,
    recordFile,
    recordUrl,
  },
});

/**
 * save the record result to the state
 *
 * @param {string} itemId
 * @param {object} [options]
 * @param {any} [options.speechRecognitionResult]
 *
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const saveRecordItemResult = (itemId, { speechRecognitionResult }) => ({
  type: SAVE_RECORD_ITEM_RESULT,
  payload: {
    itemId,
    speechRecognitionResult,
  },
});

/**
 * update to the recording state
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const finishRecordItem = (itemId) => ({
  type: FINISH_RECORD_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update to the recording state
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const addItemExecutionAnswer = (itemId, { answer, extraData }) => ({
  type: ADD_ITEM_EXECUTION_ANSWER,
  payload: {
    itemId,
    answer,
    extraData,
  },
});

/**
 * update state to add attempt
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const addItemExecutionAttempt = (itemId, { answer }) => ({
  type: ADD_ITEM_EXECUTION_ATTEMPT,
  payload: {
    itemId,
    answer,
  },
});

/**
 * update state to remove the last attempt
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const removeLastItemExecutionAttempt = (itemId) => ({
  type: REMOVE_LAST_ITEM_EXECUTION_ATTEMPT,
  payload: {
    itemId,
  },
});

/**
 * update the state to increment the error count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const incrementItemExecutionError = (itemId) => ({
  type: INCREMENT_ITEM_EXECUTION_ERROR,
  payload: {
    itemId,
  },
});
/**
 * update the state to increment the user away count
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const incrementItemExecutionUserAway = (itemId) => ({
  type: INCREMENT_ITEM_EXECUTION_USER_AWAY,
  payload: {
    itemId,
  },
});

/**
 * update the state to enable item validation after saved
 *
 * @returns {{ type: string }}
 */
export const enableItemExecutionValidation = () => ({
  type: ENABLE_ITEM_EXECUTION_VALIDATION,
});

/**
 * set all executions to disable
 *
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const disableItemExecution = () => ({
  type: DISABLE_ITEM_EXECUTION,
});

/**
 * set all executions to enable
 *
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const enableItemExecution = () => ({
  type: ENABLE_ITEM_EXECUTION,
});

/**
 * update the state to show correct option
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const showItemCorrectOption = (itemId) => ({
  type: SHOW_ITEM_CORRECT_OPTION,
  payload: {
    itemId,
  },
});

/**
 * update the state to hide correct option
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const hideItemCorrectOption = (itemId) => ({
  type: HIDE_ITEM_CORRECT_OPTION,
  payload: {
    itemId,
  },
});

/**
 * update the state to clean required fields for item instruction
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const cleanItemExecutionForInstruction = (itemId) => ({
  type: CLEAN_ITEM_EXECUTION_FOR_INSTRUCTION,
  payload: {
    itemId,
  },
});

/**
 * update the state to clean required fields for item instruction
 *
 * @param {string} itemId
 * @returns {{ type: string, payload: { itemId: {string} } }}
 */
export const addReportErrorTime = (itemId, { time }) => ({
  type: ADD_REPORT_ERROR_TIME,
  payload: {
    itemId,
    time,
  },
});

/**
 * update the state to indicate that user is reading a long text
 *
 * @returns {{type: string}}
 */
export const readLongText = () => ({
  type: READ_LONG_TEXT,
});

/**
 * update the state to indicate that user selected an item
 *
 * @param itemId
 * @returns {{payload: {itemId}, type: string}}
 */
export const selectItem = (itemId) => ({
  type: SELECT_ITEM,
  payload: {
    itemId,
  },
});

/**
 * update the state to indicate that there is no selected item
 *
 * @returns {{ type: string }}
 */
export const unselectItem = () => ({
  type: UNSELECT_ITEM,
});

/**
 * update the state to show that item audio has finished
 *
 * @param {string} itemId
 * @param {string} answerId
 * @returns {{ type: string, payload: { itemId: {string}, options: {} } }}
 */
export const cleanItemExecutionAudio = (itemId) => ({
  type: CLEAN_ITEM_EXECUTION_AUDIO,
  payload: {
    itemId,
  },
});
