import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list the message channels available for student
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.size - the size of returned list
 * @param {string} payload.page - the list page
 *
 * @returns {Promise<*>}
 */
export const getNotifications = async (payload) => {
  validate(
    {
      size: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
      page: {
        presence: {
          allowEmpty: false,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      const pageable = await request({
        url: "notifications",
        method: "get",
        params: {
          size: payload.size,
          page: payload.page,
        },
      });

      return {
        page: payload.page,
        size: payload.size,
        total: pageable.total,
        totalUnread: pageable.totalUnread,
        docs: pageable.docs,
      };
    },
    "error_load_notifications",
    "Unexpected error to load notifications."
  );
};

/**
 * mark all notifications as read
 *
 * @param {Object} payload - The object with all the params
 *
 * @returns {Promise<*>}
 */
export const markAsRead = async () => {
  return safeCall(
    async () => {
      await request({
        url: "notifications/read",
        method: "post",
      });
    },
    "error_set_notification_as_read",
    "Unexpected error to ser notifications as read."
  );
};
