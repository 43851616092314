import React from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREY_5 } from "../../consts/color";
import TranslatedText from "../../core/TranslatedText";

const ClosedRankingTypeButton = (props) => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <TranslatedText
          as="button"
          translateKey={props.label}
          onClick={props.onClick}
          style={{
            color: props.isActive ? primaryColor : GREY_5,
            background: "none",
            border: "none",
            borderRadius: 0,
            outline: "none",
            margin: "0px 10px",
            fontWeight: props.isActive ? 700 : "normal",
            textTransform: "uppercase",
            fontSize: 13,
          }}
        />
        {props.isActive && (
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "25%",
              borderBottom: `2px solid ${primaryColor}`,
            }}
          />
        )}
      </div>
    )}
  </WhitelabelContext.Consumer>
);

export default ClosedRankingTypeButton;
