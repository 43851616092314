import React, { Component } from "react";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import MessageChannelList from "./MessageChannelList";
import { LOAD_MESSAGE_CHANNELS_FLOW } from "../../consts";

class MessageChannelListContainer extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
  };

  render() {
    return (
      <Flow
        key={`messages-page-${this.props.page}`}
        autoStart={true}
        id={LOAD_MESSAGE_CHANNELS_FLOW}
        params={{
          size: 10,
          page: this.props.page,
        }}
      >
        {({ isPending }) => (
          <Entity name="profile">
            {({ getById: getProfileById }) => (
              <Entity name="messageChannel">
                {({ getAll }) => (
                  <MessageChannelList
                    fetching={isPending}
                    channels={orderBy(getAll(), ["lastMessageSentAt"], ["desc"]).map((channel) => ({
                      ...channel,
                      chattingWith: getProfileById(channel.chattingWith),
                    }))}
                  />
                )}
              </Entity>
            )}
          </Entity>
        )}
      </Flow>
    );
  }
}

export default MessageChannelListContainer;
