import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import TestItemExecutionForm from "../TestItemExecutionForm";
import { SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW } from "../../../consts";

const PlacementTestItemExecutionFormContainer = () => (
  <Flow id={SAVE_PLACEMENT_TEST_ITEM_EXECUTION_ANSWER_FLOW}>
    {({ isPending, handleStart }) => {
      return <TestItemExecutionForm onSubmit={handleStart} isSubmitting={isPending} />;
    }}
  </Flow>
);

export default PlacementTestItemExecutionFormContainer;
