import React from "react";
import PropTypes from "prop-types";
import ControlBarLayoutItem from "../../core/old-control-bar/ControlBarLayoutItem";
import ControlBarLayout from "../../core/old-control-bar/ControlBarLayout";
import withTranslation from "../../core/withTranslation";
import RecordButtonContainer from "../../core/old-control-bar/RecordButtonContainer";
import RepeatButtonContainer from "../../core/old-control-bar/RepeatButtonContainer";
import SlowRepeatButtonContainer from "../../core/old-control-bar/SlowRepeatButtonContainer";
import NextButton from "../../core/old-control-bar/NextButton";
import MasteryTestItemTimerContainer from "./MasteryTestItemTimerContainer";

class MasteryTestExecutionControlBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTimerEnding: false,
    };
  }

  static propTypes = {
    values: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isSpeechRecognitionDisabled: PropTypes.bool,
    isMasteryTestTimerDisabled: PropTypes.bool,
  };

  isRecordDisabled = () => {
    if (
      ["PRONUNCIATION", "SPEECH_PRACTICE", "UNSCRAMBLE_SPEECH_RECOGNITION"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return this.props.values.isDisabled || this.props.values.isSubmittingRecord;
    }
    return true;
  };

  isRepeatDisabled = () => {
    if (
      ["SPEECH_PRACTICE", "SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_IMAGE", "SINGLE_CHOICE_AUDIO", "TRUE_FALSE"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return (
        this.props.values.isDisabled ||
        this.props.values.isRecording ||
        this.props.values.isSubmittingRecord ||
        this.props.values.repeatCount > 0
      );
    }
    if (["DICTATION", "SPEECH_PRACTICE_SPEECHLESS"].includes(this.props.values.associativeItem.item.type.key)) {
      return this.props.values.isDisabled || this.props.values.isRecording || this.props.values.isSubmittingRecord;
    }
    return true;
  };

  isNextDisabled = () => {
    if (
      [
        "GAP_FILL_SELECT",
        "GAP_FILL_MULTIPLE",
        "UNSCRAMBLE_DRAG_AND_DROP",
        "UNSCRAMBLE_SPEECH_RECOGNITION",
        "DICTATION",
        "SPEECH_PRACTICE",
        "SPEECH_PRACTICE_SPEECHLESS",
        "PRONUNCIATION",
        "PRONUNCIATION_SPEECHLESS",
        "UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS",
      ].some((type) => type === this.props.values.associativeItem.item.type.key)
    ) {
      return (
        this.props.values.isDisabled ||
        this.props.values.isRecording ||
        this.props.values.isSubmittingRecord ||
        !this.props.values.answer
      );
    }
    return true;
  };

  handleTimerEnding = () => {
    if (!this.state.isTimerEnding) {
      this.setState({ isTimerEnding: true });
    }
  };

  handleSave = () => {
    if (this.state.isTimerEnding) {
      this.setState({ isTimerEnding: false });
    }
    this.props.onSave();
  };

  render() {
    return (
      <ControlBarLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!this.props.isSpeechRecognitionDisabled && (
            <ControlBarLayoutItem label={this.props.getTranslation("record")}>
              <RecordButtonContainer isDisabled={this.isRecordDisabled()} isRecording={this.props.values.isRecording} />
            </ControlBarLayoutItem>
          )}
          <ControlBarLayoutItem label={this.props.getTranslation("repeat")}>
            <RepeatButtonContainer isDisabled={this.isRepeatDisabled()} type="audio" />
            <div
              style={{
                position: "absolute",
                right: -23,
                bottom: 18,
              }}
            >
              <SlowRepeatButtonContainer isDisabled={this.isRepeatDisabled()} />
            </div>
          </ControlBarLayoutItem>
          {!this.props.isMasteryTestTimerDisabled && (
            <ControlBarLayoutItem
              label={this.props.getTranslation(
                this.state.isTimerEnding ? "masteryTest.timer.runningOut" : "masteryTest.timer.title"
              )}
              textColor={this.state.isTimerEnding ? "#F87171" : ""}
            >
              <MasteryTestItemTimerContainer
                onTimerEnding={this.handleTimerEnding}
                key={this.props.values.associativeItem.item.id}
                time={this.props.values.associativeItem.item.type.defaultMasteryTestTime}
                itemType={this.props.values.associativeItem.item.type.key}
                onTimerEnd={this.handleSave}
              />
            </ControlBarLayoutItem>
          )}
          <ControlBarLayoutItem label={this.props.getTranslation("next")}>
            <NextButton
              onClick={this.handleSave}
              isDisabled={this.isNextDisabled()}
              isSubmitting={this.props.isSubmitting}
            />
          </ControlBarLayoutItem>
        </div>
      </ControlBarLayout>
    );
  }
}

export default withTranslation(MasteryTestExecutionControlBar);
