import React from "react";
import PropTypes from "prop-types";
import TranslatedText from "../../../core/TranslatedText";
import { WHITE } from "../../../consts/color";

const PlacementTestLevelTitle = (props) => (
  <TranslatedText
    as="small"
    translateKey={`placementTest.${props.level}`}
    style={{
      opacity: 0.5,
      color: WHITE,
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "24px",
    }}
  />
);

PlacementTestLevelTitle.propTypes = {
  level: PropTypes.oneOf(["beginner", "intermediate", "advanced"]),
};

export default PlacementTestLevelTitle;
