import React, { useState } from "react";
import ModuleListItem from "./ModuleListItem";
import FlexColumn from "../../core/FlexColumn";
import InBetweenSpacing from "../../core/InBetweenSpacing";
import { useService } from "student-front-commons/src/hooks";
import { getAllTimeStats } from "student-front-commons/src/services/studentStatsService";
import { useSelector } from "react-redux";
import { getModuleIdsByCourseId } from "student-front-commons/src/selectors/entity";
import { useParams } from "react-router-dom";

function ModuleList() {
  const params = useParams();
  const [stats, setStats] = useState({});

  useService(getAllTimeStats, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
    },
    onData: (data) => {
      setStats(data);
    },
  });

  const moduleIds = useSelector(getModuleIdsByCourseId(params.idCourse));

  return (
    <FlexColumn>
      <InBetweenSpacing size="sm">
        {moduleIds?.map((moduleId, index) => (
          <ModuleListItem key={moduleId} showPopoverHint={!stats.points && !index} moduleId={moduleId} />
        ))}
      </InBetweenSpacing>
    </FlexColumn>
  );
}

export default ModuleList;
