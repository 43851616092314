import React from "react";
import PropTypes from "prop-types";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const Logo = (props) => (
  <WhitelabelContext.Consumer>
    {({ logoUrl }) => (
      <img
        alt=""
        src={props.src ? `${process.env.REACT_APP_FILES_URL}/${props.src}` : logoUrl}
        style={{
          height: {
            sm: 21,
            md: 42,
            lg: 66,
          }[props.size],
        }}
      />
    )}
  </WhitelabelContext.Consumer>
);

Logo.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
};

Logo.defaultProps = {
  src: null,
  size: "md",
};

export default Logo;
