import React, { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import $ from "jquery";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import QuestionLabel from "../QuestionLabel";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../CourseThemeContext";
import FlexRow from "../FlexRow";
import ItemImage from "./ItemImage";

export default class FreeTextExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      charactersMinLimit: PropTypes.number.isRequired,
      charactersMaxLimit: PropTypes.number.isRequired,
    }).isRequired,
    inputType: PropTypes.oneOf(["TEXT", "IMAGE"]).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    answer: PropTypes.string,
  };

  static defaultProps = {
    answer: "",
  };

  handleResize = (event) => {
    $(event.target)
      .css("height", "auto")
      .css("height", event.target.scrollHeight + 32);
  };

  render() {
    return (
      <ExerciseItemPanel>
        <Separator />
        <CourseThemeContext.Consumer>
          {({ primary }) => (
            <React.Fragment>
              <div
                style={{
                  borderLeft: `3px solid ${primary}`,
                  paddingLeft: 10,
                }}
              >
                {this.props.inputType === "TEXT" && (
                  <QuestionLabel text={this.props.item.text} cursor="default" fontSize={20} />
                )}
                {this.props.inputType === "IMAGE" && (
                  <QuestionLabel text="Write about the image below using as much details as possible." fontSize={20} />
                )}
              </div>
              <Separator />
              {this.props.inputType === "IMAGE" && (
                <React.Fragment>
                  <HighlightableItemBox>
                    <ItemImage
                      item={this.props.item}
                      style={{
                        width: "100%",
                      }}
                    />
                  </HighlightableItemBox>
                  <Separator />
                </React.Fragment>
              )}
              <HighlightableItemBox>
                <textarea
                  autoCorrect="off"
                  autoComplete="off"
                  autoCapitalize="off"
                  value={this.props.answer}
                  disabled={this.props.disabled}
                  onChange={(event) => this.props.onChange("answer", event.target.value)}
                  onKeyUp={this.handleResize}
                  style={{
                    border: "none",
                    padding: "0px 5px",
                    fontSize: 24,
                    width: "100%",
                    lineHeight: "32px",
                    outline: "none",
                    fontWeight: 400,
                    color: "#607d8b",
                    opacity: this.props.isDisabled ? 0.5 : 1,
                    resize: "none",
                    maxHeight: 160,
                  }}
                />
              </HighlightableItemBox>
              <Separator />
              <FlexRow justifyContent="space-between">
                <QuestionLabel text={`${get(this.props, "answer.length", 0)} characters.`} fontSize={14} />
                <QuestionLabel
                  text={`Minimum: ${this.props.item.charactersMinLimit} / Maximum: ${this.props.item.charactersMaxLimit}`}
                  fontSize={14}
                />
              </FlexRow>
            </React.Fragment>
          )}
        </CourseThemeContext.Consumer>
      </ExerciseItemPanel>
    );
  }
}
