import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import PlacementTestScoreAnalysisScene from "./PlacementTestScoreAnalysisScene";
import Async from "../../../core/Async";
import { END_PLACEMENT_TEST_EXECUTION_FLOW } from "../../../consts";

const PlacementTestScoreAnalysisSceneContainer = () => (
  <Flow autoStart={false} id={END_PLACEMENT_TEST_EXECUTION_FLOW}>
    {({ isPending }) => (
      <Async fetching={isPending}>
        <PlacementTestScoreAnalysisScene />
      </Async>
    )}
  </Flow>
);

export default PlacementTestScoreAnalysisSceneContainer;
