import React from "react";
import PropTypes from "prop-types";

const UnscramblePhraseDragandDropIcon = ({ color, style }) => (
  <svg width="178" height="123" viewBox="0 0 178 123" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      opacity="0.95"
      d="M122.8 64H18.5C17.7 64 17.1 64.7 17.1 65.4C17.1 66.2 17.8 66.8 18.5 66.8H122.8C123.6 66.8 124.2 67.5 124.2 68.2V113.7C124.2 114.5 123.5 115.1 122.8 115.1H4.2C3.4 115.1 2.8 114.4 2.8 113.7V68.2C2.8 67.4 3.5 66.8 4.2 66.8H7C7.8 66.8 8.4 66.1 8.4 65.4C8.4 64.6 7.7 64 7 64H4.2C1.8 64 0 65.9 0 68.2V113.7C0 116.1 1.9 117.9 4.2 117.9H122.7C125.1 117.9 126.9 116 126.9 113.7V68.2C127 65.9 125.1 64 122.8 64Z"
      fill={color}
    />
    <path
      opacity="0.95"
      d="M12.5 67C13.3 67 14 66.3 14 65.5C14 64.7 13.3 64 12.5 64C11.7 64 11 64.7 11 65.5C11 66.3 11.7 67 12.5 67Z"
      fill={color}
    />
    <path
      opacity="0.95"
      d="M28.8999 60.3C28.7999 60.3 28.5999 60.3 28.4999 60.3C27.5999 60.1 26.8999 59.1 27.1999 58.1C27.4999 57 27.9999 55.7 28.5999 54.4C28.9999 53.5 30.0999 53.2 30.9999 53.7C31.8999 54.1 32.1999 55.2 31.6999 56.1C31.1999 57.1 30.7999 58.1 30.5999 59C30.3999 59.8 29.7999 60.3 28.8999 60.3ZM34.1999 51.2C33.7999 51.2 33.3999 51.1 33.0999 50.8C32.2999 50.2 32.1999 49.1 32.8999 48.3C33.5999 47.4 34.4999 46.5 35.2999 45.6C35.8999 44.9 37.0999 44.8 37.7999 45.5C38.4999 46.1 38.5999 47.3 37.8999 48C37.0999 48.9 36.2999 49.7 35.5999 50.6C35.1999 51 34.6999 51.2 34.1999 51.2ZM41.5999 43.6C41.0999 43.6 40.5999 43.4 40.2999 43C39.6999 42.3 39.6999 41.2 40.3999 40.5C41.2999 39.7 42.1999 38.9 43.0999 38.1C43.7999 37.5 44.9999 37.6 45.5999 38.3C46.1999 39 46.0999 40.2 45.3999 40.8C44.4999 41.6 43.5999 42.3 42.7999 43.1C42.3999 43.4 41.9999 43.6 41.5999 43.6ZM49.6999 36.8C49.1999 36.8 48.6999 36.6 48.2999 36.1C47.6999 35.3 47.8999 34.2 48.5999 33.6C49.5999 32.9 50.4999 32.2 51.4999 31.5C52.2999 31 53.3999 31.1 53.9999 31.9C54.4999 32.7 54.3999 33.8 53.5999 34.4C52.6999 35 51.6999 35.7 50.7999 36.5C50.4999 36.6 50.0999 36.8 49.6999 36.8ZM58.4999 30.8C57.8999 30.8 57.2999 30.5 56.9999 30C56.4999 29.2 56.7999 28.1 57.5999 27.6C58.5999 27 59.6999 26.3 60.6999 25.8C61.5999 25.3 62.6999 25.7 63.0999 26.5C63.5999 27.4 63.1999 28.4 62.3999 28.9C61.3999 29.4 60.3999 30 59.3999 30.6C59.0999 30.7 58.7999 30.8 58.4999 30.8ZM68.0999 26.1C67.3999 26.1 66.6999 25.7 66.3999 24.9C66.0999 24 66.4999 23 67.3999 22.6C68.6999 22.1 69.9999 21.8 71.0999 21.6C72.0999 21.5 72.9999 22.1 73.1999 23.1C73.2999 24.1 72.6999 25 71.7999 25.2C70.8999 25.3 69.7999 25.6 68.6999 26.1C68.3999 26 68.1999 26.1 68.0999 26.1Z"
      fill={color}
    />
    <path
      opacity="0.95"
      d="M158.7 123H137.3C136.3 123 135.5 122.2 135.5 121.2C135.5 116.4 139.5 112.4 144.3 112.4H147.1L133.6 98.9C131.4 96.7 131.4 93.3 133.6 91.1C135.8 88.9 139.2 88.9 141.4 91.1L144.6 94.3C144.8 93.7 145.2 93.1 145.7 92.6C146.9 91.4 148.7 90.8 150.4 91C150.6 90 151.1 88.9 151.9 88.2C153.5 86.6 155.7 86.2 157.6 86.9C157.8 86.1 158.3 85.3 159 84.7C161.2 82.5 164.6 82.5 166.8 84.7L172.3 90.2C175.9 93.9 177.9 98.7 177.9 103.8C178 114.4 169.3 123 158.7 123ZM139.3 119.5H158.6C167.3 119.5 174.3 112.4 174.3 103.8C174.3 99.6 172.6 95.7 169.7 92.7L164.2 87.2C163.4 86.4 162.2 86.4 161.4 87.2C160.6 88 160.6 89.2 161.4 90C161.7 90.3 161.9 90.8 161.9 91.2C161.9 91.6 161.8 92.1 161.4 92.5C160.7 93.2 159.6 93.2 158.9 92.5L157.1 90.7C156.3 89.9 155.1 89.9 154.3 90.7C153.9 91.1 153.7 91.6 153.7 92.1C153.7 92.6 153.9 93.1 154.3 93.5L156.1 95.3C156.4 95.6 156.6 96.1 156.6 96.6C156.6 97 156.5 97.5 156.1 97.9C155.4 98.6 154.3 98.6 153.6 97.9L151 95.3C150.3 94.6 148.9 94.6 148.2 95.3C147.8 95.7 147.6 96.2 147.6 96.7C147.6 97.2 147.8 97.7 148.2 98.1L152.7 102.6C153 102.9 153.2 103.4 153.2 103.8C153.2 104.2 153.1 104.7 152.7 105.1C152 105.8 150.9 105.8 150.2 105.1L139 93.7C138.2 92.9 137 92.9 136.2 93.7C135.8 94.1 135.6 94.6 135.6 95.1C135.6 95.6 135.8 96.1 136.2 96.5L152.8 113.1C153.3 113.6 153.4 114.4 153.2 115C153 115.6 152.3 116.1 151.5 116.1H144.4C142 115.9 140.1 117.4 139.3 119.5Z"
      fill={color}
    />
    <g opacity="0.95">
      <path
        opacity="0.95"
        d="M29.0002 83.6V98.2H25.2002V92.2L22.2002 95.7H21.0002L18.0002 92.2V98.2H14.2002V83.6H15.4002L21.5002 91L27.6002 83.6H29.0002Z"
        fill={color}
      />
      <path
        opacity="0.95"
        d="M39.9002 92.7V98.2H36.1002V92.8L31.2002 83.9H35.3002L38.0002 88.9L40.7002 83.9H44.8002L39.9002 92.7Z"
        fill={color}
      />
      <path
        opacity="0.95"
        d="M65.6003 83.8V98.4H64.4003L56.6003 90.9V98.2H52.8003V83.6H54.0003L61.8003 91V83.8H65.6003Z"
        fill={color}
      />
      <path
        opacity="0.95"
        d="M77.4003 97H72.3003L71.8003 98.2H67.8003L74.3003 83.6H75.5003L82.0003 98.2H78.0003L77.4003 97ZM74.9003 90.7L73.6003 94.1H76.0003L74.9003 90.7Z"
        fill={color}
      />
      <path
        opacity="0.95"
        d="M98.7 83.6V98.2H95V92.2L92 95.7H90.8L87.8 92.2V98.2H84V83.6H85.2L91.3 91L97.4 83.6H98.7Z"
        fill={color}
      />
      <path
        opacity="0.95"
        d="M102.3 83.8H112.3V87.2H106.1V89.2H110.5V92.4H106.1V94.9H112.6V98.3H102.4L102.3 83.8Z"
        fill={color}
      />
    </g>
    <rect x="80" y="2" width="56" height="40" fill="white" />
    <path
      d="M133.4 1C131.5 1 93.4 1 93.4 1C92.8 1 92.3 1.5 92.3 2.1C92.3 2.7 92.8 3.2 93.4 3.2H133.4C134 3.2 134.5 3.7 134.5 4.3V41C134.5 41.6 134 42.1 133.4 42.1H81.9C81.3 42.1 80.8 41.6 80.8 41V4.4C80.8 3.8 81.3 3.3 81.9 3.3H84.2C84.8 3.3 85.3 2.8 85.3 2.2C85.3 1.6 84.8 1 84.2 1H81.9C80 1 78.5 2.6 78.5 4.4V41.1C78.5 43 80.1 44.5 81.9 44.5H133.4C135.3 44.5 136.8 42.9 136.8 41.1V4.4C136.8 2.6 135.2 1 133.4 1Z"
      fill={color}
      stroke={color}
    />
  </svg>
);

UnscramblePhraseDragandDropIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default UnscramblePhraseDragandDropIcon;
