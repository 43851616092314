import React from "react";
import PropTypes from "prop-types";
import range from "lodash/range";
import moment from "moment";

const MonthPicker = (props) => (
  <select
    style={{
      background: "none",
      border: "none",
      outline: "none",
      textTransform: "capitalize",
      color: "inherit",
    }}
    value={props.value}
    onChange={(event) => props.onChange(event.target.value)}
  >
    {range(1, 13)
      .map((month) => moment(month, "MM").year(props.selectedYear))
      .map((momentMonth) => (
        <option
          disabled={momentMonth.isAfter(moment().endOf("month"))}
          key={`option-${momentMonth.format("MM")}`}
          value={momentMonth.format("MM")}
        >
          {momentMonth.format("MMMM")}
        </option>
      ))}
  </select>
);

MonthPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedYear: PropTypes.string.isRequired,
};

export default MonthPicker;
