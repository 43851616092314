import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * Get the object for label localization
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.locale - the language locale (en, es, pt)
 * @param {string} payload.type - the labels type (STUDENT or KIDS)
 *
 * @returns {Promise<*>}
 */
export const getLocalization = async (payload) => {
  validate(
    {
      locale: {
        presence: {
          allowEmpty: false,
        },
      },
      type: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `localizations/${payload.locale}?type=${payload.type}`,
        method: "get",
      });
    },
    "error_get_localization",
    "Unexpected error to get localization."
  );
};
