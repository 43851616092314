import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import PlacementTestScoreScene from "./PlacementTestScoreScene";
import Async from "../../../core/Async";
import { END_PLACEMENT_TEST_EXECUTION_FLOW } from "../../../consts";

const PlacementTestScoreSceneContainer = () => (
  <Flow autoStart={false} id={END_PLACEMENT_TEST_EXECUTION_FLOW}>
    {({ isPending }) => (
      <Async fetching={isPending}>
        <PlacementTestScoreScene />
      </Async>
    )}
  </Flow>
);

export default PlacementTestScoreSceneContainer;
