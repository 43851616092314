import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DateLabel = (props) => (
  <label
    htmlFor="date"
    style={{
      margin: 0,
      fontWeight: 400,
      fontSize: {
        xs: "11px",
        sm: "13px",
        md: "22px",
        lg: "36px",
      }[props.size],
      color: props.color,
      display: "inline-block",
      textTransform: "capitalize",
    }}
  >
    {moment(props.date).format(props.format)}
  </label>
);

DateLabel.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  format: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
};

DateLabel.defaultProps = {
  size: "sm",
};

export default DateLabel;
