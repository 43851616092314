import React, { Component } from "react";
import PropTypes from "prop-types";
import min from "lodash/min";
import TranslatedText from "./TranslatedText";
import withTranslation from "./withTranslation";
import { WHITE } from "../consts/color";
import floor from "lodash/floor";

class CourseSummary extends Component {
  static propTypes = {
    coursePercentage: PropTypes.number.isRequired,
  };

  state = { width: 0 };

  componentDidMount() {
    this.progressTimeout = setTimeout(() => {
      this.setState({
        width: min([this.props.coursePercentage, 100]),
      });
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.progressTimeout);
  }

  getStatus = (masteryTest) => {
    if (masteryTest.approvedAt) {
      return "approved";
    } else if (masteryTest.failedAt) {
      return "failed";
    } else if (masteryTest.availableAt) {
      return "unlocked";
    }
    return "locked";
  };

  render() {
    return (
      <div
        className="stepModuleCourseSummary"
        style={{
          display: "inline-block",
          width: "100%",
          marginLeft: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TranslatedText
            as="span"
            translateKey="subMenu.courseProgress"
            style={{
              color: WHITE,
              fontSize: 11,
            }}
          />
          <span
            htmlFor="coursePercentage"
            style={{
              color: WHITE,
              fontSize: 13,
            }}
          >
            {floor(this.props.coursePercentage, 0)}%
          </span>
        </div>
        <div
          style={{
            position: "relative",
            height: 5,
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "#CCC",
            }}
          />
          <div
            style={{
              width: `${this.state.width}%`,
              height: "100%",
              background: "#fff",
              position: "absolute",
              top: 0,
              transition: "width 1s ease-in-out",
            }}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation(CourseSummary);
