import { head, sample } from "lodash";
import { call, cancelled, delay, put, race, select, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import browserHistory from "../browserHistory";
import {
  CLOSE_UNIT_EXECUTION_FLOW,
  CLOSE_USER_AWAY_MODAL,
  SHOW_USER_AWAY_MODAL,
  USER_AWAY_FLOW,
  USER_AWAY_TIMEOUT_FLOW,
} from "../consts";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import { incrementItemExecutionUserAway } from "student-front-commons/src/actions/itemExecution";
import { getEntityById } from "student-front-commons/src/selectors/entity";

export default function* () {
  yield takeLatest(getFlowStart(USER_AWAY_FLOW), function* () {
    const { userIsBack } = yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      userIsBack: take(getFlowStart(CLOSE_USER_AWAY_MODAL)),
      call: call(function* () {
        try {
          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.reduce(function* (promise, itemId) {
            yield promise;
            yield put(incrementItemExecutionUserAway(itemId));
          }, Promise.resolve());

          const userAwayAudios = yield select((state) => state.configurations.userAwayAudios);
          const firstRandomAudio = sample(userAwayAudios);

          yield put(
            startFlow(SHOW_USER_AWAY_MODAL, {
              message: firstRandomAudio.text,
            })
          );

          yield call(playAudio, {
            url: firstRandomAudio.path || firstRandomAudio.generatedAudio,
            rate: 1,
          });
          yield delay(15000);
          yield call(playAudio, {
            url: firstRandomAudio.path || firstRandomAudio.generatedAudio,
            rate: 1,
          });
          yield delay(15000);

          const profile = yield select(getEntityById("profile", sessionStorage.getItem("id")));
          const moduleId = browserHistory.location.pathname.match(/\/modules\/([^/]+)\/units/)[1];
          const module = yield select(getEntityById("module", moduleId));

          if (profile.id !== "tasting_user") {
            browserHistory.replace({
              pathname: module?.disabled
                ? `${head(browserHistory.location.pathname.split("/modules"))}/modules`
                : `${head(browserHistory.location.pathname.split("/units"))}/units`,
              state: { systemNavigation: true },
            });
            yield put(startFlow(CLOSE_UNIT_EXECUTION_FLOW));
          }
        } catch (error) {
          if (typeof error === "string" && error.indexOf("Playback was unable to start") === 0) {
            return;
          }
          logError({ error, flow: USER_AWAY_TIMEOUT_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(SHOW_USER_AWAY_MODAL));
          yield put(endFlow(USER_AWAY_FLOW));
        }
      }),
    });
    if (userIsBack) {
      yield put(startFlow(USER_AWAY_TIMEOUT_FLOW));
      yield put(endFlow(CLOSE_USER_AWAY_MODAL));
      yield put(endFlow(SHOW_USER_AWAY_MODAL));
    }
  });
}
