import React from "react";
import Separator from "../../core/Separator";
import AnnouncementsList from "../../core/AnnoucementsList";
import CertificationTestStatusBarContainer from "../../core/CertificationTestStatusBarContainer";
import ModuleList from "./ModuleList";
import SceneVideoIntroductionAutoContainer from "../../core/SceneVideoIntroductionAutoContainer";
import SubHeaderDetailsContainer from "../../core/SubHeaderDetailsContainer";
import BreadcrumbContainer from "../../core/BreadcrumbContainer";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";
import moment from "moment";

const ModuleListScene = () => {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity("company", profile?.company);

  return (
    <div>
      <SubHeaderDetailsContainer />
      <BreadcrumbContainer />
      <Separator size="xs" />
      <div className="container">
        <AnnouncementsList />
        <CertificationTestStatusBarContainer />
        <ModuleList />
      </div>
      <Separator size="md" />
      {moment(profile?.createdAt).isAfter(moment("2024-07-01")) && (
        <SceneVideoIntroductionAutoContainer scene="WELCOME" />
      )}
      {!!company?.isOptionalReviewEnabled &&
        ((moment(profile?.createdAt).isAfter(moment("2024-07-01")) && profile?.pagesAccessed?.includes("WELCOME")) ||
          moment(profile?.createdAt).isBefore(moment("2024-07-01"))) && (
          <SceneVideoIntroductionAutoContainer scene="OPTIONAL_REVIEW" />
        )}
      <Separator size="xs" />
    </div>
  );
};

export default ModuleListScene;
