import kebabCase from "lodash/kebabCase";
import { call, put, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endPlacementTestExecution } from "student-front-commons/src/services/placementTestService";
import { requestMergePlacementTestResult } from "../actionCreators/placementTestResult";
import { mergeEntities } from "student-front-commons/src/actions/entity";
import { getCourses } from "student-front-commons/src/services/courseService";
import { getProfile } from "student-front-commons/src/services/profileService";
import browserHistory from "../browserHistory";
import {
  DISMISS_SYSTEM_MESSAGE_FLOW,
  END_PLACEMENT_TEST_EXECUTION_FLOW,
  LOAD_CONFIGURATION_FLOW,
  PLACEMENT_TEST_EXECUTION_FORM,
} from "../consts";
import { addSentryUserAction, logError } from "../util";
import { playAudio } from "../stores/audio-store";
import getForm from "student-front-commons/src/selectors/getForm";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { getInstructionAudio } from "student-front-commons/src/selectors/configuration";

const sentryUserAction = { mainComponent: "endPlacementTestExecutionFlow" };

export default function* () {
  yield takeLatest(getFlowStart(END_PLACEMENT_TEST_EXECUTION_FLOW), function* () {
    try {
      const flow = yield select(getFlow(END_PLACEMENT_TEST_EXECUTION_FLOW));

      const result = yield call(endPlacementTestExecution, {
        stopReason: flow.params.stopReason,
        reachedLevel: flow.params.reachedLevel,
        placementTestExecution: flow.params.placementTestExecution,
      });

      if (sessionStorage.getItem("id") !== "tasting_user") {
        yield put(requestMergePlacementTestResult(result));
        const profileResult = yield call(getProfile, {
          id: sessionStorage.getItem("id"),
          role: "STUDENT",
        });
        yield put(mergeEntities(profileResult.entities));

        const courses = yield call(getCourses, {
          id: sessionStorage.getItem("id"),
        });
        yield put(mergeEntities(courses.entities));

        //don't need to reload it, because student can advance to the system
        if (!sessionStorage.getItem("registeredByLink")) {
          yield put(startFlow(LOAD_CONFIGURATION_FLOW));
        }
      } else {
        const placementTestExecutionForm = yield select(getForm(PLACEMENT_TEST_EXECUTION_FORM));

        yield put(
          requestMergePlacementTestResult({
            id: flow.params.placementTestExecution,
            reachedLevel: result.reachedLevel,
            answeredItems: placementTestExecutionForm.values.answers,
          })
        );
      }

      if (!sessionStorage.getItem("registeredByLink")) {
        const placementTestResultAudio = yield select(getInstructionAudio("placementTest.result"));
        yield spawn(playAudio, {
          url: placementTestResultAudio,
          isCompleteUrl: false,
          rate: 1,
        });
      }
    } catch (error) {
      if (error && error.data && error.data.message) {
        yield call(browserHistory.push, `/${kebabCase(error.data.message.toLowerCase())}`);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Placement Test error. Navigate to: /${kebabCase(error.data.message.toLowerCase())}`,
        });
      } else {
        logError({ error, flow: END_PLACEMENT_TEST_EXECUTION_FLOW });

        yield put(
          showMessage({
            message: "placementTest.endError",
            button: "placementTest.button.tryAgain",
          })
        );

        yield put(endFlow(END_PLACEMENT_TEST_EXECUTION_FLOW));
        yield take(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW));
        yield put(startFlow(END_PLACEMENT_TEST_EXECUTION_FLOW));
      }
    } finally {
      yield put(endFlow(END_PLACEMENT_TEST_EXECUTION_FLOW));
    }
  });
}
