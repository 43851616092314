import React from "react";
import PropTypes from "prop-types";

const NotificationCounter = (props) => (
  <div
    className={props.value > 0 ? "animated heartBeat" : ""}
    style={{
      position: "absolute",
      backgroundColor: "#F44336",
      zIndex: 10,
      width: 16,
      height: 16,
      top: 3,
      right: 7,
      borderRadius: "100%",
      border: "1px solid",
      borderColor: "#fff",
    }}
  >
    <div
      style={{
        position: "relative",
        textAlign: "center",
        color: "#fff",
        fontSize: 9,
        fontWeight: 700,
        left: 0,
        right: 0,
        margin: "0 auto",
        top: "50%",
        transform: "translateY(-50%)",
      }}
    >
      {props.value}
    </div>
  </div>
);

NotificationCounter.propTypes = {
  value: PropTypes.number.isRequired,
};

export default NotificationCounter;
