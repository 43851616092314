import { call, cancelled, put, race, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { CLOSE_UNIT_EXECUTION_FLOW, PLAY_RECORD_AUDIO_FLOW } from "../consts";
import { playAudio, stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import { disableItemExecution, enableItemExecution, listenItem } from "student-front-commons/src/actions/itemExecution";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import apiRequest from "student-front-commons/src/core/request";

export default function* () {
  yield takeLatest(getFlowStart(PLAY_RECORD_AUDIO_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());
          const itemId = yield select(getCurrentItemExecutionProp("item.id"));
          const recordUrl = yield select(getCurrentItemExecutionProp("recordUrl"));

          yield call(playAudio, {
            url: recordUrl,
            rate: 1,
            isCompleteUrl: true,
          });

          yield put(listenItem(itemId));

          const id = sessionStorage.getItem("id");
          if (id === "tasting_user") {
            yield spawn(function* () {
              const execution = yield select((state) => state.executions);

              yield call(apiRequest, {
                method: "put",
                url: `modules/tasting/units/${execution.unit}/update-lead`,
                data: {
                  action: "LISTEN",
                },
              });
            });
          }
        } catch (error) {
          logError({ error, flow: PLAY_RECORD_AUDIO_FLOW });
          yield put(
            showMessage({
              message: "error.error_play_record",
            })
          );
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(enableItemExecution());
          yield put(endFlow(PLAY_RECORD_AUDIO_FLOW));
        }
      }),
    });
  });
}
