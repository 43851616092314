import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import SubHeaderDetails from "./SubHeaderDetails";

class SubHeaderDetailsContainer extends Component {
  render() {
    return (
      <Entity name="course">
        {({ getById }) => <SubHeaderDetails course={getById(this.props.match.params.idCourse)} />}
      </Entity>
    );
  }
}

export default withRouter(SubHeaderDetailsContainer);
