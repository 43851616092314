import orderBy from "lodash/orderBy";
import { all, call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { startMasteryTestExecution } from "student-front-commons/src/services/masteryTestExecutionService";
import { requestCleanMasteryTestResult } from "../actionCreators/masteryTestResult";
import { addImageDataToItems, addSoundToItems, logError } from "../util";
import {
  GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW,
  MASTERY_TEST_EXECUTION_FORM,
  START_MASTERY_TEST_TASTING_EXECUTION_FLOW,
} from "../consts";
import browserHistory from "../browserHistory";

export default function* () {
  yield takeLatest(getFlowStart(START_MASTERY_TEST_TASTING_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(START_MASTERY_TEST_TASTING_EXECUTION_FLOW));
    try {
      const result = yield call(startMasteryTestExecution, {
        module: flow.params.module,
        masteryTest: flow.params.masteryTest,
      });

      const orderedItems = orderBy(result.items, ["order"], ["asc"]);

      yield all([...addImageDataToItems(orderedItems.slice(0, 3)), ...addSoundToItems(orderedItems.slice(0, 3))]);

      yield put(
        initForm(MASTERY_TEST_EXECUTION_FORM, {
          module: flow.params.module,
          masteryTest: flow.params.masteryTest,
          execution: result.masteryTestExecution.id,
          masteryTestItems: orderedItems,
          answers: [],
          currentOrder: 0,
        })
      );

      yield spawn(function* () {
        yield all([...addImageDataToItems(orderedItems.slice(3)), ...addSoundToItems(orderedItems.slice(3))]);
      });

      yield put(startFlow(GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW));
      yield put(requestCleanMasteryTestResult());
    } catch (error) {
      setTimeout(() => {
        if (error.code === "student_mastery_test_approved") {
          browserHistory.replace("/mastery-test-approved");
        } else if (error.code === "student_mastery_test_failed") {
          browserHistory.replace("/mastery-test-failed");
        } else {
          logError({ error, flow: START_MASTERY_TEST_TASTING_EXECUTION_FLOW });
          browserHistory.replace("/mastery-test-not-found");
        }
      }, 0);
    } finally {
      yield put(endFlow(START_MASTERY_TEST_TASTING_EXECUTION_FLOW));
    }
  });
}
