import React from "react";
import { connect } from "react-redux";
import TeacherWin from "../../images/teacher-win.png";
import TeacherFail from "../../images/teacher-fail.png";

const CertificationTestResultIconContainer = (props) => (
  <img
    alt="icon"
    src={!!props.certificationTest.approvedAt ? TeacherWin : TeacherFail}
    style={{
      position: "absolute",
      right: 15,
      bottom: !!props.certificationTest.approvedAt ? -25 : -10,
    }}
  />
);

const mapStateToProps = (state) => ({
  certificationTest: state.certificationTestResult,
});

export default connect(mapStateToProps)(CertificationTestResultIconContainer);
