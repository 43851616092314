import React from "react";
import { useFlow, useService } from "student-front-commons/src/hooks";
import Async from "../../core/Async";
import BonusTestScene from "./BonusTestScene";
import { START_BONUS_TEST_EXECUTION_FLOW } from "../../consts";
import { useParams } from "react-router-dom";
import { getCurrentBonusTest } from "student-front-commons/src/services/bonusTestService";
import { isEmpty } from "lodash";
import browserHistory from "../../browserHistory";

export default function BonusTestSceneContainer() {
  const [isPending, startFlow] = useFlow(START_BONUS_TEST_EXECUTION_FLOW, { autoStart: false });
  const params = useParams();

  const [isFetching] = useService(getCurrentBonusTest, {
    params: { idCourse: params.idCourse },
    autoStart: true,
    onData: (data) => {
      if (!isEmpty(data)) {
        browserHistory.replace(`/course/${params.idCourse}/bonus-test/result`);
      } else {
        startFlow();
      }
    },
  });

  return (
    <Async fetching={isPending || isFetching}>
      <BonusTestScene />
    </Async>
  );
}
