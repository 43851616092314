import React from "react";
import PropTypes from "prop-types";
import { Route, Prompt } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AnimatedRoute = (props) => (
  <Route
    exact={props.exact}
    path={props.path}
    render={() => (
      <TransitionGroup>
        <CSSTransition classNames="fade" appear timeout={{ enter: 0, exit: 250, appear: 250 }}>
          <>
            <Prompt message={props.prompt} />
            {React.createElement(props.component, props)}
          </>
        </CSSTransition>
      </TransitionGroup>
    )}
  />
);

AnimatedRoute.propTypes = {
  exact: PropTypes.bool,
  prompt: PropTypes.func,
  path: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
};

AnimatedRoute.defaultProps = {
  exact: false,
  prompt: () => true,
};

export default AnimatedRoute;
