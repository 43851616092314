import React from "react";
import { withRouter } from "react-router-dom";
import get from "lodash/get";
import Flow from "student-front-commons/src/components/Flow";
import Entity from "student-front-commons/src/components/Entity";
import MessageChatReplyForm from "./MessageChatReplyForm";
import { SEND_MESSAGE_FLOW } from "../../consts";

const MessageChatReplyFormContainer = (props) =>
  get(props.history, "location.state.messageChannel", false) && (
    <Entity name="messageChannel">
      {({ getById }) => {
        const channel = getById(props.history.location.state.messageChannel);
        return (
          <Flow
            id={SEND_MESSAGE_FLOW}
            params={{
              messageChannel: channel.id,
            }}
          >
            {({ isPending, handleStart }) => <MessageChatReplyForm onSubmit={handleStart} isSubmitting={isPending} />}
          </Flow>
        );
      }}
    </Entity>
  );

export default withRouter(MessageChatReplyFormContainer);
