import React from "react";
import PropTypes from "prop-types";
import InitialStep from "./InitialStep";
import SpeechRecognitionTestStep from "./SpeechRecognitionTestStep";
import ConfigStep from "./ConfigStep";
import Separator from "../../core/Separator";

const MicrophoneConfigScene = (props) => (
  <div className="container">
    <Separator size="md" />
    <div
      style={{
        width: 860,
        backgroundColor: "#fff",
        borderRadius: 5,
        overflow: "hidden",
        margin: "0 auto",
      }}
    >
      {props.currentStep === "INITIAL" && <InitialStep />}
      {props.currentStep === "SPEECH_RECOGNITION" && <SpeechRecognitionTestStep />}
      {props.currentStep === "TUTORIAL" && <ConfigStep />}
    </div>
  </div>
);

MicrophoneConfigScene.propTypes = {
  currentStep: PropTypes.string.isRequired,
};

export default MicrophoneConfigScene;
