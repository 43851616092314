import React from "react";
import Styled from "styled-components";
import { useUserReport } from "student-front-commons/src/components/UserReportContext";

const Button = Styled.button`
  color: #eceff1;
  font-size: 16px;
  font-weight: 500;
  margin: 0 1% 0 0;
  padding: 0.25em 1em;
  width: 130px;
  height: 30px;
  border-radius: 5px 5px 0 0;
  border: none;
  background-color: #013666;
  position: fixed;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.4);
  bottom: 0;
  right: 0;
  z-index: ${Number.MAX_SAFE_INTEGER - 1};
  transition: all ease-in-out 150ms;
  
  &:hover {
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
    transform: scale(1.1);
  }
`;

const ReportButton = () => {
  const { handleStartReport, isDeactivate } = useUserReport();
  if (isDeactivate) {
    return null;
  }

  return (
    <Button onClick={handleStartReport} data-html2canvas-ignore={true}>
      Report error
    </Button>
  );
};

export default ReportButton;
