import React from "react";
import Flow from "student-front-commons/src/components/Flow";
import TestItemExecutionForm from "../TestItemExecutionForm";
import PracticeTestTourContainer from "./PracticeTestTourContainer";
import { SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW } from "../../../consts";

const PracticeTestItemExecutionFormContainer = () => (
  <Flow id={SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW}>
    {({ isPending, handleStart }) => {
      return (
        <React.Fragment>
          <TestItemExecutionForm onSubmit={handleStart} isSubmitting={isPending} />
          <PracticeTestTourContainer />
        </React.Fragment>
      );
    }}
  </Flow>
);

export default PracticeTestItemExecutionFormContainer;
