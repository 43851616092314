import React from "react";
import PropTypes from "prop-types";
import FlexRow from "../../core/FlexRow";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const Step = (props) => (
  <FlexRow
    maxWidth={700}
    width="100%"
    position="relative"
    justifyContent="center"
    padding="40px 20px 0px 20px"
    margin="0 auto"
  >
    <div
      style={{
        height: 2,
        width: "85%",
        position: "absolute",
        backgroundColor: "#ffffff33",
        display: "flex",
        alignSelf: "center",
      }}
    />
    <WhitelabelContext.Consumer>
      {({ secondaryColor }) => (
        <div
          style={{
            height: 2,
            width: `calc(${props.completedWidth}% - 40px)`,
            position: "absolute",
            left: 20,
            padding: "0 20px",
            backgroundColor: secondaryColor,
            display: "flex",
            alignSelf: "center",
          }}
        />
      )}
    </WhitelabelContext.Consumer>
    <FlexRow width="100%" justifyContent="space-between">
      {props.children}
    </FlexRow>
  </FlexRow>
);

Step.propTypes = {
  children: PropTypes.node,
};

Step.defaultProps = {
  children: "",
};

export default Step;
