import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import FetchData from "student-front-commons/src/components/FetchData";
import { getScoreHistoryByPeriodAndGroup } from "student-front-commons/src/services/studyQualityService";
import StudyQualityChart from "../../core/StudyQualityChart";

export default class StudyQualityChartContainer extends Component {
  static propTypes = {
    type: PropTypes.oneOf(["day", "week", "month"]).isRequired,
  };

  render() {
    return (
      <FetchData
        service={getScoreHistoryByPeriodAndGroup}
        params={{
          id: sessionStorage.getItem("id"),
          group: this.props.type,
          from: moment().subtract(6, this.props.type).startOf(this.props.type).format("YYYY-MM-DD"),
          to: moment().endOf(this.props.type).format("YYYY-MM-DD"),
        }}
      >
        {({ data, isFetching }) => <StudyQualityChart data={data || []} fetching={isFetching} />}
      </FetchData>
    );
  }
}
