import React from "react";
import PropTypes from "prop-types";
import CourseIcon from "../../core/CourseIcon";
import HeaderBg from "../../images/course-summary-header-bg.png";
import Icon from "../../core/Icon";
import CourseThemeContext from "../../core/CourseThemeContext";

const CourseSummaryHeader = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          width: "100%",
          height: 290,
          position: "relative",
          overflow: "hidden",
          marginBottom: -2,
          backgroundColor: primary,
          backgroundImage: `url(${HeaderBg})`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "absolute",
            zIndex: 0,
            borderRight: "1280px solid #fff",
            borderTop: "340px solid transparent",
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 1,
            textAlign: "center",
            paddingTop: 70,
          }}
        >
          <div
            style={{
              position: "absolute",
              backgroundColor: "#00786A",
              height: 50,
              width: 50,
              borderRadius: "100%",
              right: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon icon="check" color="#fff" size="md" />
          </div>
          <CourseIcon value={props.course.name} size="xxl" />
        </div>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CourseSummaryHeader.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default CourseSummaryHeader;
