import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import UnitListItem from "./UnitListItem";
import UnitListItemV2 from "./UnitListItemV2";
import Separator from "../../core/Separator";
import ContentVideoListItem from "../../core/content-video-list/ContentVideoListItem";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "UnitList" };

const UnitList = (props) =>
  props.units.map((unit, index) => {
    if (unit.isContentVideo) {
      return (
        <React.Fragment key={`unit-${unit.id}`}>
          <ContentVideoListItem
            onClick={() => {
              props.history.replace(`units/${unit.id}/content-video-exercise`);
              addSentryUserAction({
                ...sentryUserAction,
                clickedComponent: "UnitButton",
                action: `Navigate to ${props.match.url}/${unit.id}/content-video-exercise`,
              });
            }}
            unit={unit}
            isOptionalReviewEnabled={props.isOptionalReviewEnabled}
          />
          <Separator size="xs" />
        </React.Fragment>
      );
    }

    const isSimpleReviewAllowed = !!(
      unit.status === "SIMPLE_REVIEW" &&
      ((props.currentCertificationTest && !props.currentCertificationTest.approvedAt) ||
        (props.failedMasteryTest?.failedAt &&
          (moment(props.failedMasteryTest?.failedAt).isSameOrAfter(moment(unit.lastExecutionCompletedAt)) ||
            unit.lastExecutionCompletedAt === null)))
    );

    return (
      <React.Fragment key={`unit-${unit.id}`}>
        {props.isOptionalReviewEnabled ? (
          <UnitListItemV2
            unit={unit}
            isSpeechRecognitionDisabled={props.isSpeechRecognitionDisabled}
            showFirstAccessTooltip={index === 0 ? props.isFirstAccess : false}
            isSimpleReviewAllowed={isSimpleReviewAllowed}
            numberOfDayBeforeFirstReview={props.numberOfDayBeforeFirstReview}
            timeToAllowContinueUnit={props.timeToAllowContinueUnit}
          />
        ) : (
          <UnitListItem
            unit={unit}
            isSpeechRecognitionDisabled={props.isSpeechRecognitionDisabled}
            showFirstAccessTooltip={index === 0 ? props.isFirstAccess : false}
            isSimpleReviewAllowed={isSimpleReviewAllowed}
            numberOfDayBeforeFirstReview={props.numberOfDayBeforeFirstReview}
            timeToAllowContinueUnit={props.timeToAllowContinueUnit}
          />
        )}
        <Separator size="xs" />
      </React.Fragment>
    );
  });

UnitList.propTypes = {
  units: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      scoreToPass: PropTypes.number.isRequired,
      isContentVideo: PropTypes.bool.isRequired,
    })
  ).isRequired,
  currentCertificationTest: PropTypes.shape({ approvedAt: PropTypes.string }),
  numberOfDayBeforeFirstReview: PropTypes.number.isRequired,
  numberOfDayBeforeSecondReview: PropTypes.number.isRequired,
  timeToAllowContinueUnit: PropTypes.number.isRequired,
  isFirstAccess: PropTypes.bool.isRequired,
  isSpeechRecognitionDisabled: PropTypes.bool.isRequired,
  isOptionalReviewEnabled: PropTypes.bool,
};

UnitList.defaultProps = {
  isOptionalReviewEnabled: false,
};

export default withRouter(UnitList);
