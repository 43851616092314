import React, { useMemo, useState } from "react";
import { orderBy, range, reverse, round, shuffle } from "lodash";
import TranslatedText from "../TranslatedText";
import { GREY_5 } from "../../consts/color";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp, getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import SpeechRecognitionResultWord from "./SpeechRecognitionResultWord";
import { useEntity } from "student-front-commons/src/hooks";
import { useParams } from "react-router-dom";

export default function SpeechRecognitionResultWordList() {
  const { idModule } = useParams();
  const itemType = useSelector(getItemsExecutionsType);

  const [option] = useState("score");

  const itemText = useSelector(getCurrentItemExecutionProp("item.text"));
  const itemPostPhrase = useSelector(getCurrentItemExecutionProp("item.postPhrase"));
  const speechRecognitionFailCount = useSelector(getCurrentItemExecutionProp("speechRecognitionFailCount"));
  const qualityScore = useSelector(getCurrentItemExecutionProp("speechRecognitionResult.qualityScore"));
  const wordScoreList = useSelector(getCurrentItemExecutionProp("speechRecognitionResult.wordScoreList"));

  const module = useEntity("module", idModule);
  const course = useEntity("course", module?.course);

  let text;
  if ("VOCABULARY_ACADEMIC" === itemType) {
    text = itemPostPhrase;
  } else {
    text = itemText;
  }

  const randomTextOrder = useMemo(() => {
    return text ? shuffle(text.split(" ").map((s, i) => i)) : [];
  }, [text]);

  const totalWordsToShow = useMemo(() => {
    if (!speechRecognitionFailCount || !text) {
      return 0;
    }

    if (speechRecognitionFailCount === 1) {
      return round(text.split(" ").length * 0.3);
    } else if (speechRecognitionFailCount === 2) {
      return round(text.split(" ").length * 0.45);
    } else {
      return round(text.split(" ").length * 0.6);
    }
  }, [speechRecognitionFailCount, text]);

  const textIndexesToShow = useMemo(() => {
    if (!text || !wordScoreList) {
      return [];
    }

    if (option === "inicio") {
      return range(0, totalWordsToShow, 1);
    }
    if (option === "fim") {
      return reverse(range(0, text.split(" ").length, 1)).slice(0, totalWordsToShow);
    }
    if (option === "random") {
      return randomTextOrder.slice(0, totalWordsToShow);
    }
    if (option === "score") {
      return orderBy(
        wordScoreList.map((s, index) => ({ ...s, index })),
        ["qualityScore", "index"],
        ["asc", "desc"]
      )
        .filter((s) => s.word.length >= 3)
        .slice(0, totalWordsToShow)
        .map((s) => s.index);
    }
  }, [randomTextOrder, totalWordsToShow, option, text, wordScoreList]);

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        padding: "10px 15px",
        backgroundColor: "#fff",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 3px 0px",
        borderRadius: 3,
        userSelect: "none",
        maxWidth: "100%",
        gap: 5,
      }}
    >
      {qualityScore > 30 &&
        text
          .split(" ")
          .map((sliceText, index) => (
            <SpeechRecognitionResultWord
              key={`${sliceText}-${index}`}
              text={sliceText}
              index={index}
              shouldShowWhenRecording={
                ["C1", "C2"].includes(course?.name) ||
                (!!speechRecognitionFailCount && textIndexesToShow.includes(index))
              }
            />
          ))}
      {qualityScore > 10 && qualityScore <= 30 && (
        <TranslatedText
          as="label"
          translateKey="items.speechRecognition.errorLowScore"
          style={{
            color: GREY_5,
            marginBottom: 0,
          }}
        />
      )}
      {qualityScore <= 10 && (
        <TranslatedText
          as="label"
          translateKey="items.speechRecognition.errorNoSpeech"
          style={{
            color: GREY_5,
            marginBottom: 0,
          }}
        />
      )}
    </div>
  );
}
