const labels = { 
'account.avatarTakePhoto': 'Tirar uma foto', 
'account.birthDate': 'Data de Nascimento', 
'account.button.save': 'Salvar', 
'account.button.saveContinue': 'Salvar e Continuar', 
'account.cancelAvatar': 'Cancelar', 
'account.changeAvatar': 'Alterar foto', 
'account.chooseAvatarOption': 'Escolha uma opção', 
'account.currentPassword': 'Senha Atual', 
'account.email': 'E-mail', 
'account.female': 'Feminino', 
'account.firstAccessDescription': 'Antes de iniciar, vamos completar seu perfil?', 
'account.gender': 'Sexo', 
'account.male': 'Masculino', 
'account.newPassword': 'Nova Senha', 
'account.notRankingParticipant': 'Não desejo participar do ranking', 
'account.passwordDescription': 'Para alterar sua senha, você deve inserir sua senha atual e então a nova senha desejada.', 
'account.passwordQuestion': 'Gostaria de alterar a senha?', 
'account.passwordTitle': 'Senha', 
'account.rankingParticipant': 'Desejo participar do ranking', 
'account.selectFromGallery': 'Selecionar uma imagem da galeria', 
'account.title': 'Seus dados', 
'achievement.noData': 'Você não possui conquistas, continue estudando para ganhar sua primeira medalha!', 
'achievement.noNationalData': 'Sem conquistas nacionais', 
'achievement.noRegionalData': 'Sem conquistas regionais', 
'achievement.noSchoolData': 'Sem conquistas institucionais', 
'achievement.noStudentData': 'Sem conquistas pessoais', 
'achievement.title': 'Conquistas', 
'alert.closeContentVideoExecution': 'Tem certeza que deseja sair?', 
'alert.closeExecution': 'Se você sair agora, o progresso do seu estudo será perdido após {timeToAllowContinueUnit}. Tem certeza que deseja sair ?', 
'alert.closeExecutionNoProgressLoss': 'Tem certeza que deseja sair?', 
'alert.closeMastery': 'Se você sair agora, seu Teste de Aprendizagem será finalizado como reprovado e você precisará fazer novas units para habilitar uma nova tentativa. Tem certeza que deseja sair?', 
'alert.closeTasting': 'Você realmente deseja encerrar a demonstração?', 
'alert.logout': 'Você esta saindo do aplicativo. Tem certeza que deseja sair ?', 
'alert.no': 'Não', 
'alert.oldAppVersionButton': 'Atualizar agora', 
'alert.oldAppVersionDescription': 'Você possui atualizações pendentes do aplicativo. Por favor, atualize seu aplicativo para continuar.', 
'alert.oldAppVersionTitle': 'Atualização disponível', 
'alert.title': 'Atenção', 
'alert.yes': 'Sim', 
'announcement.newMasteryTestListLayout': 'Hi, student! Temos uma mudança nos Testes de Aprendizagem. Agora, eles se encontram no final de cada fase, e serão liberados uma vez que você finalizar todos os exercícios dessa fase. Caso você tenha alguma dúvida em relação a essa alteração, o time de suporte estará à disposição para ajudar.', 
'bonusTest.closeExecution': 'Se você sair agora, perderá a chance de continuar avançando no teste para obter o progresso máximo de acordo com o seu nível e o bônus de pontuação. Tem certeza que deseja sair?', 
'bonusTest.correctAnswers': '{correctAnswers} respostas corretas', 
'bonusTest.executionTitle': 'Teste Bônus', 
'bonusTest.history': 'Histórico do Teste Bônus', 
'bonusTest.history.notCompleted	': 'Teste não finalizado', 
'bonusTest.historyDiff': 'Em relação ao teste anterior.', 
'bonusTest.hits': '{hits} acertos', 
'bonusTest.introduction.blocked.description': 'Você precisa atingir 60% do curso para poder resgatar o bônus.', 
'bonusTest.introduction.blocked.title': 'Continue progredindo no curso para resgatar seu Bônus.', 
'bonusTest.introduction.confirmation': 'Você só possui uma chance para realizar o teste e o progresso não poderá ser salvo. Deseja realmente iniciar?', 
'bonusTest.introduction.description': 'Responda o máximo de questões corretamente para analisar sua evolução e ainda conquiste pontos para o ranking.', 
'bonusTest.introduction.welcome': 'Bem-vindo ao Bonus Test!', 
'bonusTest.noBonus': 'Sem bônus', 
'bonusTest.noPoints': 'Sem Pontos', 
'bonusTest.points': '{points} pontos', 
'bonusTest.rescue': 'Resgatar Bonus', 
'bonusTest.result.bonusPoints': 'Seu bônus foi de {points} pontos.', 
'bonusTest.result.correctAnswers': 'Você acertou <b>{correctAnswers} respostas</b>!', 
'bonusTest.result.lastResults': 'Últimos Resultados', 
'bonusTest.result.noBonus.description': 'Mas não se preocupe, você poderá realizar um novo teste no próximo curso.', 
'bonusTest.result.noBonus.title': 'Não foi dessa vez.', 
'bonusTest.result.noLastResults': 'Você ainda não possui testes finalizados anteriores para visualizar a comparação com o seu resultado atual.', 
'bonusTest.result.notCompleted.description1': 'Ao não finalizar o teste você perde a chance de ganhar pontos e de analisar o seu progresso.', 
'bonusTest.result.notCompleted.description2': 'Você terá uma nova chance no próximo curso.', 
'bonusTest.result.notCompleted.title': 'Ei! Seu teste não foi finalizado.', 
'bonusTest.result.title': 'Seu resultado', 
'bonusTest.start': 'Começar o Bonus Test', 
'bugReporter.button.cancel': 'Cancelar', 
'bugReporter.button.send': 'Enviar', 
'bugReporter.inputPlaceholder': 'Descreva o problema encontrado', 
'bugReporter.successMessage': 'Seu reporte foi salvo! Obrigado por ajudar a melhorar nossa plataforma.', 
'bugReporter.title': 'Reportar Erro', 
'cancelMasteryTest': 'Encerrando o teste de aprendizagem', 
'certificationTest.awaitingRevision': 'Seu teste de certificação será avaliado em breve. Você receberá uma notificação em alguns dias com o resultado.', 
'certificationTest.button.access': 'Entrar', 
'certificationTest.button.accessNewCourse': 'Entrar no novo Curso', 
'certificationTest.button.back': 'Voltar para o Curso', 
'certificationTest.button.continue': 'CONTINUAR TESTE DE CERTIFICAÇÃO', 
'certificationTest.button.reviewed': 'Clique aqui para visualizar o Resultado', 
'certificationTest.button.start': 'INICIAR TESTE DE CERTIFICAÇÃO', 
'certificationTest.continue': 'Continue seu  Teste de Certificação. Utilize um computador para executar o teste.', 
'certificationTest.created': 'Você está apto para fazer o Teste de Certificação. Procure seu professor para fazer o agendamento e enquanto isso, continue revisando o conteúdo do seu curso para se preparar para o teste.', 
'certificationTest.enabled': 'Seu Teste de Certificação está disponível para execução. Utilize um computador para executar o teste.', 
'certificationTest.execution.ability.listening': 'Teste de Escuta', 
'certificationTest.execution.ability.reading': 'Teste de Leitura', 
'certificationTest.execution.ability.speaking': 'Teste de Fala', 
'certificationTest.execution.ability.writing': 'Teste de Escrita', 
'certificationTest.execution.completedDescription': 'Após o revisão do seu teste pelo avaliador, você receberá uma notificação com a sua nota! Boa Sorte!', 
'certificationTest.execution.completedTitle': 'Teste de Certificação finalizado e enviado para revisão.', 
'certificationTest.execution.status.available': 'Iniciar', 
'certificationTest.execution.status.blocked': 'Bloqueado', 
'certificationTest.execution.status.finished': 'Concluído', 
'certificationTest.execution.title': 'Obtenha uma nota mínima de 70 para habilitar o próximo passo que está bloqueado.', 
'certificationTest.failed': 'Você não atingiu a nota necessária em seu último Certification test. Seja aprovado em {units} units diferentes para habilitar uma nova tentativa', 
'certificationTest.help.free_speak': 'Neste exercício, diferente das units, você não deve repetir a pergunta apresentada em tela, mas, sim, respondê-la. Você terá até 1 minuto para pensar sobre a sua resposta antes que a função Gravar se inicie automaticamente. Caso deseje, você também pode iniciar antes.', 
'certificationTest.help.free_speak_image': 'Neste exercício você deverá descrever a imagem apresentada em tela. Você terá até 1 minuto para pensar sobre a sua resposta antes que a função Gravar se inicie automaticamente. Caso deseje, você também pode iniciar antes.', 
'certificationTest.help.gotIt': 'Ok, entendi', 
'certificationTest.loading': 'Calculando seu resultado', 
'certificationTest.result.approvedDescription': 'Você conseguiu a nota necessária para passar no teste de certificação.', 
'certificationTest.result.approvedDescriptionTwo': 'Você provou para nós que você aumentou seu nivel de Inglês na escala do Quadro Comum Europeu de Referência para Línguas.', 
'certificationTest.result.approvedTitle': 'Ótimo!', 
'certificationTest.result.failedDescription': 'Você não atingiu a nota necessária para passar no teste de certificação.', 
'certificationTest.result.failedDescriptionTwo': 'Estude mais {units} units no curso para tentar o teste de certificação novamente.', 
'certificationTest.result.failedTitle': 'Ah não...', 
'certificationTest.result.finalScore': 'Nota final', 
'certificationTest.result.scoreBySkill': 'Nota por habilidade', 
'certificationTest.result.unlockedCourse': 'Curso desbloqueado', 
'certificationTest.reviewed': 'Seu Certification Test foi revisado.', 
'certificationTest.scheduled': 'Seu Teste de Certificação está agendado para <b>{scheduledTo}</b>', 
'certificationTest.title': 'Teste de Certificação', 
'checkText': 'Verificar o texto', 
'checkTextAgain': 'Clique aqui para ler o texto novamente!', 
'close': 'Fechar', 
'closeExecution.defaultMessage': 'Se você sair agora, o progresso do seu estudo será perdido após {timeToAllowContinueUnit}. Tem certeza que deseja sair ?', 
'closeExecution.masteryMessage': 'Se você sair agora, seu Mastery Test será finalizado como Reprovado e você precisará fazer novas units para habilitar uma nova tentativa. Tem certeza que deseja sair?', 
'closeExecution.no': 'Não', 
'closeExecution.noProgressLossMessage': 'Tem certeza que deseja sair?', 
'closeExecution.title': 'Atenção', 
'closeExecution.yes': 'Sim', 
'closedRanking.champions': 'Ranking <b style="color: #fffc00">{month}</b>', 
'closedRanking.congratulations': 'Parabéns!', 
'closedRanking.congratulationsMessage.national': 'Você ficou em <b style="color: #fffc00">{position} lugar</b> no seu país.', 
'closedRanking.congratulationsMessage.regional': 'Você ficou em <b style="color: #fffc00">{position} lugar</b> na sua região.', 
'closedRanking.congratulationsMessage.school': 'Você ficou em <b style="color: #fffc00">{position} lugar</b> na sua instituição.', 
'closedRanking.monthly': 'Mensal', 
'closedRanking.national': 'Nacional', 
'closedRanking.points': '{points} Pontos', 
'closedRanking.regional': 'Regional', 
'closedRanking.school': 'Institucional', 
'closedRanking.semiannual': 'Semestral', 
'closedRanking.yearly': 'Anual', 
'congratulations': 'Parabéns', 
'contentVideo.execution.attachments': 'Documentos anexos', 
'contentVideo.execution.incompleteLesson': 'Aula incompleta', 
'contentVideo.execution.lesson': 'Lesson {value}', 
'contentVideo.execution.lessonCompleted': 'Aula concluída', 
'contentVideo.execution.localesSelect': 'Selecione um idioma para legendas', 
'contentVideo.execution.noAttachments': 'Não há documentos anexos nesta atividade', 
'contentVideo.execution.noRecommendedUnits': 'Não há exercícios recomendados', 
'contentVideo.execution.openAttachmentError': 'It was not possible to open the file', 
'contentVideo.execution.openRecommendedUnitModal.confirm': 'Sim', 
'contentVideo.execution.openRecommendedUnitModal.title': 'Você tem certeza que deseja iniciar este exercício?', 
'contentVideo.execution.recommendedUnits': 'Exercícios recomendados', 
'contentVideo.list.emptyState.description': 'Seu (sua) Teacher não cadastrou vídeos.', 
'contentVideo.list.emptyState.title': 'Ops... Ainda não existem vídeos aqui', 
'contentVideo.list.module': 'Módulo {value}', 
'contentVideo.list.title': 'Aulas Gravadas', 
'continue': 'Continuar', 
'courseSummary.button.access': 'Acessar o novo curso', 
'courseSummary.button.back': 'Voltar a home', 
'courseSummary.certificationTestPart1': 'Seu próximo passo é o <b>Teste de Certificação</b>, fale com seu professor para agendá-lo.', 
'courseSummary.certificationTestPart2': 'Utilize os recaps para revisar o conteúdo antes do teste de certificação. Boa sorte!', 
'courseSummary.description': 'Você completou o curso.', 
'courseSummary.hoursInCourse': 'Total de horas no curso', 
'courseSummary.pointsInCourse': 'Total de pontos no curso', 
'courseSummary.title': 'Parabéns!', 
'courseSummary.unlockedCourse': 'Curso desbloqueado', 
'courseSummary.yourProgress': 'Seu Progresso', 
'createAccount.button.saveAndContinue': 'Salvar e Continuar', 
'createAccount.button.validateCpf': 'Avançar', 
'createAccount.button.validateRa': 'Avançar', 
'createAccount.completeMessage': 'Complete seu cadastro', 
'createAccount.error.activeAccount': 'Você já fez seu cadastro. Acesse a página de login e utilize seu email e senha para acessar a plataforma.', 
'createAccount.error.email': 'O e-mail informado já está em uso. Por favor utilize outro e-mail.', 
'createAccount.error.general': 'Não foi possível atualizar seus dados. Verifique os campos e tente novamente.', 
'createAccount.error.invalidCpf': 'Não encontramos um usuário com seu CPF. Verifique seu CPF ou entre em contato com sua instituição.', 
'createAccount.error.invalidRa': 'Não encontramos um usuário com seu RA. Verifique seu RA ou entre em contato com sua instituição.', 
'createAccount.error.limit_reached': 'Todas as vagas disponíveis já foram preenchidas.', 
'createAccount.field.confirmPassword': 'Confirme sua senha', 
'createAccount.field.cpf': 'Informe seu CPF', 
'createAccount.field.email': 'Seu e-mail', 
'createAccount.field.name': 'Seu nome', 
'createAccount.field.password': 'Crie uma senha', 
'createAccount.field.ra': 'Informe seu RA', 
'createAccount.infoMessage': 'Informe os dados abaixo', 
'createAccount.infoStep.cancel': 'Cancelar', 
'createAccount.infoStep.confirmPassword': 'Confirmar Senha', 
'createAccount.infoStep.description': 'Informe os dados abaixo para criar sua conta', 
'createAccount.infoStep.email': 'E-mail', 
'createAccount.infoStep.name': 'Nome', 
'createAccount.infoStep.password': 'Senha', 
'createAccount.infoStep.saveAndContinue': 'Salvar e Continuar', 
'createAccount.infoStep.title': 'Bem vindo a {name}', 
'createAccount.planStep.confirmAndPay': 'Confirmar e Pagar', 
'createAccount.planStep.description': 'Selecione o plano e clique em confirmar', 
'createAccount.planStep.monthly.byPeriod': '/ mês', 
'createAccount.planStep.privacyPolicy': 'Política de Privacidade', 
'createAccount.planStep.quarterly.byPeriod': '/ trimestre', 
'createAccount.planStep.quarterly.monthCompare': '3 meses por R$ {price} ao mês.', 
'createAccount.planStep.quarterly.saveDescription': 'Desconto de {discount}%', 
'createAccount.planStep.semiannually.byPeriod': '/ semestre', 
'createAccount.planStep.semiannually.monthCompare': '6 meses por R$ {price} ao mês.', 
'createAccount.planStep.semiannually.saveDescription': 'Desconto de {discount}%', 
'createAccount.planStep.title': 'Qual plano você deseja?', 
'createAccount.planStep.useTerms': 'Termos de uso', 
'createAccount.success.general': 'Cadastro criado com sucesso. Você será redirecionado para o seu curso.', 
'createAccount.success.payment': 'Cadastro criado com sucesso. Você será redirecionado para o pagamento.', 
'createAccount.successStep.accessPlacement': 'Continuar', 
'createAccount.successStep.placementDescription': 'O proximo passo é descobrir seu nivel de inglês. Use o botão abaixo para acessar nosso Teste de Nivelamento', 
'createAccount.successStep.title': 'Você já pode iniciar seu curso', 
'createAccount.welcomeMessage': 'Seja bem-vindo ao seu curso de inglês.', 
'day': 'Dia', 
'days': 'Dias', 
'dismiss': 'Fechar', 
'error.account_duplicated_email': 'O e-mail informado já está em uso. Por favor utilize outro e-mail.', 
'error.account_general': 'Não foi possível atualizar seus dados. Verifique os campos e tente novamente.', 
'error.account_invalid_password': 'Verifique sua senha atual. Para alterar a senha você deve usar a senha atual e a nova senha desejada.', 
'error.account_picture_general': 'Não foi possível atualizar sua foto. Tente novamente.', 
'error.androidVersion': 'A versão do android deste dispositivo é incompatível. Versão mínima: 5.0 Lollipop', 
'error.audioDownload': 'Ocorreu um problema para tocar o áudio. Tente novamente usando o botão Repetir.', 
'error.checkAudioPermission.chrome.button': 'Permitir e continuar', 
'error.checkAudioPermission.chrome.description': 'Clique no botão abaixo para permitir e continuar.', 
'error.checkAudioPermission.description': 'Desculpe, nós detectamos que você está usando o <b style="textTransform: capitalize">{browserName}</b> web browser e que nossa plataforma não possui permissão para reproduzir automaticamente os áudios.', 
'error.checkAudioPermission.safari.description': 'Você pode alterar as configurações de reprodução automática nas <b>configurações do Safari</b> (Preferências > Sites > Reprodução automática) ou sigua os <b>passos abaixo</b> para habilitar a reprodução dos áudios:', 
'error.checkAudioPermission.safari.step1': 'Clique com o botão direito na barra de endereços e selecione a opção: <b>Ajuste para este Site</b>', 
'error.checkAudioPermission.safari.step2': 'Na opcão Reprodução Automática, selecione: <b>Permitir todas as reproduçoes automáticas</b>', 
'error.checkAudioPermission.safari.step3': 'Recarregue a pagina e você estara pronto para começar', 
'error.checkAudioPermission.title': 'Quase lá', 
'error.checkBrowserType.description1': 'Para uma boa experiência em nossa plataforma considere a utilização da última versão do Google Chrome web browser.', 
'error.checkBrowserType.description2': 'Você também pode utilizar o Mozila Firefox ou Safari.', 
'error.checkBrowserType.title': 'Desculpe, o browser que você esta utilizando não é suportado.', 
'error.checkBrowserVersion.description': 'Para uma boa experiência em nossa plataforma considere a utilização da última versão. Faça o download da ultima versão neste {link}.', 
'error.checkBrowserVersion.title': 'Seu browser esta desatualizado.', 
'error.close': 'Fechar', 
'error.create_account_duplicated_email': 'O e-mail informado já esta em uso. Entre em contato com nosso suporte para mais informações', 
'error.create_account_general': 'Não foi possível criar sua conta. Verifique as informações e tente novamente', 
'error.differentPasswords': 'As senhas não são iguais', 
'error.duplicatedEmail': 'Já existe um usuário com o e-mail informado.', 
'error.emailNotFound': 'Não encotramos nenhum usuário para o e-mail informado.', 
'error.emailPasswordInvalid': 'Verifique seu email e/ou senha.', 
'error.error_browser_block_autoplay': 'Clique no botão abaixo para continuar a atividade.', 
'error.error_browser_block_intro_autoplay': 'Vamos começar? Clique no botão abaixo e siga as instruções.', 
'error.error_find_speech_recognition_text': 'Ocorreu um problema para definir a resposta. Tente utilizar o gravar novamente', 
'error.error_get_speech_recognition_score': 'Ocorreu um problema para definir sua nota. Tente gravar novamente', 
'error.error_inspect_html': 'Não é permitido inspecionar o site. O progresso dessa unit será perdido.', 
'error.error_no_speech': 'Não conseguimos detectar sua fala. Tente gravar novamente', 
'error.error_play_audio': 'Ocorreu um problema para tocar o áudio. Tente utilizar o Repetir novamente.', 
'error.error_play_record': 'Ocorreu um problema para tocar sua gravação. Tente utilizar o escutar novamente', 
'error.error_record_missing_mic': 'Não foi possível acessar seu microfone. Verifique as configurações do seu navegador.', 
'error.error_record_not_allowed': 'A plataforma não teve permissão para acessar seu microfone. Verifique as permissões do seu navegador.', 
'error.error_reply_ct_speak': 'Parece que você repetiu a frase ao invés de respondê-la. Grave sua resposta novamente.', 
'error.error_save_answer': 'Ocorreu um problema para salvar sua resposta. Tente confirmar a resposta novamente', 
'error.error_start_record': 'Ocorreu um problema para acessar seu microfone. Tente utilizar o gravar novamente', 
'error.error_stop_record': 'Ocorreu um problema para finalizar sua gravação. Tente utilizar o gravar novamente', 
'error.error_word_alignment': 'A frase está incompleta. Tente gravar novamente.', 
'error.generalConnectionError': 'Foi detectado uma instabilidade de conexão. Por favor, tente novamente este exercício.', 
'error.generateUnitScore': 'Ocorreu um problema para gerar sua nota.', 
'error.google_access_denied': '', 
'error.google_email_not_found': '', 
'error.google_invalid_user_role': '', 
'error.invalidEmail': 'E-mail invalído', 
'error.kidsUser': 'Este aplicativo não é compatível o seu curso. Acesse a nossa plataforma web para continuar.', 
'error.noSpeechDetected': 'Não foi possível detectar sua fala. Verifique seu microfone, ele pode estar mudo ou configurado com uma sensibilidade muito baixa. Tente escutar sua gravação para verificar o áudio.', 
'error.payment_canceled': 'Compra cancelada', 
'error.payment_deferred': 'Compra não realizada por falta de permissão', 
'error.payment_error': 'Não foi possível finalizar sua compra, verifique seus dados e tente novamente', 
'error.placement_end': 'Não foi possível gerar seu resultado. Tente novamente.', 
'error.placement_save_answer': 'Não foi possível salvar sua resposta. Tente novamente.', 
'error.placement_start': 'Não foi possível iniciar o Teste de Nivelamento. Tente novamente.', 
'error.practice_save_answer': 'Não foi possível salvar sua resposta. Tente novamente.', 
'error.practice_start': 'Não foi possível iniciar o aquecimento.', 
'error.refresh': 'Recarregar', 
'error.required': 'Obrigatório', 
'error.three_minute_block': 'Você acabou de completar o exercício. É preciso esperar 3 minutos para refazê-lo. Por favor faça outro exercício enquanto isso.', 
'error.tryAgain': 'Tentar Novamente', 
'error.updateAvatarError': 'Ocorreu um problema para atualizar seu avatar.', 
'error.updateProfileError': 'Ocorreu um problema para atualizar seu perfil.', 
'grades.averageSq': 'Média de SQ: <b>{grade}!</b>', 
'grades.awaitingSq': 'Aguardando nota de SQ', 
'grades.classRoom': 'Turma', 
'grades.closed': 'Fechada', 
'grades.excellent': 'Excelente', 
'grades.finalGrade': 'Nota Final', 
'grades.good': 'Bom', 
'grades.maximumGrade': 'Nota Máxima', 
'grades.maximumSchoolGrade': 'Nota máxima da escola: {grade}', 
'grades.moderate': 'Moderado', 
'grades.noData': 'Suas notas ainda não estão disponíveis.', 
'grades.preview': 'Prévia', 
'grades.requiredStudyTime': 'Tempo de estudo exigido: <b>{time}</b>', 
'grades.school': 'Escola', 
'grades.student': 'Aluno', 
'grades.teacher': 'Professor', 
'grades.teachers': 'Professores:', 
'grades.time': 'Tempo ({percentageRelevance}%)', 
'grades.title': 'Notas - {year}', 
'grades.titleHeader': 'Suas Notas', 
'grades.totalStudyTime': 'Tempo total de estudo: <b>{time}</b>', 
'grades.veryGood': 'Muito Bom', 
'grades.veryWeak': 'Muito Fraco', 
'grades.weak': 'Fraco', 
'grades.weekToSqScore': '{count} semanas para a nota de SQ', 
'grades.yourSchoolGrade': 'Sua nota na escola: {grade}', 
'greeting.f': 'Olá {name}, Bem-vinda :)', 
'greeting.m': 'Olá {name}, Bem-vindo :)', 
'help.AUDIO_LONG': 'Escute atentamente ao áudio para responder os próximos exercícios.', 
'help.DIALOGUE': 'Acompanhe as mensagens e selecione a melhor opção para dar continuidade ao diálogo.', 
'help.DIALOGUE.typing': 'Digitando...', 
'help.DIALOGUE_OPTION': 'Acompanhe as mensagens e selecione a melhor opção para dar continuidade ao diálogo.', 
'help.DIALOGUE_OPTION.question': 'Qual é a opção correta para continuar o diálogo? Grave sua resposta.', 
'help.DIALOGUE_OPTION_SPEECHLESS.question': 'Qual é a opção correta para continuar o diálogo?', 
'help.DICTATION': 'Escute atentamente o áudio e <b>escreva</b> a frase no campo abaixo.', 
'help.GAP_FILL': 'Grave a frase <b>completa</b> com a opção correta.', 
'help.GAP_FILL_MULTIPLE': 'Complete a frase <b>clicando</b> nas palavras corretas.', 
'help.GAP_FILL_MULTIPLE_DRAG_AND_DROP': 'Complete a frase <b>arrastando</b> as palavras corretas até os espaços disponíveis.', 
'help.GAP_FILL_SELECT': 'Complete a frase <b>selecionando</b> uma das opções disponíveis.', 
'help.GAP_FILL_SPEECHLESS': 'Complete a frase <b>selecionando</b> uma das opções disponíveis.', 
'help.GRAMMAR_CHECK': 'Leia atentamente a frase apresentada para identificar o erro.', 
'help.GRAMMAR_CHECK.correction_phrase': 'Realize a correção da frase alterando as palavras ou expressões que estão incorretas.', 
'help.IMAGE': 'Analise a imagem com atenção para responder as próximas questões.', 
'help.MEANINGS_ASSOCIATING': 'Associe cada vocabulário clicando na palavra e no campo correspondente de sua definição.', 
'help.MULTIPLE_CHOICE_TEXT': 'Escute a pergunta a marque as opções corretas.', 
'help.PRESENTATION': 'Utilize os botões Repetir, Gravar e Escutar para gravar sua voz repetindo o áudio que você escutou.', 
'help.PRESENTATION_SPEECHLESS': 'Desembaralhe a frase <b>arrastando</b> as palavras corretas até os espaços disponíveis.', 
'help.PRONUNCIATION': 'Utilize o Gravar para <b>falar o texto</b> abaixo.', 
'help.PRONUNCIATION_SPEECHLESS': 'Complete a frase <b>selecionando</b> uma das opções disponíveis.', 
'help.SINGLE_CHOICE_AUDIO': 'Escute a pergunta e <b>clique</b> na opção correta.', 
'help.SINGLE_CHOICE_IMAGE': 'Escute a pergunta e <b>clique</b> na opção correta.', 
'help.SINGLE_CHOICE_TEXT': 'Escute a pergunta e <b>clique</b> na opção correta.', 
'help.SPEECH_PRACTICE': '<b>Escute</b> atentamente e utilize o Gravar para <b>repetir</b> o áudio.', 
'help.SPEECH_PRACTICE_SPEECHLESS': 'Escute atentamente o áudio e <b>escreva</b> a frase no campo abaixo.', 
'help.TEXT': '<b>Leia o texto atentamente</b> e então utilize o botão Avançar para continuar.', 
'help.TRUE_FALSE': 'Escute a afirmação e <b>clique</b> em verdadeiro ou falso.', 
'help.UNSCRAMBLE_DRAG_AND_DROP': 'Desembaralhe a frase <b>arrastando</b> as palavras corretas até os espaços disponíveis.', 
'help.UNSCRAMBLE_DRAG_AND_DROP_CLICK': 'Desembaralhe a frase <b>clicando</b> nas palavras corretas até os espaços disponíveis.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION': 'Desembaralhe a frase utilizando o botão Gravar para <b>gravar a frase</b> na sequência correta.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS': 'Desembaralhe a frase <b>arrastando</b> as palavras corretas até os espaços disponíveis.', 
'help.UNSCRAMBLE_TEXT': 'Ordene os parágrafos para estruturar o texto em sua sequência correta.', 
'help.VIDEO_LONG': 'Assista ao vídeo para responder os próximos exercícios. Se necessário, poderá repetir o vídeo uma vez.', 
'help.VOCABULARY_ACADEMIC': '<b>Clique</b> na imagem e depois grave a frase que escutar.', 
'help.VOCABULARY_ACADEMIC_SPEECHLESS': 'Desembaralhe a frase <b>arrastando</b> as palavras corretas até os espaços disponíveis.', 
'history.averageSR': 'Média de SR', 
'history.byContent': 'Histórico por conteúdo', 
'history.byDate': 'Histórico por data', 
'history.corrects': 'Corretas', 
'history.course': 'Curso', 
'history.date': 'Data', 
'history.module': 'Módulo', 
'history.noContentData': 'Você não possui histórico por conteúdo.', 
'history.noData': 'Histórico indisponível.', 
'history.noDateData': 'Você não possui histórico por data.', 
'history.notFinished': 'Não finalizada', 
'history.points': 'Pontos', 
'history.rankingPosition': 'Posições no Ranking', 
'history.score': 'Nota', 
'history.studiedTime': 'Tempo de Estudo', 
'history.title': 'Histórico', 
'history.type': 'Tipo', 
'history.unit': 'Unit', 
'home.accessCourse': 'Acesse o seu curso', 
'home.currentRulerLevel': 'Seu nível atual!', 
'home.firstCourse': 'Você acabou de começar. Continue estudando para desbloquear novos cursos.', 
'home.initialRulerLevel': 'Você começou aqui!', 
'home.reviewCourses': 'Revise seus cursos anteriores', 
'home.welcome.F': 'Bem-vinda, {name} :)', 
'home.welcome.M': 'Bem-vindo, {name} :)', 
'hours': 'Horas', 
'imageCrop.cancel': 'Cancelar', 
'imageCrop.changeLabel': 'Enviar foto', 
'imageCrop.confirm': 'Confirmar', 
'instructions': 'Instruções', 
'intro.done': 'Concluir', 
'intro.gotIt': 'Entendi', 
'intro.next': 'Avançar', 
'intro.previous': 'Voltar', 
'intro.skip': 'Pular', 
'intro.steps.controlBar1': 'Botão para repetir o áudio. Lembre-se que só poderá ser usado uma vez!', 
'intro.steps.controlBar2': 'Tempo máximo para cada questão. Lembrando que ele inicia a contagem após alguns segundos depois do carregamento do exercício.', 
'intro.steps.controlBar3': 'Ao completar a frase utilize o botão Avançar para seguir para o próximo exercício. Não se esqueça que o tempo do exercício continuará sendo contado enquanto não clicar no botão para avançar.', 
'intro.steps.controlBarRepeat': 'Botão para repetir o áudio. Lembre-se que só poderá ser usado uma vez!', 
'intro.steps.controlBarTimer': 'Tempo máximo para cada questão. A contagem é iniciada após o carregamento do exercício.', 
'intro.steps.gapFill1': 'O texto está incompleto. É necessário completá-lo com uma das opções.', 
'intro.steps.gapFill2': 'Clique e selecione uma das opções.', 
'intro.steps.gapFillMultiple1': 'O texto está incompleto. É necessário completá-lo com as opções corretas.', 
'intro.steps.gapFillMultiple2': 'Estas são as opções. Clique nos itens para montar o texto!', 
'intro.steps.gapFillMultiple2_drag': 'Estas são as opções. Arraste-os para montar o texto!', 
'intro.steps.gapFillSelect': 'Clique para ver as opções disponíveis e selecione uma para completar a frase.', 
'intro.steps.singleChoiceAudio': 'Ouça o áudio e selecione uma das opções correspondentes.', 
'intro.steps.singleChoiceImage': 'Ouça o áudio e selecione a imagem correspondente.', 
'intro.steps.singleChoiceText': 'Ouça o áudio e selecione o item correspondente.', 
'intro.steps.unscrambleDragDrop1': 'Aqui será necessário desembaralhar a frase a partir das opções disponíveis.', 
'intro.steps.unscrambleDragDrop2': 'Estas são as opções. Clique nos itens para montar o texto!', 
'intro.steps.unscrambleDragDrop2_drag': 'Estas são as opções.  Arraste-os para montar o texto!', 
'introductionButton': 'Rever instruçoes', 
'items.correctAnswer': 'Resposta correta:', 
'items.dictation.errorTitle': 'Verifique a lista abaixo para entender os erros na sua frase:', 
'items.dictation.missingWordsError': 'Na sua frase faltam {count} palavra(s)', 
'items.dictation.moreWordsError': 'Sua frase possui {count} palavra(s) a mais.', 
'items.dictation.wordsWithTyposError': 'Palavra(s) com erro(s) na escrita: {words}.', 
'items.dictation.wrongWordsError': 'Palavra(s) errada(s): {words}', 
'items.placeholder.dictation': 'Digite a aqui...', 
'items.placeholder.freeSpeak': 'Em {time} a gravação iniciará automaticamente. Comece antes se quiser.', 
'items.placeholder.gapFillSelect': 'Selecione uma opção', 
'items.speechRecognition.errorLowScore': 'Nós não conseguimos gerar uma nota para sua fala, por favor tente novamente.', 
'items.speechRecognition.errorNoSpeech': 'Nós não conseguimos detectar sua fala, por favor tente novamente.', 
'items.speechRecognition.historyTitle': 'Suas tentativas', 
'items.speechRecognition.tryAgain': 'Tente novamente!', 
'listen': 'Escutar', 
'listening': 'Escuta', 
'loadingExercises': 'Carregando o exercício', 
'loadingMasteryTest': 'Carregando o teste de aprendizagem', 
'locked': 'Bloqueado', 
'login.backToLogin': 'Voltar ao login', 
'login.classroom_not_found': 'Seu usuário não está associado a uma sala.', 
'login.createAccount': 'Criar conta', 
'login.email': 'E-mail', 
'login.email_not_found': 'E-mail não encontrado.', 
'login.enter': 'Entrar', 
'login.forgotPassword': 'Esqueci minha senha', 
'login.forgotPasswordDescription': 'Informe seu e-mail no campo abaixo e nós iremos enviar um e-mail com a sua nova senha.', 
'login.forgotPasswordPostDescription': 'Você pode alterar a nova senha na sua pagina de atualizar perfil.', 
'login.goToLogin': 'Acessar página de login', 
'login.googleSignIn': '', 
'login.inactive_classroom': 'Sala em período inativo.', 
'login.invalid_password': 'Senha inválida.', 
'login.limit_reached': 'Todas as contas disponíveis já foram utilizadas.', 
'login.out_of_access_period': 'Período de acesso invalido', 
'login.out_of_demo_period': 'Seu período de demonstração expirou.', 
'login.password': 'Senha', 
'login.payment_required': 'Existe um pagamento em aberto. Verifique seu e-mail ou entre em contato conosco.', 
'login.resetPassword': 'Enviar nova senha', 
'login.resetPasswordSuccess': 'Sua nova senha foi enviada por e-mail.', 
'login.student_contract_blocked': 'Ops, parece que seu acesso foi bloqueado. Entre em contato com seu professor para mais informações.', 
'login.unexpected_error': 'Não foi possível realizar o login. Tente novamente em alguns instantes.', 
'login.user_disabled': 'Seu usuário esta desabilitado, verifique com sua instituição.', 
'login.user_not_found_in_company': 'E-mail não encontrado.', 
'login.user_not_found_in_distributor': 'E-mail não encontrado.', 
'login.user_with_placement_in_classroom': 'Você já concluiu seu Placement Test.', 
'masteryTest.approved': 'Aprovado', 
'masteryTest.approvedMessageDescription': 'Você foi aprovado no Teste de Aprendizagem', 
'masteryTest.approvedMessageTitle': 'Parabéns!', 
'masteryTest.button.back': 'Voltar ao módulo', 
'masteryTest.button.courseSummary': 'Verificar resumo do curso', 
'masteryTest.description': 'O teste de aprendizagem serve para validar o conhecimento adquirido durante seu estudo e possui atividades semelhantes aos exercícios já realizados. Nos casos de falha, basta estudar 5 unidades e você poderá realizar o teste novamente.', 
'masteryTest.exercisesToUnlock.moreThenOneExercise': 'Faltam {total} exercícios para liberar o teste', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndOneRevision': 'Faltam {total} exercícios e 1 revisão obrigatória para liberar o teste', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndRevision': 'Faltam {totalExercises} exercícios e {totalRevisions} revisões obrigatórias para liberar o teste', 
'masteryTest.exercisesToUnlock.moreThenOneRevision': 'Faltam {total} revisões obrigatórias para liberar o teste', 
'masteryTest.exercisesToUnlock.moreThenOneRevisionAndOneExercise': 'Faltam 1 exercício e {total} revisões obrigatórias para liberar o teste', 
'masteryTest.exercisesToUnlock.oneExercise': 'Falta 1 exercício para liberar o teste', 
'masteryTest.exercisesToUnlock.oneExerciseAndMoreThenOneRevision': 'Faltam 1 exercício e {total} revisões obrigatórias para liberar o teste', 
'masteryTest.exercisesToUnlock.oneExerciseAndRevision': 'Falta 1 exercício e 1 revisão obrigatória para liberar o teste', 
'masteryTest.exercisesToUnlock.oneRevision': 'Falta 1 revisão para liberar o teste', 
'masteryTest.failed': 'Refazer', 
'masteryTest.failedMessageDescription': 'Não foi dessa vez. <b>Tente novamente!</b>', 
'masteryTest.failedMessageTitle': 'É...', 
'masteryTest.loading': 'Calculando seu resultado', 
'masteryTest.locked': 'Bloqueado', 
'masteryTest.name': 'Teste de Aprendizagem', 
'masteryTest.recommendedUnits': 'Faça pelo menos 5 exercícios novamente. Aqui estão algumas sugestões do que precisamos melhorar:', 
'masteryTest.result.aboutYourPerformance': 'Sobre seu desempenho', 
'masteryTest.result.congratulationsOnYourEfforts': 'Parabéns pelos seus esforços.', 
'masteryTest.result.dontBeDiscouraged': 'Mas não desanime.', 
'masteryTest.result.niceJob': 'Mandou bem', 
'masteryTest.result.whatToImprove': 'O que melhorar', 
'masteryTest.result.yorResultOnMasteryTest': 'Seu resultado no Teste de Aprendizagem', 
'masteryTest.result.youDidntPass': 'Você não passou!', 
'masteryTest.result.youPass': 'Você passou!', 
'masteryTest.resultTitle': 'Seu resultado', 
'masteryTest.score': 'Nota: {score}', 
'masteryTest.scoreToPass': 'Nota necessária: {score}', 
'masteryTest.start': 'Start', 
'masteryTest.timer.runningOut': 'Acabando o tempo...', 
'masteryTest.timer.title': 'Tempo para responder', 
'masteryTest.tooltip.approved': 'Você finalizou o teste.', 
'masteryTest.tooltip.failed': 'Você precisa finalizar <b>{unitsNeeded} exercicíos diferentes</b> para refazer o teste.', 
'masteryTest.tooltip.locked': 'Para desbloquear, conquiste {modulePercentageToActive}% dos pontos deste módulo. Faltam <b>{requiredPoints} pontos</b>.', 
'masteryTest.tooltip.unlocked': 'Teste de Aprendizagem disponível. Clique para iniciar.', 
'masteryTest.unlocked': 'Liberado', 
'masteryTest.whatToImprove': 'O que melhorar?', 
'masteryTestStartExecutionModal.exit': 'Sair', 
'masteryTestStartExecutionModal.start': 'Iniciar', 
'masteryTestStartExecutionModal.title': 'O teste possui cerca de 20 perguntas e após o início, não será possível pausá-lo. Caso você desista durante a execução, o teste será considerado falho e você precisará estudar 5 unidades para liberar uma nova tentativa. Tem certeza que deseja iniciar o Teste de Aprendizagem?', 
'meeting.button.join': 'Entrar na reunião', 
'meeting.title': 'Sua próxima aula', 
'menu.contentVideo': 'Aulas', 
'menu.course': 'Curso', 
'menu.errorReport': 'Reportar Erro', 
'menu.exercises': 'Exercícios', 
'menu.exercisesHelp': 'Entenda os exercícios', 
'menu.grades': 'Boletim', 
'menu.history': 'Histórico', 
'menu.logout': 'Sair', 
'menu.messages': 'Mensagens', 
'menu.microphoneTest': 'Teste do microfone', 
'menu.modules': 'Módulos', 
'menu.modulesHelp': 'Entenda os módulos', 
'menu.notifications': 'Notificações', 
'menu.privacyPolicy': 'Política de Privacidade', 
'menu.profile': 'Perfil', 
'menu.ranking': 'Ranking', 
'menu.units': 'Exercícios', 
'messages.messageToSupport': 'Para o Suporte', 
'messages.messageToTeacher': 'Para o meu Professor', 
'messages.messageTypeSelection': 'Para quem você deseja enviar a mensagem?', 
'messages.newMessage': 'Nova mensagem', 
'messages.newMessageTo': 'Nova mensagem para', 
'messages.noData': 'Você não possui mensagens', 
'messages.notRead': 'Não visualizado', 
'messages.placeholder': 'Mensagem', 
'messages.readAt': 'Visualizado em {readAt}', 
'messages.selectTeacher': 'Selecione um professor para receber a mensagem', 
'messages.send': 'Enviar', 
'messages.subjectPlaceholder': 'Assunto', 
'messages.successSendNewMessage': 'Mensagem enviada com sucesso!', 
'messages.successSent': 'Mensagem enviada com sucesso!', 
'messages.supportSubjectLabelOptions.answerExercise': 'Como responder o exercício', 
'messages.supportSubjectLabelOptions.certificationTest': 'Dúvida sobre o Teste de Certificação', 
'messages.supportSubjectLabelOptions.exercise': 'Dúvida sobre o exercício', 
'messages.supportSubjectLabelOptions.exerciseAudio': 'Áudio do exercício', 
'messages.supportSubjectLabelOptions.exerciseBlockedFunctionality': 'Função bloqueada no exercício', 
'messages.supportSubjectLabelOptions.exerciseImage': 'Imagem do exercício', 
'messages.supportSubjectLabelOptions.exerciseTranslation': 'Tradução do exercício', 
'messages.supportSubjectLabelOptions.learningTest': 'Dúvida sobre o Teste de Aprendizagem', 
'messages.supportSubjectLabelOptions.ranking': 'Dúvida sobre o ranking', 
'messages.supportSubjectLabelOptions.speechRecognition': 'Reconhecimento de fala', 
'messages.supportSubjectLabelOptions.studyQuality': 'Dúvida sobre a Qualidade de Estudo', 
'messages.title': 'Mensagens', 
'messages.titleNewMessage': 'Enviar nova mensagem', 
'microphoneTest.badScoreDescription': 'Siga o tutorial no próximo passo e verifique a <b>configuração do seu microfone</b>.', 
'microphoneTest.badScoreTitle': 'Ops! Suas notas não foram muito boas.', 
'microphoneTest.button.checkTutorial': 'Verificar tutorial', 
'microphoneTest.button.close': 'Fechar', 
'microphoneTest.button.skipTest': 'Configurar mais tarde', 
'microphoneTest.button.start': 'Iniciar Calibração', 
'microphoneTest.button.tryAgain': 'Tentar Novamente', 
'microphoneTest.description': 'Tente falar as frases a seguir, ajustando seu microfone até tirar notas acima de 80!', 
'microphoneTest.goodScoreDescription': 'Utilizando o menu de ajuda no topo desta página você pode voltar aqui quando quiser.', 
'microphoneTest.goodScoreTitle': 'Muto Bom! Suas notas foram boas.', 
'microphoneTest.phrasesTitle': 'Grave as frases abaixo:', 
'microphoneTest.title': 'Vamos calibrar seu microfone?', 
'microphoneTest.tutorialDescriptionOne': 'Seu microfone pode estar com uma sensibilidade muito alta ou baixa, em ambos os casos isso pode atrapalhar suas notas no reconhecimento de voz.', 
'microphoneTest.tutorialDescriptionTwo.part1': 'Como arrumar?', 
'microphoneTest.tutorialDescriptionTwo.part2': 'Você pode consultar o', 
'microphoneTest.tutorialDescriptionTwo.part3': 'passo a passo através deste', 
'microphoneTest.tutorialDescriptionTwo.part4': ', ou <b>veja o video</b> acima para ver como melhorar a configuração do seu microfone.', 
'microphoneTest.tutorialTitle': 'Alterando a sensibilidade do microfone', 
'missingPermission.button.openSettings': 'Verificar configurações', 
'missingPermission.camera.description': 'Verifique as configurações e permita nosso acesso à câmera', 
'missingPermission.media_library.description': 'Verifique as configurações e permita nosso acesso à biblioteca de fotos', 
'missingPermission.microphone.description': 'Verifique as configurações e permita nosso acesso ao microfone', 
'module.access': 'Acessar', 
'module.availablePoints': 'de {points}', 
'module.button.enter': 'Entrar', 
'module.button.locked': 'Bloqueado', 
'module.button.review': 'Revisar', 
'module.conqueredPercentage': '{percentage}%', 
'module.conqueredPoints': '{points} Pontos', 
'module.firstAccessTooltip': 'Clique aqui', 
'module.lastStudied': 'Último estudo', 
'module.loading': 'Carregando os módulos', 
'module.moduleDetailDescription': 'Sua pontuação neste módulo', 
'module.nationalRanking': 'Ranking Nacional', 
'module.noStudy': 'Sem registro.', 
'module.percentageComplete': '({percentage}% conquistados)', 
'module.score': 'Pontos', 
'module.tabTitle': 'Exercícios', 
'module.title': 'Módulos', 
'module.totalPoints': 'de {points}', 
'module.weekTime': 'Tempo Semanal', 
'month': 'Mês', 
'national': 'Nacional', 
'native': 'Nativo', 
'needHelp': 'Precisa de ajuda?', 
'next': 'Avançar', 
'noInternet.awaitingMessage': 'Aguardando conexão...', 
'noInternet.message': 'Você está offline. Verifique sua conexão.', 
'noInternet.title': 'Conecte-se à internet', 
'notifications.intent.congratulations': 'Parabéns!', 
'notifications.intent.informative': 'Informação!', 
'notifications.intent.motivational': 'Continue assim!', 
'notifications.noData': 'Você não possui notificações.', 
'notifications.title': 'Notificações', 
'notifications.type.1HourToCompleteEvaluationPeriodTime': 'Falta apenas 1 hora para você completar o tempo no seu período avaliativo.', 
'notifications.type.25PercentOfCourseCompleted': '25% do curso {courseName} foi concluído.', 
'notifications.type.2HourToCompleteEvaluationPeriodTime': 'Falta 2 horas para você completar o tempo no seu período avaliativo.', 
'notifications.type.30MinutesInTheDay': 'Você completou 30 minutos de estudo. Continue assim!', 
'notifications.type.50PercentOfCourseCompleted': '50% do curso {courseName} foi concluído.', 
'notifications.type.50PercentOfModuleCompleted': '50% do módulo "{moduleName}" ({courseName}) foi concluído', 
'notifications.type.80PercentOfCourseCompleted': '80% do curso {courseName} foi concluído.', 
'notifications.type.80PercentOfModuleCompleted': '80% do módulo "{moduleName}" ({courseName}) foi concluído.', 
'notifications.type.90PercentOfCourseCompleted': '90% do curso {courseName} foi concluído.', 
'notifications.type.90PercentOfModuleCompleted': '90% do módulo {moduleName} foi concluído.', 
'notifications.type.achievement': 'Parabéns, você foi o {position} de {month} em sua {location}', 
'notifications.type.certificationTestCreated': 'Seu Teste de Certificação foi criado.', 
'notifications.type.certificationTestEnabled': 'Seu Teste de Certificação esta disponível para execução.', 
'notifications.type.certificationTestReviewed': 'Seu Teste de Certificação foi revisado.', 
'notifications.type.certificationTestScheduled': 'Seu Teste de Certificação esta agendado para {scheduledTo}.', 
'notifications.type.closedRanking': 'O ranking de {month} foi encerrado.', 
'notifications.type.firstReviewEnabled': 'Revisão disponível para a unit {unitName} do módulo {moduleName}.', 
'notifications.type.masteryTestEnabled': 'Você desbloqueou um Teste de Aprendizagem no módulo {moduleName}.', 
'notifications.type.masteryTestFailed': 'Estude mais {units} units no módulo {moduleName} para habilitar o Teste de Aprendizagem novamente.', 
'notifications.type.masteryTestRetry': 'Você conquistou os pontos necessários para tentar novamente o Teste de Aprendizagem do módulo {moduleName}.', 
'notifications.type.moduleGroupEnabled': 'Você desbloqueou um novo grupo de módulos para o curso {courseName}.', 
'notifications.type.moduleListeningCompleted': 'Você atingiu o objetivo de Escuta no módulo {moduleName}.', 
'notifications.type.moduleReadingCompleted': 'Você atingiu o objetivo de Leitura no módulo {moduleName}.', 
'notifications.type.moduleSpeakingCompleted': 'Você atingiu o objetivo de Fala no módulo {moduleName}.', 
'notifications.type.moduleWritingCompleted': 'Você atingiu o objetivo de Escrita no módulo {moduleName}.', 
'notifications.type.more50PointsToUnlockUnitGroup': 'Faltam 50 pontos para você desbloquear mais units no módulo {moduleName}.', 
'notifications.type.secondReviewEnabled': 'Second review disponível para a unit {unitName} do módulo {moduleName}.', 
'notifications.type.studyQualityScore10': 'Parabéns, seu Study Quality esta muito bom.', 
'notifications.type.studyQualityScore15': 'Parabéns, você atingiu a nota máxima no Study Quality.', 
'notifications.type.top10National': 'Parabéns, você está no Top 10 do seu país.', 
'notifications.type.top10Regional': 'Parabéns, você está no Top 10 da sua região.', 
'notifications.type.top10School': 'Parabéns, você está no Top 10 da sua instituição.', 
'notifications.type.top30School': 'Parabéns, você entrou no Top 30 da sua instituição.', 
'notifications.type.top50National': 'Parabéns, você entrou no Top 50 do seu país.', 
'notifications.type.top50Regional': 'Parabéns, você entrou no Top 50 da sua região.', 
'notifications.type.unitGroupEnabled': 'Você desbloqueou um novo grupo de units para o módulo {moduleName}.', 
'notifications.type.weekTimeCloseToFinish': 'Faltam poucos minutos para você completar o tempo semanal. Continue assim!', 
'notifications.type.weekTimeCompleted': 'Parabéns!, você completou o tempo semanal.', 
'otherOptions': 'Outras opções', 
'payment.pendingPayment': 'Você possui um pagamento em aberto com vencimento em {date}. <a href="{link}" target="_blank" style="color: blue; text-decoration: underline">Clique aqui</a> para realizar o pagamento agora.', 
'placementTest.advanced': 'Avançado', 
'placementTest.beginner': 'Iniciante', 
'placementTest.button.continueToPlatform': 'Continuar', 
'placementTest.button.exit': 'Sair', 
'placementTest.button.repeatWarming': 'Repetir Aquecimento', 
'placementTest.button.startPlacement': 'Começar Teste de Nivelamento', 
'placementTest.button.startWarm': 'Iniciar Aquecimento', 
'placementTest.button.tryAgain': 'Tentar Novamente', 
'placementTest.button.watchVideo': 'Assista o video acima', 
'placementTest.completeProfile.subTitle': 'Complete seu cadastro', 
'placementTest.completeProfile.title': 'Perfil', 
'placementTest.completeProfileTitle': 'Antes de iniciar, vamos completar seu perfil?', 
'placementTest.description': 'Veja o vídeo abaixo que lhe dará dicas importantes sobre como fazer o teste de nivelamento.', 
'placementTest.endError': 'Ocorreu um problema para finalizar seu Teste de Nivelamento. Clique no botão abaixo para tentar novamente.', 
'placementTest.englishFluency': 'Fluência em inglês', 
'placementTest.finished.description': 'Obrigado por realizar o teste de nivelamento. Para ter acesso ao seu resultado entre em contato com o responsável pelo teste.', 
'placementTest.finished.title': 'Teste finalizado', 
'placementTest.greeting': 'Seja bem-vindo(a) {name}!', 
'placementTest.intermediate': 'Intermediario', 
'placementTest.introduction': 'Introdução', 
'placementTest.introduction.button.start': 'Iniciar aquecimento', 
'placementTest.introduction.button.watchVideo': 'Assista o video acima', 
'placementTest.introduction.description': 'Veja o vídeo abaixo que lhe dará dicas importantes sobre como fazer o teste de nivelamento.', 
'placementTest.introduction.subTitle': 'Faça seu teste de nivelamento', 
'placementTest.introduction.title': 'Introdução', 
'placementTest.levelSelect.beginner.description': 'Indicado para quem tem uma base de vocabulário em inglês.', 
'placementTest.levelSelect.beginner.title': 'Iniciante', 
'placementTest.levelSelect.none.description': 'Você irá começar do início, desde as primeiras palavras.', 
'placementTest.levelSelect.none.title': 'Super Iniciante', 
'placementTest.levelSelect.placement.description': 'Identifique seu nível a partir do nosso teste de nivelamento.', 
'placementTest.levelSelect.placement.title': 'Descobrir Seu Nível', 
'placementTest.levelSelect.subTitle': 'Ou faça nosso teste de nivelamento', 
'placementTest.levelSelect.title': 'Escolha seu nível de inglês', 
'placementTest.nextStep': 'Seu curso', 
'placementTest.pauseDescription1': 'Você já alcançou o nível {level} ({course}).', 
'placementTest.pauseDescription2': 'Use este tempo para fazer uma pausa. Respire fundo, então você pode continuar. O teste continuará em 30 segundos, uma vez que o temporizador pare.', 
'placementTest.pauseTitle': 'Você está indo bem!', 
'placementTest.placement': 'Teste de Nivelamento', 
'placementTest.placementResult.beginnerEnglishLevel': 'Iniciante', 
'placementTest.placementResult.button.continue': 'Continuar', 
'placementTest.placementResult.button.exit': 'Sair', 
'placementTest.placementResult.correctCount': 'Acertos', 
'placementTest.placementResult.englishLevel': 'Nível de Inglês', 
'placementTest.placementResult.englishLevelRuler': 'Seu nível de acordo com a escala internacional:', 
'placementTest.placementResult.loading': 'Calculando seu resultado...', 
'placementTest.placementResult.startingCourse': 'Você iniciará no curso', 
'placementTest.placementResult.subTitle': 'Veja sua pontuação', 
'placementTest.placementResult.title': 'Resultado do Nivelamento', 
'placementTest.placementResultCorrects': 'Acertos', 
'placementTest.placementResultEnglishLevel': 'Nível de Inglês', 
'placementTest.placementResultPoint': 'Pontuação', 
'placementTest.placementRulerTitle': 'Seu nível de acordo com as certificações internacionais de inglês.', 
'placementTest.placementScore': 'Sua pontuação', 
'placementTest.placementScoreAnalysisFirst': 'Sua primeira pontuação', 
'placementTest.placementScoreAnalysisNew': 'Sua nova pontuação', 
'placementTest.placementTest.loading': 'Carregando Teste...', 
'placementTest.placementTest.title': 'Teste de Nivelamento', 
'placementTest.practiceResult.badScoreDescription': 'Você já pode começar o Teste de Nivelamento. Caso queira, também pode repetir o Aquecimento.', 
'placementTest.practiceResult.badScoreTitle': 'Pronto.', 
'placementTest.practiceResult.button.retry': 'Repetir aquecimento', 
'placementTest.practiceResult.button.start': 'Começar teste de nivelamento', 
'placementTest.practiceResult.goodScoreDescription': 'Você parece pronto para iniciar o teste de nivelamento. Boa sorte!', 
'placementTest.practiceResult.goodScoreTitle': 'Muito bom!', 
'placementTest.practiceResult.subTitle': 'Vamos agora decobrir seu nível', 
'placementTest.practiceResult.title': 'Aquecimento Concluído', 
'placementTest.practiceTest.loading': 'Carregando Aquecimento...', 
'placementTest.practiceTest.title': 'Aquecimento', 
'placementTest.profile': 'Perfil', 
'placementTest.register.duplicatedEmail': 'E-mail já cadastrado no sistema. Verifique com seu professor como acessar a plataforma para realizar o teste de nivelamento.', 
'placementTest.register.title': 'Seja bem-vindo ao seu teste de nivelamento.', 
'placementTest.score': 'Resultado', 
'placementTest.startError': 'Ocorreu um problema para iniciar seu Teste de Nivelamento. Clique no botão abaixo para tentar novamente.', 
'placementTest.steps': '{from} de {to}', 
'placementTest.testPause.advancedLevel': '(avançado)', 
'placementTest.testPause.greeting': 'Muito bom!', 
'placementTest.testPause.greetingDescriptionPart1': 'Você já alcançou o', 
'placementTest.testPause.greetingDescriptionPart2': 'nível {course}', 
'placementTest.testPause.intermediateLevel': '(intermediário)', 
'placementTest.testPause.pauseDescription': 'O teste continuará em 30 segundos, uma vez que o temporizador pare.', 
'placementTest.testPause.pauseTitle': 'Vamos fazer uma pausa.', 
'placementTest.testPause.title': 'Teste de Nivelamento', 
'placementTest.userDemoOnboarding.button': 'Ok', 
'placementTest.userDemoOnboarding.message': '<p>Esse teste é apenas uma demonstração de como seus alunos irão realizá-lo, não se preocupe com erros e acertos!</p><p>Após a finalização, basta atualizar a página do seu perfil para acessar qualquer curso através do botão "Open Demo".</p>', 
'placementTest.warming': 'Aquecimento', 
'placementTest.warmingBadScore': 'Pronto. Você concluiu o Aquecimento.', 
'placementTest.warmingBadScoreDescription': 'Você já pode começar o Teste de Nivelamento. Caso queira, também pode repetir o Aquecimento.', 
'placementTest.warmingGoodScore': 'Muito bom, você concluiu o Aquecimento!', 
'placementTest.warmingGoodScoreDescription': 'Você parece pronto para iniciar o teste de nivelamento. Boa sorte!', 
'placementTestInvite.button.doLater': 'Agora não', 
'placementTestInvite.button.startPlacement': 'Iniciar teste de nivelamento', 
'placementTestInvite.description': 'Clique no botão abaixo e avalie o quanto você já evoluiu na fluência do inglês.', 
'placementTestInvite.subTitle': 'Parabéns pelo seu empenho em estudar inglês!', 
'placementTestInvite.title': 'Vamos medir seu progresso até aqui?', 
'points': 'Pontos', 
'profile.account.birthDate': 'Data de Nascimento', 
'profile.account.button.back': 'Voltar', 
'profile.account.button.save': 'Salvar', 
'profile.account.button.saveContinue': 'Salvar e Continuar', 
'profile.account.changeAvatar': 'Enviar Foto', 
'profile.account.city': 'Cidade', 
'profile.account.completeProfileTitle': 'Antes de iniciar, vamos completar seu perfil?', 
'profile.account.confirmPassword': 'Confirmar senha', 
'profile.account.contactPhone': 'Telefone de contato', 
'profile.account.currentPassword': 'Senha atual', 
'profile.account.email': 'E-mail', 
'profile.account.error.email': 'O e-mail informado já está em uso. Por favor utilize outro e-mail.', 
'profile.account.error.general': 'Não foi possível atualizar seus dados. Verifique os campos e tente novamente.', 
'profile.account.error.password': 'Verifique sua senha atual. Para alterar a senha você deve usar a senha atual e a nova senha desejada.', 
'profile.account.error.picture': 'Não foi possível atualizar sua foto. Tente novamente.', 
'profile.account.female': 'Feminino', 
'profile.account.gender': 'Sexo', 
'profile.account.institution': 'Instituição', 
'profile.account.locale.english': 'Inglês', 
'profile.account.locale.label': 'Idioma', 
'profile.account.locale.portuguese': 'Português', 
'profile.account.locale.spanish': 'Espanhol', 
'profile.account.male': 'Masculino', 
'profile.account.name': 'Nome', 
'profile.account.newPassword': 'Nova senha', 
'profile.account.nicknameTooltip': 'O nome que será exibido na plataforma', 
'profile.account.notRankingParticipant': 'Não desejo participar do ranking {name}', 
'profile.account.passwordDescription': 'Para alterar sua senha, você dese inserir sua senha atual e então a nova senha desejada.', 
'profile.account.passwordQuestion': 'Gostaria de alterar a senha?', 
'profile.account.passwordTitle': 'Alterar Senha', 
'profile.account.phone': 'Telefone', 
'profile.account.rankingDescription': 'Pontos recebidos durante a não participação no ranking não serão registrados.', 
'profile.account.rankingParticipant': 'Desejo participar do ranking {name}', 
'profile.account.school': 'Colégio', 
'profile.account.success.picture': 'Sua foto foi atualizada.', 
'profile.account.success.profile': 'Seus dados foram atualizados com sucesso.', 
'profile.account.title': 'Seus dados', 
'profile.achievement.noData': 'Você não possui conquista, continue estudando para ganhar sua primeira medalha!', 
'profile.achievement.noNationalData': 'Sem conquistas nacionais', 
'profile.achievement.noRegionalData': 'Sem conquistas regionais', 
'profile.achievement.noSchoolData': 'Sem conquistas institucionais', 
'profile.achievement.noStudentData': 'Sem conquistas pessoais', 
'profile.achievement.title': 'Conquistas', 
'profile.history.noData': 'Histórico indisponível.', 
'profile.history.tableTitle': 'Posições no Ranking', 
'profile.history.title': 'Histórico', 
'profile.performance.noData': 'Continue estudando para gerar seu grafico de desempenho!', 
'profile.performance.noHoursMonthChartData': 'Sem horas de estudo no mês {month}', 
'profile.performance.noHoursWeekChartData': 'Sem horas de estudo na semana {week}', 
'profile.performance.noPointsMonthChartData': 'Sem pontos conquistados no mês {month}', 
'profile.performance.noPointsWeekChartData': 'Sem pontos conquistados na semana {week}', 
'profile.performance.title': 'Desempenho', 
'profile.progressTimeline': 'Linha do tempo', 
'profile.sprint.noData': 'Continue estudando em dias seguidos para gerar suas sprints!', 
'profile.sprint.title': 'Histórico de Sprint', 
'profile.timelineApprovedMasteryTest': 'Aprovado no Mastery Test', 
'profile.timelineBonusTestCompleted': 'Teste Bônus concluído', 
'profile.timelineCertificationTestCompleted': 'Concluiu o Teste de Certificação', 
'profile.timelineCourseStarted': 'Curso iniciado', 
'profile.timelineDailyRecord': 'Conquistou <b>{points}</b> pontos', 
'profile.timelineFailedMasteryTest': 'Reprovado no Mastery Test', 
'profile.timelineModuleCompleted': 'Completou o módulo <b>{moduleName}</b>', 
'profile.timelineNoData': 'Começe a estudar para gerar a sua linha do tempo.', 
'profile.timelinePlacementTestLevel': 'Conquistou o nível <b>{level}</b> no Teste de Nivelamento', 
'profile.title': 'Perfil', 
'profile.updateProfileLink': 'Atualizar perfil', 
'profile.yourWeekTime': 'Seu Tempo Semanal', 
'ranking.cardCheckFullData': 'Veja o Ranking completo', 
'ranking.cardNoData': 'Ranking indisponível', 
'ranking.currentSchoolPosition': 'Sua posição na instituição', 
'ranking.firstSemester': 'Primeiro semestre', 
'ranking.monthSelectLabel': 'Selecione o mês', 
'ranking.nationalPosition': 'Ranking Nacional', 
'ranking.noData': 'O ranking ainda não esta disponível, seja o primeiro a estudar e habilitar o ranking.', 
'ranking.noPositionHistory': 'Histórico indisponível', 
'ranking.positionHistory': 'Histórico de posições', 
'ranking.rankingPoints': '{points} pontos', 
'ranking.regionalPosition': 'Ranking Regional', 
'ranking.rewards.ranking': 'Pontos de ranking', 
'ranking.rewards.time': 'Meta de estudo semanal', 
'ranking.rewards.title': 'Sua recompensa', 
'ranking.schoolPosition': 'Ranking Institucional', 
'ranking.secondSemester': 'Segundo semestre', 
'ranking.title': 'Ranking de {month}', 
'ranking.topOfAllTime': 'Top de todos os tempos', 
'ranking.topOfMonth': 'Top de', 
'ranking.topOfSemester': 'Top do', 
'ranking.topOfYear': 'Top do Ano', 
'ranking.yearSelect': 'Ano de', 
'reading': 'Leitura', 
'record': 'Gravar', 
'regional': 'Regional', 
'repeat': 'Repetir', 
'requestPermission.button.allow': 'Permitir', 
'requestPermission.camera.description': 'Nós precisamos da sua confirmação para acessar, processar, coletar e armazenar sua foto de perfil. Sua foto de perfil é publica e estará visível no ranking.', 
'requestPermission.camera.title': 'Acesso à câmera', 
'requestPermission.media_library.description': 'Nós precisamos da sua confirmação para acessar, processar, coletar e armazenar a foto de perfil escolhida por você. Sua foto de perfil é publica e estará visível no ranking.', 
'requestPermission.media_library.title': 'Acesso à biblioteca', 
'requestPermission.microphone.description': 'Nós precisamos da sua confirmação para acessar, processar, coletar e armazenar os aúdios gravados como respostas dos exercícios realizados na plataforma.', 
'requestPermission.microphone.title': 'Acesso ao microfone', 
'requireDesktop.hasAppCard.description': 'Use o botão abaixo para acessar o aplicativo.', 
'requireDesktop.hasAppCard.title': 'Já tem o aplicativo instalado?', 
'requireDesktop.installAppCard.step1': 'Instale o app para seu celular.', 
'requireDesktop.installAppCard.step2': 'Use o botão abaixo para abrir o app.', 
'requireDesktop.installAppCard.title': 'Ainda não tem instalado?', 
'requireDesktop.open': 'Abrir aplicativo', 
'requireDesktop.placementDemo': 'Abrir nivelamento', 
'requireDesktop.title': 'Ops! Você precisa usar nosso aplicativo', 
'requireDesktop.unitDemo': 'Abrir demonstração', 
'school': 'Institucional', 
'scoreToPass': 'Nota necessária', 
'slowRepeat': 'Repetir Lentamente', 
'speaking': 'Fala', 
'sprint.classAverage': 'Média da turma', 
'sprint.currentSprint': 'Sua sequência atual', 
'sprint.description': 'Dias seguidos estudando', 
'sprint.maximumSprint': 'Sua maior sequência', 
'sprint.no.classAverage': 'Sem média da turma', 
'sprint.no.currentSprint': 'Sem sequência atual', 
'sprint.no.maximumSprint': 'Sem sequência maxima', 
'sprint.no.schoolAverage': 'Sem média da instituição', 
'sprint.noData': 'Seu histórico de Sprint será gerado após dois dias de estudo', 
'sprint.schoolAverage': 'Média da instituição', 
'sprint.title': 'Sprint', 
'startIntroductionButton': 'Iniciar Introdução', 
'startTastingModal.button': 'Iniciar', 
'startTastingModal.firstDescription': 'Verifique se o seu fone ou autofalante está funcionando antes de iniciar a demonstração, pois as instruções serão fornecidas via áudio. Siga-as para ter uma experiência completa.', 
'startTastingModal.secondDescription': 'Vamos lá!', 
'startTastingModal.title': 'Olá, Seja bem-vindo(a)!', 
'student': 'Pessoal', 
'studyQuality.classAverage': 'Média da Turma', 
'studyQuality.classroom': 'Turma', 
'studyQuality.howToImprove': 'Como posso melhorar?', 
'studyQuality.howToImproveNoData': 'No momento não temos um detalhamento do seu estudo. Continue estudando e nós vamos gerar as informações para te ajudar.', 
'studyQuality.modalTitle': 'Minha Qualidade de Estudo', 
'studyQuality.name': 'Qualidade de Estudo', 
'studyQuality.noData': 'Sua Qualidade de Estudo será gerada após uma hora de estudo.', 
'studyQuality.noEvaluationPeriod': 'Você não está em um período de avaliação ativo.', 
'studyQuality.requirements.DAYS_STUDIED': 'Estudar pelo menos 7 dias a cada 15 dias', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'Quando estudar, fazer pelo menos 30 minutos', 
'studyQuality.requirements.LISTEN_USAGE': 'Uso do botão Escutar', 
'studyQuality.requirements.READING_USAGE': 'Não usar excessivamente o botão Leitura', 
'studyQuality.requirements.REPEAT_RECORD_LISTEN_RELATION': 'Usar 2x mais o botão Repetir do que o Escutar e Falar', 
'studyQuality.requirements.REPEAT_USAGE': 'Uso do botão Repetir', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Acerto das questions', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Nota média no Speech Recognition', 
'studyQuality.requirements.TIME_STUDIED': 'Estudar o tempo semanal exigido', 
'studyQuality.requirements.TRANSLATE_USAGE': 'Não usar excessivamente o botão Traduzir', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Nota média nas units', 
'studyQuality.studentScore': 'Seu score', 
'studyQuality.title': 'Histórico da Qualidade de Estudo', 
'studyQuality.yours': 'Eu', 
'subMenu.courseProgress': 'Seu progresso', 
'subMenu.masteryTestDescription': 'Teste de aprendizagem do módulo {moduleName}', 
'subMenu.weekTime': 'Tempo semanal', 
'survey.button.doLater': 'Mais tarde', 
'survey.button.start': 'Responder pesquisa', 
'survey.description': 'Temos muito interesse em saber. Em menos de 1 minuto você responde. Clique no botão abaixo para iniciar. Agradecemos muito sua participação!', 
'survey.title': 'Qual sua opinião sobre estudar inglês com nosso aplicativo?', 
'tastingContact.company': 'Empresa*', 
'tastingContact.country': 'País*', 
'tastingContact.description': 'Por favor, preencha o formulário abaixo para continuar.', 
'tastingContact.email': 'E-mail*', 
'tastingContact.fullName': 'Nome completo*', 
'tastingContact.message': 'Mensagem', 
'tastingContact.name': 'Nome*', 
'tastingContact.phone': 'Telefone/Skype', 
'tastingContact.saveAndContinue': 'Experimentar agora', 
'tastingContact.title': 'Experimente a {title}', 
'tastingContact.whatsApp': 'WhatsApp*', 
'timer': 'Tempo', 
'translate': 'Traduzir', 
'unit.abilities': 'Habilidades', 
'unit.blockedDueTime': 'Bloqueado por 3 minutos', 
'unit.button.continue': 'Continuar', 
'unit.button.locked': 'Bloqueado', 
'unit.button.play': 'Assistir', 
'unit.button.recap': 'Iniciar', 
'unit.button.replay': 'Rever', 
'unit.button.start': 'Iniciar', 
'unit.button.watch': 'Assistir', 
'unit.button.watch_again': 'Assistir novamente', 
'unit.challengeTooltip': 'Clique em Iniciar para fazer o Desafio e conquistar esses pontos.', 
'unit.contentVideoStep.available': 'Disponível', 
'unit.contentVideoStep.blocked': 'Bloqueada', 
'unit.contentVideoStep.completed': 'Finalizada', 
'unit.contentVideoStep.title': 'Progresso da aula', 
'unit.enunciationsModal.actions.back': 'Voltar', 
'unit.enunciationsModal.actions.start': 'Iniciar', 
'unit.enunciationsModal.content': 'Conteúdo', 
'unit.enunciationsModal.grammars': 'Gramáticas', 
'unit.enunciationsModal.skills': 'Habilidades', 
'unit.firstAccessTooltip': 'Clique aqui', 
'unit.group.A': 'Fase 1', 
'unit.group.B': 'Fase 2', 
'unit.group.C': 'Fase 3', 
'unit.group.D': 'Fase 4', 
'unit.group.E': 'Fase 5', 
'unit.optionalReview': 'Opcional', 
'unit.points': '{points} Pontos', 
'unit.requiredReview': 'Obrigatório', 
'unit.reviewTooltip': 'Você deve fazer a Revisão para completar as etapas deste exercício. Clique em Iniciar para finalizá-lo.', 
'unit.reward.conqueredTitle': 'Ganhou', 
'unit.reward.title': 'Ganhe até', 
'unit.simpleReviewTooltip': 'Você pode revisar esta unit 3 vezes, e consquistar {amountOfPoints} pontos cada vez.', 
'unit.status.continue': 'Continuar', 
'unit.status.first_review': 'Revisão', 
'unit.status.simple_review': 'Refazer', 
'unit.status.your_challenge': 'Desafio', 
'unit.steps.description': 'Todo exercício é composto por duas etapas: Desafio e Revisão. Ao concluir a etapa Desafio, a etapa Revisão será liberada no dia seguinte.', 
'unit.steps.exercise': 'Exercício', 
'unit.steps.first_review': 'Revisão', 
'unit.steps.title': 'Etapas do Exercício', 
'unit.steps.your_challenge': 'Desafio', 
'unit.title': 'Exercícios', 
'unit.unblockReviewIn': 'Revisão será liberada amanhã', 
'unitExecutionNotification.3_CHECKPOINT_SEQUENCE': [ 
'Seu esforço está valendo a pena! Arrasou!', 
'Impressionante, você sabe muito!', 
'Puxa, você aprende rápido! Muito bem!', 
'Sequência incrível de acertos, parabéns!', 
'Ótimo, continue mandando ver!', 
], 
'unitExecutionNotification.3_SPEECH_RECOGNITION_SEQUENCE': [ 
'Excelente! Você manja mesmo de inglês!', 
'Dose tripla de pronúncias incríveis! Parabéns!', 
'Você não cansa de arrasar!', 
'Acertou em cheio na fala!', 
'Sua capacidade de falar inglês é absurda!', 
'Eu ficaria por horas ouvindo você falar inglês. Que show!', 
], 
'unitExecutionNotification.6_CHECKPOINT_SEQUENCE': [ 
'Uau, você é extremamente dedicado!', 
'Impressionante, você é bom demais nisso! Assim não vale!', 
'Wow! Você está dominando tudo!', 
'Você está tentando me enganar que não sabe inglês, só pode!', 
'Assim não dá! Pode liberar os aplausos, produção!', 
], 
'unitExecutionNotification.6_SPEECH_RECOGNITION_SEQUENCE': [ 
'Você arrasa na pronúncia! Incrível!', 
'Com essa pronúncia você vai longe!', 
'Wow! Mais uma excelente pronúncia!', 
'Mandou bem na pronúncia! É isso aí!', 
'Confessa, você já sabia falar inglês né?', 
'Nossa, sua pronúncia está sensacional!', 
'Você e o idioma de inglês nasceram um para o outro.', 
'Deu match! Você e a língua inglesa já estão num relacionamento sério!', 
], 
'unitExecutionNotification.9_CHECKPOINT_SEQUENCE': [ 
'Você é um gênio!', 
'Que show de conhecimento!', 
'Você não erra, formidável!', 
'Já pode se considerar um guru do inglês!', 
'Seu nível de conhecimento é espetacular!', 
'Seu desempenho é de outro mundo! Fantástico!', 
], 
'unitExecutionNotification.9_SPEECH_RECOGNITION_SEQUENCE': [ 
'Você está falando como um expert!', 
'Você fala incrivelmente bem!', 
'Ora, ora, parece que temos um geniozinho por aqui!', 
'Wow! Você nasceu falando inglês?', 
'Até onde vai essa sequência fantástica? Estou chocado!', 
'Chocado com a sua sequência! Você é impressionante!', 
'Tenho certeza que você já pode conversar com um americano sem medo!', 
'Você já é praticamente um mestre em inglês', 
'OMG, a partir de agora vamos conversar only in english, please!', 
'Quanta emoção! Meu pequeno Padawan já está quase um Jedi do inglês!', 
'Hey, quanto você cobra para dar aulas de inglês?', 
], 
'unitExecutionNotification.LISTEN_USAGE_TIP': [ 
'Aumente seu Study Quality utilizando mais vezes o botão Escutar.', 
'Hey, não esqueça de utilizar o Escutar para escutar sua pronúncia.', 
'Ouvir a própria pronúncia potencializa o aprendizado.', 
'O Escutar ajuda a perceber a própria pronúncia. Não esqueça de usar!', 
'Ouvir-se ajuda a aprender melhor. Utilize o Escutar mais vezes.', 
], 
'unitExecutionNotification.MAX_CHECKPOINT_SEQUENCE': [ 
'Temos um americano por aqui?', 
'Você só pode ter nascido nos EUA, nem vem!', 
'Puxa, com esse nível de conhecimento você já pode virar cidadão americano!', 
'Seria você o Einstein dos idiomas?', 
'Você manja muito, nem um americano desconfiaria da sua real nacionalidade!', 
], 
'unitExecutionNotification.MAX_SPEECH_RECOGNITION_SEQUENCE': [ 
'Uau, você é americano', 
'Fala a verdade! O inglês é a sua língua materna né?', 
'Aposto que você nasceu dizendo hello ao invés de chorar!', 
'Você atingiu o nível Jedi, que a fala esteja com você!', 
'S-E-N-S-A-C-I-O-N-A-L! Você está dominando a pronúncia do inglês!', 
'Ninguém me avisou que eu estava conversando com um cidadão americano!', 
'Ok, não precisa apresentar o passaporte, já sabemos que você é americano!', 
'Wow, há quanto tempo você mora nos EUA?', 
'Se você me disser que não é fluente em inglês, só me resta acreditar!', 
'Você não tem limites! Desse jeito vamos te contratar para trabalhar com a gente!', 
'Assim você me deixa sem graça! Não estava pronto para conversar com alguém fluente em inglês.', 
'Verifiquei aqui na sua documentação e não consta que você nasceu nos EUA. Há algo errado?', 
], 
'unitExecutionNotification.NO_LISTEN_SEQUENCE': [ 
'Não desanime, escute sua pronúncia e será mais fácil acertar!', 
'Os botões de suporte facilitam a vida e auxiliam no aprendizado. Pode utilizar!', 
'Escute sua pronúncia e compare com a pronúncia do falante fluente.', 
'Se estiver com dificuldade, não esqueça de se escutar.', 
'Se escutar auxilia na melhora da pronúncia em inglês.', 
'Não esqueça de se escutar. Isso vai facilitar sua vida.', 
'Antes de tentar novamente se escute para comparar sua pronúncia com o áudio.', 
], 
'unitExecutionNotification.NO_REPEAT_SEQUENCE': [ 
'Escute mais vezes o áudio antes de tentar novamente.', 
'Use o botão Repetir para escutar atentamente a pronúncia da frase.', 
'Ouça novamente a frase para te ajudar a acertar!', 
'Se estiver com dificuldade é só utilizar os botões de suporte. Aproveite!', 
'Você pode repetir lentamente o áudio da frase se estiver com dificuldade.', 
'Os botões de suporte estão aí para te auxiliar. Abuse deles!', 
'Ouvir a pronúncia com atenção te ajudará. Utilize mais o Repetir!', 
'Que tal utilizar os botões de suporte para te ajudar?', 
], 
'unitExecutionNotification.REPEAT_USAGE_TIP': [ 
'Ao usar mais vezes o Repetir o seu Study Quality vai melhorar.', 
'Não esqueça de escutar mais vezes a pronúncia do áudio.', 
'Escutar mais vezes o áudio ajudará no desenvolvimento da sua pronúncia.', 
'Utilize mais o botão Repetir. Isso acelera seu aprendizado.', 
'Use o botão Repetir para escutar mais vezes a pronúncia do áudio.', 
], 
'unitRating.comment': 'Deixe seu comentário...', 
'unitRating.question': 'Sua avaliação é muito importante para nós.', 
'unitRating.title': 'Deixe seu feedback', 
'unitResult.closeTasting': 'Fechar', 
'unitResult.conclude': 'Concluir', 
'unitResult.congrats': 'Parabéns!', 
'unitResult.continueToUnitList': 'Continuar para lista de exercícios', 
'unitResult.currentRanking': 'Ranking', 
'unitResult.keepPracticing': 'Continue praticando', 
'unitResult.loading': 'Calculando seu resultado', 
'unitResult.newPoints': 'Sua nova pontuação', 
'unitResult.newRanking': 'Novo Ranking', 
'unitResult.next': 'Próximo', 
'unitResult.recommendedUnits': 'Aula recomendada', 
'unitResult.reviewStep.didWell.button.no': 'Não, vou pular', 
'unitResult.reviewStep.didWell.button.yes': 'Sim, quero revisar', 
'unitResult.reviewStep.didWell.reviewImportance': 'Por que é importante revisar?', 
'unitResult.reviewStep.didWell.subtitle': 'Gostaria de revisar este exercício em alguns dias?', 
'unitResult.reviewStep.didWell.title1': 'Você foi muito bem!', 
'unitResult.reviewStep.didWell.title2': 'Sua revisão será opcional.', 
'unitResult.reviewStep.firstReview.subtitle': 'Tente novamente antes de avançar no curso.', 
'unitResult.reviewStep.firstReview.title': 'Parece que você está com dificuldade neste exercício.', 
'unitResult.reviewStep.modal.button': 'Entendi', 
'unitResult.reviewStep.modal.paragraph1': 'Revisar é muito importante para consolidar o conhecimento que você acabou de ver, e preencher possíveis lacunas que ficaram.', 
'unitResult.reviewStep.modal.paragraph2': 'Isso porque estudar novamente em dias diferentes te ajuda a gravar o conteúdo estudado na memória de longo prazo!', 
'unitResult.reviewStep.modal.paragraph3': 'Sua revisão ficará disponível amanhã.', 
'unitResult.reviewStep.modal.title': 'Por que é importante revisar?', 
'unitResult.reviewStep.notWell.description': 'Uma revisão foi adicionada a sua lista e estará disponível amanhã.', 
'unitResult.reviewStep.notWell.title': 'Vamos tentar novamente', 
'unitResult.reviewStep.optionalReview.button.no': 'Não', 
'unitResult.reviewStep.optionalReview.button.yes': 'Sim', 
'unitResult.reviewStep.optionalReview.subtitle': 'Gostaria de tentar novamente?', 
'unitResult.reviewStep.optionalReview.title': 'Esta revisão era opcional.', 
'unitResult.reviewStep.title': 'Revisão', 
'unitResult.scoreToPass': 'Nota necessária: {score}', 
'unitResult.yourScore': 'Sua nota', 
'unitResult.yourTotalPoints': 'Seu total de pontos', 
'updates.button.updateNow': 'Atualizar agora', 
'updates.description': 'Temos uma atualização do aplicativo disponível!', 
'userAway.button': 'Sim, eu estou estudando!', 
'userAway.description': 'Parece que você está ausente da plataforma, se você esta estudando clique no botão abaixo para continuar.', 
'userAway.penalityLabel': 'Devido a inatividade você perderá 30 segundos do tempo de estudo neste exercício', 
'userErrorReport.buttonsText.discardChanges': 'Descartar', 
'userErrorReport.buttonsText.submit': 'Enviar', 
'userErrorReport.errorMessages.apiError': 'Não foi possível enviar seu reporte no momento. Aguarde um momento e tente novamente', 
'userErrorReport.errorMessages.studentNotFound': 'E-mail não cadastrado na plataforma', 
'userErrorReport.labels.content': 'Nos conte o que aconteceu', 
'userErrorReport.labels.email': 'E-mail', 
'userErrorReport.labels.subject': 'Assunto', 
'userErrorReport.placeholders.content': 'Sinta-se à vontade para nos enviar suas sugestões e feedbacks', 
'userErrorReport.placeholders.email': 'Coloque o seu e-mail cadastrado na plataforma', 
'userErrorReport.placeholders.subject': 'Coloque aqui o assunto do seu reporte', 
'userErrorReport.title': 'Reportar um erro', 
'userSnapButton': 'Reportar Erro', 
'validate': 'Validar', 
'validations.Invalid email': 'E-mail inválido', 
'validations.Must Match Password': 'A Confirmação deve ser igual a senha', 
'validations.Required': 'Obrigatório', 
'videoIntroduction.button': 'Continuar', 
'videoIntroduction.home.title': 'Entenda o seu curso', 
'videoIntroduction.module_list.title': 'Entenda o seu curso', 
'videoIntroduction.unit_list.title': 'Conheça seus exercícios', 
'videoIntroduction.welcome.title': 'Entenda o seu curso', 
'videoPlayer.localesSelect': 'Selecione um idioma para legendas', 
'videoPlayer.noSubtitles': 'Desativado', 
'week': 'Semana', 
'welcomeModal.description': 'Utilize seu <b>som</b> e <b>microfone</b> para realizar o exercício.', 
'welcomeModal.startButton': 'Vamos começar', 
'welcomeModal.title': 'Bem vindo à Flexge', 
'writing': 'Escrita', 
'you': 'Eu', 
}; 
export default labels;