import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Flow from "student-front-commons/src/components/Flow";
import get from "lodash/get";
import CloseExecutionModal from "../../core/CloseExecutionModal";
import { CLOSE_BONUS_TEST_EXECUTION_FLOW } from "../../consts";

const CloseBonusExecutionContainer = () => (
  <NavigationPrompt
    disableNative={false}
    renderIfNotActive={true}
    when={(from, to) => {
      if (get(to, "state.systemNavigation", false)) {
        return false;
      }
      return !to || !/\/course\/[0-9a-fA-F]{24}\/bonus-test\/result/.test(to.pathname);
    }}
  >
    {({ isActive, onCancel, onConfirm }) =>
      isActive && (
        <Flow autoStart={false} id={CLOSE_BONUS_TEST_EXECUTION_FLOW}>
          {({ handleStart: handleClose }) => (
            <CloseExecutionModal
              autoClose={false}
              message="bonusTest.closeExecution"
              onConfirm={() => {
                handleClose();
                onConfirm();
              }}
              onCancel={onCancel}
            />
          )}
        </Flow>
      )
    }
  </NavigationPrompt>
);

export default CloseBonusExecutionContainer;
