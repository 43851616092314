import React from "react";
import Separator from "../../core/Separator";
import SpeechRecognitionTestResultContainer from "./SpeechRecognitionTestResultContainer";
import SpeechRecognitionTestListContainer from "./SpeechRecognitionTestListContainer";
import TranslatedText from "../../core/TranslatedText";

const SpeechRecognitionTestStep = () => (
  <div
    style={{
      padding: "50px 60px",
    }}
  >
    <TranslatedText
      as="h1"
      translateKey="microphoneTest.phrasesTitle"
      style={{
        fontWeight: "bold",
        fontSize: 22,
        lineHeight: "24px",
        margin: 0,
        color: "#607d8b",
      }}
    />
    <Separator size="md" />
    <Separator size="xs" />
    <SpeechRecognitionTestListContainer />
    <Separator size="md" />
    <SpeechRecognitionTestResultContainer />
  </div>
);

export default SpeechRecognitionTestStep;
