import React from "react";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Separator from "../../../core/Separator";
import TranslatedText from "../../../core/TranslatedText";
import FlexColumn from "../../../core/FlexColumn";
import CompleteProfileCard from "../../../core/complete-profile/CompleteProfileCard";
import TestHeader from "../TestHeader";
import TestStepContainer from "../TestStepContainer";

const TestCompleteProfileScene = () => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <FlexColumn alignItems="center" marginBottom={20}>
        <TestHeader color={primaryColor} />
        <TestStepContainer currentStep={5} />
        <Separator size="lg" />
        <Separator size="sm" />
        <TranslatedText
          as="h1"
          translateKey="placementTest.completeProfileTitle"
          style={{
            color: "#fff",
            fontSize: 20,
            fontWeight: "bold",
            margin: 0,
            lineHeight: "26px",
          }}
        />
        <Separator size="md" />
        <CompleteProfileCard />
      </FlexColumn>
    )}
  </WhitelabelContext.Consumer>
);

export default TestCompleteProfileScene;
