import React from "react";
import head from "lodash/head";
import { getPlacementTests } from "student-front-commons/src/services/placementTestService";
import FetchData from "student-front-commons/src/components/FetchData";
import Async from "../../../core/Async";
import OldScoreSection from "./OldScoreSection";

const OldScoreSectionContainer = () => (
  <FetchData service={getPlacementTests}>
    {({ isFetching, data }) => (
      <Async fetching={isFetching}>
        <OldScoreSection placementTest={head(data)} />
      </Async>
    )}
  </FetchData>
);

export default OldScoreSectionContainer;
