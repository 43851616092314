import { schema } from "normalizr";

import schoolClassSchema from "./schoolClassSchema";
import schoolSchema from "./schoolSchema";
import { PROFILE_SCHEMA } from "./index";
import companySchema from './companySchema';

export default new schema.Entity(
  PROFILE_SCHEMA,
  {
    schoolClass: schoolClassSchema,
    school: schoolSchema,
    company: companySchema,
  },
  {
    processStrategy: (entity) => ({
      ...entity,
      rankingParticipant: entity.role === "STUDENT" ? (entity.rankingParticipant ? "S" : "N") : null,
    }),
  }
);
