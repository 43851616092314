import React, { useCallback } from "react";
import SurveyModal from "./SurveyModal";
import moment from "moment";
import { useEntity } from "student-front-commons/src/hooks";

const SurveyModalContainer = () => {
  const surveyCompleted = localStorage.getItem("nps_completed");

  const profile = useEntity("profile", sessionStorage.getItem("id"));

  const handleClose = useCallback(() => {
    localStorage.setItem("nps_completed", "true");
  }, []);

  if (
    surveyCompleted !== "true" &&
    moment().isBefore(moment("2022-12-14")) &&
    moment(profile.createdAt).isBefore(moment("2022-01-10"))
  ) {
    return <SurveyModal onClose={handleClose} />;
  }

  return null;
};

export default SurveyModalContainer;
