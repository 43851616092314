import Styled from "styled-components";

export default Styled.h1`
  display: inline-block;
  text-transform: uppercase;
  color: #607d8b;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
`;
