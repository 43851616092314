import { CLEAN_CERTIFICATION_TEST_RESULT, MERGE_CERTIFICATION_TEST_RESULT } from "../consts/actions";

export const requestMergeCertificationTestResult = (certificationTestResult) => ({
  type: MERGE_CERTIFICATION_TEST_RESULT,
  payload: {
    certificationTestResult,
  },
});

export const requestCleanCertificationTestResult = () => ({
  type: CLEAN_CERTIFICATION_TEST_RESULT,
});
