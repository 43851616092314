import Base64 from "Base64";
import jwtDecode from "jwt-decode";

import validate from "../core/validate";
import request from "../core/request";
import safeCall from "../core/safeCall";
import { setToken } from "../config";

/**
 * Execute the user login
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.email - the email to authenticate
 * @param {string} payload.password - the password
 * @param {string} payload.distributor - the password
 * @param {string} payload.company - the password
 * @param {string} payload.role - the user role
 * @param {Object} payload.deviceInfo - the device properties
 * @param {string} payload.deviceInfo.browser - The name of the browser.
 * @param {string} payload.deviceInfo.os - The operational system name.
 * @param {string} payload.deviceInfo.internetSpeed - The internet speed.
 * @param {string} payload.deviceInfo.screenSize - The screen size (width_height).
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<{id: *, accessToken: *}>}
 */
export const login = async (payload) => {
  validate(
    {
      email: {
        presence: {
          allowEmpty: false,
        },
      },
      password: {
        presence: {
          allowEmpty: false,
        },
      },
      role: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  if (payload.role === "STUDENT") {
    validate(
      {
        deviceInfo: {
          presence: {
            allowEmpty: false,
          },
        },
        "deviceInfo.browser": {
          presence: {
            allowEmpty: false,
          },
        },
        "deviceInfo.os": {
          presence: {
            allowEmpty: false,
          },
        },
        "deviceInfo.internetSpeed": {
          presence: {
            allowEmpty: false,
          },
        },
        "deviceInfo.screenSize": {
          presence: {
            allowEmpty: false,
          },
        },
      },
      payload
    );
  }

  return safeCall(
    async () => {
      const loginResult = await request({
        url: "auth",
        method: "post",
        headers: {
          Authorization: `Basic ${Base64.btoa(`${payload.email}:${payload.password}`)}`,
        },
        data: {
          distributor: payload.distributor,
          company: payload.company,
          ...payload.deviceInfo,
        },
      });

      const decodeResult = jwtDecode(loginResult.accessToken);
      setToken(loginResult.accessToken);

      return {
        id: decodeResult.sub,
        accessToken: loginResult.accessToken,
        studentAppVersion: loginResult.studentAppVersion,
        schoolClass: loginResult.schoolClass,
        locale: decodeResult.locale,
        timezone: decodeResult.timezone,
        contractCode: decodeResult.contractCode,
        school: decodeResult.school,
        academicPlan: decodeResult.academicPlan,
      };
    },
    "login_error",
    "Unexpected error on authentication."
  );
};

/**
 * Reset the user password in api
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.email - the email to authenticate
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<void>}
 */
export const resetPassword = async (payload) => {
  validate(
    {
      email: {
        presence: true,
        email: true,
      },
    },
    payload
  );

  return safeCall(
    async () => {
      await request({
        url: "auth/reset-password",
        method: "post",
        headers: {
          Authorization: `Basic ${Base64.btoa(`${payload.email}:resetpassword`)}`,
        },
        data: {
          email: payload.email,
        },
      });
    },
    "reset_password_error",
    "Unexpected error to reset password."
  );
};

/**
 * Validate placement test token
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.token - the token to validate
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<void>}
 */
export const validatePlacementTestToken = async (payload) => {
  validate(
    {
      token: {
        presence: true,
      },
    },
    payload
  );

  return safeCall(
    async () =>
      await request({
        url: "auth/validate-placement-token",
        method: "post",
        data: {
          token: payload.token,
        },
      }),
    "token_validation_error",
    "Unexpected error to validate token."
  );
};

/**
 * Register placement test user
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.name - the name of the user
 * @param {string} payload.email - the email to authenticate
 * @param {string} payload.locale - the locale of the browser
 * @param {string} payload.schoolClass - the school class of the placement test
 * @param {string} payload.distributor - the password
 * @param {string} payload.company - the password
 * @param {string} payload.role - the user role
 * @param {Object} payload.deviceInfo - the device properties
 * @param {string} payload.deviceInfo.browser - The name of the browser.
 * @param {string} payload.deviceInfo.os - The operational system name.
 * @param {string} payload.deviceInfo.internetSpeed - The internet speed.
 * @param {string} payload.deviceInfo.screenSize - The screen size (width_height).
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<void>}
 */
export const registerPlacementTestUser = async (payload) => {
  validate(
    {
      email: {
        presence: {
          allowEmpty: false,
        },
      },
      name: {
        presence: {
          allowEmpty: false,
        },
      },
      role: {
        presence: {
          allowEmpty: false,
        },
      },
      locale: {
        presence: {
          allowEmpty: false,
        },
      },
      schoolClass: {
        presence: {
          allowEmpty: false,
        },
      },
      deviceInfo: {
        presence: {
          allowEmpty: false,
        },
      },
      "deviceInfo.browser": {
        presence: {
          allowEmpty: false,
        },
      },
      "deviceInfo.os": {
        presence: {
          allowEmpty: false,
        },
      },
      "deviceInfo.internetSpeed": {
        presence: {
          allowEmpty: false,
        },
      },
      "deviceInfo.screenSize": {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () =>
      await request({
        url: "auth/placement-register",
        method: "post",
        data: {
          name: payload.name,
          email: payload.email,
          locale: payload.locale,
          role: payload.role,
          distributor: payload.distributor,
          company: payload.company,
          schoolClass: payload.schoolClass,
          browser: payload.deviceInfo.browser,
          os: payload.deviceInfo.os,
          screenSize: payload.deviceInfo.screenSize,
          internetSpeed: payload.deviceInfo.internetSpeed,
        },
      }),
    "register_validation_error",
    "Unexpected error to register user."
  );
};
