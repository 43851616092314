import React from "react";
import TestHeader from "../TestHeader";
import TestPanel from "../TestPanel";
import Separator from "../../../core/Separator";
import ScoreSection from "../ScoreSection";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { stopAudio } from "../../../stores/audio-store";
import TestStepContainer from "../TestStepContainer";
import OldScoreSectionContainer from "./OldScoreSectionContainer";
import { withRouter } from "react-router-dom";
import Button from "../../../core/Button";

class PlacementTestScoreAnalysisScene extends React.PureComponent {
  componentWillUnmount() {
    stopAudio();
  }

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <TestHeader color={primaryColor} />
            <TestStepContainer currentStep={4} />
            <TestPanel width="820px">
              <TranslatedText
                as="h1"
                translateKey="placementTest.placementScoreAnalysisNew"
                style={{
                  color: primaryColor,
                  fontSize: 30,
                  lineHeight: "24px",
                  margin: 0,
                }}
              />
              <Separator size="xs" />
              <Separator size="md" />
              <ScoreSection />
              <Separator size="lg" />
              <TranslatedText
                as="h2"
                translateKey="placementTest.placementScoreAnalysisFirst"
                style={{
                  color: "#424242",
                  opacity: 0.8,
                  fontSize: 22,
                  lineHeight: "24px",
                  margin: 0,
                }}
              />
              <Separator size="sm" />
              <OldScoreSectionContainer />
              <Separator size="xxl" />
              <TranslatedText
                as={Button}
                translateKey="placementTest.button.continueToPlatform"
                color={primaryColor}
                onClick={() => this.props.history.replace("/")}
                style={{
                  width: 240,
                  fontSize: 16,
                  fontWeight: 700,
                  borderRadius: 5,
                  height: 50,
                  textTransform: "uppercase",
                }}
              />
            </TestPanel>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withRouter(PlacementTestScoreAnalysisScene);
