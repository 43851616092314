import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import QuestionLabel from "../QuestionLabel";
import ExerciseItemPanel from "./ExerciseItemPanel";
import withTranslation from "../withTranslation";
import { FEEDBACK_WRONG, FEEDBACK_CORRECT, WHITE } from "../../consts/color";
import { last } from "lodash";
import * as diff from "diff";
import "./GrammarCheckExerciseItem.css";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import FlexColumn from "../FlexColumn";
import Separator from "../Separator";
import BackgroundedIcon from "../BackgroundedIcon";
import { Spring } from "react-spring";

const GrammarCheckText = ({ itemId }) => {
  const answer = useSelector(getItemExecutionPropById(itemId, "answer"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(itemId, "item.linkedAnswers"));
  const itemText = useSelector(getItemExecutionPropById(itemId, "item.text"));
  const extraData = useSelector(getItemExecutionPropById(itemId, "extraData"));
  const isFinished = useSelector(getItemExecutionPropById(itemId, "isFinished"));
  const showCorrectOption = useSelector(getItemExecutionPropById(itemId, "showCorrectOption"));
  const attempts = useSelector(getItemExecutionPropById(itemId, "attempts"));

  const getWordsResult = useCallback(() => {
    const correctAnswer = itemLinkedAnswers.find(
      (answer) => answer.text.replace(/['!.?,]/g, "").trim() === extraData.bestMatch
    );
    const diffs = diff.diffArrays(
      itemText.split(/([\s.,!?;])/g).filter((p) => p.trim()),
      correctAnswer.text.split(/([\s.,!?;])/g).filter((p) => p.trim())
    );
    return diffs.reduce((array, diff) => {
      return [
        ...array,
        ...diff.value.map((text) => ({
          text,
          removed: !!diff.removed,
          added: !!diff.added,
        })),
      ];
    }, []);
  }, [itemText, itemLinkedAnswers, extraData]);

  const getWordsRealTimeFeedback = useCallback(() => {
    const diffs = diff.diffArrays(
      itemText.split(/([\s.,!?;])/g).filter((p) => p.trim()),
      answer.split(/([\s.,!?;])/g).filter((p) => p.trim())
    );
    return diffs.reduce((array, diff) => {
      return [
        ...array,
        ...diff.value.map((text) => ({
          text,
          removed: !!diff.removed,
          added: !!diff.added,
        })),
      ];
    }, []);
  }, [itemText, answer]);

  const getTextChunks = useCallback(() => {
    if ((showCorrectOption || isFinished) && extraData) {
      return getWordsResult();
    } else if (answer) {
      return getWordsRealTimeFeedback();
    }
    return [{ text: itemText, added: false, removed: false }];
  }, [extraData, getWordsResult, getWordsRealTimeFeedback, itemText, isFinished, answer, showCorrectOption]);

  const textChunks = getTextChunks();

  const Content = ({ opacity = 1 }) => (
    <FlexColumn textAlign="center" opacity={opacity}>
      <FlexColumn
        borderRadius={20}
        backgroundColor="#607d8b"
        color={WHITE}
        padding="5px 20px"
        fontSize={16}
        width="fit-content"
        margin="auto"
        marginBottom={10}
      >
        {(isFinished || showCorrectOption) && !last(attempts)?.correct ? "Correct answer:" : "Your changes:"}
      </FlexColumn>
      <QuestionLabel whiteSpace={"normal"} cursor="inherit">
        {textChunks.map((chunk, index) => (
          <>
            <span
              style={{
                color:
                  showCorrectOption || isFinished
                    ? chunk.added
                      ? FEEDBACK_CORRECT
                      : chunk.removed
                      ? FEEDBACK_WRONG
                      : "inherit"
                    : "inherit",
                fontWeight: chunk.added ? "bold" : "inherit",
                textDecoration: chunk.removed ? "line-through" : chunk.added ? "underline" : "none",
                fontFamily: "inherit",
              }}
            >
              {chunk.text.trim()}
            </span>
            {textChunks[index + 1] && textChunks[index + 1].text.charAt(0).match(/[a-zA-Z0-9\s]/) ? " " : ""}
          </>
        ))}
      </QuestionLabel>
    </FlexColumn>
  );

  return (isFinished || showCorrectOption) && !last(attempts)?.correct ? (
    <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} config={{ duration: 2500, delay: 500 }}>
      {({ opacity }) => <Content opacity={opacity} />}
    </Spring>
  ) : (
    <Content />
  );
};

const GrammarCheckInput = ({ itemId }) => {
  const itemText = useSelector(getItemExecutionPropById(itemId, "item.text"));
  const isFinished = useSelector(getItemExecutionPropById(itemId, "isFinished"));
  const dispatch = useDispatch();
  const [isPending] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const attempts = useSelector(getItemExecutionPropById(itemId, "attempts"));

  const handleChange = useCallback(
    (event) => {
      dispatch(addItemExecutionAnswer(itemId, { answer: event.target.innerText }));
    },
    [itemId]
  );

  return (
    <FlexColumn
      position="relative"
      width="100%"
      backgroundColor="white"
      borderRadius={3}
      borderWidth={(isPending || isFinished) && attempts.length ? 1 : 0}
      borderStyle="solid"
      borderColor={
        (isPending || isFinished) && attempts.length && last(attempts).correct ? FEEDBACK_CORRECT : FEEDBACK_WRONG
      }
      padding="12px 15px"
    >
      <div
        contentEditable={true}
        style={{
          border: "none",
          resize: "none",
          width: "100%",
          outline: "none",
          fontSize: 22,
          color: "#607d8b",
          textAlign: "center",
          borderBottom: "2px solid rgb(206, 206, 206)",
        }}
        onInput={handleChange}
        onBlur={handleChange}
        dangerouslySetInnerHTML={{ __html: itemText }}
      />
      {(isPending || isFinished) && attempts.length > 0 && (
        <FlexColumn position="absolute" right={-45} top={0} alignItems="center" justifyContent="center" height="100%">
          <BackgroundedIcon
            size={30}
            icon={last(attempts).correct ? "check" : "close"}
            backgroundColor={last(attempts).correct ? FEEDBACK_CORRECT : FEEDBACK_WRONG}
          />
        </FlexColumn>
      )}
    </FlexColumn>
  );
};

const GrammarCheckExerciseRender = (props) => {
  return (
    <ExerciseItemPanel>
      <Separator size="lg" />
      <GrammarCheckInput itemId={props.itemId} />
      <Separator size="xl" />
      <GrammarCheckText itemId={props.itemId} />
    </ExerciseItemPanel>
  );
};

GrammarCheckExerciseItem.propTypes = {};

export default function GrammarCheckExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => withTranslation(GrammarCheckExerciseRender)({ itemId: id }));
}
