import React from "react";
import PropTypes from "prop-types";
import CircularButton from "../CircularButton";
import { GREY_5 } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "ReadButton" };

const ReadButton = (props) => (
  <MediaQuery maxWidth={540}>
    {(matches) => (
      <CircularButton
        icon="book-open"
        color={GREY_5}
        size={matches ? "md" : "lg"}
        onClick={() => {
          props.onClick();
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "CircularButton",
            action: `Read Unit Item`,
          });
        }}
        disabled={props.isDisabled}
      />
    )}
  </MediaQuery>
);

ReadButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ReadButton;
