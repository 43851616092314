import React, { useContext, useMemo } from "react";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { useEntity, useEntityList } from "student-front-commons/src/hooks";
import {
  COMPANY_SCHEMA,
  MASTERY_TEST_SCHEMA,
  MODULE_SCHEMA,
  PROFILE_SCHEMA,
  SCHEDULED_UNIT_REVIEW_SCHEMA,
  UNIT_SCHEMA,
} from "student-front-commons/src/schemas";
import { withRouter } from "react-router-dom";
import UnitList from "./UnitList";

function UnitListContainer({ group, match }) {
  const { numberOfDayBeforeFirstReview, numberOfDayBeforeSecondReview } = useSelector((state) => ({
    numberOfDayBeforeFirstReview: state.configurations.numberOfDayBeforeFirstReview,
    numberOfDayBeforeSecondReview: state.configurations.numberOfDayBeforeSecondReview,
  }));
  const { timeToAllowContinueUnit: whiteLabelTimeToAllowContinueUnit } = useContext(WhitelabelContext);

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));
  const company = useEntity(COMPANY_SCHEMA, profile.company);
  const allModules = useEntityList(MODULE_SCHEMA);
  const allCourseMasteryTests = useEntityList(MASTERY_TEST_SCHEMA);
  const allCourseUnits = useEntityList(UNIT_SCHEMA);
  const allScheduledUnitReviews = useEntityList(SCHEDULED_UNIT_REVIEW_SCHEMA);

  const timeToAllowContinueUnit = useMemo(() => {
    return company?.timeToAllowContinueUnit ?? whiteLabelTimeToAllowContinueUnit;
  }, [company?.timeToAllowContinueUnit, whiteLabelTimeToAllowContinueUnit]);

  const failedMasteryTest = useMemo(
    () =>
      allCourseMasteryTests.find((masteryTest) => masteryTest.module === match.params.idModule && masteryTest.failedAt),
    [match.params.idModule, allCourseMasteryTests]
  );

  const units = useMemo(() => {
    return [
      ...orderBy(allCourseUnits, ["group", "order"]),
      ...orderBy(company?.isOptionalReviewEnabled ? allScheduledUnitReviews : [], ["group", "order"]),
    ].filter((unit) => unit.module === match.params.idModule && unit.group === group);
  }, [match.params.idModule, allCourseUnits, allScheduledUnitReviews, company?.isOptionalReviewEnabled]);

  return (
    <UnitList
      contentVideoLearningPathEnabled={company?.allowContentVideoTab}
      units={units}
      failedMasteryTest={failedMasteryTest}
      currentCertificationTest={profile.certificationTest}
      numberOfDayBeforeFirstReview={numberOfDayBeforeFirstReview}
      numberOfDayBeforeSecondReview={numberOfDayBeforeSecondReview}
      timeToAllowContinueUnit={timeToAllowContinueUnit}
      isSpeechRecognitionDisabled={profile.isSpeechRecognitionDisabled}
      isFirstAccess={group === "A" ? !allModules.some((module) => module.percentageComplete > 0) : false}
      isOptionalReviewEnabled={company?.isOptionalReviewEnabled}
    />
  );
}

UnitListContainer.propTypes = {
  group: PropTypes.string.isRequired,
};

export default withRouter(UnitListContainer);
