import React, { Component } from "react";
import PropTypes from "prop-types";
import { animated, Spring } from "react-spring";
import FlexRow from "./FlexRow";
import Icon from "./Icon";
import Separator from "./Separator";
import FlexColumn from "./FlexColumn";

export default class MasteryTestCountDownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: 0,
      pause: true,
    };
  }

  static propTypes = {
    delay: PropTypes.number,
    isDisabled: PropTypes.bool,
    time: PropTypes.number.isRequired,
    onTimerEnd: PropTypes.func.isRequired,
    onTimerEnding: PropTypes.func.isRequired,
  };

  static defaultProps = {
    delay: 0,
    isDisabled: true,
    isMounted: false,
  };

  timerInterval = null;
  timerTimeout = null;

  handleClearTimer = () => {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    if (this.timerTimeout) {
      clearTimeout(this.timerTimeout);
    }
  };

  handleCreateTimer = () => {
    if (!this.state.isMounted) {
      return;
    }
    this.handleClearTimer();
    this.setState({
      time: this.props.time,
      pause: true,
    });

    this.timerTimeout = setTimeout(() => {
      this.setState(
        {
          pause: false,
        },
        () => {
          this.timerInterval = setInterval(this.handleInterval, 1000);
        }
      );
    }, this.props.delay);
  };

  handleInterval = () => {
    if (!this.state.isMounted) {
      return;
    }
    this.setState(
      {
        pause: this.props.isDisabled,
        time: this.state.time - (this.props.isDisabled ? 0 : 1),
      },
      () => {
        if (this.state.time <= 0) {
          this.handleCreateTimer();
          if (!this.state.pause) {
            this.props.onTimerEnd();
          }
        }
        if (this.state.time === 10) {
          this.props.onTimerEnding();
        }
      }
    );
  };

  componentDidMount() {
    this.setState(
      {
        isMounted: true,
      },
      () => {
        this.handleCreateTimer();
      }
    );
  }

  componentWillUnmount() {
    this.handleClearTimer();
    this.setState({
      isMounted: false,
    });
  }

  render() {
    return (
      <FlexColumn alignItems="center" justifyContent="center" transition="all .3s" borderRadius={3}>
        <Icon icon={this.state.pause ? "pause" : "clock-outline"} color="#64748b" size="sm" />
        <Separator size="xxs" />
        <FlexRow
          position="relative"
          alignItems="center"
          backgroundColor="#cbd5e1"
          width={120}
          height={8}
          borderRadius={32}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "#fca5a5",
              width: (120 * 10) / this.props.time,
              height: 8,
              borderRight: "2px solid #fefefe",
              borderRadius: "32px 0 0 32px",
            }}
          />
          <Spring
            from={{ width: "0%" }}
            to={{ width: "100%" }}
            delay={this.props.delay || 0}
            config={{
              duration: this.props.time * 1000,
            }}
          >
            {(styles) => (
              <>
                <animated.div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    backgroundColor: "#e2e8f0",
                    height: 8,
                    borderRadius: 32,
                    ...styles,
                  }}
                />
                <animated.div
                  style={{
                    position: "absolute",
                    height: 12,
                    right: `calc(${styles.width} - 6px)`,
                    width: 12,
                    background: this.state.time <= 10 ? "#f87171" : "#94a3b8",
                    border: "2px solid #fefefe",
                    borderRadius: 12,
                    zIndex: 4,
                  }}
                />
              </>
            )}
          </Spring>
        </FlexRow>
      </FlexColumn>
    );
  }
}
