import {
  START_ITEM_INSTRUCTION,
  TOGGLE_ITEM_STEP_INSTRUCTION,
  FINISH_ITEM_INSTRUCTION,
} from "../actions/itemInstruction";

export default (state = {}, action) => {
  switch (action.type) {
    case START_ITEM_INSTRUCTION:
      return {
        step: action.payload.step,
        highlight: false,
      };
    case TOGGLE_ITEM_STEP_INSTRUCTION:
      return {
        ...state,
        highlight: action.payload.highlight,
      };
    case FINISH_ITEM_INSTRUCTION:
      return {};
    default:
      return state;
  }
};
