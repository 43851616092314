import React from "react";
import PropTypes from "prop-types";
import browserHistory from "../../browserHistory";
import Separator from "../../core/Separator";
import CertificationTestResultDetailItem from "./CertificationTestResultDetailItem";
import ColumnSeparator from "../../core/ColumnSeparator";
import Button from "../../core/Button";
import Icon from "../../core/Icon";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const CertificationTestResultDetail = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <div>
            <TranslatedText
              as="p"
              translateKey={
                props.certificationTest.approvedAt
                  ? "certificationTest.result.approvedDescriptionTwo"
                  : "certificationTest.result.failedDescriptionTwo"
              }
              values={{
                unitsCount: props.unitsToFinishAfterCertificationTestFailure,
              }}
              style={{
                margin: 0,
                color: "#757575",
                fontSize: 13,
              }}
            />
            <Separator size="md" />
            <Separator size="xs" />
            <TranslatedText
              as="h5"
              translateKey="certificationTest.result.scoreBySkill"
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: "bold",
                color: "#757575",
              }}
            />
            <Separator />
            <CertificationTestResultDetailItem value={props.certificationTest.readingScore} type="reading" />
            <Separator size="xs" />
            <CertificationTestResultDetailItem value={props.certificationTest.listeningScore} type="listening" />
            <Separator size="xs" />
            <CertificationTestResultDetailItem value={props.certificationTest.writingScore} type="writing" />
            <Separator size="xs" />
            <CertificationTestResultDetailItem value={props.certificationTest.speakingScore} type="speaking" />
            <Separator size="md" />
            <Separator size="xs" />
            <TranslatedText
              as="h5"
              translateKey="certificationTest.result.finalScore"
              style={{
                margin: 0,
                fontSize: 14,
                fontWeight: "bold",
                color: "#757575",
              }}
            />
            <Separator size="md" />
            <h1
              style={{
                margin: 0,
                fontSize: 120,
                fontWeight: "bold",
                color: props.certificationTest.approvedAt ? "#00786A" : "#546e7a",
                lineHeight: "85px",
              }}
            >
              {(
                ((props.certificationTest.readingScore || 0) +
                  (props.certificationTest.listeningScore || 0) +
                  (props.certificationTest.writingScore || 0) +
                  (props.certificationTest.speakingScore || 0)) /
                4
              ).toFixed(0)}
            </h1>
            <Separator size="md" />
            <Separator size="sm" />
            {props.certificationTest.approvedAt && (
              <div
                style={{
                  backgroundColor: primary,
                  borderRadius: 3,
                  padding: "20px 30px 20px 20px",
                  boxSizing: "border-box",
                  width: "max-content",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Icon icon="lock-open-outline" size="lg" opacity={0.2} color="#fff" />
                  <ColumnSeparator size="xs" />
                  <div>
                    <TranslatedText
                      as="span"
                      translateKey="certificationTest.result.unlockedCourse"
                      style={{
                        color: "#fff",
                        fontSize: 14,
                        margin: "0 0 10px 0",
                      }}
                    />
                    <h1
                      style={{
                        margin: 0,
                        color: "#fff",
                        fontSize: 42,
                        fontWeight: "bold",
                      }}
                    >
                      {props.nextCourse.name}
                    </h1>
                  </div>
                  <ColumnSeparator size="md" />
                  <TranslatedText
                    as={Button}
                    translateKey="certificationTest.button.accessNewCourse"
                    style={{
                      backgroundColor: secondaryColor,
                      color: "#fff",
                      fontSize: 14,
                      height: 60,
                      fontWeight: "bold",
                      width: "auto",
                      padding: "20px 25px",
                      textTransform: "uppercase",
                    }}
                    onClick={() => browserHistory.push(`/course/${props.nextCourse.id}/modules`)}
                  />
                </div>
              </div>
            )}
            {!props.certificationTest.approvedAt && (
              <TranslatedText
                as={Button}
                translateKey="certificationTest.button.back"
                style={{
                  backgroundColor: primary,
                  color: "#fff",
                  fontSize: 14,
                  height: 60,
                  fontWeight: "bold",
                  width: "auto",
                  padding: "20px 25px",
                  textTransform: "uppercase",
                }}
                onClick={() => browserHistory.push(`/course/${props.certificationTest.course.id}/modules`)}
              />
            )}
          </div>
        )}
      </CourseThemeContext.Consumer>
    )}
  </WhitelabelContext.Consumer>
);

CertificationTestResultDetail.propTypes = {
  certificationTest: PropTypes.shape({
    approvedAt: PropTypes.string,
    course: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    readingScore: PropTypes.number,
    listeningScore: PropTypes.number,
    writingScore: PropTypes.number,
    speakingScore: PropTypes.number,
  }).isRequired,
  unitsToFinishAfterCertificationTestFailure: PropTypes.number.isRequired,
  nextCourse: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

CertificationTestResultDetail.defaultProps = {
  nextCourse: null,
};

export default CertificationTestResultDetail;
