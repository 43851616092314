import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { GREY_3 } from "../consts/color";

const DragOptionBox = Styled.div`
  background-color: ${() => GREY_3};
  width: auto;
  height: auto;
  text-align: center;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px 15px;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};

  &:hover {
    box-shadow: ${(props) => (props.disabled ? "none" : "0px 3px 3px 0 rgba(0, 0, 0, 0.1)")};
    transform: ${(props) => (props.disabled ? "none" : "scale(1.05)")};
  }
`;

const DragOptionItem = (props) => (
  <div
    onClick={() => {
      if (props.nextIndex !== null) {
        return props.onDrop(props.answer, props.nextIndex);
      } else {
        return null;
      }
    }}
    style={{
      border: props.isDragging ? `2px dashed ${GREY_3}` : "2px dashed transparent",
      minWidth: 100,
      pointerEvents: props.disabled ? "none" : "all",
    }}
  >
    {!props.answer.selected && (
      <DragOptionBox disabled={props.disabled}>
        <label
          htmlFor="text"
          style={{
            fontSize: 18,
            fontWeight: 400,
            cursor: "pointer",
            color: "#607d8b",
            margin: 0,
          }}
        >
          {/[!.?,]$/.test(props.answer.text) ? props.answer.text.replace(/[!.?,]$/, "") : props.answer.text}
        </label>
      </DragOptionBox>
    )}
  </div>
);

DragOptionItem.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  nextIndex: PropTypes.number.isRequired,
};

DragOptionItem.defaultProps = {
  disabled: false,
};

export default DragOptionItem;
