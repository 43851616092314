import React from "react";
import moment from "moment/moment";
import Column from "../../core/Column";
import Panel from "../../core/Panel";
import Row from "../../core/Row";
import Title from "../../core/Title";
import Separator from "../../core/Separator";
import CustomRankingListContainer from "./CustomRankingListContainer";
import TranslatedText from "../../core/TranslatedText";

const AllTimeRanking = () => (
  <Panel>
    <Row>
      <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
        <TranslatedText translateKey="regional" as={Title} />
        <Separator size="sm" />
        <CustomRankingListContainer
          level="regional"
          from={moment().year(2017).startOf("year").toDate()}
          to={moment().endOf("day").toDate()}
        />
      </Column>
      <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
        <TranslatedText translateKey="national" as={Title} />
        <Separator size="sm" />
        <CustomRankingListContainer
          level="national"
          from={moment().year(2017).startOf("year").toDate()}
          to={moment().endOf("day").toDate()}
        />
      </Column>
    </Row>
  </Panel>
);

export default AllTimeRanking;
