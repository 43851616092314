import React from "react";
import PropTypes from "prop-types";
import { detect } from "detect-browser";
import get from "lodash/get";
import find from "lodash/find";
import Separator from "./Separator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import bgLogin from "../images/bg-login.png";
import TranslatedText from "./TranslatedText";
import Paragraph from "./Paragraph";
import ButtonV2 from "./ButtonV2";
import IosStoreBadge from "../images/ios-store-badge.svg";
import PlayStoreBadge from "../images/play-store-badge.svg";

const styles = {
  pageTitle: {
    color: "#fff",
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    marginBottom: 20,
    padding: "0 40px",
  },
  card: {
    background: "#fff",
    borderRadius: 3,
    padding: 20,
    maxWidth: "500px",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
    zIndex: 1,
  },
  cardTitle: (primaryColor) => ({
    color: primaryColor,
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    margin: 0,
  }),
  step: (primaryColor) => ({
    width: 50,
    height: 50,
    borderRadius: "100%",
    backgroundColor: `${primaryColor}33`,
    color: primaryColor,
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  }),
};

const RequireDesktop = (props) => {
  const browser = detect();
  const isMobile = (browser) =>
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      browser
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      browser.substr(0, 4)
    );
  const isDesktopMode = (browser) => "ontouchstart" in window.document.documentElement && browser.os === "Linux";
  const isAllowedRoute = () =>
    props.allowedRoutes.some((r) =>
      r instanceof RegExp ? r.test(window.location.pathname) : r === window.location.pathname
    );

  let buttonLabel = "requireDesktop.open";
  if (/\/units\/[0-9a-fA-F]{24}\/tasting/.test(window.location.pathname)) {
    buttonLabel = "requireDesktop.unitDemo";
  }
  if (/\/placement\/tasting/.test(window.location.pathname)) {
    buttonLabel = "requireDesktop.placementDemo";
  }
  const button = (
    <a
      href={`https://${
        window.location.host.indexOf("linking") > -1
          ? window.location.host.replace("linking-", "")
          : `linking-${window.location.host}`
      }${window.location.pathname}${window.location.pathname === "/" ? "login" : ""}${window.location.search}`}
    >
      <ButtonV2 onClick={false} labelKey={buttonLabel} intent="primary" size="xs" />
    </a>
  );

  if (
    !isAllowedRoute() &&
    (isMobile(navigator.userAgent || navigator.vendor || window.opera) || isDesktopMode(browser))
  ) {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, logoUrl, appDistributions, domain }) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              minHeight: "100vh",
              backgroundColor: primaryColor,
            }}
          >
            <img
              src={bgLogin}
              alt="bg"
              style={{
                position: "fixed",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                background: "#fff",
                padding: "10px 0px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
                zIndex: 1,
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{
                  objectFit: "scale-down",
                  height: 30,
                  width: "auto",
                }}
              />
            </div>
            <Separator size="md" />
            <TranslatedText as="h1" translateKey="requireDesktop.title" style={styles.pageTitle} />
            {domain === "flexge.com" || domain === "english4all.app" ? (
              <React.Fragment>
                <div style={styles.card}>
                  <TranslatedText
                    as="h3"
                    translateKey="requireDesktop.hasAppCard.title"
                    style={styles.cardTitle(primaryColor)}
                  />
                  <Separator size="sm" />
                  <Separator size="xs" />
                  <TranslatedText
                    as={Paragraph}
                    translateKey="requireDesktop.hasAppCard.description"
                    style={{
                      color: "#343434",
                    }}
                  />
                  <Separator size="sm" />
                  {button}
                </div>
                <Separator size="md" />
                <div style={styles.card}>
                  <TranslatedText
                    as="h3"
                    translateKey="requireDesktop.installAppCard.title"
                    style={styles.cardTitle(primaryColor)}
                  />
                  <Separator size="sm" />
                  <Separator size="xs" />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={styles.step(primaryColor)}>1</div>
                      <Separator size="xs" />
                      <div>
                        <TranslatedText
                          as={Paragraph}
                          translateKey="requireDesktop.installAppCard.step1"
                          style={{
                            color: "#343434",
                          }}
                        />
                        <Separator size="xxs" />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          {(!!get(find(appDistributions, { app: "STUDENT_MOBILE" }), "androidStoreUrl", false) ||
                            domain === "flexge.com") && (
                            <a
                              href={get(
                                find(appDistributions, { app: "STUDENT_MOBILE" }),
                                "androidStoreUrl",
                                "https://play.google.com/store/apps/details?id=com.flexge.studentmobile"
                              )}
                            >
                              <img
                                alt="Disponível no Google Play"
                                src={PlayStoreBadge}
                                style={{
                                  height: 28,
                                }}
                              />
                            </a>
                          )}
                          <Separator size="xs" />
                          {(!!get(find(appDistributions, { app: "STUDENT_MOBILE" }), "iosStoreUrl", false) ||
                            domain === "flexge.com") && (
                            <a
                              href={get(
                                find(appDistributions, { app: "STUDENT_MOBILE" }),
                                "iosStoreUrl",
                                "https://itunes.apple.com/us/app/flexge/id1421907331?l=pt&amp;ls=1&amp;mt=8"
                              )}
                            >
                              <img
                                alt="Disponível na AppStore"
                                src={IosStoreBadge}
                                style={{
                                  height: 28,
                                }}
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                    <Separator size="md" />
                    <Separator size="xs" />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div style={styles.step(primaryColor)}>2</div>
                      <Separator size="xs" />
                      <div>
                        <TranslatedText
                          as={Paragraph}
                          translateKey="requireDesktop.installAppCard.step2"
                          style={{
                            color: "#343434",
                          }}
                        />
                        <Separator size="xxs" />
                        {button}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <div style={styles.card}>
                <TranslatedText
                  as="h3"
                  translateKey="requireDesktop.installAppCard.title"
                  style={styles.cardTitle(primaryColor)}
                />
                <Separator size="sm" />
                <Separator size="xs" />
                <TranslatedText
                  as={Paragraph}
                  translateKey="requireDesktop.installAppCard.step1"
                  style={{
                    color: "#343434",
                  }}
                />
                <Separator size="sm" />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {!!get(find(appDistributions, { app: "STUDENT_MOBILE" }), "androidStoreUrl", false) && (
                    <a href={get(find(appDistributions, { app: "STUDENT_MOBILE" }), "androidStoreUrl", undefined)}>
                      <img
                        alt="Disponível no Google Play"
                        src={PlayStoreBadge}
                        style={{
                          height: 28,
                        }}
                      />
                    </a>
                  )}
                  <Separator size="xs" />
                  {!!get(find(appDistributions, { app: "STUDENT_MOBILE" }), "iosStoreUrl", false) && (
                    <a href={get(find(appDistributions, { app: "STUDENT_MOBILE" }), "iosStoreUrl", undefined)}>
                      <img
                        alt="Disponível na AppStore"
                        src={IosStoreBadge}
                        style={{
                          height: 28,
                        }}
                      />
                    </a>
                  )}
                </div>
              </div>
            )}
            <Separator size="xl" />
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
  return <React.Fragment>{props.children}</React.Fragment>;
};

RequireDesktop.propTypes = {
  children: PropTypes.node.isRequired,
  allowedRoutes: PropTypes.array.isRequired,
};

export default RequireDesktop;
