import React from "react";
import get from "lodash/get";
import translations from "../locales";

const withTranslation = (WrappedComponent) => (props) =>
  (
    <WrappedComponent
      {...props}
      getTranslation={(key, values) => translations.formatString(get(translations, key), values || {})}
    />
  );

export default withTranslation;
