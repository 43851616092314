import React from "react";
import Separator from "../../core/Separator";
import AccountFormContainer from "./AccountFormContainer";
import SystemMessageContainer from "../../core/SystemMessageContainer";
import Row from "../../core/Row";

const AccountScene = () => (
  <div
    className="container"
    style={{
      maxWidth: 800,
    }}
  >
    <Separator size="xs" />
    <Separator size="md" />
    <Row>
      <SystemMessageContainer />
    </Row>
    <Separator size="sm" />
    <AccountFormContainer />
    <Separator size="md" />
  </div>
);

export default AccountScene;
