import React from "react";
import CoursePanel from "./CoursePanel";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

export default function CoursePanelContainer() {
  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  if (!profile || !profile.activeCourses.length) {
    return null;
  }
  return (
    <CoursePanel
      currentCourse={profile.activeCourses.find((course) => !course.distributor)}
      extraCourses={profile.activeCourses.filter((course) => !!course.distributor)}
    />
  );
}
