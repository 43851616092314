import React, { Component } from "react";
import Separator from "../../core/Separator";
import MasteryTestContent from "./MasteryTestContent";
import MasteryTestItemsProgressContainer from "./MasteryTestItemsProgressContainer";
import MasteryTestSubHeaderContainer from "./MasteryTestSubHeaderContainer";
import MasteryTestItemExecutionFormContainer from "./MasteryTestItemExecutionFormContainer";
import CloseMasteryTestExecutionContainer from "./CloseMasteryTestExecutionContainer";
import OldErrorModalContainer from "../../error/OldErrorModalContainer";

class MasteryTestExecutionScene extends Component {
  render() {
    return (
      <div>
        <MasteryTestSubHeaderContainer />
        <MasteryTestContent>
          <CloseMasteryTestExecutionContainer />
          <MasteryTestItemsProgressContainer />
          <Separator />
          <MasteryTestItemExecutionFormContainer />
        </MasteryTestContent>
        <OldErrorModalContainer />
      </div>
    );
  }
}

export default MasteryTestExecutionScene;
