const _config = {
  endpointUrl: null,
  speechRecognitionEndpointUrl: null,
  speechRecognitionKey: null,
  assetsUrl: null,
  setToken: () => false,
  getToken: () => false,
  onLogout: () => false,
};

/**
 *
 * @param endpointUrl
 * @param speechRecognitionEndpointUrl
 * @param speechRecognitionKey
 * @param assetsUrl
 * @param setToken
 * @param getToken
 * @param onLogout
 */
export const setConfig = ({
  endpointUrl,
  speechRecognitionEndpointUrl,
  speechRecognitionKey,
  setToken,
  assetsUrl,
  getToken,
  onLogout,
}) => {
  _config.endpointUrl = endpointUrl;
  _config.speechRecognitionEndpointUrl = speechRecognitionEndpointUrl;
  _config.speechRecognitionKey = speechRecognitionKey;
  _config.assetsUrl = assetsUrl;
  _config.setToken = setToken;
  _config.getToken = getToken;
  _config.onLogout = onLogout;
};

export const getEndpointUrl = () => _config.endpointUrl;

export const getSpeechRecognitionEndpointUrl = () => _config.speechRecognitionEndpointUrl;
export const getAssetsUrl = () => _config.assetsUrl;

export const getSpeechRecognitionKey = () => _config.speechRecognitionKey;

export const setToken = (token) => _config.setToken(token);

export const getToken = () => _config.getToken();

export const onLogout = () => _config.onLogout();
