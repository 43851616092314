import React, { Component } from "react";
import PropTypes from "prop-types";
import Flow from "student-front-commons/src/components/Flow";
import TestHeader from "../TestHeader";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { WHITE } from "../../../consts/color";
import Separator from "../../../core/Separator";
import FlexColumn from "../../../core/FlexColumn";
import FlexRow from "../../../core/FlexRow";
import LevelSelectButton from "./LevelSelectButton";
import { get, orderBy, uniqBy } from "lodash";
import { SET_MANUAL_COURSE } from "../../../consts";
import { withRouter } from "react-router-dom";
import TestStepContainer from "../TestStepContainer";

class LevelSelectScene extends Component {
  static propTypes = {
    placementTestLevels: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  render() {
    const levels = uniqBy(
      orderBy(
        this.props.placementTestLevels.filter((itemLevel) => itemLevel.level),
        ["level"],
        ["asc"]
      ),
      "course.id"
    );

    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <div
            style={{
              marginBottom: 20,
            }}
          >
            <TestHeader color={primaryColor} />
            <TestStepContainer currentStep={1} />
            <Separator size="xl" />
            <Separator size="xl" />
            <FlexColumn alignItems="center">
              <TranslatedText
                as="h1"
                translateKey="placementTest.levelSelect.title"
                style={{
                  color: WHITE,
                  fontSize: 20,
                  fontWeight: "bold",
                  margin: 0,
                  lineHeight: "26px",
                  textAlign: "center",
                }}
              />
              <Separator size="xs" />
              <TranslatedText
                as="p"
                translateKey="placementTest.levelSelect.subTitle"
                style={{
                  color: WHITE,
                  fontSize: 16,
                  margin: 0,
                  lineHeight: "20px",
                  textAlign: "center",
                }}
              />
            </FlexColumn>
            <Separator size="md" />
            <Separator size="xs" />
            <FlexRow justifyContent="center">
              <Flow
                autoStart={false}
                id={SET_MANUAL_COURSE}
                params={{
                  type: "none",
                  course: get(levels[0], "course.id", null),
                }}
              >
                {({ isPending, handleStart, getParams }) => (
                  <LevelSelectButton
                    onClick={handleStart}
                    isDisabled={isPending}
                    isLoading={isPending && getParams().type === "none"}
                    type="none"
                  />
                )}
              </Flow>
              <Separator size="lg" />
              <Flow
                autoStart={false}
                id={SET_MANUAL_COURSE}
                params={{
                  type: "beginner",
                  course: get(levels[1], "course.id", null),
                }}
              >
                {({ isPending, handleStart, getParams }) => (
                  <LevelSelectButton
                    onClick={handleStart}
                    isDisabled={isPending}
                    isLoading={isPending && getParams().type === "beginner"}
                    type="beginner"
                  />
                )}
              </Flow>
              <Separator size="lg" />
              <Flow autoStart={false} id={SET_MANUAL_COURSE}>
                {({ isPending }) => (
                  <LevelSelectButton
                    onClick={() => this.props.history.replace("/practice-test-introduction")}
                    isDisabled={isPending}
                    isLoading={false}
                    type="placement"
                  />
                )}
              </Flow>
            </FlexRow>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withRouter(LevelSelectScene);
