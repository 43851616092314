import React, { useMemo } from "react";
import browserHistory from "../../../browserHistory";
import Button from "../../../core/Button";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import FlexColumn from "../../../core/FlexColumn";
import Separator from "../../../core/Separator";
import PlacementClassFinish from "../../../images/placement-class-finish.svg";
import { getEntityById } from "student-front-commons/src/selectors/entity";
import { COMPANY_SCHEMA, PROFILE_SCHEMA, SCHOOL_CLASS_SCHEMA } from "student-front-commons/src/schemas";
import { useSelector } from "react-redux";

const PlacementByLinkResult = () => {
  const profile = useSelector(getEntityById(PROFILE_SCHEMA, sessionStorage.getItem("id")));
  const schoolClass = useSelector(getEntityById(SCHOOL_CLASS_SCHEMA, profile?.schoolClass));
  const company = useSelector(getEntityById(COMPANY_SCHEMA, profile.company));

  const reachedCourse = useSelector((state) => state.placementTestResult.reachedLevel?.course?.name);
  const answeredItems = useSelector((state) => state.placementTestResult.answeredItems);

  const placementRedirectUrl = useMemo(() => {
    return profile.apiVersion === "V2" ? company.placementTestLinkSettings?.redirectUrl : schoolClass.placementLinkRedirectUrl;
  }, [profile, schoolClass, company]);

  return (
    <WhitelabelContext.Consumer>
      {({ primaryColor }) => (
        <FlexColumn alignItems="center" maxWidth={560} width="100%" padding="0px 15px" margin="0 auto">
          <img src={PlacementClassFinish} alt="Placement finished" width="150px" />
          <Separator size="lg" />
          <TranslatedText
            as="h1"
            translateKey="placementTest.finished.title"
            style={{
              color: primaryColor,
              fontSize: 30,
              lineHeight: "24px",
            }}
          />
          <Separator />
          <TranslatedText
            as="span"
            translateKey="placementTest.finished.description"
            style={{
              color: "#646464",
              fontSize: 18,
            }}
          />
          <Separator size="lg" />
          <TranslatedText
            as={Button}
            translateKey={placementRedirectUrl ? "continue" : "close"}
            color={primaryColor}
            onClick={() => {
              sessionStorage.clear();
              if (placementRedirectUrl) {
                window.location.href = `${placementRedirectUrl}?studentId=${profile.id}&email=${
                  profile.email
                }&reachedLevel=${reachedCourse || "beginner"}&questions=${answeredItems.length}&correctAnswers=${
                  answeredItems.filter((answer) => answer.correct).length
                }`;
              } else {
                browserHistory.push("/login");
              }
            }}
            style={{
              width: "100%",
              fontSize: 16,
              fontWeight: 700,
              borderRadius: 5,
              height: 50,
              textTransform: "uppercase",
            }}
          />
        </FlexColumn>
      )}
    </WhitelabelContext.Consumer>
  );
};

export default PlacementByLinkResult;
