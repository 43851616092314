import React, { useContext } from "react";
import PropTypes from "prop-types";
import { FEEDBACK_CORRECT, GREY_5, GREY_7 } from "../../consts/color";
import PopoverButton from "../PopoverButton";
import CircularButton from "../CircularButton";
import QuestionLabel from "../QuestionLabel";
import CourseThemeContext from "../CourseThemeContext";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import useTranslation from "student-front-commons/src/hooks/useTranslation";
import { useEntity } from "student-front-commons/src/hooks";
import { PROFILE_SCHEMA } from "student-front-commons/src/schemas";

export default function DialogueMessage({
  itemId,
  text,
  dialogueCharacter,
  shouldHighlight,
  isTranslationOpen,
  handleTranslationOpen,
}) {
  const courseThemeContext = useContext(CourseThemeContext);
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));

  const messagePosition = { STUDENT: "flex-end", CHARACTER: "flex-start", ANSWER: "flex-end" }[dialogueCharacter];
  const messageColor = { STUDENT: FEEDBACK_CORRECT, CHARACTER: "#ffffff", ANSWER: "#ffffff" }[dialogueCharacter];
  const borderColor = { STUDENT: FEEDBACK_CORRECT, CHARACTER: "#ffffff", ANSWER: "#fff" }[dialogueCharacter];
  const fontColor = { STUDENT: "#ffffff", CHARACTER: GREY_7, ANSWER: GREY_7 }[dialogueCharacter];
  const showArrowRight = ["STUDENT", "ANSWER"].some((character) => character === dialogueCharacter);
  const showArrowLeft = dialogueCharacter === "CHARACTER";

  const profile = useEntity(PROFILE_SCHEMA, sessionStorage.getItem("id"));

  const [, translation] = useTranslation(itemId, profile.locale);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: messagePosition,
        position: "relative",
        margin: dialogueCharacter === "ANSWER" ? "0 20px" : "10px 20px",
        maxWidth: "70%",
        marginLeft: dialogueCharacter === "STUDENT" ? "auto" : "20px",
      }}
    >
      <div
        style={{
          width: 0,
          height: 0,
          borderRight: `${showArrowRight ? "12px" : 0} solid transparent`,
          borderLeft: `${showArrowLeft ? "12px" : 0} solid transparent`,
          borderTop: `8px solid ${shouldHighlight ? courseThemeContext.primary : borderColor}`,
          position: "absolute",
          right: showArrowRight ? "-11px" : "auto",
          left: showArrowLeft ? "-11px" : "auto",
          top: 0,
          zIndex: 2,
        }}
      >
        {dialogueCharacter === "ANSWER" && (
          <div
            style={{
              width: 0,
              height: 0,
              borderRight: `${showArrowRight ? "9px" : 0} solid transparent`,
              borderTop: "6px solid #ffffff",
              position: "absolute",
              right: "-9px",
              top: "-7px",
              zIndex: 10,
            }}
          />
        )}
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: messageColor,
          border: `1px solid ${shouldHighlight ? courseThemeContext.primary : borderColor}`,
          padding: "9px 18px",
          borderRadius: showArrowLeft ? "0 6px 6px 6px" : "6px 0 6px 6px",
        }}
      >
        {(dialogueCharacter === "STUDENT" || dialogueCharacter === "CHARACTER") && (
          <div
            style={{
              position: "absolute",
              left: dialogueCharacter === "STUDENT" ? "-12px" : "auto",
              right: dialogueCharacter === "CHARACTER" ? "-12px" : "auto",
              top: "-8px",
            }}
          >
            <PopoverButton
              isOpen={isTranslationOpen}
              position={dialogueCharacter === "STUDENT" ? "left-corner" : "right-corner"}
              positionOffset={35}
              content={<QuestionLabel fontSize={14}>{translation}</QuestionLabel>}
            >
              <CircularButton
                size="xs"
                icon={isTranslationOpen ? "close" : "crop"}
                color={GREY_5}
                disabled={isRecording || isSubmittingRecord}
                onClick={() => handleTranslationOpen(itemId)}
                style={{ zIndex: 1 }}
              />
            </PopoverButton>
          </div>
        )}
        <span style={{ fontSize: "16px", color: fontColor }}>{text}</span>
      </div>
    </div>
  );
}

DialogueMessage.propTypes = {
  itemId: PropTypes.string,
  text: PropTypes.string.isRequired,
  shouldHighlight: PropTypes.bool,
  dialogueCharacter: PropTypes.string.isRequired,
  handleTranslationOpen: PropTypes.func,
  isTranslationOpen: PropTypes.bool,
};

DialogueMessage.defaultProps = {
  shouldHighlight: false,
  isTranslationOpen: false,
  handleTranslationOpen: () => false,
  itemId: null,
};

DialogueMessage.contextTypes = {
  scrollArea: PropTypes.shape({
    scrollBottom: PropTypes.func,
    refresh: PropTypes.func,
  }),
};
