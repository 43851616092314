import omit from "lodash/omit";

import { SUBMIT_FORM } from "../actions/form";

export default (store) => (next) => (action) => {
  if (action.type && action.type === SUBMIT_FORM && !!store.getState().forms[action.payload.formId]) {
    const formValues = store.getState().forms[action.payload.formId].values;
    const errors =
      action.payload.validations &&
      Object.keys(action.payload.validations).reduce((acc, path) => {
        const errorFunc = action.payload.validations[path].find((validatorFn) =>
          validatorFn(formValues[path], formValues)
        );
        if (errorFunc) {
          return {
            ...acc,
            [path]: errorFunc(formValues[path], formValues),
          };
        } else {
          return acc;
        }
      }, {});

    return next({
      ...action,
      payload: {
        ...omit(action.payload, "validations"),
        errors,
      },
    });
  }
  return next(action);
};
