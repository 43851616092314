import React from "react";
import RecordButton from "./RecordButton";
import { END_RECORD_FLOW, START_RECORD_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function RecordButtonContainer() {
  const [isEnding, handleEndRecord] = useFlow(END_RECORD_FLOW);
  const [isStarting, handleStartRecord] = useFlow(START_RECORD_FLOW);

  const itemType = useSelector(getCurrentItemExecutionProp("item.type.key"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const isSubmittingRecord = useSelector(getCurrentItemExecutionProp("isSubmittingRecord"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const isRecordDisabled = () => {
    if (
      [
        "PRESENTATION",
        "PRONUNCIATION",
        "SPEECH_PRACTICE",
        "VIDEO_SHORT",
        "GAP_FILL",
        "UNSCRAMBLE_SPEECH_RECOGNITION",
        "DIALOGUE_OPTION",
        "VOCABULARY_ACADEMIC",
      ].some((type) => type === itemType)
    ) {
      return (
        isEnding ||
        isStarting ||
        isDisabled ||
        isSubmittingRecord ||
        isExecutionValidated ||
        (currentInstructionStep && currentInstructionStep !== "record")
      );
    }
    return true;
  };

  return (
    <RecordButton
      onClick={isRecording ? handleEndRecord : handleStartRecord}
      isLoading={isEnding || isStarting}
      isRecording={isRecording}
      isDisabled={isRecordDisabled()}
    />
  );
}

export default RecordButtonContainer;
