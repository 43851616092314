import { put, select, takeLatest } from "redux-saga/effects";
import orderBy from "lodash/orderBy";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import shuffle from "lodash/shuffle";
import {
  GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  MASTERY_TEST_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
} from "../consts";
import { addSentryUserAction, linkAnswers, logError } from "../util";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getNextMasteryTestItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW), function* () {
    try {
      const masteryTestExecutionForm = yield select(getForm(MASTERY_TEST_EXECUTION_FORM));
      const nextMasteryTestItem = masteryTestExecutionForm.values.masteryTestItems.find(
        (mtItem) => mtItem.order > masteryTestExecutionForm.values.currentOrder
      );

      const linkedAnswers = linkAnswers(nextMasteryTestItem.item.answers);

      yield put(
        initForm(ITEM_EXECUTION_FORM, {
          associativeItem: {
            ...nextMasteryTestItem,
            item: {
              ...nextMasteryTestItem.item,
              linkedAnswers: typesToOrderAnswers.find((type) => type === nextMasteryTestItem.item.type.key)
                ? orderBy(linkedAnswers, "text", "asc")
                : shuffle(linkedAnswers),
            },
          },
          isDisabled: false,
          answer: "",
          errorCount: 0,
          repeatCount: 0,
          recordCount: 0,
          listenCount: 0,
          readCount: 0,
          translateCount: 0,
          attempts: [],
          recordFile: null,
          isRecording: false,
          isSubmittingRecord: false,
          speechRecognitionResult: null,
          shouldCheckSpeechRecognitionAnswer: true,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true }));
    } catch (error) {
      logError({ error, flow: GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW));
    }
  });
}
