import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import parsePhoneNumber from "libphonenumber-js";
import MaskInput from "./MaskInput";
import Select from "./Select";
import { COUNTRIES } from "student-front-commons/src/consts";
import FlexRow from "../FlexRow";
import { toUpper, head, last } from "lodash";

function PhoneInput(props) {
  const [selectedCountry, setSelectedCountry] = useState("");

  const handleSelectChange = useCallback((code) => {
    props.onChange("");
    setSelectedCountry(code);
  }, [props.onChange]);

  useEffect(() => {
    if (props.value && props.value.startsWith("+")) {
      const parsedNumber = parsePhoneNumber(props.value);
      setSelectedCountry(parsedNumber.country);
    }
    if (!props.value) {
      setSelectedCountry(toUpper(last((navigator.language || head(navigator.languages)).split("-"))));
    }
  }, []);

  return (
    <FlexRow width="100%" justifyContent="space-between" gap={10}>
      <FlexRow minWidth={50} maxWidth={50}>
        <Select
          isDisabled={props.disabled}
          menuProps={{
            PaperProps: {
              style: {
                maxHeight: 300,
                width: 250,
              },
            },
          }}
          options={COUNTRIES.map((country) => ({
            value: country.code,
            label: (
              <FlexRow gap={20}>
                <img
                  style={{
                    minWidth: 30,
                    maxWidth: 30,
                    minHeight: 19,
                    maxHeight: 19,
                  }}
                  src={country.mini}
                  alt="country"
                />
                <span
                  style={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                >
                  {country.name}
                </span>
              </FlexRow>
            ),
          }))}
          value={selectedCountry}
          onChange={handleSelectChange}
          error={props.error}
        />
      </FlexRow>
      <MaskInput
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        error={props.error}
        label={props.label}
        maskType="phone"
        fullWidth={props.fullWidth}
        phoneRegionCode={selectedCountry}
      />
    </FlexRow>
  );
}

PhoneInput.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

PhoneInput.defaultProps = {
  value: null,
  error: null,
  fullWidth: false,
  disabled: false,
  onChange: null,
};

export default PhoneInput;
