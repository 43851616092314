import React, { Component } from "react";
import PropTypes from "prop-types";
import { getActivities } from "student-front-commons/src/services/activityService";
import FetchPageableData from "student-front-commons/src/components/FetchPageableData";
import Async from "../../core/Async";
import Paragraph from "../../core/Paragraph";
import TranslatedText from "../../core/TranslatedText";
import TimeLineList from "./TimeLineList";

class TimeLineListContainer extends Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
  };

  render() {
    return (
      <FetchPageableData
        service={getActivities}
        pageContentProperty="docs"
        page={this.props.page}
        size={25}
        params={{
          id: sessionStorage.getItem("id"),
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching && this.props.page === 1} color="dark" size="sm">
            {data && data.length ? (
              <TimeLineList activities={data} />
            ) : (
              <div
                style={{
                  padding: "20px 35px",
                }}
              >
                <TranslatedText as={Paragraph} translateKey="profile.timelineNoData" />
              </div>
            )}
          </Async>
        )}
      </FetchPageableData>
    );
  }
}

export default TimeLineListContainer;
