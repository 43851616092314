import React from "react";
import Modal from "../core/Modal";
import Separator from "../core/Separator";
import TranslatedText from "../core/TranslatedText";
import FlexRow from "../core/FlexRow";
import Icon from "../core/Icon";
import ColumnSeparator from "../core/ColumnSeparator";
import PropTypes from "prop-types";
import ButtonV2 from "../core/ButtonV2";

const ErrorModal = (props) => (
  <Modal>
    <FlexRow alignItems="center" justifyContent="center">
      {props.icon && (
        <React.Fragment>
          <Icon icon={props.icon} color="#607d8b" size="xl" />
          <ColumnSeparator size="md" />
        </React.Fragment>
      )}
      <TranslatedText translateKey={props.message} style={{ fontSize: 16, fontWeight: 600, cursor: "default" }} />
    </FlexRow>
    <Separator size="lg" />
    <ButtonV2 onClick={props.onClose} labelKey={props.button || "close"} intent="course-primary" size="md" />
  </Modal>
);

ErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.string,
  button: PropTypes.string,
};

export default ErrorModal;
