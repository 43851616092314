import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * start the execution of a certification test
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const startCertificationTestExecution = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `certification-tests/${payload.certificationTest}`,
        method: "post",
      });
    },
    "error_start_certification_test",
    "Unexpected error to start the Certification Test."
  );
};

/**
 * start the execution of a certification test
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 * @param {string} payload.ability - the certification test ability to test
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getCertificationTestItemsByAbility = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
      ability: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["READING", "LISTENING", "WRITING", "SPEAKING"],
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `certification-tests/${payload.certificationTest}/abilities/${payload.ability}/items`,
        method: "get",
      });
    },
    "error_list_ability_items",
    "Unexpected error to list ability items."
  );
};

/**
 * save the certification test item execution
 *
 * @param {!Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 * @param {string} payload.ability - the certification test ability to test
 * @param {!boolean} payload.item - the item executed
 * @param {!boolean} payload.correct - if answer is correct or not
 * @param {!number} payload.answer - the count of errors
 * @param {!blob} payload.audio - the last SR blob
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const saveCertificationTestItemExecution = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
      ability: {
        presence: {
          allowEmpty: false,
        },
        inclusion: ["READING", "LISTENING", "WRITING", "SPEAKING"],
      },
      item: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  if (payload.ability === "READING" || payload.ability === "LISTENING") {
    validate(
      {
        correct: {
          presence: {
            allowEmpty: false,
          },
        },
      },
      payload
    );
  }

  return safeCall(
    async () => {
      const formData = new FormData();

      formData.append("ability", payload.ability.toUpperCase());
      formData.append("item", payload.item);
      formData.append("answeredAt", new Date());
      if (payload.ability === "SPEAKING") {
        formData.append("audio", payload.answer);
      } else {
        formData.append("answer", payload.answer);
      }
      if (payload.ability === "READING" || payload.ability === "LISTENING") {
        formData.append("correct", payload.correct);
      }

      return await request({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `certification-tests/${payload.certificationTest}/answers`,
        method: "post",
        data: formData,
      });
    },
    "error_save_certification_test_answer",
    "Unexpected error to save the answer."
  );
};

/**
 * set the certification test as failed
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setCertificationTestAsFailed = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `certification-tests/${payload.certificationTest}/failed`,
        method: "put",
      });
    },
    "error_set_certification_test_to_failed",
    "Unexpected error to set Certification Test as failed."
  );
};

/**
 * set the certification test as finished and available for review
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setCertificationTestAsFinished = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `certification-tests/${payload.certificationTest}/finished`,
        method: "put",
      });
    },
    "error_finish_certification_test",
    "Unexpect error to finished the Certification Test."
  );
};

/**
 * set the certification test as finished and available for review
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.certificationTest - the certification test id
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const setCertificationTestAsViewed = async (payload) => {
  validate(
    {
      certificationTest: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(
    async () => {
      return await request({
        url: `certification-tests/${payload.certificationTest}/check-result`,
        method: "put",
      });
    },
    "error_set_certification_test_as_checked",
    "Unexpected error to check Certification Test result."
  );
};
