import React from "react";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import RadioInput from "../../core/form/RadioInput";
import withTranslation from "../../core/withTranslation";
import HtmlForm from "../../core/HtmlForm";
import { useEntity, useForm, useService } from "student-front-commons/src/hooks";
import { scheduleUnitReview } from "student-front-commons/src/services/scheduleUnitReviewService";
import { useParams } from "react-router-dom";
import UnitResultButton from "./UnitResultButton";
import PropTypes from "prop-types";
import FlexColumn from "../../core/FlexColumn";
import FlexRow from "../../core/FlexRow";
import ReviewImportanceLinkButton from "./ReviewImportanceLinkButton";
import { useDispatch, useSelector } from "react-redux";
import { mergeEntities, removeEntity } from "student-front-commons/src/actions/entity";
import { normalize } from "normalizr";
import scheduledUnitReviewSchema from "student-front-commons/src/schemas/scheduledUnitReviewSchema";
import MaterialTheme from "../../core/MaterialTheme";
import { deleteUnitReview } from "student-front-commons/src/services/deleteUnitReviewService";
import { getExecutionType, isExecutionApproved } from "student-front-commons/src/selectors/execution";
import { SCHEDULED_UNIT_REVIEW_SCHEMA } from "student-front-commons/src/schemas";

const UnitResultReviewStep = (props) => {
  const params = useParams();
  const dispatch = useDispatch();

  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const scheduledUnitReview = useEntity(SCHEDULED_UNIT_REVIEW_SCHEMA, params.idUnit);
  const isApproved = useSelector(isExecutionApproved);
  const executionType = useSelector(getExecutionType);

  const [isScheduling, schedule] = useService(scheduleUnitReview, {
    autoStart: false,
    onData: (data) => {
      const { entities } = normalize(data, scheduledUnitReviewSchema);
      dispatch(mergeEntities(entities));
      props.onClick();
    },
  });

  const [isDeleting, doDelete] = useService(deleteUnitReview, {
    autoStart: false,
    onData: () => {
      dispatch(removeEntity(SCHEDULED_UNIT_REVIEW_SCHEMA, scheduledUnitReview.id));
      props.onClick();
    },
  });

  const { getValue, setValue, submit } = useForm({
    initialValues: {
      reviewUnit: "",
    },
    onSubmit: (values) => {
      if (values.reviewUnit === "N") {
        if (executionType === "OPTIONAL_REVIEW") {
          doDelete({
            id: scheduledUnitReview.unitReview.id,
          });
        } else {
          props.onClick();
        }
      } else if (executionType === "DEFAULT") {
        schedule({
          module: params.idModule,
          unit: params.idUnit,
          execution: params.idExecution,
        });
      } else {
        props.onClick();
      }
    },
  });

  if (executionType === "DEFAULT") {
    return isApproved ? (
      <FlexColumn justifyContent="center" alignItems="center" maxWidth={380} textAlign="center" paddingTop="25px">
        <TranslatedText
          as="span"
          translateKey="unitResult.reviewStep.didWell.title1"
          style={{
            margin: 0,
            fontWeight: 600,
            fontSize: 20,
            color: "#475569",
            padding: "0px 20px",
          }}
        />
        <TranslatedText
          as="span"
          translateKey="unitResult.reviewStep.didWell.title2"
          style={{
            margin: 0,
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: "30px",
            color: "#475569",
            padding: "0px 20px",
          }}
        />
        <Separator size="xxs" />
        <Separator size="md" />
        <TranslatedText
          as="span"
          translateKey="unitResult.reviewStep.didWell.subtitle"
          style={{
            margin: 0,
            fontWeight: 400,
            lineHeight: "20px",
            fontSize: "16px",
            color: "#475569",
          }}
        />
        <Separator size="md" />
        <MaterialTheme>
          <HtmlForm onSubmit={submit}>
            <FlexRow width="100%" justifyContent="center">
              <RadioInput
                row={false}
                disabled={isScheduling}
                onChange={(value) => setValue("reviewUnit", value)}
                value={getValue("reviewUnit")}
                name=""
                showOptionsBorders
                optionsStyle={{
                  height: "64px",
                  width: "320px",
                  padding: "20px 28px",
                  borderRadius: "4px",
                  border: "1px solid #E2E8F0",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
                options={[
                  {
                    value: "S",
                    label: props.getTranslation("unitResult.reviewStep.didWell.button.yes"),
                  },
                  {
                    value: "N",
                    label: props.getTranslation("unitResult.reviewStep.didWell.button.no"),
                  },
                ]}
              />
            </FlexRow>
            <Separator size="xs" />
            <Separator size="md" />
            <ReviewImportanceLinkButton />
            <UnitResultButton
              labelKey={profile?.isSpeechRecognitionDisabled ? "unitResult.continueToUnitList" : "unitResult.next"}
              backgroundColor={!profile?.isSpeechRecognitionDisabled && "#285D8D"}
              isDisabled={isScheduling || !getValue("reviewUnit")}
              htmlType="submit"
            />
          </HtmlForm>
        </MaterialTheme>
      </FlexColumn>
    ) : (
      <FlexColumn justifyContent="center" alignItems="center" padding="25px 180px 0px 180px" textAlign="center">
        <TranslatedText
          as="span"
          translateKey="unitResult.reviewStep.notWell.description"
          style={{
            margin: 0,
            fontWeight: 600,
            fontSize: 20,
            color: "#475569",
          }}
        />
        <Separator size="lg" />
        <ReviewImportanceLinkButton />
        <div style={{width: "100%", height: "200px"}} />
        <UnitResultButton
          labelKey={profile?.isSpeechRecognitionDisabled ? "unitResult.continueToUnitList" : "unitResult.next"}
          backgroundColor={!profile?.isSpeechRecognitionDisabled && "#285D8D"}
          onClick={props.onClick}
          htmlType="button"
        />
      </FlexColumn>
    );
  }

  return executionType === "OPTIONAL_REVIEW" ? (
    <FlexColumn justifyContent="center" alignItems="center" maxWidth={380} textAlign="center" marginTop="25px">
      <TranslatedText
        as="span"
        translateKey="unitResult.reviewStep.optionalReview.title"
        style={{
          margin: 0,
          fontWeight: 600,
          fontSize: 20,
          color: "#475569",
          padding: "0px 20px",
        }}
      />
      <Separator size="lg" />
      <TranslatedText
        as="span"
        translateKey="unitResult.reviewStep.optionalReview.subtitle"
        style={{
          margin: 0,
          fontSize: 16,
          color: "#475569",
        }}
      />
      <Separator size="md" />
      <Separator size="sm" />
      <MaterialTheme>
        <HtmlForm onSubmit={submit}>
          <FlexRow width="100%" justifyContent="center">
            <RadioInput
              row={false}
              disabled={isScheduling || isDeleting}
              onChange={(value) => setValue("reviewUnit", value)}
              value={getValue("reviewUnit")}
              name=""
              showOptionsBorders
              optionsStyle={{
                height: "64px",
                width: "320px",
                padding: "20px 28px",
                borderRadius: "4px",
                border: "1px solid #E2E8F0",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                fontWeight: 600,
              }}
              options={[
                {
                  value: "S",
                  label: props.getTranslation("unitResult.reviewStep.optionalReview.button.yes"),
                },
                {
                  value: "N",
                  label: props.getTranslation("unitResult.reviewStep.optionalReview.button.no"),
                },
              ]}
            />
          </FlexRow>
          <div style={{width: "100%", height: "50px"}}/>
          <UnitResultButton
            labelKey={profile?.isSpeechRecognitionDisabled ? "unitResult.continueToUnitList" : "unitResult.next"}
            backgroundColor={!profile?.isSpeechRecognitionDisabled && "#285D8D"}
            isDisabled={isScheduling || isDeleting || !getValue("reviewUnit")}
            htmlType="submit"
          />
        </HtmlForm>
      </MaterialTheme>
    </FlexColumn>
  ) : (
    <FlexColumn justifyContent="center" alignItems="center" maxWidth={380} textAlign="center" marginTop="25px">
      <TranslatedText
        as="span"
        translateKey="unitResult.reviewStep.firstReview.title"
        style={{
          margin: 0,
          fontWeight: 600,
          fontSize: 20,
          color: "#475569",
          padding: "0px 20px",
        }}
      />
      <Separator size="lg" />
      <TranslatedText
        as="span"
        translateKey="unitResult.reviewStep.firstReview.subtitle"
        style={{
          margin: 0,
          fontSize: 16,
          color: "#475569",
        }}
      />
      <div style={{ width: "100%", height: "199px" }} />
      <MaterialTheme>
        <UnitResultButton
          labelKey={profile?.isSpeechRecognitionDisabled ? "unitResult.continueToUnitList" : "unitResult.next"}
          backgroundColor={!profile?.isSpeechRecognitionDisabled && "#285D8D"}
          onClick={() => props.onClick()}
        />
      </MaterialTheme>
    </FlexColumn>
  );
};

UnitResultReviewStep.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default withTranslation(UnitResultReviewStep);
