import React from "react";
import PropTypes from "prop-types";
import CourseButton from "../../core/CourseButton";
import { GREY_2 } from "../../consts/color";

const CoursesRulerListItem = (props) => (
  <li
    style={{
      position: "relative",
      textAlign: "center",
      width: "100%",
      minHeight: 110,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <label
      htmlFor="course"
      style={{
        color: props.active ? "rgba(0, 0, 0, .5)" : "rgba(0, 0, 0, .3)",
        position: "absolute",
        left: "-4.5px",
        fontSize: props.active ? 13 : 11,
        fontWeight: props.active ? 700 : 400,
        margin: 0,
      }}
    >
      <span
        style={{
          width: 11,
          height: 2,
          backgroundColor: GREY_2,
          display: "inline-block",
          marginRight: 5,
          verticalAlign: "middle",
        }}
      />
      {props.point}
    </label>
    {props.course && <CourseButton course={props.course} />}
  </li>
);

CoursesRulerListItem.propTypes = {
  point: PropTypes.number.isRequired,
  course: PropTypes.string,
  active: PropTypes.bool,
};

CoursesRulerListItem.defaultProps = {
  course: null,
  active: false,
};

export default CoursesRulerListItem;
