import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import LineChart from "../../core/LineChart";
import Async from "../../core/Async";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import withTranslation from "../../core/withTranslation";

const MonthPerformanceChart = (props) => (
  <WhitelabelContext>
    {({ primaryColor }) => (
      <Async fetching={props.isFetching} color="dark" size="sm">
        <LineChart
          data={props.data}
          labels={props.data.map((item) => item.date)}
          dataFormat={[
            {
              color: primaryColor,
              label: props.getTranslation("points"),
              yAxisID: "points",
              valueRender: (item) => item.points,
              tooltipRender: (args) => {
                if (args.yLabel) {
                  return ` ${moment(args.xLabel, "YYYY-MM").format("MMMM")}: ${args.yLabel} ${props.getTranslation(
                    "points"
                  )}`;
                }
                return props.getTranslation("profile.performance.noPointsMonthChartData", { month: args.xLabel });
              },
            },
            {
              color: "#9e9e9e",
              label: "hours",
              yAxisID: "hours",
              valueRender: (item) => item.hours,
              tooltipRender: (args) => {
                if (args.yLabel) {
                  return ` ${moment(args.xLabel, "YYYY-MM").format("MMMM")}: ${moment
                    .duration(args.yLabel, "hours")
                    .format("hh:mm", { trim: false })} ${props.getTranslation("hours")}`;
                }
                return props.getTranslation("profile.performance.noHoursMonthChartData", { month: args.xLabel });
              },
            },
          ]}
          yAxes={[
            {
              type: "linear",
              display: "true",
              position: "left",
              id: "points",
            },
            {
              type: "linear",
              display: "true",
              position: "right",
              id: "hours",
              ticks: {
                callback: (label) => {
                  return moment.duration(label, "hours").format("hh:mm", { trim: false });
                },
              },
              gridLines: {
                drawOnChartArea: false,
              },
            },
          ]}
        />
      </Async>
    )}
  </WhitelabelContext>
);

MonthPerformanceChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      points: PropTypes.number,
      hours: PropTypes.number,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default withTranslation(MonthPerformanceChart);
