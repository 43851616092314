import React, { Component } from "react";
import PropTypes from "prop-types";
import BodyPortal from "./BodyPortal";

export default class Tooltip extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  };

  static defaultProps = {
    position: "top",
    disabled: false,
  };

  state = {
    visible: false,
  };

  handleShow = () => {
    if (this.props.disabled) {
      return;
    }

    const boundaries = this.tooltipParent.getBoundingClientRect();
    boundaries.y += window.scrollY;
    this.setState(
      {
        visible: true,
        style: {},
      },
      () => {
        const tooltipBoundaries = this.tooltip.getBoundingClientRect();

        const style = {
          opacity: 1,
        };

        if (this.props.position === "top") {
          style.top = boundaries.y - tooltipBoundaries.height - 5;
          style.left = boundaries.x - tooltipBoundaries.width / 2 + boundaries.width / 2;
        }
        if (this.props.position === "bottom") {
          style.top = boundaries.y + tooltipBoundaries.height;
          style.left = boundaries.x - tooltipBoundaries.width / 2 + boundaries.width / 2;
        }
        if (this.props.position === "right") {
          style.top = boundaries.y - tooltipBoundaries.height / 2 + boundaries.height / 2;
          style.left = boundaries.x + boundaries.width;
        }

        this.setState({
          visible: true,
          style,
        });
      }
    );
  };

  handleHide = () => {
    this.setState({
      visible: false,
      style: undefined,
    });
  };

  render() {
    return (
      <span
        onMouseOver={this.handleShow}
        onMouseOut={this.handleHide}
        ref={(node) => {
          this.tooltipParent = node;
        }}
      >
        {this.props.children}
        {this.state.visible && (
          <BodyPortal>
            <div
              ref={(node) => {
                this.tooltip = node;
              }}
              className={`tooltip ${this.props.position} fade`}
              role="tooltip"
              style={{
                width: "max-content",
                position: "absolute",
                zIndex: 2000,
                top: this.state.style.top,
                left: this.state.style.left,
                opacity: this.state.style.opacity || 0,
              }}
            >
              <div className="tooltip-arrow" />
              <div className="tooltip-inner" dangerouslySetInnerHTML={{ __html: this.props.text }} />
            </div>
          </BodyPortal>
        )}
      </span>
    );
  }
}
