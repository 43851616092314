import React from "react";
import PropTypes from "prop-types";
import ReactJoyride, { EVENTS, STATUS } from "react-joyride";
import $ from "jquery";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import TranslatedText from "../../../core/TranslatedText";
import withTranslation from "../../../core/withTranslation";
import PracticeTestTourIcon from "./PracticeTestTourIcon";

class PracticeTestTour extends React.PureComponent {
  static propTypes = {
    screen: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
  };

  stepsByScreen = {
    SINGLE_CHOICE_IMAGE: [
      {
        target: ".selectRepeat",
        disableBeacon: true,
        showCloseButton: false,
        content: <TranslatedText translateKey="intro.steps.controlBar1" />,
      },
      {
        target: ".selectTime",
        content: <TranslatedText translateKey="intro.steps.controlBar2" />,
      },
      {
        target: ".selectExerciseSingleChoiceImage",
        content: <TranslatedText translateKey="intro.steps.singleChoiceImage" />,
      },
    ],
    GAP_FILL_SELECT: [
      {
        target: ".selectExerciseDropDown",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.gapFillSelect" />,
      },
    ],
    SINGLE_CHOICE_TEXT: [
      {
        target: ".selectExerciseSingleChoiceText",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.singleChoiceText" />,
      },
    ],
    SINGLE_CHOICE_AUDIO: [
      {
        target: ".selectExerciseSingleChoiceAudio",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.singleChoiceAudio" />,
      },
    ],
    GAP_FILL: [
      {
        target: ".selectTextExerciseGapFill",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.gapFill1" />,
      },
      {
        target: ".selectOptionExerciseGapFill",
        content: <TranslatedText translateKey="intro.steps.gapFill2" />,
      },
    ],
    UNSCRAMBLE_DRAG_AND_DROP: [
      {
        target: ".selectTextExerciseUnscrambleDragAndDrop",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.unscrambleDragDrop1" />,
      },
      {
        target: ".selectOptionExerciseUnscrambleDragAndDrop",
        content: <TranslatedText translateKey="intro.steps.unscrambleDragDrop2" />,
      },
    ],
    GAP_FILL_MULTIPLE: [
      {
        target: ".selectTextExerciseUnscrambleDragAndDrop",
        disableBeacon: true,
        content: <TranslatedText translateKey="intro.steps.gapFillMultiple1" />,
      },
      {
        target: ".selectOptionExerciseUnscrambleDragAndDrop",
        content: <TranslatedText translateKey="intro.steps.gapFillMultiple2" />,
      },
      {
        target: ".selectNext",
        content: <TranslatedText translateKey="intro.steps.controlBar3" />,
      },
    ],
  };

  componentWillUnmount() {
    $(".react-joyride__overlay").off("click");
  }

  handleEvents = () => {
    setTimeout(() => {
      $(".react-joyride__overlay").on("click", () => {
        $(".react-joyride__tooltip div > button")
          .addClass("animate__animated animate__bounce")
          .one("animationend webkitAnimationEnd oAnimationEnd", () => {
            $(".react-joyride__tooltip div > button").removeClass("animate__bounce");
          });
      });
    }, 0);
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor }) => (
          <ReactJoyride
            beaconComponent={PracticeTestTourIcon}
            steps={this.stepsByScreen[this.props.screen]}
            continuous={true}
            run={true}
            hideBackButton={true}
            disableOverlayClose={true}
            disableCloseOnEsc={true}
            showProgress={true}
            locale={{
              back: this.props.getTranslation("intro.back"),
              close: this.props.getTranslation("intro.done"),
              last: this.props.getTranslation("intro.gotIt"),
              next: this.props.getTranslation("intro.next"),
              skip: this.props.getTranslation("intro.skip"),
            }}
            callback={(event) => {
              this.handleEvents();
              if (event.status === STATUS.FINISHED && event.type === EVENTS.TOUR_END) {
                this.props.onFinish();
              }
            }}
            styles={{
              options: {
                primaryColor: primaryColor,
              },
              buttonClose: {
                display: "none",
              },
            }}
          />
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default withTranslation(PracticeTestTour);
