import React, { useEffect } from "react";
import PropTypes from "prop-types";
import FlexRow from "../FlexRow";
import Icon from "../Icon";
import TranslatedText from "../TranslatedText";
import { useFlow } from "student-front-commons/src/hooks";
import { END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW } from "../../consts";

function ContentVideoFinishTag(props) {
  const [, endExecution] = useFlow(END_CONTENT_VIDEO_UNIT_EXECUTION_FLOW);

  useEffect(() => {
    if (props.isCompleted) {
      endExecution();
    }
  }, [props.isCompleted]);
  return (
    <FlexRow
      width={155}
      padding="2px 10px"
      backgroundColor={props.isCompleted ? "#11AC71" : "#D9D9D9"}
      color={props.isCompleted ? "#fff" : "#636363"}
      borderRadius={100}
    >
      <FlexRow gap={10} alignItems="center">
        <Icon size="xxs" icon="check" color={props.isCompleted ? "#fff" : "#636363"} />
        <TranslatedText
          as="span"
          style={{
            fontSize: "13px",
          }}
          translateKey={
            props.isCompleted ? "contentVideo.execution.lessonCompleted" : "contentVideo.execution.incompleteLesson"
          }
        />
      </FlexRow>
    </FlexRow>
  );
}

ContentVideoFinishTag.propTypes = {
  isCompleted: PropTypes.bool.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default ContentVideoFinishTag;
