import React from "react";
import PropTypes from "prop-types";

const BreadcrumbBar = (props) => (
  <div
    id="breadcrumb-bar"
    style={{
      backgroundColor: "#f6f6f6",
      boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
      position: "relative",
      zIndex: 3,
      height: "40px",
    }}
  >
    <div
      className="container"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {props.children}
    </div>
  </div>
);

BreadcrumbBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BreadcrumbBar;
