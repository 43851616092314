import React, { useCallback } from "react";
import orderBy from "lodash/orderBy";
import Icon from "../Icon";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import { useFlow } from "student-front-commons/src/hooks";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import QuestionLabel from "../QuestionLabel";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

function TrueFalseRender(props) {
  const dispatch = useDispatch();
  const [, startCheckAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const text = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const answers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const handleSubmit = useCallback(
    (answer) => {
      dispatch(addItemExecutionAnswer(props.itemId, { answer }));
      startCheckAnswerFlow();
    },
    [dispatch, startCheckAnswerFlow, props.itemId]
  );

  return (
    <ExerciseItemPanel>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: 50,
        }}
      >
        <QuestionLabel text={text} />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          {orderBy(answers, "text", "desc").map((answer) => (
            <div
              key={`answer-${answer.id}`}
              style={{
                margin: "0px 7px",
              }}
            >
              <ExerciseButton disabled={isDisabled || !!currentInstructionStep} onClick={() => handleSubmit(answer)}>
                <Icon
                  size="lg"
                  icon={answer.text === "True" ? "thumb-up" : "thumb-down"}
                  color={answer.text === "True" ? "rgb(13, 150, 137)" : "rgb(232, 66, 66)"}
                />
              </ExerciseButton>
            </div>
          ))}
        </div>
      </div>
    </ExerciseItemPanel>
  );
}

export default function TrueFalseExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <TrueFalseRender key={id} itemId={id} />);
}
