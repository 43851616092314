import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Title from "../../core/Title";
import DateLabel from "../../core/DateLabel";
import Separator from "../../core/Separator";
import HistoryPositionBox from "./HistoryPositionBox";
import Row from "../../core/Row";
import Column from "../../core/Column";
import withTranslation from "../../core/withTranslation";
import CheckDistributorType from "../../core/CheckDistributorType";

const PositionHistory = (props) => (
  <div>
    <Row>
      <Column lgSize={12} mdSize={10} smSize={12} xsSize={12}>
        <Title>{props.getTranslation("ranking.positionHistory")}</Title>
      </Column>
      <Column lgSize={12} mdSize={2} smSize={12} xsSize={12}>
        <DateLabel date={props.date} format="MMMM/YY" color="rgba(96, 125, 139, 0.8)" size="xs" />
      </Column>
    </Row>
    <Separator size="xs" />
    <div className="d-sm-none d-md-flex">
      <Column>
        <Separator size="sm" />
      </Column>
    </div>
    <Row>
      <CheckDistributorType>
        {({ isB2C }) =>
          !isB2C && (
            <Column lgSize={12} mdSize={4} smSize={12} xsSize={12}>
              <HistoryPositionBox
                position={get(props.positions, "school", null)}
                title={props.getTranslation("school")}
              />
            </Column>
          )
        }
      </CheckDistributorType>
      <div className="d-md-none d-lg-flex">
        <Column>
          <Separator size="xs" />
        </Column>
      </div>
      <Column lgSize={12} mdSize={4} smSize={12} xsSize={12}>
        <HistoryPositionBox
          position={get(props.positions, "regional", null)}
          title={props.getTranslation("regional")}
        />
      </Column>
      <div className="d-md-none d-lg-flex">
        <Column>
          <Separator size="xs" />
        </Column>
      </div>
      <Column lgSize={12} mdSize={4} smSize={12} xsSize={12}>
        <HistoryPositionBox
          position={get(props.positions, "national", null)}
          title={props.getTranslation("national")}
        />
      </Column>
    </Row>
  </div>
);

PositionHistory.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  positions: PropTypes.shape({
    national: PropTypes.number,
    regional: PropTypes.number,
    school: PropTypes.number,
  }).isRequired,
};

export default withTranslation(PositionHistory);
