import React, { useState } from "react";
import { getCurrentRankingByLevel } from "student-front-commons/src/services/rankingService";
import Async from "../Async";
import Paragraph from "../Paragraph";
import TranslatedText from "../TranslatedText";
import RankingList from "./RankingList";
import useService from "student-front-commons/src/hooks/useService";

function NationalRankingListContainer() {
  const [data, setData] = useState([]);

  const [isFetching] = useService(getCurrentRankingByLevel, {
    autoStart: true,
    params: {
      id: sessionStorage.getItem("id"),
      size: 8,
      level: "national",
    },
    onData: setData,
  });

  return (
    <Async fetching={isFetching} size="sm" color="dark">
      {(data || []).length ? (
        <RankingList rankings={data} />
      ) : (
        <TranslatedText as={Paragraph} translateKey="ranking.noData" />
      )}
    </Async>
  );
}

export default NationalRankingListContainer;
