import { CLEAN_BONUS_TEST_RESULT, MERGE_BONUS_TEST_RESULT } from "../consts/actions";

export const requestMergeBonusTestResult = (bonusTestResult) => ({
  type: MERGE_BONUS_TEST_RESULT,
  payload: {
    bonusTestResult,
  },
});

export const requestCleanBonusTestResult = () => ({
  type: CLEAN_BONUS_TEST_RESULT,
});
