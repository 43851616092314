import { call, put, race, select, take, takeLeading } from "redux-saga/effects";
import sample from "lodash/sample";
import last from "lodash/last";
import get from "lodash/get";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import { saveMasteryTestItemExecution } from "student-front-commons/src/services/masteryTestExecutionService";
import {
  CLOSE_MASTERY_TEST_EXECUTION_FLOW,
  DISMISS_SYSTEM_MESSAGE_FLOW,
  GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  MASTERY_TEST_EXECUTION_FORM,
  SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import browserHistory from "../browserHistory";
import { playAudio } from "../stores/audio-store";
import { addSentryUserAction, logError } from "../util";
import { showMessage } from "student-front-commons/src/actions/systemMessage";

const typesWithExtraAttempts = ["PRONUNCIATION", "SPEECH_PRACTICE", "UNSCRAMBLE_SPEECH_RECOGNITION"];
const sentryUserAction = { mainComponent: "endMasteryTestExecutionFlow" };

export default function* () {
  yield takeLeading(getFlowStart(SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_MASTERY_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          let itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));

          if (
            !["SPEECH_PRACTICE", "PRONUNCIATION"].find(
              (type) => type === itemExecutionForm.values.associativeItem.item.type.key
            )
          ) {
            const answerResult = yield call(checkAnswer, {
              item: itemExecutionForm.values.associativeItem.item,
              answer: itemExecutionForm.values.answer,
            });

            const currentAnswer = { answer: answerResult.answer, correct: answerResult.status === "CORRECT" };
            yield put(
              changeFormValue(ITEM_EXECUTION_FORM, "attempts", [...itemExecutionForm.values.attempts, currentAnswer])
            );
            if (!currentAnswer.correct) {
              const errorCount = itemExecutionForm.values.errorCount + 1;
              yield put(changeFormValue(ITEM_EXECUTION_FORM, "errorCount", errorCount));
            }
          }

          itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          if (
            typesWithExtraAttempts.find((type) => type === itemExecutionForm.values.associativeItem.item.type.key) &&
            !get(last(itemExecutionForm.values.attempts), "correct", false) &&
            itemExecutionForm.values.errorCount < 3
          ) {
            const firstTryAudios = yield select((state) => state.configurations.firstTryAudios);
            const randomAudio = sample(firstTryAudios);
            yield call(playAudio, {
              url: randomAudio.path || randomAudio.generatedAudio,
              rate: 1,
            });

            addSentryUserAction({
              ...sentryUserAction,
              clickedComponent: "None",
              action: `Incorrect answer on Mastery Test Item ${itemExecutionForm.values.associativeItem.item.id}. Error count: ${itemExecutionForm.values.errorCount}`,
            });

            return true;
          }

          let masteryTestExecutionForm = yield select(getForm(MASTERY_TEST_EXECUTION_FORM));
          const answer = yield call(saveMasteryTestItemExecution, {
            module: masteryTestExecutionForm.values.module,
            masteryTest: masteryTestExecutionForm.values.masteryTest,
            masteryTestExecution: masteryTestExecutionForm.values.execution,
            item: itemExecutionForm.values.associativeItem.item.id,
            correct: get(last(itemExecutionForm.values.attempts), "correct", false),
            errorCount: itemExecutionForm.values.errorCount,
            repeatCount: itemExecutionForm.values.repeatCount,
            recordCount: itemExecutionForm.values.recordCount,
            listenCount: itemExecutionForm.values.listenCount,
            attempts: itemExecutionForm.values.attempts,
            audio: itemExecutionForm.values.recordFile,
          });

          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Save Mastery Test Item Answer`,
          });

          yield put(
            changeFormValue(MASTERY_TEST_EXECUTION_FORM, "answers", [
              ...masteryTestExecutionForm.values.answers,
              answer.id,
            ])
          );
          yield put(
            changeFormValue(
              MASTERY_TEST_EXECUTION_FORM,
              "currentOrder",
              masteryTestExecutionForm.values.currentOrder + 1
            )
          );

          masteryTestExecutionForm = yield select(getForm(MASTERY_TEST_EXECUTION_FORM));
          if (
            masteryTestExecutionForm.values.answers.length >= masteryTestExecutionForm.values.masteryTestItems.length
          ) {
            browserHistory.replace(
              `${browserHistory.location.pathname}/${masteryTestExecutionForm.values.execution}/result`
            );
            addSentryUserAction({
              ...sentryUserAction,
              clickedComponent: "None",
              action: `Mastery Test finished. Navigate to: ${browserHistory.location.pathname}/${masteryTestExecutionForm.values.execution}/result`,
            });
          } else {
            yield put(startFlow(GET_NEXT_MASTERY_TEST_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({ error, flow: SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW });

          yield put(
            showMessage({
              message: "error.error_save_answer",
            })
          );
          //await the user close the modal, so the timer will be paused
          yield take(getFlowStart(DISMISS_SYSTEM_MESSAGE_FLOW));
        } finally {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
          yield put(endFlow(SAVE_MASTERY_TEST_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
