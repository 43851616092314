import get from "lodash/get";

/**
 * @class ApiError
 */
export default class ApiError extends Error {
  constructor(error) {
    const message = get(error.response, "data.message", "Unexpected Error");
    const code = get(error.response, "data.error", "unexpected_error");

    super(message, code);

    this.message = message;
    this.code = code;
    this.axiosError = error;

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}
