import configurationReducer from "./configuration";
import formReducer from "./form";
import flowReducer from "./flow";
import entityReducer from "./entity";
import executionReducer from "./execution";
import executionAchievementReducer from "./executionAchievement";
import itemExecutionReducer from "./itemExecution";
import itemInstructionReducer from "./itemInstruction";
import systemMessageReducer from "./systemMessage";

export default {
  configurations: configurationReducer,
  forms: formReducer,
  flows: flowReducer,
  entities: entityReducer,
  executions: executionReducer,
  itemExecutions: itemExecutionReducer,
  itemInstructions: itemInstructionReducer,
  executionAchievements: executionAchievementReducer,
  systemMessages: systemMessageReducer,
};
