import orderBy from "lodash/orderBy";
import shuffle from "lodash/shuffle";
import { put, select, spawn, take, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue, initForm } from "student-front-commons/src/actions/form";
import { getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { addSentryUserAction, linkAnswers, logError } from "../util";
import {
  CERTIFICATION_TEST_ABILITY_EXECUTION_FORM,
  GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
  SYSTEM_MESSAGE_FLOW,
} from "../consts";
import { playAudio } from "../stores/audio-store";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getNextCertificationTestAbilityItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW), function* () {
    try {
      const certificationTestExecutionForm = yield select(getForm(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM));

      const nextIndex = certificationTestExecutionForm.values.currentIndex + 1;
      const nextCertificationTestItem = certificationTestExecutionForm.values.certificationTestItems[nextIndex];

      const linkedAnswers = linkAnswers(nextCertificationTestItem.item.answers);
      yield put(
        initForm(ITEM_EXECUTION_FORM, {
          associativeItem: {
            ...nextCertificationTestItem,
            item: {
              ...nextCertificationTestItem.item,
              linkedAnswers: typesToOrderAnswers.find((type) => type === nextCertificationTestItem.item.type.key)
                ? orderBy(linkedAnswers, "text", "asc")
                : shuffle(linkedAnswers),
            },
          },
          startedAt: new Date(),
          isDisabled: true,
          answer: "",
          repeatCount: 0,
          timeToAutoEndRecord:
            certificationTestExecutionForm.values.ability === "SPEAKING" ? nextCertificationTestItem.item.time : null,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      if (
        ["SPEAKING"].some((type) => type === certificationTestExecutionForm.values.ability) &&
        !certificationTestExecutionForm.values.playedInstructions.some(
          (type) => type === nextCertificationTestItem.item.type.key
        )
      ) {
        yield put(
          changeFormValue(CERTIFICATION_TEST_ABILITY_EXECUTION_FORM, "playedInstructions", [
            ...certificationTestExecutionForm.values.playedInstructions,
            nextCertificationTestItem.item.type.key,
          ])
        );
        yield put(
          startFlow(SYSTEM_MESSAGE_FLOW, {
            message: `certificationTest.help.${nextCertificationTestItem.item.type.key.toLowerCase()}`,
            button: "certificationTest.help.gotIt",
          })
        );
        yield spawn(playAudio, {
          url: nextCertificationTestItem.item.type.initialInstructionSound,
          isCompleteUrl: false,
          rate: 1,
        });

        yield take(getFlowEnd(SYSTEM_MESSAGE_FLOW));
      }

      if (certificationTestExecutionForm.values.ability === "LISTENING") {
        yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true }));
      } else {
        yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", false));
      }
    } catch (error) {
      logError({ error, flow: GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_CERTIFICATION_TEST_ABILITY_ITEM_EXECUTION_FLOW));
    }
  });
}
