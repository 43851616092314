import { START_FLOW, END_FLOW } from "../actions/flow";

export default (state = {}, action) => {
  switch (action.type) {
    case START_FLOW:
      return {
        ...state,
        [action.payload.flowId]: {
          isPending: true,
          ...(action.payload.params && {
            params: action.payload.params,
          }),
        },
      };
    case END_FLOW:
      return {
        ...state,
        [action.payload.flowId]: {
          isPending: false,
        },
      };
    default:
      return state;
  }
};
