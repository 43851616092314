import React, { Component } from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import UnitContent from "./UnitContent";
import ExecutionProgressContainer from "./ExecutionProgressContainer";
import TextReaderContainer from "./TextReaderContainer";
import UserAwayModalContainer from "../../error/UserAwayModalContainer";
import CloseUnitExecutionContainer from "./CloseUnitExecutionContainer";
import UnitItemExecution from "./UnitItemExecution";
import UnitExecutionControlBar from "./UnitExecutionControlBar";
import ExerciseHelpText from "./ExerciseHelpText";
import SpeechRecognitionResult from "../../core/speech-recognition/SpeechRecognitionResult";
import UnitSubHeader from "./UnitSubHeader";
import UnitExecutionToastList from "./UnitExecutionToastList";
import StartTastingModal from "./StartTastingModal";
import ErrorModalContainer from "../../error/ErrorModalContainer";

class UnitExecutionScene extends Component {
  static propTypes = {
    isPending: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <div>
        <StartTastingModal />
        <UnitSubHeader />
        <ExerciseHelpText />
        <ExecutionProgressContainer />
        {!this.props.isPending && (
          <UnitContent>
            <UserAwayModalContainer />
            <CloseUnitExecutionContainer />
            <Separator />
            <UnitItemExecution />
            <UnitExecutionControlBar />
            <TextReaderContainer />
            <SpeechRecognitionResult />
            <UnitExecutionToastList />
          </UnitContent>
        )}
        <ErrorModalContainer />
      </div>
    );
  }
}

export default UnitExecutionScene;
