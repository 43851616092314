import React from "react";
import { withRouter } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";
import MasteryTestSubHeader from "./MasteryTestSubHeader";

const MasteryTestSubHeaderContainer = (props) => (
  <Entity name="masteryTest">
    {({ getById }) => <MasteryTestSubHeader masteryTest={getById(props.match.params.idMasteryTest)} />}
  </Entity>
);

export default withRouter(MasteryTestSubHeaderContainer);
