import React, { useContext, useMemo } from "react";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import FlexRow from "../../core/FlexRow";
import CourseThemeContext from "../../core/CourseThemeContext";
import ColumnSeparator from "../../core/ColumnSeparator";
import FlexColumn from "../../core/FlexColumn";
import Tooltip from "../../core/Tooltip";
import Icon from "../../core/Icon";
import { useEntity } from "student-front-commons/src/hooks";
import { UNIT_TYPE_SCHEMA } from "student-front-commons/src/schemas";
import PropTypes from "prop-types";
import withTranslation from "../../core/withTranslation";

function UnitTypeAbilities({ type, unit, getTranslation, isDisabled, isReview }) {
  const { primary } = useContext(CourseThemeContext);
  const unitType = useEntity(UNIT_TYPE_SCHEMA, type);

  const color = useMemo(() => {
    if (isDisabled) {
      return "#979797";
    }
    if (isReview) {
      return "#5923C9";
    }
    return primary;
  }, [primary, isDisabled, isReview]);

  const backgroundColor = useMemo(() => {
    if (isDisabled) {
      return "#ECECEC";
    }
    return `${color}1A`;
  }, [isDisabled, color]);

  return (
    <FlexColumn minWidth={122} padding="0px 15px" alignItems="center">
      <TranslatedText
        as="label"
        translateKey="unit.abilities"
        style={{
          textTransform: "uppercase",
          fontSize: 11,
          color: "#727272",
          margin: 0,
        }}
      />
      <Separator size="sm" />
      <FlexRow>
        {unitType.abilities
          .sort((x, y) => {
            const values = { LISTENING: 4, SPEAKING: 3, READING: 2, WRITING: 1 };
            return values[x] < values[y];
          })
          .map((ability, index) => (
            <React.Fragment key={`${unit}-${ability}`}>
              {!!index && <ColumnSeparator size="xxs" />}
              <FlexColumn
                width={30}
                height={30}
                borderRadius={27.6}
                backgroundColor={backgroundColor}
                justifyContent="center"
                alignItems="center"
              >
                <Tooltip text={getTranslation(ability.toLowerCase())}>
                  <Icon
                    icon={
                      {
                        READING: "book-open-variant",
                        SPEAKING: "forum",
                        LISTENING: "ear-hearing",
                        WRITING: "lead-pencil",
                      }[ability]
                    }
                    color={color}
                    size="xs"
                  />
                </Tooltip>
              </FlexColumn>
            </React.Fragment>
          ))}
      </FlexRow>
    </FlexColumn>
  );
}

UnitTypeAbilities.propTypes = {
  unit: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isReview: PropTypes.bool,
};

export default withTranslation(UnitTypeAbilities);
