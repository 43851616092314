import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Async from "../../../core/Async";
import Flow from "student-front-commons/src/components/Flow";
import { START_MASTERY_TEST_TASTING_EXECUTION_FLOW } from "../../../consts";
import queryString from "query-string";
import MasteryTestTastingExecutionScene from "./MasteryTestTastingExecutionScene";

class MasteryTestTastingExecutionSceneContainer extends Component {
  render() {
    return (
      <Flow
        autoStart
        id={START_MASTERY_TEST_TASTING_EXECUTION_FLOW}
        params={{
          module: this.props.match.params.idModule,
          masteryTest: this.props.match.params.idMasteryTest,
          ...queryString.parse(this.props.location.search),
        }}
      >
        {({ isPending }) => (
          <Async fetching={isPending}>
            <MasteryTestTastingExecutionScene />
          </Async>
        )}
      </Flow>
    );
  }
}

export default withRouter(MasteryTestTastingExecutionSceneContainer);
