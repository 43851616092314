import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useEntityList } from "student-front-commons/src/hooks";
import { useParams, withRouter } from "react-router-dom";
import FlexRow from "../../core/FlexRow";
import Icon from "../../core/Icon";
import FlexColumn from "../../core/FlexColumn";
import TranslatedText from "../../core/TranslatedText";
import MasteryTestListItemStatusLabel from "./MasteryTestListItemStatusLabel";
import MasteryTestListItemFailedStats from "./MasteryTestListItemFailedStats";
import MasteryTestListItemLockedStats from "./MasteryTestListItemLockedStats";
import MasteryTestListItemBackground from "./MasteryTestListItemBackground";
import MasteryTestButton from "./MasteryTestButton";
import Separator from "../../core/Separator";
import { addSentryUserAction } from "../../util";
import MasteryTestListItemRecommendedUnits from "./MasteryTestListItemRecommendedUnitsList";
import { MASTERY_TEST_SCHEMA } from "student-front-commons/src/schemas";
import MasteryTestListItemConfirmExecutionModal from "./MasteryTestListItemConfirmExecutionModal";

const MasteryTestListItem = ({ group, history }) => {
  const { idModule } = useParams();
  const masteryTests = useEntityList(MASTERY_TEST_SCHEMA);
  const [expanded, setExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const masteryTest = useMemo(() => {
    return masteryTests.filter((mt) => mt.module === idModule).find((mt) => mt.group === group);
  }, [masteryTests, idModule, group]);

  const handleClick = useCallback(() => {
    if (!masteryTest?.id) {
      return;
    }

    history.push(
      `${history.location.pathname.replace(/\/[0-9a-fA-F]{24}\/units/, "")}/${masteryTest.module}/mastery-test/${
        masteryTest.id
      }/execution`
    );
    addSentryUserAction({
      mainComponent: "MasteryTestListItem",
      clickedComponent: "MasteryTestButton",
      action: `Navigate to ${history.location.pathname.replace(/\/[0-9a-fA-F]{24}\/units/, "")}/${
        masteryTest.module
      }/mastery-test/${masteryTest.id}/execution`,
    });
  }, [masteryTest?.id, masteryTest?.module, history]);

  const handleToggleExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  if (!masteryTest?.id) {
    return null;
  }

  return (
    <FlexColumn>
      <FlexRow
        position="relative"
        overflow="hidden"
        padding="20px 15px"
        borderRadius={5}
        borderBottomLeftRadius={expanded ? 0 : 5}
        borderBottomRightRadius={expanded ? 0 : 5}
        gap={20}
        backgroundColor={
          {
            UNLOCKED: "#FF9F51",
            LOCKED: "#A7A7A7",
            APPROVED: "#0FB1A2",
            FAILED: "#de5a67",
          }[masteryTest.status]
        }
        boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
      >
        <FlexRow flexBasis="10%">
          <FlexRow
            justifyContent="center"
            alignItems="center"
            width={80}
            height={80}
            borderRadius={80}
            backgroundColor="#ffffff33"
          >
            <Icon color="white" icon="file-document" size="lg" />
          </FlexRow>
        </FlexRow>
        <FlexColumn flexBasis="60%" justifyContent="center">
          <TranslatedText
            as="label"
            translateKey="masteryTest.name"
            style={{
              color: "#FFF",
              fontSize: 16,
              margin: 0,
              fontWeight: 700,
            }}
          />
          {masteryTest.status !== "UNLOCKED" && (
            <MasteryTestListItemStatusLabel color={masteryTest.status === "LOCKED" ? "#6A6D6F" : "white"}>
              {masteryTest.status}
            </MasteryTestListItemStatusLabel>
          )}
          {masteryTest.status === "LOCKED" && <MasteryTestListItemLockedStats module={idModule} group={group} />}
          {masteryTest.status === "UNLOCKED" && (
            <>
              <Separator size="xxs" /> <MasteryTestButton onClick={() => setIsModalOpen(true)} />
            </>
          )}
          {["APPROVED", "FAILED"].includes(masteryTest.status) && (
            <TranslatedText
              as="span"
              translateKey="masteryTest.score"
              style={{
                fontSize: 12,
                textTransform: "uppercase",
                color: "rgba(255, 255, 255, .8)",
              }}
              values={{ score: masteryTest.score }}
            />
          )}
        </FlexColumn>
        <FlexColumn flexBasis="20%" alignItems="center" justifyContent="center" gap={10}>
          {masteryTest.status === "FAILED" && (
            <>
              <TranslatedText
                as="label"
                translateKey="error.tryAgain"
                style={{
                  color: "#FFF",
                  fontSize: 15,
                  margin: 0,
                  fontWeight: 500,
                  textTransform: "uppercase",
                }}
              />
              <FlexRow
                backgroundColor="rgba(255, 255, 255, 0.3)"
                width={80}
                height={40}
                borderRadius={60}
                justifyContent="center"
                alignItems="center"
              >
                <MasteryTestListItemFailedStats module={idModule} failedAt={masteryTest.failedAt} />
              </FlexRow>
            </>
          )}
        </FlexColumn>
        <FlexRow flexBasis="10%" justifyContent="center" alignItems="center">
          {masteryTest.status === "FAILED" && (
            <Icon
              color="white"
              icon={expanded ? "chevron-up" : "chevron-down"}
              size="lg"
              cursor="pointer"
              onClick={handleToggleExpand}
            />
          )}
        </FlexRow>
        {masteryTest.status !== "FAILED" && (
          <MasteryTestListItemBackground
            color="rgba(255,255,255,0.3)"
            icon={
              {
                UNLOCKED: "lock-open-variant-outline",
                LOCKED: "lock-outline",
                FAILED: "lock-outline",
                APPROVED: "check-circle-outline",
              }[masteryTest.status]
            }
          />
        )}
      </FlexRow>
      {masteryTest.status === "FAILED" && (
        <MasteryTestListItemRecommendedUnits expanded={expanded} masteryTest={masteryTest} />
      )}
      {isModalOpen && (
        <MasteryTestListItemConfirmExecutionModal
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onConfirm={handleClick}
        />
      )}
    </FlexColumn>
  );
};

MasteryTestListItem.propTypes = {
  group: PropTypes.string.isRequired,
};

export default withRouter(MasteryTestListItem);
