import React from "react";
import FlexColumn from "../FlexColumn";
import CompleteProfileFormContainer from "./CompleteProfileFormContainer";
import SystemMessageContainer from "../SystemMessageContainer";
import Separator from "../Separator";

const CompleteProfileCard = () => (
  <FlexColumn width={640}>
    <FlexColumn
      flex={1}
      backgroundColor="#fff"
      padding={40}
      borderRadius={5}
      boxShadow="0px 2px 10px rgba(0, 0, 0, 0.1)"
    >
      <CompleteProfileFormContainer />
    </FlexColumn>
    <Separator size="md" />
    <SystemMessageContainer />
  </FlexColumn>
);

export default CompleteProfileCard;
