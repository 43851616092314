import { first, get, groupBy, head, min, orderBy } from "lodash";
import {
  COMPANY_SCHEMA,
  COURSE_SCHEMA,
  MASTERY_TEST_SCHEMA,
  MODULE_SCHEMA,
  SCHEDULED_UNIT_REVIEW_SCHEMA,
  UNIT_SCHEMA,
} from "../schemas";

export const getEntityById = (entity, id) => (state) => state.entities[entity]?.byId[id];

export const getCoursePercentageProgress = (id) => (state) => {
  if (get(state.entities, "module.allIds", []).length === 0) {
    return 0;
  }
  const modules = state.entities.module.allIds
    .map((id) => get(state.entities.module.byId, id, undefined))
    .filter((module) => module.course === id);

  const conqueredPoints = modules.reduce(
    (total, module) =>
      total +
      min([module.conqueredReadingPoints, module.readingPoints]) +
      min([module.conqueredListeningPoints, module.listeningPoints]) +
      min([module.conqueredSpeakingPoints, module.speakingPoints]) +
      min([module.conqueredWritingPoints, module.writingPoints]),
    0
  );

  const availablePoints = modules.reduce(
    (total, module) =>
      total + module.readingPoints + module.listeningPoints + module.speakingPoints + module.writingPoints,
    0
  );

  if (availablePoints) {
    return (conqueredPoints / availablePoints) * 100;
  }
  return 0;
};

export const getMasteryTestsByCourseId = (id) => (state) => {
  const masteryTestsIds = state.entities.masteryTest?.allIds;
  return orderBy(
    masteryTestsIds
      .map((id) => get(state.entities.masteryTest?.byId, id, undefined))
      .filter((masteryTest) => get(state.entities.module?.byId, masteryTest.module, undefined)?.course === id)
      .map((masteryTest) => ({
        ...masteryTest,
        module: get(state.entities.module?.byId, masteryTest.module, {}),
      })),
    ["module.group", "module.order", "modulePercentageToActive"]
  );
};

export const getModulesByCourseId = (id) => (state) => {
  const moduleIds = state.entities[MODULE_SCHEMA]?.allIds;
  return orderBy(
    moduleIds
      .map((id) => get(state.entities[MODULE_SCHEMA]?.byId, id, undefined))
      .filter((module) => module && module.course === id),
    ["module.group", "module.order"]
  );
};

export const getModuleIdsByCourseId = (courseId) => (state) => {
  const moduleIds = state.entities[MODULE_SCHEMA]?.allIds;
  if (!moduleIds) {
    return [];
  }
  return orderBy(
    moduleIds.filter((moduleId) => get(state.entities[MODULE_SCHEMA]?.byId, moduleId, {})?.course === courseId),
    ["module.group", "module.order"]
  );
};

export const getUnitIdsByModuleId = (moduleId) => (state) => {
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return [];
  }
  return orderBy(
    unitIds.filter((unitId) => get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.module === moduleId),
    ["module.group", "module.order"]
  );
};

export const getContentVideoUnitsByModuleId = (moduleId) => (state) => {
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return [];
  }
  return orderBy(
    unitIds
      .filter(
        (unitId) =>
          get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.module === moduleId &&
          get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.isContentVideo
      )
      .map((id) => get(state.entities[UNIT_SCHEMA]["byId"], id, undefined)),
    ["group", "order", "availableAt"]
  );
};

export const isAnyContentVideoUnitByCourseId = (courseId) => (state) => {
  const moduleIds = state.entities[MODULE_SCHEMA]?.allIds;
  if (!moduleIds) {
    return false;
  }
  const courseModules = moduleIds.filter(
    (moduleId) => get(state.entities[MODULE_SCHEMA]?.byId, moduleId, {})?.course === courseId
  );

  if (!courseModules.length) {
    return false;
  }

  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return false;
  }

  return unitIds.some(
    (unitId) =>
      courseModules.includes(get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.module) &&
      get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.isContentVideo
  );
};

export const getUnitSectionIdsByModuleId = (moduleId) => (state) => {
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;

  if (!unitIds) {
    return [];
  }

  const units = unitIds
    .filter((unitId) => get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.module === moduleId)
    .map((unitId) => get(state.entities[UNIT_SCHEMA]?.byId, unitId, {}));

  const scheduledUnitReviews = (state.entities[SCHEDULED_UNIT_REVIEW_SCHEMA]?.allIds || [])
    .filter((unitId) => get(state.entities[SCHEDULED_UNIT_REVIEW_SCHEMA]?.byId, unitId, {})?.module === moduleId)
    .map((unitId) => get(state.entities[SCHEDULED_UNIT_REVIEW_SCHEMA]?.byId, unitId, {}));

  return Object.entries(
    groupBy(
      [
        ...orderBy(units, ["group", "order", "availableAt"]),
        ...orderBy(scheduledUnitReviews, ["group", "order", "unitReview.availableAt"]),
      ],
      "group"
    )
  ).map(([group, units]) => {
    return {
      group,
      data: units,
    };
  });
};

export const getContentVideoSectionIdsByCourseId = (courseId) => (state) => {
  const moduleIds = state.entities[MODULE_SCHEMA]?.allIds;
  if (!moduleIds) {
    return [];
  }
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return [];
  }

  const courseModules = orderBy(
    moduleIds
      .filter((moduleId) => get(state.entities[MODULE_SCHEMA]?.byId, moduleId, {})?.course === courseId)
      .map((moduleId) => get(state.entities[MODULE_SCHEMA]?.byId, moduleId, {})),
    ["group", "order"]
  );

  return courseModules
    .map((module) => ({
      moduleId: module.id,
      data: orderBy(
        unitIds
          .filter(
            (unitId) =>
              get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.module === module.id &&
              get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})?.isContentVideo
          )
          .map((unitId) => get(state.entities[UNIT_SCHEMA]?.byId, unitId, {})),
        ["group", "order"]
      ).map((unit) => unit.id),
    }))
    .filter((section) => section.data.length);
};

export const getMasteryTestIdsByModuleId = (moduleId) => (state) => {
  const masteryIds = state.entities[MASTERY_TEST_SCHEMA]?.allIds;
  if (!masteryIds) {
    return [];
  }
  return masteryIds.filter((mtId) => get(state.entities[MASTERY_TEST_SCHEMA]?.byId, mtId, {})?.module === moduleId);
};

export const getMasteryTestByModuleIdAndGroup = (moduleId, group) => (state) => {
  const masteryIds = state.entities[MASTERY_TEST_SCHEMA]?.allIds;
  if (!masteryIds) {
    return [];
  }
  const mtId = masteryIds.find((mtId) => {
    const mt = get(state.entities[MASTERY_TEST_SCHEMA]?.byId, mtId, {});
    return mt?.module === moduleId && mt.group === group;
  });

  return get(state.entities[MASTERY_TEST_SCHEMA]?.byId, mtId, undefined);
};

export const getMostRecentMasteryTestFailedDateByModuleId = (moduleId) => (state) => {
  const masteryIds = state.entities[MASTERY_TEST_SCHEMA]?.allIds;
  if (!masteryIds) {
    return [];
  }
  return first(
    orderBy(
      masteryIds
        .filter((mtId) => {
          const mt = get(state.entities[MASTERY_TEST_SCHEMA]?.byId, mtId, {});
          return mt?.module === moduleId && !!mt.failedAt;
        })
        .map((mtId) => get(state.entities[MASTERY_TEST_SCHEMA]?.byId, mtId, {})),
      ["failedAt"],
      ["desc"]
    )
  )?.failedAt;
};

export const getUnitsByGrammarsIdsAndModuleIdAndGroup = (grammarsIds, moduleId, group) => (state) => {
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return [];
  }

  const units = unitIds.map((unitId) => state.entities[UNIT_SCHEMA]?.byId[unitId]);

  return grammarsIds.map((grammarId) => {
    return {
      grammarId,
      units: units.filter(
        (unit) => unit.grammars && unit.module === moduleId && unit.group === group && unit.grammars.includes(grammarId)
      ),
    };
  });
};

export const getRecommendedUnitIdsByGrammarsIdsAndModuleId = (grammarsIds, moduleId) => (state) => {
  const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
  if (!unitIds) {
    return [];
  }

  const unitsForGrammars = unitIds
    .map((unitId) => state.entities[UNIT_SCHEMA]?.byId[unitId])
    .filter(
      (unit) => !unit.isContentVideo && unit.module === moduleId && unit.grammars.some((g) => grammarsIds.includes(g))
    )
    .map((unit) => ({
      ...unit,
      statusOrder: {
        YOUR_CHALLENGE: 1,
        FIRST_REVIEW: 2,
        SECOND_REVIEW: 3,
        SIMPLE_REVIEW: 4,
        LOCKED: 5,
      }[unit.status],
      statusGroup: unit.disabled ? 2 : 1,
    }));

  return orderBy(unitsForGrammars, ["statusOrder", "statusGroup", "lastExecutionCompletedAt"], ["asc", "asc", "asc"])
    .slice(0, 3)
    .map((u) => u.id);
};

export const getRecommendedContentVideoByGrammarsIdsAndModuleIdAndCourseId =
  (grammarsIds, moduleId, courseId, isContentVideoTabAllowed) => (state) => {
    const unitIds = state.entities[UNIT_SCHEMA]?.allIds;
    if (!unitIds) {
      return [];
    }
    const moduleIds = state.entities[MODULE_SCHEMA]?.allIds;
    if (!moduleIds) {
      return [];
    }

    const moduleFromCourseIds = moduleIds.filter(
      (moduleId) => get(state.entities[MODULE_SCHEMA]?.byId, moduleId, {})?.course === courseId
    );

    if (!moduleFromCourseIds.length) {
      return [];
    }

    const contentVideoUnits = unitIds
      .map((unitId) => state.entities[UNIT_SCHEMA]?.byId[unitId])
      .filter(
        (unit) =>
          unit.isContentVideo &&
          unit.grammars.some((g) => grammarsIds.includes(g)) &&
          moduleFromCourseIds.includes(unit.module)
      )
      .filter((unit) => (isContentVideoTabAllowed ? true : !unit.disabled));

    const pendingContentVideoFromModule = contentVideoUnits.find(
      (unit) =>
        unit.module === moduleId &&
        !unit.defaultFinishedAt &&
        ["YOUR_CHALLENGE", isContentVideoTabAllowed ? "LOCKED" : ""].includes(unit.status)
    );
    if (pendingContentVideoFromModule) {
      return pendingContentVideoFromModule;
    }

    return head(
      orderBy(
        contentVideoUnits.map((unit) => ({
          ...unit,
          statusOrder:
            {
              YOUR_CHALLENGE: 1,
              FIRST_REVIEW: 3,
              SECOND_REVIEW: 4,
              SIMPLE_REVIEW: 5,
              LOCKED: isContentVideoTabAllowed ? 2 : 6,
            }[unit.status] || 7,
        })),
        ["statusOrder", "defaultFinishedAt"],
        ["asc", "asc"]
      )
    );
  };

export const getCompanyTimeToAllowContinueUnit = (state) => {
  const id = head(state.entities[COMPANY_SCHEMA].allIds);
  if (id) {
    return state.entities[COMPANY_SCHEMA].byId[id]?.timeToAllowContinueUnit;
  }
};
