import { call, cancelled, put, race, select, take, takeLeading } from "redux-saga/effects";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue } from "student-front-commons/src/actions/form";
import { checkAnswer } from "student-front-commons/src/services/itemService";
import browserHistory from "../browserHistory";
import {
  CLOSE_PRACTICE_TEST_EXECUTION_FLOW,
  GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  PRACTICE_TEST_EXECUTION_FORM,
  SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW,
} from "../consts";
import { addSentryUserAction, logError } from "../util";
import { stopAudio } from "../stores/audio-store";

const sentryUserAction = { mainComponent: "savePracticeTestItemExecutionAnswerFlow" };

export default function* () {
  yield takeLeading(getFlowStart(SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_PRACTICE_TEST_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(changeFormValue(ITEM_EXECUTION_FORM, "isDisabled", true));

          let itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
          let practiceTestExecutionForm = yield select(getForm(PRACTICE_TEST_EXECUTION_FORM));

          const answerResult = yield call(checkAnswer, {
            item: itemExecutionForm.values.associativeItem.item,
            answer: itemExecutionForm.values.answer,
          });
          const currentAnswer = { answer: answerResult.answer, correct: answerResult.status === "CORRECT" };

          yield put(
            changeFormValue(PRACTICE_TEST_EXECUTION_FORM, "answers", [
              ...practiceTestExecutionForm.values.answers,
              currentAnswer,
            ])
          );
          yield put(
            changeFormValue(
              PRACTICE_TEST_EXECUTION_FORM,
              "currentOrder",
              practiceTestExecutionForm.values.currentOrder + 1
            )
          );

          practiceTestExecutionForm = yield select(getForm(PRACTICE_TEST_EXECUTION_FORM));
          if (
            practiceTestExecutionForm.values.practiceTestItems.length ===
            practiceTestExecutionForm.values.answers.length
          ) {
            browserHistory.push("/practice-test-score");
            addSentryUserAction({
              ...sentryUserAction,
              clickedComponent: "None",
              action: `Practice Test finished. Navigate to: /practice-test-score`,
            });
          } else {
            yield put(startFlow(GET_NEXT_PRACTICE_TEST_ITEM_EXECUTION_FLOW));
          }
        } catch (error) {
          logError({ error, flow: SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW });
          // yield put(startFlow(HANDLE_ERROR_FLOW, {
          //   error: 'error_save_answer',
          //   retryFlow: {
          //     id: SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW,
          //   }
          // }));
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(SAVE_PRACTICE_TEST_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
