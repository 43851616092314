import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import RippleEffect from "../RippleEffect";
import CourseThemeContext from "../../core/CourseThemeContext";
import MediaQuery from "react-responsive";

const Button = Styled.button`
  background-color: #fff;
  transform: ${(props) => (props.highlight ? "scale(1.1)" : "none")};
  opacity: ${(props) => (!props.highlight && props.disabled ? 0.5 : 1)};
  outline: none;
  border: ${(props) =>
    props.highlight || props.isWrong || props.showBorder
      ? `2px solid ${props.isWrong ? "red" : props.highlightColor}`
      : `2px solid #fff`};
  width: 100%;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.1);
  padding: ${(props) => props.padding};
  border-radius: 3px;
  position: relative;
  cursor: ${(props) => (props.disabled ? "no-drop" : "pointer")};
  transition: all .5s;
  overflow: ${(props) => props.overflow};
  z-index: ${(props) => (props.highlight ? 1 : 0)};

  &:hover {
    background-color: ${(props) => (props.disabled ? "none" : "rgba(255, 255, 255, 0.5)")};
  }
`;

const ExerciseButton = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <MediaQuery maxWidth={368}>
        {(matches) => (
          <Button
            disabled={props.disabled}
            isWrong={props.isWrong}
            onClick={props.onClick}
            highlight={props.highlight}
            highlightColor={props.highlightColor ? props.highlightColor : primary}
            showBorder={props.showBorder}
            overflow={props.overflow}
            padding={matches ? "5px" : "15px"}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {props.children}
            </div>
          </Button>
        )}
      </MediaQuery>
    )}
  </CourseThemeContext.Consumer>
);

ExerciseButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isWrong: PropTypes.bool,
  highlight: PropTypes.bool,
  highlightColor: PropTypes.string,
  showBorder: PropTypes.bool,
  overflow: PropTypes.string,
  onClick: PropTypes.func,
};

ExerciseButton.defaultProps = {
  disabled: false,
  highlight: false,
  isWrong: false,
  highlightColor: null,
  showBorder: false,
  overflow: "hidden",
  onClick: () => false,
};

export default RippleEffect(ExerciseButton);
