import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import "./PopoverMenu";

const PopoverContainer = Styled.div`
  position: absolute;
  transition: all .3s;
  opacity: ${(props) => (props.isPopoverOpen ? 1 : 0)};
  visibility: ${(props) => (props.isPopoverOpen ? "visible" : "hidden")};
  pointer-events: ${(props) => (props.isPopoverOpen ? "all" : "none")};
  top: 55px;
  border-radius: 5px;
  animation: fade 1s;
  box-shadow: 0 1px 3px 0 rgba(85, 85, 85, 0.38);
  background-color: #fff;
  box-sizing: border-box;

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    height: 0;
    width: 0;
    border: 10px solid transparent;
    border-bottom-color: #fff;
    margin-left: -10px;
  }

  &:after{
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    height: 15px;
    width: 17px;
    background-color: transparent;
    margin-left: -8px;
    margin-bottom: -8px;
    z-index: -1;
    border-radius: 1px;
    box-shadow: 0px 0px 3px 0px rgba(85, 85, 85, 0.2);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default class PopoverMenu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    renderButton: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    contentStyle: PropTypes.shape({}),
    rootStyle: PropTypes.shape({}),
    onClose: PropTypes.func,
  };

  static defaultProps = {
    onClick: null,
    disabled: false,
    contentStyle: {},
    rootStyle: {},
    onClose: null,
  };

  state = { isPopoverOpen: false };

  handleOpenOrClosePopover = () => {
    if (!this.state.isPopoverOpen) {
      if (this.props.onClick) {
        this.props.onClick();
      }
      document.addEventListener("click", this.handleOpenOrClosePopover, true);
    } else {
      document.removeEventListener("click", this.handleOpenOrClosePopover, true);
    }

    this.setState(
      (prevState) => ({
        isPopoverOpen: !prevState.isPopoverOpen,
      }),
      () => {
        if (!this.state.isPopoverOpen && this.props.onClose) {
          this.props.onClose();
        }
      }
    );
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <PopoverContainer isPopoverOpen={this.state.isPopoverOpen} style={this.props.rootStyle}>
          <div
            style={{
              width: "100%",
              height: "100%",
              textAlign: "justify",
              overflow: "auto",
              ...this.props.contentStyle,
            }}
          >
            {this.props.children}
          </div>
        </PopoverContainer>
        <this.props.renderButton.type
          {...this.props.renderButton.props}
          onClick={this.handleOpenOrClosePopover}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
