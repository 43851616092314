import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { getRankingByPeriodAndLevel } from "student-front-commons/src/services/rankingService";
import FetchData from "student-front-commons/src/components/FetchData";
import Async from "../../core/Async";
import RankingList from "../../core/ranking/RankingList";
import Paragraph from "../../core/Paragraph";
import TranslatedText from "../../core/TranslatedText";

class CustomRankingListContainer extends Component {
  static propTypes = {
    from: PropTypes.instanceOf(Date).isRequired,
    to: PropTypes.instanceOf(Date).isRequired,
    level: PropTypes.oneOf(["national", "regional"]).isRequired,
  };

  render() {
    return (
      <FetchData
        service={getRankingByPeriodAndLevel}
        params={{
          id: sessionStorage.getItem("id"),
          size: 35,
          level: this.props.level,
          from: this.props.from,
          to: this.props.to,
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching} size="sm" color="dark">
            {data && data.length ? (
              <RankingList rankings={data} separatorPosition={10} />
            ) : (
              <TranslatedText
                as={Paragraph}
                translateKey={
                  moment(this.props.from).month() === moment().month() ? "ranking.noData" : "ranking.noOldData"
                }
              />
            )}
          </Async>
        )}
      </FetchData>
    );
  }
}

export default CustomRankingListContainer;
