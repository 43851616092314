import React from "react";
import PropTypes from "prop-types";

const FlexRow = ({ id, children, className, onClick, ...rest }) => (
  <div
    id={id}
    onClick={onClick}
    className={className}
    style={{
      display: "flex",
      flexDirection: "row",
      ...rest,
    }}
  >
    {children}
  </div>
);

FlexRow.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default FlexRow;
