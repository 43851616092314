import { put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { changeFormValue, initForm } from "student-front-commons/src/actions/form";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { addSentryUserAction, linkAnswers, logError } from "../util";
import {
  BONUS_TEST_EXECUTION_FORM,
  END_BONUS_TEST_EXECUTION_FLOW,
  GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW,
  ITEM_EXECUTION_FORM,
  OLD_PLAY_ITEM_AUDIO_FLOW,
} from "../consts";
import browserHistory from "../browserHistory";
import { get, head, orderBy, shuffle, uniqBy } from "lodash";

const typesToOrderAnswers = ["GAP_FILL"];
const sentryUserAction = { mainComponent: "getNextBonusTestItemFlow" };

export default function* () {
  yield takeLatest(getFlowStart(GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW), function* () {
    try {
      const itemExecutionForm = yield select(getForm(ITEM_EXECUTION_FORM));
      const bonusTestExecutionForm = yield select(getForm(BONUS_TEST_EXECUTION_FORM));

      const nextIndex = bonusTestExecutionForm.values.currentIndex + 1;
      let nextBonusTestItem = bonusTestExecutionForm.values.bonusTestItems[nextIndex];

      if (!nextBonusTestItem) {
        let reachedLevel = itemExecutionForm.values.associativeItem.placementTestLevel;
        const totalErrors = bonusTestExecutionForm.values.answers.filter((answer) => answer.status === "WRONG").length;
        if (reachedLevel.level === 6 && totalErrors > 5) {
          reachedLevel = bonusTestExecutionForm.values.answers.find(
            (answer) => answer.placementTestLevel.level === 5.2
          ).placementTestLevel;
        }

        yield put(
          startFlow(END_BONUS_TEST_EXECUTION_FLOW, {
            stopReason: "FINALIZOU",
            reachedLevel: get(reachedLevel, "id", null),
            bonusTestExecution: bonusTestExecutionForm.values.execution,
          })
        );

        browserHistory.push({
          pathname: `${head(browserHistory.location.pathname.split("/execution"))}/result`,
          state: {
            fromTestExecution: true,
          },
        });
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Bonus Test finished. Navigate to: ${head(
            browserHistory.location.pathname.split("/execution")
          )}/result`,
        });
        return;
      }

      const advanceToNextGrammarOnLevel =
        nextBonusTestItem.placementTestLevel.id ===
          get(itemExecutionForm, "values.associativeItem.placementTestLevel.id", 0) &&
        nextBonusTestItem.order > get(itemExecutionForm, "values.associativeItem.item.order");

      if (
        advanceToNextGrammarOnLevel &&
        bonusTestExecutionForm.values.answers.filter(
          (answer) => answer.placementTestLevel.id === nextBonusTestItem.placementTestLevel.id
        ).length > 0
      ) {
        yield put(
          changeFormValue(
            BONUS_TEST_EXECUTION_FORM,
            "extraItemsRemainingOnLevel",
            bonusTestExecutionForm.values.extraItemsRemainingOnLevel - 1
          )
        );
      }

      const totalPreviousCorrectAnswersOfCurrentLevel = bonusTestExecutionForm.values.answers.filter(
        (answer) => answer.placementTestLevel.id === nextBonusTestItem.placementTestLevel.id && answer.correct
      ).length;

      const totalBonusItemsOfCurrentLevel = bonusTestExecutionForm.values.bonusTestItems.filter(
        (bonusTestItem) => bonusTestItem.placementTestLevel.id === nextBonusTestItem.placementTestLevel.id
      ).length;

      const totalRemainingQuestionsOfCurrentLevel =
        totalBonusItemsOfCurrentLevel +
        bonusTestExecutionForm.values.extraItemsRemainingOnLevel +
        bonusTestExecutionForm.values.extraItemsAddedOnLevel;

      if (
        nextBonusTestItem.placementTestLevel.level <= 3.7 &&
        (totalPreviousCorrectAnswersOfCurrentLevel / totalRemainingQuestionsOfCurrentLevel) * 100 >=
          100 - nextBonusTestItem.placementTestLevel.placementPercentageError
      ) {
        nextBonusTestItem = bonusTestExecutionForm.values.bonusTestItems.find(
          (bonusTestItem) => bonusTestItem.placementTestLevel.level > nextBonusTestItem.placementTestLevel.level
        );
        yield put(
          changeFormValue(
            BONUS_TEST_EXECUTION_FORM,
            "currentIndex",
            bonusTestExecutionForm.values.bonusTestItems.indexOf(nextBonusTestItem) - 1
          )
        );
      }

      if (
        nextBonusTestItem.placementTestLevel.level >
          get(itemExecutionForm, "values.associativeItem.placementTestLevel.level", 0) ||
        bonusTestExecutionForm.values.answers.filter(
          (answer) => answer.placementTestLevel.id === nextBonusTestItem.placementTestLevel.id
        ).length === 0
      ) {
        yield put(changeFormValue(BONUS_TEST_EXECUTION_FORM, "extraItemsAddedOnLevel", 0));
        yield put(
          changeFormValue(
            BONUS_TEST_EXECUTION_FORM,
            "extraItemsRemainingOnLevel",
            uniqBy(
              bonusTestExecutionForm.values.bonusTestItems.filter(
                (item) => item.placementTestLevel.id === nextBonusTestItem.placementTestLevel.id
              ),
              (bonusTestItem) => bonusTestItem.order
            ).length
          )
        );
      }

      const linkedAnswers = linkAnswers(nextBonusTestItem.item.answers);
      yield put(
        initForm(ITEM_EXECUTION_FORM, {
          associativeItem: {
            ...nextBonusTestItem,
            item: {
              ...nextBonusTestItem.item,
              order: nextBonusTestItem.order,
              linkedAnswers: typesToOrderAnswers.find((type) => type === nextBonusTestItem.item.type.key)
                ? orderBy(linkedAnswers, "text", "asc")
                : shuffle(linkedAnswers),
            },
          },
          startedAt: new Date(),
          isDisabled: true,
          answer: null,
          repeatCount: 0,
        })
      );

      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Created Item Execution Form`,
      });

      if (bonusTestExecutionForm.values.currentIndex === -1) {
        try {
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Bonus Test started`,
          });
        } catch (introError) {
          logError({ introError, flow: GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW });
        }
      }

      yield put(startFlow(OLD_PLAY_ITEM_AUDIO_FLOW, { initialPlay: true, isPlacementRepeat: false }));
    } catch (error) {
      logError({ error, flow: GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(GET_NEXT_BONUS_TEST_ITEM_EXECUTION_FLOW));
    }
  });
}
