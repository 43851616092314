import React from "react";
import PropTypes from "prop-types";
import Tabs from "../../core/Tabs";
import Title from "../../core/Title";
import withTranslation from "../../core/withTranslation";
import StudyQualityChartContainer from "./StudyQualityChartContainer";

const StudyQualityChartTab = (props) => (
  <Tabs
    title={<Title>{props.title}</Title>}
    tabs={[
      {
        label: props.getTranslation("day"),
        component: <StudyQualityChartContainer type="day" key="day" />,
      },
      {
        label: props.getTranslation("week"),
        component: <StudyQualityChartContainer type="week" key="week" />,
      },
      {
        label: props.getTranslation("month"),
        component: <StudyQualityChartContainer type="month" key="month" />,
      },
    ]}
  />
);

StudyQualityChartTab.propTypes = {
  title: PropTypes.string.isRequired,
};

export default withTranslation(StudyQualityChartTab);
