import React, { Component } from "react";
import PropTypes from "prop-types";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Icon from "./Icon";
import FlexColumn from "./FlexColumn";
import CourseThemeContext from "./CourseThemeContext";
import Separator from "./Separator";
import TranslatedText from "./TranslatedText";

export default class CircularAbilityScore extends Component {
  static propTypes = {
    color: PropTypes.string.isRequired,
    totalValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    diff: PropTypes.number,
    type: PropTypes.oneOf(["listening", "speaking", "reading", "writing"]).isRequired,
    size: PropTypes.oneOf(["md", "lg"]),
  };

  static defaultProps = {
    size: "md",
    diff: 0,
  };

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <FlexColumn
            width={
              {
                md: 80,
                lg: 100,
              }[this.props.size]
            }
            alignItems="center"
            flexShrink={0}
          >
            <CircularProgressbarWithChildren
              strokeWidth={5}
              value={this.props.value}
              maxValue={this.props.totalValue}
              styles={buildStyles({
                pathColor: primary,
                trailColor: `${primary}1A`,
                strokeLinecap: "butt",
                width: 80,
              })}
            >
              <Icon
                color={primary}
                size={
                  {
                    md: "sm",
                    lg: "md",
                  }[this.props.size]
                }
                icon={
                  {
                    listening: "ear-hearing",
                    speaking: "forum",
                    reading: "book-open-variant",
                    writing: "lead-pencil",
                  }[this.props.type]
                }
              />
            </CircularProgressbarWithChildren>
            <Separator size="xs" />
            <TranslatedText
              translateKey="module.conqueredPercentage"
              values={{
                percentage: ((this.props.value / this.props.totalValue) * 100).toFixed(0),
              }}
              style={{
                color: primary,
                fontSize: 14,
                fontWeight: "bold",
                width: "max-content",
              }}
            />
          </FlexColumn>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}
