import { nth } from "lodash";
import React from "react";
import { withRouter } from "react-router-dom";
import { useEntity } from "student-front-commons/src/hooks";
import BonusTestProgress from "./BonusTestProgress";
import Breadcrumb from "./Breadcrumb";
import BreadcrumbBar from "./BreadcrumbBar";
import BreadcrumbItem from "./BreadcrumbItem";
import withTranslation from "./withTranslation";
import BreadcrumbTabItem from "./BreadcrumbTabItem";

const BreadcrumbContainer = ({ match, getTranslation }) => {
  const module = useEntity("module", match.params.idModule);
  const pathSplitted = match.path.split("/");

  const isBonusTestScreen = nth(pathSplitted, 3) === "bonus-test";
  const isModulesListScreen = nth(pathSplitted, 3) === "modules";
  const isVideoListScreen = nth(pathSplitted, 3) === "videos";

  const profile = useEntity("profile", sessionStorage.getItem("id"));
  const company = useEntity("company", profile?.company);

  const isExtraCourse = !!profile.activeCourses?.find((c) => c.id === match.params.idCourse)?.distributor;

  return (
    <BreadcrumbBar>
      <Breadcrumb>
        {company?.allowContentVideoTab && match.params.idCourse && !match.params.idModule ? (
          <>
            <BreadcrumbTabItem
              label={getTranslation("menu.exercises")}
              to={`/course/${match.params.idCourse}/modules`}
              active={isModulesListScreen}
            />
            <BreadcrumbTabItem
              label={getTranslation("menu.contentVideo")}
              to={`/course/${match.params.idCourse}/videos`}
              active={isVideoListScreen}
            />
          </>
        ) : (
          <>
            {match.params.idCourse && <BreadcrumbItem icon="home" to="/" />}
            {match.params.idCourse && !match.params.idModule ? (
              isBonusTestScreen ? (
                <BreadcrumbItem label="Bonus Test" last={true} />
              ) : (
                <BreadcrumbItem label={getTranslation("menu.modules")} last={true} />
              )
            ) : null}
          </>
        )}
        {match.params.idCourse && match.params.idModule && (
          <BreadcrumbItem to={`/course/${match.params.idCourse}/modules`} label={module.name} last={false} />
        )}
        {match.params.idModule && <BreadcrumbItem label={getTranslation("menu.units")} last />}
      </Breadcrumb>
      {!isBonusTestScreen && !isExtraCourse && <BonusTestProgress idCourse={match.params.idCourse} />}
    </BreadcrumbBar>
  );
};

export default withRouter(withTranslation(BreadcrumbContainer));
