import React from "react";
import PropTypes from "prop-types";
import Separator from "../../core/Separator";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import UnitResultRankingSection from "./UnitResultRankingSection";
import UnitResultPointsSection from "./UnitResultPointsSection";
import RecommendedUnitSection from "./RecommendedUnitSection";
import { sample } from "lodash";
import Sound1 from "../../audios/end-unit-1.mp3";
import Sound2 from "../../audios/end-unit-2.mp3";
import Sound3 from "../../audios/end-unit-3.mp3";
import { useSelector } from "react-redux";
import { isExecutionApproved } from "student-front-commons/src/selectors/execution";

const UnitResultScoreStep = ({ onClick }) => {
  const isApproved = useSelector(isExecutionApproved);
  return (
    <>
      {isApproved && <audio src={sample([Sound1, Sound2, Sound3])} autoPlay={true} />}
      <UnitResultPointsSection />
      <Separator size="md" />
      {!isApproved && <RecommendedUnitSection />}
      <RankingParticipantContainer>
        <UnitResultRankingSection />
      </RankingParticipantContainer>
    </>
  );
};

UnitResultScoreStep.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default UnitResultScoreStep;
