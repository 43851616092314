import { schema } from "normalizr";
import profileSchema from "./profileSchema";
import messageSchema from "./messageSchema";
import { MESSAGE_CHANNEL_SCHEMA } from "./index";

export default new schema.Entity(
  MESSAGE_CHANNEL_SCHEMA,
  {
    chattingWith: profileSchema,
    lastMessage: messageSchema,
  },
  {
    processStrategy: (entity) => ({
      ...entity,
      isRead: entity.chattingWith
        ? entity.chattingWith.id === entity.lastMessage.sender
          ? !!entity.lastMessage.readAt
          : true
        : true,
      lastMessageSentAt: entity.lastMessage.sentAt,
    }),
  }
);
