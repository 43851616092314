import React from "react";
import PropTypes from "prop-types";
import { GREY_5 } from "../consts/color";

const Icon = (props) => (
  <i
    className={`
      ${props.className}
      mdi
      mdi-${props.icon}
      mdi-${
        {
          xxs: "10px",
          xs: "18px",
          sm: "24px",
          md: "36px",
          mdl: "48px",
          lg: "48px",
          xl: "60px",
        }[props.size]
      }
    `}
    style={{
      color: props.color,
      cursor: !!props.onClick ? "pointer" : props.cursor,
      opacity: props.opacity,
      fontSize: {
        xxs: "14px",
        xs: "18px",
        sm: "24px",
        md: "36px",
        mdl: "48px",
        lg: "48px",
        xl: "60px",
      }[props.size],
    }}
    onClick={props.onClick}
  />
);

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  opacity: PropTypes.number,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(["xxs", "xs", "sm", "md", "mdl", "lg", "xl"]),
};

Icon.defaultProps = {
  color: GREY_5,
  opacity: 1,
  cursor: "inherit",
  onClick: null,
  className: null,
  size: "sm",
};

export default Icon;
