import React from "react";
import PropTypes from "prop-types";
import Icon from "./Icon";
import { GREY_2 } from "../consts/color";
import RippleEffect from "./RippleEffect";

const IconButton = (props) => (
  <button
    disabled={props.disabled}
    onClick={props.onClick}
    style={{
      width: 47,
      height: 47,
      borderRadius: "100%",
      background: "none",
      boxSizing: "border-box",
      padding: 4,
      border: "none",
      outline: "none",
      cursor: props.disabled ? "not-allowed" : "pointer",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Icon color={props.color} icon={props.icon} size={props.size} cursor={props.disabled ? "not-allowed" : "pointer"} />
    {props.children}
  </button>
);

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

IconButton.defaultProps = {
  size: "sm",
  color: GREY_2,
  children: null,
  disabled: false,
};

export default RippleEffect(IconButton);
