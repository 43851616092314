const labels = { 
'account.avatarTakePhoto': 'Tomar una foto', 
'account.birthDate': 'Fecha de nacimiento', 
'account.button.save': 'Guardar', 
'account.button.saveContinue': 'Guardar y Continuar', 
'account.cancelAvatar': 'Cancelar', 
'account.changeAvatar': 'Cambiar foto', 
'account.chooseAvatarOption': 'Elige una opción', 
'account.currentPassword': 'Contraseña actual', 
'account.email': 'Correo', 
'account.female': 'Femenino', 
'account.firstAccessDescription': 'Como este es tu primer acceso a la plataforma, necesitamos algunos datos para continuar.', 
'account.gender': 'Sexo', 
'account.male': 'Masculino', 
'account.newPassword': 'Nueva contraseña', 
'account.notRankingParticipant': 'No deseo participar en el ranking', 
'account.passwordDescription': 'Para cambiar tu contraseña, debes introducir la contraseña actual y luego la nueva', 
'account.passwordQuestion': '¿Quieres cambiar tu contraseña?', 
'account.passwordTitle': 'Contraseña', 
'account.rankingParticipant': 'Deseo participar en el ranking', 
'account.selectFromGallery': 'Selecciona una imagen de la galería', 
'account.title': 'Tus datos', 
'achievement.noData': 'Todavía no posees logros. ¡Continúa estudiando para ganar tu primer medalla!', 
'achievement.noNationalData': 'Sin logros nacionales', 
'achievement.noRegionalData': 'Sin logros regionales', 
'achievement.noSchoolData': 'Sin logros institucionales', 
'achievement.noStudentData': 'Sin logros personales', 
'achievement.title': 'Logros', 
'alert.closeContentVideoExecution': '¿Está seguro de que desea salir?', 
'alert.closeExecution': 'Si sales ahora, el progreso de estudio se perderá después de {timeToAllowContinueUnit}. ¿Estás seguro de que deseas salir?', 
'alert.closeExecutionNoProgressLoss': '¿Estás seguro de que deseas salir?', 
'alert.closeMastery': 'Si sales ahora, tu prueba de aprendizaje será reprobada y deberás aprobar nuevas unidades para habilitar un nuevo intento. ¿Estás seguro de que deseas salir?', 
'alert.closeTasting': '¿Estás seguro de finalizar la demostración?', 
'alert.logout': 'Estás saliendo de la app. ¿Estás seguro de que deseas salir?', 
'alert.no': 'No', 
'alert.oldAppVersionButton': 'Actualizar ahora', 
'alert.oldAppVersionDescription': 'Tienes actualizaciones pendientes. Por favor, actualiza la aplicación para continuar.', 
'alert.oldAppVersionTitle': 'Actualización disponible', 
'alert.title': 'Atención', 
'alert.yes': 'Sí', 
'announcement.newMasteryTestListLayout': 'Hi, student! Se han realizado cambios en las Pruebas de Aprendizaje. Ahora están al final de cada fase, y se desbloquearán una vez que termines todos los ejercicios de esa fase.  Si tienes alguna duda con respecto a este cambio, nuestro equipo de soporte estará encantado de ayudarte.', 
'bonusTest.closeExecution': 'Si abandonas ahora, perderás la oportunidad de seguir avanzando en la prueba para conseguir el máximo progreso según tu nivel y la bonificación de puntuación. ¿Estás seguro de que quieres abandonar?', 
'bonusTest.correctAnswers': '{correctAnswers} respuestas correctas', 
'bonusTest.executionTitle': 'Prueba Bonus', 
'bonusTest.history': 'Historial de Prueba Bonus', 
'bonusTest.history.notCompleted	': 'Examen no terminado', 
'bonusTest.historyDiff': 'Comparado al examen anterior.', 
'bonusTest.hits': '{hits} aciertos', 
'bonusTest.introduction.blocked.description': 'Tienes que llegar al 60% del recorrido para conseguir la bonificación.', 
'bonusTest.introduction.blocked.title': 'Sigue avanzando en el curso para conseguir tu Bonificación.', 
'bonusTest.introduction.confirmation': 'Solo tienes una oportunidad para hacer el examen y tu progreso no se puede guardar. ¿Estás seguro de que quieres empezar?', 
'bonusTest.introduction.description': 'Responde correctamente al mayor número de preguntas para analizar tu evolución y seguir ganando puntos para el ranking.', 
'bonusTest.introduction.welcome': '¡Bienvenido a la Prueba Bonus!', 
'bonusTest.noBonus': 'Sin bonus.', 
'bonusTest.noPoints': 'Sin puntos', 
'bonusTest.points': '{points} puntos', 
'bonusTest.rescue': 'Obtener Bonus', 
'bonusTest.result.bonusPoints': 'Has ganado {puntos} puntos extra', 
'bonusTest.result.correctAnswers': '¡Acertaste <b>{correctAnswers} respuestas</b>!', 
'bonusTest.result.lastResults': 'Últimos Resultados', 
'bonusTest.result.noBonus.description': 'Pero no te preocupes, podrás realizar otro examen en el próximo curso.', 
'bonusTest.result.noBonus.title': 'Lo siento. Buena suerte en la próxima vez.', 
'bonusTest.result.noLastResults': 'No tienes ningún examen previo terminado para poder comparar con tu resultado actual.', 
'bonusTest.result.notCompleted.description1': 'Si no terminas el examen, perderás la oportunidad de ganar puntos y de analizar tu progreso.', 
'bonusTest.result.notCompleted.description2': 'Tendrás una nueva oportunidad en tu próximo curso.', 
'bonusTest.result.notCompleted.title': '¡Ey! Tu examen no ha sido terminado.', 
'bonusTest.result.title': 'Tu resultado', 
'bonusTest.start': 'Comenzar la Prueba Bonus', 
'bugReporter.button.cancel': 'Cancelar', 
'bugReporter.button.send': 'Enviar', 
'bugReporter.inputPlaceholder': 'Describe el problema encontrado', 
'bugReporter.successMessage': '¡Tu reporte fue enviado! Gracias por ayudar a mejorar nuestra plataforma.', 
'bugReporter.title': 'Informar Error', 
'cancelMasteryTest': 'Finalizando la Prueba de Aprendizaje', 
'certificationTest.awaitingRevision': 'Tu Prueba de Certificacion será evaluada en breve. Recibirás una notificación en algunos días con el resultado.', 
'certificationTest.button.access': 'Entrar', 
'certificationTest.button.accessNewCourse': 'Acceder al nuevo curso', 
'certificationTest.button.back': 'Volver al curso', 
'certificationTest.button.continue': 'CONTINUAR PRUEBA DE CERTIFICACIÓN', 
'certificationTest.button.reviewed': 'VISUALIZAR RESULTADO', 
'certificationTest.button.start': 'INICIAR PRUEBA DE CERTIFICACIÓN', 
'certificationTest.continue': 'Continúa tu  Prueba de Certificacion. Usa un computador para continuar.', 
'certificationTest.created': 'Estás apto para hacer la Prueba de Certificación. Habla con tu profesor o profesora para programarla y, mientras tanto, continúa repasando el contenido de tu curso para prepararte para la prueba.', 
'certificationTest.enabled': 'Tu Prueba de Certificacion está disponible. Usa un computador para hacerla.', 
'certificationTest.execution.ability.listening': 'Prueba de Escucha', 
'certificationTest.execution.ability.reading': 'Prueba de Lectura', 
'certificationTest.execution.ability.speaking': 'Prueba de Habla', 
'certificationTest.execution.ability.writing': 'Prueba de Escritura', 
'certificationTest.execution.completedDescription': '¡Después de la revisión de tu prueba por el evaluador, recibiras una notificación con tu nota! ¡Buena suerte!', 
'certificationTest.execution.completedTitle': 'Prueba de Certificación finalizada y enviada para revisión.', 
'certificationTest.execution.status.available': 'Start', 
'certificationTest.execution.status.blocked': 'Locked', 
'certificationTest.execution.status.finished': 'Done', 
'certificationTest.execution.title': 'Obtén una nota mínima de 70 para habilitar el próximo paso que está bloqueado.', 
'certificationTest.failed': 'No alcanzaste la nota necesaria en tu última Prueba de Certificación. Haz {units} units diferentes para habilitar un nuevo intento', 
'certificationTest.help.free_speak': 'En este ejercicio, diferente de las unidades, no debes repetir la pregunta mostrada, pero sí responderla. Tendrás hasta 1 minuto para pensar en tu respuesta antes de que la grabación se inicie automáticamente. Si lo deseas, también puedes iniciar antes.', 
'certificationTest.help.free_speak_image': 'En este ejercicio, debes describir la imagen que aparece en la pantalla. Tendrás hasta 1 minuto para pensar tu respuesta antes de que la grabación se inicie automáticamente. Si lo deseas, también puedes empezar antes.', 
'certificationTest.help.gotIt': 'Entendí', 
'certificationTest.loading': 'Calculando tu resultado', 
'certificationTest.result.approvedDescription': 'Has conseguido la nota necesaria para pasar la Prueba de Certificacion.', 
'certificationTest.result.approvedDescriptionTwo': 'Nos probaste que has aumentado tu nivel de Inglés en la escala del Marco Común Europeo de Referencia para las lenguas.', 
'certificationTest.result.approvedTitle': '¡Excelente!', 
'certificationTest.result.failedDescription': 'No alcanzaste la nota necesaria para pasar la Prueba de Certificación.', 
'certificationTest.result.failedDescriptionTwo': 'Estudia {units} units más en el curso para hacer la Prueba de Certificacion de nuevo.', 
'certificationTest.result.failedTitle': '¡Oh no!', 
'certificationTest.result.finalScore': 'Nota final', 
'certificationTest.result.scoreBySkill': 'Nota por habilidad', 
'certificationTest.result.unlockedCourse': 'Curso desbloqueado', 
'certificationTest.reviewed': 'Tu Prueba de Certificacion ha sido revisada.', 
'certificationTest.scheduled': 'Tu Prueba de Certificacion está programada para <b>{scheduledTo}</b>', 
'certificationTest.title': 'Prueba de Certificación', 
'checkText': 'Revisa el texto nuevamente', 
'checkTextAgain': '¡Haz clic aquí para revisar el texto nuevamente!', 
'close': 'Cerrar', 
'closeExecution.defaultMessage': 'Si sales ahora, el progreso de estudio se perderá después de {timeToAllowContinueUnit}. ¿Estás seguro de que deseas salir?', 
'closeExecution.masteryMessage': 'Si sales ahora, tu Prueba de Aprendizaje será reprobada y deberás aprobar nuevas unidades para habilitar un nuevo intento. ¿Estás seguro de que deseas salir?', 
'closeExecution.no': 'No', 
'closeExecution.noProgressLossMessage': '¿Está seguro de que desea salir?', 
'closeExecution.title': 'Atención', 
'closeExecution.yes': 'Sí', 
'closedRanking.champions': 'Ranking <b style="color: #fffc00">{month}</b>', 
'closedRanking.congratulations': 'Felicidades!', 
'closedRanking.congratulationsMessage.national': 'Quedaste en el <b style="color: #fffc00">{position} lugar</b> en tu país.', 
'closedRanking.congratulationsMessage.regional': 'Quedaste en el <b style="color: #fffc00">{position} lugar</b> en tu región.', 
'closedRanking.congratulationsMessage.school': 'Quedaste en el <b style="color: #fffc00">{position} lugar</b> en tu institucion.', 
'closedRanking.monthly': 'Mensualmente', 
'closedRanking.national': 'Nacional', 
'closedRanking.points': '{points} Puntos', 
'closedRanking.regional': 'Regional', 
'closedRanking.school': 'Institucional', 
'closedRanking.semiannual': 'Semestral', 
'closedRanking.yearly': 'Anual', 
'congratulations': 'Felicitaciones', 
'contentVideo.execution.attachments': 'Documentos adjuntos', 
'contentVideo.execution.incompleteLesson': 'Clase incompleta', 
'contentVideo.execution.lesson': 'Lesson {value}', 
'contentVideo.execution.lessonCompleted': 'Lección completa', 
'contentVideo.execution.localesSelect': 'Seleccione un idioma de subtítulos', 
'contentVideo.execution.noAttachments': 'No hay documentos anexos a esta actividad', 
'contentVideo.execution.noRecommendedUnits': 'No hay ejercicios recomendados', 
'contentVideo.execution.openAttachmentError': 'No ha sido posible abrir el archivo', 
'contentVideo.execution.openRecommendedUnitModal.confirm': 'Sí', 
'contentVideo.execution.openRecommendedUnitModal.title': 'Você tem certeza que deseja iniciar este exercício?', 
'contentVideo.execution.recommendedUnits': 'Ejercicios recomendados', 
'contentVideo.list.emptyState.description': 'Tu profesor no ha subido ningún vídeo.', 
'contentVideo.list.emptyState.title': 'Ups... Todavía no hay vídeos aquí', 
'contentVideo.list.module': 'Módulo {value}', 
'contentVideo.list.title': 'Clases grabadas', 
'continue': 'Continuar', 
'courseSummary.button.access': 'Acceder al nuevo curso', 
'courseSummary.button.back': 'Volver a home', 
'courseSummary.certificationTestPart1': 'Tu próxímo paso es la <b>Prueba de Certificación</b>. Habla con tu profesor para programarla.', 
'courseSummary.certificationTestPart2': 'Utiliza los recaps para revisar el contenido antes de la Prueba de Certificación. ¡Buena suerte!', 
'courseSummary.description': 'Completaste el curso {course}', 
'courseSummary.hoursInCourse': 'Total de horas en el curso', 
'courseSummary.pointsInCourse': 'Total de puntos en el curso', 
'courseSummary.title': '¡Felicitaciones!', 
'courseSummary.unlockedCourse': 'Curso desbloqueado', 
'courseSummary.yourProgress': 'Tu Progreso', 
'createAccount.button.saveAndContinue': 'Guardar y continuar', 
'createAccount.button.validateCpf': 'Siguiente', 
'createAccount.button.validateRa': 'Siguiente', 
'createAccount.completeMessage': 'Completa tu registro', 
'createAccount.error.activeAccount': 'Parece que ya te has registrado. Accede a la página de inicio de sesión y usa tu correo electrónico y contraseña para acceder a la plataforma.', 
'createAccount.error.email': 'El correo informado ya está en uso. Por favor usa otro correo electrónico.', 
'createAccount.error.general': 'No pudimos actualizar tus datos. Verifica los campos e intenta nuevamente.', 
'createAccount.error.invalidCpf': 'No hemos podido encontrar un usuario con tu CPF. Por favor, comprueba tu CPF o ponte en contacto con tu institución.', 
'createAccount.error.invalidRa': 'No encontramos un usuario con su RA. Verifique e intente nuevamente.', 
'createAccount.error.limit_reached': 'Todas las vacantes disponibles ya han sido ocupadas.', 
'createAccount.field.confirmPassword': 'Confirma tu contraseña', 
'createAccount.field.cpf': 'Informa tu CPF', 
'createAccount.field.email': 'Tu correo', 
'createAccount.field.name': 'Tu nombre', 
'createAccount.field.password': 'Crea una contraseña', 
'createAccount.field.ra': 'Ingrese su RA', 
'createAccount.infoMessage': 'Ingresa los datos a continuación', 
'createAccount.infoStep.cancel': 'Cancelar', 
'createAccount.infoStep.confirmPassword': 'Confirmar Contraseña', 
'createAccount.infoStep.description': 'Ingresa los datos a continuación para crear tu cuenta', 
'createAccount.infoStep.email': 'Correo', 
'createAccount.infoStep.name': 'Nombre', 
'createAccount.infoStep.password': 'Contraseña', 
'createAccount.infoStep.saveAndContinue': 'Guardar y continuar', 
'createAccount.infoStep.title': 'Bienvenido a {name}', 
'createAccount.planStep.confirmAndPay': 'Confirmar y pagar', 
'createAccount.planStep.description': 'Selecciona el plan y haz clic en confirmar', 
'createAccount.planStep.monthly.byPeriod': '/ mes', 
'createAccount.planStep.privacyPolicy': 'Política de privacidad', 
'createAccount.planStep.quarterly.byPeriod': '/ cuarto', 
'createAccount.planStep.quarterly.monthCompare': '3 meses por $ {price}/mes.', 
'createAccount.planStep.quarterly.saveDescription': 'Ahorre {discount}%', 
'createAccount.planStep.semiannually.byPeriod': '/ semestre', 
'createAccount.planStep.semiannually.monthCompare': '6 meses por $ {price}/mes.', 
'createAccount.planStep.semiannually.saveDescription': 'Ahorre {discount}%', 
'createAccount.planStep.title': '¿Qué plan quieres?', 
'createAccount.planStep.useTerms': 'Terminos de uso', 
'createAccount.success.general': 'Registro creado con éxito. Serás redirigido a tu curso.', 
'createAccount.success.payment': 'Registro creado con éxito. Serás redirigido al pago.', 
'createAccount.successStep.accessPlacement': 'Continuar', 
'createAccount.successStep.placementDescription': 'El siguiente paso es conocer tu nivel de inglés. Utiliza el siguiente botón para acceder a nuestra Prueba de Nivel', 
'createAccount.successStep.title': 'Ahora puedes comenzar tu curso', 
'createAccount.welcomeMessage': 'Bienvenido a tu curso de inglés.', 
'day': 'Día', 
'days': 'Días', 
'dismiss': 'Cerrar', 
'error.account_duplicated_email': 'El correo informado ya está en uso. Por favor usa otro correo.', 
'error.account_general': 'No pudimos actualizar tus datos. Verifica los campos e intenta nuevamente.', 
'error.account_invalid_password': 'Verifica tu contraseña actual. Para cambiar la contraseña, debes usar la contraseña actual y la nueva contraseña deseada.', 
'error.account_picture_general': 'No pudimos actualizar tu foto. Inténtalo de nuevo.', 
'error.androidVersion': 'La versión del android de este dispositivo es incompatible. Versión mínima: 5.0 Lollipop', 
'error.audioDownload': 'Hubo un problema con el audio. Inténtalo de nuevo usando el botón Repetir.', 
'error.checkAudioPermission.chrome.button': 'Permitir y continuar', 
'error.checkAudioPermission.chrome.description': 'Haz clic en el botón abajo para permitir y continuar.', 
'error.checkAudioPermission.description': 'Perdona, detectamos que estás usando el <b style="textTransform: capitalize">{browserName}</b> navegador web y que nuestra plataforma no tiene permiso para reproducir automáticamente los audios.', 
'error.checkAudioPermission.safari.description': 'Tú puedes cambiar entre diferentes configuraciones de reproducción automática desde la <b>configuración de Safari</b> (Preferencias> Sitios web> Reproducción automática) o Sigue los <b>pasos abajo</b> para habilitar la reproducción de los audios:', 
'error.checkAudioPermission.safari.step1': 'Haz clic con el botón derecho en la barra de direcciones y selecciona la opción: <b>Ajuste para este site</b>', 
'error.checkAudioPermission.safari.step2': 'En la opción Reproducción Automática, selecciona: <b>Permitir todas las reproducciones automáticas</b>', 
'error.checkAudioPermission.safari.step3': 'Actualiza la página y estarás listo para comenzar', 
'error.checkAudioPermission.title': 'Casi listo', 
'error.checkBrowserType.description1': 'Para una buena experiencia en nuestra plataforma considera utilizar la última versión de Google Chrome.', 
'error.checkBrowserType.description2': 'También puedes utilizar el Mozila Firefox o Safari.', 
'error.checkBrowserType.title': 'Lo sentimos, tu navegador actual no es compatible con nosotros.', 
'error.checkBrowserVersion.description': 'Para una buena experiencia en nuestra plataforma considera utilizar última versión. Descarga la última versión en este {link}.', 
'error.checkBrowserVersion.title': 'Tu navegador está desactualizado.', 
'error.close': 'Cerrar', 
'error.create_account_duplicated_email': 'El correo informado ya está en uso. Ponte en contacto con nuestro soporte para obtener más información', 
'error.create_account_general': 'No pudimos crear tu cuenta. Verifica la información e intenta nuevamente', 
'error.differentPasswords': 'Las contraseñas no son iguales', 
'error.duplicatedEmail': 'Ya existe un usuario con el correo informado.', 
'error.emailNotFound': 'No encotramos ningún usuario para el correo informado.', 
'error.emailPasswordInvalid': 'Verifica tu correo y/o contraseña.', 
'error.error_browser_block_autoplay': 'Haz clic en el botón de abajo para continuar la actividad.', 
'error.error_browser_block_intro_autoplay': '¡Comencemos! Haz clic en el botón de abajo y sigue las instrucciones.', 
'error.error_find_speech_recognition_text': 'Hubo un problema para definir la respuesta. Intenta utilizar el botón grabar una vez más', 
'error.error_get_speech_recognition_score': 'Hubo un problema para definir tu nota. Intenta grabar una vez más', 
'error.error_inspect_html': 'No se permite inspeccionar el sitio. Se perderá el progreso de esa unidad.', 
'error.error_no_speech': 'No conseguimos detectar tu habla. Haz la grabación de nuevo', 
'error.error_play_audio': 'Hubo un problema al reproducir el audio. Intenta usar Repetir nuevamente.', 
'error.error_play_record': 'Ha habido un problema para reproducir tu grabación. Intenta utilizar el botón Escuchar nuevamente', 
'error.error_record_missing_mic': 'No se pudo acceder al micrófono. Comprueba la configuración de tu navegador.', 
'error.error_record_not_allowed': 'La plataforma no tiene permiso para acceder al micrófono. Comprueba los permisos de tu navegador.', 
'error.error_reply_ct_speak': 'Parece que repetiste la frase en vez de contestarla. Graba tu respuesta de nuevo.', 
'error.error_save_answer': 'Hubo un problema para guardar tu respuesta. Intenta confirmar la respuesta de nuevo', 
'error.error_start_record': 'Hubo un problema para acceder a tu micrófono. Intenta utilizar el botón grabar de nuevo', 
'error.error_stop_record': 'Hubo un problema para finalizar tu grabación. Intenta utilizar el botón grabar de nuevo', 
'error.error_word_alignment': 'La frase está incompleta. Intenta grabar nuevamente.', 
'error.generalConnectionError': 'Se ha detectado una inestabilidad de conexión. Por favor, haz de nuevo este ejercicio.', 
'error.generateUnitScore': 'Hubo un problema al generar tu nota.', 
'error.google_access_denied': '', 
'error.google_email_not_found': '', 
'error.google_invalid_user_role': '', 
'error.invalidEmail': 'Correo inválido', 
'error.kidsUser': 'Esta aplicación no es compatible con tu curso. Visita nuestra plataforma web para continuar. La Prueba de Nivel no está disponible en el teléfono celular. Acceso en: {link}', 
'error.noSpeechDetected': 'No fue posible detectar tu habla. Verifica tu micrófono, puede estar mudo o configurado con una sensibilidad muy baja. Intenta escuchar tu grabación para verificar el audio.', 
'error.payment_canceled': 'Compra cancelada', 
'error.payment_deferred': 'Compra no realizada por falta de permiso', 
'error.payment_error': 'No pudimos finalizar tu compra, verifica tus datos e intenta nuevamente', 
'error.placement_end': 'Tu resultado no pudo ser generado. Inténtalo de nuevo.', 
'error.placement_save_answer': 'No pudimos guardar tu respuesta. Inténtalo de nuevo.', 
'error.placement_start': 'No fue posible iniciar la Prueba de Nivel. Inténtalo de nuevo.', 
'error.practice_save_answer': 'No pudimos guardar tu respuesta. Inténtalo de nuevo.', 
'error.practice_start': 'No se pudo comenzar el calentamiento.', 
'error.refresh': 'Actualizar', 
'error.required': 'Obligatorio', 
'error.three_minute_block': 'Acabas de terminar el ejercicio. Ahora tienes que esperar 3 minutos para volver a hacerlo, pero puedes hacer cualquier otro ejercicio mientras tanto.', 
'error.tryAgain': 'Intentar nuevamente', 
'error.updateAvatarError': 'Hubo un problema para actualizar tu avatar.', 
'error.updateProfileError': 'Hubo un problema para actualizar tu perfil.', 
'grades.averageSq': 'Media de SQ: <b>{grade}!</b>', 
'grades.awaitingSq': 'Esperando nota de SQ', 
'grades.classRoom': 'Clase', 
'grades.closed': 'Definitiva', 
'grades.excellent': 'Excelente', 
'grades.finalGrade': 'Nota Final', 
'grades.good': 'Bien', 
'grades.maximumGrade': 'Nota máxima:', 
'grades.maximumSchoolGrade': 'Nota máxima de la escuela: {grade}', 
'grades.moderate': 'Moderado', 
'grades.noData': 'Tus notas aún no están disponibles.', 
'grades.preview': 'Previa', 
'grades.requiredStudyTime': 'Tiempo de estudio exigido: <b>{time}</b>', 
'grades.school': 'Escuela:', 
'grades.student': 'Alumno:', 
'grades.teacher': 'Profesor:', 
'grades.teachers': 'Profesores:', 
'grades.time': 'Tiempo  ({percentageRelevance}%)', 
'grades.title': 'Notas - {year}', 
'grades.titleHeader': 'Tus Notas', 
'grades.totalStudyTime': 'Tiempo total de estudio: <b>{time}</b>', 
'grades.veryGood': 'Muy bien', 
'grades.veryWeak': 'Muy insuficiente', 
'grades.weak': 'Insuficiente', 
'grades.weekToSqScore': '{count} semanas para la nota de SQ', 
'grades.yourSchoolGrade': 'Tu nota en la escuela: {grade}', 
'greeting.f': 'Hola {name}, Bienvenida :)', 
'greeting.m': 'Hola {name}, Bienvenido :)', 
'help.AUDIO_LONG': 'Escucha atentamente el audio para responder a los siguientes ejercicios.', 
'help.DIALOGUE': 'Lee los mensajes y selecciona la mejor opción para continuar el diálogo.', 
'help.DIALOGUE.typing': 'Escribiendo...', 
'help.DIALOGUE_OPTION': 'Lee los mensajes y selecciona la mejor opción para continuar el diálogo.', 
'help.DIALOGUE_OPTION.question': '¿Qué opción es la correcta para continuar el diálogo? Graba tu respuesta.', 
'help.DIALOGUE_OPTION_SPEECHLESS.question': '¿Qué opción es la correcta para continuar el diálogo?', 
'help.DICTATION': 'Escucha atentamente el audio y <b>escribe</b> la oración en el campo a continuación.', 
'help.GAP_FILL': 'Graba la frase <b>completa</b> con la opción correcta.', 
'help.GAP_FILL_MULTIPLE': 'Completa la frase <b>haciendo clic</b> en las palabras correctas.', 
'help.GAP_FILL_MULTIPLE_DRAG_AND_DROP': 'Completa la frase <b>arrastrando</b> las palabras correctas hasta los espacios disponibles.', 
'help.GAP_FILL_SELECT': 'Completa la frase <b>seleccionando</b> una de las opciones disponibles.', 
'help.GAP_FILL_SPEECHLESS': 'Completa la frase <b>seleccionando</b> una de las opciones disponibles.', 
'help.GRAMMAR_CHECK': 'Lee atentamente la frase presentada para identificar el error.', 
'help.GRAMMAR_CHECK.correction_phrase': 'Corrige la frase cambiando las palabras o expresiones incorrectas.', 
'help.IMAGE': 'Analiza la imagen cuidadosamente para responder a las siguientes preguntas.', 
'help.MEANINGS_ASSOCIATING': 'Empareja cada término haciendo clic en la palabra y en el campo correspondiente de su definición.', 
'help.MULTIPLE_CHOICE_TEXT': 'Escucha la pregunta y marca las opciones correctas.', 
'help.PRESENTATION': 'Utiliza los botones Repetir, Grabar y Escuchar para grabar tu voz repitiendo el audio que escuchaste.', 
'help.PRESENTATION_SPEECHLESS': 'Organiza la frase <b>arrastrando</b> las palabras correctas hasta los espacios disponibles.', 
'help.PRONUNCIATION': 'Utiliza el botón Grabar para <b>decir el texto</b> que está a continuación.', 
'help.PRONUNCIATION_SPEECHLESS': 'Completa la frase <b>seleccionando</b> una de las opciones disponibles.', 
'help.SINGLE_CHOICE_AUDIO': 'Escucha la pregunta y <b>haz clic</b> en la opción correcta.', 
'help.SINGLE_CHOICE_IMAGE': 'Escucha la pregunta y <b>haz clic</b> en la opción correcta.', 
'help.SINGLE_CHOICE_TEXT': 'Escucha la pregunta y <b>haz clic</b> en la opción correcta.', 
'help.SPEECH_PRACTICE': '<b>Escucha</b> atentamente y utiliza Grabar para <b>repetir</b> el audio.', 
'help.SPEECH_PRACTICE_SPEECHLESS': 'Escucha atentamente el audio y <b>escribe</b> la oración en el campo a continuación.', 
'help.TEXT': '<b>Lee el texto atentamente</b> y después utiliza el botón Siguiente para continuar.', 
'help.TRUE_FALSE': 'Escucha la frase y <b>haz clic</b> en verdadero o falso.', 
'help.UNSCRAMBLE_DRAG_AND_DROP': 'Organiza la frase <b>arrastrando</b> las palabras correctas hasta los espacios disponibles.', 
'help.UNSCRAMBLE_DRAG_AND_DROP_CLICK': 'Organiza la frase <b>haciendo clic</b> en las palabras correctas para completar los espacios disponibles.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION': 'Organiza la frase utilizando el botón Grabar para <b>grabar la frase</b> en la secuencia correcta.', 
'help.UNSCRAMBLE_SPEECH_RECOGNITION_SPEECHLESS': 'Organiza la frase <b>arrastrando</b> las palabras correctas hasta los espacios disponibles.', 
'help.UNSCRAMBLE_TEXT': 'Ordena los párrafos para estructurar el texto en su secuencia correcta.', 
'help.VIDEO_LONG': 'Mira el video para responder a los siguientes ejercicios. Si es necesario, puedes repetir el video una vez.', 
'help.VOCABULARY_ACADEMIC': '<b>Haga clic</b> en la imagen y luego grabe la frase que escuche.', 
'help.VOCABULARY_ACADEMIC_SPEECHLESS': 'Organiza la frase <b>arrastrando</b> las palabras correctas hasta los espacios disponibles.', 
'history.averageSR': 'Media de SR', 
'history.byContent': 'Tu histórico por contenido', 
'history.byDate': 'Tu histórico por fecha', 
'history.corrects': 'Correctas', 
'history.course': 'Curso', 
'history.date': 'Fecha', 
'history.module': 'Módulo', 
'history.noContentData': 'No tienes histórico por contenido.', 
'history.noData': 'Histórico no disponible.', 
'history.noDateData': 'No tienes histórico por fecha.', 
'history.notFinished': 'No finalizada', 
'history.points': 'Puntos', 
'history.rankingPosition': 'Posiciones en el Ranking', 
'history.score': 'Nota', 
'history.studiedTime': 'Tiempo de estudio', 
'history.title': 'Histórico', 
'history.type': 'Tipo', 
'history.unit': 'Unit', 
'home.accessCourse': 'Accede a tu curso', 
'home.currentRulerLevel': '¡Tu nivel actual!', 
'home.firstCourse': 'Recién empezaste, sigue estudiando para desbloquear nuevos cursos.', 
'home.initialRulerLevel': '¡Empezaste aquí!', 
'home.reviewCourses': 'Revisa tus cursos anteriores', 
'home.welcome.F': 'Bienvenida, {name} :)', 
'home.welcome.M': 'Bienvenido, {name} :)', 
'hours': 'Horas', 
'imageCrop.cancel': 'Cancelar', 
'imageCrop.changeLabel': 'Cambiar foto', 
'imageCrop.confirm': 'Confirmar', 
'instructions': 'Instrucciones', 
'intro.done': 'Concluir', 
'intro.gotIt': 'Entendí', 
'intro.next': 'Avanzar', 
'intro.previous': 'Volver', 
'intro.skip': 'Saltar', 
'intro.steps.controlBar1': 'Botón para repetir el audio. ¡Recuerda que solo podrás usarlo una vez!', 
'intro.steps.controlBar2': 'Tiempo máximo para cada pregunta. Recuerda que el tiempo empieza a contar algunos segundos después de cargar el ejercicio.', 
'intro.steps.controlBar3': 'Una vez completada la frase, utiliza el botón Siguiente para pasar al siguiente ejercicio. Recuerda que el tiempo de ejercicio continuará hasta que hagas clic en el botón Siguiente.', 
'intro.steps.controlBarRepeat': 'Botón para repetir el audio. ¡Recuerda que solo podrá ser usado una vez!', 
'intro.steps.controlBarTimer': 'Tiempo máximo para cada pregunta. El conteo comienza despues de cargar el ejercicio.', 
'intro.steps.gapFill1': 'El texto está incompleto. Es necesario completarlo con una de las opciones.', 
'intro.steps.gapFill2': 'Haz clic y elige una de las opciones.', 
'intro.steps.gapFillMultiple1': 'El texto está incompleto. Es necesario completarlo con las opciones correctas.', 
'intro.steps.gapFillMultiple2': 'Estas son las opciones. ¡Haz clic en los ítens para armar el texto!', 
'intro.steps.gapFillMultiple2_drag': 'Estas son las opciones. Arrástralas para armar el texto!', 
'intro.steps.gapFillSelect': 'Haz clic para ver las opciones disponibles y elige una para completar la frase.', 
'intro.steps.singleChoiceAudio': 'Escucha el audio y selecciona una de las opciones correspondientes.', 
'intro.steps.singleChoiceImage': 'Escucha el audio y selecciona la imagen correspondiente.', 
'intro.steps.singleChoiceText': 'Escucha el audio y selecciona el ítem correspondiente.', 
'intro.steps.unscrambleDragDrop1': 'Aqui será necesario descifrar la frase a partir de las opciones disponibles.', 
'intro.steps.unscrambleDragDrop2': 'Estas son las opciones. Haz clic en los ítens para armar el texto', 
'intro.steps.unscrambleDragDrop2_drag': 'Estas son las opciones. ¡Arrástralas para armar el texto!', 
'introductionButton': 'Revisar instrucciones', 
'items.correctAnswer': 'Respuesta correcta:', 
'items.dictation.errorTitle': 'Verifica la lista abajo para entender los errores en tu frase:', 
'items.dictation.missingWordsError': 'En tu frase faltan {count} palabras', 
'items.dictation.moreWordsError': 'En tu frase hay {count} palabras que están de sobra.', 
'items.dictation.wordsWithTyposError': 'Palabras con errores en la forma de escribirlas: {words}.', 
'items.dictation.wrongWordsError': 'Palabra(s) equivocada(s): {words}', 
'items.placeholder.dictation': 'Escribe aquí...', 
'items.placeholder.freeSpeak': 'En {tiempo} segundos se iniciará automáticamente la grabación. Si quieres, puedes empezar antes.', 
'items.placeholder.gapFillSelect': 'Selecciona una opción', 
'items.speechRecognition.errorLowScore': 'No pudimos generar una nota para tu habla, por favor inténtalo de nuevo.', 
'items.speechRecognition.errorNoSpeech': 'No pudimos detectar tu habla, por favor inténtalo de nuevo.', 
'items.speechRecognition.historyTitle': 'Tus intentos', 
'items.speechRecognition.tryAgain': '¡Inténtalo de nuevo!', 
'listen': 'Escuchar', 
'listening': 'Escucha', 
'loadingExercises': 'Cargando el ejercicio', 
'loadingMasteryTest': 'Cargando la Prueba de Aprendizaje', 
'locked': 'Bloqueado', 
'login.backToLogin': 'Volver al login', 
'login.classroom_not_found': 'Tu usuario no está asociado a una sala.', 
'login.createAccount': 'Create account', 
'login.email': 'Correo', 
'login.email_not_found': 'Correo no encontrado.', 
'login.enter': 'Entrar', 
'login.forgotPassword': 'Olvidé mi contraseña', 
'login.forgotPasswordDescription': 'Informa tu correo en el campo de abajo y te enviaremos un correo con tu nueva contraseña.', 
'login.forgotPasswordPostDescription': 'Puedes cambiar la nueva contraseña en tu página de modificar perfil', 
'login.goToLogin': 'Acceder a la página de ingreso', 
'login.googleSignIn': '', 
'login.inactive_classroom': 'Sala en período inactivo.', 
'login.invalid_password': 'Contraseña inválida.', 
'login.limit_reached': 'Todas las cuentas disponibles ya fueron utilizadas.', 
'login.out_of_access_period': 'Periodo de acceso no válido', 
'login.out_of_demo_period': 'Tu período de prueba ha caducado.', 
'login.password': 'Contraseña', 
'login.payment_required': 'Existe una solicitud de pago abierta. Verifica tu correo o entra en contacto con nosotros.', 
'login.resetPassword': 'Enviar nueva contraseña', 
'login.resetPasswordSuccess': 'Tu nueva contraseña fue enviada por correo.', 
'login.student_contract_blocked': 'Oops, parece que tu acceso ha sido bloqueado. Ponte en contacto con tu profesor para obtener más información.', 
'login.unexpected_error': 'No fue posible realizar el login. Trata nuevamente en algunos instantes.', 
'login.user_disabled': 'Tu usuario está desactivado.   Verifica con tu institución.', 
'login.user_not_found_in_company': 'Correo no encontrado.', 
'login.user_not_found_in_distributor': 'Correo no encontrado.', 
'login.user_with_placement_in_classroom': 'Completaste tu Prueba de Nivel.', 
'masteryTest.approved': 'Aprobado', 
'masteryTest.approvedMessageDescription': 'Fuiste aprobado en la Prueba de Aprendizaje', 
'masteryTest.approvedMessageTitle': '¡Felicitaciones!', 
'masteryTest.button.back': 'Volver al módulo', 
'masteryTest.button.courseSummary': 'Consultar resumen del curso', 
'masteryTest.description': 'La prueba de aprendizaje sirve para validar los conocimientos adquiridos durante los estudios y tiene actividades similares a los ejercicios ya realizados. En caso de no aprobar, sólo hay que estudiar 5 unidades y podrás hacer la prueba de nuevo', 
'masteryTest.exercisesToUnlock.moreThenOneExercise': 'Quedan {total} ejercicios para superar la prueba', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndOneRevision': 'Quedan {total} ejercicios y 1 repaso obligatorio para aprobar el examen', 
'masteryTest.exercisesToUnlock.moreThenOneExerciseAndRevision': 'Faltan {totalExercises} ejercicios y {totalRevisions} revisiones obligatorias para aprobar la prueba', 
'masteryTest.exercisesToUnlock.moreThenOneRevision': 'Quedan {total} revisiones obligatorias para publicar la prueba', 
'masteryTest.exercisesToUnlock.moreThenOneRevisionAndOneExercise': 'Queda 1 ejercicio y {total} revisiones obligatorias para aprobar la prueba', 
'masteryTest.exercisesToUnlock.oneExercise': 'Queda 1 ejercicio para desbloquear la prueba', 
'masteryTest.exercisesToUnlock.oneExerciseAndMoreThenOneRevision': 'Queda 1 ejercicio y {total} revisiones obligatorias para aprobar la prueba', 
'masteryTest.exercisesToUnlock.oneExerciseAndRevision': 'Queda 1 ejercicio y 1 repaso obligatorio para aprobar la prueba', 
'masteryTest.exercisesToUnlock.oneRevision': 'Queda 1 reseña para publicar la prueba', 
'masteryTest.failed': 'Rehacer', 
'masteryTest.failedMessageDescription': 'No fue esta vez. <b>¡Intenta nuevamente!</b>', 
'masteryTest.failedMessageTitle': 'Bueno...', 
'masteryTest.loading': 'Calculando tu resultado', 
'masteryTest.locked': 'Bloqueado', 
'masteryTest.name': 'Prueba de Aprendizaje', 
'masteryTest.recommendedUnits': 'Haz al menos 5 ejercicios de nuevo. Aquí hay algunas sugerencias de lo que necesitamos mejorar:', 
'masteryTest.result.aboutYourPerformance': 'Sobre tu rendimiento', 
'masteryTest.result.congratulationsOnYourEfforts': 'Felicitaciones por tu esfuerzo.', 
'masteryTest.result.dontBeDiscouraged': 'No te desanimes.', 
'masteryTest.result.niceJob': 'Lo que hiciste bien', 
'masteryTest.result.whatToImprove': 'Qué mejorar', 
'masteryTest.result.yorResultOnMasteryTest': 'Resultado de tu Prueba de Aprendizaje', 
'masteryTest.result.youDidntPass': '¡No pasaste!', 
'masteryTest.result.youPass': '¡Pasaste!', 
'masteryTest.resultTitle': 'Tu resultado', 
'masteryTest.score': 'Puntuación: {score}', 
'masteryTest.scoreToPass': 'Puntuación necesaria: {score}', 
'masteryTest.start': 'Start', 
'masteryTest.timer.runningOut': 'El tiempo se acaba...', 
'masteryTest.timer.title': 'Tiempo para responder', 
'masteryTest.tooltip.approved': 'Has terminado la prueba.', 
'masteryTest.tooltip.failed': 'Necesita terminar <b>{unitsNeeded} ejercicios diferentes</b> para volver la prueba.', 
'masteryTest.tooltip.locked': 'Para desbloquear, completa {modulePercentageToActive}% de este módulo. Faltan <b>{requiredPoints} puntos</b>.', 
'masteryTest.tooltip.unlocked': 'Prueba de Aprendizaje disponible. Haz clic para iniciar.', 
'masteryTest.unlocked': 'Desbloqueado', 
'masteryTest.whatToImprove': '¿Qué mejorar?', 
'masteryTestStartExecutionModal.exit': 'Salir', 
'masteryTestStartExecutionModal.start': 'Comenzar', 
'masteryTestStartExecutionModal.title': 'La prueba consta de aproximadamente 20 preguntas, y después de iniciar la prueba, no será posible pausar. Si decides abandonar durante la ejecución, la prueba se considerará fallida y deberás estudiar 5 unidades para desbloquear un nuevo intento. ¿Estás seguro/a de que deseas iniciar la Prueba de Aprendizaje?', 
'meeting.button.join': 'Unirse a la reunión', 
'meeting.title': 'Tu próxima clase', 
'menu.contentVideo': 'Lessons', 
'menu.course': 'Curso', 
'menu.errorReport': 'Informar Error', 
'menu.exercises': 'Ejercicios', 
'menu.exercisesHelp': 'Entendiendo los ejercicios', 
'menu.grades': 'Boletin', 
'menu.history': 'Historial', 
'menu.logout': 'Salir', 
'menu.messages': 'Mensajes', 
'menu.microphoneTest': 'Prueba el micrófono', 
'menu.modules': 'Módulos', 
'menu.modulesHelp': 'Entendiendo los módulos', 
'menu.notifications': 'Notificaciones', 
'menu.privacyPolicy': 'Política de privacidad', 
'menu.profile': 'Perfil', 
'menu.ranking': 'Ranking', 
'menu.units': 'Ejercicios', 
'messages.messageToSupport': 'Para soporte', 
'messages.messageToTeacher': 'Para mi maestro', 
'messages.messageTypeSelection': '¿A quién quieres enviar el mensaje?', 
'messages.newMessage': 'Nuevo mensaje', 
'messages.newMessageTo': 'Nuevo mensaje para', 
'messages.noData': 'No tienes mensajes', 
'messages.notRead': 'No visualizado', 
'messages.placeholder': 'Mensaje', 
'messages.readAt': 'Visualizado el {readAt}', 
'messages.selectTeacher': 'Selecciona el profesor que recibirá el mensaje', 
'messages.send': 'Enviar', 
'messages.subjectPlaceholder': 'Asunto', 
'messages.successSendNewMessage': '¡Mensaje enviado!', 
'messages.successSent': '¡Mensaje enviado!', 
'messages.supportSubjectLabelOptions.answerExercise': 'Cómo responder al ejercicio', 
'messages.supportSubjectLabelOptions.certificationTest': 'Dudas sobre la prueba de certificación', 
'messages.supportSubjectLabelOptions.exercise': 'Dudas sobre el ejercicio', 
'messages.supportSubjectLabelOptions.exerciseAudio': 'Audio del ejercicio', 
'messages.supportSubjectLabelOptions.exerciseBlockedFunctionality': 'Función bloqueada durante el ejercicio', 
'messages.supportSubjectLabelOptions.exerciseImage': 'Imagen del ejercicio', 
'messages.supportSubjectLabelOptions.exerciseTranslation': 'Traducción del ejercicio', 
'messages.supportSubjectLabelOptions.learningTest': 'Dudas sobre la prueba de aprendizaje', 
'messages.supportSubjectLabelOptions.ranking': 'Dudas sobre el ranking', 
'messages.supportSubjectLabelOptions.speechRecognition': 'Reconocimiento de voz', 
'messages.supportSubjectLabelOptions.studyQuality': 'Dudas sobre la calidad del estudio', 
'messages.title': 'Mensajes', 
'messages.titleNewMessage': 'Enviar un nuevo mensaje', 
'microphoneTest.badScoreDescription': 'Sigue el tutorial en el próximo paso y verifica la <b>configuración de tu micrófono</b>.', 
'microphoneTest.badScoreTitle': '¡Lástima! Tus notas no han sido muy buenas.', 
'microphoneTest.button.checkTutorial': 'Verificar tutorial', 
'microphoneTest.button.close': 'Cerrar', 
'microphoneTest.button.skipTest': 'Configurar más tarde', 
'microphoneTest.button.start': 'Iniciar el ajuste', 
'microphoneTest.button.tryAgain': 'Intentarlo de nuevo', 
'microphoneTest.description': '¡Intenta decir las siguientes frases, ajustando tu micrófono hasta conseguir notas superiores a 80!', 
'microphoneTest.goodScoreDescription': 'Utilizando el menú de ayuda en la parte superior de esta página puedes volver aquí cuando quieras.', 
'microphoneTest.goodScoreTitle': '¡Muy bien! Tus notas son buenas.', 
'microphoneTest.phrasesTitle': 'Graba las frases a continuación:', 
'microphoneTest.title': '¿Vamos a calibrar tu micrófono?', 
'microphoneTest.tutorialDescriptionOne': 'Tu micrófono puede estar con una sensibilidad muy alta o baja, en ambos casos eso puede perjudicar tus notas en el reconocimiento de voz.', 
'microphoneTest.tutorialDescriptionTwo.part1': '¿Cómo configurarlo?', 
'microphoneTest.tutorialDescriptionTwo.part2': 'Puedes consultar el', 
'microphoneTest.tutorialDescriptionTwo.part3': 'paso a paso a través de este', 
'microphoneTest.tutorialDescriptionTwo.part4': ', o <b>ve el vídeo</b> de arriba para ver cómo mejorar la configuración de tu micrófono.', 
'microphoneTest.tutorialTitle': 'Alterando la sensibilidad del micrófono', 
'missingPermission.button.openSettings': 'Revisar configuración', 
'missingPermission.camera.description': 'Verifique las configuraciones y permita nuestro acceso a la cámara.', 
'missingPermission.media_library.description': 'Verifique las configuraciones y permita nuestro acceso a la biblioteca de fotos.', 
'missingPermission.microphone.description': 'Comprueba la configuración y permítenos acceder al micrófono', 
'module.access': 'Acceso', 
'module.availablePoints': '{points} de {available}', 
'module.button.enter': 'Entrar', 
'module.button.locked': 'Bloqueado', 
'module.button.review': 'Revisar', 
'module.conqueredPercentage': '{percentage}%', 
'module.conqueredPoints': '{points} Puntos', 
'module.firstAccessTooltip': 'Haz clic aquí', 
'module.lastStudied': 'Último estudio', 
'module.loading': 'Cargando los módulos', 
'module.moduleDetailDescription': 'Tu puntaje en el módulo', 
'module.nationalRanking': 'Ranking Nacional', 
'module.noStudy': 'Sin registro.', 
'module.percentageComplete': '({Porcentaje}% completado)', 
'module.score': 'Puntos', 
'module.tabTitle': 'Ejercicios', 
'module.title': 'Módulos', 
'module.totalPoints': 'de {points}', 
'module.weekTime': 'Tiempo de la semana', 
'month': 'Mes', 
'national': 'Nacional', 
'native': 'Nativo', 
'needHelp': '¿Necesitas ayuda?', 
'next': 'Next', 
'noInternet.awaitingMessage': 'En espera de conexión...', 
'noInternet.message': 'Estás desconectado. Verifica tu conexión.', 
'noInternet.title': 'Conectarse a internet', 
'notifications.intent.congratulations': '¡Felicidades!', 
'notifications.intent.informative': 'Informacion', 
'notifications.intent.motivational': '¡Sigue así!', 
'notifications.noData': 'No tienes notificaciones', 
'notifications.title': 'Notificaciones', 
'notifications.type.1HourToCompleteEvaluationPeriodTime': 'Solo te queda 1 hora para completar el tiempo en tu período de prueba.', 
'notifications.type.25PercentOfCourseCompleted': '25% del curso {courseName} fue terminado', 
'notifications.type.2HourToCompleteEvaluationPeriodTime': 'Te quedan 2 horas para completar el tiempo en tu período de prueba.', 
'notifications.type.30MinutesInTheDay': 'Has completado 30 minutos de estudio. ¡Sigue así!', 
'notifications.type.50PercentOfCourseCompleted': '50% del curso {courseName} fue terminado', 
'notifications.type.50PercentOfModuleCompleted': 'El 50% del módulo "{moduleName}" ({courseName}) se ha completado', 
'notifications.type.80PercentOfCourseCompleted': '80% del curso {courseName} fue terminado', 
'notifications.type.80PercentOfModuleCompleted': 'El 80% del módulo {moduleName} se ha completado.', 
'notifications.type.90PercentOfCourseCompleted': '90% del curso {courseName} fue terminado.', 
'notifications.type.90PercentOfModuleCompleted': 'El 90% del módulo {moduleName} se ha completado.', 
'notifications.type.achievement': 'Felicidades, eres el {position} de {month} en tu {location}', 
'notifications.type.certificationTestCreated': 'Tu Prueba de Certificacion ha sido creada.', 
'notifications.type.certificationTestEnabled': 'Tu Prueba de Certificacion está disponible para su realización.', 
'notifications.type.certificationTestReviewed': 'Tu Prueba de Certificacion ha sido revisada.', 
'notifications.type.certificationTestScheduled': 'Tu Prueba de Certificacion está programada para {scheduledTo}.', 
'notifications.type.closedRanking': 'El ranking de {month} ha terminado.', 
'notifications.type.firstReviewEnabled': 'Review disponible para la unit {unitName} del módulo {moduleName}.', 
'notifications.type.masteryTestEnabled': 'Desbloqueaste una Prueba de Aprendizaje en el módulo {moduleName}.', 
'notifications.type.masteryTestFailed': 'Completa {units} units más en el módulo {moduleName} para habilitar la Prueba de Aprendizaje de nuevo.', 
'notifications.type.masteryTestRetry': 'Has logrado los puntos necesarios para hacer de nuevo la Prueba de aprendizaje del módulo {moduleName}.', 
'notifications.type.moduleGroupEnabled': 'Acabas de desbloquear un nuevo grupo de módulos para el curso {courseName}.', 
'notifications.type.moduleListeningCompleted': 'Ha alcanzado el objetivo de Escucha en el módulo {moduleName}.', 
'notifications.type.moduleReadingCompleted': 'Has alcanzado el objetivo de Lectura en el módulo {moduleName}.', 
'notifications.type.moduleSpeakingCompleted': 'Ha alcanzado el objetivo de Habla en el módulo {moduleName}.', 
'notifications.type.moduleWritingCompleted': 'Ha alcanzado el objetivo de Escritura en el módulo {moduleName}.', 
'notifications.type.more50PointsToUnlockUnitGroup': '50 puntos más para desbloquear más unidades en el módulo {moduleName}.', 
'notifications.type.secondReviewEnabled': 'Second review disponible para la unit {unitName} del módulo {moduleName}.', 
'notifications.type.studyQualityScore10': 'Felicidades, tu Study Quality es muy buena.', 
'notifications.type.studyQualityScore15': 'Felicitaciones, has alcanzado la calificación más alta en el Study Quality.', 
'notifications.type.top10National': 'Felicidades, estás en el Top 10 de tu país.', 
'notifications.type.top10Regional': 'Felicidades, estás en el Top 10 de tu región.', 
'notifications.type.top10School': 'Felicidades, estás en el Top 10 de tu institución.', 
'notifications.type.top30School': 'Felicidades, has entrado en el Top 30 de tu institución.', 
'notifications.type.top50National': 'Felicidades, estás en el Top 50 de tu país.', 
'notifications.type.top50Regional': 'Felicidades, estás en el Top 50 de tu región.', 
'notifications.type.unitGroupEnabled': 'Desbloqueaste un nuevo grupo de units para el módulo {moduleName}.', 
'notifications.type.weekTimeCloseToFinish': 'Unos minutos para completar tu tiempo semanal. ¡Sigue así!', 
'notifications.type.weekTimeCompleted': '¡Felicidades! Has completado el tiempo semanal.', 
'otherOptions': 'Otras opciones', 
'payment.pendingPayment': 'Tiene un pago pendiente vencido el {date}. <a href="{link}" target="_blank" style="color: blue; text-decoration: underline">Haga clic aquí</a>  para pagar ahora.', 
'placementTest.advanced': 'Advanced', 
'placementTest.beginner': 'Beginner', 
'placementTest.button.continueToPlatform': 'Continuar', 
'placementTest.button.exit': 'Salir', 
'placementTest.button.repeatWarming': 'Repetir Calentamiento', 
'placementTest.button.startPlacement': 'Comenzar Prueba de Nivel', 
'placementTest.button.startWarm': 'Iniciar Calentamiento', 
'placementTest.button.tryAgain': 'Intentarlo de nuevo', 
'placementTest.button.watchVideo': 'Mira el video de arriba', 
'placementTest.completeProfile.subTitle': 'Completa tu registro', 
'placementTest.completeProfile.title': 'Perfil', 
'placementTest.completeProfileTitle': 'Antes de comenzar, vamos a completar tu perfil', 
'placementTest.description': 'Mira el vídeo abajo, te dará consejos importantes sobre cómo hacer la Prueba de Nivel.', 
'placementTest.endError': 'Hubo un problema al finalizar tu Prueba de Nivel. Haz clic en el botón de abajo para volver a intentarlo.', 
'placementTest.englishFluency': 'Fluidez en inglés', 
'placementTest.finished.description': 'Gracias por realizar la prueba de nivel. Para tener acceso a su resultado, póngase en contacto con el responsable de la prueba.', 
'placementTest.finished.title': 'Prueba finalizada', 
'placementTest.greeting': 'Bienvenido(a) {name}!', 
'placementTest.intermediate': 'Intermediate', 
'placementTest.introduction': 'Introducción', 
'placementTest.introduction.button.start': 'Iniciar el calentamiento', 
'placementTest.introduction.button.watchVideo': 'Mira el video arriba', 
'placementTest.introduction.description': 'Mira el video abajo. Tiene importantes consejos para hacer la prueba de nivel.', 
'placementTest.introduction.subTitle': 'Haz tu prueba de nivel', 
'placementTest.introduction.title': 'Introducción', 
'placementTest.levelSelect.beginner.description': 'Adecuado para aquellos que tienen una base de vocabulario en inglés.', 
'placementTest.levelSelect.beginner.title': 'Principiante', 
'placementTest.levelSelect.none.description': 'Comenzarás desde el principio, desde las primeras palabras.', 
'placementTest.levelSelect.none.title': 'Super Principiante', 
'placementTest.levelSelect.placement.description': 'Identifica tu nivel con nuestra prueba de nivel.', 
'placementTest.levelSelect.placement.title': 'Descubre tu nivel', 
'placementTest.levelSelect.subTitle': 'O toma nuestra prueba de nivel', 
'placementTest.levelSelect.title': 'Elige tu nivel de inglés', 
'placementTest.nextStep': 'Tu curso', 
'placementTest.pauseDescription1': 'Ya alcanzaste el nivel {level} ({course}).', 
'placementTest.pauseDescription2': 'Usa este tiempo para hacer una pausa. Respira hondo, y puedes continuar. El test continuará en 30 segundos, una vez que el temporizador pare.', 
'placementTest.pauseTitle': '¡Estás haciéndolo bien!', 
'placementTest.placement': 'Prueba de Nivel', 
'placementTest.placementResult.beginnerEnglishLevel': 'Principiante', 
'placementTest.placementResult.button.continue': 'Continuar', 
'placementTest.placementResult.button.exit': 'Salir', 
'placementTest.placementResult.correctCount': 'Aciertos', 
'placementTest.placementResult.englishLevel': 'Nivel de inglés', 
'placementTest.placementResult.englishLevelRuler': 'Tu nivel de acuerdo a la escala internacional:', 
'placementTest.placementResult.loading': 'Calculando tu resultado', 
'placementTest.placementResult.startingCourse': 'Empezarás en el curso', 
'placementTest.placementResult.subTitle': 'Mira tu puntaje', 
'placementTest.placementResult.title': 'Resultado de la prueba de nivel', 
'placementTest.placementResultCorrects': 'Aciertos', 
'placementTest.placementResultEnglishLevel': 'Nivel de Inglés', 
'placementTest.placementResultPoint': 'Puntuación', 
'placementTest.placementRulerTitle': 'Tu nivel de inglés según el estándar internacional', 
'placementTest.placementScore': 'Tu puntuación', 
'placementTest.placementScoreAnalysisFirst': 'Tu primera puntuación', 
'placementTest.placementScoreAnalysisNew': 'Tu nueva puntuación', 
'placementTest.placementTest.loading': 'Cargando la prueba', 
'placementTest.placementTest.title': 'Prueba de Nivel', 
'placementTest.practiceResult.badScoreDescription': 'Ya puedes empezar la prueba de nivel.  Si quieres, también puedes repetir el calentamiento.', 
'placementTest.practiceResult.badScoreTitle': 'Parece que estás listo.', 
'placementTest.practiceResult.button.retry': 'Repetir el calentamiento', 
'placementTest.practiceResult.button.start': 'Empezar la prueba de nivel', 
'placementTest.practiceResult.goodScoreDescription': 'Parece que estás listo para empezar la prueba de nivel. ¡Buena suerte!', 
'placementTest.practiceResult.goodScoreTitle': '¡Muy bien!', 
'placementTest.practiceResult.subTitle': 'Ahora vamos a descubrir tu nivel', 
'placementTest.practiceResult.title': 'Calentamiento finalizado', 
'placementTest.practiceTest.loading': 'Cargando el calentamiento', 
'placementTest.practiceTest.title': 'Calentamiento', 
'placementTest.profile': 'Perfil', 
'placementTest.register.duplicatedEmail': 'Correo electrónico ya registrado en el sistema. Consulta con tu profesor cómo acceder a la plataforma para realizar la prueba de nivel.', 
'placementTest.register.title': 'Bienvenido a tu prueba de nivel.', 
'placementTest.score': 'Resultado', 
'placementTest.startError': 'Hubo un problema al iniciar tu Prueba de Nivel. Haz clic en el botón de abajo para volver a intentarlo.', 
'placementTest.steps': '{from} de {to}', 
'placementTest.testPause.advancedLevel': '(avanzado)', 
'placementTest.testPause.greeting': '¡Muy bien!', 
'placementTest.testPause.greetingDescriptionPart1': 'Ya alcanzaste el', 
'placementTest.testPause.greetingDescriptionPart2': 'nivel {course}', 
'placementTest.testPause.intermediateLevel': '(intermediario)', 
'placementTest.testPause.pauseDescription': 'La prueba seguirá en 30 segundos, una vez que pare el temporizador.', 
'placementTest.testPause.pauseTitle': 'Vamos a hacer una pausa.', 
'placementTest.testPause.title': 'Prueba de Nivel', 
'placementTest.userDemoOnboarding.button': 'Ok', 
'placementTest.userDemoOnboarding.message': '<p>Esta prueba es solo una demostración de lo que harán tus alumnos, ¡no te preocupes por equivocarte!</p><p>Después de terminar, solo tienes que actualizar la página de tu perfil para acceder a cualquier curso a través del botón "Open Demo".</p>', 
'placementTest.warming': 'Calentamiento', 
'placementTest.warmingBadScore': 'Bien, has completado el calentamiento', 
'placementTest.warmingBadScoreDescription': 'Ahora puedes comenzar la Prueba de Nivel. Si lo deseas, también puede repetir el calentamiento.', 
'placementTest.warmingGoodScore': '¡Muy bien, concluiste el calentamiento!', 
'placementTest.warmingGoodScoreDescription': 'Pareces listo para iniciar la prueba de nivel. ¡Buena suerte!', 
'placementTestInvite.button.doLater': 'Ahora no', 
'placementTestInvite.button.startPlacement': 'Comenzar Prueba de Nivel', 
'placementTestInvite.description': 'Haz clic en el botón de abajo y comprueba cuánto has mejorado.', 
'placementTestInvite.subTitle': '¡Felicidades por tu tiempo estudiando inglés!', 
'placementTestInvite.title': 'Comprobemos tu progreso hasta ahora', 
'points': 'Puntos', 
'profile.account.birthDate': 'Fecha de nacimiento', 
'profile.account.button.back': 'Volver', 
'profile.account.button.save': 'Guardar', 
'profile.account.button.saveContinue': 'Guardar y Continuar', 
'profile.account.changeAvatar': 'Cambiar Foto', 
'profile.account.city': 'Ciudad', 
'profile.account.completeProfileTitle': 'Antes de comenzar, vamos a completar tu perfil', 
'profile.account.confirmPassword': 'Confirmar contraseña', 
'profile.account.contactPhone': 'Teléfono de contacto', 
'profile.account.currentPassword': 'Contraseña actual', 
'profile.account.email': 'Correo', 
'profile.account.error.email': 'El correo informado ya está en uso. Por favor usa otro correo.', 
'profile.account.error.general': 'No pudimos actualizar tus datos. Verifica los campos e intenta nuevamente.', 
'profile.account.error.password': 'Comprueba tu contraseña actual. Para cambiar tu contraseña debes informar tu contraseña actual y la nueva contraseña.', 
'profile.account.error.picture': 'No pudimos actualizar tu foto. Inténtalo de nuevo.', 
'profile.account.female': 'Femenino', 
'profile.account.gender': 'Sexo', 
'profile.account.institution': 'Institución', 
'profile.account.locale.english': 'Inglés', 
'profile.account.locale.label': 'Idioma', 
'profile.account.locale.portuguese': 'Portugués', 
'profile.account.locale.spanish': 'Español', 
'profile.account.male': 'Masculino', 
'profile.account.name': 'Nombre', 
'profile.account.newPassword': 'Nueva contraseña', 
'profile.account.nicknameTooltip': 'El nombre que será exhibido en la plataforma', 
'profile.account.notRankingParticipant': 'No deseo participar en el ranking {name}', 
'profile.account.passwordDescription': 'Para cambiar tu contraseña, introduce tu contraseña actual y la nueva contraseña deseada.', 
'profile.account.passwordQuestion': '¿Quieres cambiar tu contraseña?', 
'profile.account.passwordTitle': 'Cambiar contraseña', 
'profile.account.phone': 'Teléfono', 
'profile.account.rankingDescription': 'Los puntos recibidos mientras no se participa en la clasificación no serán registrados.', 
'profile.account.rankingParticipant': 'Deseo participar en el ranking {name}', 
'profile.account.school': 'Escuela', 
'profile.account.success.picture': 'Tu foto ha sido actualizada.', 
'profile.account.success.profile': 'Tus datos se han actualizado correctamente.', 
'profile.account.title': 'Tus datos', 
'profile.achievement.noData': 'No posees logros.  ¡Continúa estudiando para ganar tu primer medalla!', 
'profile.achievement.noNationalData': 'Sin logros nacionales', 
'profile.achievement.noRegionalData': 'Sin logros regionales', 
'profile.achievement.noSchoolData': 'Sin logros institucionales', 
'profile.achievement.noStudentData': 'Sin logros personales', 
'profile.achievement.title': 'Logros', 
'profile.history.noData': 'Histórico no disponible.', 
'profile.history.tableTitle': 'Posiciones en el Ranking', 
'profile.history.title': 'Histórico', 
'profile.performance.noData': '¡Continúa estudiando para generar tu gráfico de desempeño!', 
'profile.performance.noHoursMonthChartData': 'Sin horas de estudio en el mes {month}', 
'profile.performance.noHoursWeekChartData': 'Sin horas de estudio en la semana {week}', 
'profile.performance.noPointsMonthChartData': 'Sin puntos logrados en el mes {month}', 
'profile.performance.noPointsWeekChartData': 'Sin puntos logrados en la semana {week}', 
'profile.performance.title': 'Desempeño', 
'profile.progressTimeline': 'Línea de tiempo', 
'profile.sprint.noData': '¡Continúa estudiando en días seguidos para generar tus sprints!', 
'profile.sprint.title': 'Histórico de Sprint', 
'profile.timelineApprovedMasteryTest': 'Prueba de Aprendizaje Aprobada', 
'profile.timelineBonusTestCompleted': 'Test Bonus completado', 
'profile.timelineCertificationTestCompleted': 'Concluiste la Prueba de Certificación', 
'profile.timelineCourseStarted': 'Curso iniciado', 
'profile.timelineDailyRecord': 'Ganaste <b>{points}</b> puntos', 
'profile.timelineFailedMasteryTest': 'Prueba de Aprendizaje Reprobada', 
'profile.timelineModuleCompleted': 'Completaste el módulo <b>{moduleName}</b>', 
'profile.timelineNoData': 'Empieza a estudiar para generar tu línea de tiempo.', 
'profile.timelinePlacementTestLevel': 'Alcanzaste el nivel <b>{level}</b> en la Prueba de Nivel', 
'profile.title': 'Perfil', 
'profile.updateProfileLink': 'Modificar perfil', 
'profile.yourWeekTime': 'Mi tiempo semanal', 
'ranking.cardCheckFullData': 'Ver el ranking completo', 
'ranking.cardNoData': 'Ranking no disponible', 
'ranking.currentSchoolPosition': 'Your School Position', 
'ranking.firstSemester': 'Primer semestre', 
'ranking.monthSelectLabel': 'Selecciona el mes', 
'ranking.nationalPosition': 'Ranking Nacional', 
'ranking.noData': 'El ranking aún no está disponible, se el primero en estudiar y habilitar el ranking.', 
'ranking.noPositionHistory': 'No position history', 
'ranking.positionHistory': 'Position History', 
'ranking.rankingPoints': '{points} puntos', 
'ranking.regionalPosition': 'Regional position', 
'ranking.rewards.ranking': 'Puntos de ranking', 
'ranking.rewards.time': 'Objetivo de estudio semanal', 
'ranking.rewards.title': 'Tu recompensa', 
'ranking.schoolPosition': 'School position', 
'ranking.secondSemester': 'Segundo semestre', 
'ranking.title': '{month} Ranking', 
'ranking.topOfAllTime': 'Top de todos los tiempos', 
'ranking.topOfMonth': 'Top de', 
'ranking.topOfSemester': 'Top del Semestre', 
'ranking.topOfYear': 'Top del Año', 
'ranking.yearSelect': 'Año de', 
'reading': 'Lectura', 
'record': 'Grabar', 
'regional': 'Región', 
'repeat': 'Repetir', 
'requestPermission.button.allow': 'Permitir', 
'requestPermission.camera.description': 'Necesitamos tu confirmación para acceder, procesar, recopilar y almacenar tu foto de perfil. Tu foto de perfil es pública y será visible en la clasificación.', 
'requestPermission.camera.title': 'Acceso a la cámara', 
'requestPermission.media_library.description': 'Necesitamos tu confirmación para acceder, procesar, recopilar y almacenar la foto de perfil que has elegido. Tu foto de perfil es pública y será visible en la clasificación.', 
'requestPermission.media_library.title': 'Acceso a la biblioteca', 
'requestPermission.microphone.description': 'Necesitamos tu confirmación para acceder, procesar, recopilar y almacenar los audios grabados como respuestas a los ejercicios realizados en la plataforma.', 
'requestPermission.microphone.title': 'Acceso al micrófono', 
'requireDesktop.hasAppCard.description': 'Usa el botón de abajo para abrir la aplicación.', 
'requireDesktop.hasAppCard.title': '¿Ya tienes instalada la aplicación?', 
'requireDesktop.installAppCard.step1': 'Instala la aplicación para tu teléfono.', 
'requireDesktop.installAppCard.step2': 'Usa el botón de abajo para abrir la aplicación.', 
'requireDesktop.installAppCard.title': '¿Aún no lo has instalado?', 
'requireDesktop.open': 'Abre la app', 
'requireDesktop.placementDemo': 'Comenzar nivelación', 
'requireDesktop.title': '¡Uy! Necesitas usar nuestra aplicación', 
'requireDesktop.unitDemo': 'Iniciar demostración', 
'school': 'Institucional', 
'scoreToPass': 'Puntuación necesaria', 
'slowRepeat': 'Repetir Lentamente', 
'speaking': 'Habla', 
'sprint.classAverage': 'Promedio de clase', 
'sprint.currentSprint': 'Tu secuencia actual', 
'sprint.description': 'Días seguidos estudiando', 
'sprint.maximumSprint': 'Tu secuencia máxima', 
'sprint.no.classAverage': 'Sin promedio de clase', 
'sprint.no.currentSprint': 'Sin secuencia actual', 
'sprint.no.maximumSprint': 'Sin secuencia máxima', 
'sprint.no.schoolAverage': 'Sin promedio de institución', 
'sprint.noData': '¡Continúa estudiando en días seguidos para generar tus sprints!', 
'sprint.schoolAverage': 'Promedio institucional', 
'sprint.title': 'Sprint', 
'startIntroductionButton': 'Comenzar Introducción', 
'startTastingModal.button': 'Iniciar', 
'startTastingModal.firstDescription': 'Verifique que los auriculares o el altavoz funcionen antes de comenzar la demostración, ya que las instrucciones se proporcionarán por audio. Síguelos para vivir la experiencia completa.', 
'startTastingModal.secondDescription': '¡Vamos allá!', 
'startTastingModal.title': '¡Hola bienvenido!', 
'student': 'Personal', 
'studyQuality.classAverage': 'Promedio de la clase', 
'studyQuality.classroom': 'Aula', 
'studyQuality.howToImprove': '¿Cómo puedes mejorarlo?', 
'studyQuality.howToImproveNoData': 'Actualmente no tenemos detalles de tu estudio. Sigue estudiando y generaremos la información para ayudarte.', 
'studyQuality.modalTitle': 'Calidad de Estudio', 
'studyQuality.name': 'Calidad de Estudio', 
'studyQuality.noData': 'Tu Calidad de Estudio se genera después de una hora de estudio.', 
'studyQuality.noEvaluationPeriod': 'No estás en un período de evaluación.', 
'studyQuality.requirements.DAYS_STUDIED': 'Estudiar al menos 7 días a cada 15 días', 
'studyQuality.requirements.DAYS_STUDIED_AT_LEAST_30_MINUTES': 'Cuando estudies, hazlo al menos por 30 minutos', 
'studyQuality.requirements.LISTEN_USAGE': 'Uso del botón Escuchar', 
'studyQuality.requirements.READING_USAGE': 'No uses en exceso el botón Lectura', 
'studyQuality.requirements.REPEAT_RECORD_LISTEN_RELATION': 'Usar 2x más el botón Repetir que el de Escuchar y Hablar', 
'studyQuality.requirements.REPEAT_USAGE': 'Uso del botón Repetir', 
'studyQuality.requirements.SINGLE_CHOICE_AVERAGE_SCORE': 'Respuestas correctas', 
'studyQuality.requirements.SPEECH_RECOGNITION_AVERAGE_SCORE': 'Nota media en Speech Recognition', 
'studyQuality.requirements.TIME_STUDIED': 'Estudiar el tiempo semanal requerido', 
'studyQuality.requirements.TRANSLATE_USAGE': 'No uses en exceso el botón Traducir', 
'studyQuality.requirements.UNIT_AVERAGE_SCORE': 'Nota media en las units', 
'studyQuality.studentScore': 'Tu puntuación', 
'studyQuality.title': 'Histórico de Calidad de estudio', 
'studyQuality.yours': 'Mío', 
'subMenu.courseProgress': 'Tu progreso', 
'subMenu.masteryTestDescription': 'Prueba de Aprendizaje del módulo {moduleName}', 
'subMenu.weekTime': 'Tiempo semanal', 
'survey.button.doLater': 'Más tarde', 
'survey.button.start': 'Responder ahora', 
'survey.description': 'Queremos saber tu opinión. Puedes responder en menos de 1 minuto. Haz clic en el botón de abajo para comenzar. ¡Agradecemos tu participación!', 
'survey.title': '¿Qué opinas sobre estudiar inglés con nuestra app?', 
'tastingContact.company': 'Empresa*', 
'tastingContact.country': 'País*', 
'tastingContact.description': 'Por favor complete el siguiente formulario para continuar.', 
'tastingContact.email': 'Correo*', 
'tastingContact.fullName': 'Nombre*', 
'tastingContact.message': 'Mensaje', 
'tastingContact.name': 'Nombre*', 
'tastingContact.phone': 'Telefono/Skype', 
'tastingContact.saveAndContinue': 'Probar ahora', 
'tastingContact.title': 'Prueba {title}', 
'tastingContact.whatsApp': 'WhatsApp*', 
'timer': 'Cronómetro', 
'translate': 'Traducir', 
'unit.abilities': 'Habilidades', 
'unit.blockedDueTime': 'Bloqueado por 3 minutos', 
'unit.button.continue': 'Continuar', 
'unit.button.locked': 'Bloqueado', 
'unit.button.play': 'Ver', 
'unit.button.recap': 'Iniciar', 
'unit.button.replay': 'Repetir', 
'unit.button.start': 'Iniciar', 
'unit.button.watch': 'Ver', 
'unit.button.watch_again': 'Ver nuevamente', 
'unit.challengeTooltip': 'Haz clic en iniciar para tomar el Desafio y ganar esos puntos.', 
'unit.contentVideoStep.available': 'Disponible', 
'unit.contentVideoStep.blocked': 'Bloqueado', 
'unit.contentVideoStep.completed': 'Finalizado', 
'unit.contentVideoStep.title': 'Progreso de la lección', 
'unit.enunciationsModal.actions.back': 'Volver', 
'unit.enunciationsModal.actions.start': 'Comenzar', 
'unit.enunciationsModal.content': 'Contenido', 
'unit.enunciationsModal.grammars': 'Gramáticas', 
'unit.enunciationsModal.skills': 'Habilidades', 
'unit.firstAccessTooltip': 'Haz clic aquí', 
'unit.group.A': 'Fase 1', 
'unit.group.B': 'Fase 2', 
'unit.group.C': 'Fase 3', 
'unit.group.D': 'Fase 4', 
'unit.group.E': 'Fase 5', 
'unit.optionalReview': '', 
'unit.points': '{points} Puntos', 
'unit.requiredReview': 'Obligatorio', 
'unit.reviewTooltip': 'Debes realizar la Revisión para completar los pasos de este ejercicio. Haz clic en iniciar para terminarlo.', 
'unit.reward.conqueredTitle': 'Obtuvo', 
'unit.reward.title': 'Gana hasta', 
'unit.simpleReviewTooltip': 'Puedes revisar esta unit 3 veces, y consquistar {amountOfPoints} puntos cada vez.', 
'unit.status.continue': 'Continuar', 
'unit.status.first_review': 'Revisión', 
'unit.status.simple_review': 'Rehacer', 
'unit.status.your_challenge': 'Desafío', 
'unit.steps.description': 'Cada ejercicio consta de dos etapas: Desafío y Revisión. Cuando se completa la etapa de Desafío, la etapa de Revisión se desbloquea al día siguiente.', 
'unit.steps.exercise': 'Ejercicio', 
'unit.steps.first_review': 'Revisar', 
'unit.steps.title': 'Pasos del ejercicio', 
'unit.steps.your_challenge': 'Desafío', 
'unit.title': 'Ejercicios', 
'unit.unblockReviewIn': 'Se publicará la revisión mañana', 
'unitExecutionNotification.3_CHECKPOINT_SEQUENCE': [ 
'¡Tu esfuerzo esta valiendo la pena! ¡Felicitaciones!', 
'¡Impresionante, sabes demasiado!', 
'¡Oh, qué rápido aprendes! ¡Bien hecho!', 
'¡Secuencia increíble de aciertos! ¡Felicitaciones!', 
'¡Buenísimo, sigue por el buen camino!', 
], 
'unitExecutionNotification.3_SPEECH_RECOGNITION_SEQUENCE': [ 
'¡Excelente! ¡Sabes mucho de ingles!', 
'¡Triple dosis de pronunciación increíble! ¡Felicitaciones!', 
'¡No te cansas de ser increíble!', 
'¡Acertaste 100% esa pronunciación!', 
'¡Tu capacidad para hablar ingles es asombrosa!', 
'¡Podría escuchar tu inglés durante horas! ¡Fascinante!', 
], 
'unitExecutionNotification.6_CHECKPOINT_SEQUENCE': [ 
'¡Guau, qué concentración!', 
'¡Impresionante, eres demasiado bueno! ¡No es justo!', 
'¡Guau, estás dominando todo!', 
'¡Sólo tratas de engañarme diciendo que no sabes inglés!', 
'¡Damas y caballeros, pónganse de pie y aplaudan!', 
], 
'unitExecutionNotification.6_SPEECH_RECOGNITION_SEQUENCE': [ 
'¡Tu pronunciación es maravillosa! ¡Increíble!', 
'¡Conseguirás lo que quieras con esa pronunciación!', 
'¡Guau! ¡Otra pronunciación excelente!', 
'¡Esa pronunciación fue muy buena! ¡Sigue así!', 
'Puedes confesar ahora. Ya sabías hablar ingles, ¿cierto?', 
'¡Increíble, tu pronunciación es sensacional!', 
'El inglés y tú fueron hechos el uno para el otro.', 
'¡Cupido pasó por aquí! ¡Tú y el ingles ya están en una relación seria!', 
], 
'unitExecutionNotification.9_CHECKPOINT_SEQUENCE': [ 
'¡Eres un genio!', 
'¡Que espectáculo de conocimiento!', 
'¡Simplemente no cometes errores! ¡Impresionante!', 
'¡Ya puedes considerarte un gurú del ingles!', 
'¡Tu nivel de conocimiento es espectacular!', 
'¡Tu desempeño es de otro mundo! ¡Fantástico!', 
], 
'unitExecutionNotification.9_SPEECH_RECOGNITION_SEQUENCE': [ 
'¡Estás hablando como un experto!', 
'¡Hablas increíblemente bien!', 
'Bueno, bueno, bueno... ¡parece que tenemos un pequeño genio aquí!', 
'¡Guau! ¿Por casualidad naciste hablando ingles?', 
'¿Hasta donde irá esta racha increíble? ¡Estoy impresionado!', 
'¡Impresionante racha! ¡Eres increíble!', 
'Estoy seguro de que podrías hablar fácilmente con un norteamericano, ¡sin problemas!', 
'¡Ya eres prácticamente un maestro del ingles!', 
'¡Increíble! ¡A partir de ahora hablaremos only in English please!', 
'¡Que emoción! ¡Mi pequeño Padawan ya es casi un Jedi de ingles!', 
'Ey, ¿cuánto cobras para dar clases de ingles?', 
], 
'unitExecutionNotification.LISTEN_USAGE_TIP': [ 
'Aumenta tu Study Quality utilizando más veces el botón Escuchar.', 
'Ey, no te olvides de usar el botón Escuchar para escuchar tu pronunciación.', 
'Escuchar tu propia pronunciación potencializa tu aprendizaje.', 
'El botón Escuchar ayuda a prestar atención a tu propia pronunciación. ¡No te olvides de usarlo!', 
'Escucharte a ti mismo ayuda a aprender mejor. Utiliza el botón Escuchar mas veces.', 
], 
'unitExecutionNotification.MAX_CHECKPOINT_SEQUENCE': [ 
'¿Tenemos un norteamericano por acá?', 
'¡Naciste en los EE.UU! ¡No me digas que no!', 
'¡Con ese nivel de conocimiento ya podrías convertirte en ciudadano norteamericano!', 
'¿Será que eres el Einstein de los idiomas?', 
'¡Sabes mucho, ni un norteamericano desconfiaría de tu nacionalidad!', 
], 
'unitExecutionNotification.MAX_SPEECH_RECOGNITION_SEQUENCE': [ 
'Guau, ya eres prácticamente un norteamericano.', 
'¡Confiesa ya! El inglés es tu lengua materna, ¿cierto?', 
'¡Apuesto que naciste diciendo hello y no llorando!', 
'¡Llegaste al nivel Jedi, que la pronunciación este contigo!', 
'¡S-E-N-S-A-C-I-O-N-A-L! ¡Estás dominando la pronunciación inglesa!', 
'¡Nadie me avisó que estaba hablando con un ciudadano norteamericano!', 
'De acuerdo, no hace falta que nos muestres tu pasaporte, ¡ya sabemos que eres estadounidense!', 
'¡Guau!, ¿Desde hace cuánto tiempo vives en los EE.UU?', 
'Si me dices que no hablas inglés con fluidez, ¡no me lo creo!', 
'¡No tienes limites! ¡Si sigues así, te contrataremos para trabajar con nosotros!', 
'¡Así no es divertido! No estaba preparado para hablar con alguien que habla inglés fluido.', 
'Verifiqué tu identidad y no dice que hayas nacido en EE.UU. ¿Hay algún error?', 
], 
'unitExecutionNotification.NO_LISTEN_SEQUENCE': [ 
'¡No pierdas la energía, escucha tu pronunciación y será mas fácil acertar!', 
'Los botones de soporte te hacen la vida mas fácil y te ayudan a aprender. ¡Úsalos!', 
'Escucha tu pronunciación y compárala con la del hablante fluido.', 
'Si tienes alguna dificultad, no te olvides de escucharte.', 
'Escucharte a ti mismo mejorará tu pronunciación en inglés.', 
'No te olvides de escucharte. Eso te va a ayudar.', 
'Antes de intentarlo nuevamente, escúchate para comparar tu pronunciación con el audio.', 
], 
'unitExecutionNotification.NO_REPEAT_SEQUENCE': [ 
'Escucha más veces el audio antes de intentarlo nuevamente.', 
'Usa el botón de Repetir para escuchar atentamente la pronunciación de la frase.', 
'Escucha nuevamente la frase para ayudarte a acertar.', 
'Si estas con alguna dificultad, solo utiliza los botones de soporte. ¡Aprovecha!', 
'Puedes repetir lentamente el audio de la frase si estas teniendo dificultad', 
'¡Los botones de soporte están ahí para ayudarte! ¡Úsalos!', 
'¡Escuchar la pronunciación con atención va a ayudarte! ¡Utiliza mas la opción Repetir!', 
'¿Que tal utilizar los botones de soporte para ayudarte?', 
], 
'unitExecutionNotification.REPEAT_USAGE_TIP': [ 
'Al usar mas veces la opción Repetir tu Study Quality mejorará.', 
'No te olvides de escuchar más veces la pronunciación en el audio.', 
'Escuchar más veces el audio ayudará a desarrollar tu pronunciación.', 
'Utiliza mas el botón Repetir. Eso acelera tu aprendizaje.', 
'Usa el botón Repetir para escuchar más veces la pronunciación en el audio.', 
], 
'unitRating.comment': '', 
'unitRating.question': '¿Te gustó esta unidad?', 
'unitRating.title': 'Deja tus comentarios', 
'unitResult.closeTasting': 'Cerrar', 
'unitResult.conclude': 'Concluir', 
'unitResult.congrats': '¡Felicidades!', 
'unitResult.continueToUnitList': 'Continuar a la lista de ejercicios', 
'unitResult.currentRanking': 'Ranking', 
'unitResult.keepPracticing': 'Sigue practicando', 
'unitResult.loading': 'Calculando tu resultado', 
'unitResult.newPoints': 'Tu nuevo puntaje', 
'unitResult.newRanking': 'Nuevo Ranking', 
'unitResult.next': 'Próximo', 
'unitResult.recommendedUnits': 'Unidad recomendada', 
'unitResult.reviewStep.didWell.button.no': 'No, saltaré', 
'unitResult.reviewStep.didWell.button.yes': 'Si, quiero revisar', 
'unitResult.reviewStep.didWell.reviewImportance': '¿Por qué es importante revisar?', 
'unitResult.reviewStep.didWell.subtitle': '¿Te gustaría repasar este ejercicio en unos días?', 
'unitResult.reviewStep.didWell.title1': '¡Lo hiciste muy bien!', 
'unitResult.reviewStep.didWell.title2': 'Su revisión será opcional.', 
'unitResult.reviewStep.firstReview.subtitle': '', 
'unitResult.reviewStep.firstReview.title': '', 
'unitResult.reviewStep.modal.button': 'Entendi', 
'unitResult.reviewStep.modal.paragraph1': 'Repasar es muy importante para consolidar los conocimientos que acabas de ver, y rellenar posibles lagunas que queden.', 
'unitResult.reviewStep.modal.paragraph2': '¡Esto se debe a que estudiar nuevamente en días diferentes te ayuda a registrar el contenido estudiado en tu memoria a largo plazo!', 
'unitResult.reviewStep.modal.paragraph3': 'Su reseña estará disponible mañana.', 
'unitResult.reviewStep.modal.title': '¿Por qué es importante revisar?', 
'unitResult.reviewStep.notWell.description': 'Se ha agregado una revisión a su lista y estará disponible mañana.', 
'unitResult.reviewStep.notWell.title': 'Vamos a intentar de nuevo', 
'unitResult.reviewStep.optionalReview.button.no': 'No', 
'unitResult.reviewStep.optionalReview.button.yes': 'Sí', 
'unitResult.reviewStep.optionalReview.subtitle': '¿Te gustaría intentarlo de nuevo?', 
'unitResult.reviewStep.optionalReview.title': 'Esta revisión era opcional.', 
'unitResult.reviewStep.title': 'Revisión', 
'unitResult.scoreToPass': 'Puntuación necesaria: {score}', 
'unitResult.yourScore': 'Tu nota en la unit', 
'unitResult.yourTotalPoints': 'Total de puntos', 
'updates.button.updateNow': 'Actualizar ahora', 
'updates.description': '¡Tenemos una actualización disponible para ti!', 
'userAway.button': '¡Sí, estoy estudiando!', 
'userAway.description': 'Parece que estás ausente de la plataforma, si estás estudiando haz clic en el botón de abajo para continuar.', 
'userAway.penalityLabel': 'Debido a la inactividad, perderás 30 segundos de tiempo de estudio en este ejercicio.', 
'userErrorReport.buttonsText.discardChanges': 'Descartar', 
'userErrorReport.buttonsText.submit': 'Enviar', 
'userErrorReport.errorMessages.apiError': 'No pudimos enviar tu reporte. Por favor, espera un momento e inténtalo de nuevo', 
'userErrorReport.errorMessages.studentNotFound': 'La dirección de correo no está registrada en la plataforma', 
'userErrorReport.labels.content': 'Cuéntanos lo que pasó', 
'userErrorReport.labels.email': 'Correo', 
'userErrorReport.labels.subject': 'Asunto', 
'userErrorReport.placeholders.content': 'No dudes en enviarnos tus sugerencias y comentarios', 
'userErrorReport.placeholders.email': 'Introduce tu dirección de correo registrada en la plataforma', 
'userErrorReport.placeholders.subject': 'Escribe aquí el motivo de tu reporte', 
'userErrorReport.title': 'Reportar un error', 
'userSnapButton': 'Informar error', 
'validate': 'Validar', 
'validations.Invalid email': 'Email inválido', 
'validations.Must Match Password': 'La confirmación debe coincidir con la contraseña', 
'validations.Required': 'Necesario', 
'videoIntroduction.button': 'Continuar', 
'videoIntroduction.home.title': 'Entiende tu curso', 
'videoIntroduction.module_list.title': 'Entiende tu curso', 
'videoIntroduction.unit_list.title': 'Conoce tus ejercicios', 
'videoIntroduction.welcome.title': 'Entiende tu curso', 
'videoPlayer.localesSelect': 'Seleccione un idioma para los subtítulos', 
'videoPlayer.noSubtitles': 'Desactivado', 
'week': 'Semana', 
'welcomeModal.description': 'Usa tu <b> sonido </b> y <b> micrófono </b> para realizar el ejercicio.', 
'welcomeModal.startButton': 'Vamos a empezar', 
'welcomeModal.title': 'Bienvenido a Flexge', 
'writing': 'Escritura', 
'you': 'Mío', 
}; 
export default labels;