import React from "react";
import MediaQuery from "react-responsive";
import Panel from "../../core/Panel";
import Tabs from "../../core/Tabs";
import AchievementPanel from "./AchievementPanel";
import Separator from "../../core/Separator";
import SprintHistoryPanel from "./SprintHistoryPanel";
import StudentSummaryContainer from "./StudentSummaryContainer";
import HistoryPanelContainer from "./HistoryPanelContainer";
import withTranslation from "../../core/withTranslation";
import PerformancePanel from "./PerformancePanel";
import TimeLinePanel from "./TimeLinePanel";
import StudentCourseListContainer from "./StudentCourseListContainer";
import StudentInformationContainer from "./StudentInformationContainer";

const StudentDetails = (props) => (
  <div>
    <Panel padding="none">
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            justifyContent: "space-between",
            boxSizing: "border-box",
            padding: "25px 35px",
          }}
        >
          <StudentInformationContainer />
          <StudentCourseListContainer />
        </div>
        <div
          className="hidden-xs stepPointsHours"
          style={{
            maxWidth: 130,
            minWidth: 70,
            width: "100%",
            height: 240,
          }}
        >
          <StudentSummaryContainer />
        </div>
      </div>
    </Panel>
    <MediaQuery minWidth={992}>
      <Separator size="md" />
      <TimeLinePanel />
    </MediaQuery>
    <MediaQuery maxWidth={991}>
      <Tabs
        tabs={[
          {
            label: "Status",
            component: (
              <div>
                <AchievementPanel />
                <Separator size="md" />
                <PerformancePanel />
                <Separator size="md" />
                <SprintHistoryPanel />
                <Separator size="md" />
                <MediaQuery minWidth={768}>
                  <HistoryPanelContainer />
                </MediaQuery>
              </div>
            ),
          },
          {
            label: props.getTranslation("profile.progressTimeline"),
            component: <TimeLinePanel />,
          },
        ]}
        headerStyle={{
          backgroundColor: "#fff",
          marginBottom: 20,
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
          borderRadius: 3,
          zIndex: 2,
          position: "relative",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          top: "-3px",
        }}
      />
    </MediaQuery>
  </div>
);

export default withTranslation(StudentDetails);
