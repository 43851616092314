import React, { Component } from "react";
import PropTypes from "prop-types";
import ceil from "lodash/ceil";
import omit from "lodash/omit";
import HighlightableItemBox from "./HighlightableItemBox";
import Separator from "../Separator";
import Icon from "../Icon";
import TranslatedText from "../TranslatedText";
import ExerciseItemPanel from "./ExerciseItemPanel";
import CourseThemeContext from "../../core/CourseThemeContext";

export default class DictationExerciseItem extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    answer: PropTypes.string,
    shouldShowCorrectOption: PropTypes.bool,
    extraData: PropTypes.shape({
      moreWords: PropTypes.number,
      missingWords: PropTypes.number,
      typos: PropTypes.arrayOf(PropTypes.string),
      wrongWords: PropTypes.arrayOf(PropTypes.string),
    }),
  };

  static defaultProps = {
    extraData: null,
    shouldShowCorrectOption: false,
  };

  state = {
    rows: 1,
  };

  componentDidUpdate() {
    this.dictationInput.focus();
  }

  handleKeyDown = (event) => {
    const key = event.keyCode || event.wich;
    if (key === 13) {
      event.preventDefault();
    }
    this.setState({
      rows: (event.target.scrollHeight / 38).toFixed(1),
    });
  };

  blockFeature = (event) => event.preventDefault();

  render() {
    return (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <ExerciseItemPanel>
            <HighlightableItemBox>
              <textarea
                spellCheck={false}
                autoCorrect="off"
                autoComplete="off"
                autoCapitalize="off"
                ref={(input) => {
                  this.dictationInput = input;
                }}
                value={this.props.shouldShowCorrectOption ? this.props.extraData.bestMatch : this.props.answer}
                onKeyDown={this.handleKeyDown}
                onCopy={this.blockFeature}
                onPaste={this.blockFeature}
                onCut={this.blockFeature}
                rows={
                  this.props.shouldShowCorrectOption
                    ? ceil(this.props.extraData.bestMatch.length / 38)
                    : this.state.rows
                }
                disabled={this.props.isDisabled}
                onChange={(event) => {
                  const value = event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
                  this.props.onChange("answer", value)
                }}
                style={{
                  border: "none",
                  borderBottom: "1px solid #cecece",
                  padding: "0px 5px",
                  fontSize: 30,
                  width: "100%",
                  lineHeight: "38px",
                  outline: "none",
                  fontWeight: 400,
                  color: "#607d8b",
                  ...(this.props.shouldShowCorrectOption && {
                    color: primary,
                  }),
                  opacity: this.props.isDisabled ? 0.7 : 1,
                  backgroundColor: "#fff",
                  resize: "none",
                  overflow: "hidden",
                }}
              />
            </HighlightableItemBox>
            <Separator />
            {this.props.extraData && !!Object.keys(omit(this.props.extraData, ["bestMatch"])).length && (
              <div
                style={{
                  paddingLeft: 5,
                }}
              >
                <TranslatedText
                  as="p"
                  translateKey="items.dictation.errorTitle"
                  style={{
                    color: "#90a4ae",
                    fontSize: 16,
                    margin: 0,
                  }}
                />
                <ul
                  style={{
                    listStyle: "none",
                    paddingLeft: 5,
                  }}
                >
                  {this.props.extraData.moreWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.moreWordsError"
                        values={{
                          count: this.props.extraData.moreWords,
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {this.props.extraData.missingWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.missingWordsError"
                        values={{
                          count: this.props.extraData.missingWords,
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {this.props.extraData.typos && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.wordsWithTyposError"
                        values={{
                          words: this.props.extraData.typos.reduce(
                            (acc, word, index) => acc.concat(index ? ", " : "").concat(word),
                            ""
                          ),
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                  {this.props.extraData.wrongWords && (
                    <li>
                      <Icon icon="close" size="xs" color="#d45151" />{" "}
                      <TranslatedText
                        as="span"
                        translateKey="items.dictation.wrongWordsError"
                        values={{
                          words: this.props.extraData.wrongWords.reduce(
                            (acc, word, index) => acc.concat(index ? ", " : "").concat(word),
                            ""
                          ),
                        }}
                        style={{
                          fontSize: 22,
                          color: "#607d8b",
                        }}
                      />
                    </li>
                  )}
                </ul>
              </div>
            )}
          </ExerciseItemPanel>
        )}
      </CourseThemeContext.Consumer>
    );
  }
}
