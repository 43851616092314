import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { isEmail, isRequired } from "student-front-commons/src/validators";
import MaterialTheme from "../../core/MaterialTheme";
import HtmlForm from "../../core/HtmlForm";
import TextInput from "../../core/form/TextInput";
import Separator from "../../core/Separator";
import FlexRow from "../../core/FlexRow";
import CircularLoading from "../../core/CircularLoading";
import withTranslation from "../../core/withTranslation";
import { useForm, useService } from "student-front-commons/src/hooks";
import { resetPassword } from "student-front-commons/src/services/authenticationService";
import AuthMessage from "../../core/AuthMessage";
import ButtonV2 from "../../core/ButtonV2";

function ForgotPasswordForm(props) {
  const [error, setError] = useState();
  const [info, setInfo] = useState();

  const [isSubmitting, start] = useService(resetPassword, {
    onData: () => {
      setInfo(props.getTranslation("login.resetPasswordSuccess"));
    },
    onError: (error) => {
      let errorMessage = props.getTranslation(`login.${error.code}`);
      if (!errorMessage) {
        errorMessage = error.message;
      }
      setError(errorMessage);
    },
  });

  const { getValue, setValue, getError, submit, isSubmitted } = useForm({
    initialValues: {
      role: "STUDENT",
    },
    validations: {
      email: [isRequired, isEmail],
    },
    onSubmit: (values) => {
      setInfo(null);
      setError(null);
      start(values);
    },
  });

  return (
    <>
      <MaterialTheme>
        <HtmlForm onSubmit={submit}>
          <TextInput
            disabled={isSubmitting}
            onChange={(value) => setValue("email", value)}
            value={getValue("email")}
            error={isSubmitted() && getError("email")}
            label={props.getTranslation("login.email")}
            fullWidth
            autoComplete={false}
          />
          <Separator size="lg" />
          <Separator size="lg" />
          <FlexRow justifyContent="space-between" alignItems="center">
            <p
              style={{
                textTransform: "uppercase",
                color: "#4a4a4a",
                margin: 0,
              }}
            >
              <Link to="/login">{props.getTranslation("login.backToLogin")}</Link>
            </p>
            <FlexRow alignItems="center">
              {isSubmitting && <CircularLoading size="xs" rgb={{ r: 141, g: 141, b: 141 }} />}
              <Separator />
              <ButtonV2
                size="md"
                intent="wl-primary"
                labelKey="login.resetPassword"
                isDisabled={isSubmitting}
                buttonType="submit"
              />
            </FlexRow>
          </FlexRow>
        </HtmlForm>
      </MaterialTheme>
      <Separator size="md" />
      <AuthMessage isOpen={!!error || !!info} message={error || info} type={error ? "error" : "success"} />
    </>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default withTranslation(ForgotPasswordForm);
