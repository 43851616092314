import React from "react";
import PropTypes from "prop-types";
import ControlBarLayoutItem from "../../core/old-control-bar/ControlBarLayoutItem";
import ControlBarLayout from "../../core/old-control-bar/ControlBarLayout";
import withTranslation from "../../core/withTranslation";
import RepeatButtonContainer from "../../core/old-control-bar/RepeatButtonContainer";
import BonusTestItemTimerContainer from "./BonusTestItemTimerContainer";
import NextButton from "../../core/old-control-bar/NextButton";

class BonusTestExecutionControlBar extends React.Component {
  static propTypes = {
    values: PropTypes.shape({}).isRequired,
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
  };

  isRepeatDisabled = () => {
    if (
      ["SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_IMAGE", "SINGLE_CHOICE_AUDIO"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return this.props.values.isDisabled || this.props.values.repeatCount > 0;
    }
    return true;
  };

  isNextDisabled = () => {
    if (
      ["UNSCRAMBLE_DRAG_AND_DROP", "GAP_FILL_MULTIPLE", "GAP_FILL_SELECT"].some(
        (type) => type === this.props.values.associativeItem.item.type.key
      )
    ) {
      return this.props.values.isDisabled || !this.props.values.answer;
    }
    return true;
  };

  render() {
    return (
      <ControlBarLayout>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 350,
            margin: "0 auto",
          }}
        >
          <div className="selectRepeat">
            <ControlBarLayoutItem label={this.props.getTranslation("repeat")}>
              <RepeatButtonContainer
                showTipMessage={false}
                showNoUsageMessage={false}
                isDisabled={this.isRepeatDisabled()}
                isPlacementRepeat={true}
                type="audio"
              />
            </ControlBarLayoutItem>
          </div>
          <div className="selectTime">
            <ControlBarLayoutItem label={this.props.getTranslation("timer")}>
              <BonusTestItemTimerContainer
                isSubmitting={this.props.isSubmitting}
                isDisabled={this.props.values.isDisabled}
                id={this.props.values.associativeItem.item.id}
                time={this.props.values.associativeItem.item.time}
                itemType={this.props.values.associativeItem.item.type.key}
                onTimerEnd={this.props.onSave}
              />
            </ControlBarLayoutItem>
          </div>
          <ControlBarLayoutItem label={this.props.getTranslation("next")}>
            <NextButton
              onClick={this.props.onSave}
              isDisabled={this.isNextDisabled()}
              isSubmitting={this.props.isSubmitting}
            />
          </ControlBarLayoutItem>
        </div>
      </ControlBarLayout>
    );
  }
}

export default withTranslation(BonusTestExecutionControlBar);
