import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { requestNextStep, requestConfigEnd } from "../../actionCreators/microphoneConfig";
import SpeechRecognitionTestResult from "./SpeechRecognitionTestResult";

const SpeechRecognitionTestResultContainer = (props) =>
  props.testResult && (
    <SpeechRecognitionTestResult
      status={props.testResult}
      onEnd={props.handleEnd}
      onReset={props.handleReset}
      onGoToTutorial={props.handleGoToTutorial}
    />
  );

SpeechRecognitionTestResultContainer.propTypes = {
  testResult: PropTypes.string,
  handleGoToTutorial: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleEnd: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  testResult: state.microphoneConfig.testResult,
});

const mapDispatchToProps = (dispatch) => ({
  handleGoToTutorial: () => dispatch(requestNextStep("TUTORIAL")),
  handleReset: () => dispatch(requestNextStep("SPEECH_RECOGNITION")),
  handleEnd: () => dispatch(requestConfigEnd()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeechRecognitionTestResultContainer);
