import React, { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Row from "../../core/Row";
import Column from "../../core/Column";
import { WHITE } from "../../consts/color";
import CircularButton from "../../core/CircularButton";
import TranslatedText from "../../core/TranslatedText";
import CourseThemeContext from "../../core/CourseThemeContext";

function BonusTestSubHeader() {
  const params = useParams();
  const history = useHistory();
  const courseThemeContext = useContext(CourseThemeContext);

  return (
    <div
      style={{
        height: 80,
        overflow: "hidden",
        position: "relative",
        boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
        backgroundImage: `linear-gradient(to right, ${courseThemeContext.primary} 50%, #FFFFFF 51%)`,
      }}
    >
      <div className="container">
        <Row>
          <Column lgSize={12} style={{ position: "relative " }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                height: 80,
                backgroundColor: courseThemeContext.primary,
                position: "relative",
              }}
            >
              <CircularButton
                onClick={() => history.replace(`/course/${params.idCourse}/modules`)}
                color={courseThemeContext.primary}
                icon="arrow-left"
                border={`2px solid ${courseThemeContext.secondary}`}
              />
              <TranslatedText
                translateKey="bonusTest.executionTitle"
                as="h1"
                style={{
                  margin: 0,
                  marginLeft: 30,
                  color: WHITE,
                  fontSize: 32,
                  fontWeight: 700,
                }}
              />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  backgroundColor: courseThemeContext.secondary,
                  height: 147,
                  width: 147,
                  border: "solid 5px #ffffff",
                  boxShadow: "-4px 3px 6px -2px rgba(33, 46, 130, 0.2)",
                  position: "absolute",
                  top: "-35px",
                  right: 0,
                  borderRadius: "100%",
                  textAlign: "center",
                }}
              ></div>
            </div>
          </Column>
        </Row>
      </div>
    </div>
  );
}

export default BonusTestSubHeader;
