import React from "react";
import { Redirect } from "react-router-dom";
import Entity from "student-front-commons/src/components/Entity";

const HomeSceneB2C = () => (
  <Entity name="course">
    {({ getAll: getAllCourse }) => <Redirect to={`/course/${getAllCourse().find((c) => !c.completedAt).id}/modules`} />}
  </Entity>
);

export default HomeSceneB2C;
