import React from "react";
import { detect } from "detect-browser";
import Separator from "../../core/Separator";
import ConfigStepOptionsContainer from "./ConfigStepOptionsContainer";
import VideoPlayer from "../../core/VideoPlayer";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";

const info = detect();

const ConfigStep = (props) => (
  <div
    style={{
      textAlign: "center",
      padding: "50px 30px 30px 30px",
    }}
  >
    <TranslatedText
      as="h1"
      translateKey="microphoneTest.tutorialTitle"
      style={{
        fontWeight: "bold",
        fontSize: 22,
        lineHeight: "24px",
        margin: 0,
        color: "#607d8b",
        textAlign: "center",
      }}
    />
    <Separator size="lg" />
    <TranslatedText
      as="p"
      translateKey="microphoneTest.tutorialDescriptionOne"
      style={{
        fontSize: 14,
        color: "#607d8b",
        textAlign: "center",
        margin: 0,
      }}
    />
    <Separator size="md" />
    {(info.os.toUpperCase().split(" ")[0] === "MAC" || info.os.toUpperCase().split(" ")[0] === "WINDOWS") && (
      <React.Fragment>
        <VideoPlayer
          src={
            {
              MAC: "https://player.vimeo.com/video/261563279",
              WINDOWS: "https://player.vimeo.com/video/261580006",
            }[info.os.toUpperCase().split(" ")[0]]
          }
          onVideoEnd={() => false}
        />
        <Separator size="lg" />
      </React.Fragment>
    )}
    <p
      style={{
        fontSize: 14,
        color: "#607d8b",
        textAlign: "center",
        margin: 0,
      }}
    >
      <TranslatedText as="b" translateKey="microphoneTest.tutorialDescriptionTwo.part1" />
      <TranslatedText as="span" translateKey="microphoneTest.tutorialDescriptionTwo.part2" />
      <span>
        <TranslatedText as="b" translateKey="microphoneTest.tutorialDescriptionTwo.part3" />
        <a
          style={{
            textDecoration: "underline",
          }}
          href={
            {
              MAC: "https://support.apple.com/kb/PH25417?viewlocale=pt_BR&locale=pt_BR",
              WINDOWS:
                "https://support.microsoft.com/pt-br/help/4027981/windows-how-to-set-up-and-test-microphones-in-windows-10",
              LINUX: "https://help.ubuntu.com/community/AudioCapture",
            }[info.os.toUpperCase().split(" ")[0]]
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>link</b>
        </a>
        <TranslatedText as="span" translateKey="microphoneTest.tutorialDescriptionTwo.part4" />
      </span>
    </p>
    <Separator size="lg" />
    <ConfigStepOptionsContainer />
  </div>
);

export default withTranslation(ConfigStep);
