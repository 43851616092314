import React from "react";
import { useSelector } from "react-redux";
import TranslatedText from "../../core/TranslatedText";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import Separator from "../../core/Separator";
import Icon from "../../core/Icon";

function UnitResultPointsSection() {
  const rankingPoints = useSelector((state) => state.executions?.result?.rankingPoints);
  const studiedTimeInSeconds = useSelector((state) => state.executions?.result?.studiedTime);

  const studiedTimeInMinutes = Math.round(studiedTimeInSeconds / 60).toString();

  return (
    <>
      <div
        style={{
          width: "100%",
          height: 1,
          position: "relative",
          marginTop: "-163px",
          marginBottom: "3px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TranslatedText
          as="p"
          translateKey="ranking.rewards.title"
          style={{
            fontWeight: 600,
            fontSize: "20px",
            margin: 0,
            color: "#FEFEFE",
            textTransform: "capitalize",
            position: "absolute",
          }}
        />
      </div>
      <Separator size="md" />
      <Separator size="sm" />
      <FlexRow columnGap={48} justifyContent="center">
        <FlexColumn
          backgroundColor="#FFFBEB"
          borderRadius={16}
          width={224}
          height={176}
          justifyContent="center"
          alignItems="center"
        >
          <div style={{ marginTop: -20 }}>
            <Icon icon="controller-classic-outline" color="#D97706" size="lg" />
          </div>
          <TranslatedText
            as="span"
            translateKey="ranking.rewards.ranking"
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#475569",
            }}
          />
          <span style={{ color: "#D97706", fontSize: 48, fontWeight: 600 }}>+{rankingPoints ?? 0}</span>
        </FlexColumn>
        <FlexColumn
          backgroundColor="#F0FDFA"
          borderRadius={16}
          width={224}
          height={176}
          justifyContent="center"
          alignItems="center"
        >
          <Icon icon="progress-clock" color="#0D9488" size="md" />
          <TranslatedText
            as="span"
            translateKey="ranking.rewards.time"
            style={{
              fontWeight: 500,
              fontSize: 14,
              color: "#475569",
            }}
          />
          <div>
            <span style={{ color: "#0D9488", fontSize: 48, fontWeight: 600 }}>+{studiedTimeInMinutes}</span>
            <span style={{ color: "#0D9488", fontSize: 14, fontWeight: 700, lineHeight: "14px" }}>min</span>
          </div>
        </FlexColumn>
      </FlexRow>
    </>
  );
}

export default UnitResultPointsSection;
