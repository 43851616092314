import React, { useEffect } from "react";
import TestHeader from "../TestHeader";
import TestPanel from "../TestPanel";
import Separator from "../../../core/Separator";
import ScoreSection from "../ScoreSection";
import CourseSectionContainer from "./CourseSectionContainer";
import TranslatedText from "../../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { stopAudio } from "../../../stores/audio-store";
import TestStepContainer from "../TestStepContainer";
import PlacementByLinkResult from "./PlacementByLinkResult";

export default function PlacementTestScoreScene() {
  const registeredByLink = sessionStorage.getItem("registeredByLink");

  useEffect(() => {
    if (registeredByLink) {
      sessionStorage.clear();
    }

    return () => stopAudio();
  }, []);

  return (
    <WhitelabelContext.Consumer>
      {({ primaryColor }) => (
        <div
          style={{
            marginBottom: 20,
          }}
        >
          <TestHeader color={primaryColor} />
          <TestStepContainer currentStep={4} />
          <TestPanel width="820px">
            {registeredByLink ? (
              <PlacementByLinkResult />
            ) : (
              <>
                <TranslatedText
                  as="h1"
                  translateKey="placementTest.placementScore"
                  style={{
                    color: primaryColor,
                    fontSize: 30,
                    lineHeight: "24px",
                    margin: 0,
                  }}
                />
                <Separator size="xs" />
                <Separator size="md" />
                <ScoreSection />
                <Separator size="xs" />
                <Separator size="md" />
                {sessionStorage.getItem("id") !== "tasting_user" && <CourseSectionContainer />}
              </>
            )}
          </TestPanel>
        </div>
      )}
    </WhitelabelContext.Consumer>
  );
}
