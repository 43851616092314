import React from "react";
import PropTypes from "prop-types";
import { Select as SelectField, MenuItem, InputLabel, FormControl } from "@material-ui/core";

const Select = (props) => (
  <FormControl style={{ width: "100%" }} error={!!props.error}>
    <InputLabel id="select-field-label">{props.label}</InputLabel>
    <SelectField
      labelId="select-field-label"
      id="select-field"
      fullWidth={props.fullWidth}
      multiple={props.multiple}
      value={props.value}
      disabled={props.disabled}
      onChange={(event) => props.onChange(event.target.value)}
      style={props.style}
      MenuProps={{ disablePortal: true, ...props.menuProps }}
    >
      {props.options.map((option) => (
        <MenuItem key={`${option.value}-${option.label}`} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </SelectField>
  </FormControl>
);

Select.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  menuProps: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
  multiple: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  style: PropTypes.object,
};

Select.defaultProps = {
  value: null,
  error: null,
  fullWidth: false,
  multiple: false,
  disabled: false,
  onChange: null,
  menuProps: {},
  style: null,
};

export default Select;
