import React from "react";
import PropTypes from "prop-types";
import Flow from "student-front-commons/src/components/Flow";
import SlowRepeatButton from "./SlowRepeatButton";
import { OLD_PLAY_ITEM_AUDIO_FLOW } from "../../consts";

const SlowRepeatButtonContainer = (props) => (
  <Flow
    autoStart={false}
    id={OLD_PLAY_ITEM_AUDIO_FLOW}
    params={{
      isSlowRepeat: true,
    }}
  >
    {({ isPending, handleStart }) => (
      <SlowRepeatButton onClick={handleStart} isDisabled={isPending || props.isDisabled} />
    )}
  </Flow>
);

SlowRepeatButtonContainer.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default SlowRepeatButtonContainer;
