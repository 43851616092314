import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Modal from "./Modal";
import ButtonV2 from "./ButtonV2";

class SurveyModal extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    allowDismiss: PropTypes.bool.isRequired,
  };

  state = {
    show: true,
  };

  handleDismiss = () => {
    this.setState({
      show: false,
    });
    this.props.onClose();
  };

  render() {
    return (
      this.state.show && (
        <Modal style={{ padding: 0, width: "90%", maxWidth: 1024, height: 640, position: "relative" }}>
          <iframe
            title="survey"
            frameborder="0"
            width="100%"
            height="100%"
            src="https://survey.hsforms.com/1uvvFo7xKQD6qjipy0Kw2gg5m461"
          />
          <div
            style={{
              backgroundColor: "#fff",
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: 100,
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ButtonV2 onClick={this.handleDismiss} labelKey="close" intent="primary" size="md" />
          </div>
        </Modal>
      )
    );
  }
}

export default withRouter(SurveyModal);
