import {
  MICROPHONE_CONFIG_INITIAL_STEP,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM,
  REQUEST_MICROPHONE_CONFIG_START,
  MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT,
  MICROPHONE_CONFIG_TUTORIAL_STEP,
} from "../consts/actions";
import HeIsABoy from "../screens/microphone-config/assets/he-is-a-boy.mp3";
import TheyAreTheBest from "../screens/microphone-config/assets/they-are-the-best.mp3";
import YouAreGreat from "../screens/microphone-config/assets/you-are-great.mp3";

const initialState = {
  currentStep: "INITIAL",
  testResult: null,
  items: [
    { id: 1, text: "He is a boy.", status: "disabled", audio: HeIsABoy },
    { id: 2, text: "You are great.", status: "disabled", audio: YouAreGreat },
    { id: 3, text: "They are the best", status: "disabled", audio: TheyAreTheBest },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_MICROPHONE_CONFIG_START:
      return {
        ...initialState,
      };
    case MICROPHONE_CONFIG_INITIAL_STEP:
      return {
        ...state,
        currentStep: "INITIAL",
      };
    case MICROPHONE_CONFIG_SPEECH_RECOGNITION_STEP:
      return {
        ...state,
        currentStep: "SPEECH_RECOGNITION",
        testResult: null,
        items: [
          { id: 1, text: "He is a boy.", status: "disabled", audio: HeIsABoy },
          { id: 2, text: "You are great.", status: "disabled", audio: YouAreGreat },
          { id: 3, text: "They are the best", status: "disabled", audio: TheyAreTheBest },
        ],
      };
    case MICROPHONE_CONFIG_SPEECH_RECOGNITION_UPDATE_ITEM:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload.body;
          }
          return item;
        }),
      };
    case MICROPHONE_CONFIG_SPEECH_RECOGNITION_TEST_RESULT:
      return {
        ...state,
        testResult: action.payload.status,
      };
    case MICROPHONE_CONFIG_TUTORIAL_STEP:
      return {
        ...state,
        currentStep: "TUTORIAL",
      };
    default:
      return state;
  }
};
