import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import TranslatedText from "../TranslatedText";
import { SPEECH_GREEN, SPEECH_RED } from "../../consts/color";
import { useSelector } from "react-redux";

function SpeechRecognitionScore({ score }) {
  const speechRecognitionMinimumScore = useSelector((state) => state.configurations.scoreToPassOfSpeechRecognition);

  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "100%",
        fontSize: 34,
        fontWeight: 400,
        width: 65,
        height: 65,
        top: -55,
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 3px 0px",
        backgroundColor: score >= speechRecognitionMinimumScore ? SPEECH_GREEN : SPEECH_RED,
        color: "rgba(255, 255, 255, 0.9)",
        padding: "5px 0px",
      }}
    >
      {score || 0}
      {score < speechRecognitionMinimumScore && (
        <TranslatedText
          as="span"
          translateKey="items.speechRecognition.tryAgain"
          style={{
            position: "absolute",
            top: -20,
            color: SPEECH_RED,
            fontSize: 11,
            fontWeight: "bold",
            width: "max-content",
          }}
        />
      )}
      {score >= speechRecognitionMinimumScore && (
        <div
          style={{
            position: "absolute",
            right: -25,
            top: -20,
          }}
        >
          <Icon icon="check" color={SPEECH_GREEN} size="md" />
        </div>
      )}
    </div>
  );
}

SpeechRecognitionScore.propTypes = {
  score: PropTypes.number.isRequired,
};

export default SpeechRecognitionScore;
