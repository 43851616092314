import React from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Column from "../../core/Column";
import Panel from "../../core/Panel";
import Row from "../../core/Row";
import Title from "../../core/Title";
import Separator from "../../core/Separator";
import TranslatedText from "../../core/TranslatedText";
import CustomRankingListContainer from "./CustomRankingListContainer";

class MonthRanking extends React.Component {
  getDate = () => queryString.parse(this.props.location.search).month;

  render() {
    return (
      <Panel>
        <Row>
          <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
            <TranslatedText translateKey="regional" as={Title} />
            <Separator size="sm" />
            <CustomRankingListContainer
              key={`regional-${this.getDate()}`}
              level="regional"
              from={moment(this.getDate(), "MM").startOf("month").toDate()}
              to={moment(this.getDate(), "MM").endOf("month").toDate()}
            />
          </Column>
          <Column lgSize={6} mdSize={6} smSize={6} xsSize={12}>
            <TranslatedText translateKey="national" as={Title} />
            <Separator size="sm" />
            <CustomRankingListContainer
              key={`national-${this.getDate()}`}
              level="national"
              from={moment(this.getDate(), "MM").startOf("month").toDate()}
              to={moment(this.getDate(), "MM").endOf("month").toDate()}
            />
          </Column>
        </Row>
      </Panel>
    );
  }
}

export default withRouter(MonthRanking);
