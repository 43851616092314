import request from "../core/request";
import safeCall from "../core/safeCall";

export const getNextMeeting = async () => {
  return await safeCall(
    async () => {
      return await request({
        url: `meetings/next`,
      });
    },
    "error_list_next_meeting",
    "Unexpected error to list the next meeting."
  );
};
