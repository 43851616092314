import React from "react";
import PropTypes from "prop-types";
import { Line } from "react-chartjs-2";

const LineChart = (props) => (
  <Line
    height={props.height}
    data={{
      labels: props.labels,
      datasets: props.dataFormat.map((format) => ({
        label: format.label,
        fill: false,
        lineTension: 0.1,
        backgroundColor: format.color,
        borderColor: format.color,
        pointHoverRadius: 0,
        pointHoverBackgroundColor: format.color,
        pointHoverBorderColor: format.color,
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
        yAxisID: format.yAxisID,
        data: props.data.map((item) => format.valueRender(item)),
      })),
    }}
    options={{
      maintainAspectRatio: props.height ? false : true,
      legend: {
        position: "bottom",
        display: true,
      },
      layout: {
        padding: {
          top: 10,
          bottom: 10,
        },
      },
      scales: {
        xAxes: props.xAxes,
        yAxes: props.yAxes,
      },
      tooltips: {
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0,0,0,0.6)",
        callbacks: {
          title: () => null,
          label: (args) => props.dataFormat[args.datasetIndex].tooltipRender(args),
        },
      },
      hover: {
        mode: "nearest",
        intersect: true,
      },
    }}
  />
);

LineChart.propTypes = {
  height: PropTypes.number,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataFormat: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      label: PropTypes.string,
      valueRender: PropTypes.func,
      tooltipRender: PropTypes.func,
    })
  ).isRequired,
  yAxes: PropTypes.arrayOf(PropTypes.shape({})),
  xAxes: PropTypes.arrayOf(PropTypes.shape({})),
};

LineChart.defaultProps = {
  height: null,
  yAxes: [{ display: false }],
  xAxes: [{ display: false }],
};

export default LineChart;
