import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import Row from "../core/Row";
import Column from "../core/Column";
import CourseIcon from "../core/CourseIcon";
import CourseDetailPanel from "./CourseDetailPanel";
import CourseSummaryContainer from "./CourseSummaryContainer";
import SubHeaderRankingsContainer from "./SubHeaderRankingsContainer";
import CourseThemeContext from "./CourseThemeContext";
import RankingParticipantContainer from "./RankingParticipantContainer";
import WeekStatsPanelContainer from "./WeekStatsPanelContainer";
import StudyQualityPanelContainer from "./StudyQualityPanelContainer";

const SubHeaderDetails = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          boxShadow: "0px 1px 8px 0 rgba(0, 0, 0, 0.2)",
          overflow: "hidden",
          position: "relative",
          zIndex: 4,
          backgroundImage: `linear-gradient(to right, ${primary} 30%, #FFFFFF 31%)`,
        }}
      >
        <div className="container">
          <Row>
            <Column lgSize={7} mdSize={8} smSize={10} xsSize={12}>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  width: "100%",
                  height: 80,
                }}
              >
                <MediaQuery maxWidth={767}>
                  {(matches) => (
                    <div>
                      <CourseDetailPanel width={matches ? "100%" : "51%"} color={primary}>
                        <CourseIcon value={props.course.name} iconUrl={props.course.iconUrl} />
                        <CourseSummaryContainer />
                      </CourseDetailPanel>
                    </div>
                  )}
                </MediaQuery>
                <div className="hidden-xs">
                  <StudyQualityPanelContainer />
                </div>
                <div className="hidden-xs">
                  <WeekStatsPanelContainer />
                </div>
              </div>
            </Column>
            <Column lgSize={5} mdSize={4} smSize={2} xsSize={12}>
              <div className="d-xs-none d-sm-flex justify-content-end">
                <RankingParticipantContainer>
                  <SubHeaderRankingsContainer />
                </RankingParticipantContainer>
              </div>
            </Column>
          </Row>
        </div>
      </div>
    )}
  </CourseThemeContext.Consumer>
);

SubHeaderDetails.propTypes = {
  course: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default SubHeaderDetails;
