import React from "react";
import PropTypes from "prop-types";
import styled from 'styled-components';
import { WHITE } from "../../consts/color";

const Icon = styled.i`
  width: ${(props) => props.style.width},
  height: ${(props) => props.style.height},
  opacity: ${(props) => props.style.opacity},
  objectFit: ${(props) => props.style.objectFit},
  position: ${(props) => props.style.position},
  right: ${(props) => props.style.right},
  bottom: ${(props) => props.style.bottom},
  &:before {
    font-size: ${(props) => props.style.fontSize}
  }
`
const StudyQualitySummary = (props) => (
  <div
    style={{
      width: "100%",
      height: 113,
      boxSizing: "border-box",
      padding: 20,
      backgroundColor: props.background,
      position: "relative",
    }}
  >
    <Icon
      className={`mdi mdi-${props.icon} mdi-light`}
      style={ {
        width: 100,
        height: 100,
        opacity: 0.4,
        objectFit: "contain",
        position: "absolute",
        ...props.iconStyle
      } }
    />
    <label
      htmlFor="value"
      style={{
        margin: 0,
        fontSize: 42,
        color: WHITE,
        display: "block",
        fontWeight: 700,
      }}
    >
      {props.value}
    </label>
    <label
      htmlFor="label"
      style={{
        margin: 0,
        marginLeft: 5,
        fontSize: 14,
        color: WHITE,
        fontWeight: 700,
      }}
    >
      {props.label}
    </label>
  </div>
);

StudyQualitySummary.propTypes = {
  background: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  iconStyle: PropTypes.shape({}),
};

export default StudyQualitySummary;
