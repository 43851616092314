import React, { useCallback, useEffect, useState } from "react";
import HighlightableItemBox from "./HighlightableItemBox";
import QuestionLabel from "../QuestionLabel";
import Select from "../Select";
import withTranslation from "../withTranslation";
import ExerciseItemPanel from "./ExerciseItemPanel";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentItemExecutionProp,
  getItemExecutionPropById,
} from "student-front-commons/src/selectors/itemExecution";
import { useFlow } from "student-front-commons/src/hooks";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW } from "../../consts";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";
import Separator from "../Separator";
import FlexRow from "../FlexRow";
import { FEEDBACK_CORRECT } from "../../consts/color";
import ValidationWrapper from "./ValidationWrapper";

const SelectExerciseRender = withTranslation((props) => {
  const dispatch = useDispatch();

  const itemText = useSelector(getItemExecutionPropById(props.itemId, "item.text"));
  const itemLinkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const itemAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const isExecutionValidated = useSelector(getCurrentItemExecutionProp("isExecutionValidated"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const showCorrectOption = useSelector(getItemExecutionPropById(props.itemId, "showCorrectOption"));
  const currentInstructionStep = useSelector(getInstructionStep);

  const [chunks, setChunks] = useState({});
  const [openSelectIndex, setOpenSelectIndex] = useState(null);

  const [isPending] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);

  const handleChange = useCallback(
    (value, id) => {
      setChunks({ ...chunks, [id]: value });
      setOpenSelectIndex(null);
    },
    [chunks]
  );

  useEffect(() => {
    setChunks({});
  }, [props.itemId]);

  useEffect(() => {
    const answerText = itemText
      .split(" ")
      .reduce((acc, slice, index) => {
        const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
        if (foundAnswer) {
          return acc
            .concat(chunks[foundAnswer.id] || "-")
            .concat(/[!.?,]$/.test(foundAnswer.text) ? foundAnswer.text.substr(foundAnswer.text.length - 1) : "")
            .concat(" ");
        }
        if (!itemAnswers.find((answer) => answer.index === index)) {
          return acc.concat(slice).concat(" ");
        }
        return acc.concat("");
      }, "")
      .trim();

    dispatch(addItemExecutionAnswer(props.itemId, { answer: Object.keys(chunks).length ? answerText : null }));
  }, [chunks, props.itemId, itemLinkedAnswers]);

  return (
    <ExerciseItemPanel>
      <HighlightableItemBox isWrong={showCorrectOption} showFeedback={isExecutionValidated}>
        {itemText.split(" ").map((slice, index) => {
          const foundAnswer = itemLinkedAnswers.find((answer) => answer.index === index);
          if (foundAnswer) {
            return (
              <div
                key={`select-answer-${foundAnswer.id}`}
                className="selectExerciseDropDown"
                style={{
                  minWidth: 100,
                  height: 40,
                  margin: "7px 13px 13px 5px",
                  display: "flex",
                  alignItems: "baseline",
                }}
              >
                <Select
                  placeholder={props.getTranslation("items.placeholder.gapFillSelect")}
                  value={chunks[foundAnswer.id]}
                  isOpen={openSelectIndex === index}
                  onOptionsClose={() => setOpenSelectIndex(null)}
                  onOptionsOpen={() => setOpenSelectIndex(index)}
                  onChange={(value) => handleChange(value, foundAnswer.id)}
                  options={itemLinkedAnswers.map((answer) => ({
                    value: answer.text.replace(/[!.?,]$/, ""),
                    label: answer.text.replace(/[!.?,]$/, ""),
                  }))}
                  disabled={isDisabled || isPending || !!currentInstructionStep}
                  style={{
                    minWidth: 175,
                  }}
                />
                {/[!.?,]$/.test(foundAnswer.text) && (
                  <QuestionLabel text={foundAnswer.text.substr(foundAnswer.text.length - 1)} />
                )}
                &nbsp;
              </div>
            );
          }
          return (
            !itemAnswers.find((answer) => answer.index === index) && (
              <div key={`${props.itemId}-text-${slice}-index-${index}`}>
                <QuestionLabel text={slice} /> &nbsp;
              </div>
            )
          );
        })}
      </HighlightableItemBox>
      <Separator size="md" />
      {showCorrectOption && (
        <ValidationWrapper>
          {itemText.split(" ").map((slice, index) => {
            const isCorrectText = itemAnswers.some((answer) => answer.index === index);

            return (
              <FlexRow
                key={`${props.itemId}-${index}`}
                alignItems="center"
                margin={3}
                marginLeft={index === 0 ? 0 : 3}
                marginRight={index === itemText.split(" ").length - 1 ? 0 : 3}
              >
                <QuestionLabel
                  cursor="normal"
                  fontWeight={isCorrectText ? 700 : 400}
                  color={isCorrectText ? FEEDBACK_CORRECT : "#607d8b"}
                  textDecoration={isCorrectText ? "underline" : "none"}
                >
                  {slice}
                </QuestionLabel>
              </FlexRow>
            );
          })}
        </ValidationWrapper>
      )}
    </ExerciseItemPanel>
  );
});

function SelectExerciseItem() {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <SelectExerciseRender key={id} itemId={id} />);
}

export default SelectExerciseItem;
