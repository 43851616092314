import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CourseThemeContext from "./CourseThemeContext.js";
import { COURSE_SCHEMA } from "student-front-commons/src/schemas";
import { useSelector } from "react-redux";
import { getColorOfCourse } from "../consts/color";

function CourseThemeProvider(props) {
  const params = useParams();
  const [theme, setTheme] = useState(getColorOfCourse("default"));

  const paramCourse = useSelector((state) => state.entities[COURSE_SCHEMA]?.byId[params.idCourse]?.name);
  const studentCourse = useSelector((state) => {
    const currentCourseId = state.entities[COURSE_SCHEMA]?.allIds.find(
      (id) => !state.entities[COURSE_SCHEMA]?.byId[id]?.distributor
    );

    return state.entities[COURSE_SCHEMA]?.byId[currentCourseId]?.name;
  });

  useEffect(() => {
    if (paramCourse) {
      setTheme(getColorOfCourse(paramCourse));
    } else if (studentCourse) {
      setTheme(getColorOfCourse(studentCourse));
    }
  }, [studentCourse, paramCourse]);

  return <CourseThemeContext.Provider value={theme}>{props.children}</CourseThemeContext.Provider>;
}

export default CourseThemeProvider;
