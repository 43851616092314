import React, { Component } from "react";
import { getSprintHistoryByPeriod } from "student-front-commons/src/services/sprintService";
import FetchData from "student-front-commons/src/components/FetchData";
import moment from "moment";
import SprintHistoryItem from "./SprintHistoryItem";
import Separator from "../../core/Separator";
import Async from "../../core/Async";
import Paragraph from "../../core/Paragraph";
import TranslatedText from "../../core/TranslatedText";

class SprintHistoryListContainer extends Component {
  getMaxSprint = (history) =>
    history.reduce((acc, history) => {
      if (history.count > acc) {
        return history.count;
      }
      return acc;
    }, 0);

  render() {
    return (
      <FetchData
        service={getSprintHistoryByPeriod}
        params={{
          id: sessionStorage.getItem("id"),
          from: moment().subtract(3, "month").startOf("month").format("YYYY-MM-DD"),
          to: moment().add(1, "day").startOf("day").format("YYYY-MM-DD"),
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching} size="sm" color="dark">
            {!!(data || []).length &&
              (data || []).map((history) => (
                <div key={`history-${history.date}`}>
                  <SprintHistoryItem
                    date={new Date(history.date)}
                    value={history.count}
                    max={this.getMaxSprint(data || [])}
                  />
                  <Separator size="xs" />
                </div>
              ))}
            {!(data || []).length && <TranslatedText as={Paragraph} translateKey="profile.sprint.noData" />}
          </Async>
        )}
      </FetchData>
    );
  }
}

export default SprintHistoryListContainer;
