import React from "react";
import PropTypes from "prop-types";
import Icon from "../../core/Icon";
import Separator from "../../core/Separator";
import browserHistory from "../../browserHistory";
import ColumnSeparator from "../../core/ColumnSeparator";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";
import CourseThemeContext from "../../core/CourseThemeContext";
import ButtonV2 from "../../core/ButtonV2";

const CourseSummaryNextStep = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <div
        style={{
          backgroundColor: primary,
          borderRadius: 3,
          padding: "20px 30px 20px 20px",
          boxSizing: "border-box",
          margin: "0 auto",
          width: "max-content",
          maxWidth: "100%",
        }}
      >
        {props.certificationTest && props.certificationTest.id ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Icon icon="bell-outline" size="xl" opacity={0.2} color="#fff" />
            <ColumnSeparator size="sm" />
            <ColumnSeparator size="md" />
            <div>
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: 14,
                }}
                dangerouslySetInnerHTML={{
                  __html: props.getTranslation("courseSummary.certificationTestPart1"),
                }}
              />
              <Separator size="xs" />
              <p
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: 14,
                }}
                dangerouslySetInnerHTML={{
                  __html: props.getTranslation("courseSummary.certificationTestPart2"),
                }}
              />
              <Separator size="md" />
              <ButtonV2
                size="md"
                intent="light"
                labelKey="courseSummary.button.back"
                onClick={() => browserHistory.replace("/")}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Icon icon="lock-open-outline" size="lg" opacity={0.2} color="#fff" />
            <ColumnSeparator size="xs" />
            <div>
              <TranslatedText
                as="span"
                translateKey="courseSummary.unlockedCourse"
                style={{
                  color: "#fff",
                  fontSize: 14,
                  margin: "0 0 10px 0",
                }}
              />
              <h1
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: 42,
                  fontWeight: "bold",
                }}
              >
                {props.course.name}
              </h1>
            </div>
            <ColumnSeparator size="md" />
            <ColumnSeparator size="sm" />
            <ButtonV2
              size="md"
              intent="light"
              labelKey="courseSummary.button.access"
              isDisabled={props.isLoading}
              onClick={() => {
                props.onNextCourse();
                setTimeout(() => {
                  browserHistory.replace("/");
                }, 150);
              }}
            />
          </div>
        )}
      </div>
    )}
  </CourseThemeContext.Consumer>
);

CourseSummaryNextStep.propTypes = {
  isLoading: PropTypes.bool,
  course: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  certificationTest: PropTypes.shape({
    id: PropTypes.string,
  }),
  onNextCourse: PropTypes.func.isRequired,
};

CourseSummaryNextStep.defaultProps = {
  isLoading: false,
  certificationTest: null,
};

export default withTranslation(CourseSummaryNextStep);
