import Styled from "styled-components";

export default Styled.div`
  width: 1px;
  top: 0px;
  opacity: 0.2;
  background-color: #757575;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
`;
