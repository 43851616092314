import React from "react";
import PropTypes from "prop-types";

const UnscramblePhraseSpeechRecognitionIcon = ({ color, style }) => (
  <svg width="132" height="135" viewBox="0 0 132 135" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
    <path
      d="M7.99257 109.026C9.09434 109.026 9.98514 108.135 9.98514 107.033V103.025C9.98514 101.923 9.09434 101.032 7.99257 101.032C6.8908 101.032 6 101.923 6 103.025V107.033C6 108.112 6.8908 109.026 7.99257 109.026Z"
      fill={color}
    />
    <path
      d="M16.0096 109.026V101.032C16.0096 99.9303 15.1188 99.0396 14.017 99.0396C12.9152 99.0396 12.0244 99.9303 12.0244 101.032V109.026C12.0244 110.128 12.9152 111.018 14.017 111.018C15.0953 111.018 16.0096 110.128 16.0096 109.026Z"
      fill={color}
    />
    <path
      d="M40.0144 109.026V101.032C40.0144 99.9303 39.1236 99.0396 38.0219 99.0396C36.9201 99.0396 36.0293 99.9303 36.0293 101.032V109.026C36.0293 110.128 36.9201 111.018 38.0219 111.018C39.1002 111.018 40.0144 110.128 40.0144 109.026Z"
      fill={color}
    />
    <path
      d="M24.0032 113.011V97.0001C24.0032 95.8983 23.1124 95.0075 22.0106 95.0075C20.9089 95.0075 20.0181 95.8983 20.0181 97.0001V113.011C20.0181 114.113 20.9089 115.004 22.0106 115.004C23.1124 115.027 24.0032 114.113 24.0032 113.011Z"
      fill={color}
    />
    <path
      d="M31.9973 113.011V97.0001C31.9973 95.8983 31.1065 95.0075 30.0048 95.0075C28.903 95.0075 28.0122 95.8983 28.0122 97.0001V113.011C28.0122 114.113 28.903 115.004 30.0048 115.004C31.1065 115.027 31.9973 114.113 31.9973 113.011Z"
      fill={color}
    />
    <path
      d="M48.0081 117.02V93.0149C48.0081 91.9131 47.1173 91.0223 46.0155 91.0223C44.9137 91.0223 44.0229 91.9131 44.0229 93.0149V117.02C44.0229 118.121 44.9137 119.012 46.0155 119.012C47.1173 119.012 48.0081 118.121 48.0081 117.02Z"
      fill={color}
    />
    <path
      d="M56.0017 121.028V89.0064C56.0017 87.9046 55.1109 87.0138 54.0092 87.0138C52.9074 87.0138 52.0166 87.9046 52.0166 89.0064V121.005C52.0166 122.106 52.9074 122.997 54.0092 122.997C55.1109 123.021 56.0017 122.13 56.0017 121.028Z"
      fill={color}
    />
    <path
      d="M64.0188 133.03V77.004C64.0188 75.9023 63.128 75.0115 62.0263 75.0115C60.9245 75.0115 60.0337 75.9023 60.0337 77.004V133.007C60.0337 134.109 60.9245 135 62.0263 135C63.1046 135.023 64.0188 134.132 64.0188 133.03Z"
      fill={color}
    />
    <path
      d="M124.031 101.009C122.929 101.009 122.038 101.899 122.038 103.001V107.01C122.038 108.112 122.929 109.002 124.031 109.002C125.132 109.002 126.023 108.112 126.023 107.01V103.001C126.023 101.923 125.132 101.009 124.031 101.009Z"
      fill={color}
    />
    <path
      d="M120.022 109.026V101.032C120.022 99.9303 119.131 99.0396 118.029 99.0396C116.927 99.0396 116.037 99.9303 116.037 101.032V109.026C116.037 110.128 116.927 111.018 118.029 111.018C119.131 111.018 120.022 110.128 120.022 109.026Z"
      fill={color}
    />
    `
    <path
      d="M96.0174 109.026V101.032C96.0174 99.9303 95.1266 99.0396 94.0248 99.0396C92.923 99.0396 92.0322 99.9303 92.0322 101.032V109.026C92.0322 110.128 92.923 111.018 94.0248 111.018C95.1266 111.018 96.0174 110.128 96.0174 109.026Z"
      fill={color}
    />
    <path
      d="M112.028 113.011V97.0001C112.028 95.8983 111.137 95.0075 110.036 95.0075C108.934 95.0075 108.043 95.8983 108.043 97.0001V113.011C108.043 114.113 108.934 115.004 110.036 115.004C111.114 115.027 112.028 114.113 112.028 113.011Z"
      fill={color}
    />
    <path
      d="M104.011 113.011V97.0001C104.011 95.8983 103.12 95.0075 102.018 95.0075C100.917 95.0075 100.026 95.8983 100.026 97.0001V113.011C100.026 114.113 100.917 115.004 102.018 115.004C103.12 115.027 104.011 114.113 104.011 113.011Z"
      fill={color}
    />
    <path
      d="M88.0237 117.02V93.0149C88.0237 91.9131 87.1329 91.0223 86.0311 91.0223C84.9294 91.0223 84.0386 91.9131 84.0386 93.0149V117.02C84.0386 118.121 84.9294 119.012 86.0311 119.012C87.1095 119.012 88.0237 118.121 88.0237 117.02Z"
      fill={color}
    />
    <path
      d="M80.0066 121.028V89.0064C80.0066 87.9046 79.1158 87.0138 78.0141 87.0138C76.9123 87.0138 76.0215 87.9046 76.0215 89.0064V121.005C76.0215 122.106 76.9123 122.997 78.0141 122.997C79.1158 123.021 80.0066 122.13 80.0066 121.028Z"
      fill={color}
    />
    <path
      d="M72.0125 133.03V77.004C72.0125 75.9023 71.1217 75.0115 70.0199 75.0115C68.9181 75.0115 68.0273 75.9023 68.0273 77.004V133.007C68.0273 134.109 68.9181 135 70.0199 135C71.1217 135.023 72.0125 134.132 72.0125 133.03Z"
      fill={color}
    />
    <path
      d="M18.7053 75C18.3404 75 17.9596 74.9207 17.6264 74.7463C16.7856 74.334 16.2303 73.4778 16.2303 72.5423L16.1351 62.315H11.5817C5.18798 62.315 0 57.1142 0 50.74V11.5751C0 5.20085 5.20385 0 11.5817 0H120.418C126.812 0 132 5.20085 132 11.5751V50.74C132 57.13 126.796 62.315 120.418 62.315H35.9986L20.2284 74.4767C19.7841 74.8256 19.2447 75 18.7053 75ZM11.5817 4.963C7.93269 4.963 4.96587 7.92812 4.96587 11.5751V50.74C4.96587 54.3869 7.93269 57.352 11.5817 57.352H18.5942C19.9587 57.352 21.0692 58.4461 21.0851 59.8097L21.1486 67.5L33.6346 57.8753C34.063 57.5423 34.6024 57.352 35.1577 57.352H120.418C124.067 57.352 127.034 54.3869 127.034 50.74V11.5751C127.034 7.92812 124.067 4.963 120.418 4.963H11.5817Z"
      fill={color}
    />
    <rect x="46" y="19" width="41" height="25" fill="white" />
    <path
      d="M37.7132 18.1191H22.0814C21.6943 18.1191 21.3965 18.4466 21.3965 18.8039C21.3965 19.1612 21.7241 19.4887 22.0814 19.4887H37.7132C38.1003 19.4887 38.3981 19.8163 38.3981 20.1736V42.5048C38.3981 42.8919 38.0705 43.1896 37.7132 43.1896H15.0842C14.6972 43.1896 14.3994 42.8621 14.3994 42.5048V20.1736C14.3994 19.7865 14.7269 19.4887 15.0842 19.4887H16.4837C16.8707 19.4887 17.1685 19.1612 17.1685 18.8039C17.1685 18.4466 16.8707 18.1191 16.4837 18.1191H15.0842C13.923 18.1191 13 19.0719 13 20.2033V42.5346C13 43.6958 13.9528 44.6188 15.0842 44.6188H37.7132C38.8745 44.6188 39.7975 43.666 39.7975 42.5346V20.2033C39.7975 19.0719 38.8447 18.1191 37.7132 18.1191Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
    <path
      d="M84.6735 18.1191H54.1541C53.7671 18.1191 53.4693 18.4466 53.4693 18.8039C53.4693 19.1612 53.7968 19.4887 54.1541 19.4887H84.6735C85.0606 19.4887 85.3583 19.8163 85.3583 20.1736V42.5048C85.3583 42.8919 85.0308 43.1896 84.6735 43.1896H47.1868C46.7997 43.1896 46.502 42.8621 46.502 42.5048V20.1736C46.502 19.7865 46.8295 19.4887 47.1868 19.4887H48.5862C48.9733 19.4887 49.271 19.1612 49.271 18.8039C49.271 18.4466 48.9435 18.1191 48.5862 18.1191H47.1868C46.0256 18.1191 45.1025 19.0719 45.1025 20.2033V42.5346C45.1025 43.6958 46.0553 44.6188 47.1868 44.6188H84.6735C85.8347 44.6188 86.7578 43.666 86.7578 42.5346V20.2033C86.7578 19.0421 85.8347 18.1191 84.6735 18.1191Z"
      fill="#73A7A9"
      stroke="#73A7A9"
      strokeWidth="2"
    />
    <path
      d="M115.916 18H100.284C99.8969 18 99.5992 18.3275 99.5992 18.6848C99.5992 19.0421 99.9267 19.3696 100.284 19.3696H115.916C116.303 19.3696 116.601 19.6972 116.601 20.0545V42.3857C116.601 42.7728 116.273 43.0705 115.916 43.0705H93.3167C92.9296 43.0705 92.6318 42.743 92.6318 42.3857V20.0545C92.6318 19.6674 92.9594 19.3696 93.3167 19.3696H94.7161C95.1032 19.3696 95.4009 19.0421 95.4009 18.6848C95.4009 18.3275 95.0734 18 94.7161 18H93.3167C92.1554 18 91.2324 18.9528 91.2324 20.0842V42.4155C91.2324 43.5767 92.1852 44.4997 93.3167 44.4997H115.916C117.077 44.4997 118 43.5469 118 42.4155V20.0842C118 18.923 117.077 18 115.916 18Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
    />
  </svg>
);

UnscramblePhraseSpeechRecognitionIcon.propTypes = {
  color: PropTypes.string.isRequired,
};

export default UnscramblePhraseSpeechRecognitionIcon;
