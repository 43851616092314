import last from "lodash/last";
import { call, cancelled, put, race, select, take, takeLatest } from "redux-saga/effects";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow } from "student-front-commons/src/actions/flow";
import { stopAudio } from "../stores/audio-store";
import { logError } from "../util";
import { CHECK_ACHIEVEMENT_EXECUTION_FLOW, CLOSE_UNIT_EXECUTION_FLOW } from "../consts";
import {
  checkSpeechRecognitionTips,
  incrementCheckpointSequence,
  incrementSpeechRecognitionSequence,
  resetCheckpointSequence,
  resetSpeechRecognitionSequence,
} from "student-front-commons/src/actions/executionAchievement";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getExecutionProgress } from "student-front-commons/src/selectors/execution";

const checkpointTypes = [
  "SINGLE_CHOICE_TEXT",
  "SINGLE_CHOICE_AUDIO",
  "SINGLE_CHOICE_IMAGE",
  "MULTIPLE_CHOICE_TEXT",
  "GAP_FILL",
  "GAP_FILL_SELECT",
  "TRUE_FALSE",
  "GAP_FILL_MULTIPLE",
  "UNSCRAMBLE_SPEECH_RECOGNITION",
  "UNSCRAMBLE_DRAG_AND_DROP",
  "UNSCRAMBLE_TEXT",
  "DICTATION",
  "DIALOGUE_OPTION",
  "MEANINGS_ASSOCIATING",
];

export default function* () {
  yield takeLatest(getFlowStart(CHECK_ACHIEVEMENT_EXECUTION_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          const flow = yield select(getFlow(CHECK_ACHIEVEMENT_EXECUTION_FLOW));
          const itemType = yield select(getCurrentItemExecutionProp("item.type.key"));

          const attempts = yield select(getCurrentItemExecutionProp("attempts"));
          const lastAnswer = last(attempts);

          if (flow.params.type === "record-check") {
            if (lastAnswer.answer >= 80) {
              yield put(incrementSpeechRecognitionSequence());
            } else {
              const listenCount = yield select(getCurrentItemExecutionProp("listenCount"));
              const repeatCount = yield select(getCurrentItemExecutionProp("repeatCount"));

              yield put(
                resetSpeechRecognitionSequence({
                  wasListenUsed: !!listenCount,
                  wasRepeatUsed: !!repeatCount,
                })
              );

              const progress = yield select(getExecutionProgress);
              yield put(checkSpeechRecognitionTips({ progress }));
            }
          } else {
            if (checkpointTypes.some((type) => type === itemType)) {
              if (lastAnswer.correct) {
                yield put(incrementCheckpointSequence());
              } else {
                yield put(resetCheckpointSequence());
              }
            }
          }
        } catch (error) {
          logError({ error, flow: CHECK_ACHIEVEMENT_EXECUTION_FLOW });
        } finally {
          if (yield cancelled()) {
            stopAudio();
          }
          yield put(endFlow(CHECK_ACHIEVEMENT_EXECUTION_FLOW));
        }
      }),
    });
  });
}
