import React from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import Icon from "./Icon";
import TranslatedText from "./TranslatedText";
import FlexRow from "./FlexRow";
import { GREY_5 } from "../consts/color";

const LinkButton = Styled.button`
  :hover {
    text-decoration: underline;
  }
`;

const MenuButtonItem = ({ icon, label, onClick, ...rest }) => (
  <FlexRow alignItems="center" cursor="pointer" minHeight={35}>
    {icon && <Icon icon={icon} size="xs" />}
    <span
      style={{
        color: GREY_5,
        marginLeft: 5,
        width: "max-content",
      }}
    >
      <LinkButton
        onClick={onClick}
        style={{
          outline: "none",
          backgroundColor: "transparent",
          border: "none",
          color: "inherit",
          paddingLeft: 0,
          textAlign: "left",
        }}
        {...rest}
      >
        <TranslatedText as="span" translateKey={label} />
      </LinkButton>
    </span>
  </FlexRow>
);

MenuButtonItem.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

MenuButtonItem.defaultProps = {
  icon: null,
};

export default MenuButtonItem;
