import head from "lodash/head";
import { put, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { clearAudios } from "../stores/audio-store";
import { clearImages } from "../stores/image-store";
import { logError } from "../util";
import browserHistory from "../browserHistory";
import { CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW } from "../consts";

export default function* () {
  yield takeLatest(getFlowStart(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW), function* () {
    try {
      clearAudios();
      clearImages();

      browserHistory.push(`${head(browserHistory.location.pathname.split("/abilities"))}/abilities`);
    } catch (error) {
      logError({ error, flow: CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW });
    } finally {
      yield put(endFlow(CLOSE_CERTIFICATION_TEST_ABILITY_EXECUTION_FLOW));
    }
  });
}
