import React from "react";
import PropTypes from "prop-types";

const Column = (props) => (
  <div
    className={`col 
      ${props.lgSize ? `col-lg-${props.lgSize}` : ""}
      ${props.mdSize ? `col-md-${props.mdSize}` : ""}
      ${props.smSize ? `col-sm-${props.smSize}` : ""}
      ${props.xsSize ? `col-xs-${props.xsSize}` : ""}
      ${props.smOffset ? `col-sm-offset-${props.smOffset}` : ""}
      ${props.xsOffset ? `col-xs-offset-${props.xsOffset}` : ""}
    `}
    style={{
      transition: "width .5s ease",
      ...props.style,
    }}
  >
    {props.children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  lgSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  mdSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  smSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xsSize: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  smOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  xsOffset: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
};

Column.defaultProps = {
  lgSize: null,
  mdSize: null,
  smSize: null,
  xsSize: 12,
  smOffset: null,
  xsOffset: null,
};

export default Column;
