import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import getForm from "student-front-commons/src/selectors/getForm";
import { getFlowStart } from "student-front-commons/src/selectors/flow";
import { createMessageChannel } from "student-front-commons/src/services/messageService";
import browserHistory from "../browserHistory";
import { CREATE_NEW_MESSAGE_CHANNEL_FLOW, LOAD_MESSAGE_CHANNELS_FLOW, NEW_MESSAGE_CHANNEL_FORM } from "../consts";
import { logError } from "../util";

export default function* () {
  yield takeLatest(getFlowStart(CREATE_NEW_MESSAGE_CHANNEL_FLOW), function* () {
    try {
      const form = yield select(getForm(NEW_MESSAGE_CHANNEL_FORM));

      yield call(createMessageChannel, {
        ...form.values,
      });

      yield put(startFlow(LOAD_MESSAGE_CHANNELS_FLOW, { page: 1, size: 10 }));

      yield delay(3000);
      browserHistory.replace({
        pathname: browserHistory.location.pathname,
        state: {
          messagePopoverScene: "channels",
          messageChannel: null,
        },
      });
    } catch (error) {
      logError({ error, flow: CREATE_NEW_MESSAGE_CHANNEL_FLOW });
    } finally {
      yield put(endFlow(CREATE_NEW_MESSAGE_CHANNEL_FLOW));
    }
  });
}
