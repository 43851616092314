import React, { Component } from "react";
import Flow from "student-front-commons/src/components/Flow";
import FetchData from "student-front-commons/src/components/FetchData";
import { getPlacementTestLevels } from "student-front-commons/src/services/placementTestLevelService";
import PlacementTestPauseModal from "./PlacementTestPauseModal";
import { PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW } from "../../../consts";

class PlacementTestPauseModalContainer extends Component {
  render() {
    return (
      <FetchData service={getPlacementTestLevels}>
        {({ isFetching, data }) => (
          <Flow autoStart={false} id={PAUSE_PLACEMENT_TEST_ITEM_EXECUTION_FLOW}>
            {({ isPending, getParams }) =>
              isPending && (
                <PlacementTestPauseModal
                  pauseTime={30}
                  placementLevel={getParams().placementTestLevel}
                  placementCourses={data || []}
                />
              )
            }
          </Flow>
        )}
      </FetchData>
    );
  }
}

export default PlacementTestPauseModalContainer;
