import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import CircularButton from "../CircularButton";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { get, head, sample } from "lodash";
import translations from "../../locales";
import QuestionLabel from "../QuestionLabel";
import PopoverButton from "../PopoverButton";
import { WHITE } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";
import { useSelector } from "react-redux";
import { getExecutionHelpNotifications } from "student-front-commons/src/selectors/executionAchievement";

const sentryUserAction = { mainComponent: "RepeatButton" };

function RepeatButton(props) {
  const [message, setMessage] = useState();
  const whitelabelThemeContext = useContext(WhitelabelContext);
  const notifications = useSelector(getExecutionHelpNotifications);

  const repeatNotification = head(
    notifications.filter((t) => ["NO_REPEAT_SEQUENCE", "REPEAT_USAGE_TIP"].some((x) => t === x))
  );

  useEffect(() => {
    if (repeatNotification) {
      setMessage(sample(get(translations, `unitExecutionNotification.${repeatNotification}`, [])));
    } else {
      setMessage(null);
    }
  }, [repeatNotification]);

  return (
    <MediaQuery maxWidth={612}>
      {(popoverMatches) => (
        <PopoverButton
          isOpen={!!message}
          position="left-corner"
          backgroundColor={whitelabelThemeContext.primaryColor}
          maxWidth={popoverMatches ? window.screen.availWidth * 0.6 : 500}
          padding={7}
          content={
            <QuestionLabel fontSize={12} color={WHITE} cursor="auto">
              {message}
            </QuestionLabel>
          }
        >
          <MediaQuery maxWidth={540}>
            {(matches) => (
              <CircularButton
                color={whitelabelThemeContext.primaryColor}
                icon="repeat"
                size={matches ? "md" : "lg"}
                onClick={() => {
                  props.onClick();
                  addSentryUserAction({
                    ...sentryUserAction,
                    clickedComponent: "CircularButton",
                    action: `Repeat Unit Item`,
                  });
                }}
                disabled={props.isDisabled}
              />
            )}
          </MediaQuery>
        </PopoverButton>
      )}
    </MediaQuery>
  );
}

RepeatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default RepeatButton;
