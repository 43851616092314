import React, { Component } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { GREY_5 } from "../consts/color";
import Icon from "./Icon";

const SelectOption = Styled.li`
  padding: 7px 10px;
  font-size: 18px;
  color: ${GREY_5};
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover {
    background-color: rgba(146, 146, 146, 0.1);
  }
`;

export default class Select extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      })
    ).isRequired,
    onChange: PropTypes.func,
    onOptionsOpen: PropTypes.func,
    onOptionsClose: PropTypes.func,
    isOpen: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.shape({}),
  };

  static defaultProps = {
    value: null,
    onChange: () => null,
    disabled: false,
    placeholder: "Select an option",
    style: {},
    isOpen: false,
  };

  state = {
    showOptions: this.props.isOpen,
    isOptionsParentControlled: !!(this.props.onOptionsClose || this.props.onOptionsOpen),
  };

  componentDidMount() {
    document.addEventListener("click", this.handleOutsideClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleOutsideClick, false);
  }

  handleToggleOptions = (e) => {
    if (!this.props.isOpen) {
      this.props.onOptionsOpen?.();
    } else {
      this.props.onOptionsClose?.();
    }
    e.stopPropagation();
    this.setState(
      {
        showOptions: !this.state.showOptions,
      },
      () => {
        if (this.state.showOptions) {
          document.addEventListener("click", this.handleOutsideClick, false);
        } else {
          document.removeEventListener("click", this.handleOutsideClick, false);
        }
      }
    );
  };

  handleOutsideClick = (e) => {
    if (this.node && this.node.contains(e.target)) {
      return;
    }

    this.setState({
      showOptions: false,
    });
    document.removeEventListener("click", this.handleOutsideClick, false);
  };

  handleOnChange = (value) => {
    this.setState({
      showOptions: false,
    });
    this.props.onChange(value);
  };

  render() {
    return (
      <div
        style={{
          position: "relative",
          minWidth: "100%",
        }}
      >
        <button
          disabled={this.props.disabled}
          onClick={this.handleToggleOptions}
          style={{
            backgroundColor: "#fff",
            borderRadius: 3,
            border: "1px solid #cecece",
            padding: "6px 25px 6px 10px",
            outline: "none",
            fontSize: 18,
            color: GREY_5,
            position: "relative",
            textAlign: "left",
            ...this.props.style,
          }}
        >
          {this.props.value || this.props.placeholder}
          <div
            style={{
              position: "absolute",
              top: 3,
              right: 5,
            }}
          >
            <Icon
              icon={
                (this.state.isOptionsParentControlled ? this.props.isOpen : this.state.showOptions)
                  ? "chevron-up"
                  : "chevron-down"
              }
              size="xs"
            />
          </div>
        </button>
        {(this.state.isOptionsParentControlled ? this.props.isOpen : this.state.showOptions) && (
          <ul
            ref={(node) => {
              this.node = node;
            }}
            style={{
              margin: 0,
              padding: 0,
              listStyle: "none",
              backgroundColor: "#fff",
              borderRadius: 3,
              border: "1px solid #cecece",
              boxShadow: "0px 3px 3px 0 rgba(0, 0, 0, 0.1)",
              transition: "all .3s",
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              position: "relative",
              top: -2,
              zIndex: 1,
            }}
          >
            {this.props.options.map((option) => (
              <SelectOption key={`option-${option.label}`} onClick={() => this.handleOnChange(option.value)}>
                {option.label}
              </SelectOption>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
