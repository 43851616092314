import { call, put, race, select, take, takeLatest } from "redux-saga/effects";
import get from "lodash/get";
import last from "lodash/last";
import { getFlow, getFlowEnd, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endFlow, startFlow } from "student-front-commons/src/actions/flow";
import { saveUnitItemExecution } from "student-front-commons/src/services/unitExecutionService";
import {
  CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  CLOSE_UNIT_EXECUTION_FLOW,
  PLAY_ITEM_AUDIO_FLOW,
  SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW,
  START_NEXT_UNIT_ITEM_EXECUTION,
} from "../consts";
import { logError } from "../util";
import {
  disableItemExecution,
  enableItemExecution,
  enableItemExecutionValidation,
  removeLastItemExecutionAttempt,
} from "student-front-commons/src/actions/itemExecution";
import { addExecutionAnswer } from "student-front-commons/src/actions/execution";
import { showMessage } from "student-front-commons/src/actions/systemMessage";
import { getAssociativeItemOrderByItemId } from "student-front-commons/src/selectors/execution";
import { getItemsExecutionsType } from "student-front-commons/src/selectors/itemExecution";
import { VALIDATION_STEP_ITEMS } from "student-front-commons/src/consts";

const typesWithoutAnswer = ["TEXT", "VIDEO", "CONTENT_VIDEO", "AUDIO_LONG", "VIDEO_LONG", "IMAGE", "DIALOGUE"];

export default function* () {
  yield takeLatest(getFlowStart(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW), function* () {
    yield race({
      cancel: take(getFlowStart(CLOSE_UNIT_EXECUTION_FLOW)),
      call: call(function* () {
        try {
          yield put(disableItemExecution());

          let execution = yield select((state) => state.executions);
          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.reduce(function* (promise, id) {
            yield promise;

            const executionItem = yield select((state) => state.itemExecutions.byId[id]);

            const answer = yield call(saveUnitItemExecution, {
              module: execution.module,
              unit: execution.unit,
              unitExecution: execution.id,
              item: executionItem.item.id,
              correct:
                typesWithoutAnswer.some((type) => type === executionItem.item.type.key) ||
                get(last(executionItem.attempts), "correct", ""),
              errorCount: executionItem.errorCount,
              repeatCount: executionItem.repeatCount,
              recordCount: executionItem.recordCount,
              listenCount: executionItem.listenCount,
              readCount: executionItem.readCount,
              translateCount: executionItem.translateCount,
              userAwayCount: executionItem.userAwayCount,
              timeReportingError: executionItem.timeReportingError,
              attempts: executionItem.attempts,
              audio: executionItem.recordFile,
            });

            const associativeItemOrder = yield select(getAssociativeItemOrderByItemId(executionItem.item.id));

            yield put(addExecutionAnswer({ answer: answer.id, itemOrder: associativeItemOrder }));
          }, Promise.resolve());

          const checkAnswerFlow = yield select(getFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          if (checkAnswerFlow?.isPending) {
            yield take(getFlowEnd(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          }

          const itemExecutionsType = yield select(getItemsExecutionsType);
          if (VALIDATION_STEP_ITEMS.includes(itemExecutionsType)) {
            yield put(enableItemExecutionValidation());
          } else {
            yield put(startFlow(START_NEXT_UNIT_ITEM_EXECUTION));
          }

          const playAudioFlow = yield select(getFlow(PLAY_ITEM_AUDIO_FLOW));
          if (playAudioFlow?.isPending) {
            yield put(endFlow(PLAY_ITEM_AUDIO_FLOW));
          }
        } catch (error) {
          logError({ error, flow: SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW });

          const checkAnswerFlow = yield select(getFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          if (checkAnswerFlow?.isPending) {
            yield take(getFlowEnd(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
          }
          yield put(
            showMessage({
              message: "error.error_save_answer",
            })
          );

          const itemIds = yield select((state) => state.itemExecutions.allIds);
          yield itemIds.map(function* (id) {
            const executionItem = yield select((state) => state.itemExecutions.byId[id]);

            if (
              !["PRESENTATION", "SPEECH_PRACTICE", "PRONUNCIATION", "SPEECH_PRACTICE"].find(
                (type) => type === executionItem.item.type.key
              )
            ) {
              yield put(removeLastItemExecutionAttempt(executionItem.item.id));
            }
          });

          yield put(enableItemExecution());
        } finally {
          yield put(endFlow(SAVE_UNIT_ITEM_EXECUTION_ANSWER_FLOW));
        }
      }),
    });
  });
}
