import React, { Component } from "react";
import moment from "moment";
import orderBy from "lodash/orderBy";
import Entity from "student-front-commons/src/components/Entity";
import FetchData from "student-front-commons/src/components/FetchData";
import { getStatsByPeriodAndGroup } from "student-front-commons/src/services/studentStatsService";
import { getPositionsHistoryByPeriod } from "student-front-commons/src/services/positionsService";
import HistoryPanel from "./HistoryPanel";

class HistoryPanelContainer extends Component {
  normalizeData = (data, profile) => {
    return orderBy(
      (data || []).filter((item) => moment(item.date, "YYYY-MM").endOf("month").isAfter(moment(profile.createdAt))),
      ["date"],
      ["desc"]
    );
  };

  render() {
    const from = moment().subtract(6, "month").startOf("month").format("YYYY-MM-DD");
    const to = moment().endOf("month").format("YYYY-MM-DD");
    return (
      <Entity name="profile">
        {({ getById }) => (
          <FetchData
            service={getPositionsHistoryByPeriod}
            params={{
              id: sessionStorage.getItem("id"),
              from,
              to,
              group: "month",
            }}
          >
            {({ data: positions, isFetching: isFetchingPositions }) => (
              <FetchData
                service={getStatsByPeriodAndGroup}
                params={{
                  id: sessionStorage.getItem("id"),
                  from,
                  to,
                  group: "month",
                }}
              >
                {({ data: stats, isFetching: isFetchingStats }) => (
                  <HistoryPanel
                    fetching={isFetchingPositions || isFetchingStats}
                    hasData={!isFetchingPositions && !isFetchingStats && !!stats}
                    stats={this.normalizeData(stats, getById(sessionStorage.getItem("id")))}
                    positions={this.normalizeData(positions, getById(sessionStorage.getItem("id")))}
                  />
                )}
              </FetchData>
            )}
          </FetchData>
        )}
      </Entity>
    );
  }
}

export default HistoryPanelContainer;
