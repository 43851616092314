import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * list the student grades
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.id - the student id
 *
 * @returns {Promise<*>}
 */
export const getGrades = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return await safeCall(
    async () => {
      return await request({
        url: `students/${payload.id}/grades`,
        method: "get",
      });
    },
    "error_get_student_grades",
    "Unexpected error to load grades."
  );
};
