import kebabCase from "lodash/kebabCase";
import { call, put, select, spawn, takeLatest } from "redux-saga/effects";
import { endFlow } from "student-front-commons/src/actions/flow";
import { getFlow, getFlowStart } from "student-front-commons/src/selectors/flow";
import { endUnitExecution } from "student-front-commons/src/services/unitExecutionService";
import browserHistory from "../browserHistory";
import { END_UNIT_EXECUTION_FLOW } from "../consts";
import { logError } from "../util";
import { endExecution } from "student-front-commons/src/actions/execution";
import { getModulesByCourse } from "student-front-commons/src/services/moduleService";
import { addEntity, mergeEntities } from "student-front-commons/src/actions/entity";
import { getEntityById } from "student-front-commons/src/selectors/entity";

export default function* () {
  yield takeLatest(getFlowStart(END_UNIT_EXECUTION_FLOW), function* () {
    const flow = yield select(getFlow(END_UNIT_EXECUTION_FLOW));
    try {
      const unitExecution = yield select((state) => state.executions);
      // check if exists, because students are using the result url to access the platform
      if (!unitExecution || !unitExecution.id) {
        yield call(browserHistory.replace, "/");
        return;
      }

      const result = yield call(endUnitExecution, {
        module: flow.params.module,
        unit: flow.params.unit,
        unitExecution: flow.params.execution,
      });
      yield put(endExecution(result));

      if (unitExecution.isReview) {
        const scheduledUnitReview = yield select(getEntityById("scheduledUnitReview", flow.params.unit));
        yield put(
          addEntity("scheduledUnitReview", {
            ...scheduledUnitReview,
            lastExecutionCompletedAt: new Date(),
          })
        );
      } else {
        const unit = yield select(getEntityById("unit", flow.params.unit));
        yield put(
          addEntity("unit", {
            ...unit,
            lastExecutionCompletedAt: new Date(),
          })
        );
      }

      yield spawn(function* () {
        const modules = yield call(getModulesByCourse, {
          id: sessionStorage.getItem("id"),
          course: flow.params.course,
        });
        yield put(mergeEntities(modules.entities));
      });
    } catch (error) {
      if (error && error.data && error.data.message) {
        yield call(browserHistory.replace, `/${kebabCase(error.data.message.toLowerCase())}`);
      } else {
        logError({ error, flow: END_UNIT_EXECUTION_FLOW });
        yield call(browserHistory.replace, "/error-end-unit");
      }
    } finally {
      yield put(endFlow(END_UNIT_EXECUTION_FLOW));
    }
  });
}
