import React, { useCallback, useEffect, useRef, useState } from "react";
import bgContainer from "../../images/dialogue-background.png";
import { useDispatch, useSelector } from "react-redux";
import { GREY_1, GREY_5 } from "../../consts/color";
import ScrolledContent from "../../core/ScrolledContent";
import Separator from "../../core/Separator";
import { getItemExecutionPropById, getSelectedId } from "student-front-commons/src/selectors/itemExecution";
import DialogueMessage from "./DialogueMessage";
import { CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW, START_ITEM_INTRODUCTION_FLOW } from "../../consts";
import { useFlow } from "student-front-commons/src/hooks";
import { random, shuffle } from "lodash";
import DialogueAnswer from "./DialogueAnswer";
import { Transition, animated } from "react-spring";
import { getDialogueHistory } from "student-front-commons/src/selectors/execution";
import TranslatedText from "../TranslatedText";
import { addItemExecutionAnswer, translateItem } from "student-front-commons/src/actions/itemExecution";

export default function DialogueExerciseItem() {
  const dispatch = useDispatch();
  const timeoutRef = useRef();
  const scrollTimeoutRef = useRef();
  const endOfMessagesRef = useRef(null);

  const [isTyping, setIsTyping] = useState(false);
  const [currentItemTranslationOpen, setCurrentItemTranslationOpen] = useState(null);
  const [answerOptions, setAnswerOptions] = useState([]);
  const [, startCheckAnswerFlow] = useFlow(CHECK_UNIT_ITEM_EXECUTION_ANSWER_FLOW);
  const [isIntroductionFlowPending] = useFlow(START_ITEM_INTRODUCTION_FLOW);

  const itemId = useSelector(getSelectedId);
  const text = useSelector(getItemExecutionPropById(itemId, "item.text"));
  const itemType = useSelector(getItemExecutionPropById(itemId, "item.type.key"));
  const answers = useSelector(getItemExecutionPropById(itemId, "item.answers"));
  const dialogueCharacter = useSelector(getItemExecutionPropById(itemId, "item.dialogueCharacter"));
  const isFinished = useSelector(getItemExecutionPropById(itemId, "isFinished"));
  const dialogueHistory = useSelector(getDialogueHistory);

  useEffect(() => {
    setIsTyping(false);
    setAnswerOptions([]);

    if (itemType === "DIALOGUE" && !isFinished) {
      if (dialogueCharacter === "CHARACTER") {
        setIsTyping(true);

        timeoutRef.current = setTimeout(() => {
          startCheckAnswerFlow();
        }, random(1500, 3000));
      } else {
        startCheckAnswerFlow();
      }
    }

    if (["DIALOGUE_OPTION", "DIALOGUE_OPTION_SPEECHLESS"].includes(itemType)) {
      timeoutRef.current = setTimeout(() => {
        setAnswerOptions(
          shuffle([...answers, { itemId, id: itemId, text, correct: true }]).map((answer, position) => ({
            ...answer,
            position: position + 1,
          }))
        );
      }, 2500);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [itemId, isFinished, isIntroductionFlowPending]);

  useEffect(() => {
    if (endOfMessagesRef?.current) {
      scrollTimeoutRef.current = setTimeout(() => {
        endOfMessagesRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100);
    }
    return () => {
      clearTimeout(scrollTimeoutRef?.current);
    };
  }, [dialogueHistory, answerOptions]);
  const handleTranslationOpen = useCallback(
    (itemId) => {
      if (currentItemTranslationOpen === itemId) {
        setCurrentItemTranslationOpen(null);
      } else {
        setCurrentItemTranslationOpen(itemId);
        dispatch(translateItem(itemId));
      }
    },
    [currentItemTranslationOpen]
  );

  const handleSelect = useCallback(
    (text) => {
      dispatch(addItemExecutionAnswer(itemId, { answer: text }));
      startCheckAnswerFlow();
    },
    [itemId]
  );

  return (
    <div
      style={{
        width: "890px",
        height: "600px",
        border: "15px solid #fff",
        borderRadius: "12px",
        margin: "0 auto",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          backgroundImage: `url(${bgContainer})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          width: "100%",
          height: "570px",
          borderRadius: "5px",
          backgroundColor: GREY_1,
          padding: "0 5px",
        }}
      >
        <ScrolledContent style={{ maxHeight: "570px", height: "100%" }} vertical={true} containerColor="transparent">
          <div
            ref={endOfMessagesRef}
            style={{
              paddingTop: "100px",
              minHeight: "570px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Transition
              items={dialogueHistory}
              keys={(message) => message.item.id}
              from={{ opacity: 0, transform: "translate3d(0, 60px, 0)" }}
              enter={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              leave={{ opacity: 0, transform: "translate3d(0, -60px, 0)" }}
            >
              {(message) =>
                message
                  ? (styles) => (
                      <div id={`message-${message.item.id}`}>
                        <animated.div style={{ ...styles }}>
                          <DialogueMessage
                            key={message.item.id}
                            itemId={message.item.id}
                            text={message.item.text}
                            dialogueCharacter={message.item.dialogueCharacter}
                            handleTranslationOpen={handleTranslationOpen}
                            isTranslationOpen={currentItemTranslationOpen === message.item.id}
                          />
                        </animated.div>
                      </div>
                    )
                  : []
              }
            </Transition>
            {answerOptions.length > 0 && (
              <>
                <Transition
                  unique
                  items={answerOptions.length}
                  from={{ opacity: 0, transform: "translate3d(0, 60px, 0)" }}
                  enter={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                  leave={{ opacity: 0, transform: "translate3d(0, -60px, 0)" }}
                >
                  {() => (styles) =>
                    (
                      <animated.span
                        style={{
                          display: "block",
                          marginRight: "20px",
                          marginTop: "40px",
                          marginBottom: "15px",
                          textAlign: "right",
                          ...styles,
                        }}
                      >
                        <TranslatedText
                          translateKey={`help.${itemType}.question`}
                          as="span"
                          style={{
                            fontSize: "16px",
                            color: GREY_5,
                            fontWeight: "bold",
                          }}
                        />
                      </animated.span>
                    )}
                </Transition>
                <Transition
                  items={answerOptions}
                  keys={(item) => item.text}
                  from={{ opacity: 0, transform: "translate3d(60px, 0, 0)" }}
                  enter={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                  leave={{ opacity: 0, transform: "translate3d(-60px, 0, 0)" }}
                  trail={500}
                >
                  {(answer) =>
                    answer
                      ? (styles) => (
                          <div id={`answer-${answer.id}`}>
                            <animated.div style={{ ...styles }}>
                              <DialogueAnswer
                                key={`answer-${answer.id}`}
                                position={answer.position}
                                itemId={itemId}
                                text={answer.text}
                                isCorrect={answer.correct}
                                onSelect={handleSelect}
                              />
                            </animated.div>
                          </div>
                        )
                      : []
                  }
                </Transition>
              </>
            )}
            <Separator />
            <Separator />
            <Separator />
          </div>
        </ScrolledContent>
        {isTyping && (
          <TranslatedText
            translateKey="help.DIALOGUE.typing"
            as="span"
            style={{
              display: "inline-block",
              fontSize: "16px",
              color: GREY_5,
              marginLeft: "12px",
              position: "relative",
              bottom: "30px",
            }}
          />
        )}
      </div>
    </div>
  );
}
