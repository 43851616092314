import React, { Component } from "react";
import PropTypes from "prop-types";
import { RecordRTCPromisesHandler } from "recordrtc";
import CircularLoading from "../../core/CircularLoading";
import Icon from "../../core/Icon";
import Button from "../../core/Button";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

export default class SpeechRecordButton extends Component {
  static propTypes = {
    onRecordEnd: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    loading: false,
  };

  state = {
    startingRecord: false,
    recording: false,
    submitting: false,
  };

  onAudioRecordingError = (error) => {
    navigator.permissions
      .query({ name: "microphone" })
      .then((result) => {
        if (result.state === "denied") {
          alert("O seu brower não está habilitado para o uso do microfone. Por favor verifique suas configurações");
        } else {
          alert("Por favor verifique suas configurações, não conseguimos encontrar nenhum dispositivo para gravação");
        }
      })
      .catch((errorPermission) => {
        alert("Por favor verifique suas configurações e as permissões para utilizar o microfone.");
      });
  };

  captureUserMedia = (callback) => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(callback)
        .catch(this.onAudioRecordingError);
    } else {
      navigator.getUserMedia =
        navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      navigator.getUserMedia({ audio: true, video: false }, callback, this.onAudioRecordingError);
    }
  };

  startRecording = () => {
    if (this.state.recording) {
      return;
    }

    this.setState({
      startingRecord: true,
    });
    this.captureUserMedia((stream) => {
      this.stream = stream;
      this.recordAudio = new RecordRTCPromisesHandler(stream, {
        type: "audio",
      });
      this.recordAudio
        .startRecording()
        .then(() => {
          this.setState({
            recording: true,
            startingRecord: false,
          });
        })
        .catch((error) => {});
    });
  };

  stopRecording = () => {
    this.recordAudio
      .stopRecording()
      .then(async () => {
        this.stream.stop();
        this.setState({
          recording: false,
        });
        this.props.onRecordEnd(await this.recordAudio.getBlob());
      })
      .catch((error) => {});
  };

  render() {
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, secondaryColor }) => (
          <Button
            onClick={this.state.recording ? this.stopRecording : this.startRecording}
            onDoubleClick={(event) => event.preventDefault()}
            disabled={this.props.disabled || this.props.loading || this.state.startingRecord}
            style={{
              width: 60,
              height: 60,
              minWidth: 60,
              minHeight: 60,
              backgroundColor: "transparent",
              boxShadow: "none",
              boxSizing: "border-box",
              border: "solid 3px",
              // eslint-disable-next-line no-nested-ternary
              borderColor:
                this.props.disabled || this.props.loading || this.state.startingRecord
                  ? "rgba(198, 198, 198, 0.5)"
                  : this.state.recording
                  ? "rgba(198, 40, 40, 0.3)"
                  : primaryColor,
              outline: "none",
              borderRadius: "50%",
              cursor:
                this.props.disabled || this.props.loading || this.state.startingRecord ? "not-allowed" : "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              overflow: "hidden",
              transition: "all .5s",
            }}
          >
            {this.props.loading && <CircularLoading />}
            {!this.props.loading && (
              <Icon
                size="md"
                icon={this.state.recording ? "stop" : "microphone"}
                // eslint-disable-next-line no-nested-ternary
                color={
                  this.props.disabled || this.props.loading
                    ? "#c6c6c6"
                    : this.state.recording
                    ? "#c62828"
                    : primaryColor
                }
                cursor={this.props.disabled || this.props.loading ? "not-allowed" : "pointer"}
              />
            )}
          </Button>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}
