import axios from "axios";
import { getAssetsUrl } from "../config";

/**
 * Transform time string to milliseconds
 *
 * @param {String} time - The time string formatted in `HH:mm:ss.SSS`
 *
 * @returns {Number}
 */
function timeStringToMs(time) {
  const [hours, minutes, seconds] = time.trim().split(":").map(Number);

  return hours * 60 * 60 * 1000 + minutes * 60 * 1000 + seconds * 1000;
}

/**
 * gets the subtitles and transform to JSON
 *
 * @param {Object} payload - The object with all the params
 * @param {string} payload.uri - the uri of the subtitles
 * @param {string} payload.language - the language of the subtitles
 *
 * @returns {Promise<*>}
 */
export const getSubtitles = async (payload) => {
  const response = await axios({
    url: `${getAssetsUrl()}/${payload.uri}`,
    method: "get",
  });

  const subtitles = response.data
    .split("\n")
    .map((chunk, i, arr) => {
      if (chunk.includes("-->")) {
        const [startTime, endTime] = chunk.split("-->");

        const withHours = startTime.split(":").length === 3;
        return {
          startTime: timeStringToMs(withHours ? startTime : `00:${startTime}`),
          endTime: timeStringToMs(withHours ? endTime : `00:${endTime}`),
          text: arr[i + 1].trim(),
        };
      }
      return null;
    })
    .filter(Boolean);

  return { data: subtitles, language: payload.language };
};
