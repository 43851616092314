import request from "../core/request";
import validate from "../core/validate";
import safeCall from "../core/safeCall";

/**
 * get instruction audios based on key, locale and type
 *
 * @param {object} payload - The instruction audios type
 * @param {string} payload.type - The instruction audios type
 * @param {array} payload.keys - The instruction audio keys
 * @param {string} payload.locale - The instruction audio locale
 *
 * @returns {Promise<*>}
 */
export const getInstructionVideos = async (payload) => {
  validate(
    {
      type: {
        presence: {
          allowEmpty: false,
        },
      },
      keys: {
        presence: {
          allowEmpty: false,
        },
      },
      locale: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  return safeCall(async () => {
    return await request(
      {
        url: `instruction-videos/${payload.locale}/${payload.type}`,
        method: "get",
        params: { keys: payload.keys },
      },
      "error_get_instruction_videos",
      "Unexpected error getting instruction videos."
    );
  });
};
