import React from "react";
import { withRouter } from "react-router-dom";
import CourseSummary from "./CourseSummary";
import { useSelector } from "react-redux";
import { getCoursePercentageProgress } from "student-front-commons/src/selectors/entity";

const CourseSummaryContainer = (props) => {
  const coursePercentage = useSelector(getCoursePercentageProgress(props.match.params.idCourse));

  return (
    <div style={{ display: "flex", justifyContent: "center", flex: 1 }}>
      <CourseSummary coursePercentage={coursePercentage} />
    </div>
  );
};

export default withRouter(CourseSummaryContainer);
