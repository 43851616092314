import React from "react";
import MicConfig from "../../images/mic-config.png";
import Separator from "../../core/Separator";
import InitialStepOptionsContainer from "./InitialStepOptionsContainer";
import TranslatedText from "../../core/TranslatedText";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const InitialStep = () => (
  <WhitelabelContext.Consumer>
    {({ primaryColor }) => (
      <div>
        <div
          style={{
            backgroundColor: primaryColor,
            width: "100%",
            height: 280,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 8px 0px",
          }}
        >
          <img alt="icon" src={MicConfig} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "42px 0px",
          }}
        >
          <TranslatedText
            as="h1"
            translateKey="microphoneTest.title"
            style={{
              fontWeight: "bold",
              fontSize: 22,
              lineHeight: "24px",
              margin: 0,
              color: "#607d8b",
            }}
          />
          <Separator size="md" />
          <Separator size="xs" />
          <TranslatedText
            as="p"
            translateKey="microphoneTest.description"
            style={{
              fontWeight: "bold",
              fontSize: 16,
              margin: 0,
              color: "#607d8b",
            }}
          />
          <Separator size="lg" />
          <Separator size="sm" />
          <InitialStepOptionsContainer />
        </div>
      </div>
    )}
  </WhitelabelContext.Consumer>
);

export default InitialStep;
