import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import Styled from "styled-components";
import { BLUE_1, GREY_5, SPEECH_GREEN, SPEECH_ORANGE, SPEECH_RED } from "../../consts/color";
import SpeechRecognitionResultWordButton from "./SpeechRecognitionResultWordButton";
import { useSelector } from "react-redux";
import { getCurrentItemExecutionProp } from "student-front-commons/src/selectors/itemExecution";
import { getInstructionStep } from "student-front-commons/src/selectors/itemInstruction";

const TextButton = Styled.button`
  color: ${(props) => props.color};
  margin: 0px;
  background: none;
  border: none;
  outline: none;
  padding: 0;
  borderRadius: 0;
  
  &:hover {
    text-decoration: underline;
  }
`;

function SpeechRecognitionResultWord(props) {
  const nodeRef = useRef();
  const [showPlay, setShowPlay] = useState(false);

  const isDisabled = useSelector(getCurrentItemExecutionProp("isDisabled"));
  const isRecording = useSelector(getCurrentItemExecutionProp("isRecording"));
  const score = useSelector(
    getCurrentItemExecutionProp(`speechRecognitionResult.wordScoreList[${props.index}].qualityScore`)
  );

  const currentInstructionStep = useSelector(getInstructionStep);

  const speechRecognitionMinimumScore = useSelector((state) => state.configurations.scoreToPassOfSpeechRecognition);

  const handleOutsidePopoverClick = useCallback((e) => {
    if (nodeRef.current?.contains(e.target)) {
      return;
    }

    setShowPlay(false);
    document.removeEventListener("click", handleOutsidePopoverClick);
  }, []);

  const handleTogglePopover = useCallback(() => {
    setShowPlay(!showPlay);
    if (showPlay) {
      document.removeEventListener("click", handleOutsidePopoverClick);
    } else {
      document.addEventListener("click", handleOutsidePopoverClick);
    }
  }, [showPlay, handleOutsidePopoverClick]);

  let textColor = GREY_5;
  if (score && score >= speechRecognitionMinimumScore) {
    textColor = SPEECH_GREEN;
  } else if (score && score >= 50 && score < speechRecognitionMinimumScore) {
    textColor = SPEECH_ORANGE;
  } else if (score && score < 50) {
    textColor = SPEECH_RED;
  }

  return (
    <div
      ref={nodeRef}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          padding: 10,
          backgroundColor: "#fff",
          bottom: 40,
          borderRadius: 3,
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 2px 0px",
          display:
            showPlay && (!currentInstructionStep || currentInstructionStep === "speech-recognition-result")
              ? "flex"
              : "none",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: 0,
            height: 0,
            bottom: -10,
            position: "absolute",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid #fff",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <SpeechRecognitionResultWordButton type="user" index={props.index} color={textColor} />
          <SpeechRecognitionResultWordButton type="native" index={props.index} color={BLUE_1} />
        </div>
      </div>
      {!isRecording && (
        <TextButton
          onClick={handleTogglePopover}
          disabled={isDisabled || (currentInstructionStep && currentInstructionStep !== "speech-recognition-result")}
          color={textColor}
        >
          {props.text}
        </TextButton>
      )}
      {isRecording && props.shouldShowWhenRecording && <TextButton color={GREY_5}>{props.text}</TextButton>}
      {isRecording && !props.shouldShowWhenRecording && (
        <TextButton color="#fff" style={{ textDecoration: "underline", textDecorationColor: GREY_5 }}>
          {props.text}
        </TextButton>
      )}
    </div>
  );
}

SpeechRecognitionResultWord.propTypes = {
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  shouldShowWhenRecording: PropTypes.bool,
};

SpeechRecognitionResultWord.defaultProps = {
  shouldShowWhenRecording: false,
};

export default SpeechRecognitionResultWord;
