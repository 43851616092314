import React, { Component } from "react";
import PropTypes from "prop-types";
import Separator from "../core/Separator";
import TranslatedText from "../core/TranslatedText";
import Modal from "../core/Modal";
import ButtonV2 from "../core/ButtonV2";

class UserAwayModal extends Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    return (
      <Modal>
        <h1
          style={{
            opacity: 0.8,
            color: "#424242",
            lineHeight: "30px",
            margin: 0,
          }}
        >
          {this.props.message}
        </h1>
        <Separator size="md" />
        <ButtonV2 onClick={this.props.onClose} labelKey="userAway.button" intent="course-primary" size="md" />
        <Separator size="lg" />
        <TranslatedText
          as="p"
          translateKey="userAway.description"
          style={{
            opacity: 0.8,
            color: "#424242",
            lineHeight: "22px",
            margin: 0,
          }}
        />
        <Separator size="md" />
        <TranslatedText
          as="p"
          translateKey="userAway.penalityLabel"
          style={{
            opacity: 0.8,
            color: "#424242",
            lineHeight: "22px",
            fontSize: "12px",
            margin: 0,
          }}
        />
      </Modal>
    );
  }
}

export default UserAwayModal;
