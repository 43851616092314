import React, { Component } from "react";
import { getGrades } from "student-front-commons/src/services/gradesService";
import FetchData from "student-front-commons/src/components/FetchData";
import Entity from "student-front-commons/src/components/Entity";
import GradePanel from "./GradePanel";

class GradePanelContainer extends Component {
  render() {
    return (
      <Entity name="profile">
        {({ getById: getProfileById }) => {
          const profile = getProfileById(sessionStorage.getItem("id"));
          if (profile.apiVersion === "V2") {
            return (
              <Entity name="company">
                {({ getById: getCompanyById }) => {
                  const company = getCompanyById(profile.company);
                  return (
                    <FetchData
                      service={getGrades}
                      params={{
                        id: sessionStorage.getItem("id"),
                      }}
                    >
                      {({ isFetching, data }) => (
                        <GradePanel
                          fetching={isFetching}
                          data={data || []}
                          school={company}
                          weeklyHoursRequired={profile.weeklyHoursRequired}
                        />
                      )}
                    </FetchData>
                  );
                }}
              </Entity>
            );
          }

          return (
            <Entity name="schoolClass">
              {({ getById: getSchoolClassById }) => (
                <Entity name="school">
                  {({ getById: getSchoolById }) => {
                    const schoolClass = getSchoolClassById(profile.schoolClass);
                    const school = getSchoolById(schoolClass.school);

                    return (
                      <FetchData
                        service={getGrades}
                        params={{
                          id: sessionStorage.getItem("id"),
                        }}
                      >
                        {({ isFetching, data }) => (
                          <GradePanel
                            fetching={isFetching}
                            data={data || []}
                            school={school}
                            weeklyHoursRequired={schoolClass.weeklyHoursRequired}
                          />
                        )}
                      </FetchData>
                    );
                  }}
                </Entity>
              )}
            </Entity>
          );
        }}
      </Entity>
    );
  }
}

export default GradePanelContainer;
