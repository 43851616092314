import React, { Component } from "react";
import FetchData from "student-front-commons/src/components/FetchData";
import { getAchievements } from "student-front-commons/src/services/achievementService";
import AchievementListPanel from "./AchievementListPanel";

class AchievementListPanelContainer extends Component {
  render() {
    return (
      <FetchData
        service={getAchievements}
        params={{
          id: sessionStorage.getItem("id"),
        }}
      >
        {({ data, isFetching }) => <AchievementListPanel fetching={isFetching} achievements={data || []} />}
      </FetchData>
    );
  }
}

export default AchievementListPanelContainer;
