import React, { Component } from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";
import CircularLoading from "../CircularLoading";
import DefaultImage from "../../images/no-image.jpg";
import { getImage } from "../../stores/image-store";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default class ItemImage extends Component {
  static propTypes = {
    item: PropTypes.shape({
      id: PropTypes.string,
    }).isRequired,
    style: PropTypes.shape({}),
    zoomable: PropTypes.bool,
  };

  static defaultProps = {
    style: {},
    zoomable: false,
  };

  state = { loading: false, reloadCount: 0 };

  handleLoad = (event) => {
    if (this.props.zoomable) {
      event.currentTarget.style.cursor = "zoom-in";
    }
    this.setState({
      loading: false,
    });
  };

  handleLargeImageLoad = (event) => {
    event.currentTarget.width = event.currentTarget.clientWidth * 2;
    event.currentTarget.height = event.currentTarget.clientHeight * 2;
  };

  handleError = () => {
    if (this.state.reloadCount === 3) {
      return;
    }

    this.setState({
      loading: true,
      reloadCount: this.state.reloadCount + 1,
    });
  };

  render() {
    return (
      <div
        style={{
          width: "100%",
        }}
      >
        {this.state.loading && (
          <div
            style={{
              margin: "60px auto",
            }}
          >
            <CircularLoading
              size="sm"
              rgb={{
                r: 175,
                g: 175,
                b: 175,
              }}
            />
          </div>
        )}
        <MediaQuery maxDeviceWidth={425}>
          {(matches) =>
            this.props.zoomable ? (
              <Zoom>
                <img
                  alt={`presentation-for-${this.props.item.id}`}
                  src={
                    this.state.reloadCount >= 3
                      ? DefaultImage
                      : getImage(`items/${this.props.item.id}/images/lg.png`) ||
                      `${process.env.REACT_APP_FILES_URL}/items/${this.props.item.id}/images/${matches ? "xs" : "lg"}.png${
                        this.state.reloadCount ? `?${this.state.reloadCount}` : ""
                      }`
                  }
                  onLoad={this.handleLoad}
                  onError={this.handleError}
                  style={{
                    ...this.props.style,
                    transition: "opacity .5s",
                    opacity: this.state.loading ? 0 : 1,
                    position: this.state.loading ? "absolute" : "relative",
                  }}
                />
              </Zoom>
            ) : (
              <img
                alt={`presentation-for-${this.props.item.id}`}
                src={
                  this.state.reloadCount >= 3
                    ? DefaultImage
                    : getImage(`items/${this.props.item.id}/images/lg.png`) ||
                    `${process.env.REACT_APP_FILES_URL}/items/${this.props.item.id}/images/${matches ? "xs" : "lg"}.png${
                      this.state.reloadCount ? `?${this.state.reloadCount}` : ""
                    }`
                }
                onLoad={this.handleLoad}
                onError={this.handleError}
                style={{
                  ...this.props.style,
                  transition: "opacity .5s",
                  opacity: this.state.loading ? 0 : 1,
                  position: this.state.loading ? "absolute" : "relative",
                }}
              />
            )
          }
        </MediaQuery>
      </div>
    );
  }
}
