import { Map } from "immutable";
import { addSentryUserAction, logError } from "../util";

let store = new Map();
const sentryUserAction = { mainComponent: "insertImage" };

export const insertImage = async ({ url }) => {
  const imageData = await new Promise((resolve) => {
    fetch(`${process.env.REACT_APP_FILES_URL}/${url}`)
      .then(function (response) {
        if (response.ok) {
          return response.blob();
        }
        throw Error("No Image Download");
      })
      .then(function (myBlob) {
        let reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
          reader = null;
        };
        reader.onerror = () => {
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "None",
            action: `Error inserting image ${url}`,
          });
          logError({ error: `Error inserting image ${url}`, flow: "image-store" });
          resolve(null);
        };
        reader.readAsDataURL(myBlob);
      })
      .catch(() => resolve(null));
  });

  store = store.set(url, imageData);
};

export const getImage = (url) => store.get(url);

export const clearImages = () => {
  store = store.clear();
  addSentryUserAction({
    ...sentryUserAction,
    clickedComponent: "None",
    action: `Unit Images cleared`,
  });
};
