import React, { useCallback } from "react";
import SystemMessage from "./SystemMessage";
import { useDispatch, useSelector } from "react-redux";
import { getErrorMessage } from "student-front-commons/src/selectors/systemMessage";
import { hideMessage } from "student-front-commons/src/actions/systemMessage";

const SystemMessageContainer = () => {
  const dispatch = useDispatch();
  const errorMessage = useSelector(getErrorMessage);

  const handleClose = useCallback(() => {
    dispatch(hideMessage());
  }, []);

  if (!errorMessage || !errorMessage.message) {
    return null;
  }

  return <SystemMessage message={errorMessage.message} type={errorMessage.type} onClose={handleClose} />;
};

export default SystemMessageContainer;
