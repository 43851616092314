import LocalizedStrings from "react-localization";
import portugueseTranslations from "./portuguese";
import englishTranslations from "./english";
import spanishTranslations from "./spanish";

const localizedLabels = new LocalizedStrings({
  en: englishTranslations,
  pt: portugueseTranslations,
  es: spanishTranslations,
});

export default localizedLabels;
