import React, { Component } from "react";
import Separator from "../../../core/Separator";
import MasteryTestContent from "../MasteryTestContent";
import MasteryTestItemsProgressContainer from "../MasteryTestItemsProgressContainer";
import MasteryTestSubHeaderContainer from "../MasteryTestSubHeaderContainer";
import MasteryTestItemExecutionFormContainer from "../MasteryTestItemExecutionFormContainer";
import ErrorModalContainer from "../../../error/ErrorModalContainer";

class MasteryTestTastingExecutionScene extends Component {
  render() {
    return (
      <div>
        <MasteryTestSubHeaderContainer />
        <MasteryTestContent>
          <MasteryTestItemsProgressContainer />
          <Separator />
          <MasteryTestItemExecutionFormContainer />
        </MasteryTestContent>
        <ErrorModalContainer />
      </div>
    );
  }
}

export default MasteryTestTastingExecutionScene;
