import React from "react";
import PropTypes from "prop-types";
import Tooltip from "../../core/Tooltip";

const AchievementListItem = (props) => (
  <div
    style={{
      position: "relative",
      display: "inline-block",
      width: 40,
      height: 40,
      boxSizing: "border-box",
      textAlign: "center",
    }}
  >
    <Tooltip text={props.description}>
      <img
        alt="achievement icon"
        src={`${process.env.REACT_APP_FILES_URL}/${props.iconSrc}`}
        style={{
          position: "relative",
          zIndex: 2,
          maxHeight: 40,
        }}
      />
    </Tooltip>
  </div>
);

AchievementListItem.propTypes = {
  iconSrc: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AchievementListItem;
