import React from "react";

const UnitListItemColumnSeparator = () => (
  <div
    style={{
      opacity: 0.075,
      border: "1px dashed #000",
      borderRadius: "0px 10px 10px 0px",
      height: "100%",
    }}
  />
);

export default UnitListItemColumnSeparator;
