import PropTypes from "prop-types";
import Button from "../../core/Button";
import FlexColumn from "../../core/FlexColumn";
import browserHistory from "../../browserHistory";
import FlexRow from "../../core/FlexRow";
import Modal from "../../core/Modal";
import QuestionLabel from "../../core/QuestionLabel";
import TranslatedText from "../../core/TranslatedText";
import { useParams } from "react-router-dom";
import Separator from "../../core/Separator";
import { GREY_5, WHITE } from "../../consts/color";
import CourseThemeContext from "../../core/CourseThemeContext";
import { useContext } from "react";

export default function BonusTestStartModal(props) {
  const courseThemeContext = useContext(CourseThemeContext);
  const params = useParams();

  if (!props.isOpen) {
    return null;
  }
  return (
    <Modal>
      <FlexColumn alignItems="center">
        <TranslatedText as={QuestionLabel} translateKey="closeExecution.title" fontSize={24} fontWeight={900} />
        <Separator size="md" />
        <TranslatedText
          as={QuestionLabel}
          translateKey="bonusTest.introduction.confirmation"
          fontSize={14}
          fontWeight={900}
        />
        <Separator size="xl" />
        <FlexRow>
          <Button onClick={props.onClose} color={WHITE}>
            <TranslatedText
              as={QuestionLabel}
              translateKey="account.cancelAvatar"
              fontSize={14}
              color={GREY_5}
              fontWeight={900}
            />
          </Button>
          <Separator />
          <Button
            onClick={() => browserHistory.push(`/course/${params.idCourse}/bonus-test/execution`)}
            color={courseThemeContext.primary}
          >
            <TranslatedText
              as={QuestionLabel}
              translateKey="unit.button.start"
              fontSize={14}
              color={WHITE}
              fontWeight={900}
            />
          </Button>
        </FlexRow>
      </FlexColumn>
    </Modal>
  );
}

BonusTestStartModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

BonusTestStartModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};
