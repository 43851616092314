import React, { Component } from "react";
import ReactGA from "react-ga";
import { Route, Router, Switch } from "react-router-dom";
import LogRocket from "logrocket";
import { detect } from "detect-browser";
import UserReportContext from "student-front-commons/src/components/UserReportContext";
import WhitelabelAppProvider from "student-front-commons/src/components/WhitelabelAppProvider";
import browserHistory from "./browserHistory";
import UserRoutesContainer from "./UserRoutesContainer";
import CheckBrowserContainer from "./core/CheckBrowserContainer";
import CheckBrowserVersionContainer from "./core/CheckBrowserVersionContainer";
import CheckAudioPermissionContainer from "./core/CheckAudioPermissionContainer";
import RequireDesktop from "./core/RequireDesktop";
import LoginScene from "./screens/login/LoginScene";
import ForgotPasswordScene from "./screens/forgot-password/ForgotPasswordScene";
import Async from "./core/Async";
import AuthSceneContainer from "./auth/AuthSceneContainer";
import TastingRoutesContainer from "./TastingRoutesContainer";
import FlexgeLogo from "./images/flexge-logo.png";
import PlacementTestRegisterSceneContainer from "./screens/placement-test/placement-register/PlacementTestRegisterSceneContainer";
import WhitelabelConfig from "./wl.config.json";
import ReportButton from "./core/ReportButton";
import UserErrorReportForm from "./core/user-error-report/UserErrorReportForm";
import html2canvas from "html2canvas";
import UserErrorReportPrintEditor from "./core/user-error-report/UserErrorReportPrintEditor";
import { useSelector } from "react-redux";

if (process.env.REACT_APP_GA_ID && process.env.REACT_APP_ENVIRONMENT === "production") {
  ReactGA.initialize(process.env.REACT_APP_GA_ID);
  browserHistory.listen((location) => ReactGA.pageview(location.pathname));
}

function Routes() {
  const executionId = useSelector((state) => state.executions.id);

  const handleScreenShot = async (onComplete) => {
    const deviceInfo = detect();
    const canvas = await html2canvas(document.body, {
      allowTaint: true,
      useCORS: true,
      logging: false,
      backgroundColor: "#ededed",
      scrollX: 0,
      scrollY: -window.scrollY,
      scale: 1.0,
    });

    let sessionURL = LogRocket.sessionURL;
    if (!(sessionURL || "").includes("app.logrocket.com")) {
      sessionURL = "Unavailable session link";
    }
    const imageDate = canvas.toDataURL("image/png");
    onComplete(imageDate, {
      currentSessionURL: sessionURL,
      device: `${deviceInfo.name}_${deviceInfo.version}`,
      deviceSystem: deviceInfo.os,
      executionId,
    });

    // existe um bug na lib que cria a tag
    const noscript = document.querySelector("noscript");
    if (noscript) {
      noscript.display = "none";
    }
  };

  return (
    <UserReportContext
      platform="student_web"
      reportFormType={UserErrorReportForm}
      imageEditorType={UserErrorReportPrintEditor}
      onTakeScreenshot={handleScreenShot}
    >
      <Switch>
        <Route path="/login" component={LoginScene} />
        <Route path="/forgot-password" component={ForgotPasswordScene} />
        <Route path="/auth-callback" component={AuthSceneContainer} />
        <Route path="/tasting" component={TastingRoutesContainer} />
        <Route path="/placement-register" component={PlacementTestRegisterSceneContainer} />
        <Route path="/v2/placement-register" component={PlacementTestRegisterSceneContainer} />
        <Route path="/v2/placement/:slug" component={PlacementTestRegisterSceneContainer} />
        <Route path="/" component={UserRoutesContainer} />
      </Switch>
      <ReportButton />
    </UserReportContext>
  );
}

class App extends Component {
  render() {
    return (
      <WhitelabelAppProvider
        defaultLogo={FlexgeLogo}
        whitelabelConfigs={WhitelabelConfig}
        host={process.env.REACT_APP_API_URL}
      >
        {({ isLoading }) => (
          <Async fetching={isLoading}>
            <RequireDesktop
              allowedRoutes={[
                /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise/,
                /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise\/[0-9a-fA-F]{24}\/result/,
                /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/content-video-exercise/,
                /\/placement-register/,
                /\/practice-test-introduction/,
                /\/practice-test/,
                /\/practice-test-score/,
                /\/placement-test/,
                /\/placement-test-score/,
                /\/v2\/placement\/[a-zA-Z0-9_.-]/,
              ]}
            >
              <CheckBrowserContainer
                allowedRoutes={[
                  /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise/,
                  /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/exercise\/[0-9a-fA-F]{24}\/result/,
                  /\/tasting\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/units\/[0-9a-fA-F]{24}\/content-video-exercise/,
                  /\/placement-register/,
                  /\/practice-test-introduction/,
                  /\/practice-test/,
                  /\/practice-test-score/,
                  /\/placement-test/,
                  /\/placement-test-score/,
                  /\/v2\/placement\/[a-zA-Z0-9_.-]/,
                ]}
              >
                <CheckBrowserVersionContainer>
                  <CheckAudioPermissionContainer>
                    <Router history={browserHistory}>
                      <Routes />
                    </Router>
                  </CheckAudioPermissionContainer>
                </CheckBrowserVersionContainer>
              </CheckBrowserContainer>
            </RequireDesktop>
          </Async>
        )}
      </WhitelabelAppProvider>
    );
  }
}

export default App;
