import React from "react";
import PropTypes from "prop-types";
import { getColorOfCourse, WHITE } from "../consts/color";

const CourseIcon = (props) => (
  <div
    style={{
      backgroundColor: getColorOfCourse(props.value).primary,
      border: `${
        {
          xs: 2,
          sm: 2.5,
          md: 3,
          xl: 6,
          xxl: 8,
        }[props.size]
      }px solid ${getColorOfCourse(props.value).secondary}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      overflow: "hidden",
      minWidth: {
        xs: 35,
        sm: 50,
        md: 60,
        xl: 120,
        xxl: 180,
      }[props.size],
      minHeight: {
        xs: 35,
        sm: 50,
        md: 60,
        xl: 120,
        xxl: 180,
      }[props.size],
      width: {
        xs: 35,
        sm: 50,
        md: 60,
        xl: 120,
        xxl: 180,
      }[props.size],
      height: {
        xs: 35,
        sm: 50,
        md: 60,
        xl: 120,
        xxl: 180,
      }[props.size],
      borderRadius: "50%",
    }}
  >
    {props.iconUrl && (
      <img
        src={`${process.env.REACT_APP_FILES_URL}/${props.iconUrl}`}
        style={{ width: "auto", height: "100%", objectFit: "cover" }}
        alt="extra-course-icon"
      />
    )}
    {!props.iconUrl && (
      <>
        {props.value.toLowerCase().startsWith("pre") && (
          <span
            style={{
              backgroundColor: "#fff",
              color: getColorOfCourse(props.value).primary,
              borderRadius: 10,
              padding: "0px 5px",
              fontSize: 7,
              fontWeight: "bold",
              marginBottom: -5,
              height: 10,
            }}
          >
            PRE
          </span>
        )}
        <span
          style={{
            color: WHITE,
            fontSize: {
              sm: 20,
              md: 26,
              xl: 58,
              xxl: 88,
            }[props.size],
            lineHeight: `${
              {
                sm: 20,
                md: 26,
                xl: 58,
                xxl: 88,
              }[props.size] + 10
            }px`,
            fontWeight: 700,
          }}
        >
          {props.value.replace(/pre/i, "").replace("Bett", "A1").replace("Demo", "DM")}
        </span>
      </>
    )}
  </div>
);

CourseIcon.propTypes = {
  value: PropTypes.string,
  iconUrl: PropTypes.string,
  size: PropTypes.oneOf(["sm", "md", "xl", "xxl"]),
};

CourseIcon.defaultProps = {
  value: "",
  iconUrl: undefined,
  size: "md",
};

export default CourseIcon;
