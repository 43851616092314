import React from "react";
import PropTypes from "prop-types";
import Paragraph from "../Paragraph";
import withTranslation from "../withTranslation";

const ItemInstruction = (props) => (
  <div
    style={{
      background: "#CFD8DC",
      textAlign: props.textAlign,
    }}
  >
    <Paragraph
      color="#444"
      style={{
        fontSize: 16,
        padding: 6,
      }}
    >
      <b>{props.getTranslation("instructions")}: </b>
      {props.children}
    </Paragraph>
  </div>
);

ItemInstruction.propTypes = {
  children: PropTypes.node.isRequired,
  textAlign: PropTypes.string,
};

ItemInstruction.defaultProps = {
  textAlign: "center",
};

export default withTranslation(ItemInstruction);
