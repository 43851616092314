import React, { Component } from "react";
import moment from "moment";
import { getPositionsByPeriod } from "student-front-commons/src/services/positionsService";
import FetchData from "student-front-commons/src/components/FetchData";
import Async from "../../core/Async";
import PositionHistory from "./PositionHistory";

class PositionHistoryContainer extends Component {
  render() {
    return (
      <FetchData
        service={getPositionsByPeriod}
        params={{
          id: sessionStorage.getItem("id"),
          from: moment().subtract(1, "month").startOf("month").toDate(),
          to: moment().subtract(1, "month").endOf("month").toDate(),
        }}
      >
        {({ data, isFetching }) => (
          <Async fetching={isFetching} size="sm" color="dark">
            <PositionHistory date={moment().subtract(1, "month").toDate()} positions={data} />
          </Async>
        )}
      </FetchData>
    );
  }
}

export default PositionHistoryContainer;
