import React from "react";
import PropTypes from "prop-types";
import CircularButton from "../CircularButton";
import { BLUE_1 } from "../../consts/color";
import MediaQuery from "react-responsive";
import { addSentryUserAction } from "../../util";

const sentryUserAction = { mainComponent: "NextButton" };

const NextButton = (props) => (
  <MediaQuery maxWidth={540}>
    {(matches) => (
      <CircularButton
        icon="skip-next"
        color={BLUE_1}
        size={matches ? "md" : "lg"}
        onClick={() => {
          props.onClick();
          addSentryUserAction({
            ...sentryUserAction,
            clickedComponent: "CircularButton",
            action: `Advance to next Unit Item`,
          });
        }}
        disabled={props.isDisabled}
        loading={props.isSubmitting}
      />
    )}
  </MediaQuery>
);

NextButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default NextButton;
