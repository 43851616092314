import React from "react";
import PropTypes from "prop-types";
import { ceil } from "lodash";
import FlexRow from "../../core/FlexRow";
import CourseThemeContext from "../../core/CourseThemeContext";
import ColumnSeparator from "../../core/ColumnSeparator";
import FlexColumn from "../../core/FlexColumn";
import Icon from "../../core/Icon";

const UnitSectionProgress = (props) => (
  <CourseThemeContext.Consumer>
    {({ primary }) => (
      <FlexRow alignItems="center">
        <label
          style={{
            margin: 0,
            fontSize: 12,
            lineHeight: "14px",
            color: props.percentage ? primary : "#727272",
          }}
        >
          {ceil(props.percentage)}%
        </label>
        <ColumnSeparator size="xs" />
        <FlexColumn
          position="relative"
          backgroundColor="#fff"
          borderRadius={10}
          height={4}
          width={210}
          overflow="hidden"
          marginRight={-2}
        >
          <FlexColumn
            width={`${props.percentage}%`}
            backgroundColor={primary}
            height="100%"
            position="absolute"
            transition="width 1s ease-in-out"
            left={0}
            top={0}
          />
        </FlexColumn>
        <FlexColumn
          width={24}
          height={24}
          borderRadius={24}
          justifyContent="center"
          alignItems="center"
          backgroundColor="#fff"
        >
          <Icon size="xs" color="#42424280" icon="lock-open-outline" />
        </FlexColumn>
      </FlexRow>
    )}
  </CourseThemeContext.Consumer>
);

UnitSectionProgress.propTypes = {
  percentage: PropTypes.number.isRequired,
};

export default UnitSectionProgress;
