import React, { Component } from "react";
import MediaQuery from "react-responsive";
import min from "lodash/min";
import FlexRow from "../../core/FlexRow";
import FlexColumn from "../../core/FlexColumn";
import Separator from "../../core/Separator";
import WindowResizeListener from "../../core/WindowResizeListener";
import RankingParticipantContainer from "../../core/RankingParticipantContainer";
import CertificationTestStatusBarContainer from "../../core/CertificationTestStatusBarContainer";
import RankingPanel from "./RankingPanel";
import SprintPanelContainer from "./SprintPanelContainer";
import NextMeetingPanelContainer from "./NextMeetingPanelContainer";
import CourseBannerContainer from "./CourseBannerContainer";
import CoursesRulerContainer from "./CoursesRulerContainer";
import StudyQualityPanelContainer from "./StudyQualityPanelContainer";

class HomeScene extends Component {
  state = { width: 0 };

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout);
  }

  handleResize = ({ windowWidth }) => {
    this.resizeTimeout = setTimeout(() => {
      this.setState({
        width: windowWidth,
      });
    }, 0);
  };

  render() {
    return (
      <div>
        <WindowResizeListener onResize={this.handleResize} />
        <CourseBannerContainer />
        <Separator size="lg" />
        <div
          className="container"
          style={{
            paddingRight: this.state.width < 1980 && this.state.width > 1280 ? min([160, 1980 - this.state.width]) : 15,
          }}
        >
          <FlexRow>
            <CertificationTestStatusBarContainer />
          </FlexRow>
          <FlexRow flexWrap="wrap" columnGap={40} rowGap={20}>
            <NextMeetingPanelContainer />
            <FlexColumn maxWidth={700} flexBasis={400} flexGrow={1}>
              <StudyQualityPanelContainer />
            </FlexColumn>
            <MediaQuery minWidth={241}>
              <FlexRow minWidth={241} flexGrow={1}>
                <SprintPanelContainer />
              </FlexRow>
            </MediaQuery>
          </FlexRow>
          <Separator size="lg" />
          <RankingParticipantContainer>
            <RankingPanel />
          </RankingParticipantContainer>
        </div>
        <MediaQuery minWidth={768}>
          <CoursesRulerContainer />
        </MediaQuery>
        <Separator size="md" />
      </div>
    );
  }
}

export default HomeScene;
