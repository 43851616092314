import axios from "axios";
import get from "lodash/get";
import trim from "lodash/trim";
import uniq from "lodash/uniq";
import random from "lodash/random";
import orderBy from "lodash/orderBy";
import invert from "lodash/invert";
import mapValues from "lodash/mapValues";
import { Permutation } from "js-combinatorics";
import ApiError from "../exceptions/apiError";
import validate from "../core/validate";
import { getSpeechRecognitionEndpointUrl, getSpeechRecognitionKey } from "../config";

/**
 * get the speech recognition score
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.id - the user id
 * @param {!boolean} payload.isDemoStudent - if student is flaged as demo
 * @param {!boolean} payload.isStagingEnvironment - if the env is diff from production
 * @param {!boolean} payload.allowSpeechRecognitionBonus - allow the SR bonus
 * @param {!blob} payload.record - the user record blob
 * @param {!string} payload.text - the item text
 * @param {!Object} payload.passUnknownWordsError - send true to receive the raw error
 * @param {Object} payload.wordsDictionary - the object of word to replace
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getScore = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      isDemoStudent: {
        presence: {
          allowEmpty: false,
        },
      },
      isStagingEnvironment: {
        presence: {
          allowEmpty: false,
        },
      },
      record: {
        presence: {
          allowEmpty: false,
        },
      },
      text: {
        presence: {
          allowEmpty: false,
        },
      },
      wordsDictionary: {
        presence: true,
      },
      allowSpeechRecognitionBonus: {
        presence: {
          allowEmpty: false,
        },
      },
    },
    payload
  );

  try {
    const formData = new FormData();

    formData.append("id", payload.id);
    formData.append("demoStudent", payload.isDemoStudent);
    formData.append("isStaging", payload.isStagingEnvironment);
    formData.append("audio", payload.record);
    formData.append("text", applyDictionaryToText(payload.text, payload.wordsDictionary));

    const { data: speechRecognitionResult } = await axios({
      headers: {
        "X-Api-Key": getSpeechRecognitionKey(),
        "Content-Type": "multipart/form-data",
      },
      url: getSpeechRecognitionEndpointUrl(),
      method: "POST",
      data: formData,
    });

    if (payload.allowSpeechRecognitionBonus && speechRecognitionResult.qualityScore < 70) {
      speechRecognitionResult.qualityScore += 20;
      speechRecognitionResult.wordScoreList = speechRecognitionResult.wordScoreList.map((wordScore) => ({
        ...wordScore,
        qualityScore: wordScore.qualityScore + 20 > 100 ? 100 : wordScore.qualityScore + 20,
      }));
    }

    // code to join words that has separated scores because of -
    speechRecognitionResult.wordScoreList = joinWordsSeparatedByChar(
      "-",
      payload.text,
      speechRecognitionResult.wordScoreList
    );

    speechRecognitionResult.text = payload.text;
    speechRecognitionResult.wordScoreList = applyDictionaryToWords(
      payload.text,
      payload.wordsDictionary,
      speechRecognitionResult.wordScoreList
    );

    return speechRecognitionResult;
  } catch (error) {
    if (error && get(error, "response.data.short_message", "") === "error_unknown_words") {
      if (payload.passUnknownWordsError) {
        throw new ApiError({
          response: {
            data: {
              message: "Invalid words in text",
              error: "error_unknown_words",
            },
          },
        });
      }
      return {
        text: payload.text,
        qualityScore: random(80, 90),
        wordScoreList: trim(payload.text)
          .split(" ")
          .map((slice) => ({
            word: slice,
            qualityScore: random(80, 90),
          })),
      };
    } else if (error && get(error, "response.data.short_message", "") === "error_no_speech") {
      throw new ApiError({
        response: {
          data: {
            message: "No speech was detected",
            error: "error_no_speech",
          },
        },
      });
    } else {
      throw new ApiError({
        response: {
          data: {
            message: "Unexpected error to get score",
            error: "error_get_speech_recognition_score",
          },
        },
      });
    }
  }
};

/**
 * get the text using speech recognition
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.id - the user id
 * @param {!boolean} payload.isDemoStudent - if student is flaged as demo
 * @param {!boolean} payload.isStagingEnvironment - if the env is diff from production
 * @param {!blob} payload.record - the user record blob
 * @param {!string} payload.itemType - the item type key
 * @param {!string} payload.text - the item text
 * @param {Object} payload.wordsDictionary - the object of word to replace
 * @param {!Array} payload.answers - the item answers
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getAnswer = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      isDemoStudent: {
        presence: {
          allowEmpty: false,
        },
      },
      isStagingEnvironment: {
        presence: {
          allowEmpty: false,
        },
      },
      record: {
        presence: {
          allowEmpty: false,
        },
      },
      text: {
        presence: {
          allowEmpty: false,
        },
      },
      answers: {
        presence: {
          allowEmpty: false,
        },
      },
      wordsDictionary: {
        presence: true,
      },
    },
    payload
  );

  try {
    const fullWordsDictionary = {
      ...payload.wordsDictionary,
      ...payload.answers
        .filter((answer) => answer.speechRecognitionDictionary)
        .reduce((acc, answer) => ({ ...acc, ...answer.speechRecognitionDictionary }), {}),
    };

    const itemText = applyDictionaryToText(payload.text, fullWordsDictionary);

    const itemAnswers = payload.answers.map((answer) => ({
      ...answer,
      text: applyDictionaryToText(answer.text, fullWordsDictionary),
    }));

    const linkedAnswers = linkAnswers(itemAnswers);

    // generate the text with pattern {index} when we need to replace to an option
    let gapIndex = 0;
    const answerIndexes = {};
    const textWithGap = itemText
      .split(" ")
      .reduce((acc, slice, index) => {
        const isGap = linkedAnswers.find((answer) => answer.index === index);

        if (itemAnswers.find((answer) => answer.index === index) && !isGap) {
          return acc;
        }

        const result = acc.concat(!isGap ? slice : `{${gapIndex}}`).concat(" ");
        if (isGap) {
          answerIndexes[gapIndex] = index;
          gapIndex += 1;
        }

        return result;
      }, "")
      .trim();

    const answersByText = {};
    // replace the pattern to one possible answer
    const permutation = new Permutation(linkedAnswers);
    const textsReplaced = permutation.toArray().map((sortedAnswers) => {
      const textWithAnswer = sortedAnswers.reduce(
        (acc, answer, index) => acc.replace(`{${index}}`, answer.text),
        textWithGap
      );

      answersByText[textWithAnswer] = sortedAnswers.reduce((acc, answer, index) => {
        if (answerIndexes[index] >= 0) {
          return {
            ...acc,
            [answerIndexes[index]]: answer.text,
          };
        }
        return acc;
      }, {});
      return textWithAnswer;
    });

    const formData = new FormData();

    formData.append("id", payload.id);
    formData.append("demoStudent", payload.isDemoStudent);
    formData.append("isStaging", payload.isStagingEnvironment);
    formData.append("audio", payload.record);
    formData.append(
      "text",
      uniq(textsReplaced).reduce((acc, answer) => acc.concat(answer).concat("\n"), "")
    );

    const { data: speechRecognitionResult } = await axios({
      headers: {
        "X-Api-Key": getSpeechRecognitionKey(),
        "Content-Type": "multipart/form-data",
      },
      url: getSpeechRecognitionEndpointUrl(),
      method: "POST",
      data: formData,
    });

    if (speechRecognitionResult.qualityScore <= 35) {
      throw new ApiError("sr_find_text_low_score");
    }

    const result = {
      answer: speechRecognitionResult.text,
    };
    const inverseDictionary = invert(mapValues(fullWordsDictionary, (x) => x.replace(/[' ']/g, "-")));
    if (inverseDictionary && Object.keys(inverseDictionary).length) {
      result.answer = speechRecognitionResult.text
        .split(" ")
        .reduce((acc, slice) => {
          if (inverseDictionary[slice.replace(/[!.?,]/g, "")]) {
            return acc
              .concat(inverseDictionary[slice.replace(/[!.?,]/g, "")].replace(/[_]$/, "").replace(/[_]/g, "."))
              .concat(/[!.?,]$/.test(slice) ? slice.substring(slice.length - 1) : "")
              .concat(" ");
          }
          return acc.concat(slice).concat(" ");
        }, "")
        .trim();
    }

    result.selectedAnswers = Object.keys(answersByText[speechRecognitionResult.text]).reduce((acc, key) => {
      const selectedAnswerGap = answersByText[speechRecognitionResult.text][key];
      return {
        ...acc,
        [key]: selectedAnswerGap
          .split(" ")
          .reduce((answerAcc, slice) => {
            if (inverseDictionary[slice.replace(/[!.?,]/g, "")]) {
              return answerAcc
                .concat(inverseDictionary[slice.replace(/[!.?,]/g, "")])
                .concat(/[!.?,]$/.test(slice) ? slice.substring(slice.length - 1) : "")
                .concat(" ");
            }
            return answerAcc.concat(slice).concat(" ");
          }, "")
          .trim(),
      };
    }, {});

    return result;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else if (error && get(error, "response.data.short_message", "") === "error_unknown_words") {
      throw new ApiError({
        response: {
          data: {
            message: "Missing unknown word in dictionary",
            error: "error_missing_words_dictionary",
          },
        },
      });
    } else if (error && get(error, "response.data.short_message", "") === "error_no_speech") {
      throw new ApiError({
        response: {
          data: {
            message: "No speech was detected",
            error: "error_no_speech",
          },
        },
      });
    } else if (error && get(error, "response.data.short_message", "") === "error_word_alignment") {
      throw new ApiError({
        response: {
          data: {
            message: "You must record the complete phrase",
            error: "error_word_alignment",
          },
        },
      });
    } else {
      throw new ApiError({
        response: {
          data: {
            message: "Unexpected error to get score",
            error: "error_find_speech_recognition_text",
          },
        },
      });
    }
  }
};

/**
 * get the option by speech
 *
 * @param {!Object} payload - The object with all the params
 * @param {!string} payload.id - the user id
 * @param {!boolean} payload.isDemoStudent - if student is flaged as demo
 * @param {!boolean} payload.isStagingEnvironment - if the env is diff from production
 * @param {!blob} payload.record - the user record blob
 * @param {!string} payload.answers - the item text
 * @param {Object} payload.wordsDictionary - the object of word to replace
 *
 * @throws {ApiError} throws an exception with the api error
 *
 * @returns {Promise<*>}
 */
export const getOption = async (payload) => {
  validate(
    {
      id: {
        presence: {
          allowEmpty: false,
        },
      },
      isDemoStudent: {
        presence: {
          allowEmpty: false,
        },
      },
      isStagingEnvironment: {
        presence: {
          allowEmpty: false,
        },
      },
      record: {
        presence: {
          allowEmpty: false,
        },
      },
      answers: {
        presence: {
          allowEmpty: false,
        },
      },
      wordsDictionary: {
        presence: true,
      },
    },
    payload
  );

  try {
    const fullWordsDictionary = {
      ...payload.wordsDictionary,
      ...payload.answers
        .filter((answer) => answer.speechRecognitionDictionary)
        .map((answer) => answer.speechRecognitionDictionary)
        .reduce((acc, dictionary) => ({ ...acc, ...dictionary }), {}),
    };

    const text = payload.answers.reduce(
      (acc, option) => acc.concat(applyDictionaryToText(option.text, fullWordsDictionary).concat("\n")),
      ""
    );

    const formData = new FormData();
    formData.append("id", payload.id);
    formData.append("demoStudent", payload.isDemoStudent);
    formData.append("isStaging", payload.isStagingEnvironment);
    formData.append("audio", payload.record);
    formData.append("text", text);

    const { data: speechRecognitionResult } = await axios({
      headers: {
        "X-Api-Key": getSpeechRecognitionKey(),
        "Content-Type": "multipart/form-data",
      },
      url: getSpeechRecognitionEndpointUrl(),
      method: "POST",
      data: formData,
    });

    if (speechRecognitionResult.qualityScore <= 35) {
      throw new ApiError("sr_find_text_low_score");
    }

    const inverseDictionary = invert(mapValues(fullWordsDictionary, (x) => x.replace(/[' ']/g, "-")));
    if (inverseDictionary && Object.keys(inverseDictionary).length) {
      speechRecognitionResult.text = speechRecognitionResult.text
        .split(" ")
        .reduce((acc, slice) => {
          if (inverseDictionary[slice.replace(/[!.?,]/g, "")]) {
            return acc
              .concat(inverseDictionary[slice.replace(/[!.?,]/g, "")].replace(/[_]$/, "").replace(/[_]/g, "."))
              .concat(/[!.?,]$/.test(slice) ? slice.substring(slice.length - 1) : "")
              .concat(" ");
          }
          return acc.concat(slice).concat(" ");
        }, "")
        .trim();
    }

    return payload.answers.find((answer) => answer.text === speechRecognitionResult.text);
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    } else if (error && get(error, "response.data.short_message", "") === "error_unknown_words") {
      throw new ApiError("error_missing_words_dictionary");
    } else if (error && get(error, "response.data.short_message", "") === "error_no_speech") {
      throw new ApiError("error_no_speech");
    } else {
      throw new ApiError("error_find_speech_recognition_text");
    }
  }
};

const applyDictionaryToText = (text, dictionary) =>
  trim(text)
    .split(" ")
    .map((slice) => {
      let translatedValue = null;
      if (slice.split(".").length - 1 > 1) {
        translatedValue = get(
          dictionary,
          slice
            .replace(/[!?,]$/, "")
            .replace(/"/g, "")
            .replace(/[.]/g, "_"),
          undefined
        );
      } else {
        translatedValue = get(
          dictionary,
          slice
            .replace(/[!?,.]$/, "")
            .replace(/"/g, "")
            .replace(/[.]/g, "_"),
          undefined
        );
      }
      if (translatedValue) {
        return trim(translatedValue)
          .replace(/[' ']/g, "-")
          .concat(/[!.?,]$/.test(slice) ? slice.substring(slice.length - 1) : "");
      }
      return slice;
    })
    .reduce((acc, slice) => acc.concat(slice).concat(" "), "")
    .trim();

const applyDictionaryToWords = (text, dictionary, words) => {
  let availableWords = [...words];

  return trim(text)
    .split(" ")
    .map((slice) => {
      const translatedWords = get(dictionary, slice.replace(/[!?,.]$/g, "").replace(/[.]/g, "_"), undefined);
      if (translatedWords) {
        const foundAsOneWord = availableWords.find(
          (wordScore) => wordScore.word === translatedWords || wordScore.word === translatedWords.replace(/[' ']/g, "-")
        );
        if (foundAsOneWord) {
          availableWords = availableWords.slice(1);
          return {
            word: slice,
            qualityScore: foundAsOneWord.qualityScore,
            audioStartTime: foundAsOneWord.audioStartTime,
            audioEndTime: foundAsOneWord.audioEndTime,
          };
        }

        const translatedScores = trim(translatedWords)
          .replace(/[' ']/g, "-")
          .split("-")
          .map((word) => {
            const currentWordScore = availableWords.find((wordScore) => wordScore.word === word);
            availableWords = availableWords.slice(1);
            return currentWordScore;
          });
        return {
          word: slice,
          qualityScore:
            translatedScores.reduce((acc, wordScore) => acc + wordScore.qualityScore, 0) / translatedScores.length,
          audioStartTime: translatedScores[0].audioStartTime,
          audioEndTime: translatedScores[translatedScores.length - 1].audioEndTime,
        };
      }

      const currentWordScore = availableWords[0];
      availableWords = availableWords.slice(1);
      return currentWordScore;
    });
};

const joinWordsSeparatedByChar = (separatorChar, text, wordScoreList) => {
  text
    .split(" ")
    .map((slice) => slice.replace(/[!.?,]/g, ""))
    .filter((slice) => slice.indexOf(separatorChar) > -1)
    .forEach((slice) => {
      const words = slice.split(separatorChar);
      const initialWordIndex = wordScoreList.findIndex((wordScore, wordScoreIndex) =>
        words.reduce((acc, w, index) => acc && w === wordScoreList[wordScoreIndex + index].word, true)
      );

      if (initialWordIndex > -1) {
        const wordsToJoin = wordScoreList.slice(initialWordIndex, initialWordIndex + words.length);

        // eslint-disable-next-line no-param-reassign
        wordScoreList = [
          ...wordScoreList.slice(0, initialWordIndex),
          {
            word: slice,
            qualityScore: wordsToJoin.reduce((acc, wordScore) => acc + wordScore.qualityScore, 0) / wordsToJoin.length,
            audioStartTime: wordsToJoin[0].audioStartTime,
            audioEndTime: wordsToJoin[wordsToJoin.length - 1].audioEndTime,
          },
          ...wordScoreList.slice(initialWordIndex + words.length),
        ];
      }
    });

  return wordScoreList;
};

const linkAnswers = (itemAnswers) =>
  orderBy(itemAnswers, "index").reduce((result, answer) => {
    if (result.find((resultAnswer) => answer.index && resultAnswer.linkTo === answer.index)) {
      return [
        ...result.map((resultAnswer) => {
          if (resultAnswer.linkTo === answer.index) {
            return {
              ...resultAnswer,
              linkTo: answer.linkTo,
              text: resultAnswer.text.concat(" ").concat(answer.text),
            };
          }
          return resultAnswer;
        }),
      ];
    }
    return [...result, answer];
  }, []);
