import React from "react";
import { withRouter } from "react-router-dom";
import CourseThemeContext from "../../core/CourseThemeContext";
import Icon from "../../core/Icon";
import Button from "../../core/Button";
import ColumnSeparator from "../../core/ColumnSeparator";
import TranslatedText from "../../core/TranslatedText";
import Separator from "../../core/Separator";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";

const CertificationTestFinishedPanel = (props) => (
  <WhitelabelContext.Consumer>
    {({ secondaryColor }) => (
      <CourseThemeContext.Consumer>
        {({ primary }) => (
          <div
            style={{
              width: 680,
              borderRadius: 3,
              backgroundColor: primary,
              padding: "25px 35px 25px 30px",
              display: "flex",
              margin: "0 auto",
            }}
          >
            <Icon icon="check" color="#00786A" size="xl" />
            <ColumnSeparator size="md" />
            <ColumnSeparator size="xs" />
            <div>
              <TranslatedText
                as="p"
                translateKey="certificationTest.execution.completedTitle"
                style={{
                  margin: 0,
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              />
              <Separator />
              <TranslatedText
                as="p"
                translateKey="certificationTest.execution.completedDescription"
                style={{
                  margin: 0,
                  color: "#fff",
                  fontSize: 14,
                }}
              />
              <Separator />
              <Separator size="xs" />
              <TranslatedText
                as={Button}
                translateKey="certificationTest.button.back"
                style={{
                  backgroundColor: secondaryColor,
                  color: "#fff",
                  fontSize: 14,
                  height: 40,
                  fontWeight: "bold",
                  width: "auto",
                  padding: "10px 30px",
                  textTransform: "uppercase",
                }}
                onClick={() => props.history.push("/")}
              />
            </div>
          </div>
        )}
      </CourseThemeContext.Consumer>
    )}
  </WhitelabelContext.Consumer>
);

export default withRouter(CertificationTestFinishedPanel);
