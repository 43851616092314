import styled, { keyframes } from "styled-components";
import RewardBg from "../images/bonus-test-result-reward-bg.png";

const rotateAnimation = keyframes`
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
`;

const ImageAnimated = styled.img`
  animation: ${rotateAnimation} 30s linear infinite;
  position: absolute;
  top: 9px;
`;

const ImgRewardAnimated = (props) => {
  return <ImageAnimated style={{ ...props }} src={RewardBg} />;
};

export default ImgRewardAnimated;
