import { HIDE_MESSAGE, SHOW_MESSAGE } from "../actions/systemMessage";

export default (state = {}, action) => {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        icon: action.payload.icon,
        message: action.payload.message,
        button: action.payload.button,
        type: action.payload.type,
      };
    case HIDE_MESSAGE:
      return {};
    default:
      return state;
  }
};
