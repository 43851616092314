import React from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import Flow from "student-front-commons/src/components/Flow";
import get from "lodash/get";
import CloseExecutionModal from "../../core/CloseExecutionModal";
import { CLOSE_MASTERY_TEST_EXECUTION_FLOW } from "../../consts";

const CloseMasteryTestExecutionContainer = () => (
  <NavigationPrompt
    disableNative={false}
    renderIfNotActive={true}
    when={(from, to) => {
      if (get(to, "state.systemNavigation", false)) {
        return false;
      }

      return (
        !to ||
        (!/\/course\/[0-9a-fA-F]{24}\/modules\/[0-9a-fA-F]{24}\/mastery-test\/[0-9a-fA-F]{24}\/execution\/[0-9a-fA-F]{24}\/result/.test(
          to.pathname
        ) &&
          !["/mastery-test-not-found", "/mastery-test-failed", "/mastery-test-approved"].some(
            (path) => to.pathname === path
          ))
      );
    }}
  >
    {({ isActive, onCancel, onConfirm }) =>
      isActive && (
        <Flow autoStart={false} id={CLOSE_MASTERY_TEST_EXECUTION_FLOW}>
          {({ handleStart: handleClose }) => (
            <CloseExecutionModal
              message="closeExecution.masteryMessage"
              onConfirm={() => {
                handleClose();
                onConfirm();
              }}
              onCancel={onCancel}
            />
          )}
        </Flow>
      )
    }
  </NavigationPrompt>
);

export default CloseMasteryTestExecutionContainer;
