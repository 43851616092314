import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { useForm, useService } from "student-front-commons/src/hooks";
import Styled from "styled-components";
import TranslatedText from "../../core/TranslatedText";
import withTranslation from "../../core/withTranslation";
import FlexRow from "../../core/FlexRow";
import Separator from "../../core/Separator";
import { saveUnitExecutionRating } from "student-front-commons/src/services/unitExecutionService";
import FlexColumn from "../../core/FlexColumn";
import { isRequired } from "student-front-commons/src/validators";
import Heart from "../../assets/icons/Heart";
import HeartOutline from "../../assets/icons/HeartOutline";

const StarRatingButton = Styled.button`
  background: transparent;
  border: none;
`;

const UnitRatingSection = ({ getTranslation }) => {
  const params = useParams();

  const [, sendRating] = useService(saveUnitExecutionRating, {
    autoStart: false,
  });

  const { getValue, setValue, submit } = useForm({
    initialValues: { score: 0 },
    validations: { score: [isRequired] },
    onSubmit: (values) => {
      sendRating({
        module: params.moduleId,
        unit: params.unitId,
        unitExecution: params.executionId,
        question: getTranslation("unitRating.question"),
        ...values,
      });
    },
  });

  const handleScoreChange = (score) => {
    setValue("score", score);
    submit();
  };

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <FlexRow alignItems="center" justifyContent="center" paddingTop="20px">
        <TranslatedText
          as="h1"
          translateKey="unitRating.question"
          style={{
            fontWeight: 600,
            fontSize: 20,
            lineHeight: "30px",
            color: "#475569"
          }}
        />
      </FlexRow>
      <Separator size="md" />
      <FlexRow alignItems="center" justifyContent="center" gap={12}>
        <StarRatingButton onClick={() => handleScoreChange(1)}>
          {getValue("score") >= 1 ? <Heart size={32} /> : <HeartOutline size={32} />}
        </StarRatingButton>
        <StarRatingButton onClick={() => handleScoreChange(2)}>
          {getValue("score") >= 2 ? <Heart size={32} /> : <HeartOutline size={32} />}
        </StarRatingButton>
        <StarRatingButton onClick={() => handleScoreChange(3)}>
          {getValue("score") >= 3 ? <Heart size={32} /> : <HeartOutline size={32} />}
        </StarRatingButton>
        <StarRatingButton onClick={() => handleScoreChange(4)}>
          {getValue("score") >= 4 ? <Heart size={32} /> : <HeartOutline size={32} />}
        </StarRatingButton>
        <StarRatingButton onClick={() => handleScoreChange(5)}>
          {getValue("score") >= 5 ? <Heart size={32} /> : <HeartOutline size={32} />}
        </StarRatingButton>
      </FlexRow>
      <Separator size="lg" />
      <FlexColumn alignItems="center" justifyContent="center">
        <textarea
          onChange={(event) => setValue("comment", event.target.value)}
          onBlur={submit}
          value={getValue("comment")}
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "#94A3B8",
            width: "400px",
            height: "120px",
            border: "1.35px solid #D9D9D9",
            borderRadius: "9px",
            resize: "none",
            padding: "14px",
          }}
          placeholder={getTranslation("unitRating.comment")}
        />
      </FlexColumn>
    </section>
  );
};

UnitRatingSection.propTypes = {
  getTranslation: PropTypes.func.isRequired,
};

export default withTranslation(UnitRatingSection);
