import LogRocket from "logrocket";
import * as Sentry from "@sentry/browser";
import { head, sample, get, orderBy } from "lodash";
import { insertImage } from "./stores/image-store";
import { insertAudio } from "./stores/audio-store";

const loadAudios = async (associativeItem) => {
  if (associativeItem.item.audio || associativeItem.item.generatedAudio) {
    await insertAudio({
      isDeletable: true,
      url: associativeItem.item.audio || associativeItem.item.generatedAudio,
      spriteWords:
        associativeItem.item.type.key !== "VOCABULARY_ACADEMIC"
          ? get(associativeItem.item, "nativeSpeechRecognition.wordScoreList", [])
          : null,
    });
  }
  if (associativeItem.item.postPhraseAudio || associativeItem.item.generatedPostPhraseAudio) {
    await insertAudio({
      isDeletable: true,
      url: associativeItem.item.postPhraseAudio || associativeItem.item.generatedPostPhraseAudio,
      spriteWords:
        associativeItem.item.type.key === "VOCABULARY_ACADEMIC"
          ? get(associativeItem.item, "nativeSpeechRecognition.wordScoreList", [])
          : null,
    });
  }
  if (associativeItem.item.type.itemInstructionSound) {
    await insertAudio({
      isDeletable: true,
      url: associativeItem.item.type.itemInstructionSound,
    });
  }
  if (associativeItem.item.type.initialInstructionSound) {
    await insertAudio({
      isDeletable: true,
      url: associativeItem.item.type.initialInstructionSound,
    });
  }
  if (
    ["SINGLE_CHOICE_TEXT", "SINGLE_CHOICE_IMAGE", "SINGLE_CHOICE_AUDIO", "MULTIPLE_CHOICE_TEXT", "GRAMMAR_CHECK"].some(
      (type) => type === associativeItem.item.type.key
    )
  ) {
    await Promise.all(
      associativeItem.item.answers.map(async (answer) => {
        if (answer.audio || answer.generatedAudio) {
          await insertAudio({
            isDeletable: true,
            url: answer.audio || answer.generatedAudio,
          });
        }
      })
    );
  }
  return associativeItem;
};

export const addSoundToItems = (items) =>
  items.map(async (associativeItem) => {
    return await loadAudios(associativeItem);
  });

export const reduceSoundToItems = (items) =>
  items.reduce(async (acc, associativeItem) => {
    await acc;
    return await loadAudios(associativeItem);
  }, Promise.resolve());

const loadImages = async (associativeItem) => {
  if (associativeItem.item.image) {
    await insertImage({
      url: `items/${associativeItem.item.id}/images/lg.png`,
    });
  }
  if (associativeItem.item.type.key === "SINGLE_CHOICE_IMAGE") {
    await Promise.all(
      associativeItem.item.answers.map(
        async (answer) =>
          await insertImage({
            url: `items/${associativeItem.item.id}/images/${answer.id}.png`,
          })
      )
    );
  }
  return associativeItem;
};

export const addImageDataToItems = (items) =>
  items.map(async (associativeItem) => {
    return await loadImages(associativeItem);
  });

export const reduceImageDataToItems = (items) =>
  items.reduce(async (acc, associativeItem) => {
    await acc;
    return await loadImages(associativeItem);
  }, Promise.resolve());

export const linkAnswers = (answers) =>
  orderBy(answers, "index").reduce((result, answer) => {
    if (result.find((resultAnswer) => answer.index && resultAnswer.linkTo === answer.index)) {
      return [
        ...result.map((resultAnswer) => {
          if (resultAnswer.linkTo === answer.index) {
            return {
              ...resultAnswer,
              linkTo: answer.linkTo,
              text: resultAnswer.text.concat(" ").concat(answer.text),
            };
          }
          return resultAnswer;
        }),
      ];
    }
    return [...result, answer];
  }, []);

export const getItemTypeInstructionAudio = (itemType, soundType, locale) => {
  locale = head(locale.split("-"));
  const instructions = get(itemType, "instructions", []).filter((instruction) => instruction.type === soundType);
  const instruction =
    sample(instructions.filter((instruction) => head(instruction.locale.split("-")) === locale)) ||
    sample(instructions.filter((instruction) => head(instruction.locale.split("-")) === "en"));
  if (instruction) {
    return instruction.audio || instruction.generatedAudio;
  }
  return null;
};

export const addSentryUserAction = ({ mainComponent, clickedComponent, action }) => {
  Sentry.addBreadcrumb({
    type: "user",
    category: "click",
    level: "info",
    message: `User clicked on ${mainComponent} > ${clickedComponent} to ${action}`,
    data: {
      mainComponent,
      clickedComponent,
      action,
    },
  });
};

export const logError = ({ flow, error }) => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    LogRocket.log({ flow, error });
  } else {
    console.log({ flow, error });
  }

  Sentry.withScope((scope) => {
    scope.setTag("flow", flow);
    scope.setExtras({
      error: JSON.stringify(error),
      request: get(error, "axiosError", null),
      sessionURL: LogRocket.sessionURL,
    });
    Sentry.captureException(error);
  });
};
