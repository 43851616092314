import { schema } from "normalizr";

import unitSchema from "./unitSchema";
import scheduledUnitReviewSchema from "./scheduledUnitReviewSchema";
import masteryTestSchema from "./masteryTestSchema";
import { MODULE_SCHEMA } from "./index";

export default new schema.Entity(MODULE_SCHEMA, {
  units: [unitSchema],
  scheduledUnitReviews: [scheduledUnitReviewSchema],
  masteryTests: [masteryTestSchema],
});
