import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import QuestionLabel from "../QuestionLabel";
import Separator from "../Separator";
import ExerciseButton from "./ExerciseButton";
import ExerciseItemPanel from "./ExerciseItemPanel";
import MultipleChoiceTextCheckbox from "./MultipleChoiceTextCheckbox";
import { FEEDBACK_CORRECT, FEEDBACK_WRONG } from "../../consts/color";
import { useDispatch, useSelector } from "react-redux";
import { getItemExecutionPropById } from "student-front-commons/src/selectors/itemExecution";
import { addItemExecutionAnswer } from "student-front-commons/src/actions/itemExecution";

function MultipleChoiceTextRender(props) {
  const dispatch = useDispatch();
  const extraData = useSelector(getItemExecutionPropById(props.itemId, "extraData"));
  const answers = useSelector(getItemExecutionPropById(props.itemId, "item.answers"));
  const linkedAnswers = useSelector(getItemExecutionPropById(props.itemId, "item.linkedAnswers"));
  const isDisabled = useSelector(getItemExecutionPropById(props.itemId, "isDisabled"));
  const playingId = useSelector(getItemExecutionPropById(props.itemId, "playingId"));
  const isExecutionValidated = useSelector(getItemExecutionPropById(props.itemId, "isExecutionValidated"));
  const maxAnswers = answers.length === 4 ? 2 : 3;

  const handleItemClick = useCallback(
    (answer) => {
      const answerAlreadySelected = !!get(extraData, "selectedAnswers", []).find(
        (selectedAnswer) => selectedAnswer.id === answer.id
      );

      const selectedAnswers = answerAlreadySelected
        ? get(extraData, "selectedAnswers", []).filter((selectedAnswer) => selectedAnswer.id !== answer.id)
        : [...get(extraData, "selectedAnswers", []), { id: answer.id, status: "SELECTED" }];

      dispatch(
        addItemExecutionAnswer(props.itemId, {
          answer: answers.filter((a) => !!selectedAnswers.find((sa) => sa.id === a.id)),
          extraData: {
            ...extraData,
            selectedAnswers,
          },
        })
      );
    },
    [props.itemId, answers, dispatch, extraData]
  );

  return (
    <ExerciseItemPanel>
      {linkedAnswers.map((answer) => {
        const currentSelectedAnswer = get(extraData, "selectedAnswers", []).find((a) => a.id === answer.id);
        return (
          <div key={`answer-${answer.id}`}>
            <div className="selectExerciseMultipleChoiceText">
              <ExerciseButton
                disabled={
                  isExecutionValidated ||
                  isDisabled ||
                  (!currentSelectedAnswer && get(extraData, "selectedAnswers", []).length === maxAnswers)
                }
                highlight={playingId === answer.id}
                highlightColor={
                  currentSelectedAnswer
                    ? { CORRECT: FEEDBACK_CORRECT, WRONG: FEEDBACK_WRONG }[currentSelectedAnswer.status]
                    : isExecutionValidated && answer.correct
                    ? FEEDBACK_CORRECT
                    : null
                }
                showBorder={
                  (currentSelectedAnswer &&
                    ["CORRECT", "WRONG"].some((status) => status === currentSelectedAnswer.status)) ||
                  (isExecutionValidated && answer.correct)
                }
                onClick={() => handleItemClick(answer)}
              >
                <MultipleChoiceTextCheckbox status={currentSelectedAnswer && currentSelectedAnswer.status} />
                <QuestionLabel text={answer.text} fontSize={{ sm: "16px", md: "22px" }[props.size]} textAlign="left" />
              </ExerciseButton>
            </div>
            <Separator size="xs" />
          </div>
        );
      })}
    </ExerciseItemPanel>
  );
}

export default function MultipleChoiceTextItem({ size }) {
  const items = useSelector((state) => state.itemExecutions.allIds);

  return items.map((id) => <MultipleChoiceTextRender key={id} itemId={id} size={size} />);
}

MultipleChoiceTextItem.propTypes = {
  size: PropTypes.oneOf(["sm", "md"]).isRequired,
};
