import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";
import UserAvatar from "../UserAvatar";
import Icon from "../Icon";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import { GREEN_3 } from "../../consts/color";

const MessageTextRender = styled.span`
  display: inline-block;
  word-break: break-word;
  a {
    color: #0000FF;
    text-decoration: underline #0000FF;
  }
`;

const MessageChatListItem = (props) => {
  const isUserMessage = props.message.sender.id === sessionStorage.getItem("id");
  return (
    <WhitelabelContext.Consumer>
      {({ primaryColor }) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            flexFlow: isUserMessage ? "row-reverse" : "row",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: isUserMessage ? "flex-end" : "flex-start",
              width: 40,
            }}
          >
            {!props.shouldGroup && <UserAvatar size="xs" src={props.message.sender.profilePicture} />}
          </div>
          <div
            style={{
              marginBottom: props.shouldGroup ? 3 : 0,
              width: "fit-content",
              borderRadius: 10,
              padding: "10px 15px",
              color: isUserMessage ? "#fff" : "#546e7a",
              backgroundColor: isUserMessage ? primaryColor : "#fff",
              ...(isUserMessage && {
                borderBottomRightRadius: !props.shouldGroup ? 0 : 8,
                boxShadow: "3px 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }),
              ...(!isUserMessage && {
                borderBottomLeftRadius: !props.shouldGroup ? 0 : 8,
                boxShadow: "-3px 3px 10px 0 rgba(0, 0, 0, 0.1)",
              }),
            }}
          >
            <MessageTextRender dangerouslySetInnerHTML={{ __html: props.message.text }} />
            <br />
            <span
              style={{
                display: "inline-block",
                fontSize: 10,
                color: isUserMessage ? "rgba(255, 255, 255, 0.5)" : "#546e7a4d",
              }}
            >
              {moment(props.message.sentAt).format("DD/MM/YY HH:mm")}
            </span>
          </div>
          {isUserMessage && props.message.readAt && (
            <div
              style={{
                marginBottom: 5,
                ...(isUserMessage && {
                  marginRight: 5,
                }),
                ...(!isUserMessage && {
                  marginLeft: 5,
                }),
              }}
            >
              <Icon size="xs" icon="check" color={GREEN_3} />
            </div>
          )}
        </div>
      )}
    </WhitelabelContext.Consumer>
  );
};

MessageChatListItem.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.string,
    sentAt: PropTypes.string,
    readAt: PropTypes.string,
    sender: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      profilePicture: PropTypes.string,
    }),
  }).isRequired,
  shouldGroup: PropTypes.bool.isRequired,
};

export default MessageChatListItem;
