import React, { Component } from "react";
import PropTypes from "prop-types";
import { detect } from "detect-browser";
import WhitelabelContext from "student-front-commons/src/components/WhitelabelContext";
import Separator from "./Separator";
import TranslatedText from "./TranslatedText";
import bgLogin from "../images/bg-login.png";

class CheckBrowserVersionContainer extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  state = { allowedVersion: true };

  componentDidMount() {
    const browser = detect();

    if (browser.name === "chrome" && parseInt(browser.version, 10) < 60) {
      this.setState({
        allowedVersion: false,
      });
    }
    if (browser.name === "safari" && parseInt(browser.version, 10) < 11) {
      this.setState({
        allowedVersion: false,
      });
    }
    if (browser.name === "firefox" && parseInt(browser.version, 10) < 55) {
      this.setState({
        allowedVersion: false,
      });
    }
  }

  render() {
    if (this.state.allowedVersion) {
      return this.props.children;
    }
    return (
      <WhitelabelContext.Consumer>
        {({ primaryColor, logoUrl }) => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
              backgroundColor: primaryColor,
              overflow: "hidden",
            }}
          >
            <img
              src={bgLogin}
              alt="bg"
              style={{
                position: "fixed",
                objectFit: "cover",
              }}
            />
            <div
              style={{
                background: "#fff",
                borderRadius: 3,
                padding: "80px 60px",
                maxWidth: "55%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px",
                zIndex: 1,
              }}
            >
              <img
                src={logoUrl}
                alt="logo"
                style={{
                  objectFit: "scale-down",
                  height: "auto",
                  width: 300,
                }}
              />
              <Separator size="md" />
              <Separator size="lg" />
              <TranslatedText
                as="p"
                translateKey="error.checkBrowserVersion.title"
                style={{
                  textTransform: "uppercase",
                  margin: 0,
                  color: "#444",
                  fontWeight: "bold",
                }}
              />
              <Separator size="md" />
              <Separator size="xs" />
              <TranslatedText
                as="label"
                translateKey="error.checkBrowserVersion.description"
                values={{
                  link: (
                    <a
                      href={
                        {
                          chrome: "https://www.google.com.br/chrome/",
                          safari: "https://support.apple.com/pt-br/HT204416",
                          firefox: "https://www.mozilla.org/en-US/firefox/new/",
                        }[detect().name]
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      link
                    </a>
                  ),
                }}
                style={{
                  margin: 0,
                  color: "#8e8e8e",
                  fontSize: 14,
                }}
              />
            </div>
          </div>
        )}
      </WhitelabelContext.Consumer>
    );
  }
}

export default CheckBrowserVersionContainer;
