import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Scrollbar from "react-scrollbars-custom";
import { GREY_2, GREY_4 } from "../consts/color";

const ScrolledContent = (props) => {
  const scrollbarRef = useRef(null);

  useEffect(() => {
    if (scrollbarRef.current) {
      if (props.scrollTo) {
        scrollbarRef.current.scrollTo(0, props.scrollTo);
      }

      if (props.scrollBottom) {
        scrollbarRef.current.scrollToBottom();
      }
    }
  }, [props.scrollTo, props.scrollBottom, scrollbarRef.current?.clientHeight]);
  return (
    <Scrollbar
      ref={scrollbarRef}
      noScrollY={!props.vertical}
      noScrollX
      style={{
        overflow: "hidden",
        position: "relative",
        ...props.style,
      }}
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <span
              {...restProps}
              ref={elementRef}
              className="MyAwesomeScrollbarsWrapper"
              style={{ position: "relative" }}
            />
          );
        },
      }}
      scrollerProps={{
        style: {
          height: "100%",
          position: "relative",
        },
      }}
      trackYProps={{
        style: {
          borderRadius: 1.5,
          backgroundColor: props.containerColor ? props.containerColor : GREY_2,
          width: 17,
          top: 0,
          right: 0,
          height: "100%",
        },
      }}
      thumbYProps={{
        style: {
          borderRadius: 1.5,
          width: 5,
          marginLeft: "calc(50% - 2px)",
          backgroundColor: GREY_4,
        },
      }}
      onScroll={props.onScroll}
    >
      {props.children}
    </Scrollbar>
  );
};

ScrolledContent.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.shape({
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  onScroll: PropTypes.func,
  containerColor: PropTypes.string,
  scrollTo: PropTypes.number,
  stopScrollPropagation: PropTypes.bool,
  scrollBottom: PropTypes.bool,
  vertical: PropTypes.bool,
};

ScrolledContent.defaultProps = {
  onScroll: () => false,
  stopScrollPropagation: false,
  vertical: false,
  scrollBottom: false,
};

export default ScrolledContent;
