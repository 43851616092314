import React from "react";
import Entity from "student-front-commons/src/components/Entity";
import GradeHeader from "./GradeHeader";

const GradeHeaderContainer = () => (
  <Entity name="profile">
    {({ getById: getProfileById }) => {
      const profile = getProfileById(sessionStorage.getItem("id"));
      if (profile.apiVersion === "V2") {
        const teachers = (profile.teachers || [])
          .map((teacher, index, teachers) => {
            if (index < teachers.length - 1) {
              return `${teacher.name}, `
            }
            return teacher.name;
          }).join("");

        return (
          <Entity name="company">
            {({ getById: getCompanyById }) => {
              const company = getCompanyById(profile.company);

              return (
                <GradeHeader
                  student={profile.name}
                  school={company.name}
                  teachers={teachers || "-"}
                  weeklyHoursRequired={profile.weeklyHoursRequired}
                  gradeFormat={company.gradeFormat}
                  maximumGrade={company.maximumGrade}
                />
              );
            }}
          </Entity>
        );
      }
      return (
        <Entity name="schoolClass">
          {({ getById: getSchoolClassById }) => (
            <Entity name="school">
              {({ getById: getSchoolById }) => {
                const schoolClass = getSchoolClassById(profile.schoolClass);
                const school = getSchoolById(schoolClass.school);

                return (
                  <GradeHeader
                    student={profile.name}
                    classRoom={schoolClass.name}
                    school={school.name}
                    teachers={schoolClass.teacher?.name || "-"}
                    weeklyHoursRequired={schoolClass.weeklyHoursRequired}
                    gradeFormat={school.gradeFormat}
                    maximumGrade={school.maximumGrade}
                  />
                );
              }}
            </Entity>
          )}
        </Entity>
      );
    }}
  </Entity>
);

export default GradeHeaderContainer;
