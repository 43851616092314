import axios from "axios";
import { Map } from "immutable";
import { Howl, Howler } from "howler";
import LogRocket from "logrocket";
import { logError, addSentryUserAction } from "../util";
import isFinite from "lodash/isFinite";

let store = new Map();
let currentPlaying = null;
const sentryUserAction = { mainComponent: "playAudio" };

const generateSpeechRecognitionSprites = (source) => {
  if (!source) {
    return null;
  }
  return source.reduce(
    (acc, wordScore, index) => ({
      ...acc,
      [`sprite_${index}`]: [
        wordScore.audioStartTime * 10 - 0.3,
        (wordScore.audioEndTime - wordScore.audioStartTime) * 10 + 0.3,
      ],
    }),
    {}
  );
};

const downloadAudio = async ({ url, isRetry }) => {
  let audioData = null;
  try {
    const response = await axios.get(
      `${isRetry ? process.env.REACT_APP_FILES_FALLBACK_URL : process.env.REACT_APP_FILES_URL}/${url}`,
      { responseType: "blob" }
    );

    audioData = await new Promise((resolve) => {
      let reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
        reader = null;
      };
      reader.onerror = () => {
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Error to convert audio to base64: ${reader.error}`,
        });
        reader = null;
        throw new Error("error_creating_base64");
      };
      reader.readAsDataURL(response.data);
    });
  } catch (error) {
    if (error.response) {
      logError({ error: `${error.response.status}: ${error.response.statusText}`, flow: "DOWNLOAD_AUDIO_DATA" });
    } else if (error.request) {
      logError({ error: `without_response`, flow: "DOWNLOAD_AUDIO_DATA" });
    } else {
      logError({ error: error.message, flow: "DOWNLOAD_AUDIO_DATA" });
    }
    return null;
  }

  if (audioData && audioData.indexOf("data:application/octet-stream") > -1) {
    const extension = url.split(".").pop();
    audioData = audioData.replace(
      "data:application/octet-stream",
      { mp3: "data:audio/mpeg", ogg: "audio/ogg", wav: "audio/x-wav", webm: "data:audio/webm" }[extension] ||
        "data:audio/mpeg"
    );
  }

  return audioData;
};

export const insertSpeechRecognitionAudio = async ({ data, isDeletable, spriteWords }) => {
  store = store.set(data, {
    data,
    isDeletable,
    spriteWords,
    audioSprites: generateSpeechRecognitionSprites(spriteWords),
  });
};

export const insertAudio = async ({ url, isDeletable, spriteWords }) => {
  const audioData = await downloadAudio({ url, isRetry: false });

  store = store.set(url, {
    data: audioData,
    isDeletable,
    spriteWords,
    audioSprites: generateSpeechRecognitionSprites(spriteWords),
  });

  if (!audioData) {
    setTimeout(async () => {
      const audioData = await downloadAudio({ url, isRetry: true });

      store = store.set(url, {
        data: audioData,
        isDeletable,
        spriteWords,
        audioSprites: generateSpeechRecognitionSprites(spriteWords),
      });
    }, 500);
  }
};

export const clearAudios = () => {
  store = store.filter((register) => !register.isDeletable);
};

export const getAudio = ({ url }) => {
  const register = store.get(url);
  if (!register) {
    throw new Error("audio_register_not_found");
  }
  if (!register.data) {
    throw new Error("audio_data_not_available");
  }

  return register.data;
};

export const getAudioInsertConfigs = ({ url }) => {
  const register = store.get(url);
  if (!register) {
    throw new Error("audio_register_not_found");
  }
  return register;
};

export const playAudio = async ({ url, rate, sprite, resolveOnPlay }) => {
  let audioData = null;
  let attempts = 0;
  let error = null;

  while (!audioData && attempts < 2) {
    try {
      LogRocket.log("getting audio " + url);
      audioData = getAudio({ url });
      LogRocket.log("audio available");
    } catch (e) {
      LogRocket.log("error to get audio " + url);
      error = e;
      const { data, ...configs } = getAudioInsertConfigs({ url });
      addSentryUserAction({
        ...sentryUserAction,
        clickedComponent: "None",
        action: `Failed to get audio: ${url}. Trying to create audio again with configs: ${JSON.stringify(configs)}.`,
      });
      LogRocket.log("Try to insert again");
      await insertAudio({
        url,
        ...configs,
      });
      attempts++;
    }
  }

  if (!audioData) {
    throw error;
  }

  const { audioSprites } = getAudioInsertConfigs({ url });

  LogRocket.log("creating howler sound");

  const sound = await new Promise((resolve, reject) => {
    const howlerInstance = new Howl({
      src: [audioData],
      autoplay: false,
      loop: false,
      html5: true,
      sprite: !!sprite ? audioSprites : null,
      onload: () => resolve(howlerInstance),
      onloaderror: (id, error) => {
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: `url: ${error} - hasData: ${!!audioData}`,
          action: `error_load_audio_${error}`,
        });
        reject(`error_load_audio_${error}`);
      },
    });
  });

  let playTimeout = null;
  let suspendTimeout = null;
  let onEndTimeout = null;
  let soundId = null;
  return new Promise((resolve, reject) => {
    try {
      currentPlaying = sound;
      currentPlaying.once("end", () => {
        LogRocket.log("audio ended");
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Audio ${url} ended`,
        });
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
        if (currentPlaying) {
          currentPlaying.unload();
          currentPlaying = null;
        }
        soundId = null;
        resolve();
      });
      currentPlaying.once("playerror", (id, error) => {
        LogRocket.log("error to play audio " + url, error);
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Error playing audio ${url}`,
        });
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
        if (currentPlaying) {
          currentPlaying.unload();
          currentPlaying = null;
        }
        soundId = null;
        reject(error);
      });
      currentPlaying.once("play", () => {
        LogRocket.log("audio played");
        let onEndTimeoutMilliseconds = 3000;
        if (soundId && currentPlaying && currentPlaying.duration(soundId)) {
          //audio duration in seconds * 1000 divided by the rate plus additional 500 milliseconds
          onEndTimeoutMilliseconds = (currentPlaying.duration(soundId) * 1000) / (rate || 1) + 500;
        }
        if (isFinite(onEndTimeoutMilliseconds)) {
          onEndTimeout = setTimeout(() => {
            LogRocket.log("audio end timeout. Howler state " + Howler.state);
            if (suspendTimeout) {
              clearTimeout(suspendTimeout);
              suspendTimeout = null;
            }
            if (playTimeout) {
              clearTimeout(playTimeout);
              playTimeout = null;
            }
            if (onEndTimeout) {
              clearTimeout(onEndTimeout);
              onEndTimeout = null;
            }
            if (currentPlaying) {
              LogRocket.log(`Audio state: ${currentPlaying.state()}`);
              currentPlaying.unload();
              currentPlaying = null;
            }
            soundId = null;
            resolve();
          }, onEndTimeoutMilliseconds);
        }
        addSentryUserAction({
          ...sentryUserAction,
          clickedComponent: "None",
          action: `Playing audio ${url}`,
        });
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (resolveOnPlay) {
          if (onEndTimeout) {
            clearTimeout(onEndTimeout);
          }
          soundId = null;
          resolve(currentPlaying);
        }
      });
      currentPlaying.once("stop", () => {
        if (suspendTimeout) {
          clearTimeout(suspendTimeout);
          suspendTimeout = null;
        }
        if (playTimeout) {
          clearTimeout(playTimeout);
          playTimeout = null;
        }
        if (onEndTimeout) {
          clearTimeout(onEndTimeout);
          onEndTimeout = null;
        }
      });
      if (!!sprite) {
        soundId = currentPlaying.play(sprite);
      } else {
        currentPlaying.rate(rate || 1);
        suspendTimeout = setTimeout(() => {
          LogRocket.log("audio timeout before play. Howler state " + Howler.state);
          if (Howler.state === "suspended") {
            LogRocket.log(Howler.ctx);
            if (currentPlaying) {
              currentPlaying.unload();
              currentPlaying = null;
            }
            reject("required-user-click");
          }
        }, 3000);
        playTimeout = setTimeout(() => {
          LogRocket.log(`Audio state: ${currentPlaying.state()}`);
          if (currentPlaying) {
            currentPlaying.unload();
            currentPlaying = null;
          }
          reject("not-started");
        }, 15000);
        soundId = currentPlaying.play();
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const stopAudio = () => {
  if (currentPlaying) {
    if (currentPlaying.playing()) {
      currentPlaying.stop();
    }
    currentPlaying = null;
  }
};
